import React, {useEffect, useState} from "react"
import lightning from "../../../../assets/icons/generic/lightning.png"
import gift from "../../../../assets/icons/generic/gift.png"
import feather from "../../../../assets/icons/generic/feather.png"
import petal from "../../../../assets/icons/generic/petal_new.png"
import checked from "../../../../assets/icons/generic/checked.png"
import remove from "../../../../assets/icons/generic/remove.png"
import brokenImage from "../../../../assets/icons/generic/broken-image.png"
import removeGrey from "../../../../assets/icons/generic/remove-grey.png"
import planningPlus from "../../../../assets/icons/generic/planning-plus.png"
import premiumPlus from "../../../../assets/icons/generic/premium-plus.png"
import premiumCoach from "../../../../assets/icons/generic/premium-coach.png"
import {Image, Platform} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

export const PremiumPlus = (props) => {
    return <IconImageM {...props} source={premiumPlus} width={props.size}/>
}

export const PremiumCoach = (props) => {
    return <IconImageM {...props} source={premiumCoach} width={props.size}/>
}

export const Xp = (props) => {
    return <IconImageM {...props} source={lightning} width={props.size} height={props.size * 128 / 83}/>
}

export const Gift = (props) => {
    return <IconImageM {...props} source={gift} width={props.size}/>
}
export const PlanningPlus = (props) => {
    return <IconImageM {...props} source={planningPlus} width={props.size}/>
}

export const Feather = (props) => {
    return <IconImageM {...props} source={feather} width={props.size}/>
}

export const Petal = (props) => {
    return <IconImageM {...props} source={petal} width={props.size}/>
}

export const SkillSetTitle = (props) => {

    // console.log("props.icon", props.icon)
    return !!props.icon && <IconImageM width={props.size}
                       uri={props.icon}/>
}

export const Check = (props) => {
    return <FontAwesome5 {...props}
                         name="check"
                         color={props.color}
                         size={props.size}/>
}

export const Checked = (props) => {
    return <IconImageM {...props} source={checked} width={props.size}/>
}

export const Remove = (props) => {
    return <IconImageM {...props} source={remove} width={props.size}/>
}

export const RemoveGrey = (props) => {
    return <IconImageM {...props} source={removeGrey} width={props.size}/>
}

export const IconImageM = (props) => {

    const {uri, source, width, style, height, resizeMode} = props
    const [size, setSize] = useState({width: width, height: width})


    useEffect(() => {
        if (Platform.OS !== 'web')
            if (!height && (!!source || !!uri))
                (async () => {
                    Image.getSize(source ? Image.resolveAssetSource(source).uri : uri, (width, height) => {
                        setSize({width, height})
                    })
                })();

        return () => setSize({})
    }, [])


    return <Image source={source ?? (uri ? {uri} : brokenImage)}
                  resizeMode={resizeMode}
                  style={{
                      ...style,
                      width: width,
                      height: height ? height : Platform.OS !== 'web' ? (width * size.height / size.width) : width
                  }}/>
}