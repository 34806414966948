import React, {useContext} from "react";
import {Platform, ScrollView, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {qualities, studentClasses, subjectPriorities} from "@data/constants/formConstants";
import SelectBox from "@common/components/select/selectSingle-Multiple";
import {xorBy} from "lodash";
import {Controller} from "react-hook-form";
import {hasRole} from "@data/utility/ability";
import {ProgressBarM} from "@common/components/progress/ProgressBarM";
import TextM from "@common/components/text/TextM";
import {reasons} from "../../auth/RegisterScreen";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {AbilityContext} from "@data/utility/Can";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {findItemInListWithId} from "@data/utility/dataUtils";

const SettingsProfileScreen = (props) => {
    const {control, errors} = props;
    const ability = useContext(AbilityContext)
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    const user = props.userId ? findItemInListWithId(props.userId, props.users) : props.user
    const isAdmin = ability.can("read", "backOffice")

    return (
        <View
            style={{
                flex: 1,
                // backgroundColor: theme.colors.white,
                justifyContent: "space-between",
                width: dimensions?.width,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
        >
            {hasRole(user, "student") && <View style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                // backgroundColor: theme.colors.whiteAlmost,
                borderBottomWidth: 0.5,
                borderColor: theme.colors.light,
                paddingTop: 10,
                paddingBottom: 10,
                paddingHorizontal: 20,
                width: IS_WEB ? dimensions?.width : "100%",
                alignSelf: "center"
            }}>
                <View style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    width: "100%"
                }}>
                    <TextM style={{marginTop: 7, marginRight: 5}} fontWeight={"SemiBold"}>Profil complété à </TextM>
                    <ProgressBarM
                        progressColor={user?.profile_completion === 100 ? theme.colors.success : theme.colors.accent}
                        count={user?.profile_completion}
                        percent
                        total={100}/>
                </View>
                <View>
                    <TextM style={{marginTop: 7, marginRight: 5}}
                           fontSize={12}
                           wrap
                           fontWeight={"Regular"}>Complète ton profil, puis clique sur le bouton en haut à droite pour
                        mettre à jour</TextM>
                </View>
            </View>}

            <ScrollView>
                <View
                    style={{
                        marginHorizontal: 15,
                        marginTop: 20,
                        marginBottom: 220,
                        alignSelf: Platform.OS !== 'web' ? null : "center"
                    }}
                >
                    {hasRole(user, "student") ? (
                        <>
                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="class"
                                render={({field: {onChange, value}}) => (
                                    <SelectBox
                                        title="Classe"
                                        options={studentClasses?.map((x) => {
                                            return {value: x, label: x};
                                        })}
                                        value={value}
                                        onSingleSelect={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />

                            {isAdmin && <Controller
                                control={control}
                                // rules={validation.required()}
                                name="prioritized_school_subjects"
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <SelectBox
                                            title="Matières prioritaires"
                                            options={props.schoolSubjects.map((x) => {
                                                return {value: x.id.toString(), label: x.name, icon: x?.icon};
                                            })}
                                            disabled={true}//
                                            selectedValues={value}
                                            onMultiSelect={(item) => {
                                                onChange(xorBy(value, [item], "value"));
                                            }}
                                            onTapClose={(item) => {
                                                onChange(xorBy(value, [item], "value"));
                                            }}
                                            isMulti
                                        />
                                    );
                                }}
                            />}

                            {(control._formValues?.class?.value === "1ère" || control._formValues?.class?.value === "Tle") &&
                                <Controller
                                    control={control}
                                    // rules={validation.required()}
                                    name="specialty_school_subjects"
                                    render={({field: {onChange, value}}) => {
                                        return (
                                            <SelectBox
                                                title="Mes matières de spécialité"
                                                options={props.schoolSubjects.map((x) => {
                                                    return {value: x.id.toString(), label: x.name, icon: x?.icon};
                                                })}
                                                selectedValues={value}
                                                onMultiSelect={(item) => {
                                                    onChange(xorBy(value, [item], "value"));
                                                }}
                                                onTapClose={(item) => {
                                                    onChange(xorBy(value, [item], "value"));
                                                }}
                                                isMulti
                                            />
                                        );
                                    }}
                                />}

                            <TextInputM
                                style={{fontSize: 14}}
                                contentContainerStyle={{marginBottom: 10}}
                                placeholder="ex : Paris"
                                labelTop="Ville"
                                name="city"
                                control={control}
                                errors={errors}
                            />

                            <TextInputM
                                style={{fontSize: 14}}
                                contentContainerStyle={{marginBottom: 10}}
                                placeholder="ex : Lycée Descartes"
                                labelTop="Établissement Scolaire"
                                name="school_name"
                                control={control}
                                errors={errors}
                            />

                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="reason"
                                render={({field: {onChange, value}}) => (
                                    <SelectBox
                                        title="En quoi Masteur peut m'être utile ?"
                                        options={reasons}
                                        value={value}
                                        onSingleSelect={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="subject_priority"
                                render={({field: {onChange, value}}) => (
                                    <SelectBox
                                        title="Les matières où j'ai le plus besoin de m'améliorer"
                                        options={subjectPriorities}
                                        value={value}
                                        onSingleSelect={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="qualities"
                                render={({field: {onChange, value}}) => (
                                    <SelectBox
                                        title="Mes principales qualités"
                                        options={qualities}
                                        selectedValues={value}
                                        onMultiSelect={(item) => {
                                            onChange(xorBy(value, [item], "value"));
                                        }}
                                        onTapClose={(item) => {
                                            onChange(xorBy(value, [item], "value"));
                                        }}
                                        isMulti
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="appreciated_school_subjects"
                                render={({field: {onChange, value}}) => {
                                    return (
                                        <SelectBox
                                            title="Les matières que j'apprecie le plus"
                                            options={props.schoolSubjects.map((x) => {
                                                return {value: x.id.toString(), label: x.name, icon: x?.icon};
                                            })}
                                            selectedValues={value}
                                            onMultiSelect={(item) => {
                                                onChange(xorBy(value, [item], "value"));
                                            }}
                                            onTapClose={(item) => {
                                                onChange(xorBy(value, [item], "value"));
                                            }}
                                            isMulti
                                        />
                                    );
                                }}
                            />

                            <TextInputM
                                style={{fontSize: 14}}
                                contentContainerStyle={{marginBottom: 10}}
                                placeholder="ex : Médecin, Enseignant..."
                                labelTop="Le(s) métier(s) où je m'imagine plus tard"
                                name="future_job"
                                control={control}
                                errors={errors}
                            />

                            <TextInputM
                                style={{fontSize: 14}}
                                contentContainerStyle={{marginBottom: 10}}
                                placeholder="ex : 1ère ST2S, Ecole d'ingénieur..."
                                labelTop="L'orientation que je souhaiterais"
                                name="future_orientation"
                                control={control}
                                errors={errors}
                            />

                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="personal_work_frequency"
                                render={({field: {onChange, value}}) => (
                                    <SelectBox
                                        inputPlaceholder=""
                                        title="Globalement en dehors des cours je travaille"
                                        options={props.personalWorkForm?.frequency?.values?.map(
                                            (x) => {
                                                return {value: x, label: x};
                                            }
                                        )}
                                        value={value}
                                        onSingleSelect={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />

                            <Controller
                                control={control}
                                // rules={validation.required()}
                                name="personal_work_duration"
                                render={({field: {onChange, value}}) => (
                                    <SelectBox
                                        title="Chaque semaine, en dehors des cours, je travaille en moyenne"
                                        options={props.personalWorkForm?.duration?.values?.map(
                                            (x) => {
                                                return {value: x, label: x};
                                            }
                                        )}
                                        value={value}
                                        onSingleSelect={(value) => {
                                            onChange(value);
                                        }}
                                    />
                                )}
                            />
                        </>
                    ) : ability.can("read", "my_tutor_profile_update") || hasRole(user, "tutor") || hasRole(user, "tutor-candidate") ? (
                        <>
                            <FilledButtonM style={{
                                // width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 2 : null
                            }} label={"Mettre à jour"} color={theme.colors.primary}
                                           onPress={() => navigation.push("tutor/complete-profile")}/>
                        </>
                    ) : null}
                </View>
            </ScrollView>

            <StatusBar
                style="dark"
                backgroundColor={theme.colors.white}
                translucent={false}
            />
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        updateStudentAccountRequest: state.api.updateStudentAccount?.request,
        updateStudentAccountSuccess: state.api.updateStudentAccount?.success,
        updateStudentAccountError: state.api.updateStudentAccount?.error,
        user: state.data.currentUser?.object,
        personalWorkForm: state.data.staticData?.personal_work_form,
        schoolSubjects: state.data.staticData?.school_subjects,
        token: state.data.session?.object?.token,
        users: state.data.users?.usersList
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsProfileScreen);
