// ** React Imports
import React, {useContext, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {backOfficeService} from "@data/services/backOfficeService";
import UsersTable from "./UsersTable";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {IconButton} from "react-native-paper";
import {ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ServerSearch from "./ServerSearch";
import {MenuFilter} from "@common/components/menu/MenuFilter";
import {interviewReportState, subscribedProducts, subscriptionStates} from "@data/constants/formConstants";
import {allOptionsFromSectionOptions} from "@data/utility/Utils";
import FilledButtonM from "@common/components/button/FilledButtonM";

const UsersTabScreen = (props) => {
    //console.log(props.users)

    const {navigation, route} = props
    const {initialTab} = route.params;

    const tabRoles = {
        "student": ["student"],
        "tutor": ["tutor", "tutor-candidate"],
        "admin": ["admin", "super-admin"]
    }

    const [urlParams, setUrlParams] = useState({
        roles: Object.values(tabRoles)?.[initialTab]?.join(",") ?? ""
    })

    const [formValues, setFormValues] = useState(null)
    // console.log("formValues", formValues)
    const requestApiService = backOfficeService.searchUser

    const urlParamsAdditional = {
        subscription_states: formValues?.subscription_states?.join(",") ?? "",
        subscribed_products: formValues?.subscribed_products?.join(",") ?? "",
        days_since_last_interview_report: formValues?.days_since_last_interview_report?.join(",") ?? ""
    }

    // ** Filter action
    const onFinishSelecting = (newFormValues = null) => {
        if (newFormValues) {
            props.apiRequest(requestApiService, {}, {}, {
                ...urlParams,
                student_classes: newFormValues?.subscription_states?.join(",") ?? "",
                subscribed_products: formValues?.subscribed_products?.join(",") ?? "",
                days_since_last_interview_report: formValues?.days_since_last_interview_report?.join(",") ?? ""
            })
        } else {
            props.apiRequest(requestApiService, {}, {}, {...urlParams, ...urlParamsAdditional})
        }
    }

    // ** Filter options
    const filterItems = [
        {
            name: "subscription_states",
            label: "Abonnement",
            sectionMode: true,
            options: subscriptionStates,
            defaultValue: allOptionsFromSectionOptions(subscriptionStates)
        },
        {
            name: "subscribed_products",
            label: "Privilèges",
            sectionMode: true,
            options: subscribedProducts,
            defaultValue: allOptionsFromSectionOptions(subscribedProducts)
        },
        {
            name: "days_since_last_interview_report",
            label: "Rapport parent",
            sectionMode: true,
            options: interviewReportState,
            defaultValue: []
        }
    ]


    useEffect(() => {
        if (props.validateSuccess) {
            props.apiIdle(backOfficeService.validateTutor)
            DialogM.show({
                text1: 'Succès',
                text2: `La candidature de ${props.validatedTutor.display_name} a bien été validée.`
            })
        }
        if (props.validateError) {
            props.apiIdle(backOfficeService.validateTutor)
            DialogM.show({
                text1: 'Erreur',
                text2: props.validateError.message
            })
        }
    }, [props.validateSuccess, props.validateError])


    const dimensions = useContext(DimensionsContext)

    const renderHeader = () => <>
        <View style={{flexDirection: "row", alignItems: "center", marginRight: 20}}>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    // position: "absolute",
                    marginRight: 10
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
            <TitleM>Liste des élèves</TitleM>
        </View>
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <FilledButtonM label={"Créer élève"} style={{width: null, margin: 10}}
                           labelStyle={{fontSize: 12}} onPress={() => {
                navigation.push("new-student")
            }}/>
            <MenuFilter style={{
                alignItems: "space-between",
                width: 300,
                marginBottom: 10
            }}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        filterItems={filterItems}
                        onFinishSelecting={onFinishSelecting}
            />
            <ServerSearch style={{
                width: 240,
                margin: 10
            }}
                          urlParams={urlParams}
                          setUrlParams={setUrlParams}
            />
        </View>
    </>

    // //** ComponentDidMount
    // useEffect(() => {
    //     props.apiRequest(backOfficeService.getAllUsers)
    // }, [])
    return (
        <View style={{
            flex: 1
        }}
        >
            <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}}>
                {dimensions?.width < 800 ? <ScrollView horizontal={true} style={{width: "100%"}}>
                    <View style={{flexDirection: "row"}}>
                        {renderHeader()}
                    </View>
                </ScrollView> : <>
                    {renderHeader()}
                </>}
            </HeaderContainerM>
            <UsersTable
                tabIndex={0}
                urlParams={{...urlParams, roles: tabRoles.student?.join(","), ...urlParamsAdditional}}
                tab="student"/>
        </View>
    )


}

const mapStateToProps = state => {
    return {
        usersRequest: state.api?.getAllUsers?.request,
        validateRequest: state.api?.validateTutor?.request,
        validateSuccess: state.api?.validateTutor?.success,
        validateError: state.api?.validateTutor?.error,
        validatedTutor: state.api?.validateTutor?.data

    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTabScreen)