export const jackets = {
    jacket0: true,
    jacket1: true,
    jacket2: true,
    jacket3: true,
    jacket4: true,
    jacket5: true,
};
export const jacketList = [
    {
        name: "jacket0",
    },
    {
        name: "jacket1",
    }, {
        name: "jacket2",
    }, {
        name: "jacket3",
    }, {
        name: "jacket4",
    }, {
        name: "jacket5",
    },
];

