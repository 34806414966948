import {View} from "react-native";
import TextM from "../common/components/text/TextM";
import React, {useContext} from 'react'
import {DimensionsContext, IS_WEB} from "../common/utils/mobileUtils";
import {theme} from "../common/theme/theme";
import {Avatar} from "react-native-paper";
import {nicerTimeFromSeconds} from "../tutor/home/interview/InterviewStatisticsCard";
import {boxedCardBorderStyle} from "../reviewer/BoxedCardBorderStyle";


export const GlobalStatsSection = (props) => {

    const dimensions = useContext(DimensionsContext)
    const GlobalStatsItem = ({stat, subtitle, icon}) => <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        width: IS_WEB && dimensions.width > 800 ? "25%" : "90%",
        paddingVertical: 10
    }}>
        <Avatar.Icon size={25}
                     style={{
                         backgroundColor: theme.colors.primaryLighter,
                         width: 50,
                         height: 50,
                         borderRadius: 50,
                         alignItems: 'center',
                         justifyContent: 'center'
                     }}
                     icon={icon}
        />
        <View style={{
            paddingHorizontal: 20,
            width: "80%",
        }}>
            <TextM wrap fontWeight={"Bold"} fontSize={20}>{stat}</TextM>
            <TextM wrap>{subtitle}</TextM>
        </View>
    </View>
    return (
        <View style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: dimensions?.width,
            paddingVertical: 20
        }}>
            <TextM fontWeight={"Bold"}
                   style={{
                       marginLeft: 5
                   }}
                   wrap
                   color={theme.colors.primary}
                   fontSize={20}>
                Statistiques globales
            </TextM>
            <View style={{
                //justifyContent: 'space-between',
                width: "100%",
                flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : "column",
                flexWrap: 'wrap',
                ...boxedCardBorderStyle,
                backgroundColor: theme.colors.white,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderColor: theme.colors.lightMore,
                marginVertical: 20,
                padding: 20,
                alignItems: 'center',
            }}>
                <GlobalStatsItem icon={"user"} stat={props.data?.global_statistics?.students}
                                 subtitle={"Comptes élèves"}/>
                <GlobalStatsItem icon={"user-check"} stat={props.data?.global_statistics?.students_with_activities}
                                 subtitle={"Comptes élèves avec au moins 1 activité"}/>
                <GlobalStatsItem icon={"clock"}
                                 stat={`${nicerTimeFromSeconds(props.data?.global_statistics?.activity_duration)[0]}h ${nicerTimeFromSeconds(props.data?.global_statistics?.activity_duration)[1]}min`}
                                 subtitle={"Temps d'activité"}/>
                <GlobalStatsItem icon={"bullseye"}
                                 stat={props.data?.global_statistics?.activity_count}
                                 subtitle={"Activités réalisées"}/>
                <GlobalStatsItem icon={"gift"}
                                 stat={props.data?.global_statistics?.gesture_count}
                                 subtitle={"Gestes achetés"}/>
                <GlobalStatsItem icon={"chalkboard-teacher"}
                                 stat={props.data?.global_statistics?.interview_count}
                                 subtitle={"Entretiens réalisés"}/>
                <GlobalStatsItem icon={"gem"}
                                 stat={props.data?.global_statistics?.gems_count}
                                 subtitle={"Gemmes attribués"}/>
            </View>
        </View>
    )
}