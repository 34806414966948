import {Image, View, TouchableOpacity} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import {AbilityContext} from "@data/utility/Can";
import fire from "../../../assets/icons/generic/fire.png";
import appStore from "../../../assets/app-store-b.png";
import playStore from "../../../assets/google-play-b.png";

const DownloadAppCongrats = props => {

    const {} = props

    const successSize = 80
    const ability = useContext(AbilityContext)

    const congratsTitle = "N’attendez pas votre première session Live pour découvrir notre appli"
    const congratsText = "95% de nos élèves ne peuvent plus s’en passer !"
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 50,
        paddingHorizontal: 15,
        width: dimensions?.width
    }}>

        <IconImageM width={successSize} source={fire}/>
        <TextM fontSize={22}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center",
                   marginTop: 10
               }}
               color={theme.colors.primary}>
            {congratsTitle.toUpperCase()}
        </TextM>

        <TextM fontSize={18}
               wrap
               fontWeight="Regular"
               style={{
                   textAlign: "center",
                   marginTop: 10,
                   marginBottom: 20,
                   marginHorizontal: 30
               }}
               color={theme.colors.grey}>
            {congratsText}
        </TextM>

        <TouchableOpacity style={{
            marginTop: 30
        }}
                          onPress={() => {
                              window.open("https://apps.apple.com/app/id1614253966", "_blank")
                          }}
        >
            <Image style={{
                width: 200,
                height: 200 * 162 / 512
            }}
                   resizeMode="contain"
                   source={appStore}
            />
        </TouchableOpacity>
        <TouchableOpacity style={{
            marginTop: 10
        }}
                          onPress={() => {
                              window.open("https://play.google.com/store/apps/details?id=com.masteur.app", "_blank")
                          }}
        >
            <Image style={{
                width: 200,
                height: 200 * 250 / 646
            }}
                   resizeMode="contain"
                   source={playStore}
            />
        </TouchableOpacity>


        <View style={{height: 100}}/>

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppCongrats)