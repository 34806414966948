import {ScrollView, View} from "react-native";
import React, {useContext, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import ActivityButton from "../list/ActivityButton";
import ChallengePlanningScroll from "./ChallengePlanningScroll";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import RealizationsListItem from "../list/RealizationsListItem";
import moment from "moment";
import TextM from "@common/components/text/TextM";
import ChallengeHeaderNew from "./ChallengeHeaderNew";
import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import {Divider} from "react-native-paper";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";

const ChallengesListItem = props => {

    const activitiesListForXP = () => {
        return <ScrollView horizontal
                           style={{
                               // height: 160,
                               width: dimensions?.width,
                               // flex:1,
                               // flexDirection: 'row',
                               // flexWrap: "wrap",
                               // alignItems: "center",
                               // justifyContent: 'space-evenly',
                               // justifyContent: 'space-evenly',
                               //paddingHorizontal: 20,
                               backgroundColor: theme.colors.primaryLighter,
                               borderBottomLeftRadius: IS_WEB ? 10 : 0,
                               borderBottomRightRadius: IS_WEB ? 10 : 0
                           }}
        >
            {/*<View*/}
            {/*    style={{*/}
            {/*        // height: 160,*/}
            {/*        flexDirection: 'row',*/}
            {/*        // justifyContent: 'space-evenly'*/}
            {/*        // flexWrap: "wrap",*/}
            {/*    }}*/}
            {/*>*/}


                {props.activityTemplates
                    ?.filter(at => {
                        return at?.challenge_template_ids[challenge?.school_subject?.category]?.includes(challenge?.challenge_template_id)
                    })
                    ?.map((activityTemplate, index) => {
                        return <ActivityButton key={index}
                                               activityTemplate={activityTemplate}
                                               student={student}
                                               challenge={challenge}
                                               activities={activities?.filter(x => x?.activity_template_id === activityTemplate?.id)}
                        />
                    })}

            {/*</View>*/}
        </ScrollView>

    }
    // ** Component props
    const {
        challenge,
        activities,
        longPress = () => {
        },
        selected,
        index,
        student,
        realizationMode = false,
        press = () => {
        }
    } = props

    // console.log(activities)

    // ** count activities that are late (information on server side)
    const countLateActivities = activities?.filter(x => x?.is_late)?.length

    // ** Manage collapse
    const [expanded, setExpanded] = useState(false)

    // ** Boolean for Planning mode or XP mode
    const activityPlanningMode = challenge?.planning_mode

    // console.log(activityPlanningMode, props.activityTemplates)

    const finishedActivities = activities
        ?.filter(a => !!a?.finished_at)
        ?.sort((a, b) => moment(b?.finished_at).diff(a?.finished_at))

    const isFinished = !!challenge?.finished_at
    const isAbandoned = !!challenge?.abandoned_at
    const dimensions = useContext(DimensionsContext)

    return <View key={challenge.id}
                 style={{
                     marginBottom: 20,
                     backgroundColor: selected ? theme.colors.lightMore : theme.colors.white,
                     borderTopWidth: 1,
                     borderBottomWidth: 1,
                     borderColor: theme.colors.lightMore,
                     width: dimensions?.width,
                     ...boxedCardBorderStyle
                 }}>

        {/* ** Header with Challenge info, only expandable in XP mode */}
        {<TouchableHighlightM
            onPress={() => {
                press(index)
            }}
            onLongPress={() => {
                press(index)
            }}
            underlayColor={theme.colors.lighter}
        >
            <ChallengeHeaderNew challenge={challenge}
                                countLateActivities={countLateActivities}
                                expanded={expanded}/>
        </TouchableHighlightM>}

        {/*<Collapsible collapsed={!expanded}>*/}

        {(isFinished || isAbandoned || realizationMode) ?
            <View style={{
                //backgroundColor: theme.colors.whiteAlmost,
            }}>
                <View style={{
                    width: "100%",
                    backgroundColor: theme.colors.primaryLighter,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 20,
                    paddingBottom: 10
                }}>
                    {finishedActivities?.length > 0
                        ? finishedActivities?.map((a, index) => <RealizationsListItem key={index}
                                                                                      noBorder
                                                                                      activity={a}
                                                                                      student={student}
                        />)

                        : <View style={{width: dimensions?.width}}>
                            <TextM style={{textAlign: "center", paddingTop: 20, paddingBottom: 30}}
                                   color={theme.colors.grey}>Aucune activité</TextM>
                        </View>

                    }

                </View>
                {realizationMode && !isFinished && !isAbandoned && <>
                    <Divider/>
                    <View style={{backgroundColor: theme.colors.primaryLighter}}>
                        <TextM style={{textAlign: "center", paddingTop: 10, paddingBottom: 5}}
                               color={theme.colors.grey}>Activités disponibles:</TextM>
                        {activitiesListForXP()}
                    </View>
                </>}
            </View>
            : activityPlanningMode
                ? <ChallengePlanningScroll challenge={challenge} // ** Scrollable timeline, visible in Planning mode
                                           countLateActivities={countLateActivities}
                                           student={student}
                                           activities={activities}/>


                : activitiesListForXP()
        }
        {/*</Collapsible>*/}


    </View>

}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesListItem)