import {DimensionsContext} from "@common/utils/mobileUtils";

import {ModalM} from "@common/components/modal/ModalM";
import React, {useContext} from "react";
import AvatarImageCropper from "react-avatar-image-cropper/src";

export function ImagePickerCropModal({isOpen, handleShowModal, apply}) {

    const dimensions = useContext(DimensionsContext)

    return <ModalM visible={isOpen}
                   onDismiss={handleShowModal}
                   style={{
                       width: 300,
                       height: 300,
                       maxHeight: "80%"
                   }}>
        <AvatarImageCropper apply={(file) => {
            apply(file)
            handleShowModal()
        }}
                            textStyle={{
                                fontFamily: "Montserrat-Regular",
                            }}
                            text={"Choisir une image"}
                            rootStyle={{
                                width: 300,
                                height: 300,
                                borderRadius: 150
                            }}/>
    </ModalM>;
}