import React, {useContext} from 'react';
import {Platform, RefreshControl, ScrollView, StyleSheet, View} from "react-native";
import {StatusBar} from "expo-status-bar";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {
    findItemInListWithId,
    groupBy,
    isSubscribed,
    isSubscriptionPaused,
    isWebConfVisibleForStudent,
    nextInterviewForStudent,
    sortLiveSessionsCallback
} from "@data/utility/dataUtils";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {authService} from "@data/services/authService";
import AvatarM from "@common/components/avatar/AvatarM";
import BecomePremiumCard, {
    canSubscribeToEssential,
    canSubscribeToTrialEssential,
} from "../account/subscription/BecomePremiumCard";
import {hasRole} from "@data/utility/ability";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import ChampionBubble from "../champions/ChampionBubble";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {BadgeM} from "@common/components/badge/BadgeM";
import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import moment from "moment";
import {capitalize} from "@data/utility/Utils";
import {AbilityContext} from "@data/utility/Can";
import LiveRoomCard, {findClosestNextOccurrence} from "../account/onboarding/LiveRoomCard";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 10,
        alignSelf: Platform.OS !== 'web' ? null : "center"
        // paddingHorizontal: 20
    }
});

export const ListButtonM = props => {

    const {onPress, icon, title, description, subTitle, badgeCount, disabled, isLast = false, style} = props

    const dimensions = useContext(DimensionsContext)

    return <TouchableHighlightM onPress={onPress}
                                underlayColor={theme.colors.lightMore}
                                disabled={disabled}
                                style={{
                                    // backgroundColor: theme.colors.white,
                                    // borderRadius: 15,
                                    // borderTopWidth: 1,
                                    borderBottomWidth: isLast ? 0 : 1,
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    alignItems: "center",
                                    borderColor: theme.colors.lightMore,
                                    height: 84,
                                    flexDirection: "row",
                                    width: "100%",
                                    ...style
                                }}
    >
        <>
            <View>
                {icon}
                {badgeCount > 0 && <BadgeM count={badgeCount}
                                           style={{
                                               backgroundColor: theme.colors.error,
                                               color: theme.colors.white,
                                               position: "absolute",
                                               top: -5,
                                               right: -5
                                           }}/>}
            </View>
            <View style={{marginLeft: 15, flex: 1}}
            >
                <TextM fontWeight={"Bold"}
                       color={theme.colors.primary}
                       wrap
                       fontSize={18}>{title}</TextM>
                {subTitle && <TextM fontWeight={"SemiBold"}
                                    color={theme.colors.primary}
                                    wrap
                                    fontSize={16}>{subTitle}</TextM>}
                {description && <TextM fontWeight={"Medium"}
                                       color={theme.colors.light}
                                       wrap
                                       fontSize={14}>{description}</TextM>}
            </View>
            {!disabled && <FontAwesome5 name={"chevron-right"}
                                        solid
                                        size={24}
                                        color={theme.colors.primary}
                                        style={{
                                            // position: "absolute",
                                            // right: 15
                                        }}
            />}
        </>
    </TouchableHighlightM>
}


const MyCoachTabScreen = (props) => {
        const dimensions = useContext(DimensionsContext)

        const {navigation, route} = props;
        const ability = useContext(AbilityContext)

        const liveSessionsByLiveRoom = Object.values(groupBy(props.user?.live_sessions, "live_room_id") ?? {})?.sort((a, b) => {
            const firstA = a?.sort(sortLiveSessionsCallback)?.[0]
            const firstB = b?.sort(sortLiveSessionsCallback)?.[0]

            return sortLiveSessionsCallback(firstA, firstB)
        })
        // console.log("liveSessionsByLiveRoom", liveSessionsByLiveRoom)

        // function onButtonPress() {
        //     navigation.push('Challenges');
        // }
        const interview = nextInterviewForStudent(props.user)
        const interviewMoment = moment(interview?.date_time)
        const isWebConfVisible = isWebConfVisibleForStudent(props.user)
        const formattedInterviewMoment = capitalize(interviewMoment?.format((isWebConfVisible ? "[aujourd'hui]" : "dddd") + " [à] HH:mm"))
        const initialCondition = hasRole(props.user, 'student') && !isSubscribed(props.user) && ability.cannot("read", "holiday_course_views")

        return (
            <View style={{
                backgroundColor: theme.colors.whiteAlmost,
                height: dimensions?.height,
                alignItems: "center",
                flex: 1
            }}>
                <ScrollView refreshControl={<RefreshControl
                    refreshing={!!props.getUserRequest}
                    onRefresh={() => props.apiRequest(authService.getUser)}
                    containerStyle={[styles.container, {width: dimensions?.width}]}
                />}>
                    <View
                        style={{
                            width: dimensions?.width,
                            alignItems: "center"
                        }}
                    >
                        <View style={{
                            flexDirection: "column",
                            alignItems: 'center',
                            width: dimensions?.width,
                            marginTop: 10
                        }}>
                            <TitleM fontWeight="ExtraBold" style={{textAlign: "center", paddingHorizontal: 20}}>MON
                                MASTEUR</TitleM>

                            {/*{(ability?.can("read", "my_interviews") || props.user?.interviews?.length > 0) &&*/}
                            {/*    <Menu3Point menuItems={[{*/}
                            {/*        title: "Historique entretiens", onPress: () => {*/}
                            {/*            navigation.push('interviewHistory', {*/}
                            {/*                studentId: props.user?.id,*/}
                            {/*                user: "isStudent"*/}
                            {/*            })*/}
                            {/*        }*/}
                            {/*    }]}*/}
                            {/*                position={'bottom'}*/}
                            {/*                style={{*/}
                            {/*                    position: "absolute",*/}
                            {/*                    right: 10,*/}
                            {/*                    top: 3*/}
                            {/*                }}/>}*/}
                        </View>
                        {!props.user?.tutor && (
                            ability.can("read", "have_tutor")
                                ? <>
                                    <FontAwesome5 name="hourglass-half"
                                                  style={{marginTop: 30, marginBottom: 10}}
                                                  size={40}
                                                  color={theme.colors.grey}/>
                                    <TextM color={theme.colors.grey}
                                           style={{textAlign: "center", marginTop: 10, paddingHorizontal: 20}}
                                           fontWeight="Medium"
                                           wrap
                                    >
                                        Tu recevras une notification dès que nous t'aurons attribué un groupe de Live et un
                                        tuteur.
                                    </TextM>
                                </>
                                : !isSubscriptionPaused(props.user) && <>
                                <TextM color={theme.colors.grey}
                                       style={{textAlign: "center", marginTop: 10, paddingHorizontal: 20}}
                                       fontWeight="Medium"
                                       wrap
                                >
                                    Tu n'as pas d'abonnement actif.
                                </TextM>
                                <TextM color={theme.colors.grey}
                                       style={{textAlign: "center", marginTop: 10, paddingHorizontal: 20}}
                                       fontWeight="Medium"
                                       wrap
                                >
                                    Pour avoir un tuteur et participer aux Lives Masteur, il faut être abonné à l'une de nos
                                    offres.
                                </TextM>
                            </>)}

                        <BecomePremiumCard style={{
                            marginTop: 20,
                            marginBottom: 20
                        }}/>

                        {initialCondition
                            && (canSubscribeToTrialEssential(props.user) || canSubscribeToEssential(props.user))
                            && !isSubscriptionPaused(props.user) &&
                            <ChampionBubble name={"tina"}
                                            variant={"main"}
                                            championPosition="left"
                                            style={{
                                                marginTop: 10
                                            }}
                                            texts={["C'est grâce à l'aide de mon masteur que je suis devenue une championne !"]}
                                            onNextPress={() => {
                                            }}
                            />}

                        {!!props.user?.tutor && <View style={{width: dimensions?.width, alignItems: "center"}}>
                            <View style={{
                                marginTop: 30,
                                marginBottom: 30,
                                width: dimensions?.width,
                                alignItems: "center"
                            }}>
                                <AvatarM user={props.user?.tutor} style={{}} size={140}/>

                                <TextM style={{textAlign: "center", marginTop: 15}}
                                       fontWeight="Bold"
                                       fontSize={20}>{props.user?.tutor.display_name}</TextM>
                                {props.user?.tutor.profile?.current_position &&
                                    <TextM style={{textAlign: "center", marginTop: 0}}
                                           fontWeight="Regular"
                                           fontSize={16}>{props.user?.tutor.profile?.current_position}</TextM>}
                            </View>

                            <ListButtonM icon={<FontAwesome5 name={"comment"}
                                                             solid
                                                             size={30}
                                                             color={theme.colors.primary}
                            />}
                                         badgeCount={props?.user?.tutor_messenger_not_read_count}
                                         title={"Messages"}
                                         description={null}
                                         style={{
                                             backgroundColor: theme.colors.white,
                                             width: dimensions?.width - 40,
                                             borderRadius: theme.roundness,
                                             borderWidth: 1,
                                             borderColor: theme.colors.lightMore,
                                             marginTop: 10,
                                         }}
                                         onPress={() => {
                                             navigation.push("messenger", {
                                                 studentId: null

                                             })
                                         }}
                            />

                            {liveSessionsByLiveRoom?.map((liveSessionsForOneRoom, index) => {
                                const closestNextOccurrence = findClosestNextOccurrence(liveSessionsForOneRoom)
                                const closestNextOccurrenceDate = moment(closestNextOccurrence?.date)
                                // console.log("closestNextOccurrence?.live_session_id", closestNextOccurrence?.live_session_id)
                                const liveSession = findItemInListWithId(closestNextOccurrence?.live_session_id, liveSessionsForOneRoom)
                                const isToday = closestNextOccurrenceDate?.isSame(moment(), "day")
                                const isAlert = moment()?.isBetween(closestNextOccurrenceDate?.clone()?.subtract(15, "minutes"), closestNextOccurrenceDate?.clone()?.add(45, "minutes"))
                                const formattedLiveMoment = capitalize(closestNextOccurrenceDate.format((isToday ? "[aujourd'hui]" : "dddd") + " [à] HH:mm"))

                                // const isHolidayCourse = liveSession?.live_room?.type === "holiday_course"

                                // console.log(liveSession?.id)
                                return <LiveRoomCard key={index}
                                                     liveRoom={{
                                                         ...liveSession?.live_room,
                                                         live_sessions: liveSessionsForOneRoom
                                                     }}
                                                     style={{
                                                         backgroundColor: theme.colors.white,

                                }}
                                                     width={dimensions?.width - 40}
                                                     description={isToday
                                                         ? "Appuie ici pour accéder"
                                                         : "Le lien sera accessible le jour du LIVE"}
                                                     tutor={liveSession?.live_room?.tutor}
                                                     hideAvailablePlaces
                                                     showLiveRoomName
                                                     disabled={!isToday}
                                                     badgeCount={isAlert ? 1 : 0}
                                                     onPress={() => {
                                                         navigation.push("pre-call", {
                                                             liveSessionId: liveSession?.id
                                                         })
                                                     }}
                                />

                            })}
                        </View>}

                    </View>

                </ScrollView>
                {/*<TextM fontWeight="Medium">Bientôt disponible</TextM>*/}
                {/*<TextM wrap style={{marginTop: 20, textAlign: "center"}}>Tu retrouveras ici ton tuteur, tes rapports d'entretiens hebdo et des conseils pour améliorer ta méthode.</TextM>*/}
                <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

            </View>
        );
    }
;

const mapStateToProps = state => {
    return {
        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        getUserError: state.api.getUser?.error,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        maxPrioritizedSchoolSubjects: state.data.staticData?.max_prioritized_school_subjects,
        isLive: state.liveMode.isLiveModeEnabled,
        getLiveSessionRequest: state.api.getLiveSession?.request
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setIsLiveModeEnabled
    }

export default connect(mapStateToProps, mapDispatchToProps)(MyCoachTabScreen)