export const championSlugsToNames = {
    "zoe": "Zoé",
    "abou": "Abou",
    "sam": "Sam",
    "tina": "Tina",
}

export default {
    // ** Zoé
    zoe_intro: {
        variant: [
            "main",
            "main",
            "main",
            "happy"
        ],
        texts: [
            "Coucou, moi c’est Zoé, championne en organisation !",
            "Mes professeurs sont admiratifs de l'organisation de mes cours et de ma méthode de travail",
            "Je t'aiderai à te fixer des objectifs et à bien planifier ton travail personnel !",
            "Allez, c'est parti !"
        ]
    },
    zoe_challenge_launch_congrats_first: {
        variant: [
            "happy",
            "main",
            "main"
        ],
        texts: [
            "Bravo ! Tu as lancé ton premier défi !",
            "Pour atteindre ton objectif, tu devras gagner des points XP en réalisant les activités du défi",
            "Je compte sur toi pour relever le défi avant la date de fin que tu as fixée !"
        ]
    },
    zoe_challenge_launch_congrats_any: {
        variant: [
            "happy",
            "main"
        ],
        texts: [
            "Bravo ! Tu t'es lancé un nouveau défi !",
            "Je compte sur toi pour terminer ce défi avant la date de fin que tu as choisie !"
        ]
    },
    zoe_challenge_progress_congrats: {
        variant: [
            "main"
        ],
        texts: [
            "Bravo, tu as bien avancé ! Continue tes efforts !"
        ]
    },
    zoe_challenge_progress_congrats_finalized: {
        variant: [
            "happy"
        ],
        texts: [
            "Tu as terminé le défi dans les temps. Un grand BRAVO !"
        ]
    },
    zoe_challenge_progress_congrats_finalized_after_deadline: {
        variant: [
            "happy"
        ],
        texts: [
            "Tu as terminé le défi un peu en retard, mais l'essentiel est que tu es allé jusqu'au bout. Bravo !"
        ]
    },
    zoe_chapter_progress_congrats: {
        variant: [
            "main"
        ],
        texts: [
            "Bravo, tu as bien avancé ! Continue tes efforts !"
        ]
    },
    zoe_chapter_progress_congrats_todo_ended: {
        variant: [
            "happy"
        ],
        texts: [
            "C'est bien, tu fais des efforts sur ce chapitre. Continue comme ça !"
        ],
    },
    zoe_chapter_progress_congrats_finalized: {
        variant: [
            "happy"
        ],
        texts: [
            "Tu as terminé les activités que tu avais à faire sur ce chapitre. Un grand BRAVO !"
        ]
    },
    // ** Abou
    abou_intro: {
        variant: [
            "main",
            "main",
            "main",
            "happy"
        ],
        texts: [
            "Salut, moi c’est Abou, champion en concentration !",
            "Je lis 100 fois plus vite que la moyenne, et je connais 10 000 décimales du nombre PI",
            "Je te partagerai mes secrets pour optimiser ta concentration et ta mémoire !",
            "Commençons ensemble une activité !"
        ]
    },
    // ** Sam
    sam_intro: {
        variant: [
            "main",
            "main",
            "main",
            "happy"
        ],
        texts: [
            "C'est vrai que je ne me suis pas encore présenté, mais est-ce vraiment nécessaire ?",
            "Je suis Sam, champion en expression",
            "Sans forcer, à l'oral comme à l'écrit, je te fais valser, tu as compris ?!",
            "Je te montrerai comment manier la langue et la plume, pour faire de chaque devoir une oeuvre d'art !"
        ]
    },
    sam_activity_finalized_congrats_pending_scoring: {
        variant: [
            "main"
        ],
        texts: [
            "Ton activité est en cours d’évaluation. Tu vas bientôt recevoir une notification, avec ton score et des conseils !"
        ]
    },
    sam_activity_finalized_congrats_photo_skipped_subscribed: {
        variant: [
            "main"
        ],
        texts: [
            "La prochaine fois, utilise l'appli Masteur sur smartphone ou tablette pour prendre en photo ton travail. Tu recevras un bonus score et des conseils pour t'améliorer."
        ]
    },
    sam_activity_finalized_congrats_photo_skipped_not_subscribed: {
        variant: [
            "main"
        ],
        texts: [
            "Tu n'as pas d'abonnement actif. Il faut être abonné pour recevoir un score et des conseils sur la qualité de ton travail."
        ]
    },
    sam_activity_finalized_congrats_no_scoring: {
        variant: [
            "main"
        ],
        texts: [
            "Tu n'as pas d'abonnement actif. Il faut être abonné pour recevoir un score et des conseils sur la qualité de ton travail."
        ]
    },

    // ** Tina
    tina_intro: {
        variant: [
            "main",
            "main",
            "happy"
        ],
        texts: [
            "Hey, moi c’est Tina, championne en coopération !",
            "On va former une belle équipe tous les deux !",
            "On y va ?"
        ]
    },
    // ** Live
    zoe_live_launch_intro: {
        variant: [
            "happy",
            "main"
        ],
        texts: [
            "C'est parti pour ta session LIVE !",
            "Ton masteur va t’appeler dès que possible. En attendant, on commence à travailler ?"
        ]
    },
    zoe_live_launch_intro_too_early: {
        variant: [
            "happy",
            "main"
        ],
        texts: [
            "Tu es en avance dis-donc ! Ta session LIVE n'a pas encore commencé.",
            "Tu peux travailler en autonomie en attendant, mais n'oublie pas de bien être connecté.e à l'heure !",
        ]
    },
    // ** Live
    zoe_live_launch_holiday_course_intro: {
        variant: [
            "happy",
            "main"
        ],
        texts: [
            "C'est parti pour ta session LIVE !",
            "Prépare tes affaires scolaires, ton masteur va t’appeler dès que possible."
        ]
    },
    // ** Quizz
    tina_quizz_intro: {
        variant: [
            "main",
            "main",
            "happy"
        ],
        texts: [
            "Bonjour, moi c'est Tina !",
            "On t'a préparé 8 petites questions concernant les élèves du secondaire, et leur façon d'étudier",
            "En répondant à ce quizz, tu financeras la plantation d'un arbre !",
            "Prêt.e ? C'est parti !"
        ]
    },
    sam_quizz_initialized_congrats: {
        variant: [
            "happy",
            "main",
            "unhappy"
        ],
        texts: [
            "Excellent ! Ta maîtrise en la matière est impressionnante. On dirait que tu es prêt(e) à affronter n'importe quelle question. J'aime quand on m'impressionne, alors continue comme ça !",
            "Bravo, mais tu peux mieux faire ! Rappelle-toi, l'apprentissage est un voyage et chaque pas compte. Continue à explorer et à apprendre, et tu atteindras de nouveaux sommets !",
            "Ce n'est pas grave, l'important est de ne pas abandonner. Comme on dit, chaque échec est une occasion d'apprendre et de grandir. Quoi qu'il en soit, je te félicite pour ton effort !"
        ]
    },
    tina_quizz_confirmed_congrats: {
        variant: [
            "happy"
        ],
        texts: [
            "C'est génial... mais on ne peut pas s'arrêter là ! Retrouve-nous sur ton réseau préféré en cliquant sur l'un des boutons ci-dessous !"
        ]
    },
    abou_application_interview_intro: {
        variant: [
            "main",
            "main",
            "main",
            "main",
            "main",
            "happy",
        ],
        texts: [
            "Salut, moi c’est Abou, champion en concentration !",
            "Je lis 100 fois plus vite que la moyenne, et je connais 10 000 décimales du nombre PI",
            "Pour intégrer Masteur, tu vas devoir suivre 4 petites étapes de candidature, chacune prenant 5 minutes maximum",
            "La première étape est un formulaire simple pour mieux connaître ton parcours scolaire",
            "Ensuite, mes amis te poseront quelques questions pour évaluer ta motivation, ton style de tutorat, et tes compétences 'techniques'",
            "Alors, on commence ?"
        ]
    },
    tina_motivation_interview_intro: {
        variant: [
            "main",
            "main",
            "main",
            "main",
            "main",
            "happy",        ],
        texts: [
            "Je suis Tina, et je vais te poser une question sur ta motivation",
            "Ta réponse sera filmée, tu auras 30 secondes de réflexion, et 2 min pour répondre",
            "Attention ! Tu n'auras le droit qu'à 1 seul essai !",
            "La confidentialité de tes données est essentielle pour nous : toutes les vidéos seront supprimées après l'instruction de ton dossier !",
            "Maintenant, soigne un peu ton look et souris, tu vas être filmé.e !",
            "Tu es prêt.e ? C'est parti !"
        ]
    },
    zoe_educational_interview_intro: {
        variant: [
            "main",
            "main",
            "main",
            "main",
            "main",
            "happy",
        ],
        texts: [
            "Je me prénomme Zoé, et je vais te poser une question pour mieux appréhender ton style de tutorat.",
            "Pas d'inquiétude, il n'y a pas de bonne ou de mauvaise réponse : l'idée est de mieux te connaître !",
            "Les règles sont identiques. Tu disposeras de 30 secondes de réflexion, et de 2 min pour répondre.",
            "Je te rappelle à toute fin utile que tu ne disposes que d’1 seul essai par question !",
            "Conformément aux prérogatives en vigueur, aucune vidéo ne sera conservée.",
            "Quand tu es prêt.e, tu peux cliquer sur continuer !"
        ]
    },
    sam_technical_interview_intro: {
        variant: [
            "main",
            "main",
            "main",
            "main",
            "main",
            "happy",
        ],
        texts: [
            "Hello, j’imagine que t’as déjà entendu parler de moi ? Sam, la star de Masteur.",
            "Je suis le champion de l’expression et de l’orthographe.",
            "Je vais te présenter un travail réalisé par un de mes élèves.",
            "J’aimerais que tu me dises ce que tu en penses par écrit.",
            "Tu auras 5 minutes chrono. N’hésite pas à relever tous les points d’amélioration !",
            "Prêt.e ?"
        ]
    }
}
