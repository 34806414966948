import moment from "moment"
import 'moment/locale/fr'
import {congratsList, stepErrorList, stepFinishedList} from "../constants/activitiesConstants"
import {daysOptions} from "@data/constants/formConstants";

// compare app versions
export function versionCompare(v1, v2, options) {
    var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1?.split('.'),
        v2parts = v2?.split('.');

    function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
    }

    if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length == i) {
            return 1;
        }

        if (v1parts[i] == v2parts[i]) {
            continue;
        } else if (v1parts[i] > v2parts[i]) {
            return 1;
        } else {
            return -1;
        }
    }

    if (v1parts.length != v2parts.length) {
        return -1;
    }

    return 0;
}

// ** Random int in interval
export function randomIntFromInterval(min, max) { // min and max included
    return Math.floor((Math.random() * (max - min + 1)) + min)
}

// ** Gets unique items for a string array
export const uniq = (a) => {
    if (!a) return null

    return a.sort().filter(function (item, pos, ary) {
        return !pos || item !== ary[pos - 1]
    })
}

// ** Gets unique items for an array on for key
export const uniqWithKey = (a, key) => {
    if (!a) return null

    return a.sort().filter(function (item, pos, ary) {
        return !pos || item[key] !== ary[pos - 1][key]
    })
}

// ** Check what type of object it is
export const whatIsIt = (object) => {
    const stringConstructor = "test".constructor
    const arrayConstructor = [].constructor
    const objectConstructor = ({}).constructor

    if (object === null) {
        return "null"
    }
    if (object === undefined) {
        return "undefined"
    }
    if (object.constructor === stringConstructor) {
        return "String"
    }
    if (object.constructor === arrayConstructor) {
        return "Array"
    }
    if (object.constructor === objectConstructor) {
        return "Object"
    }
    return "Other"
}

//find the key of a given value
export function findKeyByValue(obj, value) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === value) {
            return key;
        }
    }
    return null; // Key not found for the given value
}

// ** Updates data keys smartly (without removing keys that are not in newObject)
export const updateDataKeys = (newObject, previousObject) => {

    const returnedObject = {...previousObject}

    Object.keys(newObject).forEach(key => {

        // console.log(`entered loop with key : ${key}`)

        if (whatIsIt(newObject[key]) === "Array") {
            // console.log(`key : ${key} is array`)

            returnedObject[key] = []

            newObject[key].forEach(newItem => {
                let returnedItem

                if (whatIsIt(newObject[key]) !== "Array" && whatIsIt(newItem) === "Object") {
                    const prevItem = previousObject[key]?.find(x => x.id === newItem.id)
                    if (prevItem) returnedItem = updateDataKeys(newItem, prevItem)
                    else returnedItem = newItem
                } else {
                    returnedItem = newItem
                }

                returnedObject[key].push(returnedItem)
            })

        } else if (whatIsIt(newObject[key]) === "Object") {
            // console.log(`key : ${key} is object`)
            returnedObject[key] = updateDataKeys(newObject[key], previousObject[key])
        } else {
            // console.log(`key : ${key} is other`)
            returnedObject[key] = newObject[key]
        }
    })

    return returnedObject
}

// ** Average of array
export const calculateAverage = (a) => {
    if (a === null) return 0
    if (a?.length === 0) return 0

    let sum = 0
    for (let i = 0; i < a?.length; i++) {
        sum += parseInt(a[i], 10) //don't forget to add the base
    }

    return Math.round((sum / a?.length) * 10) / 10
}

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
    const today = new Date()
    return (
        /* eslint-disable operator-linebreak */
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
        /* eslint-enable */
    )
}

// ** String truncation
export const truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
        return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return `${str.slice(0, num)}...`
}

// ** Capitalize
export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s?.charAt(0).toUpperCase() + s?.slice(1)
}

// ** Return date options in period for Challenge form
export const dateOptionsInPeriod = (min, max) => {
    const currentMoment = moment().add(min, 'days')

    const dateArray = []

    for (let i = min; i < max; i++) {
        currentMoment.add(1, 'day')
        dateArray.push({
            value: currentMoment.format("YYYY-MM-DD"),
            label: "Dans " + (i + 1) + " jour" + (i > 0 ? "s" : ""),
            description: capitalize(currentMoment.format("ddd DD MMMM"))
        })

    }

    return dateArray
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String|Moment} value date to format
 */
export const formatDate = (value) => {
    if (!value) return value

    if (typeof value === "string") return moment(value).format("DD MMMM YYYY")

    return value.format("DD MMMM YYYY")
    // return new Intl.DateTimeFormat('fr-FR', formatting).format(new Date(value))
}
// format minutes to hours and minutes
export const formatMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
        return `${remainingMinutes} min`;
    } else if (remainingMinutes === 0) {
        return `${hours}h`;
    } else {
        return `${hours}h${padToTwo(remainingMinutes)}`;
    }
};
// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value)
    let formatting = {month: 'short', day: 'numeric'}

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = {hour: 'numeric', minute: 'numeric'}
    }

    return new Intl.DateTimeFormat('fr-FR', formatting).format(new Date(value))
}

// ** Format relativeDate
export const formatRelativeDateTime = (value, dayMode = false) => {
    moment.locale('fr')
    // const now = dayMode ? moment().startOf('day') : moment()
    const now = moment()
    // const date = dayMode ? moment(value).startOf('day') : moment(value)
    const date = moment(value)
    const diff = dayMode ? now.diff(date, 'days') : now.diff(date, 'hours')

    if (dayMode) {
        return date.fromNow()
        // else if (diff === -1) return "Demain"
        // else if (diff === 1) return "Hier"
        // else return "Aujourd'hui"
    } else {
        if (diff < 22) return date.fromNow()
        else return date.calendar()
    }


}

// ** Format as calendar day
export const formatCalendarMoment = (value, withTime = false) => {

    const withTimeAddendum = (withTime ? ' [à] HH:mm' : '')

    moment.locale('fr')

    return capitalize(moment(value).calendar(null, {
        sameDay: 'HH:mm',
        nextDay: '[Demain]',
        nextWeek: 'dddd [prochain]',
        lastDay: '[Hier]' + withTimeAddendum,
        lastWeek: 'dddd [dernier]' + withTimeAddendum,
        sameElse: 'dddd DD/MM' + withTimeAddendum
    }));
}

// ** Format full date time
export const formatFullDateTime = (value) => {
    moment.locale('fr')
    const date = moment(value)
    const dayFormat = date.isSame(moment(), 'day') ? "[aujourd'hui à]" : "dddd D MMMM [à]"

    return capitalize(date.format(`${dayFormat} HH:mm`))
}

// ** Format full date time
export const formatTime = (value) => {
    moment.locale('fr')
    const date = moment(value)

    return date.format('HH:mm')
}

// ** get week day from date
export const weekDayFromDate = (value) => {
    const date = moment(value)

    return date.locale('en').format('dddd')
}

// ** check if date is passed
export const isPassedDate = (value) => {
    const date = moment(value)

    return date.diff(moment()) <= 0
}


// ** check if we are in the weekend
export const isItWeekend = () => {
    const todayWeekDay = moment().format("dddd")
    return todayWeekDay === 'samedi' || todayWeekDay === 'dimanche'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: '#ffc80a1a', // for option hover bg-color
        primary: '#ffc80a', // for selected option bg-color
        neutral10: '#ffc80a', // for tags bg-color
        neutral20: '#ededed', // for input border-color
        neutral30: '#ededed' // for input hover border-color
    }
})

// ** convert dataURL to file
export function dataURLtoFile(dataurl, filename) {

    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, {type: mime})
}

// ** Return random congrats message
export const congratsMessage = (task) => {
    const num = task?.id % congratsList.length
    return congratsList[num] ?? "Terminé"
}

export const stepFinishedMessage = (index) => {
    const num = index % stepFinishedList.length
    return stepFinishedList[num] ?? "Bravo !"
}

export const stepErrorMessage = (index) => {
    const num = index % stepErrorList.length
    return stepErrorList[num] ?? "Raté !"
}

// ** Pad to two digits for timer
export const padToTwo = (number) => (number <= 9 && number?.toString()?.length < 2 ? `0${number}` : number);
export const displayTimeSecondsNoPad = (seconds) => {
    let minutes = 0;
    if (seconds < 0) {
        seconds = 0;
    }

    if (seconds < 60) {
        // return `00:${padToTwo(seconds)}:${(remainDeciseconds)}`;
        return `0:${seconds}`;
    }
    let remainSeconds = seconds % 60;
    minutes = (seconds - remainSeconds) / 60;
    // return `${padToTwo(minutes)}:${padToTwo(remainSeconds)}:${(remainDeciseconds)}`;
    return `${minutes}:${remainSeconds}`;
};

export const hoursMinutesAndSecondsFromSeconds = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secondsLeft = duration.seconds();
    return [hours, minutes, secondsLeft]
}

// ** Display time based on seconds for timer
export const displayTimeSeconds = (seconds) => {
    let minutes = 0;
    if (seconds < 0) {
        seconds = 0;
    }

    if (seconds < 60) {
        // return `00:${padToTwo(seconds)}:${(remainDeciseconds)}`;
        return `00:${padToTwo(seconds)}`;
    }
    let remainSeconds = seconds % 60;
    minutes = (seconds - remainSeconds) / 60;
    // return `${padToTwo(minutes)}:${padToTwo(remainSeconds)}:${(remainDeciseconds)}`;
    return `${padToTwo(minutes)}:${padToTwo(remainSeconds)}`;
};

export const toTimeString = (totalSeconds) => {
    const totalMs = totalSeconds * 1000;
    const result = new Date(totalMs).toISOString().slice(11, 19);

    return result;
}

// ** Make copy of time from object
export const copyObjectWithCurrentTimeAttribute = (attribute, obj) => {
    const copy = Object.assign({}, obj)
    copy[attribute] = moment().format("YYYY-MM-DD HH:mm:ss")
    // console.log(copy)
    return copy
}

// ** Add timestamp to useForm field
export const addTimestampToField = (field, step, action, stepData = null) => {

    // console.log("step data passed ", stepData)

    field?.onChange([...field?.value?.filter(v => v?.action !== action || v?.step !== step), {
        action: action,
        step: step,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        step_data: stepData
    }])

    // console.log("timestamp ", field.value)

}

// ** Add form data to useForm field
export const addFormDataToField = (field, step, formData, action = "step_form_sent") => {
    // console.log(field, step, formData, action)

    field?.onChange([...field?.value?.filter(v => v?.action !== action || v?.step !== step), {
        action: action,
        step: step,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        step_data: formData
    }, {
        action: "step_finished",
        step: step,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss")
        // step_data: null
    }
    ])

    // console.log(field.value)
}

// ** Add work attachment to useForm field
export const addWorkAttachmentToField = (field, step, media = [], userId, type = "jpg", action = "step_media_sent") => {

    // clean existing images
    let newFieldValue = [...field?.value]?.filter(x => x?.step !== step || x?.action !== action)

    media.forEach(x => {

        newFieldValue = [...newFieldValue, {
            action: action,
            step: step,
            work_attachment: {
                name: userId + "_wa_" + moment().format() + "." + type,
                type: type === "jpg" ? "image/jpg" : "audio/m4a",
                // uri: Platform.OS === 'ios' ? x.uri.replace('file://', '') : x.uri,
                uri: x?.uri,
                width: x?.width,
                height: x?.height
            },
            created_at: moment().format("YYYY-MM-DD HH:mm:ss")
        }]

        // console.log("media ", field.value)

    })

    field?.onChange(newFieldValue)

}

// ** Add media to useForm field
export const addMediaToField = (field, media = [], overwrite = false, prefix = null, type = "png") => {

    // let newFieldValue = overwrite ? {file_keys: []} : {...field?.value}
    //
    // media.forEach(x => {
    //
    //     const newKey = field?.name + "_" + newFieldValue?.file_keys?.length
    //
    //     newFieldValue = {
    //         ...newFieldValue,
    //         file_keys: [...newFieldValue?.file_keys, newKey]
    //     }
    //     newFieldValue[newKey] = {
    //         name: (prefix ?? newKey) + "_" + moment().format() + "." + type,
    //         type: type === "jpg" ? "image/jpg" : "audio/m4a",
    //         height: x?.height,
    //         width: x?.width,
    //         uri: x?.uri
    //     }
    // })

    field?.onChange(media?.map(file => {
        return {
            type: type === "png" ? "image/png" : "audio/m4a",
            height: file?.height,
            width: file?.width,
            uri: file?.uri
        }
    }))

}

//display time mm:ss
export function secondsToHMS(seconds) {
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const mm = minutes < 10 ? `0${minutes}` : minutes;
    const ss = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return `${mm} min ${ss} s`;
}

// ** Display time nicely
export function niceTimeFromSeconds(duration) {

    if (duration < 3600)
        return [
            Math.floor(duration / 60),
            plr("minute", Math.floor(duration / 60))
        ]
    else {
        const hours = Math.floor(duration / 3600)
        const minutes = Math.floor((duration - hours * 3600) / 60) > 0
            ? Math.floor((duration - hours * 3600) / 60)
            : ""
        return [
            duration ? `${hours}h${minutes}` : "0",
            "temps"
        ]
    }
}

// ** Get last item of an array
export function lastItem(mArray) {
    return mArray?.[mArray?.length - 1]
}

export function createArray(start, end, step = 1) {
    return Array.from({length: end - start + 1},
        (_, index) => start + (index * step));
}

// ** Get path to array
function leaf(obj, path) {
    path = path.split('.');
    let res = obj;
    for (let i = 0; i < path.length; i++) res = res?.[path[i]];
    return res;
}

// ** Check arrays items equality at path
export const areArraysEqualAtPath = (arrayA, arrayB, pathA, pathB) => {
    arrayA?.forEach((itemA, index) => {
        if (leaf(itemA, pathA) !== leaf(arrayB?.[index], pathB))
            return false
    })
    return true
}

// ** Check arrays items equality
export const isSameArray = (arrayA, arrayB) => {
    return arrayA?.sort().join(',') === arrayB?.sort().join(',')
}

export const allOptionsFromSectionOptions = (options) => options?.map(option => option?.data ?? option)?.flat()?.map(d => d?.value)

// ** Pluralize string based on count
export const plr = (textString, cnt, character = "s") => cnt > 1 ? textString + character : textString

export const readableDayTime = (day_time, durationInMinutes = 60) => {
    const dayMatch = day_time?.split(" ")?.[0];
    const readableDay = daysOptions.find(d => d.value === dayMatch)?.label?.toLowerCase()

    const additionalHours = Math.floor(durationInMinutes / 60)
    const additionalMinutes = durationInMinutes % 60

    const timeMatch = day_time?.split(" ")?.[1];
    const timeMatchHour = timeMatch?.substring(0, 2)
    const timeMatchMinutes = timeMatch?.substring(3, 5)
    const readableMinutes = timeMatchMinutes === "00" ? "" : timeMatchMinutes

    const readableEndHours = parseInt(timeMatchHour) + additionalHours
    const readableEndMinutes = parseInt(timeMatchMinutes) + additionalMinutes === 0 ? "" : parseInt(timeMatchMinutes) + additionalMinutes

    const readableTime = parseInt(timeMatchHour) + "h" + readableMinutes + "-" + readableEndHours + "h" + readableEndMinutes

    return "Tous les " + readableDay + "s " + readableTime
}

export function string_to_slug (str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export function isArraySubset(mainArray, subArray) {
    return subArray.every((element) => mainArray.includes(element));
}

