import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ChapterItemComponent from "@common/chapters/ChapterItemComponent";

const ChapterProgressCongrats = props => {

    const {activity} = props
    //console.log(activity)
    const dimensions = useContext(DimensionsContext)
    //console.log(activity)
    const chapter = {
        student_chapters: [activity?.student_chapter],
        ...activity?.student_chapter,
        ...activity?.student_chapter?.chapter,
        new_xp: activity?.xp_gained,
        school_subject_id: activity?.school_subject_id,
    }
    return <View style={{
        alignItems: "center",
        width: dimensions?.width,
        alignSelf: "center",
        paddingTop: 10,
        paddingHorizontal: 15,
    }}>
        <TextM fontSize={34}
               fontWeight="ExtraBold"
               color={theme.colors.primary}
               style={{textAlign: "center", alignSelf: "center", marginTop: 20, marginBottom: 40}}
               wrap
        >AVANCEMENT DU CHAPITRE</TextM>

        <ChapterItemComponent chapter={chapter} animated style={{marginBottom: 20}} />
    </View>


}
const mapStateToProps = state => {
    return {
        successes: state.data.staticData?.successes,

        markAchievementAsReadRequest: state.api.markAsRead?.request,
        markAchievementAsReadSuccess: state.api.markAsRead?.success,
        markAchievementAsReadError: state.api.markAsRead?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChapterProgressCongrats)