import React, {useCallback, useEffect, useImperativeHandle, useRef} from "react";
import {Audio} from "expo-av";
import {useSelector} from "react-redux";
import {Platform} from "react-native";

export const PlaySound = React.forwardRef((props, ref) => {
        // ** Component props
        const {
            // , repeat = null
        } = props


        useImperativeHandle(ref, () => ({
            play: playSound
        }));

        const isLiveModeEnabled = useSelector(state => state.liveMode?.isLiveModeEnabled)
        const liveSessionId = useSelector(state => state.liveMode?.liveSessionId)
        const liveSessions = useSelector(state => state.data.currentUser?.object?.live_sessions)
        const liveSession = liveSessions?.find((session) => session.id === liveSessionId)
        const isV2 = liveSession?.live_room?.version === 'v2'

        const cannotPlaySound = isLiveModeEnabled && isV2 && (Platform.OS === "android")
        const cannotPlaySoundRef = useRef(cannotPlaySound)

        useEffect(() => {
            cannotPlaySoundRef.current = cannotPlaySound
        }, [cannotPlaySound])

        const playSound = async (soundFile, delay = 0, isLooping = false) => {

            if (cannotPlaySoundRef.current) return

            try {

                await Audio.setAudioModeAsync({
                    // allowsRecordingIOS: true,
                    // interruptionModeAndroid: InterruptionModeAndroid.DuckOthers,
                    // interruptionModeIOS: InterruptionModeIOS.MixWithOthers,
                    // playThroughEarpieceAndroid: true,
                    playsInSilentModeIOS: true,
                    // shouldDuckAndroid: true,
                    staysActiveInBackground: true
                })

                const sound = new Audio.Sound()

                await sound.loadAsync(soundFile);

                if (isLooping) {
                    await sound.setIsLoopingAsync(isLooping)
                }

                setTimeout(async () => {
                    await sound.playAsync()
                        .then(async (playbackStatus) => {
                            if (!isLooping) {
                                setTimeout(() => {
                                    sound.unloadAsync()
                                }, 6000)
                            }
                        })
                }, delay)

                return sound

            } catch (e) {
                console.log("PLAY SOUND ERROR", e?.toString())
                return null
            }
        }

    }
)