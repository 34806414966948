import React from "react"
import {rolesAbilities} from "@data/utility/initialAbility";

export const hasRole = (user, role) => {
    return user?.roles?.includes(role)
}

export const abilitiesForRoles = (roles) => {
    let abilities = []

    if (roles) {
        roles.forEach(item => {
            abilities = abilities.concat(rolesAbilities[item])
        })
    }
    abilities = abilities.concat(rolesAbilities["any"])
// console.log(`abilities : ${JSON.stringify(abilities)}`)
    return abilities
}

export const offersNames = {
    "basic": "Basic",
    "plus": "Plus",
    "premium": "Premium",
    "initial": "Initiale",
    "essential": "Essentielle",
    "integral": "Intégrale",
    "holidaycourse": "Stage"
}


export const getPrivilegedRole = (user) => {

    const products = ["plus", "premium", 'initial', "essential", "integral"]

    for(let i = 0; i< products?.length; i++){
        if (hasRole(user, products?.[i]+"-student")) {
            return products?.[i]
        }
    }

    return "basic"
}


export const isOnTrial = (user) => {
    return (user?.subscription_state?.status === "on_generic_trial") || (user?.subscription_state?.status === "on_trial")
}

// export default new Ability(existingAbility || initialAbility)
// export default new Ability(abilitiesForRoles(currentRoles) || initialAbility)
