import React, {useContext, useEffect, useRef, useState} from "react";
import {useController} from "react-hook-form";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import TextInputM from "@common/components/input/TextInputM";
import {Dimensions, Keyboard, ScrollView, TouchableOpacity, View} from "react-native";
import {MenuM} from "@common/components/menu/MenuM";
import {renderers} from "react-native-popup-menu";
import {ActivityIndicator, Button, Dialog, IconButton, Portal, SegmentedButtons} from "react-native-paper";
import {validation} from "@data/utility/validation";
import {LinkPreview} from "@flyerhq/react-native-link-preview";
import {addMediaToField} from "@data/utility/Utils";
import {pickImage} from "@common/camera/CameraImagesM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {clearLastPhoto, clearPhotos} from "@data/redux/actions/camera";
import {connect} from "react-redux";
import {formIds} from "@data/constants/formConstants";
import FilledButtonM from "@common/components/button/FilledButtonM";
import MaterialModal from "../activity/execute/MaterialDisplayModal";
import {
    activitySlugsMaterialTitleExample,
    materialPlaceholder,
    undefinedMaterialSlug
} from "../activity/todo/ActivityPicker";
import {useCamera} from "@common/hooks/useCamera";
import {API_URL} from "@data/constants/apiConstants";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {hasRole} from "@data/utility/ability";
import IconButtonM from "@common/components/button/IconButtonM";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import ServerSearch from "../../admin/ServerSearch";
import {activitiesService} from "@data/services/activitiesService";
import MaterialItemComponent from "./MaterialItemComponent";
import {MaterialImage} from "./MaterialImage";
import {ActivityLevelBadge, materialLevelsColors, materialLevelsIcons} from "@common/chapters/ActivityLevelBadge";

const MaterialPicker = (props) => {
    const {
        control,
        goToNextStep,
        register,
        errors,
        formValues,
        setFormValues,
        field,
        trigger,
        manageTodo = false
    } = props;

    const chapterId = control._formValues?.chapter?.id;

    const [visible, setVisible] = React.useState(false);
    const [images, setImages] = useState(
        control._formValues?.material_attachments ?? []
    );
    const [pickingImage, setPickingImage] = useState(false);

    const activityTemplate = props.activityTemplates?.find(
        (activityTemplate) =>
            activityTemplate?.id === formValues?.activity_template_id
    );

    const [urlParams, setUrlParams] = useState({
        chapter_id: chapterId,
        activity_type: activityTemplate?.type
    });

    const uMaterialSlug = undefinedMaterialSlug(activityTemplate?.slug);

    const [launchCamera] = useCamera(formIds.NEW_MATERIAL, true, false);

    const [materialAction, setMaterialAction] = useState(hasRole(props.user, "student") ? "create" : "search");

    const [isMaterialOpen, setMaterialOpen] = useState(false);

    const handleShowMaterialModal = () => setMaterialOpen(!isMaterialOpen);

    // console.log(control._formValues)

    const showDialog = () => {
        setVisible(true);
        setTimeout(() => {
            materialUrlRef.current?.focus();
        }, 100);
    };
    const hideDialog = () => setVisible(false);

    const onSubmitEditing = () => {
        trigger("material_url").then((res) => {
            if (res) {
                Keyboard.dismiss();
                hideDialog();
            }
        });
    };

    const onCancelEditing = () => {
        materialUrlField.onChange("");
        Keyboard.dismiss();
        hideDialog();
    };

    const materialTextRef = useRef();
    const materialChapterRef = useRef();
    const materialUrlRef = useRef();
    const flatListRef = useRef();

    const {field: materialUrlField, fieldState: materialUrlFieldState} =
        useController({
            control,
            name: "material_url"
        });
    const {field: materialAttachmentsField} = useController({
        control,
        name: "material_attachments"
    });
    const {field: materialTitleField} = useController({
        control,
        name: "material_title"
    });
    const {field: materialTextField} = useController({
        control,
        name: "material_text"
    });
    const {field: materialLevelField} = useController({
        control,
        name: "material_level"
    });
    const isUrlInvalid =
        !!materialUrlFieldState.error?.message ||
        materialUrlField.value === "" ||
        !materialUrlField.value;
    const dimensions = useContext(DimensionsContext);

    // console.log("activityTemplate?.slug", activityTemplate?.slug)

    const levelButtonsDisabled =
        hasRole(props.user, "student") ||
        (activityTemplate?.slug !== "go_exo" &&
            activityTemplate?.slug !== "go_homework_oral");

    const levelButtonsStyle = {
        fontSize: dimensions.width < 800 ? 9 : 10
    };

    useEffect(() => {
        if (
            activityTemplate?.slug !== "go_exo" &&
            activityTemplate?.slug !== "go_homework_oral"
        ) {
            materialLevelField.onChange(1);
            setFormValues({
                ...control._formValues,
                material_level: 1
            });
        }
    }, []);

    useEffect(() => {
        addMediaToField(materialAttachmentsField, images);
        setFormValues({
            ...control._formValues,
            [materialAttachmentsField?.name]: images?.map((file) => {
                return {
                    type: "image/png",
                    height: file?.height,
                    width: file?.width,
                    uri: file?.uri
                };
            })
        });
    }, [images]);

    useEffect(() => {
        if (
            props.lastPhotoFormId === formIds.NEW_MATERIAL &&
            !!props.lastPhoto &&
            !images?.map((x) => x?.uri)?.includes(props.lastPhoto?.uri)
        ) {
            const imagesCopy = images?.map((x) => x) ?? [];
            imagesCopy?.push(props.lastPhoto);
            setImages(imagesCopy);
            props.clearLastPhoto();
        } else if (
            props.lastPhotoFormId === formIds.NEW_MATERIAL &&
            props.lastPhotos?.length > 0
        ) {
            const imagesCopy = images?.map((x) => x) ?? [];
            imagesCopy?.push(...props.lastPhotos);
            setImages(imagesCopy);
            props.clearPhotos();
        }
    }, [props.lastPhoto, props.lastPhotos]);

    useEffect(() => {
        if (materialAction === "create") {
            field.onChange(null);
        }
    }, [materialAction]);

    const renderButton = (onPress = null) =>
        pickingImage && images?.length === 0 ? (
            <ActivityIndicator
                small
                style={{marginVertical: 30}}
                color={theme.colors.primary}
            />
        ) : (
            // : <FilledButtonM icon={"photo-video"}
            //                  onPress={onPress}
            //                  disabled={!onPress}
            //                  label={`Ajouter ${uMaterialSlug}`}
            //                  mode={"outlined"}
            //                  style={{
            //                      // flex: 1,
            //                      // marginTop: 20,
            //                      // marginHorizontal: 20,
            //                      // borderRadius: theme.roundness,
            //                      //height: height
            //                      alignSelf: "center",
            //                      width: IS_WEB || dimensions?.width > 800 ? dimensions?.width * 3 / 5 : "100%",
            //                      marginBottom: 10
            //                      // borderWidth: 1,
            //                      // borderColor: theme.colors.light,
            //                      // justifyContent: "center",
            //                      // alignItems: "center"
            //                  }}
            //
            // />

            <TouchableOpacity
                disabled={!onPress}
                onPress={onPress}
                style={{
                    // flex: 1,
                    // marginTop: 20,
                    marginHorizontal: 20,
                    borderRadius: theme.roundness,
                    //height: height
                    alignSelf: "center",
                    width:
                        IS_WEB || dimensions?.width > 800
                            ? (dimensions?.width * 3) / 5
                            : "100%",
                    marginBottom: 5,
                    borderWidth: 1,
                    borderColor: theme.colors.light,
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <View
                    style={{
                        // flex: 1,
                        flexDirection: "row",
                        marginHorizontal: 20,
                        marginVertical: 15,
                        // width: 120,
                        //height: height,
                        justifyContent: "center",
                        alignItems: "center"
                        // alignSelf: "center"
                    }}
                >
                    <FontAwesome5
                        name="photo-video"
                        size={16}
                        color={theme.colors.primary}
                    />
                    <TextM
                        wrap
                        fontWeight="ExtraBold"
                        color={theme.colors.primary}
                        fontSize={14}
                        style={{
                            // textAlign: "center",
                            marginLeft: 12
                        }}
                    >
                        {`Ajouter ${uMaterialSlug}`.toUpperCase()}
                    </TextM>
                </View>
            </TouchableOpacity>
        );

    const renderLinkPreview = (disabled = false) => {
        const url = IS_WEB
            ? API_URL + "/api/cors-proxy?url=" + materialUrlField.value
            : materialUrlField.value;
        return (
            <LinkPreview
                text={url}
                renderText={() => <TextM>{materialUrlField.value}</TextM>}
                renderImage={() => <></>}
                renderTitle={(title) => {
                    materialTitleField.onChange(
                        title.substring(0, 35) + (title?.length > 35 ? "..." : "")
                    );
                    return <TextM fontWeight={"Bold"}>{title}</TextM>;
                }}
                renderDescription={(description) => <TextM>{description}</TextM>}
                touchableWithoutFeedbackProps={{
                    disabled: disabled
                }}
                containerStyle={{
                    backgroundColor: theme.colors.linkLighter,
                    borderRadius: theme.roundness,
                    marginTop: 20,
                    overflow: "hidden"
                    // maxHeight: 180
                }}
            />
        );
    };

    const renderMaterialUrlDialog = () => (
        <Portal>
            <Dialog
                visible={visible}
                onDismiss={hideDialog}
                dismissable={false}
                style={{
                    maxWidth: dimensions?.width,
                    alignSelf: "center",
                    // position: 'fixed',
                    backgroundColor: theme.colors.white,
                    borderRadius: 20,
                    elevation: 10,
                    zIndex: 10
                }}
            >
                <Dialog.Title>Lien externe</Dialog.Title>
                <Dialog.Content>
                    <TextInputM
                        {...register("material_url", {...validation.checkUrl()})}
                        ref={materialUrlRef}
                        // label={'Chapitre ou notion'}
                        placeholder={
                            'Exemple : "https://maths-pdf.fr/les-fonctions-affines-exercices-maths-troisieme-10""'
                        }
                        // autofocus={index === 0}
                        onChangeAdditional={(value) => {
                            setFormValues({
                                ...control._formValues,
                                material_url: value
                            });
                            trigger("material_url");
                        }}
                        onSubmitEditing={onSubmitEditing}
                        returnKeyType={"done"}
                        contentContainerStyle={{
                            width:
                                IS_WEB || dimensions?.width > 800
                                    ? (dimensions?.width * 3) / 5
                                    : "100%"
                        }}
                        // name={i?.name}
                        control={control}
                        errors={errors}
                    />
                    {!isUrlInvalid && renderLinkPreview()}
                </Dialog.Content>
                <Dialog.Actions>
                    {materialUrlField.value !== "" && (
                        <Button
                            style={{
                                marginRight: 5
                            }}
                            labelStyle={{
                                // color: theme.colors.white
                                fontFamily: "Montserrat-Bold",
                                flexWrap: "wrap"
                            }}
                            mode={"text"}
                            textColor={theme.colors.primary}
                            onPress={onCancelEditing}
                        >
                            {"Supprimer"}
                        </Button>
                    )}
                    <Button
                        style={{
                            marginRight: 5
                        }}
                        labelStyle={{
                            // color: theme.colors.white
                            fontFamily: "Montserrat-Bold",
                            flexWrap: "wrap"
                        }}
                        // disabled={isUrlInvalid}
                        mode={"text"}
                        textColor={theme.colors.primary}
                        onPress={onSubmitEditing}
                    >
                        {"OK"}
                    </Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    );
    // console.log(props.materials, activityTemplate);
    const renderIconButton = (onPress) => (
        <IconButton
            icon={"trash"}
            style={{
                position: "absolute",
                top: -10,
                right: -10
            }}
            iconColor={theme.colors.light}
            size={24}
            onPress={onPress}
        />
    );

    const renderNewMaterial = () => {
        return (
            <>
                <ScrollView
                    contentContainerStyle={{
                        paddingBottom: 280
                    }}
                >
                    {chapterId && !hasRole(props.user, "student") && (
                        <IconButtonM
                            size={30}
                            icon={"search"}
                            iconColor={theme.colors.primary}
                            onPress={() => {
                                setMaterialAction("search");
                            }}
                            style={{
                                alignSelf: "flex-end"
                            }}
                        />
                    )}
                    {/* button to add the material (material_url or material_attachments) */}
                    {/* if no url and no attachments : button */}
                    {isUrlInvalid &&
                        materialAttachmentsField?.value?.length === 0 &&
                        (activityTemplate?.slug === "video_course" ? (
                            renderButton(showDialog)
                        ) : (
                            <MenuM
                                buttonComponent={renderButton()}
                                style={{
                                    width:
                                        IS_WEB || dimensions?.width > 800
                                            ? (dimensions?.width * 3) / 5
                                            : "100%",
                                    alignSelf: "center"

                                    // marginBottom: insets.bottom,
                                    // marginTop: 20
                                }}
                                items={[
                                    {
                                        icon: "link",
                                        title: "Lien externe",
                                        onPress: () => {
                                            // navigation.push('Historique')
                                            showDialog();
                                        }
                                    },
                                    {
                                        icon: "camera",
                                        title: "Prendre une photo",
                                        onPress: () => {
                                            launchCamera();
                                        }
                                    },
                                    {
                                        icon: "file-image",
                                        title: "Choisir un fichier",
                                        onPress: () => {
                                            // navigation.push('Historique')
                                            setPickingImage(true);
                                            pickImage(images, setImages)
                                                .then(() => {
                                                    setPickingImage(false);
                                                })
                                                .catch(() => {
                                                    setPickingImage(false);
                                                });
                                        }
                                    }
                                ]}
                                renderer={renderers.SlideInMenu}
                            />
                        ))}
                    {/* else if url show url with link, and floating button on the right to remove the link */}
                    {!isUrlInvalid && (
                        <TouchableOpacity
                            style={{
                                backgroundColor: theme.colors.primaryLighter,
                                borderRadius: theme.roundness,
                                marginTop: 20,
                                padding: 20
                            }}
                            onPress={() => {
                                handleShowMaterialModal();
                            }}
                        >
                            <View
                                style={{
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "stretch",
                                    alignSelf: "center"
                                    // backgroundColor: "red"
                                }}
                            >
                                <TextM
                                    wrap
                                    fontWeight="ExtraBold"
                                    color={theme.colors.primary}
                                    fontSize={18}
                                    style={{textAlign: "center"}}
                                >
                                    Lien externe
                                </TextM>
                                {renderLinkPreview(true)}
                                {renderIconButton(() => {
                                    DialogM.show({
                                        text1: "Retirer le lien ?",
                                        buttonText1: "Oui",
                                        buttonColor1: theme.colors.error,
                                        onPress: () => {
                                            materialUrlField.onChange("");
                                        },
                                        buttonText2: "Annuler"
                                    });
                                })}
                            </View>
                        </TouchableOpacity>
                    )}
                    {/* else if attachments show attachment (first of the list), and floating button on the right to remove the attachment */}
                    {isUrlInvalid && materialAttachmentsField?.value?.length > 0 && (
                        <MaterialImage
                            images={images}
                            setImages={setImages}
                            renderIconButton={renderIconButton}
                            canDeleteImage={true}
                            material={{
                                text: control._formValues?.material_text,
                                url: materialUrlField?.value,
                                attachments: materialAttachmentsField?.value?.map(
                                    (i) => i?.uri
                                )
                            }}
                            handleShowMaterialModal={handleShowMaterialModal}
                        />
                    )}
                    {/* textinputm for material_chapter */}
                    {activityTemplate?.slug !== "video_course" && (
                        <TextInputM
                            {...register("material_title")}
                            ref={materialChapterRef}
                            label={"Titre de l'activité"}
                            placeholder={
                                activitySlugsMaterialTitleExample[activityTemplate?.slug]
                            }
                            // autofocus={index === 0}
                            onChangeAdditional={(value) => {
                                setFormValues({
                                    ...control._formValues,
                                    material_chapter: value
                                });
                            }}
                            onSubmitEditing={() => {
                                Keyboard.dismiss();
                            }}
                            returnKeyType={"done"}
                            contentContainerStyle={{
                                width:
                                    IS_WEB || dimensions?.width > 800
                                        ? (dimensions?.width * 3) / 5
                                        : "100%",
                                marginBottom: 10
                            }}
                            // name={i?.name}
                            control={control}
                            errors={errors}
                        />
                    )}
                    {/* textinputm for material_text */}
                    {!hasRole(props.user, "student") && (
                        <TextInputM
                            {...register("material_text")}
                            label={"Indications"}
                            ref={materialTextRef}
                            placeholder={materialPlaceholder(activityTemplate?.slug)}
                            // autofocus={index === 0}
                            onSubmitEditing={() => {
                                materialChapterRef.current?.focus();
                            }}
                            onChangeAdditional={(value) => {
                                setFormValues({
                                    ...control._formValues,
                                    material_text: value
                                });
                            }}
                            returnKeyType={"next"}
                            // returnKeyType={index === data?.inputs?.length - 1 ? "done" : "next"}
                            contentContainerStyle={{
                                width:
                                    IS_WEB || dimensions?.width > 800
                                        ? (dimensions?.width * 3) / 5
                                        : "100%"
                            }}
                            multiline={true}
                            style={{
                                // marginHorizontal: 20
                                height: 120,
                                marginBottom: 6
                            }}
                            // name={i?.name}
                            control={control}
                            errors={errors}
                        />
                    )}

                    {!!control._formValues?.estimated_duration && (
                        <>
                            {levelButtonsDisabled ? (
                                <View
                                    style={{
                                        marginTop: 10,
                                        flexDirection: "row",
                                        alignItems: "center",
                                        alignSelf: "center",
                                        width:
                                            IS_WEB || dimensions?.width > 800
                                                ? (dimensions?.width * 3) / 5
                                                : "100%"
                                    }}
                                >
                                    <TextM
                                        style={{alignSelf: "flex-start", marginRight: 3}}
                                        fontSize={12}
                                    >
                                        Niveau de difficulté par défaut :{" "}
                                    </TextM>
                                    <ActivityLevelBadge level={1} style={{marginTop: 0}}/>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        marginTop: 10,
                                        alignSelf: "center",
                                        width:
                                            IS_WEB || dimensions?.width > 800
                                                ? (dimensions?.width * 3) / 5
                                                : "100%"
                                    }}
                                >
                                    <TextM style={{alignSelf: "flex-start", marginBottom: 5}}>
                                        Estime le niveau de difficulté
                                    </TextM>
                                    <SegmentedButtons
                                        {...register("material_level")}
                                        value={materialLevelField?.value}
                                        onValueChange={(value) => {
                                            materialLevelField.onChange(value);
                                            setFormValues({
                                                ...control._formValues,
                                                material_level: value
                                            });
                                        }}
                                        buttons={
                                            [
                                                {
                                                    value: 1,
                                                    label: props.materialLevels?.[1],
                                                    labelStyle: levelButtonsStyle,
                                                    checkedColor: materialLevelsColors?.[1],
                                                    disabled: false,
                                                    icon: materialLevelsIcons?.[1]
                                                },
                                                {
                                                    value: 2,
                                                    label: props.materialLevels?.[2],
                                                    labelStyle: levelButtonsStyle,
                                                    checkedColor: materialLevelsColors?.[2],
                                                    disabled: levelButtonsDisabled,
                                                    icon: materialLevelsIcons?.[2]
                                                },
                                                {
                                                    value: 3,
                                                    label: props.materialLevels?.[3],
                                                    labelStyle: levelButtonsStyle,
                                                    checkedColor: materialLevelsColors?.[3],
                                                    style: {
                                                        // alignItems: "center"
                                                    },
                                                    disabled: levelButtonsDisabled,
                                                    icon: materialLevelsIcons?.[3]
                                                }
                                            ]
                                            // ?.filter(x => (hasRole(props.user, "student") || activityTemplate?.title !== "Go exo" || activityTemplate?.title !== "Oral exo") ? x?.value === 1 : true)
                                        }
                                    />
                                </View>
                            )}
                        </>
                    )}
                    <FilledButtonM
                        onPress={() => {
                            //TODO add validation
                            goToNextStep();
                        }}
                        label={"Continuer"}
                        color={theme.colors.primary}
                        style={{
                            width: "100%",
                            alignSelf: "center",
                            marginTop: 30
                            // position: "absolute",
                            // bottom: 0
                        }}
                    />
                </ScrollView>

                <MaterialModal
                    handleShowModal={handleShowMaterialModal}
                    isOpen={isMaterialOpen}
                    material={{
                        text: control._formValues?.material_text,
                        url: materialUrlField?.value,
                        attachments: materialAttachmentsField?.value?.map((i) => i?.uri)
                    }}
                />

                {renderMaterialUrlDialog()}
            </>
        );
    };

    const renderMaterialsList = () => {
        return (
            <View
                style={{
                    // marginTop: 20,
                    width: Dimensions.get("window").width,
                    alignItems: "center",
                    flex: 1,
                    alignSelf: "center"
                    // backgroundColor: "red",
                }}
            >
                <PaginatedFlatList
                    ref={flatListRef}
                    data={props.materials}
                    noRefreshWhenNoResults={true}
                    contentContainerStyle={{
                        paddingBottom: 100
                    }}
                    noActivityIndicator
                    additionalOnRefresh={() => {
                    }}
                    additionalRefreshing={false}
                    requestApiService={activitiesService.materialsSearch}
                    requestRouteParams={null}
                    requestUrlParams={urlParams}
                    requestData={null}
                    style={{
                        // marginTop: 20,
                        width: Dimensions.get("window").width,
                        flex: 1
                    }}
                    ListEmptyComponent={
                        props.materials?.length === 0 && (
                            <>
                                <TextM
                                    style={{
                                        textAlign: "center",
                                        marginTop: 20
                                    }}
                                >
                                    Aucun enoncé trouvé
                                </TextM>
                            </>
                        )
                    }
                    ListHeaderComponent={
                        <View style={{width: dimensions.width - 40}}>
                            <View
                                style={{
                                    flexDirection: "row"
                                }}
                            >
                                <ServerSearch
                                    style={{
                                        width: dimensions.width - 100,
                                        boxShadow: "none",
                                        borderWidth: 1,
                                        borderColor: theme.colors.lightMore,
                                        marginRight: 10,
                                        marginTop: 4
                                        // height: 55
                                    }}
                                    request={props.materialsSearchRequest}
                                    urlParams={urlParams}
                                    setUrlParams={setUrlParams}
                                />
                                <IconButtonM
                                    size={30}
                                    icon={"plus"}
                                    iconColor={theme.colors.primary}
                                    onPress={() => {
                                        setMaterialAction("create");
                                    }}
                                    style={{
                                        alignSelf: "flex-end"
                                    }}
                                />
                            </View>

                            {IS_WEB && props.materialsSearchRequest && (
                                <ActivityIndicator
                                    color={theme.colors.primary}
                                    style={{marginVertical: 20}}
                                />
                            )}

                            {props.materials?.length > 0 && (
                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginTop: 30
                                    }}
                                >
                                    <TextM fontWeight={"Bold"} fontSize={18}>
                                        {"Enoncés".toUpperCase()}
                                    </TextM>
                                    <View
                                        style={{
                                            padding: 3,
                                            borderRadius: 50,
                                            backgroundColor: theme.colors.greyAlt,
                                            width: 24,
                                            height: 24,
                                            marginLeft: 10,
                                            alignItems: "center"
                                        }}
                                    >
                                        <TextM
                                            color={theme.colors.white}
                                            fontSize={14}
                                            fontWeight={"Bold"}
                                        >
                                            {props.materials?.length}
                                        </TextM>
                                    </View>
                                </View>
                            )}
                        </View>
                    }
                    renderItem={(item) => {
                        return (
                            <MaterialItemComponent
                                material={item.item}
                                key={item.index}
                                manageTodo={manageTodo}
                                field={field}
                                goToNextStep={goToNextStep}
                                register={register}
                                materialUrlField={materialUrlField}
                                materialAttachmentsField={materialAttachmentsField}
                                materialTitleField={materialTitleField}
                            />
                        );
                    }}
                />
                <FilledButtonM
                    onPress={() => {
                        //TODO add validation
                        goToNextStep();
                    }}
                    label={"Continuer"}
                    color={theme.colors.primary}
                    style={{
                        width: dimensions.width / 2,
                        alignSelf: "center",
                        marginTop: 30
                        // position: "absolute",
                        // bottom: 0
                    }}
                />
            </View>
        );
    };

    return (
        <>
            {materialAction === "create" || !chapterId
                ? renderNewMaterial()
                : renderMaterialsList()}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        lastPhoto: state.camera?.lastPhoto,
        lastPhotos: state.camera?.lastPhotos,
        lastPhotoFormId: state.camera?.formId,
        activityTemplates: state.data.staticData?.activity_templates,
        materialLevels: state.data?.staticData?.material_levels,
        materials: state.data.materials?.materialsList,
        materialsSearchRequest: state.api.materialsSearch?.request
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    clearLastPhoto,
    clearPhotos
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialPicker);
