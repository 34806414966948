import React, {useEffect, useState} from 'react';
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {activityTypes, schoolSubjectCategories} from "@data/constants/activitiesConstants";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {validation} from "@data/utility/validation";
import {scoringService} from "@data/services/scoringService";
import {studentClasses} from "@data/constants/formConstants";


const NewScoringCommentScreen = (props) => {

    const {navigation, route} = props

    const description = route.params?.description
    const scoringCriterionId = route.params?.scoringCriterionId
    const currentActivityType = route.params?.currentActivityType
    const currentSchoolSubjectCategory = route.params?.currentSchoolSubjectCategory

    const [formValues, setFormValues] = useState(null)
    const currentRuleTitle = formValues?.title

    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Nouvelle règle",
            description: description ?? null,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "title",
                    placeholder: "Intitulé",
                    // height: 120,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ]
        },
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Nouveau commentaire associé",
            description: (description ?? null) + " - " + currentRuleTitle,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "comment",
                    placeholder: "Bref point d'amélioration ou conseil, qui alimentera l'appréciation de Sam",
                    height: 120,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ]
        },
        {
            label: "Pour quels types d'activités cette règle est-elle valable ?",
            description: (description ?? null) + " - " + currentRuleTitle,
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "activity_types",
            height: 70,
            options: Object.keys(activityTypes)?.map(x => {
                return {
                    label: activityTypes[x],
                    value: x,
                    disabled: x === currentActivityType
                }
            }),
            defaultValue: Object.keys(activityTypes),
            validationRules: {
                ...validation.minLength(1)
            }
        },
        {
            label: "Pour quelles matières cette nouvelle règle est-elle valable ?",
            description: (description ?? null) + " - " + currentRuleTitle,
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "school_subject_categories",
            height: 70,
            options: Object.keys(schoolSubjectCategories)?.map(x => {
                return {
                    label: schoolSubjectCategories[x],
                    value: x,
                    disabled: x === currentSchoolSubjectCategory
                }
            }),
            defaultValue: Object.keys(schoolSubjectCategories),
            validationRules: {
                ...validation.minLength(1)
            }
        },
        {
            label: "À partir de quelle classe cette règle est-elle valable ?",
            description: (description ?? null) + " - " + currentRuleTitle,
            type: "select.single", // select.single, select.multiple, text, date
            name: "minimum_student_class",
            height: 70,
            options: studentClasses?.map(x => {
                return {value: x, label: x}
            }),
            defaultValue: "6ème",
            validationRules: {...validation.required()}
        }
    ]

    const onSubmit = data => {
        if (props.postScoringRuleRequest) return
        const formData = {
            ...data,
            scoring_criterion_id: scoringCriterionId ?? null
        }
        // console.log("formData", formData)
        props.apiRequest(scoringService.postScoringRule, {}, formData)
    }

    useApiStatus(
        scoringService.postScoringRule, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // props.apiIdle(scoringService.postScoringRule)
            navigation.goBack()
            // const congrats = [{
            //     congratsType: congratsTypes.GENERIC,
            //     congratsData: {
            //         title: "Effectué",
            //         text: "Le créneau a bien été mis à jour"
            //     }
            // }]
            // navigation.replace("congrats", {congrats})
        },
        () => {
        },
        true
    )
    const defaultValues = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log("PARENT :")
        // console.log(defaultValues)

    }, [formValues])

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.postScoringRuleRequest}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.postScoringRuleError}
                         submitButtonText="Enregistrer"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        postScoringRuleRequest: state.api.postScoringRule?.request,
        postScoringRuleSuccess: state.api.postScoringRule?.success,
        postScoringRuleError: state.api.postScoringRule?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewScoringCommentScreen)