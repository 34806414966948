import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {roomService} from "@data/services/roomService";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {roomTypes} from "@data/constants/formConstants";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {convertMomentToFrench} from "../../AssignTutorAndLives";
import {backOfficeService} from "@data/services/backOfficeService";
import {RankingListItem} from "../../../student/social/RankingScreen";

const LiveStepper = (props) => {

    const room_id = props.route.params?.room_id ?? null
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const editing = !!room_id
    const room = findItemInListWithId(room_id, props.rooms) ?? null
    const defaultValues = {}

    const otherData = (room?.tutor?.id && formValues.tutor_id !== room?.tutor?.id) ? [
        {
            label: "Appliquer à toutes les sessions ?",
            type: "select.single",
            name: "apply_to_all_live_sessions",
            options: [{value: true, label: "Oui"}, {value: false, label: "Non"}],
            defaultValue: false,
            validationRules: {...validation.required()}
        },
    ] : []
    const formData = [
        {
            label: "Choisir le tuteur",
            name: "tutor_id",
            type: "select.paginated",
            singleMode: true,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "tutor",
                light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: room?.tutor?.id ?? "",
            validationRules: {...validation.required()}
        },
        {
            label: "Choisir le type de salle",
            type: "select.single",
            name: "type",
            options: roomTypes,
            defaultValue: room?.type ?? "",
            validationRules: {...validation.required()}
        },
        ...otherData
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(formValues)

    }, [formValues])

    const onSubmit = data => {
        if (props.newRoomRequest || props.updateRoomRequest) return
        setFormValues({...formValues, type: data?.type})
        if (editing)
            props.apiRequest(roomService.editRoom, {id: room_id}, data)
        else
            props.apiRequest(roomService.newRoom, {}, data)
    }
    useApiStatus(
        roomService.newRoom, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(roomService.getRooms, {}, {}, {type: formValues?.type})
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("rooms")
        } // success callback
    )
    useApiStatus(
        roomService.editRoom, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("rooms")
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.newRoomRequest || props.updateRoomRequest}
                         previousRouteName={"rooms"}
                         submittedMessage=""
                         serverError={props.newRoomError || props.updateRoomError}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        tutors: state.data.tutors?.list,
        newRoomRequest: state.api.newRoom?.request,
        updateRoomRequest: state.api.editRoom?.request,
        newRoomError: state.api.newRoom?.error,
        updateRoomError: state.api.editRoom?.error,
        liveSession: state.data.backOffice?.liveSession,
        rooms: state.data.backOffice?.rooms,
        users: state.data.users?.usersList,
        usersFormStep: state.data?.users?.usersListFormStep,


    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LiveStepper)
