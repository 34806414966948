import React, {useContext, useEffect} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {BackHandler, Dimensions, Keyboard, Platform, View} from "react-native";
import {connect} from "react-redux";
import {Tabs, TabScreen} from "react-native-paper-tabs";
import {theme} from "@common/theme/theme";
import MyAccountGeneralScreen from "./SettingsGeneralScreen";
import MyAccountProfileScreen from "./SettingsProfileScreen";
import MyAccountConfigurationScreen from "./SettingsConfigurationScreen";
import {useForm} from "react-hook-form";
import {authService} from "@data/services/authService";
import yup, {phoneRegExp} from "@data/utility/validation";
import {yupResolver} from "@hookform/resolvers/yup";
import {ActivityIndicator, Appbar} from "react-native-paper";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {hasRole} from "@data/utility/ability";
import {StatusBar} from "expo-status-bar";
import {subjectPriorities} from "@data/constants/formConstants";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {AbilityContext} from "@data/utility/Can";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {backOfficeService} from "@data/services/backOfficeService";
import {findItemInListWithId} from "@data/utility/dataUtils";


export const HeaderM = props => {

    const {goBack, buttonIcon, request, onPress, title} = props

    return <View style={{
        width: Platform.OS === 'web' ? Dimensions.get('window').width : null,
        alignSelf: Platform.OS !== 'web' ? null : "center"
    }}>
        <Appbar.Header
            theme={{colors: {primary: theme.colors.white}}}
            statusBarHeight={Platform.OS === "ios" ? StatusBar.height : 0}
        >
            <Appbar.BackAction onPress={goBack}/>
            <Appbar.Content
                color={theme.colors.primary}
                titleStyle={{
                    fontFamily: "Montserrat-Bold"
                }}
                title={title}
            />

            {request ? (
                <ActivityIndicator
                    animating={true}
                    color={theme.colors.primary}
                    size={25}
                    style={{
                        // position: "absolute",
                        // right: 20,
                        marginRight: 20,
                        marginLeft: 3
                    }}
                />
            ) : (
                <Appbar.Action
                    icon={buttonIcon}
                    color={theme.colors.primary}
                    onPress={onPress}
                />
            )}
        </Appbar.Header>
    </View>
}

const SettingsTabNavigator = (props) => {
    const {navigation, route} = props;

    const userId = route.params?.userId;
    // console.log("userId", userId)

    const user = userId ? findItemInListWithId(userId, props.users) : props.user;

    // console.log("props.users", props.users) // TODO : replace with a show user in case it is another user
    // console.log("user", user)
    //TODO : optimiser le code des formulaires en fonction du rôle

    const formSchema = yup.object().shape({
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        mobile: yup.string().nullable().matches(phoneRegExp, "Le numéro de téléphone est invalide"),
        email: yup.string().email().required(),
        parent_email: hasRole(user, "student") ? yup.string().email().nullable() : null,
        parent_mobile: hasRole(user, "student") ? yup.string().required() : null
    });

    const {initialTab = 0} = route.params;
    const resolver = yupResolver(formSchema);
    // console.log(user?.profile?.subject_priority)
    const {
        control,
        handleSubmit,
        formState: {errors, isDirty, dirtyFields}
    } = !user ? null : useForm({
        resolver,
        defaultValues: {
            firstname: user?.firstname,
            lastname: user?.lastname,
            email: user?.email,
            mobile: user?.mobile,
            parent_email: user?.parent_email,
            parent_mobile: user?.parent_mobile,
            school_name: user?.profile?.school_name,
            city: user?.profile?.city,
            specialty_school_subjects:
                user?.specialty_school_subjects?.map((x) => {
                    return {value: x?.id.toString(), label: x?.name};
                }) ?? [],
            class:
                {
                    value: user?.profile?.class,
                    label: user?.profile?.class
                } ?? {},
            reason: {
                value: user?.reason,
                label: user?.reason
            } ?? {},
            future_orientation: user?.profile?.future_orientation ?? "",
            future_job: user?.profile?.future_job ?? "",
            personal_work_frequency:
                {
                    value: user?.profile?.personal_work?.frequency,
                    label: user?.profile?.personal_work?.frequency
                } ?? {},
            personal_work_duration:
                {
                    value: user?.profile?.personal_work?.duration,
                    label: user?.profile?.personal_work?.duration
                } ?? {},
            prioritized_school_subjects:
                user?.prioritized_school_subjects?.map((x) => {
                    return {value: x?.id.toString(), label: x?.name, icon: x?.icon};
                }) ?? [],
            appreciated_school_subjects:
                user?.appreciated_school_subjects?.map((x) => {
                    return {value: x?.id.toString(), label: x?.name, icon: x?.icon};
                }) ?? [],
            subject_priority:
                subjectPriorities?.find(x => x?.value === user?.profile?.subject_priority) ?? {},
            qualities:
                user?.profile?.qualities?.map((x) => {
                    return {value: x, label: x};
                }) ?? [],

            professional_situation:
                {
                    value: user?.profile?.professional_situation,
                    label: user?.profile?.professional_situation
                } ?? {},
            study_field: user?.profile?.study_field,
            current_position: user?.profile?.current_position,
            motto: user?.profile?.motto ?? "",
            notification_settings: user?.notification_settings
        }

    });

    const onError = (errors) => {
        DialogM.show({
            text1: "Champ(s) invalide(s)", text2: "Vérifie bien tous les champs avant de sauvegarder"
        })
        //alertPolyfill("Champ(s) invalide(s)", "Vérifie bien tous les champs avant de sauvegarder")
    };
    const ability = useContext(AbilityContext)

    const onSubmit = (data) => {

        const classValue = data.class?.value;
        const subject_priorityValue = data.subject_priority?.value;
        const personal_work_frequencyValue = data.personal_work_frequency?.value;
        const personal_work_durationValue = data.personal_work_duration?.value;
        const reasonValue = data.reason?.value;
        const appreciated_school_subjectsValue = data.appreciated_school_subjects?.map((x) => {
            return x.value;
        });
        const prioritized_school_subjectsValue = data.prioritized_school_subjects?.map((x) => {
            return x.value;
        });
        const specialty_school_subjectsValue = data.specialty_school_subjects?.map((x) => {
            return x.value;
        });
        const qualitiesValue = data.qualities?.map((x) => {
            return x.value;
        });
        const dataStudent = {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            mobile: data.mobile,
            parent_email: data.parent_email,
            parent_mobile: data.parent_mobile,
            school_name: data.school_name,
            city: data.city,
            class: classValue,
            reason: reasonValue,
            subject_priority: subject_priorityValue,
            future_orientation: data.future_orientation,
            future_job: data.future_job,
            personal_work_frequency: personal_work_frequencyValue,
            personal_work_duration: personal_work_durationValue,
            qualities: qualitiesValue,
            specialty_school_subjects: specialty_school_subjectsValue,
            prioritized_school_subjects: prioritized_school_subjectsValue,
            appreciated_school_subjects: appreciated_school_subjectsValue,
            notification_settings: data.notification_settings

        };
        const dataTutor = {
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.email,
            mobile: data.mobile,
            city: data.city,
            professional_situation: data.professional_situation?.value,
            study_field: data.study_field,
            current_position: data.current_position,
            motto: data.motto,
            notification_settings: data.notification_settings
        };

        // console.log(dataStudent)
        if (!userId) {
            hasRole(user, "student")
                ? (
                    props.updateStudentAccountRequest,
                        props.apiRequest(authService.updateStudentAccount, {}, dataStudent, {}),
                        Keyboard.dismiss())
                : ability.can('read', 'my_tutor_profile_completion')
                    ? (

                        props.updateTutorAccountRequest,
                            props.apiRequest(authService.updateTutorAccount, {}, dataTutor, {}),
                            Keyboard.dismiss())
                    : /*Parent*/
                    null;
        } else {
            hasRole(user, "student")
                ? (
                    props.putStudentAccountRequest,
                        props.apiRequest(backOfficeService.putStudentAccount, {user_id: userId}, dataStudent, {}),
                        Keyboard.dismiss())
                : hasRole(user, "tutor") || hasRole(user, "tutor-candidate")
                    ? (

                        props.putTutorAccountRequest,
                            props.apiRequest(backOfficeService.putTutorAccount, {user_id: userId}, dataTutor, {}),
                            Keyboard.dismiss())
                    : /*Parent*/
                    null;
        }
    };

    useApiStatus(
        backOfficeService.getUserBackoffice, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        },
        error => {
            navigation.goBack()
        }
    )

    useApiStatus(
        userId ? backOfficeService.putStudentAccount : authService.updateStudentAccount, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // navigation.goBack()
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Tes paramètres ont bien été mis à jour"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )

    useApiStatus(
        userId ? backOfficeService.putTutorAccount : authService.updateTutorAccount, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Tes paramètres ont bien été mis à jour"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )


    // workaround to use the state in event listener
    const isDirtyRef = React.useRef(false);
    useEffect(() => {
        isDirtyRef.current = isDirty
    }, [isDirty]);

    // ** Handle click on hardware back button
    useEffect(() => {

        if (userId) {
            props.apiRequest(backOfficeService.getUserBackoffice, {id: userId})
        }

        BackHandler.addEventListener('hardwareBackPress', _goBack);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', _goBack);
        }
    }, [])

    const _goBack = () => {

        // console.log("dirtyFields", dirtyFields)
        // console.log("isDirtyRef", isDirtyRef)

        const goBack = () => {
            if (navigation.canGoBack()) {
                navigation.goBack()
            } else {
                navigation.replace("account")
            }
        }

        if (isDirtyRef.current) {
            DialogM.show({
                text1: 'Modifications non enregistrées',
                text2: 'Veux-tu vraiment quitter la page ?',
                buttonText1: 'Quitter',
                onPress: () => {
                    goBack()
                },
                buttonText2: 'Non, continuer',
            })
            /*            alertPolyfill('Modifications non enregistrées', 'Veux-tu vraiment quitter la page ?', [
                            {
                                text: 'Non, continuer',
                                onPress: () => {
                                },
                                style: 'cancel'
                            },
                            {
                                text: 'Quitter', onPress: () => {
                                    goBack()
                                }
                            }
                        ]);*/
        } else {
            goBack();
        }
        return true
    }

    const updateRequest = props.updateStudentAccountRequest || props.updateTutorAccountRequest || props.putStudentAccountRequest || props.putTutorAccountRequest


    const tabs = hasRole(user, "tutor-candidate")
        ? [
            {
                name: "Général",
                component: MyAccountGeneralScreen
            },
            // {
            //     name: "Profil",
            //     component: MyAccountProfileScreen
            // },
            {
                name: "Réglages",
                component: MyAccountConfigurationScreen
            }
        ]
        : [
            {
                name: "Général",
                component: MyAccountGeneralScreen
            },
            {
                name: "Profil",
                component: MyAccountProfileScreen
            },
            {
                name: "Réglages",
                component: MyAccountConfigurationScreen
            }
        ];

    return (
        <>
            <HeaderM title={"Paramètres"}
                     goBack={_goBack}
                     buttonIcon={"save"}
                     onPress={handleSubmit(onSubmit)}
                     request={updateRequest || props.getUserBackofficeRequest}
            />

            <Tabs
                defaultIndex={initialTab ?? 0} // default = 0
                // uppercase={false} // true/false | default=true | labels are uppercase
                // showTextLabel={false} // true/false | default=false (KEEP PROVIDING LABEL WE USE IT AS KEY INTERNALLY + SCREEN READERS)
                // iconPosition="leading" // leading, top | default=leading
                style={{
                    backgroundColor: theme.colors.white,
                    width: Platform.OS === 'web' ? Dimensions.get("window").width : null,
                    alignSelf: Platform.OS !== 'web' ? null : "center"
                }} // works the same as AppBar in react-native-paper
                // dark={false} // works the same as AppBar in react-native-paper
                // theme={} // works the same as AppBar in react-native-paper
                // mode="scrollable" // fixed, scrollable | default=fixed
                // onChangeIndex={(newIndex) => {}} // react on index change
                // showLeadingSpace={true} //  (default=true) show leading space in scrollable tabs inside the header
                disableSwipe={true} // (default=false) disable swipe to left/right gestures
            >
                {tabs.map((tab1) => {
                    return (
                        <TabScreen
                            label={tab1.name}
                            key={tab1.name}
                            // icon={<TextM>t</TextM>}
                        >
                            {React.createElement(tab1.component, {
                                control: control,
                                errors: errors,
                                navigation,
                                userId: userId
                            })}
                        </TabScreen>
                    );
                })}
            </Tabs>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        updateStudentAccountRequest: state.api.updateStudentAccount?.request,
        updateStudentAccountSuccess: state.api.updateStudentAccount?.success,
        updateStudentAccountError: state.api.updateStudentAccount?.error,

        updateTutorAccountRequest: state.api.updateTutorAccount?.request,
        updateTutorAccountSuccess: state.api.updateTutorAccount?.success,
        updateTutorAccountError: state.api.updateTutorAccount?.error,

        putStudentAccountRequest: state.api.putStudentAccount?.request,
        putStudentAccountSuccess: state.api.putStudentAccount?.success,
        putStudentAccountError: state.api.putStudentAccount?.error,

        putTutorAccountRequest: state.api.putTutorAccount?.request,
        putTutorAccountSuccess: state.api.putTutorAccount?.success,
        putTutorAccountError: state.api.putTutorAccount?.error,

        getUserBackofficeRequest: state.api.getUserBackoffice?.request,

        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token,
        users: state.data.users?.usersList
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsTabNavigator);
