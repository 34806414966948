import React, {useContext, useEffect, useState} from 'react';
import {ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TutorsStatsCardItem from "./TutorsStatsCardItem";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {groupByAttribute} from "./GrowthTabScreen";

const TutorsStatsTabScreen = (props) => {

    const dimensions = useContext(DimensionsContext)
    const [statsGroupedByCategory, setStatsGroupedByCategory] = useState({})

    useEffect(() => {
        if (props.appStats) {
            setStatsGroupedByCategory(groupByAttribute(Object.values(props.appStats), 'category'))
            // console.log(groupByAttribute(Object.values(props.appStats), 'category'))
        }
    }, [props.appStats]);
    return (
        <View style={{
            width: dimensions.width,
            alignSelf: 'center',
            marginTop: 20,
        }}>
            {props.getApplicationStatisticsRequest ? <ActivityIndicator color={theme.colors.primary}/>
                :
                <ScrollView style={{
                    width: dimensions.width - 20,
                    alignSelf: 'center'
                }}>
                    {Object.values(statsGroupedByCategory)?.filter((stat) => stat[0].category === 'lives' || stat[0].category === 'recruitment').map((stat, index) => {
                        return <TutorsStatsCardItem
                            key={index}
                            mainTitle={stat[0]?.category.split('_').join(' ')}
                            items={stat}
                            style={{
                                marginBottom: 20
                            }}
                        />
                    })}
                </ScrollView>}
        </View>
    )
}
const mapStateToProps = state => {
    return {
        appStats: state.data.backOffice?.appStats,
        getApplicationStatisticsRequest: state.api.getApplicationStatistics?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorsStatsTabScreen)