import React, {useContext, useEffect, useRef, useState} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../assets/icons/generic/live.png";
import AvatarM from "@common/components/avatar/AvatarM";
import {CardButtonM} from "../../tutor/home/interview/TutorInterviewCard";
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "../../data/redux/actions/api";
import {backOfficeService} from "../../data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {MenuFilter} from "@common/components/menu/MenuFilter";
import {tutorsService} from "../../data/services/tutorsService";
import {sectionStudentClasses, studentClasses} from "../../data/constants/formConstants";
import moment from "moment";
import {capitalize} from "../../data/utility/Utils";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {IconButton} from "react-native-paper";

function LivesScreen(props) {
    const navigation = useNavigation()
    const flatListRef = useRef()
    const [formValues, setFormValues] = useState({});
    const dimensions = useContext(DimensionsContext)

    useEffect(() => {
        // props.apiRequest(backOfficeService.getLiveSessions, {}, {}, {})
        props.apiRequest(tutorsService.getTutors)
    }, []);

    useApiStatus(
        tutorsService.getTutors, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (successData)
                setFormValues({
                    ...formValues,
                    tutor_ids: successData?.map(i => i?.id),
                    student_classes: studentClasses,
                })
        } // success callback
    )

    useApiStatus(
        backOfficeService.getLiveSessions, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        } // success callback
    )
    const urlParams = {
        tutor_ids: formValues?.tutor_ids?.join(",") ?? "",
        student_classes: formValues?.student_classes?.join(",") ?? "",
    }

    const filterItems = [
        {
            name: "tutor_ids",
            label: "Tuteur",
            sectionMode: true,
            options: [
                {
                    title: "Tuteur",
                    data: props.tutors
                        ?.map(x => {
                            return {avatar: x, value: x.id, label: x?.display_name}
                        })
                }
            ],
            defaultValue: props.tutors?.map(i => i?.id)
        },
        {
            name: "student_classes",
            label: "Classe",
            sectionMode: true,
            options: sectionStudentClasses?.map(section => {
                return {
                    ...section,
                    data: section?.options?.map(x => {
                        return {value: x, label: x}
                    })
                }
            }),
            defaultValue: studentClasses
        }
    ]

    const onFinishSelecting = (newFormValues = null) => {
        if (newFormValues) {
            //console.log(newFormValues)
            props.apiRequest(backOfficeService.getLiveSessions, {}, {}, {
                student_classes: newFormValues?.student_classes?.join(",") ?? "",
                tutor_ids: newFormValues?.tutor_ids?.join(",") ?? "",
            })
        } else {
            props.apiRequest(backOfficeService.getLiveSessions, {}, {}, urlParams)
        }
    }

    return (
        <View style={{
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
        }}>
            <HeaderContainerM>
                <TitleM>Liste des LIVES</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>
            <View style={{
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 1
            }}>
                <View style={{
                    width: dimensions?.width,
                    alignSelf: 'center',
                    alignItems: 'center',
                    flex: 1,
                    // height: IS_WEB ? dimensions?.height / 1.2 : null,
                    // flex: IS_WEB ? null : 1,
                }}>
                    {props.liveSessions && <PaginatedFlatList ref={flatListRef}
                                                              data={props.liveSessions}
                                                              additionalOnRefresh={() => {
                                                              }}
                                                              additionalRefreshing={false}
                                                              requestApiService={backOfficeService.getLiveSessions}
                                                              requestRouteParams={null}
                                                              requestUrlParams={urlParams}
                                                              requestData={null}
                                                              style={{
                                                                  paddingTop: 20,
                                                              }}
                                                              ListHeaderComponent={<View style={{
                                                                  flexDirection: "column",
                                                                  alignItems: 'center',
                                                                  justifyContent: 'space-between',
                                                                  width: dimensions?.width,
                                                                  borderColor: theme.colors.lightMore,
                                                              }}>
                                                                  <MenuFilter style={{
                                                                      alignItems: "space-between"
                                                                  }}
                                                                              setFormValues={setFormValues}
                                                                              formValues={formValues}
                                                                              filterItems={filterItems}
                                                                              onFinishSelecting={onFinishSelecting}
                                                                  />
                                                              </View>}
                                                              renderItem={(item) => {
                                                                  const slotMoment = item.item?.day_time ? moment(item.item?.day_time, "dddd HH:mm", "en") : null
                                                                  const slotMomentFormatted = slotMoment ? capitalize(slotMoment?.locale("fr")?.format("dddd HH:mm")) : null
                                                                  return <CardButtonM key={item.index}
                                                                                      onPress={() => {
                                                                                          navigation.push("live-show", {
                                                                                              live_id: item.item?.id
                                                                                          })
                                                                                      }}
                                                                                      membersCount={item.item?.students_count}
                                                                                      icon={<IconImageM source={live}
                                                                                                        width={30}
                                                                                                        style={{
                                                                                                            // tintColor: theme.colors.primary,
                                                                                                        }}
                                                                                      />}
                                                                                      title={slotMomentFormatted}
                                                                                      style={{
                                                                                          marginBottom: 15,
                                                                                          width: dimensions?.width - 20,
                                                                                          backgroundColor: theme.colors.white
                                                                                      }}
                                                                                      width={dimensions?.width - 20}
                                                                                      description={
                                                                                          <View style={{}}>
                                                                                              <View
                                                                                                  style={{
                                                                                                      flexDirection: 'row',
                                                                                                      alignItems: 'center',
                                                                                                  }}>
                                                                                                  <AvatarM
                                                                                                      user={item.item?.tutor}/>
                                                                                                  <TextM
                                                                                                      style={{marginLeft: 5}}
                                                                                                      color={theme.colors.primary}>{item.item?.tutor?.display_name}  </TextM>
                                                                                              </View>
                                                                                              <TextM
                                                                                                  wrap
                                                                                                  style={{
                                                                                                      marginLeft: 5,
                                                                                                      marginTop: 10,
                                                                                                  }}>
                                                                                                  {item.item?.students_list_string}
                                                                                              </TextM>
                                                                                          </View>
                                                                                      }/>
                                                              }}

                    />}

                </View>
            </View>
            <FilledButtonM
                icon={"plus"}
                onPress={() => {
                    navigation.push("lives-form", {
                        live_id: null
                    })
                }}
                label={"Ajouter"}
            />
        </View>
    );
}

const mapStateToProps = state => {
    return {
        registered: state.api.registerAndGetSanctumToken?.success,
        schoolSubjects: state.data.staticData?.school_subjects,
        liveSessions: state.data.backOffice?.liveSessions,
        tutors: state.data.tutors?.tutors,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LivesScreen)