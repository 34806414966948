import React, { useContext } from "react";
import { Dimensions, View, ScrollView } from "react-native";
import { theme } from "@common/theme/theme";
import { ListButtonM } from "../student/tutoring/MyCoachTabScreen";
import { IconImageM } from "@common/components/icons/IconsM";
import live from "../../assets/icons/generic/live.png";
import { DimensionsContext } from "@common/utils/mobileUtils";
import { useNavigation } from "@react-navigation/native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TitleM from "@common/components/text/TitleM";
import { boxedCardBorderStyle } from "../reviewer/BoxedCardBorderStyle";

const AdminActionsScreen = (props) => {
  const dimensions = useContext(DimensionsContext);
  const navigation = useNavigation();

  const actionsListItem = (action, title, icon) => (
    <ListButtonM
      icon={<FontAwesome5 name={icon} size={24} color={theme.colors.primary} />}
      title={title}
      onPress={action}
    />
  );

  return (
    <View
      style={{
        paddingTop: 30,
        flex: 1,
      }}
    >
      <TitleM fontWeight="ExtraBold" style={{ textAlign: "center" }}>
        {"Backoffice".toUpperCase()}
      </TitleM>

      <ScrollView
        style={{
          // flex: 1,
          marginTop: 40,
          alignSelf: "center",
        }}
      >
        <View
          style={{
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: theme.colors.lightMore,
            backgroundColor: theme.colors.white,
            width: dimensions?.width,
            alignItems: "center",
            justifyContent: "center",
            ...(Dimensions.get("window").width > 800
              ? boxedCardBorderStyle
              : null),
          }}
        >
          {actionsListItem(
            () => {
              navigation.push("admin-users", { initialTab: 0 });
            },
            "Gestion des élèves",
            "users"
          )}
          <ListButtonM
            icon={
              <IconImageM
                source={live}
                width={30}
                style={
                  {
                    // tintColor: theme.colors.primary
                  }
                }
              />
            }
            title={"Gestion des LIVES"}
            onPress={() => {
              navigation.push("rooms", { initialTab: 0 });
            }}
          />
          {actionsListItem(
            () => {
              navigation.push("tutors-applications", { initialTab: 1 });
            },
            "Gestion des candidatures",
            "address-book"
          )}
          {actionsListItem(
            () => {
              navigation.push("manage-tutors", { initialTab: 0 });
            },
            "Gestion des tuteurs",
            "user-tie"
          )}
            {actionsListItem(
                () => {
                    navigation.push("lives-history", { initialTab: 0 });
                },
                "Supervision des lives",
                "history"
            )}
          {actionsListItem(
            () => {
              navigation.push("admin-live-reports", { initialTab: 0 });
            },
            "Rapports de live",
            "file-medical-alt"
          )}
          {actionsListItem(
            () => {
              navigation.push("metrics", { initialTab: 0 });
            },
            "Métriques",
            "chart-bar"
          )}
          {actionsListItem(
            () => {
              navigation.push("study-programs", { initialTab: 0 });
            },
            "Gestion des programmes de révision",
            "book"
          )}
          {/*<ListButtonM icon={<FontAwesome5 name={"chart-line"}*/}
          {/*                                 size={30}*/}
          {/*                                 color={theme.colors.primary}*/}
          {/*/>}*/}
          {/*             title={"Analytique"}*/}
          {/*             isLast*/}
          {/*             onPress={() => {*/}
          {/*                 navigation.push("analytics")*/}
          {/*             }}*/}
          {/*/>*/}
        </View>
      </ScrollView>
    </View>
  );
};

export default AdminActionsScreen;
