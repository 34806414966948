import React, {useContext, useEffect, useState} from 'react';
import {Keyboard, StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {findItemInListWithId} from "@data/utility/dataUtils";
import moment from "moment";
import {dateOptionsInPeriod} from "@data/utility/Utils";
import yup, {validation} from "@data/utility/validation";
import {challengesService} from "@data/services/challengesService";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {theme} from "@common/theme/theme";
import {IS_WEB} from "@common/utils/mobileUtils";

const NewChallengeScreen = (props) => {

    // ** Component props
    const {navigation} = props
    const student_id = props.route.params.student_id

    const ability = useContext(AbilityContext)

    const [formValues, setFormValues] = useState(null)
    const currentChallenge = findItemInListWithId(formValues?.challenge_template_id, props.challengeTemplates)
    const currentSchoolSubject = findItemInListWithId(formValues?.school_subject_id, props.schoolSubjects)
    const currentStartDate = moment(formValues?.start_date)
    const currentDeadline = moment(formValues?.deadline)
    const planningMode = formValues?.planning_mode
    const numWeeks = Math.ceil((currentDeadline.diff(currentStartDate, 'day') - 1) * 10 / 7) / 10

    // console.log(currentSchoolSubject)

    const formData = [
        {
            label: "Choisis la matière",
            // is_masked: !currentChallenge?.needs_school_subject,
            type: "select.single", // select.single, select.multiple, text, date
            name: "school_subject_id",
            options: props.schoolSubjects
                // ?.slice(0, 3)
                ?.map(x => {
                    return {icon: x?.icon, value: x.id, label: x.name}
                }),
            defaultValue: ""
        },
        {
            label: "Quel est ton besoin du moment ?",
            type: "select.single", // select.single, select.multiple, text, date
            name: "challenge_template_id",
            options: props.challengeTemplates
                ?.filter(x => !x?.hidden)
                ?.filter(x => x?.school_subject_categories?.includes(currentSchoolSubject?.category))
                ?.map(x => {
                    return {icon: x.icon, value: x.id, label: x.title}
                }),
            defaultValue: "",
            validationSchema: yup.string().required()
        },
        {
            label: "Indique le chapitre que tu souhaites travailler",
            is_masked: !currentChallenge?.needs_chapter,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "chapter",
                    placeholder: "Nom du chapitre",
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ]
        },
        {
            label: "Indique le sujet",
            is_masked: !currentChallenge?.needs_domain,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "domain",
                    placeholder: "Sujet",
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ]

        },
        {
            label: "Quand souhaites-tu commencer ce défi ?",
            type: "select.single", // select.single, select.multiple, text, date
            name: "start_date",
            options: [{
                value: moment().format("YYYY-MM-DD"),
                label: "Aujourd'hui"
            }, {
                value: moment().add(1, 'day').format("YYYY-MM-DD"),
                label: "Demain"
            }],
            defaultValue: ""
            // validationSchema: yup.string().required()
        },
        {
            label: "Quelle date limite souhaites-tu fixer pour ce défi ?",
            type: "select.single", // select.single, select.multiple, text, date
            name: "deadline",
            options: dateOptionsInPeriod(2, 30),
            defaultValue: "",
            // initialVisibleIndex: 4
            // validationSchema: yup.string().required()
        },
        {
            label: "Choisis le mode de défi",
            type: "select.single", // select.single, select.multiple, text, date
            name: "planning_mode",
            height: IS_WEB ? null : 70 * 1.7,
            options: props.challengeModes
            // defaultValue: true
        },
        {
            label: "Choisis le niveau d'intensité",
            type: "select.single", // select.single, select.multiple, text, date
            name: "intensity_level",
            options: props.challengeIntensityLevels?.map((x, index) => {

                // console.log(currentChallenge?.xp_thresholds)
                // console.log(currentSchoolSubject)

                return {
                    icon: x?.icon,
                    label: x.label,
                    value: index + 1,
                    description: (planningMode ? "~" : "") + Math.ceil(currentChallenge?.xp_thresholds[currentSchoolSubject?.category][index] * numWeeks / 10) * 10 + " XP"
                }
            }),
            defaultValue: ""
            // validationSchema: yup.string().required()
        }
    ]
    const defaultValues = {}
    const formSchema = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log("PARENT :")
        // console.log(defaultValues)

    }, [formValues])

    useEffect(() => {
        if (props.postChallengeSuccess || props.postChallengeSuccessTutor) {
            if (student_id === null)
                props.apiIdle(challengesService.postChallengeAsStudent)
            else
                props.apiIdle(challengesService.postChallengeAsTutor)
            //console.log(props.postChallengeSuccessDataTutor)
            navigation.replace("congrats", {
                congrats: [
                    {
                        congratsType: congratsTypes.CHALLENGE_LAUNCHED,
                        congratsData: student_id === null ? props.postChallengeSuccessData : props.postChallengeSuccessDataTutor
                    }
                ]
            })
        }

        if (props.postChallengeError) {
            console.log(props.postChallengeError)
        }

    }, [props.postChallengeSuccess, props.postChallengeError, props.postChallengeSuccessTutor])


    const onSubmit = data => {
        if (props.postChallengeRequest || props.postChallengeRequestAsTutor) return
        Keyboard.dismiss()
        // console.log(data)
        if (student_id === null)
            props.apiRequest(challengesService.postChallengeAsStudent, {}, data)
        else {
            props.apiRequest(challengesService.postChallengeAsTutor, {studentId: student_id}, data)
        }

    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={{name: "zoe", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         formSchema={yup.object().shape(formSchema)}
                         onSubmit={onSubmit}
                         submitted={student_id === null ? props.postChallengeRequest : props.postChallengeRequestAsTutor}
                         submittedMessage={formValues?.planning_mode ? "On prépare ton planning d'activités." : "On prépare ton défi."}
                         serverError={props.postChallengeError || props.postChallengeErrorTutor}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        postChallengeRequest: state.api.postChallengeAsStudent?.request,
        postChallengeRequestAsTutor: state.api.postChallengeAsTutor?.request,
        postChallengeSuccess: state.api.postChallengeAsStudent?.success,
        postChallengeSuccessTutor: state.api.postChallengeAsTutor?.success,
        postChallengeError: state.api.postChallengeAsStudent?.error,
        postChallengeErrorTutor: state.api.postChallengeAsTutor?.error,
        postChallengeSuccessData: state.api.postChallengeAsStudent?.data,
        postChallengeSuccessDataTutor: state.api.postChallengeAsTutor?.data,

        challengeTemplates: state.data.staticData?.challenge_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        challengeIntensityLevels: state.data.staticData?.challenge_intensity_levels,
        challengeModes: state.data.staticData?.challenge_modes,
        staticData: state.data.staticData,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewChallengeScreen)