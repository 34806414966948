import {authService} from "@data/services/authService"
import {apiConstants} from "@data/constants/apiConstants"
import {authConstants} from "@data/constants/authConstants";

const initialState = {}

export function session(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_401:
        case authService.logout.name + apiConstants._SUCCESS:
        case authService.revokeSanctumToken.name + apiConstants._SUCCESS:
        case authService.deleteAccount.name + apiConstants._SUCCESS:
            return {}

        case authService.registerV2.name + apiConstants._SUCCESS:
        case authService.registerTrialV2.name + apiConstants._SUCCESS:
        case authService.registerSpecialV2.name + apiConstants._SUCCESS:
        case authService.registerWorkshopV2.name + apiConstants._SUCCESS:
        case authService.registerAndGetSanctumToken.name + apiConstants._SUCCESS:
        case authService.getSanctumToken.name + apiConstants._SUCCESS:

            // console.log(action)

            return {
                // object: user
                object: {
                    token: action.data.token
                }
            }

        case authService.login.name + apiConstants._SUCCESS:

            // console.log(action)

            return {
                // object: user
                object: {
                    token: true
                }
            }

        case authService.subscribeToNotifications.name + apiConstants._SUCCESS:

            // console.log(action)

            return {
                object: {
                    ...state.object,
                    expo_token: action.all_data.expo_token
                }
            }

        case authService.unsubscribeFromNotifications.name + apiConstants._SUCCESS:

            return {
                object: {
                    ...state.object,
                    expo_token: null
                }
            }

        default:
            return state
    }
}
