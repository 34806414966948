import { useNavigation } from "@react-navigation/native";
import { Dimensions, TouchableOpacity, Vibration, View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import { theme } from "@common/theme/theme";
import AvatarM from "@common/components/avatar/AvatarM";
import TextM from "@common/components/text/TextM";
import {
  SkillSetTitleFull,
  XPCount,
} from "@common/components/statistics/Counters";
import React, { useContext, useRef, useState } from "react";
import { IconTouchableRipple } from "./IconTouchableRipple";
import { Menu3Point } from "@common/components/menu/Menu3Point";
import { schoolsService } from "@data/services/schoolsService";
import { useApiStatus } from "@common/hooks/useApiStatus";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import LiveAvatarM from "./LiveAvatarM";
import { findItemInListWithId } from "@data/utility/dataUtils";
import { RealizationsListItemContent } from "../../student/organize/list/RealizationsListItemContent";
import TodoModal from "../../student/activity/todo/TodoModal";
import { DialogM } from "@common/components/alert/dialog/Dialog";
import { activitiesService } from "@data/services/activitiesService";
import { FlatList } from "react-native-gesture-handler";
import { hasRole } from "@data/utility/ability";
import DraggableFlatList from "react-native-draggable-flatlist";
import moment from "moment";
import { boxedCardBorderStyle } from "../../reviewer/BoxedCardBorderStyle";
import { setTodoActivities } from "@data/redux/actions/data";
import { Checked } from "@common/components/icons/IconsM";
import { LinearGradient } from "expo-linear-gradient";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { formatMinutes } from "@data/utility/Utils";
import { useNewTodo } from "@common/messenger/socketHook";
import { SchoolSubjectBadge } from "./ProfilDetails";

export const calculateSumOfEstimatedTime = (dataArray, attribute) => {
  return dataArray?.reduce((sum, obj) => sum + obj[attribute], 0);
};

function StudentsListItem(props) {
  const {
    student,
    index,
    period,
    menuItems = [],
    isLoading,
    isLiveMode = false,
    additionalActions = [],
    style,
    isTutor = false,
    withTodo = false,
    soundMode = false,
  } = props;
  const navigation = useNavigation();
  const liveSession = props.currentLiveSession;
  const dimensions = useContext(DimensionsContext);
  const width = withTodo
    ? Dimensions.get("window").width - 30
    : dimensions.width - 20;
  const activityFinalizedCount =
    liveSession && props.isLive
      ? liveSession?.users?.find((u) => u.id === student.id)?.statistics?.today
          ?.activities?.count
      : student?.statistics?.today?.activities?.count;
  const isTutorsStudent =
    !!props.user?.students?.find((x) => x?.id === student?.id) ||
    liveSession?.users?.find((u) => u.id === student.id);
  const isOnline = !!props.participants?.find((x) => x === student?.id);
  const draggableFlatListRef = useRef();
  const flatListRef = useRef();
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const handleShowModal = () => setOpen(!isOpen);
  const todos = student?.todo_activities;

  const totalEstimatedTime = calculateSumOfEstimatedTime(
    todos,
    "estimated_duration"
  );
  const finishedActivities = student?.activities?.filter((activity) =>
    moment(activity.finished_at).isSame(moment(), "day")
  );

  const [deleteOrIndexesRequest, setDeleteOrIndexesRequest] = useState(false);

  // useEffect(() => {
  //     // console.log("CHANGE")
  //     setTodos(student?.todo_activities)
  //     setFinshedActivities(student?.activities?.filter((activity) => moment(activity.finished_at).isSame(moment(), 'day')))
  // }, [student?.todo_activities, student?.activities])
  const onDeleteTodo = (todoId) => {
    DialogM.show({
      text1: "Veux-tu vraiment supprimer cette activité ?",
      buttonText1: "Supprimer",
      buttonColor1: theme.colors.error,
      onPress: () => {
        setDeleteOrIndexesRequest(true);
        props.apiRequest(activitiesService.deleteActivityTodo, { id: todoId });
      },
      buttonText2: "Annuler ",
    });
  };

  const newTodoEvent = useNewTodo({
    channel: `presence-messenger.thread.${liveSession?.thread_id}`,
    id: student?.id,
  });

  useApiStatus(
    activitiesService.deleteActivityTodo, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (data) => {
      if (deleteOrIndexesRequest) {
        newTodoEvent();
        setDeleteOrIndexesRequest(false);
      }
    } // success callback
  );

  useApiStatus(
    activitiesService.activityTodoIndexesAsTutor, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (data) => {
      if (deleteOrIndexesRequest) {
        newTodoEvent();
        setDeleteOrIndexesRequest(false);
      }
    } // success callback
  );

  const windowWidth = Dimensions.get("window").width;
  const renderActionButtons = () => {
    return (
      <>
        {/*<IconTouchableRipple*/}
        {/*    icon={"id-badge"}*/}
        {/*    label={"Profil"}*/}
        {/*    onPress={() => {*/}
        {/*        */}
        {/*    }}/>*/}
        {!withTodo && (
          <>
            <IconTouchableRipple
              icon={"bullseye"}
              label={"Activités"}
              badgeCounter={activityFinalizedCount}
              onPress={() => {
                navigation.push("student", {
                  studentId: student?.id,
                });
              }}
            />
            {!IS_WEB && (
              <IconTouchableRipple
                icon={"file-medical-alt"}
                label={"Rapports Live"}
                badgeCounter={student?.live_reports?.length}
                onPress={() => {
                  navigation.push("live-reports", {
                    studentId: student?.id,
                  });
                }}
              />
            )}
            {/*<IconTouchableRipple*/}
            {/*    icon={"file-video"}*/}
            {/*    label={"Entretiens"}*/}
            {/*    onPress={() => {*/}
            {/*        navigation.push("interviewHistory", {*/}
            {/*            studentId: student?.id,*/}
            {/*            user: "isTutor"*/}
            {/*        });*/}
            {/*    }}/>*/}
            {/*<IconTouchableRipple*/}
            {/*    icon={"sticky-note"}*/}
            {/*    label={"Notes"}*/}
            {/*    // badgeCounter={isLiveMode && showBadge && !props.getNotesRequest ? 1 : 0}*/}
            {/*    onPress={() => {*/}
            {/*        navigation.push("student-notes", {*/}
            {/*            studentId: student?.id*/}
            {/*        });*/}
            {/*    }}/>*/}
          </>
        )}
        <IconTouchableRipple
          icon={"comment"}
          label={"Messages"}
          badgeCounter={
            props.isLive
              ? props.currentLiveSession.unread_private_messages_count[
                  student.id
                ]
              : student?.student_messenger_not_read_count
          }
          disabled={!isTutorsStudent}
          color={isTutorsStudent ? theme.colors.light : theme.colors.lightMore}
          onPress={() => {
            if (props.isLiveMode) {
              navigation.navigate("messages", {
                userId: student?.id,
              });
            } else {
              navigation.navigate("messenger", {
                studentId: student?.id,
              });
            }
                    }}/>
                {!isTutor && !props.isLiveMode && IS_WEB && <>
            <IconTouchableRipple
              icon={"file-medical-alt"}
              label={"Rapports Live"}
              badgeCounter={student?.live_reports?.length}
              disabled={!isTutorsStudent}
              color={
                isTutorsStudent ? theme.colors.light : theme.colors.lightMore
              }
              onPress={() => {
                navigation.push("live-reports", {
                  studentId: student.id,
                });
              }}
            />
          </>
        }
        {additionalActions?.map((a, index) =>
          a?.request ? (
            a?.requestComponent ? (
              <React.Fragment key={index}>{a?.requestComponent}</React.Fragment>
            ) : (
              <ActivityIndicator
                key={index}
                color={theme.colors.primary}
                style={{ flex: 1 }}
              />
            )
          ) : (
            <IconTouchableRipple
              key={index}
              icon={a?.icon}
              iconSize={a?.iconSize}
              label={a?.label}
              badgeCounter={a?.badgeCounter}
              disabled={a?.disabled}
              color={a?.color}
              style={{
                backgroundColor: a?.backgroundColor,
              }}
              onPress={a?.onPress}
            />
          )
        )}
      </>
    );
  };

  useApiStatus(
    activitiesService.deleteActivitiesTodoAsTutor,
    null,
    true,
    () => {
      newTodoEvent();
    }
  );

  useApiStatus(
    schoolsService.removeStudentFromClassroom,
    "L'élève a bien été retiré de la classe",
    true
  );

  const renderItem = ({ item, drag, isActive }) => {
    const userChallenges = student?.challenges?.concat(
      student?.history?.challenges
    );
    const challenge = item?.challenge_id
      ? findItemInListWithId(item?.challenge_id, userChallenges)
      : null;
    const schoolSubject = findItemInListWithId(
      item?.school_subject_id,
      props.schoolSubjects
    );
    const schoolSubjectColor = challenge
      ? challenge?.school_subject?.color
      : schoolSubject?.color === ""
      ? theme.colors.info
      : schoolSubject?.color;
    const status =
      item?.locked_at !== null ||
      (props.updatedActivityEventData?.locked_at !== null &&
        props.updatedActivityEventData?.activity_id === item?.id)
        ? "En cours"
        : item?.started_at !== null
        ? "Commencée"
        : null;
    const activityTemplate = findItemInListWithId(
      item?.activity_template_id,
      props.activityTemplates
    );
    const formData = activityTemplate?.steps;
    const activityType = formData?.find((step) => step.type === "photo")
      ? "photo"
      : "audio";

    return (
      <TouchableOpacity
        onPress={() => {
          setSelectedItem(item);
          if (!!status) {
            navigation.push("activity-result", {
              activityIds: [item?.id],
              studentId: student?.id,
            });
          } else handleShowModal();
        }}
        // onLongPress={drag}
        // delayLongPress={300}
        disabled={isActive}
        style={{
          backgroundColor: theme.colors.white,
          borderRadius: 10,
          borderWidth: 1,
          borderColor: schoolSubjectColor,
          marginRight: 10,
          minWidth: 160,
        }}
      >
        <>
          <RealizationsListItemContent
            activity={item}
            dragButton={drag}
            challenge={challenge}
            schoolSubject={findItemInListWithId(
              item?.school_subject_id,
              props.schoolSubjects
            )}
            noBorder={true}
            currentUser={props.user}
            noClass={props.user?.id === item?.student_id}
            todo={true}
            updatedActivityEventData={props.updatedActivityEventData}
            activityType={activityType}
            liveMode
          />
          {props.deleteTodoRequest &&
            props.deleteTodoRequestActivityId === item?.id && (
              <ActivityIndicator
                animating={true}
                color={theme.colors.primary}
                size={14}
                style={{
                  position: "absolute",
                  top: -2,
                  right: -2,
                }}
              />
            )}
        </>
      </TouchableOpacity>
    );
  };
  return (
    <View
      key={index}
      style={{
        width,
        marginBottom: 10,
        backgroundColor: theme.colors.white,
        borderRightWidth: soundMode ? 2 : 1,
        borderLeftWidth: soundMode ? 2 : 1,
        borderTopWidth: soundMode ? 2 : 1,
        borderBottomWidth: soundMode ? 2 : 1,
        borderColor: !isOnline
          ? theme.colors.lightMore
          : !soundMode && !isTutor
          ? theme.colors.purple
          : theme.colors.success,
        alignSelf: "center",
        ...boxedCardBorderStyle,
        ...style,
      }}
    >
      <>
        <View
          style={{
            width,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            // paddingVertical: 12,
            paddingHorizontal: 10,
          }}
        >
          <TouchableOpacity
            style={{
              // flexDirection: "row",
              // alignItems: "center",
              // justifyContent: "flex-start",
              paddingVertical: 12,
              flex: 1,
            }}
            onPress={() => {
              if (!isTutor)
                navigation.push("student-profil", { studentId: student?.id });
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                flex: 1,
              }}
            >
              {isLiveMode ? (
                <LiveAvatarM user={student} />
              ) : (
                <AvatarM user={student} style={{ marginRight: 2 }} size={50} />
              )}

              <View
                style={{
                  marginLeft: 5,
                  flex: 1,
                }}
              >
                <TextM
                  fontWeight="SemiBold"
                  fontSize={16}
                  wrap
                  color={theme.colors.primary}
                >
                  {student?.display_name}
                </TextM>
                <TextM fontWeight="Medium" style={{}} fontSize={12}>
                  {student?.profile?.class}
                </TextM>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  {student?.prioritized_school_subjects?.map((item) => {
                    return (
                      <SchoolSubjectBadge key={item?.id} schoolSubject={item} />
                    );
                  })}
                </View>
              </View>
            </View>
            {todos?.length > 0 && (
              <LinearGradient
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  //height: 120,
                  paddingVertical: 4,
                  paddingHorizontal: 8,
                  borderRadius: 10,
                  maxWidth: 100,
                  marginTop: 5,
                }}
                colors={[theme.colors.primary, theme.colors.primary]}
              >
                <FontAwesome5
                  name={"stream"}
                  solid
                  style={{ marginRight: 6 }}
                  color={theme.colors.white}
                  size={14}
                />
                <TextM
                  fontWeight="SemiBold"
                  fontSize={12}
                  wrap
                  color={theme.colors.white}
                  style={{}}
                >
                  {formatMinutes(totalEstimatedTime)}
                </TextM>
              </LinearGradient>
            )}
          </TouchableOpacity>

          <View
            style={{
              alignItems: "flex-end",
              justifyContent: "center",
              flex: 1,
              // marginRight: 10
            }}
          >
            {menuItems?.length > 0 ? (
              !isLoading ? (
                <Menu3Point menuItems={menuItems} />
              ) : (
                <ActivityIndicator color={theme.colors.primary} />
              )
            ) : (
              <></>
            )}
            {withTodo && !isTutor ? (
              <>
                <DraggableFlatList
                  horizontal={true}
                  data={todos ?? []}
                  onDragBegin={() => Vibration.vibrate(50)}
                  onDragEnd={({ data }) => {
                    props.setTodoActivities(data);
                    setDeleteOrIndexesRequest(true);
                    if (hasRole(props.user, "student"))
                      props.apiRequest(
                        activitiesService.activityTodoIndexesAsStudent,
                        {},
                        {
                          todo_activities_ids: data.map((todo) => todo.id),
                        }
                      );
                    else
                      props.apiRequest(
                        activitiesService.activityTodoIndexesAsTutor,
                        { id: student?.id },
                        {
                          todo_activities_ids: data.map((todo) => todo.id),
                        }
                      );
                    Vibration.vibrate(50);
                  }}
                  keyExtractor={(item) => item?.id}
                  contentContainerStyle={{
                    alignItems: "stretch",
                    paddingVertical: 16,
                  }}
                  style={{
                    width:
                      dimensions.width < 800
                        ? dimensions.width / 2
                        : dimensions.width / 1.2,
                  }}
                  ref={draggableFlatListRef}
                  ListEmptyComponent={
                    <>
                      <TextM
                        style={{
                          textAlign: "center",
                          marginLeft: 10,
                          alignSelf: "center",
                        }}
                      >
                        Aucune activité à faire
                      </TextM>
                    </>
                  }
                  ListHeaderComponent={
                    <>
                      <FlatList
                        horizontal={true}
                        data={finishedActivities}
                        containerStyle={{}}
                        style={{}}
                        ref={flatListRef}
                        renderItem={(item) => {
                          const userChallenges = student?.challenges?.concat(
                            student?.history?.challenges
                          );
                          const challenge = item.item?.challenge_id
                            ? findItemInListWithId(
                                item.item?.challenge_id,
                                userChallenges
                              )
                            : null;
                          const schoolSubject = findItemInListWithId(
                            item.item?.school_subject_id,
                            props.schoolSubjects
                          );
                          const schoolSubjectColor = challenge
                            ? challenge?.school_subject?.color
                            : schoolSubject?.color === ""
                            ? theme.colors.info
                            : schoolSubject?.color;

                          return (
                            <TouchableOpacity
                              style={{
                                marginRight: 10,
                              }}
                              onPress={() => {
                                navigation.push("activity-result", {
                                  activityIds: [item.item?.id],
                                  studentId: student?.id,
                                });
                              }}
                            >
                              <RealizationsListItemContent
                                activity={item.item}
                                challenge={challenge}
                                schoolSubject={findItemInListWithId(
                                  item?.item?.school_subject_id,
                                  props.schoolSubjects
                                )}
                                noClass={true}
                                currentUser={props.user}
                                updatedActivityEventData={
                                  props.updatedActivityEventData
                                }
                                style={{
                                  opacity: 0.5,
                                  minWidth: 160,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  borderColor: schoolSubjectColor,
                                }}
                                liveMode
                              />
                              <Checked
                                size={24}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              />
                            </TouchableOpacity>
                          );
                        }}
                      />
                    </>
                  }
                  ListFooterComponent={
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <IconTouchableRipple
                        icon={"plus"}
                        iconSize={26}
                        style={{
                          marginLeft: 30,
                          paddingHorizontal: 10,
                        }}
                        label={"Ajouter"}
                        onPress={() => {
                          if (todos.length < props.maxTodoActivities)
                            navigation.push("new-todo", {
                              studentId: student?.id,
                            });
                          else
                            DialogM.show({
                              champion: "zoe",
                              text1: `Tu ne peux pas dépasser ${props.maxTodoActivities} activités dans ta liste`,
                              text2:
                                "C’est l’heure de faire un peu de ménage ! Supprime les activités qui ne sont plus utiles.",
                            });
                        }}
                      />
                      {todos.length > 0 &&
                        (props.deleteActivitiesTodoAsTutorRequest ? (
                          <ActivityIndicator
                            color={theme.colors.primary}
                            size={"small"}
                          />
                        ) : (
                          <IconTouchableRipple
                            icon={"trash"}
                            iconSize={26}
                            style={{
                              marginLeft: 30,
                              marginRight: 30,
                              paddingHorizontal: 10,
                            }}
                            label={"Nettoyer"}
                            onPress={() => {
                              DialogM.show({
                                champion: "zoe",
                                text1: `Supprimer toutes les activités de la liste de ${student?.display_name} ?`,
                                text2: "Cette action est irréversible",
                                buttonText1: "Supprimer tout",
                                onPress: () => {
                                  props.apiRequest(
                                    activitiesService.deleteActivitiesTodoAsTutor,
                                    { id: student?.id }
                                  );
                                },
                                buttonText2: "Annuler",
                              });
                            }}
                          />
                        ))}
                    </View>
                  }
                  renderItem={renderItem}
                />
              </>
            ) : (
              !isTutor && (
                <View style={{ marginRight: 8 }}>
                  <SkillSetTitleFull
                    title={student?.student_title}
                    icon={student?.student_title_icon}
                    skillSetId={student?.title_skill_set_id}
                    right
                    small
                  />
                  <XPCount
                    count={student?.xp}
                    right
                    style={{ marginRight: 3 }}
                    small
                  />
                </View>
              )
            )}
          </View>
          {!isTutor && windowWidth > 800 && (
            <View
              style={{
                borderLeftWidth: 1,
                borderColor: theme.colors.lightMore,
                flex: withTodo ? 0.9 : 3,
                height: "100%",
                flexDirection: "row",
                justifyContent: "space-around",
                // flexWrap: "wrap",
              }}
            >
              {renderActionButtons()}
            </View>
          )}
          {withTodo && !isTutor && (
            <Menu3Point
              menuItems={[
                {
                  title: "Activités",
                  onPress: () => {
                    navigation.push("student", {
                      studentId: student?.id,
                      initialTabRealizations: true,
                    });
                  },
                },
                {
                  title: "Rapports",
                  onPress: () => {
                    navigation.push("live-reports", {
                      studentId: student?.id,
                    });
                  },
                },
                {
                  title: "Messages",
                  onPress: () => {
                    if (props.isLiveMode) {
                      navigation.navigate("messages", {
                        userId: student?.id,
                      });
                    } else {
                      navigation.navigate("messenger", {
                        studentId: student?.id,
                      });
                    }
                  },
                },
                // {
                //     title: "Entretiens", onPress: () => {
                //         navigation.push("interviewHistory", {
                //             studentId: student?.id,
                //             user: "isTutor"
                //         });
                //     }
                // },
                // {
                //     title: "Notes", onPress: () => {
                //         navigation.push("student-notes", {
                //             studentId: student?.id
                //         });
                //     }
                // }
              ]}
            />
          )}
        </View>
        {!isTutor && windowWidth <= 800 && (
          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: theme.colors.lightMore,
              // flex: 1,
              width,
              flexDirection: "row",
              justifyContent: "space-around",
              // flexWrap: "wrap",
            }}
          >
            {renderActionButtons()}
          </View>
        )}
      </>
      {isOpen && (
        <TodoModal
          isOpen={isOpen}
          handleShowModal={handleShowModal}
          item={selectedItem}
          navigation={navigation}
          onDeleteTodo={onDeleteTodo}
          studentId={student?.id}
          schoolSubject={findItemInListWithId(
            selectedItem?.school_subject_id,
            props.schoolSubjects
          )}
        />
      )}
    </View>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    sessions: state.data.currentUser?.object?.sessions,
    participants: state.liveMode?.liveParticipants,
    inCallUsers: state.liveMode?.inCallUsers,
    schoolSubjects: state.data.staticData?.school_subjects,
    getSessionsRequest: state.api.getLiveSession?.request,
    isLive: state.liveMode.isLiveModeEnabled,
    notes: state.data.tutors?.notes,
    deleteTodoRequest: state.api.deleteActivityTodo?.request,
    getNotesRequest: state.api.getNotes?.request,
    deleteActivitiesTodoAsTutorRequest:
      state.api.deleteActivitiesTodoAsTutor?.request,
    deleteTodoRequestActivityId: state.api.deleteActivityTodo?.routeParams?.id,
    maxTodoActivities: state.data.staticData?.max_todo_activities,
    updatedActivityEventData: state.activity?.updatedActivityEventData,
    activityTemplates: state.data.staticData?.activity_templates,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setTodoActivities,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsListItem);
