import {View} from "react-native";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React from "react";
import {Xp} from "../icons/IconsM";

export const KeyValueItem = props => {

    const {
        keyText,
        valueComponent,
        valueText,
        isXP,
        style,
        large,
        columnMode,
        warningMode,
        noBorder,
        textColor = theme.colors.grey
    } = props

    const fontSize = large ? 18 : 14
    const fontSizeText = large ? 18 : 16
    const padding = large ? 20 : 14
    const color = warningMode ? theme.colors.warning : theme.colors.lightMore
    const bgColor = warningMode ? theme.colors.warningLight : theme.colors.white
    const titleColor = warningMode ? theme.colors.warning : theme.colors.light

    return <View style={{
        padding: padding,
        backgroundColor: bgColor,
        flexDirection: columnMode ? "column" : "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderWidth: noBorder ? 0 : 1,
        borderColor: color,
        borderRadius: 14,
        width: "100%",
        marginHorizontal: noBorder ? 0 : 10,
        ...style
    }}>
        <TextM fontSize={fontSize}
               fontWeight="Bold"
               color={titleColor}>{keyText?.toUpperCase()}</TextM>
        <View style={{
            // flex: 1,
            flexDirection: "row",
            alignItems: "center",
            marginTop: columnMode ? 5 : 0
        }}>
            {!!valueText && <TextM fontSize={fontSizeText}
                                   fontWeight={columnMode ? "Medium" : "Bold"}
                                   wrap
                                   style={{
                                       marginRight: columnMode ? 0 : 2,
                                       textAlign: columnMode ? "center" : "justify",
                                       width: columnMode ? "100%" : null
                                   }}
                                   color={isXP ? theme.colors.accentDark : textColor}>{valueText === "" ? "-" : valueText}</TextM>}
            {isXP && <Xp size={14}/>}
            {!!valueComponent && valueComponent}
        </View>
    </View>
}