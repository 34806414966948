import React, {useContext, useEffect, useState} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {View, TouchableOpacity, Platform, ScrollView} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import DataTableTitleM from "@common/components/dataTable/DataTableTitleM";
import {theme} from "@common/theme/theme";
import {interviewReportService} from "@data/services/interviewReportService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {ActivityIndicator, Divider} from "react-native-paper";
import DataTableCellM from "@common/components/dataTable/DataTableCellM";
import TextM from "@common/components/text/TextM";
import {capitalize} from "@data/utility/Utils";
import moment, {now} from "moment";
import AddResultsComponent from "./AddResultsComponent";
import IconButtonM from "@common/components/button/IconButtonM";
import {gradesService} from "@data/services/gradesService";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import FilledButtonM from "@common/components/button/FilledButtonM";
import SelectBox, {SelectModalM} from "@common/components/select/selectSingle-Multiple";
import {xorBy} from "lodash";
import {Controller, useForm} from "react-hook-form";
import {findItemInListWithId} from "@data/utility/dataUtils";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {IconImageM} from "@common/components/icons/IconsM";
import {SchoolSubjectBadge} from "../../tutor/students/ProfilDetails";

const SchoolResultsStep = (props) => {
    const {studentId, control, goToNextStep, register, errors, serverError, setFormValues, field} = props
    const dimensions = useContext(DimensionsContext)

    const titles = ["Matières prioritaires", "Derniers résultats", "Ajouter un résultat"]

    const [modalVisible, setModalVisible] = useState(false);

    const {control: prioritizedControl, handleSubmit, formState: {prioritizedErrors, dirtyFields}, trigger, setValue} = useForm({
        defaultValues : {
            prioritized_school_subjects: []
        }
    })

    const onsubmit = (data) => {
        // console.log(data)

        if (data?.prioritized_school_subjects?.length === 0) return

        props.apiRequest(interviewReportService.addPrioritizedSchoolSubjects, {studentId: studentId}, {
            prioritized_school_subjects: data?.prioritized_school_subjects?.map((x) => {
                return x?.value
            })
        })
    }

    useEffect(() => {
        // console.log(props.prioritizedSchoolSubjects)
        props.apiRequest(interviewReportService.getPrioritizedSchoolSubjects, {studentId: studentId})
    }, []);

    useApiStatus(
        interviewReportService.getPrioritizedSchoolSubjects, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data)
            // setPrioritizedSchoolSubjects(data)
        } // success callback
    )

    useEffect(() => {
        field.onChange(props.prioritizedSchoolSubjects)
        setFormValues({
            ...control._formValues,
            [field?.name]: props.prioritizedSchoolSubjects
        })
    }, [props.prioritizedSchoolSubjects]);

    useApiStatus(
        gradesService.deleteGrade, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            DialogM.show({
                text1: "Succès",
                text2: data.message
            })
        } // success callback
    )

    useApiStatus(
        gradesService.updateGrade, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
            DialogM.hide()
        }// success callback
    )

    useApiStatus(
        interviewReportService.addPrioritizedSchoolSubjects, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
        }// success callback
    )

    useEffect(() => {
        if (props.addPrioritizedSchoolSubjectsError) {
            DialogM.show({
                text1: "Erreur",
                text2: props.addPrioritizedSchoolSubjectsError?.message
            })
        }
    }, [props.addPrioritizedSchoolSubjectsError]);

    return <>
        <ScrollView
            style={{
                // marginBottom: 120,
                // paddingHorizontal: 100,
                width: dimensions.width,
                maxHeight: dimensions.height - 300,
                marginBottom: dimensions?.width < 800 ? 50 : null,
                // backgroundColor: "red"
            }}
            contentContainerStyle={{
                // maxHeight: dimensions.height - 300,
            }}
        >
            <View style={{
                flexDirection: "row",
                width: dimensions.width,
                // backgroundColor: "red"
            }}>
                {titles.map((title) => <DataTableTitleM style={{
                    backgroundColor: theme.colors.white,
                    borderRadius: 5,
                    height: 30,
                    flex: 1,
                    // alignItems: "center",
                    marginHorizontal: 5,
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderLeftWidth: 1,
                    borderColor: theme.colors.primary
                }}
                                                        fontWeight={"Bold"}
                                                        textStyle={{
                                                            // textAlign: "center",
                                                            alignSelf: "center"
                                                        }}
                                                        color={theme.colors.primary}
                >{title}</DataTableTitleM>)}

            </View>

            {props.getPrioritizedSchoolSubjectsRequest ? <ActivityIndicator animating={true}
                                                                            style={{
                                                                                marginTop: 50
                                                                            }}
                    // size="large"
                                                                            color={theme.colors.primary}/>
                : props.prioritizedSchoolSubjects.length === 0 ?
                    <TextM style={{marginTop: 10, alignSelf: "center"}} color={theme.colors.black}>Aucune
                        matière</TextM>
                    : props.prioritizedSchoolSubjects.map((schoolSubject, key) => <>
                        <View style={{
                            flexDirection: "row",
                            width: dimensions.width,
                            alignItems: "flex-start",
                            // backgroundColor: "red"
                        }}>
                            <View style={{
                                flexDirection: "row",
                                alignItems: "center",
                                flex: 1,
                                marginRight: 30,
                                // backgroundColor: "red"
                            }}>
                                <DataTableCellM style={{
                                    // maxHeight: 200,
                                    // marginHorizontal: 5
                                    // flexDirection: "row",
                                }} fontWeight={"Medium"} color={theme.colors.black}>
                                    <SchoolSubjectBadge schoolSubject={schoolSubject} style={{alignSelf: "center"}}/>
                                    {/*<TextM style={{marginLeft: 10}} color={theme.colors.black}>{schoolSubject?.name}</TextM>*/}
                                </DataTableCellM>
                                {props.removePrioritizedSchoolSubjectsRequest && props.removePrioritizedRequestSchoolSubjectId === schoolSubject?.id ?
                                    <ActivityIndicator color={theme.colors.primary}/> :
                                    <IconButtonM icon={"trash"}
                                                 iconColor={theme.colors.primary}
                                                 style={{}}
                                                 onPress={() => {
                                                     props.apiRequest(interviewReportService.removePrioritizedSchoolSubject, {studentId: studentId}, {
                                                            school_subject_id: schoolSubject?.id
                                                     })
                                                 }}
                                                 size={14}
                                    />}
                            </View>

                            <View style={{
                                flex: 1,
                                // maxHeight: 200,
                                marginHorizontal: 5,
                                flexDirection: "column",
                                // backgroundColor: "red"
                            }}>{schoolSubject?.last_grades?.length === 0 ?
                                <TextM style={{marginTop: 10}} color={theme.colors.black}>Aucun résultat</TextM>
                                : schoolSubject?.last_grades?.map((grade, key) => <View key={key} style={{
                                    flexDirection: "row",
                                    marginVertical: 5,
                                    // backgroundColor: "black",
                                    alignItems: "center"
                                }}>
                                    <View style={{
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                    <TextM style={{marginRight: 10}} color={theme.colors.black}
                                           fontWeight={"Medium"}>{capitalize(grade?.value?.toString() + "/" + grade?.total?.toString())}</TextM>
                                    <TextM style={{}} color={theme.colors.black} fontSize={12}>{capitalize(moment(grade?.date).format("DD MMMM"))}</TextM>
                                    </View>
                                    <IconButtonM icon={"edit"}
                                                 iconColor={theme.colors.primary}
                                                 style={{
                                                     marginLeft: 10
                                                 }}
                                                 onPress={() => {
                                                     DialogM.show({
                                                         text1: "Modifier la note",
                                                         component: <AddResultsComponent studentId={studentId}
                                                                                         schoolSubjectId={schoolSubject?.id}
                                                                                         key={key}
                                                                                         grade={grade}
                                                                                         style={{width: "100%"}}
                                                                                         requestApiService={gradesService.updateGrade}
                                                                                         requestRouteParams={{id: grade?.id}}
                                                         />,
                                                         buttonText1: "Annuler",
                                                     })
                                                 }}
                                                 size={14}
                                    />
                                    {props.deleteGradeRequest && props.deleteGradeRequestGradeId === grade?.id ?
                                        <ActivityIndicator color={theme.colors.primary}/> :
                                        <IconButtonM icon={"trash"}
                                                     iconColor={theme.colors.primary}
                                                     style={{}}
                                                     onPress={() => {
                                                         props.apiRequest(gradesService.deleteGrade, {id: grade?.id})
                                                     }}
                                                     size={14}
                                        />}
                                </View>)}
                            </View>
                            <DataTableCellM style={{
                                flex: 1,
                                // maxHeight: 200,
                                marginHorizontal: 5,
                                // backgroundColor: "red"
                            }}><AddResultsComponent studentId={studentId} schoolSubjectId={schoolSubject?.id}
                                                    key={key}
                                                    requestApiService={gradesService.newGrade}
                                                    requestRouteParams={{id: studentId}}
                            />
                            </DataTableCellM>
                        </View>
                        <Divider style={{
                            width: dimensions?.width < 800 ? "100%" : dimensions?.width,
                            height: dimensions?.width < 800 ? 1 : 1.5,
                        }}/>
                    </>)}
        </ScrollView>

        <FilledButtonM icon={"plus"}
                       iconColor={theme.colors.white}
                       style={{
                           // marginTop: 20,
                       }}
                       onPress={() => {
                           setModalVisible(true)
                       }}
                       mode={"contained"}
                       label={"Ajouter une matière"}
                       color={theme.colors.primary}
                       size={14}
        />

        <Controller
            control={prioritizedControl}
            // rules={validation.required()}
            name="prioritized_school_subjects"
            render={({field: {onChange, value}}) => {
                return (
                    <SelectModalM isMulti
                                  modalVisible={modalVisible}
                                  setModalVisible={setModalVisible}
                                  title={"Matières prioritaires"}
                                  options={props.schoolSubjects.map((x) => {
                                      return {value: x.id.toString(), label: x.name, icon: x?.icon, disabled: !!findItemInListWithId(x?.id, props.prioritizedSchoolSubjects)};
                                  })}
                                  selectedValues={value}
                                  onMultiSelect={(item) => {
                                      // console.log(item)
                                      onChange([item]);
                                  }}
                                  onFinishSelecting={handleSubmit(onsubmit)}
                    />
                );
            }}
        />



    </>
};

const mapStateToProps = state => {
    return {
        getPrioritizedSchoolSubjectsRequest: state.api?.getPrioritizedSchoolSubjects?.request,
        prioritizedSchoolSubjects: state.data?.interviewReports?.prioritizedSchoolSubjects,
        deleteGradeRequest: state.api?.deleteGrade?.request,
        deleteGradeRequestGradeId: state.api.deleteGrade?.routeParams?.id,
        schoolSubjects: state.data?.staticData?.school_subjects,
        addPrioritizedSchoolSubjectsError: state.api?.addPrioritizedSchoolSubjects?.error,
        removePrioritizedSchoolSubjectsRequest: state.api?.removePrioritizedSchoolSubject?.request,
        removePrioritizedRequestSchoolSubjectId: state.api?.removePrioritizedSchoolSubject?.data?.school_subject_id,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SchoolResultsStep)
