import React, {forwardRef, useCallback, useImperativeHandle, useRef} from "react";
import {usePlaySound} from "@common/components/playSound/usePlaySound";
import {PlaySound} from "@common/sound/PlaySound";


let currentRef = null
export const PlaySoundComponent = forwardRef((props, ref) => {

    const playSoundRef = useRef()

    useImperativeHandle(
        ref,
        useCallback(
            () => ({
                play: playSoundRef?.current?.play,
            }),
            []
        )
    );
    return <PlaySound ref={playSoundRef} />;

})

export function PlaySoundM(props) {
    const playSoundRef = useRef(null);

    const setRef = useCallback((ref) => {
        if (ref) {
            playSoundRef.current = ref;
            currentRef = ref;
        }
    }, []);

    return (
        <PlaySoundComponent ref={setRef} {...props} />
    );
}

PlaySoundM.play = (params) => {
    return currentRef?.play(params);
};