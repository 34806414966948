import {Platform} from "react-native";
import React, {useContext} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {Tabs, TabScreen} from "react-native-paper-tabs";
import {theme} from "@common/theme/theme";
import BrowseByChallenge from "./BrowseByChallenge";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import AllActivities from "../../../student/activity/AllActivities";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {IconButton} from "react-native-paper";

function BrowseActivities() {

    const navigation = useNavigation()
    const tabs = [
        {
            name: "Toutes les activités",
            component: AllActivities
        },
        {
            name: "Par défi",
            component: BrowseByChallenge
        }
    ]
    const dimension = useContext(DimensionsContext)
    return (<>
        <HeaderContainerM noBorder>
            <TitleM>Référentiel des activités</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>
        <Tabs
            defaultIndex={0}
            style={{
                backgroundColor: theme.colors.white,
                width: Platform.OS === 'web' ? "100%" : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
            disableSwipe={true}
        >
            {tabs.map((tab1) => {
                return (
                    <TabScreen
                        label={tab1.name}
                        key={tab1.name}
                    >
                        {React.createElement(tab1.component, {noHeader: true, browseMode: true})}
                    </TabScreen>
                );
            })}
        </Tabs>
    </>)
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        shopProducts: state.data.staticData?.shop_products
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(BrowseActivities)