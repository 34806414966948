import axios from "axios"


function postChallengeAsStudent(routeParams, formData) {
    return axios.post(`api/challenges`, formData)
}

function abandonChallenge({id}) {
    return axios.post(`/api/challenges/${id}/abandon`)
}

function getChallengeHistory({}, {}, {page}) {
    return axios.get(`api/challenge-history`, {
        params: {
            page
        }
    })
}
function postChallengeAsTutor({studentId}, formData) {
    return axios.post(`api/students/${studentId}/challenges`, formData)
}

function getStudentChallengeHistory({id}, {}, {page}) {
    return axios.get(`api/students/${id}/challenge-history`, {
        params: {
            page
        }
    })
}

function abandonChallengeAsTutor({studentId, challengeId}, data) {
    return axios.post(`/api/students/${studentId}/challenges/${challengeId}/abandon`, data)
}

export const challengesService = {
    postChallengeAsStudent,
    abandonChallenge,
    getChallengeHistory,
    getStudentChallengeHistory,
    postChallengeAsTutor,
    abandonChallengeAsTutor
}

