import React, {useCallback, useState} from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {SafeAreaProvider} from "react-native-safe-area-context";
import {TimePickerModal} from "react-native-paper-dates";
import {IconButton} from "react-native-paper";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import SelectDayModal from "./SelectDayModal";
import TextM from "@common/components/text/TextM";
import {isEmpty} from "lodash";
import {daysOptions} from "@data/constants/formConstants";

function SelectSlotsStep(props) {
    const {control, goToNextStep, register, errors, serverError, setFormValues, field} = props
    let handleAddSlot = () => {
        setSlots([...slots, {hour: "Heure", day: "Jour"}]);
    }
    const [visible, setVisible] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [dayValue, setDayValue] = useState(null)

    const [visibleModal, setVisibleModal] = useState(false)
    const handleShowModal = () => {
        setVisibleModal(!visibleModal)
    }

    const onDismiss = useCallback(() => {
        setVisible(false)
    }, [setVisible])

    const convertValues = (values) => {
        const tab = values?.length > 0 ? [] : [{
            hour: "Heure",
            day: 'Jour'
        }]
        values?.forEach((el) => {
            const day = el.split(" ")[0]
            const hour = el.split(" ")[1]
            const dayFr = daysOptions.find(d => d.value === day)?.label
            tab.push({
                hour: hour,
                day: dayFr
            })
        })
        return tab
    }

    const onConfirmHour = (e, index) => {
        setVisible(false);
        const list = [...slots]
        const value = field.value ? field.value?.slice() : []
        const hours = e.hours?.toString().length > 1 ? e.hours : "0" + e.hours
        const minutes = e.minutes?.toString().length > 1 ? e.minutes : "0" + e.minutes
        const time = hours + ":" + minutes
        list[selectedIndex]["hour"] = time
        const slot = list[selectedIndex].day + " " + time
        setSlots(list)
        if (field.value[index]) {
            const day = control?._formValues?.slots ? slot.split(" ")[0] : field.value[index].split(" ")[0]
            field.value[index] = day + " " + time
            const newValue = field.value
            newValue.splice(index, 1, day + " " + time)
            field.onChange(newValue)
            setFormValues({
                ...control._formValues,
                [field?.name]: newValue
            })
        } else if (slots[index].day !== "Jour") {
            value?.push(slot)
            field.onChange(value)
            setFormValues({
                ...control._formValues,
                [field?.name]: value
            })
        }
    }

    const onConfirmDay = (day, index, valueDay) => {
        const list = [...slots]
        list[index]["day"] = valueDay
        // console.log(valueDay)
        setDayValue(valueDay)
        setSlots(list)
        const slot = valueDay + " " + slots[index].hour
        const value = field.value ? field.value?.slice() : []
        if (field.value[index]) {
            const hour = slot.split(" ")[1]
            field.value[index] = valueDay + " " + hour
            const newValue = field.value
            newValue.splice(index, 1, valueDay + " " + hour)
            field.onChange(newValue)
            setFormValues({
                ...control._formValues,
                [field?.name]: newValue
            })
        } else if (slots[index].hour !== "Heure") {
            value?.push(valueDay + " " + slots[index].hour)
            field.onChange(value)
            setFormValues({
                ...control._formValues,
                [field?.name]: value
            })
        }
    }
    const [slots, setSlots] = useState(control?._formValues?.slots?.length > 0 ? control?._formValues?.slots : [{
        hour: "Heure",
        day: 'Jour'
    }]);
    const deleteSlot = (index) => {
        const newArray = [...slots]
        newArray.splice(index, 1)
        setSlots(newArray)
    }

    return (
        <>
            <SafeAreaProvider>
                <View style={{flex: 1, alignItems: 'center'}}>
                    {slots.map((el, index) => {
                        const dayFr = daysOptions.find(d => d.value === el.day)?.label ?? "Jour"
                        return <View key={index} style={{alignItems: 'center', flexDirection: 'row', marginTop: 10}}>
                            <TouchableOpacity
                                activeOpacity={0.8}
                                onPress={() => {
                                    setSelectedIndex(index)
                                    setVisibleModal(true)
                                }}
                            >
                                <View style={{
                                    flexDirection: "row",
                                    width: 100,
                                    backgroundColor: theme.colors.greyBg,
                                    borderRadius: 15,
                                    borderColor: theme.colors.light,
                                    borderWidth: 1,
                                    padding: 12,
                                    flexWrap: "wrap",
                                    marginRight: 10
                                }}>
                                    <TextM style={{
                                        borderColor: theme.colors.primary,
                                        fontSize: 14,
                                        color: isEmpty(el.day) ? "rgba(60, 60, 67, 1)" : "#000",
                                        marginVertical: 7
                                    }} wrap>
                                        {dayFr}
                                    </TextM>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity
                                activeOpacity={0.8}
                                onPress={() => {
                                    setVisible(true)
                                    setSelectedIndex(index)
                                }}
                            >
                                <View style={{
                                    flexDirection: "row",
                                    width: 100,
                                    backgroundColor: theme.colors.greyBg,
                                    borderRadius: 15,
                                    borderColor: theme.colors.light,
                                    borderWidth: 1,
                                    padding: 12,
                                    flexWrap: "wrap",
                                    marginRight: 10
                                }}>
                                    <TextM style={{
                                        borderColor: theme.colors.primary,
                                        fontSize: 14,
                                        color: isEmpty(el.hour) ? "rgba(60, 60, 67, 1)" : "#000",
                                        marginVertical: 7
                                    }} wrap>
                                        {el.hour ?? " "}
                                    </TextM>
                                </View>
                            </TouchableOpacity>
                            {slots.length > 1 ?
                                <IconButton
                                    icon={"trash"}
                                    iconColor={theme.colors.light}
                                    size={22}
                                    onPress={() => {
                                        deleteSlot(index)
                                    }}
                                /> : <View style={{width: 45}}>

                                </View>}
                            <TimePickerModal
                                label={"Sélectionner une heure"}
                                cancelLabel={"Annuler"}
                                confirmLabel={"Confirmer"}
                                keyboardIcon={'keyboard'}
                                clockIcon={'clock'}
                                locale={"fr"}
                                visible={visible}
                                onDismiss={onDismiss}
                                onConfirm={(e) => onConfirmHour(e, index)}
                            />
                        </View>
                    })}
                    {field?.name !== "slot" && <FilledButtonM mode="outlined"
                                                              style={{
                                                                  marginTop: 20,
                                                                  alignSelf: Platform.OS !== 'web' ? 'stretch' : 'center',
                                                                  width: "25%"
                                                              }}
                                                              onPress={handleAddSlot}
                                                              icon={"plus"}
                                                              label="Ajouter"/>}
                </View>

                <FilledButtonM label={field?.name !== "slot" ? "Terminer" : "Suivant"}
                               color={theme.colors.success}
                               style={{}}
                               onPress={goToNextStep}
                />
            </SafeAreaProvider>

            <SelectDayModal control={control}
                            handleShowModal={handleShowModal}
                            isOpen={visibleModal}
                            field={null}
                            onConfirmDay={onConfirmDay}
                            index={selectedIndex}/>
        </>
    );
}

export default SelectSlotsStep;