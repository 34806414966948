import React, {useContext, useEffect, useRef, useState} from 'react'
import styles, {MaxUidContext, MinUidContext, PropsContext, RtcContext} from "agora-react-uikit";
import MaxVideoViewWebM from "@common/video-call/AgoraUIKitWeb/MaxVideoViewWebM";
import MinVideoViewWebM from "@common/video-call/AgoraUIKitWeb/MinVideoViewWebM";
import {View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "@common/theme/theme";

/**
 * React Component to render the user videos in the pinned/spotlight layout
 */
const PinnedVideoWebM = () => {
    const {styleProps, rtcProps} = useContext(PropsContext)
    const {mediaStore, client} = useContext(RtcContext)
    const [speakerStyle, setSpeakerStyle] = useState({});
    const [speakerUid, setSpeakerUid] = useState(null);

    const {
        minViewContainer,
        pinnedVideoContainer,
        maxViewContainer,
        scrollViewContainer
    } = styleProps || {}
    const parentRef = useRef(null)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const isLandscape = width > height

    useEffect(() => {

        client.enableAudioVolumeIndicator()
        client.on("volume-indicator", volumes => {
            volumes.forEach((volume) => {
                if (rtcProps.uid === volume.uid && volume.level > 5) {
                    setSpeakerUid(volume.uid)
                    setSpeakerStyle({
                        borderColor: theme.colors.success,
                        borderWidth: 2,
                    })
                } else if (rtcProps.uid === volume.uid && volume.level < 5) {
                    setSpeakerUid(null)
                    setSpeakerStyle({});
                }
                if (rtcProps.uid !== volume.uid && volume.level > 5) {
                    setSpeakerUid(volume.uid)
                    setSpeakerStyle({
                        borderColor: theme.colors.success,
                        borderWidth: 2,
                    });
                } else if (rtcProps.uid !== volume.uid && volume.level < 5) {
                    setSpeakerUid(null)
                    setSpeakerStyle({});
                }
            });
        })
        const handleResize = () => {
            if (parentRef.current) {
                setWidth(parentRef.current.offsetWidth)
                setHeight(parentRef.current.offsetHeight)
            }
        }
        window.addEventListener('resize', handleResize)
        if (parentRef.current) {
            setWidth(parentRef.current.offsetWidth)
            setHeight(parentRef.current.offsetHeight)
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div
            ref={parentRef}
            style={{
                ...{
                    display: 'flex',
                    flex: 1,
                    flexDirection: isLandscape ? 'row' : 'column-reverse',
                    overflow: 'hidden'
                },
                ...pinnedVideoContainer,
            }}
        >
            <div
                style={{
                    ...{
                        display: 'flex',
                        flex: isLandscape ? 5 : 4
                    },
                    ...maxViewContainer,
                }}
            >
                <MaxUidContext.Consumer>
                    {(maxUsers) =>
                        rtcProps.role === 'audience' && maxUsers[0].uid === 0 ? null : (
                            <MaxVideoViewWebM user={maxUsers[0]}
                                              speakerStyle={speakerUid === maxUsers[0].uid ? speakerStyle : {}}/>
                        )
                    }
                </MaxUidContext.Consumer>
            </div>
            <div
                className={styles.scrollbar}
                style={{
                    ...{
                        overflowY: isLandscape ? 'scroll' : 'hidden',
                        overflowX: !isLandscape ? 'scroll' : 'hidden',
                        display: 'flex',
                        flex: 1,
                        flexDirection: isLandscape ? 'column' : 'row'
                    },
                    ...scrollViewContainer
                }}
            >
                <MinUidContext.Consumer>
                    {(minUsers) =>
                        minUsers.map((user) =>
                            rtcProps.role === 'audience' && user.uid === 0 ? null : (
                                <div
                                    style={{
                                        ...{
                                            minHeight: isLandscape ? '35vh' : '99%',
                                            minWidth: isLandscape ? '99%' : '40vw',
                                            margin: 2,
                                            display: 'flex'
                                        },
                                        ...minViewContainer,
                                    }}
                                    key={user.uid}
                                >
                                    <MinVideoViewWebM user={user}
                                                      speakerStyle={speakerUid === user.uid ? speakerStyle : {}}/>
                                </div>
                            )
                        )
                    }
                </MinUidContext.Consumer>
            </div>
        </div>
    )
}

export default PinnedVideoWebM