import React, {useContext, useRef, useState} from 'react';
import {Keyboard, StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {theme} from "@common/theme/theme";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {tutorsService} from "@data/services/tutorsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {sectionStudentClasses, skillSets, studentClasses, subjectPriorities} from "@data/constants/formConstants";
import {SwitchComponent} from "../application/personalInfos/ApplicationPersonalInfos";
import {weekDayNums} from "@data/constants/activitiesConstants";
import TextM from "@common/components/text/TextM";
import {Checkbox} from "react-native-paper";
import WebView from "react-native-webview";
import {TutorSlotsPicker} from "./TutorSlotsPicker";
import {TutorProfileCardHeader} from "../../student/account/onboarding/StartTrialNewScreen";
import {SelectModalM} from "@common/components/select/selectSingle-Multiple";
import {SelectInputM} from "../../student/account/onboarding/SelectInputM";
import TextInputM from "@common/components/input/TextInputM";
import TutorProfilePersonalizer from "./components/TutorProfilePersonalizer";

const ApplicationPersonalInfos = (props) => {

    const {navigation} = props
    const dimensions = useContext(DimensionsContext)
    const [formValues, setFormValues] = useState(null)
    const defaultValues = {}
    const [isAutoEntrepreneur, setIsAutoEntrepreneur] = useState(props.user?.profile?.num_SIRET !== null)
    const [charterOptIn, setCharterOptIn] = useState(false)
    const customInputs = [
        {
            label: "l'établissement d'études",

        }
    ]

    const availabilitySlots = formValues?.availability_slots

    //calculate if the following rule is respected : at least three slots, or, if two slots, they must be at least 1 day apart calculate with momentjs
    const isAvailabilitySlotsValid = availabilitySlots?.length >= 3 || (availabilitySlots?.length === 2 && Math.abs(weekDayNums[availabilitySlots?.[0]?.split(" ")[0]] - weekDayNums[availabilitySlots?.[1]?.split(" ")[0]]) !== 1)

    // console.log(isAvailabilitySlotsValid)
    // console.log(Math.abs(weekDayNums[availabilitySlots?.[0]?.split(" ")[0]] - weekDayNums[availabilitySlots?.[1]?.split(" ")[0]]))

    //     'fixed_missions'             => ['nullable', 'boolean',],
    //     'max_hours_per_week'         => ['integer', 'required_if:fixed_missions,true', 'min:2', 'max:10'],
    //     'replace_missions'           => ['nullable', 'boolean'],
    //     'availability_slots'         => ['nullable', 'array'],
    //     'availability_slots.*'       => ['date_format:l H:i'],
    //     'availability_starting_date' => ['nullable', 'date_format:Y-m-d'],
    //     'relevant_subjects'          => ['nullable', 'array'],
    //     'relevant_subjects.*'        => [Rule::in(StudentProfile::$subjectPreferences)],
    //     'relevant_classes'           => ['nullable', 'array'],
    //     'relevant_classes.*'         => [Rule::in(User::$studentClasses)],

    const formData = [
        {
            label: "Es-tu prêt.e à réaliser des missions fixes de tutorat ?",
            description: "Tu retrouveras tes élèves chaque semaine aux mêmes horaires",
            name: "fixed_missions",
            type: "select.single",
            options: [{label: "Oui", value: true}, {label: "Non", value: false}],
            defaultValue: props.user?.profile?.fixed_missions === null ? null : !!props.user?.profile?.fixed_missions,
            validationRules: {...validation.required()}
        },
        // {
        //     label: "Combien d'heures de lives es-tu prêt.e à encadrer chaque semaine au maximum ?",
        //     // is_masked: formValues?.fixed_missions === false,
        //     name: "max_hours_per_week",
        //     type: "select.single",
        //     options: createArray(2, 10).map(x => ({label: x + "h", value: x})),
        //     defaultValue: props.user?.profile?.max_hours_per_week,
        //     validationRules: {...validation.required()}
        // },
        {
            label: "Es-tu prêt.e à réaliser des missions de remplacement ?",
            description: "Tu seras appelé.e à remplacer ponctuellement d'autres tuteurs",
            name: "replace_missions",
            type: "select.single",
            options: [{label: "Oui", value: true}, {label: "Non", value: false}],
            defaultValue: props.user?.profile?.replace_missions === null ? null : !!props.user?.profile?.replace_missions,
            validationRules: {...validation.required()}
        },
        {
            label: "Indique tes disponibilités",
            name: "availability_slots",
            type: "custom",
            defaultValue: props.user?.profile?.availability_slots ?? [],
            component: TutorSlotsPicker,
            bottomComponent: !isAvailabilitySlotsValid && <>
                <TextM wrap>Pour un même groupe, les sessions Live ne peuvent pas avoir lieu deux jours de suite.</TextM>
                </>,
            showNextButton: isAvailabilitySlotsValid,
            validationRules: {...validation.required()}
        },
        {
            label: "À partir de quelle date es-tu disponible ?",
            name: "availability_starting_date",
            type: "date",
            defaultValue: props.user?.profile?.availability_starting_date,
            validationRules: {...validation.required()}
        },
        {
            label: "Quelles sont tes matières de prédilection ?",
            name: "relevant_subjects",
            type: "select.multiple",
            options: subjectPriorities,
            defaultValue: props.user?.profile?.relevant_subjects,
            validationRules: {...validation.required()}
        },
        {
            label: "Jusqu'a quel niveau te sens-tu capable d'accompagner des élèves sur tes matières de prédilection ?",
            name: "relevant_classes",
            type: "select.multiple",
            sectionMode: true,
            options: sectionStudentClasses
                ?.filter((x, i) => i < sectionStudentClasses?.length - 1)
                ?.map(section => {
                    return {
                        ...section,
                        data: section?.options?.map(x => {
                            return {value: x, label: x}
                        })
                    }
                }),
            defaultValue: props.user?.profile?.relevant_classes ?? studentClasses?.filter((x, i) => i < studentClasses?.length - 1),
            validationRules: {...validation.required()}
        },
        {
            label: "Indique tes informations de facturation",
            type: "text.multiple",
            inputs: [
                {
                    name: "num_SIRET",
                    placeholder: "Numéro SIRET",
                    defaultValue: props.user?.profile?.num_SIRET,
                    hidden: !isAutoEntrepreneur
                },
                {
                    name: "num_IBAN",
                    placeholder: "Numéro IBAN",
                    defaultValue: props.user?.profile?.num_IBAN,
                    validationRules: {...validation.required(), ...validation.iban()}
                }
            ],
            defaultValue: "",
            topComponent: <SwitchComponent value={isAutoEntrepreneur}
                                           setValue={setIsAutoEntrepreneur}
                                           label="J'ai déjà le statut auto-entrepreneur"/>
        },
        {
            label: "Personnalise ton profil",
            type: "custom",
            inputs: [
                {
                    name: "study_field",
                    defaultValue: props.user?.profile?.study_field ?? null,
                    validationRules: {...validation.required()}
                },
                {
                    name: "study_institution",
                    defaultValue: props.user?.profile?.study_institution ?? null,
                    validationRules: {...validation.required()}
                },
                {
                    name: "tutor_skill_set_title_id",
                    defaultValue: props.user?.title_skill_set_id ?? 101,
                    validationRules: {...validation.required()}
                }
            ],
            component: TutorProfilePersonalizer,
            },
        {
            label: "Lis et approuve la charte d'engagement du tuteur",
            name: "charter_opt_in",
            showNextButton: charterOptIn,
            type: "custom",
            component: () => IS_WEB
                ? <iframe style={{flex: 1}} src={"https://masteur.com/charte-dengagement"} frameBorder="0"/> :
                <WebView
                    style={{flex: 1}}
                    source={{uri: "https://masteur.com/charte-dengagement"}}
                />,
            bottomComponent: <Checkbox.Item label="J’accepte la charte d'engagement du tuteur"
                                            position={'leading'}
                                            onPress={() => {
                                                setCharterOptIn(!charterOptIn)
                                            }}
                                            color={theme.colors.primary}
                                            labelStyle={{
                                                fontSize: 14,
                                                textAlign: "left"
                                            }}
                                            style={{
                                                marginHorizontal: 5,
                                                marginTop: 5
                                            }}
                                            status={charterOptIn ? "checked" : "indeterminate"}/>,
            defaultValue: props.user?.profile?.charter_opt_in ?? false,
            validationRules: {...validation.required(), ...validation.customCondition(() => charterOptIn, "Tu dois accepter la charte d'engagement du tuteur")}
        }

    ]

    const onSubmit = data => {
        if (props.submitPersonalInfosRequest) return
        // console.log(data)
        props.apiRequest(tutorsService.submitCompleteProfile, {}, {
            ...data,
            charter_opt_in: charterOptIn
        })
    }

    useApiStatus(tutorsService.submitCompleteProfile, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {

            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Tes informations ont bien été enregistrées"
                }
            }]
            navigation.replace("congrats", {congrats})
        })

    return <View style={{
        flex: 1, // backgroundColor: '#fff',
        alignItems: 'center', justifyContent: 'center'
    }}>

        <FormStepper navigation={navigation}
                     track
                     formData={formData?.filter(x => !x.is_masked)}
                     champion={null}
                     formValues={formValues}
                     setFormValues={setFormValues}
                     defaultValues={defaultValues}
                     onSubmit={onSubmit}
                     submitted={props.request}
                     previousRouteName={"menu"}
                     submittedMessage=""
                     serverError={props.error}
        />
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}

const mapStateToProps = state => {
    return {
        success: state.api.submitCompleteProfile?.success,
        request: state.api.submitCompleteProfile?.request,
        error: state.api.submitCompleteProfile?.error,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser.object
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPersonalInfos)