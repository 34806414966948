import React, {useState} from 'react'
import {apiIdle, apiRequest} from "../../data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {useNavigation} from "@react-navigation/native";
import {liveService} from "../../data/services/liveService";
import LiveAvatarM from "../../tutor/students/LiveAvatarM";
import {addPhoneRinging, removePhoneRinging} from "../../data/redux/actions/liveMode";

const CallStudentsStepper = (props) => {

    const navigation = useNavigation()

    const liveSession = props.currentLiveSession

    const defaultValues = {
        user_ids: liveSession?.users.filter(user => !user.is_tutor).map(i => i?.id)
    }
    const [formValues, setFormValues] = useState(defaultValues)

    const formData = [
        {
            label: "Lancer un appel",
            type: "select.multiple",
            name: "user_ids",
            height: 70,
            options: liveSession?.users?.filter(user => !user.is_tutor)?.map((s) => {
                return {
                    leftComponent: <LiveAvatarM user={s} style={{marginRight: 6}}/>,
                    value: s?.id,
                    label: s?.display_name
                }
            }),
            defaultValue: defaultValues?.user_ids ?? []
        }
    ]

    const onSubmit = data => {
        if (props.makeCallRequest) return
        const formData = {...defaultValues, ...data}
        props.apiRequest(liveService.makeCall, {id: props.liveSessionId}, formData)
        data?.user_ids?.forEach(x => {
            props.addPhoneRinging(x)
            setTimeout(() => {
                props.removePhoneRinging(x)
            }, 30 * 1000)
        })
    }

    return (
        <View style={{flex: 1, alignItems: 'center'}}>


            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         formValues={formValues}
                         onSubmit={onSubmit}
                         submitted={props.makeCallRequest}
                         previousRouteName={"live-students"}
                         submittedMessage=""
                         serverError={props.makeCallError}
                         submitButtonText="Appeler"

            />
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions, 
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        getSessionsRequest: state.api.getLiveSession?.request,
        makeCallRequest: state.api.makeCall?.request,
        makeCallError: state.api.makeCall?.error,
        isLive: state.liveMode.isLiveModeEnabled
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    addPhoneRinging,
    removePhoneRinging
};

export default connect(mapStateToProps, mapDispatchToProps)(CallStudentsStepper)