import {View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "@common/theme/theme";
import React from "react";

export const CertificateIconM = ({style, size = 24}) => {
    return <View style={{
        marginLeft: 6,
        alignItems: "center",
        justifyContent: "center",
        ...style
    }}>
        <FontAwesome5 name={"certificate"}
                      size={size}
                      color={theme.colors.link}
        />
        <FontAwesome5 name={"check"}
                      size={size / 2}
                      color={theme.colors.white}
                      style={{
                          position: "absolute"
                      }}/>
    </View>;
}