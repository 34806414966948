import {apiConstants} from "../../../constants/apiConstants"
import {staticDataService} from "../../../services/staticDataService";
import {schoolSubjectsService} from "../../../services/schoolSubjectsService";
import {authConstants} from "../../../constants/authConstants";
import {authService} from "../../../services/authService";

// ** Initial State
const initialState = {
    challenge_templates: [],
    activity_templates: [],
    school_subjects: [],
    challenge_intensity_levels: []
}

export function staticData(state = initialState, action) {
    switch (action.type) {

        case staticDataService.getStaticData.name + apiConstants._SUCCESS:
            return action.data

        case schoolSubjectsService.getSchoolSubjects.name + apiConstants._SUCCESS:
            return {
                ...state,
                school_subjects: action.data
            }

        default:
            return state
    }
}

export default staticData
