import axios from "axios"

function getMessages({threadId}) {
    //console.log(`api/messenger/threads/${threadId}/messages`);
    return axios.get(`api/messenger/threads/${threadId}/messages`)


}

function postMessages({threadId}, value) {
    return axios.post(`api/messenger/threads/${threadId}/messages`, value)

}

function postImage({threadId}, image) {
    return axios.post(`api/messenger/threads/${threadId}/images`, image)

}

function postAudio({threadId}, audio) {
    return axios.post(`api/messenger/threads/${threadId}/audio`, audio)

}

function getPageMessages({threadId, pageId}) {
    return axios.get(`api/messenger/threads/${threadId}/messages/page/${pageId}`)
}

function getMarkRead({threadId}) {
    return axios.get(`api/messenger/threads/${threadId}/mark-read`)
}

export const messengerService = {
    getMessages,
    postMessages,
    getPageMessages,
    getMarkRead,
    postImage,
    postAudio

}