import {activitiesService} from "@data/services/activitiesService"
import {apiConstants} from "@data/constants/apiConstants"
import {authService} from "@data/services/authService";
import {authConstants} from "@data/constants/authConstants";
import {SET_ACTIVITIES_REVIEW_FILTER} from "@data/constants/activitiesConstants";
import {scoringService} from "@data/services/scoringService";

// ** Initial State
const initialState = {
    object: []
}

export function reviewer(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_401:
        case authService.logout.name + apiConstants._SUCCESS:
        case authService.revokeSanctumToken.name + apiConstants._SUCCESS:
        case authService.deleteAccount.name + apiConstants._SUCCESS:
            return initialState

        case activitiesService.getActivities.name + apiConstants._SUCCESS:

            // console.log(action?.data)

            const updateActivities = () => {

                let notFoundActivities = [...action.data]

                const result = state.object?.activities?.map(a => {
                    const activityFound = action.data?.find(x => x?.id === a?.id)
                    if (!!activityFound) {
                        notFoundActivities = notFoundActivities?.filter(a => a?.id !== activityFound?.id)
                        return activityFound
                    } else
                        return a
                }) ?? []

                return result?.concat(notFoundActivities)
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    activities: updateActivities()
                }
            }

        case activitiesService.reviewActivity.name + apiConstants._SUCCESS:
        case activitiesService.reviewLock.name + apiConstants._SUCCESS:

            // console.log(action?.data)
            const updateActivityIfFoundInStore = a => {
                return action.data?.id === a?.id ? action.data : a
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    activities: state.object?.activities?.map(updateActivityIfFoundInStore)
                }
            }

        case activitiesService.getActivitiesForReview.name + apiConstants._SUCCESS:

            let finishedActivities = []
            let challenges = []

            // console.log(action.data?.meta?.current_page)

            if (action.data?.meta?.current_page === 1) { // refresh
                finishedActivities = action.data?.activities
                challenges = action.data?.challenges

                // console.log("page 1 reducer", action.data?.meta)
            } else { // concat
                finishedActivities = state.object?.activities.concat(action.data?.activities)
                challenges = state.object?.challenges.concat(action.data?.challenges)

                // console.log("page + reducer", action.data?.meta)
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    activities: finishedActivities,
                    challenges: challenges
                }
            }

        case SET_ACTIVITIES_REVIEW_FILTER:

            return {
                ...state,
                object: {
                    ...state.object,
                    activitiesFilter: action.filter
                }
            }


        case scoringService.getStaticScoringData.name + apiConstants._SUCCESS:

            return {
                ...state,
                object: {
                    ...state.object,
                    scoringRules: action.data?.scoring_rules,
                    scoringComments: action.data?.scoring_comments,
                }
            }

        case scoringService.postScoringComment.name + apiConstants._SUCCESS:

            return {
                ...state,
                object: {
                    ...state.object,
                    scoringComments: [...state.object?.scoringComments, action.data],
                }
            }

        case scoringService.postScoringRule.name + apiConstants._SUCCESS:

            return {
                ...state,
                object: {
                    ...state.object,
                    scoringRules: [...state.object?.scoringRules, action.data?.scoring_rule],
                    scoringComments: [...state.object?.scoringComments, action.data?.scoring_comment],
                }
            }

        default:
            return state
    }
}

export default reviewer
