import {View} from "react-native";
import React, {useContext, useEffect, useRef, useState} from "react";
import {theme} from "../theme/theme";
import {DimensionsContext} from "../utils/mobileUtils";
import AvatarM from "../components/avatar/AvatarM";
import TextM from "../components/text/TextM";
import {EngagementScoreBar} from "./live-report/LiveReportComponent";
import {MenuM} from "../components/menu/MenuM";
import {IconTextM} from "../components/icons/IconTextM";
import {AudioRecordingContainer} from "../../student/activity/execute/AudioRecordingContainer";
import AutoSaveTextInputM from "../components/input/TextInputAutoSaveM";
import {DialogM} from "../components/alert/dialog/Dialog";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useRequestsBatch} from "../hooks/useRequestsBatch";
import {liveReportService} from "../../data/services/liveReportService";
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "../../data/redux/actions/api";
import moment from "moment";
import {formIds} from "../../data/constants/formConstants";
import {ActivityIndicator} from "react-native-paper";
import {useApiStatus} from "../hooks/useApiStatus";

const COMMENT_TYPES = {
    Vocal: "CR en vocal",
    Texte: "CR en texte"
};

const EndSessionStudentComment = (props) => {
    const {liveReport, setCommentsSentCount, commentsSentCount} = props;
    const dimensions = useContext(DimensionsContext);
    const student = liveReport.student;

    const [selectedType, setSelectedType] = useState(
        liveReport?.tutor_comment ? COMMENT_TYPES.Texte : COMMENT_TYPES.Vocal
    );
    const [recordingURI, setRecordingURI] = useState(
        liveReport?.comment_record ?? null
    );
    const [tutorComment, setTutorComment] = useState(
        liveReport?.tutor_comment ?? ""
    );
    const [time, setTime] = useState(0);
    const prevCommentRef = useRef(tutorComment);
    const onMenuSelect = () => {
        DialogM.show({
            text1: "Attention !",
            text2: `Ce que vous avez ${
                selectedType === COMMENT_TYPES.Vocal ? "enregistré" : "écrit"
            } sera supprimé`,
            buttonText1: "Valider",
            buttonColor1: theme.colors.error,
            onPress: () => {
                if (selectedType === COMMENT_TYPES.Vocal) {
                    props.apiRequest(liveReportService.deleteCommentRecord, {
                        id: liveReport.id
                    });
                } else {
                    props.apiRequest(
                        liveReportService.sendStudentComment,
                        {
                            id: liveReport?.id
                        },
                        {
                            tutor_comment: ""
                        }
                    );
                }
                setRecordingURI(null);
                setTutorComment(null);
                setSelectedType(
                    selectedType === COMMENT_TYPES.Vocal
                        ? COMMENT_TYPES.Texte
                        : COMMENT_TYPES.Vocal
                );
            },
            buttonText2: "Annuler"
        });
    };

    const menuItems = [
        {
            icon: "microphone",
            title: COMMENT_TYPES.Vocal,
            onPress: () =>
                selectedType === COMMENT_TYPES.Vocal
                    ? () => {
                    }
                    : tutorComment
                        ? onMenuSelect()
                        : setSelectedType(COMMENT_TYPES.Vocal)
        },
        {
            type: "divider"
        },
        {
            icon: "keyboard",
            title: COMMENT_TYPES.Texte,
            onPress: () =>
                selectedType === COMMENT_TYPES.Texte
                    ? () => {
                    }
                    : recordingURI
                        ? onMenuSelect()
                        : setSelectedType(COMMENT_TYPES.Texte)
        }
    ];

    const audioFile = {
        uri: recordingURI,
        name: `student_comment${student?.display_name}_${moment().format(
            "YYYY-MM-DD"
        )}.m4a`,
        type: "audio/m4a"
    };
    const filesToUpload = [audioFile];

    const [globalProgress, submitted, startRequests] = useRequestsBatch(
        [],
        filesToUpload,
        formIds.LIVE_REPORT_COMMENT + "_" + student?.id,
        {
            service: liveReportService.sendStudentComment,
            data: {},
            tmpFilesKey: "comment_record",
            tmpFilesFormatter: (attachmentTmp, index) => {
                return attachmentTmp;
            },
            additionalData: [],
            params: {
                id: liveReport?.id
            },
            onSuccessBeforeNext: () => {
            }
        },
        []
    );

    const [initialRender, setInitialRender] = useState(true);

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            return;
        }
        if (submitted) return;
        if (recordingURI) startRequests();
    }, [recordingURI]);

    useApiStatus(
        liveReportService.deleteCommentRecord, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (props.deleteCommentRecordRequestId === liveReport?.id)
                setCommentsSentCount(x => x - 1);
        }
    );

    useApiStatus(
        liveReportService.sendStudentComment, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            const prevComment = prevCommentRef.current;
            if (
                (tutorComment &&
                    tutorComment.trim() !== "" &&
                    (!prevComment || prevComment.trim() === "")) ||
                recordingURI
            ) {
                setCommentsSentCount(commentsSentCount + 1);
            } else if (
                (!tutorComment || tutorComment.trim() === "") &&
                prevComment &&
                prevComment.trim() !== ""
            ) {
                setCommentsSentCount(commentsSentCount - 1);
            }
            prevCommentRef.current = tutorComment;
        }
    );

    const renderButton = () => {
        return (
            <View
                style={{
                    // width: 120,
                    flexDirection: "row",
                    backgroundColor: theme.colors.white
                }}
            >
                <IconTextM
                    text={selectedType}
                    icon={"sort-down"}
                    style={{alignItems: "center", marginTop: 0, width: 160}}
                    iconSize={30}
                    fontSize={16}
                    iconStyle={{
                        width: 24,
                        marginBottom: 15,
                        marginLeft: 5
                    }}
                    right
                    color={theme.colors.primary}
                />
            </View>
        );
    };

    const renderTextOrVoice = () => {
        if (selectedType === COMMENT_TYPES.Vocal) {
            return submitted ? (
                <ActivityIndicator
                    animating={true}
                    style={{
                        alignSelf: "center",
                        justifySelf: "center",
                        marginBottom: 10
                    }}
                    size="large"
                    color={theme.colors.primary}
                />
            ) : (
                <View
                    style={{
                        width: "90%"
                    }}
                >
                    <AudioRecordingContainer
                        setTime={setTime}
                        recordingURI={recordingURI}
                        setRecordingURI={setRecordingURI}
                        onDeleteRecording={() => {
                            props.apiRequest(liveReportService.deleteCommentRecord, {
                                id: liveReport.id
                            });
                        }}
                    />
                </View>
            );
        } else {
            return (
                <AutoSaveTextInputM
                    placeholder="Attitude, difficultés, progrès, points forts..."
                    name="comment"
                    defaultValue={tutorComment ?? ""}
                    contentContainerStyle={{
                        width: "100%",
                        alignSelf: "center",
                        paddingHorizontal: 10
                    }}
                    style={{
                        height: 150,
                        backgroundColor: theme.colors.white,
                        marginTop: 10,
                        alignSelf: "center"
                    }}
                    multiline={true}
                    numberOfLines={2}
                    onChangeText={(value) => {
                        props.apiRequest(
                            liveReportService.sendStudentComment,
                            {
                                id: liveReport?.id
                            },
                            {
                                tutor_comment: value
                            }
                        );
                        if (!tutorComment) {
                            // setCommentsSentCount((prevCount) => prevCount + 1);
                        }
                        setTutorComment(value);
                    }}
                />
            );
        }
    };

    return (
        <View>
            <View
                style={{
                    borderWidth: 2,
                    borderColor: !liveReport?.statistics
                        ? theme.colors.light
                        : recordingURI || tutorComment
                            ? theme.colors.success
                            : theme.colors.error,
                    borderRadius: theme.roundness,
                    padding: 0,
                    backgroundColor: theme.colors.white,
                    marginTop: 20,
                    alignItems: "center"
                }}
            >

                <View
                    style={{
                        width: "100%",
                        flex: 1,
                        flexDirection: dimensions?.width < 800 ? "column" : "row",
                        justifyContent: "flex-start",
                        // backgroundColor: theme.colors.primaryLighter,
                        borderTopLeftRadius: theme.roundness,
                        borderTopRightRadius: theme.roundness,
                        borderBottomLeftRadius: liveReport?.statistics ? 0 : theme.roundness,
                        borderBottomRightRadius: liveReport?.statistics ? 0 : theme.roundness
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            paddingVertical: 10,
                            paddingLeft: 10,
                            paddingRight: 30,
                            alignSelf: "flex-start",
                            width: 200
                        }}
                    >
                        <AvatarM user={student} size={50}/>
                        <View>
                            <TextM
                                style={{marginLeft: 5}}
                                fontWeight={"Bold"}
                                fontSize={15}
                                color={theme.colors.primary}
                            >
                                {student?.display_name}
                            </TextM>
                            <TextM fontSize={15} style={{marginLeft: 5}}>
                                {student?.profile?.class}
                            </TextM>
                        </View>
                    </View>

                    {liveReport?.statistics ? (
                        <View
                            style={{
                                flexDirection: "column",
                                alignItems: "flex-start",
                                alignSelf: "stretch"

                            }}
                        >
                            <TextM
                                fontSize={24}
                                fontWeight={"ExtraBold"}
                                style={{
                                    marginLeft: 20,
                                    marginTop: 10
                                }}
                            >
                                <TextM
                                    fontSize={16}
                                    fontWeight={"Bold"}
                                    style={{
                                        marginRight: 10
                                    }}
                                >
                                    Score d'engagement
                                </TextM>
                                {liveReport?.statistics?.scores?.total_score}{" "}
                                <TextM
                                    fontSize={16}
                                    fontWeight={"Bold"}
                                >
                                    /100
                                </TextM>
                            </TextM>
                            <EngagementScoreBar
                                scores={liveReport?.statistics?.scores}
                                style={{
                                    marginTop: 5
                                    // alignItems: "center",
                                }}
                                // width={400}
                                // disableScoreButton
                                noProgressBar
                            />

                        </View>

                    ) : (
                        <TextM
                            fontSize={16}
                            fontWeight={"Bold"}
                            color={theme.colors.grey}
                            style={{marginLeft: 20, alignSelf: "center", marginVertical: 20}}
                        >
                            Absent
                        </TextM>
                    )}
                    {!!liveReport.statistics && (recordingURI || tutorComment) && (
                        <View
                            style={{
                                height: 40,
                                width: 40,
                                borderRadius: 20,
                                backgroundColor: theme.colors.success,
                                position: "absolute",
                                right: -2,
                                top: -15,
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <FontAwesome5
                                style={{
                                    backgroundColor: theme.colors.success
                                }}
                                name={"check"}
                                size={20}
                                color={theme.colors.white}
                            />
                        </View>
                    )}
                </View>
                {!!liveReport?.statistics && (
                    <View
                        style={{
                            alignItems: "center",
                            width: "100%",
                            marginTop: 10
                            // backgroundColor: "red"
                        }}
                    >
                        {renderTextOrVoice()}
                        <View style={{
                            position: "relative",
                            bottom: 10,
                            right: 40,
                            alignSelf: "flex-end"
                        }}>
                            <MenuM
                                buttonComponent={renderButton()}
                                items={menuItems}
                                style={{
                                    width: 160
                                }}
                            />
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
};
const mapStateToProps = (state) => {
    return {
        getTodayLiveOccurrenceRequest: state.api.getTodayLiveOccurrence?.request,
        getTodayLiveOccurrenceError: state.api.getTodayLiveOccurrence?.error,
        getLiveOccurrenceRequest: state.api.getLiveOccurrence?.request,
        getLiveOccurrenceError: state.api.getLiveOccurrence?.error,
        deleteCommentRecordRequestId: state.api.deleteCommentRecord?.routeParams?.id
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EndSessionStudentComment);
