import {formDataConstants} from "@data/constants/formDataConstants";
import {authConstants} from "@data/constants/authConstants";
import {authService} from "@data/services/authService";
import {apiConstants} from "@data/constants/apiConstants";
import {subscriptionService} from "@data/services/subscriptionService";

const initialState = {
    activity: null,
    answerInput: null,
    holidayOptions: [],
    trialOptions: []
}

export default function formData(state = initialState, action) {
    switch (action.type) {

        case formDataConstants.SET_ANSWER_INPUT:
            return {
                ...state,
                answerInput: action.data
            }
        case formDataConstants.SAVE_ACTIVITY:
            return {
                ...state,
                activity: action.data
            }

        case authConstants.LOGOUT_401:
        case authService.logout.name + apiConstants._SUCCESS:
        case authService.revokeSanctumToken.name + apiConstants._SUCCESS:
        case authService.deleteAccount.name + apiConstants._SUCCESS:
        case formDataConstants.CLEAR_ACTIVITY:
            return {
                ...state,
                activity: null
            }
        case subscriptionService.getPauseResumeChoice.name + apiConstants._SUCCESS:
            return {
                ...state,
                schoolSubjects: action.all_data?.school_subjects,
                nextPaymentDate: action.all_data?.next_payment_date,
                displayName: action.all_data?.display_name,
                remainingDiscounts: action.all_data?.remaining_discounts,
                user: action.all_data?.user // id, roles, subscription_state, trial_ends_at
            }
        case subscriptionService.updatePrioritizedSchoolSubjects.name + apiConstants._SUCCESS:
        case subscriptionService.updateAvailabilitySlots.name + apiConstants._SUCCESS:
            return {
                ...state,
                user: action.data,
            }
        case subscriptionService.getSubscribePricing.name + apiConstants._SUCCESS:
            return {
                ...state,
                user: action.data?.user,
                pricingTableData: action.data?.pricing_table,
                schoolSubjects: action.data?.school_subjects,
                pricingTableDataNextMode: action.data?.pricing_table_next_mode,
                commitmentEndDate: action.data?.commitment_end_date,
                billingPortalUrl: action.data?.billing_portal_url
            }
        case authService.getAvailableTrialLiveSessions.name + apiConstants._SUCCESS:
            return {
                ...state,
                trialOptions: action.data?.options
            }
        default:
            return state
    }
}
