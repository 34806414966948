import {View} from "react-native";
import TextM from "../common/components/text/TextM";
import React, {useContext} from 'react'
import {DimensionsContext, IS_WEB} from "../common/utils/mobileUtils";
import {theme} from "../common/theme/theme";
import {AnalyticsCard, sumCC} from "./AnalyticsCard";


export const SubscriptionSection = (props) => {

    const RenderCard = ({product}) => {
        return (
            <View style={{
                justifyContent: 'space-between',
                width: "100%",
                flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : "column",
                flexWrap: 'wrap',
            }}>
                <AnalyticsCard
                    product={product}
                    categories={Object.keys(props.data?.student_accounts_created?.[product]?.weekly_series ?? {})}
                    stats={props.data?.student_accounts_created?.[product]?.this_month}
                    values={[
                        {
                            name: "Abonnements " + product,
                            //data: [1, 3, 2, 1, 6],
                            data: sumCC(Object.values(props.data?.student_accounts_created?.[product]?.weekly_series ?? {})),
                            color: "#223F8F",
                        }
                    ]}
                    statTitle={"Inscriptions en essai " + product}
                    style={{
                        //width: dimensions?.width / 2.7,
                    }}
                />
                <AnalyticsCard product={product}
                               categories={Object.keys(props.data?.student_accounts_created?.[product]?.weekly_series ?? {})}
                               stats={props.data?.student_subscription_activations?.[product]?.this_month}
                               values={[
                                   {
                                       name: "Abonnements " + product,
                                       data: sumCC(Object.values(props.data?.student_subscription_activations?.[product]?.weekly_series ?? {})),
                                       color: "#223F8F",
                                       //data: [0, 3, 2, 1, 6]
                                   },
                                   {
                                       name: "Essais " + product + " expirés",
                                       data: sumCC(Object.values(props.data?.student_trials_expired?.[product]?.weekly_series ?? {})),
                                       color: "#FFC380",
                                       //data: [1, 2, 2, 7]
                                   }
                               ]}
                               statTitle={"Activations abonnements " + product + " (pour 0 essais expirés)"}
                               style={{
                                   //width: dimensions?.width / 2.7,
                               }}
                />
                <AnalyticsCard product={product}
                               statTitle={"Essais " + product + " en cours"}
                               stats={props.data?.ongoing_trials?.[product]}
                               style={{
                                   // width: dimensions?.width / 5,
                               }}
                />
            </View>
        )
    }
    const dimensions = useContext(DimensionsContext)
    return (
        <View style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: dimensions?.width,
            paddingVertical: 20
        }}>
            <TextM fontWeight={"Bold"}
                   style={{
                       marginLeft: 5
                   }}
                   wrap
                   color={theme.colors.primary}
                   fontSize={20}>
                Inscriptions
            </TextM>
            <RenderCard product={"plus"}/>
            <RenderCard product={"premium"}/>
        </View>
    )
}