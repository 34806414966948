import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import moment from "moment";
import {findItemInListWithId} from "@data/utility/dataUtils";
import React, {useContext, useState} from 'react'
import {FormStepper} from "@common/components/stepper/FormStepper";
import {useNavigation} from "@react-navigation/native";
import {dateOptionsInPeriod} from "@data/utility/Utils";
import {validation} from "@data/utility/validation";
import {schoolsService} from "@data/services/schoolsService";
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {IconImageM} from "@common/components/icons/IconsM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TitleM from "@common/components/text/TitleM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import calendar from "../../../assets/icons/generic/calendar-days.png"

export function RecapItem({item, title}) {
    return (<View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
    }}>
        <TextM fontWeight={"Bold"}>
            {title}
        </TextM>
        <View style={{flexDirection: 'row', alignItems: 'center', width: "50%"}}>
            {item.customIcon ?
                <IconImageM source={item?.customIcon} width={30}/>
                :
                <IconImageM uri={item?.icon} width={30}/>
            }
            <TextM wrap style={{
                paddingHorizontal: 10
            }}>
                {item?.title ?? item?.name ?? item}
            </TextM>
        </View>

    </View>)
}

function ClassroomNewChallenge(props) {
    const classrooms = props.user?.classrooms
    const classroom = props.route?.params.classroomId ? findItemInListWithId(parseInt(props.route?.params?.classroomId), classrooms) : null
    const navigation = useNavigation()


    const defaultValues = {
        challenge_template_id: 7, // Challenge "Préparer un cours"
        start_date: moment().format("YYYY-MM-DD"), // today
        school_subject_id: classroom?.school_subject_id, // school subject id of the classroom
        planning_mode: true, // always in planning mode
        intensity_level: 1, // 1 by default
        domain: "",
        custom_planning: [],
        student_ids: [],
        deadline: moment().add(7, "days").format("YYYY-MM-DD")
    }
    const [formValues, setFormValues] = useState(defaultValues)
    const classroomChallengeTemplate = props.challengeTemplates?.find(x => x?.id === 7)
    const activityTemplateIds = classroomChallengeTemplate?.activity_template_ids
    const options = dateOptionsInPeriod(2, 30)
    const currentActivityTemplate = props.activityTemplates?.find(x => x?.id === formValues.activity_template_id)

    const currentStudents = classroom?.students?.filter(s => formValues?.["student_ids"]?.includes(s?.id))
    // console.log("students", currentStudents)
    const currentDeadline = moment(formValues?.["deadline"])?.format("dddd DD MMMM")
    const dimension = useContext(DimensionsContext)
    const formData = [
        {
            label: "Sélectionnez une activité",
            type: "select.single",
            name: "activity_template_id",
            height: IS_WEB ? null : 85,
            options: activityTemplateIds.filter((item, index) => activityTemplateIds.indexOf(item) === index).map((el) => {
                const activityTemplate = props.activityTemplates?.find(at => at?.id === el)
                return {
                    icon: activityTemplate?.icon,
                    value: activityTemplate.id,
                    label: activityTemplate.title,
                    description: activityTemplate.description
                }
            }),
            defaultValue: null
        },
        {
            label: "Élèves",
            type: "select.multiple",
            name: "student_ids",
            options: classroom?.students?.map((s) => {
                return {
                    icon: s?.avatar,
                    value: s?.id,
                    label: s?.display_name,
                }
            }),
            defaultValue: defaultValues?.student_ids ?? []
        },
        {
            label: "Date limite",
            type: "select.single",
            name: "deadline",
            options: options,
            defaultValue: defaultValues?.deadline ?? []
        },
        {
            label: "Sujet du défi",
            type: "text.multiple",
            inputs: [
                {
                    label: "Sujet du défi",
                    name: "domain",
                    placeholder: "Sujet du défi",
                    defaultValue: defaultValues?.domain ?? "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ],
            bottomComponent: <View style={{
                width: dimension?.width / 2
            }}>
                <TitleM fontWeight={"ExtraBold"} style={{
                    paddingVertical: 15
                }}>
                    Récapitulatif
                </TitleM>
                <View style={{
                    width: IS_WEB && dimension?.width > 800 ? dimension?.width / 2 : dimension?.width - 40
                }}>
                    <RecapItem title={"Défis"} item={classroomChallengeTemplate}/>
                    <RecapItem title={"Matière"}
                               item={props.schoolSubjects?.find(x => x?.id === classroom?.school_subject_id)}/>
                    <RecapItem title={"Activité"} item={currentActivityTemplate}/>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingVertical: 10
                    }}>
                        <TextM fontWeight={"Bold"}>
                            Elèves
                        </TextM>
                        {currentStudents?.map((s, index) =>
                            <View key={index} style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: "50%",
                            }}>
                                <IconImageM uri={s?.avatar} width={30}/>
                                <TextM wrap style={{
                                    paddingHorizontal: 10
                                }}>
                                    {s?.display_name}
                                </TextM>
                            </View>)}
                    </View>
                    <RecapItem title={"Date limite"} item={{title: currentDeadline, customIcon: calendar}}/>
                </View>
            </View>
        },
    ]


    const onSubmit = data => {
        if (props.postTeacherChallengeRequest) return
        const formData = {...defaultValues, ...data}

        //console.log(formData)
        formData.custom_planning = [{
            activity_template_id: formData.activity_template_id,
            date: moment(formData?.deadline).subtract(1, 'day')?.format('YYYY-MM-DD')
        }]
        props.apiRequest(schoolsService.postTeacherChallenge, {id: classroom?.id}, formData)
    }
    useApiStatus(
        schoolsService.postTeacherChallenge, // api service
        false, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        () => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Succés",
                    text: "Défi assigné !"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )
    return (
        <View style={{flex: 1, alignItems: 'center'}}>


            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         formValues={formValues}
                         onSubmit={onSubmit}
                         submitted={props.postTeacherChallengeRequest}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.postTeacherChallengeError}
            />
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state?.data?.currentUser?.object,
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        postTeacherChallengeRequest: state.api.postTeacherChallenge?.request,
        postTeacherChallengeError: state.api.postTeacherChallenge?.error,
        schoolSubjects: state.data.staticData?.school_subjects

    }
}
const mapDispatchToProps = {
    apiRequest,
    apiIdle
}
export default connect(mapStateToProps, mapDispatchToProps)(ClassroomNewChallenge)