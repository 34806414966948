export const liveModeConstants = {
    RESET_LIVE_MODE: 'RESET_LIVE_MODE',
    IS_LIVE_MODE_CHANGE: 'IS_LIVE_MODE_CHANGE',
    IS_TYPING: 'IS_TYPING',
    TYPING_USER: 'TYPING_USER',
    LIVE_PARTICIPANTS: 'LIVE_PARTICIPANTS',
    LIVE_TUTORS: 'LIVE_TUTORS',
    ADD_PARTICIPANT: 'ADD_PARTICIPANT',
    REMOVE_PARTICIPANT: 'REMOVE_PARTICIPANT',
    REMOVE_TUTOR: 'REMOVE_TUTOR',
    CLEAR_MEMBERS: 'CLEAR_MEMBERS',
    IN_CALL_USERS: 'IN_CALL_USERS',
    ADD_IN_CALL_USER: 'ADD_IN_CALL_USER',
    REMOVE_IN_CALL_USERS: 'REMOVE_IN_CALL_USERS',
    PHONE_RINGING: 'PHONE_RINGING',
    RESET_PHONE_RINGING: 'RESET_PHONE_RINGING',
    ADD_PHONE_RINGING: 'ADD_PHONE_RINGING',
    REMOVE_PHONE_RINGING: 'REMOVE_PHONE_RINGING',
    RAISED_HANDS: 'RAISED_HANDS',
    ADD_RAISED_HAND: 'ADD_RAISED_HAND',
    REMOVE_RAISED_HAND: 'REMOVE_RAISED_HAND',
    SET_CALL_PORTAL: "SET_CALL_PORTAL",
    SHOW_INCOMING_CALL: "SHOW_INCOMING_CALL",
    HIDE_INCOMING_CALL: "HIDE_INCOMING_CALL",
    SET_MESSAGES: "SET_MESSAGES",
    NEW_LIVE_SESSION_LOG: "NEW_LIVE_SESSION_LOG",
    SET_TIMES_SINCE_LAST_CALL: "SET_TIMES_SINCE_LAST_CALL",
    PLAY_SOUND: "PLAY_SOUND",
    ACTIVITY_FINALIZED: "ACTIVITY_FINALIZED",
    SOUND_MODE: 'SOUND_MODE',
    VOICE_RECORDING_ACTIVITY: 'VOICE_RECORDING_ACTIVITY',
    SET_ACTIVE_TIME: 'SET_ACTIVE_TIME',
    SET_CALL_KEEP_UUID: 'SET_CALL_KEEP_UUID',
}