import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import React, {useContext} from "react";
import {ActivityButtonContent} from "../../../student/organize/list/ActivityButtonContent";
import {theme} from "@common/theme/theme";
import {ScrollView, TouchableOpacity, View} from "react-native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {useNavigation} from "@react-navigation/native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {Badge, List} from "react-native-paper";
import {IconImageM} from "@common/components/icons/IconsM";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";

export const schoolSubjectCategoriesColors = {
    scientific: theme.colors.primary,
    "litterary/economic": theme.colors.accent,
    language: theme.colors.warning
}
export const schoolSubjectCategoriesBadgesColors = {
    scientific: theme.colors.primaryLighter,
    "litterary/economic": theme.colors.accentLighter,
    language: theme.colors.warningLight
}

export function SchoolSubjectCategoryBadge(props) {

    const {category} = props
    const color = schoolSubjectCategoriesColors[category]
    const badgeColor = schoolSubjectCategoriesBadgesColors[category]

    return <Badge style={{backgroundColor: badgeColor}}>
        <TextM color={color}>{category?.substring(0, 3)}</TextM>
    </Badge>

}

const BrowseByChallenge = (props) => {

    const navigation = useNavigation()
    const [expanded, setExpanded] = React.useState(false);

    const handlePress = () => setExpanded(!expanded);
    const goToActivity = (activityTemplate) => {
        navigation.push("activity-presentation", {
            activityTemplateId: activityTemplate?.id,
            isTutor: true
        })
    }

    const dimensions = useContext(DimensionsContext)

    return (
        <ScrollView>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: dimensions?.width,
                alignSelf: 'center',
                marginTop: 30
            }}>
                <View style={{
                    flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                }}>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingHorizontal: 10
                    }}>
                        <SchoolSubjectCategoryBadge category="scientific"/>
                        <TextM fontSize={12} style={{
                            paddingHorizontal: 5
                        }}>Matières scientifiques</TextM>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        paddingHorizontal: 10,
                        alignItems: 'center',
                    }}>
                        <SchoolSubjectCategoryBadge category="litterary/economic"/>
                        <TextM fontSize={12} style={{
                            paddingHorizontal: 5
                        }}>Matières littéraires ou économiques</TextM>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingHorizontal: 10
                    }}>
                        <SchoolSubjectCategoryBadge category="language"/>
                        <TextM fontSize={12} style={{
                            paddingHorizontal: 5
                        }}>Langues</TextM>
                    </View>
                </View>
                <List.AccordionGroup>
                    {props.challengeTemplates
                        ?.filter(item => item?.id !== 7)
                        ?.map((item1, index1) => {
                            const activityTemplates = props.activityTemplates
                                ?.filter(at => {
                                    // console.log(Object.values(at?.challenge_template_ids))
                                    return Object.values(at?.challenge_template_ids)
                                        ?.reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
                                        ?.includes(item1?.id)
                                })
                            return (
                                <List.Section id={index1} key={index1} style={{
                                    width: dimensions?.width,
                                }}>
                                    <List.Accordion
                                        style={{
                                            backgroundColor: theme.colors.white,
                                            borderColor: theme.colors.lightMore,
                                            borderTopWidth: 1,
                                            borderBottomWidth: 1,
                                            ...boxedCardBorderStyle
                                        }}
                                        title={<View style={{
                                            flexDirection: 'row',
                                            alignItems: 'stretch',
                                            justifyContent: 'center',
                                            width: "100%",
                                            alignSelf: 'center'
                                        }}>
                                            <IconImageM
                                                uri={item1?.icon}
                                                width={40}
                                                style={{marginHorizontal: 10}}
                                            />
                                            <TextM fontWeight={"Bold"} style={{marginTop: 10}}>{item1.title}</TextM>
                                        </View>
                                        }
                                        id={item1.title}
                                        expanded={expanded}
                                        onPress={handlePress}
                                    >
                                        <View style={{
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                            marginTop: 10,
                                        }}>
                                            {activityTemplates
                                                ?.sort((a, b) => {
                                                    // console.log("a et b : ", a.localeCompare(b))
                                                    return (a.title.localeCompare(b.title))
                                                })
                                                ?.map((item2, index2) => {

                                                    const categories = Object.keys(item2?.challenge_template_ids)
                                                        ?.reduce((previousValue, currentValue) => {

                                                            if (item2?.challenge_template_ids?.[currentValue]?.includes(item1?.id)) {
                                                                return previousValue.concat([currentValue])
                                                            } else {
                                                                return previousValue
                                                            }

                                                        }, [])

                                                    return (
                                                        <TouchableOpacity key={index2} style={{
                                                            //paddingHorizontal: 10,
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'flex-start',
                                                            width: IS_WEB && dimensions.width > 800 ? "25%" : "50%",
                                                            paddingVertical: 10,
                                                            paddingHorizontal: 10
                                                        }} onPress={() => {
                                                            goToActivity(item2)
                                                        }}>
                                                            <ActivityButtonContent activityDone={false}
                                                                                   backgroundColor={theme.colors.link}
                                                                                   activityTemplate={item2}
                                                                                   width={30}
                                                            />
                                                            <View>
                                                                <TextM
                                                                    fontSize={IS_WEB && dimensions.width > 800 ? 14 : 10}
                                                                    wrap
                                                                    fontWeight="Bold"
                                                                    style={{
                                                                        textAlign: "center",
                                                                        marginTop: 5,
                                                                        paddingHorizontal: 5
                                                                    }}
                                                                    color={theme.colors.primary}>{item2?.title}
                                                                </TextM>
                                                                <View style={{
                                                                    flexDirection: 'row',
                                                                    paddingHorizontal: 5
                                                                }}>
                                                                    {categories?.map((category, index) =>
                                                                        <SchoolSubjectCategoryBadge key={index}
                                                                                                    category={category}/>)}
                                                                </View>
                                                            </View>
                                                        </TouchableOpacity>


                                                    )
                                                })}
                                        </View>
                                    </List.Accordion>

                                </List.Section>
                            )
                        })}
                </List.AccordionGroup>
            </View>
        </ScrollView>
    )
}
const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates,
        challengeTemplates: state.data.staticData?.challenge_templates,
        user: state.data.currentUser?.object
    }
}
const mapDispatchToProps = {
    apiRequest,
    apiIdle
}
export default connect(mapStateToProps, mapDispatchToProps)(BrowseByChallenge)