import {TouchableOpacity, View} from "react-native";
import TextM from "@common/components/text/TextM";
import React, {useContext, useEffect} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {hasRole} from "@data/utility/ability";
import {studyProgramsService} from "@data/services/studyProgramsService";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {theme} from "@common/theme/theme";
import {ActivityIndicator} from "react-native-paper";
import {IconImageM} from "@common/components/icons/IconsM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

const StudyProgramsFilterList = (props) => {

    const {selectedStudyProgramId, setSelectedStudyProgramId, studentIds} = props

    const dimensions = useContext(DimensionsContext);

    const apiRequest = hasRole(props.user, "student")
        ? studyProgramsService.getStudyProgramsAsStudent
        : studyProgramsService.getStudyProgramsAsTutor;

    const request = props.getStudyProgramsAsTutorRequest || props.getStudyProgramsAsStudentRequest

    useApiStatus(
        apiRequest, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            if (selectedStudyProgramId === null && props.studyPrograms?.length > 0) {
                setSelectedStudyProgramId(props.studyPrograms[0].id)
            }
        }
    )

    useEffect(() => {
        props.apiRequest(apiRequest, {}, {}, {student_ids: studentIds})
    }, [])

    return <View style={{
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        width: dimensions?.width,
        paddingHorizontal: 20,
        paddingVertical: 10,
        borderBottomWidth: props.studyPrograms?.length > 0 ? 0.5 : 0,
        borderColor: theme.colors.lightMore
    }}>
        {request ? <ActivityIndicator color={theme.colors.primary}
        /> : props.studyPrograms?.length > 0 ? <>
            {/*<TextM style={{marginRight: 20}} fontWeight={"Bold"}>Par programme : </TextM>*/}
            {props.studyPrograms.map((studyProgram, index) => {
                const selected = selectedStudyProgramId === studyProgram.id
                return <TouchableOpacity style={{
                    backgroundColor: selected ? theme.colors.white : theme.colors.whiteAlmost,
                    borderColor: selected ? theme.colors.primary : theme.colors.light,
                    paddingHorizontal: 10,
                    paddingVertical: 7,
                    borderWidth: selected ? 2 : 1,
                    borderRadius: 5,
                    marginRight: 10,
                    flexDirection: "row",
                    alignItems: "center"
                }} key={index}
                                         onPress={() => {
                                             setSelectedStudyProgramId(studyProgram.id)
                                         }}>
                    <IconImageM uri={studyProgram?.school_subject?.icon}
                                style={{
                                    marginRight: 5
                                }}
                                width={20}/>
                    <TextM color={selected ? theme.colors.primary : theme.colors.grey}
                           size={12}
                           fontWeight={selected ? "Bold" : "Regular"}>{studyProgram?.school_subject?.name} - {studyProgram.class} - {studyProgram.title}</TextM>
                </TouchableOpacity>
            })}
            {/*  Unselect icon button  */}
            {selectedStudyProgramId ? <TouchableOpacity
                style={{
                    paddingHorizontal: 8,
                    paddingVertical: 5,
                }}
                onPress={() => {
                    setSelectedStudyProgramId(null)
                }}>
                <FontAwesome5 name={"times-circle"}
                              color={theme.colors.greyAlt}
                              size={20}/>
            </TouchableOpacity> : <></>}
        </> : <>
            <TextM style={{}} fontWeight={"Regular"}>Aucun programme</TextM>
        </>}
    </View>;
}

const mapStateToProps = (state) => {
    return {
        getStudyProgramsAsStudentRequest: state.api.getStudyProgramsAsStudent?.request,
        getStudyProgramsAsTutorRequest: state.api.getStudyProgramsAsTutor?.request,
        user: state.data.currentUser?.object,
        studyPrograms: state.data.studyPrograms?.studyPrograms,
        schoolSubjects: state.data.staticData?.school_subjects
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StudyProgramsFilterList);