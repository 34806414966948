import 'react-native-gesture-handler';
import React, {useEffect, useRef, useState} from 'react';
import {Provider as PaperProvider} from 'react-native-paper';
import {NavigationContainer, useNavigationContainerRef} from "@react-navigation/native";
import {theme} from "@common/theme/theme";
import {useFonts} from "expo-font";
import axios from "axios"
import {Provider as StoreProvider} from 'react-redux';
import {persistor, store} from "@data/redux/storeConfig/store"
import {PersistGate} from 'redux-persist/lib/integration/react';
import RootNavigator from "./src/common/navigation/RootNavigator";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {abilitiesForRoles} from "@data/utility/ability";
import {AbilityContext} from "@data/utility/Can";
import {Ability} from "@casl/ability";
import {initialAbility} from "@data/utility/initialAbility";
import {API_URL} from "@data/constants/apiConstants";
import * as Notifications from "expo-notifications";
import {BottomSheetModalProvider} from "@gorhom/bottom-sheet";
import {ErrorBoundaryFallbackView} from "@common/screens/ErrorBoundaryFallbackView";
import {TourGuideProvider} from "rn-tourguide";
import {ChampionTooltipM} from "./src/student/champions/ChampionTooltipM";
import * as amplitude from '@amplitude/analytics-react-native';
import Constants from 'expo-constants';
import 'expo-dev-client';
import {PusherContext} from "@common/messenger/socketHook";
import {DimensionsContext, IS_WEB, NavigationRefContext} from "@common/utils/mobileUtils";
import {Dimensions, Platform} from "react-native";
import {MenuProvider} from "react-native-popup-menu";
import {SnackBarM} from "@common/components/alert/snackbar/SnackBar";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {GestureHandlerRootView} from "react-native-gesture-handler";
import {navigationScreens} from "@common/navigation/navigationScreens";
import {initPusher} from "@common/utils/initPusher";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import * as Linking from 'expo-linking'
//check if the browser is opened in mobile
import {initBugsnag, initErrorBoundary} from "@common/utils/initBugsnag";
import {useGTMWeb} from "@common/hooks/useGTMWeb";
import {useMetaPixel} from "@common/hooks/useMetapixel";
import {useReduxDevToolsExtension} from "@react-navigation/devtools";

const prefix = Linking.createURL("/")

initBugsnag()
const ErrorBoundary = (!__DEV__) ? initErrorBoundary() : null


if (Constants.expoConfig.extra.amplitude?.apiKey
    && JSON.stringify(Constants.expoConfig.extra.amplitude?.apiKey) !== "{}") {
    amplitude.init(Constants.expoConfig.extra.amplitude?.apiKey, null, {
        serverZone: amplitude.Types.ServerZone.EU
    })
}

if (!IS_WEB)
    Notifications.setNotificationHandler({
        handleNotification: async () => ({
            shouldShowAlert: true,
            shouldPlaySound: true,
            shouldSetBadge: true
        })
    });

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

function App() {

    useGTMWeb() // google tag manager
    useMetaPixel() // facebook pixel

    const pusher = initPusher(store.dispatch)

    const [dimensions, setDimensions] = useState({window, screen});
    const navigationRef = useNavigationContainerRef();
    const routeNameRef = useRef("");

    if (__DEV__)
        useReduxDevToolsExtension(navigationRef);

    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            "change",
            ({window, screen}) => {
                setDimensions({window, screen});
            }
        );
        return () => subscription?.remove();
    }, []);

    const [loaded] = useFonts({
        "Owl-Cute": require('./assets/fonts/Owl-Cute.ttf'),
        "Montserrat-Black": require('./assets/fonts/Montserrat-Black.ttf'),
        "Montserrat-BlackItalic": require('./assets/fonts/Montserrat-BlackItalic.ttf'),
        "Montserrat-Bold": require('./assets/fonts/Montserrat-Bold.ttf'),
        "Montserrat-BoldItalic": require('./assets/fonts/Montserrat-BoldItalic.ttf'),
        "Montserrat-ExtraBold": require('./assets/fonts/Montserrat-ExtraBold.ttf'),
        "Montserrat-ExtraBoldItalic": require('./assets/fonts/Montserrat-ExtraBoldItalic.ttf'),
        "Montserrat-ExtraLight": require('./assets/fonts/Montserrat-ExtraLight.ttf'),
        "Montserrat-ExtraLightItalic": require('./assets/fonts/Montserrat-ExtraLightItalic.ttf'),
        "Montserrat-Italic": require('./assets/fonts/Montserrat-Italic.ttf'),
        "Montserrat-Light": require('./assets/fonts/Montserrat-Light.ttf'),
        "Montserrat-LightItalic": require('./assets/fonts/Montserrat-LightItalic.ttf'),
        "Montserrat-Medium": require('./assets/fonts/Montserrat-Medium.ttf'),
        "Montserrat-MediumItalic": require('./assets/fonts/Montserrat-MediumItalic.ttf'),
        "Montserrat-Regular": require('./assets/fonts/Montserrat-Regular.ttf'),
        "Montserrat-SemiBold": require('./assets/fonts/Montserrat-SemiBold.ttf'),
        "Montserrat-SemiBoldItalic": require('./assets/fonts/Montserrat-SemiBoldItalic.ttf'),
        "Montserrat-Thin": require('./assets/fonts/Montserrat-Thin.ttf'),
        "Montserrat-ThinItalic": require('./assets/fonts/Montserrat-ThinItalic.ttf')
    });

    if (!loaded) {
        return null;
    }

    const token = store.getState()?.data?.session?.object?.token

    // ** Configure axios to make requests
    axios.defaults.baseURL = API_URL
    if (token) axios.defaults.headers.common['Authorization'] = "Bearer " + token;

    if (IS_WEB) axios.defaults.withCredentials = true

    // ** Axios verbose
    const axiosVerbose = false
    if (axiosVerbose) {
        axios.interceptors.request.use(request => {
            console.log('Starting Request', JSON.stringify(request, null, 2))
            return request
        })

        axios.interceptors.response.use(response => {
            console.log('Response:', JSON.stringify(response, null, 2))
            return response
        }, error => {
            console.log('Error:', JSON.stringify(error, null, 2))
            return Promise.reject(error);
        })
    }

    // ** init abilities
    const userData = store.getState()?.data?.currentUser?.object
    const currentRoles = userData ? userData.roles : null
    const ability = new Ability(abilitiesForRoles(currentRoles) || initialAbility)

    // update abilities when role changes
    store.subscribe(() => {
        const prevRoles = currentRoles
        const userData = store.getState()?.data?.currentUser?.object
        const newRoles = userData ? userData.roles : null

        if (prevRoles !== newRoles) {
            ability.update(abilitiesForRoles(newRoles))
        }
    });

    const defaultRoutes = Object.values(navigationScreens)
        ?.reduce((pValue, cValue) => {
            return [...pValue, ...cValue]
        }, [])
        ?.reduce((pValue, cValue) => {
            return {...pValue, ...{[cValue?.name]: cValue?.name}}
        }, {})

    //Linking and routes on web
    const config = {
        initialRouteName: 'menu',
        screens: {
            ...defaultRoutes,
            menu: {
                screens: {
                    "dashboard": 'menu/dashboard',
                    "tutor-resources": 'menu/tutor-resources',
                    "students": 'menu/students',
                    "activities-review": 'menu/activities-review',
                    "admin-actions": 'menu/admin-actions',
                    "referrals-backoffice": 'menu/referrals-backoffice',
                    "live": 'menu/live',
                    "finished-activities-live": 'menu/finished-activities-live',
                    "live-students": 'menu/live-students',
                    "live-activities-review": 'menu/live-activities-review',
                    "live-tutor-resources": 'menu/live-tutor-resources',
                    "home": 'menu/home',
                    "finished-activities": 'menu/finished-activities',
                    "skill-sets": 'menu/skill-sets',
                    "rankings": 'menu/rankings',
                    "tutor": 'menu/tutor'
                }
            },
            "not-found": "*"
        }

        // screens: routes,
        // screens: {},
    };

    const linking = {
        prefixes: [prefix, "https://app.masteur.com/"],
        config
    };

    const width = Platform.OS !== 'web' ? dimensions?.window?.width : Dimensions.get('window').width > 800 ? dimensions?.window?.width * 2 / 3 : dimensions?.window?.width
    const height = Dimensions.get('window').height

    return (

        <StoreProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AbilityContext.Provider value={ability}>
                    <MenuProvider customStyles={{
                        backdrop: {
                            backgroundColor: theme.colors.black,
                            opacity: 0.5
                        }
                    }}>
                        <GestureHandlerRootView style={{
                            flex: 1
                        }}>
                            <NavigationRefContext.Provider
                                value={{navigationRef}}>
                                <PaperProvider theme={theme}
                                               settings={{
                                                   icon: props => <FontAwesome5 solid {...props} />
                                               }}>
                                    <NavigationContainer linking={linking}
                                                         documentTitle={{
                                                             formatter: (options, route) =>
                                                                 `${options?.headerTitle ?? route?.name} - Masteur` + (Constants.expoConfig.extra.releaseStage === "production" ? "" : " (" + Constants.expoConfig.extra.releaseStage + ")")
                                                         }}
                                                         ref={navigationRef}
                                                         onReady={() => {
                                                             routeNameRef.current = navigationRef.getCurrentRoute().name;
                                                         }}
                                                         onStateChange={async () => {
                                                             const previousRouteName = routeNameRef.current;
                                                             const currentRouteName = navigationRef.getCurrentRoute().name;
                                                             const trackScreenView = (currentRouteName) => {
                                                                 // Your implementation of analytics goes here!
                                                                 if (userData) {
                                                                     const identifyObj = new amplitude.Identify();
                                                                     identifyObj.set('roles', userData?.roles);
                                                                     identifyObj.set('subscription_state', userData?.subscription_state?.status);
                                                                     amplitude.identify(identifyObj)
                                                                 amplitude.setUserId(userData?.email)
                                                                 }

                                                                 amplitude.track("screen.viewed." + currentRouteName)
                                                             };

                                                             if (previousRouteName !== currentRouteName) {
                                                                 // Save the current route name for later comparison
                                                                 routeNameRef.current = currentRouteName;

                                                                 // Replace the line below to add the tracker from a mobile analytics SDK
                                                                 trackScreenView(currentRouteName);
                                                             }
                                                         }}
                                                         theme={theme}>
                                        <PusherContext.Provider value={pusher}>
                                            <TourGuideProvider borderRadius={16}
                                                               tooltipComponent={ChampionTooltipM}
                                                               preventOutsideInteraction
                                                               animationDuration={50}
                                                               androidStatusBarVisible={false}>
                                                {!IS_WEB ?
                                                    <BottomSheetModalProvider>
                                                        <DimensionsContext.Provider
                                                            value={{width, height}}>
                                                            <RootNavigator/>
                                                            <SnackBarM/>
                                                            <DialogM/>
                                                            <PlaySoundM/>
                                                        </DimensionsContext.Provider>
                                                    </BottomSheetModalProvider>
                                                    : <DimensionsContext.Provider
                                                        value={{width, height}}>
                                                        <RootNavigator/>
                                                        <SnackBarM/>
                                                        <DialogM/>
                                                        <PlaySoundM/>
                                                    </DimensionsContext.Provider>
                                                }
                                            </TourGuideProvider>


                                        </PusherContext.Provider>
                                    </NavigationContainer>
                                </PaperProvider>
                            </NavigationRefContext.Provider>
                        </GestureHandlerRootView>
                    </MenuProvider>
                </AbilityContext.Provider>
            </PersistGate>
        </StoreProvider>

    );
}

// ** Error Boundary with Bugsnag
export default () => (!__DEV__) ? <ErrorBoundary FallbackComponent={ErrorBoundaryFallbackView}>
    <App/>
</ErrorBoundary> : <App/>