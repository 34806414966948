import React from 'react';
import {Title} from "react-native-paper";
import {theme} from "../../theme/theme";

const TitleM = (props) => {

    const {text, fontWeight, color} = props

    return <Title
        {...props}
        style={{
            ...props.style,
            color: color ? color : theme.colors.primary,
            fontFamily: "Montserrat-" + (fontWeight ?? "Bold"),
            // alignSelf: 'center'
        }}
    >
        {props.children}
    </Title>
}

export default TitleM