import React, {useEffect, useState} from 'react';
import {View} from "react-native";
import ProgressStepper from "./ProgressStepper";
import {useForm} from "react-hook-form";
import {FormStep} from "./FormStep";
import * as amplitude from "@amplitude/analytics-react-native";

export const FormStepper = (props) => {

    // ** Component props
    const {
        navigation,
        formData,
        champion,
        formValues,
        setFormValues,
        onSubmit,
        submitted,
        submittedMessage,
        circularProgress,
        circularProgressValue,
        serverError,
        hideProgressBar = false,
        resetsToDefaultOnNext,
        previousRouteName = "menu",
        track = false,
        submitButtonText = "Terminer",
        exitRouteName = "menu",
        noPrevious = false,
        noGoBack = false,
        exitWarning = false,
        exitWarningMessage = "Les données entrées seront perdues.",
        noNavigation = false
    } = props


    const defaultValues = formData?.reduce((pValue, cValue) => {

        if (cValue?.name) {
            const nValue = {...pValue}
            nValue[cValue?.name] = cValue?.defaultValue ?? null
            return nValue

        } else if (cValue?.inputs) {
            const nValue = {...pValue}

            cValue?.inputs?.forEach(i => {
                nValue[i?.name] = i?.defaultValue ?? null
            })

            if (cValue?.checkBoxes) {

                cValue?.checkBoxes?.forEach(cb => {
                    nValue[cb?.name] = cb?.defaultValue ?? null
                })


            }
            return nValue

        }


    }, {})

    useEffect(() => {
        if (!formValues)
            setFormValues(defaultValues)
    }, []);


    // console.log(defaultValues)

    // console.log(formSchema.tests)
    // const resolver = yupResolver(formSchema);
    const {
        control,
        handleSubmit,
        formState: {errors, dirtyFields, isValid, isDirty},
        resetField,
        trigger,
        register,
        setError,
        getValues
    } = useForm({
        // mode: 'all',
        // resolver,
        defaultValues
    })


    const [currentStep, setCurrentStep] = useState(1)
    const isFirstStep = currentStep === 1
    const isLastStep = currentStep === formData?.length
    const goToNextStep = async () => {

        if (track)
            amplitude.track("form.step.finished:" + (formData?.[currentStep - 1]?.name ?? formData?.[currentStep - 1]?.inputs?.map(x => x?.name).join(',') ?? "unknown"))

        if (!isLastStep) {
            // update form values of parent screen to update form data accordingly
            setFormValues({...control._formValues})
            //console.log(formValues)
            const result = await trigger();
            // console.log(getValues())
            // console.log(result, isValid)

            if (!isLastStep && result) setCurrentStep(cs => cs + 1)
        } else {
            await handleSubmit(onSubmit)()
        }
    }

    return (
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1
        }}>
            <ProgressStepper navigation={navigation}
                             noNavigation={noNavigation}
                             currentStep={currentStep}
                             setCurrentStep={setCurrentStep}
                             track={track}
                             noPrevious={noPrevious}
                             noGoBack={noGoBack}
                             exitWarning={isDirty || exitWarning}
                             exitWarningMessage={exitWarningMessage}
                             hideProgressBar={hideProgressBar}
                             onNextButtonPress={goToNextStep}
                             champion={champion}
                             champions={formData?.map(x => {
                                 if (x?.champion) {
                                     return x?.champion
                                 } else
                                     return null
                             })}
                             championLabels={formData?.map(x => x?.label)}
                             submitted={submitted}
                             circularProgress={circularProgress}
                             circularProgressValue={circularProgressValue}
                             submittedMessage={submittedMessage}
                             previousRouteName={previousRouteName}
                             exitRouteName={exitRouteName}
                             submitButtonText={submitButtonText}
                             steps={formData?.map((x, index) => {
                                 const isRequired = (formDataItem) => {
                                     if (formDataItem?.type === "text.multiple") {
                                         return !!formDataItem?.validationRules?.required
                                     } else {
                                         return !!formDataItem?.inputs?.reduce((prev, cur) => prev && !!cur?.validationRules?.required, true)
                                     }
                                 }
                                 const customCondition = (formDataItem) => {
                                     return !!formDataItem?.validationRules?.customCondition
                                 }
                                 const isMinLength = (formDataItem) => {
                                     if (formDataItem?.type === "select.multiple") {
                                         return !!formDataItem?.validationRules?.minLength
                                     }
                                     return false
                                 }

                                 const isEmpty = (formDataItem) => {
                                     if (formDataItem?.type === "select.multiple") {
                                         return control._formValues?.[formDataItem?.name]?.length === 0
                                     } else if (formDataItem?.type === "select.single") {
                                         return control._formValues?.[formDataItem?.name] === "" || control._formValues?.[formDataItem?.name] === null
                                     } else if (formDataItem?.type === "photos") {
                                         return control._formValues?.[formDataItem?.name]?.file_keys?.length === 0
                                     }

                                     return false
                                 }

                                 const isStepDirty = ((x.type === "text.multiple") && x.inputs?.reduce((pValue, cValue) => pValue || dirtyFields[cValue.name], false))
                                     || ((x.type === "text.single") && dirtyFields[x.name])

                                 // console.log ("length of ", x?.name, " : ", control._formValues?.[x.name]?.length)

                                 const hideNextButton = !x?.showNextButton && (((x.type === "select.single") && !isLastStep)
                                     || (
                                         isRequired(x) && (
                                             (x.type === "text.multiple") && x.inputs?.reduce((pValue, cValue) => pValue || !dirtyFields[cValue.name], false))
                                         || ((x.type === "select.multiple") && isEmpty(x))
                                         || ((x.type === "select.single") && isEmpty(x))
                                         //TODO : delete this deprecated case (use text.multiple instead)
                                         || ((x.type === "text.single") && !dirtyFields[x.name])
                                         || ((x.type === "photos") && !dirtyFields[x.name])
                                     )
                                     || isMinLength(x) && control._formValues?.[x.name]?.length < x?.validationRules?.minLength?.value
                                     || customCondition(x) && x?.validationRules?.customCondition?.value
                                     || (x.type === "custom" && !x?.showNextButton)
                                     || x.type === "select.two.oriented"
                                     || x.type === "select.card.single"
                                     || x.type === "select.grouped.single")


                                 return {
                                     content: <FormStep key={index}
                                                        index={index}
                                                        track={track}
                                                        data={x}
                                                        setError={setError}
                                                        resetsToDefaultOnNext={resetsToDefaultOnNext}
                                                        goToNextStep={goToNextStep}
                                                        isStepDirty={isStepDirty}
                                                        trigger={trigger}
                                                        setFormValues={setFormValues}
                                                        register={register}
                                                        champion={x?.champion ?? champion}
                                                        control={control}
                                                        isLastStep={isLastStep}
                                                        serverError={serverError}
                                                        hideNextButton={hideNextButton}
                                                        errors={errors}
                                                        formValues={formValues}
                                     />,
                                     hideNextButton
                                 }
                             })}/>
        </View>
    );
}