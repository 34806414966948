import * as yup from 'yup'

yup.setLocale({
    mixed: {
        required: 'Champ obligatoire'
    },
    string: {
        email: 'Email invalide'
    }
})

export default yup

const required = () => {
    return {required: "Champ obligatoire"}
}

const maxLength = (value) => {
    return {
        maxLength: {
            value: value,
            message: `${value} caractères maximum`
        }
    }
}

const number = () => pattern(numberRegExp, "Nombre invalide")

const min = (value) => {
    return {
        min: {
            value: value,
            message: `La valeur minimum est de ${value}`
        }
    }
}
const max = (value) => {
    return {
        max: {
            value: value,
            message: `La valeur maximum est de ${value}`
        }
    }
}

const differentFrom = (value) => {
    return {
        validate: val => {

            return val?.toLowerCase() !== value?.toLowerCase() ? true : `Doit être différent de ${value}`
        }
    }
}

const minArrayLength = (value) => {
    return {
        validate: {
            value: val => val?.length >= value,
            message: `${value} choix minimum`
        }
    }
}

const minLength = (value) => {
    return {
        minLength: {
            value: value,
            message: `${value} caractères minimum`
        }
    }
}
const pattern = (value, message = `Champ invalide`) => {
    return {
        pattern: {
            value: value,
            message: message
        }
    }
}

const iban = () => pattern(ibanRegExp, "IBAN invalide")

export function validateIbanChecksum(iban) {
    if (!iban) return false

    const ibanStripped = iban.replace(/[^A-Z0-9]+/gi,'') //keep numbers and letters only
        .toUpperCase(); //calculation expects upper-case
    const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
    if(!m) return false;

    const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g,function(ch){
        //replace upper-case characters by numbers 10 to 35
        return (ch.charCodeAt(0)-55);
    });
    //The resulting number would be to long for javascript to handle without loosing precision.
    //So the trick is to chop the string up in smaller parts.
    const mod97 = numbericed.match(/\d{1,7}/g)
        .reduce(function(total, curr){ return Number(total + curr)%97},'');

    return (mod97 === 1);
}
const customCondition = (value, message = `Champ invalide`) => {
    return {
        customCondition: {
            value: value,
            message: message
        }
    }
}

export const ibanRegExp = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/
export const emailRegExp = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/
export const numberRegExp = /^[1-9][.\d]*(,\d+)?$/

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
export const urlRegExp = new RegExp(
    "^" +
    // protocol identifier
    "(?:(?:https?|ftp)://)" +
    // user:pass authentication
    "(?:\\S+(?::\\S*)?@)?" +
    "(?:" +
    // IP address exclusion
    // private & local networks
    "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
    "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
    "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
    // IP address dotted notation octets
    // excludes loopback network 0.0.0.0
    // excludes reserved space >= 224.0.0.0
    // excludes network & broacast addresses
    // (first & last IP address of each class)
    "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
    "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
    "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
    "|" +
    // host name
    "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
    // domain name
    "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
    // TLD identifier
    "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
    // TLD may end with dot
    "\\.?" +
    ")" +
    // port number
    "(?::\\d{2,5})?" +
    // resource path
    "(?:[/?#]\\S*)?" +
    "$",
    "i"
)

const email = () => pattern(emailRegExp, "Email invalide")

const phone = () => pattern(phoneRegExp, "Le numéro de téléphone est invalide")

const url = (value) => {
    return {
        validate: {
            checkUrl: async () => await fetch(value) || 'URL invalide'  // JS only: <p>error message</p> TS only support string
        }
    }
}
const checkUrl = () => pattern(urlRegExp, "URL invalide")

export const validation = {
    required,
    differentFrom,
    minLength,
    minArrayLength,
    maxLength,
    pattern,
    email,
    phone,
    url,
    customCondition,
    checkUrl,
    min,
    max,
    number,
    iban
}