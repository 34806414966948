import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import SuccessAnimation from "@common/animations/SuccessAnimation";


const LeagueCongrats = (props) => {
    const dimensions = useContext(DimensionsContext)


    return (
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 10,
            paddingHorizontal: 15,
            width: dimensions?.width
        }}>
            <SuccessAnimation
                size={100}
                iconSize={100 * 0.7}
                dotColor={theme.colors.success}
                backgroundColor={theme.colors.success}
                iconColor={"white"}
                dotSize={20}
                duration={2000}
                style={{
                    // position: "absolute",
                    // alignSelf: "center",
                    marginBottom: 100 / 3,
                    // right: -successSize / 3
                    // right: -30
                }}
                animatedLayerColor={"white"}
            />

            <TextM fontSize={22}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center"
                       // marginTop: 50
                   }}
                   color={theme.colors.primary}>
                {props.congratsText?.toUpperCase()}
            </TextM>
            {props.rankings}

        </View>)
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LeagueCongrats)