import {View} from "react-native";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../assets/icons/generic/live.png";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React from "react";
import {connect} from "react-redux";
import {BadgeM} from "@common/components/badge/BadgeM";
import {hasRole} from "../../data/utility/ability";

const LiveModeParticipantsIcon = (props) => {

    const liveSession = props.currentLiveSession
    const badgeCount = liveSession?.unread_messages_count
    const isHandRaised = props.raisedHands?.includes(props.user?.id)

    return <View style={{
        alignItems: "center",
        flexDirection: "row",
        padding: 12,
    }}>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center'
        }}>
            <IconImageM source={live}
                        width={26}
                        style={{
                            tintColor: theme.colors.success
                        }}
            />
            <TextM fontSize={18} fontWeight={"ExtraBold"} color={theme.colors.success} style={{
                paddingLeft: 5
            }}>
                LIVE
            </TextM>
        </View>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 20,
        }}>
            <FontAwesome5 solid name="user-friends" size={32} color={theme.colors.primary}/>
            {props.participants?.length > 0 && <BadgeM count={props.participants?.length}
                                                       style={{
                                                           backgroundColor: theme.colors.light,
                                                           color: theme.colors.white,
                                                           position: "absolute",
                                                           top: -8,
                                                           right: -8
                                                       }}/>}
        </View>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 20,
        }}>
            <FontAwesome5 solid name="comment" size={32} color={theme.colors.primary}/>
            {badgeCount > 0 && <BadgeM count={badgeCount}
                                       style={{
                                           backgroundColor: theme.colors.error,
                                           color: theme.colors.white,
                                           position: "absolute",
                                           top: -8,
                                           right: -8
                                       }}/>}
        </View>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: 20,
        }}>
            <FontAwesome5 name="hand-paper"
                          solid size={32}
                          color={isHandRaised ? theme.colors.accent : theme.colors.primary}/>
            {props.raisedHands?.length > 0 && <BadgeM count={props.raisedHands?.length}
                                                      style={{
                                                          backgroundColor: theme.colors.error,
                                                          color: theme.colors.white,
                                                          position: "absolute",
                                                          // borderWidth: 0.8,
                                                          // borderColor: theme.colors.primary,
                                                          top: -8,
                                                          right: -8
                                                      }}/>}
        </View>
    </View>
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        participants: state.liveMode?.liveParticipants,
        inCallUsers: state.liveMode?.inCallUsers,
        raisedHands: state.liveMode?.raisedHands,
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LiveModeParticipantsIcon)