import {pusherConstants} from "@data/constants/pusherConstants";

const initialState = {
    status: null
}

export default function pusher(state = initialState, action) {
    switch (action.type) {
        case pusherConstants.SET_PUSHER_STATUS:
            return {
                ...state,
                status: action.status
            }
        default:
            return state
    }
}
