import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dimensions, Keyboard, Platform, ScrollView, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import LinkTextM from "@common/components/text/LinkTextM";
import {useController, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import * as Device from 'expo-device';
import ErrorTextM from "@common/components/text/ErrorTextM";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {Checkbox} from "react-native-paper";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import yup from "@data/utility/validation";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {subscriptionService} from "@data/services/subscriptionService";
import {referralCodeRetrieve} from "./StartTrialNewScreen";
import PhoneInput from "@sesamsolutions/phone-input";
import * as amplitude from "@amplitude/analytics-react-native";
import RegisterTrialPopup from "@common/messenger/RegisterTrialPopup";
import {SelectInputM} from "./SelectInputM";
import {studentClasses} from "@data/constants/formConstants";
import live from "../../../../assets/icons/generic/live.png";
import {IconImageM} from "@common/components/icons/IconsM";


const RegisterSpecialScreen = (props) => {

    const {navigation, route} = props

    const referralCode = route?.params?.ref ?? null
    const studentClass = route?.params?.student_class

    const user = props.user

    useEffect(() => {
        referralCodeRetrieve(navigation, route).then()
    }, []);

    const dimensions = useContext(DimensionsContext)

    const formSchema = yup.object().shape({
        user_profile: yup.string().required(),
        special_course: yup.string().required(),
        student_class: yup.string().required(),
        email: yup.string().email().required(),
        parent_mobile: yup.string().required(),
        parent_email: yup.string().email().required(),
        firstname: yup.string().required(),
        lastname: yup.string().required(),
        password: yup.string().required(),
        newsletter_opt_in: yup.boolean().required()
    })
    const resolver = yupResolver(formSchema);

    const {control, handleSubmit, formState: {errors, isDirty, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            user_profile: "student",
            special_course: "commentary",
            student_class: studentClass ?? user?.profile?.class,
            email: user?.email ?? '',
            parent_email: user?.parent_email ?? '',
            parent_mobile: user?.parent_mobile ?? '',
            firstname: user?.firstname ?? '',
            lastname: user?.lastname ?? '',
            password: !user ? '' : 'test1234',
            'device_name': Device.deviceName ?? "WEB",
            newsletter_opt_in: true,
            referral_code: referralCode
        }
    })

    const [submittedOnce, setSubmittedOnce] = useState(false)

    const {field: newsletterOptInField} = useController({
        name: "newsletter_opt_in",
        control
    })

    const {field: parentMobileField} = useController({
        name: "parent_mobile",
        control
    })

    const {field: emailField} = useController({
        name: "email",
        control
    })

    const service = !props.user ? authService.registerSpecialV2 : subscriptionService.subscribeToSpecialV2
    const request = !props.user ? props.registerSpecialV2Request : props.subscribeToSpecialV2Request

    const onSubmit = data => {
        setSubmittedOnce(true)

        if (request) {
            return
        }

        if (!parentMobileField.value) {
            return
        }

        Keyboard.dismiss()

        props.apiRequestWithPrerequest(service, {}, data, {})
    }

    const onError = (error) => {
        console.log(error)
        console.log(control._formValues)
    }

    useApiStatus(
        service, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            amplitude.track("register.special.success")

            navigation.goBack()
        } // success callback
    )

    const insets = useSafeAreaInsets()

    const scrollViewRef = useRef()
    const firstnameRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    const [sameEmailAddress, setSameEmailAddress] = useState(true)

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            justifyContent: 'flex-start',
            paddingTop: insets.top,
            // width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center",
            alignItems: "center",
            width: "100%"
        }}>
            <ScrollView ref={scrollViewRef}
                        contentContainerStyle={{
                            paddingBottom: 200,
                            width: Dimensions.get('screen').width,
                            alignItems: "center"
                        }}
            >

                <View style={{
                    paddingHorizontal: 15,
                    paddingTop: 40,
                    width: dimensions?.width,
                    alignItems: "center"
                }}>

                    <TextM fontSize={24}
                           fontWeight={"ExtraBold"}
                           color={theme.colors.primary}
                           style={{
                               textAlign: "center",
                               marginBottom: 10,
                               marginHorizontal: dimensions?.width > 800 ? 100 : 20
                           }}
                           wrap
                    >Plus qu'une étape pour vous inscrire au prochain Live spécial commentaire de texte !</TextM>
                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 20,
                        borderRadius: 10,
                        borderColor: theme.colors.success,
                        backgroundColor: theme.colors.successLight,
                        borderWidth: 1,
                        padding: 5,
                        paddingHorizontal: 10
                    }}>
                        <IconImageM source={live}
                                    width={24}
                                    style={{
                                        tintColor: theme.colors.success,
                                        // rotate: "-45deg",
                                        marginRight: 10
                                    }}
                        />
                        <TextM fontSize={20}
                               fontWeight={"SemiBold"}
                               color={theme.colors.success}
                               style={{textAlign: "center", marginBottom: 0}}
                               wrap
                        >Samedi 3 février, de 10h à 12h</TextM>
                    </View>


                    <>
                        <View style={{
                            alignItems: "flex-start",
                            flexDirection: "row",
                            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                            // paddingHorizontal: 20,
                            paddingVertical: 15
                        }}>
                            <SelectInputM options={studentClasses.map(x => ({label: x, value: x}))?.slice(4, 7)}
                                          name={"student_class"}
                                          label={"Classe de l'élève"}
                                          sliderLabel={"Choisir la classe de l'élève"}
                                          onSelect={(value) => {
                                          }}
                                          control={control}
                                          style={{
                                              flex: 1,
                                              marginRight: 10
                                          }}
                                          errors={errors}
                            />
                        </View>
                    </>

                    {!props.user && <View style={{
                        alignItems: "flex-start"
                    }}>

                        <View style={{
                            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4
                        }}>
                            <TextM style={{alignSelf: "flex-start"}}>Téléphone portable d'un parent</TextM>
                            <PhoneInput
                                initialCountry="FR"
                                dismissKeyboard={false}
                                textStyle={{
                                    fontFamily: "Montserrat-Regular",
                                    fontSize: 16
                                }}
                                style={{
                                    width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                                    borderColor: (!parentMobileField.value && submittedOnce) ? theme.colors.error : theme.colors.light,
                                    borderWidth: 1,
                                    borderRadius: theme.roundness,
                                    height: 55,
                                    alignItems: "center",
                                    paddingHorizontal: 10
                                }}
                                onChange={(data) => {
                                    parentMobileField.onChange(data?.e164)
                                }}/>
                            {!parentMobileField.value && submittedOnce &&
                                <ErrorTextM errorData={{message: "Numéro de téléphone invalide"}}
                                            style={{textAlign: "center"}} marginTop={3}/>}
                        </View>
                        <TextInputM
                            placeholder={`Adresse email d'un parent`}
                            labelTop={`Adresse email d'un parent`}
                            // label={`Adresse email de l'élève`}
                            name="parent_email"
                            ref={emailRef}
                            textContentType="emailAddress"
                            keyboardType="email-address"
                            control={control}
                            errors={errors}
                            contentContainerStyle={{marginTop: 10}}
                            onSubmitEditing={(e) => {
                                firstnameRef.current?.focus()
                            }}
                            onChangeAdditional={(value) => {
                                if (sameEmailAddress) emailField.onChange(value)
                            }}
                        />

                        <Checkbox.Item label="Utiliser la même adresse email pour l'élève"
                                       position={'leading'}
                                       onPress={() => {
                                           if (!sameEmailAddress) {
                                               emailField.onChange(control._formValues?.parent_email)
                                           } else {
                                               emailField.onChange("")
                                           }
                                           setSameEmailAddress(!sameEmailAddress)

                                       }}
                                       color={theme.colors.primary}
                                       labelStyle={{
                                           fontSize: 14,
                                           textAlign: "left"
                                       }}
                                       style={{
                                           marginTop: 10
                                       }}
                                       status={sameEmailAddress ? "checked" : "unchecked"}/>

                        {!sameEmailAddress && <TextInputM
                            placeholder={`Adresse email de l'élève`}
                            labelTop={`Adresse email de l'élève`}
                            // label={`Adresse email de l'élève`}
                            name="email"
                            ref={emailRef}
                            textContentType="emailAddress"
                            keyboardType="email-address"
                            liveCheck={{service: authService.checkEmailAddress}}
                            liveCheckPreRequest={true}
                            control={control}
                            errors={errors}
                            contentContainerStyle={{marginTop: 10}}
                            onSubmitEditing={(e) => {
                                passwordRef.current?.focus()
                            }}
                        />}


                        <View style={{
                            alignItems: "flex-start",
                            flexDirection: "row",
                            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                            // paddingHorizontal: 20,
                            paddingTop: 15
                        }}>
                            <TextInputM
                                placeholder={`Prénom de l'élève`}
                                labelTop={`Prénom de l'élève`}
                                // label={`Adresse email de l'élève`}
                                ref={firstnameRef}
                                name="firstname"
                                control={control}
                                errors={errors}
                                contentContainerStyle={{
                                    width: null,
                                    flex: 1,
                                    marginRight: 10
                                }}
                                onSubmitEditing={(e) => {
                                    lastnameRef.current?.focus()
                                }}
                            />
                            <TextInputM
                                placeholder={`Nom de l'élève`}
                                labelTop={`Nom de l'élève`}
                                // label={`Adresse email de l'élève`}
                                name="lastname"
                                ref={lastnameRef}
                                control={control}
                                errors={errors}
                                contentContainerStyle={{
                                    width: null,
                                    flex: 1
                                }}
                                onSubmitEditing={(e) => {
                                    passwordRef.current?.focus()
                                }}
                            />
                        </View>

                        <TextInputM
                            placeholder="Mot de passe"
                            ref={passwordRef}
                            labelTop="Mot de passe"
                            // label="Mot de passe"
                            name="password"
                            control={control}
                            password
                            errors={errors}
                            contentContainerStyle={{marginTop: 10}}
                        />
                    </View>}

                    <ErrorTextM errorData={props.error} style={{textAlign: "center"}}/>

                    <View style={{
                        marginBottom: 20,
                        marginTop: 10,
                        paddingHorizontal: 10,
                        alignItems: "center"
                    }}>

                        <Checkbox.Item label="J’accepte de recevoir des actualités et des offres de Masteur"
                                       position={'leading'}
                                       onPress={() => {
                                           newsletterOptInField.onChange(!newsletterOptInField.value)
                                       }}
                                       color={theme.colors.primary}
                                       labelStyle={{
                                           fontSize: 14,
                                           textAlign: "left"
                                       }}
                                       style={{
                                           marginHorizontal: 5,
                                           marginTop: 5
                                       }}
                                       status={newsletterOptInField.value ? "checked" : "indeterminate"}/>

                        <TextM style={{
                            fontSize: 12,
                            textAlign: 'center'
                        }} wrap>
                            En continuant, j'accepte les <LinkTextM url="https://masteur.com/cgu">Conditions
                            générales d'utilisation</LinkTextM> de Masteur. Je confirme avoir plus de 15 ans. Dans
                            le cas contraire, je confirme avoir l'autorisation d'un parent pour m'inscrire.
                        </TextM>
                    </View>

                    <FilledButtonM
                        style={{
                            marginTop: 10,
                            marginBottom: 0
                        }}
                        // disabled={!dirtyFields.email || !dirtyFields.password}
                        onPress={handleSubmit(onSubmit, onError)}
                        loading={request}
                        disabled={request}
                        label={"Inscription"}
                        // label={props.loggingIn ? "" : "Connexion"}
                    />
                    {!props.user && <TextM fontSize={14}
                                           style={{marginHorizontal: 20, textAlign: "center", marginTop: 4}}
                                           color={theme.colors.grey}
                                           fontWeight="Light"
                                           wrap>{"En attendant le Live, vous allez pouvoir découvrir notre application !"}</TextM>}


                </View>
                {!props.user && <>
                    <View style={{
                        marginBottom: 50,
                        marginTop: 50,
                        paddingHorizontal: 10,
                        justifySelf: "flex-end"
                    }}>
                        <TextM style={{alignSelf: 'center'}}>
                            Dèja un compte ? <LinkTextM onPress={() => {
                            navigation.navigate("login")
                        }
                        }>Je me connecte</LinkTextM>
                        </TextM>
                    </View>
                </>}

            </ScrollView>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
            {/*<RegisterTrialPopup referralCode={referralCode} lastInputTime={lastInputTime} resolver={resolver}/>*/}
        </View>
    );
};

const mapStateToProps = state => {
    return {
        getLiveRoomsAvailableForTrialRequest: state.api.getLiveRoomsAvailableForTrial?.request,
        subscribeToSpecialV2Request: state.api.subscribeToSpecialV2?.request,
        callMeBackRequest: state.api.callMeBack?.request,
        getLiveRoomsAvailableForTrialError: state.api.getLiveRoomsAvailableForTrial?.error,
        registerSpecialV2Request: state.api.registerSpecialV2?.request,
        registerSpecialV2Success: state.api.registerSpecialV2?.success,
        registerSpecialV2Error: state.api.registerSpecialV2?.error,
        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSpecialScreen)