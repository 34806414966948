import axios from "axios"

function getSchoolSubjects() {
    return axios.get(`/api/school-subjects`)
}

function chooseSchoolSubject({id}, data) {
    return axios.post(`/api/school-subjects/${id}/prioritize`, data)
}

function removeSchoolSubject({id}) {
    return axios.post(`/api/school-subjects/${id}/remove`)
}

function getSchoolSubjectHistory({}, {}, {page}) {
    return axios.get(`api/school-subject-history`, {
        params: {
            page
        }
    })
}

function getStudentSchoolSubjectHistory({id}, {}, {page}) {
    return axios.get(`api/students/${id}/school-subject-history`, {
        params: {
            page
        }
    })
}


export const schoolSubjectsService = {
    getSchoolSubjects,
    chooseSchoolSubject,
    removeSchoolSubject,
    getSchoolSubjectHistory,
    getStudentSchoolSubjectHistory
}

