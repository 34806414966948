import React, {useEffect} from "react";
import Animated, {
    runOnJS,
    useAnimatedGestureHandler,
    useAnimatedStyle,
    useSharedValue, withSpring,
    withTiming
} from "react-native-reanimated";
import {PanGestureHandler} from "react-native-gesture-handler";
import {Dimensions} from "react-native";

const DEVICE_HEIGHT = Dimensions.get("window").height;
const DEVICE_WIDTH = Dimensions.get("window").width;

const DraggableView = ({
                           children,
                           maxHeight = DEVICE_HEIGHT,
                           minHeight = 0,
                           maxWidth = DEVICE_WIDTH,
                           minWidth = 0,
                           contentWidth = 0,
                           contentHeight = 0,
                           initValue = {x: 0, y: 0},
                           onRelease = () => {
                           },
                           onStart = () => {
                           },
                           onActive = () => {
                           },
                           style,
                           isDraggable = true
                       }) => {
    const x = useSharedValue(initValue.x || 0);
    const y = useSharedValue(initValue.y || 0);

    const gestureHandler = useAnimatedGestureHandler({
        // onActive: (event, ctx) => {
        //         const endX = ctx.startX + event.translationX;
        //         const endY = ctx.startY + event.translationY;
        //         x.value = endX;
        //         y.value = endY;
        //         ctx.endX = endX;
        //         ctx.endY = endY;
        //         runOnJS(onActive)({x: ctx.endX, y: ctx.endY});
        // },
        // onFinish: () => {
        //     x.value = withTiming(initValue.x);
        //     y.value = withTiming(initValue.y);
        // }
        onStart: (_, ctx) => {
            runOnJS(onStart)();
            ctx.startX = x.value;
            ctx.startY = y.value;
        },
        onActive: (event, ctx) => {
            const endX = ctx.startX + event.translationX;
            const endY = ctx.startY + event.translationY;
            x.value = endX;
            y.value = endY;
            ctx.endX = endX;
            ctx.endY = endY;
            runOnJS(onActive)({x: ctx.endX, y: ctx.endY});
        },
        onEnd: (event, ctx) => {
            if (ctx.endY < minHeight) {
                ctx.endY = minHeight;
                y.value = withSpring(minHeight);
            }
            if (ctx.endY + contentHeight > maxHeight) {
                ctx.endY = maxHeight-contentHeight;
                y.value = withSpring(maxHeight-contentHeight);
            }
            if (ctx.endX < minWidth) {
                ctx.endX = minWidth;
                x.value = withSpring(minWidth);
            }
            if (ctx.endX + contentWidth > maxWidth) {
                ctx.endX = maxWidth-contentWidth;
                x.value = withSpring(maxWidth-contentWidth);
            }

            // x.value = withTiming(initValue.x);
            // y.value = withTiming(initValue.y);

            runOnJS(onRelease)({x: ctx.endX, y: ctx.endY});
        }
    });

    const animatedStyle = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateX: x.value
                },
                {translateY: y.value}
            ]
        };
    }, [x.value, y.value]);

    useEffect(() => {
        if (isDraggable) {
            x.value = withTiming(initValue.x);
            y.value = withTiming(initValue.y);
        } else {
            x.value = withTiming(0);
            y.value = withTiming(0);
        }


        return () => {

        };
    }, [isDraggable]);


    return (
        <PanGestureHandler onGestureEvent={gestureHandler} enabled={isDraggable}>
            <Animated.View style={[animatedStyle, style]}>{children}</Animated.View>
        </PanGestureHandler>
    );
};

export default DraggableView;