import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {ChampionImageM} from "../champions/ChampionImageM";
import {IconImageM} from "@common/components/icons/IconsM";
import tree from "../../../assets/icons/generic/tree.png"
import lunchBag from "../../../assets/icons/generic/lunch-bag.png"
import FilledButtonM from "@common/components/button/FilledButtonM";
import TextButtonM from "@common/components/button/TextButtonM";
import {useNavigation} from "@react-navigation/native";
import {plr} from "@data/utility/Utils";

const ShareStatsCongrats = props => {

    const {
        shopProduct
    } = props
    const dimensions = useContext(DimensionsContext)
    const countPurchases = props.user?.shop_purchases?.filter(p => p?.shop_product_id === shopProduct?.id)?.length
    const congratsTitle = "Bravo pour ce nouveau geste !"
    const isRepas = shopProduct.name === "Offrir un repas"
    const enTout = countPurchases > 1 ? "en tout " : ""
    const congratsText = isRepas ?
        "Tu as offert " + enTout +  countPurchases + " repas"+ " grâce à tes efforts !" :
        "Tu as planté " + enTout + countPurchases + plr(" arbre", countPurchases) + " grâce à tes efforts !"

    const left = countPurchases.toString().length > 1 ? 70 : 50
    const navigation = useNavigation()

    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        paddingHorizontal: 15,
        width: dimensions?.width,
        alignSelf: 'center',
    }}>
        <View>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <TextM fontSize={120} color={isRepas ? "#FAA037" : "#AFD755"}
                       style={{
                           textShadowColor: 'rgba(0, 0, 0, 0.5)',
                           textShadowOffset: {width: 0, height: 2},
                           textShadowRadius: 5,
                           paddingHorizontal: countPurchases.toString().length > 1 ? 0 : 20
                       }}
                       fontWeight={"ExtraBold"}>
                    {countPurchases}
                </TextM>
                <IconImageM width={120} source={isRepas ? lunchBag : tree}/>
            </View>
            <ChampionImageM name={"tina"}
                            variant={"happy"}
                            width={140}
                            style={{
                                position: 'absolute',
                                top: IS_WEB && dimensions?.width > 800 ? 80 : 120,
                                right: 0,
                                left: IS_WEB && dimensions?.width > 800 ? 50 : left
                            }}
            />
        </View>
        <View style={{marginTop: IS_WEB && dimensions?.width > 800 ? 260 : 200}}>
            <TextM fontSize={22}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center"
                       // marginTop: 50
                   }}
                   color={theme.colors.primary}>
                {congratsTitle.toUpperCase()}
            </TextM>

            <TextM fontSize={18}
                   wrap
                   fontWeight="Regular"
                   style={{
                       textAlign: "center",
                       marginTop: 20,
                       marginHorizontal: 30
                   }}
                   color={theme.colors.grey}>
                {congratsText}
            </TextM>

            {!IS_WEB && <>
            <FilledButtonM
                style={{
                    marginTop: 60
                }}
                onPress={() => {
                    navigation.push("share-stats", {
                        shopProduct: shopProduct
                    })
                }}
                label="Partager mes statistiques"
            />
            <TextButtonM
                onPress={() => {
                    navigation.goBack()
                }}
                style={{
                    marginTop: 10,
                    alignSelf: "center",
                }}
                fontWeight="ExtraBold"
                label="CONTINUER"
            />
            </>}
        </View>


    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ShareStatsCongrats)