import React, {useContext} from 'react';
import withPreventDoubleClick from "../../utils/withPreventDoubleClick";
import {Pressable, View} from "react-native";
import {DimensionsContext} from "../../utils/mobileUtils";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import {IconImageM} from "@common/components/icons/IconsM";

const CustomButton = (props) => {

    const {label, icon, selected, setSelectedIndex, toggleMode = false, selectedIndex, image} = props
    const dimensions = useContext(DimensionsContext)

    return !toggleMode ? <Pressable style={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            borderRadius: 20,
            backgroundColor: selected ? theme.colors.white : theme.colors.grey,
            padding: 5,
            paddingHorizontal: 10,
            marginHorizontal: 5,
            borderWidth: selected ? 2 : 0,
            borderColor: theme.colors.grey,
        }}
                                    onPress={() => {
                                        setSelectedIndex(label[0])
                                    }}
        >
            {image ? <IconImageM source={image[0]}
                                 width={20}
                                 style={{
                                     tintColor: selected ? theme.colors.grey : theme.colors.white
                                 }}
            /> : <FontAwesome5 name={icon[0]}
                               style={{}}
                               size={20}
                               color={selected ? theme.colors.grey : theme.colors.white}/>}
            {selected && <TextM color={selected ? theme.colors.grey : theme.colors.white} style={{marginLeft: 10}}>
                {label[0]}
            </TextM>}

        </Pressable> :
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: selected ? 2 : 0,
            borderColor: theme.colors.grey,
            borderRadius: 20,
            marginHorizontal: 5,
            //paddingHorizontal: 10,
            backgroundColor: selected ? theme.colors.white : theme.colors.grey,
        }}>
            <Pressable style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                backgroundColor: selected && selectedIndex === label[0] ? theme.colors.white : theme.colors.grey,
                padding: 5,
                paddingHorizontal: 10,
                borderRadius: 15,
                height: "100%",
            }}
                       onPress={() => {
                           setSelectedIndex(label[0])
                       }}
            >
                {image ? <IconImageM source={image[0]}
                                     width={26}
                                     style={{
                                         tintColor: selected && selectedIndex === label[0] ? theme.colors.grey : theme.colors.white,
                                     }}
                /> : <FontAwesome5 name={icon[0]}
                                   style={{}}
                                   size={20}
                                   color={selected && selectedIndex === label[0] ? theme.colors.grey : theme.colors.white}/>}
                {selected && selectedIndex === label[0] &&
                    <TextM color={selected ? theme.colors.grey : theme.colors.white} style={{marginLeft: 10}}>
                        {label[0]}
                    </TextM>}

            </Pressable>
            <Pressable style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                backgroundColor: selected && selectedIndex === label[1] ? theme.colors.white : theme.colors.grey,
                padding: 5,
                borderRadius: 15,
                paddingHorizontal: 10,
                height: "100%",
            }}
                       onPress={() => {
                           setSelectedIndex(label[1])
                       }}
            >
                <FontAwesome5 name={icon[1] ?? icon[0]}
                              style={{}}
                              size={selected && selectedIndex === label[1] ? 20 : 21}
                              color={selected && selectedIndex === label[1] ? theme.colors.grey : theme.colors.white}/>
                {selected && selectedIndex === label[1] &&
                    <TextM color={selected ? theme.colors.grey : theme.colors.white} style={{marginLeft: 10}}>
                        {label[1]}
                    </TextM>}

            </Pressable>
        </View>
}

export default withPreventDoubleClick(CustomButton)