import React, {useContext, useEffect, useState} from 'react';
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {setOngoingActivity} from "@data/redux/actions/activity";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {connect} from "react-redux";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TitleM from "@common/components/text/TitleM";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {StatusBar} from "expo-status-bar";
import {referralService} from "@data/services/referralService";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ReferralCodeItemsContainer} from "./ReferralCodeItemsContainer";
import IconButtonM from "@common/components/button/IconButtonM";
import {Dimensions, Platform, ScrollView, View} from "react-native";
import {ChampionImageM} from "../student/champions/ChampionImageM";
import UncontrolledTextInputM from "@common/components/input/UncontrolledTextInputM";
import * as Clipboard from "expo-clipboard";
import {SnackBarM} from "@common/components/alert/snackbar/SnackBar";

const ReferralCodeScreen = (props) => {
    const hash = props.route?.params?.h ?? null
    const [referralCodeFromHash, setReferralCodeFromHash] = useState(null);

    const navigation = useNavigation()

    useEffect(() => {
        if (hash) {
            //props.apiRequest(schoolSubjectsService.getSchoolSubjects)
            props.apiRequest(referralService.showReferralCodeByHash, {hash})
        }
    }, [])

    const affiliationWord = !referralCodeFromHash?.referrer?.id ? "affiliation" : "parrainage"
    const affiliationWordPrefix = !referralCodeFromHash?.referrer?.id ? "d'" : "de "
    const affiliationWordPrefixD = !referralCodeFromHash?.referrer?.id ? "L'" : "Le "
    const affiliationWordSuffix = !referralCodeFromHash?.referrer?.id ? "e" : ""

    useApiStatus(
        referralService.showReferralCodeByHash, // api service
        false, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setReferralCodeFromHash(successData)
            console.log(successData)
        } // success callback,
    )

    const dimensions = useContext(DimensionsContext)


    return <>
        {hash && <HeaderContainerM>
            <TitleM>{referralCodeFromHash?.name}</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack())
                        navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>}

        {props.showReferralCodeByHashRequest
            ? <ActivityIndicator color={theme.colors.primary} style={{marginTop: 20}}/>
            : props.showReferralCodeByHashError
                ? <>
                    <TextM color={theme.colors.error}
                           wrap
                           style={{
                               marginTop: 20,
                               marginBottom: 20,
                               textAlign: "center"
                           }}
                           fontWeight={"Bold"}
                           fontSize={14}>
                        {props.showReferralCodeByHashError.message}
                    </TextM>
                    <FilledButtonM
                        onPress={() => {
                            if (navigation.canGoBack())
                                navigation.goBack()
                            else navigation.replace("menu")
                        }}
                        label={"Retour"}
                    />
                </>
                : <ScrollView style={{
                    width: Dimensions.get("window").width
                    // backgroundColor: theme.colors.error
                }}
                              contentContainerStyle={{
                                  alignSelf: 'center',
                                  alignItems: 'center',
                                  width: dimensions?.width
                                  // backgroundColor: theme.colors.black
                              }}
                >
                    {<View style={{
                        alignItems: 'center',
                        alignSelf: 'center',
                        // backgroundColor: 'blue',
                        width: dimensions?.width,
                        paddingHorizontal: 20
                    }}>


                        <ChampionImageM name={"zoe"}
                                        style={{}}
                                        variant={"happy"}
                                        width={dimensions?.width > 800 ? 130 : 100}
                                        height={dimensions?.width > 800 ? 220 : null}
                        />
                        <TextM color={theme.colors.primary}
                               style={{
                                   marginTop: 10,
                                   textAlign: "center"
                               }}
                               fontWeight={"Bold"}
                               fontSize={18}
                               wrap
                        >Partagez votre lien {affiliationWordPrefix + affiliationWord}, et gagnez {referralCodeFromHash?.commission_amount}€ pour
                            chaque {affiliationWord} réussi{affiliationWordSuffix}
                            !</TextM>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 20
                            }}
                        >
                            <UncontrolledTextInputM
                                placeholder={"Votre lien " + affiliationWordPrefix + affiliationWord}
                                name="url"
                                value={referralCodeFromHash?.referee_url}
                                style={{
                                    height: dimensions?.width > 800 ? null : 50,
                                    backgroundColor: theme.colors.primaryLighter,
                                    width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4
                                }}
                                disabled
                            />
                            <IconButtonM
                                size={30}
                                icon={"copy"}
                                iconColor={theme.colors.primary}
                                style={{
                                    position: "absolute",
                                    right: 0
                                }}
                                onPress={() => {
                                    Clipboard.setStringAsync(referralCodeFromHash?.referee_url).then(() => {
                                        SnackBarM.show({
                                            text1: `📋 Lien ${affiliationWordPrefix + affiliationWord} copié dans le presse-papier`,
                                            duration: 6000
                                        })
                                    });
                                }}
                            />
                        </View>

                        <TextM fontSize={14}
                               style={{
                                   textAlign: "center",
                                   marginTop: 15
                               }}
                               fontWeight={"Light"}
                               wrap>{affiliationWordPrefixD + affiliationWord} est réussi{affiliationWordSuffix} lorsque l'élève parrainé active son abonnement.</TextM>
                        <TextM fontSize={14}
                               style={{
                                   textAlign: "center"
                               }}
                               fontWeight={"Light"}
                               wrap>Vous gagnez alors {referralCodeFromHash?.commission_amount}€{referralCodeFromHash?.commission_type === "fnac_coupon" ? " sous forme de bon d'achat FNAC" : referralCodeFromHash?.commission_type === "refund" ? " sous forme de remboursement sur votre abonnement" : ""}.</TextM>
                        <TextM fontSize={14}
                               style={{
                                   textAlign: "center"
                               }}
                               fontWeight={"Light"}
                               wrap>La personne parrainée bénéficie de {referralCodeFromHash?.referee_discount}% de réduction sur l'abonnement (offre Essentielle).</TextM>
                        {/*<TextM fontSize={10}*/}
                        {/*       style={{*/}
                        {/*           textAlign: "center",*/}
                        {/*           marginTop: 10*/}
                        {/*       }}*/}
                        {/*       fontWeight={"Light"}*/}
                        {/*       wrap>Offre valable du 09/01/2024 au 26/01/2024, dans la limite de 250€ remboursés en tout, soit 5 parrainages réussis.</TextM>*/}
                    </View>}


                    <View style={{
                        width: Dimensions.get("window").width,
                        backgroundColor: theme.colors.primaryLighter,
                        marginTop: 30,
                        paddingVertical: 40,
                    }}>

                        {<TitleM style={{textAlign: "center"}}>Mes {affiliationWord}s</TitleM>}

                        <ReferralCodeItemsContainer referralCode={referralCodeFromHash} style={{
                            justifySelf: "center"
                            // backgroundColor: "red"
                        }}/>

                    </View>

                </ScrollView>}
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </>
}
const mapStateToProps = state => {
    return {
        showReferralCodeByHashRequest: state.api.showReferralCodeByHash?.request,
        showReferralCodeByHashError: state.api.showReferralCodeByHash?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setOngoingActivity,
        setIsLiveModeEnabled
    }

export default connect(mapStateToProps, mapDispatchToProps)(ReferralCodeScreen)