import {apiConstants} from "@data/constants/apiConstants";
import {chaptersService} from "@data/services/chaptersService";

const initialState = {
    chaptersList: []
}

export default function chapters(state = initialState, action) {
    switch (action.type) {
        case chaptersService.getChaptersAsStudent.name + apiConstants._SUCCESS:
        case chaptersService.getChaptersAsAdmin.name + apiConstants._SUCCESS:
        case chaptersService.getChaptersForStudents.name + apiConstants._SUCCESS:
        case chaptersService.getChaptersAsTutor.name + apiConstants._SUCCESS :
            let chaptersList = []
            if (action.data?.meta?.current_page === 1) {
                chaptersList = action.data?.chapters
            } else {
                chaptersList = state.chaptersList.concat(action.data?.chapters)
            }
            return {
                ...state,
                chaptersList: chaptersList
            }
        case chaptersService.archiveChapter.name + apiConstants._SUCCESS:
            return {
                ...state,
                chaptersList: state.chaptersList?.map(x => x.id === action.data?.chapter?.id ? action.data?.chapter : x)
            }
        case chaptersService.newChapter.name + apiConstants._SUCCESS:
            return {
                ...state,
                chaptersList: [action.data, ...state.chaptersList]
            }
        case chaptersService.archiveChapterForStudents.name + apiConstants._SUCCESS:
            let chaptersFirstList = state.chaptersList

            const newArchivedChapters = action.data?.map(x => x.chapter)
            for (let i = 0; i < newArchivedChapters.length; i++) {
                if (chaptersFirstList.findIndex(x => x.id === newArchivedChapters[i].id) !== -1) {
                    chaptersFirstList[chaptersFirstList.findIndex(x => x.id === newArchivedChapters[i].id)] = newArchivedChapters[i]
                }
            }
            return {
                ...state,
                chaptersList: chaptersFirstList
            }

        default:
            return state
    }
}
