import {useInternalTheme} from "react-native-paper/src/core/theming";
import React, {useEffect, useState} from "react";
import {Animated, I18nManager, Pressable, StyleSheet, TouchableWithoutFeedback, View} from "react-native";
import Icon from "react-native-paper/src/components/Icon";

import TextM from "@common/components/text/TextM";
import color from 'color';
import {theme} from "@common/theme/theme";

const DataTableTitleM = ({
                             numeric,
                             children,
                             textStyle,
                             sortDirection,
                             style,
                             //theme,
                             numberOfLines = 1,
                             sortingColumn,
                             dataToSort,
                             setDataToSort,
                             ...rest
                         }) => {

    const [sortDir, setSortDir] = useState(sortDirection)
    const [clickCounter, setClickCounter]=useState(0)
    const [iconColor,setIconColor ]=useState(theme.colors.black)


    const sortAsc = () => {

        setDataToSort(
            [...dataToSort]?.sort((a, b) => {
                const itemA = typeof sortingColumn.selector != "function" ? a[sortingColumn.selector] : sortingColumn.selector(a)
                const itemB = typeof sortingColumn.selector != "function" ? b[sortingColumn.selector] : sortingColumn.selector(b)
                if(typeof itemA==="string" && typeof itemB==="string"){
                    if(itemA < itemB){
                        return -1;
                    }
                    if(itemA > itemB){
                        return 1;
                    }
                    return 0
                }
                return itemA - itemB;
            })
        )

        // console.log(dataToSort)
    }

    const sortDesc = () => {

        setDataToSort(
            [...dataToSort]?.sort((a, b) => {
                const itemA = typeof sortingColumn.selector != "function" ? a[sortingColumn.selector] : sortingColumn.selector(a)
                const itemB = typeof sortingColumn.selector != "function" ? b[sortingColumn.selector] : sortingColumn.selector(b)
                if(typeof itemA==="string" && typeof itemB==="string"){
                    if(itemA > itemB){
                        return -1;
                    }
                    if(itemA < itemB){
                        return 1;
                    }
                    return 0
                }
                return itemB - itemA;
            })
        )
        //  console.log(dataToSort)
    }




    const {current: spinAnim} = React.useRef(
        new Animated.Value(sortDir === 'ascending' ? 0 : 1)
    );

    React.useEffect(() => {
        Animated.timing(spinAnim, {
            toValue: sortDir === 'ascending' ? 0 : 1,
            duration: 150,
            useNativeDriver: true,
        }).start();
    }, [sortDir, spinAnim]);

    const spin = spinAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '180deg'],
    });

    const icon = sortDir ? (
        <Pressable  style={{width:16}} onPressIn={()=>setIconColor(theme.colors.primary)} onPressOut={()=>setIconColor(theme.colors.black)} onPress={() => {
            setClickCounter(clickCounter+1)
            if (sortDir) {

                if (sortDir === "descending") {
                    if(clickCounter>=1) {
                        setSortDir("ascending");
                        sortAsc();
                    }
                    else
                        sortDesc();
                } else {
                    if(clickCounter>=1) {
                        setSortDir("descending")
                        sortDesc();
                    }
                    else
                        sortAsc();
                }
            } else {
                setSortDir("ascending");
                sortAsc();
            }

        }}>
            <Animated.View style={[styles.icon, {transform: [{rotate: spin}]},]} >
                <Icon
                    source="arrow-up"
                    size={16}
                    color={iconColor}
                    direction={I18nManager.getConstants().isRTL ? 'rtl' : 'ltr'}
                />
            </Animated.View>
        </Pressable>

    ) : null;


    return (
        // <TouchableWithoutFeedback disabled={!sortingColumn.sortable}
        //                           style={[styles.container, numeric && styles.right, style] }
        //
        //                           {...rest}>
            <View style={[styles.container, numeric && styles.right, style] }>
                {/*{icon}*/}

                <TextM
                    style={
                        {
                            ...textStyle,
                            marginLeft: sortDir ? 2 : null,
                        }
                    }
                    numberOfLines={numberOfLines}
                    {...rest}
                    wrap
                >
                    {children}
                </TextM>
            </View>
        // </TouchableWithoutFeedback>
    );
};

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },

    rightText: {
        textAlign: 'right',
    },

    leftText: {
        textAlign: 'left',
    },

    centerText: {
        textAlign: 'center',
    },

    right: {
        justifyContent: 'flex-end',
    },

    cell: {
        lineHeight: 24,
        fontSize: 12,
        fontWeight: '500',
        alignItems: 'center',
    },


    icon: {
        height: 24,
        justifyContent: 'center',
    },
});

export default DataTableTitleM;
