import React, {useContext, useEffect, useState} from 'react';
import {ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import EvaluationScreen from "./EvaluationScreen";
import ApplicationScreen from "./ApplicationScreen";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {tutorsService} from "@data/services/tutorsService";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TitleM from "@common/components/text/TitleM";
import {StatusBar} from "expo-status-bar";
import {HeaderContainerM} from "@common/header/HeaderContainerM";

const TutorApplicationDetailsScreen = (props) => {
    const {navigation, route} = props
    const tutorId = route.params?.tutorId ?? null
    const dimensions = useContext(DimensionsContext)
    const tutor = findItemInListWithId(tutorId, props.users)
    const tutorProfile = tutor?.profile
    //console.log(tutor)
    const evaluated = !!tutor?.evaluation_report

    useEffect(() => {
        props.apiRequest(tutorsService.getTestAnswers, {id: tutorId})
    }, []);

    useApiStatus(
        tutorsService.getTestAnswers, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            //console.log(data)
        } // success callback
    )

    return (
        <View style={{
            flex: 1
            //maxHeight: Dimensions.get('screen').height
            // height:dimensions.height-1
        }}
        >
            <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}}>
                <View style={{flexDirection: "row", alignItems: "center"}}>
                    <IconButton
                        icon={"times"}
                        iconColor={theme.colors.primary}
                        size={32}
                        style={{
                            // position: "absolute",
                            marginRight: 10
                        }}
                        onPress={() => {
                            if (navigation.canGoBack()) navigation.goBack()
                            else navigation.replace("menu")
                        }}
                    />
                    <TitleM>Candidature {evaluated ? "évaluée" : "à évaluer"}</TitleM>
                </View>
            </HeaderContainerM>

            {props.testAnswersRequest ? <ActivityIndicator color={theme.colors.primary} style={{marginTop: 20}}/> :
            <View style={{
                width: dimensions.width * 3 / 2,
                flexDirection: 'row',
                marginTop: 20,
                flex: 1,
            }}>
                <ScrollView style={{
                    width: dimensions.width * 4 / 5,
                    paddingBottom: 50,
                    flex: 3
                }}>
                    <ApplicationScreen bacInfo={tutorProfile?.bac_infos}
                                       tutor={tutor}
                                       application={props.tutorApplication}
                                       applicationSteps={props.applicationSteps}/>
                </ScrollView>
                <ScrollView style={{
                    width: dimensions.width * 4 / 5,
                    paddingBottom: 50,
                    flex: 2
                }}>
                    <EvaluationScreen tutorId={tutorId} tutor={tutor} evaluated={evaluated} report={tutor?.evaluation_report}/>

                </ScrollView>
            </View>}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        testAnswersRequest: state.api?.getTestAnswers?.request,
        users: state.data?.users?.usersList,
        tutorApplication: state.data?.tutors?.tutorApplication,
        applicationSteps: state.data.staticData?.application_steps,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationDetailsScreen)