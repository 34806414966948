import React, {useEffect} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {StatusBar} from "expo-status-bar";
import {ActivityIndicator} from "react-native-paper";
import {subscriptionService} from "@data/services/subscriptionService";
import {IS_WEB} from "@common/utils/mobileUtils";
import * as WebBrowser from "expo-web-browser";
import base64 from "@common/utils/base64";


const SubscribeRedirectScreen = (props) => {

    const userEmailb64 = props.route?.params?.user ?? ""
    const userEmail = base64.decode(userEmailb64)

    const navigation = useNavigation()

    useEffect(() => {
        props.apiRequest(subscriptionService.getSubscribeRedirectUrl, {}, {}, {
            email: userEmail
        })
    }, [])

    useApiStatus(
        subscriptionService.getSubscribeRedirectUrl, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {

            if (IS_WEB) {
                window.open(data.url, "_self")
            } else {
                // console.log(data)
                WebBrowser.openBrowserAsync(data?.url)
                navigation.goBack()
            }

        } // success callback
    )
    return (
        <View style={{
            alignItems: 'center',
            flex: 1,
            padding: 20
        }}>
            {props.request && <>
                <ActivityIndicator color={theme.colors.primary}
                                   style={{marginTop: 30}}
                />
                <TextM wrap>Redirection...</TextM>
            </>
            }

            {props.error && <>
                <TextM color={theme.colors.error}
                       wrap
                       style={{
                           marginTop: 20,
                           marginBottom: 20,
                           textAlign: "center"
                       }}
                       fontWeight={"Bold"}
                       fontSize={14}>
                    {props.error.message}
                </TextM>
                <FilledButtonM
                    onPress={() => {
                        navigation.goBack()
                    }}
                    label={"Retour à l'accueil"}
                />
            </>}

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        request: state.api.getSubscribeRedirectUrl?.request,
        success: state.api.getSubscribeRedirectUrl?.success,
        error: state.api.getSubscribeRedirectUrl?.error,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeRedirectScreen)