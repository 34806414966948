import React from "react";
import { Rating } from "react-native-ratings/src";
import { View } from "react-native";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import { ActivityIndicator } from "react-native-paper";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { liveService } from "@data/services/liveService";
import { theme } from "@common/theme/theme";

const SatisfactionComponent = (props) => {
  const {
    rating,
    setRating,
    readonly = false,
    imageSize = 40,
    withApiRequest = false,
    requestApiService,
    requestRouteParams,
    additionalActions = () => {},
    ...rest
  } = props;

  // console.log(props.sendStudentCommentData?.satisfaction)
  return (
    <View
      style={{
        // width: dimensions?.width - 40,
        alignSelf: "center",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginTop: 10,
        width: imageSize * 5 + 20,
        //flex: 1
      }}
    >
      <Rating
        ratingCount={5}
        // ratingBackgroundColor={ratingBackgroundColor}
        //type={"custom"}
        // ratingImage={sparkle}
        // ratingColor={"red"}
        // ratingBackgroundColor="red"
        readonly={readonly}
        startingValue={rating}
        imageSize={imageSize}
        style={
          {
            // alignSelf: "center",
            // width: "100%",
          }
        }
        // tintColor={theme.colors.whiteAlmost}
        disabled={
          props.sendLiveQualityRequest ||
          props.sendSatisfactionRequest ||
          props.sendStudentCommentRequest
        }
        onFinishRating={(value) => {
          setRating(value);
          additionalActions(value);
          if (withApiRequest) {
            const requestData =
              requestApiService === liveService.sendSatisfaction ||
              requestApiService === liveService.sendStudentComment
                ? { satisfaction: value }
                : { quality_score: value };
            // console.log("requestData", requestData)
            props.apiRequest(
              requestApiService,
              requestRouteParams,
              requestData
            );
          }
        }}
        {...rest}
      />
      {(props.sendSatisfactionRequest ||
        props.sendLiveQualityRequest ||
        props.sendStudentCommentRequest) && (
        <ActivityIndicator
          size={"small"}
          animating={true}
          style={{
            position: "absolute",
            right: -30,
            // marginLeft: 10,
          }}
          color={theme.colors.primary}
        />
      )}
      {(props.sendSatisfactionSuccess ||
        props.sendLiveQualitySuccess ||
        props.sendStudentCommentSuccess) && (
        <FontAwesome
          name={"check-circle"}
          solid
          size={30}
          style={{
            position: "absolute",
            right: -30,
          }}
          color={theme.colors.success}
        />
      )}
    </View>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    sendLiveQualityRequest: state.api.sendLiveQuality?.request,
    sendLiveQualitySuccess: state.api.sendLiveQuality?.success,
    sendSatisfactionRequest: state.api.sendSatisfaction?.request,
    sendSatisfactionSuccess: state.api.sendSatisfaction?.success,
    sendStudentCommentRequest: state.api.sendStudentComment?.request,
    sendStudentCommentSuccess: state.api.sendStudentComment?.success,
    sendStudentCommentData: state.api.sendStudentComment?.data,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SatisfactionComponent);
