import React, {useContext, useRef} from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TitleM from "@common/components/text/TitleM";
import {StatusBar} from "expo-status-bar";
import {theme} from "@common/theme/theme";
import {activitiesService} from "@data/services/activitiesService";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import {RealizationsListItemContent} from "../student/organize/list/RealizationsListItemContent";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {MenuFilter} from "@common/components/menu/MenuFilter";
import {activityStatuses, sectionStudentClasses, studentClasses} from "@data/constants/formConstants";
import {setActivitiesReviewFilter} from "@data/redux/actions/data";
import {scoringService} from "@data/services/scoringService";
import {useApiStatus} from "@common/hooks/useApiStatus";


const ReviewActivitiesTabScreen = (props) => {

    // ** Component props
    const {navigation} = props

    // ** Manage scrolling to specific index in flat list
    const flatListRef = useRef()
    const activities = props.reviewer?.activities
    const challenges = props.reviewer?.challenges
    const dimensions = useContext(DimensionsContext)


    const formValues = props.reviewer?.activitiesFilter
    const setFormValues = props.setActivitiesReviewFilter
    // console.log("formValues", formValues)

    const requestApiService = activitiesService.getActivitiesForReview
    const urlParams = {
        student_classes: formValues?.student_classes?.join(",") ?? "",
        school_subject_ids: formValues?.school_subject_ids?.join(",") ?? "",
        activity_statuses: formValues?.activity_statuses?.join(",") ?? ""
    }

    useApiStatus(
        scoringService.getStaticScoringData, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            // console.log(props.scoringRules)
        } // success callback
    )

    // ** Filter action
    const onFinishSelecting = (newFormValues = null) => {
        if (newFormValues) {
            props.apiRequest(requestApiService, {}, {}, {
                student_classes: newFormValues?.student_classes?.join(",") ?? "",
                school_subject_ids: newFormValues?.school_subject_ids?.join(",") ?? "",
                activity_statuses: newFormValues?.activity_statuses?.join(",") ?? ""
            })
        } else {
            props.apiRequest(requestApiService, {}, {}, urlParams)
        }
    }

    // ** Filter options
    const filterItems = [
        {
            name: "school_subject_ids",
            label: "Matière",
            sectionMode: true,
            options: [
                {
                    title: "Scientifiques",
                    data: props.schoolSubjects?.filter(x => x?.category === "scientific")
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.name}
                        })
                },
                {
                    title: "Littéraires / éco",
                    data: props.schoolSubjects?.filter(x => x?.category === "litterary/economic")
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.name}
                        })
                },
                {
                    title: "Langues",
                    data: props.schoolSubjects?.filter(x => x?.category === "language")
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.name}
                        })
                }
            ],
            defaultValue: props.schoolSubjects?.map(x => x.id)
        },
        {
            name: "student_classes",
            label: "Classe",
            sectionMode: true,
            options: sectionStudentClasses?.map(section => {
                return {
                    ...section,
                    data: section?.options?.map(x => {
                        return {value: x, label: x}
                    })
                }
            }),
            defaultValue: studentClasses
        },
        {
            name: "activity_statuses",
            label: "Statut",
            options: activityStatuses,
            defaultValue: activityStatuses?.map(x => x.value)
        }
    ]

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            <PaginatedFlatList data={activities}
                               ref={flatListRef}
                               style={{
                                   width: dimensions?.width
                               }}
                               contentContainerStyle={{
                                   alignItems: "center",
                                   width: dimensions?.width
                               }}
                               additionalOnRefresh={() => {
                                   // console.log("additionalOnRefresh")
                                   props.apiRequest(scoringService.getStaticScoringData)
                               }}
                               additionalRefreshing={props.getStaticScoringDataRequest}
                               requestApiService={requestApiService}
                               requestRouteParams={null}
                               requestUrlParams={urlParams}
                               requestData={null}
                               ListHeaderComponent={<>
                                   <View style={{
                                       flexDirection: "column",
                                       alignItems: 'center',
                                       justifyContent: 'space-between',
                                       width: dimensions?.width,
                                       borderColor: theme.colors.lightMore,
                                       paddingVertical: 8,
                                       paddingLeft: 20,
                                       marginBottom: 20
                                   }}>
                                       <TitleM fontWeight="ExtraBold">REVUE D'ACTIVITÉS</TitleM>
                                       {/*<View style={{flexDirection: "row", marginLeft: 10}}>*/}
                                       {/*    {renderMenu()}*/}
                                       {/*</View>*/}
                                       <MenuFilter style={{
                                           alignItems: "space-between"
                                       }}
                                                   setFormValues={setFormValues}
                                                   formValues={formValues}
                                                   filterItems={filterItems}
                                                   onFinishSelecting={onFinishSelecting}
                                       />
                                   </View>
                               </>}
                               renderItem={(item) => {

                                   const activity = item.item
                                   const challenge = activity?.challenge_id ? findItemInListWithId(activity?.challenge_id, challenges) : null
                                   const schoolSubject = findItemInListWithId(activity?.school_subject_id, props.schoolSubjects)

                                   return <TouchableOpacity onPress={() => {
                                       navigation.push("activity-review", {
                                           activityId: activity?.id
                                       })
                                   }}
                                                            style={{
                                                                backgroundColor: theme.colors.white,
                                                                borderRadius: 15,
                                                                borderWidth: 1,
                                                                borderColor: theme.colors.lightMore,
                                                                width: Platform.OS === 'web' ? dimensions?.width - 80 : dimensions?.width - 10,
                                                                marginBottom: 15
                                                            }}>
                                       <RealizationsListItemContent activity={activity}
                                                                    challenge={challenge}
                                                                    currentUser={props.user}
                                                                    schoolSubject={schoolSubject}/>
                                   </TouchableOpacity>
                               }}
            />

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        reviewer: state.data.reviewer?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        getStaticScoringDataRequest: state.api.getStaticScoringData?.request,
        scoringRules: state.data.reviewer?.object?.scoringRules,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setActivitiesReviewFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewActivitiesTabScreen)