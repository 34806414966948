import React, {createContext, useContext, useImperativeHandle, useRef, useState} from "react";
import {BottomSheetView} from "@gorhom/bottom-sheet";
import {BottomSheetModalM} from "@common/components/modal/BottomSheetM";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {View} from "react-native";
import {ChampionImageM} from "./ChampionImageM";
import TextM from "@common/components/text/TextM";
import TextButtonM from "@common/components/button/TextButtonM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import * as amplitude from "@amplitude/analytics-react-native";

export const ChampionModalContext = createContext(null);

export const ChampionBottomSheetModal = React.forwardRef((props, ref) => {

    // ** Component props

    const [name, setName] = useState("zoe")
    const [variant, setVariant] = useState("main")
    const [title, setTitle] = useState(null)
    const [text, setText] = useState(null)
    const [actionButtons, setActionButtons] = useState(null)
    const [laterButton, setLaterButton] = useState(null)

    const bottomSheetRef = useRef();
    // const championBubbleRef = useRef();

    useImperativeHandle(ref, () => ({
        showChampion(name = "zoe", variant = "main", title = "", text = "", actionButtons = [], laterButton = {}) {
            setName(name)
            setVariant(variant)
            setTitle(title)
            setText(text)
            setActionButtons(actionButtons)
            setLaterButton(laterButton)

            bottomSheetRef?.current?.present(null)
        }
    }));
    const dimensions = useContext(DimensionsContext)


    return <BottomSheetModalM ref={bottomSheetRef}
        // animateOnMount={false}
                              height="65%"
                              backgroundStyleProps={{
                                  backgroundColor: theme.colors.white,
                                  borderRadius: 0
                              }}>
        <BottomSheetView style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "space-between",
            width: dimensions?.width,
            height: "100%"
        }}>

            <View style={{
                alignItems: "center",
                justifyContent: "center",
                width: dimensions?.width - 20
            }}>
                <ChampionImageM name={name}
                                style={{
                                    // flex:1
                                    marginTop: 20
                                }}
                                variant={variant}
                                width={100}/>
                {/*<ChampionBubble name={name}*/}
                {/*                ref={championBubbleRef}*/}
                {/*                variant={variant}*/}
                {/*                texts={texts}*/}
                {/*                championPosition="center"*/}
                {/*                onNextPress={() => {*/}
                {/*                    if (onNextPress)*/}
                {/*                        onNextPress()*/}

                {/*                    bottomSheetRef.current?.close()*/}
                {/*                }}/>*/}

                <TextM wrap
                       fontSize={20}
                       fontWeight={"Bold"}
                       style={{
                           marginTop: 10,
                           textAlign: "center"
                       }}>{title}</TextM>
                <TextM wrap
                       fontSize={16}
                       style={{
                           marginTop: 4,
                           marginBottom: 18,
                           textAlign: "center",
                       }}>{text}</TextM>
            </View>


            <View style={{
                alignItems: "center",
                justifyContent: "center",
                width: dimensions?.width
            }}>
                <View style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    width: dimensions?.width
                }}>
                    {actionButtons?.map((buttonData, index) => <FilledButtonM onPress={() => {
                        bottomSheetRef.current?.dismiss()

                        if (buttonData?.onPress) {
                            buttonData?.onPress()
                        }
                    }}
                                                                              key={index}
                                                                              label={buttonData?.label}
                                                                              color={theme.colors.primary}
                                                                              style={{
                                                                                  width: dimensions?.width / (actionButtons.length) - 20,
                                                                                  alignSelf: "center",
                                                                                  marginTop: 5,
                                                                                  marginHorizontal: 8
                                                                                  // marginBottom: 30
                                                                              }}
                    />)}
                </View>

                <TextButtonM
                    onPress={() => {
                        bottomSheetRef.current?.dismiss()

                        if (laterButton?.onPress) {
                            laterButton?.onPress()
                        }



                    }}
                    style={{
                        width: dimensions?.width - 20,
                        marginTop: 10,
                        marginBottom: 30,
                        alignSelf: "center",
                        textAlign: "center"
                    }}
                    color={theme.colors.primary}
                    fontWeight="ExtraBold"
                    label={laterButton?.label ?? "PLUS TARD"}/>

            </View>
        </BottomSheetView>
    </BottomSheetModalM>;
})