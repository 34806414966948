import React, {useContext, useEffect, useState} from "react";
import {
    Platform,
    RefreshControl,
    ScrollView,
    StyleSheet,
    View
} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import {StatusBar} from "expo-status-bar";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TutorNextInterviewsCard from "./interview/TutorNextInterviewsCard";
import CoachStats from "./dashboard/CoachStats";
import {hasRole} from "@data/utility/ability";
import ProfileCompletionCard from "./components/ProfileCompletionCard";
import SlotCompletion from "./components/SlotCompletion";
import {AbilityContext} from "@data/utility/Can";
import {ActivityIndicator} from "react-native-paper";
import {authService} from "@data/services/authService";
import {studentsService} from "@data/services/studentsService";
import {skillSetsService} from "@data/services/skillSetsService";
import TutorNextLivesCard from "../lives/TutorNextLivesCard";
import {useNavigation} from "@react-navigation/native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import TutorLatestLivesCard from "../lives/MissingLiveReportsCard";
import MissingLiveReportsCard from "../lives/MissingLiveReportsCard";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.colors.whiteAlmost,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingTop: 10,
        // paddingHorizontal: 20,
        alignSelf: Platform.OS !== "web" ? null : "center"
    }
});

function TrainingCompletionCard() {
    const navigation = useNavigation();
    const dimensions = useContext(DimensionsContext);

    return (
        <View
            style={{
                borderWidth: 1,
                borderColor: theme.colors.lightMore,
                borderRadius: 5,
                padding: 20,
                backgroundColor: theme.colors.white,
                marginTop: 30,
                width: dimensions?.width,
                justifyContent: "center"
            }}
        >
            <View>
                <TextM fontWeight={"Bold"} wrap fontSize={20}>
                    Visionne les vidéos de formation
                </TextM>
                <TextM
                    wrap
                    style={{
                        marginTop: 10
                    }}
                >
                    {
                        "Prépare toi à prendre des groupes de Lives en visionnant les vidéos de formation !"
                    }
                </TextM>
            </View>
            <View
                style={{
                    marginTop: 20,
                    alignSelf: "flex-start"
                }}
            >
                <FilledButtonM
                    style={{
                        width:
                            IS_WEB && dimensions.width > 800 ? dimensions?.width / 4 : null
                    }}
                    label={"Voir les vidéos"}
                    onPress={() =>
                        navigation.push("videos-coaching", {coaching: false})
                    }
                />
            </View>
        </View>
    );
}

const TutorHomeTabScreen = (props) => {
    const dimensions = useContext(DimensionsContext);
    const navigation = useNavigation();

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            // props.apiRequest(studentsService.getStudents)
            setLoading(false);
        }, 500);
    }, []);
    const request = !!(
        props.getUserRequest ||
        props.getStudentsRequest ||
        props.getSkillSetsRequest ||
        isLoading
    );

    function onButtonPress() {
        // props.apiRequest(authService.revokeSanctumToken, {}, {})
    }

    const ability = useContext(AbilityContext);
    return (
        <ScrollView
            refreshControl={
                <RefreshControl
                    refreshing={request}
                    onRefresh={() => {
                        props.apiRequest(authService.getUser);
                        if (hasRole(props.user, "student"))
                            props.apiRequest(skillSetsService.getSkillSets);
                        else if (hasRole(props.user, "tutor"))
                            props.apiRequest(studentsService.getStudents);
                    }}
                />
            }
        >
            <View
                style={[
                    styles.container,
                    {
                        //width: dimensions?.width
                    }
                ]}
            >
                <TitleM fontWeight="ExtraBold">TABLEAU DE BORD</TitleM>

                {!request || !IS_WEB ? (
                    <>
                        {/* ** Tutor candidature validation status */}
                        {hasRole(props.user, "tutor-candidate") &&
                            !!props.user?.candidature_sent_at && (
                                <View
                                    style={{
                                        width: dimensions.width - 40,
                                        borderRadius: 15,
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        padding: 15,
                                        marginTop: 20,
                                        backgroundColor: theme.colors.warningLight
                                    }}
                                >
                                    <TextM>
                                        {props.user?.candidature_validated_at
                                            ? "Candidature validée"
                                            : "Candidature envoyée"}
                                    </TextM>
                                </View>
                            )}

                        {/* ** Tutor last live sessions */}
                        {ability?.can("read", "my_students") &&
                            props.user?.incomplete_live_occurrences?.count > 0 && (
                                <>
                                    {/*<TutorNextInterviewsCard/>*/}
                                    <MissingLiveReportsCard/>
                                    {/*<TextM wrap style={{marginTop: 20, textAlign: "center"}}>Tu retrouveras ici des récompenses à débloquer en*/}
                                    {/*    utilisant tes crédits pétales</TextM>*/}
                                </>
                            )}

                        {/* ** Tutor candidate profile completion */}
                        {ability?.can("read", "my_tutor_profile_completion") && (
                            <>
                                <View>
                                    {/*<ProfileCompletionCard*/}
                                    {/*    message="Remplis les informations sur toi pour permettre à tes élèves de te connaître"/>*/}

                                    <SlotCompletion/>
                                    <TrainingCompletionCard/>
                                </View>
                            </>
                        )}


                        {/* ** Tutor interviews and stats */}
                        {ability?.can("read", "my_students") && (
                            <>
                                {/*<TutorNextInterviewsCard/>*/}
                                <TutorNextLivesCard/>
                                <CoachStats/>
                                {/*<TextM wrap style={{marginTop: 20, textAlign: "center"}}>Tu retrouveras ici des récompenses à débloquer en*/}
                                {/*    utilisant tes crédits pétales</TextM>*/}
                            </>
                        )}

                        {/* ** Placeholder for users who are not tutors */}
                        {!ability?.can("read", "my_students") && (
                            <>
                                <TextM style={{marginTop: 10}}>Bientôt disponible</TextM>
                            </>
                        )}
                        {/* ** Admin & superAdmin Analytics */}
                        {ability?.can("read", "analytics") && (
                            <>
                                {/*<FilledButtonM*/}
                                {/*    style={{*/}
                                {/*        marginTop: 40*/}
                                {/*    }}*/}
                                {/*    color={theme.colors.primary}*/}
                                {/*    icon="chart-bar"*/}
                                {/*    onPress={() => {*/}
                                {/*        navigation.push("analytics")*/}
                                {/*    }}*/}
                                {/*    label="Analytics"*/}
                                {/*/>*/}
                            </>
                        )}
                    </>
                ) : (
                    <ActivityIndicator
                        color={theme.colors.primary}
                        style={{
                            marginTop: 10
                        }}
                    />
                )}
                <StatusBar
                    style="dark"
                    backgroundColor={theme.colors.white}
                    translucent={false}
                />
            </View>
        </ScrollView>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        getStudentsRequest: state.api.getStudents?.request,
        getSkillSetsRequest: state.api.getSkillSets?.request
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(TutorHomeTabScreen);
