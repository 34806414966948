import React, {useContext, useEffect, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {Platform, RefreshControl, ScrollView, View} from "react-native";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {connect} from "react-redux";
import {formatCalendarMoment, formatRelativeDateTime} from "@data/utility/Utils";
import TextM from "@common/components/text/TextM";
import {StatusBar} from "expo-status-bar";
import {activitiesService} from "@data/services/activitiesService";
import {DoneChip} from "../student/activity/read/DoneChip";
import ActivityTemplateHeader from "../student/activity/read/ActivityTemplateHeader";
import {StepLogs} from "../student/activity/read/StepLogs";
import {ActivityScoringKeyValueItem} from "../student/activity/read/ActivityScoringKeyValueItem";
import {ActivityXpKeyValueItem} from "../student/activity/read/ActivityXpKeyValueItem";
import ActivityGem from "../student/activity/read/ActivityGem";
import {dismissNotificationIfExists} from "@common/notifications/notifications";
import {KeyValueItem} from "@common/components/card/KeyValueItem";
import ChampionBubble from "../student/champions/ChampionBubble";
import {samVariantForActivity} from "../student/champions/ChampionImageM";
import {IconTouchableRipple} from "../tutor/students/IconTouchableRipple";
import ActivityReviewSection from "./ActivityReviewSection";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {boxedCardBorderStyle} from "./BoxedCardBorderStyle";
import {definedMaterialSlug} from "../student/activity/todo/ActivityPicker";
import MaterialModal from "../student/activity/execute/MaterialDisplayModal";
import {ActivityJsonDetails} from "./ActivityJsonDetails";


const ActivityReviewScreen = props => {

    // ** Component props
    const {navigation, route} = props

    const activityId = route.params?.activityId

    // console.log(activityId)

    const activities = props.reviewer?.activities
    const challenges = props.reviewer?.challenges

    // ** Component variables
    const activity = activities?.find(a => a?.id === parseInt(activityId))
    const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)
    const challenge = activity?.challenge_id ? findItemInListWithId(activity?.challenge_id, challenges) : null
    const schoolSubject = activity?.school_subject_id ? findItemInListWithId(activity?.school_subject_id, props.schoolSubjects) : challenge?.school_subject

    const dimensions = useContext(DimensionsContext)

    const activityMaterial=activity?.material
    const dMaterialSlug = definedMaterialSlug(activity?.slug)
    const [isOpen, setOpen] = useState(false)

    const handleShowModal = () => setOpen(!isOpen)

    // console.log(getValues())

    const refresh = () => {
        const urlParams = {
            with_logs: 1,
            activity_ids: activityId,
        }

        if (Platform.OS === 'web')
            urlParams.with_details = 1

        props.apiRequest(activitiesService.getActivities, {}, {}, urlParams)
    }

    useEffect(() => {

        if (Platform.OS !== 'web')
            dismissNotificationIfExists("activities-for-review", "activity_id", activityId)

        // if (!activity?.activity_logs)
        refresh()
    }, []);

    useApiStatus(
        activitiesService.getActivities, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {

        }, // success callback
        () => {
        },
    )

    useApiStatus(
        activitiesService.reviewActivity, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log("SUCCESS")
        }, // success callback
        () => {
        },
    )


    const onReviewLock = () => {
        props.apiRequest(activitiesService.reviewLock, {id: activity?.id})
    }

    useApiStatus(
        activitiesService.reviewLock, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {

        } // success callback
    )

    return <View style={{
        flex: 1,
        // backgroundColor: theme.colors.white,
        alignItems: 'center',
        justifyContent: 'flex-start'
        // width: Platform.OS === 'web' ? dimensions?.width : null,
        // alignSelf: Platform.OS !== 'web' ? null : "center"
    }}>

        <ActivityTemplateHeader activityTemplate={activityTemplate}
                                activity={activity}
                                challenge={challenge}
                                navigation={navigation}
                                previous
                                schoolSubject={schoolSubject}/>

        <ScrollView style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}
                    contentContainerStyle={{
                        paddingBottom: 40
                    }}
            // scrollEnabled={!IS_WEB}
                    refreshControl={<RefreshControl
                        refreshing={!!props.getActivitiesRequest}
                        onRefresh={refresh}
                    />}>

            <View style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor: theme.colors.primaryLighter,
                backgroundColor: theme.colors.white,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                marginTop: 30,
                borderColor: theme.colors.lightMore,
                paddingVertical: 15,
                paddingHorizontal: 10,
                width: dimensions?.width,
                alignSelf: Platform.OS !== 'web' ? null : "center",
                ...boxedCardBorderStyle
            }}>
                <View>
                    {activity?.student_class &&
                        <TextM fontWeight="SemiBold" color={theme.colors.primary} style={{marginTop: 0}}>
                            Elève de {activity?.student_class}
                        </TextM>}
                    {activity?.student_created_at &&
                        <TextM fontWeight="SemiBold" color={theme.colors.primary} style={{marginTop: 0}}>
                            Inscrit {formatRelativeDateTime(activity?.student_created_at, true)}
                        </TextM>}
                </View>
                <View style={{
                    // borderWidth: 1,
                    // borderColor: theme.colors.light,
                    // borderRadius: 10
                }}>
                    <IconTouchableRipple
                        icon={"bullseye"}
                        label={"Activités"}
                        style={{width: 100, flex: null}}
                        onPress={() => {
                            navigation.push("student", {
                                studentId: activity?.student_id,
                                anonymous: true
                            });
                        }}/>
                </View>
            </View>

            <View style={{
                // flex: 1,
                alignItems: 'center',
                justifyContent: "flex-start",
                backgroundColor: theme.colors.white,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                marginTop: 30,
                borderColor: theme.colors.lightMore,
                paddingVertical: 15,
                paddingHorizontal: 10,
                width: dimensions?.width,
                alignSelf: Platform.OS !== 'web' ? null : "center",
                ...boxedCardBorderStyle
            }}>
                <View style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    // paddingHorizontal: 10,
                    width: "100%",
                    // backgroundColor: "red",
                    flex: 1
                }}>
                    <TextM fontWeight="Bold" color={theme.colors.light} style={{marginTop: 5}}>
                        {formatCalendarMoment(activity?.finished_at, true)}
                    </TextM>
                    <DoneChip count={0} status={activity?.status} large/>
                </View>

                <View style={{
                    flex: 1,
                    width: "100%",
                    marginTop: 15
                }}>

                    {challenge?.domain &&
                        <TextM fontWeight="SemiBold" color={theme.colors.grey} style={{marginTop: 0}}>
                            Sujet : {challenge?.domain}
                        </TextM>}
                    {challenge?.chapter &&
                        <TextM fontWeight="SemiBold" color={theme.colors.grey} style={{marginTop: 0}}>
                            Chapitre : {challenge?.chapter}
                        </TextM>}
                </View>

                {!!activity?.gem_id && <View style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    paddingHorizontal: 10,
                    marginBottom: 10,
                    width: "100%"
                }}>
                    <ActivityGem activity={activity} large/>
                </View>}

                <ActivityXpKeyValueItem activity={activity} style={{marginTop: 30}}/>
                {activity?.status === "validated" &&
                    <ActivityScoringKeyValueItem activity={activity} style={{marginTop: 20}}/>}


                {/* ** Activity review comment (if applicable) */}
                {(!!activity?.review_comment) &&
                    <KeyValueItem keyText="COMMENTAIRE DE SAM"
                                  valueComponent={<ChampionBubble name={"sam"}
                                                                  variant={samVariantForActivity(activity)}
                                                                  noAnimation={true}
                                                                  championPosition="left"
                                                                  texts={[activity?.review_comment]}
                                                                  onNextPress={() => {
                                                                  }}
                                      // ref={championsBubbleRef}
                                  />}
                                  style={{marginTop: 20}}
                                  columnMode/>}

                {activityMaterial && <View style={{
                    marginTop: 30
                    // backgroundColor: theme.colors.lighter,
                    // height: dimensions?.height / 3 - 50
                }}>
                    <FilledButtonM mode={"outlined"}
                                   label={"Voir "+ dMaterialSlug}
                                   style={{}}
                                   color={theme.colors.primary}
                                   icon={activityMaterial?.url ? "link" : activityMaterial?.attachments?.length > 0 ? "image" : "align-justify"}
                                   onPress={() => {
                                       // open material modal
                                       handleShowModal()
                                   }}
                    />
                </View>}

                {activity?.activity_logs && activity?.steps?.map((step, index) => <StepLogs step={step}
                                                                                            key={index}
                                                                                            index={index}
                                                                                            style={{marginTop: 20}}
                                                                                            activity={activity}/>)}

                {isOpen && <MaterialModal handleShowModal={handleShowModal}
                                          isOpen={isOpen}
                                          material={activityMaterial}/>}

                {IS_WEB && <ActivityJsonDetails activity={activity} />}

            </View>


            {(activity?.status === "pending_review" && (activity?.review_locked_by === props.user?.id)) &&
                <ActivityReviewSection activity={activity} schoolSubject={schoolSubject}/>}
        </ScrollView>

        {(activity?.status === "pending_review" && activity?.review_locked_by !== props.user?.id) && <View style={{
            backgroundColor: theme.colors.white,
            paddingBottom: 20,
            paddingTop: 20,
            borderTopWidth: 1,
            borderColor: theme.colors.lightMore,
            position: "absolute",
            width: "100%",
            alignItems: "center",
            bottom: 0
        }}>

            <FilledButtonM color={theme.colors.primary}
                           icon={"star"}
                           loading={props.reviewLockRequest}
                           disabled={props.reviewLockRequest}
                           onPress={onReviewLock}
                           style={{
                               width: dimensions?.width - 20
                           }}
                           label="ÉVALUER"/>

        </View>}


        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

    </View>
}
const mapStateToProps = state => {
    return {
        reviewLockRequest: state.api.reviewLock?.request,
        getActivitiesRequest: state.api.getActivities?.request,
        getActivitiesSuccess: state.api.getActivities?.success,
        getActivitiesError: state.api.getActivities?.error,

        scoringCriteria: state.data.staticData?.scoring_criteria,
        reviewer: state.data.reviewer?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReviewScreen)