import React, {useContext} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ModalM} from "@common/components/modal/ModalM";
import {ScrollView, View} from "react-native";
import {theme} from "@common/theme/theme";
import {ListItemM} from "@common/components/list/ListItemM";
import {ListGroupM} from "@common/components/list/ListGroupM";
import {daysOptions} from "@data/constants/formConstants";
import {useController} from "react-hook-form";
import TitleM from "@common/components/text/TitleM";

export default function SelectDayModal({
                                           isOpen,
                                           handleShowModal,
                                           navigation,
                                           initialValue = null,
                                           control,
                                           index,
                                           fieldName = "days",
                                           onConfirmDay
                                       }) {
    const dimensions = useContext(DimensionsContext)
    const {field} = useController({
        control,
        name: fieldName
    })
    return (
        <ModalM visible={isOpen} onDismiss={handleShowModal}
                style={{width: IS_WEB && dimensions.width > 800 ? "50%" : "90%", maxHeight: "80%"}}>
            <ScrollView style={{
                marginTop: 10,
                backgroundColor: theme.colors.white,
                paddingHorizontal: 20,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10
            }}
                        contentContainerStyle={{
                            flexDirection: IS_WEB && dimensions.width > 800 ? "row" : "column",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            paddingBottom: 30
                        }}>
                <TitleM>Sélectionner un jour</TitleM>
                <View style={{
                    marginBottom: 10,
                    marginTop: 20,
                    width: "100%",
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <ListGroupM options={daysOptions}
                                style={{
                                    // marginHorizontal: 20
                                    marginBottom: 70
                                }}
                                itemHeight={70}
                                renderItem={(item) => {
                                    return <ListItemM isFirst={(item.index === 0)}
                                                      isLast={(item.index === daysOptions?.length - 1)}
                                                      onPress={() => {
                                                          field.onChange(item.item?.value)
                                                          onConfirmDay(item.item?.label, index, item.item.value)
                                                          handleShowModal()
                                                      }}
                                                      itemHeight={70}
                                                      selected={item.item?.value === field.value || item.item?.value === initialValue}
                                                      option={item.item}
                                                      disabled={item.item?.disabled}
                                    />
                                }}
                    />
                </View>
            </ScrollView>
        </ModalM>
    )
}