import React, {useContext, useEffect, useState} from 'react';
import {View, TouchableOpacity} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import sparkle from "../../../assets/icons/stars/sparkle_b.png";
import {Rating} from "react-native-ratings/src";
import {IconButton, Switch} from "react-native-paper";
import TextInputM from "@common/components/input/TextInputM";
import {useForm} from "react-hook-form";
import {DimensionsContext} from "@common/utils/mobileUtils";
import RemunerationModal from "./RemunerationModal";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import AssignTutorModal from "./AssignTutorModal";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {tutorsService} from "@data/services/tutorsService";
import {useNavigation} from "@react-navigation/native";
import {createArray} from "@data/utility/Utils";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {congratsTypes} from "@common/screens/CongratsScreen";

const EvaluationScreen = (props) => {

    const {tutorId, evaluated, report, tutor} = props
    const dimensions = useContext(DimensionsContext)
    const [motivationScore, setMotivationScore] = useState(report?.motivation_score)
    const [educationalScore, setEducationalScore] = useState(report?.educational_score);
    const [technical, setTechnicalScore] = useState(report?.technical_score);
    const [applicationStatus, setApplicationStatus] = useState(report?.is_validated ?? false);
    const [remuneration, setRemuneration] = useState(tutor?.profile?.initial_salary);
    const [message, setMessage] = useState(report?.message);
    const [admins, setAdmins] = useState([]);
    const [admin, setAdmin] = useState(tutor?.admin ?? null);
    const disabled = motivationScore === null || educationalScore === null || technical === null || message === null || applicationStatus && (remuneration === null || admin === null)
    const role = ["admin"]
    const navigation = useNavigation()

    useEffect(() => {
        props.apiRequest(backOfficeService.searchAdmins, {}, {}, {})
    }, []);

    useApiStatus(
        backOfficeService.searchAdmins, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            setAdmins(data?.users)
            // console.log(data)
        } // success callback
    )

    useApiStatus(
        tutorsService.submitApplicationEvaluation, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {

            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Le tuteur a été notifié"
                }
            }]
            navigation.replace("congrats", {congrats})

        } // success callback
    )

    const [visible, setVisible] = useState(false)
    const handleShowModal = () => {
        setVisible(!visible)
    }

    const [visibleAdmins, setVisibleAdmins] = useState(false)
    const handleShowModalAdmins = () => {
        setVisibleAdmins(!visibleAdmins)
    }

    const remunerationsList = createArray(10,30)?.map(i => {
        return {
            value: i,
            label: i + "€",
        }
    })

    const {control, handleSubmit, formState: {errors, dirtyFields}} = useForm({
        defaultValues: {
            "message": report?.message
        }
    })

    const scoreItem = (title, value, setValue) => <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%',
        alignItems: 'center',
        marginTop: 10
    }}>
        <TextM fontWeight={"SemiBold"}>
            {title}
        </TextM>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Rating
                ratingCount={5}
                type={"custom"}
                ratingImage={sparkle}
                ratingColor={theme.colors.accent}
                ratingBackgroundColor={theme.colors.lighter}
                startingValue={evaluated ? value : 0}
                readonly={evaluated}
                imageSize={30}
                onFinishRating={(value) => {
                    setValue(value)
                }}
            />
            <TextM style={{marginLeft: 15}} fontWeight={'Bold'}>
                {value ?? "-"}
            </TextM>
        </View>
    </View>

    const onSubmit = (data) => {
        props.apiRequest(tutorsService.submitApplicationEvaluation, {id: tutorId}, {
            "motivation_score": motivationScore,
            "educational_score": educationalScore,
            "technical_score": technical,
            "message": message,
            "is_validated": applicationStatus,
            "salary": remuneration,
            "admin_id": admin?.id
        })
    }
    return (
        <>
            <TextM color={theme.colors.primary}
                   fontWeight={"Bold"}
                   style={{alignSelf: "center"}}
                   fontSize={16}>
                Evaluation
            </TextM>
            <View style={{
                width: '80%',
                alignItems: 'center',
                borderRadius: 10,
                borderWidth: 1,
                borderColor: theme.colors.lightMore,
                padding: 10,
                backgroundColor: theme.colors.white,
                alignSelf: "center",
                marginTop: 10
                // height: dimensions?.height,
            }}>

                {scoreItem("Score motivation", motivationScore, setMotivationScore)}
                {scoreItem("Score pédagogie", educationalScore, setEducationalScore)}
                {scoreItem("Score technique", technical, setTechnicalScore)}
                <View style={{
                    flexDirection: 'row',
                    marginTop: 20,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <TextM fontWeight={"SemiBold"} style={{marginRight: 10}}>
                        Refusée
                    </TextM>
                    <Switch color={applicationStatus ? theme.colors.success : theme.colors.error}
                            disabled={evaluated}
                            style={{alignSelf: "center"}}
                            value={applicationStatus}
                            onValueChange={(value) => {
                                setApplicationStatus(value)
                            }}/>
                    <TextM fontWeight={"SemiBold"} style={{marginLeft: 10}}>
                        Validée
                    </TextM>
                </View>
                {applicationStatus && <View style={{width: '80%'}}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        marginTop: 10
                    }}>
                        <TextM fontWeight={"SemiBold"}>
                            Salaire initial
                        </TextM>
                        {remuneration ? <TouchableOpacity onPress={() => setVisible(true)} disabled={evaluated}>
                            <TextM fontWeight={'SemiBold'}>
                                {remuneration}€
                            </TextM>
                        </TouchableOpacity> : <IconButton
                            icon="plus"
                            iconColor={theme.colors.primary}
                            size={16}
                            style={{marginLeft: 10}}
                            onPress={() => {
                                //navigation.goBack()
                                setVisible(true)
                            }}
                        />}
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        alignItems: 'center',
                        marginTop: 10
                    }}>
                        <TextM fontWeight={"SemiBold"}>
                            Attribuer un admin
                        </TextM>
                        {admin ? <TouchableOpacity onPress={() => setVisibleAdmins(true)} disabled={evaluated}>
                            <TextM fontWeight={'SemiBold'}>
                                {admin?.display_name}
                            </TextM>
                        </TouchableOpacity> : <IconButton
                            icon="plus"
                            iconColor={theme.colors.primary}
                            size={16}
                            style={{marginLeft: 10}}
                            onPress={() => {
                                //navigation.goBack()
                                setVisibleAdmins(true)
                            }}
                        />}
                    </View>
                </View>}
                <TextInputM
                    placeholder="Message..."
                    name="message"
                    contentContainerStyle={{
                        width: "90%"
                    }}
                    style={{
                        height: 250,
                        backgroundColor: theme.colors.white,
                        marginTop: 10
                    }}
                    multiline={true}
                    disabled={evaluated}
                    numberOfLines={3}
                    control={control}
                    errors={errors}
                    onChangeAdditional={(value) => {
                        setMessage(value)
                    }}
                />

                <RemunerationModal handleShowModal={handleShowModal} isOpen={visible} data={remunerationsList}
                                   setRemuneration={setRemuneration}/>
                <AssignTutorModal handleShowModal={handleShowModalAdmins} data={admins} isOpen={visibleAdmins}
                                  setAdmin={setAdmin}/>
                {!evaluated && <FilledButtonM color={applicationStatus ? theme.colors.success : theme.colors.error}
                                              onPress={onSubmit}
                                              disabled={disabled}
                                              loading={props.submitApplicationEvaluationRequest}
                                              style={{
                                                  width: "90%",
                                                  marginTop: 20
                                                  // position: 'absolute',
                                                  // bottom: 80
                                              }}
                                              label="Envoyer"/>}
            </View>

        </>
    )
}
const mapStateToProps = state => {
    return {
        submitApplicationEvaluationRequest: state.api?.submitApplicationEvaluation?.request,
        users: state.data?.users?.usersList,
        tutorApplication: state.data?.tutors?.tutorApplication,
        applicationSteps: state.data.staticData?.application_steps
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationScreen)