import {useSelector} from "react-redux";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import intensityLevel1 from "../../../assets/icons/intensity-level/intensity_level_1.png";
import TextM from "@common/components/text/TextM";
import React from "react";
import intensityLevel2 from "../../../assets/icons/intensity-level/intensity_level_2.png";
import intensityLevel3 from "../../../assets/icons/intensity-level/intensity_level_3.png";

export const materialLevelsIcons = {
    "1": intensityLevel1,
    "2": intensityLevel2,
    "3": intensityLevel3
}
export const materialLevelsColors = {
    "1": theme.colors.successLighter,
    "2": theme.colors.warningLighter,
    "3": theme.colors.errorLighter,
}

export function ActivityLevelBadge({level, style = {}}) {
    const materialLevels = useSelector(state => state.data?.staticData?.material_levels)

    return <View style={{
        borderWidth: 1,
        borderColor: theme.colors.white,
        backgroundColor: materialLevelsColors[level],
        borderRadius: 4,
        paddingHorizontal: 4,
        paddingVertical: 2,
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "flex-start",
        marginTop: 4,
        ...style
    }}
    >
        <IconImageM
            source={materialLevelsIcons[level] ?? intensityLevel1}
            width={10}
            style={{
                marginRight: 4,
                tintColor: theme.colors.white
            }}
        />
        <TextM fontWeight="SemiBold"
               color={theme.colors.white}
               fontSize={10}>
            {materialLevels?.[level]}
        </TextM>
    </View>;
}