import { View } from "react-native";
import React, { useContext, useEffect } from "react";
import { theme } from "@common/theme/theme";
import { displayTimeSeconds } from "@data/utility/Utils";
import { IconButton } from "react-native-paper";
import { useInterval } from "@common/utils/useInterval";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import { State, TapGestureHandler } from "react-native-gesture-handler";
import TextM from "@common/components/text/TextM";
import { SnackBarM } from "@common/components/alert/snackbar/SnackBar";
import { PlaySoundM } from "@common/components/playSound/PlaySoundM";
import CircularProgress from "react-native-circular-progress-indicator/src/circularProgress/index";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withSequence,
  withTiming,
} from "react-native-reanimated";

export const CHRONO_ORANGE_LIMIT = 95;
export const CHRONO_RED_LIMIT = 105;

export const calculateTimerColor = (timerProgress, status, whiteMode = false) =>
  status !== "finished"
    ? timerProgress < CHRONO_ORANGE_LIMIT
      ? whiteMode
        ? theme.colors.white
        : theme.colors.primary
      : timerProgress < CHRONO_RED_LIMIT
      ? theme.colors.warning
      : theme.colors.error
    : timerProgress < CHRONO_RED_LIMIT
    ? theme.colors.success
    : theme.colors.error;

const TimerContainer = (props) => {
  // ** Component props
  const {
    time,
    setTime,
    timerDuration,
    status,
    setStatus,
    recordStartTime,
    chronoMode,
    startChrono,
    setStartedWithOfflineMode,
    isOffline,
    liveMode,
    estimatedDuration,
    animationFinished,
    setAnimationFinished,
    activityPaused,
  } = props;

  const onPausePlayTap = () => {
    onSwitch();
  };

  useEffect(() => {
    // console.log("activityPaused", activityPaused)
    if (activityPaused) {
      setStatus("paused");
    }
  }, [activityPaused]);

  const onSwitch = (force) => {
    if (status === "finished" || (!status && !force)) return;
    if (status !== "ongoing") setStatus("ongoing");
    else setStatus("paused");
  };

  const onMultiTap = (event) => {
    if (event.nativeEvent.state === State.ACTIVE) {
      if (!chronoMode && status === "ongoing") setTime(3);
      else if (chronoMode && status === "ongoing")
        setTime((time) => time + 10 * 60);
    }
  };

  // ** custom setInterval handler compatible with hooks (cf https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
  useInterval(
    () => {
      if (!chronoMode) {
        if (time > 0) setTime((pt) => pt - 1);
        if (time <= 0) setStatus("finished");
      } else {
        setTime((pt) => pt + 1);
      }
    },
    status === "ongoing" ? 1000 : null
  );

  const dimensions = useContext(DimensionsContext);

  //console.log(dimensions)

  // let mWidth = (dimensions?.width / 2 - 60)
  //
  // let adaptedWidth = mWidth < 140 ? mWidth : 140

  const timerProgress = (time * 100) / estimatedDuration;
  const timerColor = calculateTimerColor(timerProgress, status);

  const opacity = useSharedValue(0);

  // Set the opacity value to animate between 0 and 1
  opacity.value = withRepeat(
    withSequence(
      withTiming(1, { duration: 500, easing: Easing.ease }),
      withTiming(0, { duration: 500, easing: Easing.ease })
    ),
    -1,
    true
  );

  const style = useAnimatedStyle(
    () => ({
      opacity: opacity.value,
    }),
    []
  );

  useEffect(() => {
    if (status === null)
      setTimeout(() => {
        PlaySoundM.play(
          require("../../../../assets/sounds/game-start-cut.mp3")
        );
      }, 2000);
  }, []);

  useEffect(() => {
    if (time === Math.round((estimatedDuration * CHRONO_ORANGE_LIMIT) / 100)) {
      SnackBarM.show({
        text1: "⏳ Tu as bientôt atteint la durée max prévue",
        duration: 4000,
      });
      PlaySoundM.play(require("../../../../assets/sounds/question_002.mp3"));
    } else if (
      time === Math.round((estimatedDuration * CHRONO_RED_LIMIT) / 100)
    ) {
      SnackBarM.show({
        text1: "⏳ Tu as dépassé la durée max prévue",
        duration: 4000,
      });
      PlaySoundM.play(require("../../../../assets/sounds/error_003.mp3"));
    }
  }, [time]);

  useEffect(() => {
    setTimeout(() => {
      if (!animationFinished && liveMode) {
        setAnimationFinished(true);
        startChrono();
      }
    }, 6000);
  }, []);

  return (
    <View
      style={{
        // backgroundColor: '#faf',
        flex: 1,
        alignItems: "center",
        alignContent: "flex-start",
        justifyContent: "flex-start",
        marginBottom: 10,
      }}
    >
      <View
        style={{
          // flexDirection: "row",
          alignItems: "center",
          //backgroundColor:"red"
        }}
      >
        {!!chronoMode && (
          <TapGestureHandler
            onHandlerStateChange={onMultiTap}
            numberOfTaps={30}
          >
            <View
              style={{
                marginRight: 3,
                flexDirection: "row",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <View style={{ marginRight: 5, position: "relative", width: 65 }}>
                <TextM
                  fontWeight={"SemiBold"}
                  color={timerColor}
                  style={{ textAlign: "center" }}
                  fontSize={20}
                >
                  {displayTimeSeconds(time)}
                </TextM>
                <TextM
                  fontWeight={"Medium"}
                  color={timerColor}
                  style={{ textAlign: "center" }}
                  fontSize={12}
                >
                  {"/" + displayTimeSeconds(estimatedDuration)}
                </TextM>
              </View>
              {!IS_WEB ? (
                <View style={{ marginRight: 5 }}>
                  <CircularProgress
                    //title={displayTimeSeconds(time)}
                    value={Math.min((time * 100) / estimatedDuration, 100)}
                    radius={30}
                    activeStrokeColor={timerColor}
                    inActiveStrokeColor={timerColor}
                    activeStrokeWidth={10}
                    inActiveStrokeWidth={10}
                    inActiveStrokeOpacity={0.1}
                    textColor={timerColor}
                    titleStyle={{ fontFamily: "Montserrat-Medium" }}
                    titleFontSize={10}
                    showProgressValue={false}
                  />
                  {status !== "finished" && timerProgress > 100 ? (
                    <Animated.View
                      style={[
                        {
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: [{ translateX: -16 }, { translateY: -17 }],
                        },
                        style,
                      ]}
                    >
                      <FontAwesome5
                        name={"exclamation-circle"}
                        size={32}
                        color={timerColor}
                      />
                    </Animated.View>
                  ) : (
                    !!status &&
                    status !== "finished" && (
                      <IconButton
                        icon={status === "ongoing" ? "pause" : "play"}
                        iconColor={timerColor}
                        size={17}
                        onPress={onPausePlayTap}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: [{ translateX: -18 }, { translateY: -18 }],
                        }}
                      />
                    )
                  )}
                </View>
              ) : status !== "finished" && timerProgress > 100 ? (
                <Animated.View style={[{}, style]}>
                  <FontAwesome5
                    name={"exclamation-circle"}
                    size={32}
                    color={timerColor}
                  />
                </Animated.View>
              ) : (
                !!status &&
                status !== "finished" && (
                  <IconButton
                    icon={status === "ongoing" ? "pause" : "play"}
                    iconColor={theme.colors.primary}
                    size={17}
                    onPress={onPausePlayTap}
                    style={{}}
                  />
                )
              )}
            </View>
          </TapGestureHandler>
        )}
        {/* To shorten the timer, tap 30 times */}
        {!chronoMode && (
          <TapGestureHandler
            onHandlerStateChange={onMultiTap}
            numberOfTaps={30}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextM
                fontWeight={"Medium"}
                color={timerColor}
                style={{
                  marginVertical: 20,
                  width: IS_WEB ? 240 : 200,
                  textAlign: "center",
                }}
                fontSize={
                  dimensions?.width / 7 < 70 ? dimensions?.width / 7 : 60
                }
              >
                {displayTimeSeconds(time)}
              </TextM>
            </View>
          </TapGestureHandler>
        )}
        {/*          {!!estimatedDuration && <TextM color={theme.colors.primary}
                                           fontWeight={"Medium"}
                                           fontSize={16}
                                           style={{
                                               position: "absolute",
                                               bottom: IS_WEB ? 20 : 80
                                           }}>/ {displayTimeSeconds(estimatedDuration)}</TextM>}*/}
      </View>
    </View>
  );
};

export default TimerContainer;
