import React, {useContext, useState} from 'react';
import {ModalM} from "@common/components/modal/ModalM";
import {formIds} from "@data/constants/formConstants";
import CameraImagesM from "@common/camera/CameraImagesM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import AudioVideoActivityStep from "../execute/AudioVideoActivityStep";
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useSelector} from "react-redux";
import {useCamera} from "@common/hooks/useCamera";
import IconButtonM from "@common/components/button/IconButtonM";

const MyWorkModal = ({
                         images,
                         setImages,
                         isOpen,
                         handleShowModal,
                         onSubmit = () => {
                         },
                         activityType,
                         status,
                         setStatus,
                         control,
                         activityId,
                         recordingURI,
                         setRecordingURI,
                         stepDescriptions
                     }) => {

    const dimensions = useContext(DimensionsContext)

    const postActivityLogV1Request = useSelector(state => state.api.postActivityLogV1?.request)
    const user = useSelector(state => state.data?.currentUser?.object)

    const [launchCamera] = useCamera(formIds.NEW_MATERIAL, true, true)

    const [showInfo, setShowInfo] = useState(user?.xp <= 300)

    return (<ModalM visible={isOpen} onDismiss={() => {
        handleShowModal()
        setRecordingURI(null)
    }}
                    style={{width: dimensions?.width > 800 ? "60%" : "90%", maxHeight: "80%", alignItems: 'center'}}>
        <TextM fontSize={25} fontWeight={"ExtraBold"}
               color={theme.colors.primary}
               style={{
                   marginTop: 20
               }}
        >
            {"Mon travail".toUpperCase()}
        </TextM>

        <IconButtonM
            onPress={() => {setShowInfo(!showInfo)}}
            icon={"info"}
            size={12}
            style={{
                height: 22,
                width: 22,
                backgroundColor: theme.colors.primary,
                alignSelf: "center",
                borderRadius: 20,
                position: "absolute",
                right: 20,
                top: 20
            }}
            iconColor={theme.colors.white}
        />

        {showInfo && <TextM
            style={{
                marginBottom: 10,
                textAlign: 'center',
                marginHorizontal: 10
            }}
            fontSize={12}
            wrap
            color={theme.colors.grey}>

            {stepDescriptions[1]}
        </TextM>}
        {activityType === "photo" ? <CameraImagesM images={images}
                                                   setImages={setImages}
                                                   activityId={activityId}
                                                   longPressDisabled={postActivityLogV1Request}
                                                   noButton
                                                   style={{
                                                       marginTop: 20,
                                                       // height: dimensions?.height * 0.6,
                                                       width: "100%"
                                                   }}
                                                   formId={formIds.NEW_MATERIAL}
                                                   dismissModal={handleShowModal}
                                                   useFullWidthToScroll
        /> : <View style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: "100%"
        }}
        >
            <AudioVideoActivityStep stepIndex={1}
                                    status={status}
                                    setStatus={setStatus}
                                    finishStep={onSubmit}
                                    control={control}
                                    stepData={[]}
                                    recordingURI={recordingURI}
                                    setRecordingURI={setRecordingURI}
                                    style={{
                                        width: "100%"
                                    }}
            />
        </View>}
        <View style={{height: 30, flexDirection: "row", alignItems: "center", paddingVertical: 4}}>
            {postActivityLogV1Request && <>
                <TextM style={{textAlign: "center", marginLeft: 10}}
                       fontSize={12}
                       wrap>Sauvegarde {activityType === "audio" ? "du vocal" : "des photos"} en cours...</TextM>
            </>}
        </View>
        {activityType === "photo" && <FilledButtonM color={theme.colors.primary}
                                                    mode={"outlined"}
                                                    style={{width: "90%", alignSelf: "center"}}
                                                    onPress={() => {
                                                        launchCamera()
                                                        handleShowModal()
                                                    }}
                                                    icon={'camera'}
                                                    label={"Ajouter une photo"}/>}
        <FilledButtonM color={theme.colors.success}
                       disabled={postActivityLogV1Request || (activityType === "audio" && !recordingURI)}
                       onPress={() => {
                           if (IS_WEB && images.length === 0 && activityType === "photo") {
                               DialogM.show({
                                   text1: 'Veux-tu vraiment terminer l\'activité sans avoir pris de photo ?',
                                   buttonText1: 'Terminer',
                                   buttonColor1: theme.colors.success,
                                   onPress: () => {
                                       if (activityType === "photo")
                                           onSubmit()
                                       else {
                                           setStatus("finished")
                                           handleShowModal()
                                       }
                                   },
                                   buttonText2: 'Annuler'
                               })
                           } else {
                               if (activityType === "photo")
                                   onSubmit()
                               else {
                                   setStatus("finished")
                                   handleShowModal()
                               }
                           }
                           handleShowModal()
                       }}
                       icon={'bolt'}
                       style={{
                           width: "90%",
                           alignSelf: "center"
                       }}
                       label="Soumettre l'activité"/>
        <FilledButtonM mode={"text"}
                       color={theme.colors.primary}
                       style={{width: IS_WEB ? "50%" : "90%", height: 40, marginBottom: 10}}
                       contentStyle={{height: 40}}
                       onPress={handleShowModal}
                       label={"Retour"}/>
    </ModalM>)
}

export default MyWorkModal;