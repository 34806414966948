import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {roomService} from "@data/services/roomService";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {
    sectionStudentClasses,
    subjectPrioritiesSingLight,
    subjectPrioritiesSingLightWithNullOption
} from "@data/constants/formConstants";
import TextM from "@common/components/text/TextM";
import {subscriptionService} from "@data/services/subscriptionService";
import base64 from "@common/utils/base64";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useController} from "react-hook-form";

export const schoolSubjectCategoriesToPriorities = {
    "scientific": "scientific",
    "litterary/economic": "literary",
    "language": "literary"
}

const PrioritizedSchoolSubjectsScreen = (props) => {

    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}

    const user = props.user

    const userEmailb64 = props.route?.params?.user ?? ""
    const userEmail = base64.decode(userEmailb64.replace(/-/g, ""))

    const isHighSchool = sectionStudentClasses?.find(x => x?.title === "Lycée")?.options?.includes(user?.profile?.class)

    const subjectPriority = formValues?.subject_priority
    const prioritySchoolSubjects = formValues?.prioritized_school_subjects

    const formData = [
        {
            label: "Soutien souhaité",
            type: "select.single", // select.single, select.multiple, text, date
            name: "subject_priority",
            options: isHighSchool ? subjectPrioritiesSingLight : subjectPrioritiesSingLightWithNullOption,
            defaultValue: props.user?.profile?.subject_priority,
            validationRules: {},
            // hack to reset the prioritized_school_subjects field when the subject_priority changes
            bottomComponent: (control) => {
                const { field } = useController({
                    control,
                    name: "prioritized_school_subjects",
                });
                useEffect(() => {
                    field.onChange([])
                }, []);
                return <></>
            }
        },
        {
            label: subjectPriority === null ? "Confirmation" : "Choisir 1 ou 2 matières prioritaires",
            description: subjectPriority === null ? "Vous avez choisi l'aide aux devoirs toutes matières." : "parmi les matières " + subjectPrioritiesSingLight.find(x => x.value === subjectPriority)?.label?.toLowerCase() + "s",
            type: subjectPriority === null ? "custom" : "select.multiple",
            topComponent: subjectPriority === null ? <>
                <TextM wrap fontSize={12}>Cet accompagnement peut convenir si vous souhaitez un accompagnement global,
                    non spécialisé. Nous vous proposerons un tuteur ou une tutrice compétent pour accompagner votre enfant
                    globalement, dans les matières scientifiques et littéraires.</TextM>
                <TextM wrap fontSize={12}>Si vous souhaitez voir des progrès rapides dans certaines matières, nous vous
                    recommandons de revenir à l'étape précédente pour choisir un accompagnement plus spécialisé.</TextM>
            </> : <></>,
            component: () => <></>,
            name: "prioritized_school_subjects",
            options: props.schoolSubjects?.filter(x => schoolSubjectCategoriesToPriorities[x?.category] === subjectPriority)
                ?.map(x => ({
                    label: x?.name,
                    value: x?.id,
                    icon: x?.icon,
                    // disabled if prioritySchoolSubjects contains two selected and this one is not one of them
                    disabled: prioritySchoolSubjects?.length >= 2 && !prioritySchoolSubjects?.includes(x?.id)
                })),
            showNextButton: subjectPriority === null,
            defaultValue: [],
            validationRules: {...validation.required(), ...validation.max(2)}
        }
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(formValues)

    }, [formValues])

    const onSubmit = data => {
        if (props.request) return

        props.apiRequest(subscriptionService.updatePrioritizedSchoolSubjects, {}, {
            ...data,
            email: userEmail
        })

    }
    useApiStatus(
        subscriptionService.updatePrioritizedSchoolSubjects, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("subscribe", {user: base64.encode(props.user?.email)})

            DialogM.show({
                text1: "Félicitations !",
                text2: "Les matières de "+ props.user?.firstname +" ont bien été enregistrées.",
            })
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.request}
                         submittedMessage=""
                         serverError={props.error}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        user: state.data.formData?.user,
        billingPortalUrl: state.data.formData?.billingPortalUrl,
        pricingTableData: state.data.formData?.pricingTableData,
        schoolSubjects: state.data.formData?.schoolSubjects,
        pricingTableDataNextMode: state.data.formData?.pricingTableDataNextMode,
        request: state.api.updatePrioritizedSchoolSubjects?.request,
        error: state.api.updatePrioritizedSchoolSubjects?.error,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(PrioritizedSchoolSubjectsScreen)
