import {MenuM} from "@common/components/menu/MenuM";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {renderers} from "react-native-popup-menu";
import React from "react";
import {useController} from "react-hook-form";
import ErrorTextM from "@common/components/text/ErrorTextM";
import {Text} from "react-native-paper";

export const SelectInputM = ({style, onSelect, label, sliderLabel = label, options, value, name, control, noLabel = false, errors}) => {

    const {field} = control ? useController({
        name,
        control
    }) : {field: null};

    const selectedValueLabel = value
        ? options?.find((x) => x?.value === value)?.label
        : options?.find((x) => x?.value === field?.value)?.label;

    const isControlAvailable = !!field;

    const handleOptionSelect = (selectedOptionValue) => {
        onSelect(selectedOptionValue);
        if (isControlAvailable) {
            field?.onChange(selectedOptionValue);
        }
    };

    return <MenuM name={name}
                  center
                  buttonComponent={<>
                      {!noLabel && <TextM color={theme.colors.primary}
                                          fontWeight={"Medium"}
                                          fontSize={12}
                      >{label}</TextM>}
                      <View style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          borderRadius: theme.roundness,
                          paddingVertical: 10,
                          paddingHorizontal: 15,
                          marginTop: 2,
                          borderWidth: 1,
                          borderColor: errors && errors[name] ? theme.colors.error : theme.colors.lightMore,
                          backgroundColor: theme.colors.primaryLighter
                      }}>
                          <TextM color={!value && !field?.value ? theme.colors.light : theme.colors.primary}
                                 fontWeight={!value && !field?.value ? "Regular" : "Medium"}
                                 fontSize={14}
                          >{selectedValueLabel}</TextM>
                          <FontAwesome5 name={"caret-down"} size={18} color={theme.colors.primary}/>
                      </View>
                      {errors && errors[name] && <Text style={{
                          color: theme.colors.error,
                          textAlign: "center"
                      }}>{errors[name].message}</Text>}
                  </>}
                  style={{
                      borderRadius: theme.roundness,
                      ...style
                  }}
                  items={[{
                      title: sliderLabel,
                      type: "title"
                  }].concat(options?.map(x => {
                      return {
                          title: x?.label,
                          onPress: () => handleOptionSelect(x?.value)
                      }
                  }))}
                  renderer={renderers.SlideInMenu}/>
}