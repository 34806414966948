import {IconImageM} from "@common/components/icons/IconsM";
import students from "../../../assets/icons/menus/students.png";
import studentsG from "../../../assets/icons/menus/students-g.png";
import React from "react";
import loupe from "../../../assets/icons/menus/loupe.png";
import loupeG from "../../../assets/icons/menus/loupe-g.png";
import StudentsList from "@common/liveMode/StudentsList";
import ReviewActivitiesTabScreen from "../../reviewer/ReviewActivitiesTabScreen";
import TutorResources from "../../tutor/home/tutorResources/TutorResources";
import write from "../../../assets/icons/menus/write.png";
import writeG from "../../../assets/icons/menus/write-g.png";

export const liveTutorTabScreens = (user, liveVersion) => {
    const iconSize = 36

    return [
        {
            name: "live-students",
            component: StudentsList,
            can: 'my_students',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize * 1.1} source={students}/> :
                    <IconImageM width={iconSize * 1.1} source={studentsG}/>,
                headerTitle: "Participants",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        },
        {
            name: "live-activities-review",
            component: ReviewActivitiesTabScreen,
            can: 'review_activities',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={loupe}/> :
                    <IconImageM width={iconSize} source={loupeG}/>,
                tabBarBadge: user?.pending_reviews_count > 0 ? user?.pending_reviews_count : null,
                headerTitle: "Revue d'activités",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        },
        {
            name: "live-tutor-resources",
            can: 'tutor_resources',
            component: TutorResources,
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={write}/> :
                    <IconImageM width={iconSize} source={writeG}/>,
                headerTitle: "Ressources pédagogiques",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        }

    ]
}