import React, {useContext, useEffect, useRef, useState} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useInterval} from "@common/utils/useInterval";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {setVideoRecord} from "@data/redux/actions/camera";
import {useRequestsBatch} from "@common/hooks/useRequestsBatch";
import moment from "moment";
import {ActivityIndicator} from "react-native-paper";
import {tutorsService} from "@data/services/tutorsService";
import TechnicalQuestionsScreen from "./TechnicalQuestionsScreen";
import {ResizeMode, Video} from "expo-av";

const InterviewQuestion = (props) => {

    const {control, goToNextStep, register, errors, serverError, setFormValues, field} = props

    const questionId = field?.name?.split('_')?.[1]
    // console.log("questionId", questionId)
    const question = props.testQuestions?.find((q) => q?.id === parseInt(questionId))
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    // const answer =
    const answer = props.testAnswers?.find((answer) => answer?.application_question_id === parseInt(questionId))
    const video = useRef(null);
    const [status, setStatus] = useState({});
    const isLastQuestion = props.testQuestions[props.testQuestions.length - 1] === question

    const [reflectionDuration, setReflectionDuration] = useState(Math.max(question?.reflection_duration - Math.min((answer?.viewed_at ? moment().diff(answer?.viewed_at, 'seconds') : 0), question?.reflection_duration - 1), 0));

    const tooLate = answer?.viewed_at && moment().diff(answer?.viewed_at, 'seconds') > 5 * 60

    useEffect(() => {
        props.setVideoRecord(null)

        if (!answer)
            props.apiRequest(tutorsService.submitQuestionViewed, {id: questionId})

    }, []);

    useEffect(() => {
        //console.log(control)
        if (reflectionDuration <= 0 && question?.category !== "technical" && !answer?.video)
            navigation?.push("video-recording", {
                questionId: questionId
            })
    }, [reflectionDuration]);

    useInterval(() => {
        setReflectionDuration(p => p - 1)
    }, reflectionDuration > 0 && !answer?.video && !(answer && tooLate) ? 1000 : null);

    /*    useApiStatus(
            tutorsService.submitTestAnswer, // api service
            null, // success message on toast (string or null)
            true, // error message on toast (true) or keep it in redux state (false)
            (successData) => {
                //console.log("successData", successData)
            }
        )*/

    const videoFile = IS_WEB ? props.videoRecord : {
        uri: props.videoRecord?.uri,
        name: `Masteur_Video_${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.mp4`,
        type: "video/mp4"
    }
    const filesToUpload = [videoFile]

    // console.log(formValues)

    const [globalProgress, submitted, startRequests] = useRequestsBatch(
        [],
        filesToUpload,
        "APPLICATION_FORM",
        {
            service: tutorsService.submitTestAnswer,
            data: {},
            tmpFilesKey: "video",
            tmpFilesFormatter: (attachmentTmp, index) => {
                return attachmentTmp
            },
            additionalData: [],
            params: {id: questionId},
            onSuccessBeforeNext: (successData) => {
                // setAnswer(successData)
                // props.setVideoRecord(null)
                // console.log(successData)
                //goToNextStep()
            }
        },
        [],
        false,
    )

    useEffect(() => {
        // console.log("answer", answer)
        setTimeout(() => {
            if (!answer?.video && props.videoRecord && question?.category !== "technical")
                startRequests()
        }, 700)

    }, [props.videoRecord]);
    const submitAnswer = () => {
        props.setVideoRecord(null)
        goToNextStep()
    }

    return <View style={{
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        flex: 1
    }}>
        {question?.category === "technical" ?
            <TechnicalQuestionsScreen question={question}
                                      control={control}
                                      goToNextStep={goToNextStep}
                                      field={field}/> :
            <View style={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                marginTop: 10,
                flex: 1
            }}>
                {(submitted) ? <>
                    <ActivityIndicator animating={true}
                                       style={{
                                           alignSelf: "center",
                                           justifySelf: "center",
                                           // marginTop: IS_WEB ? 50 : -50,
                                           marginBottom: 10
                                       }}
                                       size="large"
                                       color={theme.colors.primary}/>
                    <TextM style={{textAlign: "center"}} wrap>Envoi de la vidéo en cours...</TextM>
                    <TextM style={{textAlign: "center"}} fontWeight={"Bold"} wrap>{globalProgress}%</TextM>
                </> : (answer?.video || answer?.answer?.startsWith("video_tmp:")) ?
                    <View style={{
                        // marginTop: 200,
                        width: "100%",
                        // height: "100%",
                        // alignSelf: 'center',
                        alignItems: 'center',
                        justifyContent: 'flex-start'
                        // backgroundColor: theme.colors.link,
                    }}>
                        <Video
                            ref={video}
                            style={{
                                position: "relative",
                                width: dimensions?.width,
                                height: dimensions?.height / 2,
                                marginTop: 20,
                                alignSelf: "center"
                            }}
                            videoStyle={{
                                position: IS_WEB ? "relative" : "absolute",
                                // alignSelf: 'center',
                            }}
                            source={{
                                uri: answer?.answer?.startsWith("video_tmp:") ? props.videoRecord?.uri : answer?.video
                            }}
                            useNativeControls
                            resizeMode={ResizeMode.CONTAIN}
                            onPlaybackStatusUpdate={status => setStatus(() => status)}
                        />
                    </View> : (!!answer && tooLate) ?
                        <TextM wrap style={{marginHorizontal: 10, textAlign: "center"}}>Pas de vidéo. Comme tu as
                            déjà vu cette question, passe à la suite.</TextM> : <>
                            <TextM color={theme.colors.primary} fontWeight={"Bold"} style={{textAlign: "center"}}
                                   fontSize={15} wrap>
                                L'enregistrement vidéo va commencer dans
                            </TextM>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 5
                            }}>
                                <TextM color={theme.colors.primary}
                                       fontSize={20}
                                       fontWeight={"Bold"}
                                       style={{
                                           marginRight: 10
                                       }}
                                >
                                    {reflectionDuration}
                                </TextM>
                                <TextM color={theme.colors.primary}>
                                    secondes
                                </TextM>
                            </View>
                            <TextM color={theme.colors.error}
                                   wrap
                                   style={{
                                       marginTop: 20,
                                       textAlign: "center"
                                   }}
                                   fontWeight={"Light"}
                                   fontSize={14}>
                                Attention, si tu quittes cet écran, c'est comme si tu avais passé la question.
                            </TextM>
                        </>}
                {(!!answer?.video || answer?.answer?.startsWith("video_tmp:") || (tooLate)) &&
                    <FilledButtonM label={isLastQuestion ? "Terminer" : "Question suivante"}
                                   color={isLastQuestion ? theme.colors.success : theme.colors.primary}
                                   style={{
                                       position: 'absolute',
                                       bottom: 15
                                   }}
                        //loading={props.submitTestAnswerRequest || submitted}
                                   onPress={submitAnswer}
                    />}
            </View>
        }
    </View>
}

const mapStateToProps = state => {
    return {
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        testQuestions: state.data.tutors?.testQuestions,
        testAnswers: state.data.tutors?.testAnswers,
        videoRecord: state.camera?.videoRecord,
        submitTestAnswerRequest: state.api.submitTestAnswer?.request,
        submitTestAnswerSuccess: state.api.submitTestAnswer?.success
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setVideoRecord
    }

export default connect(mapStateToProps, mapDispatchToProps)(InterviewQuestion)