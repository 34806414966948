import {apiConstants} from "@data/constants/apiConstants"
import {studyProgramsService} from "@data/services/studyProgramsService";

// ** Initial State
const initialState = {
    studyPrograms: [],
}

function studyPrograms(state = initialState, action) {
    switch (action.type) {

        case studyProgramsService.getStudyProgramsAsStudent.name + apiConstants._SUCCESS:
        case studyProgramsService.getStudyProgramsAsTutor.name + apiConstants._SUCCESS:
            return {
                ...state,
                studyPrograms: action.data
            }
        case studyProgramsService.indexPaginatedStudyPrograms.name + apiConstants._SUCCESS:
            let studyPrograms = []
            if (action.data?.meta?.current_page === 1) {
                studyPrograms = action.data?.study_programs
            } else {
                studyPrograms = state.studyPrograms?.concat(action.data?.study_programs)
            }
            return {
                ...state,
                studyPrograms: studyPrograms
            }
        case studyProgramsService.deleteStudyProgram.name + apiConstants._SUCCESS:
            return {
                ...state,
                studyPrograms: state.studyPrograms.filter(x => x.id !== action.routeParams.id)
            }
        case studyProgramsService.newStudyProgram.name + apiConstants._SUCCESS:
            return {
                ...state,
                studyPrograms: [action.data, ...state.studyPrograms]
            }
        case studyProgramsService.updateStudyProgram.name + apiConstants._SUCCESS:
            return {
                ...state,
                studyPrograms: state.studyPrograms.map(x => x.id === action.routeParams.id ? action.data : x)
            }
        default:
            return state
    }
}

export default studyPrograms
