import React, {useContext, useEffect, useState} from 'react';
import {Keyboard, StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import yup, {validation} from "@data/utility/validation";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {theme} from "@common/theme/theme";
import {activitiesService} from "@data/services/activitiesService";
import {useRoute} from "@react-navigation/native";
import {useRequestsBatch} from "@common/hooks/useRequestsBatch";
import MaterialPicker from "./MaterialPicker";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {materialLabel} from "../activity/todo/ActivityPicker";

const ManageMaterialScreen = (props) => {

    // ** Component props
    const {navigation} = props
    const {activityId} = props.route.params

    const route = useRoute()
    const dimensions = useContext(DimensionsContext)

    const routeName = route?.name

    const [formValues, setFormValues] = useState(null)
    const [submittedMessage, setSubmittedMessage] = useState("On réceptionne ton énoncé.")

    const currentActivity = props.user?.activities?.find(a => a?.id === activityId) ?? props.user?.todo_activities?.find(a => a?.id === activityId)
    const currentActivityMaterial = currentActivity?.material
    // console.log("currentActivityMaterial", currentActivityMaterial)

    const currentSubject = props.schoolSubjects?.find(s => s?.id === currentActivity?.school_subject_id)
    const currentActivityTemplate = props.activityTemplates?.find(s => s?.id === currentActivity?.activity_template_id)

    const apiServiceError = (routeName === "update-material" ? props.putMaterialError : props.postMaterialError) || props.vaporFileUploadError
    const requestRouteParams = routeName === "update-material" ? {id: currentActivityMaterial?.id} : {activity_id: activityId}
    const requestApiService = routeName === "update-material" ? activitiesService.putMaterial : activitiesService.postMaterial


    const onSuccess = routeName === "update-material" ? () => {
            navigation.replace("congrats", {
                congrats: [
                    {
                        congratsType: congratsTypes.GENERIC,
                        congratsData: {
                            title: "Succés",
                            text: "L'énoncé a bien été modifié"
                        }
                    }
                ]
            })
            return true
        } :
        () => {
            navigation.replace("congrats", {
                congrats: [
                    {
                        congratsType: congratsTypes.GENERIC,
                        congratsData: {
                            title: "Terminé",
                            text: "L'énoncé a bien été ajouté"
                        }
                    }
                ]
            })
            return true

        }

    const filesToUpload = formValues?.material_attachments

    // console.log(formValues)

    const [globalProgress, submitted, startRequests] = useRequestsBatch(
        [],
        filesToUpload,
        "MATERIAL_FORM",
        {
            service: requestApiService,
            data: formValues,
            tmpFilesKey: "material_attachments",
            tmpFilesFormatter: (attachmentTmp, index) => {
                return attachmentTmp
            },
            additionalData: [],
            params: requestRouteParams,
            onSuccessBeforeNext: onSuccess,
            onError: () => {
            }
        },
        [],
        true
    )


    const formData = [
        {
            label: materialLabel(currentActivityTemplate?.slug, currentSubject?.name),
            type: "custom",
            // name: "material_attachments",
            inputs: [
                {
                    name: "material_chapter",
                    defaultValue: routeName === "update-material" ? (currentActivityMaterial?.chapter ?? null) : null,
                },
                {
                    name: "material_text",
                    defaultValue: routeName === "update-material" ? (currentActivityMaterial?.text ?? null) : null,
                },
                {
                    name: "material_url",
                    defaultValue: routeName === "update-material" ? (currentActivityMaterial?.url ?? null) : null
                },
                {
                    name: "material_attachments",
                    defaultValue: routeName === "update-material" ? (currentActivityMaterial?.attachments?.map(x => {
                        return {
                            uri: x,
                            width: IS_WEB ? dimensions?.width * 0.4 : dimensions?.width * 0.6,
                            height: dimensions?.height * 0.4,
                        }
                    }) ?? []) : []
                },
                {
                    name: "material_level",
                    defaultValue: routeName === "update-material" ? (currentActivityMaterial?.level ?? null) : 1
                }
            ],
            // options: [],
            // defaultValue: [],
            component: MaterialPicker,
        },
    ]
    const defaultValues = {}
    const formSchema = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log("PARENT :")
        // console.log(defaultValues)

    }, [formValues])


    const onSubmit = data => {
        if (submitted) return
        Keyboard.dismiss()
        // console.log("data", data)
        startRequests()

    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         formData={formData?.filter(x => !x.is_masked)}
                //champion={}
                         champion={{name: "zoe", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         formSchema={yup.object().shape(formSchema)}
                         onSubmit={onSubmit}
                         submitted={submitted}
                         submittedMessage={submittedMessage}
                         serverError={apiServiceError}
                         circularProgress
                         circularProgressValue={globalProgress}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        // putMaterialRequest: state.api.putMaterial?.request,
        putMaterialError: state.api.putMaterial?.error,
        // postMaterialRequest: state.api.postMaterial?.request,
        postMaterialError: state.api.postMaterial?.error,
        schoolSubjects: state.data.staticData?.school_subjects,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ManageMaterialScreen)