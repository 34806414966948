import {useCallback, useState} from 'react'
import {DEFAULT_DATA, DEFAULT_OPTIONS, mergeIfDefined} from "@common/components/alert/snackbar/useSnackBar";


export function useDialog({defaultOptions}) {
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState(DEFAULT_DATA);

    const initialOptions = mergeIfDefined(
        DEFAULT_OPTIONS,
        defaultOptions)

    const [options, setOptions] = useState(initialOptions);

    const hide = useCallback(() => {
        setIsVisible(false);
        options.onHide();
    }, [options]);

    const show = useCallback(
        (params) => {
            const {
                component = DEFAULT_DATA?.component,
                champion = DEFAULT_DATA.champion,
                variant = DEFAULT_DATA.variant,
                text1 = DEFAULT_DATA.text1,
                text2 = DEFAULT_DATA.text2,
                buttonText1 = DEFAULT_DATA.buttonText1,
                buttonText2 = DEFAULT_DATA.buttonText2,
                buttonText3 = DEFAULT_DATA.buttonText3,
                buttonDisabled1 = DEFAULT_DATA.buttonDisabled1,
                buttonColor1 = DEFAULT_DATA.buttonColor1,
                buttonColor2 = DEFAULT_DATA.buttonColor2,
                buttonColor3 = DEFAULT_DATA.buttonColor3,
                duration = DEFAULT_DATA.duration,
                onShow = initialOptions.onShow,
                onHide = initialOptions.onHide,
                onPress = initialOptions.onPress,
                onPressBack = initialOptions.onPressBack,
                onPress3 = initialOptions.onPress3,
                hideButton1 = DEFAULT_DATA.hideButton1,
                props = initialOptions.props
            } = params;
            setData({
                component,
                champion,
                variant,
                text1,
                text2,
                buttonText1,
                buttonText2,
                buttonText3,
                buttonDisabled1,
                buttonColor1,
                buttonColor2,
                buttonColor3,
                duration,
                hideButton1
            });
            setOptions(
                mergeIfDefined(initialOptions, {
                    duration,
                    onShow,
                    onHide,
                    onPress,
                    onPressBack,
                    onPress3,
                    props
                })
            );
            setIsVisible(true);
            onShow();
        },
        [initialOptions]
    );


    return {
        isVisible,
        data,
        options,
        show,
        hide
    };
}