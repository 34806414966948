import axios from "axios"

function subscribeToNotifications(routeParams, {expo_token}) {

    return axios.post('api/exponent/devices/subscribe', {
        expo_token: expo_token
    })
}

function unsubscribeFromNotifications(routeParams, {expo_token}) {

    return axios.post('api/exponent/devices/unsubscribe', {
        expo_token: expo_token
    })
}

function getCsrfToken() {

    return axios.get('sanctum/csrf-cookie')

}

function getSanctumToken(routeParams, values) {

    return axios.post('api/sanctum/token', {
        email: values.email,
        password: values.password,
        device_name: values.device_name
    })
}

function onboardingViewed() {

    return axios.post('api/user/onboarding-viewed')
}

function championIntroViewed({}, data) {

    return axios.post('api/user/champion-intro-viewed', data)
}

function registerAndGetSanctumToken(routeParams, values) {
    return axios.post('api/v1/register/token', values)
}

function registerV2(routeParams, values) {
    return axios.post('api/v2/register', values)
}

function registerTrialV2(routeParams, values) {
    return axios.post('api/v2/register-trial', values)
}

function registerSpecialV2(routeParams, values) {
    return axios.post('api/v2/register-special', values)
}

function registerWorkshopV2(routeParams, values) {
    return axios.post('api/v2/register-workshop', values)
}

function revokeSanctumToken(routeParams, values) {

    return axios.post('api/sanctum/token/revoke', {})
}

function login(routeParams, values) {

    return axios.post('api/login', {
        email: values.email,
        password: values.password,
        remember: values.remember
    }).then((response) => {

        return response

    })
}

function logout() {

    return axios.post('api/logout').then((data) => {
        // console.log("LOGOUT")

        return data
    })
}

function register(routeParams, values) {
    return axios.post('api/register', values).then((response) => {
        // console.log('server response'+ response);

        return response
    })
}


function checkEmailAddress({}, values) {
    return axios.post('api/check-email-address', values)
}

function checkEmailAddressAuth({}, values) {
    return axios.post('api/check-email-address-auth', values)
}

function emailVerify({id, hash}, data, params) {

    return axios.get(`api/email/verify/${id}/${hash}`, {params})

}

function emailResend() {

    return axios.post('api/email/resend')

}

function sendResetPasswordLink(routeParams, {email}) {

    return axios.post('api/password/email', {
        email
    })

}

function resetPassword(routeParams, values) {

    return axios.post('api/password/reset', values)
}

function updateUser(routeParams, values) {

    return axios.post(`api/user?_method=PUT`, values)

        .then((response) => {

            return response
        })

}

function updateStudentAccount(routeParams, values) {
    return axios.put(`api/user/student-account`, values)
}

function updateTutorAccount(routeParams, values) {
    return axios.put(`api/user/tutor-account`, values)
}


function getUser() {

    return axios.get(`api/user`)

}

function updateProfile(routeParams, values, urlParams, onUploadProgress) {

    return axios.post(`api/user/profile?_method=PUT`, values, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress
    })

}

function updateUserProfile(routeParams, values) {

    return axios.put(`api/user/profile`, values)
        .then((response) => {

            return response
        })

}
function updatePassword(routeParams, values) {
    return axios.put('api/user/password', values)
}

function updateAvatarSelected(routeParams, values) {
    return axios.post('api/user/avatar-selected?_method=PUT', values)
}

function deleteAccount() {
    return axios.delete('api/user')
}

function updateTitleSkillSet(routeParams, values) {
    return axios.put('api/v1/user/title-skill-set', values)
}

function updateSlots(routeParams, values) {
    return axios.put('/api/user/interview-slots', values)
}

function updateStudentSlot({slotId}, values) {
    return axios.put(`/api/user/interview-slots/${slotId}`, values)
}

function updateEmail({}, values) {
    return axios.put(`/api/user/email`, values)
}

function updateClass({}, values) {
    return axios.put(`/api/user/class`, values)
}

function getAvailableTrialLiveSessions() {
    return axios.get(`/api/v1/user/trial-slots`)
}

function postNewTrialLiveSessionChoice({}, values) {
    return axios.post(`/api/v1/user/trial-slots`, values)
}

function postConfirmTrial({}, values) {
    return axios.post(`/api/v1/user/confirm-trial`, values)
}

export const authService = {
    updateSlots,
    updateClass,
    championIntroViewed,
    subscribeToNotifications,
    unsubscribeFromNotifications,
    getCsrfToken,
    login,
    logout,
    register,
    checkEmailAddress,
    checkEmailAddressAuth,
    emailVerify,
    emailResend,
    sendResetPasswordLink,
    resetPassword,
    updateUser,
    updateProfile,
    updateUserProfile,
    updatePassword,
    updateStudentAccount,
    updateTutorAccount,
    updateAvatarSelected,
    getSanctumToken,
    registerAndGetSanctumToken,
    registerV2,
    registerTrialV2,
    registerSpecialV2,
    registerWorkshopV2,
    revokeSanctumToken,
    getUser,
    onboardingViewed,
    deleteAccount,
    updateTitleSkillSet,
    updateStudentSlot,
    updateEmail,
    getAvailableTrialLiveSessions,
    postNewTrialLiveSessionChoice,
    postConfirmTrial,
}
