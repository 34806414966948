import React from 'react';
import {ModalM} from "@common/components/modal/ModalM";
import {AudioRecordingContainer} from "../../student/activity/execute/AudioRecordingContainer";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";

const AudioRecordingModal = (props) => {

    const {
        visible, onDismiss, status = null, setTime, recordingURI, setRecordingURI, setStatus, onSend = () => {
        }
    } = props


    return (
        <ModalM visible={visible}
                onDismiss={onDismiss}
        >
            <TextM wrap
                   color={theme.colors.primary}
                   style={{textAlign: "center", marginTop: 10}}
            >Message vocal</TextM>
            <AudioRecordingContainer status={status}
                                     setTime={setTime}
                                     recordingURI={recordingURI}
                                     setRecordingURI={setRecordingURI}
                                     setStatus={setStatus}
                                     onSend={onSend}
                                     chatMode
            />

        </ModalM>
    )
};

export default AudioRecordingModal;