import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dimensions, Keyboard, Platform, ScrollView, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import LinkTextM from "@common/components/text/LinkTextM";
import {useController, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import * as Device from 'expo-device';
import ErrorTextM from "@common/components/text/ErrorTextM";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {Checkbox, IconButton} from "react-native-paper";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import yup from "@data/utility/validation";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {subscriptionService} from "@data/services/subscriptionService";
import {referralCodeRetrieve} from "./StartTrialNewScreen";
import PhoneInput from "@sesamsolutions/phone-input";
import * as amplitude from "@amplitude/analytics-react-native";
import RegisterTrialPopup from "@common/messenger/RegisterTrialPopup";
import {SelectInputM} from "./SelectInputM";
import {studentClasses, subjectPrioritiesSing} from "@data/constants/formConstants";
import Constants from "expo-constants";

const formSchema = yup.object().shape({
    user_profile: yup.string().required(),
    email: yup.string().email().required(),
    student_class: yup.string().required(),
    subject_priority: yup.string().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    password: yup.string().required().min(8, "Le mot de passe doit contenir au moins 8 caractères"), //min length 8
    newsletter_opt_in: yup.boolean().required()
})

const RegisterTrialSessionScreen = (props) => {

    const {navigation, route} = props

    const referralCode = route?.params?.ref ?? null
    const studentClass = route?.params?.student_class
    const subjectPriority = route?.params?.subject_priority
    const liveRoomIdOrSlug = route?.params?.live_room
    const tutorId = route?.params?.tutor_id === "null" ? null : route?.params?.tutor_id

    // if route name contains "register-holidays"
    const isHolidays = route?.name?.includes("register-holidays")

    const user = props.user

    useEffect(() => {
        referralCodeRetrieve(navigation, route).then()
    }, []);


    const resolver = yupResolver(formSchema);
    const dimensions = useContext(DimensionsContext)

    const [lastInputTime, setLastInputTime] = useState(Date.now());
    const handleInputChange = () => {
        setLastInputTime(Date.now());
    };
    const {control, handleSubmit, formState: {errors, isDirty, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            email: user?.email ?? '',
            password: !user ? '' : 'test1234',
            firstname: user?.firstname ?? '',
            lastname: user?.lastname ?? '',
            newsletter_opt_in: true,
            user_profile: "student",
            'device_name': Device.deviceName ?? "WEB",
            student_class: studentClass ?? user?.profile?.class,
            subject_priority: subjectPriority ?? user?.profile?.subject_priority,
            live_room: 0,
            parent_mobile: user?.parent_mobile ?? '',
            referral_code: referralCode,
            is_holidays: isHolidays,
            is_from_meta_ad: !!route.params?.fbclid,
        }
    })

    const [submittedOnce, setSubmittedOnce] = useState(false)

    const {field: newsletterOptInField} = useController({
        name: "newsletter_opt_in",
        control
    })

    const {field: parentMobileField} = useController({
        name: "parent_mobile",
        control
    })

    const service = !props.user ? authService.registerTrialV2 : subscriptionService.subscribeToTrialV2
    const request = !props.user ? props.registerTrialV2Request : props.subscribeToTrialV2Request

    const onSubmit = data => {
        setSubmittedOnce(true)

        if (request) {
            return
        }

        if (!parentMobileField.value) {
            return
        }

        Keyboard.dismiss()

        if (IS_WEB) {
            // console.log("EVENT SUBSCRIBE SUCCESS")

            // Déclencher l'événement pour GTM
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'subscribe_to_trial_success' // Nom de l'événement personnalisé
            });

            if (Constants.expoConfig.extra.releaseStage === "production") {
                // Déclencher l'événement pour Meta Pixel
                fbq("track", "StartTrial");
            }
        }

        props.apiRequestWithPrerequest(service, {}, data, {})
    }

    useApiStatus(
        service, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {

            amplitude.track("register.trial.success")

            navigation.goBack()


        }, // success callback,
        ()=>{}, // error callback
        true
    )

    const insets = useSafeAreaInsets()

    const scrollViewRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            justifyContent: 'flex-start',
            paddingTop: insets.top,
            // width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center",
            alignItems: "center",
            width: "100%"
        }}>
            <ScrollView ref={scrollViewRef}
                        contentContainerStyle={{
                            paddingBottom: 200,
                            width: Dimensions.get('screen').width,
                            alignItems: "center"
                        }}
            >

                <View style={{
                    paddingHorizontal: 15,
                    // paddingTop: 20,
                    width: dimensions?.width,
                    alignItems: "center"
                }}>

                    <IconButton
                        icon={"arrow-left"}
                        iconColor={theme.colors.primary}
                        size={32}
                        style={{
                            alignSelf: "flex-start"
                            // left: 4
                        }}
                        onPress={() => {
                            navigation.goBack()
                        }}
                    />

                    <TextM fontSize={24}
                           fontWeight={"ExtraBold"}
                           color={theme.colors.primary}
                           style={{textAlign: "center", marginBottom: 20}}
                           wrap
                    >Plus qu'une étape pour programmer votre séance d'essai gratuit !</TextM>

                    <>
                    <View style={{
                        alignItems: "flex-start",
                        flexDirection: "row",
                        width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                        // paddingHorizontal: 20,
                        paddingVertical: 15
                    }}>
                        <SelectInputM options={studentClasses.map(x => ({label: x, value: x})).slice(0, -1)}
                                      name={"student_class"}
                                      label={"Classe de l'élève"}
                                      sliderLabel={"Choisir la classe de l'élève"}
                                      onSelect={(value) => {
                                      }}
                                      control={control}
                                      style={{
                                          flex: 1,
                                          marginRight: 10
                                      }}
                                      errors={errors}
                        />
                        <SelectInputM options={subjectPrioritiesSing}
                                      label={"Soutien souhaité"}
                                      sliderLabel={"Soutien souhaité"}
                                      name={"subject_priority"}
                                      onSelect={(value) => {
                                      }}
                                      control={control}
                                      style={{
                                          flex: 1
                                      }}
                                      errors={errors}
                        />
                    </View>
                    </>

                    {!props.user && <>

                        <View style={{
                            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4
                        }}>
                            <TextM style={{alignSelf: "flex-start"}}>Téléphone portable d'un parent</TextM>
                            <PhoneInput
                                initialCountry="FR"
                                dismissKeyboard={false}
                                textStyle={{
                                    fontFamily: "Montserrat-Regular",
                                    fontSize: 16
                                }}
                                style={{
                                    width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                                    borderColor: (!parentMobileField.value && submittedOnce) ? theme.colors.error : theme.colors.light,
                                    borderWidth: 1,
                                    borderRadius: theme.roundness,
                                    height: 55,
                                    alignItems: "center",
                                    paddingHorizontal: 10
                                }}
                                onChange={(data) => {
                                    parentMobileField.onChange(data?.e164)
                                    handleInputChange()
                                }}/>
                            {!parentMobileField.value && submittedOnce &&
                                <ErrorTextM errorData={{message: "Numéro de téléphone invalide"}}
                                            style={{textAlign: "center"}} marginTop={3}/>}
                        </View>

                        {/*<TextInputM*/}
                        {/*    placeholder={`Téléphone portable d'un parent`}*/}
                        {/*    name="parent_mobile"*/}
                        {/*    keyboardType="phone-pad"*/}
                        {/*    control={control}*/}
                        {/*    errors={errors}*/}
                        {/*/>*/}

                        <View style={{
                            alignItems: "flex-start",
                            flexDirection: "row",
                            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                            // paddingHorizontal: 20,
                            paddingTop: 15
                        }}>
                            <TextInputM
                                placeholder={`Prénom de l'élève`}
                                labelTop={`Prénom de l'élève`}
                                // label={`Adresse email de l'élève`}
                                name="firstname"
                                control={control}
                                errors={errors}
                                contentContainerStyle={{
                                    width: null,
                                    flex: 1,
                                    marginRight: 10
                                }}
                                onSubmitEditing={(e) => {
                                    lastnameRef.current?.focus()
                                }}
                                onChangeAdditional={handleInputChange}
                            />
                            <TextInputM
                                placeholder={`Nom de l'élève`}
                                labelTop={`Nom de l'élève`}
                                // label={`Adresse email de l'élève`}
                                name="lastname"
                                ref={lastnameRef}
                                control={control}
                                errors={errors}
                                contentContainerStyle={{
                                    width: null,
                                    flex: 1
                                }}
                                onSubmitEditing={(e) => {
                                    emailRef.current?.focus()
                                }}
                                onChangeAdditional={handleInputChange}
                            />
                        </View>

                        <TextInputM
                            placeholder={`Adresse email de l'élève`}
                            labelTop={`Adresse email de l'élève`}
                            // label={`Adresse email de l'élève`}
                            name="email"
                            ref={emailRef}
                            textContentType="emailAddress"
                            keyboardType="email-address"
                            liveCheck={{service: authService.checkEmailAddress}}
                            liveCheckPreRequest={true}
                            control={control}
                            errors={errors}
                            contentContainerStyle={{marginTop: 10}}
                            onSubmitEditing={(e) => {
                                passwordRef.current?.focus()
                            }}
                            onChangeAdditional={handleInputChange}
                        />

                        <TextInputM
                            placeholder="Mot de passe"
                            ref={passwordRef}
                            labelTop="Mot de passe"
                            // label="Mot de passe"
                            name="password"
                            control={control}
                            password
                            errors={errors}
                            contentContainerStyle={{marginTop: 10}}
                            onChangeAdditional={handleInputChange}
                        />

                        <Checkbox.Item label="J’accepte de recevoir des actualités et des offres de Masteur"
                                       position={'leading'}
                                       onPress={() => {
                                           newsletterOptInField.onChange(!newsletterOptInField.value)
                                       }}
                                       color={theme.colors.primary}
                                       labelStyle={{
                                           fontSize: 14,
                                           textAlign: "left"
                                       }}
                                       style={{
                                           marginHorizontal: 5,
                                           marginTop: 5
                                       }}
                                       status={newsletterOptInField.value ? "checked" : "indeterminate"}/>
                    </>}

                    <ErrorTextM errorData={props.registerTrialV2Error || props.subscribeToTrialV2Error} style={{textAlign: "center", marginBottom: 10}}/>

                    <View style={{
                        marginBottom: 20,
                        marginTop: 10,
                        paddingHorizontal: 10
                    }}>

                        <TextM style={{
                            fontSize: 12,
                            textAlign: 'center'
                        }} wrap>
                            En continuant, j'accepte les <LinkTextM url="https://masteur.com/cgu">Conditions
                            générales d'utilisation</LinkTextM> de Masteur. Je confirme avoir plus de 15 ans. Dans
                            le cas contraire, je confirme avoir l'autorisation d'un parent pour utiliser ce service.
                        </TextM>
                    </View>

                    <FilledButtonM
                        style={{
                            marginTop: 10,
                            marginBottom: 0
                        }}
                        // disabled={!dirtyFields.email || !dirtyFields.password}
                        onPress={handleSubmit(onSubmit)}
                        loading={request}
                        disabled={request}
                        label={dimensions?.width > 800 ? "Réserver une séance d'essai gratuit" : "Réserver un essai gratuit"}
                        // label={props.loggingIn ? "" : "Connexion"}
                    />
                    {!props.user && <TextM fontSize={14}
                           style={{marginHorizontal: 20, textAlign: "center", marginTop: 4}}
                           color={theme.colors.grey}
                           fontWeight="Light"
                           wrap>{"En attendant la séance d'essai, vous allez pouvoir découvrir notre application !"}</TextM>}


                </View>
                {!props.user && <>
                    <View style={{
                        marginBottom: 50,
                        marginTop: 30,
                        paddingHorizontal: 10,
                        justifySelf: "flex-end"
                    }}>
                        <TextM style={{alignSelf: 'center'}}>
                            Dèja un compte ? <LinkTextM onPress={() => {
                            navigation.navigate("login")
                        }
                        }>Je me connecte</LinkTextM>
                        </TextM>
                    </View>
                </>}

            </ScrollView>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
            <RegisterTrialPopup referralCode={referralCode} lastInputTime={lastInputTime} resolver={resolver}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        getLiveRoomsAvailableForTrialRequest: state.api.getLiveRoomsAvailableForTrial?.request,
        subscribeToTrialV2Request: state.api.subscribeToTrialV2?.request,
        subscribeToTrialV2Error: state.api.subscribeToTrialV2?.error,
        callMeBackRequest: state.api.callMeBack?.request,
        getLiveRoomsAvailableForTrialError: state.api.getLiveRoomsAvailableForTrial?.error,
        registerTrialV2Request: state.api.registerTrialV2?.request,
        registerTrialV2Success: state.api.registerTrialV2?.success,
        registerTrialV2Error: state.api.registerTrialV2?.error,
        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTrialSessionScreen)