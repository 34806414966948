import PopUp, {
    BtnTemplate,
    EndCall,
    GridVideo,
    layout,
    LocalUserContext,
    LocalVideoMute,
    PropsContext,
    RtcConfigure,
    RtcContext,
    RtmConfigure,
    TracksConfigure
} from "agora-react-uikit";
import React, {useContext, useEffect, useState} from "react";
import PinnedVideoWebM from "@common/video-call/AgoraUIKitWeb/PinnedVideoWebM";
import AgoraRTC from "agora-rtc-react";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "@common/theme/theme";
import {View} from "react-native";
import MuteAudioVideo from "@common/video-call/MuteAudioVideo";
import GridVideoWebM from "@common/video-call/AgoraUIKitWeb/GridVideoWebM";
import TracksConfigureM from "@common/video-call/AgoraUIKitWeb/TracksConfigureM";

const AgoraUIKitM = (props) => {
    const {styleProps, rtcProps} = props
    const {UIKitContainer} = styleProps || {}
    return (
        <PropsContext.Provider value={props}>
            <div style={{
                ...style,
                ...UIKitContainer
            }}
            >
                {rtcProps.role === 'audience' ? (
                    <VideocallUIM/>
                ) : (
                    <TracksConfigureM >
                        <VideocallUIM/>
                    </TracksConfigureM>
                )
                }
            </div>
        </PropsContext.Provider>
    )
}

function LocalAudioMuteM(props) {
    const {styleProps, callbacks, rtcProps} = useContext(PropsContext)
    const {localBtnStyles} = styleProps || {}
    const {muteLocalAudio} = localBtnStyles || {}
    const {dispatch, localAudioTrack, client} = useContext(RtcContext)
    const [muted, setMuted] = useState(localAudioTrack?.muted);
    // write me a way to mute/unmute the audio of the local user
    //how


    return (
        <div>
            <BtnTemplate
                style={muteLocalAudio}
                disabled={props.disabled}
                name={!muted ? 'mic' : 'micOff'}
                onClick={props.disabled
                    ? () => {
                    }
                    : () => {
                        localAudioTrack?.setMuted(!muted)
                        setMuted(!muted)
                    }
                    //localAudioTrack && props.setMuteAudio(muted)
                }
            />
        </div>
    )
}

const ScreenshareM = () => {
    const {styleProps} = useContext(PropsContext)
    const {localBtnStyles} = styleProps || {}
    const {screenshare} = localBtnStyles || {}
    const {toggleScreensharing, isScreensharing} = useContext(RtcContext)

    return (
        <div>
            <BtnTemplate
                style={screenshare}
                name={isScreensharing ? 'stop' : 'screen'}
                onClick={() => toggleScreensharing()}
            />
        </div>
    )
}
const PinnedVideoButton = () => {
    const {styleProps, rtcProps} = useContext(PropsContext)
    const {localBtnStyles, BtnTemplateStyles} = styleProps || {}
    const {screenshare} = localBtnStyles || {}
    const {toggleScreensharing, isScreensharing} = useContext(RtcContext)
    const disabled = false
    return (
        <div>
            <div
                style={{
                    ...{
                        width: 35,
                        height: 35,
                        borderRadius: '100%',
                        borderWidth: 2,
                        borderStyle: 'solid',
                        borderColor: '#fff',
                        backgroundColor: 'rgba(0,80,180,0.2)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        cursor: disabled ? 'auto' : 'pointer',
                        margin: 4
                    },
                    ...BtnTemplateStyles,
                    ...screenshare
                }}
                onClick={() => rtcProps?.setPinnedMode(!rtcProps?.pinnedMode)}
            >
                {rtcProps?.pinnedMode
                    ? <FontAwesome5 name={'th'} size={24} color={theme.colors.white}/>
                    : <View style={{justifyContent:"center"}}>
                        <FontAwesome5 name={'square'}
                                      solid
                                      size={20}
                                      style={{
                                          marginRight: 4
                                      }}
                                      color={theme.colors.white}/>
                        <FontAwesome5 name={'grip-vertical'}
                                      solid
                                      size={18}
                                      style={{
                                          position: "absolute",
                                          right: -2
                                      }}
                                      color={theme.colors.white}/>
                    </View>}
            </div>
        </div>
    )
}
const LocalControlsM = () => {

    const {styleProps, rtcProps} = useContext(PropsContext)
    const {localBtnContainer} = styleProps || {}

    return (
        <div
            style={{
                ...{
                    backgroundColor: '#007bff',
                    width: '100%',
                    height: rtcProps?.miniMode ? 46 : 70,
                    zIndex: 10,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center'
                },
                ...localBtnContainer
            }}
        >
            {rtcProps.role !== 'audience' && <>
                {rtcProps?.miniMode ? rtcProps.soundMode && <LocalVideoMute/> : <LocalVideoMute/>}
                {rtcProps?.miniMode ? rtcProps.soundMode && <LocalAudioMuteM disabled={!rtcProps.soundMode}/> :
                    <LocalAudioMuteM disabled={!rtcProps.soundMode}/>}
                {!rtcProps?.miniMode && <PinnedVideoButton/>}

                {rtcProps.enableScreensharing && !rtcProps?.miniMode && (
                    <ScreenshareM/>
                )}
            </>}
            {!rtcProps?.hideEndCallBtn && <EndCall/>}
        </div>
    )
}
export const VideocallUIM = () => {
    const {rtcProps, callbacks} = useContext(PropsContext)

    return (
        <RtcConfigure callActive={rtcProps.callActive}>
            <LocalUserContext>
                <MuteAudioVideo/>
                <ChangeDevice/>
                {rtcProps.disableRtm ? (
                    <React.Fragment>
                        {rtcProps?.layout === layout.grid || !rtcProps?.pinnedMode ? <GridVideoWebM/> : <PinnedVideoWebM/>}
                        <>
                            <LocalControlsM/>
                            {rtcProps.isStudent && <View style={{
                                width: "100%",
                                height: 70,
                                backgroundColor: rtcProps?.soundMode ? theme.colors.success : theme.colors.purple,
                                position: "absolute",
                                bottom: 0,
                                opacity: .6,
                                //alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {!rtcProps?.soundMode &&
                                    <FontAwesome5 name={"volume-mute"} size={25} color={theme.colors.white}
                                                  style={{marginLeft: 30}}/>}
                            </View>}
                        </>
                    </React.Fragment>
                ) : (
                    <RtmConfigure>
                        <PopUp/>
                        {rtcProps?.layout === layout.grid ? <GridVideoWebM/> : <PinnedVideoWebM/>}
                        {!rtcProps?.miniMode ? <LocalControlsM/> :
                            !rtcProps?.soundMode &&
                            <View style={{
                                width: "100%",
                                height: "20%",
                                backgroundColor: theme.colors.purple,
                                position: "absolute",
                                bottom: 0,
                                opacity: .6,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <FontAwesome5 name={"volume-mute"} size={25} color={theme.colors.white}/>
                            </View>}
                    </RtmConfigure>
                )}
            </LocalUserContext>
        </RtcConfigure>
    )
}

const ChangeDevice = () => {
    const {rtcProps, callbacks} = useContext(PropsContext)
    const {dispatch, localVideoTrack, localAudioTrack} = useContext(RtcContext)

    useEffect(() => {
        AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
            // When plugging in a device, switch to a device that is newly plugged in.
            if (changedDevice.state === "ACTIVE") {
                localAudioTrack.setDevice(changedDevice.device.deviceId);
                //console.log("actiiiiveeee")
                // Switch to an existing device when the current device is unplugged.
            } else if (changedDevice.device.label === localAudioTrack.getTrackLabel()) {
                //console.log("localAudioTrack.getTrackLabel()", localAudioTrack.getTrackLabel())
                const oldMicrophones = await AgoraRTC.getMicrophones();
                oldMicrophones[0] && localAudioTrack.setDevice(oldMicrophones[0].deviceId);
            }
        }
    }, [localAudioTrack]);

    return <></>
}

const style = {
    display: 'flex',
    flex: 1,
    minHeight: 0,
    flexDirection: 'column'
}

export default AgoraUIKitM