import BottomSheet, {BottomSheetBackdrop, BottomSheetModal} from "@gorhom/bottom-sheet";
import React, {useCallback, useMemo} from "react";
import {theme} from "../../theme/theme";

export const BottomSheetModalM = React.forwardRef((props, ref) => {

    const {backgroundStyleProps, height = "25%", withPanning = false, style, animateOnMount = true} = props

    // variables for the bottomSheet
    const snapPoints = useMemo(() => [height], []);

    // callbacks for the bottomSheet
    const handleSheetChanges = useCallback(() => {
        // console.log('handleSheetChanges', index);
    }, []);

    // renders
    const renderBackdrop = useCallback(
        (props) => (
            <BottomSheetBackdrop
                {...props}
                pressBehavior={'none'}
                // opacity={1}
                // contentStyle={{
                //     backgroundColor: theme.colors.grey
                // }}
                disappearsOnIndex={-1}
                appearsOnIndex={0}
            />
        ),
        []
    );

    return <BottomSheetModal
        handleComponent={() => <></>}
        enablePanDownToClose={withPanning}
        index={0}
        backdropComponent={renderBackdrop}
        animateOnMount={animateOnMount}
        backgroundStyle={{
            ...backgroundStyleProps,
            borderRadius: 0
        }}
        style={{elevation: 1000, zIndex: 1000, ...style}}
        snapPoints={snapPoints}
        onChange={handleSheetChanges}
        {...props}
        ref={ref}
    >
        {props.children}
    </BottomSheetModal>
})

const BottomSheetM = React.forwardRef((props, ref) => {

    const {backgroundStyleProps, height = "25%", withPanning, style} = props

    // variables for the bottomSheet
    const snapPoints = useMemo(() => [height], []);

    // callbacks for the bottomSheet
    const handleSheetChanges = useCallback((index) => {
        // console.log('handleSheetChanges', index);
    }, []);

    return <BottomSheet
        handleComponent={() => <></>}
        enablePanDownToClose={withPanning}
        index={-1}
        backgroundStyle={{
            ...backgroundStyleProps,
            borderRadius: 0
        }}
        style={{elevation: 1000, zIndex: 1000, ...style}}
        snapPoints={snapPoints}
        onChange={handleSheetChanges}
        {...props}
        ref={ref}
    >
        {props.children}
    </BottomSheet>
})

export default BottomSheetM