import React, {useContext, useEffect, useState} from 'react';
import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import TextM from "@common/components/text/TextM";
import {useNavigation} from "@react-navigation/native";
import {useInterval} from "@common/utils/useInterval";
import {setVideoRecord} from "@data/redux/actions/camera";
import VideoRecordingComponent from "./VideoRecordingComponent";
import FilledButtonM from "@common/components/button/FilledButtonM";
import TextButtonM from "@common/components/button/TextButtonM";
import {IconButton} from "react-native-paper";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const VideoRecordingScreen = (props) => {

    const questionId = props.route?.params?.questionId ?? 1
    const testCategory = props.route?.params?.testCategory ?? "motivation"
    const testMode = props.route?.params?.testMode ?? 0

    const testModeBool = parseInt(testMode?.toString()) > 0

    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const question = props.testQuestions?.find((q) => q?.id?.toString() === questionId)
    const [answerDuration, setAnswerDuration] = useState(question?.answer_duration)
    const [recordingStarted, setRecordingStarted] = useState(false);

    const testModeText = "Avant la question, vérifie que tout fonctionne bien, puis clique sur le bouton ci-dessous pour commencer"

    const minutes = Math.floor(answerDuration / 60);

    const seconds = answerDuration % 60;

    useEffect(() => {
        if(testMode)
            props.setVideoRecord(null)
    }, []);

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;

    useInterval(() => {
        //if (recordingStarted)
        setAnswerDuration(p => p - 1)
    }, answerDuration > 0 && !testModeBool && recordingStarted ? 1000 : null);


    return (
        <View style={{
            flex: 1, backgroundColor: theme.colors.black, paddingTop: insets.top, alignItems: "center"
        }}>
            <VideoRecordingComponent questionId={questionId}
                                     setRecordingStarted={setRecordingStarted}
                                     testMode={testModeBool}
                                     answerDuration={answerDuration}/>
            <View style={{
                position: 'absolute',
                top: insets.top,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-around',
                // marginLeft: 20,
                backgroundColor: theme.colors.blackTransparent2,
                paddingHorizontal: 10,
                paddingVertical: 10,
                width: "100%"
            }}>
                <TextM color={theme.colors?.white}
                       fontSize={12}
                       fontWeight={"Bold"}
                       style={{width: dimensions?.width - 80, textAlign: 'center'}}
                       wrap>
                    {testModeBool ? testModeText : question?.question}
                </TextM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.white}
                    size={32}
                    style={{
                        position: "absolute",
                        left: -5
                    }}
                    onPress={() => {
                        if (testModeBool)
                            navigation.goBack()
                        else
                            DialogM.show({
                                text1: 'Veux-tu vraiment quitter ?',
                                text2: "Ta réponse ne sera pas enregistrée",
                                buttonText1: 'Quitter',
                                buttonColor1: theme.colors.error,
                                onPress: () => {
                                    navigation.goBack()
                                },
                                buttonText2: 'Annuler'
                            })
                    }}
                />
            </View>
            {!testModeBool && recordingStarted && <TextM fontWeight="Medium"
                   fontSize={14}
                   color={theme.colors.error}
                   style={{
                       position: 'absolute',
                       bottom: 110,
                   }}
            >{"Enregistrement en cours"}</TextM>}
            {!testModeBool && <TextM style={{
                position: 'absolute',
                bottom: 80,
            }}
                   color={theme.colors.error}
                   fontWeight={"Bold"}
                   fontSize={25}
            >
                {result}
            </TextM>}
            {!testModeBool && recordingStarted && <FilledButtonM
                style={{
                    position: 'absolute',
                    bottom: 0,
                }}
                color={theme.colors.error}
                onPress={() => {
                    setAnswerDuration(0)
                }}
                label={"Terminer"}
            />}
            {testModeBool && <FilledButtonM
                style={{
                    position: 'absolute',
                    bottom: 0,
                }}
                color={theme.colors.success}
                onPress={() => {
                    navigation.replace("application-interview", {
                        testCategory
                    })
                }}
                label={"C'est parti !"}
            />}
        </View>
    )
}
const mapStateToProps = state => {
    return {
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        testQuestions: state.data.tutors?.testQuestions,
        videoRecord: state.camera?.videoRecord,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setVideoRecord
    }

export default connect(mapStateToProps, mapDispatchToProps)(VideoRecordingScreen)