import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useEffect} from "react";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {useNavigation} from "@react-navigation/native";
import RankingScreen from "../social/RankingScreen";


const LeagueProgressScreen = (props) => {

    const navigation = useNavigation()

    useEffect(() => {

        const congrats = [{
            congratsType: congratsTypes.LEAGUE_PROGRESS,
            congratsData: {
                title: "Bravo !",
                text: "Tu as avancé dans le classement",
                rankings: <RankingScreen userRank={props.user?.rank} rankingData={props.user?.rankings}/>
            }
        }]
        navigation.replace("congrats", {congrats})
    })

    return (
        <>


        </>)
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LeagueProgressScreen)