import axios from "axios";

function getAllUsers() {
  return axios.get(`api/users`);
}

function getUserBackoffice({ id }) {
  return axios.get(`/api/users/${id}`);
}

function updateStudent({ routeParams }, { formattedValues }) {
  return axios.put(`/api/users/${routeParams}/profile`, formattedValues);
}

function deleteUser({id}) {
    return axios.delete(`/api/users/${id}`)
}

function postNewTrialLiveSessionChoiceBackoffice({id}, values) {
    return axios.post(`/api/users/${id}/trial-slots`, values)
}

function updateTutor({ routeParams }, { values }) {
  return axios.put(`/api/users/${routeParams}/profile`, values);
}

function updateSlotTutor({ routeParams }, { formattedValues }) {
  return axios.put(
    `/api/users/${routeParams}/interview-slots`,
    formattedValues
  );
}

function validateTutor({ id }) {
  return axios.post(`api/tutors/${id}/validate`);
}

function updateUserBackoffice({ routeParams }, values) {
  return axios.post(`/api/users/${routeParams}?_method=PUT`, values);
}

function updateStudentSlotForTutor({ tutorId, slotId }, formattedValues) {
  return axios.put(
    `/api/users/${tutorId}/interview-slots/${slotId}`,
    formattedValues
  );
}

function updateSlotsTutor({ routeParams }, { formattedValues }) {
  return axios.put(
    `/api/users/${routeParams}/interview-slots`,
    formattedValues
  );
}

function getTutors(params) {
  return axios.get("/api/tutors", { params });
}

function assignTutorAndSlot({ user_id }, data) {
  return axios.post(`/api/users/${user_id}/assign-tutor-and-slot`, data);
}

function assignTutorAndLiveSlots({ user_id }, data) {
  return axios.post(`/api/users/${user_id}/assign-tutor-and-lives`, data);
}

function assignTutor({ id }, data) {
  return axios.put(`/api/users/${id}/tutor`, data);
}

function changePrivileges({ id }, data) {
  return axios.put(`/api/users/${id}/privileges`, data);
}

function updatePrioritizedSchoolSubjects({ id }, data) {
  return axios.put(`/api/users/${id}/prioritized-school-subjects`, data);
}

function updateChannel({ id }, data) {
  return axios.put(`/api/users/${id}/channel`, data);
}

function putStudentAccount({ user_id }, values) {
  return axios.put(`api/backoffice/users/${user_id}/student-account`, values);
}

function getAnalytics({}, {}, params) {
  return axios.get(`api/analytics`, { params });
}

function getLiveSessions({}, {}, params) {
  return axios.get(`api/backoffice/live-sessions`, { params });
}

function getLiveSessionAdmin({ id }, {}, params) {
  return axios.get(`api/backoffice/live-sessions/${id}`, {
    params,
  });
}

function newLiveSession({}, formData) {
  return axios.post(`api/backoffice/live-sessions`, formData);
}

function updateLiveSession({ id }, formData) {
  return axios.put(`api/backoffice/live-sessions/${id}`, formData);
}

function deleteLiveSession({ id }) {
  return axios.delete(`api/backoffice/live-sessions/${id}`);
}

function resetReschedulingsForLiveSession({ id }) {
  return axios.post(`api/backoffice/live-sessions/${id}/reset-reschedulings`);
}

function deleteUsersFromLive({ id }, formData) {
  return axios.post(
    `api/backoffice/live-sessions/${id}/delete-users`,
    formData
  );
}

function addUsersLive({ id }, formData) {
  return axios.post(`api/backoffice/live-sessions/${id}/add-users`, formData);
}

function searchUser({}, {}, params) {
  return axios.get(`api/backoffice/users`, { params });
}

function searchUserFormStep({}, {}, params) {
  return axios.get(`api/backoffice/users`, { params });
}
function searchAdmins({}, {}, params) {
  return axios.get(`api/backoffice/users?roles=admin`, { params });
}

function searchTutors({}, {}, params) {
  return axios.get(`api/backoffice/users?roles=tutor`, { params });
}

function putTutorAccount({ user_id }, values) {
  return axios.put(`api/backoffice/users/${user_id}/tutor-account`, values);
}

function getAppreciation({ user_id }) {
  return axios.post(`api/users/${user_id}/generate-weekly-comment`);
}

function createWeeklyReport({ user_id }, values) {
  return axios.post(`api/users/${user_id}/weekly-report`, values);
}

function sendWeeklyReport({ report_id }, values) {
  return axios.post(`/api/weekly-reports/${report_id}/send`, values);
}

function newStudent({}, values) {
  return axios.post(`/api/v1/backoffice/users`, values);
}

function updateTutorSlots({ user_id }, values) {
  return axios.put(`/api/backoffice/${user_id}/interview-slots`, values);
}

function updateSalary({ id }, values) {
  return axios.post(`/api/tutors/${id}/update-salary`, values);
}

function updateMaxStudentsPerLive({ id }, values) {
  return axios.post(`/api/tutors/${id}/max-students-per-live`, values);
}

function acceptNewStudents({ id }, values) {
  return axios.post(`/api/tutors/${id}/accept-new-students`, values);
}

function assignAdmin({ id }, values) {
  return axios.post(`/api/tutors/${id}/assign-admin`, values);
}

function reschedule({ id }, values) {
  return axios.post(`/api/backoffice/live-sessions/${id}/reschedule`, values);
}

function requestSubstitution({ id }, values) {
  return axios.post(
    `/api/backoffice/live-sessions/${id}/request-substitution`,
    values
  );
}

function acceptSubstitution({ id }, values) {
  return axios.post(
    `/api/backoffice/substitutions/${id}/accept-substitution`,
    values
  );
}

function removeSubstitution({ id }) {
  return axios.delete(`/api/backoffice/substitutions/${id}`);
}

function getApplicationStatistics({}, {}, params) {
  return axios.get(`api/backoffice/app-statistics`, { params });
}

function getLiveReports({}, {}, params) {
  return axios.get(`api/v1/backoffice/live-reports`, { params });
}

function getLiveOccurrences({}, {}, params) {
  return axios.get(`api/backoffice/live-occurrences`, { params });
}

function editBilling({ id }, values) {
  return axios.put(`/api/backoffice/live-occurrences/${id}`, values);
}

function deleteOccurrence({ id }) {
  return axios.delete(`/api/backoffice/live-occurrences/${id}`);
}

export const backOfficeService = {
  updateSlotsTutor,
  validateTutor,
  getAllUsers,
    deleteUser,
    postNewTrialLiveSessionChoiceBackoffice,
  getUserBackoffice,
  updateSlotTutor,
  updateStudent,
  updateTutor,
  updateUserBackoffice,
  updateStudentSlotForTutor,
  assignTutorAndSlot,
  getTutors,
  putStudentAccount,
  putTutorAccount,
  getAppreciation,
  createWeeklyReport,
  sendWeeklyReport,
  getAnalytics,
  getLiveSessions,
  newLiveSession,
  getLiveSessionAdmin,
  deleteUsersFromLive,
  updateLiveSession,
  deleteLiveSession,
  resetReschedulingsForLiveSession,
  searchUser,
  searchUserFormStep,
  addUsersLive,
  assignTutorAndLiveSlots,
  updateTutorSlots,
  newStudent,
  assignTutor,
  changePrivileges,
  updatePrioritizedSchoolSubjects,
  updateChannel,
  searchAdmins,
  searchTutors,
  updateSalary,
  acceptNewStudents,
  assignAdmin,
  reschedule,
  requestSubstitution,
  acceptSubstitution,
  updateMaxStudentsPerLive,
  removeSubstitution,
  getApplicationStatistics,
  getLiveReports,
  getLiveOccurrences,
  editBilling,
  deleteOccurrence,
};
