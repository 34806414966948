import {ScrollView, View} from "react-native";
import React, {useContext, useEffect, useState} from "react"
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
//import {findItemInListWithId, interviewsForStudents} from "@data/utility/dataUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
//import {interviewsService} from "@data/services/interviewsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {IconButton} from "react-native-paper";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {backOfficeService} from "@data/services/backOfficeService";
import {ChampionImageM} from "../student/champions/ChampionImageM";
import InterviewStatisticsCard from "../tutor/home/interview/InterviewStatisticsCard";
import ToggleIconButtonM from "@common/components/toggleIconButton";
import {ToggleButtonM} from "@common/components/button/ToggleButtonM";
import {isSubscribed} from "@data/utility/dataUtils";

function SendWeeklyReportScreen(props) {

    const student = props.users?.find(x => x?.id === parseInt(props.route.params?.student_id))

    const [selectedIndex, setSelectedIndex] = useState(0);

    const onSubmit = () => {
        props.apiRequest(backOfficeService.sendWeeklyReport, {report_id: props.route.params.report_id}, {no_activation_link: selectedIndex === 1})
    }

    const navigation = useNavigation()

    useApiStatus(
        backOfficeService.sendWeeklyReport, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            navigation.goBack()
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "C'est terminé",
                    text: "Le rapport a bien été envoyé"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )
    useEffect(() => {
        if (props.interviewFinalizeSuccess)
            navigation.goBack()
    }, [])
    const dimensions = useContext(DimensionsContext)
    const insets = useSafeAreaInsets()

    return (
        <View style={{
            backgroundColor: theme.colors.white,
            paddingTop: insets.top
        }}>
            {/*{props.student && header()}*/}
            <ScrollView>
                {/*<View style={{*/}
                {/*    alignItems: 'center',*/}
                {/*    justifyContent: 'center',*/}
                {/*    paddingTop: 20*/}
                {/*}}>*/}
                {/*    {interview.finalized_at ? <>*/}
                {/*        <TitleM>Entretien hebdomadaire</TitleM>*/}
                {/*        <TextM wrap> {formatFullDateTime(interview?.date_time)}</TextM>*/}
                {/*    </> : <TitleM>Relire le rapport et envoyer</TitleM>}*/}

                {/*</View>*/}
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    onPress={() => {
                        if (navigation.canGoBack()) {
                            navigation.goBack()
                        } else {
                            navigation.replace("menu")
                        }
                    }}
                />
                <View style={{
                    width: dimensions?.width,
                    alignSelf: 'center',
                    // marginTop: 30,
                    paddingHorizontal: 10,
                    // paddingBottom: 0,
                    alignItems: 'center'
                }}>
                    <ChampionImageM style={{alignSelf: 'center'}} name="zoe" variant={"main"} width={100}/>
                    <View style={{
                        borderColor: theme.colors.lightMore,
                        borderBottomWidth: 1,
                        width: "100%",
                        alignItems: "center",
                        paddingBottom: 20
                    }}>
                        <TextM style={{marginTop: 10}}>Bonjour</TextM>
                        <TextM style={{
                            paddingBottom: 5,
                            textAlign: 'center',
                            marginTop: 10
                        }} wrap>Voici votre rapport personnalisé pour {student?.firstname}.</TextM>
                    </View>
                    {/*<TitleM wrap color={theme.colors.grey}*/}
                    {/*        style={{*/}
                    {/*            paddingVertical: 20,*/}
                    {/*            paddingHorizontal: 10,*/}
                    {/*            textAlign: 'center'*/}
                    {/*        }}>*/}
                    {/*    Rapport hebdomadaire*/}
                    {/*</TitleM>*/}
                    <View style={{
                        width: dimensions.width,
                        alignSelf: "flex-start",
                        alignItems: IS_WEB && dimensions.width > 800 ? "flex-start" : "center",
                        marginTop: 20
                    }}>
                        <TitleM color={theme.colors.grey}

                        >
                            Message aux parents
                        </TitleM>
                        <TextM wrap>
                            {props.report?.comment}
                        </TextM>
                    </View>

                    <InterviewStatisticsCard
                        statistics={props.report?.statistics}
                        report={props.report}
                    />

                    {!isSubscribed(student) && <View style={{flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
                        <TextM wrap fontSize={20} fontWeight={"Bold"} style={{marginRight: 10}}>Inviter à s'abonner</TextM>
                        <ToggleButtonM labels={["Oui", "Non"]}
                                       color={theme.colors.primary}
                                       buttonsStyle={{width: 80}}
                                   onSelectIndex={setSelectedIndex}/>
                    </View>}

                    <FilledButtonM color={theme.colors.success}
                                   onPress={onSubmit}
                                   loading={props.sendWeeklyReportRequest}
                                   style={{
                                       marginTop: 20,
                                       width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 2 : dimensions?.width - 20,
                                       // marginTop: Platform.OS !== 'web' ? null : 20,
                                       //bottom: 30
                                       marginBottom: 70
                                       //paddingBottom: Platform.OS === 'ios' ? 20 : 5,

                                   }}
                                   label={"Envoyer le rapport"}/>

                </View>


                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else navigation.push("menu")
                                          }}
                                          label={"Retour"}/>}
            </ScrollView>

        </View>
    )
}

const mapStateToProps = state => {
    return {
        users: state.data.users?.usersList,
        report: state.data.users?.report,
        sendWeeklyReportRequest: state.api?.sendWeeklyReport?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SendWeeklyReportScreen)