import React, {useState} from 'react';
import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {useApiStatus} from "@common/hooks/useApiStatus";
import FeelingQuiz from "@common/chapters/feeling-quiz/FeelingQuiz";
import {chaptersService} from "@data/services/chaptersService";
import useLaunchActivity from "@common/hooks/useLaunchActivity";
import {theme} from "@common/theme/theme";

const ChapterFeelingQuiz = (props) => {
    const {navigation, route} = props
    const {
        activityTemplateId,
        challengeId,
        activityId,
        againActivityId,
        schoolSubjectId,
        savedActivityLogs = [],
        savedWorkAttachmentLogs = [],
        chapterName,
        chapterId,
    } = route.params
    const [formValues, setFormValues] = useState({
        chapterName,
    });
    const defaultValues = {}
    const formData = [
        {
            label: `Avant de commencer dis-moi, comment tu te sens concernant le chapitre “${chapterName}” ?`,
            type: "custom", // select.single, select.multiple, text, date
            name: "feeling_questions",
            component: FeelingQuiz,
        },
    ]
    const onSubmit = data => {
        if (props.feelingQuestionsRequest || props.feelingQuestionsError) return
        // console.log({...data?.feeling_questions})
        props.apiRequest(chaptersService.studentChapterFeelings, {id: chapterId}, {...data?.feeling_questions})
    }

    const launchActivity = useLaunchActivity()
    useApiStatus(
        chaptersService.studentChapterFeelings, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (activityId)
                launchActivity({
                    activity_template_id: activityTemplateId,
                    id: activityId,
                    school_subject_id: schoolSubjectId,
                    challengeId: challengeId,
                    againActivityId: againActivityId,
                    savedActivityLogs: savedActivityLogs,
                    savedWorkAttachmentLogs: savedWorkAttachmentLogs
                }, "replace", true)
            else if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("live")
        }, // success callback,
        (error) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("live")
        }
    )
    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         hideProgressBar
                         champion={{name: "tina", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.feelingQuestionsRequest}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.feelingQuestionsError}
            />
        </View>);
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        feelingQuestions: state.data.staticData?.feeling_questions,
        feelingQuestionsRequest: state.api.feelingQuestionsRequest,
        feelingQuestionsError: state.api.feelingQuestionsError,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterFeelingQuiz)