import React, { useContext, useEffect } from "react";
import { Dimensions, ScrollView, View } from "react-native";
import { ActivityIndicator, IconButton } from "react-native-paper";
import { theme } from "@common/theme/theme";
import { DimensionsContext } from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import { liveService } from "@data/services/liveService";
import { useApiStatus } from "@common/hooks/useApiStatus";
import { setOngoingActivity } from "@data/redux/actions/activity";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { setIsLiveModeEnabled } from "@data/redux/actions/liveMode";
import LiveReportComponent from "@common/liveMode/live-report/LiveReportComponent";
import { StatusBar } from "expo-status-bar";
import { DialogM } from "@common/components/alert/dialog/Dialog";
import LiveSessionDialogComponent from "../header/LiveSessionDialogComponent";

const EndSessionScreenStudent = (props) => {
  const navigation = props.navigation;
  const dimensions = useContext(DimensionsContext);
  const insets = useSafeAreaInsets();

  const showDialog = (leave = false) => {
    DialogM.show({
      text1: `Qu’as-tu pensé de cette session LIVE avec ${props.currentLiveSession.tutor?.display_name}`,
      component: <LiveSessionDialogComponent navigation={navigation} />,
      hideButton1: true,
      buttonText2: "Pas maintenant",
      onPressBack: () => {
        props.apiRequest(liveService.leaveLiveSession, {
          id: props.liveSessionId,
        });
      },
    });
  };

  useApiStatus(
    liveService.sendSatisfaction, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    () => {},
    () => {},
    true
  );

  useEffect(() => {
    props.apiRequest(liveService.getTodayLiveReport, {
      id: props.liveSessionId ?? 3,
    });
  }, []);

  return (
    <>
      {props.getTodayLiveReportRequest ? (
        <ActivityIndicator
          color={theme.colors.primary}
          style={{ marginTop: insets.top + 20 }}
        />
      ) : (
        <ScrollView
          contentContainerStyle={{
            alignSelf: "center",
            alignItems: "center",
            width: Dimensions.get("window").width,
            marginTop: insets.top,
          }}
        >
          <View
            style={{
              flex: 1,
              alignSelf: "center",
              alignItems: "center",
              width: dimensions?.width,
              // backgroundColor: theme.colors.white
            }}
          >
            <View
              style={{
                // flex: 1,
                alignSelf: "center",
                width: dimensions?.width,
                // paddingTop: insets.top,
                // backgroundColor: theme.colors.white
              }}
            >
              <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{}}
                onPress={() => {
                  showDialog(false);
                }}
              />
              <View
                style={{
                  alignSelf: "center",
                  alignItems: "center",
                  marginHorizontal: 10,
                  // width: dimensions?.width,
                }}
              >
                <TextM
                  wrap
                  fontSize={18}
                  fontWeight={"Bold"}
                  color={theme.colors.primary}
                  style={{ marginBottom: 20, textAlign: "center" }}
                >
                  Ton masteur a mis fin à la session LIVE
                </TextM>
                {props.ongoingActivity && (
                  <>
                    <TextM
                      wrap
                      style={{ marginBottom: 20, textAlign: "center" }}
                    >
                      Ne t’inquiète pas ! Ton activité a été mise en pause.
                    </TextM>
                  </>
                )}
                <LiveReportComponent liveReport={props.liveReport} withTitle />
              </View>
            </View>
            {props.ongoingActivity && (
              <>
                <TextM
                  wrap
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                    marginHorizontal: 20,
                    textAlign: "center",
                  }}
                >
                  N’hésite pas à poursuivre ton activité avant de quitter ton
                  live.
                </TextM>
                <FilledButtonM
                  onPress={() => {
                    showDialog(false);
                  }}
                  label={"Poursuivre mon travail"}
                  color={theme.colors.primary}
                  // loading={props.sendSatisfactionRequest}
                  style={{
                    marginTop: 10,
                    marginBottom: 0,
                    alignSelf: "center",
                  }}
                />
              </>
            )}
            <FilledButtonM
              onPress={() => {
                showDialog(true);
              }}
              label={"Quitter le live"}
              color={theme.colors.error}
              loading={props.leaveLiveSessionRequest}
              style={{ alignSelf: "center", marginTop: 20, marginBottom: 100 }}
            />
          </View>
        </ScrollView>
      )}
      <StatusBar
        style="dark"
        backgroundColor={theme.colors.white}
        translucent={false}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    ongoingActivity: state.activity?.ongoingActivity,
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    sendSatisfactionRequest: state.api.sendSatisfaction?.request,
    sendSatisfactionSuccess: state.api.sendSatisfaction?.success,
    leaveLiveSessionRequest: state.api.leaveLiveSession?.request,
    getTodayLiveReportRequest: state.api.getTodayLiveReport?.request,
    liveReport: state.liveMode?.liveReport,
    schoolSubjects: state.data.staticData?.school_subjects,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setOngoingActivity,
  setIsLiveModeEnabled,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndSessionScreenStudent);
