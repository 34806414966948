import {ModalM} from "@common/components/modal/ModalM";
import TitleM from "@common/components/text/TitleM";
import {ScrollView, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "@common/theme/theme";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {SkillSetTitleButton} from "./SkillSetTitleButton";
import {authService} from "@data/services/authService";
import {useApiStatus} from "@common/hooks/useApiStatus";

const ChooseTitleModal = props => {

    const {visible, onDismiss} = props

    const skillSetsList = props.user?.skill_sets
    const skills = props.skills

    useApiStatus(
        authService.updateTitleSkillSet,
        null,
        true,
        (successData) => {
            onDismiss()
        }
    );

    // console.log(skillSetsList)

    return <ModalM visible={visible}
                   onDismiss={onDismiss}
    >
        <TitleM style={{textAlign: "center", marginHorizontal: 10, marginTop: 10}}>Choisis un titre pour ton
            profil</TitleM>

        <ScrollView style={{
            // height: WINDOW_HEIGHT * 2 / 3,m
            marginVertical: 20
            // flex: 1
        }}>
            {props.updateTitleSkillSetRequest
                ? <ActivityIndicator animating={true}
                                     color={theme.colors.primary}/>
                : <View style={{
                    // flex: 1
                }}>
                    {skills && skillSetsList ? skillSetsList?.map((s, index) => {
                        // console.log(index)

                        return <SkillSetTitleButton key={index}
                                                    onPress={() => {
                                                        props.apiRequest(authService.updateTitleSkillSet, {}, {skill_set_id: s?.id})
                                                    }}
                                                    skillSet={s}/>

                    }) : ""}
                </View>}
        </ScrollView>
    </ModalM>
}

const mapStateToProps = state => {
    return {

        updateTitleSkillSetRequest: state.api.updateTitleSkillSet?.request,
        updateTitleSkillSetSuccess: state.api.updateTitleSkillSet?.success,
        updateTitleSkillSetError: state.api.updateTitleSkillSet?.error,


        user: state.data.currentUser?.object,
        expoToken: state.data.session?.object?.expo_token,

        getUserRequest: state.api.getUser?.request,

        successes: state.data.staticData?.successes,
        avatars: state.data.staticData?.avatars,
        skill_sets: state.data.staticData?.skill_sets,
        skills: state.data.staticData?.skills

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTitleModal)