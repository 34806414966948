import React, {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "../../theme/theme";
import {IconImageM} from "../icons/IconsM";

export const SkillBadge = (props) => {

    const {skill, level, style, width = 90, color} = props

    const borderWidth = 8


    // const width = iconWidth * 1.4
    const height = width * 1.20

    return <View style={{
        ...style,
        backgroundColor: color,
        borderRadius: 10,
        width: width,
        height: height,
        margin: 15
    }}>
        <View style={{
            width: width,
            height: width,
            backgroundColor: "white",
            borderColor: color,
            alignItems: "center",
            justifyContent: "center",

        }}>
            <IconImageM
                uri={skill?.icon}
                width={width}
                style={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,

                }}/>
        </View>
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            marginVertical: 2,
        }}>
            <TextM fontWeight="Black"
                   fontSize={width * 0.11}
                   color="white"

            >
                {skill?.name?.toUpperCase()}
            </TextM>
        </View>


    </View>
}