import {Image, View} from "react-native";
import TextM from "@common/components/text/TextM";
import React, {useContext} from "react"
import {IconImageM} from "@common/components/icons/IconsM";
import calendarDays from "../../../../assets/icons/generic/calendar-days.png";
import chronometer from "../../../../assets/icons/generic/chronometer.png";
import starRating from "../../../../assets/icons/stars/sparkle_filled.png";
import target from "../../../../assets/icons/menus/target.png";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import ActivityDaysSinceLastWeek from "../dashboard/ActivityDaysSinceLastWeek";
import {theme} from "@common/theme/theme";
import {BarChartWeekSinceTwoWeeksAgo} from "../dashboard/BarChartWeekSinceTwoWeeksAgo";

export function nicerTimeFromSeconds(duration) {

    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration - (hours * 3600)) / 60)
    const seconds = duration - (hours * 3600) - (minutes * 60)


    return [
        hours,
        minutes,
        seconds
    ]

}

export const StatisticsCardContent = (props) => {
    const {count = 0, overCount, countMinutes = 0, title, source, uri, onClick, noCount, style} = props

    return (
        <View style={{
            // flex: 1,
            borderTopRightRadius: 5,
            borderTopLeftRadius: 5,
            paddingTop: 20,
            paddingBottom: noCount ? 0 : 20,
            paddingRight: 20,
            marginLeft: 20,
            marginRight: 20,
            justifyContent: 'center',
            alignItems: 'center',
            ...style
        }}>
            <TextM>{title?.toUpperCase()}</TextM>
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <IconImageM width={30}
                            source={source}
                />
                {!noCount && <>
                    <TextM style={{marginLeft: 8}} fontSize={30} fontWeight={"Bold"}>{count}</TextM>
                    {!!overCount && <>
                        <TextM style={{marginLeft: 5}} fontSize={20} fontWeight={"Bold"}>sur</TextM>
                        <TextM style={{marginLeft: 5}} fontSize={30} fontWeight={"Bold"}>{overCount}</TextM>
                    </>}</>}

                {!!countMinutes && <>
                    <TextM style={{marginLeft: 5}} fontSize={30} fontWeight={"Bold"}>h</TextM>
                    <TextM style={{marginLeft: 5}} fontSize={30} fontWeight={"Bold"}>{countMinutes}</TextM>
                    <TextM style={{marginLeft: 5}} fontSize={30} fontWeight={"Bold"}>min</TextM>
                </>}
            </View>
        </View>
    )
}
export default function InterviewStatisticsCard({statistics, report}) {
    const period = "this_week"
    const dimensions = useContext(DimensionsContext)


    const boxedCardBorderStyle = IS_WEB && dimensions.width > 800 ? {} : {
        borderRightWidth: 1,
        borderLeftWidth: 1,
        borderRadius: 10
    }

    return (
        <>
            {statistics ? <View style={{
                width: dimensions?.width,
                flex: 1,
                paddingBottom: 20,
                alignItems: IS_WEB && dimensions.width > 800 ? "stretch" : "center"
            }}>
                <TextM fontWeight={"Bold"} fontSize={20} style={{
                    paddingVertical: 20
                }}>
                    Statistiques de la semaine
                </TextM>
                <View style={{
                    backgroundColor: theme.colors.white,
                    marginBottom: 20,
                    // marginLeft: 20,
                    // marginRight: 20,
                    borderColor: theme.colors.lightMore,
                    borderWidth: 1,
                    borderRadius: 10,
                    width: IS_WEB && dimensions.width > 800 ? dimensions.width : dimensions.width - 20
                }}>
                    <StatisticsCardContent source={calendarDays}
                                           title={`jours d'activité`}
                                           count={statistics?.[period]?.activity_days?.[0]}
                                           overCount={statistics?.[period]?.activity_days?.[1]}
                    />
                    <ActivityDaysSinceLastWeek data={statistics?.per_day_since_two_weeks_ago?.day}
                                               endOfWeekDay={statistics?.end_of_week_day}/>
                </View>
                <View style={{
                    flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : "column",
                    flexWrap: 'wrap',
                    // marginLeft: 20,
                    // marginRight: 20,
                    justifyContent: "space-between"

                }}>

                    <View style={{
                        borderColor: theme.colors.lightMore,
                        borderWidth: 1,
                        backgroundColor: theme.colors.white,
                        borderRadius: 10,
                        marginBottom: 20
                    }}>
                        <StatisticsCardContent source={chronometer}
                                               count={nicerTimeFromSeconds(statistics?.[period]?.activities?.duration)[0]}
                                               countMinutes={nicerTimeFromSeconds(statistics?.[period]?.activities?.duration)[1]}
                                               title={`temps d'activité`}
                        />

                        <BarChartWeekSinceTwoWeeksAgo data={statistics?.per_week_since_two_weeks_ago?.duration}
                                                      type="duration"

                        />
                    </View>
                    <View style={{
                        borderColor: theme.colors.lightMore,
                        borderWidth: 1,
                        backgroundColor: theme.colors.white,
                        borderRadius: 10,
                        marginBottom: 20
                    }}>
                        <StatisticsCardContent source={target}
                                               title="activités réalisées"
                                               count={statistics?.[period]?.activities?.count}/>
                        <BarChartWeekSinceTwoWeeksAgo data={statistics?.per_week_since_two_weeks_ago?.count}
                                                      type="count"

                        />
                    </View>
                    <View style={{
                        borderColor: theme.colors.lightMore,
                        borderWidth: 1,
                        backgroundColor: theme.colors.white,
                        borderRadius: 10,
                        marginBottom: 20
                    }}>
                        <StatisticsCardContent source={starRating}
                                               count={statistics?.[period]?.activities?.average_score ?? "-"}
                                               title="score qualité"
                        />

                        <BarChartWeekSinceTwoWeeksAgo data={statistics?.per_week_since_two_weeks_ago?.score}
                                                      type="score"

                        />
                    </View>
                </View>
            </View> : report ? <View style={{
                alignItems: IS_WEB && dimensions.width > 800 ? "stretch" : "center"
            }}>
                <TextM fontWeight={"Bold"} fontSize={20} style={{
                    paddingTop: 20
                }}>
                    Statistiques de la semaine
                </TextM>
                <Image source={{uri: report}}
                       resizeMode={"contain"}
                       style={{
                           width: dimensions?.width - 20,
                           aspectRatio: 1,
                           // flex: 1,
                           // paddingBottom: 20
                           // alignItems: IS_WEB && dimensions.width > 800 ? "stretch" : "center"
                       }}/>

            </View> : null}
        </>
    )
}