import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ScrollView, View} from "react-native";
import {ActivityIndicator, IconButton} from "react-native-paper";
import React, {useContext, useEffect} from "react";
import AvatarM from "@common/components/avatar/AvatarM";
import {theme} from "@common/theme/theme";
import {ProfileSection} from "../../student/account/ProfileSection";
import {ProfileInfo} from "../../student/social/ProfileInfo";
import {SkillSetTitleButton} from "../../student/account/SkillSetTitleButton";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import StatisticsList from "@common/components/statistics/StatisticsList";
import ProfileDetails from "./ProfilDetails";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ActivitiesStatistics} from "../../student/progress/ActivitiesStatistics";
import {capitalize} from "@data/utility/Utils";
import {studentsService} from "@data/services/studentsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {SubscriptionPrivilegeChip} from "../../student/account/SubscriptionPrivilegeChip";

const StudentProfil = (props) => {
    const {navigation, noHeader = false} = props;

    // const navigation = props.navigation ? props.navigation : useNavigation()
    const student = props.student
        ? props.student
        : props.students?.find(
        (x) => x?.id === parseInt(props.route.params?.studentId)
    ) ?? null;
    const dimensions = useContext(DimensionsContext);

    const withActions = props.route?.params?.withActions ?? false;

    useEffect(() => {
        props.apiRequest(studentsService.getStudent, {
            id: props.route?.params?.studentId ?? student?.id,
        });
    }, []);

    useApiStatus(
        studentsService.getStudent, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        } // success callback
    );
    const renderBackButton = () =>
        IS_WEB ? (
            <FilledButtonM
                mode={"text"}
                icon={"chevron-left"}
                color={theme.colors.link}
                style={{marginTop: 60}}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack();
                    else navigation.replace("menu");
                }}
                label={"Retour"}
            />
        ) : null;

    /*    if (!student)
              return <>
                  <TextM style={{marginVertical: 20, alignSelf: 'center'}}>Non trouvé</TextM>
                  {renderBackButton()}
              </>*/

    return (
        <>
            <View
                style={{
                    flex: 1,
                    // backgroundColor: '#fff',
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: props.student ? dimensions.width : "100%",
                    height: props.student ? dimensions.height : null,
                    alignSelf: "center",
                    // paddingTop: 5
                }}
            >
                {!noHeader && (
                    <HeaderContainerM>
                        <TitleM>Profil</TitleM>
                        <IconButton
                            icon={"times"}
                            iconColor={theme.colors.primary}
                            size={32}
                            style={{
                                position: "absolute",
                                left: 4,
                            }}
                            onPress={() => {
                                if (navigation.canGoBack()) navigation.goBack();
                                else navigation.replace("menu");
                            }}
                        />
                    </HeaderContainerM>
                )}
                {props.getStudentRequest ? (
                    <ActivityIndicator
                        style={{
                            marginTop: 30,
                        }}
                        color={theme.colors.primary}
                    />
                ) : (
                    <ScrollView
                        style={{
                            width: "100%",
                        }}
                        contentContainerStyle={{
                            // width: dimensions?.width,
                            alignItems: "center",
                        }}
                    >
                        <View
                            style={{
                                width: dimensions?.width,
                            }}
                        >
                            <ProfileSection title={student?.display_name}>
                                <ProfileInfo user={student} showSchoolSubjects/>
                                <SubscriptionPrivilegeChip
                                    product={capitalize(student?.current_offer)}
                                    style={{marginTop: 5}}
                                />
                                <AvatarM
                                    user={student}
                                    style={{
                                        position: "absolute",
                                        top: 15,
                                        right: 15,
                                    }}
                                    size={60}
                                />
                                {
                                    withActions && <>
                                        <FilledButtonM label={"Activités"}
                                                       color={theme.colors.primary}
                                                       style={{}}
                                                       contentStyle={{}}
                                                       mode={"outlined"}
                                                       onPress={() => {
                                                           navigation.push("student", {
                                                               studentId: student?.id,
                                                               initialTabRealizations: true,
                                                           });
                                                       }}
                                        />
                                        <FilledButtonM label={"Rapports Live"}
                                                       color={theme.colors.primary}
                                                       style={{}}
                                                       contentStyle={{}}
                                                       mode={"outlined"}
                                                       onPress={() => {
                                                           navigation.push("live-reports", {
                                                               studentId: student?.id,
                                                           });
                                                       }}
                                        />
                                    </>
                                }
                                <SkillSetTitleButton
                                    skillSet={student?.skill_sets?.find(
                                        (s) => s?.id === student?.title_skill_set_id
                                    )}
                                    style={{
                                        width: dimensions?.width,
                                        marginTop: 10,
                                    }}
                                    withChampion={false}
                                    readOnly={true}
                                />

                                <StatisticsList
                                    student={student}
                                    studentId={null}
                                    style={{marginTop: 10}}
                                />
                            </ProfileSection>

                            <ProfileSection title={"Statistiques"}>
                                <ActivitiesStatistics
                                    style={{marginTop: 25, marginBottom: 15}}
                                    user={student}
                                />
                            </ProfileSection>
                            <ProfileSection title={"Informations"}>
                                <ProfileDetails student={student}/>
                            </ProfileSection>
                            {/*<ProfileSection title={"Succés"}>*/}
                            {/*    <AchievementsList student={user} limit={3}/>*/}
                            {/*</ProfileSection>*/}
                            {/*{!props.student && renderBackButton()}*/}
                        </View>
                    </ScrollView>
                )}
            </View>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        getStudentRequest: state.api.getStudent?.request,
        getStudentSuccess: state.api.getStudent?.success,
        successes: state.data.staticData?.successes,
        students: state.data.currentUser?.object?.students,
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentProfil);
