import React, {useState} from 'react';
import {View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {backOfficeService} from "@data/services/backOfficeService";
import {findItemInListWithId} from "@data/utility/dataUtils";

function UpdateMaxStudentsPerLive(props) {
    const {userId} = props.route.params
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}
    const user = findItemInListWithId(userId, props.users)
    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Quel est le nombre maximum d'élèves par live ?",
            type: "select.single", // select.single, select.multiple, text, date
            name: "max_students_per_live",
            defaultValue: user?.profile?.max_students_per_live,
            options: [
                {label: "2", value: 2},
                {label: "3", value: 3},
                {label: "4", value: 4},
                {label: "5", value: 5},
                {label: "6", value: 6},
            ]
        },
    ]
    const onSubmit = data => {
        if (props.updateMaxStudentsPerLiveRequest || props.updateMaxStudentsPerLiveError) return
        props.apiRequest(backOfficeService.updateMaxStudentsPerLive, {id: userId}, data)
    }

    useApiStatus(
        backOfficeService.updateMaxStudentsPerLive, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("menu")
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={{name: "tina", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.updateMaxStudentsPerLiveRequest}
                         previousRouteName={"manage-tutors"}
                         submittedMessage=""
                         serverError={props.updateMaxStudentsPerLiveError}
            />
        </View>);
}

const mapStateToProps = state => {
    return {
        updateMaxStudentsPerLiveRequest: state.api.updateMaxStudentsPerLive?.request,
        updateMaxStudentsPerLiveError: state.api.updateMaxStudentsPerLive?.error,
        user: state.data.currentUser?.object,
        users: state.data?.users?.usersList,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMaxStudentsPerLive)