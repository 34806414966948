import React, {useState, useEffect, useRef, PropsWithChildren, useContext} from 'react'
import {
    ILocalVideoTrack,
    ILocalAudioTrack,
    createMicrophoneAndCameraTracks,
    createMicrophoneAudioTrack
} from 'agora-rtc-react'
import {RtcPropsInterface, mediaStore} from "agora-react-uikit/dist/PropsContext";
import {PropsContext, TracksContext} from "agora-react-uikit";

const useTracks = createMicrophoneAndCameraTracks(
    { encoderConfig: {} },
    // { encoderConfig: "480p", }
    { encoderConfig: {} }
)

const useMicrophoneTrack = createMicrophoneAudioTrack(
    { encoderConfig: {} },
)
/**
 * React component that create local camera and microphone tracks and assigns them to the child components
 */
const TracksConfigureM: React.FC<PropsWithChildren<Partial<RtcPropsInterface>>> = (props) => {
    const [ready, setReady] = useState<boolean>(false)
    const [localVideoTrack, setLocalVideoTrack] =
    useState<ILocalVideoTrack | null>(null)
    const [localAudioTrack, setLocalAudioTrack] =
    useState<ILocalAudioTrack | null>(null)
    const { rtcProps} = useContext(PropsContext)

    const tracksResult = rtcProps?.enableVideo ? {...useTracks(), track: null} : {...useMicrophoneTrack(), tracks: null}

    const trackReady = tracksResult.ready
    const error = tracksResult.error
    const tracks = rtcProps?.enableVideo ? tracksResult.tracks : [tracksResult.track, null]

    const mediaStore = useRef<mediaStore>({})


    useEffect(() => {
        if (tracks !== null) {
            setLocalAudioTrack(tracks[0])
            setLocalVideoTrack(tracks[1])
            mediaStore.current[0] = {
                audioTrack: tracks[0],
                videoTrack: tracks[1]
            }
            setReady(true)
        } else if (error) {
            console.error(error)
            setReady(false)
        }
        return () => {
            if (tracks) {
                // eslint-disable-next-line no-unused-expressions
                tracks[0]?.close()
                // eslint-disable-next-line no-unused-expressions
                tracks[1]?.close()
            }
        }
    }, [trackReady, error, rtcProps?.enableVideo]) //, ready])

    return (
        <TracksContext.Provider
            value={{
                localVideoTrack: localVideoTrack,
                localAudioTrack: localAudioTrack
            }}
        >
            {ready ? props.children : null}
        </TracksContext.Provider>
    )
}

export default TracksConfigureM