import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {studyProgramsService} from "@data/services/studyProgramsService";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {studentClasses} from "@data/constants/formConstants";
import {backOfficeService} from "@data/services/backOfficeService";
import {RankingListItem} from "../../student/social/RankingScreen";
import StudyProgramItemsStep from "@common/studyPrograms/StudyProgramItemsStep";

const CreateEditStudyProgram = (props) => {

    const study_program_id = props.route.params?.study_program_id ?? null
    const editing = !!study_program_id
    const studyProgram = findItemInListWithId(study_program_id, props.studyPrograms) ?? null

    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}

    const currentClass = formValues?.class ?? studyProgram?.class ?? null
    const currentSubjectName = props.schoolSubjects?.find(x => x?.id === formValues?.school_subject_id ?? studyProgram?.school_subject?.id ?? null)?.name
    const currentTitle = formValues?.title ?? studyProgram?.title ?? null

    const formData = [
        {
            name: "class",
            label: "Classe du programme",
            type: "select.single", // select.single, select.multiple, text, date
            defaultValue: studyProgram?.class ?? "",
            options: studentClasses?.map(x => ({
                label: x,
                value: x
            })) ?? [],
            validationRules: {...validation.required()}
        },
        {
            name: "school_subject_id",
            label: "Matière du programme",
            description: `${currentClass}`,
            type: "select.single", // select.single, select.multiple, text, date
            defaultValue: studyProgram?.school_subject?.id ?? "",
            options: props.schoolSubjects?.map(x => ({
                label: x?.name,
                value: x?.id,
                icon: x?.icon
            })) ?? [],
            validationRules: {...validation.required()}
        },
        {
            label: "Nom du programme",
            description: `${currentClass} - ${currentSubjectName}`,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "title",
                    placeholder: "ex: Les fondamentaux en 1 mois",
                    defaultValue: studyProgram?.title ?? "",
                    validationRules: {
                        ...validation.required(),
                        ...validation.minLength(3),
                    },
                },
            ],
        },
        {
            label: "Chapitres du programme",
            description: `${currentClass} - ${currentSubjectName} - "${currentTitle}"`,
            name: "study_program_items",
            type: "custom",
            component: StudyProgramItemsStep,
            componentProps: {
                studyProgram: studyProgram ?? null,
            },
            defaultValue: studyProgram?.study_program_items?.map((item) => {
                return {
                    chapter_id: item?.chapter?.id,
                    start_date: item?.start_date,
                    end_date: item?.end_date
                }
            }) ?? [],
            validationRules: {...validation.required()}
        },
        {
            label: "Choisissez les élèves",
            description: `${currentClass} - ${currentSubjectName} - ${currentTitle}`,
            name: "student_ids",
            type: "select.paginated",
            singleMode: false,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "student",
                light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: studyProgram?.students?.map(x => x?.id) ?? [],
            defaultSelectedItems: studyProgram?.students ?? [],
            validationRules: {...validation.required()}
        },
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })
    }, [formValues])

    const onSubmit = data => {
        if (props.createStudyProgramRequest) return

        const formData = {
            ...data
        }
        if (editing)
            props.apiRequest(studyProgramsService.updateStudyProgram, {id: study_program_id}, formData)
        else {
            props.apiRequest(studyProgramsService.newStudyProgram, {}, formData)
        }
    }
    useApiStatus(
        studyProgramsService.newStudyProgram, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(studyProgramsService.indexPaginatedStudyPrograms)
            navigation.goBack()
        } // success callback
    )

    useApiStatus(
        studyProgramsService.updateStudyProgram, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            navigation.goBack()
        } // success callback
    )

    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         // hideProgressBar
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.createStudyProgramRequest || props.updateStudyProgramRequest}
                         previousRouteName={"menu/study-programs"}
                         submittedMessage=""
                         serverError={props.createStudyProgramError || props.updateStudyProgramError}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        createStudyProgramRequest: state.api.newStudyProgram?.request,
        updateStudyProgramRequest: state.api.updateStudyProgram?.request,
        createStudyProgramError: state.api.newStudyProgram?.error,
        updateStudyProgramError: state.api.updateStudyProgram?.error,
        studyPrograms: state.data?.studyPrograms?.studyPrograms,
        schoolSubjects: state.data?.staticData?.school_subjects,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditStudyProgram)
