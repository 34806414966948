import {View} from "react-native";
import React, {useContext, useState} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {schoolsService} from "@data/services/schoolsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import yup from "@data/utility/validation";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import SelectBox from "@common/components/select/selectSingle-Multiple";
import * as Clipboard from "expo-clipboard";
import {theme} from "@common/theme/theme";
import {Button} from "react-native-paper";
import {DialogM} from "@common/components/alert/dialog/Dialog";

function ClassroomSettingsTab(props) {

    const {classroom} = props
    const navigation = useNavigation()
    useApiStatus(
        schoolsService.putClassroom, // api service
        false, // success message on toast (string or null)
        false // error message from server in toast (true) or keep it in redux state (false)
    )

    useApiStatus(
        schoolsService.deleteClassroom, // api service
        false, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false),
        () => {
            props.apiRequest(schoolsService.getClassrooms)
            navigation.goBack()
        })


    const formSchema = yup.object().shape({
        name: yup.string().required(),
    });
    const resolver = yupResolver(formSchema);
    const schoolSubject = props.schoolSubjects.find(s => s.id === classroom?.school_subject_id)

    const {
        control,
        handleSubmit,
        formState: {errors, isDirty, dirtyFields}
    } = useForm({
        resolver,
        defaultValues: {
            name: classroom?.name,
            code: classroom?.code,
            school_subject_id: {
                value: schoolSubject?.id,
                label: schoolSubject?.name
            }
        }
    })

    const onSubmit = data => {
        if (props.putClassroomRequest) return
        //console.log(data)
        props.apiRequest(
            schoolsService.putClassroom,
            {id: classroom?.id},
            {
                ...data,
                school_subject_id: data.school_subject_id.value
            }
        )
    }


    return (

        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginVertical: 20,
            marginHorizontal: 15,
        }}>
            <TextInputM
                style={{marginBottom: 10, fontSize: 14}}
                placeholder="Nom de la classe"
                labelTop="Nom de la classe"
                name="name"
                control={control}
                errors={errors}
            />
            <Controller
                control={control}
                // rules={validation.required()}
                name="school_subject_id"
                render={({field: {onChange, value}}) => (
                    <SelectBox
                        title="Quelle matière est étudiée par la classe ?"
                        options={props.schoolSubjects?.map((x) => {
                            return {icon: x?.icon, value: x.id.toString(), label: x.name};
                        })}
                        value={value}
                        onSingleSelect={(value) => {
                            onChange(value);
                        }}
                    />
                )}
            />
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <TextInputM
                    style={{
                        marginBottom: 10,
                        fontSize: 14,
                    }}
                    placeholder="Code de classe"
                    labelTop="Code de classe"
                    name="code"
                    control={control}
                    errors={errors}
                    disabled={true}
                />
                <Button mode={'outlined'}
                        onPress={() => {
                            Clipboard.setString(classroom?.code)
                        }}
                        style={{
                            borderRadius: 20,
                            borderColor: theme.colors.primary,
                            position: 'absolute',
                            alignSelf: 'center',
                            right: 10,
                            bottom: 20
                        }}
                >
                    Copier
                </Button>
            </View>
            <FilledButtonM
                label={"Enregistrer"}
                onPress={handleSubmit(onSubmit)}
                loading={props.putClassroomRequest}
            />
            <FilledButtonM
                color={theme.colors.error}
                textColor={theme.colors.white}
                label={"Supprimer la classe "}
                onPress={() => {
                    DialogM.show({
                        text1: 'Supprimer la classe ?',
                        text2: 'Cette action est irréversible.',
                        buttonText1: 'Supprimer',
                        buttonColor1: theme.colors.error,
                        onPress: () => {
                            props.apiRequest(
                                schoolsService.deleteClassroom,
                                {id: classroom?.id}
                            )
                        },
                        buttonText2: 'Annuler',
                    })
                }}
                loading={props.deleteClassroomRequest}
            />
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        putClassroomRequest: state.api.putClassroom?.request,
        putClassroomError: state.api.putClassroom?.error,
        deleteClassroomRequest: state.api.deleteClassroom?.request,
        deleteClassroomError: state.api.deleteClassroom?.error,
        schoolSubjects: state.data.staticData?.school_subjects,

    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomSettingsTab)