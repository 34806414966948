import {FlatList, View} from "react-native";
import React, {useContext, useRef} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import TitleM from "@common/components/text/TitleM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {SchoolCard} from "./SchoolCard";
import {authService} from "@data/services/authService";

function Schools(props) {

    const dimensions = useContext(DimensionsContext)
    const schools = props.user?.schools
    const flatListRef = useRef()


    return (
        <View style={{alignSelf: 'center', flex: 1}}>
            <FlatList data={schools}
                      ref={flatListRef}
                      refreshing={!!props.getUserRequest}
                      onRefresh={() => props.apiRequest(authService.getUser)}
                      ListHeaderComponent={
                          <>
                              <View
                                  style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: dimensions?.width,
                                      borderColor: theme.colors.lightMore,
                                      paddingVertical: 8,
                                      marginBottom: 20
                                  }}
                              >
                                  <TitleM fontWeight="ExtraBold">{"écoles".toUpperCase()}</TitleM>
                              </View>
                          </>
                      }
                      ListEmptyComponent={<>
                          <TextM style={{
                              textAlign: "center",
                              marginTop: 0
                          }}>Aucune école</TextM>
                      </>}
                      renderItem={(item) => {
                          return <SchoolCard key={item.index} school={item.item}/>
                      }
                      }/>

        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(Schools)