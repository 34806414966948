import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import ChampionBubble from "../../../student/champions/ChampionBubble";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";


const ApplicationFinishedCongrats = (props) => {
    const dimensions = useContext(DimensionsContext)

    const [canStartAnimation, setStartAnimation] = React.useState(false)

    useEffect(() => {
        PlaySoundM.play(require('../../../../assets/sounds/finished.mp3'))
        setTimeout(() => {
            setStartAnimation(true)
        }, 1000)

    }, [])

    return (
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingHorizontal: 15,
            paddingBottom: 20,
            width: dimensions?.width
        }}>
            <TextM color={theme.colors.success} fontWeight={"Bold"} fontSize={25} wrap style={{textAlign: 'center'}}>
                {props.congratsTitle}
            </TextM>
            <ChampionBubble name={"sam"}
                            variant={"main"}
                            championPosition="left"
                            texts={[props.congratsText]}
                            onNextPress={() => {
                            }}
                            style={{marginTop: 30}}
            />

        </View>)
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFinishedCongrats)