import {FlatList, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import React, {useContext, useEffect, useRef} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import ClassroomCard from "./ClassroomCard";
import {schoolsService} from "@data/services/schoolsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";


function Classrooms(props) {

    const dimensions = useContext(DimensionsContext)
    const classrooms = props.user?.classrooms
    const navigation = useNavigation()
    const flatListRef = useRef()

    useEffect(() => {
        props.apiRequest(schoolsService.getClassrooms)
    }, [])

    useApiStatus(
        schoolsService.getClassrooms, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        } // success callback
    )


    return (
        <View style={{alignSelf: 'center', flex: 1}}>
            {props.getClassroomsRequest && IS_WEB ? <ActivityIndicator animating={true}
                                                                       style={{
                                                                           marginTop: 50
                                                                       }}
                                                                       color={theme.colors.primary}/> :
                <FlatList data={classrooms}
                          ref={flatListRef}
                          refreshing={!!props.getClassroomsRequest}
                          onRefresh={() => props.apiRequest(schoolsService.getClassrooms)}
                          ListHeaderComponent={
                              <>
                                  <View
                                      style={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: dimensions?.width,
                                          borderColor: theme.colors.lightMore,
                                          paddingVertical: 8,
                                          marginBottom: 20
                                      }}
                                  >
                                      <TitleM fontWeight="ExtraBold">{"MES classes".toUpperCase()}</TitleM>
                                  </View>
                              </>
                          }
                          ListEmptyComponent={<>
                              <TextM style={{
                                  textAlign: "center",
                                  marginTop: 0
                              }}>Aucune classe</TextM>
                          </>}
                          renderItem={(item) => {
                              return <ClassroomCard key={item.index} classroom={item.item}/>
                          }
                          }/>}
            <FilledButtonM
                style={{
                    marginVertical: 30,
                    alignSelf: 'center'
                }}
                icon="plus"
                onPress={() => {
                    navigation.push("new-classroom")
                }}
                label="NOUVELLE CLASSE"
            />

        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        schoolSubjects: state.data.staticData?.school_subjects,
        postClassroomRequest: state.api.postClassroom?.request,
        postClassroomError: state.api.postClassroom?.error,
        postClassroomSuccessData: state.api.postClassroom?.data,
        getClassroomsSuccess: state.api.getClassrooms?.success,
        getClassroomsRequest: state.api.getClassrooms?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(Classrooms)