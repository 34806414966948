// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import alert from './alert'
import data from "./data"
import offlineMode from "./offlineMode"
import liveMode from "./liveMode"
import camera from "./camera"
import api from "./api"
import notifications from "./notifications";
import pusher from "@data/redux/reducers/pusher";
import activity from "@data/redux/reducers/activity";


const rootReducer = combineReducers({
    alert,
    data, // persisted
    api,
    offlineMode,
    liveMode,
    camera,
    notifications,
    pusher,
    activity
})

export default rootReducer
