import {DimensionsContext} from "../../utils/mobileUtils";
import {Platform, View} from "react-native";
import {ProgressBar} from "react-native-paper";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React, {useContext, useEffect, useState} from "react";
import SuccessAnimation from "../../animations/SuccessAnimation";
import Animated, {useAnimatedStyle, useSharedValue, withTiming} from "react-native-reanimated";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";

export const ProgressBarM = (props) => {
    const dimensions = useContext(DimensionsContext)

    const {
        progressColor,
        count,
        previousCount,
        total,
        barWidth = Platform.OS !== 'web' ? dimensions?.width / 3 : dimensions?.width / 5,
        percent,
        style,
        barStyle,
        withAnimation = true,
        canStartAnimation,
        isBgColorWarning = false,
        noText = false
    } = props


    const [progressBarCount, setProgressBarCount] = useState(previousCount ?? count)
    const [triggerAnimation, setTriggerAnimation] = useState(false)
    const circle = useSharedValue(progressBarCount / total * (barWidth - 18));

    const reanimatedStyleCircle = useAnimatedStyle(() => {
        return {
            transform: [{translateX: circle.value}]
        }
    })
    // useEffect(() => {
    //     if (canStartAnimation) {
    //         console.log("TRIGGER ANIMATION")
    //
    //     }
    //
    // }, [progressBarCount])

    useEffect(() => {
        if (canStartAnimation && withAnimation && count > previousCount)
            setTimeout(() => {
                // setProgressBarCount(0)
                setTriggerAnimation(true)
                PlaySoundM.play(require('../../../../assets/sounds/progress.mp3'))
                setTimeout(() => {
                    setProgressBarCount(count)
                    circle.value = withTiming(count / total * (barWidth - 18), {duration: 200})
                }, 500)

            }, 100)

    }, [canStartAnimation])

    return <View style={{
        alignItems: "center",
        justifyContent: props.columnMode ? "center" : "flex-start",
        flexDirection: props.columnMode ? "column" : "row",
        marginTop: 6,
        ...style
    }}>
        <ProgressBar
            progress={withAnimation ? progressBarCount / total : count / total}
            color={progressColor}
            style={{
                width: barWidth,
                height: 16,
                borderRadius: 15,
                backgroundColor: isBgColorWarning ? theme.colors.warningLight : theme.colors.lightMore,
                marginRight: props.columnMode ? 0 : 10,
                ...barStyle
            }}
        />
        <Animated.View style={[{
            position: "absolute",
            alignSelf: "center",
        }, reanimatedStyleCircle]}>
            {triggerAnimation && <>
                {/*
                <PlaySound soundFile={require('../../../../assets/sounds/progress.mp3'))}/>
*/}
                <SuccessAnimation
                    size={14}
                    iconSize={0.5}
                    dotColor={progressColor}
                    backgroundColor={progressColor}
                    iconColor={"white"}
                    dotSize={20}
                    duration={1000}
                    animatedLayerColor={"white"}
                />
            </>}

        </Animated.View>

        {!noText && <View style={{
            flexDirection: 'row',
            alignItems: 'center'
        }}>

            {count >= 0 && <TextM
                color={theme.colors.grey}
                fontWeight="ExtraBold"
                fontSize={14}
                style={{marginBottom: 1}}
            >
                {`${withAnimation ? progressBarCount : count}${percent ? "%" : ""}`}
            </TextM>}
            {!percent && <TextM
                color={theme.colors.grey}
                fontWeight="Regular"
                fontSize={12}
                style={{marginBottom: 0}}
            >
                {" / " + total}
            </TextM>}
        </View>}

    </View>;
}