import React, {useCallback, useContext, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ScrollView, View, TouchableOpacity} from "react-native";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";
import * as WebBrowser from "expo-web-browser";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

//const height = (Dimensions.get("window").width / 16) * 6;
export const videoItem = (title, url, playing, onStateChanged, NB) => {

    const dimensions = useContext(DimensionsContext)

    return <TouchableOpacity style={{
        marginTop: 30,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        ...boxedCardBorderStyle,
        backgroundColor: theme.colors.white,
        width: dimensions?.width,
        alignSelf: 'center',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderColor: theme.colors.lightMore,
        paddingVertical: 20,
    }} onPress={() => {
        if (IS_WEB) {
            window.open(url, "_blank")
        } else {
            WebBrowser.openBrowserAsync(url)
        }
    }}>
        <View style={{
            width: dimensions?.width,
            paddingHorizontal: 20,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            // flex: 1
        }}>
            <TextM fontWeight={"Bold"}
                   fontSize={20}
                   wrap
                   style={{
                       // paddingTop: 10,
                       // paddingBottom: 20,
                       textAlign: "left"
                   }}>
                {title}
            </TextM>
            <FontAwesome5 name={"chevron-right"}
                // style={{position: "absolute", right: 12}}
                          color={theme.colors.primary}
                          size={20}/>
            {/*{url !== "" ?*/}
            {/*    <LinkTextM url={url}>{url}</LinkTextM> :*/}
            {/*    <TextM>*/}
            {/*        (à venir)*/}
            {/*    </TextM>}*/}
            {/*{NB && <TextM wrap style={{textAlign: "center", marginTop: 10}}>*/}
            {/*    NB: {NB}*/}
            {/*</TextM>}*/}
        </View>
    </TouchableOpacity>
}
export default function TutorVideos(props) {

    const coaching = props.route.params.coaching
    const navigation = useNavigation()

    const [playing, setPlaying] = useState(false);


    const onStateChanged = useCallback(state => {
        if (state === 'ended') {
            setPlaying(false);
        }
        if (state === 'playing') {
            setPlaying(true);
        }
        if (state === 'paused') {
            setPlaying(false);
        }
    }, []);

    return (
        <ScrollView contentContainerStyle={{paddingBottom: 20}}>
            {coaching === true ? <>
                <TextM fontWeight={"Bold"}
                       fontSize={23}
                       color={theme.colors.primary}
                       style={{
                           alignSelf: 'center',
                           marginTop: 20
                       }}>
                    Le coaching
                </TextM>
                {videoItem("Introduction théorique au coaching scolaire", "https://youtu.be/jj3AYKlz1-8", playing, onStateChanged)}
                {videoItem("Quelques notions pour mieux connaître son élève", "https://youtu.be/d8C7WA59Pi0", playing, onStateChanged)}
            </> : <>
                {/*<TextM fontWeight={"Bold"} fontSize={23} color={theme.colors.primary} style={{*/}
                {/*    alignSelf: 'center',*/}
                {/*    marginTop: 20*/}
                {/*}}>*/}
                {/*    L'application*/}
                {/*</TextM>*/}
                {videoItem("1. La vision pédagogique des fondateurs", "https://www.youtube.com/playlist?list=PLui4w-WLkxEjz8xbu66S0pVXiuffWw2n2", playing, onStateChanged)}
                {videoItem("2. L'expérience Masteur du point de vue de l'élève", "https://www.youtube.com/playlist?list=PLui4w-WLkxEh0l20lNtY4GJ3pTSXH_oKJ", playing, onStateChanged)}
                {videoItem("3. Introduction aux Lives Masteur©", "https://youtu.be/aVvJ0TJuZxc", playing, onStateChanged)}
                {videoItem("4. La boite à outil du tuteur", "https://www.youtube.com/playlist?list=PLui4w-WLkxEhB2_dy2dNmSIRHAB-y7xXI", playing, onStateChanged)}
            </>
            }

            {IS_WEB && <FilledButtonM mode={"text"}
                                      icon={"chevron-left"}
                                      color={theme.colors.link}
                                      style={{marginTop: 60}}
                                      onPress={() => {
                                          if (navigation.canGoBack())
                                              navigation.goBack()
                                          else
                                              navigation.replace("menu")
                                      }}
                                      label={"Retour"}/>}
        </ScrollView>
    )
}
