import React, {useContext, useRef, useState} from "react";
import {Platform, TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import moment from "moment";
import TextM from "@common/components/text/TextM";
import {ChampionImageM} from "../../../student/champions/ChampionImageM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import {capitalize, hoursMinutesAndSecondsFromSeconds} from "@data/utility/Utils";
import videoIcon from "../../../../assets/icons/generic/video.png";
import chronometer from "../../../../assets/icons/generic/chronometer.png";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {setOngoingActivity} from "@data/redux/actions/activity";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import * as WebBrowser from "expo-web-browser";
import liveIcon from "../../../../assets/icons/generic/live.png";
import ChapterItemComponent from "@common/chapters/ChapterItemComponent";
import {Divider} from "react-native-paper";
import SatisfactionComponent from "@common/liveMode/SatisfactionComponent";

const engagementScores = {
    assiduity: {
        color: theme.colors.success,
        name: "Assiduité",
        total: 40
    },
    interaction: {
        color: theme.colors.phone,
        name: "Interaction",
        total: 30
    },
    productivity: {
        color: theme.colors.purple,
        name: "Productivité",
        total: 30
    }
};

const engagementBarHeight = 30;

function LiveReportActivityItem({data, report}) {
    const activityTemplate = report?.activity_templates?.find(
        (a) => a.id === data?.activity_template_id
    );

    return (
        <View
            style={{
                flexDirection: "row",
                alignItems: "center",
                marginTop: 10,
                alignSelf: "center"
            }}
        >
            <TextM fontSize={24} fontWeight={"ExtraBold"}>
                {data?.digit}
            </TextM>
            <IconImageM
                uri={activityTemplate?.icon}
                width={60}
                style={{marginHorizontal: 10}}
            />
            <TextM fontSize={17} fontWeight={"Bold"}>
                {data?.label}
            </TextM>
        </View>
    );
}

export const formatHoursMinutesAndSeconds = (duration, fontSize = 20, noSeconds = false) => {
    const [hours, minutes, seconds] = hoursMinutesAndSecondsFromSeconds(duration);

    return (
        <>
            {hours > 0 && (
                <>
                    {hours}{" "}
                    <TextM fontSize={fontSize} fontWeight={"ExtraBold"}>
                        h
                    </TextM>{" "}
                </>
            )}
            {minutes}{" "}
            <TextM fontSize={fontSize} fontWeight={"ExtraBold"}>
                min
            </TextM>
            {!noSeconds && <>
                {" "}
                {seconds}{" "}
                <TextM fontSize={fontSize} fontWeight={"ExtraBold"}>
                    s
                </TextM>
            </>}

        </>
    );
};

function EngagementSubScoreBar({scoreDetails, scoreKey, width}) {
    const totalWidth = width;

    const elementWidth = scoreDetails?.score
        ? totalWidth * (scoreDetails?.score / 100)
        : 0;

    return (
        <View
            style={{
                height: engagementBarHeight,
                backgroundColor: engagementScores[scoreKey]?.color,
                justifyContent: "center",
                alignItems: "center",
                width: elementWidth
            }}
            disabled
            onPress={() => {
            }}
        />
    );
}

function EngagementSubScoreTitleComponent({
                                              scoreDetails,
                                              scoreKey,
                                              detailsVisible
                                          }) {
    return (
        <View>
            <TextM
                color={engagementScores[scoreKey]?.color}
                fontWeight={"Bold"}
                fontSize={14}
            >
                {engagementScores?.[scoreKey]?.name?.toUpperCase()}{" "}
                {scoreDetails?.score ?? "-"}{" "}
                <TextM
                    fontSize={12}
                    fontWeight={"Medium"}
                    color={engagementScores[scoreKey]?.color}
                >
                    /{scoreDetails?.score_max}
                </TextM>{" "}
            </TextM>
            {detailsVisible && (
                <View style={{marginTop: 5}}>
                    {scoreDetails?.details?.map((item, index) => {
                        return (
                            <View style={{marginBottom: 5}} key={index}>
                                <TextM
                                    fontWeight={"Medium"}
                                    color={engagementScores[scoreKey]?.color}
                                    fontSize={12}
                                >
                                    {item?.name} {item?.score}{" "}
                                    <TextM
                                        color={engagementScores[scoreKey]?.color}
                                        fontSize={10}
                                    >
                                        /{item?.score_max}
                                    </TextM>
                                </TextM>
                                <TextM fontWeight={"Thin"} fontSize={10}>
                                    {item?.value} {item?.unity}
                                </TextM>
                            </View>
                        );
                    })}
                </View>
            )}
        </View>
    );
}

function EngagementSubScoreTitle({
                                     scoreDetails,
                                     scoreKey,
                                     detailsVisible,
                                     setDetailsVisible,
                                     disableScoreButton = false
                                 }) {
    return !disableScoreButton ? (
        <TouchableOpacity
            style={{
                // backgroundColor: engagementScores[scoreKey]?.color,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderRadius: 5,
                paddingHorizontal: 10,
                paddingVertical: detailsVisible ? 5 : 2
            }}
            onPress={() => {
                setDetailsVisible(!detailsVisible);
            }}
        >
            <EngagementSubScoreTitleComponent
                scoreDetails={scoreDetails}
                scoreKey={scoreKey}
                detailsVisible={detailsVisible}
            />
        </TouchableOpacity>
    ) : (
        <View
            style={{
                // backgroundColor: engagementScores[scoreKey]?.color,
                justifyContent: "flex-start",
                alignItems: "flex-start",
                borderRadius: 5,
                paddingHorizontal: 10,
                paddingVertical: detailsVisible ? 5 : 2
            }}
        >
            <EngagementSubScoreTitleComponent
                scoreDetails={scoreDetails}
                scoreKey={scoreKey}
                detailsVisible={detailsVisible}
            />
        </View>
    );
}

export function EngagementScoreBar({
                                       scores,
                                       forceVerticalDetails = false,
                                       width,
                                       noProgressBar = false,
                                       disableScoreButton = false,
                                       style
                                   }) {
    const [detailsVisible, setDetailsVisible] = useState(false);
    const dimensions = useContext(DimensionsContext);

    return (
        <>
            <View
                style={{
                    flexDirection:
                        dimensions?.width < 800 || forceVerticalDetails ? "column" : "row",
                    // backgroundColor: theme.colors.whiteAlmost,
                    width,
                    alignItems: forceVerticalDetails ? "center" : null,
                    marginTop: noProgressBar ? 0 : 30,
                    // justifyContent: 'space-between',
                    paddingHorizontal: 10,
                    paddingBottom: noProgressBar ? 0 : 5,
                    marginLeft: 10,
                    ...style
                }}
            >
                {Object.keys(engagementScores).map((key) => {
                    return (
                        <EngagementSubScoreTitle
                            key={key}
                            scoreKey={key}
                            width={width}
                            scoreDetails={scores?.[key]}
                            detailsVisible={detailsVisible}
                            setDetailsVisible={setDetailsVisible}
                            disableScoreButton={disableScoreButton}
                        />
                    );
                })}
            </View>
            {!noProgressBar && (
                <View
                    style={{
                        flexDirection: "row",
                        height: engagementBarHeight,
                        backgroundColor: theme.colors.whiteAlmost,
                        width,
                        justifyContent: "flex-start"
                    }}
                >
                    {Object.keys(engagementScores).map((key) => {
                        return (
                            <EngagementSubScoreBar
                                key={key}
                                scoreKey={key}
                                width={width}
                                scoreDetails={scores?.[key]}
                            />
                        );
                    })}
                </View>
            )}
        </>
    );
}

const LiveReportComponent = (props) => {
    const {
        liveReport,
        withTitle = false,
        style,
        forceVerticalDetails = false,
        noDate = false
    } = props;
    const report = liveReport;
    const schoolSubjects = report?.school_subjects;
    const sessionDate = capitalize(
        moment(report?.statistics?.live_date_time).format("dddd DD MMMM HH:mm")
    );
    const validatedActivities = report?.statistics?.validated_activities;
    const todoActivities = report?.statistics?.todo_activities;
    const chapters = report?.statistics?.ongoing_student_chapters;

    const flatListRef = useRef();
    const inputRef = useRef();

    const dimensions = useContext(DimensionsContext);

    const isV1Report =
        report && !!report?.statistics
            ? !("ongoing_student_chapters" in report.statistics)
            : false;

    const boxStyle = {
        borderWidth: 2,
        borderColor: theme.colors.lightMore,
        borderRadius: theme.roundness,
        padding: 20,
        backgroundColor: theme.colors.white,
        marginTop: 20,
        width: dimensions?.width - 60,
        justifyContent: "center",
        alignItems: "center"
    };

    return (
        <>
            <View
                style={{
                    borderWidth: 1,
                    borderColor: theme.colors.lightMore,
                    borderRadius: 10,
                    width: dimensions?.width - 20,
                    padding: 20,
                    backgroundColor: theme.colors.white,
                    marginTop: 30,
                    marginBottom: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    ...style
                }}
            >
                {withTitle && (
                    <TextM
                        color={theme.colors.primary}
                        fontSize={20}
                        fontWeight={"ExtraBold"}
                        style={{textAlign: "center"}}
                        wrap
                    >
                        RAPPORT DE LIVE
                    </TextM>
                )}
                {!noDate && (
                    <TextM
                        color={theme.colors.primary}
                        fontSize={20}
                        fontWeight={"ExtraBold"}
                        style={{textAlign: "center"}}
                        wrap
                    >
                        {sessionDate}
                    </TextM>
                )}
                <TextM wrap style={{textAlign: "center"}}>
                    {report?.student?.display_name}
                </TextM>
                <ChampionImageM
                    style={{alignSelf: "center", marginVertical: 20}}
                    name="zoe"
                    variant={
                        report?.statistics?.zoe_variant
                            ? report?.statistics?.zoe_variant
                            : !report?.statistics
                                ? "main"
                                : "happy"
                    }
                    width={100}
                />
                <TextM
                    color={theme.colors.primary}
                    fontSize={18}
                    fontWeight={"Bold"}
                    style={{textAlign: "center", marginBottom: 10}}
                    wrap
                >
                    {!report?.statistics
                        ? "Malheureusement, nous n’avons pas eu la chance de te voir aujourd’hui. Quel dommage..."
                        : report?.statistics?.CTAS?.zoe}
                </TextM>
                {!!report?.statistics?.CTAS?.sam && (
                    <FilledButtonM
                        label={"J’active mon abonnement"}
                        color={theme.colors.success}
                        style={{
                            width: dimensions.width - 60,
                            alignSelf: "center",
                            marginTop: Platform.OS !== "web" ? null : 15,
                            marginBottom: Platform.OS !== "web" ? null : 15
                            // bottom: 15
                        }}
                        onPress={() => {
                            const subscribeUrl = report?.subscribe_url;
                            if (IS_WEB) {
                                window.open(subscribeUrl);
                            } else {
                                WebBrowser.openBrowserAsync(subscribeUrl);
                            }
                        }}
                    />
                )}

                {!!report?.statistics?.CTAS?.sam &&
                    report?.satisfaction &&
                    report?.satisfaction >= 5 && (
                        <View
                            style={{
                                ...boxStyle
                            }}
                        >
                            <TextM
                                fontSize={18}
                                fontWeight={"Bold"}
                                style={{textAlign: "center"}}
                                color={theme.colors.primary}
                                wrap
                            >
                                Qu'a pensé{" "}
                                {report?.student?.display_name?.substring(
                                    0,
                                    report?.student?.display_name?.length - 3
                                )}{" "}
                                de cette session LIVE ?
                            </TextM>
                            <SatisfactionComponent rating={report?.satisfaction} readonly/>
                        </View>
                    )}

                {validatedActivities?.length + todoActivities?.length > 0 &&
                    isV1Report &&
                    !!report?.statistics && (
                        <View
                            style={{
                                ...boxStyle
                            }}
                        >
                            <TextM fontSize={18} fontWeight={"Bold"}>
                                NOTIONS ABORDÉES
                            </TextM>
                            <View
                                style={{
                                    //alignItems: 'flex-start',
                                    width: "100%"
                                }}
                            >
                                {report?.statistics?.concepts?.map((c, index) => {
                                    const schoolSubject = schoolSubjects?.find(
                                        (s) => s.id === c.school_subject_id
                                    );
                                    return (
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                // alignItems: 'center',
                                                marginTop: 20,
                                                width: "100%"
                                            }}
                                            key={index}
                                        >
                                            <IconImageM
                                                uri={schoolSubject?.icon}
                                                style={{
                                                    marginRight: 10
                                                }}
                                                width={60}
                                            />
                                            <View style={{flex: 1}}>
                                                <TextM fontWeight={"Bold"} fontSize={17}>
                                                    {schoolSubject?.name}
                                                </TextM>
                                                <TextM fontSize={17} wrap>
                                                    {c?.concepts?.join(", ")}
                                                </TextM>
                                            </View>
                                        </View>
                                    );
                                })}
                            </View>
                        </View>
                    )}

                {!isV1Report && !!report?.statistics && (
                    <>
                        <View
                            style={{
                                ...boxStyle,
                                paddingBottom: 0,
                                overflow: "hidden"
                            }}
                        >
                            <TextM
                                fontSize={18}
                                fontWeight={"Bold"}
                                style={{textAlign: "center"}}
                                wrap
                            >
                                SCORE D'ENGAGEMENT SUR LA SESSION
                            </TextM>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    marginTop: 10
                                }}
                            >
                                <TextM fontSize={30} fontWeight={"ExtraBold"}>
                                    {report?.statistics?.scores?.total_score}{" "}
                                    <TextM fontSize={20} fontWeight={"Bold"}>
                                        /100
                                    </TextM>
                                </TextM>
                            </View>

                            <EngagementScoreBar
                                scores={report?.statistics?.scores}
                                width={
                                    style?.width ? style?.width - 60 : dimensions?.width - 60
                                }
                                forceVerticalDetails={forceVerticalDetails}
                            />
                        </View>
                    </>
                )}

                {chapters?.length > 0 && !isV1Report && !!report?.statistics && (
                    <View
                        style={{
                            ...boxStyle
                        }}
                    >
                        <TextM
                            fontSize={18}
                            fontWeight={"Bold"}
                            style={{textAlign: "center"}}
                            wrap
                        >
                            AVANCEMENT DES CHAPITRES
                        </TextM>
                        <>
                            {chapters?.map((item, index) => {
                                const chapterInfos = {...item, ...item?.chapter};

                                return <ChapterItemComponent chapter={chapterInfos}
                                                             key={index}
                                                             liveReportMode
                                                             validatedActivities={chapterInfos?.validated_activities_count}
                                                             todoActivities={chapterInfos?.todo_activities_count}
                                />
                            })}
                        </>
                    </View>
                )}
                {isV1Report && !!report?.statistics && <>
                    <View style={{
                        ...boxStyle
                    }}>
                        <TextM fontSize={18} fontWeight={'Bold'} style={{textAlign: 'center'}} wrap>
                            TEMPS CONNECTÉ.E AU LIVE
                        </TextM>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 10
                        }}>
                            <IconImageM source={liveIcon}
                                        width={50}
                                        style={{marginRight: 10}}
                            />
                            <TextM fontSize={30} fontWeight={"ExtraBold"}>
                                {formatHoursMinutesAndSeconds(
                                    report?.statistics?.live_connection_duration
                                )}
                            </TextM>
                        </View>
                    </View>
                    <View
                        style={{
                            ...boxStyle
                        }}
                    >
                        <TextM
                            fontSize={18}
                            fontWeight={"Bold"}
                            style={{textAlign: "center"}}
                            wrap
                        >
                            TEMPS D'EXPLICATION
                        </TextM>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 10
                            }}
                        >
                            <IconImageM
                                source={videoIcon}
                                width={50}
                                style={{marginRight: 10}}
                            />
                            <TextM fontSize={30} fontWeight={"ExtraBold"}>
                                {formatHoursMinutesAndSeconds(
                                    report?.statistics?.tutor_call_duration
                                )}
                            </TextM>
                        </View>
                    </View>
                    <View
                        style={{
                            ...boxStyle
                        }}
                    >
                        <TextM
                            fontSize={18}
                            fontWeight={"Bold"}
                            style={{textAlign: "center"}}
                            wrap
                        >
                            TEMPS DE PRODUCTION CHRONOMÉTRÉ
                        </TextM>
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 10
                            }}
                        >
                            <IconImageM
                                source={chronometer}
                                width={50}
                                style={{marginRight: 10}}
                            />
                            <TextM fontSize={30} fontWeight={"ExtraBold"}>
                                {formatHoursMinutesAndSeconds(report?.statistics?.duration)}
                            </TextM>
                        </View>
                    </View>
                </>}

                {validatedActivities?.length + todoActivities?.length > 0 &&
                    !!report?.statistics && (
                        <View
                            style={{
                                ...boxStyle
                            }}
                        >
                            {validatedActivities?.length > 0 && (
                                <>
                                    <TextM fontSize={18} fontWeight={"Bold"} wrap>
                                        TRAVAIL VALIDÉ ✅
                                    </TextM>
                                    {validatedActivities?.map((v, index) => (
                                        <LiveReportActivityItem
                                            key={index}
                                            report={report}
                                            data={v}
                                        />
                                    ))}
                                </>
                            )}

                            {validatedActivities?.length > 0 &&
                                todoActivities?.length > 0 && (
                                    <Divider
                                        style={{
                                            width: dimensions?.width - 60,
                                            marginVertical: 20,
                                            borderWidth: 1,
                                            borderColor: theme.colors.lightMore
                                        }}
                                    />
                                )}

                            {todoActivities?.length > 0 && (
                                <>
                                    <TextM fontSize={18} fontWeight={"Bold"} wrap>
                                        TRAVAIL À FAIRE 📋
                                    </TextM>
                                    {todoActivities?.map((todo, index) => (
                                        <LiveReportActivityItem
                                            key={index}
                                            report={report}
                                            data={todo}
                                        />
                                    ))}
                                </>
                            )}
                        </View>
                    )}

                {!!report?.statistics?.CTAS?.sam && (
                    <>
                        <ChampionImageM
                            style={{alignSelf: "center", marginTop: 40, marginBottom: 20}}
                            name="sam"
                            variant={"happy"}
                            width={100}
                        />
                        <TextM
                            color={theme.colors.primary}
                            fontSize={18}
                            fontWeight={"Bold"}
                            style={{textAlign: "center", marginBottom: 10}}
                            wrap
                        >
                            {report?.statistics?.CTAS?.sam}
                        </TextM>

                        <FilledButtonM
                            label={"J’active mon abonnement"}
                            color={theme.colors.success}
                            style={{
                                width: dimensions.width - 60,
                                alignSelf: "center",
                                marginTop: Platform.OS !== "web" ? null : 15,
                                marginBottom: Platform.OS !== "web" ? null : 15
                                // bottom: 15
                            }}
                            onPress={() => {
                                const subscribeUrl = report?.subscribe_url;
                                if (IS_WEB) {
                                    window.open(subscribeUrl);
                                } else {
                                    WebBrowser.openBrowserAsync(subscribeUrl);
                                }
                            }}
                        />
                    </>
                )}
            </View>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        getLiveReportRequest: state.api.getLiveReport?.request,
        getLiveReportError: state.api.getLiveReport?.error
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setOngoingActivity,
    setIsLiveModeEnabled
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveReportComponent);
