import axios from "axios"


function getSkillSets() {
    return axios.get(`/api/v1/user/skill-sets`)
}

function markSkillSetEvolutionAsRead({}, values) {
    return axios.post(`/api/v1/user/skill-sets/read`, values)
}


export const skillSetsService = {
    getSkillSets,
    markSkillSetEvolutionAsRead
}

