import React, { useContext, useEffect, useRef, useState } from "react";
import { AppState, Platform, Pressable, View } from "react-native";
import * as Notifications from "expo-notifications";
import { messengerService } from "../../data/services/messengerService";
import { useApiStatus } from "@common/hooks/useApiStatus";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import fr from "dayjs/locale/fr";
import * as Clipboard from "expo-clipboard";
import {
  Bubble,
  Composer,
  GiftedChat,
  InputToolbar,
  LoadEarlier,
  Message,
  Send,
} from "react-native-gifted-chat";
import { theme } from "@common/theme/theme";
import { ActivityIndicator } from "react-native-paper";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { connect } from "react-redux";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import TypingIndicator from "react-native-gifted-chat/lib/TypingIndicator";
import AvatarM from "@common/components/avatar/AvatarM";
import * as joypixels from "emoji-toolkit";
import { decode } from "html-entities";
import { clearLastPhoto, setLastPhoto } from "../../data/redux/actions/camera";
import { useNavigation } from "@react-navigation/native";
import * as ImagePicker from "expo-image-picker";
import { API_URL, cleanMediaUrl } from "../../data/constants/apiConstants";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  changeCount,
  changeUnreadMessageInLiveCount,
} from "@data/redux/actions/broadcast";
import { useInterval } from "@common/utils/useInterval";
import { useCamera } from "@common/hooks/useCamera";
import { StatusBar } from "expo-status-bar";
import { dataURLtoFile } from "@data/utility/Utils";
import moment from "moment";
import AudioRecordingModal from "./AudioRecordingModal";
import { AudioRecordingContainer } from "../../student/activity/execute/AudioRecordingContainer";
import { fetchResourceFromURI } from "../hooks/useRequestsBatch";

const dayjs = require("dayjs");

export const clearNotifications = (path) => {
  Notifications.getPresentedNotificationsAsync().then((notifications) => {
    notifications.forEach((notification) => {
      // console.log(notification)
      // console.log(notification.request.content.data.path)
      if (path === notification.request.content.data.path) {
        Notifications.dismissNotificationAsync(
          notification.request.identifier
        ).then();
      }
    });
  });
};

export const countNotifications = async (path) => {
  const notifications = await Notifications.getPresentedNotificationsAsync();

  let i = 0;
  notifications.forEach((notification) => {
    // console.log(notification)
    // console.log(notification.request.content.data.path)
    if (path === notification.request.content.data.path) {
      i++;
    }
  });

  return i;
};

const cleanURLsInBody = (body) => {
  // extract URLs from body
  const urls = body.match(/(https?:\/\/[^\s]+)/g) || [];

  // url encode each url
  const encodedURLs = urls.map((url) => encodeURI(url).replace(/%25/g, "%"));

  //replace each url in body with encoded url
  let newBody = body;
  urls.forEach((url, i) => {
    newBody = newBody.replace(url, encodedURLs[i]);
  });

  return newBody;
};

export const transformMessage = (x) => {
  return {
    _id: x?.id,
    text:
      x?.image || x?.audio
        ? ""
        : joypixels.shortnameToUnicode(cleanURLsInBody(decode(x?.body))),
    createdAt: x?.created_at,
    user: {
      _id: x?.owner_id,
      name: x?.owner?.name,
    },
    image: x?.image ? API_URL + x?.image?.lg : "",
    audio: x?.audio ? API_URL + x?.audio : "",
    threadId: x?.thread_id,
    //https://api-staging.masteur.com/messenger/assets/threads/98751f4c-556b-404d-b67b-ff846b9a7514/gallery/989921c4-a0f7-4b91-b87d-b784bcaa69b5/md/Chat_ImageWajihH_2023-03-03_aff508c8-1301-4510-b922-ea83ca102ac0.png
    //https://api-staging.masteur.com/messenger/assets/threads/98751f4c-556b-404d-b67b-ff846b9a7514/audio/989f2e03-44a9-4e83-8268-846456fbe999/Chat_audioJuniorW_2023-03-06_192f7c5b-f8ae-4c9e-b3da-234c01397968.mp4
    // received: moment(x.created_at).isSameOrBefore(
    //     student?.tutor_messenger_last_read
    // ),
    // sent: true
  };
};
const CommonChat = (props) => {
  const {
    threadId,
    messages,
    setMessages,
    sendWhisper,
    groupChat = false,
    participants = [],
    liveChat = false,
  } = props;
  const updateRequest = props.postMessagesRequest;
  const getMessageRequest = props.getMessagesRequest;
  const appState = useRef(AppState.currentState);
  const [currentMsgId, setCurrentMsgId] = useState(null);
  const handleAppStateChange = async (nextAppState) => {
    if (
      appState.current === "active" &&
      nextAppState.match(/inactive|background/)
    ) {
    } else if (
      appState.current.match(/inactive|background/) &&
      nextAppState === "active"
    ) {
      if (Platform.OS !== "web") clearNotifications("messages");
      props.apiRequest(messengerService.getMessages, {
        threadId: threadId,
      });
    }
    appState.current = nextAppState;
  };

  const loadEarlear = () => {
    if (props.getPageMessagesRequest || props.getMessagesRequest) return;

    const next_page = props.user?.messageMeta?.next_page_id;
    // console.log("next_page", next_page)
    props.apiRequest(messengerService.getPageMessages, {
      threadId: threadId,
      pageId: next_page,
    });
  };
  const onSend = (msg = []) => {
    // console.log(msg);
    setCurrentMsgId(msg[0]?._id);
    setMessages((old) => GiftedChat.append(old, [msg[0]]));
    props.apiRequest(
      messengerService.postMessages,
      { threadId: threadId },
      { message: msg[0].text, temporary_id: "message" }
    );
  };

  useEffect(() => {
    if (Platform.OS !== "web") clearNotifications("messages");
    props.apiRequest(messengerService.getMessages, {
      threadId: threadId,
    });
    // console.log("GET MESSAGES threadId", threadId)

    const subscription = Notifications.addNotificationReceivedListener(
      (notification) => {
        // console.log("notification", notification);

        if (
          notification.request.content.data.path === "messages" &&
          notification.request.content.data.data?.thread_id === threadId
        ) {
          // console.log("dismissing");

          setTimeout(() => {
            Notifications.dismissNotificationAsync(
              notification.request.identifier
            ).then();
          }, 1000);
        }
      }
    );

    const sub = AppState.addEventListener("change", handleAppStateChange);

    return () => {
      if (Platform.OS !== "web") subscription.remove();
      if (!liveChat)
        props.apiRequest(messengerService.getMarkRead, {
          threadId: threadId,
        });

      sub.remove();
    };

    // Notifications.dismissNotificationAsync("").then(() => {
    // })
  }, [threadId]);

  useApiStatus(
    messengerService.getPageMessages,
    null,
    true,
    (successData, successAlldata) => {
      const messageEarler = props.user?.messages[threadId];
      setMessages(messageEarler.map(transformMessage));
    }
  );
  useApiStatus(
    messengerService.getMessages, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData, successAlldata) => {
      const messageServeur = props.user?.messages[threadId];
      setMessages(messageServeur?.map(transformMessage));
      props.apiRequest(messengerService.getMarkRead, {
        threadId: threadId,
      });
    }
  );
  useApiStatus(
    messengerService.getMarkRead, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData, successAlldata) => {},
    (error) => {
      console.log(error);
    }
  );
  useApiStatus(
    messengerService.postMessages, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData, successAlldata) => {}
  );

  const navigation = useNavigation();

  const [hasInputText, setHasInputText] = useState(false);

  const [launchCamera] = useCamera("chat", false, false);

  function renderComposer(options) {
    return (
      !getMessageRequest && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            // alignSelf: 'flex-end',
            width: "92%",
            alignItems: "center",
          }}
        >
          <Composer
            {...options}
            textInputStyle={{
              ...options.textInputStyle,
              // width: "90%"
            }}
            textInputProps={{
              ...props.textInputProps,
              onContentSizeChange: (event) => {
                // console.log(event?.nativeEvent?.contentSize?.width, event?.nativeEvent?.contentSize?.height)
                // if (event?.nativeEvent?.contentSize?.height > inputHeight)
                if (!IS_WEB)
                  setInputHeight(event?.nativeEvent?.contentSize?.height);
              },
              blurOnSubmit: IS_WEB,
              onSubmitEditing: IS_WEB
                ? () => {
                    if (options.text && options.onSend) {
                      options.onSend({ text: options.text.trim() }, true);
                    }
                  }
                : undefined,
              // onSubmitEditing: ({nativeEvent}) => onSend([nativeEvent])
            }}
            composerHeight={Math.max(35, inputHeight) + 16}
          />
          {!hasInputText &&
            !updateRequest &&
            !props.postImagesRequest &&
            !props.postAudioRequest && (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  marginRight: -30,
                  // alignSelf: 'flex-end',
                  alignItems: "center",
                }}
              >
                <Pressable
                  onPress={() => {
                    launchCamera();
                  }}
                >
                  <FontAwesome5
                    color={theme.colors.primary}
                    size={30}
                    name="camera"
                    style={{ marginHorizontal: 8, paddingBottom: 3 }}
                  />
                </Pressable>
                <Pressable onPress={pickImage}>
                  <FontAwesome5
                    color={theme.colors.primary}
                    size={30}
                    name="image"
                    style={{ marginHorizontal: 8, paddingBottom: 3 }}
                  />
                </Pressable>
                <Pressable
                  onPress={() => {
                    setVisible(true);
                  }}
                >
                  <FontAwesome5
                    color={theme.colors.primary}
                    size={30}
                    name="microphone"
                    style={{ marginHorizontal: 8, paddingBottom: 3 }}
                  />
                </Pressable>
              </View>
            )}
        </View>
      )
    );
  }

  const [inputHeight, setInputHeight] = useState(0);
  const dimensions = useContext(DimensionsContext);

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      // allowsEditing: true,
      // aspect: [4, 3],
      quality: 1,
    });

    //console.log(result);

    if (!result.canceled) {
      // props.setChatPhoto()
      props.setNewPhoto("chat", result.assets[0]);
    }
  };

  useEffect(() => {
    if (props.lastPhotoFormId === "chat" && !!props.lastPhoto) {
      // console.log(props.chatPhoto)
      const cleanFormData = new FormData();
      //cleanFormData.append("image", props.chatPhoto)
      cleanFormData.append("temporary_id", "test");
      const image = {
        uri: props.lastPhoto?.uri,
        name: `Masteur_Image_${props.user?.display_name}_${moment().format(
          "YYYY-MM-DD"
        )}.png`,
        type: "image/png",
      };
      //console.log(dataURLtoFile(props.chatPhoto.uri, `Chat_Image${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.png`))
      if (IS_WEB)
        cleanFormData.append(
          "image",
          dataURLtoFile(
            props.lastPhoto?.uri,
            `Masteur_Image_${props.user?.display_name}_${moment().format(
              "YYYY-MM-DD"
            )}.png`
          )
        );
      else cleanFormData.append("image", image);
      const temporaryMsg = {
        _id: "temporaryMsg",
        text: "",
        createdAt: moment.now(),
        user: {
          _id: props.user?.id,
          name: props.user?.display_name,
        },
        image: props.lastPhoto?.uri,
      };
      setCurrentMsgId(temporaryMsg?._id);
      setMessages((old) => GiftedChat.append(old, [temporaryMsg]));
      props.apiRequest(
        messengerService.postImage,
        { threadId: threadId },
        cleanFormData
      );
      props.clearLastPhoto();
    }
  }, [props.lastPhoto]);

  useApiStatus(
    messengerService.postImage, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData, successAlldata) => {}
  );
  useApiStatus(
    messengerService.postAudio, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData, successAlldata) => {}
  );
  const customMessage = (props) => {
    return (
      <Message
        {...props}
        containerStyle={{
          left: {
            alignItems: "flex-start",
          },
          right: {
            alignItems: "flex-start",
          },
        }}
      />
    );
  };

  const [recordingURI, setRecordingURI] = useState(null);
  const [time, setTime] = useState(0);
  const [visible, setVisible] = useState(false);
  const handleShowModal = () => {
    setVisible(!visible);
  };

  const onAudioSend = async () => {
    //console.log(recordingURI)
    const cleanFormData = new FormData();
    const audio = IS_WEB
      ? await fetchResourceFromURI(recordingURI)
      : {
          uri: recordingURI,
          name: `Masteur_audio${props.user?.display_name}_${moment().format(
            "YYYY-MM-DD"
          )}.m4a`,
          type: "audio/m4a",
        };
    //console.log(audio)
    cleanFormData.append("audio", audio);
    cleanFormData.append("temporary_id", "test");
    const temporaryMsg = {
      _id: "temporaryMsg",
      text: "",
      createdAt: moment.now(),
      user: {
        _id: props.user?.id,
        name: props.user?.display_name,
      },
      audio: recordingURI,
    };
    setCurrentMsgId(temporaryMsg?._id);
    setMessages((old) => GiftedChat.append(old, [temporaryMsg]));
    props.apiRequest(
      messengerService.postAudio,
      { threadId: threadId },
      cleanFormData
    );
    setRecordingURI(null);
    handleShowModal();
  };

  const renderMessageAudio = (data) => {
    const userId = data?.currentMessage?.user?._id;
    // console.log(userId)
    // return <></>;
    return (
      <View
        style={{
          padding: 10,
          width: 260,
        }}
      >
        <AudioRecordingContainer
          recordingURI={cleanMediaUrl(data?.currentMessage?.audio)}
          readOnly
          chatMode
          color={
            userId === props.user?.id
              ? theme.colors.white
              : theme.colors.primary
          }
        />
      </View>
    );
  };
  const renderTicks = (data) => {
    const userId = data?.currentMessage?.user?._id;
    const messageId = data?.currentMessage?._id;
    return (
      userId === props.user?.id &&
      messageId === currentMsgId &&
      updateRequest && (
        <ActivityIndicator
          animating={true}
          color={theme.colors.white}
          style={{ marginHorizontal: 4 }}
          size={12}
        />
      )
    );
  };
  const renderCustomView = (data) => {
    const userId = data?.currentMessage?.user?._id;
    const messageId = data?.currentMessage?._id;
    return (
      userId === props.user?.id &&
      messageId === currentMsgId &&
      props.postImagesRequest && (
        <ActivityIndicator
          animating={true}
          color={theme.colors.white}
          style={{ marginHorizontal: 4 }}
          size={22}
        />
      )
    );
  };

  const insets = useSafeAreaInsets();

  const [scrolling, setScrolling] = useState(false);
  useInterval(
    () => {
      if (!scrolling) {
        props.changeCount(0);
        if (threadId) props.changeUnreadMessageInLiveCount(threadId, null, 0);
      }
    },
    appState.current.match(/inactive|background/) ? null : 5000
  );

  useEffect(() => {
    // console.log(props.user?.messageMeta)
  }, [props.user?.messageMeta]);

  return (
    <>
      <View style={{ flex: 1, paddingBottom: insets.bottom }}>
        <GiftedChat
          messagesContainerStyle={{
            width: dimensions?.width,
            paddingBottom: Math.max(20, inputHeight - 24),
            // paddingTop: 0
          }}
          wrapInSafeArea={false}
          messages={messages}
          listViewProps={{
            onEndReached:
              !props.user?.messageMeta?.final_page && IS_WEB
                ? loadEarlear
                : () => {},
            onEndReachedThreshold: 0.3,
            onScroll: ({ nativeEvent }) => {
              if (!scrolling && nativeEvent.contentOffset.y !== 0)
                setScrolling(true);
              else setScrolling(false);
            },
          }}
          renderCustomView={renderCustomView}
          isCustomViewBottom
          renderMessage={(props) => customMessage(props)}
          locale={fr}
          timeFormat={"HH:mm"}
          dateFormat={"ddd DD MMM"}
          infiniteScroll
          isAnimated
          onSend={(messages) => onSend(messages)}
          onLongPress={(context, currentMessage) => {
            const options = ["Copier", "Annuler"];
            const cancelButtonIndex = options.length - 1;
            context.actionSheet().showActionSheetWithOptions(
              {
                options,
                cancelButtonIndex,
              },
              (buttonIndex) => {
                switch (buttonIndex) {
                  case 0:
                    Clipboard.setStringAsync(currentMessage.text).then();
                    break;
                }
              }
            );
          }}
          user={{
            _id: props.user?.id,
            avatar: groupChat ? props.user?.avatar : null,
            name: props.user?.display_name,
          }}
          renderComposer={renderComposer}
          renderMessageAudio={renderMessageAudio}
          // renderMessageImage={renderMessageImage}
          renderFooter={() => {
            return (
              groupChat && (
                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  {props.isTyping && (
                    <AvatarM
                      user={props.currentLiveSession?.users?.find(
                        (x) => x?.id === props.typingUser
                      )}
                      style={{
                        marginHorizontal: 10,
                      }}
                    />
                  )}

                  <TypingIndicator isTyping={props.isTyping} />
                </View>
              )
            );
          }}
          renderUsernameOnMessage={groupChat}
          shouldUpdateMessage={() => {
            return true;
          }}
          //isTyping={props.isTyping}
          onLoadEarlier={loadEarlear}
          renderLoadEarlier={() => {
            return (
              <LoadEarlier
                isLoadingEarlier={props.getPageMessagesRequest}
                label=""
                wrapperStyle={{ backgroundColor: theme.colors.transparent }}
                activityIndicatorColor={theme.colors.primary}
              />
            );
          }}
          loadEarlier={!props.user?.messageMeta?.final_page}
          renderLoading={() => {
            return (
              <View
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ActivityIndicator
                  animating={true}
                  color={theme.colors.primary}
                  size={40}
                />
              </View>
            );
          }}
          onInputTextChanged={(text) => {
            // console.log(text)
            if (text !== "") {
              sendWhisper();
              setHasInputText(true);
            } else setHasInputText(false);
          }}
          renderInputToolbar={(props) => {
            return (
              <InputToolbar
                {...props}
                placeholder="Écrire un message..."
                textInputStyle={{
                  color: theme.colors.black,
                  fontFamily: "Montserrat-Regular",
                  // height: Math.max(35, inputHeight)

                  // marginBottom: 20
                  paddingTop: IS_WEB || Platform.OS === "ios" ? 10 : 0,
                  // paddingBottom: Platform.OS === "ios" ? 10 : 0
                }}
                accessoryStyle={{
                  borderWidth: 0,
                }}
                containerStyle={{
                  height: Math.max(45, inputHeight) + 16,
                  // width: Dimensions.get("window").width,
                  // alignSelf:"center"
                  borderTopLeftRadius: IS_WEB ? 20 : 0,
                  borderTopRightRadius: IS_WEB ? 20 : 0,
                  // borderLeftWidth: IS_WEB ? 1 : 0,
                  // borderRightWidth: IS_WEB ? 1 : 0,
                  // borderColor: theme.colors.light
                }}
                primaryStyle={{
                  height: Math.max(45, inputHeight) + 16,
                  // height: 100,
                }}
              />
            );
          }}
          renderSend={(props) => {
            return (
              <>
                {getMessageRequest ? (
                  <ActivityIndicator
                    animating={true}
                    color={theme.colors.primary}
                    size={25}
                    style={{
                      position: "absolute",
                      right: 10,
                      top: 10,
                    }}
                  />
                ) : updateRequest ? (
                  <></>
                ) : (
                  <Send {...props}>
                    <FontAwesome5
                      color={theme.colors.primary}
                      size={35}
                      solid
                      name="arrow-alt-circle-right"
                      style={{ paddingBottom: 20, marginLeft: -16 }}
                    />
                  </Send>
                )}
              </>
            );
          }}
          showAvatarForEveryMessage={groupChat}
          renderAvatar={
            groupChat
              ? (data) => {
                  const user = participants.find(
                    (u) => u.id === data.currentMessage?.user?._id
                  );
                  return <AvatarM user={user} />;
                }
              : null
          }
          renderBubble={(props) => {
            return (
              <Bubble
                {...props}
                renderTicks={() => renderTicks(props)}
                textStyle={{
                  right: {
                    color: "white",
                    fontFamily: "Montserrat-Regular",
                    maxWidth: dimensions?.width / 1.5,
                  },
                  left: {
                    color: "#24204F",
                    fontFamily: "Montserrat-Regular",
                    maxWidth: dimensions?.width / 1.5,
                  },
                }}
                wrapperStyle={{
                  left: {
                    backgroundColor: theme.colors.white,
                  },
                  right: {
                    backgroundColor: theme.colors.primary,
                  },
                }}
              />
            );
          }}
          scrollToBottom
          scrollToBottomComponent={() => {
            return (
              <FontAwesome5
                name="angle-double-down"
                size={22}
                color={theme.colors.primary}
              />
            );
          }}
        />
      </View>
      <StatusBar
        style="dark"
        backgroundColor={theme.colors.white}
        translucent={false}
      />

      <AudioRecordingModal
        visible={visible}
        onDismiss={handleShowModal}
        setTime={setTime}
        recordingURI={recordingURI}
        setRecordingURI={setRecordingURI}
        onSend={onAudioSend}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getMessagesRequest: state.api.getMessages?.request,
    getMessagesSuccess: state.api.getMessages?.success,
    getMessagesError: state.api.getMessages?.error,

    getPageMessagesRequest: state.api.getPageMessages?.request,
    getPageMessagesSuccess: state.api.getPageMessages?.success,
    getPageMessagesError: state.api.getPageMessages?.error,

    getMarkReadRequest: state.api.getMarkRead?.request,
    getMarkReadSuccess: state.api.getMarkRead?.success,
    getMarkReadError: state.api.getMarkRead?.error,

    postMessagesRequest: state.api.postMessages?.request,
    postMessagesSuccess: state.api.postMessages?.success,
    postMessagesError: state.api.postMessages?.error,

    user: state.data.currentUser?.object,
    token: state.data.session?.object?.token,
    isTyping: state.liveMode?.isTyping,
    typingUser: state.liveMode?.typingUser,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    lastPhoto: state.camera?.lastPhoto,
    lastPhotoFormId: state.camera?.formId,

    postImagesRequest: state.api.postImage?.request,
    postImagesSuccess: state.api.postImage?.success,
    postImagesError: state.api.postImage?.error,

    postAudioRequest: state.api.postAudio?.request,
    postAudioSuccess: state.api.postAudio?.success,
    postAudioError: state.api.postAudio?.error,
  };
};
const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  clearLastPhoto,
  setNewPhoto: setLastPhoto,
  changeCount,
  changeUnreadMessageInLiveCount,
};
export default connect(mapStateToProps, mapDispatchToProps)(CommonChat);
