import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";

function ActivityGem(props) {

    const {activity, style, large} = props

    const gemId = activity?.gem_id
    const gem = props.gems?.find(x => x?.id === gemId)
    const color = gem?.color
    const name = gem?.name ?? "Gemme"
    const icon = gemId ? gem?.icon : gemNone

    const fontSize = large ? 16 : 10

    return <View style={{
        backgroundColor: gemId ? `${color}33` : "",
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 4,
        flexDirection: "row",
        alignItems: "center",
        ...style
    }}>
        <IconImageM uri={icon}
                    width={fontSize + 4}/>
        <TextM fontWeight={"Bold"}
               fontSize={fontSize}
               color={color}
        > {name}</TextM>

    </View>;
}

const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates,
        challengeTemplates: state.data.staticData?.challenge_templates,
        gems: state.data.staticData?.gems

    }
}
const mapDispatchToProps =
    {apiRequest, apiIdle}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityGem)