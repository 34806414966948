import axios from "axios"

function getStaticScoringData() {
  return axios.get(`api/v1/static-data/scoring`)
}

function postScoringRule({}, formData) {
  return axios.post(`api/v1/scoring-rules`, formData)
}

function postScoringComment({}, formData) {
  return axios.post(`api/v1/scoring-comments`, formData)
}

function generateSamAppreciation({}, formData) {
  return axios.post(`api/v1/generate-sam-appreciation`, formData)
}


export const scoringService = {
  getStaticScoringData,
  postScoringRule,
  postScoringComment,
  generateSamAppreciation
}

