import React, {useContext, useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {activitiesService} from "@data/services/activitiesService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import * as yup from "yup";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {hasRole} from "@data/utility/ability";
import {useRequestsBatch} from "@common/hooks/useRequestsBatch";
import MaterialPicker from "../../material/MaterialPicker";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import ActivityPicker, {activitySlugsObjectives, materialLabel} from "./ActivityPicker";
import {useNewTodo} from "@common/messenger/socketHook";
import {validation} from "@data/utility/validation";
import SelectChapterScreen from "@common/chapters/SelectChapterScreen";


const NewTodoScreen = (props) => {

    const todoId = props.route.params?.todoId ?? null
    const studentId = props.route.params?.studentId ?? null
    const studyProgramId = props.route.params?.studyProgramId ?? null

    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const forStudents = props.route.params?.forStudents === "true" || props.route.params?.forStudents
    const defaultValues = {}
    const currentSubject = props.schoolSubjects?.find(s => s?.id === formValues?.school_subject_id) ?? props.schoolSubjects?.find(s => s?.id === formValues?.chapter?.school_subject_id)
    const currentActivityTemplate = props.activityTemplates?.find(s => s?.id === formValues?.activity_template_id)

    const liveSession = props.currentLiveSession
    // console.log("liveSession", liveSession)

    const studyProgram = props.studyPrograms?.find(x => x?.id === studyProgramId);

    const studentsList = props.isLiveMode ? liveSession?.users?.filter(user => !user.is_tutor) : studyProgram ? studyProgram?.students : props.user?.students
    const student = (studentId && studentId !== "null") ? findItemInListWithId(studentId, studentsList) : props.user

    if (!student) return null

    const todoActivities = student?.todo_activities

    // console.log("todoActivities", todoActivities)

    const dimensions = useContext(DimensionsContext)

    //update to do
    const todo = findItemInListWithId(todoId, todoActivities)
    const schoolSubject = todo?.school_subject_id ?? ""
    const activityType = todo?.type ? todo?.type : ""
    const activityTemplate = todo?.activity_template_id ?? ""
    const duration = todo?.estimated_duration ?? 10
    const estimatedDurations = () => {
        const durationsTab = []
        for (let i = 5; i < 61; i = i + 5) {
            durationsTab.push({
                value: i,
                label: i + " min" + (i === 10 || i === 15 ? " (recommandé)" : "")
            })
        }
        return durationsTab
    }
    const requestApiService = todoId ? activitiesService.updateActivityTodo : hasRole(props.user, 'student') ? activitiesService.activityTodoAsStudent : forStudents ? activitiesService.activityTodoForStudents : activitiesService.activityTodoAsTutor
    const requestRouteParams = requestApiService?.name === "updateActivityTodo" ? {id: todoId} : requestApiService?.name === "activityTodoAsStudent" ? {} : {id: studentId}
    const filesToUpload = formValues?.material_attachments

    // console.log(filesToUpload)
    const newTodoEvent = useNewTodo({
        channel: `presence-messenger.thread.${liveSession?.thread_id}`,
        id: studentId ?? props.user?.id
    })

    const [globalProgress, submitted, startRequests] = useRequestsBatch(
        [],
        filesToUpload,
        "MATERIAL_FORM",
        {
            service: requestApiService,
            data: {
                ...formValues,
                live_session_id: forStudents ? props.liveSessionId : null,
                chapter_id: formValues?.chapter?.id,
                chapter_name: formValues?.chapter?.name,
                chapter_class: formValues?.chapter?.class,
                school_subject_id: formValues?.chapter?.school_subject_id ?? formValues?.school_subject_id,
            },
            tmpFilesKey: "material_attachments",
            tmpFilesFormatter: (attachmentTmp, index) => {
                return attachmentTmp
            },
            additionalData: [],
            params: requestRouteParams,
            onSuccessBeforeNext: (successData) => {
                if (props.isLiveMode) {
                    if (forStudents) {
                        studentsList?.forEach(student => {
                            newTodoEvent(student?.id)
                        })
                    } else {
                        newTodoEvent()
                    }
                }

                if (forStudents) {
                    const congrats = [{
                        congratsType: congratsTypes.GENERIC,
                        congratsData: {
                            title: "Effectué",
                            text: "L'activité commune a bien été ajoutée"
                        },
                    }]
                    navigation.replace("congrats", {
                        congrats
                    })
                } else {
                    const congrats = [{
                        congratsType: congratsTypes.NEW_TODO_CONGRATS,
                        congratsData: {
                            todo: successData,
                            studentId: studentId
                        },
                        hideButton: true
                    }]
                    navigation.replace("congrats", {
                        congrats
                    })
                }

            },
            onError: () => {
            }
        },
        [],
        true
    )

    const formData = [
        {
            label: "Sélectionner les élèves",
            is_masked: !forStudents,
            description: "",
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "student_ids",
            // height: 90,
            // sectionMode: true,
            options: studentsList?.map(x => {
                return {
                    value: x.id,
                    label: x.display_name,
                    avatar: x
                }
            }),
            defaultValue: studentsList?.map(x => x.id),
            validationRule: {...validation.required()}
        },
        {
            label: "Sur quel chapitre souhaites-tu travailler ?",
            is_masked: false,
            description: "",
            type: "custom", // select.single, select.multiple, text, date
            // name: "chapter",
            component: SelectChapterScreen,
            inputs: [
                {
                    name: "chapter",
                    defaultValue: {
                        studentId: studentId && studentId !== "null" && !forStudents ? studentId : null,
                        id: todo?.student_chapter?.chapter?.id ?? null,
                    }
                },
                {
                    name: "school_subject_id",
                    defaultValue: null,
                },
                {
                    name: "class",
                    defaultValue: null,
                },
            ],
        },
        {
            label: "Que souhaites-tu faire" + (formValues?.chapter?.name ? (" sur le chapitre " + formValues?.chapter?.name) : "") + " ?",
            description: "",
            type: "custom", // select.single, select.multiple, text, date
            name: "activity_template_id",
            // height: 90,
            // sectionMode: true,
            options: [
                {
                    title: "Apprendre une leçon",
                    data: props.activityTemplates?.filter(x => (x?.type === "card") && x.is_active)
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.title, description: x?.description}
                        })
                },
                {
                    title: "M’exercer",
                    data: props.activityTemplates?.filter(x => (x?.type === "written_exercise" || x?.type === "oral_exercise") && x.is_active)
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.title, description: x?.description}
                        })
                },
                {
                    title: "Réviser une leçon",
                    data: props.activityTemplates?.filter(x => x?.type === "revision" && x.is_active)
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.title, description: x?.description}
                        })
                },
                {
                    title: "Comprendre une leçon",
                    data: props.activityTemplates?.filter(x => x?.type === "external_resource" && x.is_active)
                        ?.map(x => {
                            return {icon: x?.icon, value: x.id, label: x.title, description: x?.description}
                        })
                }
            ],
            component: ActivityPicker,
            defaultValue: activityTemplate,
            validationRule: yup.string().required()
        },
        {
            is_masked: !!formValues?.chapter?.new || !!formValues?.chapter?.id,
            label: "En quelle matière veux-tu " + activitySlugsObjectives?.[currentActivityTemplate?.slug]?.toLowerCase()?.replace("m'", "t'") + " ?",
            description: "",
            type: "select.single", // select.single, select.multiple, text, date
            name: "school_subject_id",
            options: props.schoolSubjects
                // ?.slice(0, 3)
                ?.map(x => {
                    return {icon: x?.icon, value: x.id, label: x.name}
                }),
            defaultValue: schoolSubject,
            validationRule: yup.string().required()
        },
        {
            label: materialLabel(currentActivityTemplate?.slug, (formValues?.chapter?.name ? "du chapitre " + formValues?.chapter?.name : formValues?.activity_template_id === 8 || formValues?.activity_template_id === 26 ? "de " + currentSubject?.name : "du cours de " + currentSubject?.name)),
            // label: "Ajoute l'exercice à réaliser",
            name: "material_id",
            type: "custom",
            inputs: [
                {
                    name: "material_text",
                    defaultValue: (todo?.material?.text ?? null)
                },
                {
                    name: "material_url",
                    defaultValue: (todo?.material?.url ?? null)
                },
                {
                    name: "material_title",
                    defaultValue: (todo?.material?.title ?? null)
                },
                {
                    name: "material_attachments",
                    defaultValue: (todo?.material?.attachments?.map(x => {
                        return {
                            uri: x,
                            width: IS_WEB ? dimensions?.width * 0.4 : dimensions?.width * 0.6,
                            height: dimensions?.height * 0.4
                        }
                    }) ?? [])
                },
                {
                    name: "material_level",
                    defaultValue: (todo?.material?.level ?? 1)
                }
            ],
            component: MaterialPicker,
            componentProps: {
                manageTodo: true,
            },
            defaultValue: todo?.material?.id ?? null,
        },
        {
            label: "Combien de temps prévois-tu pour faire cette activité ? (au maximum)",
            description: "",
            type: "select.single", // select.single, select.multiple, text, date
            name: "estimated_duration",
            height: 70,
            options: estimatedDurations(),
            defaultValue: duration,
            validationRule: yup.string().required()
        }

    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log("formValues", formValues)

    }, [formValues])

    const onSubmit = data => {

        if (submitted || props.updateTodoRequest) return
        // if (requestApiService?.name === "updateActivityTodo") {
        //     props.apiRequest(requestApiService, requestRouteParams, data)
        //     return
        // }

        // console.log("data", data)
        // console.log("formValues", formValues)

        // setFormValues(data)
        startRequests()

    }

    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={{name: "zoe", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={submitted}
                         previousRouteName={"finished-activities-live"}
                         submittedMessage=""
                         serverError={props.activityTodoAsStudentError || props.updateTodoError || props.activityTodoAsTutorError || props.vaporFileUploadError}
                         circularProgress={formValues?.material_attachments?.length > 0}
                         circularProgressValue={globalProgress}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        updateTodoRequest: state.api.updateActivityTodo?.request,
        updateTodoError: state.api.updateActivityTodo?.error,
        activityTodoAsStudentError: state.api.activityTodoAsStudent?.error,
        activityTodoAsTutorError: state.api.activityTodoAsTutor?.error,
        vaporFileUploadError: state.api.vaporFileUpload?.error,
        schoolSubjects: state.data.staticData?.school_subjects,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        studyPrograms: state.data.studyPrograms.studyPrograms,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewTodoScreen)
