import React, {useContext, useState} from 'react';
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {theme} from "@common/theme/theme";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {tutorsService} from "@data/services/tutorsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {Switch} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {subjectPriorities} from "@data/constants/formConstants";

export const SwitchComponent = ({value, setValue, label, renderAdditionalComponent = () => null}) => {

    const dimensions = useContext(DimensionsContext)

    return <View style={{
        width: dimensions?.width - 40,
        marginTop: 10,
        marginBottom: 10,
        paddingVertical: 20,
        borderWidth: 1,
        borderRadius: theme.roundness,
        borderColor: theme.colors.lightMore,
        backgroundColor: value ? theme.colors.primaryLighter : null
        // marginHorizontal: 20,
    }}>
        <View style={{
            width: dimensions?.width - 40,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: "space-between",
            paddingHorizontal: 10,
            // marginHorizontal: 20,
        }}>
            <TextM
                color={theme.colors.grey}
                wrap
                style={{
                    // marginTop: 10,
                    textAlign: "left",
                    maxWidth: "80%",
                    // marginRight: 10
                }} fontWeight="Bold">
                {label}
            </TextM>
            <Switch color={theme.colors.primary}
                    style={{alignSelf: "center"}}
                    value={value}
                    onValueChange={(val) => {
                        setValue(val)
                    }}/>
        </View>
        {renderAdditionalComponent()}
    </View>
}

const ApplicationPersonalInfos = (props) => {

    const {navigation} = props
    const dimensions = useContext(DimensionsContext)
    const [formValues, setFormValues] = useState(null)
    const defaultValues = {}
    const currentYear = new Date().getFullYear()
    const [abroadBac, setAbroadBac] = useState(false)
    const [isAutoEntrepreneur, setIsAutoEntrepreneur] = useState(false)
    const mentions = [
        {
            value: "Très bien ou plus",
            label: "Très bien ou plus"
        },
        {
            value: "Bien",
            label: "Bien"
        },
        {
            value: "Assez bien",
            label: "Assez bien"
        },
        {
            value: "Pas de mention",
            label: "Pas de mention"
        }
    ]
    const studyLevels = ["BAC", "BAC+1", "BAC+2", "BAC+3", "BAC+4", "BAC+5", "BAC+6 ou plus"]?.map(x => {
        return {value: x, label: x}
    })
    const graduationYears = () => {
        const years = []
        for (let i = currentYear; i >= 1960; i--) {
            years.push({
                value: i,
                label: i
            })
        }
        return years
    }

    const formData = [
        {
            label: "Indique tes coordonnées",
            type: "text.multiple",
            inputs: [
                {
                    name: "firstname",
                    placeholder: "Prénom",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                },
                {
                    name: "lastname",
                    placeholder: "Nom",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                },
                {
                    name: "mobile",
                    placeholder: "Téléphone portable",
                    defaultValue: "",
                    keyboardType: "phone-pad",
                    validationRules: {
                        ...validation.required(),
                        ...validation.phone()
                    }
                }
            ]
        },
        {
            label: "En quelle année as-tu obtenu ton bac ?",
            type: "select.single",
            name: "graduation_year",
            options: graduationYears(),
            defaultValue: "",
            topComponent: <SwitchComponent value={abroadBac}
                                           setValue={setAbroadBac}
                                           label="J'ai un équivalent du baccalauréat passé à l'étranger"/>
        },
        {
            label: "Peux-tu préciser la filière de ton bac ?",
            type: "select.single",
            name: "sector",
            options: [
                {
                    value: "Générale",
                    label: "Générale"
                },
                {
                    value: "Technologique",
                    label: "Technologique"
                }],
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        // {
        //     label: "Peux-tu préciser tes matières de prédilection ?",
        // name: "speciality",
        // type: "select.multiple",
        // options: props.schoolSubjects?.filter(ss => ss?.is_specialty)?.map(x => {
        //     return {icon: x?.icon, value: x.id, label: x.name}
        // }),
        // defaultValue: "",
        // validationRules: {...validation.required()}
        // },
        {
            label: "Quelles sont tes matières de prédilection ?",
            name: "relevant_subjects",
            type: "select.multiple",
            options: subjectPriorities,
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Quelle mention as-tu as obtenue ?",
            type: "select.single",
            name: "mention",
            options: mentions,
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "En première, quelle note as-tu obtenu à l'écrit du bac de français ?",
            type: "text.multiple",
            inputs: [
                {
                    name: "writing_french_score",
                    placeholder: "Note à l'écrit",
                    keyBoardType: "decimal-pad",
                    inputMode: "decimal",
                    defaultValue: "",
                    validationRules: {
                        ...validation.required(),
                        ...validation.number(),
                        ...validation.min(0),
                        ...validation.max(20)
                    }
                },
                {
                    name: "oral_french_score",
                    label: "Et à l'oral ?",
                    placeholder: "Note à l'oral",
                    // keyBoardType: "decimal-pad",
                    inputMode: "decimal",
                    defaultValue: "",
                    validationRules: {
                        ...validation.required(),
                        ...validation.number(),
                        ...validation.min(0),
                        ...validation.max(20)
                    }
                }
            ],
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Quel est ton niveau d'étude ?",
            type: "select.single",
            name: "study_level",
            options: studyLevels,
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Quel est ton établissement d'étude ?",
            type: "text.multiple",
            inputs: [
                {
                    name: "study_institution",
                    placeholder: "Université Paris-Saclay",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                },
                {
                    name: "study_field",
                    label: "Pour quelle formation ?",
                    placeholder: "Licence de commerce international",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                }
            ],
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Si tu es déjà auto-entrepreneur, indique ton SIRET",
            type: "text.multiple",
            inputs: [
                {
                    name: "num_SIRET",
                    placeholder: "Numéro SIRET (14 chiffres)",
                    defaultValue: "",
                    hidden: !isAutoEntrepreneur
                }
            ],
            defaultValue: "",
            topComponent: <SwitchComponent value={isAutoEntrepreneur}
                                           setValue={setIsAutoEntrepreneur}
                                           label="J'ai déjà le statut auto-entrepreneur"/>
        }
    ]

    const onSubmit = data => {
        if (props.submitPersonalInfosRequest) return
        // console.log(data)
        props.apiRequest(tutorsService.submitPersonalInfos, {}, {
            firstname: data.firstname,
            lastname: data.lastname,
            mobile: data.mobile,
            study_institution: data.study_institution,
            bac_infos: {
                graduation_year: data.graduation_year,
                abroad_bac: abroadBac,
                sector: data.sector,
                // speciality: data.speciality?.join(", "),
                mention: data.mention,
                writing_french_score: data.writing_french_score,
                oral_french_score: data.oral_french_score
            },
            relevant_subjects: data.relevant_subjects,
            availability_starting_date: data.availability_starting_date,
            availability_slots: data.availability_slots,
            study_level: data.study_level,
            study_field: data.study_field,
            num_SIRET: isAutoEntrepreneur ? data?.num_SIRET : null
        })
    }

    useApiStatus(
        tutorsService.submitPersonalInfos, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            const congrats = [{
                congratsType: congratsTypes.APPLICATION_STEP_CONGRATS,
                congratsData: {
                    champion: "abou",
                    text: "Bravo tu as fini cette première étape ! Tu vas pouvoir passer à l'entretien de Tina"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )

    return <View style={{
        flex: 1,
        // backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center'
    }}>

        <FormStepper navigation={navigation}
                     track
                     formData={formData?.filter(x => !x.is_masked)}
                     champion={null}
                     formValues={formValues}
                     setFormValues={setFormValues}
                     defaultValues={defaultValues}
                     onSubmit={onSubmit}
                     submitted={props.submitPersonalInfosRequest}
                     previousRouteName={"menu"}
                     submittedMessage=""
                     serverError={props.submitPersonalInfosError}
        />
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}

const mapStateToProps = state => {
    return {
        submitPersonalInfosSuccess: state.api.submitPersonalInfos?.success,
        submitPersonalInfosRequest: state.api.submitPersonalInfos?.request,
        submitPersonalInfosError: state.api.submitPersonalInfos?.error,
        schoolSubjects: state.data.staticData?.school_subjects
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPersonalInfos)