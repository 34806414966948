import React, {useEffect, useState} from 'react';
import {Keyboard, StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {IS_WEB} from "@common/utils/mobileUtils";
import {validation} from "@data/utility/validation";
import {authService} from "@data/services/authService";
import {Text} from "react-native-paper";
import LinkTextM from "@common/components/text/LinkTextM";
import * as amplitude from "@amplitude/analytics-react-native";
import * as Device from "expo-device";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";


const TutorRegisterScreen = (props) => {

    // ** Component props
    const {navigation} = props


    const [formValues, setFormValues] = useState(null)
    // const currentUserProfile = formValues?.user_profile
    const currentEmail = formValues?.email
    const currentStudentClass = formValues?.student_class
    const currentDisplayName = formValues?.firstname + " " + formValues?.lastname?.substring(0, 1) + "."
    const isDisplayNameEmpty = (formValues?.firstname === "") || (formValues?.lastname === "")


    const formData = [
        {
            label: "Comment as-tu entendu parler de Masteur ?",
            type: "select.single",
            name: "channel",
            options: [
                {value: "Famille/amis", label: "Famille/amis"},
                {value: "Facebook/Instagram", label: "Facebook/Instagram"},
                {value: "Recherche Google", label: "Recherche Google"},
                {value: "App Store ou Play Store", label: "App Store ou Play Store"},
                {value: "Actualités/article/blog", label: "Actualités/article/blog"},
                {value: "Autre", label: "Autre"}
            ],
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Comment t'appelles-tu ?",
            type: "text.multiple",
            indication: isDisplayNameEmpty ? "" : 'Tu apparaîtras sous le nom "' + currentDisplayName + '" sur ton profil Masteur.',
            inputs: [
                {
                    name: "firstname",
                    placeholder: "Prénom",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                },
                {
                    name: "lastname",
                    placeholder: "Nom",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                }
            ]
        },
        {
            label: "Indique tes coordonnées",
            type: "text.multiple",
            indication: "Tu devras confirmer l'adresse email par la suite.",
            inputs: [
                {
                    name: "email",
                    placeholder: "Adresse email",
                    keyboardType: "email-address",
                    defaultValue: "",
                    liveCheck: {service: authService.checkEmailAddress},
                    liveCheckPreRequest: true,
                    validationRules: {
                        ...validation.required(),
                        ...validation.email()
                    }
                },
                {
                    name: "mobile",
                    placeholder: "Téléphone portable",
                    defaultValue: "",
                    keyboardType: "phone-pad",
                    validationRules: {
                        ...validation.required(),
                        ...validation.phone()
                    }
                }
            ]
        },
        {
            label: "Choisis ton mot de passe",
            type: "text.multiple",
            inputs: [

                {
                    name: "password",
                    placeholder: "Mot de passe",
                    password: true,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required(),
                        ...validation.minLength(8)
                    }
                }
            ],
            bottomComponent: <Text style={{
                fontSize: 12,
                textAlign: 'center',
                marginTop: IS_WEB ? 10 : 0,
                // marginBottom: 10
            }}>
                En t'inscrivant, tu acceptes les <LinkTextM url="https://masteur.com/cgu">Conditions générales
                d'utilisation de Masteur</LinkTextM>
            </Text>
        }
    ]

    const defaultValues = {}
    // const formSchema = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log(defaultValues)

    }, [formValues])

    useEffect(() => {
        if (props.registered) {
            props.apiIdle(authService.registerAndGetSanctumToken)
            // console.log(props.postChallengeSuccessData)
            // navigation.replace("", {
            //
            // })
        }

        if (props.error) {
            // console.log(props.error)
        }

    }, [props.registered, props.error])


    const onSubmit = data => {
        if (props.registering) return
        Keyboard.dismiss()
        // console.log(data)
        props.apiRequestWithPrerequest(authService.registerAndGetSanctumToken, {}, {
            ...data,
            'user_profile': 'tutor',
            'device_name': Device.deviceName ?? IS_WEB ? "WEB" : "emu"
        })
    }


    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.registering}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.error}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        registered: state.api.registerAndGetSanctumToken?.success,
        registering: state.api.registerAndGetSanctumToken?.request,
        error: state.api.registerAndGetSanctumToken?.error,

        getSchoolSubjectsRequest: state.api.getSchoolSubjects?.request,
        getSchoolSubjectsSuccess: state.api.getSchoolSubjects?.success,
        getSchoolSubjectsError: state.api.getSchoolSubjects?.error,

        schoolSubjects: state.data.staticData?.school_subjects
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiRequestWithPrerequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(TutorRegisterScreen)