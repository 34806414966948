import React, {useContext, useEffect, useRef, useState} from "react";
import {AppState, Platform, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {useController} from "react-hook-form";
import {addTimestampToField} from "@data/utility/Utils";
import {getElapsedTime, recordStartTime} from "@data/utility/asyncStorageUtils";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import * as Network from "expo-network";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useInterval} from "@common/utils/useInterval";
import {OfflineModeBottomSheetModal} from "./OfflineModeBottomSheetModal";
import {connect} from "react-redux";
import {
    activityOfflineModeInvalid,
    activityStartedWithOfflineMode,
    setIsInternetReachable,
    setIsOfflineModeEnabled,
    timerStepStarted
} from "@data/redux/actions/offlineMode";
import {ModalM} from "@common/components/modal/ModalM";
import {ChampionImageM} from "../../champions/ChampionImageM";
import {useNavigation} from "@react-navigation/native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import MaterialModal from "./MaterialDisplayModal";
import {definedMaterialSlug, undefinedMaterialSlug} from "../todo/ActivityPicker";
import {pauseActivity} from "@data/redux/actions/activity";
import OneStepActivityHeader from "../one-step/OneStepActivityHeader";
import LottieView from "lottie-react-native";
import ChampionBubble from "../../champions/ChampionBubble";
import CircularProgress from "react-native-circular-progress-indicator/src/circularProgress";
import {ActivityIndicator} from "react-native-paper";
import {activitiesService} from "@data/services/activitiesService";

const TimerActivityStep = (props) => {
    // ** Component props
    const {
        stepData,
        stepIndex,
        control,
        status,
        statusRef,
        setStatus,
        chronoMode,
        finishStep,
        activity,
        setChronoStepFinishedComment = () => {
        },
        animationFinished,
        setAnimationFinished,
        submitted,
        globalProgress,
        submittedMessage,
        stepDescriptions,
        handleShowMyWorkModal
    } = props;

    const navigation = useNavigation();
    const [isOpen, setOpen] = useState(false);
    const handleShowModal = () => setOpen(!isOpen);
    const [showLottie, setShowLottie] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLottie(true);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    // console.log("activity", activity)
    // console.log("activity?.material", activity?.material)

    const activityMaterial = activity?.material;
    // const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)
    // //console.log(activityTemplate)
    //
    // const images = activityMaterial?.attachments?.map((file) => {
    //     return {
    //         uri: file,
    //         width: 200,
    //         height: 200
    //     }
    // })

    // const activityTemplate = props.activityTemplates.find((template) => template.id === activity?.activity_template_id)
    // console.log("activityslug", activity?.slug)
    const dMaterialSlug = definedMaterialSlug(activity?.slug);
    const uMaterialSlug = undefinedMaterialSlug(activity?.slug);

    const td = !chronoMode ? stepData?.timer_duration : activity?.duration / 60; // in minutes
    // const td = 0.05 // in minutes

    // ** Time elapsed in seconds
    const [time, _setTime] = useState(td * 60);
    const timeRef = React.useRef(time); // time ref is used to make use of state within app state event listener
    const setTime = (data) => {
        _setTime(data);
        timeRef.current = time;
    };

    const bottomSheetRef = useRef();

    const isOffline = props.isOfflineModeEnabled && !props.isInternetReachable;

    const onStart = () => {
        bottomSheetRef.current?.dismiss();

        setStatus("ongoing");
        // recordStartTime()

        if (isOffline) props.activityStartedWithOfflineMode();
    };

    // ** App state to detect if the app goes to background
    const appState = useRef(AppState.currentState);

    // ** Form field
    const {field} = useController({
        control,
        name: "activity_logs"
    });

    useEffect(() => {
        const stepFinishedData = field?.value
            ? field?.value?.find(
                (log) =>
                    log?.action === "step_finished" && log?.step === stepIndex + 1
            )
            : null;

        if (!stepFinishedData) {
            // update field to be sent to server
            if (field?.value)
                addTimestampToField(field, stepIndex + 1, "step_started");

            props.timerStepStarted();

            // add event listener in case the app goes to background
            const sub = AppState.addEventListener("change", handleAppStateChange);
            return () => sub.remove();
        } else {
            setStatus("finished");
            setTime(chronoMode ? stepFinishedData?.step_data?.chrono_duration : 0);
        }
    }, []);

    useEffect(() => {
        if (
            status === "finished" &&
            chronoMode &&
            time > activity?.estimated_duration * 60 * 1.05
        ) {
            // we allow 5% more time than estimated
            // if timer is more than estimated duration
            setChronoStepFinishedComment(
                "Tu as dépassé la durée maximum prévue pour cette activité"
            );
        }
    }, [status]);

    useEffect(() => {
        if (
            props.startedWithOfflineMode &&
            props.isOfflineModeValid &&
            !isOffline &&
            (status === "ongoing" || status === "paused")
        ) {
            props.activityOfflineModeInvalid();
            showModal();
        }
    }, [isOffline]);

    const checkAirplaneMode = async () => {
        const isAirplaneModeEnabled =
            Platform.OS === "ios" ? true : await Network.isAirplaneModeEnabledAsync();
        if (isAirplaneModeEnabled !== props.isOfflineModeEnabled)
            props.setIsOfflineModeEnabled(isAirplaneModeEnabled);

        try {
            const networkState = await Network.getNetworkStateAsync();
            // console.warn(networkState);

            if (networkState.isInternetReachable !== props.isInternetReachable)
                props.setIsInternetReachable(networkState.isInternetReachable);
        } catch (err) {
            // console.warn(err);

            if (props.isInternetReachable) props.setIsInternetReachable(false);
        }
    };

    // ** custom setInterval handler compatible with hooks (cf https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
    useInterval(() => {
        if (Platform.OS !== "web" && !props.isLiveModeEnabled && props.user?.xp > 300) checkAirplaneMode();
    }, 1000);

    const handleAppStateChange = async (nextAppState) => {
        if (
            appState?.current === "active" &&
            nextAppState.match(/inactive|background/) &&
            statusRef?.current === "ongoing"
        ) {
            recordStartTime();
        }

        if (
            appState?.current.match(/inactive|background/) &&
            nextAppState === "active" &&
            statusRef?.current === "ongoing"
        ) {
            // We just became active again: recalculate elapsed time based
            // on what we stored in AsyncStorage when we started.
            const elapsed = await getElapsedTime();
            // Update the elapsed seconds state
            if (chronoMode) setTime(timeRef.current + elapsed);
            else
                setTime(timeRef.current - elapsed <= 0 ? 1 : timeRef.current - elapsed);
        }
        appState.current = nextAppState;
    };

    useEffect(() => {
        if (!chronoMode && time === 0) {
            finishStep();
            // update field to be sent to server
            addTimestampToField(field, stepIndex + 1, "step_finished", {
                chrono_duration: td * 60,
                offline_mode_bonus:
                    props.startedWithOfflineMode && props.isOfflineModeValid
            });
        }
    }, [time]);

    const [visible, setVisible] = React.useState(false);
    const showModal = () => {
        return setVisible(true);
    };
    const hideModal = () => {
        return setVisible(false);
    };
    const dimensions = useContext(DimensionsContext);

    const startChrono = () => {
        if (Platform.OS !== "web" && !props.isLiveModeEnabled && props.user?.xp > 300) {
            bottomSheetRef.current?.present();
        } else setStatus("ongoing");
    };
    useEffect(() => {
        if (props.activityPaused) props.pauseActivity(false);
    }, []);

    // ** Update chrono every 30 seconds
    useInterval(() => {
        props.apiRequest(
            activitiesService.updateChrono,
            {id: activity?.id},
            {
                chrono_duration: timeRef.current,
                live_mode: !!props.liveSessionId,
                live_session_id: props.liveSessionId ?? undefined
            }
        );
    }, 30000);

    return (
        <View
            style={{
                flex: 1,
                width: dimensions?.width,
                // paddingTop: 4,
                alignItems: "center",
                alignContent: "flex-start",
                justifyContent: "flex-start",
                paddingHorizontal: 15
            }}
        >
            <OneStepActivityHeader
                activity={activity}
                chronoMode={chronoMode}
                liveMode={props.isLiveModeEnabled}
                time={time}
                estimatedDuration={activity?.estimated_duration * 60}
                timerDuration={td}
                field={field}
                recordStartTime={recordStartTime}
                setTime={setTime}
                isOffline={isOffline}
                setStartedWithOfflineMode={props.setStartedWithOfflineMode}
                startChrono={startChrono}
                status={status}
                setStatus={setStatus}
                animationFinished={animationFinished}
                setAnimationFinished={setAnimationFinished}
                activityPaused={props.activityPaused}
                handleShowMyWorkModal={handleShowMyWorkModal}
            />
            {!animationFinished && (
                <View
                    style={{
                        flex: 1,
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    {showLottie ? (
                        <LottieView
                            autoPlay
                            speed={1}
                            loop={false}
                            pointerEvents="none"
                            style={{
                                width: "100%",
                                alignSelf: "center"
                                // height: WINDOW_HEIGHT,
                                // backgroundColor: '#eee',
                            }}
                            onAnimationFinish={() => {
                                setAnimationFinished(true);
                                startChrono();
                            }}
                            source={require("../../../../assets/animations/Countdown.json")}
                        />
                    ) : (
                        <TextM
                            fontWeight={"ExtraBold"}
                            fontSize={24}
                            color={theme.colors.primary}
                            style={{textAlign: "center"}}
                            wrap>
                            {timeRef?.current === 0
                                ? "Ton activité va commencer..."
                                : "Ton activité va reprendre..."}
                        </TextM>
                    )}
                </View>
            )}
            {((status === "finished" &&
                    props.startedWithOfflineMode &&
                    props.isOfflineModeValid) ||
                (isOffline &&
                    props.startedWithOfflineMode &&
                    props.isOfflineModeValid &&
                    status !== null)) && (
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingVertical: 5,
                        marginVertical: 5,
                        width: dimensions?.width,
                        backgroundColor: theme.colors.purple
                    }}
                >
                    <FontAwesome5 name="plane" size={15} color={theme.colors.white}/>
                    <TextM
                        fontWeight="Medium"
                        fontSize={14}
                        wrap
                        style={{marginLeft: 5}}
                        color={theme.colors.white}
                    >
                        Bonus Hors-ligne activé
                    </TextM>
                </View>
            )}
            {submitted ? (
                <View
                    style={{
                        flex: 1,
                        // backgroundColor: '#fff',
                        alignItems: "center",
                        justifyContent: "center"
                        // paddingBottom: 100
                    }}
                >
                    {globalProgress ? (
                        IS_WEB ? (
                            <TextM color={theme.colors.success} fontWeight={"Bold"}>
                                {globalProgress + "%"}
                            </TextM>
                        ) : (
                            <CircularProgress
                                title={globalProgress + "%"}
                                value={globalProgress}
                                radius={40}
                                activeStrokeColor={theme.colors.success}
                                inActiveStrokeColor={theme.colors.success}
                                activeStrokeWidth={10}
                                inActiveStrokeWidth={10}
                                inActiveStrokeOpacity={0.1}
                                textColor={theme.colors.success}
                                titleStyle={{fontFamily: "Montserrat-Bold"}}
                                titleFontSize={16}
                                // titleFontSize={WINDOW_WIDTH*6/36}
                                showProgressValue={false}
                            />
                        )
                    ) : (
                        <ActivityIndicator
                            animating={true}
                            style={{
                                alignSelf: "center",
                                justifySelf: "center",
                                marginTop: IS_WEB ? 50 : -50,
                                marginBottom: 10
                            }}
                            size="large"
                            color={theme.colors.primary}
                        />
                    )}
                    <TextM
                        fontWeight="Bold"
                        color={theme.colors.grey}
                        style={{
                            marginTop: 10
                        }}
                        fontSize={22}
                    >
                        Chargement en cours...
                    </TextM>
                    {!!submittedMessage && submittedMessage !== "" && (
                        <TextM
                            fontWeight="Regular"
                            color={theme.colors.light}
                            style={{
                                marginTop: 5
                            }}
                            fontSize={16}
                        >
                            {submittedMessage}
                        </TextM>
                    )}
                </View>
            ) : (
                <>
                    {animationFinished && !activityMaterial && (
                        <ChampionBubble
                            name={"abou"}
                            variant={"main"}
                            championPosition="left"
                            texts={[stepDescriptions[0]]}
                            style={{}}
                        />
                    )}
                    {/*<ActivityStatusChampion stepData={stepData} status={status} />*/}
                    {activityMaterial && animationFinished ? (
                        <MaterialModal
                            handleShowModal={handleShowModal}
                            isOpen={isOpen}
                            material={activityMaterial}
                            time={time}
                            estimatedDuration={activity?.estimated_duration * 60}
                            startChrono={startChrono}
                            noModal
                            status={status}
                        />
                    ) : animationFinished ? (
                        <View
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                marginTop: 20
                            }}
                        >
                            {/*<IconButton*/}
                            {/*    icon={"plus"}*/}
                            {/*    iconColor={theme.colors.primary}*/}
                            {/*    size={30}*/}
                            {/*    onPress={() => {*/}
                            {/*        navigation.push("new-material", {*/}
                            {/*            activityId: activity?.id*/}
                            {/*        })*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <FilledButtonM
                                mode={"text"}
                                label={"Ajouter " + uMaterialSlug}
                                color={theme.colors.primary}
                                onPress={() => {
                                    navigation.push("new-material", {
                                        activityId: activity?.id
                                    });
                                }}
                                icon={"plus"}
                                contentStyle={{
                                    flexDirection: "column",
                                    marginTop: 10
                                }}
                            />
                        </View>
                    ) : null}

                    <ModalM visible={visible} onDismiss={hideModal}>
                        <View
                            style={{
                                maxHeight: (dimensions?.width * 2) / 3,
                                alignItems: "center",
                                paddingHorizontal: 15,
                                paddingTop: 4,
                                paddingBottom: 20
                            }}
                        >
                            <ChampionImageM name="abou" variant={"unhappy"} width={140}/>

                            <TextM
                                fontWeight="Bold"
                                fontSize={18}
                                wrap
                                style={{
                                    paddingHorizontal: 20,
                                    paddingTop: 20,
                                    textAlign: "center"
                                }}
                                color={theme.colors.purple}
                            >
                                Mode Hors-ligne désactivé
                            </TextM>

                            <TextM
                                fontWeight="Regular"
                                fontSize={16}
                                wrap
                                style={{
                                    paddingHorizontal: 20,
                                    paddingTop: 5,
                                    textAlign: "center"
                                }}
                                color={theme.colors.grey}
                            >
                                Tu as perdu ton bonus XP
                            </TextM>

                            <FilledButtonM
                                onPress={hideModal}
                                label="OK"
                                color={theme.colors.purple}
                                textColor={theme.colors.white}
                                style={{
                                    width: "100%",
                                    marginTop: 20,
                                    alignSelf: "center"
                                }}
                            />
                        </View>
                    </ModalM>
                    {/*
            {chronoMode && status && status !== 'finished' && <FilledButtonM color={theme.colors.success}
                                                                             onPress={() => {
                                                                                 finishStep()
                                                                                 addTimestampToField(field, stepIndex + 1, "step_finished", {
                                                                                     chrono_duration: time,
                                                                                     offline_mode_bonus: (props.startedWithOfflineMode && props.isOfflineModeValid),
                                                                                     live_mode_bonus: (props.isLiveModeEnabled)
                                                                                 })
                                                                             }}
                                                                             style={{
                                                                                 width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,
                                                                                 position: "absolute",
                                                                                 alignSelf: "center",
                                                                                 bottom: Platform.OS === 'ios' ? 20 : 5
                                                                             }}
                                                                             label="J'ai terminé cette étape"/>}*/}

                    {Platform.OS !== "web" && !props.isLiveModeEnabled && props.user?.xp > 300 && (
                        <OfflineModeBottomSheetModal
                            ref={bottomSheetRef}
                            onPressButton1={onStart}
                            onPressButton2={onStart}
                            isOffline={isOffline}
                        />
                    )}
                </>
            )}
            <MaterialModal
                handleShowModal={handleShowModal}
                isOpen={isOpen}
                material={activityMaterial}
                time={time}
                estimatedDuration={activity?.estimated_duration * 60}
                startChrono={startChrono}
                status={status}
            />
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        isOfflineModeValid: state.offlineMode.isActivityOfflineModeValid,
        isLiveModeEnabled: state.liveMode.isLiveModeEnabled,
        startedWithOfflineMode: state.offlineMode.activityStartedWithOfflineMode,
        isInternetReachable: state.offlineMode.isInternetReachable,
        isOfflineModeEnabled: state.offlineMode.isOfflineModeEnabled,
        timerActivityStepOngoing: state.offlineMode?.timerStepOngoing,
        activityTemplates: state.data.staticData?.activity_templates,
        activityPaused: state.activity?.activityPaused,
        liveSessionId: state.liveMode?.liveSessionId,
        chrono: state.data.currentUser?.object?.chrono,
        user: state.data.currentUser?.object,
    };
};

const mapDispatchToProps = {
    activityStartedWithOfflineMode,
    activityOfflineModeInvalid,
    setIsInternetReachable,
    setIsOfflineModeEnabled,
    timerStepStarted,
    apiRequest,
    apiIdle,
    pauseActivity
};

export default connect(mapStateToProps, mapDispatchToProps)(TimerActivityStep);
