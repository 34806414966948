import React, {useState} from "react";
import {weekDaysObject} from "@data/constants/activitiesConstants";
import {ScrollView, TouchableOpacity, View} from "react-native";
import {SwitchComponent} from "../application/personalInfos/ApplicationPersonalInfos";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {padToTwo} from "@data/utility/Utils";
import {SelectModalM} from "@common/components/select/selectSingle-Multiple";
import {IS_WEB} from "@common/utils/mobileUtils";

export const TutorSlotsPicker = (props) => {

    const {control, goToNextStep, register, errors, serverError, setFormValues, field, trigger} = props

    const [visible, setVisible] = useState(false)
    const [timePickerDay, setTimePickerDay] = useState(null)
    const [time, setTime] = useState("17:00")

    // console.log("field.value", field.value)
    const weekDays = Object.keys(weekDaysObject)

    const handleShowModal = () => {
        setVisible(!visible)
    }

    // all :00 and :30 times, starting from 14:00 to 19:30
    const options = Array.from({length: 12}, (v, i) => {
        const hour = Math.floor(i / 2) + 14
        const minute = i % 2 === 0 ? "00" : "30"
        return padToTwo(hour) + ":" + padToTwo(minute)
    }).map(x => ({label: x, value: x}))

    return <ScrollView style={{}}>
        {weekDays?.filter((x, index) => index < weekDays?.length - 2).map(weekDay => {

            const isDaySelected = field.value?.filter(x => x?.split(" ")[0] === weekDay).length > 0
            const daySelectedTime = field.value?.find(x => x?.split(" ")[0] === weekDay)?.split(" ")[1]

            return <SwitchComponent key={weekDay}
                                    value={isDaySelected}
                                    setValue={(value) => {
                                        if (value) {
                                            // console.log([...field.value, weekDay + " " + time])

                                            field.onChange([...field.value, weekDay + " " + time])
                                            setFormValues({
                                                ...control._formValues,
                                                availability_slots: [...field.value, weekDay + " " + time]
                                            })
                                            setTimePickerDay(weekDay)
                                            handleShowModal()
                                        } else {
                                            const newValues = [...field.value]
                                            newValues.splice(newValues.indexOf(weekDay + " " + time), 1)
                                            field.onChange(newValues)
                                            setFormValues({...control._formValues, availability_slots: newValues})
                                            // console.log("field.value", newValues)
                                        }
                                    }}
                                    label={weekDaysObject[weekDay]}
                                    renderAdditionalComponent={() => isDaySelected && <TouchableOpacity onPress={() => {
                                        setTimePickerDay(weekDay)
                                        handleShowModal()
                                    }}
                                    >
                                        <View style={{
                                            paddingHorizontal: 10,
                                            width: "100%"
                                        }}>
                                            <TextM color={theme.colors.grey}>
                                                à partir de <TextM fontWeight={"Bold"}
                                                                   style={{
                                                                       // backgroundColor: theme.colors.successLight,
                                                                       // paddingHorizontal: 3,
                                                                       // borderRadius: 3
                                                                       textDecorationLine: "underline"
                                                                   }}
                                                                   color={theme.colors.link}>{daySelectedTime}</TextM>
                                            </TextM>
                                        </View>
                                    </TouchableOpacity>}
            />
        })}

        {visible && <SelectModalM isMulti={false}
                                  modalVisible={visible}
                                  setModalVisible={handleShowModal}
                                  title={`${weekDaysObject?.[timePickerDay] ?? "Lundi"} à partir de`}
                                  selectedValue={time ?? "17:00"}
                                  options={options}
                                  valueMode={true}
                                  onSingleSelect={(value) => {
                                      handleShowModal()
                                      setTime(value.value)
                                      const newValues = field.value?.map(x => x?.split(" ")[0] === timePickerDay ? timePickerDay + " " + value.value : x)
                                      field.onChange(newValues)
                                      // console.log("field.value", newValues)
                                  }}
                                  itemHeight={50}

        />}
        {/*<TimePickerModal*/}
        {/*    label={`${weekDaysObject?.[timePickerDay] ?? "Lundi"} à partir de`}*/}
        {/*    hours={time ? time?.split(":")[0] : 17}*/}
        {/*    minutes={time ? time?.split(":")[1] : 0}*/}
        {/*    cancelLabel={"Annuler"}*/}
        {/*    confirmLabel={"Confirmer"}*/}
        {/*    keyboardIcon={'keyboard'}*/}
        {/*    clockIcon={'clock'}*/}
        {/*    locale={"fr"}*/}
        {/*    visible={visible}*/}
        {/*    use24HourClock*/}
        {/*    onDismiss={() => {*/}
        {/*        handleShowModal()*/}
        {/*        // console.log("field.value", field.value)*/}
        {/*    }}*/}
        {/*    onConfirm={(e) => {*/}
        {/*        handleShowModal()*/}
        {/*        setTime(e?.hours + ":" + e?.minutes)*/}
        {/*        const newValues = field.value?.map(x => x?.split(" ")[0] === timePickerDay ? timePickerDay + " " + padToTwo(e?.hours) + ":" + padToTwo(e?.minutes) : x)*/}
        {/*        field.onChange(newValues)*/}
        {/*        // console.log("field.value", newValues)*/}
        {/*    }}*/}
        {/*/>*/}
    </ScrollView>
}