import React, {useContext} from 'react';
import {Keyboard, Platform, View} from "react-native";
import {IconImageM} from "@common/components/icons/IconsM";
import logo from "../../assets/logo-blue.png"
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import TextInputM from "@common/components/input/TextInputM";
import {useForm} from "react-hook-form";
import {apiIdle, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import yup from "@data/utility/validation"
import {authService} from "@data/services/authService";
import {yupResolver} from "@hookform/resolvers/yup";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import ErrorTextM from "@common/components/text/ErrorTextM";
import {boxedCardBorderStyle} from "../reviewer/BoxedCardBorderStyle";

const formSchema = yup.object().shape({
    email: yup.string().email().required()
})
const ForgotPassword = (props) => {
    const resolver = yupResolver(formSchema);
    const {navigation} = props
    const dimensions = useContext(DimensionsContext)
    const {control, handleSubmit, formState: {errors, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            email: '',
        }
    })

    const onSubmit = (data) => {
        if (props.isSendingEmail) return
        Keyboard.dismiss()
        props.apiRequestWithPrerequest(authService.sendResetPasswordLink, {}, {email: data.email})
    }

    useApiStatus(
        authService.sendResetPasswordLink, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        () => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Nous t'avons envoyé par email le lien de réinitialisation du mot de passe !"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )
    return (
        <View style={{
            alignItems: 'center',
            flex: 1,
            padding: 40,

        }}>
            <View style={{
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderColor: theme.colors.lightMore,
                backgroundColor: theme.colors.white,
                padding: 20,
                ...boxedCardBorderStyle
            }}>
                {/*<IconImageM source={logo}*/}
                {/*            style={{marginVertical: 20, alignSelf: 'center'}}*/}
                {/*            width={150}*/}
                {/*            height={150 * 339 / 1024}*/}
                {/*/>*/}
                <TextM fontWeight={"Bold"}
                       fontSize={20}
                       color={theme.colors.grey}
                       style={{marginVertical: 10}}>
                    Mot de passe oublié ?
                </TextM>
                <TextM wrap>
                    Entre l'adresse email avec laquelle tu t'es inscrit. Nous allons t'envoyer un email avec un lien
                    pour réinitialiser ton mot de passe.
                </TextM>
                <TextInputM
                    placeholder="Adresse email"
                    name="email"
                    textContentType="emailAddress"
                    keyboardType="email-address"
                    control={control}
                    errors={errors}
                    style={{
                        marginTop: 10,
                        width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 2,
                    }}
                />
                <ErrorTextM errorData={props.sendEmailError} style={{textAlign: "left"}}/>
                <FilledButtonM onPress={handleSubmit(onSubmit)}
                               label="Envoyer"
                               style={{
                                   marginTop: 20
                               }}
                               loading={props.isSendingEmail}
                />
            </View>

            {IS_WEB && <FilledButtonM mode={"text"}
                                      icon={"chevron-left"}
                                      color={theme.colors.link}
                                      style={{marginTop: 60}}
                                      onPress={() => {
                                          if (navigation.canGoBack())
                                              navigation.goBack()
                                          else
                                              navigation.replace("menu")
                                      }}
                                      label={"Retour"}/>}
        </View>
    );
}
const mapStateToProps = state => {
    return {
        isSendingEmail: state.api.sendResetPasswordLink?.request,
        sendSuccess: state.api.sendResetPasswordLink?.success,
        sendEmailError: state.api.sendResetPasswordLink?.error
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)