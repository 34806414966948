import {apiConstants} from "@data/constants/apiConstants"
import {liveReportService} from "@data/services/liveReportService";

// ** Initial State
const initialState = {
    liveReports: []
}

export function liveReports(state = initialState, action) {
    switch (action.type) {
        case liveReportService.getStudentLiveReports.name + apiConstants._SUCCESS:
            let liveReports = []
            if (action.data?.meta?.current_page === 1) {
                liveReports = action.data?.live_reports
            } else {
                liveReports = state.liveReports?.concat(action.data?.live_reports)
            }
            return {
                ...state,
                liveReports: liveReports
            }

        default:
            return state
    }
}

export default liveReports
