import {BackHandler, ScrollView, View} from "react-native";
import React, {useContext, useEffect, useState} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {authService} from "@data/services/authService";
import {theme} from "@common/theme/theme";
import {Button, List, Switch} from "react-native-paper";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {weekDays, weekDaysObject} from "@data/constants/activitiesConstants";
import moment from "moment";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {ModalM} from "@common/components/modal/ModalM";
import SlotModal from "./SlotModal";
import {HeaderM} from "../student/account/SettingsTabNavigator";
import {useController, useForm} from "react-hook-form";
import {IconTextM} from "@common/components/icons/IconTextM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {backOfficeService} from "@data/services/backOfficeService";

const TutorSlotsScreen = (props) => {
    const navigation = useNavigation()
    const [selectedStudentId, setSelectedStudentId] = useState(null)
    const [selectedSlotId, setSelectedSlotId] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const userId = props.route.params?.userId ?? null
    const user = userId ? props.users?.find(u => u?.id === parseInt(userId)) : props.user
    //console.log(user)
    const resetModal = () => {
        setSelectedStudentId(null)
        setSelectedSlotId(null)
    }
    const handleShowModal = () => {
        if (isModalOpen) resetModal()
        setIsModalOpen(!isModalOpen)
    }

    const onSubmit = (data) => {
        if (userId) {
            props.apiRequest(backOfficeService.updateTutorSlots, {user_id: userId}, data)
        } else {
            props.apiRequest(authService.updateSlots, {}, data)
        }

    }
    const [expanded, setExpanded] = React.useState(false);

    const handlePress = () => setExpanded(!expanded);

    const initialSlots = (user && user?.interview_slots) ? (user.interview_slots).map(function (abc) {
        return abc?.day_time
    }) : []


    const {control, handleSubmit, formState: {errors, dirtyFields, isDirty}} = useForm({
        defaultValues: {
            slots: initialSlots ?? [],
            accept_new_students: !!user?.accept_new_students
        }
    })

    const {field: slotsField} = useController({
        control,
        name: "slots"
    })

    const {field: acceptNewStudentsField} = useController({
        control,
        name: "accept_new_students"
    })

    const handleChange = () => {
        acceptNewStudentsField.onChange(!acceptNewStudentsField.value)
    }

    const [dayTitle, setDayTitle] = useState([])
    const numTotal = slotsField?.value?.length

    const numTaken = slotsField?.value?.filter(slot => {
        return user?.interview_slots?.find(sl => (sl?.day_time === slot) && sl?.is_taken)
    })
        ?.length

    const numAvailable = numTotal - numTaken


    useApiStatus(
        userId ? backOfficeService.updateTutorSlots : authService.updateSlots, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Tes créneaux ont bien été mis à jour"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )

    const dimensions = useContext(DimensionsContext)

    // workaround to use the state in event listener
    const isDirtyRef = React.useRef(false);
    useEffect(() => {
        isDirtyRef.current = isDirty
    }, [isDirty]);

    // ** Handle click on hardware back button
    useEffect(() => {
        BackHandler.addEventListener('hardwareBackPress', _goBack);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', _goBack);
        }
    }, [])


    const _goBack = () => {

        // console.log("dirtyFields", dirtyFields)
        // console.log("isDirtyRef", isDirtyRef)

        const goBack = () => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("settings", {initialTab: 1})
        }

        if (isDirtyRef.current) {
            DialogM.show({
                text1: 'Modifications non enregistrées',
                text2: 'Veux-tu vraiment quitter la page ?',
                buttonText1: 'Non, continuer',
                buttonText2: 'Quitter',
                onPressBack: () => {
                    goBack()
                }
            })
            /*            alertPolyfill('Modifications non enregistrées', 'Veux-tu vraiment quitter la page ?', [
                            {
                                text: 'Non, continuer',
                                onPress: () => {
                                },
                                style: 'cancel'
                            },
                            {
                                text: 'Quitter', onPress: () => {
                                    goBack()
                                }
                            }
                        ]);*/
        } else {
            goBack();
        }
        return true
    }


    return (<>

            <HeaderM title={"Créneaux"}
                     goBack={_goBack}
                     buttonIcon={"save"}
                     onPress={handleSubmit(onSubmit)}
                     request={props.updateSlotsRequest}
            />

            <ScrollView style={{
                width: dimensions?.width,
                alignSelf: 'center'
            }}>
                <IconTextM text={`J'ai actuellement ${user?.students?.length ?? 0} élève(s)`}
                           icon={"user"}
                           color={null}/>

                <View style={{
                    flexDirection: 'row',
                    marginTop: 20,
                    alignItems: 'center',
                    // backgroundColor: "red",
                    justifyContent: "space-between",
                    marginHorizontal: 10
                }}>
                    <IconTextM text={`J'accepte de prendre de nouveaux élèves`}
                               style={{
                                   width: IS_WEB ? null : "70%",
                                   marginHorizontal: 0,
                                   marginTop: 0
                               }}
                               icon={"user-check"}
                               color={theme.colors.primary}/>
                    <Switch color={theme.colors.primary}
                            style={{alignSelf: "center"}}
                            value={acceptNewStudentsField.value}
                            onValueChange={handleChange}/>
                </View>

                {!!acceptNewStudentsField.value &&
                    <IconTextM text={`${numAvailable} créneau(x) de 20 min disponible(s)`}
                               icon={"calendar-check"}
                               color={theme.colors.success}/>
                }
                <IconTextM text={`${numTaken} créneau(x) de 20 min occupé(s)`}
                           icon={"calendar-times"}
                           color={theme.colors.warning}/>

                <IconTextM
                    text={`Les créneaux sont à indiquer selon l'heure locale, à l'endroit où tu te situes actuellement.`}
                    icon={"exclamation-triangle"}/>

                <View style={{
                    width: dimensions?.width,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: 20
                }}>
                    <List.AccordionGroup>
                        {weekDays?.map((x, index) => {

                            const slotMinutes = [0, 20, 40]

                            const onSlotClick = (slotMomentFormatted, interviewSlot) => {

                                if (!acceptNewStudentsField.value)
                                    return

                                // console.log(slotMomentFormatted)

                                if (interviewSlot?.is_taken) {
                                    const student = user?.students?.find(s => s?.id === interviewSlot?.student_id)
                                    setSelectedSlotId(interviewSlot?.id)
                                    setSelectedStudentId(student?.id)
                                    handleShowModal()
                                } else {
                                    let newSlotsValue = [...slotsField.value]

                                    if (newSlotsValue?.includes(slotMomentFormatted)) {
                                        newSlotsValue = newSlotsValue?.filter(slot => slot !== slotMomentFormatted)
                                    } else {
                                        newSlotsValue.push(slotMomentFormatted)
                                    }
                                    // console.log(newSlotsValue)

                                    slotsField.onChange(newSlotsValue)
                                    // setSlots(newSlotsValue)
                                }
                            }

                            const renderLine = (i) => {

                                return <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {slotMinutes.map((slotMinute, ind) => {

                                        const slotMoment = moment(`2022-01-01 ${i}:${slotMinute}`, "YYYY-MM-DD HH:mm").day((index + 1) % 7)
                                        const slotMomentFormatted = slotMoment.locale("en").format("dddd HH:mm")
                                        const slotMomentId = slotMoment.locale("en").format("dddd-HH-mm")
                                        // console.log(slotMomentFormatted)
                                        const isTaken = user?.interview_slots?.find(slot => (slot?.day_time === slotMomentFormatted) && slot?.is_taken)
                                        const interviewSlot = user?.interview_slots?.find(slot => (slot?.day_time === slotMomentFormatted))

                                        const status = slotsField?.value?.includes(slotMomentFormatted) ? (!!acceptNewStudentsField.value && !isTaken ? "available" : (isTaken ? "taken" : "")) : ""

                                        const color = (status === "taken") ? theme.colors.error : (status === "available") ? theme.colors.success : theme.colors.light
                                        const outline = (status === "available")
                                        const student = (status === "taken") ? user?.students?.find(s => s?.id === interviewSlot?.student_id) : null

                                        return <View key={`${slotMomentId}`}>
                                            <Button
                                                buttonColor={color}
                                                style={{width: 100}}
                                                outline={outline}
                                                onPress={() => onSlotClick(slotMomentFormatted, interviewSlot)}
                                                id={`slot-${slotMomentId}`}>
                                                {slotMoment.format("HH:mm")}
                                            </Button>
                                        </View>
                                    })}
                                </View>
                            }
                            const renderLines = (minHour = 7, maxHour = 20) => {
                                const hours = []
                                for (let i = minHour; i < maxHour; i++) {
                                    hours.push(i)
                                }
                                return hours.map(h => <View key={h} style={{
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                    // backgroundColor:"blue",
                                }}>
                                    {renderLine(h, true)}
                                </View>)
                            }
                            let slotsPerDay = slotsField?.value?.filter(s => s.substring(0, s.indexOf(' ')) === x)

                            return <List.Section id={index.toString()}
                                                 key={index}
                                                 style={{
                                                     width: dimensions?.width
                                                 }}>
                                <List.Accordion
                                    style={{backgroundColor: theme.colors.lightMore}}
                                    title={""}
                                    left={() => <View style={{
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap',
                                        alignItems: "center"
                                    }}>
                                        <TextM fontSize={18} style={{width: "25%"}}>{weekDaysObject[x]}</TextM>
                                        <View style={{
                                            flexDirection: 'row',
                                            flexWrap: 'wrap',
                                            width: IS_WEB ? dimensions.width * 2 / 3 : "60%"
                                            // backgroundColor: "red"
                                        }}>
                                            {slotsPerDay?.filter(s => {
                                                const isTaken = user?.interview_slots?.find(slot => (slot?.day_time === s) && slot?.is_taken)
                                                return acceptNewStudentsField.value || (!acceptNewStudentsField.value && isTaken)
                                            })
                                                ?.map((s, i) => {
                                                    const isTaken = user?.interview_slots?.find(slot => (slot?.day_time === s) && slot?.is_taken)
                                                    const status = slotsField?.value?.includes(s) ? (!!acceptNewStudentsField.value && !isTaken ? "available" : (isTaken ? "taken" : "")) : ""
                                                    const color = (status === "taken") ? theme.colors.error : (status === "available") ? theme.colors.success : theme.colors.light
                                                    return <TextM color={color} style={{
                                                        marginHorizontal: 5,
                                                        width: 60,
                                                        textAlign: "center"
                                                    }} key={i}> {s.substring(s.indexOf(' ') + 1)}
                                                    </TextM>
                                                })}
                                        </View>
                                    </View>}
                                    id={weekDaysObject[x]}
                                    expanded={expanded}
                                    onPress={handlePress}>
                                    <View style={{
                                        flexWrap: 'wrap',
                                        flexDirection: 'row',
                                        justifyContent: "center",
                                        maxWidth: 400,
                                        marginLeft: -65,
                                        alignSelf: "center"
                                    }}>
                                        {renderLines(7, 22)}
                                    </View>

                                </List.Accordion>
                            </List.Section>
                        })}
                    </List.AccordionGroup>
                </View>
                {/*<FilledButtonM*/}
                {/*    style={{*/}
                {/*        borderRadius: 10,*/}
                {/*        marginTop: 20,*/}
                {/*        marginBottom: 20,*/}
                {/*        width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 4 : dimensions?.width - 20,*/}
                {/*        alignSelf: 'center'*/}
                {/*    }}*/}
                {/*    loading={props.updateSlotsRequest}*/}
                {/*    onPress={handleSubmitSlot}*/}
                {/*    label={"Enregistrer"}*/}
                {/*/>*/}
                <ModalM visible={isModalOpen} onDismiss={handleShowModal}
                        style={{width: IS_WEB && dimensions?.width > 800 ? null : "100%", paddingHorizontal: 30}}>
                    <SlotModal isOpen={isModalOpen}
                               handleShowModal={handleShowModal}
                               slotId={selectedSlotId}
                               studentId={selectedStudentId}
                               tutor={user}
                               navigation={navigation}
                               dimensions={dimensions}

                    />
                </ModalM>
            </ScrollView>
        </>

    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        listStudents: state.data.currentUser?.object?.students,
        updateSlotsSuccess: state.api.updateSlots?.success,
        updateSlotsRequest: state.api.updateSlots?.request,
        updateSlotsError: state.api.updateSlots?.error,
        updatingSlotRequest: state.api.updateSlotsTutor?.request,
        updatingSlotSuccess: state.api.updateSlotsTutor?.success,
        updatingSlotError: state.api.updateSlotsTutor?.error,
        users: state.data.users?.usersList
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorSlotsScreen)