import {useEffect} from "react";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const PermissionsManager = ({isDenied, setIsDenied, permissionsChecked, setPermissionsChecked}) => {


    useEffect(() => {

        const userAgent = navigator.userAgent.toLowerCase();
        let browser = '';
        if (userAgent.includes('chrome')) {
            browser = 'Chrome';
        } else if (userAgent.includes('firefox')) {
            browser = 'Firefox';
        } else if (userAgent.includes('safari')) {
            browser = 'Safari';
        } else if (userAgent.includes('edge')) {
            browser = 'Edge';
        } else {
            browser = 'Navigateur Inconnu';
        }

        navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then(stream => {
                setPermissionsChecked(true);
                setIsDenied(false);
                console.log('Permission Granted');
            })
            .catch(error => {
                setPermissionsChecked(true);
                setIsDenied(true);

                renderGuide(browser)

                console.error('Error accessing media devices:', error);
            });
    }, [])

    const renderGuide = (browser) => {
        const commonDialogParams = {
            champion: "abou",
            variant: "main",
            text1: "Active les permissions de la caméra dans les paramètres de ton navigateur, puis actualise la page.",
            buttonText1: 'Actualiser',
            onPress: () => {
                window.location.reload();
            },
            buttonText2: 'Ignorer'
        };

        switch (browser) {
            case 'Chrome':
                return DialogM.show({
                    ...commonDialogParams,
                    text2: "Clique sur l'icône cadenas à gauche de l'URL.\n Cherche \"Caméra\" dans le menu déroulant et sélectionne \"Autoriser\".\n\n Si le problème persiste, vérifie également les paramètres de confidentialité et de sécurité de ton système."
                });
            case 'Firefox':
                return DialogM.show({
                    ...commonDialogParams,
                    text2: "Clique sur l'icône de la caméra dans la barre d'adresse.\n Choisis \"Autoriser\" dans le menu déroulant.\n\n Si le problème persiste, vérifie également les paramètres de confidentialité et de sécurité de ton système."
                });
            case 'Safari':
                return DialogM.show({
                    ...commonDialogParams,
                    text2: "Ouvre les Réglages Safari.\n Clique sur l'onglet \"Sites web\".\n Sous \"Caméra\", sélectionne \"Autoriser\" pour app.masteur.com\n\n Si le problème persiste, vérifie également les paramètres de confidentialité et de sécurité de ton système."
                });
            case 'Edge':
                return DialogM.show({
                    ...commonDialogParams,
                    text2: "Clique sur l'icône cadenas à gauche de l'URL.\n Sous \"Caméra\", sélectionne \"Autoriser\" dans le menu déroulant.\n\n Si le problème persiste, vérifie également les paramètres de confidentialité et de sécurité de ton système."
                });
            default:
                return DialogM.show({
                    ...commonDialogParams,
                    text2: "..."
                });
        }
    };

    return null
}

export default PermissionsManager;