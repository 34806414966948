import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import React, {useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {ActivityIndicator} from "react-native-paper";

const AssignAdmin = (props) => {

    const navigation = useNavigation()
    const {tutorId} = props.route.params
    const [formValues, setFormValues] = useState(null)

    const tutor = props.users?.find(x => x?.id === tutorId)
    const [admins, setAdmins] = useState([])

    useApiStatus(
        backOfficeService.assignAdmin, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("menu")
        }
    )

    useEffect(() => {
        props.apiRequest(backOfficeService.searchAdmins)
    }, [])

    useApiStatus(
        backOfficeService.searchAdmins, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setAdmins(successData?.users)
        }
    )

    const defaultValues = {}


    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Assigner un coordinateur à ce tuteur",
            type: "select.single", // select.single, select.multiple, text, date
            name: "admin_id",
            options: admins
                ?.map(x => {
                    return {
                        value: x?.id,
                        label: x?.display_name,
                        avatar: x,
                    }
                }),
            defaultValue: tutor?.admin?.id,
            validationRule: {
                ...validation.required()
            },
            // bottomComponent: <>
            //     <TextM>
            //
            //     </TextM>
            // </>
        }

    ]

    const onSubmit = data => {
        if (props.assignAdminRequest) return
        props.apiRequest(backOfficeService.assignAdmin, {id: tutorId}, data)
    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            {props.searchAdminsRequest
                ? <ActivityIndicator animating={true}
                                     style={{
                                         marginTop: 50
                                     }}
                                     size="large"
                                     color={theme.colors.primary}/>
                : <FormStepper navigation={navigation}
                               track
                               formData={formData?.filter(x => !x.is_masked)}
                               champion={null}
                               setFormValues={setFormValues}
                               defaultValues={defaultValues}
                               onSubmit={onSubmit}
                               submitted={props.assignAdminRequest}
                               previousRouteName={"manage-tutors"}
                               submittedMessage=""
                               serverError={props.assignAdminError}
                               submitButtonText="Terminer"
                />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        assignAdminRequest: state.api?.assignAdmin?.request,
        assignAdminError: state.api?.assignAdmin?.error,
        searchAdminsRequest: state.api?.searchAdmins?.request,

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AssignAdmin)