import React, {useContext} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {subjectPriorities} from "@data/constants/formConstants";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {whatIsIt} from "@data/utility/Utils";
import {AbilityContext} from "@data/utility/Can";


export const PrioritizedSchoolSubjects = ({student, style}) => <View style={{
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    ...style
}}>
    {student?.prioritized_school_subjects?.map((item) => {
        return <SchoolSubjectBadge key={item?.id} schoolSubject={item}/>
    })}
</View>
export const SchoolSubjectBadge = ({schoolSubject, iconWidth, fontSize, style}) => <View style={{
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.colors.lightMore,
    marginRight: 5,
    // marginTop: 5,
    paddingVertical: 3,
    paddingHorizontal: 6,
    flexDirection: 'row',
    alignItems: "center",
    maxWidth: 100,
    ...style
}}>
    {schoolSubject?.icon && <IconImageM uri={schoolSubject?.icon} width={iconWidth ?? 16}/>}
    <TextM style={{marginLeft: 4}} fontSize={fontSize ?? 10}>
        {schoolSubject?.name}
    </TextM>
</View>

const profilItem = (text, content, borderBottom) => {

    const displayArrayContent = whatIsIt(content) === "Array" ? content?.length > 0 ? content : null : null
    const dimensions = useContext(DimensionsContext)
    return <View style={{
        paddingBottom: 10,
        marginTop: 10,
        borderColor: borderBottom ? theme.colors.lightMore : null,
        borderBottomWidth: borderBottom ? 1 : null,
        flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : null,
        justifyContent: 'space-between'
    }}>
        <TextM fontWeight={"Bold"} wrap>
            {text}:
        </TextM>
        {displayArrayContent ? <View style={{
            flexDirection: 'row',
            flexWrap: "wrap"
        }}>
            {displayArrayContent}
        </View> : content ? <TextM wrap>
                {content}
            </TextM>
            : <TextM wrap fontWeight={"LightItalic"}>
                (non renseigné)
            </TextM>}
    </View>
}

function ProfileDetails(props) {
    const {student} = props

    const ability = useContext(AbilityContext)

    return (
        <>
            <View
                style={{
                    flex: 1,
                    width: "100%",
                    marginTop: 20
                }}
            >
                {ability.can("read", "backOffice") && <>
                    {profilItem("E-mail", `Elève : ${student?.email ?? "-"} | Parent : ${student?.parent_email ?? "-"}`)}
                    {profilItem("Numéro de téléphone", `Elève : ${student?.mobile ?? "-"} | Parent : ${student?.parent_mobile ?? "-"}`)}
                </>}
                {profilItem("Fuseau horaire", student?.timezone)}
                {profilItem("Comment j'ai connu Masteur", student?.channel)}
                {profilItem("Classe", student?.profile?.class)}
                {profilItem("Masteur peut m'être utile pour", student?.reason)}
                {profilItem("Matières où j'ai le plus besoin de m'améliorer", student?.profile?.subject_priority ? subjectPriorities?.find(x => x?.value === student?.profile?.subject_priority)?.label : null)}
                {profilItem("J'ai une préférence pour les matières", student?.profile?.subject_preference ? subjectPriorities?.find(x => x?.value === student?.profile?.subject_preference)?.label : null)}
                {profilItem("Ville", student?.profile?.city)}
                {profilItem("Etablissement scolaire", student?.profile?.school_name, true)}


                {(student?.profile?.class === "1ère" || student?.profile?.class === "Tle") &&
                    profilItem("Mes matières de spécialité", student?.school_subjects?.filter(item => item?.specialty)?.map((item, index) => {
                        return <SchoolSubjectBadge key={item?.id} schoolSubject={item}/>
                    }))}


                {profilItem("Mes principales qualités", student?.profile?.qualities?.map((item) => {
                    return <View style={{
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: theme.colors.lightMore,
                        marginRight: 10,
                        marginBottom: 10,
                        padding: 3
                    }} key={item}>
                        <TextM>
                            {item}
                        </TextM>
                    </View>
                }))}
                {profilItem("Les matières que j'apprécie le plus", student?.appreciated_school_subjects?.map((item) => {
                    return <SchoolSubjectBadge key={item?.id} schoolSubject={item}/>
                }), true) ?? null}

                {profilItem("Le(s) métier(s) où je m'imagine plus tard", student?.profile?.future_job)}
                {profilItem("L'orientation que je souhaiterais", student?.profile?.future_orientation, true)}

                {profilItem(props.personalWorkForm?.duration?.title, student?.profile?.personal_work?.duration)}
                {profilItem(props.personalWorkForm?.frequency?.title, student?.profile?.personal_work?.frequency)}

            </View>
        </>
    )
}

const mapStateToProps = state => {
    return {
        personalWorkForm: state.data.staticData?.personal_work_form
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails)