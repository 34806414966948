import {formDataConstants} from "@data/constants/formDataConstants";

export function saveActivityData(data) {
    return {type: formDataConstants.SAVE_ACTIVITY, data}
}

export function clearActivityData() {
    return {type: formDataConstants.CLEAR_ACTIVITY}
}

export function setAnswerInput(data) {
    return {type: formDataConstants.SET_ANSWER_INPUT, data}
}