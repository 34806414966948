import React, {useContext} from 'react';
import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {ImagesList} from "@common/camera/CameraImagesM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {KeyComponentItem} from "@common/components/card/KeyComponentItem";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import TitleM from "@common/components/text/TitleM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {useNavigation} from "@react-navigation/native";
import {Menu3Point} from "@common/components/menu/Menu3Point";
import {activitiesService} from "@data/services/activitiesService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import LinkTextM from "@common/components/text/LinkTextM";

const MaterialItem = (text, title, url = false) => <View
    style={{flexDirection: "row", alignItems: 'center', justifyContent: 'space-between', marginTop: 5}}>
    <TextM fontSize={18}
           fontWeight="Bold"
           color={theme.colors.light}>{title}</TextM>
    {url ? <LinkTextM
        url={text}
    >
        <FontAwesome5 name={"link"} solid size={18}/>
    </LinkTextM> : <TextM fontSize={18}
                          fontWeight="Bold"
                          color={theme.colors.primary}>{text}</TextM>}
</View>
const MaterialDetails = (props) => {
    const {activityId, studentId} = props.route.params
    const activity = findItemInListWithId(activityId, props.user?.todo_activities)
    const activityMaterial = activity?.material
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const images = activityMaterial?.attachments?.map((file) => {
        return {
            uri: file,
            width: 200,
            height: 200
        }
    })

    // console.log(activity)

    const menuItems = [
        {
            title: "Modifier",
            onPress: () => {
                navigation.push("update-material", {
                    activityId: activity?.id,
                })
            }
        },
        {
            title: "Supprimer",
            onPress: () => {
                props.apiRequest(activitiesService.deleteMaterial, {id: activityMaterial?.id})
            }
        },
    ]

    useApiStatus(
        activitiesService.deleteMaterial, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack()) {
                navigation.goBack()
            } else {
                navigation.replace("menu")
            }
        } // success callback
    )

    return <View style={{
        flex: 1,
    }}>
        <HeaderContainerM>
            {props.deleteMaterialRequest ? <ActivityIndicator color={theme.colors.primary}
                                                              style={{
                                                                  position: "absolute",
                                                                  right: 4,
                                                              }}/> :
                <Menu3Point menuItems={menuItems}
                            position={'bottom'}
                            style={{
                                position: "absolute",
                                right: 4,
                            }}/>}
            <TitleM>Énoncé</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>
        <View style={{
            marginTop: 20,
            width: dimensions?.width,
            alignSelf: 'center'
        }}>
            <KeyComponentItem keyText={"ÉTAPE 1"} style={{marginTop: 20}}>
                {MaterialItem(activityMaterial?.chapter, "Chapitre :")}
                {MaterialItem(activityMaterial?.text, "Text :")}
                {MaterialItem(activityMaterial?.url, "Lien externe :", true)}
            </KeyComponentItem>
            <KeyComponentItem keyText={"ÉTAPE 2"} style={{marginTop: 20}}>
                <ImagesList images={images} contentHeight={dimensions?.height / 4}
                            style={{marginLeft: 30, marginTop: 10}}/>
            </KeyComponentItem>
        </View>
    </View>
};
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        deleteMaterialRequest: state.api.deleteMaterial?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDetails)