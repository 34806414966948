import axios from "axios"

function getTutors() {
    return axios.get('/api/tutors')
}

function chooseTutor({tutor_id}, data) {
    return axios.post(`/api/tutors/${tutor_id}/choose`, data)
}

function updateSlot(values) {

    return axios.put('/api/user/interview-slots', values)

}

function postNote({id}, values) {

    return axios.post(`/api/v1/users/${id}/tutor-notes`, values)

}

function getNotes({id}) {

    return axios.get(`/api/v1/users/${id}/tutor-notes`)

}

function updateNote({id}, values) {

    return axios.put(`/api/v1/tutor-notes/${id}`, values)

}

function deleteNote({id}) {

    return axios.delete(`/api/v1/tutor-notes/${id}`)

}


function getTestAnswers({id}) {

    return axios.get(`/api/tutor-candidates/${id}/test-answers`)

}

function submitApplicationEvaluation({id}, data) {

    return axios.post(`/api/tutor-candidates/${id}/submit-application-evaluation`, data)

}

function submitPersonalInfos({}, data) {

    return axios.put(`/api/v1/personal-infos`, data)

}

function submitCompleteProfile({}, data) {

    return axios.put(`/api/v1/update-tutor-profile`, data)

}

function getTestQuestions({}, {}, params) {

    return axios.get(`/api/v1/test-questions`, {params})

}

function submitTestAnswer({id}, data) {

    return axios.post(`/api/v1/app-questions/${id}/submit-answer`, data)


}

function submitQuestionViewed({id}) {

    return axios.post(`/api/v1/app-questions/${id}/submit-view`)


}

function getTutoringInvoices() {

    return axios.get(`/api/v2/tutoring-invoices`)

}

export const tutorsService = {
    getTutors,
    chooseTutor,
    updateSlot,
    postNote,
    getNotes,
    updateNote,
    deleteNote,
    submitPersonalInfos,
    getTestQuestions,
    submitTestAnswer,
    submitQuestionViewed,
    getTestAnswers,
    submitApplicationEvaluation,
    submitCompleteProfile,
    getTutoringInvoices
}
