import {useController} from "react-hook-form";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {scoringService} from "@data/services/scoringService";
import {View} from "react-native";
import {ChampionImageM} from "../student/champions/ChampionImageM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";
import TextInputM from "@common/components/input/TextInputM";
import {IS_WEB} from "@common/utils/mobileUtils";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DialogM} from "@common/components/alert/dialog/Dialog";

function SamAppreciationGenerator(props) {

    const {
        variant,
        fieldName,
        control,
        errors,
        activity,
        width,
        rawAppreciation,
        dimensions,
        activityStatus = "validated"
    } = props

    const {field: commentField} = useController({
        control,
        name: fieldName
    })

    useApiStatus(
        scoringService.generateSamAppreciation, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            commentField?.onChange(data?.appreciation)
        } // success callback
    )

    const formData = {
        raw_comment: rawAppreciation,
        activity_status: activityStatus,
        activity_id: activity?.id
    }

    return <View style={{flexDirection: "row", width}}>
        <View>
            <ChampionImageM width={100}
                // style={{flex:2}}
                            name={"sam"}
                            variant={variant}/>
            {props.generateSamAppreciationRequest
                ? <ActivityIndicator style={{
                    position: "absolute",
                    top: -5,
                    alignSelf: "center"
                    // backgroundColor: theme.colors.primary
                }}
                                     color={theme.colors.primary}/>
                : <IconButton
                    icon="comment-dots"
                    iconColor={theme.colors.white}
                    size={32}
                    // disabled={true}
                    disabled={rawAppreciation === null || rawAppreciation?.trim() === ""}
                    style={{
                        position: "absolute",
                        top: -5,
                        alignSelf: "center",
                        padding: 5,
                        backgroundColor: theme.colors.primary
                    }}
                    onPress={() => {
                        if (commentField?.value !== "") {
                            DialogM.show({
                                text1: "Regénérer une appréciation ?",
                                text2: "Cela écrasera l'appréciation actuelle",
                                buttonText1: 'Oui',
                                buttonText2: 'Annuler',
                                onPress: () => {
                                    props.apiRequest(scoringService.generateSamAppreciation, {}, formData)
                                }
                            })
                            /*                         alertPolyfill("Regénérer une appréciation ?", "Cela écrasera l'appréciation actuelle", [
                                                         {
                                                             text: 'Annuler',
                                                             onPress: () => {
                                                             },
                                                             style: 'cancel'
                                                         },
                                                         {
                                                             text: 'Oui',
                                                             onPress: () => {
                                                                 props.apiRequest(scoringService.generateSamAppreciation, {}, formData)
                                                             }
                                                         }
                                                     ])*/
                        } else {
                            props.apiRequest(scoringService.generateSamAppreciation, {}, formData)
                        }
                    }}
                />}
        </View>
        <TextInputM
            placeholder="Appréciation de Sam..."
            name={fieldName}
            style={{
                // flex:1,
                width: width - 100,
                height: IS_WEB && dimensions.width > 800 ? 120 : 240,
                alignSelf: "stretch"
            }}
            multiline={true}
            // numberOfLines={3}
            // textContentType="emailAddress"
            // keyboardType="email-address"
            control={control}
            errors={errors}
        />
    </View>;
}

const mapStateToProps = state => {
    return {
        generateSamAppreciationRequest: state.api.generateSamAppreciation?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SamAppreciationGenerator)