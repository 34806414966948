import React, {useContext} from "react";
import {BottomSheetModalM} from "@common/components/modal/BottomSheetM";
import {theme} from "@common/theme/theme";
import {View} from "react-native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextButtonM from "@common/components/button/TextButtonM";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {BottomSheetView} from "@gorhom/bottom-sheet";
import {ChampionImageM} from "../../champions/ChampionImageM";
import live from "../../../../assets/icons/generic/live.png";
import {IconImageM} from "@common/components/icons/IconsM";

export const OfflineModeBottomSheetModal = React.forwardRef((props, ref) => {
    // ** Component props
    const {onPressButton1, onPressButton2, isOffline} = props

    const backgroundColor = isOffline ? theme.colors.purple : theme.colors.transparent
    const textColor = isOffline ? theme.colors.white : theme.colors.purple
    const iconName = isOffline ? "plane" : "plane-slash"
    const offlineText = isOffline ? "Bonus Hors-ligne activé !" : "En attente de l'activation du mode Hors-ligne..."
    const buttonText = isOffline ? "Commencer" : "En attente..."
    const dimensions = useContext(DimensionsContext)

    return <BottomSheetModalM ref={ref}
                                         height="90%"
                                         backgroundStyleProps={{
                                             backgroundColor: theme.colors.purpleLight,
                                             borderRadius: 0,
                                             borderTopWidth: 1,
                                             borderColor: theme.colors.lighter,
                                         }}>
        <BottomSheetView style={{
            // flex: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            width: dimensions?.width,
            height: "100%"
        }}>

            <ChampionImageM name="abou" variant={isOffline ? "happy" : "main"} width={140}/>

            <TextM fontWeight="Bold"
                   fontSize={18}
                   wrap
                   style={{
                       paddingHorizontal: 20,
                       paddingTop: 20,
                       textAlign: "center"
                   }}
                   color={theme.colors.purple}>{!isOffline ? "Active le mode Hors-ligne" : "Yay !"}</TextM>


            {!isOffline && <>
                <TextM fontWeight="Regular"
                       fontSize={16}
                       wrap
                       style={{
                           paddingHorizontal: 20,
                           paddingTop: 20,
                           textAlign: "center"
                       }}
                       color={theme.colors.grey}>Pour rester focus sur ton travail et gagner un bonus XP</TextM>
            </>}

            <View style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 40,
                paddingVertical: 10,
                marginTop: 20,
                width: dimensions?.width,
                // borderRadius: 5,
                backgroundColor: backgroundColor
            }}>
                {<FontAwesome5 name={iconName} size={30} color={textColor}/>}
                {(isOffline ) && <TextM fontWeight="Medium"
                                                 fontSize={16}
                                                 wrap
                                                 style={{marginLeft: 15, textAlign: "left"}}
                                                 color={textColor}>{offlineText}</TextM>}
            </View>

            <View style={{
                alignItems: "center",
                justifyContent: "center",
                width: dimensions?.width,
                position: "absolute",
                bottom: 0
            }}>
                <FilledButtonM
                    onPress={onPressButton1}
                    disabled={!isOffline}
                    label={buttonText}
                    color={theme.colors.success}
                    // contentStyle={{
                    //     padding: 0
                    // }}
                    style={{
                        width: dimensions?.width - 20,
                        alignSelf: "center",
                        marginTop: 10
                    }}
                    // label={props.loggingIn ? "" : "Connexion"}
                />

                {!isOffline && <TextButtonM
                    onPress={onPressButton2}
                    style={{
                        marginTop: 10,
                        marginBottom: 40,
                        alignSelf: "center"
                    }}
                    color={theme.colors.grey}
                    fontWeight="ExtraBold"
                    label="CONTINUER SANS BONUS HORS-LIGNE"/>}
            </View>
        </BottomSheetView>
    </BottomSheetModalM>;
})