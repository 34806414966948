import {View} from "react-native";
import {Rating} from "react-native-ratings/src";
import TextM from "../text/TextM";
import {theme} from "../../theme/theme";
import React from "react";
import sparkle from "../../../../assets/icons/stars/sparkle_b.png"

export function RatingM({score, color, size = 40, readonly = true, style, noMargin, noText, scale = 5}) {

    return <View style={{
        flexDirection: "row",
        alignItems: "center",
        ...style
    }}>
        <Rating
            ratingCount={scale}
            type={"custom"}
            ratingImage={sparkle}
            ratingColor={color ?? theme.colors.accent}
            ratingBackgroundColor={theme.colors.lighter}            // ratingBackgroundColor={'#c8c7c8'}
            // ratingColor={'#3498db'}
            startingValue={score ?? 0}
            readonly={readonly}
            // tintColor={'#3498db'}
            style={{
                marginTop: noMargin ? 7 : 10,
                marginBottom: noMargin ? 0 : 20,
            }}
            imageSize={size}
        />
        {!noText && <TextM fontSize={size * 0.52}
                           fontWeight="SemiBold"
                           style={{
                               position: "absolute",
                               right: size < 40 ? -size : -size * 3 / 2,
                               paddingBottom: 5
                           }}
                           color={color ?? theme.colors.accent}>{score ? (Math.round(score * 10) / 10 ?? 0) : ""}</TextM>}
    </View>;
}