import React, {forwardRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {apiRequest} from "@data/redux/actions/api";
import {PaginatedFlatListContent} from "@common/screens/PaginatedFlatListContent";

export const PaginatedFlatListNoNavigation = forwardRef((props, ref) => {

    const {
        data,
        ListHeaderComponent,
        renderItem,
        style,
        additionalOnRefresh,
        additionalRefreshing = false,
        requestApiService,
        requestRouteParams,
        requestUrlParams,
        requestData,
        contentContainerStyle,
        tooManyColumns = false,
        ListEmptyComponent,
        noRefreshWhenNoResults = false,
        noActivityIndicator = false
    } = props

    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [totalCount, setTotalCount] = useState(-1)

    const resetCounters = () => {
        setCurrentPage(1)
        setLastPage(0)
        // setTotalCount(0)
    }

    const request = useSelector((state) => state.api[requestApiService?.name]?.request) ?? false
    const refreshing = additionalRefreshing || request
    const dispatch = useDispatch();

    const refresh = () => {
        // console.log("REFRESH")

        dispatch(apiRequest(requestApiService, requestRouteParams ?? {}, requestData ?? {}, {
            ...requestUrlParams,
            page: 1
        }))
        additionalOnRefresh()
        resetCounters()
    }

    return <PaginatedFlatListContent {...props}
                                     totalCount={totalCount}
                                     setTotalCount={setTotalCount}
                                     currentPage={currentPage}
                                     setCurrentPage={setCurrentPage}
                                     lastPage={lastPage}
                                     setLastPage={setLastPage}
                                     refresh={refresh}
                                     refreshing={refreshing}
    />
})