import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {theme} from "@common/theme/theme";
import {quizzService} from "@data/services/quizzservice";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {validation} from "@data/utility/validation";
import {Text} from "react-native-paper";
import {IS_WEB} from "@common/utils/mobileUtils";
import LinkTextM from "@common/components/text/LinkTextM";


const ConfirmQuizzScreen = (props) => {
    const navigation = useNavigation()

    const [formValues, setFormValues] = useState(null)
    const defaultValues = {}

    const {quizzSubmitId} = props.route?.params


    // console.log(formValues)


    useApiStatus(
        quizzService.confirmQuizzSubmit, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {

            const congrats = [
                {
                    congratsType: congratsTypes.QUIZZ_INITIALIZED,
                    congratsData: {
                        title: "QUIZZ TERMINÉ",
                        // text: "Tu as terminé le quizz",
                        score: successData?.score
                    }
                },
                {
                    congratsType: congratsTypes.QUIZZ_CONFIRMED,
                    congratsData: {
                        title: "MERCI ❤️",
                        text: "+1 arbre planté"
                    },
                    hideButton: true
                }
            ]
            navigation.replace("congrats", {congrats})
        }
    )

    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Complète tes informations pour obtenir ton score et planter un arbre",
            description: "",
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "submitter_firstname",
                    placeholder: "Votre prénom",
                    height: 60,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                },
                {
                    name: "submitter_lastname",
                    placeholder: "Votre nom",
                    height: 60,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                },
                {
                    name: "submitter_email",
                    placeholder: "Votre email",
                    height: 60,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required(),
                        ...validation.email()
                    }
                }
            ],
            checkBoxes: [
                {
                    label: "Je souhaite me tenir informé des actualités de Masteur",
                    icon: "envelope-open-text",
                    name: "newsletter",
                    defaultValue: true
                }
            ],
            bottomComponent: <Text style={{
                fontSize: 12,
                textAlign: 'center',
                marginTop: IS_WEB ? 10 : 0
                // marginBottom: 10
            }}>
                En cliquant sur Envoyer, vous acceptez la <LinkTextM url="https://masteur.com/confidentialite">Politique
                de confidentialité</LinkTextM> de Masteur
            </Text>
        }

    ]


    const onSubmit = data => {
        // console.log("data", data)
        props.apiRequest(quizzService.confirmQuizzSubmit, {quizz_submit_id: quizzSubmitId}, data)

    }
    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                // champion={{name: "tina", variant: "main"}}
                         formData={formData?.filter(x => !x.is_masked)}
                         setFormValues={setFormValues}
                         formValues={formValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         previousRouteName={"menu"}
                         exitRouteName={"menu"}
                         submitted={props.confirmQuizzSubmitRequest}
                         submittedMessage=""
                         submitButtonText="Envoyer"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}

const mapStateToProps = state => {
    return {
        confirmQuizzSubmitRequest: state.api.confirmQuizzSubmit?.request

    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle

};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmQuizzScreen)