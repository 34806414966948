import React, {useEffect, useState} from 'react';
import {TouchableOpacity, View} from "react-native";
import {DatePickerModal, TimePickerModal} from "react-native-paper-dates";
import moment from "moment";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {SafeAreaProvider} from "react-native-safe-area-context";
import SelectDayModal from "./live/rooms/SelectDayModal";
import {daysOptions} from "@data/constants/formConstants";

const DayTimeItem = ({
                         onPress = () => {
                         }, label
                     }) => <TouchableOpacity
    activeOpacity={0.8}
    onPress={onPress}
>
    <View style={{
        flexDirection: "row",
        width: 140,
        backgroundColor: theme.colors.greyBg,
        borderRadius: 15,
        borderColor: theme.colors.light,
        borderWidth: 1,
        paddingVertical: 6,
        paddingHorizontal: 12,
        flexWrap: "wrap",
        marginRight: 10
    }}>
        <TextM style={{
            borderColor: theme.colors.primary,
            fontSize: 14,
            // color: label === "Jour" ? "rgba(60, 60, 67, 1)" : "#000",
            marginVertical: 7
        }} wrap>
            {label ?? " "}
        </TextM>
    </View>
</TouchableOpacity>
const DateTimePicker = (props) => {
    const {control, setFormValues, field, type, range = undefined} = props
    const [isTimePickerVisible, setTimePickerVisibility] = useState(false);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

    // console.log("date", field?.value)

    const [date, setDate] = useState(field?.value ? type === "day-time" ? new Date(field?.value?.day + " " + field?.value?.time) : new Date(field?.value) : null);
    const [dateLabel, setDateLabel] = useState(type === "day-time" || type === "time" ? null : field?.value ? moment(field?.value?.split(" ")?.[0]).format("DD-MM-YYYY") : "Date");
    const [timeLabel, setTimeLabel] = useState(field?.value?.split(" ")?.[1] ?? "Heure");
    const [dayLabel, setDayLabel] = useState(daysOptions.find(d => d.value === field?.value?.split(" ")?.[0])?.label ?? "Jour");
    const [dayValue, setDayValue] = useState(field?.value?.split(" ")?.[0] ?? null);

    useEffect(() => {

        if (field?.value) {
            if (type === "day-time") {
                const day = daysOptions.find(d => d.value === field?.value?.split(" ")?.[0])?.label
                setDayLabel(day)
                setDayValue(field?.value?.split(" ")?.[0])
                setTimeLabel(field?.value?.split(" ")?.[1])
            } else if (type === "time") {
                setTimeLabel(field?.value)
            } else {
                const dd = new moment(field?.value).format("YYYY-MM-DD")
                const dl = new moment(field?.value).format("DD-MM-YYYY")
                setDate(new Date(dd))
                setDateLabel(dl)
            }
        }

    }, [field.value]);


    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };
    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };
    const showTimePicker = () => {
        setTimePickerVisibility(true);
    };
    const hideTimePicker = () => {
        setTimePickerVisibility(false);
    };
    const [visibleModal, setVisibleModal] = useState(false)
    const handleShowModal = () => {
        setVisibleModal(!visibleModal)
    }

    const onConfirmDay = (day, index, valueDay) => {
        setDayLabel(day)
        setDayValue(valueDay)
        field.onChange(valueDay + " " + timeLabel)
        setFormValues({
            ...control._formValues,
            [field?.name]: valueDay + " " + timeLabel
        })
    }

    return <>
        <SafeAreaProvider>
            <View style={{flex: 1, alignItems: 'center'}}>
                <View style={{flexDirection: 'row', marginTop: 10}}>
                    {(type === "day-time") && <DayTimeItem onPress={() => handleShowModal()} label={dayLabel}/>}
                    {(type === "date" || type === "date-time") &&
                        <DayTimeItem onPress={() => showDatePicker()} label={dateLabel}/>}
                    {(type === "time" || type === "date-time" || type === "day-time") &&
                        <DayTimeItem onPress={() => showTimePicker()} label={timeLabel}/>}
                </View>
            </View>
            {(type === "time" || type === "date-time" || type === "day-time") &&
                <TimePickerModal
                    label={"Sélectionner une heure"}
                    hours={timeLabel?.split(":")[0]}
                    minutes={timeLabel?.split(":")[1]}
                    cancelLabel={"Annuler"}
                    confirmLabel={"Confirmer"}
                    keyboardIcon={'keyboard'}
                    clockIcon={'clock'}
                    locale={"fr"}
                    visible={isTimePickerVisible}
                    onDismiss={hideTimePicker}
                    onConfirm={(e) => {
                        const hours = e.hours?.toString().length > 1 ? e.hours : "0" + e.hours
                        const minutes = e.minutes?.toString().length > 1 ? e.minutes : "0" + e.minutes
                        const time = hours + ":" + minutes
                        const dd = new moment(date).format("YYYY-MM-DD")

                        // console.log("dd", date.date)
                        // console.log("dd", dd)

                        setTimeLabel(time)
                        hideTimePicker()
                        field.onChange(type === "time" ? time : type === "day-time" ? (dayValue) + " " + time : dd + " " + time)
                        setFormValues({
                            ...control._formValues,
                            [field?.name]: type === "time" ? time : type === "day-time" ? dayValue + " " + time : dd + " " + time
                        })
                    }}
                />}
            {(type === "date" || type === "date-time") && <DatePickerModal
                label={"Sélectionner une date"}
                cancelLabel={"Annuler"}
                saveLabel={"Confirmer"}
                closeIcon={"times"}
                editIcon={'edit'}
                allowEditing={false}
                calendarIcon={'calendar'}
                locale="fr"
                mode="single"
                visible={isDatePickerVisible}
                onDismiss={hideDatePicker}
                date={date}
                validRange={range}
                onChange={(e) => {
                    // console.log("date.date", e.date)
                    hideDatePicker()
                    const dd = new moment(e.date).format("YYYY-MM-DD")
                    const dl = new moment(e.date).format("DD-MM-YYYY")
                    setDate(new Date(dd))
                    setDateLabel(dl)
                    field.onChange(type === "date" ? dd : dd + " " + timeLabel)
                    setFormValues({
                        ...control._formValues,
                        [field?.name]: type === "date" ? dd : dd + " " + timeLabel
                    })
                }}
            />}
        </SafeAreaProvider>
        <SelectDayModal control={control}
                        handleShowModal={handleShowModal}
                        isOpen={visibleModal}
                        initialValue={dayValue}
            // fieldName={field?.name}
                        onConfirmDay={onConfirmDay}/>
    </>
};

export default DateTimePicker;