import React, {useContext, useRef, useState} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {ResizeMode, Video} from "expo-av";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ProfileInfo} from "../../student/social/ProfileInfo";
import AvatarM from "@common/components/avatar/AvatarM";
import moment from "moment";

const bacItem = (title, value) => <View style={{
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15
}}>
    <TextM fontWeight={'SemiBold'}>
        {title}
    </TextM>
    <TextM wrap>
        {value ?? "(non renseigné)"}
    </TextM>
</View>
const ApplicationScreen = (props) => {

    const {bacInfo, application, applicationSteps, tutor} = props
    const video = useRef(null);
    const [status, setStatus] = useState({});

    const dimensions = useContext(DimensionsContext)
    const categories = [
        "motivation",
        "educational",
        "technical"
    ]
    const titles = [
        {"motivation": "Motivation"},
        {"educational": "Pédagogie"},
        {"technical": "Technique"}
    ]

    const questions = application?.application_questions
    const answers = application?.application_answers

    return (
        <View style={{
            width: '100%',
            alignItems: 'center'
        }}>
            <TextM color={theme.colors.primary} fontWeight={"Bold"} fontSize={16}>
                Candidature
            </TextM>
            <View style={{
                width: '80%',
                borderRadius: 10,
                borderWidth: 1,
                borderColor: theme.colors.lightMore,
                backgroundColor: theme.colors.white,
                padding: 10,
                marginTop: 10,
                minHeight: 100
            }}>
                <TextM color={theme.colors.primary} fontWeight={'Bold'}>
                    {tutor?.firstname} {tutor?.lastname}
                </TextM>
                <ProfileInfo user={tutor} showEmail showMobile dateFormat={"[le] DD MMMM YYYY [à] HH:mm"}
                             style={{marginBottom: 0}}/>
                <TextM style={{marginTop: 5}} fontWeight="Light" wrap>Candidature
                    reçue {moment(tutor?.candidature_sent_at).format("[le] DD MMMM YYYY [à] HH:mm")}</TextM>
                <AvatarM user={tutor} style={{
                    position: "absolute",
                    top: 15,
                    right: 15
                }} size={60}/>
            </View>
            <View style={{
                width: '80%',
                borderRadius: 10,
                borderWidth: 1,
                borderColor: theme.colors.lightMore,
                backgroundColor: theme.colors.white,
                padding: 10,
                marginTop: 10
            }}>
                <TextM color={theme.colors.primary} fontWeight={'Bold'}>
                    Profil
                </TextM>
                {bacItem("Année d'obtention du baccalauréat :", bacInfo?.graduation_year)}
                {bacItem("Equivalent du baccalauréat passé à l'étranger :", bacInfo?.abroad_bac ? "Oui" : "Non")}
                {bacItem("Filière bac :", bacInfo?.sector)}
                {bacItem("Matières de prédilection :", tutor?.profile?.relevant_subjects?.join(", "))}
                {bacItem("Mention au bac :", bacInfo?.mention)}
                {bacItem("Note obtenue à l'écrit du bac de Français :", bacInfo?.writing_french_score)}
                {bacItem("Note obtenue à l'oral du bac de Français :", bacInfo?.oral_french_score)}
                {bacItem("Niveau d'étude :", tutor?.profile?.study_level)}
                {bacItem("Établissement d'étude :", tutor?.profile?.study_institution)}
                {bacItem("Formation :", tutor?.profile?.study_field)}
                {bacItem("SIRET :", tutor?.profile?.num_SIRET)}
            </View>
            {categories?.map((category, index) => {
                return <View
                    key={index}
                    style={{
                        width: '80%',
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: theme.colors.lightMore,
                        padding: 10,
                        backgroundColor: theme.colors.white,
                        marginTop: 10
                    }}>
                    <TextM color={theme.colors.primary} fontWeight={'Bold'}>
                        {titles.find((title) => title[category])?.[category]}
                    </TextM>
                    {answers?.map((answer, index) => {
                        const question = questions?.find((question) => answer?.application_question_id === question?.id)
                        if (question?.category === category) {
                            return <View key={index}>
                                <TextM fontWeight={'SemiBold'} style={{marginTop: 10}} wrap>
                                    {question?.question}
                                </TextM>
                                {answer?.video ? <View style={{
                                    width: "100%",
                                    height: "100%",
                                    flex: 1,
                                    alignSelf: 'center',
                                    alignItems: 'center'
                                }}>
                                    <Video
                                        ref={video}
                                        style={{
                                            width: "100%",
                                            height: 500,
                                            alignSelf: 'center',
                                            marginTop: 20
                                        }}
                                        videoStyle={{
                                            position: "relative"
                                        }}
                                        source={{
                                            uri: answer?.video
                                        }}
                                        useNativeControls
                                        onLoad={(res) => {
                                            // console.log(res)
                                        }}
                                        onError={(error) => {
                                            // console.log(error)
                                        }}
                                        resizeMode={ResizeMode.CONTAIN}
                                        onPlaybackStatusUpdate={status => setStatus(() => status)}
                                    />
                                </View> : <TextM style={{marginTop: 10}} wrap>
                                    {answer?.answer ?? "(pas de réponse)"}
                                </TextM>}

                            </View>
                        }
                    })}
                </View>
            })}
        </View>
    )
}

export default ApplicationScreen;
