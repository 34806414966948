import React, { useContext } from "react";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import { ModalM } from "@common/components/modal/ModalM";
import { ScrollView, View } from "react-native";
import { ListItemM } from "@common/components/list/ListItemM";
import { ListGroupM } from "@common/components/list/ListGroupM";
import TitleM from "@common/components/text/TitleM";
import { theme } from "@common/theme/theme";
import { useForm } from "react-hook-form";
import TextInputM from "../../../common/components/input/TextInputM";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../data/utility/validation";
import FilledButtonM from "../../../common/components/button/FilledButtonM";
import { backOfficeService } from "../../../data/services/backOfficeService";
import { useApiStatus } from "../../../common/hooks/useApiStatus";

const formSchema = yup.object().shape({
  transfer_amount: yup.number(),
  tutoring_credit_amount: yup.number(),
  application_fee_amount: yup.number(),
  application_compensation_amount: yup.number(),
});

export default function EditBillingModal(props) {
  const { isOpen, handleShowModal, data, isLoading, apiRequest } = props;
  const dimensions = useContext(DimensionsContext);
  const resolver = yupResolver(formSchema);
  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver,
    defaultValues: {
      transfer_amount: data?.transfer_amount ?? 0,
      tutoring_credit_amount: data?.tutoring_credit_amount ?? 0,
      application_fee_amount: data?.application_fee_amount ?? 0,
      application_compensation_amount:
        data?.application_compensation_amount ?? 0,
    },
  });

  const onSubmit = (billingData) => {
    apiRequest(backOfficeService.editBilling, { id: data.id }, billingData);
    handleShowModal();
  };

  useApiStatus(
    backOfficeService.editBilling, // api service
    null, // success message on toast (string or null)
    false, // error message from server in toast (true) or keep it in redux state (false)
    (successData) => {}
  );
  return (
    <ModalM
      visible={isOpen}
      onDismiss={handleShowModal}
      style={{
        // width: IS_WEB && dimensions.width > 800 ? "50%" : "90%",
        maxHeight: "80%",
        padding: 20,
        alignItems: "center",
      }}
    >
      <TitleM>Facturation</TitleM>
      <TextInputM
        placeholder="transfert au tuteur"
        label="transfert au tuteur"
        name="transfer_amount"
        control={control}
        errors={errors}
        onSubmitEditing={(e) => {}}
      />
      <TextInputM
        placeholder="Crédits de tutorat"
        label="Crédits de tutorat"
        name="tutoring_credit_amount"
        control={control}
        errors={errors}
        onSubmitEditing={(e) => {}}
      />
      <TextInputM
        placeholder="Commission"
        label="Commission"
        name="application_fee_amount"
        control={control}
        errors={errors}
        onSubmitEditing={(e) => {}}
      />
      <TextInputM
        placeholder="Compensation"
        label="Compensation"
        name="application_compensation_amount"
        control={control}
        errors={errors}
        onSubmitEditing={(e) => {}}
      />
      <FilledButtonM
        label={"Terminer"}
        color={theme.colors.success}
        loading={isLoading}
        style={{ marginTop: 20 }}
        onPress={handleSubmit(onSubmit)}
      />
    </ModalM>
  );
}
