import React, {useContext} from 'react';
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {View} from "react-native";
import TitleM from "@common/components/text/TitleM";
import {ModalM} from "@common/components/modal/ModalM";
import TextInputM from "@common/components/input/TextInputM";
import {Controller, useController, useForm} from "react-hook-form";
import yup from "@data/utility/validation";
import {yupResolver} from "@hookform/resolvers/yup";
import {studentClasses} from "@data/constants/formConstants";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import SelectBox from "@common/components/select/selectSingle-Multiple";
import FilledButtonM from "@common/components/button/FilledButtonM";

const formSchema = yup.object().shape({
    name: yup.string().required(),
    school_subject_id: yup.string().required(),
    class: yup.string().required()
})
const NewChapterModal = (props) => {
    const {
        isOpen,
        handleShowModal,
        navigation,
        setNewChapter,
        defaultValues,
        onSelectChapter
    } = props
    const dimensions = useContext(DimensionsContext)
    const resolver = yupResolver(formSchema);
    const {control, handleSubmit, formState: {errors, dirtyFields, isValid}} = useForm(
        {
            resolver,
            defaultValues: {
                name: defaultValues?.name ?? "",
                school_subject_id: defaultValues?.school_subject_id ? parseInt(defaultValues?.school_subject_id) : null,
                class: defaultValues?.class ?? ""
            }
        });
    const {field: chapterNameField} = useController({
        name: "name",
        control
    })

    const onSubmit = (data) => {
        //console.log(data)
        setNewChapter({...data})
        onSelectChapter({...data, new: true})
        handleShowModal()
    }
    return (
        <ModalM visible={isOpen} onDismiss={handleShowModal}
                style={{
                    width: IS_WEB && dimensions.width > 800 ? "50%" : "90%",
                    maxHeight: "80%",
                    padding: 20,
                    paddingBottom: 0
                }}>
            <View>
                <TitleM>Ajouter un chapitre</TitleM>
                <View style={{
                    marginBottom: 10,
                    marginTop: 20,
                    width: "100%"
                }}>
                    <Controller
                        control={control}
                        name="school_subject_id"
                        render={({field: {onChange, value}}) => (
                            <SelectBox
                                title="Matière"
                                options={props.schoolSubjects.map((x) => {
                                    return {value: x.id, label: x.name, icon: x?.icon};
                                })}
                                value={value}
                                onSingleSelect={(value) => {
                                    onChange(value.value);
                                }}
                                style={{width: "100%"}}
                                valueMode={true}
                            />)}/>
                    <Controller
                        control={control}

                        name="class"
                        render={({field: {onChange, value}}) => {

                            return <SelectBox
                                title="Classe"
                                options={studentClasses.map(x => ({label: x, value: x})).slice(0, -1)}
                                value={value}
                                onSingleSelect={(value) => {
                                    onChange(value.value);
                                }}
                                style={{width: "100%"}}
                                valueMode={true}
                            />
                        }
                        }/>
                    <TextInputM
                        placeholder={`Nom du chapitre`}
                        name="name"
                        labelTop={"Nom du chapitre"}
                        control={control}
                        errors={errors}
                        contentContainerStyle={{width: "100%", marginBottom: 10}}
                    />
                </View>
                <FilledButtonM label={"Continuer"}
                               onPress={handleSubmit(onSubmit)}
                               disabled={!isValid}
                               style={{
                                   width: "100%"
                               }}/>
                <FilledButtonM label={"Annuler"}
                               mode={"text"}
                               onPress={() => {
                                   handleShowModal()
                               }}
                               style={{
                                   width: "100%"
                               }}/>
            </View>
        </ModalM>
    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        chapters: state.data?.chapters?.chaptersList
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(NewChapterModal)