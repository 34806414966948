import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import React, {useContext} from "react";
import {AbilityContext} from "@data/utility/Can";
import moment from "moment";
import {getPrivilegedRole, hasRole} from "@data/utility/ability";
import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import {productsLightColors, SubscriptionPrivilegeChip} from "../SubscriptionPrivilegeChip";
import {useNavigation} from "@react-navigation/native";
import {View} from "react-native";
import {formatDate} from "@data/utility/Utils";

export function SubscriptionStateCard({user, style, disabled}) {

    const ability = useContext(AbilityContext)
    const navigation = useNavigation()
    const onPress = () => {
        navigation.push("my-privileges")
    }
    return hasRole(user, "student") ? <>
            {/*<TextM fontWeight="Light" style={{marginTop: 20}}>Mes privilèges</TextM>*/}
            <TouchableHighlightM style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // marginHorizontal: 20,
                padding: 15,
                // borderRadius: 15,
                flexWrap: "wrap",
                // marginTop: 20,
                // backgroundColor: bgColor,
                ...style
            }}
                                 onPress={onPress}
                                 disabled={disabled}
                                 underlayColor={theme.colors.lightMore}
            >
                <>
                    <View style={{flexDirection: "row"}}>
                        <SubscriptionPrivilegeChip product={getPrivilegedRole(user)}
                                                   style={{marginRight: 10}}/>
                        {ability.can("read", "holiday_course_views") && <SubscriptionPrivilegeChip product={"holidaycourse"}
                                                                                                   style={{marginRight: 10}}/>}
                    </View>
                    <View>
                        {moment(user?.trial_ends_at).isAfter(moment()) ?
                            <TextM style={{marginRight: 5}} wrap>Fin de l'essai gratuit :
                                le {moment(user?.trial_ends_at).format("DD MMMM YYYY")}</TextM> :
                            <TextM fontWeight="Medium" color={theme.colors.grey} style={{marginRight: 5}} wrap
                                   fontSize={14}>Voir
                                les détails</TextM>
                        }
                    </View>
                </>
            </TouchableHighlightM>
        </>
        : null

}