import {Pressable, View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import {PetalCounter} from "@common/components/statistics/Counters";
import React from "react";

export function ShopProductListItem({shopProduct, onPress, countPurchases}) {
    return <Pressable onPress={onPress}
                      style={{
                          borderWidth: 1,
                          borderRadius: 15,
                          backgroundColor: theme.colors.white,
                          borderColor: theme.colors.lightMore,
                          marginBottom: 20,
                          marginHorizontal: 20,
                          flexDirection: "row",
                          padding: 10,
                          // flexWrap: "wrap",
                          // flexGrow: 0
                      }}>
        <IconImageM uri={shopProduct?.icon_path} width={100} style={{borderRadius: 15}}/>
        <View style={{
            flex: 1,
            marginLeft: 15,
            alignItems: "flex-start"
        }}>
            <TextM fontWeight="Bold" fontSize={16} wrap style={{}}>{shopProduct?.name}</TextM>
            <TextM fontWeight="Medium" fontSize={null} wrap
                   style={{marginTop: 5}}>{shopProduct?.short_description}</TextM>
            {countPurchases > 0 && <TextM fontWeight="Light" fontSize={12} wrap style={{marginTop: 5,}}>Acheté {countPurchases} fois</TextM>}
            <PetalCounter count={shopProduct?.petals_value} style={{marginTop: 8}} small/>
        </View>

    </Pressable>;
}