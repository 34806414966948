export const cameraConstants = {
    RECORD_CAMERA_SETTINGS: 'RECORD_CAMERA_SETTINGS',
    RESET_CAMERA_SETTINGS: 'RESET_CAMERA_SETTINGS',
    NEW_PHOTO: 'NEW_PHOTO',
    NEW_PHOTOS: 'NEW_PHOTOS',
    PHOTO_CLEAR: 'PHOTO_CLEAR',
    PHOTOS_CLEAR: 'PHOTOS_CLEAR',
    NEW_CHAT_PHOTO: 'NEW_CHAT_PHOTO',
    SET_VIDEO_RECORD: 'SET_VIDEO_RECORD',
}
