import {useNavigation} from "@react-navigation/native";
import React, {useContext, useEffect, useState} from "react";
import {Platform, ScrollView, View} from "react-native";
import InterviewReportComponent from "../interviewReports/interviewReportComponent";
import FilledButtonM from "../components/button/FilledButtonM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import TextM from "../components/text/TextM";
import {apiIdle, apiRequest} from "../../data/redux/actions/api";
import {connect} from "react-redux";
import {interviewReportService} from "../../data/services/interviewReportService";
import {useApiStatus} from "../hooks/useApiStatus";
import moment from "moment";
import {theme} from "../theme/theme";
import {ChampionImageM} from "../../student/champions/ChampionImageM";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";

const WeeklyReportScreen = (props) => {
    const navigation = useNavigation();
    const dimensions = useContext(DimensionsContext);

    const hash = props.route?.params?.h ?? null

    const {id} = props.route.params;

    const interviewReport = id
        ? findItemInListWithId(id, props.interviewReports)
        : null;

    const [interviewReportFromHash, setInterviewReportFromHash] = useState(null);
    const [schoolSubjects, setSchoolSubjects] = useState([]);
    const [prioritizedSchoolSubjects, setPrioritizedSchoolSubjects] = useState(
        props.prioritizedSchoolSubjects
    );

    useEffect(() => {
        if (hash) {
            props.apiRequest(interviewReportService.getInterviewReport, {hash: hash});
        }
    }, []);

    useApiStatus(
        interviewReportService.getInterviewReport, // api service
        false, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data)
            setInterviewReportFromHash(data?.interview_report);
            setSchoolSubjects(data?.school_subjects);
            setPrioritizedSchoolSubjects(data?.prioritized_school_subjects);
        } // success callback
    );

    const report = interviewReportFromHash ?? interviewReport;
    const currentWeekChapters = report?.statistics?.current_week_chapters;
    const nextWeekChapters = report?.statistics?.next_week_chapters;

    const startDate = moment(report?.start_date).format("DD MMMM");
    const endDate = moment(report?.end_date).format("DD MMMM");

    return (props.getInterviewReportRequest ? (
            <ActivityIndicator color={theme.colors.primary} style={{marginTop: 10}}/>
        ) : props.getInterviewReportError ? (
            <>
                <TextM
                    color={theme.colors.error}
                    wrap
                    style={{
                        marginBottom: 20,
                        textAlign: "center"
                    }}
                    fontWeight={"Bold"}
                    fontSize={14}
                >
                    {props.getInterviewReportError.message}
                </TextM>
                <FilledButtonM
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack();
                        else navigation.replace("menu");
                    }}
                    label={"Retour à l'accueil"}
                />
            </>
        ) : <>
            {hash && <HeaderContainerM>
                <View style={{alignItems: "center"}}>
                    <TitleM>Rapport hebdomadaire</TitleM>
                    <TextM wrap>
                        {report?.student?.display_name} - Période du {`${startDate} au ${endDate}`}
                    </TextM>
                </View>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack())
                            navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>}
            <ScrollView contentContainerStyle={{
                marginTop: 10,
                backgroundColor: theme.colors.whiteAlmost,
                width: dimensions?.width,
                alignSelf: "center"
            }}>
                <ChampionImageM
                    style={{alignSelf: "center", marginVertical: 20}}
                    name="zoe"
                    variant={
                        report?.statistics?.zoe_variant
                            ? report?.statistics?.zoe_variant
                            : !report?.statistics
                                ? "main"
                                : "happy"
                    }
                    width={100}
                />
                <TextM
                    color={theme.colors.primary}
                    fontSize={18}
                    fontWeight={"Bold"}
                    style={{textAlign: "center", marginBottom: 10}}
                    wrap
                >
                    {!report?.statistics
                        ? "Malheureusement, nous n’avons pas eu la chance de te voir aujourd’hui. Quel dommage..."
                        : report?.statistics?.CTAS?.zoe}
                </TextM>

                {report?.statistics?.can_activate_subscription && <FilledButtonM
                    label={"J’active mon abonnement"}
                    color={theme.colors.success}
                    style={{
                        width: dimensions.width - 60,
                        alignSelf: "center",
                        marginTop: Platform.OS !== "web" ? null : 15,
                        marginBottom: Platform.OS !== "web" ? null : 15
                        // bottom: 15
                    }}
                    onPress={() => {
                        const subscribeUrl = report?.statistics?.subscribe_url;
                        if (IS_WEB) {
                            window.open(subscribeUrl);
                        } else {
                            WebBrowser.openBrowserAsync(subscribeUrl);
                        }
                    }}
                />}

                <View
                    style={{
                        width: dimensions.width,
                        marginTop: 20
                    }}
                >
                    <InterviewReportComponent
                        interviewReport={interviewReportFromHash ?? interviewReport}
                        withTitle={false}
                        forAdmin={hash === null}
                        forParent={hash && hash === interviewReportFromHash?.parent_hash}
                        forTutor={hash && hash === interviewReportFromHash?.tutor_hash}
                        schoolSubjects={schoolSubjects}
                        prioritizedSchoolSubjects={prioritizedSchoolSubjects}
                        currentWeekChapters={currentWeekChapters}
                        nextWeekChapters={nextWeekChapters}
                    />
                </View>

                {!!report?.statistics?.CTAS?.sam && (
                    <>
                        <ChampionImageM
                            style={{alignSelf: "center", marginTop: 40, marginBottom: 20}}
                            name="sam"
                            variant={"happy"}
                            width={100}
                        />
                        <TextM
                            color={theme.colors.primary}
                            fontSize={18}
                            fontWeight={"Bold"}
                            style={{textAlign: "center", marginBottom: 10}}
                            wrap
                        >
                            {report?.statistics?.CTAS?.sam}
                        </TextM>

                        <FilledButtonM
                            label={"J’active mon abonnement"}
                            color={theme.colors.success}
                            style={{
                                width: dimensions.width - 60,
                                alignSelf: "center",
                                marginTop: Platform.OS !== "web" ? null : 15,
                                marginBottom: Platform.OS !== "web" ? null : 15
                                // bottom: 15
                            }}
                            onPress={() => {
                                const subscribeUrl = report?.subscribe_url;
                                if (IS_WEB) {
                                    window.open(subscribeUrl);
                                } else {
                                    WebBrowser.openBrowserAsync(subscribeUrl);
                                }
                            }}
                        />
                    </>
                )}
            </ScrollView>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        getInterviewReportRequest: state.api.getInterviewReport?.request,
        getInterviewReportError: state.api.getInterviewReport?.error,
        interviewReports: state.data.interviewReports?.interviewReportsList,
        prioritizedSchoolSubjects:
        state.data.interviewReports?.prioritizedSchoolSubjects
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyReportScreen);
