import React from "react";
import SpeechBubble from "./index";
import {theme} from "../../theme/theme";
import {Platform, View} from "react-native";

export const SpeechBubbleM = React.forwardRef((props, ref) => {
    const {
        texts,
        onNextPress,
        containerStyle,
        textStyle,
        position,
        noAnimation = false,
        allowSpeechReplay = false,
        hideIcons = true,
    } = props
    return <View style={{
        // backgroundColor: theme.colors.accentLighter,
        ...containerStyle
    }}
    >

        <SpeechBubble speeches={texts}
                      ref={ref}
                      noAnimation={noAnimation}
                      allowSkip={true}
                      allowSpeechReplay={allowSpeechReplay}
                      hideIcons={hideIcons}
                      writingDelay={Platform.OS === "ios" ? 10 : Platform.OS === "web" ? 20 : 1}
                      position={position}
                      onSpeechReplay={onNextPress}
                      speechBubbleStyle={{
                          // flex:2,
                          // borderColor: theme.colors.lightMore,
                          // borderWidth: 1.5,
                          backgroundColor: theme.colors.lighter,
                          borderRadius: 15,
                          paddingVertical: 10,
                          paddingHorizontal: 15,
                          // borderTopWidth: 1.5,
                          // borderRightWidth: 1.5,
                          // borderColor: theme.colors.samLight
                          // marginBottom: 20,
                      }}
                      typeWriterStyle={{
                          color: theme.colors.grey,
                          fontFamily: "Montserrat-Medium",
                          // flexShrink: 1,
                          // alignSelf: 'center',
                          ...textStyle
                      }}
                      numberOflines={props.numberOfSpeechLines}
        />

    </View>
});