import React, {useContext, useState} from 'react';
import {backOfficeService} from "@data/services/backOfficeService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import UsersTable from "../UsersTable";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";

const TutorsScreen = (props) => {

    const {tab} = props
    const role = ["tutor"]
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    const urlParams = {}
    const [tutors, setTutors] = useState([]);

    useApiStatus(
        backOfficeService.searchTutors, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            setTutors(data?.users)
            // console.log(data)
        } // success callback
    )

    return <View style={{width: dimensions?.width * 3 / 2, flex: 1}}>
        <HeaderContainerM>
            <TitleM>Liste des tuteurs</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>
        {props.usersRequest ? <ActivityIndicator color={theme.colors.primary}/> :
            <UsersTable
                tabIndex={1}
                urlParams={{roles: 'tutor'}}
                tab={"tutor"}
            />
        }
    </View>
}
const mapStateToProps = state => {
    return {
        usersRequest: state.api?.searchTutors?.request,
        users: state.data?.users?.usersList,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorsScreen)