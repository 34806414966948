export const hairs = {
    hair0: true,
    hair1: true,
    hair2: true,
    hair3: true,
    hair4: true,
    hair5: true,
    hair6: true,
    hair7: true,
    hair8: true,
};
export const hairsList = [
    {
        name: "hair0",
    }, {
        name: "hair1",
    }, {
        name: "hair2",
    }, {
        name: "hair3",
    }, {
        name: "hair4",
    }, {
        name: "hair5",
    }, {
        name: "hair6",
    }, {
        name: "hair7",
    }, {
        name: "hair8",
    }
];

export const hairColors = {
    hc1: 'blanc',
    hc2: 'blond',
    hc3: 'roux',
    hc4: 'marron',
    hc5: 'noir',

};

export const hairColorsList = [
    {
        name: "hc1",
        color: "#dedede"
    }, {
        name: "hc2",
        color: "#e7da56"
    }, {
        name: "hc3",
        color: "#c9853d"
    }, {
        name: "hc4",
        color: "#824f3a"
    },
    {
        name: "hc5",
        color: "#1a1a1a"
    },
];