import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {TutorSlotsPicker} from "../tutor/home/TutorSlotsPicker";
import {weekDayNums} from "@data/constants/activitiesConstants";
import {subscriptionService} from "@data/services/subscriptionService";
import base64 from "@common/utils/base64";
import {DialogM} from "@common/components/alert/dialog/Dialog";


const AvailabilitySlotsScreen = (props) => {

    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}

    const user = props.user

    const userEmailb64 = props.route?.params?.user ?? ""
    const userEmail = base64.decode(userEmailb64.replace(/-/g, ""))

    const availabilitySlots = formValues?.availability_slots

    //calculate if the following rule is respected : at least three slots, or, if two slots, they must be at least 1 day apart calculate with momentjs
    const isAvailabilitySlotsValid = availabilitySlots?.length >= 3 || (availabilitySlots?.length === 2 && Math.abs(weekDayNums[availabilitySlots?.[0]?.split(" ")[0]] - weekDayNums[availabilitySlots?.[1]?.split(" ")[0]]) !== 1)

    const formData = [
        {
            label: "Indique tes disponibilités",
            description: "Sélectionne au moins trois jours différents pour faciliter la planification des sessions Live.",
            name: "availability_slots",
            type: "custom",
            defaultValue: user?.slots_possible ?? [],
            component: TutorSlotsPicker,
            showNextButton: isAvailabilitySlotsValid,
            validationRules: {...validation.required()}
        }
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(formValues)

    }, [formValues])

    const onSubmit = data => {
        if (props.request) return

        props.apiRequest(subscriptionService.updateAvailabilitySlots, {}, {
            ...data,
            email: userEmail
        })

    }
    useApiStatus(
        subscriptionService.updateAvailabilitySlots, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("subscribe", {user: base64.encode(props.user?.email)})

            DialogM.show({
                text1: "Félicitations !",
                text2: "Les disponibilités de "+ props.user?.firstname +" ont bien été enregistrées.",
            })
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.request}
                         submittedMessage=""
                         serverError={props.error}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        user: state.data.formData?.user,
        billingPortalUrl: state.data.formData?.billingPortalUrl,
        pricingTableData: state.data.formData?.pricingTableData,
        schoolSubjects: state.data.formData?.schoolSubjects,
        pricingTableDataNextMode: state.data.formData?.pricingTableDataNextMode,
        request: state.api.updateAvailabilitySlots?.request,
        error: state.api.updateAvailabilitySlots?.error,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilitySlotsScreen)
