
import {    MD2LightTheme as DefaultTheme,} from 'react-native-paper';

import {configureFonts} from "react-native-paper";

const fontConfig = {
    web: {
        black: {
            fontFamily: 'Montserrat-Black',
            fontWeight: 'normal',
        },
        bold: {
            fontFamily: 'Montserrat-Bold',
            fontWeight: 'normal',
        },
        extrabold: {
            fontFamily: 'Montserrat-ExtraBold',
            fontWeight: 'normal',
        },
        semibold: {
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: 'normal',
        },
        regular: {
            fontFamily: 'Montserrat-Regular',
            fontWeight: 'normal',
        },
        medium: {
            fontFamily: 'Montserrat-Medium',
            fontWeight: 'normal',
        },
        light: {
            fontFamily: 'Montserrat-Light',
            fontWeight: 'normal',
        },
        thin: {
            fontFamily: 'Montserrat-Thin',
            fontWeight: 'normal',
        },
    },
    ios: {
        black: {
            fontFamily: 'Montserrat-Black',
            fontWeight: 'normal',
        },
        bold: {
            fontFamily: 'Montserrat-Bold',
            fontWeight: 'normal',
        },
        extrabold: {
            fontFamily: 'Montserrat-ExtraBold',
            fontWeight: 'normal',
        },
        semibold: {
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: 'normal',
        },
        regular: {
            fontFamily: 'Montserrat-Regular',
            fontWeight: 'normal',
        },
        medium: {
            fontFamily: 'Montserrat-Medium',
            fontWeight: 'normal',
        },
        light: {
            fontFamily: 'Montserrat-Light',
            fontWeight: 'normal',
        },
        thin: {
            fontFamily: 'Montserrat-Thin',
            fontWeight: 'normal',
        },
    },
    android: {
        black: {
            fontFamily: 'Montserrat-Black',
            fontWeight: 'normal',
        },
        bold: {
            fontFamily: 'Montserrat-Bold',
            fontWeight: 'normal',
        },
        extrabold: {
            fontFamily: 'Montserrat-ExtraBold',
            fontWeight: 'normal',
        },
        semibold: {
            fontFamily: 'Montserrat-SemiBold',
            fontWeight: 'normal',
        },
        regular: {
            fontFamily: 'Montserrat-Regular',
            fontWeight: 'normal',
        },
        medium: {
            fontFamily: 'Montserrat-Medium',
            fontWeight: 'normal',
        },
        light: {
            fontFamily: 'Montserrat-Light',
            fontWeight: 'normal',
        },
        thin: {
            fontFamily: 'Montserrat-Thin',
            fontWeight: 'normal',
        },
    }
};

export const theme = {
    ...DefaultTheme,
    fonts: configureFonts({config: fontConfig, isV3: false}),
    colors: {
        ...DefaultTheme.colors,
        background: "#fafafa",
        primary: '#223F8F',
        primaryTransparent: '#223F8FAA',
        primaryLight: '#999db3',
        primaryLighter: '#f3f5ff',
        accentDark: '#f1ba00',
        accent: '#ffc80a',
        accentLight: '#FFDC64',
        accentLighter: '#fff1db',
        successDark: '#249e7f',
        success: '#00BE89',
        successTransparent: '#01AE5144',
        phone: '#4CABB4',
        phoneLight: '#cdfbff',
        successLighter: '#59bba0',
        successGray: '#bad3cb',
        successLight: '#f1fff3',
        successLightMore: '#f7fff8',
        warning: '#ff9f43',
        warningLight: '#fff1e6',
        warningLighter: '#ffb980',
        error: '#ea5455',
        errorLight: '#ffe5e5',
        errorLighter: '#ff7171',
        errorDark: '#662526',
        brown: '#895D33',
        info: '#00cfe8',
        white: '#ffffff',
        whiteTransparentMore: '#ffffff99',
        whiteTransparent: '#ffffff44',
        light: '#aaaaaa',
        lightMore: '#e2e2e2',
        lightMoreTransparent: '#e2e2e244',
        lighter: '#F3F3F3',
        whiteAlmost: '#fafafa',
        linkAlt: '#662FFF',
        link: '#2F66FF',
        linkLight: '#779bff',
        linkLighter: '#e0ebff',
        xp: '#01AE51',
        xpText: '#004D24',
        feather: '#2D2F38',
        greyAlt: '#6E6E6E',
        grey: '#505050',
        greyBg: '#F2F2F2',
        black: '#000000',
        blackTransparent: '#00000033',
        blackTransparent2: '#00000066',
        petal: '#9DD767',
        petalDarker: '#80af54',
        petalNew: '#D3918F',
        petalNewDarker: '#985857',
        gems: '#BC3B60',
        gemsLight: '#ffe5ed',
        transparent: '#FFFFFF00',
        purple: '#7A6EB5',
        purpleTransparent: '#7A6EB588',
        purpleLight: '#faf9ff',
        zoe: '#D98759',
        zoeLight: '#F2EB64',
        abou: '#9650C9',
        abouLight: '#DCC1F7',
        sam: '#3B708E',
        samLight: '#8ABFEA',
        tina: '#45D090',
        tinaLight: '#9CF6C7',
        tintColors: [
            "#FF6B6B",  // A vibrant red
            "#4ECDC4",  // A calming teal
            "#FFD166",  // A sunny yellow
            "#06D6A0",  // A bright turquoise
            "#8338EC",  // A lively purple
            "#FF9F1C",  // A zesty orange
            "#3A86FF",  // A clear blue
            "#118AB2",  // A deep ocean blue
            "#EE6352",  // A warm coral
            "#6F2DBD"   // A rich violet
        ],
        material: {
            "primary": {
                "50": "#e7eaf4",
                "100": "#c2c9e3",
                "200": "#9aa6d0",
                "300": "#7284be",
                "400": "#536ab1",
                "500": "#3250a4",
                "600": "#2c499b",
                "700": "#223f8f", // main primary
                "800": "#1a3583",
                "900": "#0a256d"
            },
            "secondary": {
                "50": "#fff8e0",
                "100": "#ffecb0",
                "200": "#ffe07c",
                "300": "#ffd544",
                "400": "#ffc80a", // main secondary
                "500": "#ffc100",
                "600": "#ffb200",
                "700": "#ff9f00",
                "800": "#ff8d00",
                "900": "#ff6b00"
            }
        },
    },
    roundness: 15,
    animation: {
        scale: 1.0,
    },
};