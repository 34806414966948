import React, {useContext, useState} from "react";
import {Linking, Platform, ScrollView, TouchableOpacity, View} from "react-native";
import TextM from "@common/components/text/TextM";
import TitleM from "@common/components/text/TitleM";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {StatusBar} from "expo-status-bar";
import {Card, Text} from "react-native-paper";
import moment from "moment";
import * as WebBrowser from "expo-web-browser";
import {useController} from "react-hook-form";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import {NotificationSettingsItem} from "@common/account/NotificationSettingsItem";
import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import {useNavigation} from "@react-navigation/native";
import * as StoreReview from 'expo-store-review';
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";

const SettingsConfigurationScreen = (props) => {
    const {control, errors, name} = props;

    const [isTimePickerVisible, setTimePickerVisibility] = useState(false);
    const showTimePicker = () => {
        setTimePickerVisibility(true);
    };
    const hideTimePicker = () => {
        setTimePickerVisibility(false);
    };

    const navigation = useNavigation();

    const {field} = useController({
        control,
        name: "notification_settings"
    });

    const roleNotifications = props.notification?.filter(
        (notif) => notif?.roles?.filter((nRole) => props.user?.roles?.includes(nRole))?.length > 0
    );

    const customTimeTrue = roleNotifications?.filter(function (item) {
        return item.config?.custom_time === true;
    });
    const customTimeFalse = roleNotifications?.filter(function (item) {
        return item.config?.custom_time === false;
    });
    const dimensions = useContext(DimensionsContext)

    return (
        <View
            style={{
                flex: 1,
                // backgroundColor: "#fff",
                paddingTop: 0,
                justifyContent: "space-between",
                width: Platform.OS === 'web' ? dimensions?.width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
        >
            <ScrollView>
                <View
                    style={{
                        marginHorizontal: 15,
                        marginTop: 20
                    }}
                >
                    <TitleM fontWeight="ExtraBold">Notifications</TitleM>

                    {customTimeTrue?.length > 0 && <Card style={{
                        marginTop: 20,
                        borderColor: theme.colors.lightMore,
                        borderWidth: 1,
                        backgroundColor: theme.colors.white
                    }}>
                        {customTimeTrue?.map((x, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <NotificationSettingsItem notificationItem={x}
                                                              field={field}
                                                              control={control}
                                                              errors={errors}/>

                                    <View
                                        key={i}
                                        style={{
                                            // borderColor: theme.colors.lightMore,
                                            flexDirection: "row",
                                            alignItems: "center",
                                            width: "100%",
                                            paddingVertical: 20
                                        }}
                                    >
                                        <TextM
                                            fontWeight="Bold"
                                            color={theme.colors.grey}
                                            wrap
                                            fontSize={16}
                                            style={{paddingLeft: 10}}
                                        >
                                            Heure de rappel
                                        </TextM>

                                        <TouchableOpacity
                                            key={i}
                                            disabled
                                            style={{position: "absolute", right: 30, paddingHorizontal: 10}}
                                            onPress={showTimePicker}
                                        >
                                            <TextM
                                                control={control}
                                                fontWeight="Bold"
                                                color={theme.colors.grey}
                                                wrap
                                                fontSize={16}
                                            >
                                                {field.value?.[x.slug]?.custom_time ?? "19:00"}
                                            </TextM>
                                        </TouchableOpacity>

                                        <DateTimePickerModal
                                            isVisible={isTimePickerVisible}
                                            cancelTextIOS={"Annuler"}
                                            confirmTextIOS={"Confirmer"}
                                            mode="time"
                                            onConfirm={(time) => {
                                                let filedValue = {
                                                    ...field.value,
                                                    [x.slug]: {
                                                        channels: {
                                                            email: field.value?.[x.slug]?.channels?.email ?? false,
                                                            push: field.value?.[x.slug]?.channels?.push
                                                        },
                                                        custom_time: moment(time).format("HH:mm")
                                                    }
                                                };
                                                field.onChange(filedValue);
                                                hideTimePicker();
                                            }}
                                            onCancel={hideTimePicker}
                                            name="custom_time"
                                            is24Hour
                                        />
                                    </View>
                                </React.Fragment>
                            );
                        })}
                    </Card>}
                    <Card
                        style={{
                            marginTop: 20,
                            marginBottom: 20,
                            borderColor: theme.colors.lightMore,
                            borderWidth: 1,
                            backgroundColor: theme.colors.white
                        }}
                    >
                        {customTimeFalse?.map((x, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <NotificationSettingsItem notificationItem={x}
                                                              lastItem={i === customTimeFalse?.length - 1}
                                                              field={field}
                                                              control={control}
                                                              errors={errors}/>
                                </React.Fragment>
                            );
                        })}
                    </Card>
                    <TitleM fontWeight="ExtraBold">Aide</TitleM>
                    <FilledButtonM
                        mode="outlined"
                        style={{
                            borderColor: theme.colors.primary,
                            borderRadius: 10,
                            marginTop: 20
                        }}
                        onPress={() => {
                            if (IS_WEB)
                                location.href = "mailto:support@masteur.com"
                            else
                                Linking.openURL("mailto:support@masteur.com").catch(err => console.error("Couldn't load page", err))
                        }}
                        label={"Contacter le support"}
                    />

                    <TouchableHighlightM
                        underlayColor={theme.colors.lightMore}
                        style={{
                            width: "100%",
                            paddingVertical: 10
                        }}
                        onPress={() => {
                            navigation.push("account-close")
                        }}
                    >
                        <Text style={{}}>

                            <TextM wrap
                                   fontSize={12}>Pour supprimer définitivement ton compte, </TextM>
                            <TextM wrap
                                   style={{
                                       textDecorationLine: 'underline'
                                   }}
                                   fontSize={12}>appuie
                                ici</TextM>
                            <TextM wrap
                                   fontSize={12}>.</TextM>
                        </Text>
                    </TouchableHighlightM>

                    <TitleM fontWeight="ExtraBold" style={{marginTop: 20}}>
                        À propos
                    </TitleM>

                    <FilledButtonM
                        mode="outlined"
                        style={{
                            borderColor: theme.colors.primary,
                            borderRadius: 10,
                            marginTop: 20
                        }}
                        onPress={() => {
                            WebBrowser.openBrowserAsync(
                                "https://masteur.com/confidentialite"
                            );
                        }}
                        label={"Confidentialité"}
                    />

                    <FilledButtonM
                        mode="outlined"
                        style={{
                            borderColor: theme.colors.primary,
                            borderRadius: 10,
                            marginTop: 10,
                        }}
                        onPress={() => {
                            WebBrowser.openBrowserAsync(
                                "https://masteur.com/mentions-legales"
                            );
                        }}
                        label={"Mentions légales"}
                    />


                    {!IS_WEB && <FilledButtonM
                        mode="outlined"
                        style={{
                            borderColor: theme.colors.primary,
                            borderRadius: 10,
                            marginTop: 10,
                            marginBottom: 50
                        }}
                        onPress={() => {
                            Linking.openURL((StoreReview.storeUrl()) + (Platform.OS === 'ios' ? "?action=write-review" : "&showAllReviews=true"));
                        }}
                        label={"Noter l'application"}
                    />}

                    {/*<LogoutButton/>*/}

                </View>
            </ScrollView>

            <StatusBar
                style="dark"
                backgroundColor={theme.colors.white}
                translucent={false}
            />
        </View>
    );
};

const mapStateToProps = (state) => {
    return {

        user: state.data.currentUser?.object,
        expoToken: state.data.session?.object?.expo_token,

        getUserRequest: state.api.getUser?.request,
        successes: state.data.staticData?.successes,
        notification: state.data.staticData?.notifications,
        token: state.data.session?.object?.token
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsConfigurationScreen);
