import {ScrollView, TouchableOpacity, View} from "react-native";
import React, {useContext} from "react"
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {useNavigation} from "@react-navigation/native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {hasRole} from "@data/utility/ability";
import TextM from "@common/components/text/TextM";
import {BadgeM} from "@common/components/badge/BadgeM";
import {connect} from "react-redux";
import {formatFullDateTime} from "@data/utility/Utils";
import {StudentSlot} from "../SlotModal";
import {IconButton} from "react-native-paper";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {sortByDateAttribute} from "@data/utility/dataUtils";
import * as PropTypes from "prop-types";
import {boxedCardBorderStyle} from "../../reviewer/BoxedCardBorderStyle";


function InterviewCard({interview, onPress, disabled}) {
    return <TouchableOpacity style={{
        marginBottom: 20,
        backgroundColor: theme.colors.white,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: theme.colors.lightMore,
        alignSelf: "center",
        ...boxedCardBorderStyle,
        paddingHorizontal: 20,
        paddingVertical: 20,
        // marginHorizontal: IS_WEB ? 10 : 0,
        width: "100%",
        opacity: (disabled) ? 0.6 : 1
    }}
                             disabled={disabled}
                             onPress={onPress}

    >
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>

            <TextM wrap
                   style={{
                       // paddingHorizontal: 20
                   }}>
                {formatFullDateTime(interview?.date_time)}
            </TextM>

            {!!interview.finalized_at &&
                <BadgeM style={{color: theme.colors.success}} count={"Terminé"}/>}
        </View>
    </TouchableOpacity>;
}

InterviewCard.propTypes = {};

function InterviewHistory(props) {
    const student = hasRole(props.user, "student") ? props.user : !props.route.params?.isInBackOffice ? props.students?.find(x => x?.id === parseInt(props.route.params?.studentId)): props.users?.find(x => x?.id === parseInt(props.route.params?.studentId)) ?? null
    const isCurrentUserStudentTutor = props.user?.id === student?.tutor?.id
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)

    const interviews = student?.interviews
    const finishedInterviews = sortByDateAttribute(interviews?.filter(x => !!x?.finalized_at), "finalized_at", false)
    const futureInterviews = interviews?.filter(x => !x?.finalized_at)

    const renderInterviewsList = (interviews, title) => <View style={{marginTop: 20}}>
        <TextM fontSize={18} fontWeight={"Bold"} color={theme.colors.primary}>{title}</TextM>
        {interviews?.length > 0 ? <View style={{
            marginVertical: 10,
            width: dimensions.width,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignSelf: 'center',
            alignItems: 'center'
        }}>
            {interviews?.map((el, index) => {
                return (

                    <InterviewCard key={index}
                                   interview={el}
                                   disabled={!isCurrentUserStudentTutor && !el.finalized_at}
                                   onPress={() => {
                                       navigation.push("interview", {
                                           interview_id: el.id,
                                           student_id: student.id,
                                           isInBackOffice: props.route.params?.isInBackOffice
                                       })
                                   }}/>
                )
            })}

        </View> : <TextM style={{marginTop: 20, textAlign: "center"}}>
            Aucun élément
        </TextM>}
    </View>

    //const list = props.route.params?.user === "isStudent" ? student?.interviews.filter(i => !!i.finalized_at) : student?.interviews
    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'flex-start',
            alignSelf: "center",
            width: "100%"
            // paddingTop: 5
        }}>
            <HeaderContainerM>
                <TitleM>Entretiens</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>
            <ScrollView contentContainerStyle={{
                width: dimensions?.width
            }}>

                <View style={{alignItems: "center"}}>
                    {isCurrentUserStudentTutor &&
                        <StudentSlot tutor={isCurrentUserStudentTutor ? props.user : student?.tutor}
                                     slotId={student?.interview_slot?.id}
                                     studentId={student?.id}
                                     dimensions={dimensions}
                        />}

                    {isCurrentUserStudentTutor && <FilledButtonM mode="outlined"
                                                                 label={"Modifier le créneau"}
                                                                 style={{
                                                                     borderColor: theme.colors.primary,
                                                                     borderRadius: 10,
                                                                     // marginTop: 20,
                                                                     marginBottom: 20
                                                                 }}
                                                                 onPress={() => {
                                                                     navigation.push("update-occupied-slots", {
                                                                         slotId: student?.interview_slot?.id
                                                                     })
                                                                 }}
                    />}


                </View>
                {renderInterviewsList(finishedInterviews, "Terminés")}
                {renderInterviewsList(futureInterviews, "Planifiés")}

            </ScrollView>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        getStudentRequest: state.api.getStudents?.request,
        interviewFinalizeSuccess: state.api.interviewFinalize?.success,
        users: state.data.users?.list
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewHistory)