import React from 'react';
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {StatusBar} from "expo-status-bar";
import {ChampionImageM} from "../student/champions/ChampionImageM";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";


const SubscribeReturnScreen = (props) => {

    const status = props.route?.params?.status ?? ""

    const navigation = useNavigation()

    return (
        <View style={{
            alignItems: 'center',
            flex: 1,
            padding: 20
        }}>
            {status === "cancel" && <>
                <ChampionImageM name="zoe" variant={"unhappy"} width={140}/>
                <TitleM fontWeight="ExtraBold"
                        style={{textAlign: "center", marginTop: 10,  marginBottom: 20,color: theme.colors.grey}}
                        wrap>Opération annulée</TitleM>
                <FilledButtonM
                    onPress={() => {
                        navigation.goBack()
                    }}
                    label={"Retour"}
                />
            </>}

            {status === "success" && <>
                <ChampionImageM name="zoe" variant={"happy"} width={140}/>
                <TitleM fontWeight="ExtraBold"
                        style={{textAlign: "center", marginTop: 10, color: theme.colors.primary}}
                        wrap>Ton abonnement est activé !</TitleM>
                <TextM fontWeight="Medium" style={{textAlign: "center", marginBottom: 20}} wrap>Un mail de confirmation t'a été envoyé</TextM>
                <FilledButtonM
                    onPress={() => {
                        navigation.goBack()
                    }}
                    label={"Retour"}
                />
            </>}

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeReturnScreen)