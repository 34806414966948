import React, {useEffect} from "react"
import Pusher from "pusher-js";
import axios from "axios";
import {PUSHER_KEY} from "@data/constants/apiConstants";
import {setPusherStatus} from "@data/redux/actions/pusher";

export const initPusher = (dispatch) => {

    const [pusher, setPusher] = React.useState(null)

    useEffect(() => {

        const pusherNew = new Pusher(
            PUSHER_KEY,
            // "4df10506a95efb22bcee",
            {
                cluster: "eu",
                authorizer: (channel) => ({
                    authorize: async (socketId, callback) => {
                        try {
                            const response = await axios.post('/api/broadcasting/auth', {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                            callback(null, response.data);
                        } catch (err) {
                            callback(true, err.status);
                        }
                    }
                })
            })

        pusherNew.connection.bind('state_change', function (states) {
            // states = {previous: 'oldState', current: 'newState'}
            console.log(`onConnectionStateChange. previousState=${states.previous} newState=${states.current}`);
            dispatch(setPusherStatus(states.current))
        });

        setPusher(pusherNew)

    }, [])

    return pusher
}

