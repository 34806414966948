import React from 'react';
import {Text} from "react-native-paper";
import {theme} from "../../theme/theme";
import {StyleSheet} from "react-native";

const ErrorTextM = (props) => {

    const {fontWeight, errorData, style, marginTop = 10} = props
    const styles = StyleSheet.create({
        error: {
            color: theme.colors.error,
            fontFamily: "Montserrat-" + (fontWeight ?? "Regular"),
            // height: 30,
            marginTop: marginTop
        }
    });

    // console.log(errorData)

    return <>
        {(errorData && errorData?.errors) ?
            Object.keys(errorData?.errors)?.map(key => <Text
                style={{
                    ...style,
                    ...styles.error
                }}
                key={key}>{errorData?.errors[key][0]}</Text>) :
            (errorData && errorData?.message) ? <Text
                {...props}
                style={{
                    ...style,
                    ...styles.error
                }}
            >
                {errorData?.message ? errorData?.message : ""}
            </Text> : null
        }
    </>
}

export default ErrorTextM