import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dimensions, ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {roomService} from "@data/services/roomService";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import {theme} from "@common/theme/theme";
import {CardButtonM} from "../../../tutor/home/interview/TutorInterviewCard";
import TextM from "@common/components/text/TextM";
import AvatarM from "@common/components/avatar/AvatarM";
import RoomSessionsModal from "./RoomSessionsModal";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {capitalize, readableDayTime} from "@data/utility/Utils";
import moment from "moment";
import {findClosestNextOccurrence} from "../../../student/account/onboarding/LiveRoomCard";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {IconButton} from "react-native-paper";
import TitleM from "@common/components/text/TitleM";
import {MenuFilter} from "@common/components/menu/MenuFilter";
import ServerSearch from "../../ServerSearch";
import {
    availabilityFilter,
    liveDaysFilter,
    liveOccurrenceDatesFilter,
    roomTypes,
    roomTypesFilter
} from "@data/constants/formConstants";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {SchoolSubjectBadge} from "../../../tutor/students/ProfilDetails";

function RoomsScreen(props) {
    const navigation = useNavigation()
    const flatListRef = useRef()
    const [avgStudentsPerLiveSession, setAvgStudentsPerLiveSession] = useState(0);
    const dimensions = useContext(DimensionsContext)
    const [isOpen, setOpen] = useState(false)
    const [sessions, setSessions] = useState([])
    const [roomId, setRoomId] = useState(null)

    useEffect(() => {
        //const [a, setA] = useState(null);
        navigation.setParams({initialTab: props.tab})
    }, []);

    const handleShowModal = () => setOpen(!isOpen)

    const renderHeader = () => <>
        <View style={{flexDirection: "row", alignItems: "center", marginRight: 20}}>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    // position: "absolute",
                    marginRight: 10
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
            <TitleM>Salles de LIVE</TitleM>
        </View>
        <View style={{flexDirection: "row", alignItems: "center"}}>
            <MenuFilter style={{
                alignItems: "space-between",
                width: 400,
                marginBottom: 10
            }}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        filterItems={filterItems}
                        onFinishSelecting={onFinishSelecting}
            />
            <ServerSearch style={{
                width: 240,
                margin: 10
            }}
                          urlParams={urlParams}
                          setUrlParams={setUrlParams}
            />
        </View>
    </>

    const [urlParams, setUrlParams] = useState({})

    const [formValues, setFormValues] = useState(null)
    // console.log("formValues", formValues)
    const requestApiService = roomService.getRooms

    const urlParamsAdditional = {
        types: formValues?.types?.join(",") ?? "",
        days: formValues?.days?.join(",") ?? "",
        occurrence_dates: formValues?.occurrence_dates?.join(",") ?? "",
        availability: formValues?.availability?.join(",") ?? ""
    }

    // ** Filter action
    const onFinishSelecting = (newFormValues = null) => {
        if (newFormValues) {
            props.apiRequest(requestApiService, {}, {}, {
                ...urlParams,
                types: newFormValues?.types?.join(",") ?? "",
                days: newFormValues?.days?.join(",") ?? "",
                occurrence_dates: newFormValues?.occurrence_dates?.join(",") ?? "",
                availability: newFormValues?.availability?.join(",") ?? ""
            })
        } else {
            props.apiRequest(requestApiService, {}, {}, {...urlParams, ...urlParamsAdditional})
        }
    }

    // ** Filter options
    const filterItems = [
        {
            name: "types",
            label: "Type de salle",
            sectionMode: true,
            options: roomTypesFilter,
            defaultValue: []
        },
        {
            name: "days",
            label: "Jours",
            sectionMode: true,
            options: liveDaysFilter,
            defaultValue: []
        },
        {
            name: "occurrence_dates",
            label: "Prochaine occurrence",
            sectionMode: true,
            options: liveOccurrenceDatesFilter,
            defaultValue: []
        },
        {
            name: "availability",
            label: "Disponibilité",
            sectionMode: true,
            options: availabilityFilter,
            defaultValue: []
        }
    ]

    const onDelete = (id) => {
        setRoomId(id)
        handleShowModal()
        DialogM.show({
            text1: 'Veux-tu vraiment supprimer cette salle ?',
            text2: 'Toutes les sessions LIVE associées seront également supprimées !',
            buttonText1: 'Supprimer',
            buttonColor1: theme.colors.error,
            onPress: () => {
                props.apiRequest(roomService.deleteRoom, {id: id})
            },
            buttonText2: 'Annuler'
        })
    }

    useApiStatus(
        roomService.getRooms, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            setAvgStudentsPerLiveSession(data?.average_students_per_live)
        } // success callback
    )

    useApiStatus(
        roomService.deleteRoom, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(roomService.getRooms, {}, {}, {type: findItemInListWithId(roomId, props.rooms).type})
        } // success callback
    )
    return (
        <>
            <View style={{
                alignSelf: 'center',
                alignItems: 'center',
                width: Dimensions.get("window").width,
                flex: 1
            }}>
                <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}}>
                    {dimensions?.width < 800 ? <ScrollView horizontal={true} style={{width: "100%"}}>
                        <View style={{flexDirection: "row"}}>
                            {renderHeader()}
                        </View>
                    </ScrollView> : <>
                        {renderHeader()}
                    </>}
                </HeaderContainerM>
                <PaginatedFlatList ref={flatListRef}
                                   style={{
                                       width: "100%",
                                       flex: 1
                                   }}
                                   data={props.rooms}
                                   additionalOnRefresh={() => {
                                   }}
                                   additionalRefreshing={false}
                                   requestApiService={requestApiService}
                                   requestRouteParams={null}
                                   requestUrlParams={{...urlParams, ...urlParamsAdditional}}
                                   requestData={null}
                    //ListHeaderComponent={}
                                   renderItem={(item) => {

                                       const closestNextOccurrence = findClosestNextOccurrence(item.item?.live_sessions)
                                       const liveMoment = moment(closestNextOccurrence?.date)
                                       const isToday = liveMoment?.isSame(moment(), "day")
                                       const liveSession = item.item?.live_sessions?.find(ls => closestNextOccurrence?.live_session_id === ls?.id)

                                       const membersCountColor = item?.item?.type === "biweekly" ? item.item?.students_count < 2 ? theme.colors.error : item.item?.students_count < 3 ? theme.colors.warning : theme.colors.success : theme.colors.primary

                                       return <CardButtonM key={item.index}
                                                           onPress={() => {
                                                               handleShowModal()
                                                               setSessions(item?.item?.live_sessions)
                                                               setRoomId(item.item.id)
                                                           }}
                                                           membersCount={item.item?.students_count}
                                                           membersCountColor={membersCountColor}
                                           // studentsListString={item.item?.students_list_string}
                                                           liveSessionsCount={<>
                                                               <View style={{
                                                                   // flexDirection: 'row',
                                                                   // alignItems: 'center',
                                                                   // justifyContent: "flex-end",
                                                                   // width: "40%",
                                                                   backgroundColor: theme.colors.successLightMore,
                                                                   paddingHorizontal: 10,
                                                                   paddingVertical: 10,
                                                                   borderRadius: 10,
                                                                   borderColor: theme.colors.lightMore,
                                                                   borderWidth: 0.5
                                                               }}>
                                                                   <TextM color={theme.colors.success}
                                                                          fontWeight={"Bold"}
                                                                          style={{
                                                                              marginLeft: 5
                                                                          }}
                                                                          wrap>Prochaine session</TextM>
                                                                   <TextM color={theme.colors.grey}
                                                                       // fontWeight={"Medium"}
                                                                          style={{
                                                                              marginLeft: 5
                                                                          }}
                                                                          wrap>{!!closestNextOccurrence?.rescheduling_id &&
                                                                       <FontAwesome5 name={"pen"}
                                                                                     style={{marginRight: 5}}
                                                                                     color={theme.colors.success}
                                                                                     size={15}/>}{capitalize(isToday
                                                                       ? liveMoment.format("[Aujourd'hui à] HH[h]mm")
                                                                       : liveMoment.format("dddd DD MMMM [à] HH[h]mm"))}</TextM>
                                                                   <TextM color={theme.colors.grey}
                                                                       // fontWeight={"Medium"}
                                                                          fontSize={12}
                                                                          style={{
                                                                              marginLeft: 5
                                                                          }}
                                                                          wrap>{liveSession?.students_list_string === "" ? "-" : liveSession?.students_list_string}</TextM>
                                                                   {liveSession?.tutor?.id !== item?.item?.tutor?.id &&
                                                                       <TextM color={theme.colors.grey}
                                                                              fontWeight={"Medium"}
                                                                              fontSize={12}
                                                                              style={{
                                                                                  marginLeft: 5
                                                                              }}
                                                                              wrap><FontAwesome5 name={"retweet"}
                                                                                                 style={{marginRight: 5}}
                                                                                                 color={theme.colors.success}
                                                                                                 size={15}/>{liveSession?.tutor?.display_name}
                                                                       </TextM>}
                                                               </View>
                                                               {/*<View style={{*/}
                                                               {/*    flexDirection: "row",*/}
                                                               {/*    alignItems: "center",*/}
                                                               {/*    justifyContent: "flex-end",*/}
                                                               {/*    marginTop: 10,*/}
                                                               {/*}}>*/}
                                                               {/*    {item.item?.prioritized_school_subject_ids*/}
                                                               {/*        ?.map(x => props.schoolSubjects?.find(ss => ss?.id === x))*/}
                                                               {/*        ?.map((item) => {*/}
                                                               {/*        return <SchoolSubjectBadge key={item?.id}*/}
                                                               {/*                                   schoolSubject={item}/>*/}
                                                               {/*    })}*/}
                                                               {/*</View>*/}
                                                           </>
                                                           }
                                                           title={item.item?.name + " [" + roomTypes?.find(x => x?.value === item.item?.type)?.label?.substring(0, 3)?.toUpperCase() + "]"}
                                                           style={{
                                                               // marginBottom: 15,
                                                               width: dimensions?.width - 20,
                                                               backgroundColor: theme.colors.white
                                                           }}
                                                           width={dimensions?.width - 20}
                                                           description={
                                                               <View style={{
                                                                   maxWidth: 600
                                                               }}>

                                                                   <TextM
                                                                       wrap
                                                                       fontWeight={"Medium"}
                                                                       style={{
                                                                           marginLeft: 5,
                                                                           marginBottom: 10
                                                                       }}>
                                                                       {item.item?.live_sessions?.map(ls => readableDayTime(ls?.day_time, ls?.duration))?.join(", ")}
                                                                   </TextM>
                                                                   <View
                                                                       style={{
                                                                           flexDirection: 'row',
                                                                           alignItems: 'center'
                                                                       }}>
                                                                       <AvatarM user={item.item?.tutor} size={30}/>
                                                                       <TextM
                                                                           style={{marginLeft: 5}}
                                                                           color={theme.colors.primary}>{item.item?.tutor?.display_name}  </TextM>
                                                                   </View>
                                                               </View>
                                                           }/>
                                   }}

                />
                <View style={{flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "center"}}>
                    <FilledButtonM
                        icon={"plus"}
                        onPress={() => {
                            navigation.push("new-room", {
                                room_id: null
                            })
                        }}
                        label={"Ajouter"}
                    />
                    <TextM style={{position: "absolute", right: 20}}
                           fontWeight={"Bold"}
                           color={avgStudentsPerLiveSession < 2.7 ? theme.colors.error : theme.colors.primary}
                    >{avgStudentsPerLiveSession ?? "-"}</TextM>
                </View>
            </View>

            <RoomSessionsModal handleShowModal={handleShowModal}
                               isOpen={isOpen}
                               navigation={navigation}
                               sessions={sessions}
                               room_id={roomId}
                               onDelete={onDelete}
                               deleteRequest={props.deleteRoomRequest}
            />
        </>
    );
}

const mapStateToProps = state => {
    return {
        rooms: state.data.backOffice?.rooms,
        getRoomsRequest: state.api.getRooms?.request,
        deleteRoomRequest: state.api.deleteRoom?.request,
        schoolSubjects: state.data.staticData?.school_subjects
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(RoomsScreen)