import {useSafeAreaInsets} from "react-native-safe-area-context";
import {Platform, View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconButton} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import {IconImageM} from "@common/components/icons/IconsM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import moment from "moment";
import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";

const ActivityTemplateHeader = (props) => {

    const {
        activityTemplate,
        schoolSubject,
        challenge,
        navigation,
        activity,
        previous,
        isTutor
    } = props

    const insets = useSafeAreaInsets();

    const challengeTitle = challenge ? challenge?.title : null
    const dimensions = useContext(DimensionsContext)
    const activitySchoolSubjectName = challenge ? challenge?.school_subject?.name : schoolSubject?.name
    //
    // console.log(activity)
    // console.log(activityTemplate)

    return <View style={{
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: "center",
        backgroundColor: theme.colors.white,
        // backgroundColor: activity ? theme.colors.primaryLighter : null,
        borderBottomWidth: 1,
        borderColor: theme.colors.lightMore,
        width: "100%",
        paddingTop: !props.isLive && Platform.OS === "ios" ? insets.top : 0
    }}>
        <View style={{
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            alignContent: "center",
            paddingRight: 15,
            width: dimensions?.width,
            borderBottomWidth: activity?.date ? 1 : 0,
            borderColor: theme.colors.lightMore
        }}>
            <IconButton
                icon={previous ? "arrow-left" : "times"}
                iconColor={theme.colors.primary}
                size={32}
                onPress={() => {
                    if (navigation.canGoBack())
                        navigation.goBack()
                    else if (isTutor)
                        navigation.replace("browse-activities")
                    else
                        navigation.replace("menu")
                }}
            />
            <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10
            }}>
                <TextM fontSize={22}
                       wrap
                       fontWeight="ExtraBold"
                       style={{
                           // marginTop: 5
                       }}
                       color={theme.colors.primary}>{activityTemplate?.title}</TextM>
                {!isTutor && <View style={{
                    alignItems: 'center',
                    justifyContent: "center",
                    flexDirection: "row"
                    // position: "absolute",
                    // alignSelf: "center",
                    // bottom: 0
                }}>
                    {challengeTitle && <TextM fontWeight="SemiBold"
                           color={theme.colors.grey}
                           style={{textAlign: "center"}}
                           wrap
                           fontSize={12}>{challengeTitle} - </TextM>}
                    <TextM fontSize={12}
                           wrap
                           fontWeight="Medium"
                           style={{textAlign: "center"}}
                           color={theme.colors.grey}>{activitySchoolSubjectName}</TextM>

                </View>}

            </View>
            {!!activityTemplate && <IconImageM width={40}
                        style={{
                            // opacity: (0.5)
                            marginLeft: 5
                        }}
                        uri={activityTemplate?.icon}/>}
        </View>

        {activity?.date && <View style={{
            // flex: 1,
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: "center",
            paddingHorizontal: 5,
            backgroundColor: theme.colors.primaryLighter,
            paddingVertical: 5,
            width: "100%"
        }}>

            <FontAwesome5 color={theme.colors.primary} size={12} name="calendar"/>
            <TextM fontSize={12}
                   style={{
                       marginLeft: 5
                   }}
                   fontWeight="SemiBold"
                   color={theme.colors.primary}>{moment(activity?.date)?.format("ddd DD/MM").toUpperCase()}</TextM>

        </View>}
    </View>
}
const mapStateToProps = state => {
    return {
        isLive: state.liveMode.isLiveModeEnabled,

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTemplateHeader)