import {FlatList, View} from "react-native";
import React, {useContext, useRef} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import moment from "moment";
import {idsToObjects} from "@data/utility/dataUtils";
import ChallengesListItem from "../../student/organize/challenges/ChallengesListItem";
import {schoolsService} from "@data/services/schoolsService";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {Button} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {boxedCardBorderStyle} from "../../reviewer/BoxedCardBorderStyle";

function ClassroomChallengesTab(props) {
    const {classroom} = props
    const flatListRef = useRef()
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const challenges = classroom?.challenges
        ?.sort((a, b) => {
            const dateA = a?.finished_at ? a?.finished_at : a?.abandoned_at ? a?.abandoned_at : a?.created_at
            const dateB = b?.finished_at ? b?.finished_at : b?.abandoned_at ? b?.abandoned_at : b?.created_at
            return moment(dateB).diff(dateA)
        })

    return (
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20
        }}>
            <View style={{
                ...boxedCardBorderStyle,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                width: dimensions?.width,
                borderColor: theme.colors.lightMore,
                backgroundColor: theme.colors.white,
                paddingHorizontal: 10,
                paddingVertical: 10,
                marginVertical: 10,
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <TextM fontWeight={"Bold"} fontSize={IS_WEB ? 20 : 15}>
                    {challenges?.filter(x => !x?.finished_at && !x?.abandoned_at)?.length} DÉFI{challenges?.length > 1 && "S"} EN
                    COURS
                </TextM>
                <Button
                    icon="plus"
                    onPress={() => {
                        navigation.push("new-challenge-classroom", {
                            classroomId: classroom?.id
                        })
                    }}
                    mode={"contained"}
                    labelStyle={{fontSize: 12}}
                >
                    Créer un défi
                </Button>
            </View>
            <FlatList data={challenges}
                      ref={flatListRef}
                      refreshing={!!props.getClassroomRequest}
                      onRefresh={() => {
                          props.apiRequest(schoolsService.getClassroom, {id: classroom?.id})
                      }}
                      style={{
                          paddingVertical: 10,
                          marginBottom: 50
                      }}
                      showsHorizontalScrollIndicator={false}

                      renderItem={(item) => {
                          const student = classroom?.students?.find(s => s?.id === item.item?.student_id)

                          const activities = idsToObjects(item.item.activity_ids, student?.activities)
                          return <ChallengesListItem activities={activities}
                                                     key={item.index}
                                                     student={student}
                                                     selected={false}
                                                     index={item.index}
                                                     Press={() => {
                                                     }}
                                                     challenge={item.item}
                          />
                      }}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        schoolSubjects: state.data.staticData?.school_subjects,
        getClassroomRequest: state.api.getClassroom?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomChallengesTab)