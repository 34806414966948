import {Platform, TouchableOpacity, View} from "react-native";
import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {useNavigation} from "@react-navigation/native";
import {RealizationsListItemContent} from "./RealizationsListItemContent";
import {ActivityIndicator, Badge} from "react-native-paper";
import {activitiesService} from "@data/services/activitiesService";
import {connect} from "react-redux";
import {hasRole} from "@data/utility/ability";

const RealizationsListItem = props => {

    // ** Component props
    const {
        activity, index, student, noBorder, disabled, onLongPress, todo = false, onPress = () => {
        }, onPressSwipe = () => {
        }, dragButton, style, custom
    } = props

    const navigation = useNavigation()

    const user = student ? student : props.user
    const userActivities = user?.activities?.concat(user?.history?.activities)
    const userChallenges = user?.challenges?.concat(user?.history?.challenges)

    const challenge = activity?.challenge_id ? findItemInListWithId(activity?.challenge_id, userChallenges) : null
    const schoolSubject = findItemInListWithId(activity?.school_subject_id, props.schoolSubjects)
    const schoolSubjectColor = challenge ? challenge?.school_subject?.color : schoolSubject?.color === "" ? theme.colors.info : schoolSubject?.color
    const dimensions = useContext(DimensionsContext)
    const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)
    const formData = activityTemplate?.steps
    const activityType = formData?.find(step => step.type === "photo") ? "photo" : "audio"

    return <View key={activity?.id}
                 style={{
                     width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 20 : dimensions?.width / 1.2,
                     marginBottom: 12,
                     alignSelf: "center",
                     // borderColor: theme.colors.white
                     ...style
                 }}>

        {/* ** Header with School subject info */}
        {<TouchableOpacity
            onLongPress={!IS_WEB ? onLongPress : () => {
            }}
            delayLongPress={300}
            disabled={disabled}
            onPress={() => {
                if (!todo)
                    if (!activity?.is_review_read && hasRole(props.user, "student")) {
                        if (props.readReviewRequest) return
                        props.apiRequest(activitiesService.readReview, {id: activity?.id})
                    } else {
                        if (hasRole(props.user, "student") && !props.user?.champion_intros_viewed_at?.sam) {
                            navigation.push("intro", {
                                name: "sam",
                                nextScreen: "activity-result",
                                nextScreenParams: {
                                    activityIds: [activity?.id],
                                    studentId: student?.id
                                }
                            })

                        } else {
                            navigation.push("activity-result", {
                                activityIds: [activity?.id],
                                studentId: student?.id
                            })
                        }
                    } else onPress()
            }}
            style={{
                backgroundColor: theme.colors.white,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: schoolSubjectColor
            }}
        >
            <RealizationsListItemContent activity={activity}
                                         challenge={challenge}
                                         schoolSubject={schoolSubject}
                                         noBorder={noBorder}
                                         user={user}
                                         currentUser={todo ? props.user : null}
                                         dragButton={dragButton}
                                         onPressSwipe={onPressSwipe}
                                         custom={custom}
                                         noClass={props.user?.id === activity?.student_id}
                                         todo={todo}
                                         updatedActivityEventData={props.updatedActivityEventData}
                                         activityType={activityType}
            />
            {props.deleteTodoRequest && props.deleteTodoRequestActivityId === activity?.id &&
                <ActivityIndicator animating={true}
                                   color={theme.colors.primary}
                                   size={14}
                                   style={{
                                       position: "absolute",
                                       top: -2,
                                       right: -2
                                   }}/>}
            {props.lockActivityRequest && props.lockActivityRequestActivityId === activity?.id &&
                <ActivityIndicator animating={true}
                                   color={theme.colors.primary}
                                   size={14}
                                   style={{
                                       position: "absolute",
                                       top: -2,
                                       right: -2
                                   }}/>}

            {!activity?.is_review_read && activity?.finished_at && <>
                {(props.readReviewRequest && props.readReviewRequestActivityId === activity?.id)
                    ? <ActivityIndicator animating={true}
                                         color={theme.colors.primary}
                                         size={14}
                                         style={{
                                             position: "absolute",
                                             top: -2,
                                             right: -2
                                         }}/>
                    : <Badge size={12}
                             style={{
                                 position: "absolute",
                                 backgroundColor: theme.colors.accentDark,
                                 top: -2,
                                 right: -2
                             }}
                    />}
            </>}

        </TouchableOpacity>}


    </View>

}

const mapStateToProps = state => {
    return {
        readReviewRequest: state.api.readReview?.request,
        readReviewRequestActivityId: state.api.readReview?.routeParams?.id,
        deleteTodoRequest: state.api.deleteActivityTodo?.request,
        deleteTodoRequestActivityId: state.api.deleteActivityTodo?.routeParams?.id,
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        lockActivityRequest: state.api.lockActivityV2?.request,
        lockActivityRequestActivityId: state.api.lockActivityV2?.routeParams?.id,
        updatedActivityEventData: state.activity?.updatedActivityEventData,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(RealizationsListItem)