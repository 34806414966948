import {createContext, useContext, useEffect, useRef, useState} from "react";
import {AppState} from "react-native";
import {liveService} from "../../data/services/liveService";
import {useDispatch, useSelector} from "react-redux";
import {apiRequest} from "../../data/redux/actions/api";

export const PusherContext = createContext(null);


export const useOnline = ({
                              channel,
                              hereCallBack,
                              joiningCallBack,
                              leavingCallBack,
                              errorCallBack,
                              typingCallback,
                              activityFinalizedCallback,
                              activityCameraOnCallback,
                              newTodoCallback
                          },
                          fire = [],
                          noBackgroundEvent = false
) => {

    const appState = useRef(AppState.currentState);
    const pusher = useContext(PusherContext)
    const joinScreen = () => {
        if (!pusher?.channel(channel)) {
            const theChannel = pusher.subscribe(channel)
            pusher.bind("client-typing", (data) => {
                typingCallback(data)
            });
            pusher.bind("client-new-todo", (data) => {
                newTodoCallback(data)
            });
            pusher.bind("client-activity-finalized", (data) => {
                activityFinalizedCallback(data)
            });
            pusher.bind("client-activity-camera-on", (data) => {
                activityCameraOnCallback(data)
            });
            theChannel.bind('pusher:member_added', joiningCallBack);
            theChannel.bind('pusher:member_removed', leavingCallBack);
            theChannel.bind('pusher:subscription_succeeded', (subscriptionSuccessData) => {
                const membersIds = []
                theChannel.members.each((member) => membersIds.push(member?.id))
                if (membersIds)
                    hereCallBack(membersIds)
                pusher.connection.bind('error', function (err) {
                    if (err.error?.data?.code === 4004) {
                        console.log('Over limit!');
                    }
                });
            })
        }

        pusher.connection.bind("state_change", function (states) {
            //console.log(states)
            if (states.current === "disconnected")
                pusher?.connect()
        });

    };
    const handleAppStateChange = async (nextAppState) => {

        if (
            appState.current === "active" &&
            nextAppState.match(/inactive|background/)
        ) {
            if (noBackgroundEvent)
                pusher.unsubscribe(channel)
            //pusher.disconnect()
        } else if (
            appState.current.match(/inactive|background/) &&
            nextAppState === "active"
        ) {
            if (noBackgroundEvent)
                joinScreen();
        }

        appState.current = nextAppState;
    };

    useEffect(() => {
        joinScreen();

    }, fire);

    useEffect(() => {
        const sub = AppState.addEventListener("change", handleAppStateChange);

        return function cleanUp() {
            // console.log("CLEANUP")

            pusher.unsubscribe(channel)
            //pusher.disconnect()
            sub.remove()
        };
    }, []);


};

export const useCall = ({
                            channel,
                            joiningCallBack,
                            leavingCallBack,
                            handRaisedCallBack,
                            handLoweredCallBack,
                            incomingCallBack,
                            soundModeEnabledCallBack,
                            soundModeDisabledCallBack,
                            voiceRecordingStartedCallBack,
                            voiceRecordingStoppedCallBack,
                            newMessageCallBack,
                            finalizeLiveCallBack,
                            chapterFeelingCallback,
                            activityUpdatedCallback
                        }, fire = [], noBackgroundEvent = false) => {

    const pusher = useContext(PusherContext)
    const appState = useRef(AppState.currentState);
    const dispatch = useDispatch()
    const liveSessions = useSelector(state => state.data.currentUser?.object?.live_sessions)
    const liveSessionId = useSelector(state => state.liveMode?.liveSessionId)
    const liveSession = liveSessions?.find(x => x?.id === liveSessionId)


    const joinScreen = () => {
        if (!pusher.channel(channel)) {
            const theChannel = pusher.subscribe(channel)
            theChannel.bind('incoming.call', (data) => incomingCallBack(data));
            theChannel.bind('joined.call', (data) => joiningCallBack(data));
            theChannel.bind('left.call', (data) => leavingCallBack(data));
            theChannel.bind('new.message', (data) => newMessageCallBack(data));
            theChannel.bind('hand.raised', (data) => handRaisedCallBack(data));
            theChannel.bind('hand.lowered', (data) => handLoweredCallBack(data));
            theChannel.bind('sound.mode.enabled', (data) => soundModeEnabledCallBack(data));
            theChannel.bind('sound.mode.disabled', (data) => soundModeDisabledCallBack(data));
            theChannel.bind('voice.recording.activity.enabled', (data) => voiceRecordingStartedCallBack(data));
            theChannel.bind('voice.recording.activity.disabled', (data) => voiceRecordingStoppedCallBack(data));
            theChannel.bind('live.finalized', (data) => finalizeLiveCallBack(data));
            theChannel.bind("student.chapter.feeling", (data) => {
                chapterFeelingCallback(data)
            });
            theChannel.bind("activity.updated", (data) => {
                activityUpdatedCallback(data)
            });
        }
    };
    const handleAppStateChange = async (nextAppState) => {

        if (
            appState.current === "active" &&
            nextAppState.match(/inactive|background/)
        ) {
            // console.log("background")
            if (noBackgroundEvent)
                pusher.unsubscribe(channel)
            //pusher.disconnect()
        } else if (
            appState.current.match(/inactive|background/) &&
            nextAppState === "active"
        ) {
            // console.log("back")
            // dispatch(apiRequest(liveService.getLiveSession, {id: liveSession?.id}))
            dispatch(apiRequest(liveService.getCall, {id: liveSessionId}))

            if (noBackgroundEvent)
                joinScreen();
        }
        appState.current = nextAppState;
    };

    useEffect(() => {
        joinScreen();
        const sub = AppState.addEventListener("change", handleAppStateChange);

        return function cleanUp() {
            pusher.unsubscribe(channel)
            //pusher.disconnect()
            sub.remove()
        };

    }, fire);


};

export const useSendWhisper = ({channel, id}) => {

    const [charactersTyped, setCharactersTyped] = useState(0)
    const pusher = useContext(PusherContext)
    return () => {

        if (charactersTyped === 3) {
            try {
                //console.log(channel)
                pusher.channel(channel).trigger("client-typing", {
                    data: JSON.stringify({user: id})
                });

            } catch (e) {
                console.log('ERROR: ' + e);
            }
        }

        setCharactersTyped(c => (c === 3) ? 0 : c + 1)
    }

}
export const useNewTodo = ({channel, id}) => {
    //console.log(channel)
    const pusher = useContext(PusherContext)

    return (userId = id) => {
        try {
            pusher.channel(channel).trigger("client-new-todo", {
                data: JSON.stringify({user: userId})
            });
        } catch (e) {
            console.log('ERROR: ' + e);
        }
    }
}
export const useActivityFinalized = ({channel, id, displayName}) => {
    //console.log(channel)
    const pusher = useContext(PusherContext)

    return () => {
        try {
            pusher.channel(channel).trigger("client-activity-finalized", {
                data: JSON.stringify({user: id, displayName})
            });
        } catch (e) {
            console.log('ERROR: ' + e);
        }
    }
}

export const useSocket = ({event, callBack, channel}) => {
    const pusher = useContext(PusherContext)
    const [subscribed, setSubscribed] = useState(false);

    //console.log(event, channel)
    useEffect(() => {
        //pusher.unsubscribe(channel)
        let theChannel;
        if (!pusher.channel(channel)) {
            theChannel = pusher.subscribe(channel)
            setSubscribed(true)
            theChannel.bind(event, (data) => {
                callBack(data)
            });
        } else {
            //console.log("else")
            theChannel = pusher.channel(channel)
            theChannel.bind(event, (data) => {
                callBack(data)
            });
        }
        return function cleanUp() {
            if (subscribed)
                pusher.unsubscribe(channel)
            //pusher.disconnect()
        };
    }, []);
};