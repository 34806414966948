import React from 'react';
import {Text} from "react-native-paper";
import {theme} from "../../theme/theme";
import {Platform} from "react-native";

const TextM = (props) => {

    const {fontWeight, fontSize, color, wrap} = props

    return <Text
        {...props}
        // ellipsizeMode="tail"
        numberOfLines={wrap ? 0 : 1}
        style={{
            ...props.style,
            flex: Platform.OS !== 'web' ? 0 : null,
            color: color ?? theme.colors.grey,
            fontFamily: "Montserrat-" + (fontWeight ?? "Regular"),
            fontSize: fontSize ?? 14
            // flexShrink: 1,
            // alignSelf: 'center'
        }}
    >
        {props.children}
    </Text>
}

export default TextM