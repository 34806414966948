export const skinColors = {
    sc1: '#FFCC22',
    sc2: '#FBD2C7',
    sc3: '#F2AD9B',
    sc4: '#e58f7b',
    sc5: '#e4a06f',
    sc6: '#b1695a',
    sc7: '#92584b',
    sc8: '#613d36',
    sc9: '#c9e6dc',
};
export const skinColorsList = [
    {
        name: "sc1",
        color: '#FFCC22',
    },
    {
        name: "sc2",
        color: '#FBD2C7',
    },
    {
        name: "sc3",
        color: '#F2AD9B',
    },
    {
        name: "sc4",
        color: '#e58f7b',
    },
    {
        name: "sc5",
        color: '#e4a06f',
    },
    {
        name: "sc6",
        color: '#b1695a',
    },
    {
        name: "sc7",
        color: '#92584b',
    },
    {
        name: "sc8",
        color: '#613d36',
    },
    {
        name: "sc9",
        color: '#c9e6dc',
    },
];