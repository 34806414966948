import { liveModeConstants } from "../../../constants/liveModeConstants";
import { liveService } from "../../../services/liveService";
import { apiConstants } from "../../../constants/apiConstants";
import { messengerConstants } from "@data/constants/messengerConstants";
import { messengerService } from "@data/services/messengerService";
import { findKeyByValue } from "@data/utility/Utils";
import videoCallConstants from "@data/constants/videoCallConstants";

const initialState = {
  isLiveModeEnabled: false,
  liveSessionId: null,
  isTyping: false,
  liveParticipants: [],
  liveTutors: [],
  typingUser: null,
  inCallUsers: [],
  raisedHands: [],
  phoneRinging: [],
  todayLiveSessionLogs: [],
  timesSinceLastCall: [],
  conferenceToken: null,
  callPortal: false,
  visibleInvite: false,
  sound: null,
  callMode: null,
  //inLiveNotReadMessagesCount: {},
  activityFinalizedCount: null,
  isAudioMuted: false,
  isVideoMuted: false,
  soundModeIds: [],
  // soundMode: false,
  voiceActivityRecordingIds: [],
  activeTime: [],
  liveReport: null,
  callKeepUUID: null,
};

export default function liveMode(state = initialState, action) {
  switch (action.type) {
    case liveModeConstants.SET_CALL_KEEP_UUID:
      return {
        ...state,
        callKeepUUID: action.uuid,
      };
    case liveService.getTodayLiveReport.name + apiConstants._SUCCESS:
      return {
        ...state,
        liveReport: action.data,
      };
    case liveService.setSoundMode.name + apiConstants._SUCCESS:
      return {
        ...state,
        soundModeIds: action.data?.sound_mode_ids,
        // soundMode: !state?.soundMode,
        todayLiveSessionLogs: action.data?.today_live_session_logs,
      };
    case liveModeConstants.SOUND_MODE:
      return {
        ...state,
        soundModeIds: action.newState
          ? state.soundModeIds?.includes(action.userId)
            ? state.soundModeIds
            : [...state.soundModeIds, action.userId]
          : state?.soundModeIds.filter((id) => id !== action.userId),
      };
    case liveModeConstants.VOICE_RECORDING_ACTIVITY:
      return {
        ...state,
        voiceActivityRecordingIds: state.voiceActivityRecordingIds.find(
          (id) => id === action.newState
        )
          ? state.voiceActivityRecordingIds.filter(
              (id) => id !== action.newState
            )
          : [...state.voiceActivityRecordingIds, action.newState],
      };
    case videoCallConstants.MUTE_AUDIO:
      return {
        ...state,
        isAudioMuted: action?.muted,
      };
    case videoCallConstants.MUTE_VIDEO:
      return {
        ...state,
        isVideoMuted: action?.muted,
      };
    case liveModeConstants.RESET_LIVE_MODE:
      return initialState;
    // case liveService.leaveLiveSession.name + apiConstants._SUCCESS:
    //     //case liveService.finalizeLiveSession.name + apiConstants._SUCCESS:
    //     return {
    //         ...state,
    //         isLiveModeEnabled: false,
    //     }
    case liveService.getLiveSession.name + apiConstants._SUCCESS:
      return {
        ...state,
        conferenceToken: action.data?.conference_token,
        screenshareToken: action.data?.screenshare_token,
      };
    case liveService.leaveCall.name + apiConstants._SUCCESS:
    case liveService.joinCall.name + apiConstants._SUCCESS:
    case liveService.getCall.name + apiConstants._SUCCESS:
    case liveService.raiseHand.name + apiConstants._SUCCESS:
    case liveService.lowerHands.name + apiConstants._SUCCESS:
    case liveService.lowerAllHands.name + apiConstants._SUCCESS:
      return {
        ...state,
        inCallUsers: action.data?.call_participant_ids,
        raisedHands: action.data?.raised_hands_ids,
        visibleInvite: action.data?.has_incoming_call,
        todayLiveSessionLogs: action.data?.today_live_session_logs,
        soundModeIds: action.data?.sound_mode_ids,
        voiceActivityRecordingIds: action.data?.voice_recording_activity_ids,
      };
    case liveModeConstants.SET_TIMES_SINCE_LAST_CALL:
      return {
        ...state,
        timesSinceLastCall: action.newState,
      };
    case liveModeConstants.SET_ACTIVE_TIME:
      return {
        ...state,
        activeTime: action.newState,
      };
    case liveModeConstants.NEW_LIVE_SESSION_LOG:
      return {
        ...state,
        todayLiveSessionLogs: [
          ...(state?.todayLiveSessionLogs ?? []),
          action.newState,
        ],
      };

    case liveModeConstants.HIDE_INCOMING_CALL:
      return {
        ...state,
        visibleInvite: false,
      };
    case liveModeConstants.SHOW_INCOMING_CALL:
      return {
        ...state,
        visibleInvite: true,
      };
    case liveModeConstants.IS_LIVE_MODE_CHANGE:
      return {
        ...state,
        isLiveModeEnabled: action.newState,
        liveSessionId: action.liveSessionId,
      };
    case liveModeConstants.IS_TYPING:
      return {
        ...state,
        isTyping: action.newState,
      };
    case liveModeConstants.TYPING_USER:
      return {
        ...state,
        typingUser: action.newState,
      };
    case liveModeConstants.LIVE_PARTICIPANTS:
      return {
        ...state,
        liveParticipants: action.newState,
      };
    case liveModeConstants.ADD_PARTICIPANT:
      if (!state.liveParticipants.includes(action.newState))
        return {
          ...state,
          liveParticipants: [...state.liveParticipants, action.newState],
        };
      return state;
    // case liveModeConstants.LIVE_TUTORS:
    //     if (!state.liveTutors.map(item => item.id).includes(action.newState.id))
    //         return {
    //             ...state,
    //             liveTutors: [...state.liveTutors, action.newState]
    //         }
    //     return state
    case liveModeConstants.REMOVE_PARTICIPANT:
      return {
        ...state,
        liveParticipants: state.liveParticipants.filter(
          (p) => p !== action.newState
        ),
      };
    // case liveModeConstants.REMOVE_TUTOR:
    //     return {
    //         ...state,
    //         liveTutors: state.liveTutors.filter(t => t.id.toString() !== action.newState?.toString())
    //     }
    case liveModeConstants.CLEAR_MEMBERS:
      return {
        ...state,
        liveTutors: [],
        liveParticipants: [],
      };
    case liveModeConstants.IN_CALL_USERS:
      return {
        ...state,
        inCallUsers: action.newState,
      };
    case liveModeConstants.ADD_IN_CALL_USER:
      return {
        ...state,
        inCallUsers: state.inCallUsers?.includes(action.newState)
          ? state.inCallUsers
          : [...state.inCallUsers, action.newState],
      };

    case liveModeConstants.REMOVE_IN_CALL_USERS:
      return {
        ...state,
        inCallUsers: [
          ...state.inCallUsers.filter((t) => t !== action.newState),
        ],
      };
    case liveModeConstants.PHONE_RINGING:
      return {
        ...state,
        phoneRinging: action.newState,
      };
    case liveModeConstants.RESET_PHONE_RINGING:
      return {
        ...state,
        phoneRinging: [],
      };
    case liveModeConstants.ADD_PHONE_RINGING:
      return {
        ...state,
        phoneRinging: state.phoneRinging?.includes(action.newState)
          ? state.phoneRinging
          : [...state.phoneRinging, action.newState],
      };

    case liveModeConstants.REMOVE_PHONE_RINGING:
      return {
        ...state,
        phoneRinging: [
          ...state.phoneRinging.filter((t) => t !== action.newState),
        ],
      };
    case liveModeConstants.RAISED_HANDS:
      return {
        ...state,
        raisedHands: action.newState,
      };
    case liveModeConstants.ADD_RAISED_HAND:
      return {
        ...state,
        raisedHands: state.raisedHands?.includes(action.newState)
          ? state.raisedHands
          : [...state.raisedHands, action.newState],
      };
    case liveModeConstants.REMOVE_RAISED_HAND:
      return {
        ...state,
        raisedHands: [
          ...state.raisedHands.filter((t) => t !== action.newState),
        ],
      };
    case liveModeConstants.SET_CALL_PORTAL:
      return {
        ...state,
        callPortal: action.newState,
        callMode: action?.mode,
      };
    case liveModeConstants.PLAY_SOUND:
      return {
        ...state,
        sound: action.sound,
      };
    default:
      return state;
  }
}
