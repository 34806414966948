import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import React, {useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import TextM from "@common/components/text/TextM";
import {convertMomentToFrench, liveSessionsString} from "./AssignTutorAndLives";
import {ActivityIndicator} from "react-native-paper";
import {RankingListItem} from "../student/social/RankingScreen";

const AssignTutor = (props) => {

    const navigation = useNavigation()

    const [formValues, setFormValues] = useState(null)

    const student = props.users?.find(x => x?.id === props.route.params?.studentId)

    useApiStatus(
        backOfficeService.assignTutor, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Opération réussie !"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )

    useEffect(() => {
        props.apiRequest(backOfficeService.getUserBackoffice, {id: props.route.params?.studentId})
    }, [])
    useApiStatus(
        backOfficeService.getUserBackoffice, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(student)
            // console.log("successData", successData)
        }
    )

    const defaultValues = {}


    const formData = [
        {
            label: "Choisir le tuteur",
            description: "Les sessions LIVE de l'élève ne seront pas affectés par ce changement."+ (student?.interview_slot ? "Le créneau d'entretien " + convertMomentToFrench(student?.interview_slot) + " sera conservé et transféré au nouveau tuteur." : ""),
            name: "tutor_id",
            type: "select.paginated",
            singleMode: true,
            withNull: true,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "tutor",
                light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: student?.tutor?.id ?? "",
            validationRules: {...validation.required()}
        },
        // {
        //     // is_masked: !currentChallenge?.needs_school_subject,
        //     label: "Choisir un nouveau masteur",
        //     description: "Les sessions LIVE de l'élève ne seront pas affectés par ce changement."+ (student?.interview_slot ? "Le créneau d'entretien " + convertMomentToFrench(student?.interview_slot) + " sera conservé et transféré au nouveau tuteur." : ""),
        //     type: "select.single", // select.single, select.multiple, text, date
        //     name: "tutor_id",
        //     options: props.tutors
        //         ?.map(x => {
        //             return {
        //                 value: x?.id,
        //                 label: x?.display_name,
        //                 avatar: x,
        //                 description: liveSessionsString(x?.tutor_live_sessions)
        //             }
        //         }),
        //     defaultValue: student?.tutor?.id,
        //     validationRule: {
        //         ...validation.required()
        //     },
            // bottomComponent: <>
            //     <TextM>
            //
            //     </TextM>
            // </>
        // }

    ]

    const onSubmit = data => {
        if (props.assignTutorRequest) return
        props.apiRequest(backOfficeService.assignTutor, {id: props.route.params?.studentId}, data)
    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            {props.getUserBackofficeRequest
                ? <ActivityIndicator animating={true}
                                     style={{
                                         marginTop: 50
                                     }}
                                     size="large"
                                     color={theme.colors.primary}/>
                :  <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.assignTutorRequest}
                         previousRouteName={""}
                         submittedMessage=""
                         serverError={props.assignTutorError}
                         submitButtonText="Terminer"
            />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        usersFormStep: state.data?.users?.usersListFormStep,
        assignTutorRequest: state.api?.assignTutor?.request,
        assignTutorError: state.api?.assignTutor?.error,
        getUserBackofficeRequest: state.api?.getUserBackoffice?.request,

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AssignTutor)