import React, {useContext, useEffect, useState} from "react";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {chaptersService} from "@data/services/chaptersService";
import {ActivityIndicator} from "react-native-paper";
import {useApiStatus} from "@common/hooks/useApiStatus";
import IconButtonM from "@common/components/button/IconButtonM";

const ChapterItemArchive = (props) => {
    const {chapter, animated = false, isHighlighted = false, style} = props;

    const dimensions = useContext(DimensionsContext);
    const schoolSubject = props.schoolSubjects?.find(
        (subject) =>
            subject.id?.toString() === chapter?.school_subject_id?.toString()
    );
    // const differenceInDays = moment().diff(moment(chapter?.last_set_ongoing_at), 'days');
    const color = schoolSubject?.color ?? theme.colors.info;

    const widthMax = dimensions.width - 40;

    //Animation setup
    const [triggerAnimation, setTriggerAnimation] = useState(false);

    useEffect(() => {
        if (animated) {
            setTimeout(() => {
                setTriggerAnimation(true);
            }, 500);
        }
    }, []);

    useApiStatus(
        chaptersService.archiveChapter, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            DialogM.hide();
        }
    );
    return (
        <View style={{}}>
            <View
                style={{
                    backgroundColor: isHighlighted
                        ? theme.colors.primaryLighter
                        : theme.colors.white,
                    borderWidth: 1,
                    borderColor: isHighlighted
                        ? theme.colors.primary
                        : theme.colors.lightMore,
                    alignSelf: "center",
                    borderRadius: 10,
                    width: widthMax,
                    marginTop: 10,
                    paddingHorizontal: 10,
                    paddingTop: 15,
                    paddingBottom: 0,
                    ...style
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 10
                        }}
                    >
                        {schoolSubject?.icon && (
                            <IconImageM
                                uri={schoolSubject?.icon}
                                width={dimensions.width > 800 ? 60 : 34}
                                style={{}}
                            />
                        )}
                        <View
                            style={{
                                marginLeft: 10,
                                maxWidth: widthMax - 60 - 10 * 2 - 40
                            }}
                        >
                            <TextM
                                color={color}
                                fontWeight={"SemiBold"}
                                fontSize={dimensions.width > 800 ? 17 : 14}
                                wrap
                            >
                                {chapter?.name}
                            </TextM>
                            <TextM fontSize={11} fontWeight={"SemiBold"} wrap>
                                {chapter?.parent_chapter?.name ? chapter?.parent_chapter?.name + " - " : ""}{schoolSubject?.name} - {chapter?.class}
                            </TextM>
                            {/*{chapter?.student_chapters?.length > 0 || liveReportMode && <TextM fontSize={10}*/}
                            {/*                                                                   fontWeight={"Light"}*/}
                            {/*                                                                   color={theme.colors.light}>*/}
                            {/*    {differenceInDaysString}*/}
                            {/*</TextM>}*/}
                            {/*{level && <ActivityLevelBadge level={level}/>}*/}
                        </View>
                    </View>
                    <View
                        style={{
                            alignItems: "center"
                        }}
                    >
                        {props.archiveChapterRequest &&
                        props.archivedChapterId === chapter?.student_chapters?.[0]?.id ? (
                            <ActivityIndicator
                                animating={true}
                                color={theme.colors.primary}
                                size="small"
                            />
                        ) : chapter?.student_chapters?.[0]?.ongoing ? (
                            <IconButtonM
                                disabled={
                                    props.archiveChapterRequest &&
                                    props.archivedChapterId === chapter?.student_chapters?.[0]?.id
                                }
                                iconColor={theme.colors.primary}
                                icon={"archive"}
                                onPress={() => {
                                    props.apiRequest(
                                        chaptersService.archiveChapter,
                                        {id: chapter?.student_chapters[0]?.id},
                                        {tutor_comment: null}
                                    );
                                }}
                            />
                        ) : null}
                    </View>
                </View>
            </View>
        </View>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        archivedChapterId: state.api.archiveChapter?.routeParams?.id,
        archiveChapterRequest: state.api.archiveChapter?.request,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveModeEnabled: state.liveMode?.isLiveModeEnabled
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterItemArchive);
