import React from "react";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {nextInterviewForStudent, sortByDateAttribute} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {CardButtonM} from "./TutorInterviewCard";
import {useNavigation} from "@react-navigation/native";
import {findItemInListWithId} from "../../../data/utility/dataUtils";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {formatFullDateTime} from "../../../data/utility/Utils";
import AvatarM from "@common/components/avatar/AvatarM";
import {TutorNextEventsCard} from "./TutorNextEventsCard";
import moment from "moment";


export function TutorNextInterviewsCard(props) {

    const navigation = useNavigation()

    return <TutorNextEventsCard title={"Mes prochains entretiens"}
                                data={sortByDateAttribute(props.students
                                    ?.map(x => nextInterviewForStudent(x))
                                    ?.filter(x => !!x), "date_time")}
                                renderItem={(item, key, additionalOnPress = () => {
                                }) => {
                                    const student = findItemInListWithId(item?.student_id, props.students)
                                    const isToday = moment(item.date_time).isSame(moment(), "day") || moment(item.date_time).diff(moment(), "day") <= 0

                                    return <CardButtonM key={key}
                                                        onPress={() => {
                                                            navigation.push("interview", {
                                                                interview_id: item.id,
                                                                student_id: item?.student_id
                                                            })
                                                            if (!!additionalOnPress)
                                                                additionalOnPress()
                                                        }}
                                                        badgeCount={isToday ? 1 : 0}
                                                        icon={<FontAwesome5 name="video" size={24} solid
                                                                            color={theme.colors.primary}/>}
                                                        title={formatFullDateTime(item?.date_time)}
                                                        description={<View style={{
                                                            flexDirection: 'row',
                                                            alignItems: 'center'
                                                        }}>
                                                            <AvatarM user={student}/>
                                                            <TextM style={{marginLeft: 5}}
                                                                   color={theme.colors.primary}>{student?.display_name}  </TextM>
                                                        </View>}/>
                                }}
    />
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        getStudentRequest: state.api.getStudents?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorNextInterviewsCard)