import {View} from "react-native";
import {daysOptions} from "@data/constants/formConstants";
import {BadgeM} from "@common/components/badge/BadgeM";
import {theme} from "@common/theme/theme";
import React from "react";

export function AvailabilitySlotsM({slots}) {
    return <View style={{flexDirection: "row", flexWrap: "wrap"}}>
        {slots?.map((slot, key) => {
            const day = slot.split(" ")[0]
            const hour = slot.split(" ")[1]
            const dayFr = daysOptions.find(d => d.value === day)?.label
            return <BadgeM
                key={key}
                style={{
                    backgroundColor: theme.colors.lighter,
                    color: theme.colors.grey,
                    marginVertical: 3,
                    marginRight: 3
                }} count={" " + dayFr + " ap. " + hour + " "} size={23}/>
        })}

    </View>;
}