import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../../assets/icons/generic/live.png";
import TextM from "@common/components/text/TextM";
import {formatFullDateTime, plr, readableDayTime} from "@data/utility/Utils";
import moment from "moment";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {BadgeM} from "@common/components/badge/BadgeM";

export const findClosestNextOccurrence = (liveSessions) => {

    // console.log("liveSessions", liveSessions?.map(x => x?.id))

    // 1. Extract all next occurrences from the LiveSessions
    const notFinishedOccurrences = (liveSessions?.length > 0 && !!liveSessions?.[0])
        ? liveSessions?.reduce((pValue, cValue) => {

            return cValue?.next_occurrences ? [
                ...pValue,
                ...cValue.next_occurrences
            ] : [...pValue]
        }, [])
            // 2. Filter out any occurrence that is already finished
            ?.filter(occurrence => !occurrence?.finalized_at)
            // 3. Sort the remaining occurrences to find the closest one.
            ?.sort((a, b) => {
                return moment(a?.date).diff(b?.date)
            })
        : null

    // console.log("notFinishedOccurrences?.[0]", notFinishedOccurrences?.[0])

    // 4. Return this closest occurrence
    return notFinishedOccurrences?.[0]


    // //old version to be deleted
    // if (!notFinishedOccurrences || notFinishedOccurrences?.length === 0) return null
    //
    // const todayNotFinishedOccurrences = notFinishedOccurrences?.filter(occurrence => moment(occurrence?.date).isSame(moment(), "day"))
    // // console.log("todayNotFinishedOccurrences", todayNotFinishedOccurrences)
    //
    // if (todayNotFinishedOccurrences?.length > 0) {
    //
    //     const sortedTodayNotFinishedOccurrences = todayNotFinishedOccurrences?.sort((a, b) => {
    //         return Math.abs(moment().diff(a?.date)) - Math.abs(moment().diff(b?.date))
    //     })
    //     console.log("closest = today", sortedTodayNotFinishedOccurrences?.[0])
    //
    //     return sortedTodayNotFinishedOccurrences?.[0]
    // } else {
    //     console.log("closest = NOT today", notFinishedOccurrences?.sort((a, b) => {
    //         return moment(a?.date).diff(b?.date)
    //     })?.[0])
    //
    //     return notFinishedOccurrences?.sort((a, b) => {
    //         return moment(a?.date).diff(b?.date)
    //     })?.[0]
    // }
}
const LiveRoomCard = ({
                          liveRoom,
                          tutor,
                          onPress = null,
                          description,
                          hideAvailablePlaces = false,
                          style,
                          badgeCount,
                          disabled = false,
                          width = null,
                          showLiveRoomName = false,
                          hideSlots = false
                      }) => {

    const dimensions = useContext(DimensionsContext)

    const closestNextOccurrence = findClosestNextOccurrence(liveRoom?.live_sessions)

    // console.log("liveRoom", liveRoom)

    const availablePlaces = tutor?.profile?.max_students_per_live - liveRoom?.students_count
    return <TouchableOpacity style={{
        alignItems: "flex-start",
        justifyContent: "center",
        width: width ?? dimensions?.width - 80,
        borderRadius: theme.roundness,
        borderWidth: 1,
        borderColor: theme.colors.lightMore,
        backgroundColor: disabled ? theme.colors.white : theme.colors.successLightMore,
        marginTop: 10,
        ...style
    }}
                             disabled={!onPress || disabled}
                             onPress={onPress}>
        <View style={{
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "space-between",
            width: width ?? dimensions?.width - 80,
            paddingHorizontal: 15

        }}>
            <View style={{
                flexDirection: "row",
                alignItems: "flex-start",
                flex: 1,
                paddingVertical: 15
            }}>
                <IconImageM source={live}
                            width={30}
                            style={{
                                tintColor: disabled ? theme.colors.light : theme.colors.success
                            }}
                />
                {badgeCount > 0 && <BadgeM count={badgeCount}
                                           style={{
                                               backgroundColor: theme.colors.error,
                                               color: theme.colors.white,
                                               position: "absolute",
                                               top: -20,
                                               right: -30
                                           }}/>}
                <View style={{
                    flex: 1,
                    marginLeft: 10
                    // marginTop: 5
                }}>
                    {showLiveRoomName && <TextM color={onPress ? theme.colors.grey : theme.colors.success}
                                                fontWeight={onPress ? "Medium" : "Bold"}
                                                style={{marginBottom: 5}}
                                                wrap>LIVE - {liveRoom?.name}</TextM>}

                    {!hideSlots && <>{liveRoom?.live_sessions?.map((ls, index) => <TextM
                        color={theme.colors.grey}
                        key={index}
                        wrap>{readableDayTime(ls?.day_time, ls?.duration)}</TextM>)}</>}
                    <TextM color={onPress ? theme.colors.grey : theme.colors.success}
                           fontWeight={onPress ? "Medium" : "Bold"}
                           style={{marginTop: 5}}
                           wrap>{"Prochaine session " + formatFullDateTime(closestNextOccurrence?.date)}</TextM>
                    {!!onPress && !hideAvailablePlaces && <TextM color={theme.colors.success}
                                                                 fontWeight={"Medium"}
                                                                 style={{marginTop: 10}}
                                                                 wrap>{availablePlaces} {plr("place", availablePlaces)} {plr("disponible", availablePlaces)}</TextM>}
                    {description && <TextM fontWeight={"Light"}
                                           color={theme.colors.grey}
                                           fontSize={12}
                                           wrap
                                           style={{marginTop: 5}}>{description}</TextM>}
                </View>
            </View>
            {!!onPress && !disabled && <View style={{
                paddingLeft: 10,
                // backgroundColor: theme.colors.success,
                borderTopEndRadius: theme.roundness,
                borderBottomEndRadius: theme.roundness,
                alignItems: "center",
                justifyContent: "center"
            }}
                // start={[0, 0]}
                // end={[1, 0]}
                // colors={[theme.colors.su, theme.colors.whiteTransparent]}
            >
                <FontAwesome5 name={"chevron-right"}
                              size={24}
                              color={theme.colors.success}
                />
            </View>}
        </View>
    </TouchableOpacity>
}
export default LiveRoomCard