import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {Platform, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useSelector} from "react-redux";

export const HeaderContainerM = ({style, children, noBorder = false, noTopInset = false}) => {
    const dimensions = useContext(DimensionsContext)
    const insets = useSafeAreaInsets();

    const isLiveModeEnabled = useSelector(state => state.liveMode.isLiveModeEnabled)

    return <View style={{
        width: "100%",
        // height: 60,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.colors.white,
        borderBottomWidth: noBorder ? 0 : 1,
        borderColor: theme.colors.lightMore,
        paddingTop: Platform.OS === "ios" && !isLiveModeEnabled && !noTopInset ? insets.top : 0
    }}>
        <View style={{
            width: dimensions?.width,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            height: 60,
            ...style
        }}>
            {children}
        </View>
    </View>
}