import {FlatList, View} from "react-native";
import {ActivityIcon} from "../ActivityIcon";
import TextM from "@common/components/text/TextM";
import {_getMMSSFromMillis, AudioRecordingContainer} from "../execute/AudioRecordingContainer";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {cleanMediaUrl} from "@data/constants/apiConstants";
import React, {useEffect, useState} from "react";
import PhotoElement from "@common/camera/PhotoElement";
//import ImageView from "react-native-image-viewing";
import {KeyComponentItem} from "@common/components/card/KeyComponentItem";
import Hyperlink from 'react-native-hyperlink'
import {ImageViewerModal} from "@common/camera/CameraImagesM";
import live from "../../../../assets/icons/generic/live.png";
import {IconImageM} from "@common/components/icons/IconsM";
import {displayTimeSeconds} from "@data/utility/Utils";
import {calculateTimerColor} from "../execute/TimerContainer";

export function StepLogs({step, index, activity, style}) {

    const logImages = activity?.activity_logs?.filter(al => al?.step === index + 1 && al?.action === "step_media_sent");
    const logAudio = activity?.activity_logs?.find(al => al?.step === index + 1 && al?.action === "step_media_sent");
    const logAudioChrono = activity?.activity_logs?.find(al => al?.step === index + 1 && al?.action === "step_finished");
    const duration = activity.duration * 1000;
    //const logImages = activity?.activity_logs?.filter(al => al?.step === index + 1 && al?.action === "step_media_sent")

    const [openImageViewer, setOpenImageViewer] = useState(false)
    const [visibleIndex, setVisibleIndex] = useState(0)
    const hideImageViewer = () => {
        return setOpenImageViewer(false)
    }

    const renderStepContent = () => {
        switch (step?.type) {
            case "timer":
            case "chrono":

                const logChrono = activity?.activity_logs?.find(al => al?.step === index + 1 && al?.action === "step_finished")
                // console.log(step?.chrono_duration * 100 / (activity?.estimated_duration * 60))

                return <View
                    style={{flexDirection: "row", width: "100%", marginTop: 10, alignItems: "center"}}>
                    <ActivityIcon type={step?.type} width={30} style={{}}/>

                    <TextM fontWeight="SemiBold"
                           color={calculateTimerColor(logChrono?.step_data?.chrono_duration * 100 / (activity?.estimated_duration * 60), "finished")}
                           fontSize={20}
                           style={{marginLeft: 5}}
                           wrap>
                        {_getMMSSFromMillis(duration)}
                    </TextM>
                    {activity?.estimated_duration && <TextM color={theme.colors.primary}
                                                            fontWeight={"Medium"}
                                                            fontSize={18}
                                                            style={{
                                                                marginLeft: 10
                                                            }}>/ {displayTimeSeconds(activity?.estimated_duration * 60)}</TextM>}
                    {logChrono?.step_data?.offline_mode_bonus && <View style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: 30,
                        width: 30,
                        marginHorizontal: 5,
                        borderRadius: 10,
                        backgroundColor: theme.colors.purple
                    }}>
                        <FontAwesome5 name="plane" size={15} color={theme.colors.white}/>
                    </View>}
                    {logChrono?.step_data?.live_mode_bonus && <View style={{
                        alignItems: "center",
                        justifyContent: "center",
                        height: 30,
                        width: 30,
                        marginHorizontal: 5,
                        borderRadius: 10,
                        backgroundColor: theme.colors.purple
                    }}>
                        <IconImageM source={live}
                                    width={18}
                                    style={{
                                        tintColor: theme.colors.white
                                    }}
                        />
                    </View>}
                </View>

            case "audio_video":

                return logAudio?.work_attachment &&
                    <AudioRecordingContainer recordingURI={cleanMediaUrl(logAudio?.work_attachment)}
                                             duration={logAudioChrono?.step_data?.chrono_duration}
                                             readOnly/>

            case "form":
                const logForm = activity?.activity_logs?.find(al => al?.step === index + 1 && al?.action === "step_form_sent")

                return step?.form_fields?.map((ff, i) => <View
                    style={{flexDirection: "row", width: "80%", marginTop: 10}} key={i}>
                    <TextM fontWeight="SemiBold" style={{width: "25%"}} wrap>{ff?.label}</TextM>
                    <Hyperlink linkDefault={true} linkStyle={{color: theme.colors.link}}>
                        <TextM style={{marginLeft: 10}} wrap>{logForm?.step_data[i]}</TextM>
                    </Hyperlink>
                </View>)

            case "photo":


                return <>
                    <FlatList data={logImages}
                        // ref={flatListRef}
                              initialScrollIndex={0}
                              horizontal
                              style={{
                                  // marginTop: 10,
                                  // backgroundColor: "red",
                                  width: "100%",
                                  marginTop: 10,
                                  height: 100
                              }}
                              contentContainerStyle={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: 100

                              }}
                              keyExtractor={(item, index) => index.toString()}
                              ListEmptyComponent={() => <>
                                  <TextM style={{marginLeft: 10}} wrap>Aucune photo</TextM>
                              </>}
                              renderItem={(itemInfo) => {

                                  return <PhotoElement uri={cleanMediaUrl(itemInfo.item?.work_attachment_thumb)}
                                                       height={100}
                                                       width={100}
                                                       parentHeight={100}
                                                       isSelecting={false}
                                                       selected={false}
                                                       onLongPress={() => {
                                                       }}
                                                       onPress={() => {
                                                           // console.log("ONPRESS", itemInfo.index)
                                                           setVisibleIndex(itemInfo.index)
                                                           setOpenImageViewer(true)
                                                       }}
                                  />

                              }
                              }/>
                    {/*                    <ImageView
                        images={logImages?.map(x => {
                            return {uri: cleanMediaUrl(x?.work_attachment)}
                        })}
                        imageIndex={visibleIndex}
                        visible={visible}
                        onRequestClose={hideImageView}
                        swipeToCloseEnabled={false}
                        // presentationStyle="overFullScreen"
                    />*/}
                </>

            default:
                return null
        }
    }

    return <KeyComponentItem keyText={step?.title}
                             style={style}>
        {renderStepContent()}
        <ImageViewerModal visible={openImageViewer}
                          onDismiss={hideImageViewer}
                          imageIndex={visibleIndex}
                          useFullWidthToScroll
                          images={logImages?.map(l => l?.work_attachment)}/>

    </KeyComponentItem>;
}