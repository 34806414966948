import {Pressable, View} from "react-native";
import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {useNavigation} from "@react-navigation/native";
import AvatarM from "../components/avatar/AvatarM";
import {getPrivilegedRole} from "@data/utility/ability";
import {BadgeM} from "@common/components/badge/BadgeM";
import {theme} from "@common/theme/theme";

const StudentMainMenuHeader = (props) => {

    const navigation = useNavigation()

    const ability = useContext(AbilityContext)

    // console.log(props.user)

    function onAvatarPress() {
        navigation.push('account');
    }

    const count = (!props.user?.is_verified ? 1 : 0)

    return <Pressable onPress={onAvatarPress}
                      style={{
                          width: "100%"
                      }}>

        <View style={{
            flex: 1,
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            flexDirection: "row",
            alignItems: "center",
            paddingHorizontal: 10,

        }}>
            {/*<SuccessXpBar*/}
            {/*    // count={50}*/}
            {/*    count={xpAchievement?.current_count}*/}
            {/*    countTotal={xpAchievement?.next_level_sum}*/}
            {/*    level={xpAchievement?.level}/>*/}

            <View style={{
                width: "100%",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center"
            }}>
                {/*<FeatherCounter count={props.user?.feathers}/>*/}
                {/*<PetalCounter count={props.user?.petals}/>*/}

                {/*<TextM fontWeight="SemiBold"*/}
                {/*       fontSize={16}*/}
                {/*       color={theme.colors.primary}>{props.user?.display_name}</TextM>*/}

                <AvatarM user={props.user} style={{marginLeft: 10}} size={50}/>
                {count > 0 &&
                    <BadgeM count={count}
                            style={{
                                backgroundColor: theme.colors.error,
                                color: theme.colors.white,
                                position: "absolute",
                                top: -2,
                                right: -2
                            }}/>}
            </View>

        </View>
    </Pressable>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentMainMenuHeader)
