import React, {useContext, useRef, useState} from "react";
import {Platform, View} from "react-native";
import {theme} from "../theme/theme";
import TextM from "../components/text/TextM";
import AvatarM from "../components/avatar/AvatarM";
import {Badge, IconButton} from "react-native-paper";
import {hasRole} from "@data/utility/ability";
import {useOnline} from "./socketHook";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {BadgeM} from "@common/components/badge/BadgeM";
import {HeaderContainerM} from "@common/header/HeaderContainerM";

const ChatScreenHeader = (props) => {
    const {student, navigation} = props;
    const [enligne, setEnligne] = useState(false);
    const [typing, setTyping] = useState(false);
    const clearTimeoutRef = useRef();
    const _goBack = () => {
        if (navigation?.canGoBack())
            navigation.goBack();
        else
            navigation.replace("menu")
    };

    useOnline({
        channel: `presence-messenger.thread.${student?.tutor_messenger_thread_id}`,
        hereCallBack: (usersIds) => {
            // console.log("here")
            if (usersIds) {
                const id = hasRole(props.user, "tutor")
                    ? student?.id
                    : student?.tutor?.id;

                setEnligne(!!usersIds.find((userId) => userId?.toString() === id?.toString()));
            }
        },
        joiningCallBack: (user) => {
            // console.log("joining", user)

            if (user) {
                const id = hasRole(props.user, "tutor")
                    ? student?.id
                    : student?.tutor?.id;
                const providerId = user?.info?.provider_id ?? user?.userInfo?.provider_id

                // console.log("id", id)
                // console.log("user.provider_id", user?.provider_id)
                setEnligne(providerId === id);
            }

        },
        leavingCallBack: (user) => {
            // console.log("leaving", user)

            if (user) {
                const id = hasRole(props.user, "tutor")
                    ? student?.id
                    : student?.tutor?.id;

                const providerId = user?.info?.provider_id ?? user?.userInfo?.provider_id
                // console.log("id", id)
                // console.log("user.provider_id", user?.provider_id)
                setEnligne(!providerId === id);
            }
        },

        errorCallBack: (errors) => {
            console.log(errors);
        },

        typingCallback: (e) => {
            const id = hasRole(props.user, "tutor")
                ? student?.id
                : student?.tutor?.id;
            if (e.data?.user?.toString() === id?.toString()) return;

            if (clearTimeoutRef.current) {
                clearTimeout(clearTimeoutRef.current);
            }
            setTyping(true);
            clearTimeoutRef.current = setTimeout(() => {
                setTyping(false);
            }, 3000);
        }
    }, [], true);

    const insets = useSafeAreaInsets();
    const dimensions = useContext(DimensionsContext)
    const badgeCount = hasRole(props.user, "tutor") ? props.user?.students?.reduce((pValue, cValue) => cValue?.student_messenger_not_read_count + pValue, 0) : student?.tutor_messenger_not_read_count
    return (
        <HeaderContainerM style={{justifyContent: "flex-start"}}>
            <IconButton icon={"arrow-left"}
                        size={26}
                        onPress={_goBack}
                        iconColor={theme.colors.primary}
            />

            <View style={{marginLeft: -5}}>
                <AvatarM
                    user={hasRole(props.user, "tutor") ? student : props.user?.tutor}
                />

                <Badge
                    style={{
                        backgroundColor: (enligne ? theme.colors.success : theme.colors.light),
                        position: "absolute",
                        bottom: 0,
                        right: 0
                    }}
                    size={12}
                />

            </View>

            <View style={{justifyContent: "flex-start", marginLeft: 5}}>
                <View style={{flexDirection: "row", alignItems: "center"}}>
                    <TextM color={theme.colors.primary} fontWeight={"Bold"} fontSize={16}>
                        {hasRole(props.user, "tutor")
                            ? student?.display_name
                            : props.user?.tutor?.display_name}
                    </TextM>
                    <TextM style={{marginLeft: 6}}>
                        {hasRole(props.user, "tutor")
                            ? " " + (student?.profile?.class ?? "")
                            : " Mon Masteur "}
                    </TextM>
                </View>
                <View style={{flexDirection: "row", alignItems: "flex-start"}}>
                    <TextM color={theme.colors.grey} fontWeight={"Light"} fontSize={10}>
                        {typing
                            ? " est en train d'écrire..."
                            : enligne
                                ? " connecté à la conversation"
                                : " non connecté à la conversation"}
                    </TextM>
                </View>
            </View>
            {props.count > 0 && <BadgeM count={props.count}
                                            style={{
                                                backgroundColor: theme.colors.error,
                                                color: theme.colors.white,
                                                position: "absolute",
                                                right: 10,
                                                // alignSelf: 'flex-end'
                                            }}/>}
        </HeaderContainerM>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        count: state.data.currentUser?.object?.unreadInChatMessagesCount,
        token: state.data.session?.object?.token
    };
};
const mapDispatchToProps = {
    apiRequest,
    apiIdle
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatScreenHeader);