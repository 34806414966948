import {ActivitiesStatistics} from "../progress/ActivitiesStatistics";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import StatisticsList from "@common/components/statistics/StatisticsList";
import React, {useContext} from "react";

export const MesStatistiques = ({user}) => {
    const dimensions = useContext(DimensionsContext)

    return (<>
        <ActivitiesStatistics style={{marginTop: 25, marginBottom: 15}} user={user}/>
        <View style={{
            borderWidth: 1,
            borderColor: theme.colors.lightMore,
            // backgroundColor: theme.colors.white,
            borderRadius: 10,
            alignItems: "center",
            alignSelf: "center",
            width: dimensions?.width - 40,
            // paddingHorizontal: 4,
            paddingVertical: 5,
            marginTop: 10,
            marginBottom: 10
            // right: 10
        }}>

            <TextM color={theme.colors.grey}
                   style={{
                       textAlign: "center"
                   }}
                   fontWeight="Medium">GÉNÉRALES</TextM>
        </View>
        <StatisticsList student={user} studentId={null} style={{marginTop: 10}}/>
    </>);
}