import React, {useContext, useState} from 'react';
import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {SelectInputM} from "../../student/account/onboarding/SelectInputM";
import moment from "moment";
import {DatePickerModal} from "react-native-paper-dates";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

const MetricsFilter = (props) => {
    const {setUrlParams, urlParams} = props
    const dimensions = useContext(DimensionsContext);
    const [period, setPeriod] = useState(urlParams.period);
    const yesterday = (function(d){ d.setDate(d.getDate()-1); return d})(new Date)
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
    const [date, setDate] = useState(new Date(urlParams.reference_date));

    const formatLabelDate = () => {
        const endDate = moment(date)
        if (period === "daily")
            return endDate.format("DD-MMM-YYYY")
        else if (period === "weekly")
        {
            const startDate = endDate.clone().startOf("week")
            return "Du " + startDate.format("DD-MMM-YYYY") + " au " + endDate.format("DD-MMM-YYYY")
        }
        else if (period === "monthly")
        {
            const startDate = endDate.clone().startOf("month")
            return "Du " + startDate.format("DD-MMM-YYYY") + " au " + endDate.format("DD-MMM-YYYY")
        }
        else
            return "Du 01-août-2023 au " + endDate.format("DD-MMM-YYYY")
    }

    const range = {
        startDate: new Date("2023-08-01"),
        endDate: (function(d){ d.setDate(d.getDate()-1); return d})(new Date)
    }
    const showDatePicker = () => {
        setDatePickerVisibility(!isDatePickerVisible);
    };
    const periodItems = [
        {
            label: "Jour",
            value: "daily"
        },
        {
            label: "Semaine",
            value: "weekly",
        },
        {
            label: "Mois",
            value: "monthly",
        },
        {
            label: "Depuis le début",
            value: "lifetime",
        },
    ]
    return (
        <View style={{
            backgroundColor: theme.colors.white,
            width: "100%",
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center'
        }}>
            <SelectInputM options={periodItems}
                          noLabel
                          sliderLabel={"Choisir la periode"}
                          value={period}
                          onSelect={(value) => {
                              setPeriod(value)
                              setUrlParams({
                                  ...urlParams,
                                  period: value
                              })
                          }}
                          style={{
                              margin: 5,
                              width: (dimensions.width / 2) - 20,
                          }}
            />
            <TouchableOpacity onPress={() => {
                showDatePicker()
            }}>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: theme.roundness,
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                    //marginTop: 2,
                    borderWidth: 1,
                    borderColor: theme.colors.lightMore,
                    backgroundColor: theme.colors.primaryLighter,
                    width: (dimensions.width / 2) - 20,
                    margin: 5,
                }}>
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <FontAwesome5 name={"calendar"} solid size={18} color={theme.colors.primary}/>
                        <TextM color={theme.colors.primary}
                               fontWeight={"Medium"}
                               fontSize={10}
                               style={{
                                   marginLeft: 5
                               }}
                        >{formatLabelDate()}</TextM>
                    </View>
                    <FontAwesome5 name={"caret-down"} size={18} color={theme.colors.primary}/>
                </View>
            </TouchableOpacity>
            <DatePickerModal
                label={"Sélectionner une date"}
                cancelLabel={"Annuler"}
                saveLabel={"Confirmer"}
                closeIcon={"times"}
                editIcon={'edit'}
                allowEditing={false}
                calendarIcon={'calendar'}
                locale="fr"
                mode="single"
                visible={isDatePickerVisible}
                onDismiss={showDatePicker}
                date={date}
                validRange={range}
                onChange={(e) => {
                    showDatePicker()
                    const dd = new moment(e.date).format("YYYY-MM-DD")
                    setDate(new Date(dd))
                    setUrlParams({
                        ...urlParams,
                        reference_date: dd
                    })
                }}
            />
        </View>
    )
}

export default MetricsFilter;