import React, {useContext} from 'react';
import {View} from "react-native";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import StudyProgramItemComponent from "@common/studyPrograms/StudyProgramItemComponent";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TextM from "@common/components/text/TextM";
import {IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";


const StudyProgramScreen = (props) => {
    const { id } = props.route.params

    const currentStudyProgram = props.studyPrograms?.find(x => x?.id === id) ?? props.subscribeUserStudyPrograms?.find(x => x?.id === id);
    const navigation = useNavigation();
    const dimensions = useContext(DimensionsContext);

    return (
        <>
            <HeaderContainerM style={{
                justifyContent: "center",
                paddingRight: 15,
                flexDirection: "row"
            }}>
                <IconButton
                    icon={"arrow-left"}
                    iconColor={theme.colors.primary}
                    size={30}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
                <TextM fontSize={20}
                       fontWeight={"ExtraBold"}
                       color={theme.colors.primary}
                       style={{textAlign: "center"}}
                       wrap
                >Programme de révision</TextM>

            </HeaderContainerM>
            <View style={{
                flex: 1,
                // backgroundColor: "red",
                alignItems: 'center'
                // justifyContent: 'center'
            }}>
                <StudyProgramItemComponent studyProgram={currentStudyProgram} liveReportMode />
            </View>
        </>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        studyPrograms: state.data.studyPrograms.studyPrograms,
        subscribeUserStudyPrograms: state.data.formData?.user?.current_study_programs,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiRequestWithPrerequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(StudyProgramScreen)