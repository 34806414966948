import {Button, Dialog, Portal, Text} from "react-native-paper";
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from "react";
import {useDialog} from "@common/components/alert/dialog/useDialog";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ChampionImageM} from "../../../../student/champions/ChampionImageM";
import * as amplitude from '@amplitude/analytics-react-native';
import slugify from "slugify";


let currentRef = null
export const DialogComponent = forwardRef((props, ref) => {
    const {config, ...defaultOptions} = props;
    const {show, hide, isVisible, options, data} = useDialog({
        defaultOptions
    })

    const dimensions = useContext(DimensionsContext)

    useImperativeHandle(
        ref,
        useCallback(
            () => ({
                show,
                hide
            }),
            [hide, show]
        )
    );

    const championCenteredStyle = {
        alignItems: data?.champion ? "center" : null,
        textAlign: data?.champion ? "center" : null
    }
    return (

        <Portal>

            <Dialog visible={isVisible}
                    onDismiss={() => {

                        hide()

                    }}
                    dismissable={false}
                // theme={{
                //     colors: {
                //         backdrop: theme.colors.blackTransparent2
                //     }
                // }}
                    style={{
                        maxWidth: dimensions?.width,
                        alignSelf: 'center',
                        // position: 'fixed',
                        backgroundColor: theme.colors.white,
                        borderRadius: 20,
                        elevation: 10,
                        zIndex: 10,

                    }}>
                {data?.champion && <Dialog.Content style={{alignItems: "center"}}>
                    <ChampionImageM style={{alignSelf: 'center', marginBottom: -30}} name={data?.champion}
                                    variant={data?.variant ?? "main"} width={100}/>
                </Dialog.Content>}
                <Dialog.Title style={championCenteredStyle}>{data.text1}</Dialog.Title>
                <DimensionsContext.Provider value={{width: dimensions?.width, height: dimensions?.height}}>
                <Dialog.Content style={championCenteredStyle}>
                    {data?.component ? data?.component :
                        <Text variant="bodyMedium" style={championCenteredStyle}>{data.text2}</Text>}
                </Dialog.Content>
                </DimensionsContext.Provider>
                <Dialog.Actions style={{flexWrap: "wrap"}}>
                    {data?.buttonText3 && <Button
                        style={{
                            width: "100%",
                            marginBottom: 10,
                        }}
                        labelStyle={{
                            //     color: theme.colors.white
                            fontFamily: "Montserrat-Bold",
                            flexWrap: "wrap",
                            marginBottom: 10,
                        }}
                        buttonColor={data?.buttonColor3 ?? theme.colors.primary}
                        textColor={theme.colors.white}
                        mode={"contained"}
                        onPress={() => {
                            options.onPress3()
                            hide()
                        }}>
                        {data?.buttonText3}
                    </Button>}
                    {!data.hideButton1 && <Button
                        style={{
                            // marginRight: 5
                            width: "100%",
                            marginBottom: 10,
                        }}
                        labelStyle={{
                            // color: theme.colors.white
                            fontFamily: "Montserrat-Bold",
                            flexWrap: "wrap"
                        }}
                        mode={"contained"}
                        disabled={data?.buttonDisabled1}
                        buttonColor={data?.buttonColor1 ?? theme.colors.primary}
                        textColor={theme.colors.white}
                        onPress={() => {
                            amplitude.track("dialog.button.clicked." + slugify(data.text1?.substring(0, 15)) + "." + slugify((data.buttonText1 ?? "OK")?.substring(0, 15)))
                            options.onPress()
                            hide()
                        }}>
                        {data.buttonText1 ?? "OK"}
                    </Button>}
                    {data.buttonText2 && <Button
                        style={{
                            width: "100%",
                        }}
                        labelStyle={{
                            fontFamily: "Montserrat-Bold",
                            flexWrap: "wrap",
                        }}
                        textColor={data.buttonColor2 ?? theme.colors.primary}
                        mode={"text"}
                        onPress={() => {
                            amplitude.track("dialog.button.clicked." + slugify(data.text1?.substring(0, 15)) + "." + slugify(data.buttonText2?.substring(0, 15)))
                            options.onPressBack()
                            hide()
                        }}>
                        {data.buttonText2}
                    </Button>}
                </Dialog.Actions>
            </Dialog>

        </Portal>


    );

})

export function DialogM(props) {
    const dialogRef = useRef(null);

    const setRef = useCallback((ref) => {
        if (ref) {
            dialogRef.current = ref;
            currentRef = ref;
        }
    }, []);

    return (
        <DialogComponent ref={setRef} {...props} />
    );
}

DialogM.show = (params) => {
    currentRef?.show(params);
};

DialogM.hide = (params) => {

    currentRef?.hide(params);
};