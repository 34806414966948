import {View} from "react-native";
import {theme} from "@common/theme/theme";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {SkillBadge} from "@common/components/skill/SkillBadge";
import TextM from "@common/components/text/TextM";
import {ProgressBarM} from "@common/components/progress/ProgressBarM";
import {SkillSetTitle} from "@common/components/icons/IconsM";
import {connect} from "react-redux";
import {championSlugsToNames} from "@data/constants/championsConstants";
import {PetalCounter} from "@common/components/statistics/Counters";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {formatFrenchDe} from "../../account/SkillSetTitleButton";

function SkillItem(props) {

    const {
        stepNum, skillSet, borderBottom = false, canStartAnimation = true, style, active, width, withAnimation = true
    } = props


    const stepNumAnimation = (withAnimation && !skillSet?.is_last_evolution_read && skillSet?.current_count === 0) ? stepNum - 1 : stepNum
    const currentStep = skillSet?.steps?.[stepNumAnimation]

    const skill = props.skills?.find(s => s?.id === currentStep?.skill_id)
    const totalCount = skill?.levels_totals[currentStep?.skill_level]
    const currentCount = (stepNumAnimation < skillSet?.current_step) ? totalCount : (stepNumAnimation > skillSet?.current_step) ? 0 : skillSet?.current_count
    const stepDescription = totalCount === 1 ? skill?.description_sng?.replace("[count]", totalCount) : skill?.description?.replace("[count]", totalCount)

    const activitiesDurationToday = (user) => {
        return Math.floor(user?.statistics?.today?.activities?.duration / 60)
    }

    const totalSteps = skillSet?.steps?.length
    const color = skillSet?.color
    const titleGained = skillSet?.steps?.[stepNumAnimation]?.title_gained !== skillSet?.steps?.[stepNumAnimation - 1]?.title_gained
    const petals = skillSet?.steps?.[stepNumAnimation]?.petals_gained

    const previousCount = (withAnimation && !skillSet?.is_last_evolution_read) ? skillSet?.previous_count : currentCount

    const disabled = false


    // useEffect(() => {
    //     if (canStartAnimation && stepNumAnimation !== stepNum)
    //         setTimeout(() => {
    //             setStepNumAnimation(stepNum)
    //         }, 2000)
    //
    // }, [canStartAnimation])


    return <View style={{
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        // flex: 1,
        minHeight: 160,
        opacity: disabled ? 0.5 : 1,
        borderBottomWidth: borderBottom ? 1 : 0
    }}
    >
        <View style={{}}>

            <SkillBadge skill={skill}
                        color={color}
                        width={width}
            />
        </View>
        <View style={{
            paddingTop: 16, marginRight: 20, marginBottom: 20, width: "60%",
            alignContent: "space-between", justifyContent: "space-between"
        }}
        >

            <View style={{flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                <TextM color={color}
                       style={{}}
                       fontWeight="Bold">
                    {("Quête " + formatFrenchDe(championSlugsToNames[skillSet?.champion])).toUpperCase()} {stepNumAnimation}/{totalSteps - 1}
                </TextM>
                {currentCount === totalCount && <FontAwesome5 name={"check-circle"}
                                                              solid
                                                              style={{marginLeft: 8}}
                                                              color={skillSet?.color}
                                                              size={20}/>}
            </View>

            <TextM fontWeight="Regular"
                   color={theme.colors.greyAlt}
                   style={{}}
                   wrap
                   fontSize={14}>
                {stepDescription}
            </TextM>

            {(skill?.id === 8 && currentCount < totalCount && active) && <TextM fontWeight="Light"
                                                                                color={theme.colors.greyAlt}
                                                                                wrap
                                                                                fontSize={14}>
                Aujourd'hui : {activitiesDurationToday(props.user)} minutes
            </TextM>}


            <ProgressBarM progressColor={color}
                          style={{marginTop: 10}}
                          previousCount={previousCount ?? 0}
                          canStartAnimation={canStartAnimation}
                          withAnimation={withAnimation}
                          count={currentCount ?? 0}
                          total={totalCount}/>

            {active && !disabled && <View style={{flexDirection: "row", marginTop: 10}}>
                {titleGained && <SkillSetTitle size={22}
                                               style={{marginRight: 5}}
                                               icon={skillSet?.icon_path + skillSet?.steps?.[skillSet?.current_step]?.title_gained_icon}/>}
                <PetalCounter count={petals} small/>
            </View>}

        </View>
    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
        skill_sets: state.data.staticData?.skill_sets,
        skills: state.data.staticData?.skills
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SkillItem)