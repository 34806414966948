import React, {useContext} from "react";
import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {formatFullDateTime} from "@data/utility/Utils";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "@common/theme/theme";
import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import TextM from "@common/components/text/TextM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import AvatarM from "@common/components/avatar/AvatarM";
import {BadgeM} from "@common/components/badge/BadgeM";


export const CardButtonM = props => {

    const {
        onPress,
        icon,
        title,
        disabled = false,
        description,
        badgeCount = 0,
        membersCount = 0,
        membersCountColor = theme.colors.primary,
        width,
        liveSessionsCount = null,
        studentsListString = null
    } = props
    const dimensions = useContext(DimensionsContext)

    // console.log("studentsListString", studentsListString)

    return <TouchableHighlightM
        underlayColor={theme.colors.lighter}
        disabled={disabled}
        style={{
            // alignItems: 'flex-start',
            // justifyItems: 'stretch',
            borderRadius: 10,
            marginTop: 10,
            // marginBottom: 20,
            marginRight: membersCount || membersCount === 0 ? 0 : 10,
            // height: IS_WEB ? "100%" : null,
            borderColor: theme.colors.lightMore,
            borderWidth: 1,
            ...props.style
        }}
        onPress={onPress}>
        <View style={{
            // flex: membersCount ? 0 : 1,
            flex: 1,
            // backgroundColor: "blue",
            width: "100%",
            padding: 15
        }}>
            <View style={{flexDirection: "row"}}>
                <View style={{flex: 2}}>

                    <View style={{flexDirection: 'row', marginBottom: 10, alignItems: 'center'}}>
                        {icon}
                        <TextM wrap style={{marginLeft: 5}}>{title}</TextM>
                    </View>


                    <>{description}</>


                </View>
                <View style={{
                    // flexDirection: 'row',
                    alignItems: 'flex-end',
                    flex: 1
                    // width: "100%"
                    // justifyContent: 'space-between'
                }}>
                    {liveSessionsCount ? <View style={{flex: 1}}>
                        {liveSessionsCount}
                    </View> : null}
                    {!!membersCount&& <View
                        >
                        <View style={{marginTop: 10}}>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <FontAwesome5 name={"child"}
                                              size={20}
                                              color={membersCountColor}
                                />
                                <TextM color={membersCountColor}
                                       fontWeight={'Bold'}
                                       fontSize={20}
                                       style={{marginLeft: 5}}
                                >
                                    {membersCount}
                                </TextM>
                            </View>
                        </View>
                    </View>}
                </View>
            </View>
            {badgeCount > 0 && <BadgeM count={badgeCount}
                                       style={{
                                           backgroundColor: theme.colors.error,
                                           color: theme.colors.white,
                                           position: "absolute",
                                           top: 5,
                                           right: 5
                                       }}/>}
        </View>
    </TouchableHighlightM>
}

export function TutorInterviewCard(props) {

    const student = findItemInListWithId(props.interview?.student_id, props.students)

    return <CardButtonM onPress={() => {
        props.navigation.push("interview", {
            interview_id: props.interview.id,
            student_id: student.id
        })
        if (props.handleShowModal) props.handleShowModal()
    }}
                        icon={<FontAwesome5 name="video" size={24} solid color={theme.colors.primary}/>}
                        title={formatFullDateTime(props.interview?.date_time)}
                        description={<View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <AvatarM user={student}/>
                            <TextM style={{marginLeft: 5}}
                                   color={theme.colors.primary}>{student?.display_name}  </TextM>
                        </View>}/>

}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorInterviewCard)