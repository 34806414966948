import {useFocusEffect, useRoute} from "@react-navigation/native";
import React, {useContext} from "react";
import {BackHandler, Platform, View} from "react-native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import * as amplitude from "@amplitude/analytics-react-native";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {IconButton, ProgressBar} from "react-native-paper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";

const ProgressStepperHeader = (props) => {

    // ** Component props
    const {
        navigation,
        currentStep,
        goToPreviousStep,
        exitHandler = () => {
        },
        countSteps,
        submitted,
        headerRightComponent,
        hideProgressBar,
        exitWarning,
        noPrevious,
        noGoBack,
        track,
        previousRouteName = "menu",
        exitRouteName = "menu",
        exitWarningMessage = "Tous tes progrès dans cette activité seront perdus."
    } = props

    // if (navigation) {
    //     // ** React Navigation callback
    //     useFocusEffect(
    //         React.useCallback(() => {
    //             BackHandler.addEventListener('hardwareBackPress', backPressed);
    //             return () =>
    //                 BackHandler.removeEventListener('hardwareBackPress', backPressed);
    //         }, [currentStep])
    //     );
    // }

    const insets = useSafeAreaInsets();

    const route = useRoute() ?? {name: "unknown"}

    const backPressed = () => {
        // console.log('back pressed, current step :', currentStep)
        if (track)
            amplitude.track("button.clicked:" + route?.name + ".exit")

        if (noPrevious || currentStep <= 1) {
            // console.log('back pressed, no previous')
            if (!exitWarning) {
                if (navigation.canGoBack())
                    navigation.goBack()
                else
                    navigation.replace(previousRouteName)
            } else {
                // alertBeforeExit()
                DialogM.show({
                    text1: 'Veux-tu vraiment quitter ?',
                    text2: exitWarningMessage,
                    buttonText1: 'Quitter',
                    buttonColor1: theme.colors.error,
                    onPress: exit,
                    buttonText2: 'Annuler'
                })
            }
        } else {
            goToPreviousStep()
        }
        return true
    }

// ** Handle click on hardware back button
// useEffect(() => {
//     BackHandler.addEventListener('hardwareBackPress', backPressed);
//     return () => {
//         BackHandler.removeEventListener('hardwareBackPress', backPressed);
//     }
// }, [])


    const exit = () => {
        if (noPrevious) {
            if (navigation.canGoBack()) {
                navigation.goBack()
            } else {
                navigation.replace(exitRouteName)
            }
            exitHandler()
        } else {
            if (currentStep > 1)
                goToPreviousStep()
            else {
                if (navigation.canGoBack()) {
                    navigation.goBack()
                } else {
                    navigation.replace(exitRouteName)
                }

                exitHandler()
            }
        }
    }
    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'flex-start',
            // backgroundColor: theme.colors.accent,
            width: dimensions?.width,
            paddingTop: !props.isLive && Platform.OS === "ios" ? insets.top : 0
        }}>
            <IconButton
                icon={noPrevious ? "times" : currentStep > 1 ? "arrow-left" : "times"}
                iconColor={theme.colors.primary}
                size={32}
                onPress={backPressed}
                disabled={noGoBack}
            />

            {!hideProgressBar && <View style={{
                width: dimensions?.width - 70 - (headerRightComponent ? 60 : 0),
                height: 16,
                borderRadius: 15,
            }}>
                <ProgressBar
                    progress={submitted ? 1 : countSteps ? (currentStep / (countSteps + 1)) : 0}
                    color={theme.colors.success}
                    style={{
                        width: dimensions?.width - 70 - (headerRightComponent ? 60 : 0),
                        height: 16,
                        borderRadius: 15,
                        backgroundColor: theme.colors.lightMore
                    }}
                />
            </View>
            }
            {!!headerRightComponent && <View style={{
                width: 60,
                alignItems: "flex-end"
            }}>
                {headerRightComponent}
            </View>}
        </View>
    );
}

const mapStateToProps = state => {
    return {
        isLive: state.liveMode.isLiveModeEnabled

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ProgressStepperHeader)