import {apiConstants} from "@data/constants/apiConstants"
import {tutorsService} from "@data/services/tutorsService"

// ** Initial State
const initialState = {
    list: [],
    notes: [],
    tutorApplication: [],
    testQuestions: [],
    testAnswers: [],
    tutoringInvoices: []
}

export function tutors(state = initialState, action) {
    switch (action.type) {

        case tutorsService.submitTestAnswer.name + apiConstants._SUCCESS:
            return {
                ...state,
                testAnswers: state.testAnswers?.map(ta => ta?.id)?.includes(action.data?.id)
                    ? state.testAnswers.map(x => x.id === action.data?.id ? action.data : x)
                    : state.testAnswers.concat(action.data)
            }


        case tutorsService.getTestQuestions.name + apiConstants._SUCCESS:
            return {
                ...state,
                testQuestions: action.data?.application_questions,
                testAnswers: action.data?.application_answers
            }


        case tutorsService.getTestAnswers.name + apiConstants._SUCCESS:
            return {
                ...state,
                tutorApplication: action.data
            }
        case tutorsService.getTutors.name + apiConstants._SUCCESS:
            return {
                ...state,
                list: action.data
            }

        case tutorsService.updateNote.name + apiConstants._SUCCESS:
            return {
                ...state,
                notes: state.notes.map(n => n.id === action.data?.id ? action.data : n)
            }

        case tutorsService.postNote.name + apiConstants._SUCCESS:
            return {
                ...state,
                notes: [action.data].concat(state.notes),
            }
        case tutorsService.deleteNote.name + apiConstants._SUCCESS:
            return {
                ...state,
                notes: state.notes.filter(n => n?.id !== action.routeParams?.id)
            }
        case tutorsService.getNotes.name + apiConstants._SUCCESS:

            let newNotes = []

            if (action.data?.meta?.current_page === 1) { // refresh
                newNotes = action.data?.tutor_notes

                // console.log("page 1 reducer", action.data?.meta)
            } else { // concat
                newNotes = state?.notes.concat(action.data?.tutor_notes)
                // console.log("page + reducer", action.data?.meta)
            }

            return {
                ...state,
                notes: newNotes,
            }

        case tutorsService.getTutoringInvoices.name + apiConstants._SUCCESS:

            let tutoringInvoices = []

            if (action.data?.meta?.current_page === 1) { // refresh
                tutoringInvoices = action.data?.tutoring_invoices

                // console.log("page 1 reducer", action.data?.meta)
            } else { // concat
                tutoringInvoices = state?.tutoringInvoices.concat(action.data?.tutoring_invoices)
                // console.log("page + reducer", action.data?.meta)
            }

            return {
                ...state,
                tutoringInvoices: tutoringInvoices,
            }

        default:
            return state
    }
}

export default tutors
