import React, { useEffect, useRef, useState } from "react";
import { liveService } from "@data/services/liveService";
import { useApiStatus } from "@common/hooks/useApiStatus";
import { useCall, useOnline } from "@common/messenger/socketHook";
import {
  addInCallUser,
  addParticipant,
  addRaisedHand,
  clearMembers,
  hideIncomingCall,
  newLiveSessionLog,
  removeInCallUsers,
  removeParticipant,
  removePhoneRinging,
  removeRaisedHand,
  setActiveTime,
  setActivityFinalizedCount,
  setCallPortal,
  setInCallUsers,
  setIsLiveModeEnabled,
  setIssoundMode,
  setIsTyping,
  setLiveParticipants,
  setRaisedHands,
  setTimesSinceLastCall,
  setTypingUser,
  setVoiceRecordingIds,
  showIncomingCall,
} from "@data/redux/actions/liveMode";
import { connect } from "react-redux";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import LiveInvitationModal from "@common/liveMode/LiveInvitationModal";
import VideoCallPortal from "@common/video-call/VideoCallPortal";
import { theme } from "@common/theme/theme";
import { Platform, View } from "react-native";
import {
  changeUnreadMessageInLiveCount,
  newMessage,
} from "@data/redux/actions/broadcast";
import { hasRole } from "@data/utility/ability";
import { ActivityIndicator } from "react-native-paper";
import NotificationSubscriptionHandler from "@common/notifications/NotificationSubscriptionHandler";
import * as Notifications from "expo-notifications";
import LiveModeParticipantsIcon from "@common/header/LiveModeParticipantsIcon";
import { useInterval } from "@common/utils/useInterval";
import moment from "moment";
import LivesBottomTabsNavigator from "@common/main-menu/LivesBottomTabsNavigator";
import { PlaySoundM } from "@common/components/playSound/PlaySoundM";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  findItemInListWithId,
  sortByDateAttribute,
} from "@data/utility/dataUtils";
import { SnackBarM } from "@common/components/alert/snackbar/SnackBar";
import { setMuteAudio } from "@data/redux/actions/videoCall";
import { useKeepAwake } from "expo-keep-awake";
import { authService } from "@data/services/authService";
import { useNavigation } from "@react-navigation/native";
import {
  pauseActivity,
  updateActivityEvent,
} from "@data/redux/actions/activity";
import { IS_WEB } from "@common/utils/mobileUtils";
import RNCallKeep from "react-native-callkeep";
import { transformMessage } from "@common/messenger/CommonChat";
import { findClosestNextOccurrence } from "../../student/account/onboarding/LiveRoomCard";
import { DialogM } from "@common/components/alert/dialog/Dialog";
import {studentsService} from "@data/services/studentsService";

const OnlineUsers = (props) => {
  const clearTimeoutRef = useRef();

  useKeepAwake("Live", { suppressDeactivateWarnings: true });

  const liveSession = props.currentLiveSession;
  // console.log(liveSession?.users)
  const backPressed = () => true;

  const alerts = {
    hand_raised: {
      soundFileName: "",
      message: "",
    },
    activity_finalized: {},
    time_since_last_call: {},
  };

  const alertIfEnabled = (alertName, userId) => {};

  const navigation = useNavigation();

  // ** Update timers for each user in live to know since when he is not in call
    useInterval(() => {

        const timesSinceLastCall = liveSession?.users?.filter(x => !x?.is_tutor)?.reduce((pValue, user) => {
            const isSoundOn = props.soundModeIds?.find(id => id === user?.id)
            const closestNextOccurrence = findClosestNextOccurrence([liveSession])

            const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null
            const didStudentConnect = props.todayLiveSessionLogs?.filter(log => ((log?.action === "get_live_session") && log?.user_id === user?.id && moment(log?.created_at)?.isSameOrAfter(liveMoment.clone().subtract(15, "minutes"))))?.length > 0


            if (isSoundOn || !didStudentConnect)
            return {
              ...pValue,
              [user.id]: 0,
            };

          const joinedOrLeftCallLogs = props.todayLiveSessionLogs?.filter(
            (log) =>
              (log?.action === "sound_mode_on" ||
                log?.action === "sound_mode_off") &&
              log?.user_id === user?.id
          );
          if (joinedOrLeftCallLogs?.length > 0) {
            const lastEventMoment = moment(
              sortByDateAttribute(joinedOrLeftCallLogs, "created_at")?.[
                joinedOrLeftCallLogs?.length - 1
              ]?.created_at
            );
            return {
              ...pValue,
              [user.id]: moment().diff(lastEventMoment, "seconds"),
            };
          } else {
            const closestNextOccurrence = findClosestNextOccurrence([
              liveSession,
            ]);

            const liveMoment = closestNextOccurrence?.date
              ? moment(closestNextOccurrence?.date)
              : null;

            const logs = props.todayLiveSessionLogs?.filter(
              (log) =>
                log?.action === "get_live_session" &&
                log?.user_id === user?.id &&
                moment(log?.created_at)?.isSameOrAfter(
                  liveMoment.clone().subtract(15, "minutes")
                )
            );
            const firstGetLiveSessionMoment = moment(
              sortByDateAttribute(logs, "created_at")?.[0]?.created_at
            );

            return {
              ...pValue,
              [user.id]: moment().diff(firstGetLiveSessionMoment, "seconds"),
            };
          }
        }, {});
      props.setTimesSinceLastCall(timesSinceLastCall);
    },
    hasRole(props.user, "tutor") ? 5000 : null
  );

  // ** Update timers for each user in live to know since when he is not in sound mode
  function calculateDifference(array) {
    // loop through all logs, and calculate diffs between sound_mode_on and sound_mode_off
    let total = 0;
    let lastOn = null;
    let lastOff = null;
    array.forEach((log) => {
      if (log?.action === "sound_mode_on") {
        lastOn = moment(log?.created_at);
        lastOff = null;
      } else if (log?.action === "sound_mode_off") {
        lastOff = moment(log?.created_at);
        total += lastOff.diff(lastOn, "seconds");
      }
    });
    if (lastOn && !lastOff) {
      total += moment().diff(lastOn, "seconds");
    }
    return total;
  }

  useInterval(
    () => {
      const activeTime = liveSession?.users
        ?.filter((x) => !x?.is_tutor)
        ?.map((user) => {
          //console.log(props.todayLiveSessionLogs)
          const closestNextOccurrence = findClosestNextOccurrence([liveSession])
            const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null
            const didStudentConnect = props.todayLiveSessionLogs?.filter(log => ((log?.action === "get_live_session") && log?.user_id === user?.id && moment(log?.created_at)?.isSameOrAfter(liveMoment.clone().subtract(15, "minutes"))))?.length > 0
            const soundModeLogs = props.todayLiveSessionLogs?.filter(
            (log) =>
              (log?.action === "sound_mode_on" ||
                log?.action === "sound_mode_off") &&
              log?.user_id === user?.id
          && moment(log?.created_at)?.isSameOrAfter(liveMoment.clone().subtract(15, "minutes"))) ;
          calculateDifference(soundModeLogs);
          if (soundModeLogs?.length > 0&& didStudentConnect) {
            return {
              id: user?.id,
              time: calculateDifference(soundModeLogs) ?? 0,
            };
          }  else {
                return {
                    id: user?.id,
                    time: 0
                }
            }
        }, {})
        props.setActiveTime(activeTime)
    }, hasRole(props.user, "tutor") ? 5000 : null);

  /*    useEffect(() => {
            if (liveSession?.live_room?.version === 'v1') {
                const shouldAlert = (time) => time >= TIME_SINCE_LAST_CALL_MINUTES * 60 && (time - TIME_SINCE_LAST_CALL_MINUTES * 60) % 120 < 5
                const filteredTimesSinceLastCall = Object?.values(props.timesSinceLastCall ?? {})?.filter(x => shouldAlert(x))
                if (filteredTimesSinceLastCall?.length > 0) {
                    // console.log("snack")
                    // PlaySoundM.play(require('../../../assets/sounds/question_002.mp3'))

                    const index = Object?.values(props.timesSinceLastCall ?? {}).findIndex(x => shouldAlert(x))
                    // console.log("index", index)
                    const user = liveSession?.users?.filter(x => !x?.is_tutor)?.[index]
                    SnackBarM.show({
                        text1: "⏰ " + user?.display_name + " n'a pas rejoint l'appel depuis " + Math.floor(Object?.values(props.timesSinceLastCall ?? {})[index] / 60) + " min",
                        duration: 6000
                    })
                }
            }
        }, [props.timesSinceLastCall])*/

  // console.log("onff", props.ongoingActivity)

  // every 60 seconds, get live session
  useInterval(() => {
    props.apiRequest(liveService.getLiveSession, { id: props.liveSessionId });
    props.apiRequest(liveService.getCall, { id: props.liveSessionId });
  }, 60 * 1000);

  useEffect(() => {
    props.apiRequest(liveService.getLiveSession, { id: props.liveSessionId });
    props.apiRequest(liveService.getCall, { id: props.liveSessionId });

    const subscription = Notifications.addNotificationReceivedListener(
      (notification) => {
        // console.log("notification", notification);

        if (
          notification.request.content.data.path === "incoming-call" &&
          notification.request.content.data.data?.live_session_id ===
            props.liveSessionId
        ) {
          // console.log("dismissing");

          setTimeout(() => {
            Notifications.dismissNotificationAsync(
              notification.request.identifier
            ).then();
          }, 1000);
        }
      }
    );

    // BackHandler.addEventListener('hardwareBackPress', backPressed);
    return () => {
      if (Platform.OS !== "web") subscription.remove();

      // BackHandler.removeEventListener('hardwareBackPress', backPressed);
    };
  }, []);

  // useEffect(() => {
  //     console.log("timesSinceLastCall CHANGE", props.timesSinceLastCall)
  //     console.log("todayLiveSessionLogs", props.todayLiveSessionLogs)
  //
  // }, [props.timesSinceLastCall])

  const [token, setToken] = useState(null);
  const [ssToken, setSSToken] = useState(null);

  useApiStatus(
    liveService.getCall, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (data) => {
      // const isFinalized = data?.today_live_session_logs?.find(log => (log?.action === "finalized_live_session"))
      // if (isFinalized) {
      //     DialogM.show({
      //         text1: hasRole(props.user, 'student') ? "Cette session a été clôturée par le tuteur aujourd'hui" : 'Session terminée',
      //     })
      // }
      // console.log("SUCCESS GET CALL")
      // console.log(data)
    },
    () => {
      props.setIsLiveModeEnabled(false);
    }
  );

  useApiStatus(
    liveService.getLiveSession, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData) => {
      // navigation.push("end-session-tutor")
      if (!ssToken) {
        setSSToken(successData.screenshare_token);
      }
      if (!token) {
        setToken(successData.conference_token);
      }
    },
    (error) => {
      // if error message starts with "No query results for model" then show dialog for refreshing
      if (error?.message?.startsWith("No query results for model") && IS_WEB) {
        DialogM.show({
          champion: "abou",
          variant: "unhappy",
          text1: "Erreur de synchronisation des données",
          text2: "Actualise la page ou quitte le Live et reviens.",
          buttonText1: "Quitter le live",
          onPress: () => {
            props.setIsLiveModeEnabled(false);
          },
          buttonText3: "Actualiser",
          onPress3: () => {
            //reload page
            window.location.reload();
          },
          buttonText2: "Retour",
          onPressBack: () => {
            // _pusherNotConnectedCount.current = 0
          },
        });
      } else {
        props.setIsLiveModeEnabled(false);
        if (!IS_WEB) RNCallKeep.endAllCalls();
      }
    }
  );

  useApiStatus(
    liveService.joinCall, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (data) => {
      //console.log(data)
      // setFire(true)
    }
  );

  useApiStatus(
    liveService.leaveCall, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (data) => {
      //console.log(data)
      // setFire(false)
    }
  );

  const pendingRemoves = {};
  useOnline(
    {
      channel: `presence-messenger.thread.${liveSession?.thread_id}`,
      hereCallBack: (usersIds) => {
        props.setLiveParticipants(usersIds?.map((x) => parseInt(x)));
      },
      joiningCallBack: (user) => {
        // console.log("joining", user)
        let pendingRemoveTimeout = pendingRemoves[user.id ?? user.userId];
        if (pendingRemoveTimeout) {
          // user left, but has rejoined
          clearTimeout(pendingRemoveTimeout);
          pendingRemoves[user.id ?? user.userId] = null;
        } else {
          props.addParticipant(parseInt(user.id ?? user.userId));

          props.newLiveSessionLog({
            user_id: parseInt(user.id ?? user.userId),
            live_session_id: props.liveSessionId,
            action: "get_live_session",
            created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
          if (hasRole(props.user, "tutor")) {
            PlaySoundM.play(require("../../../assets/sounds/glass_006.mp3"));
          }
        }
      },
      leavingCallBack: (user) => {
        // console.log("leaving", user)
        pendingRemoves[user.id ?? user.userId] = setTimeout(function () {
          props.removeParticipant(parseInt(user.id ?? user.userId));
          if (hasRole(props.user, "tutor")) {
            PlaySoundM.play(require("../../../assets/sounds/error_004.mp3"));
          }
          pendingRemoves[user.id ?? user.userId] = null;
        }, 10 * 1000); // wait 10 seconds
      },

      errorCallBack: (errors) => {
        console.log(errors);
      },

      typingCallback: (e) => {
        const id = props.user?.id;
        //console.log(e)
        if (e.data?.user?.toString() === id.toString()) return;

        if (clearTimeoutRef.current) {
          clearTimeout(clearTimeoutRef.current);
        }
        const jsonData = e?.data ? JSON.parse(e.data).user?.toString() : null;
        const userID = e?.userId?.toString() ?? jsonData ?? e?.user?.toString();
        // const typingUser = liveSession?.users?.find((user) => user.id?.toString() === userID)
        props.setTypingUser(parseInt(userID));
        props.setIsTyping(true);
        clearTimeoutRef.current = setTimeout(() => {
          props.setIsTyping(false);
        }, 3000);
      },
      activityFinalizedCallback: (e) => {
        if (hasRole(props.user, "tutor")) {
                const jsonData = e?.data ? JSON.parse(e.data).user?.toString() : null
                const jsonData2 = e?.data ? JSON.parse(e.data).displayName?.toString() : null
                const userID = e?.userId?.toString() ?? jsonData ?? e?.user?.toString()
                const userDisplayName = jsonData2 ?? e?.displayName?.toString()
                props.setActivityFinalizedCount(props.liveSessionId, userID)
                PlaySoundM.play(require('../../../assets/sounds/finished.mp3'))
                SnackBarM.show({text1: "🎯 " + userDisplayName + " a terminé une activité", duration: 6000})
                if (userID)
                    props.apiRequest(studentsService.getStudent, {id: userID})
        }
      },
      activityCameraOnCallback: (e) => {
        if (
          hasRole(props.user, "tutor") ||
          (hasRole(props.user, "student") &&
            props.soundModeIds?.includes(props.user?.id))
        ) {
          const jsonData2 = e?.data
            ? JSON.parse(e.data).displayName?.toString()
            : null;
          const userDisplayName = jsonData2 ?? e?.displayName?.toString();
          SnackBarM.show({
            text1:
              "📷 " +
              userDisplayName +
              " est temporairement déconnecté pour prendre une photo",
            duration: 6000,
          });
        }
      },
      newTodoCallback: (e) => {
        const rawJSONData = e?.data ? JSON.parse(e.data) : null;
        const jsonData = rawJSONData?.user
          ? rawJSONData
          : rawJSONData?.data
          ? JSON.parse(rawJSONData?.data)
          : null;
        const studentId = e?.data ? jsonData?.user?.toString() : null;
        const senderId = e?.userId?.toString() ?? e?.user?.toString();

            if (hasRole(props.user, "tutor") && senderId !== props.user?.id?.toString()) {
                if (studentId)
                    props.apiRequest(studentsService.getStudent, {id: studentId})
            } else if (hasRole(props.user, "student") && studentId === props.user?.id?.toString() && senderId !== props.user?.id?.toString()) {
                props.apiRequest(authService.getUser)
        }
      },
    },
    [],
    false
  );

  useCall(
    {
      channel: `private-messenger.user.${props.user?.id}`,
      joiningCallBack: (event) => {
        // console.log("event JOINING CALL", event)
        props.addInCallUser(event.owner_id);
        props.removePhoneRinging(parseInt(event.owner_id));
        props.newLiveSessionLog({
          user_id: parseInt(event.owner_id),
          live_session_id: props.liveSessionId,
          action: "joined_call",
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      },
      leavingCallBack: (event) => {
        props.removeInCallUsers(event.owner_id);
        props.newLiveSessionLog({
          user_id: parseInt(event.owner_id),
          live_session_id: props.liveSessionId,
          action: "left_call",
          created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        });
      },
      handRaisedCallBack: (event) => {
        props.addRaisedHand(event.owner_id);

        // props.playASound('../../../assets/sounds/toggle_001.mp3'))
        if (hasRole(props.user, "tutor")) {
          PlaySoundM.play(require("../../../assets/sounds/toggle_001.mp3"));

          const user = findItemInListWithId(event.owner_id, liveSession?.users);
          // console.log("user", user)
          // console.log("event.owner_id", event.owner_id)
          // console.log("liveSession?.users", liveSession?.users)
          SnackBarM.show({
            text1: "✋ " + user?.display_name + " a levé la main",
            duration: 4000,
          });
        }
      },
      handLoweredCallBack: (event) => {
        props.removeRaisedHand(event.owner_id);
      },
      incomingCallBack: (event) => {
        // console.log("event INCOMING CALL", event)
        props.showIncomingCall();
      },
      newMessageCallBack: (event) => {
        /*if (event.thread_id === liveSession.thread_id) {
                props.newMessage(event)*/
        if (event.owner?.provider_id !== props.user.id) {
          let item = transformMessage(event);
          props.newMessage(event);
          props.changeUnreadMessageInLiveCount(event.thread_id, null);
          if (hasRole(props.user, "tutor")) {
            PlaySoundM.play(require("../../../assets/sounds/toggle_001.mp3"));
          }
        }
      },
      finalizeLiveCallBack: (event) => {
        props.pauseActivity(true);
        setTimeout(() => {
          if (hasRole(props.user, "student")) {
            navigation.push("end-session-student");
          }
        }, 2000);
      },
      soundModeEnabledCallBack: (event) => {
        props.setIssoundMode(true, props.user?.id);
        PlaySoundM.play(require("../../../assets/sounds/maximize_001.mp3"));
        SnackBarM.show({
          text1: liveSession?.tutor?.display_name + " t'a mis en mode dialogue",
          duration: 3000,
        });
      },
      soundModeDisabledCallBack: (event) => {
        props.setIssoundMode(false, props.user?.id);
        PlaySoundM.play(require("../../../assets/sounds/minimize_001.mp3"));
        SnackBarM.show({
          text1:
            liveSession?.tutor?.display_name + " t'a mis en mode silencieux",
          duration: 3000,
        });
      },
      voiceRecordingStartedCallBack: (event) => {
        props.setVoiceRecordingIds(event?.owner_id);
      },
      voiceRecordingStoppedCallBack: (event) => {
        props.setVoiceRecordingIds(event?.owner_id);
      },
      chapterFeelingCallback: (e) => {
          const data = e
        if (data?.chapter_name && data?.student_chapter_id)
          navigation.push("feeling", {
            chapterName: data?.chapter_name,
            chapterId: data?.student_chapter_id,
          });
      },
      activityUpdatedCallback: (e) => {
        if (hasRole(props.user, "tutor")) {
          if (IS_WEB) props.updateActivityEvent(e);
          else {
            props.updateActivityEvent(JSON.parse(e?.data));
          }
        }
      },
    },
    [],
    false
  );

  const insets = useSafeAreaInsets();

  return (
    <View
      style={{
        backgroundColor: theme.colors.whiteAlmost,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        // paddingTop: insets.top,
      }}
    >
      {Platform.OS !== "web" && <NotificationSubscriptionHandler />}
      {props.getLiveSessionRequest && !liveSession ? (
        <ActivityIndicator
          color={theme.colors.primary}
          animating={true}
          size={"large"}
        />
      ) : (
        <LivesBottomTabsNavigator />
      )}

      <VideoCallPortal
        roomLink={liveSession?.conference_link}
        suspended={props.cameraOn}
        token={token}
        ssToken={ssToken}
        setToken={setToken}
        setSSToken={setSSToken}
        users={liveSession?.users}
        renderTopLeftComponent={() => <LiveModeParticipantsIcon />}
      />

      <LiveInvitationModal
        onDismiss={props.hideIncomingCall}
        visible={props.visibleInvite}
      />
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    getLiveSessionRequest: state.api.getLiveSession?.request,
    getCallRequest: state.api.getCall?.request,
    user: state.data.currentUser?.object,
    successes: state.data.staticData?.successes,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    liveSessions: state.data.currentUser?.object?.live_sessions,
    liveSessionId: state.liveMode?.liveSessionId,
    tutors: state.liveMode?.liveTutors,
    participants: state.liveMode?.liveParticipants,
    inCallUsers: state.liveMode?.inCallUsers,
    todayLiveSessionLogs: state.liveMode?.todayLiveSessionLogs,
    timesSinceLastCall: state.liveMode?.timesSinceLastCall,
    callPortal: state.liveMode?.callPortal,
    conferenceToken: state.liveMode?.conferenceToken,
    screenshareToken: state.liveMode?.screenshareToken,
    visibleInvite: state.liveMode?.visibleInvite,
    soundMode: state.liveMode?.soundMode,
    soundModeIds: state.liveMode?.soundModeIds,
    ongoingActivity: state.activity?.ongoingActivity,
    cameraOn: state.activity?.cameraOn,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setIsLiveModeEnabled,
  setLiveParticipants,
  addParticipant,
  setIsTyping,
  setTypingUser,
  removeParticipant,
  clearMembers,
  setInCallUsers,
  addInCallUser,
  removeInCallUsers,
  setRaisedHands,
  addRaisedHand,
  removeRaisedHand,
  setCallPortal,
  showIncomingCall,
  hideIncomingCall,
  newMessage,
  newLiveSessionLog,
  setTimesSinceLastCall,
  removePhoneRinging,
  changeUnreadMessageInLiveCount,
  setIssoundMode,
  setVoiceRecordingIds,
  setActiveTime,
  setMuteAudio,
  setActivityFinalizedCount,
  pauseActivity,
  updateActivityEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnlineUsers);
