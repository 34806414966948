import {useCallback, useState} from 'react'

export const DEFAULT_DATA = {
    component: undefined,
    champion: undefined,
    variant: undefined,
    text1: undefined,
    text2: undefined,
    buttonText1: "Ok",
    buttonText2: undefined,
    buttonText3: undefined,
    buttonDisabled1: false,
    duration: undefined,
    buttonColor1: undefined,
    buttonColor2: undefined,
    buttonColor3: undefined,
    hideButton1: false
};

export const DEFAULT_OPTIONS = {
    onShow: () => {
    },
    onHide: () => {
    },
    onPress: () => {
    },
    onPressBack: () => {
    },
    onPress3: () => {
    },
    props: {}
};

export function mergeIfDefined(
    obj1,
    obj2
) {
    const newObj = {
        ...obj1
    };
    Object.entries(obj2).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
            newObj[key] = value;
        }
    });
    return newObj;
}

export function useSnackBar({defaultOptions}) {
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState(DEFAULT_DATA);

    const initialOptions = mergeIfDefined(
        DEFAULT_OPTIONS,
        defaultOptions)

    const [options, setOptions] = useState(initialOptions);

    const hide = useCallback(() => {
        setIsVisible(false);
        options.onHide();
    }, [options]);

    const show = useCallback(
        (params) => {
            const {
                text1 = DEFAULT_DATA.text1,
                text2 = DEFAULT_DATA.text2,
                buttonText1 = DEFAULT_DATA.buttonText1,
                buttonText2 = DEFAULT_DATA.buttonText2,
                buttonColor1 = DEFAULT_DATA.buttonColor1,
                buttonColor2 = DEFAULT_DATA.buttonColor2,
                duration = DEFAULT_DATA.duration,
                onShow = initialOptions.onShow,
                onHide = initialOptions.onHide,
                onPress = initialOptions.onPress,
                onPressBack = initialOptions.onPressBack,
                props = initialOptions.props
            } = params;
            setData({
                text1,
                text2,
                buttonText2,
                buttonText1,
                duration,
                buttonColor1,
                buttonColor2,
            });
            setOptions(
                mergeIfDefined(initialOptions, {
                    duration,
                    onShow,
                    onHide,
                    onPress,
                    onPressBack,
                    props
                })
            );
            setIsVisible(true);
            onShow();
        },
        [initialOptions]
    );


    return {
        isVisible,
        data,
        options,
        show,
        hide
    };
}