import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ChallengeHeaderNew from "./ChallengeHeaderNew";

const ChallengeProgressCongrats = props => {

    const {challenge, activity} = props


    useEffect(() => {

    }, [])
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        alignItems: "center",
        paddingTop: 10,
        paddingHorizontal: 15,
        width: dimensions?.width
    }}>

        <TextM fontSize={34}
               fontWeight="ExtraBold"
               color={theme.colors.primary}
               style={{textAlign: "center", alignSelf: "center", marginTop: 20}}
               wrap
        >AVANCEMENT DU DÉFI</TextM>
        <ChallengeHeaderNew challenge={challenge}
            // columnMode
            //              hideProgressBar
                            hideChevron/>

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeProgressCongrats)