import React, {useContext} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ModalM} from "@common/components/modal/ModalM";
import {ScrollView, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";

export const TutorNextEventsModal = props => {
    const {isOpen, data, handleShowModal, renderItem, title} = props
    const dimensions = useContext(DimensionsContext)
    return (
        <ModalM visible={isOpen}
                onDismiss={handleShowModal}
                style={{width: IS_WEB && dimensions.width > 800 ? "50%" : dimensions?.width - 50}}>
            <ScrollView style={{
                marginTop: 10,
                backgroundColor: theme.colors.white,
                paddingHorizontal: 20,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10
            }}
                        contentContainerStyle={{
                            flexDirection: IS_WEB && dimensions.width > 800 ? "row" : "column",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            paddingBottom: 30
                        }}>
                <View style={{marginBottom: 10, marginTop: 20, width: "100%"}}>
                    <TextM fontWeight={"Bold"} fontSize={20} wrap>{title}</TextM>
                </View>

                {data?.map((item, key) => {
                    return renderItem(item, key, () => {
                        handleShowModal()
                    })
                })}
            </ScrollView>
        </ModalM>
    )
}