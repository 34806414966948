import React, { useContext, useEffect, useState } from "react";
import { Dimensions, View, TouchableOpacity } from "react-native";
import { IconButton } from "react-native-paper";
import { theme } from "@common/theme/theme";
import { DimensionsContext } from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import { liveService } from "@data/services/liveService";
import { useApiStatus } from "@common/hooks/useApiStatus";
import { setOngoingActivity } from "@data/redux/actions/activity";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { setIsLiveModeEnabled } from "@data/redux/actions/liveMode";
import { StatusBar } from "expo-status-bar";
import AvatarM from "@common/components/avatar/AvatarM";
import { IconImageM } from "@common/components/icons/IconsM";
import liveLogo from "../../../assets/icons/generic/live.png";
import SatisfactionComponent from "@common/liveMode/SatisfactionComponent";
import { isArraySubset } from "@data/utility/Utils";
import { useNavigation } from "@react-navigation/native";

const SatisfactionScreen = (props) => {
  const { rating, liveSessionId = null } = props.route.params;

  const [score, setScore] = useState(rating);
  const [studentComment, setStudentComment] = useState([]);
  const [allowStudentCommentSending, setAllowStudentCommentSending] =
    useState(false);

  const dimensions = useContext(DimensionsContext);
  const navigation = useNavigation();
  const insets = useSafeAreaInsets();
  const liveSession = props.liveSessions?.find(
    (x) => x?.id?.toString() === liveSessionId.toString()
  );
  const tutor = props.liveSessions?.find(
    (x) => x?.id?.toString() === liveSessionId.toString()
  )?.tutor;

  useEffect(() => {
    // console.log(studentComment)
    if (studentComment?.length > 0) {
      props.apiRequest(
        liveService.sendStudentComment,
        { id: liveSession?.id },
        { student_comment: studentComment }
      );
    }
    if (allowStudentCommentSending) {
      props.apiRequest(
        liveService.sendStudentComment,
        { id: liveSession?.id },
        { student_comment: null }
      );
      setAllowStudentCommentSending(false);
    }
  }, [studentComment]);

  useEffect(() => {
    if (allowStudentCommentSending) setStudentComment([]);
  }, [allowStudentCommentSending]);

  useEffect(() => {
    if (
      studentComment?.length > 0 &&
      !isArraySubset(props.satisfactionStudentComments?.[score], studentComment)
    ) {
      // console.log("reinitialize student comment")
      setAllowStudentCommentSending(true);
    }
  }, [score]);

  useApiStatus(
    liveService.sendStudentComment, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    () => {}, // success callback
    () => {},
    true
  );

  return (
    <>
      <View
        style={{
          alignSelf: "center",
          alignItems: "center",
          width: Dimensions.get("window").width,
          marginTop: insets.top,
          backgroundColor: theme.colors.white,
          flex: 1,
        }}
      >
        <View
          style={{
            flex: 1,
            alignSelf: "center",
            alignItems: "center",
            width: dimensions?.width,
            // backgroundColor: theme.colors.white
          }}
        >
          <View
            style={{
              // flex: 1,
              alignSelf: "center",
              width: dimensions?.width,
              // paddingTop: insets.top,
              // backgroundColor: theme.colors.white
            }}
          >
            <IconButton
              icon={"times"}
              iconColor={theme.colors.primary}
              size={32}
              style={{}}
              onPress={() => {
                if (navigation.canGoBack()) navigation.goBack();
                else navigation.replace("menu");
              }}
            />
            <View
              style={{
                alignSelf: "center",
                alignItems: "center",
                marginHorizontal: 10,
                // width: dimensions?.width,
              }}
            >
              <View
                style={{
                  width: 120,
                  alignItems: "center",
                }}
              >
                <AvatarM user={tutor} size={100} style={{ marginBottom: 20 }} />
                <IconImageM
                  source={liveLogo}
                  style={{ position: "absolute", top: 0, right: 0 }}
                  width={40}
                />
              </View>

              <TextM
                wrap
                fontSize={18}
                fontWeight={"Bold"}
                color={theme.colors.primary}
                style={{ marginBottom: 80, textAlign: "center" }}
              >
                Qu’as-tu pensé de cette session live avec {tutor?.display_name}{" "}
                ?
              </TextM>
              <SatisfactionComponent
                rating={score}
                setRating={setScore}
                withApiRequest
                requestApiService={liveService.sendStudentComment}
                requestRouteParams={{ id: liveSession?.id }}
                ratingBackgroundColor={theme.colors.light}
              />
              <TextM
                wrap
                fontSize={15}
                color={theme.colors.grey}
                style={{ marginTop: 20, textAlign: "center" }}
              >
                {props.satisfactionSubTitles?.[score]}
              </TextM>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 80,
                  // justifyContent: "space-between",
                  flexWrap: "wrap",
                  width: dimensions.width < 800 ? dimensions.width * 0.9 : null,
                }}
              >
                {score !== null &&
                  props.satisfactionStudentComments?.[score]?.map(
                    (subTitle, index) => {
                      const isStudentCommentSelected =
                        studentComment.includes(subTitle);
                      return (
                        <TouchableOpacity
                          key={index}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            padding: dimensions.width > 800 ? 10 : 7,
                            borderRadius: 2,
                            borderColor: isStudentCommentSelected
                              ? theme.colors.primary
                              : theme.colors.light,
                            borderWidth: 1,
                            marginHorizontal: dimensions.width > 800 ? 10 : 5,
                            marginVertical: dimensions.width > 800 ? null : 5,
                            backgroundColor: isStudentCommentSelected
                              ? theme.colors.primaryLighter
                              : theme.colors.white,
                          }}
                          onPress={() => {
                            if (
                              isStudentCommentSelected &&
                              studentComment.length > 1
                            )
                              setStudentComment(
                                studentComment.filter((x) => x !== subTitle)
                              );
                            else if (
                              isStudentCommentSelected &&
                              studentComment.length === 1
                            )
                              setAllowStudentCommentSending(true);
                            else
                              setStudentComment([...studentComment, subTitle]);
                          }}
                        >
                          <TextM
                            fontSize={dimensions.width > 800 ? 15 : 13}
                            style={{ flex: 1 }}
                            color={
                              isStudentCommentSelected
                                ? theme.colors.primary
                                : theme.colors.light
                            }
                            fontWeight={"Medium"}
                          >
                            {subTitle}
                          </TextM>
                        </TouchableOpacity>
                      );
                    }
                  )}
              </View>
            </View>
          </View>
        </View>
      </View>
      <FilledButtonM
        onPress={() => {
          if (navigation.canGoBack()) navigation.goBack();
          else navigation.replace("menu");
        }}
        label={"Terminer"}
        color={theme.colors.error}
        style={{
          alignSelf: "center",
          position: "absolute",
          bottom: 0,
          marginBottom: 30,
        }}
      />
      <StatusBar
        style="dark"
        backgroundColor={theme.colors.white}
        translucent={false}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    ongoingActivity: state.activity?.ongoingActivity,
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    liveReport: state.liveMode?.liveReport,
    satisfactionStudentComments:
      state.data.staticData?.satisfaction_student_comments,
    satisfactionSubTitles: state.data.staticData?.satisfaction_subtitles,
    isLiveModeEnabled: state.liveMode?.isLiveModeEnabled,
    users: state.data?.users?.usersList,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setOngoingActivity,
  setIsLiveModeEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(SatisfactionScreen);
