import {TouchableHighlight, View} from "react-native";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React, {useContext} from "react";
import {DimensionsContext} from "../../utils/mobileUtils";
import {IconImageM} from "../icons/IconsM";

export function StatisticsItem(props) {

    // ** Component props
    const {count, title, color, source, uri, onPress, style} = props
    const dimensions = useContext(DimensionsContext)

    return <TouchableHighlight style={{
        // borderWidth: 1,
        borderRadius: 15,
        borderColor: theme.colors.lightMore,
        marginRight: 10,
        marginTop: 5,
        flexGrow: 0,
        alignItems: "center",
        height: 69,
        width: dimensions?.width / 2 - 20,
        ...style
    }}
                               onPress={onPress}
                               underlayColor={theme.colors.lightMore}
    >
        <View style={{
            // paddingVertical: 10,
            borderColor: theme.colors.lightMore,
            flexDirection: "row",
            alignItems: "flex-start",
            paddingLeft: 10,
            paddingRight: 10,
            width: "100%"
        }}
        >
            <IconImageM width={30}
                        source={source}
                        uri={uri}
                        style={{
                            marginVertical: 8,
                            // borderRadius: 4

                        }}/>
            <View style={{
                // width: "100%",
                paddingVertical: 8,
                marginLeft: 10,
                width: "75%"
            }}
            >
                <TextM fontWeight="Bold"
                       color={color ?? theme.colors.grey}
                       wrap
                       fontSize={16}>{count}</TextM>
                <TextM fontWeight="Regular"
                       color={theme.colors.grey}
                       style={{
                           marginTop: 1
                       }}
                       wrap
                       fontSize={12}>{title}</TextM>

            </View>
        </View>
    </TouchableHighlight>;
}