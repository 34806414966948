//import {getMeetingCode, getMeetingUrl} from "@common/video-call/callUtilities";
import videoCallConstants from "../../../constants/videoCallConstants";

// ** Initial State
const initialState = {
    userName: '',
    //roomLink: getMeetingUrl(),
    //roomCode: getMeetingCode(),
    hmsLocalPeer: null,
    hmsInstance: null,
    joinConfig: {
        mutedAudio: true,
        mutedVideo: true,
        mirrorCamera: true,
    },
}

export function videoCall(state = initialState, action) {
    switch (action.type) {
        case videoCallConstants.SAVE_USER_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case videoCallConstants.CLEAR_HMS_INSTANCE:
            return {
                ...state,
                hmsInstance: null,
            };

        case videoCallConstants.RESET_JOIN_CONFIG:
            return {...state, joinConfig: initialState.joinConfig};

        case videoCallConstants.CHANGE_JOIN_AUDIO_MUTED:
            return {
                ...state,
                joinConfig: {
                    ...state.joinConfig,
                    mutedAudio: action.payload.mutedAudio ?? true,
                },
            };
        case videoCallConstants.CHANGE_JOIN_VIDEO_MUTED:
            return {
                ...state,
                joinConfig: {
                    ...state.joinConfig,
                    mutedVideo: action.payload.mutedVideo ?? true,
                },
            };
        case videoCallConstants.CHANGE_MIRROR_CAMERA:
            return {
                ...state,
                joinConfig: {
                    ...state.joinConfig,
                    mirrorCamera: action.payload.mirrorCamera ?? true,
                },
            };

        default:
            return state
    }
}

export default videoCall
