import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import React, {useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {subscriptionStatusNames} from "@data/constants/formConstants";
import DateTimePicker from "./DateTimePicker";
import {formatFullDateTime} from "@data/utility/Utils";
import {ActivityIndicator} from "react-native-paper";

const ChangePrivileges = (props) => {

    const navigation = useNavigation()

    const [formValues, setFormValues] = useState(null)

    const student = props.users?.find(x => x?.id === parseInt(props.route.params?.studentId))

    useEffect(() => {
        props.apiRequest(backOfficeService.getUserBackoffice, {id: props.route.params?.studentId})
    }, [])
    useApiStatus(
        backOfficeService.getUserBackoffice, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(student)
            // console.log("successData", successData)
        }
    )

    useApiStatus(
        backOfficeService.changePrivileges, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Opération réussie !"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )

    const defaultValues = {}
    const plan = student?.subscription_state?.plan !== "-" ? " - Formule " + student?.subscription_state?.plan : ""
    const subscriptionState = subscriptionStatusNames?.[student?.subscription_state?.status] ?? "-"
    const trialEndsAt = formatFullDateTime(student?.trial_ends_at).includes("Aujourd'hui à") ? "" : formatFullDateTime(student?.trial_ends_at)
    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Modifier les privilèges",
            description: subscriptionState + plan,
            type: "select.single", // select.single, select.multiple, text, date
            name: "product",
            options: [
                {
                    label: "Basic",
                    value: "basic",
                    disabled: student?.subscription_state?.status === "recurring"
                },
                {
                    label: "Offre initiale",
                    value: "initial"
                },
                {
                    label: "Offre essentielle",
                    value: "essential"
                },
                {
                    label: "Offre intégrale",
                    value: "integral"
                }
            ],
            defaultValue: student?.current_offer,
            validationRule: {
                ...validation.required()
            }
        },
        {
            is_masked: student?.subscription_state?.status === "recurring",
            label: "Modifier la date de fin de l'essai gratuit",
            description: "Date actuelle :" + trialEndsAt,
            type: "date-time", // select.single, select.multiple, text, date
            name: "trial_ends_at",
            defaultValue: student?.trial_ends_at,
            validationRule: {
                ...validation.required()
            }
        }

    ]

    const onSubmit = data => {
        if (props.changePrivilegesRequest) return
        // console.log(data)
        props.apiRequest(backOfficeService.changePrivileges, {id: props.route.params?.studentId}, data)
    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            {props.getUserBackofficeRequest
                ? <ActivityIndicator animating={true}
                                     style={{
                                         marginTop: 50
                                     }}
                                     size="large"
                                     color={theme.colors.primary}/>
                : <FormStepper navigation={navigation}
                               track
                               formData={formData?.filter(x => !x.is_masked)}
                               champion={null}
                               setFormValues={setFormValues}
                               defaultValues={defaultValues}
                               onSubmit={onSubmit}
                               submitted={props.changePrivilegesRequest}
                               previousRouteName={""}
                               submittedMessage=""
                               serverError={props.changePrivilegesError}
                               submitButtonText="Terminer"
                />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        getUserBackofficeRequest: state.api?.getUserBackoffice?.request,
        changePrivilegesRequest: state.api?.changePrivileges?.request,
        changePrivilegesError: state.api?.changePrivileges?.error

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChangePrivileges)