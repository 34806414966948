import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import {Platform, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {Avatar} from "react-native-paper";
import SuccessAnimation from "@common/animations/SuccessAnimation";


const ApplicationStepCongrats = (props) => {
    const dimensions = useContext(DimensionsContext)


    useEffect(() => {
        PlaySoundM.play(require('../../../assets/sounds/finished.mp3'))

    }, [])

    const iconSize = 70
    const successSize = iconSize * 4 / 5

    return (
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingHorizontal: 15,
            paddingBottom: 20,
            width: dimensions?.width
        }}>
            <TextM fontSize={34}
                   fontWeight="ExtraBold"
                   color={theme.colors.success}
                   style={{textAlign: "center", alignSelf: "center", marginVertical: 20}}
                   wrap
            >
                {/*{congratsMessage(activity).toUpperCase()}*/}
                ÉTAPE TERMINÉE !
            </TextM>

            <View style={{
                marginTop: 0,
                alignItems: "center",
                justifyContent: "center"
            }}>


                <Avatar.Image size={iconSize * 1.7}
                              style={{
                                  backgroundColor: theme.colors.white,
                                  borderColor: theme.colors.success
                                  // borderWidth: 1
                              }}/>
                <SuccessAnimation
                    size={successSize}
                    iconSize={successSize * 0.7}
                    dotColor={theme.colors.success}
                    backgroundColor={theme.colors.success}
                    iconColor={"white"}
                    dotSize={20}
                    duration={Platform.OS !== 'web' ? 2000 : 500}
                    style={{
                        position: "absolute",
                        alignSelf: "center",
                        top: -successSize / 3
                        // right: -successSize / 2
                        // right: -30
                    }}
                    animatedLayerColor={"white"}
                />
            </View>
        </View>)
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStepCongrats)