import FormStepperModal from "@common/components/stepper/FormStepperModal";
import {validation} from "@data/utility/validation";
import {authService} from "@data/services/authService";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {backOfficeService} from "@data/services/backOfficeService";

function RescheduleTrialLiveSessionModal(props) {

    const {visible, navigation, setVisible, notify = false, student = null} = props

    const apiService = student ? backOfficeService.postNewTrialLiveSessionChoiceBackoffice : authService.postNewTrialLiveSessionChoice
    const routeParams = student ? {id: student?.id} : {}

    return <FormStepperModal visible={visible}
                             navigation={navigation}
                             setVisible={setVisible}
                             submitErrorCalback={(errorData) => {
                                 DialogM.show({
                                     text1: 'Erreur', text2: errorData?.message
                                 })
                                 setVisible(false)
                                 props.apiIdle(apiService)
                             }}
                             formData={[
                                 {
                                     label: "Replanifiez votre session d'essai",
                                     description: "Une session Live d'essai dure 30 minutes, et permet de réellement tester l'expérience Masteur en tant qu'élève. C'est gratuit et sans engagement !",
                                     type: "select.single",
                                     name: "new_choice",
                                     options: props.trialOptions,
                                     defaultValue: props.user?.live_sessions?.[0]?.day_time,
                                     validationRules: {...validation.required()}
                                 }
                             ]}
                             initialGetService={authService.getAvailableTrialLiveSessions}
                             submitService={apiService}
                             submitRouteParams={routeParams}
                             submitAdditionalData={{
                                 notify: notify
                             }}
                             submitSuccessMessage={"Votre séance d'essai a bien été replanifiée ✅. Un SMS de confirmation vous a été envoyé."}
                             submitButtonText={"Confirmer"}

    />;
}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        trialOptions: state.data.formData?.trialOptions,
        postConfirmTrialRequest: state.api?.[authService.postConfirmTrial?.name]?.request
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleTrialLiveSessionModal)