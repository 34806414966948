import {Platform, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {ChampionImageM} from "../../champions/ChampionImageM";
import {hasRole} from "@data/utility/ability";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import useLaunchActivity from "@common/hooks/useLaunchActivity";
import RealizationsListItem from "../../organize/list/RealizationsListItem";
import {activitiesService} from "@data/services/activitiesService";
import {useApiStatus} from "@common/hooks/useApiStatus";

const NewTodoCongrats = props => {

    const {
        todo,
        studentId
    } = props
    const dimensions = useContext(DimensionsContext)
    const congratsTitle = "Bravo !"
    const congratsText = "Ton activité est prête !"

    const liveSession = props.currentLiveSession //todo : tester sans chapitre et sans autres notions étudiées

    const studentsList = props.isLiveMode ? liveSession?.users?.filter(user => !user.is_tutor) : props.user?.students
    const student = studentId ? studentsList?.find(s => s.id === studentId) : props.user

    const todos = student?.todo_activities;
    useApiStatus(
        activitiesService.lockActivityV2, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            launchActivity({
                chapterName: todo?.student_chapter?.chapter?.name,
                chapterId: todo?.student_chapter?.id,
                ongoing: todo?.student_chapter?.ongoing,
                xpGained: todo?.student_chapter?.xp_gained,
                lastFeelingSentAt: todo?.student_chapter?.last_feeling_sent_at,
                activity_template_id: todo?.activity_template_id,
                id: todo?.id,
                school_subject_id: todo?.school_subject_id,
                againActivityId: null,
            }, "replace")
        },
        () => {
        }
    )
    const navigation = useNavigation()

    const schoolSubject = findItemInListWithId(todo?.school_subject_id, props.schoolSubjects)
    const launchActivity = useLaunchActivity()

    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        paddingHorizontal: 15,
        width: dimensions?.width,
        alignSelf: 'center',
    }}>
        <View style={{height: 20}}/>
        <ChampionImageM name={"zoe"}
                        variant={"happy"}
                        width={140}
        />
        <View style={{marginTop: 40}}>
            <TextM fontSize={22}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center"
                       // marginTop: 50
                   }}
                   color={theme.colors.primary}>
                {congratsTitle.toUpperCase()}
            </TextM>

            <TextM fontSize={18}
                   wrap
                   fontWeight="Regular"
                   style={{
                       textAlign: "center",
                       marginVertical: 20,
                       marginHorizontal: 30,
                   }}
                   color={theme.colors.grey}>
                {congratsText}
            </TextM>

            <RealizationsListItem activity={todo}
                                  disabled={true}
                                  todo={true}
                                  style={{
                                      width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 2,
                                      marginBottom: 30,
                                  }}
            />

            {hasRole(props.user, "student") && <FilledButtonM
                style={{
                    alignSelf: 'center'
                }}
                onPress={() => {
                    props.apiRequest(activitiesService.lockActivityV2, {id: todo?.id}, {
                        lock: true,
                        live_mode: props.isLiveMode,
                        live_session_id: props.liveSessionId ?? undefined
                    })
                }}
                loading={props.lockActivityRequest}
                color={theme.colors.primary}
                label="Commencer maintenant"
            />}
            <FilledButtonM
                color={theme.colors.primary}
                icon={"plus"}
                style={{
                    alignSelf: 'center'
                }}
                mode={"outlined"}
                onPress={() => {
                    if (todos?.length < props.maxTodoActivities)
                        navigation.replace("new-todo", {
                            todoId: null,
                            studentId: studentId
                        })
                    else DialogM.show({
                        champion: "zoe",
                        text1: `Tu ne peux pas dépasser ${props.maxTodoActivities} activités dans ta liste`,
                        text2: "C’est l’heure de faire un peu de ménage ! Supprime les activités qui ne sont plus utiles."
                    })
                }}
                label="Nouvelle activité"
            />
            <FilledButtonM
                color={theme.colors.primary}
                icon={"arrow-left"}
                style={{
                    alignSelf: 'center'
                }}
                mode={"text"}
                onPress={() => {
                    if (navigation.canGoBack())
                        navigation.goBack()
                    else
                        navigation.replace("finished-activities-live")
                }}
                label="Retour à la liste"
            />
        </View>


    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        maxTodoActivities: state.data.staticData?.max_todo_activities,
        user: state.data.currentUser?.object,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        lockActivityRequest: state.api.lockActivityV2?.request,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewTodoCongrats)