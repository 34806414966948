import React, { useContext, useRef, useState } from "react";
import { FlatList, View } from "react-native";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import TextM from "@common/components/text/TextM";
import { theme } from "@common/theme/theme";
import { DimensionsContext } from "@common/utils/mobileUtils";
import FeelingQuizItem from "@common/chapters/feeling-quiz/FeelingQuizItem";
import FilledButtonM from "@common/components/button/FilledButtonM";
import { ScrollView } from "react-native-gesture-handler";

const FeelingQuiz = (props) => {
  const {
    control,
    goToNextStep,
    register,
    errors,
    serverError,
    setFormValues,
    field,
    formValues,
  } = props;

  const flatListRef = useRef();
  const dimensions = useContext(DimensionsContext);
  const [selectedIndexes, setSelectedIndexes] = useState({
    understanding: null,
    pleasure: null,
    sense: null,
  });
  const feelingQuestions = Object.values(props.feelingQuestions);
  const onSelectAnswer = (answer, type) => {
    setSelectedIndexes({
      ...selectedIndexes,
      [type]: answer,
    });
    field?.onChange({
      ...selectedIndexes,
      [type]: answer,
    });
    // console.log(selectedIndexes)
  };
  return (
    <View
      style={{
        // marginTop: 20,
        width: "100%",
        flex: 1,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <TextM
          style={{
            maxWidth: "25%",
          }}
          wrap
        >
          Ce n’est pas du tout vrai
        </TextM>
        <TextM
          style={{
            maxWidth: "25%",
            textAlign: "right",
          }}
          wrap
        >
          C’est totalement vrai
        </TextM>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
          width: dimensions?.width - 40,
        }}
      >
        <View
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: theme.colors.grey,
            //marginHorizontal: 5,
          }}
        />
        <View
          style={{
            flex: 1,
            height: 2,
            backgroundColor: theme.colors.grey,
          }}
        />
        <View
          style={{
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: theme.colors.grey,
            //marginHorizontal: 5,
          }}
        />
      </View>
      <FlatList
        data={feelingQuestions}
        ref={flatListRef}
        style={{
          width: dimensions?.width,
        }}
        contentContainerStyle={{
          paddingTop: 20,
          paddingBottom: 100,
        }}
        // showsHorizontalScrollIndicator={false}
        ListFooterComponent={
          selectedIndexes.understanding &&
          selectedIndexes.pleasure &&
          selectedIndexes.sense && (
            <FilledButtonM
              label={"Envoyer"}
              color={theme.colors.primary}
              style={{}}
              loading={props.studentChapterFeelingsRequest}
              onPress={goToNextStep}
            />
          )
        }
        renderItem={(item) => {
          let type = null;
          for (const key in props.feelingQuestions) {
            if (props.feelingQuestions[key] === item.item) {
              type = key;
            }
          }
          return (
            <FeelingQuizItem
              question={item?.item.replace(
                "[studentChapter]",
                "“" + formValues?.chapterName + "”"
              )}
              onSelectAnswer={onSelectAnswer}
              type={type}
              selectedIndexes={selectedIndexes}
            />
          );
        }}
      />
    </View>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    feelingQuestions: state.data.staticData?.feeling_questions,
    feelingQuestionsRequest: state.api.feelingQuestionsRequest,
    studentChapterFeelingsRequest: state.api.studentChapterFeelings?.request,
    feelingQuestionsError: state.api.feelingQuestionsError,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
};

export default connect(mapStateToProps, mapDispatchToProps)(FeelingQuiz);
