import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {ActivityIcon} from "../ActivityIcon";
import React from "react";

export const StepNumAndIcon = ({text, type, style, rowMode}) => {

    return <View style={{
        ...style,
        alignItems: "center",
        flexDirection: rowMode ? "row" : "column"
        // flex: 1
    }}>
        <TextM fontWeight="ExtraBold"
               fontSize={28}
               style={{
                   marginLeft: rowMode ? 0 + (text === 1 && 3) : 0
               }}
               color={theme.colors.primary}>{text}</TextM>
        <ActivityIcon type={type} width={rowMode ? 22 : 30} style={{
            marginTop: rowMode ? 0 : 5,
            marginLeft: rowMode ? 10 + (text === 1 && 3) : 0
        }}/>
    </View>
}