import {suggestionsConstants} from "../../../constants/suggestionsConstants";

export function resetSuggestions() {

    return {type: suggestionsConstants.RESET_SUGGESTIONS}
}

export function markHomeworkSuggestionAsShown() {

    return {type: suggestionsConstants.HOMEWORK_SUGGESTION}
}

export function markAdditionalSuggestionAsShown() {

    return {type: suggestionsConstants.ADDITIONAL_SUGGESTION}
}