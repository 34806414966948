import axios from "axios"

function getStudents() {
    return axios.get('/api/students')

}

function getStudent({id}) {
    return axios.get(`api/students/${id}`)
    //console.log(axios.get(`api/students/${  id_student  }`))
}

function studentSurvey() {
    return axios.get('/api/student-survey')
}

function addStudentSurvey(value) {
    return axios.post(`api/student-survey`, {...value})
}

export const studentsService = {
    getStudents,
    getStudent,
    studentSurvey,
    addStudentSurvey
}
