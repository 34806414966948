import {View} from "react-native";
import {Avatar, Badge} from "react-native-paper";
import {Checked, IconImageM} from "@common/components/icons/IconsM";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {Rating} from "react-native-ratings/src";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React from "react";
import { LinearGradient } from 'expo-linear-gradient';

export const ActivityButtonContent = props => {

    const {activityDone, activityTemplate, backgroundColor, activityPlanned, activities, width} = props

    // console.log("activityTemplate?.icon", activityTemplate?.icon)
    return <>
        <View style={{
            alignItems: "center",
            justifyContent: "center"
        }}>
            <LinearGradient
                // Button Linear Gradient
                colors={[theme.colors.link, theme.colors.linkLight ]}
                style={{
                    width: width ? width + 30 : 80,
                    height: width ? width + 30 : 80,
                    borderRadius: 40
                }}/>

                {/*<Avatar.Image size={80}*/}
                {/*          style={{*/}
                {/*              backgroundColor: backgroundColor,*/}
                {/*              // borderRadius: 5*/}
                {/*          }}/>*/}
            <IconImageM width={width ?? 50}
                        style={{
                            position: "absolute",
                            opacity: (activityDone ? 0.2 : 1)
                        }}
                        uri={activityTemplate?.icon}/>
        </View>
        {activityPlanned?.is_late && <Badge style={{
            position: "absolute",
            top: 0,
            right: 0
        }}
                                            size={10}
        />}
        {/*{activities?.length > 0 && <View style={{*/}
        {/*    position: "absolute",*/}
        {/*    bottom: -6,*/}
        {/*    alignSelf: "center",*/}
        {/*    borderWidth: 1,*/}
        {/*    borderColor: theme.colors.light,*/}
        {/*    backgroundColor: theme.colors.white,*/}
        {/*    borderRadius: 10,*/}
        {/*    paddingHorizontal: 4*/}

        {/*}}*/}
        {/*                                 size={10}*/}
        {/*><TextM fontWeight="Bold"*/}
        {/*        color={theme.colors.light}>Fait {activities?.filter(x => !!x?.finished_at)?.length}x</TextM></View>}*/}

        {/*{activityDone && <View style={{*/}
        {/*    alignItems: "center",*/}
        {/*    justifyContent: "center",*/}
        {/*    borderWidth: 1,*/}
        {/*    borderColor: activityPlanned?.scoring_result ? theme.colors.accent : theme.colors.light,*/}
        {/*    borderRadius: 10,*/}
        {/*    backgroundColor: theme.colors.white,*/}
        {/*    flexDirection: "row",*/}
        {/*    paddingHorizontal: 6,*/}
        {/*    paddingVertical: 0,*/}
        {/*    position: "absolute",*/}
        {/*    alignSelf: "center",*/}
        {/*    bottom: -6*/}
        {/*}}>*/}
        {/*    {activityPlanned?.scoring_result ? <Rating*/}
        {/*        ratingCount={3}*/}
        {/*        startingValue={3}*/}
        {/*        tintColor={theme.colors.white}*/}
        {/*        readonly*/}
        {/*        fractions={0}*/}
        {/*        imageSize={20}/> : <>*/}
        {/*        <TextM fontWeight="Bold" color={theme.colors.light}>SCORE</TextM>*/}
        {/*        <FontAwesome5 name={"hourglass-half"}*/}
        {/*                      style={{marginLeft: 4}}*/}
        {/*                      color={theme.colors.light}*/}
        {/*                      size={12}/>*/}
        {/*    </>}*/}
        {/*</View>}*/}
        {/*{activityDone && <Checked size={25}*/}
        {/*                          style={{*/}
        {/*                              position: "absolute",*/}

        {/*                              top: 0,*/}
        {/*                              right: 0*/}
        {/*                          }}/>}*/}
    </>
}