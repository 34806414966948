import React, {useContext, useEffect, useRef} from 'react';
import Header from "../header/Header";
import {connect} from "react-redux";
import {createStackNavigator} from "@react-navigation/stack";
import axios from "axios";
import {ChampionBottomSheetModal, ChampionModalContext} from "../../student/champions/ChampionBottomSheetModal";
import {AbilityContext} from "@data/utility/Can";
import {navigationScreens} from "./navigationScreens";
import {Platform} from "react-native";
import {theme} from "../theme/theme";
import DeepLinking from "@common/utils/DeepLinking";
import * as Linking from "expo-linking";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import NotFound from "../../auth/NotFound";
import Unauthorized from "../../auth/Unauthorized";

const Stack = createStackNavigator();

const RootNavigator = props => {

    //** ComponentDidMount
    useEffect(() => {

        if (props.token) {
            axios.defaults.headers.common['Authorization'] = "Bearer " + props.token;
        }
    }, [props.token])

    const ability = useContext(AbilityContext)

    const championModalRef = useRef()

    const unauthorized = (title, name) => {
        return {
            name: name,
            component: Unauthorized,
            options: {
                headerShown: false,
                headerTitle: "Non autorisé"
            },
            titles: [{"auth": {}}, {"main": {}}, {"live": {}}]?.filter((t) => !Object.keys(t)?.includes(title))
        }
    }
    const renderNavigationScreens = (ns, title) => ns.map(screen => <Stack.Screen key={screen.name}
                                                                                  name={screen.name}
                                                                                  component={screen.titles?.find((t) => Object.keys(t)?.includes(title))?.[title]?.component ? screen.titles?.find((t) => Object.keys(t).includes(title))?.[title]?.component : screen?.component}
                                                                                  options={screen.titles?.find((t) => Object.keys(t).includes(title))?.[title]?.options ? screen.titles?.find((t) => Object.keys(t).includes(title))?.[title]?.options : typeof screen.options === 'function' ? screen.options : {
                                                                                      headerTitle: screen.name,
                                                                                      ...screen.options
                                                                                  }}/>)
    const renderNavigator = () => <Stack.Navigator
        screenOptions={{
            headerMode: "screen",
            header: ({scene, previous, navigation, route, options, back}) => {


                return <Header scene={scene}
                               previous={previous}
                               navigation={navigation}
                               route={route}
                               options={options}
                               back={back}/>
            },
            gestureEnabled: false,
            cardStyle: {
                backgroundColor: theme.colors.whiteAlmost,
                flex: 1
            }
        }}
    >
        {props.token ?
            /*
                        !props?.user?.is_verified ?
                            <>
                                {/!*** Email validation screens*!/}
                                {renderNavigationScreens(navigationScreens.main.filter((screen) => screen?.titles?.includes("emailValidation")), "emailValidation")}
                            </>
                            : (hasRole(props.user, "student") && (
                                !props?.user?.onboarding_viewed_at && !ability.can('read', 'premium_icon') && !ability.can('read', 'plus_icon')
                            )) ?
                                <>
                                    {/!*** Student onboarding screens*!/}
                                    {renderNavigationScreens(navigationScreens.main.filter((screen) => screen?.titles?.includes("studentOnboarding")), "studentOnboarding")}
                                </> : (hasRole(props.user, "student") && !props.user?.champion_intros_viewed_at?.zoe) ?
                                    <>
                                        {renderNavigationScreens(navigationScreens.main.filter((screen) => screen?.titles?.includes("studentFirstChallenge")), "studentFirstChallenge")}
                                    </> :*/
            props.isLiveMode ?
                <>
                    {/*** Live Mode screens*/}
                    {renderNavigationScreens(navigationScreens.main.filter((screen) => screen?.titles?.find((t) => Object.keys(t)?.includes("live"))), "live")}
                </>
                :
                <>
                    {/*** Main screens*/}
                    {/*{renderNavigationScreens(navigationScreens.main)}*/}
                    {renderNavigationScreens(navigationScreens.main.filter((screen) => screen?.titles?.find((t) => Object.keys(t)?.includes("main"))), "main")}
                </>
            :
            <>
                {/*** Auth screens*/}
                {renderNavigationScreens(navigationScreens.main.filter((screen) => screen?.titles?.find((t) => Object.keys(t)?.includes("auth"))), "auth")}
            </>
        }
        <Stack.Screen name={"not-found"}
                      component={NotFound}
                      options={{
                          headerTitle: "Page introuvable",
                          headerShown: false
                      }}/>
        {/*                <Stack.Screen name="Loading"
                              component={LoadingScreen}
                              options={{headerTitle: "Loading"}}/>*/}

    </Stack.Navigator>
    const prefix = Linking.createURL("/")
    return <>
        {Platform.OS !== 'web'
            ? <ChampionModalContext.Provider value={championModalRef}>
                {renderNavigator()}
            </ChampionModalContext.Provider>
            : <>
                <DeepLinking prefix={prefix}/>
                {renderNavigator()}
            </>
        }

        {Platform.OS !== 'web' && <ChampionBottomSheetModal ref={championModalRef}/>}

    </>
}

const mapStateToProps = state => {
    return {
        networkOn: state.api?.networkOn,
        token: state.data.session?.object?.token,
        url: state.data.session?.initialUrl,
        user: state.data.currentUser?.object,
        isLiveMode: state.liveMode.isLiveModeEnabled
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RootNavigator)