import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import React from "react";
import {useNavigation} from "@react-navigation/native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import ActivityPlannedButtonContent from "./ActivityPlannedButtonContent";


function ActivityPlannedButton(props) {

    // ** Component props
    const {
        activityTemplate,
        activityPlanned,
        challenge,
        activities,
        schoolSubject,
        readOnly,
        small,
        student,
        style
    } = props
    const doneActivities = activities?.filter(x => !!x?.finished_at)

    const user = student ? student : props.user

    // console.log(student)

    const navigation = useNavigation();

    const goToActivity = () => {

        if (doneActivities?.length > 0) {
            navigation.push("activity-result", {
                activityIds: doneActivities?.map(x => x?.id),
                studentId: student?.id
            })

        } else {
            navigation.push("activity-presentation", {
                activityTemplateId: activityTemplate?.id,
                challengeId: challenge?.id,
                activityId: activityPlanned?.id,
                studentId: student?.id,
                schoolSubjectId: schoolSubject?.id
            })
        }
    }


    // console.log(sameActivitiesTodayCount)

    const backgroundColor = activities?.filter(x => !!x?.finished_at)?.length
        ? [theme.colors.white, theme.colors.white]
        : [theme.colors.link, theme.colors.linkLight]

    const textColor = activities?.filter(x => !!x?.finished_at)?.length
        ? theme.colors.light
        : theme.colors.white

    const buttonWidth = small ? 100 : 150
    const buttonHeight = buttonWidth * 1.1

    return <View
        style={{
            width: buttonWidth,
            // height: buttonHeight,
            alignItems: "center",
            // justifyContent: 'center',
            borderRadius: buttonWidth / 3,
            paddingVertical: 10,
            alignSelf: "stretch",
            ...style
        }}>

        {/* ** Popover shown to launch activity or to view history of activity */}
        {readOnly ? <ActivityPlannedButtonContent activities={activities}
                                                  buttonWidth={buttonWidth}
                                                  small={small}
                                                  activityPlanned={activityPlanned}
                                                  challenge={challenge}
                                                  schoolSubject={schoolSubject}
                                                  backgroundColor={backgroundColor}
                                                  textColor={textColor}
                                                  activityTemplate={activityTemplate}/>
            : <TouchableOpacity onPress={goToActivity}>
                <ActivityPlannedButtonContent
                    activityPlanned={activityPlanned}
                    buttonWidth={buttonWidth}
                    small={small}
                    activities={activities}
                    challenge={challenge}
                    schoolSubject={schoolSubject}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    activityTemplate={activityTemplate}/>
            </TouchableOpacity>}

    </View>;
}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPlannedButton)