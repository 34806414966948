import React, {useContext, useState} from 'react';
import {Dimensions, Platform, View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {connect} from "react-redux";
import {IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import {pauseActivity} from "@data/redux/actions/activity";
import TimerContainer from "../execute/TimerContainer";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {activitiesService} from "@data/services/activitiesService";

const OneStepActivityHeader = (props) => {
    const {
        activity,
        time,
        activityPaused,
        animationFinished,
        chronoMode,
        setAnimationFinished,
        recordStartTime,
        status,
        setStatus,
        field,
        liveMode,
        estimatedDuration,
        isOffline,
        setStartedWithOfflineMode,
        startChrono,
        setTime,
        timerDuration,
        handleShowMyWorkModal
    } = props
    const dimensions = useContext(DimensionsContext)
    const insets = useSafeAreaInsets();
    const navigation = useNavigation()
    const schoolSubject = findItemInListWithId(activity?.school_subject_id, props.schoolSubjects)
    const activityTemplate = props.activityTemplates?.find(s => s?.id === activity?.activity_template_id)
    const [progress, setProgress] = useState(0);

    const onExit = () => {

        const title = activity?.media_count > 0 ? "As-tu terminé ton travail ?" : "Veux-tu quitter l'activité ?"
        const description = activity?.media_count > 0 ? "Si tu as terminé, soumets l'activité pour qu'elle soit validée" : "Ton chrono sera sauvegardé, et tu pourras reprendre quand tu veux"

        const buttonText3 = activity?.media_count > 0 ? "Oui, soumettre" : null
        const buttonText1 = activity?.media_count > 0 ? "Non, quitter" : "Quitter"
        const onPress3 = activity?.media_count > 0 ? handleShowMyWorkModal : null

        DialogM.show({
            text1: title,
            text2: description,
            champion: 'abou',
            buttonColor1: theme.colors.grey,
            buttonColor3: theme.colors.success,
            buttonText3: buttonText3,
            onPress3: onPress3,
            buttonText1: buttonText1,
            onPress: () => {
                props.apiRequest(activitiesService.lockActivityV2, {id: activity?.id}, {
                    lock: false,
                    live_mode: !!props.liveSessionId,
                    live_session_id: props.liveSessionId ?? undefined
                })
                if (navigation.canGoBack()) navigation.goBack()
                else navigation.replace("menu")
            },
            buttonText2: 'Retour',
        })
    }

    const activityItem = () => {

        return <View style={{
            flexDirection: "row",
            alignItems: "center",
            marginLeft: -15,
            flex: 1
        }}>
            <IconImageM uri={activityTemplate?.icon}
                        width={40}
                        style={{}}/>
            <View style={{
                //alignItems: 'center',
                marginLeft: 3,
                flex: 1
            }}>
                <TextM
                    fontWeight={"SemiBold"}
                    fontSize={14}
                    style={{
                        flex: 1
                    }}
                >
                    {activity?.material?.title ?? activity?.title}
                </TextM>
                <TextM fontWeight="Bold"
                    //wrap
                       color={schoolSubject?.color}
                       style={{
                           paddingRight: 5,
                           maxWidth: dimensions.width < 800 ? 150 : 500
                       }}
                       fontSize={9}>{activity?.student_chapter?.chapter?.name ?? (schoolSubject?.name?.length > 4 ? schoolSubject?.name?.substring(0, 4) + "." : schoolSubject?.name)}</TextM>
            </View>
        </View>
    }

    return (
        <View style={{
            width: Dimensions.get("window").width,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.colors.white,
            borderBottomWidth: 1,
            borderColor: theme.colors.lightMore,
            paddingTop: Platform.OS === "ios" && !props.isLiveMode ? insets.top : 0
        }}>
            <View style={{
                width: dimensions?.width,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: 65,
            }}>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    flex: 1,
                }}>
                    <IconButton
                        icon={"times"}
                        iconColor={theme.colors.primary}
                        size={32}
                        style={{}}
                        onPress={onExit}
                    />
                    {activityItem()}
                </View>
                <View>
                    <TimerContainer
                        chronoMode={chronoMode}
                        liveMode={liveMode}
                        time={time}
                        estimatedDuration={estimatedDuration}
                        timerDuration={timerDuration}
                        field={field}
                        recordStartTime={recordStartTime}
                        setTime={setTime}
                        isOffline={isOffline}
                        setStartedWithOfflineMode={setStartedWithOfflineMode}
                        startChrono={startChrono}
                        status={status}
                        setStatus={setStatus}
                        animationFinished={animationFinished}
                        setAnimationFinished={setAnimationFinished}
                        activityPaused={activityPaused}
                    />
                </View>
            </View>
        </View>
    )
}
const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        schoolSubjects: state.data.staticData?.school_subjects,
        activityPaused: state.activity?.activityPaused,
        liveSessionId: state.liveMode.liveSessionId,
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle,
    pauseActivity

}

export default connect(mapStateToProps, mapDispatchToProps)(OneStepActivityHeader)