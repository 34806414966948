import {Dimensions, Platform} from "react-native";
import {createContext} from "react";

export const WINDOW_WIDTH = Platform.OS !== 'web' ? Dimensions.get('window').width : Dimensions.get('window').width > 800 ? Dimensions.get('window').width * 2 / 3 : Dimensions.get('window').width;
export const WINDOW_HEIGHT = Dimensions.get('window').height;

export const IS_WEB = Platform.OS === "web"
export const IS_BIG = Dimensions.get('window').width > 800

export const NavigationRefContext = createContext()

export const DimensionsContext = createContext()
export const isMobile = () => {

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;

    return regexp.test(details)
}