import axios from "axios"

function indexPaginatedReferralCodes({}, {}, params) {
    return axios.get(`api/v1/referral-codes`, {params})
}
function createReferralCode({}, formData) {
    return axios.post(`api/v1/referral-codes`, formData)
}
function showReferralCodeByHash({hash}) {
    return axios.get(`api/v1/referral-codes/${hash}`)
}
function updateReferralCode({id}, formData) {
    return axios.put(`api/v1/referral-codes/${id}`, formData)
}
function deleteReferralCode({id}) {
    return axios.delete(`api/v1/referral-codes/${id}`)
}
function markDueAsPaid({id}) {
    return axios.post(`api/v1/referral-codes/${id}/mark-due-as-paid`)
}
function incrementClicks({code}) {
    return axios.post(`api/v1/referral-codes/${code}/increment-clicks`)
}

function getReferralCode() {
    return axios.get(`api/v1/referral-code` )
}

function sendUrl({id}, formData) {
    return axios.post(`api/v1/referral-codes/${id}/send-url`, formData)
}

function getUrl({id}) {
    return axios.get(`api/v1/referral-codes/${id}/url`)
}

export const referralService = {
    indexPaginatedReferralCodes,
    createReferralCode,
    showReferralCodeByHash,
    updateReferralCode,
    deleteReferralCode,
    markDueAsPaid,
    incrementClicks,
    getReferralCode,
    sendUrl,
    getUrl
}
