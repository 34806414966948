import {offlineModeConstants} from "../../../constants/offlineModeConstants";

const initialState = {
  isOfflineModeEnabled: false,
  isInternetReachable: true,
  activityStartedWithOfflineMode: false,
  isActivityOfflineModeValid: true,
  timerStepOngoing: false
}

export default function offlineMode(state = initialState, action) {
  switch (action.type) {
    case offlineModeConstants.IS_OFFLINE_MODE_CHANGE:
      return {
        ...state,
        isOfflineModeEnabled: action.newState
      }
    case offlineModeConstants.IS_INTERNET_REACHABLE_CHANGE:
      return {
        ...state,
        isInternetReachable: action.newState
      }
    case offlineModeConstants.ACTIVITY_STARTED_WITH_OFFLINE_MODE:
      return {
        ...state,
        activityStartedWithOfflineMode: true
      }
    case offlineModeConstants.ACTIVITY_OFFLINE_MODE_INVALID:
      return {
        ...state,
        isActivityOfflineModeValid: false
      }

    case offlineModeConstants.TIMER_STEP_STARTED:
      return {
        ...state,
        timerStepOngoing: true
      }

    case offlineModeConstants.OFFLINE_MODE_CLEAR:
      return initialState
    default:
      return state
  }
}
