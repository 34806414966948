import {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {Dimensions, ScrollView, View} from "react-native";
import {StatisticsItemCol} from "@common/components/statistics/StatisticsItemCol";
import clicked from "../../assets/icons/generic/click.png";
import edit from "../../assets/icons/generic/edit.png";
import {plr} from "@data/utility/Utils";
import checked from "../../assets/icons/generic/checked.png";
import money from "../../assets/icons/generic/money.png";

export const ReferralCodeItemsContainer = (props) => {
    const {referralCode, style} = props
    const dimensions = useContext(DimensionsContext)

    return <ScrollView containerStyle={{
        width: Dimensions.get("window").width,
        alignItems: "center",
    }}
    style={{
        ...style
    }}
    >
        <View style={{
            width: dimensions?.width,
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center"
        }}>
            <View style={{
                flexDirection: "row",
                marginTop: 10,
                paddingVertical: 10,
                paddingHorizontal: 10,
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: 'center',
                alignSelf: "center"
            }}>
                <StatisticsItemCol source={clicked}
                                   count={referralCode?.clicks_count}
                                   title={"clics sur le lien"}
                                   style={{
                                       width: dimensions?.width / 3 - 10,
                                       paddingHorizontal: 15

                                   }}
                />
                <StatisticsItemCol source={edit}
                                   count={referralCode?.pending_referrals_count}
                                   title={plr("essai", referralCode?.pending_referrals_count)+ plr(" lancé", referralCode?.pending_referrals_count)}
                                   style={{
                                       width: dimensions?.width / 3 - 10,
                                       paddingHorizontal: 15
                                   }}
                />
                <StatisticsItemCol source={checked}
                                   count={referralCode?.activated_referrals_count}
                                   title={plr("abonnement", referralCode?.activated_referrals_count)
                                       + plr(" activé", referralCode?.activated_referrals_count)}
                                   style={{
                                       width: dimensions?.width / 3 - 10,
                                       paddingHorizontal: 15
                                   }}
                />

            </View>
            <View style={{
                flexDirection: "row",
                marginTop: 10,
                paddingVertical: 10,
                paddingHorizontal: 10,
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: 'center',
                alignSelf: "center"
            }}>
                <StatisticsItemCol source={money}
                                   count={referralCode?.amount_due}
                                   title={"€ cagnottés"}
                                   style={{
                                       width: dimensions?.width / 3 - 10,
                                       paddingHorizontal: 15
                                   }}
                />
            </View>
        </View>
    </ScrollView>
}