import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {PetalCounter} from "@common/components/statistics/Counters";
import Animated, {FadeOutUp} from "react-native-reanimated";
import {Gift} from "@common/components/icons/IconsM";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";

const PetalsGainedCongrats = props => {

    const {congratsTitle = "Titre", congratsText = "Texte", petalsGainedCount} = props

    const successSize = 100
    const navigation = useNavigation()
    const [showPetals, setShowPetals] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setShowPetals(false)
        }, 150)
        PlaySoundM.play(require('../../../../assets/sounds/petals-gained-2.mp3'))
    }, [])

    const dimensions = useContext(DimensionsContext)

    const hasSufficientPetals = props.user?.petals >= 12

    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        paddingHorizontal: 15,
        width: dimensions?.width
    }}>

        <PetalCounter count={props.user?.petals} style={{alignSelf: "flex-end"}}/>

        <View style={{height: 150}}/>

        <View>
            {showPetals && <Animated.View exiting={FadeOutUp.delay(200).duration(2000).damping(30)}
                                          style={{
                                              position: "absolute",
                                              top: -20,
                                              alignSelf: "center"
                                              // backgroundColor: "red"
                                          }}>
                <TextM
                    color={theme.colors.petalNew}
                    fontWeight={"ExtraBold"}
                    fontSize={18}
                >
                    + {petalsGainedCount} pétales
                </TextM>

            </Animated.View>}
            <Gift size={140}/>
        </View>


        {/*<SuccessAnimation*/}
        {/*    size={successSize}*/}
        {/*    iconSize={successSize * 0.7}*/}
        {/*    dotColor={theme.colors.petal}*/}
        {/*    backgroundColor={theme.colors.petal}*/}
        {/*    iconColor={"white"}*/}
        {/*    dotSize={20}*/}
        {/*    duration={2000}*/}
        {/*    style={{*/}
        {/*        // position: "absolute",*/}
        {/*        // alignSelf: "center",*/}
        {/*        marginBottom: successSize / 3*/}
        {/*        // right: -successSize / 3*/}
        {/*        // right: -30*/}
        {/*    }}*/}
        {/*    animatedLayerColor={"white"}*/}
        {/*/>*/}
        <TextM fontSize={22}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center",
                   marginTop: 50
               }}
               color={theme.colors.primary}>
            Tu as gagné {petalsGainedCount} pétales !
        </TextM>

        <TextM fontSize={18}
               wrap
               fontWeight="Regular"
               style={{
                   textAlign: "center",
                   marginTop: 20,
                   marginHorizontal: 30
               }}
               color={theme.colors.grey}>
            Continue de réaliser des quêtes pour gagner encore plus de récompenses
        </TextM>

        <FilledButtonM mode={hasSufficientPetals ? "contained" : "text"}
                       color={theme.colors.petalNew}
                       style={{
                           marginTop: 60
                       }}
                       onPress={() => {
                           navigation.replace("shop")
                       }}
                       label={hasSufficientPetals ? "Faire un geste" : "Voir la boutique"}/>
        <FilledButtonM mode={hasSufficientPetals ? "text" : "contained"}
                       color={theme.colors.primary}
                       style={{
                           // marginTop: 10
                       }}
                       onPress={() => {
                           if (navigation.canGoBack())
                               navigation.goBack()
                           else
                               navigation.replace("menu")
                       }}
                       label={hasSufficientPetals ? "Plus tard" : "Continuer"}/>

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(PetalsGainedCongrats)