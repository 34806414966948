import React, {useContext, useEffect, useRef} from 'react';
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {tutorsService} from "@data/services/tutorsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {IconButton, TouchableRipple} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import NoteCard from "./NoteCard";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import {hasRole} from "@data/utility/ability";

const StudentNotesScreen = (props) => {

    const {studentId} = props.route.params
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    // const student = hasRole(props.user, "student") ? props.user : findItemInListWithId(studentId, props.user?.students)
    const flatListRef = useRef();

    useEffect(() => {
        props.apiRequest(tutorsService.getNotes, {id: studentId})
    }, [])

    useApiStatus(
        tutorsService.getNotes, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            //console.log(data)
        } // success callback
    )


    return (
        <View style={{
            alignSelf: 'center',
            alignItems: 'center',
            width: "100%",
            flex: 1
        }}>
            <HeaderContainerM>
                <TitleM>Notes du masteur</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>

            <PaginatedFlatList
                data={props.user?.tutor_notes}
                ref={flatListRef}
                style={{
                    width: dimensions?.width,
                    marginBottom: 30
                }}
                contentContainerStyle={{
                    alignItems: "center"
                }}
                additionalOnRefresh={() => {
                }}
                additionalRefreshing={false}
                requestApiService={tutorsService.getNotes}
                requestRouteParams={{id: studentId}}
                requestUrlParams={null}
                requestData={null}
                ListEmptyComponent={<>
                    <TextM style={{
                        textAlign: "center",
                        marginTop: 0
                    }}>Aucune note</TextM>
                </>}
                ListHeaderComponent={
                    <View style={{flexDirection: "row", justifyContent: "flex-end", width: dimensions?.width}}>
                        <TouchableRipple style={{alignSelf: "flex-end", marginTop: 20, padding: 10}}
                                         onPress={() => {
                                             navigation.push("new-note", {
                                                 studentId: studentId
                                             })
                                         }}>
                            <FontAwesome5 name="plus"
                                          size={26}
                                          color={theme.colors.primary}/>
                        </TouchableRipple>
                    </View>
                }
                renderItem={(item) => {

                    return <NoteCard note={item.item}/>
                }}
            />

        </View>
    )
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        notes: state.data.tutors?.notes,
        getNotesRequest: state.api.getNotes?.request
    };
};
const mapDispatchToProps = {
    apiRequest,
    apiIdle
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentNotesScreen);