import {notificationConstants} from "../../../constants/notificationConstants";

const initialState = {
    lastIdentifier: null,
}

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case notificationConstants.SET_LAST_IDENTIFIER:
            return {
                ...state,
                lastIdentifier: action.lastIdentifier,
            }

        case notificationConstants.CLEAR_LAST_IDENTIFIER:
            return {
                ...state,
                lastIdentifier: null,
            }
        default:
            return state
    }
}
