import React, {useEffect} from "react";
import {authService} from "@data/services/authService";
import {IS_WEB} from "@common/utils/mobileUtils";
import * as ImagePicker from "expo-image-picker";
import moment from "moment";
import {MenuM} from "@common/components/menu/MenuM";
import AvatarM from "@common/components/avatar/AvatarM";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {ProductIcon} from "./subscription/MySubscriptionScreen";
import {hasRole} from "@data/utility/ability";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {renderers} from "react-native-popup-menu";
import {ImagePickerCropModal} from "./ImagePickerCropModal";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ActivityIndicator} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";

const CustomizableAvatar = (props) => {
    const {user, onboardingMode = false} = props;

    const navigation = useNavigation()

    const [imagePickerModalVisible, setImagePickerModalVisible] = React.useState(false);
    const handleShowImagePickerModal = () => setImagePickerModalVisible(!imagePickerModalVisible);

    const sendImage = (file) => {
        const cleanFormData = new FormData();
        cleanFormData.append("avatar", file)
        cleanFormData.append("avatar_characters", "image")
        props.apiRequest(authService.updateAvatarSelected, {}, cleanFormData)
    }

    const pickImage = async () => {

        if (IS_WEB) {
            handleShowImagePickerModal()

        } else {
            // No permissions request is necessary for launching the image library
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [1, 1],
                quality: 1
            });

            //console.log(result);

            if (!result.canceled) {
                // props.setChatPhoto()
                const image = {
                    uri: result.assets[0]?.uri,
                    name: `Avatar${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.png`,
                    type: "image/png"
                }
                // const cleanFormData = new FormData();
                // if (IS_WEB)
                //     cleanFormData.append("avatar", dataURLtoFile(result.assets[0]?.uri, `Avatar${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.png`))
                // else
                //     cleanFormData.append("avatar", image)
                // cleanFormData.append("avatar_characters", "test")
                // props.apiRequest(authService.updateAvatarSelected, {}, cleanFormData)

                sendImage(image)
            }
        }

    };

    useEffect(() => {
        // Show Toast if failure
        if (props.updateAvatarSelectedSuccess) {
            props.apiIdle(authService.updateAvatarSelected)
            // hideChooseAvatarModal()
        } else if (props.updateAvatarSelectedError) {
            props.apiIdle(authService.updateAvatarSelected)
            DialogM.show({
                text1: 'Erreur', text2: props.updateAvatarSelectedError.message
            })
            //alertPolyfill('Erreur', props.updateAvatarSelectedError.message)
        }
    }, [props.updateAvatarSelectedSuccess, props.updateAvatarSelectedError])

    return (
        <>
            {props.updateAvatarSelectedRequest ? <ActivityIndicator color={theme.colors.primary}/>
                :
                <>
                    <MenuM buttonComponent={<>
                        <AvatarM user={user} style={{}} size={90} onboardingMode={onboardingMode}/>
                        <View style={{
                            padding: 5,
                            borderRadius: 20,
                            width: 24,
                            height: 24,
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: theme.colors.primary,
                            // opacity: 0.8,
                            position: "absolute",
                            top: -6,
                            right: -6
                        }}>
                            <FontAwesome5 name="pen" color={theme.colors.white}
                                          size={12}/>
                        </View>
                        <ProductIcon style={{
                            position: "absolute",
                            right: -4,
                            bottom: 0
                        }}/>
                    </>}
                           style={{}}
                           items={[
                               {
                                   icon: "user-circle",
                                   title: "Personnaliser mon avatar",
                                   masked: !hasRole(user, 'student'),
                                   onPress: () => {
                                       if (IS_WEB) {
                                           DialogM.show({
                                               text1: 'Pour personnaliser ton avatar, il faut te connecter sur l\'application mobile',
                                               buttonText1: 'Quitter'
                                           });
                                       } else {
                                           navigation.push("avatar");
                                       }
                                   }
                               },
                               {
                                   icon: "image",
                                   masked: hasRole(user, 'student'),
                                   title: "Choisir une image",
                                   onPress: () => {
                                       pickImage()
                                   }
                               },
                               {
                                   icon: "undo",
                                   title: "Réinitialiser",
                                   onPress: () => {
                                       DialogM.show({
                                           text1: 'Veux-tu réinitialiser ton avatar ?',
                                           text2: 'L\'avatar actuel sera perdu',
                                           buttonText1: 'Réinitialiser',
                                           buttonColor1: theme.colors.error,
                                           onPress: () => {
                                               props.apiRequest(authService.updateAvatarSelected, {}, {
                                                   avatar: null,
                                                   avatar_characters: null
                                               });
                                           },
                                           buttonText2: 'Annuler'
                                       })
                                   }
                               }
                           ]}
                           renderer={renderers.SlideInMenu}/>
                    <ImagePickerCropModal apply={sendImage} handleShowModal={handleShowImagePickerModal}
                                          isOpen={imagePickerModalVisible}/>
                </>
            }
        </>
    )
}

const mapStateToProps = state => {
    return {
        updateAvatarSelectedRequest: state.api.updateAvatarSelected?.request,
        updateAvatarSelectedSuccess: state.api.updateAvatarSelected?.success,
        updateAvatarSelectedError: state.api.updateAvatarSelected?.error,
    };
};

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(CustomizableAvatar)