import React, {useContext} from 'react';
import {Platform, SectionList, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {StatusBar} from "expo-status-bar";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ActivityButton from "../organize/list/ActivityButton";
import {activityGoals} from "@data/constants/activitiesConstants";
import TextM from "@common/components/text/TextM";
import {IconButton} from "react-native-paper";
import TitleM from "@common/components/text/TitleM";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {useNavigation, useRoute} from "@react-navigation/native";

const AllActivities = (props) => {

    const {noHeader = false} = props

    const browseMode = props?.route?.params?.browseMode ?? false

    const navigation = useNavigation()
    const route = useRoute()
    const goToActivity = (activityTemplate) => {
        navigation.push("activity-presentation", {
            activityTemplateId: activityTemplate?.id,
            isTutor: true
        })
    }

    //console.log(props.activityTemplates)
    const activityTemplates = props.activityTemplates
        // ?.filter(at => at?.parent_type === "school_subject")


    const user = route?.params?.student ? route?.params?.student : props.user

    // console.log(props.shopProducts)
    const dimensions = useContext(DimensionsContext)

    const sections = Object.keys(activityGoals).map(goal => {
        return {
            "title": activityGoals[goal],
            "data": activityTemplates?.filter(a => goal === "exercise"
                    ? a?.type?.includes("exercise") || a?.slug?.includes("go_homework_oral")
                    : a?.type === goal)?.filter(a => a?.is_active)
        }
    });
    //console.log(sections)
    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center',
            width: "100%",
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            {!noHeader && <HeaderContainerM>
                <TitleM>Toutes les activités</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>}

            <SectionList sections={sections}
                         stickySectionHeadersEnabled={false}
                         style={{
                             width: dimensions?.width
                         }}
                         contentContainerStyle={{
                             // alignItems:"center",
                             //flex:1

                         }}
                         renderItem={(item) => (
                             <>
                             </>
                         )}
                         renderSectionHeader={({section}) => (
                             <View style={{
                                 marginBottom: 20

                             }}>
                                 <TextM
                                     fontWeight="Bold"
                                     style={{
                                         marginTop: 10,
                                         //marginLeft:5,
                                         marginBottom: 5,
                                         alignSelf: "center"
                                     }}
                                     fontSize={20}
                                     color={theme.colors.primaryTransparent}

                                 >{section.title}</TextM>

                                 <View
                                     style={{
                                         // height: 160,
                                         width: dimensions?.width,
                                         // flex:1,
                                         flexDirection: 'row',
                                         flexWrap: "wrap",
                                         alignItems: "center",
                                         justifyContent: "center",
                                         // justifyContent: 'space-evenly',
                                         paddingHorizontal: 20
                                     }}
                                 >
                                     {section.data.map(at => <ActivityButton activityTemplate={at}
                                                                             style={{
                                                                                 height: 140
                                                                             }}
                                                                             key={at?.id}
                                                                             student={user}
                                                                             onPress={!browseMode ? null : () => {
                                                                                 goToActivity(at)
                                                                             }}
                                         // showSchoolSubjectBottomSheet={showSchoolSubjectBottomSheet}
                                                                             activities={props.activities?.filter(x => x?.activity_template_id === at?.id)}
                                     />)}


                                 </View>
                             </View>
                         )}


            />


            {/*{IS_WEB && <FilledButtonM mode={"text"}*/}
            {/*                          icon={"chevron-left"}*/}
            {/*                          color={theme.colors.link}*/}
            {/*                          style={{marginTop: 60}}*/}
            {/*                          onPress={() => {*/}
            {/*                              if (navigation.canGoBack())*/}
            {/*                                  navigation.goBack()*/}
            {/*                              else*/}
            {/*                                  navigation.replace("menu")*/}
            {/*                          }}*/}
            {/*                          label={"Retour"}/>}*/}

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        purchaseRequest: state.api.purchase?.request,
        purchaseSuccess: state.api.purchase?.success,
        purchaseError: state.api.purchase?.error,

        getUserRequest: state.api.getUser?.request,
        activityTemplates: state.data.staticData?.activity_templates,
        isLive: state.liveMode.isLiveModeEnabled,

        user: state.data.currentUser?.object,
        shopProducts: state.data.staticData?.shop_products
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(AllActivities)