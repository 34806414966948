import LoginScreen from "../../auth/LoginScreen";
import RegisterScreen from "../../auth/RegisterScreen";
import ValidateEmailScreen from "../account/ValidateEmailScreen";
import BottomTabsNavigator from "../main-menu/BottomTabsNavigator";
import StudentTabsNavigator from "../../student/organize/StudentTabsNavigator";
import NewChallengeScreen from "../../student/organize/challenges/NewChallengeScreen";
import ChallengeHistoryScreen from "../../student/organize/challenges/ChallengeHistoryScreen";
import ActivityPresentationScreen from "../../student/activity/read/ActivityPresentationScreen";
import ActivityReviewScreen from "../../reviewer/ActivityReviewScreen";
import ActivityResultScreen from "../../student/activity/read/ActivityResultScreen";
import ActivityScreen from "../../student/activity/execute/ActivityScreen";
import CongratsScreen from "../screens/CongratsScreen";
import CameraScreen from "../camera/CameraScreen";
import MyProfileTabScreen from "../../student/account/MyAccountScreen";
import MyAccountTabNavigation from "../../student/account/SettingsTabNavigator";
import UpdatePasswordScreen from "../../student/account/UpdatePasswordScreen";
import JoinClassroomScreen from "../../student/account/classroom/JoinClassroomScreen";
import JoinSchoolScreen from "../../student/account/classroom/JoinSchoolScreen";
import DeleteAccountScreen from "../../student/account/DeleteAccountScreen";
import ZoeIntroScreen from "../../student/champions/ZoeIntroScreen";
import ChampionIntroScreen from "../../student/champions/ChampionIntroScreen";
import MySubscriptionScreen from "../../student/account/subscription/MySubscriptionScreen";
import TchatScreen from "../messenger/ChatScreen";
import ProfilAmi from "../../student/social/ProfilFriendScreen";
import LeagueHistoryScreen from "../../student/league/LeagueHistoryScreen";
import LeagueProgressScreen from "../../student/league/LeagueProgressScreen";
import MyProgressScreen from "../../student/progress/my progress/MyProgressScreen";
import MyPetalsTabScreen from "../../student/shop/MyPetalsTabScreen";
import AllActivities from "../../student/activity/AllActivities";
import StudentProfil from "../../tutor/students/StudentProfil";
import Interview from "../../tutor/home/interview/Interview";
import {theme} from "../theme/theme";
import InterviewSummary from "../../tutor/home/interview/InterviewSummary";
import TutorSlotsScreen from "../../tutor/TutorSlotsScreen";
import BrowseActivities from "../../tutor/home/tutorResources/BrowseActivites";
import TutorVideos from "../../tutor/home/tutorResources/TutorVideos";
import InterviewHistory from "../../tutor/home/InterviewHistory";
import SlotUpdateScreen from "../../tutor/SlotUpdateScreen";
import NewScoringCommentScreen from "../../reviewer/NewScoringCommentScreen";
import NewScoringRuleScreen from "../../reviewer/NewScoringRuleScreen";
import Schools from "../../school/Schools";
import SchoolScreen from "../../school/SchoolScreen";
import NewClassroomStepper from "../../teacher/classrooms/NewClassroomStepper";
import ClassroomScreen from "../../teacher/classrooms/ClassroomScreen";
import ClassroomNewChallenge from "../../teacher/classrooms/ClassroomNewChallenge";
import Classrooms from "../../teacher/classrooms/Classrooms";
import LiveParticipants from "../liveMode/LiveParticipants";
import LiveChat from "../liveMode/LiveChat";
import ShareStatsScreen from "../../student/social/ShareStatsScreen";
import ForgotPassword from "../../auth/ForgotPassword";
import CallStudentsStepper from "@common/liveMode/CallStudentsStepper";
import OnlineUsers from "@common/liveMode/OnlineUsers";
import Analytics from "../../admin/Analytics";
import LaunchLiveScreen from "@common/liveMode/LaunchLiveScreen";
import StudentNotesScreen from "../../tutor/notes/StudentNotesScreen";
import NewNoteScreen from "../../tutor/notes/NewNoteScreen";
import LivesScreen from "../../admin/live/LivesScreen";
import LiveStepper from "../../admin/live/LiveStepper";
import LiveShow from "../../admin/live/LiveShow";
import AddUserToLive from "../../admin/live/AddUserToLive";
import NewWeeklyReportScreen from "../../admin/NewWeeklyReportScreen";
import SendWeeklyReportScreen from "../../admin/SendWeeklyReportScreen";
import UsersTabScreen from "../../admin/UsersTabScreen";
import AssignTutorAndLives from "../../admin/AssignTutorAndLives";
import ChangeEmailStepper from "@common/account/ChangeEmailStepper";
import CreateEditRoom from "../../admin/live/rooms/CreateEditRoom";
import AddUsersToSessions from "../../admin/live/rooms/AddUsersToSessions";
import NewSessions from "../../admin/live/rooms/NewSessions";
import SubscribeScreen from "../../auth/SubscribeScreen";
import SubscribeRedirectScreen from "../../auth/SubscribeRedirectScreen";
import SubscribeReturnScreen from "../../auth/SubscribeReturnScreen";
import EmailVerifyScreen from "../../auth/EmailVerifyScreen";
import {AuthNavigator} from "../../auth/AuthNavigator";
import RegisterTrialScreen from "../../student/account/onboarding/RegisterTrialScreen";
import NewTodoScreen from "../../student/activity/todo/NewTodoScreen";
import ManageMaterialScreen from "../../student/material/ManageMaterialScreen";
import MaterialDetails from "../../student/material/MaterialDetails";
import TutorApplicationScreen from "../../tutor/application/TutorApplicationScreen";
import ApplicationPersonalInfos from "../../tutor/application/personalInfos/ApplicationPersonalInfos";
import CompleteTutorProfileStepper from "../../tutor/home/CompleteTutorProfileStepper";
import VideoRecordingScreen from "../../tutor/application/motivation/VideoRecordingScreen";
import MotivationInterviewScreen from "../../tutor/application/motivation/InterviewScreen";
import UpdateClassScreen from "../../student/account/classroom/UpdateClassScreen";
import CreateAvatarScreen from "../../student/account/CreateAvatarScreen";
import AssignTutor from "../../admin/AssignTutor";
import ChangePrivileges from "../../admin/ChangePrivileges";
import TutorsApplicationsTabNavigator from "../../admin/applications/TutorsApplicationsTabNavigator";
import TutorApplicationDetailsScreen from "../../admin/applications/TutorApplicationDetailsScreen";
import TutorsScreen from "../../admin/tutors/TutorsScreen";
import FixSalaryScreen from "../../admin/tutors/FixSalaryScreen";
import AssignAdmin from "../../admin/tutors/AssignAdmin";
import RescheduleLive from "../../admin/live/RescheduleLive";
import AcceptSubstitution from "../../admin/live/AcceptSubstitution";
import NewQuizzScreen from "../../quiz/NewQuizzScreen";
import ConfirmQuizzScreen from "../../quiz/ConfirmQuizzScreen";
import TrialOnboardingScreen from "../../student/account/onboarding/TrialOnboardingScreen";
import RegisterNewScreen from "../../auth/RegisterNewScreen";
import RegisterReturnScreen from "../../auth/RegisterReturnScreen";
import EndSessionScreen from "@common/liveMode/EndSessionScreen";
import EndSessionScreenStudent from "@common/liveMode/EndSessionScreenStudent";
import LiveReportScreen from "@common/liveMode/live-report/LiveReportScreen";
import {AccountTypeScreen} from "../../auth/AccountTypeScreen";
import TutorRegisterScreen from "../../auth/TutorRegisterScreen";
import ResetPasswordScreen from "../../auth/ResetPasswordScreen";
import CreateEditReferralCode from "../../admin/referrals/CreateEditReferralCode";
import ReferralCodeScreen from "../../admin/ReferralCodeScreen";
import PreCallScreen from "@common/liveMode/PreCallScreen";
import SponsorshipScreen from "../../student/account/SponsorshipScreen";
import MetricsTabNavigator from "../../admin/metrics/MetricsTabNavigator";
import LiveReportsScreen from "../../admin/live/LiveReportsScreen";
import ChapterFeelingQuiz from "@common/chapters/feeling-quiz/ChapterFeelingQuiz";
import InterviewReportsScreen from "../interviewReports/InterviewReportsScreen";
import InterviewReportScreen from "../interviewReports/InterviewReportScreen";
import NewInterviewReportScreen from "../../admin/interviewReports/NewInterviewReportScreen";
import UpdateMaxStudentsPerLive from "../../admin/tutors/UpdateMaxStudentsPerLive";
import RegisterTrialSessionScreen from "../../student/account/onboarding/RegisterTrialSessionScreen";
import TodoCheck from "@common/liveMode/TodoCheck";
import RegisterSpecialScreen from "../../student/account/onboarding/RegisterSpecialScreen";
import StudyProgramScreen from "../studyPrograms/studyProgramScreen";
import RegisterWorkshopScreen from "../../student/account/onboarding/RegisterWorkshopScreen";
import SubscribtionContinuity from "@common/account/SubscribtionContinuity";
import RoomsScreen from "../../admin/live/rooms/RoomsScreen";
import TutoringInvoicesScreen from "../../tutor/invoices/TutoringInvoicesScreen";
import CreateEditStudyProgram from "../../admin/studyPrograms/CreateEditStudyProgram";
import StudyProgramsScreen from "../studyPrograms/StudyProgramsScreen";
import SatisfactionScreen from "@common/liveMode/SatisfactionScreen";
import TodoListByChapterScreen from "../../student/activity/todo/TodoListByChapterScreen";
import LivesHistoryScreen from "../../admin/live/live-history/LivesHistoryScreen";
import PrioritySchoolSubjectsScreen from "../../auth/PrioritizedSchoolSubjectsScreen";
import AvailabilitySlotsScreen from "../../auth/AvailabilitySlotsScreen";

/**
 * Main screens
 *
 * @type [{component: ((function({navigation: *}): *)|*), name: string, options: {animationTypeForReplace: string}}, {component: *, name: string}, {component: *, name: string}]
 */
const main = [
    // ** Main menu
    {
        name: "menu",
        titles: [
            {
                main: {
                    component: BottomTabsNavigator
                }
            },
            {
                live: {
                    component: OnlineUsers,
                    options: {
                        headerShown: true,
                        headerTitle: "LIVE"
                    }
                }
            },
            {
                auth: {
                    component: AuthNavigator,
                    options: {
                        animationTypeForReplace: "pop",
                        headerShown: false,
                        headerTitle: "Accueil"
                    }
                }
            }
        ]
    },
    // ** common
    {
        name: "pre-call",
        component: PreCallScreen,
        options: {
            headerTitle: "Rejoindre le Live",
            headerShown: true
        },
        titles: [{main: {}}]
    },
    {
        name: "subscribe",
        component: SubscribeScreen,
        options: {
            headerShown: false,
            headerTitle: "Abonnement"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
    name: "prioritized-school-subjects",
    component: PrioritySchoolSubjectsScreen,
    options: {
      headerShown: false,
      headerTitle: "Matières prioritaires",
    },
    titles: [{ main: {} }, { live: {} }, { auth: {} }],
  },
  {
    name: "availability-slots",
    component: AvailabilitySlotsScreen,
    options: {
      headerShown: false,
      headerTitle: "Disponibilités",
    },
    titles: [{ main: {} }, { live: {} }, { auth: {} }],
  },
  {
        name: "subscribe/redirect",
        component: SubscribeRedirectScreen,
        options: {
            headerShown: false,
            headerTitle: "Abonnement"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "subscribe/return",
        component: SubscribeReturnScreen,
        options: {
            headerShown: false,
            headerTitle: "Abonnement"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "email/verify/:id/:hash",
        component: EmailVerifyScreen,
        options: {
            headerShown: false,
            headerTitle: "Vérification de l'email"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "r",
        component: LiveReportScreen,
        options: {
            headerShown: false,
            headerTitle: "Rapport de LIVE"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "live-reports",
        component: LiveReportsScreen,
        options: {
            headerShown: false,
            headerTitle: "Rapports de LIVE"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    // ** LIVE
    {
        name: "launch-live",
        component: LaunchLiveScreen,
        options: {
            headerShown: true,
            headerTitle: "LaunchLive"
        },
        titles: [{live: {}}]
    },
    {
        name: "call-students-stepper",
        component: CallStudentsStepper,
        options: {
            headerShown: true,
            headerTitle: "Appeler"
        },
        titles: [{live: {}}]
    },
    //Leave session
    {
        name: "end-session-tutor",
        component: EndSessionScreen,
        options: {
            headerShown: false,
            headerTitle: "LIVE clôturé"
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "end-session-student",
        component: EndSessionScreenStudent,
        options: {
            headerShown: false,
            headerTitle: "Clôturer le LIVE"
        },
        titles: [{live: {}}]
    },
    {
        name: "live-satisfaction",
        component: SatisfactionScreen,
        options: {
            headerShown: false,
            headerTitle: "Satisfaction de l'élève"
        },
        titles: [{main: {},live: {}}]
    },
    // ** Tutor students
    {
        name: "student",
        component: StudentTabsNavigator,
        options: {
            headerShown: false,
            headerTitle: "Elève"
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "student-profil",
        component: StudentProfil,
        options: {
            headerTitle: "Profil",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "interview-summary",
        component: InterviewSummary,
        options: {
            headerTitle: "Relecture avant envoi",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "interview",
        component: Interview,
        options: {
            headerTitle: "Entretien",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "interviewHistory",
        component: InterviewHistory,
        options: {
            headerTitle: "Entretiens",
            headerShown: false
        },
        titles: [
            {main: {}},
            {
                live: {
                    options: {
                        headerTitle: "Entretiens",
                        headerShown: true
                    }
                }
            }
        ]
    },
    {
        name: "browse-activities",
        component: BrowseActivities,
        options: {
            headerTitle: "Référentiel des activités",
            headerShown: false
        },
        titles: [
            {main: {}},
            {
                live: {
                    options: {
                        headerTitle: "Référentiel des activités",
                        headerShown: true
                    }
                }
            }
        ]
    },
    {
        name: "videos-coaching",
        component: TutorVideos,
        options: {
            headerTitle: "Vidéos de formation"
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "update-occupied-slots",
        component: SlotUpdateScreen,
        options: {
            headerTitle: "Modifier un créneau",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "student-notes",
        component: StudentNotesScreen,
        options: {
            headerTitle: "Notes du Masteur",
            headerShown: false
        },
        titles: [
            {main: {}},
            {
                live: {
                    options: {
                        headerTitle: "Notes du Masteur",
                        headerShown: true
                    }
                }
            }
        ]
    },
    {
        name: "new-note",
        component: NewNoteScreen,
        options: {
            headerTitle: "Nouvelle note",
            headerShown: false
        },
        titles: [
            {main: {}},
            {
                live: {
                    options: {
                        headerTitle: "Nouvelle note",
                        headerShown: true
                    }
                }
            }
        ]
    },
    {
        name: "weeklyReport",
        component: NewWeeklyReportScreen,
        options: {
            headerTitle: "Nouveau rapport",
            headerShown: true
        },
        titles: [{main: {}}]
    },
    {
        name: "interview-reports",
        component: InterviewReportsScreen,
        options: {
            headerTitle: "Liste des entretiens parent",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "ir",
        component: InterviewReportScreen,
        options: {
            headerTitle: "Entretien parent",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "interview-report",
        component: NewInterviewReportScreen,
        options: {
            headerTitle: "Entretien parent",
            headerShown: false
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "send-weekly-report",
        component: SendWeeklyReportScreen,
        options: {
            headerTitle: "Envoyer rapport",
            headerShown: false
        },
        titles: [{main: {}}]
    },
    {
        name: "tutor-application",
        component: TutorApplicationScreen,
        options: {
            headerTitle: "Candidature tuteur",
            headerShown: false
        },
        titles: [{main: {}}]
    },
    {
        name: "application-personal-infos",
        component: ApplicationPersonalInfos,
        options: {
            headerTitle: "Information personnelle",
            headerShown: false
        },
        titles: [{main: {}}]
    },
    {
        name: "application-interview",
        component: MotivationInterviewScreen,
        options: {
            headerTitle: "Entretien de motivation",
            headerShown: false
        },
        titles: [{main: {}}]
    },
    {
        name: "video-recording",
        component: VideoRecordingScreen,
        options: {
            headerTitle: "Enregistrement",
            headerShown: false
        },
        titles: [{main: {}}]
    },

  // ** Reviewer
  {
    name: "activity-review",
    component: ActivityReviewScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Revue d'activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Revue d'activité",
          },
        },
      },
    ],
  },
  {
    name: "new-scoring-comment",
    component: NewScoringCommentScreen,
    options: {
      headerShown: false,
            headerTitle: "Nouveau commentaire"
    },
    titles: [{main: {} }],
  },
  {
    name: "new-scoring-rule",
    component: NewScoringRuleScreen,
    options: {
      headerShown: false,
      headerTitle: "Nouvelle règle",
    },
    titles: [{main: {} }],
  },
  // ** School
  {
    name: "schools",
    component: Schools,
    options: {
      headerTitle: "Ecoles",
      headerShown: false,
    },
    titles: [{main: {} }],
  },
  {
    name: "school",
    component: SchoolScreen,
    options: {
      headerTitle: "École",
    },
    titles: [{main: {} }],
  },
  // ** Teacher
  {
    name: "new-classroom",
    component: NewClassroomStepper,
    options: {
      headerShown: false,
      headerTitle: "Nouvelle classe",
    },
    titles: [{main: {} }],
  },
  {
    name: "classrooms",
    component: Classrooms,
    options: {
      headerShown: false,
      headerTitle: "Classes",
    },
    titles: [{main: {} }],
  },
  {
    name: "classroom",
    component: ClassroomScreen,
    options: {
      headerShown: false,
      headerTitle: "Classe",
    },
    titles: [{main: {} }],
  },
  {
    name: "new-challenge-classroom",
    component: ClassroomNewChallenge,
    options: {
      headerShown: false,
      headerTitle: "Nouveau défis",
    },
    titles: [{main: {} }],
  },
  // ** Student home
  {
    name: "my-privileges",
    component: MySubscriptionScreen,
    options: {
      headerShown: true,
      headerTitle: "Mes privilèges",
    },
    titles: [{main: {} }],
  },
  // ** Student challenges / school subjects
  {
    name: "challenges-history",
    component: ChallengeHistoryScreen,
    titles: [
      {
            main: {
                options: {
                    headerTitle: "Historique défis",
            headerShown: false,
          },
        },
      },
      {
            live: {
                options: {
                    headerTitle: "Historique défis",
            headerShown: true,
          },
        },
      },
    ],
  },
  // ** Student activity
  {
    name: "activity-presentation",
    component: ActivityPresentationScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Présentation activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Présentation activité",
          },
        },
      },
    ],
  },
  {
    name: "activity-result",
    component: ActivityResultScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Résultat activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Résultat activité",
          },
        },
      },
    ],
  },
  {
    name: "activity",
    component: ActivityScreen,
    titles: [
      {
            main: {
                options: {
                    headerShown: false,
            headerTitle: "Activité",
          },
        },
      },
      {
            live: {
                options: {
                    headerShown: true,
            headerTitle: "Activité",
          },
        },
      },
    ],
  },
  {
    name: "share-stats",
    component: ShareStatsScreen,
    options: {
      headerShown: false,
      headerTitle: "Partager mes statistiques",
    },
    titles: [{main: {}, live: {} }],
  },

  // ** Study programs
  {
    name: "study-program",
    component: StudyProgramScreen,
    options: {
      headerShown: false,
      headerTitle: "Programme d'étude",
    },
    titles: [{main: {} }],
  },
  {
    name: "study-programs",
    component: StudyProgramsScreen,
    options: {
      headerShown: false,
      headerTitle: "Programmes de révision",
    },
    titles: [{main: {} }],
  },
  {
    name: "new-study-program",
    component: CreateEditStudyProgram,
    options: {
      headerShown: false,
      headerTitle: "Nouveau programme d'étude",
    },
    titles: [{main: {} }],
  },

  // ** Profile
  {
    name: "account",
    component: MyProfileTabScreen,
    options: {
      headerShown: true,
      headerTitle: "Mon compte",
    },
    titles: [{main: {} }],
  },
  {
    name: "settings",
    component: MyAccountTabNavigation,
    options: {
      headerShown: false,
      headerTitle: "Paramètres",
    },
    titles: [{main: {} }],
  },
  {
    name: "update-slots",
    component: TutorSlotsScreen,
    options: {
      headerShown: false,
      headerTitle: "Modifier mes créneaux",
    },
    titles: [{main: {} }],
  },
  {
    name: "update-class",
    component: UpdateClassScreen,
    options: {
      headerShown: false,
      headerTitle: "Modifier ma classe",
    },
    titles: [{main: {} }],
  },
  {
    name: "tutor/complete-profile",
    component: CompleteTutorProfileStepper,
    options: {
      headerShown: false,
      headerTitle: "Compléter mon profil tuteur",
    },
    titles: [{main: {} }],
  },
  {
    name: "account-password",
    component: UpdatePasswordScreen,
    options: {
      headerTitle: "Mot de passe",
    },
    titles: [{main: {} }],
  },
  {
    name: "account-close",
    component: DeleteAccountScreen,
    options: {
      headerTitle: "Fermer mon compte",
    },
    titles: [{main: {} }],
  },
  {
    name: "join-classroom",
    component: JoinClassroomScreen,
    options: {
      headerTitle: "Rejoindre une classe",
    },
    titles: [{main: {} }],
  },
  {
    name: "join-school",
    component: JoinSchoolScreen,
    options: {
      headerTitle: "Rejoindre une école",
    },
    titles: [{main: {} }],
  },
  {
    name: "sponsorship",
    component: SponsorshipScreen,
    options: {
      headerShown: false,
      headerTitle: "Parrainage",
    },
    titles: [{main: {} }],
  },
  // ** Common
  {
    name: "camera",
    component: CameraScreen,
    options: {
      headerShown: false,
      headerTitle: "Caméra",
    },
    titles: [{main: {} }, {live: {} }],
  },
  {
    name: "metrics",
    component: MetricsTabNavigator,
    options: {
      headerShown: false,
      headerTitle: "Dashboard",
    },
    titles: [{main: {} }],
  },
  {
    name: "messenger",
    component: TchatScreen,
    options: {
      headerShown: false,
      headerTitle: "Messages",
    },
    titles: [
      {main: {} },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Messages",
          },
        },
      },
    ],
  },
  {
    name: "Profile",
    component: ProfilAmi,
    options: {
      headerShown: false,
    },
    titles: [{main: {} }],
  },
  {
    name: "Historique",
    component: LeagueHistoryScreen,
    options: {
      headerShown: true,
    },
    titles: [{main: {} }],
  },
  {
    name: "League_progress",
    component: LeagueProgressScreen,
    options: {
      headerShown: false,
    },
    titles: [{main: {} }],
  },
  {
    name: "skill-set",
    component: MyProgressScreen,
    options: {
      headerShown: true,
      headerTitle: "Mes quêtes",
    },
    titles: [{main: {} }, {live: {} }],
  },
  {
    name: "shop",
    component: MyPetalsTabScreen,
    options: {
      headerShown: false,
      headerTitle: "Boutique de gestes",
    },
        titles: [{
            main: {}, live: {
          options: {
            headerShown: true,
            headerTitle: "Boutique de gestes",
          }
            }
        ,
      },
    ],
  },
  {
    name: "all-activities",
    component: AllActivities,
    titles: [
      {
            main: {
                options: {
                    headerShown: false,
            headerTitle: "Toutes les activités",
          },
        },
      },
      {
            live: {
                options: {
                    headerShown: true,
            headerTitle: "Toutes les activités",
          },
        },
      },
    ],
  },
  {
    name: "avatar",
    component: CreateAvatarScreen,
    options: {
      headerShown: true,
      headerTitle: "Personnalise ton avatar",
    },
    titles: [{main: {} }],
  },
  {
        name: "todos-by-chapter",
        component: TodoListByChapterScreen,
        options: {
            headerShown: false
        },
        titles: [{main: {}, live: {}}]
    },
    {
    name: "new-todo",
    component: NewTodoScreen,
    titles: [
      {
            main: {
                options: {
                    headerShown: false,
            headerTitle: "Nouvelle activité",
          },
        },
      },
      {
            live: {
                options: {
                    headerShown: true,
            headerTitle: "Nouvelle activité",
          },
        },
      },
    ],
  },
  {
    name: "new-material",
    component: ManageMaterialScreen,
    titles: [
      {
            main: {
                options: {
                    headerShown: false,
            headerTitle: "Nouvel énoncé",
          },
        },
      },
      {
            live: {
                options: {
                    headerShown: true,
            headerTitle: "Nouvel énoncé",
          },
        },
      },
    ],
  },
  {
    name: "update-material",
    component: ManageMaterialScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Modifier énoncé",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Modifier énoncé",
          },
        },
      },
    ],
  },
  {
    name: "material-details",
    component: MaterialDetails,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Énoncé",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Énoncé",
          },
        },
      },
    ],
  },
  // {
  //     name: "pause-resume-subscription",
  //     component: SubscribtionSuspension,
  //     options: {
  //         headerShown: false,
  //         headerTitle: "Suspension abonnement"
  //     },
  //     titles: [{"main": {}}, {"auth": {}}]
  // },
  {
    name: "subscription-continuity",
    component: SubscribtionContinuity,
    options: {
      headerShown: false,
      headerTitle: "Suite de l'abonnement",
    },
    titles: [{ main: {} }, { auth: {} }],
  },
  // admin
  {
    name: "analytics",
    component: Analytics,
    options: {
      headerShown: true,
      headerTitle: "Analytics",
    },
    titles: [{ main: {} }],
  },
  {
    name: "lives-history",
    component: LivesHistoryScreen,
    options: {
      headerShown: false,
      headerTitle: "Historique des lives",
    },
    titles: [{ main: {} }],
  },
  {
    name: "admin-live-reports",
    component: LiveReportsScreen,
    options: {
      headerShown: false,
      headerTitle: "Rapports de LIVE",
    },
    titles: [{ main: {} }, { live: {} }, { auth: {} }],
  },
  {
    name: "new-referral-code",
    component: CreateEditReferralCode,
    options: {
      headerShown: false,
      headerTitle: "Nouveau code d'affiliation",
    },
    titles: [{ main: {} }],
  },
  {
    name: "referrer/dashboard/:h",
    component: ReferralCodeScreen,
    options: {
      headerShown: false,
      headerTitle: "Mes affiliations",
    },
    titles: [{ main: {} }, { live: {} }, { auth: {} }],
  },
  {
    name: "admin-users",
    component: UsersTabScreen,
    options: {
      headerShown: false,
      headerTitle: "Liste des utilisateurs",
    },
    titles: [{ main: {} }],
  },
  {
    name: "admin-lives",
    component: LivesScreen,
    options: {
      headerShown: false,
      headerTitle: "Liste des LIVES",
    },
    titles: [{ main: {} }],
  },
  {
    name: "lives-form",
    component: LiveStepper,
    options: {
      headerShown: false,
      headerTitle: "Configurer un LIVE",
    },
    titles: [{ main: {} }],
  },
  {
    name: "live-show",
    component: LiveShow,
    options: {
      headerShown: false,
      headerTitle: "Consulter un LIVE",
    },
    titles: [{ main: {} }],
  },
  {
    name: "add-user-live",
    component: AddUserToLive,
    options: {
      headerShown: false,
      headerTitle: "Ajouter un utilisateur au live",
    },
    titles: [{ main: {} }],
  },
  {
    name: "assign-master-lives",
    component: AssignTutorAndLives,
    options: {
      headerShown: false,
      headerTitle: "Attribuer un élève",
    },
    titles: [{ main: {} }],
  },
  {
    name: "assign-tutor",
    component: AssignTutor,
    options: {
      headerShown: false,
      headerTitle: "Attribuer un Masteur",
    },
    titles: [{ main: {} }],
  },
  {
    name: "change-privileges",
    component: ChangePrivileges,
    options: {
      headerShown: false,
      headerTitle: "Modifier les privilèges",
    },
    titles: [{ main: {} }],
  },
  {
    name: "rooms",
    component: RoomsScreen,
    options: {
      headerShown: false,
      headerTitle: "Liste des LIVES",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-room",
    component: CreateEditRoom,
    options: {
      headerShown: false,
      headerTitle: "Nouvelle salle",
    },
    titles: [{ main: {} }],
  },
  {
    name: "add-members-to-sessions",
    component: AddUsersToSessions,
    options: {
      headerShown: false,
      headerTitle: "Ajouter des membres",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-sessions",
    component: NewSessions,
    options: {
      headerShown: false,
      headerTitle: "Créer des sessions",
    },
    titles: [{ main: {} }],
  },
  {
    name: "tutors-applications",
    component: TutorsApplicationsTabNavigator,
    options: {
      headerShown: false,
      headerTitle: "Candidature des tuteurs",
    },
    titles: [{ main: {} }],
  },
  {
    name: "application-details",
    component: TutorApplicationDetailsScreen,
    options: {
      headerShown: false,
      headerTitle: "Candidature",
    },
    titles: [{ main: {} }],
  },
  {
    name: "manage-tutors",
    component: TutorsScreen,
    options: {
      headerShown: false,
      headerTitle: "Tuteurs",
    },
    titles: [{ main: {} }],
  },
  {
    name: "update-salary",
    component: FixSalaryScreen,
    options: {
      headerShown: false,
      headerTitle: "Mettre à jour le salaire",
    },
    titles: [{ main: {} }],
  },
  {
    name: "update-max-students-live",
    component: UpdateMaxStudentsPerLive,
    options: {
      headerShown: false,
      headerTitle: "Maximum d'élèves par LIVE",
    },
    titles: [{ main: {} }],
  },
  {
    name: "assign-admin",
    component: AssignAdmin,
    options: {
      headerShown: false,
      headerTitle: "Assigner un admin",
    },
    titles: [{ main: {} }],
  },
  {
    name: "reschedule-live",
    component: RescheduleLive,
    options: {
      headerShown: false,
      headerTitle: "Assigner un admin",
    },
    titles: [{ main: {} }],
  },
  {
    name: "accept-substitution",
    component: AcceptSubstitution,
    options: {
      headerShown: false,
      headerTitle: "Assigner un admin",
    },
    titles: [{ main: {} }],
  },
  {
    name: "quizz",
    component: NewQuizzScreen,
    options: {
      headerShown: false,
      headerTitle: "Lancer un quiz",
    },
    titles: [{ main: {} }, { auth: {} }],
  },
  {
    name: "quizz-confirm",
    component: ConfirmQuizzScreen,
    options: {
      headerShown: false,
      headerTitle: "Lancer un quiz",
    },
    titles: [{ main: {} }, { auth: {} }],
  },
  {
    name: "new-student",
    component: RegisterScreen,
    options: {
      headerShown: false,
      headerTitle: "Créer élève",
    },
    titles: [{ main: {} }],
  },
  {
    name: "offer-essential",
    component: TrialOnboardingScreen,
    options: {
      headerShown: false,
      headerTitle: "Offre Essentielle",
    },
    titles: [{ main: {} }],
  },
  {
    name: "start-trial",
    component: RegisterTrialSessionScreen,
    options: {
      headerShown: false,
      headerTitle: "Essai gratuit",
    },
    titles: [{ main: {} }],
  },
  {
    name: "start-trial/confirm",
    component: RegisterTrialScreen,
    options: {
      headerShown: false,
      headerTitle: "Essai gratuit",
    },
    titles: [{ main: {} }],
  },
  {
    name: "validate-email",
    component: ValidateEmailScreen,
    options: {
      headerShown: false,
      headerTitle: "Validation email",
    },
    titles: [{ main: {} }],
  },
  {
    name: "edit-email",
    component: ChangeEmailStepper,
    options: {
      headerShown: false,
            headerTitle: "Modifier email"
    },
    titles: [{ main: {} }],
  },
  {
    name: "intro-zoe",
    component: ZoeIntroScreen,
    options: {
      headerShown: false,
      headerTitle: "Introduction",
    },
    titles: ["studentFirstChallenge"],
  },
  {
    name: "new-challenge",
    component: NewChallengeScreen,
    options: {
      headerShown: true,
      headerTitle: "Nouveau défi",
    },
    titles: [
      "studentFirstChallenge",
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Nouveau défi",
          }
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Nouveau défi",
          }
        },
      },
    ],
  },
  {
    name: "presence",
    component: LiveParticipants,
    options: {
      headerShown: true,
      headerTitle: "LIVE",
    },
    titles: [{ live: {} }],
  },
  {
    name: "messages",
    component: LiveChat,
    options: {
      headerShown: true,
      headerTitle: "LIVE Messages",
    },
    titles: [{ live: {} }],
  },
  /*    {
  // ** Reviewer
  {
    name: "activity-review",
    component: ActivityReviewScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Revue d'activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Revue d'activité",
          },
        },
      },
    ],
  },
  {
    name: "new-scoring-comment",
    component: NewScoringCommentScreen,
    options: {
      headerShown: false,
      headerTitle: "Nouveau commentaire",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-scoring-rule",
    component: NewScoringRuleScreen,
    options: {
      headerShown: false,
      headerTitle: "Nouvelle règle",
    },
    titles: [{ main: {} }],
  },
  // ** School
  {
    name: "schools",
    component: Schools,
    options: {
      headerTitle: "Ecoles",
      headerShown: false,
    },
    titles: [{ main: {} }],
  },
  {
    name: "school",
    component: SchoolScreen,
    options: {
      headerTitle: "École",
    },
    titles: [{ main: {} }],
  },
  // ** Teacher
  {
    name: "new-classroom",
    component: NewClassroomStepper,
    options: {
      headerShown: false,
      headerTitle: "Nouvelle classe",
    },
    titles: [{ main: {} }],
  },
  {
    name: "classrooms",
    component: Classrooms,
    options: {
      headerShown: false,
      headerTitle: "Classes",
    },
    titles: [{ main: {} }],
  },
  {
    name: "classroom",
    component: ClassroomScreen,
    options: {
      headerShown: false,
      headerTitle: "Classe",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-challenge-classroom",
    component: ClassroomNewChallenge,
    options: {
      headerShown: false,
      headerTitle: "Nouveau défis",
    },
    titles: [{ main: {} }],
  },
  // ** Student home
  {
    name: "my-privileges",
    component: MySubscriptionScreen,
    options: {
      headerShown: true,
      headerTitle: "Mes privilèges",
    },
    titles: [{ main: {} }],
  },
  // ** Student challenges / school subjects
  {
    name: "challenges-history",
    component: ChallengeHistoryScreen,
    titles: [
      {
        main: {
          options: {
            headerTitle: "Historique défis",
            headerShown: false,
          },
        },
      },
      {
        live: {
          options: {
            headerTitle: "Historique défis",
            headerShown: true,
          },
        },
      },
    ],
  },
  // ** Student activity
  {
    name: "activity-presentation",
    component: ActivityPresentationScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Présentation activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Présentation activité",
          },
        },
      },
    ],
  },
  {
    name: "activity-result",
    component: ActivityResultScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Résultat activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Résultat activité",
          },
        },
      },
    ],
  },
  {
    name: "activity",
    component: ActivityScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Activité",
          },
        },
      },
    ],
  },
  {
    name: "share-stats",
    component: ShareStatsScreen,
    options: {
      headerShown: false,
      headerTitle: "Partager mes statistiques",
    },
    titles: [{ main: {}, live: {} }],
  },
  // ** Profile
  {
    name: "account",
    component: MyProfileTabScreen,
    options: {
      headerShown: true,
      headerTitle: "Mon compte",
    },
    titles: [{ main: {} }],
  },
  {
    name: "settings",
    component: MyAccountTabNavigation,
    options: {
      headerShown: false,
      headerTitle: "Paramètres",
    },
    titles: [{ main: {} }],
  },
  {
    name: "update-slots",
    component: TutorSlotsScreen,
    options: {
      headerShown: false,
      headerTitle: "Modifier mes créneaux",
    },
    titles: [{ main: {} }],
  },
  {
    name: "update-class",
    component: UpdateClassScreen,
    options: {
      headerShown: false,
      headerTitle: "Modifier ma classe",
    },
    titles: [{ main: {} }],
  },
  {
    name: "tutor/complete-profile",
    component: CompleteTutorProfileStepper,
    options: {
      headerShown: false,
      headerTitle: "Compléter mon profil tuteur",
    },
    titles: [{ main: {} }],
  },
  {
    name: "account-password",
    component: UpdatePasswordScreen,
    options: {
      headerTitle: "Mot de passe",
    },
    titles: [{ main: {} }],
  },
  {
    name: "account-close",
    component: DeleteAccountScreen,
    options: {
      headerTitle: "Fermer mon compte",
    },
    titles: [{ main: {} }],
  },
  {
    name: "join-classroom",
    component: JoinClassroomScreen,
    options: {
      headerTitle: "Rejoindre une classe",
    },
    titles: [{ main: {} }],
  },
  {
    name: "join-school",
    component: JoinSchoolScreen,
    options: {
      headerTitle: "Rejoindre une école",
    },
    titles: [{ main: {} }],
  },
  {
    name: "sponsorship",
    component: SponsorshipScreen,
    options: {
      headerShown: false,
      headerTitle: "Parrainage",
    },
    titles: [{ main: {} }],
  },
  // ** Common
  {
    name: "camera",
    component: CameraScreen,
    options: {
      headerShown: false,
      headerTitle: "Caméra",
    },
    titles: [{ main: {} }, { live: {} }],
  },
  {
    name: "metrics",
    component: MetricsTabNavigator,
    options: {
      headerShown: false,
      headerTitle: "Dashboard",
    },
    titles: [{ main: {} }],
  },
  {
    name: "messenger",
    component: TchatScreen,
    options: {
      headerShown: false,
      headerTitle: "Messages",
    },
    titles: [
      { main: {} },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Messages",
          },
        },
      },
    ],
  },
  {
    name: "Profile",
    component: ProfilAmi,
    options: {
      headerShown: false,
    },
    titles: [{ main: {} }],
  },
  {
    name: "Historique",
    component: LeagueHistoryScreen,
    options: {
      headerShown: true,
    },
    titles: [{ main: {} }],
  },
  {
    name: "League_progress",
    component: LeagueProgressScreen,
    options: {
      headerShown: false,
    },
    titles: [{ main: {} }],
  },
  {
    name: "skill-set",
    component: MyProgressScreen,
    options: {
      headerShown: true,
      headerTitle: "Mes quêtes",
    },
    titles: [{ main: {} }, { live: {} }],
  },
  {
    name: "shop",
    component: MyPetalsTabScreen,
    options: {
      headerShown: false,
      headerTitle: "Boutique de gestes",
    },
    titles: [
      {
        main: {},
        live: {
          options: {
            headerShown: true,
            headerTitle: "Boutique de gestes",
          },
        },
      },
    ],
  },
  {
    name: "all-activities",
    component: AllActivities,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Toutes les activités",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Toutes les activités",
          },
        },
      },
    ],
  },
  {
    name: "avatar",
    component: CreateAvatarScreen,
    options: {
      headerShown: true,
      headerTitle: "Personnalise ton avatar",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-todo",
    component: NewTodoScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Nouvelle activité",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Nouvelle activité",
          },
        },
      },
    ],
  },
  {
    name: "new-material",
    component: ManageMaterialScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Nouvel énoncé",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Nouvel énoncé",
          },
        },
      },
    ],
  },
  {
    name: "update-material",
    component: ManageMaterialScreen,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Modifier énoncé",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Modifier énoncé",
          },
        },
      },
    ],
  },
  {
    name: "material-details",
    component: MaterialDetails,
    titles: [
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Énoncé",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Énoncé",
          },
        },
      },
    ],
  },
  // {
  //     name: "pause-resume-subscription",
  //     component: SubscribtionSuspension,
  //     options: {
  //         headerShown: false,
  //         headerTitle: "Suspension abonnement"
  //     },
  //     titles: [{"main": {}}, {"auth": {}}]
  // },
  {
    name: "subscription-continuity",
    component: SubscribtionContinuity,
    options: {
      headerShown: false,
      headerTitle: "Suite de l'abonnement",
    },
    titles: [{ main: {} }, { auth: {} }],
  },
  // admin
  {
    name: "analytics",
    component: Analytics,
    options: {
      headerShown: true,
      headerTitle: "Analytics",
    },
    titles: [{ main: {} }],
  },
  {
    name: "admin-live-reports",
    component: LiveReportsScreen,
    options: {
      headerShown: false,
      headerTitle: "Rapports de LIVE",
    },
    titles: [{ main: {} }, { live: {} }, { auth: {} }],
  },
  {
    name: "new-referral-code",
    component: CreateEditReferralCode,
    options: {
      headerShown: false,
      headerTitle: "Nouveau code d'affiliation",
    },
    titles: [{ main: {} }],
  },
  {
    name: "referrer/dashboard/:h",
    component: ReferralCodeScreen,
    options: {
      headerShown: false,
      headerTitle: "Mes affiliations",
    },
    titles: [{ main: {} }, { live: {} }, { auth: {} }],
  },
  {
    name: "admin-users",
    component: UsersTabScreen,
    options: {
      headerShown: false,
      headerTitle: "Liste des utilisateurs",
    },
    titles: [{ main: {} }],
  },
  {
    name: "admin-lives",
    component: LivesScreen,
    options: {
      headerShown: false,
      headerTitle: "Liste des LIVES",
    },
    titles: [{ main: {} }],
  },
  {
    name: "lives-form",
    component: LiveStepper,
    options: {
      headerShown: false,
      headerTitle: "Configurer un LIVE",
    },
    titles: [{ main: {} }],
  },
  {
    name: "live-show",
    component: LiveShow,
    options: {
      headerShown: false,
      headerTitle: "Consulter un LIVE",
    },
    titles: [{ main: {} }],
  },
  {
    name: "add-user-live",
    component: AddUserToLive,
    options: {
      headerShown: false,
      headerTitle: "Ajouter un utilisateur au live",
    },
    titles: [{ main: {} }],
  },
  {
    name: "assign-master-lives",
    component: AssignTutorAndLives,
    options: {
      headerShown: false,
      headerTitle: "Attribuer un élève",
    },
    titles: [{ main: {} }],
  },
  {
    name: "assign-tutor",
    component: AssignTutor,
    options: {
      headerShown: false,
      headerTitle: "Attribuer un Masteur",
    },
    titles: [{ main: {} }],
  },
  {
    name: "change-privileges",
    component: ChangePrivileges,
    options: {
      headerShown: false,
      headerTitle: "Modifier les privilèges",
    },
    titles: [{ main: {} }],
  },
  {
    name: "rooms",
    component: RoomsScreen,
    options: {
      headerShown: false,
      headerTitle: "Liste des LIVES",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-room",
    component: CreateEditRoom,
    options: {
      headerShown: false,
      headerTitle: "Nouvelle salle",
    },
    titles: [{ main: {} }],
  },
  {
    name: "add-members-to-sessions",
    component: AddUsersToSessions,
    options: {
      headerShown: false,
      headerTitle: "Ajouter des membres",
    },
    titles: [{ main: {} }],
  },
  {
    name: "new-sessions",
    component: NewSessions,
    options: {
      headerShown: false,
      headerTitle: "Créer des sessions",
    },
    titles: [{ main: {} }],
  },
  {
    name: "tutors-applications",
    component: TutorsApplicationsTabNavigator,
    options: {
      headerShown: false,
      headerTitle: "Candidature des tuteurs",
    },
    titles: [{ main: {} }],
  },
  {
    name: "application-details",
    component: TutorApplicationDetailsScreen,
    options: {
      headerShown: false,
      headerTitle: "Candidature",
    },
    titles: [{ main: {} }],
  },
  {
    name: "manage-tutors",
    component: TutorsScreen,
    options: {
      headerShown: false,
      headerTitle: "Tuteurs",
    },
    titles: [{ main: {} }],
  },
  {
    name: "update-salary",
    component: FixSalaryScreen,
    options: {
      headerShown: false,
      headerTitle: "Mettre à jour le salaire",
    },
    titles: [{ main: {} }],
  },
  {
    name: "update-max-students-live",
    component: UpdateMaxStudentsPerLive,
    options: {
      headerShown: false,
      headerTitle: "Maximum d'élèves par LIVE",
    },
    titles: [{ main: {} }],
  },
  {
    name: "assign-admin",
    component: AssignAdmin,
    options: {
      headerShown: false,
      headerTitle: "Assigner un admin",
    },
    titles: [{ main: {} }],
  },
  {
    name: "reschedule-live",
    component: RescheduleLive,
    options: {
      headerShown: false,
      headerTitle: "Assigner un admin",
    },
    titles: [{ main: {} }],
  },
  {
    name: "accept-substitution",
    component: AcceptSubstitution,
    options: {
      headerShown: false,
      headerTitle: "Assigner un admin",
    },
    titles: [{ main: {} }],
  },
  {
    name: "quizz",
    component: NewQuizzScreen,
    options: {
      headerShown: false,
      headerTitle: "Lancer un quiz",
    },
    titles: [{ main: {} }, { auth: {} }],
  },
  {
    name: "quizz-confirm",
    component: ConfirmQuizzScreen,
    options: {
      headerShown: false,
      headerTitle: "Lancer un quiz",
    },
    titles: [{ main: {} }, { auth: {} }],
  },
  {
    name: "new-student",
    component: RegisterScreen,
    options: {
      headerShown: false,
      headerTitle: "Créer élève",
    },
    titles: [{ main: {} }],
  },
  {
    name: "offer-essential",
    component: TrialOnboardingScreen,
    options: {
      headerShown: false,
      headerTitle: "Offre Essentielle",
    },
    titles: [{ main: {} }],
  },
  {
    name: "start-trial",
    component: RegisterTrialSessionScreen,
    options: {
      headerShown: false,
      headerTitle: "Essai gratuit",
    },
    titles: [{ main: {} }],
  },
  {
    name: "start-trial/confirm",
    component: RegisterTrialScreen,
    options: {
      headerShown: false,
      headerTitle: "Essai gratuit",
    },
    titles: [{ main: {} }],
  },
  {
    name: "validate-email",
    component: ValidateEmailScreen,
    options: {
      headerShown: false,
      headerTitle: "Validation email",
    },
    titles: [{ main: {} }],
  },
  {
    name: "edit-email",
    component: ChangeEmailStepper,
    options: {
      headerShown: false,
      headerTitle: "Modifier email",
    },
    titles: [{ main: {} }],
  },
  {
    name: "intro-zoe",
    component: ZoeIntroScreen,
    options: {
      headerShown: false,
      headerTitle: "Introduction",
    },
    titles: ["studentFirstChallenge"],
  },
  {
    name: "new-challenge",
    component: NewChallengeScreen,
    options: {
      headerShown: true,
      headerTitle: "Nouveau défi",
    },
    titles: [
      "studentFirstChallenge",
      {
        main: {
          options: {
            headerShown: false,
            headerTitle: "Nouveau défi",
          },
        },
      },
      {
        live: {
          options: {
            headerShown: true,
            headerTitle: "Nouveau défi",
          },
        },
      },
    ],
  },
  {
    name: "presence",
    component: LiveParticipants,
    options: {
      headerShown: true,
      headerTitle: "LIVE",
    },
    titles: [{ live: {} }],
  },
  {
    name: "messages",
    component: LiveChat,
    options: {
      headerShown: true,
      headerTitle: "LIVE Messages",
    },
    titles: [{ live: {} }],
  },
  /*    {
            name: "presentation",
            component: IntroScreen,
            options: {
                headerShown: false,
                headerTitle: "Présentation"
            },
            titles: ["auth"]
        },*/
    {
        name: "account-type",
        component: AccountTypeScreen,
        options: {
            headerShown: false,
            headerTitle: "Créer un compte"
        },
        titles: [{auth: {}}]
    },
    {
        name: "register-tutor",
        component: TutorRegisterScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription"
        },
        titles: [{auth: {}}]
    },
    {
        name: "login",
        component: LoginScreen,
        options: {
            headerShown: false,
            headerTitle: "Connexion"
        },
        titles: [{auth: {}}]
    },
    {
        name: "register",
        component: RegisterNewScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription"
        },
        // deepLinkConfig: {
        //     platform: "web&mobile",
        //     downloadRequired: false,
        //     downloadBanner: false,
        // },
        titles: [{auth: {}}]
    },
    {
        name: "register-trial",
        component: RegisterTrialSessionScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription"
        },
        titles: [
            {auth: {}},
            {
                menu: {
                    component: RegisterReturnScreen
                }
            }
        ]
    },
    {
        name: "register-holidays",
        component: RegisterTrialSessionScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription vacances"
        },
        titles: [
            {auth: {}},
            {
                menu: {
                    component: RegisterReturnScreen
                }
            }
        ]
    },
    {
        name: "register-special",
        component: RegisterSpecialScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription Live Spécial"
        },
        titles: [{auth: {}}, {menu: {}}]
    },
    {
        name: "register-workshop",
        component: RegisterWorkshopScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription Live Atelier"
        },
        titles: [{auth: {}}, {menu: {}}]
    },
    {
        name: "register-trial/confirm",
        component: RegisterTrialScreen,
        options: {
            headerShown: false,
            headerTitle: "Inscription"
        },
        titles: [{auth: {}}]
    },
    {
        name: "reset-password",
        component: ForgotPassword,
        options: {
            headerShown: true,
            headerTitle: "Mot de passe oublié"
        },
        titles: [{auth: {}}]
    },
    {
        name: "password/reset/:passwordToken",
        component: ResetPasswordScreen,
        options: {
            headerShown: false,
            headerTitle: "Nouveau mot de passe"
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "congrats",
        component: CongratsScreen,
        options: {
            headerShown: false,
            headerTitle: "Félicitations",
            cardStyle: {backgroundColor: theme.colors.white}
        },
        titles: [{main: {}}, {live: {}}, {auth: {}}]
    },
    {
        name: "feeling",
        component: ChapterFeelingQuiz,
        options: {
            headerShown: false,
            headerTitle: "Questionnaire",
            cardStyle: {backgroundColor: theme.colors.white}
        },
        titles: [{main: {}}, {live: {}}]
    },
    {
        name: "todo-check",
        component: TodoCheck,
        options: {
            headerShown: false,
            headerTitle: "vérifier todos",
            cardStyle: {backgroundColor: theme.colors.white}
        },
        titles: [{live: {}}]
    },
    {
        name: "intro",
        component: ChampionIntroScreen,
        options: {
            headerShown: false,
            headerTitle: "Introduction"
        },
        titles: [
            {
                main: {}
            },
            {
                live: {
                    options: {
                        headerShown: true,
                        headerTitle: "Introduction"
                    }
                }
            },
            {auth: {}}
        ]
    },
    {
        name: "tutoring-invoices",
        component: TutoringInvoicesScreen,
        options: {
            headerShown: false,
            headerTitle: "Factures"
        },
        titles: [{main: {}}]
    }
];

export const navigationScreens = {
    main
};
