import React, {useContext, useEffect} from 'react';
import {ActivityIndicator, Text, TextInput, TouchableRipple} from "react-native-paper";
import {theme} from "../../theme/theme";
import {useController} from "react-hook-form";
import {Platform, View} from "react-native";
import TextM from "../text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import ErrorTextM from "../text/ErrorTextM";
import {useDispatch, useSelector} from "react-redux";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {DimensionsContext} from "../../utils/mobileUtils";

const TextInputM = React.forwardRef((props, ref) => {

        const {
            password,
            control,
            name,
            errors,
            style,
            labelTop,
            right,
            suggestions,
            onChangeAdditional = () => {
            },
            contentContainerStyle,
            liveCheck,
            liveCheckUrlParams = {},
            liveCheckPreRequest = false,
            searchMode = false,
            disabled = false,
            hidden = false
        } = props

        const {field} = useController({
            control,
            name
        })

        const request = liveCheck?.service ? (useSelector((state) => state.api[liveCheck?.service?.name]?.request) ?? false) : null
        const success = liveCheck?.service ? useSelector((state) => state.api[liveCheck?.service?.name]?.success) : null
        const error = liveCheck?.service ? useSelector((state) => state.api[liveCheck?.service?.name]?.error) : null
        const successData = liveCheck?.service ? useSelector((state) => state.api[liveCheck?.service?.name]?.data) : null
        const dispatch = useDispatch();


        useEffect(() => {
            if (success && !!liveCheck?.successCallback) {
                liveCheck?.successCallback(successData)
            }
            if (error && !!liveCheck?.errorCallback) {
                liveCheck?.errorCallback(error)
            }

        }, [success, error])

        useEffect(() => {
            const delayDebounceFn = liveCheck?.service ? setTimeout(() => {

                const apiRequestFonction = liveCheckPreRequest ? apiRequestWithPrerequest : apiRequest
                // console.log(fieldPromoCode?.value)
                if (field?.value !== "")
                    dispatch(apiRequestFonction(liveCheck?.service, {}, {
                        ...liveCheckUrlParams,
                        [name]: field?.value
                    }, {...liveCheckUrlParams, [name]: field?.value}))
                else {
                    dispatch(apiIdle(liveCheck?.service))
                }
            }, 1000) : null

            return liveCheck?.service ? () => clearTimeout(delayDebounceFn) : undefined
        }, [field?.value])

        //for users searching (get users when field is empty)
        useEffect(() => {
            const apiRequestFonction = liveCheckPreRequest ? apiRequestWithPrerequest : apiRequest

            const delayDebounceFn = liveCheck?.service ? setTimeout(() => {
                // console.log(fieldPromoCode?.value)
                if (searchMode && field?.value === "")
                    dispatch(apiRequestFonction(liveCheck?.service))
            }, 1000) : null

            return liveCheck?.service ? () => clearTimeout(delayDebounceFn) : undefined
        }, [field?.value])

        const [secure, setSecure] = React.useState(true);

        const dimensions = useContext(DimensionsContext)
        return <View style={{
            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
            alignItems: "center",
            justifyContent: "center",
            alignSelf: Platform.OS !== 'web' ? null : 'center',
            ...contentContainerStyle
        }}>

            {suggestions?.map((c, index) => {
                return <TouchableRipple key={index}
                                        style={{
                                            width: "90%",
                                            flexWrap: "wrap",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            paddingHorizontal: 10,
                                            marginBottom: 5,
                                            paddingVertical: 5,
                                            borderRadius: 20,
                                            borderWidth: 1,
                                            borderColor: theme.colors.lightMore
                                        }}
                                        onPress={() => {
                                            field?.onChange(field.value + c)
                                        }}>
                    <TextM wrap>{c}</TextM>
                </TouchableRipple>
            })}

            {labelTop && <TextM style={{alignSelf: "flex-start", marginBottom: -5}}>{labelTop}</TextM>}
            {!hidden && <View style={{
                width: "100%",
            }}>
                <TextInput
                    {...props}
                    ref={ref}
                    selectionColor={theme.colors.light}
                    disabled={disabled}
                    mode="outlined"
                    // underlineColor={theme.colors.light}
                    // activeUnderlineColor={theme.colors.light}
                    outlineColor={theme.colors.light}
                    activeOutlineColor={theme.colors.light}
                    style={{
                        fontSize: 16,
                        width: "100%",
                        fontFamily: "Montserrat",
                        marginTop: 5,
                        // backgroundColor: theme.colors.white,
                        ...style
                    }}
                    outlineStyle={{
                        ...props.outlineStyle
                    }}
                    contentStyle={{
                        ...props.contentStyle
                    }}
                    value={field?.value}
                    onChangeText={(value) => {
                        field?.onChange(value)
                        onChangeAdditional(value)
                    }}
                    secureTextEntry={password && secure}
                    right={right ?? (password && <TextInput.Icon color={() => theme.colors.primary}
                                                                 style={{
                                                                     color: theme.colors.primary
                                                                 }}
                                                                 icon={secure ? "eye" : "eye-slash"}
                                                                 onPress={() => {
                                                                     setSecure(!secure)
                                                                 }}/>)}
                    error={errors && errors[name]}
                />
                {!!liveCheck?.service && <>
                    <View style={{position: "absolute", right: 15, top: 20}}>
                        {request
                            ? <ActivityIndicator animating={true}
                                                 color={theme.colors.primary}/>
                            : success
                                ? <FontAwesome5 name="check" size={26} color={theme.colors.success}/>
                                : error
                                    ? <FontAwesome5 name="times" size={26} color={theme.colors.error}/>
                                    : null}
                    </View>
                    {error
                        ? <ErrorTextM errorData={error} style={{textAlign: "center"}} marginTop={3}/>
                        : (success && !!liveCheck?.successMessage)
                            ? <TextM color={theme.colors.success}
                                     style={{marginTop: 5}}>{liveCheck?.successMessage}</TextM>
                            : null}
                </>}
            </View>}

            {errors && errors[name] && <Text style={{
                color: theme.colors.error,
                textAlign: "center"
            }}>{errors[name].message}</Text>}
        </View>
    }
)

export default TextInputM