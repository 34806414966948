import React from 'react';
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextInputM from "@common/components/input/TextInputM";
import {Keyboard, ScrollView, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {SelectInputM} from "../../../student/account/onboarding/SelectInputM";
import {skillSetIds} from "@data/constants/formConstants";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {TutorProfileCardHeader} from "../../../student/account/onboarding/StartTrialNewScreen";
import {validation} from "@data/utility/validation";
import TextM from "@common/components/text/TextM";

const TutorProfilePersonalizer = (props) => {
    const {control, goToNextStep, register, errors, serverError, setFormValues, field} = props

    // useEffect(() => {
    //     console.log(control._formValues)
    // }, [control._formValues]);


    const dimensions = React.useContext(DimensionsContext);

    return <>
        <ScrollView style={{paddingTop: 0}}>
            <TextInputM
                {...register("study_institution", {...validation.required()})}
                labelTop={'Etablissement d\'études'}
                placeholder={'Université Paris-Saclay'}
                // autofocus={index === 0}
                onChangeAdditional={(value) => {
                    setFormValues({
                        ...control._formValues,
                        study_institution: value
                    })
                }}
                onSubmitEditing={() => {
                    Keyboard.dismiss()
                }}
                returnKeyType={"done"}
                contentContainerStyle={{
                    width: IS_WEB || dimensions?.width > 800 ? dimensions?.width * 3 / 5 : "100%",
                    marginBottom: 10
                }}
                // name={i?.name}
                control={control}
                errors={errors}
            />
            <TextInputM
                {...register("study_field", {...validation.required()})}
                labelTop={'Filière d\'étude'}
                placeholder={'Licence de commerce international'}
                // autofocus={index === 0}
                onChangeAdditional={(value) => {
                    setFormValues({
                        ...control._formValues,
                        study_field: value
                    })
                    // console.log(control._formValues)
                }}
                onSubmitEditing={() => {
                    Keyboard.dismiss()
                }}
                returnKeyType={"done"}
                contentContainerStyle={{
                    width: IS_WEB || dimensions?.width > 800 ? dimensions?.width * 3 / 5 : "100%",
                    marginBottom: 10
                }}
                // name={i?.name}
                control={control}
                errors={errors}
            />

            <SelectInputM {...register("tutor_skill_set_title_id", {...validation.required()})}
                          onSelect={(value) => {
                              setFormValues({
                                  ...control._formValues,
                                  tutor_skill_set_title_id: value
                              })
                              // console.log(field?.value)
                          }}
                          label="Choisis ton slogan"
                          options={skillSetIds}
                          style={{
                              marginTop: 10,
                              width: IS_WEB || dimensions?.width > 800 ? dimensions?.width * 3 / 5 : "100%",
                              alignSelf: "center"
                          }}
                          value={control._formValues?.tutor_skill_set_title_id}
                          control={control}

            />

            <TextM style={{marginTop: 40, marginBottom: 10}}>Tu apparaîtras de cette façon :</TextM>
            <View style={{backgroundColor: theme.colors.white, paddingTop: 20, borderRadius: 15}}>
                <TutorProfileCardHeader tutor={props.user} editableAvatar={true} selectedData={control._formValues}/>
            </View>

        </ScrollView>

        <FilledButtonM onPress={() => {
            goToNextStep()
        }}
                       label={"Continuer"}
                       color={theme.colors.primary}
                       style={{
                           width: "100%",
                           alignSelf: "center",
                           marginTop: 30,
                           position: "absolute",
                           bottom: 0
                       }}
        />


    </>

};

const mapStateToProps = state => {
    return {
        user: state.data.currentUser.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(TutorProfilePersonalizer)