import {Badge} from "react-native-paper";
import {theme} from "../../theme/theme";
import React from "react";

export function BadgeM({count, style, size = null}) {

    if (!!size)
        return <Badge style={{
            backgroundColor: theme.colors.white,
            color: theme.colors.link,
            fontFamily: "Montserrat-Bold",
            alignSelf: "center",
            ...style
        }}
                      size={size}
        >{count}</Badge>;
    else
        return <Badge style={{
            backgroundColor: theme.colors.white,
            color: theme.colors.link,
            fontFamily: "Montserrat-Bold",
            alignSelf: "center",
            ...style
        }}
        >{count}</Badge>;
}