import React, {useContext, useEffect, useRef, useState} from 'react';
import {Animated, FlatList, Image, Platform, StyleSheet, View} from "react-native";
import logo from '../../assets/logo-blue.png'
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {StatusBar} from "expo-status-bar";
import * as amplitude from "@amplitude/analytics-react-native";
import {useNavigation} from "@react-navigation/native";
import appScreen from "../../assets/screens/app_screen.png";
import liveScreen from "../../assets/screens/live_screen_.png";
import cycleScreen from "../../assets/screens/cycle_screen.png";
import successesScreen from "../../assets/screens/quests_screen.png";
import {Slide} from "../student/account/IntroScreen";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {IconButton} from "react-native-paper";
import ExpandingDot from "@common/animations/ExpandingDot";
import {useInterval} from "@common/utils/useInterval";
import LinkTextM from "@common/components/text/LinkTextM";


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        // paddingTop: 30,
        // paddingHorizontal: 15,
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    buttonsContainer: {
        width: "100%",
        // marginTop: 30,
        paddingTop: 10,
        paddingBottom: 20,
        // marginBottom: 20,
        alignItems: "center"
    },
    button: {
        marginBottom: 10,
        marginHorizontal: 10
    },
    pagerView: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden"
        // backgroundColor: '#000'
    }
});

export const SliderNavigationM = (props) => {

    const {style, currentIndex, flatListRef, scrollX, data, withDots = false} = props

    const dimensions = useContext(DimensionsContext)

    return <View style={{
        width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 2,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        ...style
    }}>
        <IconButton
            size={20}
            icon={"angle-left"}
            disabled={currentIndex === 0}
            iconColor={theme.colors.primary}
            onPress={() => {
                if (currentIndex > 0) flatListRef?.current?.scrollToIndex({index: currentIndex - 1})
            }}
        />

        {withDots && <ExpandingDot
            data={data}
            scrollX={scrollX}
            expandingDotWidth={30}
            inActiveDotOpacity={0.6}
            flatListWidth={dimensions?.width}
            activeDotColor={theme.colors.primary}
            dotStyle={{
                width: 10,
                height: 10,
                backgroundColor: "#347af0",
                borderRadius: 5,
                marginHorizontal: 5
            }}
            containerStyle={{
                position: "relative",
                bottom: null
                // top: 15,
                // alignSelf: "center"
            }}
        />}

        <IconButton
            size={20}
            icon={"angle-right"}
            disabled={currentIndex === data?.length - 1}
            iconColor={theme.colors.primary}
            onPress={() => {
                if (currentIndex < data?.length - 1) flatListRef?.current?.scrollToIndex({index: currentIndex + 1})
            }}
        />
    </View>
}

export const AuthNavigator = ({navigation}) => {

    const keyExtractor = (item, index) => index.toString()
    const SLIDER_DATA = [
        {
            // backgroundColor: theme.colors.accentDark,
            image: <Slide title="L'appli idéale pour développer de bonnes habitudes de travail"
                          image={appScreen}/>,
            title: "",
            subtitle: ""
        },
        {
            // backgroundColor: theme.colors.link,
            image: <Slide title="Un cycle d'apprentissage simple et efficace"
                          image={cycleScreen}/>,
            title: "",
            subtitle: ""
        },
        {
            // backgroundColor: theme.colors.accentDark,
            image: <Slide title="Des sessions d'étude pour travailler efficacement"
                // product={"Premium"}
                          image={liveScreen}/>,
            title: "",
            subtitle: ""
        },
        {
            // backgroundColor: theme.colors.link,
            image: <Slide title="Des quêtes stimulantes pour valider ses compétences"
                          image={successesScreen}/>,
            title: "",
            subtitle: ""
        }
        // {
        //     backgroundColor: theme.colors.link,
        //     image: <Slide title="Tes efforts permettent de financer des gestes éco-solidaires !"
        //                   image={gesturesScreen}/>,
        //     title: "",
        //     subtitle: ""
        // }
    ]

    const [currentIndex, setCurrentIndex] = useState(0)
    const flatListRef = useRef()
    const onViewableItemsChanged = ({viewableItems, changed}) => {
        setCurrentIndex(viewableItems?.[0]?.index)
        setTimeSinceLastScroll(0)
    }
    const viewabilityConfig = {waitForInteraction: false, viewAreaCoveragePercentThreshold: 95}
    const viewabilityConfigCallbackPairs = useRef([{viewabilityConfig, onViewableItemsChanged}])

    const scrollX = React.useRef(new Animated.Value(0)).current;

    const dimensions = useContext(DimensionsContext)
    const nav = useNavigation()
    const insets = useSafeAreaInsets();

    const [timeSinceLastScroll, setTimeSinceLastScroll] = useState(0)

    useInterval(() => {
        if (timeSinceLastScroll >= 5) {
            const newIndex = (currentIndex + 1) % SLIDER_DATA?.length
            flatListRef?.current?.scrollToIndex({index: newIndex})
        }
        setTimeSinceLastScroll(timeSinceLastScroll + 1)
    }, currentIndex !== undefined ? 1000 : null)

    return (
        <View style={styles.container}>
            <View style={styles.pagerView}>
                <View style={{
                    marginBottom: 0,
                    marginTop: insets.top + 10,
                    alignItems: "center",
                    alignSelf: 'center'
                }}>
                    <Image style={{
                        width: 150,
                        height: 50
                    }}
                           resizeMode="contain"
                           source={logo}
                    />
                </View>

                {/*<TextM fontSize={16}*/}
                {/*       fontWeight="SemiBold"*/}
                {/*       color={theme.colors.primary}*/}
                {/*       style={{textAlign: "center", marginHorizontal: 10}} wrap>La bonne méthode pour réussir.</TextM>*/}

                <FlatList
                    data={SLIDER_DATA}
                    ref={flatListRef}
                    style={{
                        width: dimensions?.width,
                        bottom: -20
                    }}
                    contentContainerStyle={{
                        // width: dimensions?.width,
                        paddingTop: 20
                    }}
                    keyExtractor={keyExtractor}
                    showsHorizontalScrollIndicator={false}
                    onScroll={Animated.event(
                        [{nativeEvent: {contentOffset: {x: scrollX}}}],
                        {
                            useNativeDriver: false
                        }
                    )}
                    pagingEnabled
                    horizontal
                    decelerationRate={'normal'}
                    scrollEventThrottle={16}
                    getItemLayout={(data, index) => (
                        {length: dimensions?.width, offset: dimensions?.width * index, index}
                    )}
                    viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
                    renderItem={item => item.item?.image}
                />
            </View>
            <SliderNavigationM style={null}
                               currentIndex={currentIndex}
                               flatListRef={flatListRef}
                               scrollX={scrollX}
                               data={SLIDER_DATA}
                               withDots
            />
            <View style={styles.buttonsContainer}>

                <FilledButtonM
                    label="C'est parti !"
                    mode="outlined"
                    onPress={() => {
                        navigation.push("register-trial")
                        /*  navigation.push("intro", {
                              name: "tina",
                              nextScreen: "register",
                              nextScreenParams: {}
                          })*/
                    }}
                />
                <FilledButtonM
                    onPress={() => {
                        nav.push('login')
                        // nav.push('subscription-continuity', {
                        //     user: "YXViaW4uYmFlbnNjaEBnbWFpbC5jb20="
                        // })
                    }}
                    label="J'ai déjà un compte"
                />
                <LinkTextM
                    onPress={() => {
                        nav.push('register')
                    }}
                    style={{fontSize: 12, fontFamily: "Montserrat-Bold"}}
                >DEVENIR TUTEUR</LinkTextM>
            </View>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}