import { IconButton, TouchableRipple } from "react-native-paper";
import { View } from "react-native";
import { theme } from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import { BadgeM } from "@common/components/badge/BadgeM";
import React, { useContext } from "react";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";

export const IconTouchableRipple = ({
  icon,
  iconSize = null,
  onPress,
  label,
  badgeCounter,
  style,
  disabled = false,
  color = theme.colors.greyAlt,
  horizontal = false,
  fontSize = null,
}) => {
  const dimensions = useContext(DimensionsContext);
  return (
    <TouchableRipple
      style={{
        alignItems: IS_WEB && dimensions.width > 800 ? "center" : "flex-start",
        flex: 1,
        ...style,
      }}
      disabled={disabled}
      onPress={onPress}
    >
      <View
        style={{
          marginBottom: 6,
          flex: 1,
          flexDirection: horizontal ? "row" : "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <IconButton
          size={iconSize ?? 14}
          icon={icon}
          style={{
            // position: "absolute",
            // top: -10,
            color,
          }}
          iconColor={color}
        />
        <TextM
          style={{ marginTop: horizontal ? 0 : -6, textAlign: "center" }}
          fontSize={fontSize ? fontSize : IS_WEB ? 16 : 12}
          fontWeight={"Regular"}
          color={color}
          wrap
        >
          {label}
        </TextM>
        {badgeCounter > 0 && (
          <BadgeM
            count={badgeCounter}
            style={{
              backgroundColor: theme.colors.error,
              color: theme.colors.white,
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        )}
      </View>
    </TouchableRipple>
  );
};
