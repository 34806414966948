import React, {useContext} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ModalM} from "@common/components/modal/ModalM";
import {ScrollView, View} from "react-native";
import {ListItemM} from "@common/components/list/ListItemM";
import {ListGroupM} from "@common/components/list/ListGroupM";
import TitleM from "@common/components/text/TitleM";
import {theme} from "@common/theme/theme";

export default function RemunerationModal({
                                              isOpen,
                                              handleShowModal,
                                              navigation,
                                              control,
                                              index,
                                              data,
                                              setRemuneration
                                          }) {
    const dimensions = useContext(DimensionsContext)
    return (
        <ModalM visible={isOpen} onDismiss={handleShowModal}
                style={{width: IS_WEB && dimensions.width > 800 ? "50%" : "90%", maxHeight: "80%"}}>
            <ScrollView style={{
                marginTop: 10,
                backgroundColor: theme.colors.white,
                paddingHorizontal: 20,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10
            }}
                        contentContainerStyle={{
                            flexDirection: IS_WEB && dimensions.width > 800 ? "row" : "column",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            paddingBottom: 30
                        }}>
                <TitleM>Sélectionner un salaire initial</TitleM>
                <View style={{
                    marginBottom: 10,
                    marginTop: 20,
                    width: "100%",
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <ListGroupM options={data}
                                style={{
                                    // marginHorizontal: 20
                                    marginBottom: 70
                                }}
                                itemHeight={70}
                                renderItem={(item) => {
                                    return <ListItemM isFirst={(item.index === 0)}
                                                      isLast={(item.index === data?.length - 1)}
                                                      onPress={() => {
                                                          handleShowModal()
                                                          setRemuneration(item.item?.value)
                                                      }}
                                                      itemHeight={70}
                                        //selected={item.item?.value === field.value}
                                                      option={item.item}
                                                      disabled={item.item?.disabled}
                                    />
                                }}
                    />
                </View>
            </ScrollView>
        </ModalM>
    )
}