import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";

export const SuccessBadge = (props) => {

    const {success, level, style, iconWidth = 80} = props

    const borderWidth = 8
    const color = success?.color ? success?.color : theme.colors.primary

    const width = iconWidth * 1.4
    const height = iconWidth * 1.4 * 1.20

    return <View style={{
        ...style,
        backgroundColor: color,
        borderRadius: 10,
        width: width,
        height: height
    }}>
        <View style={{
            width: width,
            height: width,
            backgroundColor: "white",
            borderColor: color,
            alignItems: "center",
            justifyContent: "center"
            // position: "absolute",
            // top: 0
            // right: -1
        }}>
            <IconImageM
                // uri={challenge?.school_subject?.icon}
                uri={success?.icon}
                width={iconWidth*0.8}
                style={{}}/>
        </View>
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            marginTop: -3,
        }}>
            <TextM fontWeight="Black"
                   fontSize={iconWidth * 0.2}
                   color="white">
                {success?.name}
            </TextM>
        </View>


    </View>
}