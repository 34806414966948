import {TouchableOpacity, View} from "react-native";
import {PetalCounter, SkillSetTitleFull, XPCount} from "../components/statistics/Counters";
import {theme} from "../theme/theme";
import {PremiumCoach, PremiumPlus} from "../components/icons/IconsM";
import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {useNavigation} from "@react-navigation/native";
import TextM from "../components/text/TextM";
import AvatarM from "../components/avatar/AvatarM";
import {BadgeM} from "../components/badge/BadgeM";
import {getPrivilegedRole, hasRole} from "@data/utility/ability";
import {TourGuideZone} from "rn-tourguide";
import {IconButton} from "react-native-paper";
import {ProductIcon} from "../../student/account/subscription/MySubscriptionScreen";

const StudentMainMenuHeader = (props) => {


    const {studentId, anonymous = false} = props

    const navigation = useNavigation()


    const ability = useContext(AbilityContext)

    function onAvatarPress() {
        navigation.push('account');
    }

    function onPetalPress() {
        navigation.push('shop');
    }

    const user = !studentId ? props.user : props.students?.find(x => x?.id === parseInt(studentId))
    const count = (user?.profile_completion < 100 ? 1 : 0) + (!user?.is_verified ? 1 : 0)

    return <View
        style={{
            width: "100%",
            height: 50,

        }}>
        {!!user?.champion_intros_viewed_at?.zoe_menus && <TourGuideZone
            zone={0}
            width={0}
            height={0}
            isTourGuide
            style={{}}
            maskOffset={0}
            shape="circle"
            tooltipBottomOffset={200}
            text={'Hello ! Il y a quelques nouveautés dans l\'appli, on fait un tour rapide ?'}
            borderRadius={30}
        />}
        <View style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingHorizontal: studentId ? 0 : 10,
            width: "100%",
            height: 50,
        }}>

            {!studentId ? <TourGuideZone
                zone={4}
                shape={'circle'}
                text={'Les pétales sont des crédits qui te permettent d\'acheter des gestes dans la Boutique'}
            >
                <TouchableOpacity onPress={onPetalPress}
                                  disabled={!!studentId}
                                  style={{
                                      padding: 2
                                  }}>
                    <PetalCounter count={user?.petals} style={{height: 46, marginHorizontal: 4}}/>
                </TouchableOpacity>
            </TourGuideZone> : <IconButton
                icon={"arrow-left"}
                iconColor={theme.colors.primary}
                size={32}
                onPress={() => {
                    if (navigation.canGoBack()) {
                        navigation.goBack()
                    } else {
                        navigation.replace("menu")
                    }
                }}
            />}
            <TourGuideZone
                zone={5}
                text={'Ici, tu peux personnaliser ton titre et ton avatar, et modifier tes paramètres'}
                borderRadius={16}
            >
                <TouchableOpacity style={{
                    padding: 2
                }}
                                  disabled={!!studentId}
                                  onPress={onAvatarPress}
                >

                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        minWidth: 230,
                        // maxWidth: 340,
                        // backgroundColor: "red"
                        // width: "100%"
                    }}>
                        {/*<FeatherCounter count={props.user?.feathers}/>*/}


                        <XPCount count={user?.xp} style={{marginRight: 5}}/>
                        {/*<SuccessXpBar*/}
                        {/*// count={50}*/}
                        {/*small={!!student}*/}
                        {/*count={xpAchievement?.current_count}*/}
                        {/*countTotal={xpAchievement?.next_level_sum}*/}
                        {/*level={xpAchievement?.level}/>*/}
                        <SkillSetTitleFull title={user?.student_title}
                                           icon={user?.student_title_icon}
                                           skillSetId={user?.title_skill_set_id}/>
                        {/*<SuccessXpBar*/}
                        {/*// count={50}*/}
                        {/*small={!!student}*/}
                        {/*count={xpAchievement?.current_count}*/}
                        {/*countTotal={xpAchievement?.next_level_sum}*/}
                        {/*level={xpAchievement?.level}/>*/}

                        {!anonymous && <View style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start"
                        }}>
                            <AvatarM user={user} size={50}/>
                            {studentId && <TextM fontWeight="SemiBold"
                                // wrap
                                // numberOfLines={2}
                                                 style={{textAlign: "right", marginLeft: 4}}
                                                 color={theme.colors.primary}> {user?.display_name}</TextM>}
                            <ProductIcon style={{
                                position: "absolute",
                                right: -4,
                                bottom: 0
                            }}/>
                            {count > 0 && (getPrivilegedRole(props.user) !== "basic") &&
                                <BadgeM count={count}
                                        style={{
                                            backgroundColor: theme.colors.error,
                                            color: theme.colors.white,
                                            position: "absolute",
                                            top: -2,
                                            right: -2
                                        }}/>}
                        </View>}


                        {/*{props?.user?.profile_completion < 100 && <Badge size={12}*/}
                        {/*                                                 style={{*/}
                        {/*                                                     position: "absolute",*/}
                        {/*                                                     backgroundColor: theme.colors.error,*/}
                        {/*                                                     top: -2,*/}
                        {/*                                                     right: -2*/}
                        {/*                                                 }}*/}
                        {/*/>}*/}


                    </View>
                </TouchableOpacity>
            </TourGuideZone>

        </View>
    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        successes: state.data.staticData?.successes
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentMainMenuHeader)
