import {Platform, View} from "react-native";
import React, {useContext, useEffect} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import {header} from "../../tutor/home/InterviewHeader";
import {IconImageM} from "@common/components/icons/IconsM";
import {schoolsService} from "@data/services/schoolsService";
import {Tabs, TabScreen} from "react-native-paper-tabs";
import ChallengesTab from "./ClassroomChallengesTab";
import ClassroomSettingsTab from "./ClassroomSettingsTab";
import ClassroomStudentsTab from "./ClassroomStudentsTab";

function ClassroomScreen(props) {

    const dimensions = useContext(DimensionsContext)
    const classrooms = props.user?.classrooms
    const classroom = props.route?.params.classroomId ? findItemInListWithId(parseInt(props.route?.params?.classroomId), classrooms) : null
    const navigation = useNavigation()
    const schoolSubject = findItemInListWithId(classroom?.school_subject_id, props.schoolSubjects)

    const tabs = [
        {
            name: "Élèves",
            component: ClassroomStudentsTab
        }, {
            name: "Défis",
            component: ChallengesTab
        }, {
            name: "Réglages",
            component: ClassroomSettingsTab
        },
    ]
    useEffect(() => {
        props.apiRequest(schoolsService.getClassroom, {id: props.route?.params?.classroomId})
    }, [])

    return (
        <>

            {header(<View style={{
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <IconImageM uri={schoolSubject?.icon} width={50}/>
                <View style={{
                    paddingHorizontal: 10,
                    alignItems: 'flex-start'
                }}>
                    <TextM fontWeight={"Bold"} fontSize={20}>{classroom?.name}</TextM>
                    <TextM wrap>{classroom?.teacher_display_name}</TextM>
                </View>

            </View>, () => {
                navigation.goBack()
            })}

            <Tabs
                defaultIndex={0}
                style={{
                    backgroundColor: theme.colors.white,
                    width: Platform.OS === 'web' ? dimensions.width * 3 / 2 : null,
                    alignSelf: Platform.OS !== 'web' ? null : "center",
                }}
                disableSwipe={true}
            >
                {tabs.map((tab1) => {
                    return (
                        <TabScreen
                            label={tab1.name}
                            key={tab1.name}
                        >
                            {React.createElement(tab1.component, {
                                classroom: classroom
                            })}
                        </TabScreen>
                    );
                })}
            </Tabs>

            {IS_WEB && <FilledButtonM mode={"text"}
                                      icon={"chevron-left"}
                                      color={theme.colors.link}
                                      style={{marginTop: 60}}
                                      onPress={() => {
                                          if (navigation.canGoBack())
                                              navigation.goBack()
                                          else
                                              navigation.replace("menu")
                                      }}
                                      label={"Retour"}/>}
            {/*  <ClassroomInviteModal handleShowModal={} isOpen={} classroom={classroom}/>*/}
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        schoolSubjects: state.data.staticData?.school_subjects

    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomScreen)