import {IconImageM} from "@common/components/icons/IconsM";
import home from "../../assets/icons/menus-new/accueil.png";
import homeG from "../../assets/icons/menus-new/accueil-gris.png";
import target from "../../assets/icons/menus-new/travaux-faits.png";
import targetG from "../../assets/icons/menus-new/travaux-faits-gris.png";
import RankingTabScreen from "./social/RankingTabScreen";
import ranking from "../../assets/icons/menus-new/classement.png";
import rankingG from "../../assets/icons/menus-new/classement-gris.png";
import MyCoachTabScreen from "./tutoring/MyCoachTabScreen";
import liveChat from "../../assets/icons/menus-new/prof.png";
import liveChatG from "../../assets/icons/menus-new/prof-gris.png";
import rise from "../../assets/icons/menus-new/quete.png";
import riseG from "../../assets/icons/menus-new/quete-gris.png";
import React, {useEffect} from "react";
import {isWebConfVisibleForStudent} from "@data/utility/dataUtils";
import {TourGuideZone} from "rn-tourguide";
import {Platform} from "react-native";
import * as Notifications from "expo-notifications";
import {useDispatch} from "react-redux";
import {incrementTutorMessengerNotReadCount} from "@data/redux/actions/data";
import MySkillSetsTabScreen from "./progress/my progress/MySkillSetsTabScreen";
import moment from "moment";
import TodoListScreen from "./activity/todo/TodoListScreen";
import RealizationsTabScreen from "./organize/list/RealizationsTabScreen";
import {findClosestNextOccurrence} from "./account/onboarding/LiveRoomCard";

export const studentTabScreens = (student, activityTemplates) => {

    const iconSize = 56
    const tourGuideZoneSize = 74

    const countLateActivities = 0
    const countNotReadReviews = student?.not_read_reviews_count
    const dispatch = useDispatch();
    useEffect(() => {
        const subscription = Notifications.addNotificationReceivedListener(notification => {
            if (notification.request.content.data.path === "messages") {
                dispatch(incrementTutorMessengerNotReadCount())
            }
        });
        return () => subscription.remove();
    }, [])

    const countMyTutorNotifications = (isWebConfVisibleForStudent(student) ? 1 : 0)
        + student?.tutor_messenger_not_read_count
        + student?.live_sessions?.filter(liveSession => {


            const closestNextOccurrence = findClosestNextOccurrence([liveSession])

            const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null

            if (!liveMoment) return false

            return moment()?.isBetween(liveMoment?.clone()?.subtract(15, "minutes"), liveMoment?.clone()?.add(45, "minutes"))

            // return liveMoment?.isSame(moment(), "day")
        })?.length

    const mt = (Platform.OS === "ios" ? 0 : 40)


    return [
        {
            name: "home",
            component: TodoListScreen,
            options: {
                headerTitle: "Activités",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => <TourGuideZone
                    zone={1}
                    width={tourGuideZoneSize}
                    height={tourGuideZoneSize}
                    isTourGuide
                    style={{marginTop: mt}}
                    maskOffset={10 + mt / 2}
                    shape="circle"
                    tooltipBottomOffset={200}
                    text={'La page d\'accueil te permet de lancer une nouvelle activité'}
                    borderRadius={30}
                >{status?.focused
                    ? <IconImageM style={{marginTop: -mt}} width={iconSize}
                                  source={home}/> :
                    <IconImageM style={{marginTop: -mt, opacity: 0.5}} width={iconSize}
                                source={homeG}/>}</TourGuideZone>,
                tabBarBadge: countLateActivities > 0 ? countLateActivities : null,
            }
        },
        {
            name: "finished-activities",
            component: RealizationsTabScreen,
            options: {
                headerTitle: "Activités réalisées",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => <TourGuideZone
                    zone={10}
                    width={tourGuideZoneSize}
                    height={tourGuideZoneSize}
                    isTourGuide
                    style={{marginTop: mt}}
                    maskOffset={10 + mt / 2}
                    shape="circle"
                    tooltipBottomOffset={200}
                    text={'Ici, tu pourras consulter tes activités terminées'}
                    borderRadius={30}
                >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={target}/> :
                    <IconImageM style={{marginTop: -mt, opacity: 0.5}} width={iconSize} source={targetG}/>}
                </TourGuideZone>,
                tabBarBadge: countNotReadReviews > 0 ? countNotReadReviews : null
            }
        },

        // {
        //     name: "Classement",
        //     component: RankupScreen,
        //     options: {
        //         tabBarIcon: (status) => <TourGuideZone
        //             zone={11}
        //             width={tourGuideZoneSize}
        //             height={tourGuideZoneSize}
        //             isTourGuide
        //             style={{marginTop: mt}}
        //             maskOffset={10 + mt / 2}
        //             shape="circle"
        //             tooltipBottomOffset={200}
        //             text={'Ici, tu pourras consulter ton classement pour rivaliser avec tes amis'}
        //             borderRadius={30}
        //         >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={ranking}/> :
        //             <IconImageM style={{marginTop: -mt}} width={iconSize} source={rankingG}/>}</TourGuideZone>
        //         // tabBarBadge: true,
        //     }
        // },
        // {
        //     name: "League_progress",
        //     component: LeagueProgressScreen,
        //     options: {
        //         tabBarIcon: (status) => <TourGuideZone
        //             zone={11}
        //             width={tourGuideZoneSize}
        //             height={tourGuideZoneSize}
        //             isTourGuide
        //             style={{marginTop: mt}}
        //             maskOffset={10 + mt / 2}
        //             shape="circle"
        //             tooltipBottomOffset={200}
        //             text={'Ici, tu pourras consulter ton classement pour rivaliser avec tes amis'}
        //             borderRadius={30}
        //         >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={ranking}/> :
        //             <IconImageM style={{marginTop: -mt}} width={iconSize} source={rankingG}/>}</TourGuideZone>
        //         // tabBarBadge: true,
        //     }
        // }
        /*        {
                    name: "My_progress",
                    component: MyProgress,
                    options: {
                        tabBarIcon: (status) => <TourGuideZone
                            zone={11}
                            width={tourGuideZoneSize}
                            height={tourGuideZoneSize}
                            isTourGuide
                            style={{marginTop: mt}}
                            maskOffset={10 + mt / 2}
                            shape="circle"
                            tooltipBottomOffset={200}
                            text={'Ici, tu pourras consulter ton classement pour rivaliser avec tes amis'}
                            borderRadius={30}
                        >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={ranking}/> :
                            <IconImageM style={{marginTop: -mt}} width={iconSize} source={rankingG}/>}</TourGuideZone>
                        // tabBarBadge: true,
                    }
                },*/
        {
            name: "skill-sets",
            component: MySkillSetsTabScreen,
            options: {
                headerTitle: "Mes quêtes",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => <TourGuideZone zone={10}
                                                       width={tourGuideZoneSize}
                                                       height={tourGuideZoneSize}
                                                       isTourGuide
                                                       style={{
                                                           marginTop: mt,
                                                       }}
                                                       maskOffset={10 + mt / 2}
                                                       shape="circle"
                                                       tooltipBottomOffset={200}
                                                       text={'Ici, tu trouveras tes quêtes, pour progresser et gagner des récompenses ! ' + (student?.champion_intros_viewed_at?.zoe_menus ? "(les quêtes remplacent les succès de l'ancienne version)" : "")}
                    // text={'La Boutique te permettra de valoriser tes efforts en finançant des gestes écologique et responsable'}
                                                       borderRadius={30}
                >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={rise}/> :
                    <IconImageM style={{marginTop: -mt, opacity: 0.5}} width={iconSize}
                                source={riseG}/>}</TourGuideZone>
                // tabBarBadge: true,
            }
        },
        {
            name: "rankings",
            component: RankingTabScreen,
            options: {
                headerTitle: "Classement",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => <TourGuideZone zone={10}
                                                       width={tourGuideZoneSize}
                                                       height={tourGuideZoneSize}
                                                       isTourGuide
                                                       style={{
                                                           marginTop: mt,
                                                       }}
                                                       maskOffset={10 + mt / 2}
                                                       shape="circle"
                                                       tooltipBottomOffset={200}
                                                       text={'Ici, tu pourras consulter ton classement pour rivaliser avec tes amis'}
                                                       borderRadius={30}
                >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={ranking}/> :
                    <IconImageM style={{marginTop: -mt, opacity: 0.5}} width={iconSize}
                                source={rankingG}/>}</TourGuideZone>
                // tabBarBadge: true,
            }
        },
        {
            name: "tutor",
            component: MyCoachTabScreen,
            options: {
                headerTitle: "Mon masteur",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => <TourGuideZone zone={10}
                                                       width={iconSize * 1.2}
                                                       height={iconSize * 1.2}
                                                       isTourGuide
                                                       style={{
                                                           marginTop: mt,
                                                       }}
                                                       maskOffset={10 + mt / 2}
                                                       shape="circle"
                                                       tooltipBottomOffset={200}
                                                       text={'Ici, tu pourras communiquer avec ton masteur et rejoindre les sessions LIVE'}
                                                       borderRadius={30}
                >{status?.focused ? <IconImageM style={{marginTop: -mt}} width={iconSize} source={liveChat}/> :
                    <IconImageM style={{marginTop: -mt, opacity: 0.5}} width={iconSize}
                                source={liveChatG}/>}</TourGuideZone>,
                tabBarBadge: countMyTutorNotifications > 0 ? countMyTutorNotifications : null
            }
        }
    ]
}