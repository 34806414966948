import TextM from "@common/components/text/TextM";
import {View} from "react-native";
import React from "react"
import TitleM from "@common/components/text/TitleM";
import ChallengesTabScreen from "../../../../student/organize/challenges/ChallengesTabScreen";
import {idsToObjects, lastFinishedInterviewForStudent} from "@data/utility/dataUtils";
import moment from "moment";
import ChallengesListItem from "../../../../student/organize/challenges/ChallengesListItem";
import {useNavigation} from "@react-navigation/native";
import InterviewHeader, {InterviewTitle} from "../InterviewHeader";

export default function InterviewChallengesStep({student, interview}) {

    const lastFinishedInterview = lastFinishedInterviewForStudent(student)
    const challenges = student?.challenges

    const finishedSinceLastInterviewChallenges = student?.history?.challenges
        ?.filter(item => (!!item?.finished_at && (lastFinishedInterview ? moment(item?.finished_at).diff(lastFinishedInterview?.finalized_at) > 0 : true)))
        ?.sort((a, b) => {
            const dateA = a?.finished_at ? a?.finished_at : a?.abandoned_at
            const dateB = b?.finished_at ? b?.finished_at : b?.abandoned_at
            return moment(dateB).diff(dateA)
        })
    const countLateActivities = student?.activities?.filter(x => x?.is_late)?.length
    const navigation = useNavigation()
    return (
        <>
            <InterviewHeader student={student}
                             interview={interview}/>
            <ChallengesTabScreen navigation={navigation}
                                 realizationMode={true}
                                 renderAdditionalHeader={() => <View style={{marginBottom: 20, marginTop: -10}}>
                                     {InterviewTitle("Défis")}
                                     <View style={{marginHorizontal: 20, marginTop: 20, alignItems: 'flex-start'}}>
                                         <TitleM
                                             fontWeight="ExtraBold">DÉFI{finishedSinceLastInterviewChallenges?.length > 1 && "S"} TERMINÉ{finishedSinceLastInterviewChallenges?.length > 1 && "S"}
                                             {lastFinishedInterview ? " DEPUIS LE DERNIER ENTRETIEN" : ""}</TitleM>
                                         <TextM fontWeight="SemiBold" fontSize={12} style={{marginLeft: 6}}>
                                             {finishedSinceLastInterviewChallenges?.length ?? 0}
                                         </TextM>
                                     </View>

                                     {finishedSinceLastInterviewChallenges?.length > 0 && finishedSinceLastInterviewChallenges?.map((item, index) => {

                                         const activities = idsToObjects(item.activity_ids, student?.activities)

                                         return (
                                             <ChallengesListItem activities={activities}
                                                                 realizationMode={!item?.planning_mode}
                                                                 student={student}
                                                                 index={index}
                                                                 challenge={item}
                                             />
                                         )
                                     })
                                     }
                                 </View>}
                                 student={student}
                                 showButton/>
        </>

    )
}