import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {Platform, ScrollView, StatusBar, View} from "react-native";
import {countSameActivitiesToday, findItemInListWithId} from "@data/utility/dataUtils";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {KeyValueItem} from "@common/components/card/KeyValueItem";
import {KeyComponentItem} from "@common/components/card/KeyComponentItem";
import {StepNumAndIcon} from "../execute/StepNumAndIcon";
import moment from "moment";
import {AbilityContext} from "@data/utility/Can";
import ActivityTemplateHeader from "./ActivityTemplateHeader";
import ChampionBubble from "../../champions/ChampionBubble";
import {hasRole} from "@data/utility/ability";
import {activitiesService} from "@data/services/activitiesService";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useApiStatus} from "@common/hooks/useApiStatus";
import useLaunchActivity from "@common/hooks/useLaunchActivity";

const ActivityPresentationScreen = props => {

    // ** Component props
    const {navigation, route} = props
    const {
        activityTemplateId,
        challengeId,
        activityId,
        schoolSubjectId,
        studentId,
        isTutor,
        chapterId,
        chapterName,
        lastFeelingSentAt,
        ongoing,
        xpGained
    } = route.params

    const ability = useContext(AbilityContext)

    const user = studentId ? props.user?.students?.find(s => s.id === studentId) : props.user

    // ** Component variables
    const activityTemplate = findItemInListWithId(activityTemplateId, props.activityTemplates)
    const plannedActivity = activityId ? findItemInListWithId(activityId, user?.activities) : null
    const challenge = challengeId ? findItemInListWithId(challengeId, user?.challenges) : null
    const schoolSubject = findItemInListWithId(schoolSubjectId, props.schoolSubjects)

    const doneActivities = user?.activities
        ?.filter(a => a?.activity_template_id === activityTemplateId)
        ?.filter(a => (challengeId ? a?.challenge_id === challengeId : a?.school_subject_id === schoolSubjectId))
        ?.filter(a => !!a?.finished_at)

    // group activities by finished_at
    const reducedDoneActivities = doneActivities?.reduce((pValue, cValue) => {
        for (let i = 0; i < pValue.length; i++) {
            // if element was already treated, go to next one
            if (moment(cValue?.finished_at).isSame(pValue[i][0]?.finished_at, 'day'))
                return pValue
        }

        const sameDateActivities = doneActivities?.filter(a => moment(cValue?.finished_at).isSame(a?.finished_at, 'day'))

        return sameDateActivities?.length > 0 ? [...pValue, sameDateActivities] : pValue
    }, [])


    const sameActivitiesTodayCount = countSameActivitiesToday(
        activityTemplate,
        doneActivities,
        challengeId ? challengeId : null,
        schoolSubjectId ? schoolSubjectId : null
    )
    const maxReached = sameActivitiesTodayCount >= activityTemplate?.max_per_day
    const maxReachedTitle = "Nombre max atteint"
    const maxReachedText = "Tu as atteint le maximum d'activités '" + activityTemplate?.title + "' sur " + (challengeId ? "ce défi" : "cette matière") + " aujourd'hui. Tu peux faire d'autres activités ou revenir demain."
    const launchActivity = useLaunchActivity()
    useApiStatus(
        activitiesService.initActivity, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
            launchActivity({
                activity_template_id: activityTemplateId,
                id: successData?.id,
                school_subject_id: schoolSubjectId,
                challengeId: challengeId,
                againActivityId: null,
                chapterName: chapterName,
                chapterId: chapterId,
                ongoing: ongoing,
                xpGained: xpGained,
                lastFeelingSentAt: lastFeelingSentAt
            }, "replace")
        }
    )

    const onPress = () => {
        if (maxReached) {
            DialogM.show({
                text1: maxReachedTitle,
                text2: maxReachedText
            })
            //alertPolyfill(maxReachedTitle, maxReachedText)
            return
        }
        if (!activityId) {
            props.apiRequest(activitiesService.initActivity, {}, {
                activity_template_id: activityTemplateId,
                challenge_id: challengeId,
                school_subject_id: schoolSubjectId,
                activity_id: null,
                again_activity_id: null
            }, {}, () => {
            })


            return
        }

        if (activityTemplate?.parent_type === "school_subject" && !schoolSubjectId) {
            bottomSheetRef.current.present()
        } else {

            if (hasRole(user, "student") && !user?.champion_intros_viewed_at?.abou) {
                navigation.replace("intro", {
                    name: "abou",
                    nextScreen: "activity",
                    nextScreenParams: {
                        activityTemplateId: activityTemplateId,
                        challengeId: challengeId,
                        activityId: !plannedActivity?.finished_at ? activityId : null,
                        againActivityId: !plannedActivity?.finished_at ? null : activityId,
                        schoolSubjectId: schoolSubjectId
                    }
                })

            } else {
                if (!activityId) {
                    props.apiRequest(activitiesService.initActivity, {}, {
                        activityTemplateId: activityTemplateId,
                        challengeId: challengeId,
                        schoolSubjectId: schoolSubjectId
                    })
                }
                launchActivity(challengeId ? {
                        activityTemplateId: activityTemplateId,
                        challengeId: challengeId,
                        activityId: !plannedActivity?.finished_at ? activityId : null,
                        againActivityId: !plannedActivity?.finished_at ? null : activityId,
                        schoolSubjectId: schoolSubjectId,
                        ongoing: ongoing,
                        xpGained: xpGained,
                        lastFeelingSentAt: lastFeelingSentAt
                    } :
                    {
                        activityTemplateId: activityTemplateId,
                        activityId: !plannedActivity?.finished_at ? activityId : null,
                        againActivityId: !plannedActivity?.finished_at ? null : activityId,
                        schoolSubjectId: schoolSubjectId,
                        chapterName: chapterName,
                        chapterId: chapterId,
                        ongoing: ongoing,
                        xpGained: xpGained,
                        lastFeelingSentAt: lastFeelingSentAt
                    }, "replace")
            }
        }


    }
    const dimensions = useContext(DimensionsContext)
    return <View style={{
        flex: 1,
        backgroundColor: theme.colors.whiteAlmost,
        alignItems: 'center',
        justifyContent: 'flex-start'
    }}>

        <ActivityTemplateHeader activityTemplate={activityTemplate}
                                activity={plannedActivity}
                                challenge={challenge}
                                navigation={navigation}
                                schoolSubject={schoolSubject}
                                isTutor={isTutor}
        />

        <ScrollView style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost
        }}>

            <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: "flex-start",
                paddingHorizontal: 5,
                // marginTop: 20,
                // backgroundColor: theme.colors.accent,
                width: dimensions?.width
            }}>

                {/* ** Max number warning if applicable */}
                {maxReached && <KeyValueItem
                    warningMode
                    keyText={maxReachedTitle}
                    valueText={maxReachedText}
                    style={{marginTop: 20}}
                    columnMode
                />}

                {/* ** Activity description and recommendations (if applicable) */}
                <KeyValueItem keyText="LES CONSIGNES DE ZOÉ"
                              valueComponent={<ChampionBubble name={"zoe"}
                                                              variant={"main"}
                                                              hideIcons={!activityTemplate?.recommendations}
                                                              allowSpeechReplay={!!activityTemplate?.recommendations}
                                                              championPosition="left"
                                                              texts={activityTemplate?.recommendations ? [activityTemplate?.description, activityTemplate?.recommendations] : [activityTemplate?.description]}
                                                              onNextPress={() => {
                                                              }}
                                  // ref={championsBubbleRef}
                                  // style={{marginTop: 30}}
                              />}
                              style={{marginTop: 20}}
                              columnMode/>

                {/* ** Activity steps */}
                <KeyComponentItem
                    keyText="ÉTAPES DE L'ACTIVITÉ"
                    style={{marginTop: 20}}
                >
                    {activityTemplate?.steps?.map((step, index) => {
                        return <View key={index}
                                     style={{
                                         flexDirection: "row",
                                         alignItems: "center",
                                         justifyContent: "flex-start",
                                         width: dimensions?.width - 14 - 25,
                                         marginHorizontal: 10,
                                         marginBottom: 5
                                     }}>
                            <StepNumAndIcon text={(index + 1)} type={step?.type} rowMode/>
                            <TextM fontSize={14}
                                   style={{
                                       marginLeft: 10
                                   }}
                                   fontWeight="SemiBold"
                                   color={theme.colors.primary}>{step?.title}</TextM>

                        </View>
                    })}
                </KeyComponentItem>

                {/* ** Activity XP value */}
                {/*<KeyValueItem keyText="XP À GAGNER"*/}
                {/*              valueText={activityTemplate?.xp_value}*/}
                {/*              style={{marginTop: 20}}*/}
                {/*              isXP/>*/}

                {/* ** My realizations */}
                {/*{((challenge && !challenge?.planning_mode) || !challenge) && !isTutor && <KeyComponentItem*/}
                {/*    keyText="MES RÉALISATIONS"*/}
                {/*    style={{marginTop: 20}}*/}
                {/*>*/}
                {/*    {doneActivities?.length > 0 ? <ScrollView horizontal style={{*/}
                {/*        width: "100%",*/}
                {/*        paddingVertical: 10*/}
                {/*    }}>*/}
                {/*        <View*/}
                {/*            style={{*/}
                {/*                // height: 160,*/}
                {/*                flexDirection: 'row',*/}
                {/*                justifyContent: 'space-evenly'*/}
                {/*                // flexWrap: "wrap",*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            {doneActivities?.map((la, index) => {*/}

                {/*                const color = (la?.status === "not_reviewed")*/}
                {/*                    ? theme.colors.primary*/}
                {/*                    : (la?.status === "pending_review")*/}
                {/*                        ? theme.colors.warning*/}
                {/*                        : (la?.status === "rejected")*/}
                {/*                            ? theme.colors.grey*/}
                {/*                            : theme.colors.success*/}

                {/*                return <TouchableOpacity key={index}*/}
                {/*                                         onPress={() => {*/}
                {/*                                             navigation.push("activity-result", {*/}
                {/*                                                 activityIds: [la?.id],*/}
                {/*                                                 studentId: studentId,*/}
                {/*                                                 noLaunchButton: true*/}
                {/*                                             })*/}
                {/*                                         }}*/}
                {/*                                         style={{*/}
                {/*                                             alignItems: "center",*/}
                {/*                                             justifyContent: "center",*/}
                {/*                                             backgroundColor: color,*/}
                {/*                                             width: 100,*/}
                {/*                                             borderRadius: 100 / 6,*/}
                {/*                                             paddingVertical: 10,*/}
                {/*                                             marginRight: 10*/}
                {/*                                         }}>*/}

                {/*                    <DoneChip status={la?.status}*/}
                {/*                              style={{marginBottom: 5}}*/}
                {/*                              noBorder/>*/}
                {/*                    <TextM fontSize={10}*/}
                {/*                           color={theme.colors.white}>{moment(la?.finished_at)?.format("dddd DD/MM")}</TextM>*/}

                {/*                </TouchableOpacity>*/}
                {/*            })}*/}

                {/*        </View>*/}
                {/*    </ScrollView> : <TextM style={{alignSelf: "center"}}>Aucune réalisation pour le moment</TextM>}*/}
                {/*</KeyComponentItem>}*/}

            </View>

            <View style={{height: 120}}/>


        </ScrollView>

        {ability.can('read', 'realize_activity') && <View style={{
            backgroundColor: theme.colors.primaryLighter,
            paddingBottom: 10,
            paddingTop: 20,
            borderTopWidth: 1,
            borderColor: theme.colors.lightMore,
            position: "absolute",
            width: "100%",
            alignItems: "center",
            bottom: 0
        }}>

            <FilledButtonM color={theme.colors.primary}
                           onPress={onPress}
                           style={{
                               width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,
                           }}
                           label="Commencer"
                           loading={props.initActivityRequest}
                           disable={props.initActivityRequest}
            />

        </View>}
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}
const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        initActivityRequest: state.api.initActivity?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPresentationScreen)