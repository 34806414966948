// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import {applyMiddleware, compose, createStore} from 'redux'
// ** redux-persist wrappers
import {persistReducer, persistStore} from 'redux-persist'
// ** the local storage we'll be using to persist data
import AsyncStorage from '@react-native-async-storage/async-storage'
// ** redux-persist merge level
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'


// persist config
const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel2,
    whitelist: ['data', 'camera', 'liveMode'],
};
// wrap persist API around root reducer and store
const persistedReducer = persistReducer(persistConfig, rootReducer);

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** CreateM store
const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(...middleware)))
const persistor = persistStore(store);


export {store, persistor}