// ** React Imports
import React, {useContext, useState} from 'react'
import {connect} from 'react-redux'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import LinkTextM from "@common/components/text/LinkTextM";
import moment from "moment";
import {tutorsService} from "@data/services/tutorsService";
import {PaginatedTableM} from "../../admin/PaginatedTableM";
import {IconButton} from "react-native-paper";
import {padToTwo} from "@data/utility/Utils";

const TutoringInvoicesScreen = (props) => {

    const {navigation, route} = props
    const [urlParams, setUrlParams] = useState({})

    const dimensions = useContext(DimensionsContext);

    const columns = [
        {
            name: '',
            style: {
                width: 300,
                marginRight: 40
                // height: 60,
            },
            selector: 'name',
            sortable: true,
            cell: row => <View style={{
                justifyContent: "flex-start",
                alignItems: "flex-start",
                maxWidth: 300
            }}>
                <TextM color={theme.colors.primary}
                       fontWeight={"Bold"}
                       wrap
                       style={{}}>{`Date : ${moment(row?.date)?.format("D MMMM YYYY")}`}</TextM>
                <TextM fontWeight={"Medium"}
                       fontSize={12}
                       wrap
                       style={{}}>{`De : ${row?.issuer?.display_name ?? "Masteur"}`}</TextM>
                <TextM fontWeight={"Medium"}
                       fontSize={12}
                       wrap
                       style={{}}>{`À : ${row?.recipient?.display_name}`}</TextM>
                <TextM fontWeight={"Medium"}
                       fontSize={12}
                       wrap
                       style={{}}>{`Montant : €${row?.total?.toFixed(2)?.replace(".", ",")}`}</TextM>
            </View>
        },
        {
            name: '',
            style: {
                width: 200,
                marginRight: 40
            },
            selector: 'attachment',
            sortable: true,
            cell: row => <LinkTextM url={row?.attachment} style={{flexWrap: "wrap"}}>Facture</LinkTextM>
        },
        {
            name: '',
            style: {
                width: 200,
                marginRight: 40
            },
            selector: 'payment_recap',
            sortable: true,
            cell: row => row?.payment_recap ?
                <LinkTextM url={row?.payment_recap} style={{flexWrap: "wrap"}}>Récapitulatif</LinkTextM> : ""
        }
    ]


    return (
        <View style={{
            flex: 1
        }}
        >
            <HeaderContainerM>
                <View style={{flexDirection: "row", alignItems: "center", marginRight: 20}}>
                    <IconButton
                        icon={"times"}
                        iconColor={theme.colors.primary}
                        size={32}
                        style={{
                            // position: "absolute",
                            marginRight: 10
                        }}
                        onPress={() => {
                            if (navigation.canGoBack()) navigation.goBack()
                            else navigation.replace("menu")
                        }}
                    />
                    <TitleM>Mes factures</TitleM>
                </View>
            </HeaderContainerM>
            <PaginatedTableM data={props.tutoringInvoices}
                             flatListRef={null}
                             columns={columns}
                             headerHeight={60}
                             requestService={tutorsService.getTutoringInvoices}
                             urlParams={urlParams}
                             noHeader
            />
        </View>
    )


}

const mapStateToProps = state => {
    return {
        tutoringInvoices: state.data?.tutors?.tutoringInvoices,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutoringInvoicesScreen)