import TitleM from "@common/components/text/TitleM";
import {Dimensions, ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import React, {useContext, useEffect, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ChampionImageM} from "../champions/ChampionImageM";
import TextInputM from "@common/components/input/TextInputM";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import yup from "@data/utility/validation";
import * as Clipboard from "expo-clipboard";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {referralService} from "@data/services/referralService";
import {SnackBarM} from "@common/components/alert/snackbar/SnackBar";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import IconButtonM from "@common/components/button/IconButtonM";
import {ReferralCodeItemsContainer} from "../../admin/ReferralCodeItemsContainer";

const SponsorshipScreen = (props) => {
    const {visible, onDismiss} = props

    const [referralCode, setReferralCode] = useState(null)

    useEffect(() => {
        props.apiRequest(referralService.getReferralCode)
    }, []);

    useApiStatus(
        referralService.getReferralCode, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setReferralCode(successData)
        }
    )

    useEffect(() => {
        if (referralCode) {
            props.apiRequest(referralService.getUrl, {id: referralCode?.id})
        }
    }, [referralCode]);

    useApiStatus(
        referralService.getUrl, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setValue("url", successData?.url)
            setValue("commission_amount", successData?.commission_amount)
        }
    )

    const navigation = useNavigation()

    const formSchema = yup.object().shape({
        emails: yup.string().email().required()
    })
    const resolver = yupResolver(formSchema);
    const dimensions = useContext(DimensionsContext)
    const defaultValues = {
        emails: '',
        url: '',
        commission_amount: 30
    }
    const {control, handleSubmit, formState: {errors, isValid}, setValue, trigger, getValues} = useForm({
        resolver,
        defaultValues: defaultValues
    })

    const onSubmit = (data) => {
        // console.log(data)
        // convert email data to array
        data.emails = data.emails.split(",").map((email) => email.trim())
        // console.log(data)
        props.apiRequest(referralService.sendUrl, {id: referralCode?.id}, data)
    }
    useApiStatus(
        referralService.sendUrl, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
            setValue("emails", "")
            DialogM.show({
                text1: 'Bravo 🎉',
                text2: "L'email de parrainage a bien été envoyé"
            })
        } // success callback
    )


    return <View style={{
        flex: 1,
        // backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: Dimensions.get("window").width
        // paddingTop: 5
    }}>
        <HeaderContainerM>
            <TitleM>Je parraine</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>
        {props.getReferralCodeRequest
            ? <ActivityIndicator animating={true}
                                 size="large"
                                 color={theme.colors.primary}/>
            : <ScrollView style={{
                width: Dimensions.get("window").width
                // backgroundColor: theme.colors.error
            }}
                          contentContainerStyle={{
                              alignSelf: 'center',
                              alignItems: 'center',
                              width: dimensions?.width
                              // backgroundColor: theme.colors.black
                          }}
            >
                <View style={{
                    alignItems: 'center',
                    alignSelf: 'center',
                    // backgroundColor: 'blue',
                    width: dimensions?.width,
                    paddingHorizontal: 20
                }}>


                    <ChampionImageM name={"zoe"}
                                    style={{}}
                                    variant={"happy"}
                                    width={dimensions?.width > 800 ? 130 : 100}
                                    height={dimensions?.width > 800 ? 220 : null}
                    />
                    <TextM color={theme.colors.primary}
                           style={{
                               marginTop: 10,
                               textAlign: "center"
                           }}
                           fontWeight={"Bold"}
                           fontSize={18}
                           wrap
                    >Partage ton lien de parrainage, et gagne {getValues()?.commission_amount}€ pour chaque parrainage réussi
                        !</TextM>
                    <TextM fontSize={14}
                           style={{
                               textAlign: "center"
                           }}
                           fontWeight={"Light"}
                           wrap>Le bon d'achat est gagné lorsque l'élève parrainé active son abonnement</TextM>

                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 20
                        }}
                    >
                        <TextInputM
                            control={control}
                            placeholder="Votre lien de parrainage"
                            name="url"
                            errors={errors}
                            style={{
                                height: dimensions?.width > 800 ? null : 50,
                                backgroundColor: theme.colors.primaryLighter
                            }}
                            disabled
                        />
                        <IconButtonM
                            size={30}
                            icon={"copy"}
                            iconColor={theme.colors.primary}
                            style={{
                                position: "absolute",
                                right: 0
                            }}
                            onPress={() => {
                                Clipboard.setStringAsync(getValues()?.url).then(() => {
                                    SnackBarM.show({
                                        text1: '📋 Lien de parrainage copié dans le presse-papier',
                                        duration: 6000
                                    })
                                });
                            }}
                        />
                    </View>

                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: 10
                            // backgroundColor: "red",
                        }}
                    >
                        <TextInputM
                            control={control}
                            errors={errors}
                            placeholder="exemple@email.com"
                            name="emails"
                            // textContentType="emailAddress"
                            // keyboardType="email-address"
                            style={{
                                height: dimensions?.width > 800 ? null : 50

                            }}
                        />
                        {props.sendUrlRequest
                            ? <ActivityIndicator animating={true}
                                                 style={{
                                                     position: "absolute",
                                                     top: 22,
                                                     right: 6
                                                 }}
                                                 size="small"
                                                 color={theme.colors.primary}/>
                            : <IconButtonM
                                size={30}
                                icon={"envelope"}
                                iconColor={theme.colors.primary}
                                disabled={props.sendUrlRequest}
                                style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 0
                                }}
                                onPress={() => {
                                    trigger().then((isValid) => {
                                        if (isValid) {
                                            DialogM.show({
                                                text1: 'Envoyer le lien par mail ?',
                                                text2: 'Un email sera envoyé à ' + getValues()?.emails + ' avec le lien de parrainage',
                                                buttonText1: 'Envoyer', // buttonColor1: theme.colors.error,
                                                onPress: () => {
                                                    handleSubmit(onSubmit)()
                                                },
                                                buttonText2: 'Annuler',
                                                onPressBack: () => {
                                                }
                                            })
                                        }
                                    })
                                }}
                            />}
                    </View>
                </View>

                {/*<Divider style={{*/}
                {/*    width: dimensions?.width < 800 ? "100%" : "60%",*/}
                {/*    height: dimensions?.width < 800 ? 1 : 1.5,*/}
                {/*    marginTop: 25*/}
                {/*}}/>*/}

                <View style={{
                    width: Dimensions.get("window").width,
                    backgroundColor: theme.colors.primaryLighter,
                    marginTop: 30,
                    paddingVertical: 40
                }}>

                    <TitleM style={{textAlign: "center"}}>Mes parrainages</TitleM>

                    <ReferralCodeItemsContainer referralCodeFromHash={referralCode} style={{
                        justifySelf: "center"
                        // backgroundColor: "red"
                    }}/>

                </View>

            </ScrollView>}
    </View>

}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getReferralCodeRequest: state.api.getReferralCode?.request,
        sendUrlRequest: state.api.sendUrl?.request,
        sendUrlError: state.api.sendUrl?.error,
        sendUrlSuccess: state.api.sendUrl?.success
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SponsorshipScreen)