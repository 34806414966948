import React, {useContext, useRef} from 'react';
import {Platform, View} from "react-native";
import TitleM from "@common/components/text/TitleM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {StatusBar} from "expo-status-bar";
import RealizationsListItem from "./RealizationsListItem";
import {activitiesService} from "@data/services/activitiesService";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import ReadReviewRequestHandler from "../../activity/read/ReadReviewRequestHandler";
import {ChampionImageM} from "../../champions/ChampionImageM";
import TextM from "@common/components/text/TextM";
import BecomePremiumCard from "../../account/subscription/BecomePremiumCard";

const RealizationsTabScreen = (props) => {

    // ** Component props
    const {navigation, student, centerTitle} = props

    // console.log(student)

    const user = student ? student : props.user
    // const request = !!student ? props.getStudentRequest : props.getUserRequest
    // const getUserService = !!student ? studentsService.getStudent : authService.getUser
    // const getUserParams = !!student ? {id: student?.id} : {}

    const requestApiService = student ? activitiesService.getStudentFinishedActivities : activitiesService.getFinishedActivities
    const requestRouteParams = student ? {id: student?.id} : {}


    // ** Manage scrolling to specific index in flat list
    const flatListRef = useRef()

    const finishedActivities = user?.history?.activities
    const dimensions = useContext(DimensionsContext)


    return <View style={{
        // backgroundColor: theme.colors.whiteAlmost,
        flex: 1
    }}>

        {(user?.xp > 0 || user?.activities?.length > 0) ? <View style={{
            flex: 1,
            // backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center',
            width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            <PaginatedFlatList data={finishedActivities}
                               ref={flatListRef}
                               style={{
                                   width: Platform.OS !== 'web' ? dimensions?.width : "100%",
                                   //backgroundColor: theme.colors.gemsLight

                               }}
                               contentContainerStyle={{
                                   alignItems: "center"
                               }}
                               additionalOnRefresh={() => {
                               }}
                               additionalRefreshing={false}
                               requestApiService={requestApiService}
                               requestRouteParams={requestRouteParams}
                               requestUrlParams={null}
                               requestData={null}
                               ListHeaderComponent={<>
                                   <View style={{
                                       flexDirection: "row",
                                       alignItems: 'center',
                                       justifyContent: centerTitle ? 'center' : 'space-between',
                                       width: Platform.OS !== 'web' ? dimensions?.width : "100%", // borderBottomWidth: 1,
                                       borderColor: theme.colors.lightMore, // backgroundColor: theme.colors.accentLighter,
                                       paddingVertical: 15,
                                       paddingHorizontal: 10,
                                       // marginBottom: 20
                                       // marginTop: 10
                                   }}>

                                       <TitleM
                                           fontWeight="ExtraBold"> {centerTitle ? "Activités réalisées" : "ACTIVITÉS RÉALISÉES"}  </TitleM>
                                       {/*<TextM fontWeight="Regular"*/}
                                       {/*       wrap*/}
                                       {/*       color={theme.colors.grey}*/}
                                       {/*       style={{*/}
                                       {/*           alignSelf: "center",*/}
                                       {/*           // marginLeft: 20,*/}
                                       {/*           marginTop: -5*/}
                                       {/*           // marginBottom: 4*/}
                                       {/*       }}>*/}
                                       {/*    Pour rester à jour*/}
                                       {/*</TextM>*/}
                                       {/*<View style={{flexDirection: "row", marginLeft: 10}}>*/}
                                       {/*    <Menu3Point text="Médias (bientôt disponible)" disabled={true}/>*/}
                                       {/*</View>*/}
                                   </View>
                                   <BecomePremiumCard index={0} style={{
                                       marginTop: 0, marginBottom: 20, alignSelf: "center"
                                   }}/>
                               </>}
                               renderItem={(item) => {
                                   return <RealizationsListItem activity={item.item}
                                                                custom
                                                                student={student}
                                   />
                               }}/>
            <ReadReviewRequestHandler/>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

        </View> : <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: 20,
            // backgroundColor: theme.colors.white,
            flex: 1,
            width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            <ChampionImageM name="sam" variant={"main"} width={90}/>
            <TitleM fontWeight="ExtraBold" style={{textAlign: "center", marginTop: 20}} wrap>Tu n'as pas encore réalisé
                d'activité</TitleM>
            <TextM fontWeight="Medium" style={{textAlign: "center"}} wrap>Réalise ta première activité pour accéder à
                tes scores et des conseils personnalisés pour t'améliorer</TextM>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

        </View>}
    </View>
}

const mapStateToProps = state => {
    return {
        removeSchoolSubjectRequest: state.api.removeSchoolSubject?.request,
        removeSchoolSubjectSuccess: state.api.removeSchoolSubject?.success,
        removeSchoolSubjectError: state.api.removeSchoolSubject?.error,
        removeSchoolSubjectSuccessData: state.api.removeSchoolSubject?.data,

        getStudentRequest: state.api.getStudent?.request,
        getStudentSuccess: state.api.getStudent?.success,
        getStudentError: state.api.getStudent?.error,

        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        getUserError: state.api.getUser?.error,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        maxPrioritizedSchoolSubjects: state.data.staticData?.max_prioritized_school_subjects
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RealizationsTabScreen)