import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import React, {useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import moment from "moment";
import {capitalize} from "@data/utility/Utils";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {ActivityIndicator} from "react-native-paper";
import {RankingListItem} from "../student/social/RankingScreen";

export const convertMomentToFrench = (live) => {
    const liveMoment = moment(live?.day_time, "dddd HH:mm", "en").locale("fr")
    // console.log("liveMoment",liveMoment)
    const isToday = liveMoment?.isSame(moment(), "day")
    return capitalize(liveMoment.format((isToday ? "[aujourd'hui]" : "dddd") + " [à] HH:mm"))
}
export const liveSessionsString = (liveSessions) => {
    return liveSessions?.length === 0 ? "Aucun live" : liveSessions?.map(live => {
        return convertMomentToFrench(live);
    })?.join(',  ')
}
const AssignTutorAndLives = (props) => {

    useEffect(() => {
        props.apiRequest(backOfficeService.getUserBackoffice, {id: props.route.params?.studentId})
        // props.apiRequest(roomService.getRooms)
    }, [])

    useApiStatus(
        backOfficeService.getUserBackoffice, // api service
        null, // success message on toast (string or null)
        true // error message on toast (true) or keep it in redux state (false)
    )
    // useApiStatus(
    //     roomService.getRooms, // api service
    //     null, // success message on toast (string or null)
    //     true, // error message on toast (true) or keep it in redux state (false)
    // )

    const navigation = useNavigation()

    const [formValues, setFormValues] = useState(null)

    const student = props.users?.find(x => x?.id === props.route.params?.studentId)

    //console.log(props.route.params?.studentId,student)


    const currentTutor = props.usersFormStep?.find(x => x?.id === formValues?.tutor_id);

    const live_sessions = currentTutor?.tutor_live_sessions

    //console.log(currentTutor,live_sessions)

    const live_sessions_by_liveRoom = live_sessions?.reduce((pValue, cValue) => {
        const name = cValue?.live_room?.name
        pValue[name] = pValue[name] ?? [];
        pValue[name].push(cValue);
        return pValue;
    }, {});
    //console.log(live_sessions_by_liveRoom)

    useApiStatus(
        backOfficeService.assignTutorAndLiveSlots, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            props.apiIdle(backOfficeService.assignTutorAndLiveSlots)
            // navigation.goBack()
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Les changements ont été enregistrés"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )


    const defaultValues = {}


    const formData = [
        {
            label: "Choisir le tuteur",
            name: "tutor_id",
            type: "select.paginated",
            singleMode: true,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "tutor",
                // light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: student?.tutor?.id ?? "",
            validationRules: {...validation.required()}
        },
        {
            label: "Choisir des lives ",
            // description: "Disponibilités de l'élève : " + student?.slots_possible?.join(',  '),
            // is_masked: !currentDay,
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "live_session_ids",
            sectionMode: true,
            options: live_sessions_by_liveRoom ? Object?.keys(live_sessions_by_liveRoom)
                ?.map(section => {
                    //console.log(x?.day_time)
                    return {
                        title: section,
                        data: live_sessions_by_liveRoom?.[section]?.map(x => {
                            return {
                                value: x?.id,
                                label: convertMomentToFrench(x),
                                description: x?.students_count === 0 ? "Aucun élève" : x?.students_count + " élèves : " + x?.students_list_string,
                                avatar: currentTutor
                            }
                        })
                    }
                }) : [],
            defaultValue: [],
            validationRule: {
                ...validation.maxLength(2)
            }
        },
        {
            label: "Notifier l'élève et le tuteur ? ",
            // is_masked: !currentDay,
            type: "select.single", // select.single, select.multiple, text, date
            name: "notify",
            options:
                [
                    {
                        value: true,
                        label: "Oui"
                    },
                    {
                        value: false,
                        label: "Non"
                    }
                ],
            defaultValue: false
        }

    ]

    const onSubmit = data => {
        //console.log("data", data)
        if (props.updateStudentSlotRequest) return
        props.apiRequest(backOfficeService.assignTutorAndLiveSlots, {user_id: props.route.params?.studentId}, data)
    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            {props.getTutorsRequest || props.getUserBackofficeRequest || props.getRoomsRequest
                ? <ActivityIndicator animating={true}
                                     style={{
                                         marginTop: 50
                                     }}
                                     size="large"
                                     color={theme.colors.primary}/>
                : <FormStepper navigation={navigation}
                               track
                               formData={formData?.filter(x => !x.is_masked)}
                               champion={null}
                               setFormValues={setFormValues}
                               defaultValues={defaultValues}
                               onSubmit={onSubmit}
                               submitted={props.assignTutorAndSlotRequest}
                               previousRouteName={""}
                               submittedMessage=""
                               serverError={props.assignTutorAndSlotError}
                               submitButtonText="Terminer"
                />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        usersFormStep: state.data?.users?.usersListFormStep,
        getUserBackofficeRequest: state.api?.getUserBackoffice?.request,
        getTutorsRequest: state.api?.getTutors?.request,
        assignTutorAndSlotRequest: state.api?.assignTutorAndLiveSlots?.request,
        assignTutorAndSlotError: state.api?.assignTutorAndLiveSlots?.error,
        rooms: state.data.backOffice?.rooms
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AssignTutorAndLives)