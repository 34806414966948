import * as React from 'react';
import {useContext} from 'react';
import {Button, Dialog, Portal, Text} from 'react-native-paper';
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ChampionImageM} from "../../student/champions/ChampionImageM";

const VideoCallDialog = ({
                             visible,
                             onDismiss = () => {
                             },
                             soundMode
                         }) => {

    const dimensions = useContext(DimensionsContext)
    return (
        <Portal>
            <Dialog
                visible={visible}
                onDismiss={onDismiss}
                style={{
                    maxWidth: dimensions?.width,
                    alignSelf: 'center',
                    // position: 'fixed',
                    backgroundColor: theme.colors.white,
                    borderRadius: 20,
                }}>
                <Dialog.Title
                    style={{
                        color: soundMode ? theme.colors.success : theme.colors.purple,
                        alignSelf: 'center',
                        textAlign: 'center',
                    }}>{soundMode ? 'Tu es en mode dialogue 🔊' : 'Tu es en mode silencieux 🔇'}
                </Dialog.Title>
                <Dialog.Content style={{alignItems: 'center'}}>
                    <Text
                        style={{textAlign: 'center'}}
                        variant="bodyMedium">{soundMode ? 'Le son et ton micro sont actifs !' : 'Le son et ton micro sont désactivés'}</Text>
                </Dialog.Content>
                <Dialog.Content style={{alignItems: 'center'}}>
                    <ChampionImageM style={{alignSelf: 'center', marginBottom: 10}}
                                    name={'abou'}
                                    variant={"main"} width={100}/>
                </Dialog.Content>
                <Dialog.Content style={{alignItems: 'center'}}>
                    <Text
                        style={{textAlign: 'center'}}
                        variant="bodyMedium">{soundMode ? 'Tu peux dès maintenant discuter avec ton masteur !' : 'En attendant que ton masteur te mette en mode dialogue, on commence à travailler ?'}</Text>
                </Dialog.Content>
                <Dialog.Actions>
                    <Button onPress={onDismiss}>OK</Button>
                </Dialog.Actions>
            </Dialog>
        </Portal>
    );
};

export default VideoCallDialog;