import {apiConstants} from "@data/constants/apiConstants"
import {referralService} from "@data/services/referralService";
import {roomService} from "@data/services/roomService";

// ** Initial State
const initialState = {
    referralCodes: []
}

export function referrals(state = initialState, action) {
    switch (action.type) {

        case referralService.createReferralCode.name + apiConstants._SUCCESS:
            return {
                ...state,
                referralCodes: [...state.referralCodes, action?.data]
            }
        case referralService.updateReferralCode.name + apiConstants._SUCCESS:
            return {
                ...state,
                referralCodes: state.referralCodes.map((x) => {
                    if (x?.id === action?.data?.id) {
                        return action?.data
                    }
                    return x
                })
            }

        case referralService.deleteReferralCode.name + apiConstants._SUCCESS:
            return {
                ...state,
                referralCodes: state.referralCodes.filter((x) => x?.id !== action?.routeParams?.id)
            }

        case referralService.indexPaginatedReferralCodes.name + apiConstants._SUCCESS:
            let referralCodes = []
            if (action.data?.meta?.current_page === 1) {
                referralCodes = action.data?.referral_codes
            } else {
                referralCodes = state.referralCodes?.concat(action.data?.referral_codes)
            }
            return {
                ...state,
                referralCodes: referralCodes
            }


        default:
            return state
    }
}

export default referrals
