import React, {useContext} from "react";
import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import moment from "moment";
import {capitalize} from "@data/utility/Utils";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../assets/icons/generic/live.png";
import AvatarM from "@common/components/avatar/AvatarM";
import {CardButtonM} from "../home/interview/TutorInterviewCard";
import {TutorNextEventsCard} from "../home/interview/TutorNextEventsCard";
import {groupBy, sortLiveSessionsCallback} from "@data/utility/dataUtils";
import {findClosestNextOccurrence} from "../../student/account/onboarding/LiveRoomCard";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext} from "@common/utils/mobileUtils";

function TutorNextLivesCard(props) {

    const liveSessionsByLiveRoom = Object.values(groupBy(props.user?.live_sessions, "live_room_id") ?? {})?.sort((a, b) => {
        const firstA = a?.sort(sortLiveSessionsCallback)?.[0]
        const firstB = b?.sort(sortLiveSessionsCallback)?.[0]

        return sortLiveSessionsCallback(firstA, firstB)
    })

    const navigation = useNavigation()

    const dimensions = useContext(DimensionsContext);

    return <TutorNextEventsCard title={"Mes prochains lives"}
                                data={liveSessionsByLiveRoom}
                                renderItem={(liveSessionsForOneRoom, key, additionalOnPress = () => {
                                }) => {
                                    const item = liveSessionsForOneRoom?.sort(sortLiveSessionsCallback)?.[0]

                                    const closestNextOccurrence = findClosestNextOccurrence([item])

                                    // if (item?.live_room_id === 3)
                                    //     console.log("closestNextOccurrence", closestNextOccurrence)

                                    const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null
                                    // console.log("liveMoment",liveMoment)
                                    const isToday = liveMoment?.isSame(moment(), "day")
                                    const formattedLiveMoment = capitalize(liveMoment?.format((isToday ? "[aujourd'hui]" : "dddd") + " [à] HH:mm"))


                                    return <CardButtonM key={key}
                                                        style={{width: "100%"}}
                                                        onPress={() => {
                                                            // props.setIsLiveModeEnabled(true, item?.id)
                                                            navigation.push("pre-call", {
                                                                liveSessionId: item?.id
                                                            })
                                                            if (additionalOnPress)
                                                                additionalOnPress()
                                                        }}
                                                        icon={<IconImageM source={live}
                                                                          width={30}
                                                                          style={{
                                                                              // tintColor: theme.colors.primary
                                                                          }}
                                                        />}
                                                        badgeCount={isToday ? 1 : 0}
                                                        title={item?.live_room?.name + " - " + formattedLiveMoment}
                                                        description={<>
                                                            <TextM color={theme.colors.grey}
                                                                // fontWeight={"Medium"}
                                                                   fontSize={12}
                                                                   style={{
                                                                       marginLeft: 5,
                                                                       marginBottom: 10
                                                                   }}
                                                                   wrap>{item?.students_list_string === "" ? "-" : item?.students_list_string}</TextM>
                                                            <View
                                                            style={{flexDirection: 'row', alignItems: 'center'}}>
                                                            <AvatarM user={item?.tutor}/>
                                                            <TextM style={{marginLeft: 5}}
                                                                   color={theme.colors.primary}>{item?.tutor?.display_name}  </TextM>
                                                        </View>
                                    </>}/>
                                }}
    />
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        getStudentRequest: state.api.getStudents?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setIsLiveModeEnabled
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorNextLivesCard)