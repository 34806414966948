import React from "react";
import {IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";

export const MiniModeM = (props) => {
    const {miniMode, setMiniMode} = props;

    return (
        <IconButton
            icon={miniMode ? "expand-alt" : "compress-alt"}
            style={{
                backgroundColor: theme.colors.blackTransparent2,
                marginHorizontal: 10
            }}
            iconColor={theme.colors.white}
            size={30}
            onPress={() => {
                setMiniMode(!miniMode)
            }}
        />
    );
};