import {View} from "react-native";
import TextM from "../common/components/text/TextM";
import React, {useContext} from 'react'
import {DimensionsContext, IS_WEB} from "../common/utils/mobileUtils";
import {theme} from "../common/theme/theme";
import {AnalyticsCard} from "./AnalyticsCard";

export const ActivitiesSection = (props) => {

    const dimensions = useContext(DimensionsContext)
    const substract = (arr1, arr2) => {
        return arr1?.map((x, index) => x - arr2?.[index])
    }
    return (
        <View style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: dimensions?.width,
            paddingVertical: 20
        }}>
            <TextM fontWeight={"Bold"}
                   wrap
                   color={theme.colors.primary}
                   fontSize={20}
                   style={{
                       marginLeft: 5
                   }}
            >
                Activité
            </TextM>
            <View style={{
                justifyContent: 'space-between',
                width: "100%",
                flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : "column",
                flexWrap: 'wrap',
            }}>
                <AnalyticsCard
                    icon={"bullseye"}
                    backgroundColor={theme.colors.primaryLighter}
                    categories={Object.keys(props.data?.activities?.weekly_series ?? {})}
                    stats={props.data?.activities?.this_month}
                    values={[
                        {
                            name: `Activités`,
                            data: Object.values(props.data?.activities?.weekly_series ?? {}),
                            color: "#223F8F",
                        }
                    ]}
                    statTitle={"Activités"}
                    style={{
                        width: IS_WEB && dimensions.width > 800 ? (dimensions.width) / 2.02 : dimensions.width,
                    }}
                />
                <AnalyticsCard
                    icon={"bolt"}
                    backgroundColor={theme.colors.primaryLighter}
                    categories={Object.keys(props.data?.activities?.weekly_series ?? {})}
                    stats={props.data?.students_with_activities?.global?.this_month}
                    values={[
                        {
                            name: `Elèves actuellement BASIC`,
                            data: (substract(substract(Object.values(props.data?.students_with_activities?.global?.weekly_series ?? {}),
                                    Object.values(props.data?.students_with_activities?.plus?.weekly_series ?? {})),
                                Object.values(props.data?.students_with_activities?.premium?.weekly_series ?? {}))),
                            color: "#505050"
                        },
                        {
                            name: `Elèves actuellement PLUS`,
                            data: Object.values(props.data?.students_with_activities?.plus?.weekly_series ?? {}),
                            color: "#ffe07c"
                        },
                        {
                            name: `Elèves actuellement PREMIUM`,
                            data: Object.values(props.data?.students_with_activities?.premium?.weekly_series ?? {}),
                            color: "#ffb200"
                        }
                    ]}
                    statTitle={"Elèves ayant fait au moins une activité"}
                    style={{
                        width: IS_WEB && dimensions.width > 800 ? (dimensions.width) / 2.02 : dimensions.width,
                    }}
                />
            </View>
        </View>
    )
}