import axios from "axios";

function getLiveSession({ id }) {
  return axios.get(`api/v1/live-sessions/${id}`);
}

function getCall({ id }) {
  return axios.get(`api/v1/live-sessions/${id}/call`);
}

function makeCall({ id }, value) {
  return axios.post(`api/v1/live-sessions/${id}/call`, value);
}

function joinCall({ id }) {
  return axios.post(`api/v1/live-sessions/${id}/call/join`);
}

function leaveCall({ id }) {
  return axios.post(`api/v1/live-sessions/${id}/call/leave`);
}

function callKeepAlive({ id }) {
  return axios.post(`api/v1/live-sessions/${id}/call/keep-alive`);
}

function raiseHand({ id }, data) {
  return axios.post(`api/v1/live-sessions/${id}/raise-hand`, data);
}

function lowerHands({ id }, data) {
  return axios.post(`api/v1/live-sessions/${id}/lower-hands`, data);
}

function lowerAllHands({ id }) {
  return axios.post(`api/v1/live-sessions/${id}/lower-all-hands`);
}

function leaveLiveSession({ id }) {
  return axios.post(`api/v1/live-sessions/${id}/leave`);
}

function finalizeLiveSession({ id }) {
  return axios.post(`api/v1/live-sessions/${id}/finalize`);
}

function sendSatisfaction({ id }, data) {
  return axios.post(`api/v1/live-sessions/${id}/send-satisfaction`, data);
}

function sendLiveQuality({ id }, data) {
  return axios.post(
    `api/v1/live-occurrences/${id}/send-quality?_method=PUT`,
    data
  );
}

function sendStudentComment({ id }, data) {
  return axios.put(`api/v1/live-sessions/${id}/send-student-comment`, data);
}

function sendStudentInfos({ id }, data) {
  return axios.post(`api/v1/live-sessions/${id}/send-student-infos`, data);
}

function getTodayLiveReports({ id }) {
  return axios.get(`api/v1/live-sessions/${id}/today-live-reports`);
}

function getTodayLiveReport({ id }) {
  return axios.get(`api/v1/live-sessions/${id}/today-live-report`);
}

function getTodayLiveOccurrence({ id }) {
  return axios.get(`api/v1/live-sessions/${id}/today-live-occurrence`);
}

function getLiveOccurrence({ id }) {
  return axios.get(`api/v1/live-occurrences/${id}`);
}

function setSoundMode({ id }, data) {
  return axios.post(`api/v1/live-sessions/${id}/sound-mode`, data);
}

function setVoiceRecordingActivity({ id }, data) {
  return axios.post(
    `api/v1/live-sessions/${id}/voice-recording-activity`,
    data
  );
}

export const liveService = {
  getLiveSession,
  getCall,
  makeCall,
  joinCall,
  leaveCall,
  raiseHand,
  lowerHands,
  lowerAllHands,
  setSoundMode,
  setVoiceRecordingActivity,
  callKeepAlive,
  leaveLiveSession,
  finalizeLiveSession,
  sendSatisfaction,
  sendStudentInfos,
  getTodayLiveReports,
  getTodayLiveReport,
  sendLiveQuality,
  sendStudentComment,
  getTodayLiveOccurrence,
  getLiveOccurrence,
};
