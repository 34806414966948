import React, {useEffect} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect, useDispatch} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {authService} from "@data/services/authService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {useNavigation} from "@react-navigation/native";
import {StatusBar} from "expo-status-bar";
import {ActivityIndicator} from "react-native-paper";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {authConstants} from "@data/constants/authConstants";


const EmailVerifyScreen = (props) => {
    const successTitle = "Bravo ! 🎉"
    const successMessage = "L'adresse email a bien été validée !"

    const expires = props.route?.params?.expires ?? ""
    const signature = props.route?.params?.signature ?? ""
    const id = props.route?.params?.id ?? ""
    const hash = props.route?.params?.hash ?? ""

    const navigation = useNavigation()
    const dispatch = useDispatch()

    useEffect(() => {
        props.apiRequest(authService.emailVerify, {id, hash}, {}, {
            expires,
            signature
        })
    }, [])

    useApiStatus(
        authService.emailVerify, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        () => {

            if (!!props.user && props.user?.id !== parseInt(id)) {
                DialogM.show({
                    text1: successTitle,
                    text2: successMessage+"\n\nTu es connecté avec un autre compte, merci de te déconnecter."
                })
                // dispatch({type: authConstants.LOGOUT_401})
                navigation.replace("menu")
            } else {
                DialogM.show({
                    text1: successTitle,
                    text2: successMessage
                })
                navigation.replace("menu")
            }

        } // success callback
    )
    return (
        <View style={{
            alignItems: 'center',
            flex: 1,
            padding: 20
        }}>
            {props.verifying &&
                <ActivityIndicator color={theme.colors.primary}
                                   style={{marginTop: 30}}
                />
            }

            {props.verifyError && <>
                <TextM color={theme.colors.error}
                       wrap
                       style={{
                           marginTop: 20,
                           marginBottom: 20,
                           textAlign: "center"
                       }}
                       fontWeight={"Bold"}
                       fontSize={14}>
                    {props.verifyError.message}
                </TextM>
                <FilledButtonM
                    onPress={() => {
                        navigation.goBack()
                    }}
                    label={"Retour à l'accueil"}
                />
            </>}

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        verifying: state.api.emailVerify?.request,
        verifySuccess: state.api.emailVerify?.success,
        verifyError: state.api.emailVerify?.error,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerifyScreen)