import React, {useContext, useEffect, useRef} from 'react';
import {Platform, Pressable, SectionList, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import live from "../../../assets/icons/generic/live.png";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {DimensionsContext} from "@common/utils/mobileUtils";
import AvatarM from "@common/components/avatar/AvatarM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useNavigation} from "@react-navigation/native";
import {capitalize} from "@data/utility/Utils";
import moment from "moment";
import {RankingListItem} from "../../student/social/RankingScreen";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import NextOccurencesScreen from "./NextOccurencesScreen";

const LiveShow = (props) => {

    const {live_id, room_id} = props.route.params
    const dimensions = useContext(DimensionsContext)
    // const slotMoment = props.liveSession?.next_occurrences[0]?.date ? moment(props.liveSession?.next_occurrences[0]?.date) : null
    const slotMoment = props.liveSession?.day_time ? moment(props.liveSession?.day_time, "dddd HH:mm", "en") : null
    const slotMomentFormatted = slotMoment ? capitalize(slotMoment?.locale("fr")?.format("dddd HH:mm")) : null
    const membersCount = props.liveSession?.users ? props.liveSession?.users?.length : 0
    const studentParticipants = props.liveSession?.users?.filter(x => !x.is_tutor) ?? []
    const tutorParticipants = props.liveSession?.users?.filter(x => x?.is_tutor) ?? []
    const navigation = useNavigation()
    const flatListRef = useRef();

    useEffect(() => {
        props.apiRequest(backOfficeService.getLiveSessionAdmin, {id: live_id})
    }, []);

    useApiStatus(
        backOfficeService.getLiveSessionAdmin, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        } // success callback
    )

    const onDeleteUser = (userId) => {
        DialogM.show({
            text1: 'Veux-tu vraiment supprimer cet utilisateur du live ?',
            buttonText1: 'Supprimer',
            buttonColor1: theme.colors.error,
            onPress: () => {
                props.apiRequest(backOfficeService.deleteUsersFromLive, {id: live_id}, {user_ids: [userId]})
            },
            buttonText2: 'Annuler'
        })
    }

    useApiStatus(
        backOfficeService.deleteUsersFromLive, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        } // success callback
    )

    const onDeleteLive = () => {
        DialogM.show({
            text1: 'Veux-tu vraiment supprimer ce live ?',
            buttonText1: 'Supprimer',
            buttonColor1: theme.colors.error,
            onPress: () => {
                props.apiRequest(backOfficeService.deleteLiveSession, {id: live_id})
            },
            buttonText2: 'Annuler'
        })
    }
    useApiStatus(
        backOfficeService.deleteLiveSession, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("rooms")

        } // success callback
    )
    return (
        <View style={{flex: 1}}>
            <HeaderContainerM>
                <TitleM>Live</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("admin-lives")
                    }}
                />
            </HeaderContainerM>
            {props.getLiveRequest ?

                <ActivityIndicator color={theme.colors.primary}
                                   style={{marginTop: 30}}
                /> :
                <View style={{
                    alignSelf: 'center',
                    width: dimensions?.width,
                    flex: 1
                }}>
                    <SectionList sections={[
                        {
                            title: "Élèves (" + studentParticipants?.length + ")",
                            data: studentParticipants
                        },
                        {
                            title: "Masteur (" + tutorParticipants?.length + ")",
                            data: tutorParticipants
                        }
                    ]}
                                 stickySectionHeadersEnabled={false}
                                 style={{
                                     flex: 1
                                 }}
                                 contentContainerStyle={{
                                     // flex: 1,
                                     width: dimensions?.width
                                     // alignItems: 'center'
                                 }}
                                 ref={flatListRef}
                                 ListHeaderComponent={<>
                                     <View style={{
                                         paddingTop: 20
                                     }}>
                                         <View style={{
                                             flexDirection: 'row',
                                             alignItems: 'center',
                                             justifyContent: 'space-between'
                                         }}>
                                             <View style={{
                                                 flexDirection: 'row',
                                                 alignItems: 'center',
                                                 marginLeft: 10
                                             }}>
                                                 <IconImageM source={live}
                                                             width={50}
                                                             style={{
                                                                 // tintColor: theme.colors.primary,
                                                                 marginRight: 15
                                                             }}
                                                 />
                                                 <TextM color={theme.colors.primary}
                                                        fontSize={20}
                                                 >
                                                     {slotMomentFormatted}
                                                 </TextM>

                                             </View>
                                             <View style={{
                                                 flexDirection: 'row',
                                                 alignItems: 'center',
                                                 marginRight: 10
                                             }}>
                                                 <Pressable onPress={() => {
                                                     navigation.push("lives-form", {
                                                         live_id: live_id,
                                                         room_id: room_id
                                                     })
                                                 }}>
                                                     <FontAwesome5 name={"edit"}
                                                                   style={{marginRight: 15}}
                                                                   size={20}
                                                                   solid
                                                                   color={theme.colors.primary}
                                                     />
                                                 </Pressable>
                                                 {props.deleteLiveRequest ?
                                                     <ActivityIndicator color={theme.colors.primary}/> :
                                                     <Pressable onPress={onDeleteLive}>
                                                         <FontAwesome5 name={"trash"}
                                                                       size={20}
                                                                       color={theme.colors.primary}
                                                         />
                                                     </Pressable>}

                                             </View>
                                         </View>
                                         <View style={{
                                             flexDirection: 'row',
                                             alignItems: 'center',
                                             marginTop: 10,
                                             marginLeft: 10
                                         }}>
                                             <AvatarM size={50}
                                                      user={props.liveSession?.tutor}
                                                      style={{marginRight: 15}}
                                             />
                                             <TextM color={theme.colors.primary}
                                                    fontSize={20}
                                             >
                                                 {props.liveSession?.tutor?.display_name}
                                             </TextM>

                                         </View>
                                     </View>

                                     <View style={{
                                         flexDirection: "row",
                                         alignItems: 'center',
                                         justifyContent: 'center',
                                         width: dimensions?.width,
                                         borderColor: theme.colors.lightMore,
                                         marginTop: 20
                                     }}>
                                         <View style={{
                                             flexDirection: "row",
                                             alignItems: 'center',
                                             alignSelf: 'center',
                                             marginRight: 10
                                         }}>
                                             <FontAwesome5 solid name="user-friends" size={22}
                                                           color={theme.colors.primary}/>
                                             <TextM color={theme.colors.primary}
                                                    fontWeight={"Bold"}
                                                    fontSize={18}
                                                    style={{marginLeft: 10}}
                                             >
                                                 {"Participants"}
                                             </TextM>
                                         </View>

                                     </View>
                                     <Pressable onPress={() => {
                                         navigation.push("add-user-live")
                                     }}>
                                         <FontAwesome5 solid name="user-plus"
                                                       size={20}
                                                       color={theme.colors.primary}
                                                       style={{alignSelf: 'flex-end', marginRight: 5}}/>
                                     </Pressable>
                                 </>}
                                 showsHorizontalScrollIndicator={Platform.OS === "web" && false}
                                 renderSectionHeader={({section: {title}}) => (
                                     <View style={{
                                         paddingLeft: 15,
                                         paddingTop: 15,
                                         backgroundColor: theme.colors.whiteAlmost,
                                         flex: 1
                                     }}>
                                         <TextM color={theme.colors.light}
                                                fontSize={20}
                                         >
                                             {title}
                                         </TextM>
                                     </View>
                                 )}
                                 renderItem={(item) => {
                                     return <RankingListItem user={item.item}
                                                             isHighlighted={false}
                                                             height={60}
                                                             backofficeMode
                                                             onDelete={() => onDeleteUser(item.item?.id)}
                                                             isLoading={props.deleteUsersRequest && props.deletedUserId?.includes(item.item?.id)}
                                     />
                                 }}
                                 ListFooterComponent={<>
                                     <NextOccurencesScreen/>
                                 </>}
                    />
                </View>

            }
        </View>
    )
};

const mapStateToProps = state => {
    return {
        deleteUsersRequest: state.api.deleteUsersFromLive?.request,
        deleteLiveRequest: state.api.deleteLiveSession?.request,
        getLiveRequest: state.api.getLiveSessionAdmin?.request,
        deletedUserId: state.api.deleteUsersFromLive?.data?.user_ids,
        liveSession: state.data.backOffice?.liveSession
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LiveShow)