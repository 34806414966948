import {TouchableHighlight, View} from "react-native";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React, {useContext} from "react";
import {DimensionsContext} from "../../utils/mobileUtils";
import {IconImageM} from "../icons/IconsM";

export function StatisticsItemCol(props) {

    // ** Component props
    const {count, title, source, uri, rating, onPress, style} = props
    const dimensions = useContext(DimensionsContext)
    return <TouchableHighlight style={{
        // borderWidth: 1,
        // borderRadius: 15,
        // borderColor: theme.colors.lightMore,
        // marginRight: 10,
        marginTop: 10,
        // flexGrow: 0,
        alignItems: "center",
        justifyContent: "center",
        // height: 69,
        width: dimensions?.width / 4 - 4,
        // paddingHorizontal: 10,
        ...style
    }}
                               onPress={onPress}
                               underlayColor={theme.colors.lightMore}
    >
        <View style={{
            // paddingVertical: 10,
            borderColor: theme.colors.lightMore,
            // flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
        }}
        >
            <IconImageM width={22}
                        source={source}
                        uri={uri}
                        style={{
                            marginTop: 8
                            // position: "absolute",
                            // top: -8,
                            // left: -8
                        }}/>
            <View style={{
                // width: "100%",
                alignItems: "center",
                justifyContent: "center",
                marginTop: -5
                // paddingVertical: 8
            }}
            >
                <View style={{
                    height: 50,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "120%"
                }}
                >
                    <TextM fontWeight="Bold"
                           color={theme.colors.grey}
                           fontSize={count?.length > 4 ? 28 : count?.length > 3 ? 34 : 34}>{count ?? 0}</TextM>
                </View>
                <TextM fontWeight="Regular"
                       color={theme.colors.grey}
                       style={{
                           marginTop: -4,
                           textAlign: "center"
                       }}
                       wrap
                       fontSize={12}>{title}</TextM>

            </View>

        </View>
    </TouchableHighlight>;
}