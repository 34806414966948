import React from 'react';
import {TouchableOpacity, View} from "react-native";
import {useNavigation} from "@react-navigation/native";
import {IconImageM} from "@common/components/icons/IconsM";
import tutor from "../../assets/avatar/default/tutor.png"
import student from "../../assets/avatar/default/student.png"
import logo from "../../assets/logo-blue.png"
import TextM from "@common/components/text/TextM";
import TitleM from "@common/components/text/TitleM";
import LinkTextM from "@common/components/text/LinkTextM";


export const AccountTypeScreen = ({navigation}) => {


    const nav = useNavigation()

    return (
        <View style={{
            alignItems: 'center',
            flex: 1
        }}>

            <View style={{
                padding: 40
            }}>
                <IconImageM source={logo}
                            style={{marginVertical: 20, alignSelf: 'center'}}
                            width={150}
                            height={150 * 339 / 1024}
                />
                <TitleM style={{marginVertical: 10, alignSelf: 'center'}}>
                    Créer un compte
                </TitleM>

                <View style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    marginTop: 20,
                    marginBottom: 20
                }}>
                    <TouchableOpacity
                        onPress={() => {
                            nav.push("intro", {
                                name: "tina",
                                isTracked: false,
                                nextScreen: "register",
                                nextScreenParams: {}
                            })
                        }}
                        style={{marginHorizontal: 20}}>
                        <View style={{alignItems: 'center'}}>
                            <IconImageM source={student}
                                        style={{borderRadius: 70}}
                                        width={130}/>
                            <TextM fontWeight="SemiBold"
                                   style={{
                                marginTop: 4
                            }}>
                                Je suis un élève
                            </TextM>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        nav.push("register-tutor")
                    }} style={{marginHorizontal: 30}}>
                        <View style={{alignItems: 'center'}}>
                            <IconImageM source={tutor}
                                        style={{borderRadius: 70}}
                                        width={130}/>
                            <TextM fontWeight="SemiBold"
                                   style={{
                                marginTop: 4
                            }}>
                                Je suis un tuteur
                            </TextM>
                        </View>
                    </TouchableOpacity>
                </View>
                <TextM style={{alignSelf: 'center'}}>
                    Dèja un compte ? <LinkTextM onPress={() => {
                    nav.replace("login")
                }
                }>Connecte-toi</LinkTextM>
                </TextM>
            </View>
        </View>
    );
}