import React, {useContext, useEffect, useRef} from 'react';
import {Image, Keyboard, Platform, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import TextButtonM from "@common/components/button/TextButtonM";
import LinkTextM from "@common/components/text/LinkTextM";
import {IconButton} from "react-native-paper";
import {useForm} from "react-hook-form";
import yup from "@data/utility/validation"
import {yupResolver} from '@hookform/resolvers/yup';
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect, useDispatch} from "react-redux";
import {authService} from "@data/services/authService";
import * as Device from 'expo-device';
import ErrorTextM from "@common/components/text/ErrorTextM";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import logo from "../../assets/logo-blue.png";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {authConstants} from "@data/constants/authConstants";
import {useApiStatus} from "@common/hooks/useApiStatus";


const formSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required()
})

const LoginScreen = (props) => {

    const {navigation} = props

    const resolver = yupResolver(formSchema);
    const dimensions = useContext(DimensionsContext)

    const dispatch = useDispatch()

    const {control, handleSubmit, formState: {errors, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            email: '',
            password: ''
        }
    })

    const onSubmit = data => {
        if (props.loggingIn) return
        Keyboard.dismiss()
        if (!IS_WEB)
            props.apiRequest(authService.getSanctumToken, {}, {
                ...data,
                'device_name': Device.deviceName ?? "emu"
            })
        else
            props.apiRequestWithPrerequest(authService.getSanctumToken, {}, {
                ...data,
                'device_name': Device.deviceName ?? "WEB"
            })
    }

    const insets = useSafeAreaInsets()

    useEffect(() => {
        if (props.user || props.token)
            dispatch({type: authConstants.LOGOUT_401})

    }, [])

    const emailRef = useRef()
    const passwordRef = useRef()

    useApiStatus(
        authService.getSanctumToken, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )
    // useEffect(() => {
    //     console.log(props.error)
    // }, [props.error])

    // console.log(props.error)

    return (
        <View style={{
            backgroundColor: theme.colors.whiteAlmost,
            justifyContent: 'flex-start',
            // width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center",
            alignItems: "center",
            width: "100%"
        }}>
            <HeaderContainerM style={{
                justifyContent: "center",
                paddingRight: 15
            }}>
                <TextM fontSize={24}
                       fontWeight={"ExtraBold"}
                       color={theme.colors.primary}
                       style={{textAlign: "center"}}
                       wrap
                >Connexion</TextM>
                <IconButton
                    icon={"arrow-left"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>
            <View style={{
                paddingHorizontal: 15,
                paddingTop: 20,
                width: dimensions?.width,
                alignItems: "center"
            }}>
                <View style={{
                    marginHorizontal: 15
                }}>
                    <View style={{
                        marginBottom: 30,
                        // marginTop: insets.top + 10,
                        alignItems: "center",
                        alignSelf: 'center'
                    }}>
                        <Image style={{
                            width: 150,
                            height: 50
                        }}
                               resizeMode="contain"
                               source={logo}
                        />
                    </View>
                    <TextInputM
                        ref={emailRef}
                        placeholder="Adresse email"
                        name="email"
                        textContentType="emailAddress"
                        keyboardType="email-address"
                        control={control}
                        errors={errors}
                        onSubmitEditing={(e) => {
                            passwordRef.current?.focus()
                        }}
                    />

                    <TextInputM
                        ref={passwordRef}
                        placeholder="Mot de passe"
                        name="password"
                        control={control}
                        password
                        errors={errors}
                        onSubmitEditing={handleSubmit(onSubmit)}
                    />
                    <View style={{
                        width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                        alignSelf: "center"
                    }}>
                        <TextButtonM
                            // mode="text"
                            style={{
                                marginTop: 4,
                                alignSelf: "flex-end"
                            }}
                            fontWeight="ExtraBold"
                            onPress={() => {
                                //WebBrowser.openBrowserAsync("https://web.masteur.com/password/email")
                                navigation.push("reset-password")
                            }}
                            label="MOT DE PASSE OUBLIÉ ?"/>
                    </View>

                    <ErrorTextM errorData={props.error} style={{textAlign: "center"}}/>
                    <ErrorTextM errorData={props.errorWeb} style={{textAlign: "center"}}/>

                    <FilledButtonM
                        style={{marginTop: 20}}
                        disabled={!dirtyFields.email || !dirtyFields.password}
                        onPress={handleSubmit(onSubmit)}
                        loading={props.loggingIn || props.loggingInWeb}
                        label={"Connexion"}
                        // label={props.loggingIn ? "" : "Connexion"}
                    />


                    <View style={{
                        marginBottom: 20,
                        marginTop: 10,
                        paddingHorizontal: 10
                    }}>

                        <TextM style={{
                            fontSize: 12,
                            textAlign: 'center'
                        }} wrap>
                            En me connectant, j'accepte les <LinkTextM url="https://masteur.com/cgu">Conditions
                            générales
                            d'utilisation</LinkTextM> de Masteur. Je confirme avoir plus de 15 ans. Dans le cas
                            contraire,
                            je confirme avoir l'autorisation d'un parent pour utiliser ce service.
                        </TextM>
                    </View>

                </View>
            </View>
            <View style={{
                marginBottom: 30,
                marginTop: 30,
                paddingHorizontal: 10,
                justifySelf: "flex-end"
            }}>
                <TextM style={{alignSelf: 'center'}}>
                    Pas de compte ? <LinkTextM onPress={() => {
                    navigation.navigate("register-trial")
                }
                }>Je m'inscris</LinkTextM>
                </TextM>
            </View>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        loggingIn: state.api.getSanctumToken?.request,
        loggedIn: state.api.getSanctumToken?.success,
        error: state.api.getSanctumToken?.error,
        loggingInWeb: state.api.login?.request,
        loggedInWeb: state.api.login?.success,
        errorWeb: state.api.login?.error,
        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen)