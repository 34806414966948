import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {subscriptionService} from "@data/services/subscriptionService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import SuspensionRecap from "@common/account/SuspensionRecap";
import {ActivityIndicator} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import ErrorTextM from "@common/components/text/ErrorTextM";
import base64 from "@common/utils/base64";
import SuspensionContinuityInformation from "@common/account/SubscriptionContinuityInformation";
import moment from "moment";
import {ChooseSubscriptionOffer} from "../../auth/SubscribeScreen";
import {isSubscribed} from "@data/utility/dataUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const SubscriptionContinuity = (props) => {

    const user = props.route.params?.user ?? ""
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});

    const defaultValues = {}
    const email = base64.decode(user)
    useEffect(() => {
        props.apiRequest(subscriptionService.getSubscribePricing, {}, {}, {
            email,
            continuity: 1
        })
    }, []);


    const isSubscriptionCanceled = props.subscriptionUser?.subscription_state?.status === "ended" || props.subscriptionUser?.subscription_state?.status === "on_grace_period"
    const subscriptionEndDate = isSubscriptionCanceled ? moment(props.subscriptionUser?.subscription_state?.end_date) : null

    // if subscription end date is between 15 of july and 5 of september, the next product is the holidayworkbook
    const nextProductHolidayWorkbook = subscriptionEndDate?.isValid() && !subscriptionEndDate.isBetween(moment().month(6).date(15), moment().month(8).date(5))

    const pricingTableData = props.pricingTableData
    const pricingTableDataNextMode = props.pricingTableDataNextMode
    const commitmentEndDate = props.commitmentEndDate
    const billingPortalUrl = props.billingPortalUrl
    const emailUser = props.user

    const formData = [
        {
            label: nextProductHolidayWorkbook ? "Cet été : cahiers de vacances en live" : "Pendant l'année scolaire, l'accompagnement se poursuit !",
            // description: `Compte élève : ${props.displayName}`,
            type: "custom",
            showNextButton: false,
            name: "information",
            component: SuspensionContinuityInformation,
            componentProps: {nextProductHolidayWorkbook},
            defaultValue: "",
            validationRules: null
        },
        {
            is_masked: nextProductHolidayWorkbook,
            label: "Choisissez votre offre", // 1h / semaine à partir de x, 2h / semaine à partir de x, 3h / semaine à partir de x
            description: `Compte élève : ${props.displayName}`,
            type: "custom",
            inputs: [
                {
                    name: "product_name",
                    defaultValue: "essential"
                },
                {
                    name: "with_commitment",
                    defaultValue: 1
                }
            ],
            component: ChooseSubscriptionOffer,
            componentProps: {
                userEmail: email,
                pricingTableData,
                pricingTableDataNextMode
            },
            defaultValue: "",
            validationRules: null
        },
        {
            label: "Récapitulatif",
            description: `Compte élève : ${props.displayName}`,
            type: "custom",
            showNextButton: false,
            options: [],
            component: SuspensionRecap,
            componentProps: {
                userEmail: email,
                pricingTableData,
                pricingTableDataNextMode,
                commitmentEndDate
            },
            defaultValue: false
        }
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(formValues)

    }, [formValues])

    const onSubmit = data => {
        if (props.suspensionRequest) return
        // console.log(data)

        props.apiRequest(subscriptionService.suspendSubscription, {}, {
            ...data,
            "email": email
        })
    }

    useApiStatus(
        subscriptionService.getSubscribePricing, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {

            // if user is not subscribed, redirect to main subscribe screen
            if (!isSubscribed(data?.user)) {
                navigation.replace("subscribe", {
                    user
                })
            }
        }, // success callback
        (error) => {
            console.log(error)
        }, // error callback
        true
    )

    useApiStatus(
        subscriptionService.suspendSubscription, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            navigation.replace("subscribe", {
                user,
                show_details: 1
            })
            DialogM.show({
                text1: "Félicitations ! 🎉",
                text2: "Votre choix a bien été enregistré. Indiquez maintenant vos choix de matières et d'horaires."
            })
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>
            {/*<HeaderContainerM style={{justifyContent: "space-between", width: "100%"}}>*/}
            {/*    <View style={{alignItems: "center", justifyContent: "center", width: "100%"}}>*/}
            {/*        <TitleM>Suite de votre abonnement</TitleM>*/}

            {/*    </View>*/}
            {/*</HeaderContainerM>*/}

            {props.request ? <ActivityIndicator color={theme.colors.primary}
                                                style={{marginVertical: 20}}/>
                : props.error ? <View style={{
                        backgroundColor: theme.colors.errorLight,
                        // marginTop: 20,
                        width: "100%",
                        alignItems: "center",
                        paddingVertical: 10
                    }}>
                        <TextM color={theme.colors.error} fontWeight={"Bold"}>ERREUR</TextM>
                        <ErrorTextM errorData={props.error}/>
                    </View>
                    : <FormStepper navigation={navigation}
                                   track
                                   formData={formData?.filter(x => !x.is_masked)}
                                   champion={null}
                                   formValues={formValues}
                                   setFormValues={setFormValues}
                                   defaultValues={defaultValues}
                                   onSubmit={onSubmit}
                                   submitted={props.suspensionRequest}
                                   previousRouteName={"menu"}
                                   submittedMessage=""
                                   serverError={props.suspensionError}
                                   submitButtonText={"Je confirme mon choix"}
                    />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        holidayOptions: state.data.formData?.holidayOptions,
        schoolSubjects: state.data.formData?.schoolSubjects,
        nextPaymentDate: state.data.formData?.nextPaymentDate,
        currentValues: state.data.formData?.currentValues,
        displayName: state.data.formData?.user?.display_name,
        remainingDiscounts: state.data.formData?.remainingDiscounts,
        subscriptionUser: state.data.formData?.user,
        discountPercentage: state.data.formData?.discountPercentage,
        request: state.api.getSubscribePricing?.request,
        success: state.api.getSubscribePricing?.success,
        error: state.api.getSubscribePricing?.error,
        suspensionRequest: state.api.suspendSubscription?.request,
        suspensionError: state.api.suspendSubscription?.error,
        user: state.data.formData?.user,
        billingPortalUrl: state.data.formData?.billingPortalUrl,
        pricingTableData: state.data.formData?.pricingTableData,
        pricingTableDataNextMode: state.data.formData?.pricingTableDataNextMode,
        commitmentEndDate: state.data.formData?.commitmentEndDate
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionContinuity)
