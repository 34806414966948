import React, {useEffect, useState} from 'react';
import {Keyboard, StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {validation} from "@data/utility/validation";
import {connect} from "react-redux";
import {ActivityIndicator, Text} from "react-native-paper";
import LinkTextM from "@common/components/text/LinkTextM";
import {authService} from "@data/services/authService";
import * as Device from "expo-device";
import {studentClasses, subjectPriorities} from "@data/constants/formConstants";
import {theme} from "@common/theme/theme";
import graduate from "../../assets/icons/reasons/graduate.png"
import star from "../../assets/icons/reasons/star.png"
import method from "../../assets/icons/reasons/method.png"
import study from "../../assets/icons/reasons/study.png"
import move_ahead from "../../assets/icons/reasons/move_ahead.png"
import offline_mode from "../../assets/icons/reasons/offline_mode.png"
import organize from "../../assets/icons/reasons/organize.png"
import other from "../../assets/icons/reasons/other.png"
import grade from "../../assets/icons/reasons/grade.png"
import {schoolSubjectsService} from "@data/services/schoolSubjectsService";
import * as amplitude from "@amplitude/analytics-react-native";
import {IS_WEB} from "@common/utils/mobileUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import SponsorshipConfirmation from "@common/account/SponsorshipConfirmation";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {hasRole} from "@data/utility/ability";
import {backOfficeService} from "@data/services/backOfficeService";

export const reasons = [
    {label: 'Me mettre au travail', value: 'Me mettre au travail', source: study},
    {
        label: 'Être plus concentré dans mon travail',
        value: 'Être plus concentré dans mon travail',
        source: offline_mode
    },
    {label: 'Améliorer ma méthode de travail', value: 'Améliorer ma méthode de travail', source: method},
    {label: 'M\'aider à m\'organiser', value: 'M\'aider à m\'organiser', source: organize},
    {label: 'Valoriser mon travail', value: 'Valoriser mon travail', source: star},
    {label: 'Augmenter mes notes', value: 'Augmenter mes notes', source: grade},
    {label: 'Prendre de l\'avance', value: 'Prendre de l\'avance', source: move_ahead},
    {label: 'Me préparer aux études supérieures', value: 'Me préparer aux études supérieures', source: graduate},
    {label: 'Autre', value: 'Autre', source: other}
]

const RegisterScreen = (props) => {

    // ** Component props
    const {navigation, route} = props
    const isAdmin = route?.name !== "register"
    const apiService = isAdmin ? backOfficeService.newStudent : authService.registerAndGetSanctumToken
    const pronounLetter = isAdmin ? "s" : "t"
    const sponsorshipCode = props.route?.params?.sponsorship_code ?? null
    const [formValues, setFormValues] = useState(null)
    // const currentUserProfile = formValues?.user_profile
    const currentEmail = formValues?.email
    const currentStudentClass = formValues?.student_class
    const currentDisplayName = formValues?.firstname + " " + formValues?.lastname?.substring(0, 1) + "."
    const isDisplayNameEmpty = (formValues?.firstname === "") || (formValues?.lastname === "")


    useApiStatus(
        backOfficeService.newStudent, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            props.apiIdle(backOfficeService.newStudent)
            // navigation.goBack()
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "L'élève a bien été créé"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )

    const formData = [
        {
            label: "Bienvenue ! ",
            is_masked: !sponsorshipCode,
            type: "custom", // select.single, select.multiple, text, date
            name: "channel",
            component: SponsorshipConfirmation,
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Comment as-tu entendu parler de Masteur ?",
            // is_masked: !currentChallenge?.needs_school_subject,
            type: "select.single", // select.single, select.multiple, text, date
            name: "channel",
            options: [
                {value: "Famille/amis", label: "Famille/amis"},
                {value: "Facebook/Instagram", label: "Facebook/Instagram"},
                {value: "Recherche Google", label: "Recherche Google"},
                {value: "App Store ou Play Store", label: "App Store ou Play Store"},
                {value: "Actualités/article/blog", label: "Actualités/article/blog"},
                {value: "Autre", label: "Autre"}
            ],
            defaultValue: "",
            validationRules: {...validation.required()}
            // validationSchema: yup.string().required()
        },
        // {
        //     label: "Tu es",
        //     // is_masked: !currentChallenge?.needs_school_subject,
        //     type: "select.single", // select.single, select.multiple, text, date
        //     name: "user_profile",
        //     options: [
        //         {source: student, value: "student", label: "Un élève"},
        //         {source: parent, value: "parent", label: "Un parent", disabled: true}
        //         // {source: tutor, value: "tutor", label: "Un tuteur", disabled: true}
        //     ],
        //     defaultValue: "",
        //     validationRules: {...validation.required()}
        //     // validationSchema: yup.string().required()
        // },
        {
            label: "En quelle classe es-tu ?",
            // is_masked: currentUserProfile !== "student",
            type: "select.single", // select.single, select.multiple, text, date
            name: "student_class",
            options: studentClasses?.map(x => {
                return {value: x, label: x}
            }),
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Quelles sont tes matières de spécialité ?",
            is_masked: currentStudentClass !== "1ère" && currentStudentClass !== "Tle",
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "specialty_school_subjects",
            height: 70,
            options: props.schoolSubjects
                ?.filter(ss => ss?.is_specialty)
                // ?.slice(0, 3)
                ?.map(x => {
                    return {icon: x?.icon, value: x.id, label: x.name}
                }),
            defaultValue: ""
        },
        {
            label: "En quoi Masteur peut t'être utile ?",
            // is_masked: currentUserProfile !== "student",
            type: "select.single", // select.single, select.multiple, text, date
            name: "reason",
            options: reasons,
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Quelles sont les matières où tu as le plus besoin de t'améliorer ?",
            // is_masked: currentUserProfile !== "student",
            type: "select.single", // select.single, select.multiple, text, date
            name: "subject_priority",
            options: subjectPriorities,
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Comment t'appelles-tu ?",
            // is_masked: !currentChallenge?.needs_domain,
            type: "text.multiple", // select.single, select.multiple, text, date
            indication: isDisplayNameEmpty ? "" : 'Tu apparaîtras sous le nom "' + currentDisplayName + '" sur ton profil Masteur.',
            inputs: [
                {
                    name: "firstname",
                    placeholder: "Prénom",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                },
                {
                    name: "lastname",
                    placeholder: "Nom",
                    defaultValue: "",
                    validationRules: {...validation.required()}
                }
            ]
        },
        {
            label: "Indique ton adresse email",
            // is_masked: !currentChallenge?.needs_domain,
            type: "text.multiple", // select.single, select.multiple, text, date
            indication: "Tu devras confirmer l'adresse email par la suite.",
            inputs: [
                {
                    name: "email",
                    placeholder: "Adresse email",
                    keyboardType: "email-address",
                    defaultValue: "",
                    liveCheck: isAdmin ? null : {service: authService.checkEmailAddress},
                    liveCheckPreRequest: true,
                    validationRules: {
                        ...validation.required(),
                        ...validation.email()
                    }
                }
                // {
                //     name: "mobile",
                //     placeholder: "Téléphone portable",
                //     defaultValue: "",
                //     keyboardType: "phone-pad",
                //     validationRules: {
                //         ...validation.required(),
                //         ...validation.phone()
                //     }
                // }
            ]
        },
        {
            label: "Indique les coordonnées de l'un de tes parents",
            indication: "Nous l'informerons de tes progrès.",
            // is_masked: !currentChallenge?.needs_domain,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "parent_mobile",
                    placeholder: "Téléphone portable d'un parent",
                    defaultValue: "",
                    keyboardType: "phone-pad",
                    validationRules: {
                        ...validation.required(),
                        ...validation.phone()
                    }
                },
                {
                    name: "parent_email",
                    placeholder: "Adresse email d'un parent",
                    keyboardType: "email-address",
                    defaultValue: props.user?.parent_email ?? "",
                    validationRules: {
                        // ...validation.required(),
                        ...validation.email(),
                        // ...validation.differentFrom(currentEmail)
                    }
                },
            ]
        },
        {
            label: "Choisis ton mot de passe",
            is_masked: isAdmin,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [

                {
                    name: "password",
                    placeholder: "Mot de passe",
                    password: true,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required(),
                        ...validation.minLength(8)
                    }
                }
            ],
            bottomComponent: <Text style={{
                fontSize: 12,
                textAlign: 'center',
                marginTop: IS_WEB ? 10 : 0
                // marginBottom: 10
            }}>
                En t'inscrivant, tu acceptes les <LinkTextM url="https://masteur.com/cgu">Conditions générales
                d'utilisation</LinkTextM> de Masteur. Tu confirmes avoir plus de 15 ans. Dans le cas contraire, tu
                confirmes avoir l'autorisation d'un parent pour utiliser ce service.
            </Text>
        }
    ]

    const defaultValues = {}
    // const formSchema = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log(defaultValues)

    }, [formValues])

    useEffect(() => {
        if (props.registered || props.newStudentSuccess) {
            props.apiIdle(apiService)
            // console.log(props.postChallengeSuccessData)
            // navigation.replace("", {
            //
            // })
        }

        if (props.error) {
            // console.log(props.error)
        }

    }, [props.registered, props.error, props.newStudentSuccess, props.newStudentError])


    const onSubmit = data => {
        if (props.registering || props.newStudentRequest) return
        Keyboard.dismiss()
        // console.log(data)
        props.apiRequestWithPrerequest(apiService, {}, {
            ...data,
            'user_profile': 'student',
            'device_name': Device.deviceName ?? IS_WEB ? "WEB" : "emu",
            'sponsorship_code': sponsorshipCode
        })
    }

    useApiStatus(
        authService.registerAndGetSanctumToken, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (sponsorshipCode) {
                const congrats = [{
                    congratsType: congratsTypes.GENERIC,
                    congratsData: {
                        title: "Félicitations !",
                        text: "Parrainage effectué avec succès."
                    }
                }]
                navigation.replace("congrats", {congrats, nextScreen: "validate-email"})
            }
        }
    )

    useEffect(() => {
        props.apiRequest(schoolSubjectsService.getSchoolSubjects)
    }, [])

    useEffect(() => {
        if (props.getSchoolSubjectsSuccess) {
            props.apiIdle(schoolSubjectsService.getSchoolSubjects)
        }
        if (props.getSchoolSubjectsError) {
            props.apiIdle(schoolSubjectsService.getSchoolSubjects)
            DialogM.show({
                text1: 'Erreur', text2: props.getSchoolSubjectsError.message
            })
            //alertPolyfill('Erreur', props.getSchoolSubjectsError.message)

        }
    }, [props.getSchoolSubjectsSuccess, props.getSchoolSubjectsError])

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            {props.getSchoolSubjectsRequest
                ? <ActivityIndicator animating={true}
                                     style={{
                                         marginTop: 50
                                     }}
                                     size="large"
                                     color={theme.colors.primary}/>
                : <FormStepper navigation={navigation}
                               track
                               formData={formData?.filter(x => !x.is_masked)}
                               champion={isAdmin ? null : {name: "tina", variant: "main"}}
                               formValues={formValues}
                               setFormValues={setFormValues}
                               defaultValues={defaultValues}
                               onSubmit={onSubmit}
                               submitted={props.registering || props.newStudentRequest}
                               previousRouteName={"menu"}
                               submittedMessage=""
                               serverError={props.error || props.newStudentError}
                />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        registered: state.api.registerAndGetSanctumToken?.success,
        registering: state.api.registerAndGetSanctumToken?.request,
        error: state.api.registerAndGetSanctumToken?.error,

        newStudentSuccess: state.api.newStudent?.success,
        newStudentRequest: state.api.newStudent?.request,
        newStudentError: state.api.newStudent?.error,

        getSchoolSubjectsRequest: state.api.getSchoolSubjects?.request,
        getSchoolSubjectsSuccess: state.api.getSchoolSubjects?.success,
        getSchoolSubjectsError: state.api.getSchoolSubjects?.error,

        schoolSubjects: state.data.staticData?.school_subjects,

        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiRequestWithPrerequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScreen)