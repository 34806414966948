import axios from "axios"

function newGrade({id}, formData) {
    return axios.post(`/api/v1/students/${id}/grades`, formData)
}

function deleteGrade({id}) {
    return axios.delete(`/api/v1/grades/${id}`)
}

function updateGrade({id}, formData) {
    return axios.put(`/api/v1/grades/${id}`, formData)
}

export const gradesService = {
    newGrade,
    deleteGrade,
    updateGrade
}

