import React, {useContext, useState} from 'react'
import {PropsContext, RemoteAudioMute, RemoteVideoMute, RtcContext, SwapUser} from "agora-react-uikit";
import {AgoraVideoPlayer} from "agora-rtc-react";
import AvatarM from "@common/components/avatar/AvatarM";
import {View} from "react-native";
import {UsernameComponent} from "@common/video-call/UsernameComponent";

/**
 * React context to expose user array displayed in the smaller view
 */
const MinVideoViewWebM = (props) => {
    const {mediaStore, localAudioTrack} = useContext(RtcContext)
    const {styleProps, rtcProps} = useContext(PropsContext)
    const {minViewStyles, videoMode, minViewOverlayContainer} = styleProps || {}
    const renderModeProp = videoMode?.min
    const [isShown, setIsShown] = useState(false)
    const {user} = props
    const userObject = rtcProps?.users?.find(u => u?.id === user?.uid)
    const localUser = {
        id: rtcProps?.uid,
        avatar: rtcProps?.avatar,
        display_name: rtcProps?.username,
        is_tutor: !rtcProps?.isStudent
    }
    const isMuted = user?.uid === 0 ? localAudioTrack?.muted : user?.hasAudio !== 1
    return (
        <div
            style={{
                ...{display: 'flex', flex: 1},
                ...minViewStyles,
            }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            {user.hasVideo === 1 && mediaStore[user.uid].videoTrack ? (
                <div
                    style={{
                        ...{
                            display: 'flex',
                            flex: 1,
                            position: 'relative'
                        },
                    }}
                >
                    <AgoraVideoPlayer
                        style={{flex: 10, display: 'flex'}}
                        config={{
                            fit: renderModeProp !== undefined ? renderModeProp : 'cover'
                        }}
                        videoTrack={mediaStore[user.uid].videoTrack}
                    />
                    <UsernameComponent userObject={userObject ?? localUser}
                                       rtcProps={rtcProps}
                                       user={user}
                                       condition={isMuted}
                                       soundMode={rtcProps.soundModeIds?.includes(userObject ? user.uid : rtcProps?.uid)}
                                       speakerStyles={props.speakerStyle}
                                       withVideo
                    />
                    {isShown && (
                        <div
                            style={{
                                ...{
                                    margin: 4,
                                    position: 'absolute',
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column'
                                },
                                ...minViewOverlayContainer
                            }}
                        >
                            {/*{!rtcProps.disableRtm && <RemoteVideoMute UIKitUser={user}/>}*/}
                            {/*{!rtcProps.disableRtm && <RemoteAudioMute UIKitUser={user}/>}*/}
                            <SwapUser UIKitUser={user}/>
                        </div>
                    )}
                </div>
            ) : (
                <View style={{alignItems: 'center', justifyContent: 'center', flex: 1}}>
                    <AvatarM user={userObject ?? localUser} size={70} style={{alignSelf: 'center'}}/>
                    <UsernameComponent userObject={userObject ?? localUser} rtcProps={rtcProps} user={user}
                                       speakerStyles={props.speakerStyle}
                                       soundMode={rtcProps.soundModeIds?.includes(userObject ? user.uid : rtcProps?.uid)}
                                       condition={isMuted}
                    />
                </View>
            )}
        </div>
    )
}

export default MinVideoViewWebM