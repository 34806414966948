import React, {useContext, useRef, useState} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {calculateTimerColor} from "./TimerContainer";
import {ModalM} from "@common/components/modal/ModalM";
import {Dimensions, Linking, Platform, Pressable, ScrollView, View} from "react-native";
import CircularProgress from "react-native-circular-progress-indicator/src/circularProgress";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {displayTimeSeconds} from "@data/utility/Utils";
import WebView from "react-native-webview";
import {ActivityIndicator, IconButton, ProgressBar} from "react-native-paper";
import {PinchableBox} from "@common/components/image/PinchableBox";
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withSequence,
    withTiming
} from "react-native-reanimated";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {Menu3Point} from "@common/components/menu/Menu3Point";
import {activitiesService} from "@data/services/activitiesService";
import {
    activityOfflineModeInvalid,
    activityStartedWithOfflineMode,
    setIsInternetReachable,
    setIsOfflineModeEnabled,
    timerStepStarted
} from "@data/redux/actions/offlineMode";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {activityTypesActivitySlugs, definedMaterialSlug} from "../todo/ActivityPicker";
import {YoutubePlayer} from "./YoutubePlayer";

const MaterialDisplayModal = (props) => {
    const {
        handleShowModal,
        material,
        isOpen,
        startChrono,
        status = null,
        estimatedDuration = 0,
        time = null,
        noModal = false,
        manageTodo = false,
        field,
        goToNextStep
    } = props;

    const dimensions = useContext(DimensionsContext);

    const materialType = material?.url
        ? "url"
        : material?.attachments?.length > 0
            ? "image"
            : "text";
    // console.log(field)

    const timerProgress = (time * 100) / estimatedDuration;
    const isUrl = materialType === "url";
    const isImage = materialType === "image";
    const isText = materialType === "text";
    const hasTextSubheader = !isText && !!material?.text && material?.text !== "";
    const timerColor = calculateTimerColor(timerProgress, status, isImage);

    const dMaterialSlug = definedMaterialSlug(
        activityTypesActivitySlugs?.[material?.activity_type]
    );

    const opacity = useSharedValue(0);

    // Set the opacity value to animate between 0 and 1
    opacity.value = withRepeat(
        withSequence(
            withTiming(1, {duration: 500, easing: Easing.ease}),
            withTiming(0, {duration: 500, easing: Easing.ease})
        ),
        -1,
        true
    );
    const style = useAnimatedStyle(
        () => ({
            opacity: opacity.value
        }),
        []
    );

    // ** URL CASE **

    const webViewRef = useRef();

    const [isLoading, setIsLoading] = React.useState(true);
    const [loadingProgress, setLoadingProgress] = React.useState(0);

    const onLoadingEvent = (syntheticEvent) => {
        // update component to be aware of loading status
        const {nativeEvent} = syntheticEvent;
        setIsLoading(nativeEvent.loading);
        if (!nativeEvent.loading) {
            setTimeout(() => {
                setLoadingProgress(0);
            }, 300);
        }
    };
    const onLoadProgress = ({nativeEvent}) => {
        setLoadingProgress(nativeEvent.progress);
    };
    const renderUrlFooter = () => null;

    const renderUrlBody = () => {
        //if android and url corresponds to pdf, prepend with https://docs.google.com/gview?embedded=true&url=
        const url =
            Platform.OS === "android" &&
            material?.url?.endsWith(".pdf") &&
            material?.url?.startsWith("https")
                ? "https://docs.google.com/gview?embedded=true&url=" + material?.url
                : material?.url;
        // const url = material?.url

        return (
            <>
                <View
                    style={{
                        width: "100%",
                        height: 6
                    }}
                >
                    <ProgressBar
                        progress={loadingProgress}
                        color={theme.colors.primary}
                        style={{
                            width: "100%",
                            height: 6
                            // borderRadius: 15,
                            // backgroundColor: theme.colors.white,
                        }}
                    />
                </View>
                {!IS_WEB ? (
                    <WebView
                        style={{flex: 1}}
                        ref={webViewRef}
                        javaScriptEnabled={true}
                        allowsFullscreenVideo={true}
                        source={{uri: url}}
                        onLoadStart={onLoadingEvent}
                        onLoadEnd={onLoadingEvent}
                        onLoadProgress={onLoadProgress}
                    />
                ) : (
                    <View style={{flex: 1, alignItems: "center"}}>
                        {material?.url?.includes("youtube") ? (
                            <YoutubePlayer url={material?.url}/>
                        ) : (
                            <FilledButtonM
                                onPress={() => {
                                    Linking.openURL(material?.url).then();
                                }}
                                style={{marginTop: 10}}
                                color={theme.colors.primary}
                                label={"Ouvrir le lien"}
                                icon={"link"}
                            />
                        )}
                    </View>
                )}
            </>
        );
    };

    // ** IMAGE CASE **

    const zoomRef = useRef();
    const renderImageFooter = () => (
        <View
            style={{flexDirection: "row", width: "100%", justifyContent: "center"}}
        >
            {
                <IconButton
                    size={30}
                    icon={"minus-circle"}
                    iconColor={noModal ? theme.colors.primary : theme.colors.white}
                    style={{}}
                    containerColor={
                        noModal ? theme.colors.white : theme.colors.blackTransparent
                    }
                    onPress={() => {
                        zoomRef.current?.zoomOut();
                    }}
                />
            }
            <IconButton
                size={30}
                icon={"redo"}
                iconColor={noModal ? theme.colors.primary : theme.colors.white}
                containerColor={
                    noModal ? theme.colors.white : theme.colors.blackTransparent
                }
                onPress={() => {
                    zoomRef.current?.rotate();
                }}
            />
            {
                <IconButton
                    size={30}
                    icon={"plus-circle"}
                    iconColor={noModal ? theme.colors.primary : theme.colors.white}
                    containerColor={
                        noModal ? theme.colors.white : theme.colors.blackTransparent
                    }
                    onPress={() => {
                        zoomRef.current?.zoomIn();
                    }}
                />
            }
        </View>
    );
    const renderImageBody = () => (
        <View
            style={{
                flex: 1
            }}
        >
            <PinchableBox imageUri={material?.attachments[0]} ref={zoomRef}/>
        </View>
    );

    // ** TEXT CASE **
    const renderTextFooter = () => null;
    const renderTextBody = () => (
        <ScrollView>
            <View
                style={{
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingHorizontal: 10
                }}
            >
                <TextM style={{textAlign: "center"}} fontSize={20} wrap>
                    {material?.text}
                </TextM>
            </View>
        </ScrollView>
    );
    const [subheaderTextExpended, setSubheaderTextExpended] = useState(false);
    const onSubheaderTextPress = () => {
        setSubheaderTextExpended(!subheaderTextExpended);
    };
    const subheaderText =
        subheaderTextExpended || material?.text?.length <= (noModal ? 100 : 45)
            ? material?.text
            : material?.text?.substring(0, 45) + "...";
    const subheaderTextColor = isImage ? theme.colors.white : theme.colors.grey;

    useApiStatus(
        activitiesService.deleteMaterial, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            handleShowModal();
        } // success callback
    );
    const renderContent = () => {
        return (
            <>
            {!noModal && <View
                    style={{
                        width: "100%",
                        height: 60,
                        paddingRight: 5,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        {!noModal && (
                            <IconButton
                                icon={"times"}
                                iconColor={isImage ? theme.colors.white : theme.colors.primary}
                                size={32}
                                onPress={handleShowModal}
                            />
                        )}
                        {isUrl && (
                            <IconButton
                                icon={"sync"}
                                iconColor={isImage ? theme.colors.white : theme.colors.primary}
                                size={24}
                                onPress={() => {
                                    webViewRef.current?.reload();
                                }}
                            />
                        )}
                    </View>

                    {estimatedDuration > 0 && (
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            {status !== "finished" && timerProgress > 100 ? (
                                <Animated.View
                                    style={[
                                        {
                                            marginRight: 6
                                        },
                                        style
                                    ]}
                                >
                                    <FontAwesome5
                                        name={"exclamation-circle"}
                                        size={26}
                                        color={timerColor}
                                    />
                                </Animated.View>
                            ) : IS_WEB ? (
                                <></>
                            ) : (
                                !noModal && (
                                    <CircularProgress
                                        value={Math.min(timerProgress, 100)}
                                        radius={16}
                                        activeStrokeColor={timerColor}
                                        inActiveStrokeColor={timerColor}
                                        activeStrokeWidth={10}
                                        inActiveStrokeWidth={10}
                                        inActiveStrokeOpacity={0.1}
                                        showProgressValue={false}
                                    />
                                )
                            )}
                            {!noModal && (
                                <TextM
                                    fontWeight={"Medium"}
                                    color={timerColor}
                                    style={{
                                        width: 80,
                                        textAlign: "left"
                                    }}
                                    fontSize={24}
                                >
                                    {displayTimeSeconds(time)}
                                </TextM>
                            )}
                            {props.deleteMaterialRequest ? (
                                <ActivityIndicator
                                    color={isImage ? theme.colors.white : theme.colors.primary}
                                />
                            ) : (
                                <Menu3Point
                                    menuItems={[
                                        {
                                            title: "Supprimer " + (dMaterialSlug ?? "le support"),
                                            onPress: () => {
                                                props.apiRequest(activitiesService.deleteMaterial, {
                                                    id: material?.id
                                                });
                                            }
                                        }
                                    ]}
                                    color={isImage ? theme.colors.white : theme.colors.primary}
                                />
                            )}
                        </View>
                    )}
                </View>}
                <>
                    {hasTextSubheader && !noModal && (
                        <Pressable
                            style={{width: "100%", paddingBottom: 5}}
                            onPress={onSubheaderTextPress}
                        >
                            <TextM
                                color={subheaderTextColor}
                                style={{textAlign: "center"}}
                                fontWeight={"Bold"}
                                wrap
                            >
                                {subheaderText}
                            </TextM>
                        </Pressable>
                    )}
                    {isUrl
                        ? renderUrlBody()
                        : isImage
                            ? renderImageBody()
                            : renderTextBody()}
                    {isUrl
                        ? renderUrlFooter()
                        : isImage
                            ? renderImageFooter()
                            : renderTextFooter()}
                    {noModal && <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            position: "absolute",
                            top: 10,
                            left: 10,
                            backgroundColor: theme.colors.white,
                            borderRadius: 50,
                        }}
                    >
                        {isUrl && (
                            <IconButton
                                icon={"sync"}
                                iconColor={isImage ? theme.colors.white : theme.colors.primary}
                                size={24}
                                onPress={() => {
                                    webViewRef.current?.reload();
                                }}
                            />
                        )}
                    </View>}
                    {hasTextSubheader && noModal && (
                        <Pressable
                            style={{width: "100%", paddingVertical: 10}}
                            onPress={onSubheaderTextPress}
                        >
                            <TextM
                                color={subheaderTextColor}
                                style={{textAlign: "center"}}
                                fontWeight={"Bold"}
                                fontSize={12}
                                wrap
                            >
                                {subheaderText}
                            </TextM>
                        </Pressable>
                    )}
                    {!status && estimatedDuration > 0 && (
                        <FilledButtonM
                            color={theme.colors.success}
                            icon={"stopwatch"}
                            onPress={() => {
                                startChrono();
                                if (!IS_WEB) handleShowModal();
                            }}
                            style={{
                                width: "90%",
                                marginBottom: 10,
                                alignSelf: "center"
                            }}
                            label="C'est parti !"
                        />
                    )}
                    {manageTodo && (
                        <FilledButtonM
                            color={theme.colors.success}
                            onPress={() => {
                                if (
                                    props.materialUrlField.value !== "" ||
                                    props.materialTitleField.value !== "" ||
                                    props.materialAttachmentsField.value?.length > 0
                                ) {
                                    props.materialTitleField.onChange("");
                                    props.materialUrlField.onChange("");
                                    props.materialAttachmentsField.onChange([]);
                                }
                                field.onChange(material?.id);
                                goToNextStep();
                            }}
                            style={{
                                width: dimensions?.width > 800 ? "50%" : "80%",
                                marginVertical: 10,
                                alignSelf: "center"
                            }}
                            label={
                                ["written_exercise", "oral_exercise"].includes(
                                    material?.activity_type
                                )
                                    ? "Choisir cet énoncé"
                                    : "Choisir ce support"
                            }
                        />
                    )}
                </>
            </>
        );
    };
    return noModal ? (
        <View
            style={{
                width: Dimensions.get("window").width,
                flex: 1,
                backgroundColor: theme.colors.white,
                marginBottom: 20
            }}
        >
            {renderContent()}
        </View>
    ) : (
        <ModalM
            visible={isOpen}
            onDismiss={handleShowModal}
            style={{
                width: "96%",
                maxHeight: "90%",
                height: "90%",
                flex: 1,
                // alignSelf: "center",
                backgroundColor: isImage ? theme.colors.black : theme.colors.white
            }}
        >
            {renderContent()}
        </ModalM>
    );
};

const mapStateToProps = (state) => {
    return {
        deleteMaterialRequest: state.api?.deleteMaterial?.request
    };
};

const mapDispatchToProps = {
    activityStartedWithOfflineMode,
    activityOfflineModeInvalid,
    setIsInternetReachable,
    setIsOfflineModeEnabled,
    timerStepStarted,
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialDisplayModal);
