export const facialHairs = {
    facialHair1: true,
    facialHair2: true,
    facialHair3: true,
    facialHair4: true,
    facialHair5: true,
    facialHair6: true,
};
export const facialHairsList = [
    {
        name: "facialHair1"
    },
    {
        name: "facialHair2"
    },
    {
        name: "facialHair3"
    },
    {
        name: "facialHair4"
    },
    {
        name: "facialHair5"
    },
    {
        name: "facialHair6"
    },
];
export const facialHairColors = {
    fhc1: '#362d46',
    fhc2: '#665e97',
    fhc3: '#5ac4d4',
    fhc4: '#dee2f5',
    fhc5: '#6b4445',
    fhc6: '#f29c64',
    fhc7: '#e16381',
    fhc8: '#e15b65',
};

export const facialHairColorsList = [
    {
        name: "fhc1",
        color: "#362d46"
    }, {
        name: "fhc2",
        color: "#665e97"
    }, {
        name: "fhc3",
        color: "#5ac4d4"
    }, {
        name: "fhc4",
        color: "#dee2f5"
    }, {
        name: "fhc5",
        color: "#6b4445"
    }, {
        name: "fhc6",
        color: "#f29c64"
    }, {
        name: "fhc7",
        color: "#e16381"
    }, {
        name: "fhc8",
        color: "#e15b65"
    },
];