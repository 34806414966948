import React, {useContext, useRef} from 'react';
import {FlatList, Pressable} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";

const CustomizeColors = (props) => {

    const flatListRef = useRef();
    const {avatar, setAvatar, name, colors} = props
    const dimensions = useContext(DimensionsContext)
    return (
        <FlatList data={colors}
                  ref={flatListRef}
                  style={{
                      //width: dimensions?.width,
                      height: dimensions?.height / 2.1
                  }}
                  contentContainerStyle={{
                      //alignItems: "center",
                      justifyContent: 'center',
                  }}
                  keyExtractor={(item, index) => index.toString()}
                  numColumns={5}
                  renderItem={(item) => {
                      return <Pressable style={{
                          backgroundColor: item.item?.color,
                          width: 50,
                          height: 50,
                          margin: 10,
                          shadowColor: '#171717',
                          shadowOffset: {width: -2, height: 4},
                          shadowOpacity: 0.2,
                          shadowRadius: 3,
                          borderRadius: 5
                      }}
                                        onPress={() => {
                                            setAvatar({...avatar, [name]: item.item?.name})
                                        }}
                      >

                      </Pressable>
                  }}
        />
    );
}

export default CustomizeColors;