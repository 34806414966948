import {theme} from "@common/theme/theme";
import {KeyValueItem} from "@common/components/card/KeyValueItem";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ModalM} from "@common/components/modal/ModalM";
import ScoringDetails from "./ScoringDetails";
import {RatingM} from "@common/components/rating/RatingM";
import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import TextM from "@common/components/text/TextM";
import {View} from "react-native";

export const ActivityScoringKeyValueItem = (props) => {

    const {activity, style, large} = props

    const [visible, setVisible] = React.useState(false);
    const showModal = () => {
        return setVisible(true);
    }
    const hideModal = () => {
        return setVisible(false);
    }

    return <TouchableHighlightM style={{
        marginTop: 10,
        width: "100%",
        borderRadius: 14,
        alignSelf: "center",
        ...style
    }}
                                underlayColor={theme.colors.lightMore}
                                onPress={() => {
                                    showModal()
                                }}>
        <>
            <KeyValueItem keyText="SCORE"
                          valueText={(activity?.status === "pending_review")
                              ? "En attente"
                              : (activity?.status === "not_reviewed")
                                  ? "-"
                                  : null}
                          style={{
                              // marginTop: 10,
                              paddingVertical: 2,
                              backgroundColor: theme.colors.transparent,
                              marginHorizontal: 0
                          }}
                          valueComponent={(activity?.status === "validated") && <View>
                              <RatingM score={activity?.score}
                                       style={{marginRight: 30, marginTop: 6}}
                                       size={30}/>
                              <TextM fontSize={10}
                                     color={theme.colors.greyAlt}
                                     fontWeight={"Light"}
                                     style={{
                                         position: "absolute",
                                         alignSelf: "center",
                                         bottom: 0
                                     }}>Voir les détails</TextM>
                          </View>
                          }
                          textColor={(activity?.status === "pending_review") ? theme.colors.warning : theme.colors.primary}
                          large={large}/>
            <ModalM visible={visible}
                    onDismiss={hideModal}
            >
                <ScoringDetails activity={activity}/>
            </ModalM>
        </>
    </TouchableHighlightM>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityScoringKeyValueItem)