import React, {useContext, useEffect, useState} from 'react'
import Username, {PropsContext, RemoteAudioMute, RemoteVideoMute, RtcContext} from "agora-react-uikit";
import {AgoraVideoPlayer} from 'agora-rtc-react'
import AvatarM from "@common/components/avatar/AvatarM";
import {View} from "react-native";
import {UsernameComponent} from "@common/video-call/UsernameComponent";

/**
 * React component to display the user video in maximized view
 */
const MaxVideoViewWebM = (props) => {
    const {mediaStore, localAudioTrack} = useContext(RtcContext)
    const {styleProps, rtcProps} = useContext(PropsContext)
    const {maxViewStyles, videoMode, maxViewOverlayContainer} = styleProps || {}
    const renderModeProp = videoMode?.max
    const [isShown, setIsShown] = useState(false)
    const {user} = props

    const localUser = {
        id: rtcProps?.uid,
        avatar: rtcProps?.avatar,
        display_name: rtcProps?.username,
        is_tutor: !rtcProps?.isStudent
    }

    const userObject = rtcProps?.users?.find(u => u?.id === user?.uid)
    const isMuted = user?.uid === 0 ? localAudioTrack?.muted : user?.hasAudio !== 1

    useEffect(() => {
        // console.log("useeffect", rtcProps)
    }, [])
    return (
        <div
            style={{
                ...styles.container,
                ...props.style,
                ...maxViewStyles,
            }}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
        >
            {user.hasVideo === 1 && mediaStore[user.uid].videoTrack ? (
                // hasVideo is 1 if the local user has video enabled, or if remote user video is subbed
                <div style={styles.videoContainer}>
                    {!rtcProps.disableRtm && <Username user={user}/>}
                    <AgoraVideoPlayer
                        style={styles.videoplayer}
                        config={{
                            fit: renderModeProp || 'cover'
                        }}
                        videoTrack={mediaStore[user.uid].videoTrack}
                    />
                    <UsernameComponent userObject={userObject} rtcProps={rtcProps} user={user} withVideo
                                       speakerStyles={props.speakerStyle}
                                       soundMode={rtcProps.soundModeIds?.includes(userObject ? user.uid : rtcProps?.uid)}
                                       condition={isMuted}/>
                    {isShown && (
                        <div
                            style={{
                                ...styles.overlay,
                                ...maxViewOverlayContainer
                            }}
                        >
                            {!rtcProps.disableRtm && <RemoteVideoMute UIKitUser={user}/>}
                            {!rtcProps.disableRtm && <RemoteAudioMute UIKitUser={user}/>}
                        </div>
                    )}
                </div>
            ) : (
                <View style={{
                    alignItems: 'center', justifyContent: 'center', flex: 1, ...props.speakerStyle
                }}>
                    {!rtcProps.disableRtm && <Username user={user}/>}
                    <AvatarM user={userObject ?? localUser} size={70} style={{alignSelf: 'center', marginBottom: 10}}/>
                    <UsernameComponent userObject={userObject ?? localUser} rtcProps={rtcProps} user={user}
                                       speakerStyles={props.speakerStyle}
                                       soundMode={rtcProps.soundModeIds?.includes(userObject ? user.uid : rtcProps?.uid)}
                                       condition={isMuted}/>
                </View>
            )}
        </div>
    )
}

const styles = {
    container: {display: 'flex', flex: 1},
    videoContainer: {
        display: 'flex',
        flex: 1,
        position: 'relative',
    },
    videoplayer: {
        width: '100%',
        display: 'flex',
        flex: 1
    },
    overlay: {
        position: 'absolute',
        margin: 5,
        flexDirection: 'column',
        display: 'flex'
    },
    username: {
        position: 'absolute',
        background: '#007bffaa',
        padding: '2px 8px',
        color: '#fff',
        margin: 0,
        bottom: 0,
        right: 0,
        zIndex: 90
    }
}

export default MaxVideoViewWebM