import React, {useContext, useEffect, useState} from "react";
import {connect} from "react-redux";
import {hasRole} from "@data/utility/ability";
import {PropsContext, RtcContext} from "agora-react-uikit";
import {apiRequest} from "@data/redux/actions/api";

const MuteAudioVideo = (props) => {
    const {rtcProps, callbacks} = useContext(PropsContext)
    const {client, dispatch, localAudioTrack, localVideoTrack} = useContext(RtcContext)


    useEffect(() => {
        // RtcEngine.setParameters('{"che.audio.keep.audiosession": true}');
    }, []);

    const [firstTime, setFirstTime] = useState(false)

    useEffect(() => {

        const soundMode = hasRole(props.user, 'student') ? props.soundModeIds?.includes(props.user?.id) : true

        if (!soundMode) {
            setFirstTime(true)
            client.remoteUsers.forEach(user => {
                user.audioTrack && user.audioTrack.setVolume(0);
                // user.videoTrack && user.videoTrack.stop();
            });
            localAudioTrack?.setVolume(0);
            // localAudioTrack.setMuted()
            // client.massUnsubscribe(client.remoteUsers.map(user => ({user}))).then()
            // client.unpublish().then()
        } else if (soundMode) {
            client.remoteUsers.forEach(user => {
                user.audioTrack && user.audioTrack.setVolume(100);
                // user.videoTrack && user.videoTrack.play();
            });

            if (firstTime) {
                setFirstTime(false)
                localAudioTrack?.setVolume(100);
            }

            // muteAudio({
            //     ...client.local,
            //     hasAudio: !props.soundMode ? ToggleState.enabled : ToggleState.disabled
            // }, dispatch, localAudioTrack)
            // client.massSubscribe(client.remoteUsers.map(user => ({user}))).then()
            // client.publish().then()
        }


    }, [props.soundModeIds]);

    useEffect(() => {
        // localVideoTrack.setMuted(props.isVideoMuted)
    }, [props.isVideoMuted])

    useEffect(() => {
        // localVideoTrack.setMuted(props.isAudioMuted)
    }, [props.isAudioMuted])

    return (<></>);
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        liveSessionId: state.liveMode?.liveSessionId,
        isAudioMuted: state.liveMode?.isAudioMuted,
        isVideoMuted: state.liveMode?.isVideoMuted,
        soundModeIds: state.liveMode?.soundModeIds
    }
}

const mapDispatchToProps = {
    apiRequest
}
export default connect(mapStateToProps, mapDispatchToProps)(MuteAudioVideo)