import React, {useContext, useEffect, useRef} from 'react';
import {Platform, StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect} from "react-redux";
import ChampionBubble from "./ChampionBubble";
import FilledButtonM from "@common/components/button/FilledButtonM";
import championsConstants from "@data/constants/championsConstants";
import {authService} from "@data/services/authService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {hasRole} from "@data/utility/ability";


const ChampionIntroScreen = (props) => {

    // ** Component props
    const {navigation, route} = props
    const {name, nextScreen = null, nextScreenParams = null, introSuffix = "_intro", isTracked = true, liveModeOnly = false} = route.params

    const championBubbleRef = useRef();

    useEffect(() => {
        if (liveModeOnly && !props.isLiveMode)
            navigation.goBack()
    }, [props.isLiveMode]);


    useApiStatus(
        authService.championIntroViewed, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            props.apiIdle(authService.championIntroViewed)
            if (nextScreen)
                navigation.replace(nextScreen, nextScreenParams ?? {})
            else
                navigation.goBack()
        } // success callback
    )
    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flex: 1,
            backgroundColor: Platform.OS !== 'web' ? theme.colors.white : null,
            alignItems: "center",
            justifyContent: "space-between",
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center",
            height: "100%"
        }}>
            <ChampionBubble name={name}
                            ref={championBubbleRef}
                            variant={championsConstants?.[name + introSuffix]?.variant}
                            texts={championsConstants?.[name + introSuffix]?.texts}
                            championPosition="center"
                            onNextPress={() => {
                                if (isTracked && hasRole(props.user, "student"))
                                    props.apiRequest(authService.championIntroViewed, {}, {intro_name: name})
                                else if (nextScreen)
                                    navigation.replace(nextScreen, nextScreenParams ?? {})
                                // else if (name === "tina" || (props.isLiveMode && hasRole(props.user,"student")))
                                else
                                    navigation.goBack()
                            }}/>


            <View style={{
                alignItems: "center",
                justifyContent: "center",
                width: dimensions?.width
            }}>
                <FilledButtonM
                    onPress={() => {
                        championBubbleRef.current?.onChampionBubbleNext()
                    }}
                    disabled={props.championIntroViewedRequest}
                    loading={props.championIntroViewedRequest}
                    label={"Continuer"}
                    color={theme.colors.primary}
                    style={{
                        width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2,
                        alignSelf: "center",
                        marginTop: 10,
                        marginBottom: 30
                    }}
                />

            </View>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        championIntroViewedRequest: state.api.championIntroViewed?.request,

        user: state.data.currentUser?.object,

        isLiveMode: state.liveMode.isLiveModeEnabled
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChampionIntroScreen)