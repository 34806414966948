import React, {useContext, useEffect, useRef} from 'react';
import {Platform, SectionList, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {StatusBar} from "expo-status-bar";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ModalM} from "@common/components/modal/ModalM";
import {ShopProductListItem} from "./ShopProductListItem";
import ShopProductItemDetails from "./ShopProductItemDetails";
import {authService} from "@data/services/authService";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {shopService} from "@data/services/shopService";
import ChampionBubble from "../champions/ChampionBubble";
import {PetalCounter} from "@common/components/statistics/Counters";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";

const MyPetalsTabScreen = (props) => {

    const {navigation} = props

    const flatListRef = useRef()

    const [visible, setVisible] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(null);

    const onPurchase = () => {
        props.apiRequest(shopService.purchase, {id: selectedItem?.id})
    }

    useApiStatus(
        shopService.purchase, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData, allData) => {

            const userSkillSet = findItemInListWithId(4, allData?.skill_sets)

            const congrats = [
                {
                    congratsType: congratsTypes.SHOP_PRODUCT_PURCHASED,
                    congratsData: {
                        shopProduct: {...selectedItem}
                    }
                },
                {
                    congratsType: congratsTypes.MY_PROGRESS,
                    congratsData: {
                        userSkillSets: [userSkillSet]
                    }
                }
            ]

            if (
                userSkillSet?.current_count === 0
                && !userSkillSet?.is_last_evolution_read
                && userSkillSet?.current_step >= 2
                && userSkillSet?.steps?.[userSkillSet?.current_step - 1]?.title_gained !== userSkillSet?.steps?.[userSkillSet?.current_step - 2]?.title_gained
            ) {
                congrats?.push({
                    congratsType: congratsTypes.NEW_TITLE_CONGRATS,
                    congratsData: {
                        userSkillSet: userSkillSet
                    }
                })
            }


            // if (!IS_WEB)
            congrats?.push({
                congratsType: congratsTypes.SHARE_STATS,
                congratsData: {
                    shopProduct: {...selectedItem}
                },
                hideButton: !IS_WEB
            })

            hideModal()
            navigation.push("congrats", {congrats})
        }, // success callback,
        (errorData) => {
            hideModal()
            props.apiRequest(authService.getUser)
        }
    )

    const showModal = (item) => {
        setSelectedItem(item)
        return setVisible(true);
    }
    const hideModal = () => {
        setSelectedItem(null)
        return setVisible(false);
    }

    const sections = [
        {
            title: <></>,
            data: props.shopProducts?.filter(sp => sp?.category === "gesture")
        }
    ]

    const dimensions = useContext(DimensionsContext)

    // console.log(props.shopProducts)

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : 'center'
        }}>
            <HeaderContainerM>
                <TitleM>Boutique de gestes</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>

            <SectionList sections={sections}
                         refreshing={!!props.getUserRequest}
                         stickySectionHeadersEnabled={false}
                         onRefresh={() => props.apiRequest(authService.getUser)}
                         ref={flatListRef}
                         style={{
                             width: dimensions?.width
                         }}
                         ListHeaderComponent={<>
                             <View style={{
                                 flexDirection: "row",
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                                 borderColor: theme.colors.lightMore,
                                 paddingVertical: 8,
                                 marginBottom: 20,
                                 marginHorizontal: 15
                                 // paddingLeft: 20,
                                 // marginTop: 10
                             }}>
                                 <TextM fontWeight="Bold">
                                     Tes crédits
                                 </TextM>
                                 {props.getUserRequest
                                     ? <ActivityIndicator color={theme.colors.primary}/>
                                     : <PetalCounter count={props.user?.petals}
                                                     style={{
                                                         height: 46,
                                                         marginHorizontal: 4
                                     }} />}
                                     </View>
                         </>}
                         ListFooterComponent={<>
                             <ChampionBubble name={"tina"}
                                             variant={"main"}
                                             championPosition="left"
                                             texts={[props.user?.petals < 12 ? "Termine de nouvelles quêtes pour gagner plus de pétales et acheter des gestes !" : "Choisis un geste pour faire fructifier tes efforts !"]}
                                             onNextPress={() => {
                                             }}
                             />
                             {IS_WEB && <FilledButtonM mode={"text"}
                                                       icon={"chevron-left"}
                                                       color={theme.colors.link}
                                                       style={{marginTop: 60}}
                                                       onPress={() => {
                                                           if (navigation.canGoBack())
                                                               navigation.goBack()
                                                           else
                                                               navigation.replace("menu")
                                                       }}
                                                       label={"Retour"}/>}
                         </>}
                         keyExtractor={(item, index) => item + index}
                         renderSectionHeader={({section: {title}}) => (<>
                             {title}
                         </>)}
                         renderItem={({item, index}) => {

                             // console.log(props.user?.shop_purchases)
                             const countPurchases = props.user?.shop_purchases?.filter(p => p?.shop_product_id === item?.id)?.length

                             return <ShopProductListItem shopProduct={item}
                                                         countPurchases={countPurchases}
                                                         onPress={() => {
                                                             showModal(item)
                                                         }}/>
                         }}
            />

            <ModalM visible={visible}
                    onDismiss={hideModal}
                    style={{
                        maxHeight: dimensions?.height * 3 / 4
                    }}
            >
                <ShopProductItemDetails shopProduct={selectedItem} onPurchase={onPurchase}
                                        loading={props.purchaseRequest}/>
            </ModalM>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        purchaseRequest: state.api.purchase?.request,
        purchaseSuccess: state.api.purchase?.success,
        purchaseError: state.api.purchase?.error,

        getUserRequest: state.api.getUser?.request,

        user: state.data.currentUser?.object,
        shopProducts: state.data.staticData?.shop_products
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPetalsTabScreen)