import {
    RESET_ACTIVITIES_HISTORY,
    SET_ACTIVITIES_REVIEW_FILTER,
    SET_TODO_ACTIVITIES
} from "@data/constants/activitiesConstants";
import {messengerConstants} from "@data/constants/messengerConstants";

export function resetActivitiesHistory(studentId) {
    return {type: RESET_ACTIVITIES_HISTORY, studentId: studentId}
}

export function incrementTutorMessengerNotReadCount() {
    return {type: messengerConstants.INCREMENT_TUTOR_MESSENGER_NOT_READ_COUNT}
}

export function incrementStudentMessengerNotReadCount(studentId) {
    return {type: messengerConstants.INCREMENT_STUDENT_MESSENGER_NOT_READ_COUNT, studentId: studentId}
}

export function setActivitiesReviewFilter(filter) {
    return {type: SET_ACTIVITIES_REVIEW_FILTER, filter: filter}
}

export function setTodoActivities(todoActivities) {
    return {type: SET_TODO_ACTIVITIES, todoActivities: todoActivities}
}