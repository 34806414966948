import React, { useEffect, useRef, useState } from "react";
import { apiIdle, apiRequest } from "../../data/redux/actions/api";
import { connect } from "react-redux";
import DraggableView from "@common/video-call/DraggableView";
import { Dimensions, View } from "react-native";
import { Portal } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { liveService } from "../../data/services/liveService";
import { theme } from "@common/theme/theme";
import { MiniModeM } from "@common/video-call/MiniModeM";
import { IS_WEB } from "@common/utils/mobileUtils";
import { ConnectingView } from "@common/video-call/ConnectingView";
import AgoraUIKitM from "@common/video-call/AgoraUIKitWeb/AgoraUIKitM";
import LiveModeHeader from "@common/header/LiveModeHeader";
import { useInterval } from "@common/utils/useInterval";
import { PlaySoundM } from "@common/components/playSound/PlaySoundM";
import { setIsLiveModeEnabled } from "@data/redux/actions/liveMode";
import { hasRole } from "@data/utility/ability";
import VideoCallDialog from "@common/video-call/VideoCallDialog";
import { DialogM } from "@common/components/alert/dialog/Dialog";
import { useApiStatus } from "@common/hooks/useApiStatus";
import AgoraRTC from "agora-rtc-react";
import PermissionsManager from "@common/video-call/PermissionsManager";
import { SnackBarM } from "@common/components/alert/snackbar/SnackBar";
import { useNavigation, useRoute } from "@react-navigation/native";

const VideoCallPortal = (props) => {
  const {
    roomLink,
    token,
    ssToken,
    users,
    renderTopLeftComponent = () => {},
    setToken,
    setSSToken,
  } = props;
  const insets = useSafeAreaInsets();
  const [miniMode, setMiniMode] = useState(hasRole(props.user, "tutor"));
  const [pinnedMode, setPinnedMode] = useState(true);
  const liveSession = props.currentLiveSession;
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const _isConnected = useRef(false);
  const _pusherStatus = useRef(props.pusherStatus);
  const _pusherNotConnectedCount = useRef(0);
  const [isDenied, setIsDenied] = useState(true);
  const [permissionsChecked, setPermissionsChecked] = useState(false);

  const navigation = useNavigation();
  const route = useRoute();

  const isSoundMode =
    hasRole(props.user, "student") &&
    props.soundModeIds?.includes(props.user?.id);

  useEffect(() => {
    if (!isSoundMode && !miniMode && isConnected) setMiniMode(true);
  }, [isSoundMode]);

  useEffect(() => {
    _isConnected.current = isConnected;
  }, [isConnected]);

  useEffect(() => {
    _pusherStatus.current = props.pusherStatus;
  }, [props.pusherStatus]);

  useInterval(() => {
    // console.log(_pusherStatus.current)
    if (_pusherStatus.current !== "connected") {
      _pusherNotConnectedCount.current = _pusherNotConnectedCount.current + 1;
      // console.log("_pusherNotConnectedCount.current", _pusherNotConnectedCount.current)
      if (_pusherNotConnectedCount.current === 2) {
        showRetryDialogIfNotConnected();
      }
    } else if (_pusherNotConnectedCount.current > 0) {
      _pusherNotConnectedCount.current = 0;
    }
  }, 30 * 1000);

  useInterval(() => {
    props.apiRequest(liveService.callKeepAlive, { id: props.liveSessionId });
  }, 30 * 1000);

  const showRetryDialogIfNotConnected = () => {
    // console.log("CALLBACK NOT CONNECTED TEST")
    if (!_isConnected.current || _pusherStatus.current !== "connected") {
      // console.log("RESULT : is not connected")
      DialogM.show({
        champion: "abou",
        variant: "unhappy",
        text1: "Erreur de connexion",
        text2: "Actualise la page ou contacte le support",
        buttonText1: "Actualiser",
        onPress: () => {
          //reload page
          window.location.reload();
        },
        buttonText2: "Retour",
        onPressBack: () => {
          // _pusherNotConnectedCount.current = 0
        },
      });
    }
  };

  useEffect(() => {
    // console.log("ssToken", ssToken)
    props.apiRequest(liveService.joinCall, { id: props.liveSessionId });

    AgoraRTC.setLogLevel(3);

    setTimeout(() => {
      showRetryDialogIfNotConnected();
    }, 30 * 1000);

    return () => {
      props.apiRequest(liveService.leaveCall, { id: props.liveSessionId });
    };
  }, []);

  useApiStatus(
    liveService.getLiveSession, // api service
    null, // success message on toast (string or null)
    false, // error message on toast (true) or keep it in redux state (false)
    (successData) => {
      // navigation.push("end-session-tutor")
      if (!_isConnected.current) {
        setToken(successData.conference_token);
        setSSToken(successData.screenshare_token);
      }
    }
  );

  const agoraProps = {
    appId: "908a3214386248958ce1cd3d98e74de9",
    uid: props.user?.id,
    channel: roomLink,
    token: token,
    //activeSpeaker: true,
    enableScreensharing: true,
    screenshareUid: props.user?.id + "screenshare",
    screenshareToken: ssToken,
    // enableVideo: false,
    enableVideo: !isDenied,
    disableRtm: true,
    avatar: props.user?.avatar,
    users: users,
    username: props.user?.display_name,
    pinnedMode: pinnedMode || miniMode,
    setPinnedMode: setPinnedMode,
    // tutorUid: props.inCallUsers.find((x) => x === liveSession?.tutor?.id) && !hasRole(props.user, "tutor") ? liveSession?.tutor?.id : null,
    soundMode: hasRole(props.user, "student")
      ? props.soundModeIds?.includes(props.user?.id)
      : true,
    soundModeIds: props.soundModeIds,
    miniMode: miniMode,
    hideEndCallBtn: true,
    isStudent: hasRole(props.user, "student"),
  };

  const rtcCallbacks = {
    EndCall: () => {
      setTimeout(() => {
        props.setIsLiveModeEnabled(false);
      }, 500);
    },
    "crypt-error": () => {
      console.error("crypt-error");
    },
    "token-privilege-did-expire": () => {
      console.error("token-privilege-did-expire");
    },
    "media-reconnect-end": (uid, streamType) => {
      console.error("media-reconnect-end", uid, streamType);
    },
    "media-reconnect-start": (uid, streamType) => {
      console.error("media-reconnect-start", uid, streamType);
    },
    "live-streaming-warning": (url, err) => {
      console.error("live-streaming-warning", url, err);
    },
    "live-streaming-error": (url, err) => {
      console.error("live-streaming-error", url, err);
    },
    "connection-state-change": (curState, prevState, reason) => {
      console.log("connection-state-change", curState, prevState, reason);
      if (curState === "CONNECTED") {
        setIsConnected(true);
        setDialogVisible(true);
      } else {
        setIsConnected(false);
      }
    },
    "user-joined": (user) => {
      if (hasRole(props.user, "tutor")) playJoinSound();
      else
        setTimeout(() => {
          props.apiRequest(liveService.getCall, { id: props.liveSessionId });
        }, 2000);
    },
    "user-left": (user) => {
      if (hasRole(props.user, "tutor")) playLeaveSound();
    },
  };

  const playJoinSound = () => {
    PlaySoundM.play(require("../../../assets/sounds/maximize_001.mp3"));
  };

  const playLeaveSound = () => {
    PlaySoundM.play(require("../../../assets/sounds/minimize_001.mp3"));
  };

  const renderAgoraUIKitM = () => {
    return (
      <AgoraUIKitM
        rtcProps={agoraProps}
        // rtmProps={{
        //     username: props.user?.display_name,
        //     displayUsername: true,
        //     uid: props.user?.id,
        //     token: token
        // }}
        callbacks={rtcCallbacks}
        styleProps={{
          videoMode: {
            max: "contain",
            min: "contain",
          },
          localBtnContainer: {
            backgroundColor: theme.colors.whiteTransparent,
            // position: "absolute",
            // bottom: 0
          },
          maxViewContainer: {
            backgroundColor: theme.colors.grey,
          },
          minViewContainer: {
            backgroundColor: theme.colors.grey,
          },
          localBtnStyles: {
            muteLocalAudio: {
              backgroundColor: theme.colors.primary,
              borderColor: theme.colors.primary,
            },
            muteLocalVideo: {
              backgroundColor: theme.colors.primary,
              borderColor: theme.colors.primary,
            },
            switchCamera: {
              backgroundColor: theme.colors.primary,
              borderColor: theme.colors.primary,
            },
            screenshare: {
              backgroundColor: theme.colors.primary,
              borderColor: theme.colors.primary,
            },
          },
          usernameText: {
            backgroundColor: theme.colors.primary,
            borderColor: theme.colors.primary,
          },
        }}
      />
    );
  };

  const safeHeight =
    Dimensions.get("window").height - insets.top - insets.bottom;
  const safeWidth = Dimensions.get("window").width - insets.left - insets.right;
  const miniHeight =
    !props.soundModeIds?.includes(props.user?.id) &&
    hasRole(props.user, "student")
      ? 130
      : 200;
  const miniWidth = IS_WEB && Dimensions.get("window").width > 800 ? 280 : 120;
  const miniWidthBySoundMode =
    !props.soundModeIds?.includes(props.user?.id) &&
    hasRole(props.user, "student")
      ? miniWidth / 2
      : miniWidth;
  const contentHeight = miniMode ? miniHeight : safeHeight;
  const contentWidth = miniMode ? miniWidthBySoundMode : safeWidth;
  return (
    <Portal>
      <DraggableView
        initValue={{
          x: safeWidth - miniWidthBySoundMode,
          y: safeHeight - miniHeight,
        }}
        isDraggable={miniMode}
        style={{
          position: "absolute",
          marginTop: insets.top,
          marginBottom: insets.bottom,
          marginLeft: insets.left,
          marginRight: insets.right,
          backgroundColor: theme.colors.black,
          alignItems: "center",
          justifyContent: "center",
        }}
        contentHeight={contentHeight}
        contentWidth={contentWidth}
        maxHeight={safeHeight}
        maxWidth={safeWidth}
      >
        <View
          style={{
            width: contentWidth,
            height: contentHeight,
          }}
        >
          {!miniMode && hasRole(props.user, "tutor") && (
            <View
              style={{
                // width: "100%",
                height: 54,
                marginLeft: 20,
                alignSelf: "flex-start",
                // position: "absolute",
                // top: 0,
              }}
            >
              <LiveModeHeader
                headerNavigation={navigation}
                headerRoute={route}
                setMiniMode={setMiniMode}
                noSubHeader
              />
            </View>
          )}
          {/*{renderAgoraUIKit()}*/}
          {!!token && permissionsChecked && renderAgoraUIKitM()}
          <View
            style={{
              bottom: null,
              position: "absolute",
              right: 0,
              top: 0,
              justifyContent: "flex-end",
            }}
          >
            <MiniModeM miniMode={miniMode} setMiniMode={setMiniMode} />
          </View>
          {/*{!miniMode && <View style={{*/}
          {/*    bottom: null,*/}
          {/*    position: "absolute",*/}
          {/*    left: 0,*/}
          {/*    top: 0,*/}
          {/*    justifyContent: "flex-end",*/}
          {/*    borderBottomEndRadius: 15,*/}
          {/*    backgroundColor: theme.colors.whiteTransparentMore*/}
          {/*}}>*/}
          {/*    {renderTopLeftComponent()}*/}
          {/*</View>}*/}
        </View>
        {(!isConnected || !token) && (
          <ConnectingView
            width={contentWidth}
            height={contentHeight}
            onPress={() => {
              props.apiRequest(liveService.leaveLiveSession, {
                id: props.liveSessionId,
              });
            }}
          />
        )}
      </DraggableView>
      <DialogM />
      <SnackBarM />
      <PermissionsManager
        isDenied={isDenied}
        setIsDenied={setIsDenied}
        permissionsChecked={permissionsChecked}
        setPermissionsChecked={setPermissionsChecked}
      />
      {hasRole(props.user, "student") && (
        <VideoCallDialog
          visible={dialogVisible}
          soundMode={props.soundModeIds?.includes(props.user?.id)}
          onDismiss={() => {
            setDialogVisible(false);
            setMiniMode(true);
          }}
        />
      )}
    </Portal>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    participants: state.liveMode?.liveParticipants,
    pusherStatus: state.pusher?.status,
    tutors: state.liveMode?.liveTutors,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    liveSessionId: state.liveMode?.liveSessionId,
    inCallUsers: state.liveMode?.inCallUsers,
    soundModeIds: state.liveMode?.soundModeIds,
    currentLiveSession: state.liveMode?.currentLiveSession,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setIsLiveModeEnabled,
};
export default connect(mapStateToProps, mapDispatchToProps)(VideoCallPortal);
