import {View} from "react-native";
import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {TouchableTile} from "@common/components/button/TouchableTile";
import {hasRole} from "@data/utility/ability";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {IconTextM} from "@common/components/icons/IconTextM";
import {DimensionsContext} from "@common/utils/mobileUtils";

export const activitySlugsObjectives = {
    "video_course": "Comprendre",
    "lesson_card": "Mémoriser",
    "flash_revision": "Réviser",
    "go_exo": "M'exercer à l'écrit",
    "go_homework_oral": "M'exercer à l'oral"
}

export const activitySlugsMaterialSlugs = {
    "video_course": "vidéo",
    "lesson_card": "cours",
    "flash_revision": "cours",
    "go_exo": "énoncé",
    "go_homework_oral": "énoncé"
}

export const activitySlugsMaterialSlugsGenders = {
    "video_course": "F",
    "lesson_card": "M",
    "flash_revision": "M",
    "go_exo": "M'",
    "go_homework_oral": "M'"
}

export const definedSpecifiers = {
    "M": "le ",
    "F": "la ",
    "M'": "l'",
    "F'": "l'"
}

export const undefinedSpecifiers = {
    "M": "un ",
    "F": "une ",
    "M'": "un ",
    "F'": "une "
}

export const definedMaterialSlug = (slug) => activitySlugsMaterialSlugs?.[slug] ? (definedSpecifiers?.[activitySlugsMaterialSlugsGenders?.[slug]] + activitySlugsMaterialSlugs?.[slug]) : "le support"
export const undefinedMaterialSlug = (slug) => activitySlugsMaterialSlugs?.[slug] ? undefinedSpecifiers?.[activitySlugsMaterialSlugsGenders?.[slug]] + activitySlugsMaterialSlugs?.[slug] : "un support"

export const activitySlugsMaterialLabel = {
    "lesson_card": "Indique quelle partie [ss] tu vas résumer dans une fiche",
    "flash_revision": "Indique quelle partie [ss] tu vas réviser",
    "video_course": "Ajoute la vidéo [ss] à regarder",
    "go_exo": "Ajoute l'énoncé de l'exercice [ss] à faire",
    "go_homework_oral": "Ajoute l'énoncé de l'exercice [ss] à faire",
}

export const materialLabel = (slug, schoolSubjectName) => activitySlugsMaterialLabel?.[slug]?.replace("[ss]", schoolSubjectName)

export const activitySlugsActivityTypes = {
    "lesson_card": "card",
    "flash_revision": "revision",
    "video_course": "external_resource",
    "go_exo": "written_exercise",
    "go_homework_oral": "oral_exercise"
}
export const activityTypesActivitySlugs = {
    "card": "lesson_card",
    "revision": "flash_revision",
    "external_resource": "video_course",
    "written_exercise": "go_exo",
    "oral_exercise": "go_homework_oral"
}

export const activitySlugsMaterialTitleExample = {
    "lesson_card": 'Exemple : "Partie 2 du chapitre"',
    "flash_revision": 'Exemple : "Partie 2 du chapitre"',
    "video_course": 'Exemple : "Partie 2 du chapitre"',
    "go_exo": 'Exemple : "Application simple"',
    "go_homework_oral": 'Exemple : "Introduction orale"'
}
export const activitySlugsMaterialTextExample = {
    "lesson_card": 'Exemple : "Résumer en 1 page la partie 2 du chapitre"',
    "flash_revision": 'Exemple : "Réviser à l\'oral tout le chapitre"',
    "video_course": 'Exemple : "Visionner la vidéo entre 2min30 et 5min45"',
    "go_exo": 'Exemple : "Faire l\'exercice 4"',
    "go_homework_oral": 'Exemple : "Faire l\'introduction à l\'oral sur le sujet"'
}
export const schoolSubjectMaterialTextExample = {
    "lesson_card": 'Exemple : "Résumer en 1 page la partie 2 du chapitre"',
    "flash_revision": 'Exemple : "Résumer à l\'oral tout le chapitre"',
    "video_course": 'Exemple : "Résumer la vidéo entre 2min30 et 5min45"',
    "go_exo": 'Exemple : "Faire l\'exercice 4"',
    "go_homework_oral": 'Exemple : "Faire l\'introduction à l\'oral sur le sujet"'
}
export const materialPlaceholder = (slug) => activitySlugsMaterialTextExample?.[slug]
export const activitySlugsColors = {
    "lesson_card": theme.colors.light,
    "flash_revision": theme.colors.accentLight,
    "video_course": theme.colors.errorLight,
    "go_exo": theme.colors.linkLight,
    "go_homework_oral": theme.colors.warningLight
}
const ActivityPicker = (props) => {

    const {control, goToNextStep, register, errors, serverError, formValues, setFormValues, field, trigger} = props

    const activityTemplates = Object.keys(activitySlugsObjectives).map(slug => props.activityTemplates?.find(x => x?.slug === slug))
    const activityTemplatesIcons = Object.keys(activitySlugsObjectives).map(slug => props.activityTemplates?.find(x => x?.slug === slug)?.icon)
    const activityTemplatesObjectives = Object.values(activitySlugsObjectives)
    const dimensions = useContext(DimensionsContext)

    // console.log(formValues?.chapter?.student_chapters)

    const chapterDoneActivities = (type) => {
        const doneChapterActivities = formValues?.chapter?.student_chapters?.[0]?.done_activities_count_by_type?.[type]
        const todoChapterActivities = formValues?.chapter?.student_chapters?.[0]?.todo_activities_count_by_type?.[type]

        // concatenate both in a string, with condition > 0 for each with french plurals
        const text = (doneChapterActivities > 0 ? doneChapterActivities + " fait" + (doneChapterActivities > 1 ? "s" : "") : "") + (doneChapterActivities > 0 && todoChapterActivities > 0 ? ", " : "") + (todoChapterActivities > 0 ? todoChapterActivities + " à faire" : "")

        return text !== "" &&
            <IconTextM icon={"history"}
                       text={text}
                       color={theme.colors.grey}
                       fontSize={dimensions.width > 800 ? 12 : 10}
                       iconSize={dimensions.width > 800 ? 15 : 13}
                       style={{
                           position: "absolute",
                           right: 0,
                           bottom: 2,
                           // backgroundColor: "red",
                           width: null,
                           // alignSelf: "center",
                       }}
                       textStyle={{
                           fontFamily: "Montserrat-Bold",
                           // fontSize: 12
                       }}
                       iconStyle={{
                            // backgroundColor: "blue",
                            width: null

                       }}
            />
    }

    const onPress = (index) => {

        if (index === 0 && hasRole(props.user, "tutor") && props.isLiveModeEnabled) {
            DialogM.show({
                champion: "zoe",
                variant: "main",
                text1: "Tu veux vraiment donner une vidéo de cours à ton élève ?!",
                text2: "Pourquoi pas, mais dans ce cas ça doit être un travail à faire après le Live !",
                buttonText1: "Continuer",
                onPress: () => {
                    field.onChange(activityTemplates[index]?.id)
                    goToNextStep()
                },
                buttonText2: "Annuler",
                onPressBack: () => {
                }
            })

        } else {
            field.onChange(activityTemplates[index]?.id)
            goToNextStep()
        }
    }

    return <View style={{
        flex: 1,
        paddingBottom: 70
    }}>
        <View style={{
            flex: 1 / 3,
            flexDirection: "row"
        }}>
            <TouchableTile icon={activityTemplatesIcons?.[0]}
                           onPress={() => onPress(0)}
                           selected={field.value === activityTemplates[0]?.id}
                           subtitle={activityTemplates?.[0]?.title}
                           title={activityTemplatesObjectives?.[0]}
                           additionalComponent={chapterDoneActivities(activitySlugsActivityTypes?.[activityTemplates[0]?.slug])}
            />
            <TouchableTile icon={activityTemplatesIcons?.[1]}
                           onPress={() => onPress(1)}
                           selected={field.value === activityTemplates[1]?.id}
                           subtitle={activityTemplates?.[1]?.title}
                           title={activityTemplatesObjectives?.[1]}
                           additionalComponent={chapterDoneActivities(activitySlugsActivityTypes?.[activityTemplates[1]?.slug])}
            />
        </View>
        <View style={{
            flex: 2 / 3,
            flexDirection: "row"
        }}>
            <TouchableTile icon={activityTemplatesIcons?.[2]}
                           onPress={() => onPress(2)}
                           selected={field.value === activityTemplates[2]?.id}
                           subtitle={activityTemplates?.[2]?.title}
                           title={activityTemplatesObjectives?.[2]}
                           additionalComponent={chapterDoneActivities(activitySlugsActivityTypes?.[activityTemplates[2]?.slug])}
            />
            <View style={{
                flex: 2,
                margin: 4
            }}>
                <TouchableTile icon={activityTemplatesIcons?.[3]}
                               onPress={() => onPress(3)}
                               selected={field.value === activityTemplates[3]?.id}
                               subtitle={activityTemplates?.[3]?.title}
                               title={activityTemplatesObjectives?.[3]}
                               style={{
                                   margin: 0,
                                   marginBottom: 4
                               }}
                               additionalComponent={chapterDoneActivities(activitySlugsActivityTypes?.[activityTemplates[3]?.slug])}
                />
                <TouchableTile icon={activityTemplatesIcons?.[4]}
                               onPress={() => onPress(4)}
                               selected={field.value === activityTemplates[4]?.id}
                               subtitle={activityTemplates?.[4]?.title}
                               title={activityTemplatesObjectives?.[4]}
                               style={{
                                   margin: 0,
                                   marginTop: 4
                               }}
                               additionalComponent={chapterDoneActivities(activitySlugsActivityTypes?.[activityTemplates[4]?.slug])}
                />
            </View>
        </View>
    </View>
}

const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        isLiveModeEnabled: state.liveMode.isLiveModeEnabled,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPicker)