import {IconImageM} from "@common/components/icons/IconsM";
import RealizationsTabScreen from "../../student/organize/list/RealizationsTabScreen";
import home from "../../../assets/icons/menus-new/accueil.png";
import homeG from "../../../assets/icons/menus-new/accueil-gris.png";
import target from "../../../assets/icons/menus-new/travaux-faits.png";
import targetG from "../../../assets/icons/menus-new/travaux-faits-gris.png";
import React from "react";
import TodoListScreen from "../../student/activity/todo/TodoListScreen";
import {Platform} from "react-native";


export const liveStudentTabScreens = (student) => {


    const countLateActivities = 0
    const countNotReadReviews = student?.not_read_reviews_count
    const mt = 0 //(Platform.OS === "ios" ? 0 : 40)
    const iconSize = 56

    return [
        {
            name: "live",
            component: TodoListScreen,
            options: {
                headerTitle: "Activités",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => status?.focused ?
                    <IconImageM style={{marginTop: -mt}} width={iconSize} source={home}/> :
                    <IconImageM style={{marginTop: -mt}} width={iconSize} source={homeG}/>,
                tabBarBadge: countLateActivities > 0 ? countLateActivities : null
            }
        },
        {
            name: "finished-activities-live",
            component: RealizationsTabScreen,
            options: {
                headerTitle: "Activités réalisées",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarIcon: (status) => status?.focused ?
                    <IconImageM style={{marginTop: -mt}} width={iconSize} source={target}/> :
                    <IconImageM style={{marginTop: -mt}} width={iconSize} source={targetG}/>,
                tabBarBadge: countNotReadReviews > 0 ? countNotReadReviews : null
            }
        },
    ]
}