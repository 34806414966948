import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {DeviceEventEmitter, FlatList, Platform, ScrollView, Vibration, View} from "react-native";
import {theme} from "@common/theme/theme";
import TitleM from "@common/components/text/TitleM";
import ChallengesListItem from "./ChallengesListItem";
import {authService} from "@data/services/authService";
import {DimensionsContext} from "@common/utils/mobileUtils";
import moment from "moment";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {challengesService} from "@data/services/challengesService";
import {StatusBar} from "expo-status-bar";
import {connect} from "react-redux";
import {studentsService} from "@data/services/studentsService";
import {AbilityContext} from "@data/utility/Can";
import ActivityButton from "../list/ActivityButton";
import SchoolSubjectBottomSheetModal from "../school-subjects/SchoolSubjectBottomSheetModal";
import TextM from "@common/components/text/TextM";
import {TourGuideZone, TourGuideZoneByPosition} from "rn-tourguide";
import BecomePremiumCard from "../../account/subscription/BecomePremiumCard";
import {
    getActivitiesCount,
    getLastVersionPromptedForReview,
    setLastVersionPromptedForReview
} from "@data/utility/asyncStorageUtils";
import Constants from "expo-constants";
import * as StoreReview from 'expo-store-review';
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {hasRole} from "@data/utility/ability";
import {clearActivityData} from "@data/redux/actions/formData";
import StudentSuggestions from "./StudentSuggestions";
import {ModalM} from "@common/components/modal/ModalM";
import SchoolSubjectModal from "../school-subjects/SchoolSubjectModal";
import {Menu3Point} from "@common/components/menu/Menu3Point";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {idsToObjects} from "../../../data/utility/dataUtils";
import {removeInCallUsers, setInCallUsers} from "../../../data/redux/actions/liveMode";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";

const ChallengesTabScreen = (props) => {

    // ** Component props
    const {
        student,
        route,
        showButton,
        renderAdditionalHeader = () => <></>,
        realizationMode = false
    } = props

    const navigation = useNavigation()
    const liveSession = props.currentLiveSession

    useEffect(() => {
        //console.log("intro loaded")
        /*        if (props.isLive && hasRole(props.user, "student") && props.getLiveSessionSuccess && !props.callPortal) {
                    if (liveSession?.live_room?.type === "holiday_course")
                        navigation?.push('intro', {
                            // name: 'live_launch_holiday_course',
                            name: 'zoe',
                            introSuffix: '_live_launch_holiday_course_intro',
                            isTracked: false
                        })
                    else
                        navigation?.push('intro', {
                            // name: 'live_launch',
                            name: 'zoe',
                            introSuffix: '_live_launch_intro',
                            isTracked: false,
                            nextScreen: "pre-call",
                            nextScreenParams: {}
                        })

                }*/
        const event = DeviceEventEmitter.addListener("selectSchoolSubject", (eventData) => {
            showSchoolSubjectBottomSheet(eventData?.activityTemplateId)
        })
        return () => {
            //DeviceEventEmitter.removeAllListeners("selectSchoolSubject")
            event.remove()
        }

    }, [])

    // console.log(student)

    // const suggestionChallengeId = route.params?.challengeId
    const user = student ? student : props.user
    const request = student ? props.getStudentRequest : props.getUserRequest
    const getUserService = student ? studentsService.getStudent : authService.getUser
    const getUserParams = student ? {id: student?.id} : {}

    // const [expandedIndex, setExpandedIndex] = useState(null)

    // ** Manage scrolling to specific index in flat list
    const flatListRef = useRef()

    const ability = useContext(AbilityContext)

    const sortedChallenges = user?.challenges
        ?.sort((a, b) => moment(a?.created_at).isBefore(b?.created_at))
    // ?.sort((a, b) => {
    //     const aLateActivities = countLateActivitiesForChallenge(user?.activities, a)
    //     const bLateActivities = countLateActivitiesForChallenge(user?.activities, b)
    //
    //     return aLateActivities < bLateActivities
    // })

    // const homeWorkWrittenTemplate = props.activityTemplates.find(at => at.slug === "go_homework_written")
    // const homeWorkOralTemplate = props.activityTemplates.find(at => at.slug === "go_homework_oral")
    const standaloneActivityTemplates = props.activityTemplates
        ?.filter(at => at?.parent_type === "school_subject")
        ?.sort((a, b) => a?.title >= b?.title)


    // ** New challenge button press
    function onButtonPress() {

        const maxSimultaneousChallenges = props.maxSimultaneousChallenges[ability.can("read", "have_many_challenges") ? 'premium' : 'not_premium']

        // if student has already reached the max number of challenges
        if (user?.challenges?.length >= maxSimultaneousChallenges) {
            DialogM.show({
                text1: 'Maximum atteint', text2: 'Tu as atteint le nombre maximum de défis simultanés.'
            })
            //alertPolyfill("Maximum atteint", "Tu as atteint le nombre maximum de défis simultanés.")
        } else {
            navigation.push('new-challenge', {student_id: student ? user.id : null});
        }
    }

    const menuItems = [
        {
            title: "Historique",
            onPress: () => {
                navigation.push("challenges-history", {
                    studentId: student?.id
                })
            }
        }
    ]

    const [selectedItem, setSelectedItem] = useState(null);
    const bottomSheetRef = useRef();
    const [visible, setVisible] = useState(false)

    const ssBottomSheetRef = useRef();
    const [selectedActivityTemplateId, setSelectedActivityTemplateId] = useState(null)

    const schoolSubjectOptions = props.schoolSubjects?.map(x => {
        return {icon: x?.icon, value: x.id, label: x.name}
    })
    const onSelectSchoolSubject = (schoolSubjectId) => {
        navigation.push("activity-presentation", {
            activityTemplateId: selectedActivityTemplateId,
            schoolSubjectId: schoolSubjectId
        })
        setVisible(false)
    }

    const showSchoolSubjectBottomSheet = (activityTemplateId) => {
        setSelectedActivityTemplateId(activityTemplateId)
        ssBottomSheetRef.current?.present()
        setVisible(true)
    }

    const Press = (index) => {
        // if (student) return
        if (selectedItem) return
        Vibration.vibrate(50)
        setSelectedItem(sortedChallenges[index]?.id)
        DialogM.show({
            text1: 'Veux-tu vraiment abandonner ce défi ?',
            text2: 'Tu ne pourras plus faire d\'activité sur ce défi.',
            buttonText1: 'Abandonner',
            buttonColor1: theme.colors.error,
            onPress: () => {
                if (student) {
                    props.apiRequest(
                        challengesService.abandonChallengeAsTutor,
                        {studentId: student?.id, challengeId: sortedChallenges[index]?.id},
                        {
                            include_history_since_last_interview: 1
                        }
                    )
                } else {
                    props.apiRequest(
                        challengesService.abandonChallenge,
                        {id: sortedChallenges[index]?.id}
                    )
                }


            },
            buttonText2: 'Annuler',
            onPressBack: resetSelection
        })
        //setVisible2(true)
        //bottomSheetRef.current?.present()
    }

    const resetSelection = () => {
        setSelectedItem(null)
        //bottomSheetRef.current?.dismiss()
    }
    useApiStatus(
        challengesService.abandonChallenge, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            resetSelection()
        }, // success callback
        () => {
            resetSelection()
        } // error callback
    )
    useApiStatus(
        challengesService.abandonChallengeAsTutor, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            resetSelection()
        }, // success callback
        () => {
            resetSelection()
        } // error callback
    )

    const promptStoreReviewIsApplicable = async () => {
        const isAvailable = await StoreReview.isAvailableAsync()
        const hasAction = await StoreReview.hasAction()
        if (isAvailable && hasAction) {
            const count = await getActivitiesCount()
            // console.debug("count", count)
            if (count >= 1) {
                const lastVersionPromptedForReview = await getLastVersionPromptedForReview()

                // console.debug("lastVersionPromptedForReview", lastVersionPromptedForReview)
                if (lastVersionPromptedForReview !== Constants.expoConfig.version) {
                    return true
                }
            }
        }
        return false
    }

    useFocusEffect(
        useCallback(() => {

            if (hasRole(props.user, "student")) {
                let isActive = true;
                if (Platform.OS !== 'web')
                    promptStoreReviewIsApplicable().then((isApplicable) => {
                        if (isApplicable)
                            setTimeout(() => {
                                if (isActive) {
                                    // console.debug("PROMPT")
                                    setLastVersionPromptedForReview().then()
                                    StoreReview.requestReview()
                                        .then(() => {
                                            // console.log("success")
                                        })
                                        .catch((error) => {
                                            console.error("error", error)
                                        })
                                }
                            }, 2000)
                    })
                return () => {
                    isActive = false
                }
            }
        }, [])
    );


    const hideModal = () => {
        return setVisible(false);
    }
    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flex: 1
            // backgroundColor: theme.colors.whiteAlmost
        }}>
            <View style={{
                flex: 1,
                // backgroundColor: theme.colors.whiteAlmost,
                alignItems: 'center',
                width: Platform.OS === 'web' ? dimensions?.width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}>

                {(Platform.OS !== 'web' && !student) &&
                    <>
                        <StudentSuggestions showSchoolSubjectBottomSheet={showSchoolSubjectBottomSheet}/>
                    </>
                }
                <FlatList data={sortedChallenges}
                          ref={flatListRef}
                          refreshing={!!request}
                          onRefresh={() => props.apiRequest(getUserService, getUserParams)}
                          style={{
                              width: "100%"
                          }}
                          showsHorizontalScrollIndicator={false}
                          ListHeaderComponent={<>

                              <View style={{
                                  width: dimensions?.width,
                                  paddingVertical: 8
                              }}/>
                              {renderAdditionalHeader()}

                              {!student && <TourGuideZone
                                  zone={!user?.champion_intros_viewed_at?.zoe_menus ? 2 : null}
                                  text={"Ces 4 activités sont là pour t'accompagner chaque jour dans la réalisation de tes devoirs"}
                                  keepTooltipPosition
                                  borderRadius={16}
                              >
                                  <View style={{
                                      alignItems: 'flex-start',
                                      justifyContent: 'space-between',
                                      width: dimensions?.width,
                                      paddingBottom: 8,
                                      marginBottom: 20
                                      // marginTop: 10
                                  }}>
                                      <TitleM fontWeight="ExtraBold" style={{
                                          marginLeft: 20
                                      }}>
                                          LANCER UN DEVOIR
                                      </TitleM>

                                      <ScrollView horizontal
                                                  style={{
                                                      width: dimensions?.width,
                                                      backgroundColor: theme.colors.primaryLighter,
                                                      borderTopWidth: 1,
                                                      borderBottomWidth: 1,
                                                      borderColor: theme.colors.lightMore,
                                                      marginTop: 20,
                                                      ...boxedCardBorderStyle
                                                  }}>
                                          <View
                                              style={{
                                                  flexDirection: 'row',
                                                  justifyContent: 'space-evenly',
                                                  paddingHorizontal: 20,
                                                  width: Platform.OS === 'web' ? dimensions?.width : null

                                              }}
                                          >
                                              <TourGuideZoneByPosition
                                                  zone={!user?.champion_intros_viewed_at?.zoe_menus ? 20 : null}
                                                  isTourGuide
                                                  top={Platform.OS === "ios" ? 0 : 24}
                                                  keepTooltipPosition
                                                  containerStyle={{
                                                      backgroundColor: "#ffffffef"
                                                  }}
                                                  text={"Maintenant, si tu as des devoirs à faire, commence par lancer une activité (sinon lance-toi un défi)"}
                                                  width={"100%"}
                                                  height={"100%"}
                                              />
                                              {standaloneActivityTemplates?.map(at => <ActivityButton
                                                  activityTemplate={at}
                                                  key={at?.id}
                                                  student={user}
                                                  // showSchoolSubjectBottomSheet={showSchoolSubjectBottomSheet}
                                                  activities={props.activities?.filter(x => x?.activity_template_id === at?.id)}
                                              />)}

                                              <FilledButtonM mode="text"
                                                             style={{
                                                                 marginLeft: 10,
                                                                 alignSelf: 'center',
                                                                 width: 160
                                                             }}
                                                             onPress={() => {
                                                                 navigation.push("all-activities", {
                                                                     // showSchoolSubjectBottomSheet,
                                                                     student
                                                                 })
                                                             }}
                                                             label="Voir plus"/>

                                          </View>
                                      </ScrollView>


                                  </View>
                              </TourGuideZone>}

                              <TourGuideZone
                                  zone={!user?.champion_intros_viewed_at?.zoe_menus ? 3 : null}
                                  keepTooltipPosition
                                  text={"Les défis te permettent de planifier des activités pour atteindre un objectif, comme par exemple préparer un contrôle"}

                                  borderRadius={16}
                              >
                                  <View style={{
                                      flexDirection: "row",
                                      alignItems: 'center',
                                      justifyContent: 'space-between',
                                      width: dimensions?.width,
                                      borderColor: theme.colors.lightMore,
                                      paddingVertical: 8,
                                      paddingLeft: 20,
                                      marginBottom: 20
                                      // marginTop: 10
                                  }}>
                                      <View style={{
                                          alignItems: 'flex-start'
                                      }}>
                                          <TitleM fontWeight="ExtraBold">
                                              MES DÉFIS
                                          </TitleM>
                                          <TextM fontWeight="SemiBold" fontSize={12} style={{marginTop: -4}}>
                                              {sortedChallenges?.length ?? 0} en cours
                                          </TextM>
                                      </View>

                                      <View style={{flexDirection: "row", marginLeft: 10}}>
                                          <Menu3Point menuItems={menuItems}/>
                                      </View>

                                  </View>
                              </TourGuideZone>
                          </>}
                          ListFooterComponent={<View style={{
                              marginBottom: 100
                          }}>
                              <FilledButtonM
                                  mode={sortedChallenges?.length > 0 ? "outlined" : null}
                                  color={theme.colors.primary}
                                  style={{
                                      marginTop: 40,
                                      marginHorizontal: 10,
                                      width: Platform.OS !== 'web' ? dimensions?.width - 20 : null
                                  }}
                                  icon="plus"
                                  onPress={onButtonPress}
                                  label="Lancer un nouveau défi"/>

                              {user?.subscription_state?.status !== "recurring" && <BecomePremiumCard style={{
                                  marginTop: 40,
                                  marginBottom: 20,
                                  alignSelf: "center"
                              }}/>}
                          </View>}

                          renderItem={(item) => {

                              const activities = idsToObjects(item.item.activity_ids, user?.activities)

                              return <ChallengesListItem activities={activities}
                                                         student={student}
                                                         realizationMode={realizationMode && !item.item?.planning_mode}
                                                         selected={item.item?.id === selectedItem}
                                                         index={item.index}
                                                         press={Press}
                                                         challenge={item.item}
                              />
                          }}/>


                {Platform.OS !== 'web' ? <SchoolSubjectBottomSheetModal ref={ssBottomSheetRef}
                                                                        onSelectSchoolSubject={onSelectSchoolSubject}
                                                                        options={schoolSubjectOptions}
                                                                        height="70%"/>
                    : <ModalM visible={visible} onDismiss={hideModal} style={{height: "90%"}}>
                        <SchoolSubjectModal onSelectSchoolSubject={onSelectSchoolSubject}
                                            options={schoolSubjectOptions}
                                            setVisible={setVisible}
                        />
                    </ModalM>}
                <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

            </View>

            {/*{props.isLive && <OnlineUsers/>}*/}
        </View>
    );
}

const mapStateToProps = state => {
    return {
        abandonChallengeAsTutorRequest: state.api.abandonChallengeAsTutor?.request,
        abandonChallengeRequest: state.api.abandonChallenge?.request,
        abandonChallengeSuccess: state.api.abandonChallenge?.success,
        abandonChallengeError: state.api.abandonChallenge?.error,
        abandonChallengeSuccessData: state.api.abandonChallenge?.data,

        savedActivityData: state.data?.formData?.activity,

        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        getUserError: state.api.getUser?.error,
        user: state.data.currentUser?.object,
        last_homework_suggestion: state.data.suggestions?.last_homework_suggestion,
        last_additional_suggestion: state.data.suggestions?.last_additional_suggestion,
        schoolSubjects: state.data.staticData?.school_subjects,
        maxSimultaneousChallenges: state.data.staticData?.max_simultaneous_challenges,
        activityTemplates: state.data.staticData?.activity_templates,
        challengeTemplates: state.data.staticData?.challenge_templates,
        isLive: state.liveMode.isLiveModeEnabled,
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        inCallUsers: state.liveMode?.inCallUsers,
        getLiveSessionSuccess: state.api.getLiveSession?.success,
        callPortal: state.liveMode?.callPortal,

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        clearActivityData,
        setInCallUsers,
        removeInCallUsers
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesTabScreen)