import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import * as yup from "yup";
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {theme} from "@common/theme/theme";
import {activitiesService} from "@data/services/activitiesService";
import {useInterval} from "@common/utils/useInterval";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {authService} from "@data/services/authService";
import {congratsTypes} from "@common/screens/CongratsScreen";


const LaunchLiveScreen=(props)=>{
    const navigation=useNavigation()

    const [formValues, setFormValues] = useState(null)
    const defaultValues = {}


    const currentSubject=props.schoolSubjects?.find(s=>s?.id===formValues?.schoolSubjectId)

   // console.log(currentSubject)

    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "En quelle matière veux-tu commencer à travailler ?",
            description: "",
            type: "select.single", // select.single, select.multiple, text, date
            name: "schoolSubjectId",
            options: props.schoolSubjects
                // ?.slice(0, 3)
                ?.map(x => {
                    return {icon: x?.icon, value: x.id, label: x.name}
                }),
            defaultValue: "",
            validationRule: yup.string().required()
        },
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Que souhaites-tu faire en " + currentSubject?.name +" ?",
            description: "",
            type: "select.single", // select.single, select.multiple, text, date
            name: "activity_type",
            options: [
                {value:"faire un devoir",label:"Faire un devoir",icon:props.activityTemplates?.find(a=>a?.slug==="go_homework_written")?.icon},
                {value:"apprendre une leçon",label:"Apprendre une leçon",icon:props.activityTemplates?.find(a=>a?.slug==="chapter_card")?.icon},
                {value:"t'exercer",label:"M’exercer",icon:props.activityTemplates?.find(a=>a?.slug==="go_exo")?.icon},
                {value:"réviser une leçon",label:"Réviser une leçon",icon:props.activityTemplates?.find(a=>a?.slug==="flash_revision")?.icon},
                {value:"comprendre une leçon",label:"Comprendre une leçon",icon:props.activityTemplates?.find(a=>a?.slug==="video_course")?.icon}
            ],
            defaultValue: "",
            validationRule: yup.string().required()
        },
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Choisis une activité pour "+ formValues?.activity_type +" en " + currentSubject?.name,
            description: "",
            type: "select.single", // select.single, select.multiple, text, date
            name: "activityTemplateId",
            height: 90,
            options: props.activityTemplates?.filter(a=>{
                switch(formValues?.activity_type){
                    case("faire un devoir"):return a?.slug?.includes('go_homework');
                    case("t'exercer"):return (a?.type==="written_exercise"||a?.type==="oral_exercise") && (!a?.slug?.includes('go_homework'));
                    case("apprendre une leçon"):return a?.type==="card";
                    case("réviser une leçon"):return a?.type==="revision";
                    case("comprendre une leçon"):return a?.type==="external_resource";
                    default:break;
                }
            })
                ?.map(x => {
                    return {
                        value: x?.id,
                        label: x?.title,
                        icon:x?.icon,
                        description:x?.description
                    }
                }),
            defaultValue: "",
            validationRule: yup.string().required()
        },

    ]

    const onSubmit = data => {
        //console.log("data", data)
            navigation.replace("activity-presentation",data)
    }
    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={{name: "zoe", variant: "main"}}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         submitButtonText="Commencer"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}

const mapStateToProps = state => {
    return {
        student:state.data?.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        activityTemplates: state.data.staticData?.activity_templates,
        initActivityRequest:state.api?.initActivity?.request,
        initActivityError:state.api?.initActivity?.error
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,

};

export default connect(mapStateToProps, mapDispatchToProps)(LaunchLiveScreen)