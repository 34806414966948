import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TitleM from "@common/components/text/TitleM";
import React, {useContext} from "react";

export const ProfileSection = ({title, children, style, noBorder = false, tiny = false}) => {
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        paddingHorizontal: 15,
        paddingVertical: tiny ? 5 : 20,
        alignItems: "flex-start",
        justifyContent: 'flex-start',
        borderBottomWidth: noBorder? 0 : 1,
        borderColor: theme.colors.lightMore,
        width: dimensions?.width,
        ...style
    }}>
        {title && <TitleM style={{
            width: "80%",
            fontSize: tiny ? 16 : 20,
        }}
                          color={tiny ? theme.colors.grey : theme.colors.primary}
                          fontWeight="Bold">{title} </TitleM>}

        {children}
    </View>
}