import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import SuccessAnimation from "@common/animations/SuccessAnimation";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import {ProgressBarM} from "@common/components/progress/ProgressBarM";


const QuizzInitializedCongrats = (props) => {
    const dimensions = useContext(DimensionsContext)

    const [canStartAnimation, setStartAnimation] = React.useState(false)

    useEffect(() => {
        PlaySoundM.play(require('../../assets/sounds/finished.mp3'))
        setTimeout(() => {
            setStartAnimation(true)
        }, 1000)

    }, [])

    return (
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 20,
            paddingHorizontal: 15,
            paddingBottom: 20,
            width: dimensions?.width
        }}>
            <SuccessAnimation
                size={100}
                iconSize={100 * 0.7}
                dotColor={theme.colors.success}
                backgroundColor={theme.colors.success}
                iconColor={"white"}
                dotSize={20}
                duration={2000}
                style={{
                    // position: "absolute",
                    // alignSelf: "center",
                    marginBottom: 100 / 3,
                    // right: -successSize / 3
                    // right: -30
                }}
                animatedLayerColor={"white"}
            />
            <TextM fontSize={22}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center"
                       // marginTop: 50
                   }}
                   color={theme.colors.primary}>
                {props.congratsTitle.toUpperCase()}
            </TextM>

            <TextM fontSize={18}
                   wrap
                   fontWeight="Regular"
                   style={{
                       textAlign: "center",
                       marginTop: 40,
                       marginHorizontal: 30
                   }}
                   color={theme.colors.grey}>
                {props.congratsText}
            </TextM>
            <TextM fontSize={18}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center",
                       marginTop: 20,
                       marginHorizontal: 30
                   }}
                   color={theme.colors.accent}>
                {"Score"}
            </TextM>
            <ProgressBarM progressColor={theme.colors.accent}
                          // previousCount={0}
                          count={Math.round(props.score)}
                          // canStartAnimation={canStartAnimation}
                          barWidth={200}
                          percent
                          // withAnimation
                          total={100}/>



        </View>)
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(QuizzInitializedCongrats)