import React, {useContext, useEffect, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {RefreshControl, ScrollView, View} from "react-native";
import {countSameActivitiesToday, countSameAgainActivities, findItemInListWithId} from "@data/utility/dataUtils";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {StatusBar} from "expo-status-bar";
import {activitiesService} from "@data/services/activitiesService";
import {AbilityContext} from "@data/utility/Can";
import ActivityTemplateHeader from "./ActivityTemplateHeader";
import SingleActivityResult from "./SingleActivityResult";
import {whatIsIt} from "@data/utility/Utils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {ActivityIndicator} from "react-native-paper";
import useLaunchActivity from "@common/hooks/useLaunchActivity";
import {hasRole} from "@data/utility/ability";
import {SnackBarM} from "@common/components/alert/snackbar/SnackBar";

const ActivityResultScreen = props => {

    // ** Component props
    const {navigation, route} = props
    const {studentId, activityIds, noLaunchButton, fromNotification} = route.params

    // console.log(studentId, activityIds)

    const ability = useContext(AbilityContext)

    const liveSession = props.currentLiveSession
    const studentsList = props.isLiveMode ? liveSession?.users : props.user?.students

    const user = studentId ? studentsList?.find(s => s.id === parseInt(studentId)) : props.user

    const userActivities = user?.activities?.concat(user?.history?.activities)
    const userChallenges = user?.challenges?.concat(user?.history?.challenges)

    const [resultActivities, setResultActivities] = useState([])
    const activityIdsString = whatIsIt(activityIds) === "Array" ? activityIds?.join(",") : activityIds

    // ** Component variables
    const activities = resultActivities
    const activity = activities?.[0]
    const activityTemplate = activity ? findItemInListWithId(activity?.activity_template_id, props.activityTemplates) : null
    const challenge = activity?.challenge_id ? findItemInListWithId(activity?.challenge_id, userChallenges) : null
    const schoolSubject = activity?.school_subject_id ? findItemInListWithId(activity?.school_subject_id, props.schoolSubjects) : challenge?.school_subject

    const sameActivitiesCount = challenge?.planning_mode ? countSameAgainActivities(activityTemplate, activities, activity?.id) : countSameActivitiesToday(activityTemplate, activities, challenge ? challenge?.id : null, schoolSubject ? schoolSubject?.id : null)

    // console.log(sameActivitiesCount)

    // const sameActivitiesTodayCount = countSameActivitiesToday(
    //     activityTemplate,
    //     user,
    //     challenge ? challenge?.id : null,
    //     schoolSubject ? schoolSubject?.id : null
    // )
    const maxReached = sameActivitiesCount >= activityTemplate?.max_per_day

    const maxReachedTitle = "Nombre max atteint"
    const maxReachedText = "Tu as atteint le maximum d'activités '" + activityTemplate?.title + "' sur " + (challenge?.id ? "ce défi" : "cette matière") + " aujourd'hui. Tu peux faire d'autres activités ou revenir demain."
    const requestApiService = hasRole(props.user, 'student') ? activitiesService.activityTodoAsStudent : activitiesService.activityTodoAsTutor
    const launchActivity = useLaunchActivity()

    useApiStatus(
        requestApiService, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (hasRole(props.user, 'student')) {
                launchActivity({
                    activity_template_id: activityTemplate?.id,
                    id: data.id,
                    school_subject_id: schoolSubject?.id,
                    chapterName: activity?.student_chapter?.chapter?.name,
                    chapterId: activity?.student_chapter?.id
                }, "replace")
            } else {
                SnackBarM.show({
                    text1: "✅ La même activité a été ajoutée à la liste de l'élève",
                    duration: 4000,
                });
                navigation.goBack()
            }
        } // success callback
    )
    const onPress = () => {
        if (maxReached) {
            DialogM.show({
                text1: maxReachedTitle,
                text2: maxReachedText
            })
            //alertPolyfill(maxReachedTitle, maxReachedText)
            return
        }
        props.apiRequest(requestApiService, hasRole(props.user, "student") ? {} : {id: studentId}, {
            activity_template_id: activityTemplate?.id,
            school_subject_id: schoolSubject?.id,
            material_id: activity?.material?.id,
            material_chapter: activity?.chapter,
            estimated_duration: activity?.estimated_duration,
            chapter_id: activity?.student_chapter?.chapter?.id,
            chapter_name: activity?.student_chapter?.chapter?.name,
            chapter_class: activity?.student_chapter?.chapter?.class
        })
        /*
        if (hasRole(user, "student") && !user?.champion_intros_viewed_at?.abou) {
            navigation.push("intro", {
                name: "abou",
                nextScreen: "activity",
                nextScreenParams: {
                    activityTemplateId: activityTemplate?.id,
                    challengeId: challenge?.id,
                    againActivityId: challenge?.planning_mode ? activity?.id : null, // only provide activity id for activities which challenge is in planning mode
                    schoolSubjectId: schoolSubject?.id
                }
            })

        } else {
            navigation.replace("activity", {
                activityTemplateId: activityTemplate?.id,
                challengeId: challenge?.id,
                againActivityId: challenge?.planning_mode ? activity?.id : null, // only provide activity id for activities which challenge is in planning mode
                schoolSubjectId: schoolSubject?.id
            })
        }*/
    }

    const refresh = () => {
        props.apiRequest(activitiesService.getActivities, {}, {}, {
            with_logs: 1,
            activity_ids: activityIdsString
        })
    }

    useEffect(() => {

        if (!activity?.activity_logs || fromNotification)
            refresh()

    }, []);

    useApiStatus(
        activitiesService.getActivities, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            setResultActivities(data)
        } // success callback
    )


    const dimensions = useContext(DimensionsContext)


    return <View style={{
        flex: 1,
        // backgroundColor: theme.colors.white,
        alignItems: 'center',
        justifyContent: 'flex-start'
    }}>

        <ActivityTemplateHeader activityTemplate={activityTemplate}
                                activity={activity}
                                challenge={challenge}
                                navigation={navigation}
                                previous
                                schoolSubject={schoolSubject}/>

        {IS_WEB && !!props.getActivitiesRequest
            ? <ActivityIndicator animating={true} style={{marginTop: 10}} color={theme.colors.primary}/>
            : <ScrollView style={{
                flex: 1,
                backgroundColor: theme.colors.whiteAlmost
            }}
                          refreshControl={<RefreshControl
                              refreshing={!!props.getActivitiesRequest}
                              onRefresh={refresh}
                          />}>

                {activities?.map((a, index) => <SingleActivityResult key={index} activity={a}/>)}
                <View style={{height: 120}}/>
            </ScrollView>}

        {(!challenge?.finished_at && !challenge?.abandoned_at) && !noLaunchButton && !maxReached && !!activity?.finished_at &&
            <View style={{
                backgroundColor: theme.colors.primaryLighter,
                paddingBottom: 10,
                paddingTop: 20,
                borderTopWidth: 1,
                borderColor: theme.colors.lightMore,
                position: "absolute",
                width: "100%",
                alignItems: "center",
                bottom: 0
            }}>

                <FilledButtonM color={theme.colors.grey}
                               onPress={onPress}
                               style={{
                                   width: dimensions?.width - 20
                               }}
                               icon={"redo"}
                               loading={props.activityTodoAsStudentRequest || props.activityTodoAsTutorRequest}
                               label={hasRole(props.user, "student") ? "Refaire l'activité" : "Redonner l'activité"}/>

            </View>}

        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

    </View>
}
const mapStateToProps = state => {
    return {
        getActivitiesRequest: state.api.getActivities?.request,
        getActivitiesSuccess: state.api.getActivities?.success,
        getActivitiesError: state.api.getActivities?.error,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        activityTodoAsStudentRequest: state.api.activityTodoAsStudent?.request,
        activityTodoAsTutorRequest: state.api.activityTodoAsTutor?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityResultScreen)