import React, {useContext} from 'react'
import {DimensionsContext, IS_WEB} from "../common/utils/mobileUtils";
import {
    VictoryArea,
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryTooltip,
    VictoryVoronoiContainer
} from "victory-native";
import {theme} from "../common/theme/theme";

export const AnalyticsChart = (props) => {
    const dimensions = useContext(DimensionsContext)
    return (<>
        {props.values && <VictoryChart
            //padding={{right: -20, left: -20}}
            width={IS_WEB && dimensions.width > 800 ? (dimensions.width) / 2.2 : dimensions.width - 20}
            domainPadding={{x: 50, y: 50}}
            containerComponent={
                <VictoryVoronoiContainer/>
            }
        >


            {props.values && props.values.map((s, index) => {
                return <VictoryGroup offset={10}
                                     key={index}


                >
                    <VictoryArea
                        style={{
                            data: {
                                fill: s.color + "DD",
                                stroke: s.color,
                                strokeWidth: 2,
                                padding: '0 0 1px 0',
                            },
                        }}
                        data={s.data}
                        y={d => d.datum?._y}
                        domain={{y: Math.max(...s.data) === 0 ? [0, 1] : [0, Math.max(...s.data)]}}
                        labels={d => props.categories[d.datum?._x] + "\n" + s.name + ": " + d.datum?._y}
                        labelComponent={<VictoryTooltip
                            constrainToVisibleArea
                            renderInPortal={false}
                            cornerRadius={0}
                            style={{fill: s.color}}
                            flyoutStyle={{
                                stroke: "none",
                                fill: theme.colors.white
                            }}
                        />}
                    />
                </VictoryGroup>

            })}


            <VictoryAxis dependentAxis style={{tickLabels: {fill: '#999999'}}}
                         tickCount={4}
            />
            <VictoryAxis style={{
                axis: {stroke: "transparent"},
                ticks: {stroke: "transparent"},
                tickLabels: {fill: "transparent"}
            }}
            />

        </VictoryChart>
        }</>)
}