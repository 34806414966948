import {connect} from "react-redux";
import React from "react";
import {apiRequest} from "../../../data/redux/actions/api";
import {HeaderContainerM} from "../../../common/header/HeaderContainerM";
import {IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {theme} from "../../../common/theme/theme";
import {Dimensions, View} from "react-native";
import TodoListScreen from "./TodoListScreen";
import {IconImageM} from "../../../common/components/icons/IconsM";
import TextM from "../../../common/components/text/TextM";
import {formatRelativeDateTime} from "../../../data/utility/Utils";
import {ActivityLevelBadge} from "../../../common/chapters/ActivityLevelBadge";

const TodoListByChapterScreen = (props) => {
    const {todosList} = props.route.params;

    const navigation = useNavigation();
    const chapter = todosList[0]?.student_chapter?.chapter;
    const schoolSubject = props.schoolSubjects?.find(
        (subject) =>
            subject.id?.toString() ===
            (chapter?.school_subject_id?.toString() ??
                todosList[0]?.school_subject_id?.toString())
    );
    const color = schoolSubject?.color ?? theme.colors.info;
    const differenceInDaysString = formatRelativeDateTime(
        chapter?.last_set_ongoing_at,
        true
    );
    const level = chapter?.student_chapters?.[0]?.level ?? chapter?.level;

    return (
        <>
            <HeaderContainerM
                style={{
                    width: Dimensions.get("window").width,
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {schoolSubject?.icon && (
                        <IconImageM uri={schoolSubject?.icon} width={50} style={{marginLeft: 10}}/>
                    )}
                    <View
                        style={{
                            marginLeft: 10,
                        }}
                    >
                        <TextM color={color} fontWeight={"SemiBold"} fontSize={17} wrap>
                            {chapter ? chapter?.name : "Autres activités"}
                        </TextM>
                        <TextM fontSize={11} fontWeight={"SemiBold"} wrap>
                            {chapter?.parent_chapter?.name
                                ? chapter?.parent_chapter?.name + " - "
                                : ""}
                            {schoolSubject?.name} - {chapter?.class}
                        </TextM>
                        {!!todosList[0]?.student_chapter &&
                            chapter?.student_chapters?.length > 0 && (
                                <TextM
                                    fontSize={10}
                                    fontWeight={"Light"}
                                    color={theme.colors.light}
                                >
                                    {differenceInDaysString}
                                </TextM>
                            )}
                        {level && !renderChapterOnly && (
                            <ActivityLevelBadge level={level}/>
                        )}
                    </View>
                </View>
                <IconButton
                    icon={"arrow-left"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4,
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack();
                        else navigation.replace("menu");
                    }}
                />
            </HeaderContainerM>
            <View
                style={{
                    marginTop: 40,
                    flex: 1,
                }}
            >
                <TodoListScreen todoList={todosList} goBack />
            </View>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
    };
};

const mapDispatchToProps = {
    apiRequest,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TodoListByChapterScreen);
