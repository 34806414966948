import React, { useContext, useEffect, useRef, useState } from 'react'
import {MaxUidContext, MinUidContext, PropsContext, RtcContext} from "agora-react-uikit";
import {theme} from "@common/theme/theme";
import MaxVideoViewWebM from "@common/video-call/AgoraUIKitWeb/MaxVideoViewWebM";

/**
 * React Component to render the user videos in a grid
 */
const GridVideo = () => {
    const { styleProps, rtcProps } = useContext(PropsContext)
    const {mediaStore, client} = useContext(RtcContext)
    const { gridVideoCells, gridVideoContainer } = styleProps || {}
    const max = useContext(MaxUidContext)
    const min = useContext(MinUidContext)
    const users =
        rtcProps.role === 'audience'
            ? [...max, ...min].filter((user) => user.uid !== 0)
            : [...max, ...min]
    const parentRef = useRef(null)
    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)
    const isLandscape = width > height
    const unit = 'minmax(0, 1fr) '

    const [speakerStyle, setSpeakerStyle] = useState({});
    const [speakerUid, setSpeakerUid] = useState(null);

    useEffect(() => {

        client.enableAudioVolumeIndicator()
        client.on("volume-indicator", volumes => {
            volumes.forEach((volume) => {
                if (rtcProps.uid === volume.uid && volume.level > 5) {
                    setSpeakerUid(volume.uid)
                    setSpeakerStyle({
                        borderColor: theme.colors.success,
                        borderWidth: 2,
                    })
                } else if (rtcProps.uid === volume.uid && volume.level < 5) {
                    setSpeakerUid(null)
                    setSpeakerStyle({});
                }
                if (rtcProps.uid !== volume.uid && volume.level > 5) {
                    setSpeakerUid(volume.uid)
                    setSpeakerStyle({
                        borderColor: theme.colors.success,
                        borderWidth: 2,
                    });
                } else if (rtcProps.uid !== volume.uid && volume.level < 5) {
                    setSpeakerUid(null)
                    setSpeakerStyle({});
                }
            });
        })
        const handleResize = () => {
            if (parentRef.current) {
                setWidth(parentRef.current.offsetWidth)
                setHeight(parentRef.current.offsetHeight)
            }
        }
        window.addEventListener('resize', handleResize)
        if (parentRef.current) {
            setWidth(parentRef.current.offsetWidth)
            setHeight(parentRef.current.offsetHeight)
        }
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <div
            ref={parentRef}
            style={{
                ...{
                    width: '100%',
                    height: '100%',
                    display: 'grid',
                    gridTemplateColumns: isLandscape
                        ? users.length > 9
                            ? unit.repeat(4)
                            : users.length > 4
                                ? unit.repeat(3)
                                : users.length > 1
                                    ? unit.repeat(2)
                                    : unit
                        : users.length > 8
                            ? unit.repeat(3)
                            : users.length > 2
                                ? unit.repeat(2)
                                : unit
                },
                ...gridVideoContainer
            }}
        >
            {users.map((user) => (
                <MaxVideoViewWebM
                    user={user}
                    style={{ ...{ height: '100%', width: '100%' }, ...gridVideoCells }}
                    key={user.uid}
                    speakerStyle={speakerUid === user.uid ? speakerStyle : {}}
                />
            ))}
        </div>
    )
}

export default GridVideo