import React from 'react';
import {Text, TextInput} from "react-native-paper";
import {theme} from "../../theme/theme";
import {View} from "react-native";

const UncontrolledTextInputM = React.forwardRef((props, ref) => {

    const {password, name, errors, onChangeText, value, style = {}, contentContainerStyle = {}} = props

    const [secure, setSecure] = React.useState(true);


    return <>
        <View style={{
            // marginHorizontal: 15
            width: "100%",
            ...contentContainerStyle
        }}>

            <TextInput
                {...props}
                ref={ref}
                selectionColor={theme.colors.light}
                mode="outlined"
                outlineColor={theme.colors.light}
                activeOutlineColor={theme.colors.light}
                style={{
                    fontSize: 16,
                    width: "100%",
                    ...style
                }}
                value={value}
                onChangeText={onChangeText}
                secureTextEntry={password && secure}
                right={password && <TextInput.Icon
                    color={theme.colors.primary}
                    name={secure ? "eye" : "eye-off"}
                    onPress={() => {
                        setSecure(!secure)
                    }}/>}
                error={errors && errors[name]}
            />
            {errors && errors[name] && <Text style={{
                color: theme.colors.error
            }}>{errors[name].message}</Text>}
        </View>

    </>
})

export default UncontrolledTextInputM