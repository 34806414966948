import {View} from "react-native";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React from "react";

export const KeyComponentItem = props => {

    const {style, children, keyText} = props

    const fontSize = 14
    const padding = 14

    return <View style={{
        ...style,
        padding: padding,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        borderWidth: 1,
        borderRadius: 14,
        width: "100%",
        borderColor: theme.colors.lightMore,
        backgroundColor: theme.colors.white,
        marginHorizontal: 10
    }}>
        <TextM fontSize={fontSize}
               fontWeight="Bold"
               color={theme.colors.light}>{keyText}</TextM>
        <View style={{
            // flex: 1,
            flexDirection: "column",
            width: "100%",
            marginTop: 5
        }}>
            {children}
        </View>
    </View>;
}