import {liveModeConstants} from "../../../constants/liveModeConstants";
import {messengerConstants} from "../../../constants/messengerConstants";

export function setIsLiveModeEnabled(newState, liveSessionId) {
    if (newState)
        return {type: liveModeConstants.IS_LIVE_MODE_CHANGE, newState, liveSessionId}
    else
        return {type: liveModeConstants.RESET_LIVE_MODE}
}

export function setIsTyping(newState) {
    return {type: liveModeConstants.IS_TYPING, newState}
}

export function setTypingUser(newState) {
    return {type: liveModeConstants.TYPING_USER, newState}
}

export function setLiveParticipants(newState) {
    return {type: liveModeConstants.LIVE_PARTICIPANTS, newState}
}

export function addParticipant(newState) {
    return {type: liveModeConstants.ADD_PARTICIPANT, newState}
}

export function removeParticipant(newState) {
    return {type: liveModeConstants.REMOVE_PARTICIPANT, newState}
}

// export function removeTutor(newState) {
//     return {type: liveModeConstants.REMOVE_TUTOR, newState}
// }

export function clearMembers() {
    return {type: liveModeConstants.CLEAR_MEMBERS}
}

export function showIncomingCall() {
    return {type: liveModeConstants.SHOW_INCOMING_CALL}
}

export function hideIncomingCall() {
    return {type: liveModeConstants.HIDE_INCOMING_CALL}
}

export function setInCallUsers(newState) {
    return {type: liveModeConstants.IN_CALL_USERS, newState}
}

export function addInCallUser(newState) {
    return {type: liveModeConstants.ADD_IN_CALL_USER, newState}
}

export function removeInCallUsers(newState) {
    return {type: liveModeConstants.REMOVE_IN_CALL_USERS, newState}
}

export function newLiveSessionLog(newState) {
    return {type: liveModeConstants.NEW_LIVE_SESSION_LOG, newState}
}

export function setTimesSinceLastCall(newState) {
    return {type: liveModeConstants.SET_TIMES_SINCE_LAST_CALL, newState}
}

export function setActiveTime(newState) {
    return {type: liveModeConstants.SET_ACTIVE_TIME, newState}
}

export function setRaisedHands(newState) {
    return {type: liveModeConstants.RAISED_HANDS, newState}
}

export function addRaisedHand(newState) {
    return {type: liveModeConstants.ADD_RAISED_HAND, newState}
}

export function removeRaisedHand(newState) {
    return {type: liveModeConstants.REMOVE_RAISED_HAND, newState}
}

export function setPhoneRinging(newState) {
    return {type: liveModeConstants.PHONE_RINGING, newState}
}

export function resetPhoneRinging() {
    return {type: liveModeConstants.RESET_PHONE_RINGING}
}

export function addPhoneRinging(newState) {
    return {type: liveModeConstants.ADD_PHONE_RINGING, newState}
}

export function removePhoneRinging(newState) {
    return {type: liveModeConstants.REMOVE_PHONE_RINGING, newState}
}

export function setCallPortal(newState, mode) {
    return {type: liveModeConstants.SET_CALL_PORTAL, newState, mode}
}

export function addMessage(newState) {
    return {type: liveModeConstants.SET_MESSAGES, newState}
}

export function resetLiveMessagesCount(threadId) {
    return {type: messengerConstants.RESET_LIVE_MESSAGES_COUNT, threadId}
}

export function playASound(sound) {
    return {type: liveModeConstants.PLAY_SOUND, sound}
}

export function setActivityFinalizedCount(sessionId, studentId) {
    return {type: liveModeConstants.ACTIVITY_FINALIZED, sessionId, studentId}
}

export function setIssoundMode(newState, userId) {
    return {type: liveModeConstants.SOUND_MODE, newState, userId}
}

export function setVoiceRecordingIds(newState) {
    return {type: liveModeConstants.VOICE_RECORDING_ACTIVITY, newState}
}

export function setCallKeepUUID(uuid) {
    return {type: liveModeConstants.SET_CALL_KEEP_UUID, uuid}
}