import {DeviceEventEmitter, TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import React from "react";
import TextM from "@common/components/text/TextM";
import {useNavigation} from "@react-navigation/native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ActivityButtonContent} from "./ActivityButtonContent";


function ActivityButton(props) {

    // ** Component props
    const {
        activityTemplate,
        activityPlanned,
        challenge,
        activities,
        schoolSubject,
        student,
        readOnly,
        onPress = null,
        // showSchoolSubjectBottomSheet,
        style
    } = props

    const navigation = useNavigation()

    const user = student ? student : props.user

    // console.log(activityTemplate)

    const goToActivity = () => {
        navigation.push("activity-presentation", {
            activityTemplateId: activityTemplate?.id,
            challengeId: challenge?.id,
            activityId: activityPlanned?.id,
            schoolSubjectId: schoolSubject?.id,
            studentId: student?.id
        })
    }

    // ** Planning mode rendering of activity
    // const sameActivitiesTodayCount = countSameActivitiesToday(activityTemplate, props.user, challenge ? challenge?.id : null, schoolSubject ? schoolSubject?.id : null)
    const activityDone = (!!activityPlanned && !!activityPlanned?.finished_at)

    const backgroundColor = theme.colors.link

    return <View
        style={{
            width: 100,
            height: "100%",
            alignItems: "center",
            // justifyContent: 'center',
            paddingBottom: 10,
            paddingTop: 20,
            ...style
        }}>

        {/* ** Popover shown to launch activity or to view history of activity */}
        {readOnly ? <ActivityButtonContent activityDone={activityDone}
                                           activities={activities}
                                           activityPlanned={activityPlanned}
                                           backgroundColor={backgroundColor}
                                           activityTemplate={activityTemplate}/>
            : <TouchableOpacity onPress={onPress ? onPress : () => {
                if (challenge)
                    goToActivity()
                else
                    DeviceEventEmitter.emit("selectSchoolSubject", {activityTemplateId: activityTemplate?.id});
                // showSchoolSubjectBottomSheet(activityTemplate?.id)
            }}>
                <ActivityButtonContent activityDone={activityDone}
                                       activityPlanned={activityPlanned}
                                       activities={activities}
                                       backgroundColor={backgroundColor}
                                       activityTemplate={activityTemplate}/>
            </TouchableOpacity>}

        <TextM fontSize={14}
               wrap
               fontWeight="Bold"
               style={{textAlign: "center", marginTop: 5}}
               color={theme.colors.primary}>{activityTemplate?.title}</TextM>
    </View>;
}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityButton)