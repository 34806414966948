import {Modal, Portal} from "react-native-paper";
import {theme} from "../../theme/theme";
import {DimensionsContext, IS_WEB} from "../../utils/mobileUtils";
import React, {useContext} from "react";

export const ModalM = (props) => {
    const {visible, onDismiss, children, style,takeFullScreenWidth=false} = props
    const dimensions = useContext(DimensionsContext)

    return <Portal>
        <DimensionsContext.Provider
            value={{width: dimensions.width, height: dimensions.height}}>
            <Modal visible={visible}
                   onDismiss={onDismiss}
                   theme={{
                       colors: {
                           backdrop: theme.colors.blackTransparent2
                       }
                   }}
                   contentContainerStyle={{
                       alignSelf: "center",
                       backgroundColor: theme.colors.white,
                       borderRadius: 10,
                       elevation: 10,
                       zIndex: 10,
                       width: takeFullScreenWidth ? dimensions?.width + 20 : dimensions?.width - 50 ,
                       // position: IS_WEB ? "fixed" : null,
                       // top: IS_WEB ? 0 : null,
                       ...style
                   }}
            >
                {children}
            </Modal>
        </DimensionsContext.Provider>
    </Portal>
}