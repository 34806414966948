import {TouchableOpacity, View} from "react-native";
import {IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import {DoneChip} from "../../activity/read/DoneChip";
import React, {useContext} from "react";
import {TodoActivityBadges} from "./TodoActivityBadges";
import {hasRole} from "@data/utility/ability";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {formatCalendarMoment} from "@data/utility/Utils";
import {LinearGradient} from "expo-linear-gradient";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {useSelector} from "react-redux";

export const getActivityType = (activity) => {

    const activityTemplates = useSelector((state) => state.data.staticData?.activity_templates);

    const activityTemplate = findItemInListWithId(
        activity?.activity_template_id,
        activityTemplates
    );
    const formData = activityTemplate?.steps;
    return formData?.find((step) => step.type === "photo")
        ? "photo"
        : "audio";
}

export function ActivityInfosIcons(props) {

    const large = props.large ?? false

    const duration = props.activity?.duration;
    const mediaCount = props.activity?.media_count;
    const isStarted = !!props.activity?.started_at;
    const isLocked = !!props.activity?.locked_at;

    const activityType = getActivityType(props.activity);

    // useEffect(() => {
    //     if (
    //         props.updatedActivityEventData &&
    //         props.updatedActivityEventData?.activity_id === props.activity?.id
    //     )
    //         if (props.updatedActivityEventData.action === "activity chrono") {
    //             setDuration(props.updatedActivityEventData?.chrono_duration);
    //         } else if (
    //             props.updatedActivityEventData.action === "activity media deleted"
    //         ) {
    //             setMediaCount(mediaCount - 1);
    //         } else if (
    //             props.updatedActivityEventData.action === "activity media sent"
    //         ) {
    //             setMediaCount(mediaCount + 1);
    //         }
    // }, [props.updatedActivityEventData]);

    return (
        (isLocked || isStarted) && <View
            style={{
                flexDirection: "row",
                alignItems: "center",
                ...props.style
            }}
        >

            {<View
                style={{
                    alignItems: "center",
                    flexDirection: "row"
                }}
            >
                <LinearGradient
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        alignSelf: "flex-end",
                        //height: 120,
                        paddingVertical: isLocked ? 2 : 0,
                        paddingHorizontal: isLocked ? 4 : 0,
                        marginHorizontal: 0,
                        borderRadius: 6
                    }}
                    colors={isLocked ? [theme.colors.primary, theme.colors.primary] : [theme.colors.whiteTransparent, theme.colors.whiteTransparent]}
                >
                    <FontAwesome5
                        name={activityType === "photo" ? "camera" : "microphone"}
                        color={isLocked ? theme.colors.white : theme.colors.light}
                        solid
                        size={large ? 16 : 12}
                        style={{}}
                    />
                    <TextM
                        color={isLocked ? theme.colors.white : theme.colors.light}
                        fontWeight={"SemiBold"}
                        style={{marginLeft: 4}}
                        fontSize={large ? 14 : 10}
                    >
                        {mediaCount ?? 0}
                    </TextM>
                </LinearGradient>
            </View>
            }
        </View>
    );
}

function DragAndDropHandle(props) {
    const height = props.height || 50;

    const table = Array.from(Array(Math.round(height / 3.6)).keys());

    return table?.map((item, index) => (
        <View
            key={index}
            style={{
                width: 4,
                height: 4,
                borderRadius: 2,
                margin: 1.4,
                backgroundColor: theme.colors.white,
                opacity: 0.6
            }}
        />
    ));
}

export const RealizationsListItemContent = (props) => {
    const {
        activity,
        challenge,
        schoolSubject,
        noBorder,
        noClass,
        todo = false,
        liveMode = false,
        user,
        onPressSwipe,
        dragButton,
        style,
        updatedActivityEventData,
        currentUser,
        noHeight = false,
        custom = true,
        tinyMode = false
    } = props;
    const challengeTitle = challenge ? challenge?.title : null;
    const activitySchoolSubjectName = challenge
        ? challenge?.school_subject?.name
        : schoolSubject?.name;
    // console.log(activity?.student_chapter?.chapter?.name)
    const schoolSubjectColor = challenge
        ? challenge?.school_subject?.color
        : schoolSubject?.color === ""
            ? theme.colors.info
            : schoolSubject?.color;
    const dimensions = useContext(DimensionsContext);
    const status =
        activity?.started_at !== null &&
        (activity?.locked_at !== null)
            ? "En cours"
            : activity?.started_at !== null &&
            (activity?.locked_at === null)
                ? "En pause"
                : null;

    const activityType = getActivityType(activity);

    return (
        <View
            style={{
                width: "100%",
                height: liveMode ? (noHeight ? "100%" : null) : null,
                flexDirection: "row",
                ...style
                //alignItems: "center",
            }}
        >
            <View
                style={{
                    width: IS_WEB ? 16 : 10,
                    backgroundColor: schoolSubjectColor,
                    borderTopLeftRadius: 9,
                    borderBottomLeftRadius: 9,
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                {!!todo && IS_WEB && (
                    <TouchableOpacity
                        onPressIn={dragButton}
                        style={{
                            //position: "absolute",
                            //left: -25,
                            //top: liveMode ? 4 : null,
                            width: liveMode ? null : null,
                            height: custom ? 50 : 80,
                            alignSelf: "center",
                            flexWrap: "wrap",
                            flexDirection: liveMode ? "column" : "column",
                            marginTop: 4
                        }}
                    >
                        <DragAndDropHandle height={custom ? 50 : 80}/>
                    </TouchableOpacity>
                )}
            </View>
            <View
                style={{
                    flexDirection: "row",
                    // alignItems: "center",
                    justifyContent: "space-between",
                    alignContent: "center",
                    flex: 1,
                    paddingVertical: 4,
                    paddingLeft: 5,
                    backgroundColor:
                        hasRole(currentUser, "tutor") && !!activity?.locked_at && !activity?.finished_at
                            ? theme.colors.linkLighter
                            : theme.colors.white,
                    borderTopRightRadius: 9,
                    borderBottomRightRadius: 9
                }}
            >
                <View
                    style={{
                        paddingLeft: 5,
                        flex: 1,
                        maxWidth: tinyMode ? "80%" : "90%",
                        justifyContent: "space-between"
                        // backgroundColor: "red"
                    }}
                >
                    <View style={{
                        flexDirection: custom ? "row" : "column",
                        alignItems: custom ? "center" : "flex-start"
                    }}
                    ><View
                        style={{
                            //alignItems: "center",
                            //justifyContent: "center",
                            //marginLeft: IS_WEB && !liveMode ? 16 : 6,
                            marginLeft: -3,
                            marginRight: 5,
                            marginBottom: custom ? 0 : -5
                            // marginTop: 5
                        }}
                    >
                        <IconImageM
                            width={custom ? 30 : 50}
                            style={
                                {
                                    //position: "absolute"
                                }
                            }
                            uri={activity?.icon}
                        />
                    </View>
                        {!custom && (<TextM
                                fontSize={custom ? 12 : 16}
                                wrap
                                fontWeight={custom ? "SemiBold" : "Bold"}
                                style={{
                                    marginBottom: !activity?.material?.title && !custom ? 5 : 0,
                                    marginLeft: custom ? 0 : 3
                                }}
                                color={theme.colors.grey}
                            >
                                {activity?.title}
                            </TextM>
                        )}
                        {!!activity?.material?.title && !custom && (
                            <TextM
                                fontSize={12}
                                wrap
                                fontWeight="Medium"
                                style={{
                                    marginLeft: 3
                                }}
                                color={theme.colors.grey}
                            >
                                {activity?.material?.title}
                            </TextM>
                        )}
                    </View>
                    {custom && (
                        <TextM
                            fontSize={12}
                            fontWeight={"SemiBold"}
                            color={theme.colors.grey}
                            wrap
                        >
                            {activity?.material?.title ?? activity?.title}
                        </TextM>
                    )}
                    {liveMode && (
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <TodoActivityBadges
                                activity={activity}
                                updatedActivityEventData={updatedActivityEventData}
                            />
                            {hasRole(currentUser, "tutor") && (
                                <ActivityInfosIcons
                                    activity={activity}
                                    status={status}
                                    updatedActivityEventData={updatedActivityEventData}
                                    activityType={activityType}
                                    style={{
                                        marginLeft: 6,
                                        marginRight: 10
                                    }}
                                />
                            )}
                        </View>
                    )}
                    {(!noBorder || todo) && (
                        <TextM
                            fontWeight="Bold"
                            //wrap
                            color={schoolSubjectColor}
                            style={{
                                marginLeft: custom ? 0 : -5,
                                marginRight: 5,
                                marginTop: custom ? 2 : 5,
                                paddingRight: 5
                                // backgroundColor: "blue"
                                //textAlign: "center",
                                // maxWidth: liveMode ? 200 : 240
                            }}
                            fontSize={10}
                        >
                            {activity?.student_chapter?.chapter?.name
                                ? activity?.student_chapter?.chapter?.name?.length > 20
                                    ? activity?.student_chapter?.chapter?.name?.substring(0, 20) +
                                    "..."
                                    : activity?.student_chapter?.chapter?.name
                                : schoolSubject?.name?.length > 4
                                    ? schoolSubject?.name?.substring(0, 4) + "."
                                    : schoolSubject?.name}
                        </TextM>
                    )}
                </View>

                {!liveMode && (
                    <View
                        style={{
                            justifyContent: "flex-end",
                            position: custom ? "absolute" : "relative",
                            bottom: custom ? 3 : 0,
                            right: 0
                        }}
                    >
                        {!todo ? (
                            <View style={{alignItems: "flex-end"}}>
                                <TextM
                                    fontWeight="Regular"
                                    fontSize={custom ? 10 : 12}
                                    wrap
                                    color={theme.colors.grey}
                                    style={{marginTop: 5, marginRight: 10, textAlign: custom ? 'center' : "right"}}
                                >
                                    {formatCalendarMoment(activity?.finished_at)}
                                </TextM>
                                <DoneChip
                                    count={0}
                                    status={activity?.status}
                                    style={{
                                        marginTop: 5,
                                        alignSelf: "flex-end",
                                        marginRight: 10,
                                        marginBottom: 5
                                    }}
                                />
                            </View>
                        ) : (
                            <View
                                style={{
                                    // flexDirection: "row",
                                    alignItems: "flex-end",
                                    marginRight: 10
                                }}
                            >
                                {hasRole(currentUser, "tutor") && (
                                    <ActivityInfosIcons
                                        status={status}
                                        activity={activity}
                                        activityType={activityType}
                                        updatedActivityEventData={updatedActivityEventData}
                                        style={{
                                            // marginLeft: 6, marginRight: 10
                                            marginBottom: 6
                                        }}
                                    />
                                )}
                                <TodoActivityBadges
                                    activity={activity}
                                    custom={custom}
                                    updatedActivityEventData={updatedActivityEventData}
                                />
                            </View>
                        )}
                    </View>
                )}

                {!!todo && hasRole(user, "student") && (
                    <IconButton
                        icon={"ellipsis-h"}
                        iconColor={theme.colors.light}
                        size={24}
                        style={{
                            position: "absolute",
                            right: 0,
                            top: -5
                        }}
                        onPress={onPressSwipe}
                    />
                )}
                {/*{hasRole(currentUser, "tutor") && status === "En cours" && (*/}
                {/*    <LinearGradient*/}
                {/*        style={{*/}
                {/*            flexDirection: "row",*/}
                {/*            alignItems: "center",*/}
                {/*            justifyContent: "center",*/}
                {/*            position: "absolute",*/}
                {/*            right: !!todo && hasRole(user, "student") ? 40 : -6,*/}
                {/*            top: !!todo && hasRole(user, "student") ? 5 : -10,*/}
                {/*            paddingVertical: 2,*/}
                {/*            paddingHorizontal: 6,*/}
                {/*            borderRadius: 10*/}
                {/*        }}*/}
                {/*        colors={[theme.colors.link, theme.colors.link]}*/}
                {/*    >*/}
                {/*        <TextM*/}
                {/*            fontWeight="SemiBold"*/}
                {/*            fontSize={10}*/}
                {/*            wrap*/}
                {/*            color={theme.colors.white}*/}
                {/*            style={{}}*/}
                {/*        >*/}
                {/*            {"En cours"}*/}
                {/*        </TextM>*/}
                {/*    </LinearGradient>*/}
                {/*)}*/}
            </View>
        </View>
    );
};
