import {View} from "react-native";
import React, {useContext} from 'react'
import {DimensionsContext, IS_WEB} from "../common/utils/mobileUtils";
import {theme} from "../common/theme/theme";
import premiumPlus from "../../assets/icons/generic/premium-plus.png";
import premiumCoach from "../../assets/icons/generic/premium-coach.png";
import {Avatar} from "react-native-paper";
import TextM from "../common/components/text/TextM";
import {AnalyticsChart} from "./AnalyticsChart";
import {IconImageM} from "../common/components/icons/IconsM";
import {boxedCardBorderStyle} from "../reviewer/BoxedCardBorderStyle";

export const sumCC = (arr) => {
    return arr?.reduce((pValue, cValue) => [...pValue, (pValue?.length > 0 ? pValue?.[pValue?.length - 1] : 0) + cValue], [])
}
export const AnalyticsCard = (props) => {
    const dimensions = useContext(DimensionsContext)

    const productIcons = {
        plus: premiumPlus,
        premium: premiumCoach
    }
    const {stats, statTitle, style, categories, backgroundColor = theme.colors.accentLighter, icon} = props
    return (
        <View style={{
            ...boxedCardBorderStyle,
            backgroundColor: theme.colors.white,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: theme.colors.lightMore,
            marginVertical: 20,
            justifyContent: 'center',
            padding: 10,
            alignItems: 'center',
            width: props.values || !IS_WEB || dimensions.width < 800 ? IS_WEB && dimensions.width > 800 ? (dimensions.width - 80) / 2.4 : dimensions.width : dimensions.width / 5,
            ...style,
        }}>
            {icon ? <Avatar.Icon size={25}
                                 style={{
                                     backgroundColor: backgroundColor,
                                     width: 50,
                                     height: 50,
                                     borderRadius: 50,
                                     alignItems: 'center',
                                     justifyContent: 'center'
                                 }}
                                 icon={icon}

            /> : <View style={{
                marginTop: 30,
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Avatar.Image size={25}
                              style={{
                                  backgroundColor: backgroundColor,
                                  width: 50,
                                  height: 50,
                                  borderRadius: 50,
                                  alignItems: 'center',
                                  justifyContent: 'center'
                              }}
                />
                <IconImageM width={25}
                            style={{
                                position: "absolute"
                            }}
                            source={productIcons[props.product]}/>
            </View>
            }
            <TextM wrap fontWeight={"Bold"}>
                {stats}
            </TextM>
            <TextM wrap>
                {statTitle}
            </TextM>
            <AnalyticsChart values={props.values} categories={categories}/>
        </View>
    )
}