import {activitiesService} from "./activitiesService"
import {authService} from "./authService"
import {studentsService} from "./studentsService"
import {tutorsService} from "./tutorsService"
import {backOfficeService} from "./backOfficeService"
import {interviewsService} from "./interviewsService"
import {staticDataService} from "./staticDataService";
import {challengesService} from "./challengesService";
import {achievementsService} from "./achievementsService";
import {shopService} from "./shopService";
import {schoolsService} from "./schoolsService";
import {subscriptionService} from "./subscriptionService";
import {messengerService} from "./messengerService";
import {skillSetsService} from "./skillSetsService";
import {scoringService} from "./scoringService";
import {liveService} from "./liveService";
import {roomService} from "@data/services/roomService";
import {quizzService} from "@data/services/quizzservice";
import {liveReportService} from "@data/services/liveReportService";
import {referralService} from "@data/services/referralService";
import {chaptersService} from "@data/services/chaptersService";
import {interviewReportService} from "@data/services/interviewReportService";
import {gradesService} from "@data/services/gradesService";
import {studyProgramsService} from "@data/services/studyProgramsService";

export const apiService = {
    auth: authService,
    activities: activitiesService,
    schools: schoolsService,
    subscriptionService: subscriptionService,
    scoringService: scoringService,
    achievements: achievementsService,
    challenges: challengesService,
    staticData: staticDataService,
    shop: shopService,
    interviews: interviewsService,
    students: studentsService,
    tutors: tutorsService,
    backOffice: backOfficeService,
    messenger: messengerService,
    skill_sets: skillSetsService,
    live: liveService,
    liveReport: liveReportService,
    room: roomService,
    quizz: quizzService,
    referral: referralService,
    chapters: chaptersService,
    interviewReport: interviewReportService,
    grades: gradesService,
    studyPrograms: studyProgramsService
}
export {vaporStore} from "./vaporService";