import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";

const AcceptSubstitution = (props) => {

    const navigation = useNavigation()
    const {subId} = props.route.params
    const [formValues, setFormValues] = useState(null)

    useApiStatus(
        backOfficeService.acceptSubstitution, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("menu")
        }
    )

    const defaultValues = {}

    const formData = [
        {
            label: "Choisir un remplacant",
            type: "select.single", // select.single, select.multiple, text, date
            name: "substitute_id",
            options: props.tutors
                ?.map(x => {
                    return {
                        value: x?.id,
                        label: x?.display_name,
                        avatar: x,
                    }
                }),
            defaultValue: "",
            validationRule: {
                ...validation.required()
            }
        }

    ]

    const onSubmit = data => {
        if (props.acceptSubstitutionRequest) return
        props.apiRequest(backOfficeService.acceptSubstitution, {id: subId}, data)
    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.acceptSubstitutionRequest}
                         previousRouteName={""}
                         submittedMessage=""
                         serverError={props.acceptSubstitutionError}
                         submitButtonText="Terminer"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        acceptSubstitutionRequest: state.api?.acceptSubstitution?.request,
        acceptSubstitutionError: state.api?.acceptSubstitution?.error,
        liveSession: state.data.backOffice?.liveSession
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AcceptSubstitution)