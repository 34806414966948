import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React from "react";
import {View} from "react-native";

const ShareStatsScreen = () => {

    return <View />
}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ShareStatsScreen)