import {findItemInListWithId, interviewsForStudents} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {Linking, View} from "react-native";
import React, {useEffect, useState} from 'react'
import {theme} from "@common/theme/theme";
import ProgressStepper from "@common/components/stepper/ProgressStepper";
import InterviewStepX1 from "./interview steps/InterviewStepX1";
import {studentsService} from "@data/services/studentsService";
import RealizationsTabScreen from "../../../student/organize/list/RealizationsTabScreen";
import InterviewStepFinal from "./interview steps/InterviewStepFinal";
import {interviewsService} from "@data/services/interviewsService";
import {useForm} from "react-hook-form";
import InterviewChallengesStep from "./interview steps/InterviewChallengesStep";
import {useApiStatus} from "@common/hooks/useApiStatus";
import InterviewSummary from "./InterviewSummary";
import {ActivityIndicator, IconButton} from "react-native-paper";
import InterviewHeader from "./InterviewHeader";
import {IS_WEB} from "@common/utils/mobileUtils";
import {hasRole} from "@data/utility/ability";
import {DialogM} from "@common/components/alert/dialog/Dialog";

function Interview(props) {

    const student_id = props.route.params?.student_id ? props.route.params?.student_id : null
    const interview_id = props.route.params?.interview_id ? props.route.params?.interview_id : null
    const students =props.route.params?.isInBackOffice ? props.users?.filter(x => hasRole(x,"student")) : props.user?.students;
    //console.log(students)
    useEffect(() => {
        if (currentStudent()) props.apiRequest(studentsService.getStudent, {id: currentStudent()?.id}, {}, {include_history_since_last_interview: 1})

    }, [])

    const interviews = () => students ? interviewsForStudents(students) : props.user?.interviews
    const currentInterview = () => findItemInListWithId(parseInt(interview_id), interviews())
    const currentStudentId = () => currentInterview()?.student_id

    const studentForId = (student_id) => students?.find(student => parseInt(student?.id) === parseInt(student_id))
    const currentStudent = () => students ? studentForId(currentStudentId()) : props.user
    const currentSlot = () => props.slots?.find(slot => slot?.student_id === currentStudentId())

    const [currentStep, setCurrentStep] = useState(1)
    const {navigation} = props
    const goToNextStep = () => {
        if (!isLastStep) setCurrentStep(currentStep + 1)
    }
    const [globalComment, setGlobalComment] = useState("")

    const defaultValues = {
        comment: currentInterview()?.global_comment,
        status: "validated"
    }
    const {control, handleSubmit, formState: {errors, dirtyFields}, getValues, trigger} = useForm({
        defaultValues
    })

    const onSubmit = () => {
        if (globalComment?.length === 0) {
            DialogM.show({
                text1: 'Erreur', text2: 'Merci d\'indiquer un commentaire'
            })
            //alertPolyfill("Erreur", "Merci d'indiquer un commentaire")
            return
        }
        props.apiRequest(interviewsService.interviewGlobalComment, {id: interview_id}, {global_comment: globalComment})
    }
    useApiStatus(
        interviewsService.interviewGlobalComment, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            navigation.push("interview-summary", {student_id: student_id, interview_id: interview_id})
        } // success callback
    )

    const steps = [
        {
            id: 'step1',
            title: '',
            content: <InterviewStepX1 student={currentStudent()}
                                      interview={currentInterview()}
            />
        }, {
            id: 'step2',
            title: '',
            content: <>
                <InterviewHeader student={currentStudent()}
                                 interview={currentInterview()}
                />
                <RealizationsTabScreen student={currentStudent()}
                                       interview={currentInterview()}
                                       centerTitle
                />
            </>
        }, {
            id: 'step3',
            title: '',
            content: <InterviewChallengesStep student={currentStudent()}
                                              interview={currentInterview()}
            />
        }, {
            id: 'step4',
            title: '',
            content: <InterviewStepFinal student={currentStudent()}
                                         interview={currentInterview()}
                                         control={control}
                                         errors={errors}
                                         setGlobalComment={setGlobalComment}
            />
        }
    ]
    const isLastStep = currentStep === steps?.length

    const onWebConfClick = (e) => {
        props.apiRequest(interviewsService.interviewTutorConnected, {id: currentInterview()?.id})
        if (IS_WEB)
            window.open(currentSlot()?.conference_link, '_blank', 'noopener,noreferrer')
        else
            Linking.openURL(currentSlot()?.conference_link).catch(err => console.error("Couldn't load page", err))
    }

    useApiStatus(
        interviewsService.interviewTutorConnected, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )

    return (
        <>
            {currentInterview() ? <>
                {!currentInterview()?.finalized_at ?
                    <View style={{
                        flex: 1,
                        backgroundColor: theme.colors.whiteAlmost,
                        alignItems: 'center'
                    }}>
                        <ProgressStepper steps={steps}
                                         currentStep={currentStep}
                                         navigation={navigation}
                                         setCurrentStep={setCurrentStep}
                                         onNextButtonPress={isLastStep ? handleSubmit(onSubmit) : goToNextStep}
                                         isInterview
                                         exitWarning
                                         submitted={props.interviewGlobalCommentRequest}
                                         headerRightComponent={props.tutorConnectedRequest ?
                                             <ActivityIndicator color={theme.colors.primary}/> : <IconButton
                                                 icon={"video"}
                                                 iconColor={theme.colors.primary}
                                                 size={30}
                                                 onPress={onWebConfClick}
                                             />}
                                         exitWarningMessage={"L'entretien n'a pas encore été finalisé"}
                        />

                    </View> : <InterviewSummary student={currentStudent()} interview={currentInterview()}/>}
            </> : <ActivityIndicator color={theme.colors.primary}/>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        getStudentRequest: state.api.getStudents?.request,
        slots: state.data?.currentUser?.object?.interview_slots,
        tutorConnectedRequest: state.api.interviewTutorConnected?.request,
        interviewGlobalCommentRequest: state.api.interviewGlobalComment?.request,
        users: state.data.users?.list
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(Interview)