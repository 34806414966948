import { combineReducers } from "redux";
import { currentUser } from "./currentUser";
import tutors from "./tutors";
import users from "./users";
import { session } from "./session";
import { staticData } from "./staticData";
import reviewer from "./reviewer";
import formData from "./formData";
import suggestions from "./suggestions";
import videoCall from "./videoCall";
import backOffice from "./backOffice";
import referrals from "@data/redux/reducers/data/referrals";
import { liveReports } from "@data/redux/reducers/data/liveReports";
import chapters from "@data/redux/reducers/data/chapters";
import interviewReports from "@data/redux/reducers/data/interviewReports";
import materials from "@data/redux/reducers/data/materials";
import studyPrograms from "@data/redux/reducers/data/studyPrograms";

const dataReducers = combineReducers({
    currentUser,
    suggestions,
    reviewer,
    session,
    tutors,
    users,
    staticData,
    formData,
    videoCall,
    backOffice,
    referrals,
    liveReports,
    chapters,
    interviewReports,
    studyPrograms,
    materials,
});

export default dataReducers;
