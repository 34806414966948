import {VictoryAxis, VictoryBar, VictoryChart} from "victory-native";
import React, {useContext, useEffect, useState} from 'react'
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {statisticsColors} from "./ActivityDaysSinceLastWeek";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";

export const allValues = (d) => (d ? Object.values(d) : null)
export const formattedDataFromData = (d, type) => {
    if (!d) return []
    switch (type) {
        case "duration":
            return d?.map(x => ((x === 0 || x === undefined) ? 0.2 : Math.round(x / 60)))
        case "count":
            return d?.map(x => ((x === 0 || x === undefined) ? 0.05 : x))
        case "score":
            return d?.map(x => ((x === 0 || x === null) ? 0.05 : Math.round(x * 10) / 10))
        default:
            return []
    }
}
export const maxForData = (d, type) => {
    if (!d) return 10

    switch (type) {
        case "duration":
            return Math.max(20, Math.ceil((Math.max(...d) / 60) / 10) * 10)
        case "count":
            return Math.max(5, Math.ceil(Math.max(...d)))
        case "score":
            return 5
        default:
            return 10
    }
}

export function BarChartWeekSinceTwoWeeksAgo({data, type, x, y}) {

    const dimensions = useContext(DimensionsContext)

    const [dataSet, setDataSet] = useState([])
    const chartDataSet = formattedDataFromData(allValues(data), type)
    const max = maxForData(allValues(data), type)
    const backgroundColor = statisticsColors[type]
    const labels = ["il y a deux semaines", "la semaine dernière", "cette semaine"]
    //const  sampleDate = [1,3,2,4]
    const formatData = () => {
        let testData = [{}]
        chartDataSet.map((el, index) => {
            testData.push({
                x: labels[index],
                y: el
            })
        })
        setDataSet(testData)
    }

    useEffect(() => {

        formatData()
    }, [])


    return (
        <View style={{
            borderTopWidth: 1, borderColor: theme.colors.lightMore
        }}>
            {dataSet && dataSet.length > 0 &&
                <VictoryChart
                    width={IS_WEB && dimensions.width > 800 ? (dimensions.width - 80) / 3 : dimensions.width - 20}
                    domainPadding={{x: 20}}
                >
                    <VictoryBar
                        cornerRadius={{top: 20}}
                        barWidth={40}
                        style={{
                            data: {
                                fill: ({datum}) => datum.x === "cette semaine" ? backgroundColor : '#DDDDDD'
                            }


                        }}
                        alignment="middle"
                        data={dataSet}
                        domain={{y: [0, max]}}
                        categories={{
                            x: labels
                        }}
                    />
                    <VictoryAxis dependentAxis
                                 style={{tickLabels: {fill: '#999999'}}}
                    />
                    <VictoryAxis
                        tickValues={[1, 2, 3, 4, 5]}
                        style={{tickLabels: {fontSize: 13, fill: '#999999', angle: -10}}}
                    />
                </VictoryChart>}
        </View>
    )
}