import {useController} from "react-hook-form";
import React, {useContext, useState} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {Rating} from "react-native-ratings/src";
import sparkle from "../../assets/icons/stars/sparkle_b.png";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {studentClasses} from "@data/constants/formConstants";
import {IconButton, TouchableRipple} from "react-native-paper";
import {BadgeM} from "@common/components/badge/BadgeM";
import {SelectModalM} from "@common/components/select/selectSingle-Multiple";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {scoringService} from "@data/services/scoringService";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

function ScoringRuleButton({onPress, scoringRule, isSelected, criterion}) {
    return <TouchableRipple style={{
        // width: "90%",
        flexWrap: "wrap",
        alignItems: "center",
        alignSelf: "stretch",
        justifyContent: "center",
        paddingHorizontal: 14,
        marginHorizontal: 5,
        marginBottom: 10,
        paddingVertical: 10,
        borderRadius: 20,
        borderWidth: isSelected ? 2 : 1,
        maxWidth: 150,
        backgroundColor: isSelected ? theme.colors.lightMore : null,
        borderColor: isSelected ? theme.colors.black : (criterion?.color)
    }}
                            onPress={onPress}>
        <>
            <TextM wrap
                   style={{textAlign: "center"}}
                   fontWeight={isSelected ? "SemiBold" : "Regular"}
                   color={isSelected ? theme.colors.black : theme.colors.grey}>{scoringRule?.title}</TextM>
            {isSelected && <BadgeM count={-1}
                                   style={{
                                       backgroundColor: theme.colors.black,
                                       color: theme.colors.white,
                                       position: "absolute",
                                       top: -4,
                                       right: -4
                                   }}/>}
        </>
    </TouchableRipple>;
}


function ScoringCriterion(props) {

    const {criterion, control, trigger, activityType, schoolSubjectCategory, studentClass} = props

    const navigation = useNavigation()

    const {field} = useController({
        control,
        name: "criterion_" + criterion?.id
    })

    const {field: scoringRuleIdsField} = useController({
        control,
        name: "scoring_rule_ids"
    })

    const {field: scoringCommentIdsField} = useController({
        control,
        name: "scoring_comment_ids"
    })

    const dimensions = useContext(DimensionsContext)

    const studentClassIndex = studentClasses?.findIndex(y => y === studentClass)

    const scoringRules = props.scoringRules
        ?.filter(x => x?.scoring_criterion_id === criterion?.id
            && x?.activity_types?.includes(activityType)
            && x?.school_subject_categories?.includes(schoolSubjectCategory)
            && (studentClassIndex >= studentClasses?.findIndex(y => y === x?.minimum_student_class))
        )


    const scoringRuleIds = scoringRules?.map(y => y?.id)

    const selectedScoringRuleIds = scoringRuleIdsField?.value?.filter(x => scoringRuleIds?.includes(x)) ?? []

    // const criterionScore = Math.max(0, 5 - selectedScoringRuleIds?.length)

    const [modalSelectedRule, setModalSelectedRule] = useState(null)
    const scoringCommentsOptions = props.scoringComments
        ?.filter(x => x?.scoring_rule_id === modalSelectedRule?.id)
        ?.map(x => {
            return {
                label: x?.comment,
                value: x?.id
                // height: 100
            }
        })
    const modalSelectedComment = scoringCommentsOptions?.find(x => x?.value === scoringCommentIdsField?.value?.[modalSelectedRule?.id])
    // console.log("modalSelectedComment", modalSelectedComment)
    const [modalVisible, setModalVisible] = useState(null)

    const selectNewComment = (value, ruleId) => {
        const newValue = {
            ...scoringCommentIdsField?.value,
            [ruleId]: value
        }
        scoringCommentIdsField?.onChange(newValue)
        // console.log("newValue",newValue)
        if (!scoringRuleIdsField?.value?.includes(ruleId)) {
            const newScoringRuleIdsFieldValue = scoringRuleIdsField?.value?.slice()
            newScoringRuleIdsFieldValue?.push(ruleId)
            scoringRuleIdsField?.onChange(newScoringRuleIdsFieldValue)

            field.onChange(Math.max(0, field.value - 1))
            trigger()
        }


    }

    useApiStatus(
        scoringService.postScoringComment, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log("data", data)
            if (data?.type === "rule" && scoringRuleIds?.includes(data?.scoring_rule_id)) {
                // console.log("SUCCESS rule")
                // console.log("data", data)
                selectNewComment(data?.id, data?.scoring_rule_id)
                props.apiIdle(scoringService.postScoringComment)
            }

        }, // success callback
        () => {
        },
        true
    )

    useApiStatus(
        scoringService.postScoringRule, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (data?.scoring_rule?.scoring_criterion_id === criterion?.id) {
                selectNewComment(data?.scoring_comment?.id, data?.scoring_rule?.id)
                props.apiIdle(scoringService.postScoringRule)
            }
        }, // success callback
        () => {
        },
        true
    )


    const onSingleSelect = (item) => {
        // console.log("item", item)

        //deselect
        if (modalSelectedComment?.value === item?.value) {

            // update comments
            const newValue = {
                ...scoringCommentIdsField?.value,
                [modalSelectedRule?.id]: null
            }
            scoringCommentIdsField?.onChange(newValue)

            // update rules
            // console.log("newValue",newValue)
            if (scoringRuleIdsField?.value?.includes(modalSelectedRule?.id)) {
                const newScoringRuleIdsFieldValue = scoringRuleIdsField?.value?.slice()
                const index = newScoringRuleIdsFieldValue?.indexOf(modalSelectedRule?.id)
                newScoringRuleIdsFieldValue?.splice(index, 1)
                scoringRuleIdsField?.onChange(newScoringRuleIdsFieldValue)

                field.onChange(Math.min(5, field.value + 1))
                trigger()
            }

        } else {
            selectNewComment(item?.value, modalSelectedRule?.id)
        }

    }

    return <View style={{
        // width: dimensions?.width,
        marginTop: 30
        // paddingHorizontal: 15,

        // backgroundColor: "red"
    }}>
        <View style={{
            width: dimensions?.width,
            // marginTop: 10,
            paddingHorizontal: 15,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between"
            // backgroundColor: "red"
        }}>

            <TextM fontSize={18}
                   wrap
                   color={criterion?.color}
                   fontWeight="Bold"
                   style={{textAlign: "left", marginTop: 0}}>
                {criterion?.title}
            </TextM>
            {/*<TextM fontSize={14}*/}
            {/*       wrap*/}
            {/*       fontWeight="Regular"*/}
            {/*       style={{textAlign: "left", marginTop: 0}}>*/}
            {/*    {criterion?.question}*/}
            {/*</TextM>*/}
            <View style={{
                flexDirection: "row",
                alignItems: "flex-end"
                // backgroundColor: "blue"
            }}>
                <Rating
                    ratingCount={criterion?.scale}
                    type={"custom"}
                    ratingImage={sparkle}
                    ratingColor={criterion?.color ?? theme.colors.accent}
                    ratingBackgroundColor={theme.colors.lighter}
                    startingValue={field.value}
                    style={{
                        // marginTop: 10,
                        // marginBottom: 20
                    }}
                    imageSize={30}
                    // readonly
                    onFinishRating={(value) => {

                        field.onChange(value)
                        trigger()
                    }}
                />
                <TextM fontSize={18}
                       style={{
                           // position: "absolute",
                           marginLeft: 10,
                           paddingBottom: 5
                       }}
                       color={criterion?.color}>{field.value}</TextM>
            </View>
        </View>
        {field?.value > (5-selectedScoringRuleIds?.length) && <View style={{
            width: dimensions?.width,
            marginTop: 4,
            paddingHorizontal: 15,
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "row",
            // backgroundColor: "red"
        }}>
            <FontAwesome5 name={"exclamation-triangle"}
                          solid
                          style={{marginRight: 4}}
                          color={theme.colors.error}
                          size={14}/>
            <TextM fontWeight={"SemiBold"}
                   color={theme.colors.error}>
            {"Le score devrait être <= "+(5-selectedScoringRuleIds?.length)}
        </TextM>
        </View>}
        <View style={{
            flexDirection: "row",
            flexWrap: "wrap",
            marginVertical: 10,
            paddingHorizontal: 10,
            alignItems: "stretch"
            // justifyContent: "center",
        }}>
            {scoringRules?.map((x, index) => <ScoringRuleButton scoringRule={x}
                                                                criterion={criterion}
                                                                key={index}
                                                                onPress={() => {
                                                                    setModalSelectedRule(x)
                                                                    setModalVisible(true)
                                                                }}
                                                                isSelected={selectedScoringRuleIds?.includes(x?.id)}
            />)}
            <IconButton
                size={24}
                icon={"plus"}
                iconColor={"white"}
                style={{
                    // borderWidth: 1,
                    borderRadius: 15,
                    marginLeft: 30,
                    borderColor: criterion?.color,
                    backgroundColor: criterion?.color
                    // marginBottom: 1,
                    // alignSelf: "flex-start"
                }}
                onPress={() => {
                    navigation.push("new-scoring-rule", {
                        description: criterion?.title,
                        scoringCriterionId: criterion?.id,
                        currentActivityType: activityType,
                        currentSchoolSubjectCategory: schoolSubjectCategory,
                    })

                }}
            />
        </View>

        <SelectModalM isMulti={false}
                      modalVisible={modalVisible}
                      setModalVisible={setModalVisible}
                      title={modalSelectedRule?.title}
            // description={modalSelectedRule?.title}
                      selectedValue={modalSelectedComment?.value}
                      options={scoringCommentsOptions}
                      valueMode={true}
                      onSingleSelect={onSingleSelect}
                      titleRightButtonIcon={"plus"}
                      itemHeight={(IS_WEB && dimensions.width > 800) ? 70 : 140}
                      onTitleRightButtonPress={() => {
                          navigation.push("new-scoring-comment", {
                              type: "rule",
                              description: modalSelectedRule?.title,
                              scoringRuleId: modalSelectedRule?.id,
                              currentActivityType: activityType,
                              currentSchoolSubjectCategory: schoolSubjectCategory,
                          })
                      }}
                      withClipBoard
        />

        {/*<Divider/>*/}
    </View>
}

const mapStateToProps = state => {
    return {

        scoringCriteria: state.data.staticData?.scoring_criteria,
        scoringRules: state.data.reviewer?.object?.scoringRules,
        scoringComments: state.data.reviewer?.object?.scoringComments,
        reviewer: state.data.reviewer?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoringCriterion)