import axios from "axios";

function getActivities({}, {}, params) {
  return axios.get(`api/activities`, { params });
}

function getFinishedActivities({}, {}, params) {
  return axios.get(`api/activities/finished`, { params });
}

function getStudentFinishedActivities({ id }, {}, params) {
  return axios.get(`api/students/${id}/activities/finished`, { params });
}

function initActivity({}, formData, urlParams, onUploadProgress) {
  return axios.post(`api/activities`, formData, {
    // headers: {
    //     "Content-Type": "multipart/form-data",
    // },
    onUploadProgress,
  });
}

function postActivityLogs({ id }, formData, urlParams, onUploadProgress) {
  return axios.post(`api/activities/${id}/logs`, formData, {
    // headers: {
    //     "Content-Type": "multipart/form-data",
    // },
    onUploadProgress,
  });
}

function postActivityLog({ id }, formData, urlParams, onUploadProgress) {
  // console.log(id)
  // console.log(formData)
  return axios.post(`api/activities/${id}/log`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

function postActivityLogV1({ id }, formData, urlParams, onUploadProgress) {
  // console.log(id)
  // console.log(formData)
  return axios.post(`api/v1/activities/${id}/log`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

function deleteActivityLog({ id }, formData) {
  // console.log(id)
  // console.log(formData)
  return axios.delete(`api/activity-logs/${id}`, { data: formData });
}

function finalizeActivity({ id }, formData, urlParams, onUploadProgress) {
  return axios.post(
    `api/activities/${id}/finalize`,
    {},
    {
      // headers: {
      //     "Content-Type": "multipart/form-data",
      // },
      onUploadProgress,
    }
  );
}

function readReview({ id }) {
  return axios.post(`api/activities/${id}/read-review`);
}

function getActivitiesForReview({}, {}, params) {
  return axios.get(`api/activities/for-review`, { params });
}

function reviewActivity({ id }, formData) {
  return axios.post(`api/v1/activities/${id}/review`, formData);
}

function reviewLock({ id }) {
  return axios.post(`api/v1/activities/${id}/review-lock`);
}

function postMaterial({ activity_id }, formData) {
  return axios.post(`api/activities/${activity_id}/material`, formData);
}

function putMaterial({ id }, formData) {
  return axios.put(`api/materials/${id}`, formData);
}

function deleteMaterial({ id }) {
  return axios.delete(`api/materials/${id}`);
}

function materialsSearch({}, {}, params) {
  return axios.get(`api/materials`, { params });
}

function lockActivity({ id }, formData) {
  return axios.post(`api/v1/activities/${id}/lock`, formData);
}

function getActivity({ id }) {
  return axios.get(`api/v1/activities/${id}`);
}

function activityTodoAsStudent({}, formData, {}, onUploadProgress) {
  return axios.post(`api/v2/activities/todo`, formData, {
    onUploadProgress,
  });
}

function activityTodoAsTutor({ id }, formData, {}, onUploadProgress) {
  return axios.post(`api/v2/students/${id}/activities/todo`, formData, {
    onUploadProgress,
  });
}

function activityTodoForStudents({}, formData, {}, onUploadProgress) {
  return axios.post(`api/v1/students/activities/todo`, formData, {
    onUploadProgress,
  });
}

function updateActivityTodo({ id }, formData, {}, onUploadProgress) {
  return axios.put(`api/v1/activities/${id}/todo`, formData, {
    onUploadProgress,
  });
}

function activityTodoIndexesAsStudent({}, formData) {
  return axios.put(`api/v1/activities/todo/indexes`, formData);
}

function activityTodoIndexesAsTutor({ id }, formData) {
  return axios.put(`api/v1/students/${id}/activities/todo/indexes`, formData);
}

function deleteActivityTodo({ id }) {
  return axios.delete(`api/v1/activities/${id}/todo`);
}

function deleteActivitiesTodoAsStudent() {
  return axios.delete(`api/v1/activities/todo`);
}

function deleteActivitiesTodoAsTutor({ id }) {
  return axios.delete(`api/v1/students/${id}/activities/todo`);
}

function deleteCommonActivityTodo({}, {}, params) {
  return axios.delete(`api/v1/students/activities/todo`, { params });
}

function lockActivityV2({ id }, formData) {
  return axios.post(`api/v2/activities/${id}/lock`, formData);
}

function updateChrono({ id }, formData) {
  return axios.post(`api/activities/${id}/update-chrono`, formData);
}

export const activitiesService = {
  getActivities,
  getFinishedActivities,
  readReview,
  getStudentFinishedActivities,
  initActivity,
  postActivityLogs,
  postActivityLog,
  postActivityLogV1,
  finalizeActivity,
  getActivitiesForReview,
  reviewActivity,
  reviewLock,
  activityTodoAsStudent,
  activityTodoAsTutor,
  activityTodoForStudents,
  updateActivityTodo,
  activityTodoIndexesAsStudent,
  activityTodoIndexesAsTutor,
  deleteActivityTodo,
  deleteCommonActivityTodo,
  postMaterial,
  putMaterial,
  deleteMaterial,
  lockActivity,
  getActivity,
  deleteActivitiesTodoAsStudent,
  deleteActivitiesTodoAsTutor,
  lockActivityV2,
  updateChrono,
  deleteActivityLog,
  materialsSearch,
};
