import {theme} from "@common/theme/theme";

export const studentsConstants = {
    CHANGE_LIST_SURVEY: 'CHANGE_LIST_SURVEY'
}

export const avatarColors = [
    theme.colors.lightMore,
    theme.colors.purpleLight,
    theme.colors.primaryLighter,
    theme.colors.accentLighter,
    theme.colors.warningLight,
    theme.colors.linkLighter,
    theme.colors.successLight,
    theme.colors.gemsLight,
]

