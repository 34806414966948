import React, {useContext, useEffect, useRef, useState} from 'react';
import {Platform, TouchableOpacity, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "../../data/redux/actions/api";
import {connect} from "react-redux";
import {backOfficeService} from "../../data/services/backOfficeService";
import {useNavigation} from "@react-navigation/native";
import {RankingListItem} from "../../student/social/RankingScreen";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import TitleM from "@common/components/text/TitleM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import {IconButton} from "react-native-paper";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import ErrorTextM from "@common/components/text/ErrorTextM";
import ServerSearch from "../ServerSearch";

const AddUserToLive = (props) => {

    const dimensions = useContext(DimensionsContext)
    const flatListRef = useRef();
    const [selectedUsers, setSelectedUsers] = useState([])
    const navigation = useNavigation()
    /*    let members = props.users
        members = members.filter((el) => !props.liveSession?.users.map(u => u.id).includes(el.id))*/

    const [urlParams, setUrlParams] = useState({
        roles: ["student", "tutor"].join(','),
        search: "",
    })

    useEffect(() => {
        // props.apiRequest(backOfficeService.searchUser)
    }, []);

    useApiStatus(
        backOfficeService.addUsersLive, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("admin-lives")

        }, // success callback,
        () => {
        }
    )
    return (
        <View style={{
            alignSelf: 'center',
            width: "100%",
            alignItems: 'center',
            justifyContent: 'center',
            // marginTop: 20,
            flex: 1
        }}>
            <HeaderContainerM>
                <TitleM>Ajouter un participant</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("admin-lives")
                    }}
                />
            </HeaderContainerM>

            <View style={{
                width: dimensions?.width,
                alignSelf: 'center',
                // height: IS_WEB ? dimensions?.height / 1.1 : null,
                flex: 1,
                alignItems: 'center',
                paddingTop: 20
            }}>
                <ServerSearch style={{
                    width: dimensions?.width / 2,
                    margin: 10
                }}
                              urlParams={urlParams}
                              setUrlParams={setUrlParams}
                              request={props.searchingRequest}
                />

                <ErrorTextM errorData={props.addUsersError} style={{textAlign: "center", marginBottom: 20}}/>

                <PaginatedFlatList
                    ref={flatListRef}
                    data={props.users}
                    // contentContainerStyle={{paddingBottom: 100}}
                    additionalOnRefresh={() => {
                    }}
                    additionalRefreshing={false}
                    requestApiService={backOfficeService.searchUser}
                    requestRouteParams={null}
                    requestUrlParams={urlParams}
                    requestData={null}
                    renderItem={(item) => {
                        return (
                            <TouchableOpacity
                                disabled={!!props.searchingRequest}
                                onPress={() => {
                                    if (selectedUsers.includes(item.item?.id))
                                        setSelectedUsers(selectedUsers.filter(u => u !== item.item?.id))
                                    else
                                        setSelectedUsers([...selectedUsers, item.item?.id])
                                }}>
                                <RankingListItem user={item.item}
                                                 isHighlighted={selectedUsers.includes(item.item?.id)}
                                                 withEmail
                                                 height={60}
                                />
                            </TouchableOpacity>
                        );
                    }}
                />
            </View>
            <View style={{
                backgroundColor: theme.colors.whiteTransparentMore,
                paddingTop: 10,
                paddingBottom: Platform.OS === 'ios' ? 20 : 5,
                position: "absolute",
                bottom: 0,
                width: "100%",
                alignItems: 'center'
            }}>
                <FilledButtonM
                    onPress={() => {
                        //console.log(selectedUsers)
                        props.apiRequest(backOfficeService.addUsersLive, {id: props.liveSession.id}, {user_ids: selectedUsers})
                    }}
                    label={"AJOUTER"}
                    color={theme.colors.success}
                    loading={props.addUsersRequest}
                />
            </View>
        </View>

    )
};

const mapStateToProps = state => {
    return {
        liveSession: state.data.backOffice?.liveSession,
        users: state.data.users?.usersList,
        getUsersRequest: state.api.getAllUsers?.request,
        searchingRequest: state.api.searchUser?.request,
        addUsersRequest: state.api.addUsersLive?.request,
        addUsersError: state.api.addUsersLive?.error,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AddUserToLive)