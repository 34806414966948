import React, {useContext, useEffect, useRef, useState} from "react";
import TextM from "../components/text/TextM";
import {Dimensions, ImageBackground, Platform, TouchableHighlight, TouchableOpacity, View} from "react-native";
import FilledButtonM from "../components/button/FilledButtonM";
import {theme} from "../theme/theme";
import {Camera, CameraType} from "expo-camera";
import TextButtonM from "../components/button/TextButtonM";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {IconButton} from "react-native-paper";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import {StatusBar} from "expo-status-bar";
import {connect} from "react-redux";
import {recordCameraSettings, resetCameraSettings, setLastPhoto, setPhotos} from "@data/redux/actions/camera";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import * as Device from "expo-device";

export const CameraPreview = ({photo, onConfirm, onRetry, imagePadding}) => {
    const dimensions = useContext(DimensionsContext)

    return (<View
        style={{
            backgroundColor: 'transparent', // alignItems: "center",
            // justifyContent: "center",
            flex: 1, width: '100%'
            // height: '100%'
        }}
    >
        <ImageBackground
            source={{uri: photo?.uri ? photo?.uri : photo}}
            resizeMode={'contain'}
            imageStyle={{
                marginTop: imagePadding / 2
            }}
            style={{
                // flex: 1,
                // top: 0
                height: photo?.height ? photo?.height * dimensions?.width / photo?.width : "100%"
            }}
        />
        <View style={{
            flexDirection: "row", position: "absolute", bottom: 0, alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            <TouchableHighlight onPress={onRetry}
                                underlayColor={theme.colors.light}
                                style={{
                                    width: dimensions?.width / 2,
                                    alignItems: "center",
                                    paddingVertical: 25,
                                    backgroundColor: theme.colors.blackTransparent
                                }}>
                <TextM color={theme.colors.white}
                       fontWeight="ExtraBold"
                       fontSize={16}>Réessayer</TextM>
            </TouchableHighlight>
            <TouchableHighlight onPress={onConfirm}
                                underlayColor={theme.colors.light}
                                style={{
                                    width: dimensions?.width / 2,
                                    alignItems: "center",
                                    paddingVertical: 25,
                                    backgroundColor: theme.colors.blackTransparent
                                }}>
                <TextM color={theme.colors.white} fontWeight="ExtraBold" fontSize={16}>OK</TextM>
            </TouchableHighlight>
        </View>

    </View>)
}

// ** custom component, not useful for the moment since we use ImagePicker
const CameraScreen = props => {


    // ** Component props
    const {navigation, route} = props
    const {formId = null} = route.params

    const cameraRef = useRef()

    const [hasPermission, setHasPermission] = useState(null);
    const [type, setType] = useState(CameraType.back);

    // console.log("retrieved camera settings : ratio ", props.ratioSaved ?? "null", ", padding ", props.imagePaddingSaved ? parseInt(props.imagePaddingSaved) : "null")

    // Screen Ratio and image padding
    const [imagePadding, setImagePadding] = useState(props.imagePaddingSaved ? parseInt(props.imagePaddingSaved) : 0);
    const [ratio, setRatio] = useState(props.ratioSaved ?? "4:3");
    const {height, width} = Dimensions.get('window');
    const screenRatio = height / width;
    const [isRatioSet, setIsRatioSet] = useState(!!props.imagePaddingSaved && !!props.ratioSaved);
    const [previewVisible, setPreviewVisible] = useState(false)
    const [capturedImage, setCapturedImage] = useState(null)

    const requestCameraAccess = async () => {
        try {
            // (async () => {
            const {status} = await Camera.requestCameraPermissionsAsync();
            setHasPermission(status === 'granted');
            // })();
        } catch (e) {
            //Bugsnag.notify(e)
        }
    }

    useEffect(() => {
        requestCameraAccess().then()
    }, []);

    // set the camera ratio and padding.
    // this code assumes a portrait mode screen
    const prepareRatio = async () => {

        let desiredRatio = '4:3';  // Start with the system default
        // This issue only affects Android
        if (Platform.OS === 'android') {
            cameraRef.current?.pausePreview()

            const parts = desiredRatio.split(':');
            const realRatio = parseInt(parts[0]) / parseInt(parts[1]);

            //  calculate the difference between the camera width and the screen height
            const remainder = Math.floor((height - realRatio * width));

            props.recordCameraSettings(remainder, desiredRatio)

            // set the preview padding and preview ratio
            setImagePadding(remainder);
            setRatio(desiredRatio);
            // Set a flag so we don't do this
            // calculation each time the screen refreshes
            setIsRatioSet(true);
            cameraRef.current?.resumePreview()

        }
    };


    // the camera must be loaded in order to access the supported ratios
    const setCameraReady = async () => {
        try {

            if (!isRatioSet || ratio !== "4:3") {
                console.log("preparing new ratio calculation")
                await prepareRatio();
            } else {
                console.log("recovered settings : ratio ", ratio, ", imagePadding ", imagePadding)
            }
        } catch (e) {
            //Bugsnag.notify(e)
        }
    };

    const __takePicture = async () => {
        // console.log("takepictureasync")
        if (!cameraRef) return
        // console.log("takepictureasync2")
        await cameraRef.current?.takePictureAsync({
            onPictureSaved: onPictureSaved, quality: Platform.OS === "ios" ? 0.05 : 0.5
        })
    }

    const onPictureSaved = photo => {
        // console.log("onpicturesaved")
        setPreviewVisible(true)
        setCapturedImage(photo)
    }

    const onRetry = () => {
        // console.log(photo)
        setPreviewVisible(false)
        setCapturedImage(null)
    }

    const onConfirm = () => {
        // console.log(photo)
        props.setNewPhoto(formId, capturedImage)

        navigation.goBack()
        // navigation.navigate("Activité", {
        //     newImage: capturedImage
        // })

    }
    const dimensions = useContext(DimensionsContext)

    const insets = useSafeAreaInsets()

    if (hasPermission === null) {
        return <View style={{paddingTop: 50, alignItems: "center", flex: 1, backgroundColor: "black"}}>
            <TextM color={theme.colors.white}>Chargement...</TextM>
        </View>;
    }
    if (hasPermission === false) {
        return <View style={{alignItems: "center", justifyContent: "center", flex: 1}}>
            <TextM fontSize={16}>Pas d'accès à la caméra</TextM>
            <FilledButtonM color={theme.colors.primary}
                           onPress={() => {
                               requestCameraAccess().then()
                           }}
                           style={{
                               width: Platform.OS !== 'web' ? 200 : dimensions?.width / 2.5, marginTop: 20
                           }}
                           label="Autoriser"/>
            <TextButtonM
                // mode="text"
                style={{
                    // marginTop: 5,
                    alignSelf: "center"
                }}
                onPress={() => {
                    navigation.goBack()
                }}
                fontWeight="ExtraBold"
                label="RETOUR"/>
        </View>;
    }


    return <View style={{
        flex: 1, backgroundColor: theme.colors.black, paddingTop: insets.top, alignItems: "center"
    }}>
        {(IS_WEB || !Device.isDevice) ? previewVisible && capturedImage ? (<CameraPreview photo={capturedImage}
                                                                                          imagePadding={imagePadding}
                                                                                          onConfirm={onConfirm}
                                                                                          onRetry={onRetry}/>) :
            <Camera style={{
                flex: 1, alignSelf: "center", // width: height * width / (height - imagePadding)
                width: width, marginTop: imagePadding / 2, marginBottom: imagePadding / 2
            }}
                    useCamera2Api={false}
                    type={type}
                    ref={cameraRef}
                    onMountError={(e) => {
                        console.error(e)
                        //Bugsnag.notify(e.message)
                    }}
                    onCameraReady={setCameraReady}
                    ratio={ratio}
                // ratio={ratio}
            >
            </Camera> : null}

        {(!previewVisible || !capturedImage) && <View style={{
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center", // paddingVertical: 20,
            position: "absolute",
            bottom: 0,
            backgroundColor: theme.colors.blackTransparent,
            height: (imagePadding / 2) < 100 ? 100 : (imagePadding / 2), // opacity: 0.2,
            width: dimensions?.width
        }}>

            <TouchableOpacity
                style={{
                    // alignSelf: 'flex-end',
                    justifySelf: 'center',
                    alignItems: 'center',
                    alignSelf: 'center',
                    position: "absolute",
                    top: 10,
                    backgroundColor: 'transparent'
                }}
                onPress={__takePicture}>
                <MaterialCommunityIcons
                    name="circle-slice-8"
                    style={{color: theme.colors.white, fontSize: 80}}
                />
            </TouchableOpacity>

            {!IS_WEB && Device.isDevice && <TouchableOpacity
                style={{
                    // alignSelf: 'flex-end',
                    // marginRight: 20,
                    alignItems: 'center', position: "absolute", top: 30, right: 30, backgroundColor: 'transparent'
                }}
                onPress={() => {
                    setType(type === CameraType.back ? CameraType.front : CameraType.back);
                }}>
                <MaterialCommunityIcons
                    name="camera-switch"
                    style={{color: theme.colors.white, fontSize: 40}}
                />
            </TouchableOpacity>}


        </View>}

        <View style={{
            flexDirection: "row",
            alignSelf: "center", // alignItems: "center",
            // alignContent: "center",
            // justifyContent: "center",
            position: "absolute",
            top: 0,
            paddingTop: insets.top,
            backgroundColor: theme.colors.blackTransparent,
            height: (imagePadding / 2) < 80 ? 80 : (imagePadding / 2), // opacity: 0.2,
            width: dimensions?.width
        }}>
            <IconButton
                icon="times"
                iconColor={theme.colors.white}
                size={32}
                style={{marginLeft: 10}}
                onPress={() => {
                    navigation.goBack()
                }}
            />
        </View>
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}

const mapStateToProps = state => {
    return {
        ratioSaved: state.camera?.ratio, imagePaddingSaved: state.camera?.imagePadding
    }
}

const mapDispatchToProps = {
    setNewPhoto: setLastPhoto, recordCameraSettings, resetCameraSettings, setPhotos
}

export default connect(mapStateToProps, mapDispatchToProps)(CameraScreen)