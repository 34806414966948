import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FlatList, Platform, View} from "react-native";
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigation} from "@react-navigation/native";
import {theme} from "@common/theme/theme";
import SkillSetHeader from "./SkillSetHeader";
import SkillItem from "./SkillItem";
import {PetalCounter, SkillSetTitleFull} from "@common/components/statistics/Counters";
import TextM from "@common/components/text/TextM";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {StatusBar} from "expo-status-bar";
import {IconButton} from "react-native-paper";
import {ChampionImageM} from "../../champions/ChampionImageM";
import FilledButtonM from "@common/components/button/FilledButtonM";

const MyProgressScreen = (props) => {

    const navigation = useNavigation()
    const skillSet = props.user?.skill_sets?.find(x => x?.id === parseInt(props.route.params?.skillSetId))

    const skillSetSteps = skillSet?.steps?.slice(1)

    const insets = useSafeAreaInsets();

    const flatListRef = useRef()

    const itemHeight = 500

    const [currentIndex, setCurrentIndex] = useState(skillSet?.current_step - 1)

    useEffect(() => {

        // console.log("skillSet", skillSet)
        // console.log("skillSetSteps", skillSetSteps)
        // console.log("currentIndex", currentIndex)

    }, []);


    const onViewableItemsChanged = ({viewableItems, changed}) => {
        // console.log("Visible items are", viewableItems);
        // console.log("Changed in this iteration", changed);

        setCurrentIndex(viewableItems?.[0]?.index)
    }

    const viewabilityConfig = {waitForInteraction: false, viewAreaCoveragePercentThreshold: 95}
    const viewabilityConfigCallbackPairs = useRef([{viewabilityConfig, onViewableItemsChanged}])
    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            // backgroundColor: '#fff',
            // paddingTop: Platform.OS === "ios" ? insets.top : 0,
            flex: 1,
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            {/*<View style={{*/}
            {/*    backgroundColor: '#fff',*/}
            {/*    // paddingTop: 10,*/}
            {/*    paddingLeft: 10,*/}
            {/*    marginBottom: 5*/}
            {/*}}>*/}
            {/*    <IconButton*/}
            {/*        icon={"arrow-left"}*/}
            {/*        iconColor={theme.colors.primary}*/}
            {/*        size={20}*/}
            {/*        onPress={() => {*/}
            {/*            navigation.goBack()*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</View>*/}


            <SkillSetHeader name={skillSet?.name}
                            color={skillSet?.color}
                            title={skillSet?.steps?.[skillSet?.current_step - 1]?.title_gained}/>
            <View style={{
                width: dimensions?.width,
                flex: 1
            }}>
                <View style={{
                    width: dimensions?.width,
                    // flexDirection: "row",
                    alignItems: "center",
                    position: "absolute",
                    bottom: 0
                }}>
                    <ChampionImageM name={skillSet?.champion} variant={"main"} width={100}/>
                </View>
                <FlatList
                    data={skillSetSteps}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    pagingEnabled
                    style={{
                        width: dimensions?.width,
                        flex: 1
                    }}
                    initialScrollIndex={currentIndex}
                    ref={flatListRef}
                    getItemLayout={(data, index) => (
                        {length: dimensions?.width, offset: dimensions?.width * index, index}
                    )}
                    viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
                    renderItem={(item) => {

                        const stepNum = item?.index + 1
                        const step = item?.item
                        const titleGained = step?.title_gained
                        const petalsGained = step?.petals_gained
                        const hasTitleGained = titleGained !== skillSet?.steps?.[stepNum - 1]?.title_gained

                        const laterStep = stepNum > skillSet?.current_step
                        const soonAvailable = false
                        const disabled = soonAvailable || laterStep

                        return <View style={{
                            height: itemHeight,
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            width: dimensions?.width,
                            paddingTop: 40,
                            opacity: !disabled ? 1 : 0.5,
                            // flex: 1

                            // borderTopWidth: 1,
                            // borderBottomWidth: 1
                            // backgroundColor: theme.colors.primaryTransparent
                        }}>


                            <View style={{
                                alignItems: "flex-start",
                                justifyContent: "center",
                                paddingRight: 20
                                // height: "100%",
                            }}>

                                <SkillItem stepNum={stepNum}
                                           skillSet={skillSet}
                                           active={false}
                                           withAnimation={false}
                                           width={110}
                                />
                                {(disabled) && <TextM fontWeight="Regular"
                                                      fontSize={12}
                                                      color={theme.colors.black}
                                                      style={{
                                                          paddingHorizontal: 8,
                                                          paddingVertical: 4,
                                                          borderWidth: 0,
                                                          borderRadius: 6,
                                                          borderColor: theme.colors.black,
                                                          backgroundColor: theme.colors.lighter,
                                                          position: "absolute",
                                                          top: -20,
                                                          left: 15
                                                      }}>{soonAvailable ? "Bientôt disponible" : "Pas encore débloquée"}</TextM>}
                            </View>
                            <View style={{marginTop: 26}}>
                                <View style={{
                                    height: 20,
                                    width: dimensions?.width,
                                    backgroundColor: theme.colors.lighter
                                }}/>
                                <View style={{
                                    height: 20,
                                    width: stepNum === skillSet?.current_step ? 50 + 20 : stepNum < skillSet?.current_step ? dimensions?.width : 0,
                                    backgroundColor: skillSet?.color,
                                    position: "absolute",
                                    left: 0
                                }}/>
                                <View style={{
                                    height: 40,
                                    width: 40,
                                    borderRadius: 20,
                                    backgroundColor: theme.colors.white,
                                    borderWidth: 10,
                                    borderColor: skillSet?.color,
                                    position: "absolute",
                                    left: 50,
                                    top: -10
                                }}/>
                                <View style={{
                                    backgroundColor: skillSet?.color,
                                    width: 4,
                                    height: 40,
                                    position: "absolute",
                                    left: 50 + 20 - 2,
                                    top: -40
                                }}/>
                            </View>
                            {stepNum >= skillSet?.current_step && <View style={{
                                paddingLeft: 100,
                                alignItems: "flex-start",
                                justifyContent: "center",
                                marginTop: 20,
                                marginBottom: 20
                                // height: "100%",
                            }}>
                                {petalsGained > 0 ?
                                    <PetalCounter count={petalsGained + " pétales"}/>
                                    : ""}
                                {hasTitleGained ? <SkillSetTitleFull title={titleGained}
                                                                     large
                                                                     style={{
                                                                         marginTop: 10,
                                                                         marginLeft: -6
                                                                     }}
                                                                     icon={skillSet?.icon_path + skillSet?.steps?.[stepNum]?.title_gained_icon}
                                                                     skillSetId={skillSet?.id}/>

                                    : ""}
                            </View>}

                        </View>

                    }}
                />

            </View>
            <View style={{
                backgroundColor: skillSet?.color + "22",
                height: 80,
                width: dimensions?.width,
                flexDirection: "row",
                justifyContent: "space-between",
                paddingTop: 5
            }}>
                <IconButton
                    size={30}
                    icon={"angle-double-left"}
                    iconColor={skillSet?.color}
                    onPress={() => {
                        flatListRef?.current?.scrollToIndex({index: 0})
                    }}
                />
                <IconButton
                    size={30}
                    icon={"angle-left"}
                    iconColor={skillSet?.color}
                    onPress={() => {
                        if (currentIndex > 0) flatListRef?.current?.scrollToIndex({index: currentIndex - 1})
                    }}
                />
                {(skillSet?.current_step !== 1 && skillSet?.current_step !== skillSetSteps?.length) ? <IconButton
                    size={30}
                    icon={"circle-notch"}
                    iconColor={skillSet?.color}
                    onPress={() => {
                        flatListRef?.current?.scrollToIndex({index: skillSet?.current_step - 1})
                    }}
                /> : <View style={{width: 30, height: 30}}/>}
                <IconButton
                    size={30}
                    icon={"angle-right"}
                    iconColor={skillSet?.color}
                    onPress={() => {
                        if (currentIndex < skillSetSteps?.length - 1) flatListRef?.current?.scrollToIndex({index: currentIndex + 1})
                    }}
                />
                <IconButton
                    size={30}
                    icon={"angle-double-right"}
                    iconColor={skillSet?.color}
                    onPress={() => {
                        flatListRef?.current?.scrollToIndex({index: skillSetSteps?.length - 1})
                    }}
                />
            </View>

            {IS_WEB && <FilledButtonM mode={"text"}
                                      icon={"chevron-left"}
                                      color={theme.colors.link}
                                      style={{
                                          position: "absolute",
                                          top: 10,
                                          left: 10,
                                          width: null
                                      }}
                                      onPress={() => {
                                          if (navigation.canGoBack())
                                              navigation.goBack()
                                          else
                                              navigation.replace("menu")
                                      }}
                                      label={"Retour"}/>}

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

        </View>
    );
}

const mapStateToProps = state => {
    return {
        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        user: state.data?.currentUser?.object,
        successes: state.data.staticData?.successes
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(MyProgressScreen)