export const bodies = {
    body1: true,
    body2: true,
    body3: true,
    body4: true,
    body5: true,
};
export const bodiesList = [
    {
        name: "body1",
    },
    {
        name: "body2",
    },
    {
        name: "body3",
    },
    {
        name: "body4",
    },
    {
        name: "body5",
    },
];
export const bodyColors = {
    bc1: '#456dff',
    bc2: '#5a45ff',
    bc3: '#6dbb57',
    bc4: '#f55c80',
    bc5: '#7554ca',
    bc6: '#e24552',
    bc7: '#54d8c7',
    bc8: '#f3b63b',
};

export const bodyColorsList = [
    {
        name: "bc1",
        color: "#456dff"
    },
    {
        name: "bc2",
        color: "#5a45ff"
    },
    {
        name: "bc3",
        color: "#6dbb57"
    },
    {
        name: "bc4",
        color: "#f55c80"
    },
    {
        name: "bc5",
        color: "#7554ca"
    }, {
        name: "bc6",
        color: "#e24552"
    },
    {
        name: "bc7",
        color: "#54d8c7"
    },
    {
        name: "bc8",
        color: "#f3b63b"
    },
];