import {View} from "react-native";
import {ProgressBar} from "react-native-paper";
import {theme} from "@common/theme/theme";
import React, {useContext} from "react";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";

export function ChallengeProgressBarNew(props) {
    const dimensions = useContext(DimensionsContext)

    let barWidth = IS_WEB ? dimensions?.width - 2 : dimensions?.width
    const xpTargetTodayWidth = 22
    const xpTargetTodayPosition = (props.challenge?.xp_target_today / props.challenge?.xp_target) * barWidth - xpTargetTodayWidth / 2

    // const progressColor = (props.challenge?.xp_gained >= props.challenge?.xp_target_today) ? theme.colors.success : theme.colors.accent
    const progressColor = props.challenge?.xp_gained === props.challenge?.xp_target ? theme.colors.success : theme.colors.accent

    return <View style={{
        // flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginTop: 6
    }}>
        <View style={{
            // position: "absolute",
            // left: 8,
            flexDirection: 'row',
            alignItems: 'center'
        }}>

            <TextM
                color={theme.colors.greyAlt}
                fontWeight="ExtraBold"
                fontSize={14}
                style={{marginBottom: 1}}
            >
                {props.challenge?.xp_gained}
            </TextM>
            <TextM
                color={theme.colors.grey}
                fontWeight="Regular"
                fontSize={12}
                style={{marginBottom: 0}}
            >
                {" /" + props.challenge?.xp_target}
            </TextM>
        </View>
        <ProgressBar
            progress={(props.challenge?.xp_progress === 0 ? 1 : props.challenge?.xp_progress) / 100}
            color={progressColor}
            style={{
                width: barWidth,
                height: 16,
                // borderRadius: 15,
                backgroundColor: theme.colors.whiteAlmost,
                marginRight: 0
            }}
        />
        {!props.challenge?.planning_mode && !!props.challenge?.abandoned_at && !!props.challenge?.finished_at &&
            <View style={{
                position: "absolute",
                left: xpTargetTodayPosition + 40,
                top: 0,
                alignItems: "center",
                justifyContent: "center",
            }}>
                <View style={{
                    width: 2,
                    height: 12,
                    backgroundColor: theme.colors.white,
                }}/>
                <View style={{
                    width: xpTargetTodayWidth,
                    height: xpTargetTodayWidth,
                    borderWidth: 2,
                    borderColor: theme.colors.white,
                    backgroundColor: progressColor,
                    borderRadius: 20,
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <FontAwesome5 name={"flag"}
                                  solid
                                  color={theme.colors.white}
                                  size={10}/>
                </View>
            </View>}


    </View>;
}