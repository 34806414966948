import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import React, {useEffect, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {ChampionImageM} from "../../champions/ChampionImageM";
import {SkillSetTitle} from "@common/components/icons/IconsM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {authService} from "@data/services/authService";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {ActivityIndicator} from "react-native-paper";
import {connect} from "react-redux";
import Animated, {BounceIn, FadeIn} from "react-native-reanimated";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";

function NewTitleCongrats(props) {

    const {userSkillSet} = props


    const [titleChanged, setTitleChanged] = useState(false)

    useApiStatus(
        authService.updateTitleSkillSet,
        null,
        true,
        () => {
            setTitleChanged(true)
        }
    );
    useEffect(() => {
        PlaySoundM.play(require('../../../../assets/sounds/new-title.mp3'))
    }, [])
    return <View style={{
        backgroundColor: theme.colors.red,
    }}>

        <View style={{
            // backgroundColor: theme.colors.white,
            // paddingVertical: 10,
            paddingHorizontal: 15,
            paddingTop: 30,
            flexGrow: 0,
            width: "100%",
            alignItems: "center"
        }}>


            {/*<TextM fontSize={30}*/}
            {/*       wrap*/}
            {/*       fontWeight="ExtraBold"*/}
            {/*       style={{*/}
            {/*           textAlign: "center"*/}
            {/*           // marginTop: 50*/}
            {/*       }}*/}
            {/*       color={theme.colors.primary}>*/}
            {/*    FÉLICITATIONS !*/}
            {/*</TextM>*/}
            <View style={{height: 20}}/>

            <ChampionImageM name={userSkillSet?.champion} variant={"happy"} width={140}/>

            <TextM fontSize={20}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center",
                       marginTop: 40,
                       marginHorizontal: 10
                   }}
                   color={theme.colors.primary}>
                Félicitations ! Tu as remporté le titre
            </TextM>
            <Animated.View entering={BounceIn.delay(500).duration(1200)}

                           style={{
                               marginTop: 20,
                               marginBottom: 25,
                               marginHorizontal: 30,
                               flexDirection: "row",
                               alignItems: "center"
                           }}
            >

                <SkillSetTitle size={40}
                               icon={userSkillSet?.icon_path + userSkillSet?.steps?.[userSkillSet?.current_step - 1]?.title_gained_icon}/>

                <TextM fontSize={26}
                       wrap
                       fontWeight="ExtraBold"
                       style={{
                           textAlign: "center",
                       }}
                       color={userSkillSet?.color}>
                    {userSkillSet?.steps?.[userSkillSet?.current_step]?.title_gained}
                </TextM>
            </Animated.View>

            <Animated.View entering={FadeIn.delay(2000).duration(500)}
                           style={{
                               // backgroundColor: "red"
                           }}>
                {props.user?.title_skill_set_id !== userSkillSet?.id ? <>
                    {props.updateTitleSkillSetRequest
                        ? <ActivityIndicator animating={true}
                                             color={theme.colors.primary}/>
                        : <TouchableOpacity disabled={titleChanged}
                                            onPress={() => {
                                                props.apiRequest(authService.updateTitleSkillSet, {}, {skill_set_id: userSkillSet?.id})
                                            }}
                                            style={{
                                                padding: 10,
                                                borderWidth: titleChanged ? 0 : 1,
                                                // borderWidth: 1,
                                                borderColor: theme.colors.primary,
                                                borderRadius: 10
                                                // height: 20
                                            }}
                        >
                            <TextM color={theme.colors.primary}
                                   wrap
                                   fontWeight={"Medium"}>Choisir ce titre à la place
                                de {props.user?.student_title}</TextM>
                        </TouchableOpacity>}
                </> : <View style={{flexDirection: "row", alignItems: "center"}}>
                    <TextM>Ton profil a été mis à jour avec ce titre</TextM>
                    <FontAwesome5 name={"check-circle"}
                                  solid
                                  style={{marginLeft: 8}}
                                  color={userSkillSet?.color}
                                  size={20}/>
                </View>}
            </Animated.View>


        </View>

    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
        updateTitleSkillSetRequest: state.api.updateTitleSkillSet?.request,
        updateTitleSkillSetSuccess: state.api.updateTitleSkillSet?.success,
        updateTitleSkillSetError: state.api.updateTitleSkillSet?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewTitleCongrats)