import {FlatList, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import React, {useContext, useEffect, useRef, useState} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {SchoolCard} from "./SchoolCard";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {theme} from "@common/theme/theme";
import StudentsListItem from "../tutor/students/StudentsListItem";
import {authService} from "@data/services/authService";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import TextM from "@common/components/text/TextM";
import {ToggleButtonM} from "@common/components/button/ToggleButtonM";
import {schoolsService} from "@data/services/schoolsService";
import {useApiStatus} from "@common/hooks/useApiStatus";

function SchoolScreen(props) {

    const dimensions = useContext(DimensionsContext)
    const schools = props.user?.schools
    const school = findItemInListWithId(props.route.params.schoolId, schools)
    const flatListRef = useRef();
    const navigation = useNavigation()
    const [period, setPeriod] = useState('this_week')
    const labels = ["Cette semaine", "La semaine dernière"]

    useEffect(() => {
        props.apiRequest(schoolsService.getSchool, {id: school?.id})
    }, [])

    useApiStatus(
        schoolsService.getSchool, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        } // success callback
    )
    const onSelectIndex = () => {
        if (period === "this_week")
            setPeriod("last_week")
        else setPeriod("this_week")
    }
    const scrollToIndex = (index) => {
        flatListRef.current.scrollToIndex({index});
    };
    return (
        <View style={{alignSelf: 'center', alignItems: 'center', justifyContent: 'center'}}>
            {props.getSchoolRequest && IS_WEB ? <ActivityIndicator style={{
                marginTop: 30
            }} color={theme.colors.primary}/> : <>

                <FlatList
                    data={school?.students}
                    ref={flatListRef}
                    refreshing={!!props.getUserRequest}
                    onRefresh={() => props.apiRequest(authService.getUser)}
                    ListHeaderComponent={
                        <>
                            <View style={{
                                paddingVertical: 20
                            }}>
                                <SchoolCard school={school} noClick noCard/>
                            </View>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                {IS_WEB && dimensions.width > 800 &&
                                    <ToggleButtonM labels={labels}
                                                   color={theme.colors.primary}
                                                   onSelectIndex={onSelectIndex}/>}
                            </View>
                        </>
                    }
                    ListEmptyComponent={() => <TextM style={{
                        alignSelf: 'center',
                        paddingVertical: 30
                    }}>
                        Pas d'élèves
                    </TextM>}
                    style={{
                        width: dimensions?.width,
                    }}
                    renderItem={(item) => {
                        const scroll = () => {
                            scrollToIndex(item.index);
                        };

                        return <StudentsListItem student={item.item} index={item.index} period={period}/>;
                    }}
                />
                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else
                                                  navigation.replace("menu")
                                          }}
                                          label={"Retour"}/>}
            </>
            }
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        getSchoolRequest: state.api.getSchool?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolScreen)