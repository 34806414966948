import React, {useContext, useEffect, useState} from "react";
import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {chaptersService} from "@data/services/chaptersService";
import {hasRole} from "@data/utility/ability";
import {ActivityIndicator} from "react-native-paper";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {formatRelativeDateTime} from "@data/utility/Utils";
import {ProgressBarM} from "@common/components/progress/ProgressBarM";
import {ActivityLevelBadge} from "@common/chapters/ActivityLevelBadge";

const ChapterItemComponent = (props) => {

    const {
        chapter,
        onPress = () => {
        },
        animated = false,
        isHighlighted = false,
        liveReportMode = false,
        validatedActivities = 0,
        todoActivities = 0,
        style,
        withParentChapter = false,
        renderChapterOnly = false,
        interviewReportMode = false,
        width = null
    } = props

    const dimensions = useContext(DimensionsContext);
    const schoolSubject = props.schoolSubjects?.find(
        (subject) =>
            subject.id?.toString() === chapter?.school_subject_id?.toString()
    );
    // const differenceInDays = moment().diff(moment(chapter?.last_set_ongoing_at), 'days');
    const differenceInDaysString = formatRelativeDateTime(
        chapter?.last_set_ongoing_at,
        true
    );
    const color = schoolSubject?.color ?? theme.colors.info;
    const cantArchive =
        liveReportMode ||
        (hasRole(props.user, "student")
            ? !chapter?.id ||
            animated ||
            !(
                chapter?.ongoing === 1 &&
                chapter?.last_set_ongoing_by === props.user?.id
            ) ||
            chapter?.ongoing === 0
            : !chapter?.id ||
            chapter?.ongoing === 0 ||
            chapter?.student_chapters?.length === 0);

    const xpTotal = chapter?.xp_total;

    const xpMax = Math.max(props.xpToValidateChapter ?? 350, xpTotal);
    const widthMax = width ? width : liveReportMode
        ? dimensions.width - 100
        : dimensions.width - 40;

    const xpTotalWidth = (xpTotal / xpMax) * widthMax;

    const level = chapter?.student_chapters?.[0]?.level ?? chapter?.level;

    //Animation setup
    const [triggerAnimation, setTriggerAnimation] = useState(false);

    useEffect(() => {
        if (animated) {
            setTimeout(() => {
                setTriggerAnimation(true);
            }, 500);
        }
    }, []);

    const chapterItemComponentContent = () => (
        <View
            style={{
                backgroundColor: isHighlighted
                    ? theme.colors.primaryLighter
                    : theme.colors.white,
                borderWidth: 1,
                borderColor: isHighlighted
                    ? theme.colors.primary
                    : theme.colors.lightMore,
                alignSelf: "center",
                borderRadius: 10,
                width: widthMax,
                marginTop: liveReportMode ? 20 : 10,
                paddingHorizontal: 10,
                paddingTop: 15,
                paddingBottom: 0,
                ...style,
            }}
        >
            <View
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 10,
                    }}
                >
                    {schoolSubject?.icon && (
                        <IconImageM uri={schoolSubject?.icon} width={60} style={{}}/>
                    )}
                    <View
                        style={{
                            marginLeft: 10,
                            maxWidth: widthMax - 60 - 10 * 2 - 10,
                        }}
                    >
                        <TextM color={color} fontWeight={"SemiBold"} fontSize={17} wrap>
                            {chapter?.name}
                        </TextM>
                        {withParentChapter && <TextM fontSize={11} fontWeight={"SemiBold"} wrap>
                            {chapter?.parent_chapter?.name
                                ? chapter?.parent_chapter?.name + " - "
                                : ""}
                            {schoolSubject?.name} - {chapter?.class}
                        </TextM>}
                        {level && chapter?.id && !renderChapterOnly && <ActivityLevelBadge level={level}/>}
                    </View>
                </View>
                {!renderChapterOnly && props.archiveChapterRequest &&
                props.archivedChapterId === chapter?.student_chapters?.[0]?.id ? (
                    <ActivityIndicator color={theme.colors.primary} size={"small"}/>
                ) : !renderChapterOnly && !cantArchive ? (
                    <View style={{}}>
                        {/*<Menu3Point*/}
                        {/*    menuItems={menuItems}*/}
                        {/*    icon={"ellipsis-v"}*/}
                        {/*    color={theme.colors.light}*/}
                        {/*/>*/}
                    </View>
                ) : null}
            </View>
            {
                //liveReportMode && chapter?.ongoing === 0 ? <></>
                //     <View style={{
                //     flexDirection: 'row',
                //     alignItems: 'center',
                //     justifyContent: 'center',
                //     marginVertical: 10,
                //     width: IS_WEB ? "50%" : "100%",
                //     marginHorizontal: IS_WEB ? 0 : 10,
                //     alignSelf: 'center'
                // }}>
                //     <View style={{
                //         backgroundColor: theme.colors.lighter,
                //         padding: 10,
                //         borderRadius: 10,
                //         width: "100%"
                //     }}>
                //         <TextM wrap>
                //             {chapter.last_tutor_comment}
                //         </TextM>
                //     </View>
                //     <AvatarM user={chapter?.last_commented_by_user}
                //              size={35}
                //              style={{
                //                  alignSelf: 'flex-end',
                //                  marginLeft: 5
                //              }}
                //     />
                // </View>
                //     :
                liveReportMode && (
                    <View
                        style={{
                            alignItems: "flex-start",
                            justifyContent: "center",
                            marginBottom: 15,
                            alignSelf: "flex-start",
                        }}
                    >
                        {validatedActivities > 0 && (
                            <TextM
                                color={theme.colors.success}
                                fontWeight={"SemiBold"}
                                wrap
                                fontSize={12}
                            >
                                {"+" +
                                    validatedActivities +
                                    (validatedActivities > 1 ? " travaux" : " travail") +
                                    " envoyé" +
                                    (validatedActivities > 1 ? "s" : "")}
                            </TextM>
                        )}
                        {todoActivities > 0 ? (
                            <TextM
                                color={theme.colors.accent}
                                fontWeight={"SemiBold"}
                                wrap
                                fontSize={12}
                            >
                                {"+" +
                                    todoActivities +
                                    (todoActivities > 1 ? " travaux" : " travail") +
                                    " à faire"}
                            </TextM>
                        ) : validatedActivities > 0 && interviewReportMode ? (
                            <TextM
                                color={theme.colors.success}
                                fontWeight={"SemiBold"}
                                wrap
                                fontSize={15}
                            >
                                OBJECTIF ATTEINT ✅
                            </TextM>
                        ) : null}
                    </View>
                )
            }
            {!renderChapterOnly && <View
                style={{
                    width: widthMax,
                    height: 15,
                    backgroundColor: theme.colors.lighter,
                    alignSelf: "center",
                    marginTop: 15,
                    // borderBottomWidth: 1,
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderColor: isHighlighted
                        ? theme.colors.primary
                        : theme.colors.lightMore,
                    borderBottomRightRadius: 9,
                    borderBottomLeftRadius: 9,
                }}
            >
                {!!xpTotal && (
                    <View
                        style={{
                            flexDirection: "row",
                            position: "absolute",
                            top: -16,
                            left: 10,
                        }}
                    >
                        <TextM fontSize={12} fontWeight={"SemiBold"}>
                            {chapter?.xp_gained ?? 0}
                            <TextM fontSize={10}>{" /" + xpTotal} </TextM>
                        </TextM>
                    </View>
                )}
                {!!xpTotalWidth && (
                    <View
                        style={{
                            flexDirection: "row",
                            position: "absolute",
                            height: "100%",
                            width: xpTotalWidth - 2,
                            justifyContent: "space-between",
                        }}
                    >
                        {barsElements}
                    </View>
                )}
                {!!xpTotalWidth && (
                    <View
                        style={{
                            position: "absolute",
                            //backgroundColor: theme.colors.successDark,
                            width: xpTotalWidth,
                            height: "100%",
                            borderBottomLeftRadius: 9,
                            alignItems: "flex-end",
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesome5
                            solid
                            name="flag"
                            size={18}
                            color={
                                todoActivities > 0
                                    ? theme.colors.accent
                                    : theme.colors.success
                            }
                            style={{
                                top: -17,
                                position: "absolute",
                                right: -14.5,
                                // opacity: chapter?.id && (chapter?.ongoing === 0) ? 0.5 : 1,
                            }}
                        />
                        <View
                            style={{
                                backgroundColor: todoActivities > 0
                                    ? theme.colors.accent
                                    : theme.colors.success,
                                width: 2.4,
                                height: "100%",
                                // opacity: chapter?.id && (chapter?.ongoing === 0) ? 0.5 : 1,
                            }}
                        ></View>
                    </View>
                )}
            </View>}
        </View>
    );

    const barsElements = chapter?.xp_gained_steps
        ?.map((stepXp, index) => {
            const firstNewIndex =
                chapter?.xp_gained_steps?.length - validatedActivities;
            const hasNew = validatedActivities > 0;

            const barColor = !hasNew
                ? liveReportMode
                    ? theme.colors.successGray
                    : theme.colors.success
                : index >= firstNewIndex
                    ? theme.colors.success
                    : theme.colors.successGray;
            const barWidth = (stepXp / xpMax) * widthMax - 2;

            const isLast =
                index === chapter?.xp_gained_steps?.length - 1 &&
                chapter?.xp_remaining_steps?.length === 0;
            const isTotalMax = xpTotal === xpMax;

            return (
                <View
                    key={index}
                    style={{
                        alignItems: "center",
                        flexDirection: "row",
                    }}
                >
                    {animated && index === chapter?.xp_gained_steps?.length - 1 ? (
                        <ProgressBarM
                            noText
                            progressColor={barColor}
                            barWidth={barWidth}
                            barStyle={{
                                height: "100%",
                                borderRadius: 0,
                                borderBottomLeftRadius: index === 0 ? 9 : 0,
                                borderBottomRightRadius: isLast && isTotalMax ? 9 : 0,
                                marginRight: 0,
                            }}
                            style={{marginTop: 0}}
                            previousCount={0}
                            canStartAnimation={triggerAnimation}
                            withAnimation={true}
                            count={100}
                            total={100}
                        />
                    ) : (
                        <View
                            style={{
                                backgroundColor: barColor,
                                width: barWidth,
                                height: "100%",
                                borderBottomLeftRadius: index === 0 ? 9 : 0,
                                borderBottomRightRadius: isLast && isTotalMax ? 9 : 0,
                            }}
                        ></View>
                    )}
                    {/*<IconImageM source={materialLevelsIcons[chapter?.level_gained_steps?.[index]] ?? intensityLevel1}*/}
                    {/*            width={12}*/}
                    {/*            style={{*/}
                    {/*                tintColor: theme.colors.white,*/}
                    {/*                position: "absolute",*/}
                    {/*                right: 5*/}
                    {/*            }}*/}
                    {/*/>*/}
                </View>
            );
        })
        ?.concat(
            chapter?.xp_remaining_steps?.map((stepXp, index) => {
                const barColor = theme.colors.lightMore;
                const barWidth = (stepXp / xpMax) * widthMax - 2;

                const isLast = index === chapter?.xp_remaining_steps?.length - 1;
                const isTotalMax = xpTotal === xpMax;

                return (
                    <View
                        key={index + chapter?.xp_gained_steps?.length}
                        style={{
                            backgroundColor: barColor,
                            flexDirection: "row",
                            alignItems: "center",
                            width: barWidth,
                            height: "100%",
                            borderBottomLeftRadius:
                                index === 0 && chapter?.xp_gained_steps?.length === 0 ? 9 : 0,
                            borderBottomRightRadius: isLast && isTotalMax ? 9 : 0,
                        }}
                    >
                        {/*<IconImageM source={materialLevelsIcons[chapter?.level_remaining_steps?.[index]] ?? intensityLevel1}*/}
                        {/*            width={12}*/}
                        {/*            style={{*/}
                        {/*                tintColor: theme.colors.white,*/}
                        {/*                position: "absolute",*/}
                        {/*                right: 5*/}
                        {/*            }}*/}
                        {/*/>*/}
                    </View>
                );
            })
        );

    const menuItems = [
        {
            title: "Archiver",
            onPress: () => {
                DialogM.show({
                    text1: `Archiver ce chapitre ?`,
                    //text2: "Cette action est irréversible",
                    buttonText1: "Archiver",
                    onPress: () => {
                        props.apiRequest(
                            chaptersService.archiveChapter,
                            {id: chapter?.student_chapters[0]?.id},
                            {tutor_comment: null}
                        );
                    },
                    buttonText2: "Annuler",
                });
            },
        },
    ];
    useApiStatus(
        chaptersService.archiveChapter, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        }
    );
    return props.forceDisabled ? (
        <View style={{}}>{chapterItemComponentContent()}</View>
    ) : (
        <TouchableOpacity
            style={{}}
            onPress={onPress}
            disabled={animated || liveReportMode}
        >
            {chapterItemComponentContent()}
        </TouchableOpacity>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        xpToValidateChapter: state.data.staticData?.xp_to_validate_chapter,
        archivedChapterId: state.api.archiveChapter?.routeParams?.id,
        archiveChapterRequest: state.api.archiveChapter?.request,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveModeEnabled: state.liveMode?.isLiveModeEnabled,
        materialLevels: state.data?.staticData?.material_levels,
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChapterItemComponent);
