import TouchableHighlightM from "@common/components/button/TouchableHighlightM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import {Badge} from "react-native-paper";
import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";

function ValidateEmailActionCard(props) {

    const {style} = props
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)

    return !props?.user?.is_verified ? <TouchableHighlightM onPress={() => {
        navigation.push('validate-email')
    }}
                                                             style={{
                                                                 alignItems: 'center',
                                                                 justifyContent: 'center',
                                                                 alignSelf: "center",
                                                                 backgroundColor: theme.colors.white,
                                                                 // borderTopWidth: 1,
                                                                 borderWidth: 1,
                                                                 borderColor: theme.colors.lightMore,
                                                                 borderRadius: 15,
                                                                 width: dimensions?.width - 20,
                                                                 paddingLeft: 13,
                                                                 paddingRight: 13,
                                                                 paddingTop: 18,
                                                                 paddingBottom: 18,
                                                                 marginTop: 20,
                                                                 // opacity: count > 0 ? 1 : 0.7,
                                                                 ...style
                                                             }}
        // disabled={count === 0}
                                                             underlayColor={theme.colors.lightMore}
    >
        <View style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: "row",
            width: "100%"
        }}>

            <View style={{
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "row",
                width: "100%"
            }}>
                <FontAwesome5 name={"envelope"}
                              solid
                              style={{
                                  // flex:1
                              }}
                              color={theme.colors.warning}
                              size={24}/>

                <View style={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    marginLeft: 14
                }}>
                    <TextM color={theme.colors.grey}
                           fontWeight="SemiBold"
                           fontSize={18}
                           wrap
                           style={{
                               // marginTop: 4
                           }}
                    >
                        Valide ton adresse email
                    </TextM>

                </View>
            </View>
            <Badge size={12}
                   style={{
                       position: "absolute",
                       backgroundColor: theme.colors.error,
                       top: -20,
                       right: -15
                   }}
            />
        </View>
    </TouchableHighlightM> : null
}

const mapStateToProps = state => {
    return {

        user: state.data.currentUser?.object


    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmailActionCard)