import React, {useContext, useEffect, useRef, useState} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import {Dimensions, FlatList, Platform, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {liveService} from "@data/services/liveService";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {SnackBarM} from "@common/components/alert/snackbar/SnackBar";
import {
    addPhoneRinging,
    removePhoneRinging,
    resetPhoneRinging,
    setCallPortal,
    setPhoneRinging
} from "@data/redux/actions/liveMode";
import {Menu3Point} from "@common/components/menu/Menu3Point";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useNewTodo} from "@common/messenger/socketHook.web";
import {activitiesService} from "@data/services/activitiesService";
import {displayTimeSeconds, formatRelativeDateTime} from "@data/utility/Utils";
import TextM from "@common/components/text/TextM";
import {hasRole} from "@data/utility/ability";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TitleM from "@common/components/text/TitleM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {findItemInListWithId} from "@data/utility/dataUtils";
import NewStudentsListItem from "../../tutor/students/NewStudentsListItem";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ImageViewerModal} from "@common/camera/CameraImagesM";
import DualIconButton from "../components/icons/DualIconButton";

export const TIME_SINCE_LAST_CALL_MINUTES = 15;
const StudentsList = (props) => {
    const [byChapter, setByChapter] = useState(!props.isLive);
    const flatListRef = useRef();
    const dimensions = useContext(DimensionsContext);
    const navigation = useNavigation();

    const liveSession = props.currentLiveSession;
    const studentParticipants =
        liveSession?.users?.filter((user) => !user?.is_tutor) ?? [];
    const tutorParticipants =
        liveSession?.users?.filter((user) => user?.is_tutor) ?? [];
    const presentStudents =
        studentParticipants?.filter((user) =>
            props.participants?.includes(user?.id)
        ) ?? [];
    const presentTutors =
        tutorParticipants?.filter((user) =>
            props.participants?.includes(user?.id)
        ) ?? [];

    const retrieveLastCommonActivity = () => {
        let studentsActivities = studentParticipants
            ?.map((u) => u?.todo_activities)
            ?.flat()
            ?.filter((x) => x?.common_live_room_id === liveSession?.live_room_id);

        // get the common activity with same school_subject_id, chapter and with max of 10 seconds gap between created_at
        if (studentsActivities?.length > 1) {
            studentsActivities = studentsActivities?.filter((b) => {
                const a = studentsActivities?.[0];

                return (
                    a?.school_subject_id === b?.school_subject_id &&
                    a?.chapter === b?.chapter &&
                    a?.common_live_room_id === b?.common_live_room_id &&
                    Math.abs(new Date(a?.created_at) - new Date(b?.created_at)) < 10000
                );
            });

            return studentsActivities?.length > 1 ? studentsActivities?.[0] : null;
        } else {
            return null;
        }
    };

    const commonActivity = retrieveLastCommonActivity();

    // console.log("presentStudents", presentStudents)
    // console.log("presentTutors", presentTutors)
    useEffect(() => {
        //props.apiRequest(studentsService.getStudents)

        setTimeout(() => {
            props.setCallPortal(true);
        }, 1000);
    }, []);

    const request =
        props.getSessionsRequest ||
        props.getCallRequest ||
        props.deleteCommonActivityTodoRequest;

    const newTodoEvent = useNewTodo({
        channel: `presence-messenger.thread.${liveSession?.thread_id}`,
        id: null
    });

    useApiStatus(
        liveService.makeCall, // api service
        false, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack()) navigation.popToTop();
            SnackBarM.show({text1: "Appel en cours...", duration: 4000});
        } // success callback
    );
    useApiStatus(
        liveService.lowerAllHands, // api service
        false, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        } // success callback
    );

    useApiStatus(
        liveService.lowerHands, // api service
        false, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        } // success callback
    );

    useApiStatus(
        liveService.setSoundMode, // api service
        false, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log("setSoundMode", successData)
        } // success callback
    );

    useApiStatus(
        activitiesService.deleteCommonActivityTodo, // api service
        false, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            successData?.students_ids?.forEach((id) => {
                newTodoEvent(id);
            });

            DialogM.show({
                text1: "Effectué",
                text2: successData?.message
            });
        } // success callback
    );

    useEffect(() => {
        props.resetPhoneRinging();
    }, []);

    // useEffect(() => {
    //     if (props.updatedActivityEventData && hasRole(props.user, "tutor")) {
    //         const user = findItemInListWithId(
    //             props.updatedActivityEventData?.student_id,
    //             liveSession?.users
    //         );
    //         const todo = findItemInListWithId(
    //             props.updatedActivityEventData?.activity_id,
    //             user?.todo_activities
    //         );
    //         const activityTemplate = findItemInListWithId(
    //             todo?.activity_template_id,
    //             props.activityTemplates
    //         );
    //         const formData = activityTemplate?.steps;
    //         const activityType = formData?.find((step) => step.type === "photo")
    //             ? "photo"
    //             : "audio";
    //         if (props.updatedActivityEventData.action === "activity media sent") {
    //             const msg = `${user?.display_name} a envoyé ${
    //                 activityType === "photo" ? "une photo" : "un audio"
    //             }`;
    //             Toast.info(msg, "top");
    //         }
    //     }
    // }, [props.updatedActivityEventData]);
    const additionalActions = (item, isSoundOn, isOnline, activeTime) => {
        return [

            {
                icon: "headset",
                backgroundColor: null,
                disabled: true,
                label: activeTime ? displayTimeSeconds(activeTime) : "-",
                color: theme.colors.success,
                onPress: () => {
                }
            },
            {
                icon: "stopwatch",
                backgroundColor:
                    props.timesSinceLastCall?.[item.item?.id] >=
                    TIME_SINCE_LAST_CALL_MINUTES * 60
                        ? theme.colors.errorLight
                        : null,
                disabled: true,
                label:
                    props.timesSinceLastCall?.[item.item?.id] > 0
                        ? displayTimeSeconds(props.timesSinceLastCall?.[item.item?.id])
                        : "-",
                badgeCounter:
                    props.timesSinceLastCall?.[item.item?.id] >=
                    TIME_SINCE_LAST_CALL_MINUTES * 60
                        ? 1
                        : 0,
                color:
                    props.timesSinceLastCall?.[item.item?.id] >=
                    TIME_SINCE_LAST_CALL_MINUTES * 60
                        ? theme.colors.error
                        : theme.colors.purple,
                onPress: () => {
                }
            },
            {
                icon: !isSoundOn ? "volume-mute" : "volume-up",
                backgroundColor: !isOnline
                    ? theme.colors.lightMore
                    : !isSoundOn
                        ? theme.colors.purple
                        : theme.colors.success,
                label: !isOnline ? "-" : !isSoundOn ? "Silence" : "Dialogue",
                color: theme.colors.white,
                request:
                    props.setSoundModeRequest &&
                    props.setSoundModeRequestUserIds?.includes(item.item?.id),
                disabled: !isOnline,
                fontSize: 10,
                borderRadius: 10,
                onPress: () => {
                    props.apiRequest(
                        liveService.setSoundMode,
                        {id: props.liveSessionId},
                        {
                            enabled: !props.soundModeIds?.find((id) => id === item.item?.id),
                            user_ids: [item.item?.id]
                        }
                    );
                    props.apiRequest(
                        liveService.lowerHands,
                        {id: props.liveSessionId},
                        {user_ids: [item.item?.id]}
                    );
                }
            }
        ];
    };
    const renderItem = (item) => {
        const isRinging = props.phoneRinging?.includes(item.item?.id);
        const isSoundOn = props.soundModeIds?.find((id) => id === item.item?.id);
        const isOnline = !!props.participants?.find((x) => x === item.item?.id);
        const activeTime = props.activeTime?.find(
            (x) => x?.id === item.item?.id
        )?.time;

        return (
            <NewStudentsListItem
                student={item.item}
                isTutor={item.item?.is_tutor}
                index={item.index}
                studentsNumber={studentParticipants.length}
                byChapter={byChapter}
                soundMode={isSoundOn && isOnline && !item.item?.is_tutor}
                style={
                    {
                        // borderColor: props.raisedHands?.includes(item.item?.id) ? theme.colors.accent : theme.colors.lightMore,
                    }
                }
                withTodo
                additionalActions={additionalActions(
                    item,
                    isSoundOn,
                    isOnline,
                    activeTime
                )}
                isLiveMode
            />
        );
    };

    const [openGoldenRules, setOpenGoldenRules] = React.useState(false);

    const renderGoldenRules = () => (
        <>
            <FilledButtonM
                onPress={() => {
                    setOpenGoldenRules(true);
                }}
                icon={"book-open"}
                // compact
                style={{
                    marginBottom: 0,
                    maxWidth: 160,
                    marginRight: 10,
                    height: 30
                }}
                contentStyle={{
                    marginBottom: 0,
                    maxWidth: 160,
                    height: 30
                }}
                labelStyle={{
                    fontSize: 11
                }}
                color={theme.colors.accent}
                // mode={"text"}
                label={"5 règles d'or"}
            />
        </>

    );
    const renderHeader = () => {
        return (
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: dimensions.width > 800 ? "center" : "flex-start",
                    width: Dimensions.get("window").width,
                    borderColor: theme.colors.lightMore,
                    paddingVertical: 8,
                    paddingHorizontal: 20,
                    marginBottom: dimensions.width > 800 ? 20 : 0
                }}
            >
                {dimensions.width > 800 && (
                    <View
                        style={{
                            position: "absolute",
                            left: 40,
                            top: 5,
                            alignItems: "center"
                        }}
                    >
                        {renderFooter({width: "100%", justifyContent: "flex-start"})}
                    </View>
                )}
                <ImageViewerModal
                    visible={openGoldenRules}
                    onDismiss={() => setOpenGoldenRules(false)}
                    imageIndex={0}
                    images={[
                        "https://masteur.com/wp-content/uploads/2024/04/regles-dor-live-scaled.webp"
                    ]}
                />
                <TitleM fontWeight="ExtraBold">{"élèves".toUpperCase()}</TitleM>
                <View
                    style={{
                        position: "absolute",
                        right: 20,
                        flexDirection: "row",
                        alignItems: "center"
                    }}
                >
                    {dimensions.width > 800 ? renderGoldenRules() : null}
                    <DualIconButton
                        icon1="th-list"
                        icon2="book"
                        onSelectIcon1={() => setByChapter(false)}
                        onSelectIcon2={() => setByChapter(true)}
                        initialSelectedIcon={byChapter ? "icon2" : "icon1"}
                    />
                    {/*{dimensions.width > 800 ? renderGoldenRules() : null}*/}
                    <FilledButtonM
                        onPress={() => {
                            navigation.push("new-todo", {forStudents: true});
                        }}
                        icon={"plus"}
                        // compact
                        style={{
                            marginBottom: 0,
                            maxWidth: 150,
                            height: 30,
                            marginLeft: 5
                        }}
                        contentStyle={{
                            marginBottom: 0,
                            maxWidth: 150,
                            height: 30
                        }}
                        labelStyle={{
                            fontSize: 11
                        }}
                        color={theme.colors.primary}
                        // mode={"text"}
                        label={"Activité"}
                    />
                    {request && IS_WEB ? (
                        <ActivityIndicator
                            color={theme.colors.primary}
                            size={"small"}
                            style={{marginRight: 6}}
                        />
                    ) : (
                        <IconButton
                            onPress={() => {
                                props.apiRequest(liveService.getLiveSession, {
                                    id: props.liveSessionId
                                });
                                props.apiRequest(liveService.getCall, {
                                    id: props.liveSessionId
                                });
                            }}
                            iconColor={theme.colors.primary}
                            disabled={request}
                            icon={"sync-alt"}
                            size={20}
                        />
                    )}
                    <Menu3Point
                        menuItems={[
                            {
                                icon: "volume-mute",
                                title: "Mettre tous en mode silencieux",
                                onPress: () => {
                                    props.apiRequest(
                                        liveService.setSoundMode,
                                        {id: props.liveSessionId},
                                        {
                                            enabled: false,
                                            user_ids: studentParticipants?.map((u) => u?.id)
                                        }
                                    );
                                }


                            },
                            {
                                icon: "volume-up",
                                title: "Mettre tous en mode actif",
                                onPress: () => {
                                    props.apiRequest(
                                        liveService.setSoundMode,
                                        {id: props.liveSessionId},
                                        {
                                            enabled: true,
                                            user_ids: studentParticipants?.map((u) => u?.id)
                                        }
                                    );
                                }


                            },
                            //{
                            //icon: "hand-paper",
                            //title: "Baisser toutes les mains", onPress: () => {
                            //props.apiRequest(liveService.lowerAllHands, {id: props.liveSessionId})
                            //}
                            //},
                            {
                                icon: "plus",
                                title: "Nouvelle activité commune",
                                onPress: () => {
                                    navigation.push("new-todo", {forStudents: true});
                                }
                            },
                            {
                                icon: "trash",
                                title: "Suppr. dernière activité commune",
                                onPress: () => {
                                    if (!commonActivity)
                                        DialogM.show({
                                            text1: "Aucune activité commune",
                                            text2: "Il n'y a aucune activité commune à supprimer",
                                            buttonText1: "OK",
                                            onPress: () => {
                                            }
                                        })
                                    else
                                        DialogM.show({
                                            text1: "Supprimer l’activité pour tous les élèves ?",
                                            text2:
                                                commonActivity?.title +
                                                ", " +
                                                findItemInListWithId(
                                                    commonActivity?.school_subject_id,
                                                    props.schoolSubjects
                                                )?.name +
                                                ", créée " +
                                                formatRelativeDateTime(commonActivity?.created_at),
                                            buttonText1: "Supprimer",
                                            onPress: () => {
                                                props.apiRequest(activitiesService.deleteCommonActivityTodo, {}, {}, {
                                                    live_session_id: props.liveSessionId
                                                })
                                            },
                                            buttonText2: "Annuler",
                                            onPressBack: () => {
                                            }
                                        });
                                }
                            },
                            {
                                icon: "book-open",
                                title: "Les 5 règles d'or",
                                onPress: () => {
                                    setOpenGoldenRules(true);
                                }
                            }
                        ]}
                        optionStyle={{
                            width: 200
                        }}
                    />
                </View>
            </View>
        );
    };
    const renderFooter = (style = {}) => {
        return (
            <>
                {hasRole(props.user, "tutor") && (
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            //justifyContent: "flex-end",
                            width: Dimensions.get("window").width,
                            borderColor: theme.colors.lightMore,
                            paddingTop: 8,
                            paddingRight: 10,
                            ...style
                        }}
                    >
                        <FontAwesome5
                            name="headset"
                            solid
                            size={16}
                            color={theme.colors.success}
                        />
                        <TextM wrap style={{marginLeft: 5}} fontSize={10}>
                            {" "}
                            = Temps total en mode dialogue
                        </TextM>
                    </View>
                )}
                {hasRole(props.user, "tutor") && (
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: "flex-end",
                            width: Dimensions.get("window").width,
                            borderColor: theme.colors.lightMore,
                            paddingTop: 8,
                            //marginBottom: 20,
                            paddingRight: 10,
                            ...style
                        }}
                    >
                        <FontAwesome5
                            name="stopwatch"
                            solid
                            size={16}
                            color={theme.colors.purple}
                        />
                        <TextM wrap style={{marginLeft: 5}} fontSize={10}>
                            {" "}
                            = Temps écoulé de puis la dernière activation du mode silencieux
                        </TextM>
                    </View>
                )}
            </>
        );
    };
    const ITEM_WIDTH = Dimensions.get("window").width - 60;
    const snapToOffsets = studentParticipants.map((x, i) => {
        return i * ITEM_WIDTH - 10;
    });
    const renderFinalize = () => {
        return (
            <View
                style={{
                    alignSelf: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginVertical: 20,
                    width: "100%"
                }}>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack();
                        else navigation.replace("menu");
                    }}
                />
                <View
                    style={{
                        alignSelf: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "70%"
                    }}
                >
                    <TextM
                        wrap
                        color={theme.colors.primary}
                        fontWeight={"Bold"}
                        fontSize={17}
                        style={{
                            textAlign: "center"
                        }}
                    >
                        Avant de clôturer, mets bien à jour la liste d'activités de chaque
                        élève
                    </TextM>
                    <TextM
                        style={{
                            textAlign: "center"
                        }}
                        wrap
                        color={theme.colors.primary}
                        fontWeight={"SemiBold"}
                    >
                        Fais un peu de nettoyage pour que chaque élève sache ce qu’il doit
                        faire avant la prochaine séance
                    </TextM>
                </View>
            </View>
        );
    };
    return (
        <View
            style={{
                flex: 1,
                backgroundColor: theme.colors.whiteAlmost,
                alignItems: "center",
                width: Dimensions.get("window").width,
                alignSelf: Platform.OS !== "web" ? null : "center"
            }}
        >
            <>
                {props.finalizeMode ? (
                    renderFinalize()
                ) : (
                    <>
                        {renderHeader()}
                        {/*{dimensions.width < 800 && renderFooter({marginLeft: 10})}*/}
                    </>
                )}
                <FlatList
                    data={studentParticipants ?? []}
                    ref={flatListRef}
                    //initialScrollIndex={currentIndex}
                    refreshing={!!props.getSessionsRequest}
                    onRefresh={() => {
                        props.apiRequest(liveService.getLiveSession, {
                            id: props.liveSessionId
                        });
                        props.apiRequest(liveService.getCall, {id: props.liveSessionId});
                    }}
                    style={{
                        width: Dimensions.get("window").width
                        //width: "100%",
                    }}
                    horizontal
                    pagingEnabled={!IS_WEB}
                    decelerationRate={0}
                    snapToOffsets={snapToOffsets}
                    snapToAlignment={"center"}
                    ListEmptyComponent={
                        <TextM style={{textAlign: "center", marginTop: 0}}>
                            Aucun participant
                        </TextM>
                    }
                    //ListHeaderComponent={renderHeader}
                    //ListFooterComponent={renderFooter}
                    renderItem={renderItem}
                />
            </>
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
            (x) => x?.id === state.liveMode?.liveSessionId
        ),
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        raisedHands: state.liveMode?.raisedHands,
        soundModeIds: state.liveMode?.soundModeIds,
        phoneRinging: state.liveMode?.phoneRinging,
        timesSinceLastCall: state.liveMode?.timesSinceLastCall,
        activeTime: state.liveMode?.activeTime,
        participants: state.liveMode?.liveParticipants,
        schoolSubjects: state.data.staticData?.school_subjects,
        activityTemplates: state.data.staticData?.activity_templates,
        deleteCommonActivityTodoRequest:
        state.api.deleteCommonActivityTodo?.request,
        getSessionsRequest: state.api.getLiveSession?.request,
        getCallRequest: state.api.getCall?.request,
        lowerAllHandsRequest: state.api.lowerAllHands?.request,
        lowerHandsRequest: state.api.lowerHands?.request,
        lowerHandsRequestUserIds: state.api.lowerHands?.data?.user_ids,
        setSoundModeRequest: state.api.setSoundMode?.request,
        setSoundModeRequestUserIds: state.api.setSoundMode?.data?.user_ids,
        isLive: state.liveMode.isLiveModeEnabled,
        updatedActivityEventData: state.activity?.updatedActivityEventData
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    addPhoneRinging,
    removePhoneRinging,
    setPhoneRinging,
    resetPhoneRinging,
    setCallPortal
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentsList);
