import {Portal, Snackbar} from "react-native-paper";
import {theme} from "@common/theme/theme";
import React, {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from "react";
import {useSnackBar} from "@common/components/alert/snackbar/useSnackBar";
import {DimensionsContext} from "@common/utils/mobileUtils";


let currentRef = null
export const SnackBar = forwardRef((props, ref) => {
    const {config, ...defaultOptions} = props;
    const {show, hide, isVisible, options, data} = useSnackBar({
        defaultOptions
    })
    const dimensions = useContext(DimensionsContext)

    useImperativeHandle(
        ref,
        useCallback(
            () => ({
                show,
                hide
            }),
            [hide, show]
        )
    );

    return (
        <Portal>
            <Snackbar
                style={{
                    backgroundColor: theme.colors.grey,
                    textColor: theme.colors.white,
                    // width: dimensions?.width - 20,
                    alignSelf: 'center',
                }}
                duration={data.duration}
                visible={isVisible}
                theme={{colors: {accent: theme.colors.white, surface: theme.colors.white}}}
                onDismiss={hide}
                action={{
                    onPress: () => {
                        // Do something
                    }
                }}>
                {data.text1}
            </Snackbar>
        </Portal>
    );

})

export function SnackBarM(props) {
    const snackRef = useRef(null);

    const setRef = useCallback((ref) => {
        if (ref) {
            snackRef.current = ref;
            currentRef = ref;
        }
    }, []);

    return (
        <SnackBar ref={setRef} {...props} />
    );
}

SnackBarM.show = (params) => {
    currentRef?.show(params);
};

SnackBarM.hide = (params) => {
    currentRef?.hide(params);
};