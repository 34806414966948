// ** React Imports
import React, {useContext, useState} from 'react'
import {connect} from 'react-redux'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {TouchableOpacity, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ServerSearch from "../ServerSearch";
import {PaginatedTableM} from "../PaginatedTableM";
import {referralService} from "@data/services/referralService";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import LinkTextM from "@common/components/text/LinkTextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import AvatarM from "@common/components/avatar/AvatarM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useApiStatus} from "@common/hooks/useApiStatus";

const ReferralsBackofficeScreen = (props) => {

    const {navigation, route} = props
    const [urlParams, setUrlParams] = useState({})

    const commissionTypes = {
        "cash": "Montant fixe",
        "fnac_coupon": "Bon Fnac",
        "refund": "Remboursement"
    }

    const dimensions = useContext(DimensionsContext);

    const columns = [
        {
            name: 'Nom',
            style: {
                width: 160,
                marginRight: 40
                // height: 60,
            },
            selector: 'name',
            sortable: true,
            cell: row => <TouchableOpacity onPress={() => {
                navigation.push("referrer/dashboard/:h", {h: row?.hash})
            }}
                                           style={{
                                               justifyContent: "flex-start", alignItems: "center", flexDirection: "row"
                                           }}>
                <View style={{marginLeft: 0}}>
                    <TextM color={theme.colors.primary}
                           fontWeight={"Medium"}
                           wrap
                           style={{maxWidth: 100}}>{`${row?.name}`}</TextM>
                </View>
            </TouchableOpacity>
        },
        {
            name: 'Lien',
            style: {
                width: 200,
                marginRight: 40
            },
            selector: 'referee_url',
            sortable: true,
            cell: row => <LinkTextM url={row?.referee_url} style={{flexWrap: "wrap"}}>{row?.referee_url}</LinkTextM>
        },
        {
            name: 'Commission',
            style: {
                width: 80,
                marginRight: 40
            },
            selector: 'commission_type',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.commission_amount}€`}</TextM>
                <TextM fontWeight={"Light"}
                       wrap
                       fontSize={8}
                       style={{}}>{`${commissionTypes?.[row?.commission_type]}`}</TextM>
            </View>
        },
        {
            name: 'Remise clients',
            style: {
                width: 40,
                marginRight: 40
            },
            selector: 'referee_discount',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.referee_discount}%`}</TextM>
            </View>
        },
        {
            name: 'Clics',
            style: {
                width: 40,
                marginRight: 40
            },
            selector: 'clicks_count',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.clicks_count}`}</TextM>
            </View>
        },
        {
            name: 'Essais lancés',
            style: {
                width: 40,
                marginRight: 40
            },
            selector: 'pending_referrals_count',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.pending_referrals_count}`}</TextM>
            </View>
        },
        {
            name: 'Abonn. activés',
            style: {
                width: 40,
                marginRight: 40
            },
            selector: 'activated_referrals_count',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.activated_referrals_count}`}</TextM>
            </View>
        },
        {
            name: 'Montant dû',
            style: {
                width: 40,
                marginRight: 40
            },
            selector: 'amount_due',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.amount_due}€`}</TextM>
            </View>
        },
        {
            name: 'Montant payé',
            style: {
                width: 40,
                marginRight: 40
            },
            selector: 'amount_paid',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.amount_paid}€`}</TextM>
            </View>
        },
        {
            name: 'Créé par',
            style: {
                width: 140,
                marginRight: 40
            },
            selector: 'created_by',
            sortable: true,
            cell: row => <View style={{
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row"
            }}>
                <AvatarM user={row?.created_by} size={26}/>
                <View style={{marginLeft: 5}}>
                    <TextM wrap>{`${row?.created_by?.display_name}`}</TextM>
                </View>
            </View>
        },
        {
            name: 'Actions',
            style: {
                width: 340,
                marginRight: 40
            },
            selector: 'commission_amount',
            sortable: true,
            cell: row => <View style={{flexDirection: "row"}}>
                <FilledButtonM label={"Modifier"}
                               icon={"edit"}
                               style={{
                                   marginRight: 5,
                                   marginBottom: 0,
                                   width: null
                               }}
                               color={theme.colors.primary}
                               labelStyle={{fontSize: 10}}
                               contentStyle={{height: 30}}
                               onPress={() => {
                                   navigation.push("new-referral-code", {"referral_code_id": row?.id})
                               }}/>
                <FilledButtonM label={"Payer"}
                               style={{
                                   marginRight: 5,
                                   marginBottom: 0,
                                   width: null
                               }}
                               icon={"euro-sign"}
                               loading={props.markDueAsPaidRequest && props.markDueAsPaidRequestId === row?.id}
                               disabled={props.markDueAsPaidRequest && props.markDueAsPaidRequestId === row?.id}
                               color={theme.colors.success}
                               labelStyle={{fontSize: 10}}
                               contentStyle={{height: 30}}
                               onPress={() => {
                                   DialogM.show({
                                       text1: 'Marquer le montant dû comme payé ?',
                                       buttonColor1: theme.colors.success,
                                       buttonText1: 'Marquer comme payé', // buttonColor1: theme.colors.error,
                                       onPress: () => {
                                           props.apiRequest(referralService.markDueAsPaid, {id: row?.id})
                                       },
                                       buttonText2: 'Annuler',
                                       onPressBack: () => {

                                       }
                                   })
                               }}/>
                <FilledButtonM label={"Supprimer"}
                               style={{
                                   marginBottom: 0,
                                   width: null
                               }}
                               icon={"trash"}
                               loading={props.deleteReferralCodeRequest && props.deleteReferralCodeRequestId === row?.id}
                               disabled={props.deleteReferralCodeRequest && props.deleteReferralCodeRequestId === row?.id}
                               color={theme.colors.error}
                               labelStyle={{fontSize: 10}}
                               contentStyle={{height: 30}}
                               onPress={() => {
                                   DialogM.show({
                                       text1: 'Supprimer le code d\'affiliation ?',
                                       buttonColor1: theme.colors.error,
                                       buttonText1: 'Supprimer', // buttonColor1: theme.colors.error,
                                       onPress: () => {
                                           props.apiRequest(referralService.deleteReferralCode, {id: row?.id})
                                       },
                                       buttonText2: 'Annuler',
                                       onPressBack: () => {

                                       }
                                   })
                               }}/>
            </View>
        }

    ]

    useApiStatus(
        referralService.deleteReferralCode, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
        } // success callback
    )
    useApiStatus(
        referralService.markDueAsPaid, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
        } // success callback
    )

    return (
        <View style={{
            flex: 1
        }}
        >
            <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}} noTopInset>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginHorizontal: 20
                }}>
                    <TitleM>Liens d'affiliation</TitleM>
                </View>
                <View style={{flexDirection: "row", alignItems: "center"}}>
                    <FilledButtonM label={"Créer"}
                                   icon={"plus"}
                                   style={{width: null, margin: 10}}
                                   labelStyle={{fontSize: 12}}
                                   onPress={() => {
                                       navigation.push("new-referral-code")
                                   }}/>
                    <ServerSearch style={{
                        width: dimensions?.width / 3,
                        marginVertical: 10,
                        marginHorizontal: 20
                    }}
                                  urlParams={urlParams}
                                  setUrlParams={setUrlParams}
                    />
                </View>
            </HeaderContainerM>
            <PaginatedTableM data={props.referralCodes}
                             flatListRef={null}
                             columns={columns}
                             headerHeight={60}
                             requestService={referralService.indexPaginatedReferralCodes}
                             urlParams={urlParams}
            />
        </View>
    )


}

const mapStateToProps = state => {
    return {
        referralCodes: state.data?.referrals?.referralCodes,
        indexPaginatedReferralCodesRequest: state.api?.indexPaginatedReferralCodes?.request,
        indexPaginatedReferralCodesSuccess: state.api?.indexPaginatedReferralCodes?.success,
        deleteReferralCodeRequest: state.api?.deleteReferralCode?.request,
        deleteReferralCodeRequestId: state.api?.deleteReferralCode?.routeParams?.id,
        markDueAsPaidRequest: state.api?.markDueAsPaid?.request,
        markDueAsPaidRequestId: state.api?.markDueAsPaid?.routeParams?.id
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralsBackofficeScreen)