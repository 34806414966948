import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import TextM from "@common/components/text/TextM";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {IconImageM} from "@common/components/icons/IconsM";

function FileStep(props) {

    const {
        stepNum, stepInfo, borderBottom = false, style, active, width, onPress = () => {
        }, currentStep
    } = props
    const navigation = useNavigation()


    const disabled = false


    return <View style={{
        flexDirection: "row",
        alignItems: "flex-start",
        width: "100%",
        //flex: 1,
        minHeight: 130,
        opacity: disabled ? 0.5 : 1,
        borderBottomWidth: borderBottom ? 1 : 0
    }}
    >
        <TouchableOpacity
            disabled={stepNum !== currentStep}
            onPress={onPress}>
            <TextM color={theme.colors.primary}
                   fontWeight={"Bold"}
                   style={{textAlign: 'center'}}>{stepInfo?.title}</TextM>
            <View style={{
                backgroundColor: theme.colors.primary,
                borderRadius: 10,
                width: 90,
                height: 70 * 1.2,
                marginBottom: 15,
                marginHorizontal: 15,
                marginTop: 5,
                alignItems: "center",
                justifyContent: "center"
            }}>
                <IconImageM
                    uri={stepInfo?.image}
                    width={50}
                    style={{}}/>
            </View>
        </TouchableOpacity>

    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
        skill_sets: state.data.staticData?.skill_sets,
        skills: state.data.staticData?.skills,
        lastPhoto: state.camera?.lastPhoto,
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(FileStep)