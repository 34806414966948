import React, { useContext } from "react";
import { View } from "react-native";
import StudentsList from "@common/liveMode/StudentsList";
import { theme } from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import { DimensionsContext } from "@common/utils/mobileUtils";
import { liveService } from "@data/services/liveService";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";

const TodoCheck = (props) => {
  const dimensions = useContext(DimensionsContext);
  return (
    <View
      style={{
        flex: 1,
        maxHeight: dimensions.height,
      }}
    >
      <StudentsList finalizeMode />
      <FilledButtonM
        label={"Clôturer le live"}
        color={theme.colors.error}
        loading={props.finalizeLiveSessionRequest}
        style={{
          alignSelf: "center",
          marginTop: 20,
        }}
        onPress={() => {
          props.apiRequest(liveService.finalizeLiveSession, {
            id: props.liveSessionId,
          });
        }}
      />
    </View>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    finalizeLiveSessionRequest: state.api.finalizeLiveSession?.request,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoCheck);
