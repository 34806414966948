import axios from "axios"

function purchase({id}) {
    return axios.post(`api/shop-products/${id}/purchase`)
}



export const shopService = {
    purchase,
}

