import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dimensions, SectionList, StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect} from "react-redux";
import {subscriptionService} from "@data/services/subscriptionService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {
    skillSetIds,
    studentClasses,
    subjectPrioritiesSing,
    subjectPrioritiesSingLight,
    subjectPrioritiesSubjectsDisplay
} from "@data/constants/formConstants";
import TextM from "@common/components/text/TextM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {OfferPrivilegesList, trialPrivileges} from "./TrialOnboardingScreen";
import {ActivityIndicator, IconButton} from "react-native-paper";
import AvatarM from "@common/components/avatar/AvatarM";
import {IconImageM} from "@common/components/icons/IconsM";
import logo from "../../../../assets/icon-2.png";
import {SelectInputM} from "./SelectInputM";
import {CertificateIconM} from "./CertificateIconM";
import {renderers, withMenuContext} from "react-native-popup-menu";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import LiveRoomCard from "./LiveRoomCard";
import IconButtonM from "@common/components/button/IconButtonM";
import CustomizableAvatar from "../CustomizableAvatar"
import {getFromStorage, removeFromStorage, setToStorage} from "@data/utility/asyncStorageUtils";

export const referralCodeRetrieve = async (navigation, route) => {
    if (IS_WEB) {

        const referralCode = route?.params?.ref ?? null

        // If ref parameter is found, save it to Local Storage along with a timestamp
        if (referralCode) {
            await setToStorage('referralCode', referralCode);
            await setToStorage('referralCodeTimestamp', JSON.stringify(Date.now()));
        }

        // Get referral code and timestamp from Local Storage
        const dataCode = await getFromStorage('referralCode') ?? null;
        const referralTimestamp = await getFromStorage('referralCodeTimestamp') ?? null;

        // Check if the referral data is less than 45 days old
        const isReferralValid = referralTimestamp && ((Date.now() - referralTimestamp) <= (45 * 24 * 60 * 60 * 1000));

        if (isReferralValid && dataCode && dataCode !== referralCode) {
            navigation.setParams({
                ...route?.params,
                ref: dataCode
            })
        }

        // Optionally, remove expired referral data from Local Storage
        if (!isReferralValid && referralTimestamp) {
            await removeFromStorage('referralCode');
            await removeFromStorage('referralCodeTimestamp');
        }
    }
}


export function TutorProfileCardHeader({tutor, editableAvatar=false, selectedData}) {
    const dimensions = useContext(DimensionsContext)

    const studyInstitution = selectedData ? selectedData?.study_institution : tutor?.profile?.study_institution
    const studyField = selectedData ? selectedData?.study_field : tutor?.profile?.study_field
    const selectedBio = skillSetIds?.find((x) => x?.value === selectedData?.tutor_skill_set_title_id)?.label
    const bio = studyField + " | " + (selectedData ? selectedBio : tutor?.profile?.bio)

    return (
        <>
            <IconImageM source={logo}
                        width={70}
                        style={{
                            borderRadius: 35,
                            opacity: 0.08,
                            position: "absolute",
                            top: 15,
                            right: 15
                        }}/>
            <View style={{
                flexDirection: "row",
                width: dimensions?.width - 40,
                paddingHorizontal: 20,
                paddingBottom: 20,
            }}>
                {editableAvatar ? <CustomizableAvatar user={tutor} onboardingMode/>
                    : <AvatarM user={tutor}
                               size={80}
                               onboardingMode
                    />
                }
                <View style={{
                    flex: 1,
                    marginLeft: 10,
                    marginTop: 5
                }}>
                    <View style={{
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <TextM color={theme.colors.primary}
                               fontSize={18}
                               fontWeight={"Bold"}
                               wrap>{tutor?.display_name}</TextM>
                        <CertificateIconM/>

                    </View>
                    <TextM color={theme.colors.grey}
                           fontSize={14}
                           fontWeight={"Medium"}
                           style={{marginTop: 5}}
                           wrap>{studyInstitution}</TextM>
                    <TextM color={theme.colors.grey}
                           fontSize={14}
                           fontWeight={"Regular"}
                           style={{marginTop: 5}}
                           wrap>{bio}</TextM>

                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 20
                    }}>
                        {subjectPrioritiesSubjectsDisplay[selectedData?.relevant_subjects?.join(",")]?.map((x, index) => {
                            return <View key={index}
                                         style={{
                                             backgroundColor: theme.colors.primaryLighter,
                                             paddingVertical: 7,
                                             paddingHorizontal: 10,
                                             marginRight: 8,
                                             borderRadius: 5,
                                             alignItems: "center",
                                             justifyContent: "center"
                                         }}>
                                <TextM color={theme.colors.grey}
                                       fontSize={11}
                                       fontWeight={"Regular"}
                                       wrap>{x}</TextM>
                            </View>
                        })}
                    </View>

                </View>

            </View>
        </>
    )
}
export function TutorProfileCard({tutor, onSelectLiveRoom, liveRoom, style, withIndication = false, hideSlots=false}) {

    const dimensions = useContext(DimensionsContext)

    return <View style={{
        width: dimensions?.width - 40,
        borderRadius: theme.roundness,
        backgroundColor: theme.colors.white,
        alignItems: "center",
        paddingVertical: 20,
        // paddingHorizontal: 10,
        borderWidth: 1,
        borderColor: theme.colors.lightMore,
        marginTop: 20,
        ...style
    }}
    >
        <TutorProfileCardHeader tutor={tutor}/>

        {!!onSelectLiveRoom && <View style={{
            width: dimensions?.width - 40,
            alignItems: "center",
            marginTop: 20
        }}>
            <TextM style={{
                marginLeft: 20,
                alignSelf: "flex-start"
            }}

                   fontWeight={"Bold"}>Groupes de LIVE disponibles</TextM>
            {tutor?.live_rooms?.map((lr, index) => <LiveRoomCard key={index}
                                                                 liveRoom={lr}
                                                                 tutor={tutor}
                                                                 onPress={() => {
                                                                     onSelectLiveRoom(lr?.id?.toString() ?? lr?.slug, tutor?.id)
                                                                 }}
            />)}

        </View>}

        {!!liveRoom && <View style={{
            width: dimensions?.width - 40,
            alignItems: "center"
            // marginTop: 20
        }}>
            <LiveRoomCard liveRoom={liveRoom}
                          tutor={tutor}
                          description={withIndication ? "Le lien d'accès au Live sera disponible ici, sur l'accueil de votre compte." : null}
                          hideSlots={hideSlots}
            />
        </View>}

    </View>;
}

const StartTrialNewScreen = (props) => {

    // ** Component props
    const {navigation, route} = props

    const listRef = useRef()

    const dimensions = useContext(DimensionsContext)

    const studentClass = route?.params?.student_class ?? "6ème"
    const subjectPriority = route?.params?.subject_priority ?? null
    const subjectPriorityLight = subjectPrioritiesSingLight?.find(x => x?.value === subjectPriority)?.label

    const [availableLiveRooms, setAvailableLiveRooms] = useState([])

    // group available live rooms by tutor
    const tutors = availableLiveRooms?.reduce((acc, curr) => {

        // group the ones with id together, and the ones with no id together
        if (!curr?.id) {
            const foundTutorLiveRoomCreation = acc.find(x => x?.id === curr?.tutor?.id && x?.live_rooms?.length > 0 && x?.live_rooms?.[0]?.id === null)

            if (!foundTutorLiveRoomCreation) {
                acc.push({
                    ...curr?.tutor,
                    live_rooms: [curr]
                })
            } else {
                foundTutorLiveRoomCreation?.live_rooms?.push(curr)
            }
            return acc

        } else {
            const foundTutor = acc.find(x => x?.id === curr?.tutor?.id && x?.live_rooms?.length > 0 && x?.live_rooms?.[0]?.id !== null)

            if (!foundTutor) {
                acc.push({
                    ...curr?.tutor,
                    live_rooms: [curr]
                })
            } else {
                foundTutor?.live_rooms?.push(curr)
            }
            return acc
        }

    }, [])


    useEffect(() => {
        if (!route?.params?.student_class) {
            setTimeout(() => {
                props.ctx.menuActions.openMenu('student_class').then()
            }, 300)
            navigation.setParams({
                ...route?.params,
                student_class: studentClass
            })
        }

        referralCodeRetrieve(navigation, route).then()

    }, []);

    useEffect(() => {
        if (!studentClass) return

        props.apiRequest(subscriptionService.getLiveRoomsAvailableForTrial, {}, {}, {
            student_class: studentClass,
            subject_priority: subjectPriority ?? null
        })
    }, [studentClass, subjectPriorityLight])

    const onSelectLiveRoom = (liveRoom, tutorId) => {
        navigation.replace(props.user ? "start-trial/confirm" : "register-trial/confirm", {
            ...route?.params,
            live_room: liveRoom,
            tutor_id: tutorId
        })
    }

    useApiStatus(
        subscriptionService.getLiveRoomsAvailableForTrial, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            setAvailableLiveRooms(data)
        } // success callback
    )

    const renderScreenHeader = () => {
        return <View style={{
            width: Dimensions.get("window").width,
            backgroundColor: theme.colors.accentLighter,
            alignItems: "center",
            paddingTop: IS_WEB ? 20 : 0,
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderColor: theme.colors.lightMore
        }}>
            {!IS_WEB && <IconButton
                icon={"arrow-left"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    alignSelf: "flex-start"
                    // left: 4
                }}
                onPress={() => {
                    navigation.goBack()
                }}
            />}
            <TextM color={theme.colors.primary}
                   fontSize={28}
                   fontWeight={"Black"}
                   style={{textAlign: "center", marginHorizontal: 10}}
                   wrap
            >Choisissez votre tuteur et vos horaires</TextM>
            <OfferPrivilegesList privilegesList={trialPrivileges}
                                 whiteBackgroundMode={true}/>
            <IconButtonM
                size={40}
                icon={"angle-double-down"}
                iconColor={theme.colors.primary}
                onPress={() => {
                    // scroll to index 0
                    listRef.current?.scrollToLocation({
                        animated: true,
                        itemIndex: 1,
                        sectionIndex: 0,
                        viewOffset: 0,
                        viewPosition: 0
                    })
                }}
            />
        </View>;
    };


    const renderListHeader = () => {
        return <View style={{
            alignItems: "center",
            backgroundColor: theme.colors.white,
            borderBottomWidth: 1,
            // borderLeftWidth: 1,
            // borderRightWidth: 1,
            borderColor: theme.colors.lightMore,
            // borderBottomLeftRadius: dimensions?.width < 800 ? 0 : theme.roundness,
            // borderBottomRightRadius: dimensions?.width < 800 ? 0 : theme.roundness,
            // flexDirection: "row",
            width: Dimensions?.get("window")?.width
        }}>
            <View style={{
                alignItems: "center",
                flexDirection: "row",
                width: dimensions?.width,
                paddingHorizontal: 20,
                paddingVertical: 15
            }}>

                <SelectInputM options={studentClasses.map(x => ({label: x, value: x})).slice(0, -1)}
                              name={"student_class"}
                              label={"Classe de l'élève"}
                              sliderLabel={"Choisir la classe de l'élève"}
                              value={studentClass}
                              onSelect={(value) => {
                                  navigation.setParams({
                                      ...route?.params,
                                      student_class: value
                                  })
                              }}
                              style={{
                                  flex: 1,
                                  marginRight: 10
                              }}
                />
                <SelectInputM options={subjectPrioritiesSing}
                              label={"Soutien souhaité"}
                              sliderLabel={"Soutien souhaité"}
                              name={"subject_priority"}
                              value={subjectPriority}
                              onSelect={(value) => {
                                  navigation.setParams({
                                      ...route?.params,
                                      subject_priority: value
                                  })
                              }}
                              style={{
                                  flex: 1
                              }}
                />
            </View>
            {props.getLiveRoomsAvailableForTrialRequest && <ActivityIndicator size={"small"}
                                                                              style={{
                                                                                  position: "absolute",
                                                                                  bottom: -40
                                                                              }}
                                                                              color={theme.colors.primary}/>}
        </View>;
    };

    const renderListItem = (item) => {

        const tutor = item?.item
        const index = item?.index

        return <TutorProfileCard key={index}
                                 tutor={tutor}
                                 onSelectLiveRoom={onSelectLiveRoom}
        />
    };

    const sectionListData = [
        // {
        //     title: renderScreenHeader(),
        //     data: []
        // },
        {
            title: renderListHeader(),
            data: props.getLiveRoomsAvailableForTrialRequest ? [] : tutors
        }
    ]

    const insets = useSafeAreaInsets();

    return (
        <View style={{
            flex: 1,
            backgroundColor: null,
            alignItems: 'center',
            width: "100%",
            paddingTop: insets.top
            // justifyContent: 'center'
        }}>

            <SectionList sections={sectionListData}
                         onScrollToIndexFailed={(error) => {
                             console.log('error', error)
                         }}
                         ref={listRef}
                         stickySectionHeadersEnabled={true}
                         style={{
                             width: "100%"
                             // flex:1
                         }}
                         contentContainerStyle={{
                             alignItems: "center",
                             width: "100%",
                             paddingBottom: 200
                             // flex:1
                             // width: dimensions?.width
                         }}
                         renderItem={renderListItem}
                         renderSectionHeader={({section}) => section.title}
                         ListHeaderComponent={renderScreenHeader()}
                         ListFooterComponent={<>
                             {!props.getLiveRoomsAvailableForTrialRequest
                                 && <View style={{
                                     alignSelf: "center",
                                     alignItems: "center",
                                     marginTop: 50
                                     // position: "absolute",
                                     // backgroundColor : theme.colors.error,
                                     // bottom: -130
                                 }}>
                                     <TextM wrap style={{
                                         marginBottom: 10,
                                         textAlign: "center"
                                     }}>
                                         {tutors?.length === 0 ? "Aucun groupe disponible actuellement avec ces critères." : "Aucun créneau ne vous convient ?"}
                                     </TextM>
                                     <FilledButtonM mode={"contained"}
                                                    style={{}}
                                                    color={theme.colors.primary}
                                                    onPress={() => {
                                                        onSelectLiveRoom((0).toString(), null)
                                                    }}
                                                    label={"Demander un nouveau groupe"}
                                     />
                                 </View>}
                         </>}
            />

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        getLiveRoomsAvailableForTrialRequest: state.api.getLiveRoomsAvailableForTrial?.request,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default withMenuContext(connect(mapStateToProps, mapDispatchToProps)(StartTrialNewScreen))