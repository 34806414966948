import React, {useContext, useState} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {Vibration, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import alertPolyfill from "@common/components/alert/alertPolyfill";
import {displayTimeSeconds} from "@data/utility/Utils";
import {useInterval} from "@common/utils/useInterval";
import TitleM from "@common/components/text/TitleM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import RejectActivity from "./RejectActivity";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import ValidateActivity from "./ValidateActivity";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const boxedCardBorderStyle = IS_WEB ? {
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderRadius: 10
} : {}

function ActivityReviewSection(props) {

    const {activity, schoolSubject} = props

    const navigation = useNavigation()

    const [rejectMode, setRejectMode] = React.useState(false);
    const dimensions = useContext(DimensionsContext)


    const [time, setTime] = useState(0)

    // ** custom setInterval handler compatible with hooks (cf https://overreacted.io/making-setinterval-declarative-with-react-hooks/)
    useInterval(() => {
        setTime(pt => pt + 1)
        if (Math.floor(time / 1000) === 15 * 60) {
            Vibration.vibrate(50)
            DialogM.show({
                text1: "Verrou perdu",
                text2: "Tu as dépassé la durée de 15 minutes, l'activité n'est donc plus verrouillée par toi"
            })
            // alertPolyfill("Verrou perdu", "Tu as dépassé la durée de 15 minutes, l'activité n'est donc plus verrouillée par toi")
        }
    }, activity?.status === "pending_review" ? 1000 : null)

    return <View style={{
        // flex: 1,
        alignItems: 'center',
        justifyContent: "flex-start",
        paddingHorizontal: 5,
        backgroundColor: theme.colors.white,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        marginTop: 30,
        borderColor: theme.colors.lightMore,
        // paddingBottom: 15,
        width: dimensions?.width,
        ...boxedCardBorderStyle
    }}>

        <View style={{
            flexDirection: "row",
            alignItems: 'center',
            justifyContent: 'space-between',
            width: dimensions?.width,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 20
        }}>
            <View style={{
                alignItems: 'flex-start'
            }}>
                <TitleM>REVUE</TitleM>
                <TextM fontWeight="SemiBold" fontSize={12} style={{marginTop: -4}}>
                    {displayTimeSeconds(time)}
                </TextM>
            </View>
            <FilledButtonM icon={rejectMode ? "check" : "times"}
                           mode="outlined"
                           color={rejectMode ? theme.colors.success : theme.colors.error}
                           style={{marginTop: 20, width: null}}
                // labelStyle={{color: theme.colors.white}}
                           onPress={() => {
                               setRejectMode(!rejectMode)
                           }}
                           label={rejectMode ? "Valider" : "Rejeter"}/>
        </View>

        {!rejectMode ? <ValidateActivity activity={activity}
                                         time={time}
                                         dimensions={dimensions}
                                         schoolSubject={schoolSubject}
                                         navigation={navigation}
        /> : <RejectActivity activity={activity}
                             schoolSubject={schoolSubject}
                             time={time}
                             dimensions={dimensions}
                             navigation={navigation}/>}
    </View>;
}

const mapStateToProps = state => {
    return {
        getActivitiesRequest: state.api.getActivities?.request,
        getActivitiesSuccess: state.api.getActivities?.success,
        getActivitiesError: state.api.getActivities?.error,

        scoringCriteria: state.data.staticData?.scoring_criteria,
        reviewer: state.data.reviewer?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReviewSection)