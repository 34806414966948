import {Divider} from "react-native-paper";
import React, {useContext} from "react";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {Menu, MenuOption, MenuOptions, MenuTrigger, renderers} from "react-native-popup-menu";
import {theme} from "../../theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "../text/TextM";
import {DimensionsContext, IS_WEB} from "../../utils/mobileUtils";
import {View} from "react-native";

export const MenuM = ({buttonComponent, items, style, position = 'left', renderer = renderers.ContextMenu, name = null, center = false, optionStyle}) => {
    const insets = useSafeAreaInsets();

    const dimensions = useContext(DimensionsContext)

    return <Menu style={style}
                 renderer={renderer}
                 name={name}
    >
        <MenuTrigger>
            {buttonComponent}
        </MenuTrigger>
        <MenuOptions customStyles={{
            optionsWrapper: {
                paddingBottom: renderer === renderers.SlideInMenu ? insets.bottom : 0
            },
            optionWrapper: {
                width: IS_WEB && dimensions.width > 800 ? dimensions.width / 2 : dimensions.width - 40,
                height: 60
                // flexDirection: "row",
                // margin: 5,
            }
        }}>
            {items?.map((i, index) => {
                switch (i.type) {
                    case "title":
                        return <React.Fragment key={index}>
                            <TextM fontWeight={"ExtraBold"}
                                   fontSize={18}
                                   color={theme.colors.primary}
                                   style={{
                                       marginTop: 15,
                                       marginBottom: 10,
                                       paddingLeft: 10,
                                       textAlign: center ? "center" : "left"
                                   }}
                                   wrap
                            >{i?.title}</TextM>
                        </React.Fragment>
                    case "divider":
                        return <Divider key={index}/>
                    default:
                        return !i?.masked && <MenuOption key={index}
                                                         style={{
                                                             alignSelf: center ? "center" : "flex-start",
                                                             backgroundColor: i?.highlighted && theme.colors.lightMore,
                                                             width: "100%",
                                                             ...optionStyle
                                                         }}
                                                         onSelect={i?.onPress}>
                            <View style={{
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: center ? "center" : "flex-start",
                                flex: 1,
                                // backgroundColor: "red"
                            }}>
                                {i?.icon && <View style={{width: 30, alignItems: "center", justifyContent: "center"}}>
                                    <FontAwesome5 style={{paddingVertical: 5, marginHorizontal: 4}}
                                                  name={i?.icon}
                                                  size={18}
                                                  color={theme.colors.grey}/>
                                </View>}
                                {i?.leftComponent ? i?.leftComponent : null}
                                {i?.title && <TextM fontSize={16}
                                                    wrap
                                                    fontWeight={"Medium"}
                                                    style={{
                                                        paddingHorizontal: 10,
                                                        paddingVertical: 5,
                                                        textAlign: center ? "center" : "left"
                                                        // width: IS_WEB && dimensions.width > 800 ? dimensions.width / 2 : dimensions.width - 40
                                                    }}>
                                    {i?.title}
                                </TextM>}
                                {i?.subtitle && <TextM fontSize={10}
                                    //fontWeight={"Medium"}
                                                       style={{
                                                           paddingRight: 80,
                                                           paddingLeft: 5,
                                                           //paddingVertical: 5,
                                                           // width: IS_WEB && dimensions.width > 800 ? dimensions.width / 2 : dimensions.width - 40
                                                       }}>
                                    {i?.subtitle}
                                </TextM>}
                                {i?.component && i?.component}
                            </View>
                        </MenuOption>
                }
            })}

        </MenuOptions>
    </Menu>

}