import React, {useContext} from 'react';
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";

const TutorsStatsCardItem = (props) => {
    const {mainTitle, items, style} = props
    const dimensions = useContext(DimensionsContext);
    return (
        <View style={{
            borderWidth: 1,
            borderColor: theme.colors.lightMore,
            backgroundColor: theme.colors.white,
            borderRadius: theme.roundness,
            paddingVertical: 10,
            paddingHorizontal: 25,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            ...style
        }}>
            <TextM color={theme.colors.primary}
                   fontSize={25}
                   fontWeight={'Bold'}
                   style={{
                       marginBottom: 15
                   }}
            >
                {mainTitle.toUpperCase()}
            </TextM>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: IS_WEB ? 'space-between' : 'center',
                width: IS_WEB ? '100%' : dimensions.width,
                flexWrap: 'wrap',
            }}>
                {items.map((item, index) => {

                    return <View key={index} style={{
                        marginBottom: 10,
                        width: IS_WEB ? "45%" : '90%',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: theme.colors.lightMore,
                        backgroundColor: theme.colors.primaryLighter,
                        borderRadius: theme.roundness,
                        paddingVertical: 10,
                        paddingHorizontal: 25,
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginHorizontal: 5
                    }}>
                        <TextM color={theme.colors.primary}
                               fontSize={IS_WEB ? 20 : 12}
                               style={{
                                   //textAlign: 'center',
                                   marginRight: 10
                               }}
                               wrap
                        >
                            {item.name}
                        </TextM>
                        <TextM fontSize={25} style={{minWidth: 70}} fontWeight={'Bold'}>
                            {item.value ?? "0"}
                        </TextM>
                    </View>
                })}
            </View>

        </View>
    )
}

export default TutorsStatsCardItem;