import moment from "moment";
import {hasRole} from "@data/utility/ability";
import {Dimensions, View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../../assets/icons/generic/live.png";
import TextM from "@common/components/text/TextM";
import {capitalize} from "@data/utility/Utils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import React, {useContext, useState} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {setTodoActivities} from "@data/redux/actions/data";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {findClosestNextOccurrence} from "../../account/onboarding/LiveRoomCard";
import LinkTextM from "@common/components/text/LinkTextM";
import RescheduleTrialLiveSessionModal from "../../tutoring/RescheduleTrialLiveSessionModal";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const NextLiveSessionBanner = (props) => {

    const {noButton = false, subscriptionUser = null} = props

    const user = subscriptionUser ?? props.user

    if (!hasRole(user, "student"))
        return null



    const dimensions = useContext(DimensionsContext)

    const navigation = useNavigation()

    const closestNextLiveSessionOccurrence = findClosestNextOccurrence(user?.live_sessions)
    // console.log("closestNextLiveSessionOccurrence?.live_session_id", closestNextLiveSessionOccurrence?.live_session_id)
    const closestNextLiveSession = user?.live_sessions?.find(x => x?.id === closestNextLiveSessionOccurrence?.live_session_id)
    const liveMoment = moment(closestNextLiveSessionOccurrence?.date)
    const isToday = liveMoment?.isSame(moment(), "day")

    const [selectLiveSessionModalVisible, setSelectLiveSessionModalVisible] = useState(false)

    // const isTooEarly = moment()?.isBefore(liveMoment?.clone()?.subtract(15, "minutes"))

    // const isAlert = moment()?.isBetween(liveMoment?.clone()?.subtract(15, "minutes"), liveMoment?.clone()?.add(45, "minutes"))
    // useEffect(() => {
    //     if (props.isLiveMode && isToday) {
    //         if (isTooEarly) {
    //             navigation.push("intro", {
    //                 name: "zoe",
    //                 introSuffix: `_live_launch_intro_too_early`,
    //                 isTracked: false,
    //                 goBack: true,
    //                 liveModeOnly: true
    //             })
    //         } else {
    //             navigation.push("intro", {
    //                 name: "zoe",
    //                 introSuffix: `_live_launch_intro`,
    //                 isTracked: false,
    //                 goBack: true,
    //                 liveModeOnly: true
    //             })
    //         }
    //     }
    //
    //
    // }, [])

    if (props.isLiveMode) {
        return null
    }


    return !!closestNextLiveSessionOccurrence && <View style={{
        width: Dimensions.get('window').width,
        borderBottomWidth: 1,
        borderColor: theme.colors.lightMore,
        backgroundColor: isToday ? theme.colors.successLight : theme.colors.white,
        // flexDirection: "row",
        alignItems: "center"
    }}>
        <View style={{
            width: dimensions?.width,
            flexDirection: "row",
            alignItems: "center",
            flex: 1,
            paddingVertical: 10,
            paddingHorizontal: 15
        }}>
            <IconImageM source={live}
                        width={40}
                        style={{
                            alignSelf: "flex-start"
                            // tintColor: theme.colors.success
                        }}
            />
            <View style={{
                flex: 1,
                marginLeft: 10
                // marginTop: 5
            }}>
                <TextM color={theme.colors.success}
                       fontWeight={"Regular"}
                       fontSize={12}
                    // style={{marginTop: 5}}
                       wrap>{"Prochaine session LIVE"}</TextM>
                <TextM color={theme.colors.success}
                       fontWeight={"Bold"}
                    // style={{marginTop: 5}}
                       wrap>{capitalize(isToday
                    ? liveMoment.format("[Aujourd'hui à] HH[h]mm")
                    : liveMoment.format("dddd DD MMMM [à] HH[h]mm"))}</TextM>
                {props.user?.subscription_state?.status === "on_generic_trial" && <LinkTextM onPress={() => {
                    setSelectLiveSessionModalVisible(true)
                }}
                           style={{
                               fontSize: 10,
                               marginTop: 8
                           }}>PAS DISPONIBLE ? REPLANIFIER</LinkTextM>}
            </View>
            {!noButton && <FilledButtonM onPress={() => {
                if (isToday) {
                    navigation.push("pre-call", {
                        liveSessionId: closestNextLiveSessionOccurrence?.live_session_id
                    })
                } else {
                    DialogM.show({
                        champion: "zoe",
                        variant: "main",
                        text1: "Le live n'est accessible que le jour-même",
                        text2: `Reviens ${liveMoment.format("dddd")} pour te connecter à ton live !`,
                    });
                }
            }}
                                       color={isToday ?theme.colors.success : theme.colors.light}
                                       style={{
                                           width: 80,
                                           alignSelf: "flex-start",
                                           marginBottom: 0
                                       }}
                                       label={"GO !"}/>}
        </View>


        <RescheduleTrialLiveSessionModal visible={selectLiveSessionModalVisible}
                                         navigation={navigation}
                                         setVisible={setSelectLiveSessionModalVisible}
                                         notify
        />

    </View>;
}

const mapStateToProps = state => {
    return {
        activityTodoIndexesAsStudentRequest: state.api.activityTodoIndexesAsStudent?.request,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        getUserRequest: state.api.getUser?.request,
        getStudentRequest: state.api.getStudent?.request,
        liveSessionId: state.liveMode?.liveSessionId,
        isLiveMode: state.liveMode.isLiveModeEnabled
    }
}

const mapDispatchToProps = {
    apiRequest, apiIdle, setTodoActivities, setIsLiveModeEnabled

}

export default connect(mapStateToProps, mapDispatchToProps)(NextLiveSessionBanner)