import axios from "axios"

function interviewWeekComments({id}, formData) {
  return axios.post(`api/interviews/${id}/week-comments`, formData)
}

function interviewGlobalComment({id}, formData) {
  return axios.post(`api/interviews/${id}/global-comment`, formData)
}

function interviewFinalize({id}, formData) {
  return axios.post(`api/interviews/${id}/finalize`, formData)
}
function interviewTutorConnected({id}) {
  return axios.post(`api/interviews/${id}/tutor-connected`)
}

export const interviewsService = {
  interviewWeekComments,
  interviewGlobalComment,
  interviewFinalize,
  interviewTutorConnected
}

