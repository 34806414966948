import React, { useState } from "react";
import { Dimensions, View } from "react-native";
import AvatarM from "../components/avatar/AvatarM";
import { IconImageM } from "../components/icons/IconsM";
import SatisfactionComponent from "../liveMode/SatisfactionComponent";
import liveLogo from "../../../assets/icons/generic/live.png";
import TextM from "../components/text/TextM";
import { liveService } from "../../data/services/liveService";
import { connect } from "react-redux";
import { apiIdle, apiRequest } from "../../data/redux/actions/api";
import { theme } from "../theme/theme";
import { DialogM } from "../components/alert/dialog/Dialog";

const LiveSessionDialogComponent = (props) => {
  const { navigation } = props;
  const [rating, setRating] = useState(0);
  const dimensions = Dimensions.get("window");
  const liveSession = props.currentLiveSession;

  return (
    <View
      style={{
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: 80,
          alignItems: "center",
        }}
      >
        <AvatarM
          user={props.currentLiveSession?.tutor}
          size={dimensions.width > 800 ? 60 : 70}
          style={{}}
        />
        <IconImageM
          source={liveLogo}
          style={{ position: "absolute", top: 0, right: 0 }}
          width={30}
        />
      </View>

      <TextM
        color={theme.colors.grey}
        style={{ marginTop: 20, textAlign: "center" }}
        wrap
      >
        Ton avis régulier est précieux, pour nous aider à nous améliorer !
      </TextM>

      <SatisfactionComponent
        rating={rating}
        setRating={setRating}
        withApiRequest
        requestApiService={liveService.sendSatisfaction}
        requestRouteParams={{ id: liveSession?.id }}
        additionalActions={(value) => {
          DialogM.hide();
          props.apiRequest(liveService.leaveLiveSession, {
            id: liveSession?.id,
          });
          navigation.push("live-satisfaction", {
            rating: value,
            liveSessionId: liveSession?.id,
          });
        }}
      />
    </View>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
  };
};
const mapDispatchToProps = {
  apiRequest,
  apiIdle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveSessionDialogComponent);
