import React, {useContext} from 'react';
import {Platform, ScrollView, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import moment from "moment";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import LinkTextM from "@common/components/text/LinkTextM";
import {Text} from "react-native-paper";
import {PriceDataHeaderM} from "../../auth/PricingColumnM";
import FilledButtonM from "@common/components/button/FilledButtonM";

const SuspensionRecap = (props) => {
    const {
        control,
        goToNextStep,
        register,
        errors,
        serverError,
        setFormValues,
        field,
        pricingTableData,
        pricingTableDataNextMode,
        commitmentEndDate
    } = props

    const isSubscriptionCanceled = props.subscriptionUser?.subscription_state?.status === "ended" || props.subscriptionUser?.subscription_state?.status === "on_grace_period"

    const dimensions = useContext(DimensionsContext)

    const data = pricingTableData.find(x => x?.product_name === control._formValues.product_name)
    const withCommitment = control._formValues.with_commitment

    return <>
        <ScrollView style={{flex: 1, width: dimensions?.width, alignSelf: "center"}}
                    contentContainerStyle={{
                        alignItems: "center",
                        alignSelf: "center",
                        width: dimensions?.width,
                        paddingHorizontal: 10
                    }}>
            <View style={{padding: 20}}>

                {/* Formule choisie section */}
                <TextM style={{marginTop: 0}}
                       fontSize={18}
                       fontWeight={"Bold"}>Offre choisie</TextM>
                <View
                    style={{
                        backgroundColor: theme.colors.white,
                        borderWidth: 1,
                        borderColor: theme.colors.primary,
                        borderRadius: 15,
                        width: dimensions?.width - 40,
                        padding: 20
                    }}
                >
                    <PriceDataHeaderM data={data}
                                      withCommitment={withCommitment}
                                      size={"md"}
                                      index={0}/>
                    <TextM fontWeight={"Regular"}
                           fontSize={10}
                           wrap
                           style={{
                               // marginTop: 20,
                               marginBottom: 5
                           }}>{data?.product_period_end}.</TextM>
                    <TextM fontWeight={"Regular"}
                           fontSize={10}
                           wrap
                           style={{
                               // marginTop: 20,
                               marginBottom: 5
                           }}>Puis :</TextM>
                    <PriceDataHeaderM data={pricingTableDataNextMode?.[0]}
                                      withCommitment={withCommitment}
                                      size={"sm"}
                                      nextMode
                                      lightMode
                                      index={0}/>
                </View>
                <TextM style={{marginTop: 5, marginBottom: 20}} fontWeight={"Light"} fontSize={12} wrap>Prélèvement automatique le 1er
                    du mois.</TextM>

                {/* Precisions section */}
                <TextM style={{marginBottom: 10}}
                       fontSize={18}
                       fontWeight={"Bold"}>Précisions</TextM>
                <TextM wrap>Juste après avoir confirmé votre choix, vous pourrez préciser les matières et
                    horaires que vous souhaitez.</TextM>

                <Text style={{
                    fontSize: 12,
                    textAlign: 'center',
                    marginTop: 30
                    // marginBottom: 10
                }}>
                    En confirmant, vous acceptez les <LinkTextM url="https://masteur.com/cgu">Conditions générales
                    d'utilisation et la Politique de
                    confidentialité</LinkTextM>, ainsi que les <LinkTextM url="https://masteur.com/cgv">Conditions
                    générales
                    de vente</LinkTextM> de Masteur
                </Text>

            </View>
            <FilledButtonM color={theme.colors.success}
                           onPress={goToNextStep}
                           style={{
                               width: IS_WEB && dimensions.width > 800 ? dimensions.width / 1.2 : dimensions.width - 20,
                               alignSelf: 'center',
                               marginTop: Platform.OS !== 'web' ? null : 15,
                               marginBottom: Platform.OS !== 'web' ? null : 15
                               // bottom: 15
                           }}
                           label={"Je confirme mon choix"}/>
        </ScrollView>
    </>
};
const mapStateToProps = state => {
    return {
        holidayOptions: state.data.formData?.holidayOptions,
        daysBeforePayment: state.data.formData?.daysBeforePayment,
        discountPercentage: state.data.formData?.discountPercentage,
        remainingDiscounts: state.data.formData?.remainingDiscounts,
        subscriptionUser: state.data.formData?.user
    }
}
const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SuspensionRecap)