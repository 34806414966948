import {Linking, ScrollView, View} from "react-native";
import React, {useContext} from 'react'
import TitleM from "@common/components/text/TitleM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {ImageViewerModal} from "@common/camera/CameraImagesM";

export default function TutorResources() {

    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    const [openGoldenRules, setOpenGoldenRules] = React.useState(false);

    return (
        <ScrollView>
            <View style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: dimensions?.width,
                alignSelf: 'center',
                paddingHorizontal: 20,
                paddingTop: 10
            }}>
                <TitleM fontWeight="ExtraBold" style={{textAlign:"center"}}>{"Ressources du tuteur".toUpperCase()}</TitleM>

                <FilledButtonM
                    icon={"book-open"}
                    // mode="outlined"
                    style={{
                        marginTop: 20
                    }}
                    color={theme.colors.accent}
                    onPress={() => {
                        setOpenGoldenRules(true)
                    }}
                    label={"Les 5 règles d'or"}
                />

                <ImageViewerModal visible={openGoldenRules}
                                  onDismiss={() => setOpenGoldenRules(false)}
                                  imageIndex={0}
                                  images={["https://masteur.com/wp-content/uploads/2024/04/regles-dor-live-scaled.webp"]}/>

                <FilledButtonM
                    icon={"book-open"}
                    // mode="outlined"
                    style={{
                        marginTop: 20
                    }}
                    color={theme.colors.primary}
                    onPress={() => {
                        navigation.push("all-activities", {
                            browseMode: true
                        })
                    }}
                    label={"Référentiel des activités"}
                />
                {/*<FilledButtonM*/}
                {/*    // labelStyle={{fontSize: IS_WEB && dimensions.width > 800 ? 15 : 12}}*/}
                {/*    icon={"youtube"}*/}
                {/*    mode="outlined"*/}
                {/*    style={{*/}
                {/*        marginTop: 20*/}
                {/*    }}*/}
                {/*    color={theme.colors.primary}*/}
                {/*    onPress={() => {*/}
                {/*        navigation.push("videos-coaching", {*/}
                {/*            coaching: true*/}
                {/*        })*/}
                {/*    }}*/}
                {/*    label={"Formation - Le tutorat"}*/}
                {/*/>*/}
                {/*<FilledButtonM*/}
                {/*    // labelStyle={{fontSize: IS_WEB && dimensions.width > 800 ? 15 : 12}}*/}
                {/*    icon={"youtube"}*/}
                {/*    mode="outlined"*/}
                {/*    style={{*/}
                {/*        marginTop: 20*/}
                {/*    }}*/}
                {/*    color={theme.colors.primary}*/}
                {/*    onPress={() => {*/}
                {/*        navigation.push("videos-coaching", {*/}
                {/*            coaching: false*/}
                {/*        })*/}
                {/*    }}*/}
                {/*    label={"Formation - L'application"}*/}

                {/*/>*/}

                <FilledButtonM
                    // labelStyle={{fontSize: IS_WEB && dimensions.width > 800 ? 15 : 12}}
                    icon={"youtube"}
                    // mode="outlined"
                    style={{
                        marginTop: 20
                    }}
                    color={"red"}
                    onPress={() => {
                        navigation.push("videos-coaching", {
                            coaching: false
                        })
                    }}
                    label={"Formation du tuteur"}
                />
                <FilledButtonM
                    icon={"file-excel"}
                    style={{
                        marginTop: 20
                    }}
                    color={theme.colors.success}
                    onPress={() => {
                        if (IS_WEB)
                            window.open("https://masteur-my.sharepoint.com/:x:/p/fida/EXdUpqOMTH9KgUVnSuL0PCsBhu3TdvfLCcjoaYOXAsv7hw?e=F5ssWD", '_blank', 'noopener,noreferrer')
                        else
                            Linking.openURL("https://masteur-my.sharepoint.com/:x:/p/fida/EXdUpqOMTH9KgUVnSuL0PCsBhu3TdvfLCcjoaYOXAsv7hw?e=F5ssWD").catch(err => console.error("Couldn't load page", err))
                    }}
                    label={"Fichier des énoncés"}
                />
                <FilledButtonM
                    icon={"file-contract"}
                    style={{
                        marginTop: 20
                    }}
                    color={theme.colors.primary}
                    onPress={() => {
                        if (IS_WEB)
                            window.open("https://masteur.com/charte-dengagement", '_blank', 'noopener,noreferrer')
                        else
                            Linking.openURL("https://masteur.com/charte-dengagement").catch(err => console.error("Couldn't load page", err))
                    }}
                    label={"La charte d'engagement"}
                />
                <FilledButtonM
                    icon={"blog"}
                    style={{
                        marginTop: 20
                    }}
                    // mode="outlined"
                    color={theme.colors.primary}
                    onPress={() => {
                        if (IS_WEB)
                            window.open("https://masteur.com/blog", '_blank', 'noopener,noreferrer')
                        else
                            Linking.openURL("https://masteur.com/blog").catch(err => console.error("Couldn't load page", err))
                    }}
                    label={"Le Blog de Masteur"}
                />
            </View>

        </ScrollView>
    )
}