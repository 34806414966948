import React, {useContext, useEffect, useState} from 'react';
import {ScrollView, View} from "react-native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import StatsCardItem from "./StatsCardItem";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {groupByAttribute} from "./GrowthTabScreen";

const QualityTabScreen = (props) => {

    const dimensions = useContext(DimensionsContext)
    const [liveQualityStats, setLiveQualityStats] = useState([])
    useEffect(() => {
        if (props.appStats) {
            setLiveQualityStats(groupByAttribute(Object.values(props.appStats), 'category')["live_quality"])
        }
    }, [props.appStats]);

    return (
        <View style={{
            width: dimensions.width,
            alignSelf: 'center',
            marginVertical: 20,
        }}>
            {props.getApplicationStatisticsRequest ? <ActivityIndicator color={theme.colors.primary}/>
                :
                <ScrollView style={{
                    width: dimensions.width,
                }}>
                    <View style={{
                        width: dimensions.width,
                        flexDirection: IS_WEB ? 'row' : 'column',
                        justifyContent: IS_WEB ? 'space-between' : 'center',
                        alignItems: IS_WEB ? 'stretch' : 'center',
                        flexWrap: IS_WEB ? 'wrap' : 'nowrap',
                    }}>
                        <StatsCardItem
                            mainTitle={"Satisfaction Live"}
                            style={{
                                marginTop: IS_WEB ? 0 : 10,
                                marginRight: IS_WEB ? 10 : 0,
                                width: IS_WEB ? null : dimensions.width - 20
                            }}
                            items={liveQualityStats?.filter(item => item.name?.toLowerCase()?.includes("satisfaction"))}
                        />
                        <StatsCardItem
                            mainTitle={"Qualité Live"}
                            style={{
                                marginTop: IS_WEB ? 0 : 10,
                                marginRight: IS_WEB ? 10 : 0,
                                width: IS_WEB ? null : dimensions.width - 20
                            }}
                            items={liveQualityStats?.filter(item => item.name?.toLowerCase()?.includes("qualité"))}
                        />
                    </View>
                </ScrollView>}
        </View>
    )
}
const mapStateToProps = state => {
    return {
        appStats: state.data.backOffice?.appStats,
        getApplicationStatisticsRequest: state.api.getApplicationStatistics?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityTabScreen)