// ** Initial user ability

export const initialAbility = [

    {
        action: 'read',
        subject: 'Auth'
    }
]

export const rolesAbilities = {

    'super-admin': [
        {
            action: 'read',
            subject: 'backOffice'
        },
        {
            action: 'read',
            subject: 'interviews'
        },
        {
            action: 'read',
            subject: 'analytics'
        },
        {
            action: 'read',
            subject: 'manage_referrals'
        },
    ],
    admin: [
        {
            action: 'read',
            subject: 'backOffice'
        },
        {
            action: 'read',
            subject: 'interviews'
        },
        {
            action: 'read',
            subject: 'analytics'
        },
        {
            action: 'read',
            subject: 'manage_referrals'
        },
    ],
    "referral-admin": [
        {
            action: 'read',
            subject: 'manage_referrals'
        },
    ],
    reviewer: [
        {
            action: 'read',
            subject: 'review_activities'
        }
    ],
    'school-admin': [
        {
            action: 'read',
            subject: 'schools'
        }
    ],
    student: [
        {
            action: 'read',
            subject: 'my_activities'
        },
        {
            action: 'read',
            subject: 'tutor_name'
        },
        {
            action: 'read',
            subject: 'survey_statistics'
        },
        {
            action: 'read',
            subject: 'student_survey'
        },
        {
            action: 'read',
            subject: 'my_student_profile_completion'
        },
        {
            action: 'read',
            subject: 'my_student_profile_update'
        },
        {
            action: 'read',
            subject: 'subscribe'
        },
        {
            action: 'read',
            subject: 'my_subscription'
        },
        {
            action: 'read',
            subject: 'my_successes'
        },
        {
            action: 'read',
            subject: 'my_friends'
        },
        {
            action: 'read',
            subject: 'realize_activity'
        }
    ],
    'integral-student': [
        {
            action: 'read',
            subject: 'have_tutor'
        },
        {
            action: 'read',
            subject: 'have_many_challenges'
        },
        {
            action: 'read',
            subject: 'premium_icon'
        },
        {
            action: 'read',
            subject: 'request_activity_review'
        },
    ],
    'essential-student': [
        {
            action: 'read',
            subject: 'have_tutor'
        },
        {
            action: 'read',
            subject: 'have_many_challenges'
        },
        {
            action: 'read',
            subject: 'plus_icon'
        },
        {
            action: 'read',
            subject: 'request_activity_review'
        }

    ],
    'initial-student': [
        {
            action: 'read',
            subject: 'have_tutor'
        },
        {
            action: 'read',
            subject: 'have_many_challenges'
        },
        {
            action: 'read',
            subject: 'initial_icon'
        },
        {
            action: 'read',
            subject: 'request_activity_review'
        }

    ],
    'holidaycourse-student': [
        {
            action: 'read',
            subject: 'have_tutor'
        },
        {
            action: 'read',
            subject: 'have_many_challenges'
        },
        {
            action: 'read',
            subject: 'plus_icon'
        },
        {
            action: 'read',
            subject: 'request_activity_review'
        },
        {
            action: 'read',
            subject: 'holiday_course_views'
        }
    ],
    'premium-student': [
        {
            action: 'read',
            subject: 'have_tutor'
        },
        {
            action: 'read',
            subject: 'have_many_challenges'
        },
        {
            action: 'read',
            subject: 'premium_icon'
        },
        {
            action: 'read',
            subject: 'request_activity_review'
        },
        {
            action: 'read',
            subject: 'my_interviews'
        },

    ],
    'plus-student': [
        {
            action: 'read',
            subject: 'have_many_challenges'
        },
        {
            action: 'read',
            subject: 'plus_icon'
        },
        {
            action: 'read',
            subject: 'request_activity_review'
        }

    ],
    parent: [],
    teacher: [
        {
            action: 'read',
            subject: 'my_teacher_classrooms'
        },
        {
            action: 'read',
            subject: 'classroom'
        }
    ],
    "tutor-candidate": [
        {
            action: 'read',
            subject: 'my_tutor_profile_completion'
        },
        {
            action: 'read',
            subject: 'my_tutor_slot_completion'
        },
        // {
        //     action: 'read',
        //     subject: 'my_students'
        // },
        // {
        //     action: 'read',
        //     subject: 'my_tutor_profile_update'
        // }
    ],
    tutor: [
        {
            action: 'read',
            subject: 'my_tutor_profile_completion'
        },
        {
            action: 'read',
            subject: 'my_tutor_slot_completion'
        },
        {
            action: 'read',
            subject: 'my_tutor_profile_update'
        },
        {
            action: 'read',
            subject: 'my_students'
        },
        {
            action: 'read',
            subject: 'my_slot'
        },
        {
            action: 'read',
            subject: 'student_details'
        },
        {
            action: 'read',
            subject: 'interviews'
        },
        {
            action: 'read',
            subject: 'tutor_resources'
        },
        {
            action: 'write',
            subject: 'interviews'
        },
        {
            action: 'assign',
            subject: 'activities'
        }
    ],
    any: [
        {
            action: 'read',
            subject: 'dashboard'
        },
        {
            action: 'read',
            subject: 'account'
        },
        {
            action: 'read',
            subject: 'email_verify'
        },
        {
            action: 'read',
            subject: 'logout'
        }
    ]
}
