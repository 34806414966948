import {ScrollView} from "react-native";
import React from "react"
import InterviewStatisticsCard from "../InterviewStatisticsCard";
import InterviewHeader, {InterviewTitle} from "../InterviewHeader";

export default function InterviewStepX1({student, interview}) {

    return (<>
            <InterviewHeader student={student}
                             interview={interview}
            />
            <ScrollView style={{marginBottom: 40, paddingTop: 10}}>
                {InterviewTitle("Bilan global")}
                <InterviewStatisticsCard statistics={student?.statistics}/>

            </ScrollView>
        </>

    )
}