import {View} from "react-native";
import {Badge} from "react-native-paper";
import {IconImageM} from "@common/components/icons/IconsM";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import React from "react";
import {countSameActivitiesToday, countSameAgainActivities} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DoneChip} from "../../activity/read/DoneChip";
import {LinearGradient} from "expo-linear-gradient";

const ActivityPlannedButtonContent = props => {

    const {
        activityTemplate,
        backgroundColor,
        activityPlanned,
        textColor,
        activities,
        schoolSubject,
        challenge,
        buttonWidth,
        small
    } = props

    const activitySchoolSubject = schoolSubject ? schoolSubject : challenge?.school_subject

    // ** Count number of realizations of the same activities (to know if it can be done >again)
    const sameActivitiesCount = challenge?.planning_mode ? countSameAgainActivities(activityTemplate, activities, activityPlanned?.id) : countSameActivitiesToday(activityTemplate, activities, challenge ? challenge?.id : null, schoolSubject ? schoolSubject?.id : null)

    // const doneActivities = activities?.filter(x => !!x?.finished_at)

    // const doneActivities = activities?.filter(x => !!x?.finished_at)

    return <View style={{
        alignItems: "flex-start",
        // height: "100%",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: theme.colors.lightMore,
        borderRadius: buttonWidth / 6 + 1,
        alignSelf: "stretch"
    }}>
        <LinearGradient style={{
            alignItems: "center",
            justifyContent: "center",
            width: buttonWidth,
            height: 120,
            paddingTop: 20,
            paddingBottom: 10,
            paddingHorizontal: 5,
            borderTopLeftRadius: buttonWidth / 6,
            borderTopRightRadius: buttonWidth / 6,
            borderBottomLeftRadius: small ? buttonWidth / 6 : 0,
            borderBottomRightRadius: small ? buttonWidth / 6 : 0
        }}
                        colors={backgroundColor}

        >
            <IconImageM width={50}
                        style={{
                            // position: "absolute",
                            // opacity: (activityDone ? 0.2 : 1)
                        }}
                        uri={activityTemplate?.icon}/>
            <TextM fontSize={14}
                   wrap
                   fontWeight="Bold"
                   style={{textAlign: "center", marginTop: 5}}
                   color={textColor}>{activityTemplate?.title}</TextM>
            {/*{doneActivities?.length === 0 && <FontAwesome5 color={theme.colors.white}*/}
            {/*                                         style={{*/}
            {/*                                             position: "absolute",*/}
            {/*                                             top: 8,*/}
            {/*                                             left: 8,*/}
            {/*                                         }}*/}
            {/*                                         size={12}*/}
            {/*                                         name="calendar"/>}*/}
            {sameActivitiesCount > 0 &&
                <DoneChip count={sameActivitiesCount} style={{position: "absolute", top: 10, right: 10}}/>}

            {activityPlanned?.is_late && <Badge size={10}
                                                style={{
                                                    backgroundColor: theme.colors.warning,
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0
                                                }}
            />}
        </LinearGradient>
        {!small && <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: 15,
            paddingBottom: 10,
            paddingTop: 5,
            backgroundColor: theme.colors.white,
            width: buttonWidth,
            borderTopWidth: 1,
            borderColor: theme.colors.lightMore,
            borderBottomLeftRadius: buttonWidth / 6,
            borderBottomRightRadius: buttonWidth / 6
            // height: "100%",
        }}>


            <View style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // bottom: 2,
                width: buttonWidth
                // paddingTop: 15
                // paddingBottom: 5
            }}>
                {/*<IconImageM*/}
                {/*    // uri={challenge?.school_subject?.icon}*/}
                {/*    uri={schoolSubject ? schoolSubject?.icon : challenge?.icon}*/}
                {/*    width={16}*/}
                {/*    style={{*/}
                {/*        marginRight: 5,*/}
                {/*        marginBottom: 0*/}
                {/*    }}/>*/}
                <View style={{
                    // flex: 1,
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    flexDirection: "row"
                }}>
                    <TextM fontWeight="SemiBold"
                           color={theme.colors.link}
                           style={{textAlign: "center"}}
                           wrap
                           fontSize={12}>{schoolSubject ? "Mes devoirs" : challenge?.title}</TextM>
                </View>


            </View>

            <TextM fontSize={11}
                   wrap
                   fontWeight="Medium"
                   style={{textAlign: "center"}}
                   color={theme.colors.link}>{activitySchoolSubject?.name}</TextM>
        </View>}
    </View>
}

const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityPlannedButtonContent)