import React, { useContext, useEffect } from "react";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import { ScrollView, View } from "react-native";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import { interviewReportService } from "@data/services/interviewReportService";
import { useApiStatus } from "@common/hooks/useApiStatus";
import { ActivityIndicator } from "react-native-paper";
import { theme } from "@common/theme/theme";
import liveIcon from "../../../assets/icons/generic/live.png";
import activityIcon from "../../../assets/icons/generic/refresh.png";
import giftIcon from "../../../assets/icons/generic/gift.png";
import TextM from "@common/components/text/TextM";
import { EngagementScoreComponent } from "../live/LiveReportsScreen";
import { IconImageM } from "@common/components/icons/IconsM";

export const formatEngagementScoreData = (
  assiduityScore,
  interactionScore,
  productivityScore
) => ({
  assiduity: {
    score: assiduityScore,
    score_max: 40,
  },
  interaction: {
    score: interactionScore,
    score_max: 30,
  },
  productivity: {
    score: productivityScore,
    score_max: 30,
  },
});
export const InterviewStatisticsCard = (props) => {
  const dimensions = useContext(DimensionsContext);
  const { statistics } = props;

  // console.log(statistics)

  const renderStatCard = (item) => (
    <View
      style={{
        flexDirection: "column",
        // flex: 1,
        paddingVertical: 15,
        paddingHorizontal: 15,
        marginBottom: 20,
      }}
    >
      <View
        style={{
          alignItems: "center",
          flex: 1,
        }}
      >
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            flex: 1,
          }}
        >
          <TextM
            color={theme.colors.primary}
            wrap
            fontSize={20}
            style={{
              marginBottom: 5,
              textAlign: "center",
            }}
          >
            {item.name}
          </TextM>
          <TextM
            style={{
              textAlign: "center",
            }}
            fontSize={25}
            fontWeight={"Bold"}
            wrap
          >
            {item.value}
          </TextM>
          {item?.additionalComponent}
        </View>
      </View>
    </View>
  );

  const engagementScores = formatEngagementScoreData(
    statistics?.["average_assiduity_score"]?.value?.result,
    statistics?.["average_interaction_score"]?.value?.result,
    statistics?.["average_productivity_score"]?.value?.result
  );

  return (
    <View
      style={{
        width: "100%",
        // paddingBottom: 100
        // height: dimensions.height - 220
      }}
      contentContainerStyle={
        {
          // flex: dimensions.width > 800 ? 1 : null
        }
      }
    >
      <View
        style={{
          // flexDirection: 'column',
          // flex: 1,
          width: IS_WEB ? null : dimensions.width - 20,
          justifyContent: IS_WEB ? "space-between" : "center",
          // alignItems: IS_WEB ? 'stretch' : 'center',
          // flexWrap: IS_WEB ? 'wrap' : 'nowrap',
          // backgroundColor: 'red'
        }}
      >
        {renderStatCard({
          name: "Présence en Live",
          value: `${statistics?.["live_reports"]?.value} / ${statistics?.["total_live_reports"]?.value}`,
        })}
        {renderStatCard({
          name: "Score d'engagement en Live",
          value: `${statistics?.["average_engagement_score"]?.value?.result} / 100`,
          additionalComponent: (
            <EngagementScoreComponent
              totalScore={
                statistics?.["average_engagement_score"]?.value?.result
              }
              scores={engagementScores}
              style={{
                // backgroundColor: 'red',
                // marginTop: 15
                flex: 1,
                // height: 300
              }}
              width={"100%"}
              disableScoreButton
              forceVerticalDetails
            />
          ),
        })}
        {renderStatCard({
          name: "Jours actifs hors Live",
          value: `${statistics?.["no_live_active_days_count"]?.value} / ${statistics?.["no_live_total_days_count"]?.value}`,
        })}
        {renderStatCard({
          name: "Nombre d'activités hors Live",
          value: `${statistics?.["no_live_activities_count"]?.value}`,
        })}
      </View>
    </View>
  );
};
const StatisticsStep = (props) => {
  const {
    studentId,
    control,
    goToNextStep,
    register,
    errors,
    serverError,
    setFormValues,
    field,
  } = props;
  // console.log(studentId)
  const dimensions = useContext(DimensionsContext);

  const statistics = props.interviewStats;
  //
  // console.log(engagementScores)

  useEffect(() => {
    props.apiRequest(
      interviewReportService.getInterviewStatistics,
      { studentId: studentId },
      {},
      {
        start_date: control._formValues?.start_date,
        end_date: control._formValues?.end_date,
      }
    );
  }, []);

  useApiStatus(
    interviewReportService.getInterviewStatistics, // api service
    null, // success message on toast (string or null)
    false, // error message on toast (true) or keep it in redux state (false)
    (data) => {
      // console.log(data)
      field.onChange(data.statistics);
      setFormValues({
        ...control._formValues,
        [field?.name]: data.statistics,
      });
    } // success callback
  );
  return (
    <ScrollView
      style={{
        width: dimensions.width,
        alignSelf: "center",
        // marginVertical: 20,
        ...props.style,
      }}
    >
      {props.getInterviewStatisticsRequest ? (
        <ActivityIndicator color={theme.colors.primary} />
      ) : (
        <InterviewStatisticsCard statistics={statistics} />
      )}
    </ScrollView>
  );
};

const mapStateToProps = (state) => {
  return {
    getInterviewStatisticsRequest: state.api.getInterviewStatistics?.request,
    interviewStats: state.data.interviewReports?.interviewStats,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsStep);
