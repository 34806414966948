import axios from "axios"



function quizzQuestions() {
    return axios.get(`api/v1/quizz`)
}

function initQuizzSubmit({}, values) {
    return axios.post(`api/v1/quizz/init`, values)
}

function confirmQuizzSubmit({quizz_submit_id}, values) {
    return axios.post(`/api/v1/quizz/${quizz_submit_id}/confirm`, values)
}

export const quizzService = {
    quizzQuestions,
    initQuizzSubmit,
    confirmQuizzSubmit
}
