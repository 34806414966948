import React, {useContext, useEffect} from 'react';
import {Platform, RefreshControl, ScrollView, StatusBar, View} from "react-native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {authService} from "@data/services/authService";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {useNavigation} from "@react-navigation/native";
import CompleteProfileCard from "./profile/CompleteProfileCard";
import {SubscriptionStateCard} from "./subscription/SubscriptionStateCard";
import LogoutButton from "@common/account/LogoutButton";
import {ProfileSection} from "./ProfileSection";
import {ProfileInfo} from "../social/ProfileInfo";
import {ActivitiesStatistics} from "../progress/ActivitiesStatistics";
import StatisticsList from "@common/components/statistics/StatisticsList";
import ChooseTitleModal from "./ChooseTitleModal";
import {SkillSetTitleButton} from "./SkillSetTitleButton";
import {skillSetsService} from "@data/services/skillSetsService";
import ValidateEmailActionCard from "@common/account/ValidateEmailActionCard";
import CustomizableAvatar from "./CustomizableAvatar";
import TextButtonM from "@common/components/button/TextButtonM";


const MyAccountScreen = (props) => {

    const navigation = useNavigation()
    const [chooseTitleModalVisible, setChooseTitleModalVisible] = React.useState(false);
    const showChooseTitleModal = () => setChooseTitleModalVisible(true);
    const hideChooseTitleModal = () => setChooseTitleModalVisible(false);

    useEffect(() => {
        // hideModal()

        // console.log(props.avatars)
    }, []);


    const ability = useContext(AbilityContext)

    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
            // paddingTop: 5
        }}>

            <ScrollView refreshControl={<RefreshControl
                refreshing={!!props.getUserRequest}
                onRefresh={() => {
                    props.apiRequest(authService.getUser)
                    props.apiRequest(skillSetsService.getSkillSets)
                }}
            />}
                        style={{
                            width: "100%"
                        }}
                        contentContainerStyle={{
                            alignItems: 'center'
                            // paddingTop: 5
                        }}>

                <ValidateEmailActionCard/>
                <CompleteProfileCard/>

                <ProfileSection title={props.user?.display_name} style={{
                    paddingBottom: 20,
                    paddingTop: 20,
                    width: "100%"
                }}>
                    <ProfileInfo user={props.user} showEmail={false}
                                 showSchoolSubjects={ability.can('read', 'my_successes')}/>

                    {ability.can('read', 'my_successes') && <>
                        <SkillSetTitleButton
                            skillSet={props.user?.skill_sets?.find(s => s?.id === props.user?.title_skill_set_id)}
                            style={{
                                width: dimensions?.width,
                                marginTop: 10
                            }}
                            withChampion={false}
                            withIcon
                            onPress={showChooseTitleModal}
                        />

                        <StatisticsList student={props.user} studentId={null} style={{marginTop: 10}}/>

                        {/*{props?.user?.profile_completion === 100 &&*/}
                        {/*    <TextM style={{marginTop: 5}} fontWeight="Light">Profil complété <Checked size={14}/></TextM>}*/}
                    </>}
                    <View style={{
                        position: "absolute",
                        top: 15,
                        right: 15
                    }}>
                        <CustomizableAvatar user={props.user}/>
                    </View>

                    {ability.can("read", "my_students") && <TextButtonM onPress={() => {
                        navigation.push("tutoring-invoices")
                    }}
                                                                        style={{
                                                                            paddingHorizontal: 4
                                                                        }}
                                                                        fontWeight={"Bold"}
                                                                        label="MES FACTURES"/>}

                </ProfileSection>

                {ability.can('read', 'my_successes') &&
                    <ProfileSection style={{paddingVertical: 0, marginTop: 0, width: "100%"}}>
                        <SubscriptionStateCard user={props.user}
                                               style={{
                                                   width: dimensions?.width,
                                                   alignSelf: "center"
                                               }}/>
                    </ProfileSection>}

                {ability.can('read', 'my_successes') &&
                    <ProfileSection title={"Mes statistiques"} style={{width: "100%"}}>
                        <ActivitiesStatistics style={{marginTop: 25, marginBottom: 15}} user={props.user}/>
                    </ProfileSection>}

                {ability.can('read', 'my_successes') && <ProfileSection title={"Classes"} style={{width: "100%"}}>

                    <TextM wrap>Partage ton activité avec tes professeurs</TextM>
                    <FilledButtonM mode="outlined"
                                   style={{
                                       marginTop: 20,
                                       marginHorizontal: 5,
                                       alignSelf: Platform.OS !== 'web' ? 'stretch' : 'center'
                                   }}
                                   onPress={() => {
                                       navigation.push("join-classroom")
                                   }}
                                   label="Rejoindre une classe"/>

                    <FilledButtonM mode="outlined"
                                   style={{
                                       marginTop: 10,
                                       marginHorizontal: 5,
                                       alignSelf: Platform.OS !== 'web' ? 'stretch' : 'center'
                                   }}
                                   onPress={() => {
                                       navigation.push("join-school")
                                   }}
                                   label="Rejoindre une école"/>

                </ProfileSection>}

                <ProfileSection title={"Parrainage"} style={{width: "100%"}}>

                    <TextM wrap>Prime de {props.user?.referral_commission_amount ?? 30}€ pour chaque parrainage réussi
                        !</TextM>
                    <TextM fontSize={10} fontWieght={"Light"} wrap>La prime est gagnée lorsque l'élève parrainé
                        active son abonnement</TextM>
                    <FilledButtonM mode="outlined"
                                   style={{
                                       marginTop: 20,
                                       marginHorizontal: 5,
                                       alignSelf: Platform.OS !== 'web' ? 'stretch' : 'center'
                                   }}
                                   onPress={() => {
                                       navigation.push("sponsorship")
                                   }}
                                   label="Je parraine"/>
                </ProfileSection>

                {/*{ability.can('read', 'my_friends') && <ProfileSection title={"Amis"}>*/}
                {/*    <TextM>Bientôt disponible</TextM>*/}
                {/*</ProfileSection>}*/}

                <LogoutButton/>

                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else
                                                  navigation.replace("menu")
                                          }}
                                          label={"Retour"}/>}


            </ScrollView>

            <ChooseTitleModal visible={chooseTitleModalVisible}
                              onDismiss={hideChooseTitleModal}/>
            {/*            <ChooseAvatarModal visible={chooseAvatarModalVisible}
                               onDismiss={hideChooseAvatarModal}/>*/}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}


const mapStateToProps = state => {
    return {
        isResendingEmail: state.api.emailResend?.request,
        resentEmail: state.api.emailResend?.success,
        resendEmailError: state.api.emailResend?.error,

        loggingOut: state.api.revokeSanctumToken?.request,
        loggedOut: state.api.revokeSanctumToken?.success,
        error: state.api.revokeSanctumToken?.error,

        unsubscribeFromNotificationsRequest: state.api.unsubscribeFromNotifications?.request,
        unsubscribeFromNotificationsSuccess: state.api.unsubscribeFromNotifications?.success,
        unsubscribeFromNotificationsError: state.api.unsubscribeFromNotifications?.error,

        user: state.data.currentUser?.object,
        expoToken: state.data.session?.object?.expo_token,

        getUserRequest: state.api.getUser?.request,

        successes: state.data.staticData?.successes,
        avatars: state.data.staticData?.avatars
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountScreen)