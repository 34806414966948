import React, {useContext} from "react"
import {ModalM} from "@common/components/modal/ModalM";
import TextM from "@common/components/text/TextM";
import {View} from "react-native";
import {Button} from "react-native-paper";
import {theme} from "@common/theme/theme";
import * as Clipboard from 'expo-clipboard';
import {DimensionsContext} from "@common/utils/mobileUtils";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

export function ClassroomInviteModal(props) {

    const {handleShowModal, isOpen, classroom} = props
    const dimensions = useContext(DimensionsContext)
    const data = [
        {key: 'Se rendre sur l\'application mobile Masteur et créer un compte ou se connecter à un compte existant.'},
        {key: 'Se rendre sur l\'écran "Mon Profil" et appuyer sur "Rejoindre une classe".'},
        {
            key: <><TextM>Entrer le code de classe </TextM>
                <TextM fontWeight={"Bold"}
                       style={{
                           letterSpacing: 10,
                           paddingLeft: 5
                       }}>
                    {classroom?.code}
                </TextM></>
        },
    ]
    return <ModalM visible={isOpen} onDismiss={handleShowModal}
                   style={{
                       //width: IS_WEB && dimensions.width > 800 ? "50%" : "90%",
                       maxHeight: "80%",
                       padding: 15,
                       alignSelf: 'center'
                   }}>

        <TextM fontWeight={"Bold"} fontSize={18} style={{textAlign: 'center'}}>
            Inviter
        </TextM>
        <View>

            <TextM wrap fontWeight={"Bold"} fontSize={18} style={{
                textAlign: 'center'
            }}>Envoyez le code de classe à vos élèves</TextM>

            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                backgroundColor: theme.colors.lighter,
                paddingHorizontal: 20,
                paddingVertical: 10,
                borderRadius: 10,
                marginVertical: 10
            }}>
                <TextM fontWeight={"Bold"}
                       fontSize={22}
                       style={{
                           letterSpacing: 10
                       }}>{classroom?.code}</TextM>
                <Button mode={'outlined'}
                        onPress={() => {
                            Clipboard.setString(classroom?.code)
                        }}
                        style={{
                            borderRadius: 15,
                            borderColor: theme.colors.primary
                        }}
                >
                    Copier
                </Button>
            </View>

            <TextM style={{
                paddingVertical: 10
            }}>Instructions :</TextM>
            {data.map((el, index) => (
            <View style={{flexDirection: "row", alignItems: "flex-start", paddingVertical: 5,            }}>
                <FontAwesome5 name={"circle"}  solid size={6} color={theme.colors.grey} style={{marginTop: 4}} />
                <TextM wrap key={index} style={{
                    paddingHorizontal: 10,
                }}>
                    {el.key}
                </TextM>
            </View>
            ))}
        </View>
    </ModalM>
}