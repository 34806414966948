import React, {useContext, useEffect, useState} from 'react';
import {Dimensions, Platform} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {IconButton} from "react-native-paper";
import {theme} from "@common/theme/theme";
import TitleM from "@common/components/text/TitleM";
import {Tabs, TabScreen} from "react-native-paper-tabs";
import MetricsFilter from "./MetricsFilter";
import GrowthTabScreen from "./GrowthTabScreen";
import {backOfficeService} from "@data/services/backOfficeService";
import moment from "moment";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TutorsStatsTabScreen from "./TutorsStatsTabScreen";
import {useApiStatus} from "@common/hooks/useApiStatus";
import EngagementTabScreen from "./EngagementTabScreen";
import QualityTabScreen from "./QualityTabScreen";

const MetricsTabNavigator = (props) => {
    const {navigation, route} = props
    const {initialTab} = route.params;
    const dimensions = useContext(DimensionsContext)
    const [urlParams, setUrlParams] = useState({
        period: 'weekly',
        reference_date: moment().subtract(1, "day").format("YYYY-MM-DD")
    })

    useEffect(() => {
        props.apiRequest(backOfficeService.getApplicationStatistics, {}, {}, urlParams)
    }, [urlParams]);

    useApiStatus(
        backOfficeService.getApplicationStatistics, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )
    const tabs = [
        {
            name: "Croissance",
            component: GrowthTabScreen,
        },
        {
            name: "Tuteurs",
            component: TutorsStatsTabScreen,
        },
        {
            name: "Engagement",
            component: EngagementTabScreen,
        },
        {
            name: "Qualité",
            component: QualityTabScreen,
        },
    ]


    return <>
        <HeaderContainerM style={{justifyContent: 'flex-start'}}>
            <IconButton
                icon={"arrow-left"}
                iconColor={theme.colors.primary}
                size={32}
                style={{}}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
            <TitleM>Métriques</TitleM>
        </HeaderContainerM>
        <MetricsFilter setUrlParams={setUrlParams} urlParams={urlParams}/>
        <Tabs
            defaultIndex={initialTab ?? 1}
            style={{
                backgroundColor: theme.colors.white,
                width: Platform.OS === 'web' ? Dimensions.get("window").width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
            disableSwipe={true}
        >
            {tabs.map((tab) => {
                return <TabScreen label={tab.name}
                                  key={tab.name}
                    // icon={<TextM>t</TextM>}
                >
                    {React.createElement(tab.component, {
                        name: tab?.name,
                        tab: tab?.tabIndex,
                        urlParams: tab?.urlParams
                    })}
                </TabScreen>
            })}

        </Tabs>
    </>
}
const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
}

export default connect(mapStateToProps, mapDispatchToProps)(MetricsTabNavigator)