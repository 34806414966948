import React, {useContext} from 'react';
import {Platform, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "../../theme/theme";
import FilledButtonM from "../button/FilledButtonM";
import TextM from "../text/TextM";
import {DimensionsContext, IS_WEB} from "../../utils/mobileUtils";
import CircularProgress from "react-native-circular-progress-indicator/src/circularProgress/index";
//import CircularProgress from 'react-native-circular-progress-indicator';
import ChampionBubble from "../../../student/champions/ChampionBubble";
import ProgressStepperHeader from "@common/components/stepper/ProgressStepperHeader";


const ProgressStepper = (props) => {
    const dimensions = useContext(DimensionsContext)

    // ** Component props
    const {
        navigation,
        noNavigation = false,
        steps,
        exitHandler,
        champion,
        championLabels,
        hideProgressBar = false,
        submitButtonText = "Terminer",
        currentStep,
        setCurrentStep,
        onNextButtonPress,
        submitted,
        headerRightComponent,
        submittedMessage,
        previousRouteName = "menu",
        exitRouteName = "menu",
        exitWarning,
        noPrevious,
        noGoBack,
        circularProgress,
        circularProgressValue,
        exitWarningMessage,
        track = false,
        champions
    } = props

    const isFirstStep = currentStep === 1
    const isLastStep = currentStep === steps?.length

    const goToPreviousStep = () => {
        if (!isFirstStep) setCurrentStep(cs => cs - 1)
    }

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center',
            justifyContent: 'flex-start'
        }}>
            {!noNavigation && <ProgressStepperHeader navigation={navigation}
                                                     noNavigation={noNavigation}
                                                     track={track}
                                                     currentStep={currentStep}
                                                     exitWarning={exitWarning}
                                                     exitHandler={exitHandler}
                                                     noPrevious={noPrevious}
                                                     noGoBack={noGoBack}
                                                     previousRouteName={previousRouteName}
                                                     exitRouteName={exitRouteName}
                                                     countSteps={steps?.length}
                                                     hideProgressBar={hideProgressBar}
                                                     headerRightComponent={headerRightComponent}
                                                     goToPreviousStep={goToPreviousStep}
                                                     exitWarningMessage={exitWarningMessage}
                                                     submitted={submitted}
            />}

            {!submitted && (champion || champions?.some(x => x !== null)) &&
                <ChampionBubble name={champion?.name ?? champions[currentStep - 1]?.name}
                                variant={champion?.variant ?? champions[currentStep - 1]?.variant}
                                championPosition="left"
                                texts={[championLabels[currentStep - 1]]}
                                style={{marginTop: -20}}
                />}

            {!submitted ? steps?.[currentStep - 1]?.content : <View style={{
                flex: 1,
                // backgroundColor: '#fff',
                alignItems: 'center',
                justifyContent: 'center'
                // paddingBottom: 100
            }}>
                {circularProgress ?
                    IS_WEB ?
                        <TextM color={theme.colors.success} fontWeight={"Bold"}>{circularProgressValue + "%"}</TextM> :
                        <CircularProgress
                            title={circularProgressValue + "%"}
                            value={circularProgressValue}
                            radius={40}
                            activeStrokeColor={theme.colors.success}
                            inActiveStrokeColor={theme.colors.success}
                            activeStrokeWidth={10}
                            inActiveStrokeWidth={10}
                            inActiveStrokeOpacity={0.1}
                            textColor={theme.colors.success}
                            titleStyle={{fontFamily: "Montserrat-Bold"}}
                            titleFontSize={16}
                            // titleFontSize={WINDOW_WIDTH*6/36}
                            showProgressValue={false}
                        /> : <ActivityIndicator animating={true}
                                                style={{
                                                    alignSelf: "center",
                                                    justifySelf: "center",
                                                    marginTop: IS_WEB ? 50 : -50,
                                                    marginBottom: 10
                                                }}
                                                size="large"
                                                color={theme.colors.primary}/>}
                <TextM fontWeight="Bold"
                       color={theme.colors.grey}
                       style={{
                           marginTop: 10
                       }}
                       fontSize={22}>Chargement en cours...</TextM>
                {!!submittedMessage && submittedMessage !== "" && <TextM fontWeight="Regular"
                                                                         color={theme.colors.light}
                                                                         style={{
                                                                             marginTop: 5
                                                                         }}
                                                                         fontSize={16}>{submittedMessage}</TextM>}
            </View>}
            {(!submitted && (!steps?.[currentStep - 1]?.hideNextButton)) && <View style={{
                backgroundColor: theme.colors.whiteTransparentMore,
                paddingTop: Platform.OS !== 'web' ? 10 : null,
                paddingBottom: Platform.OS === 'ios' ? 20 : 5,
                position: "absolute",
                bottom: 0,
                width: "100%",
                alignItems: 'center',
                alignSelf: 'center',
            }}>
                <FilledButtonM color={isLastStep ? theme.colors.success : theme.colors.primary}
                               loading={submitted}
                               onPress={IS_WEB ? () => {
                                   window.scrollTo(0, 0)
                                   onNextButtonPress()
                               } : onNextButtonPress}
                               style={{
                                   width: IS_WEB && dimensions.width > 800 ? dimensions.width / 1.2 : dimensions.width - 20,
                                   alignSelf: 'center',
                                   marginTop: Platform.OS !== 'web' ? null : 15,
                                   marginBottom: Platform.OS !== 'web' ? null : 15
                                   // bottom: 15
                               }}
                               label={isLastStep ? submitButtonText : "Suivant"}/>
            </View>}

        </View>
    )
}

export default ProgressStepper