import {suggestionsConstants} from "../../../constants/suggestionsConstants";
import moment from "moment";
import {authConstants} from "../../../constants/authConstants";
import {authService} from "../../../services/authService";
import {apiConstants} from "../../../constants/apiConstants";

const initialState = {}

export default function suggestions(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_401:
        case authService.logout.name + apiConstants._SUCCESS:
        case authService.revokeSanctumToken.name + apiConstants._SUCCESS:
        case authService.deleteAccount.name + apiConstants._SUCCESS:
        case suggestionsConstants.RESET_SUGGESTIONS:
            return initialState

        case suggestionsConstants.HOMEWORK_SUGGESTION:
            return {
                ...state,
                last_homework_suggestion: moment().format("YYYY-MM-DD HH:mm:ss")
            }

        case suggestionsConstants.ADDITIONAL_SUGGESTION:
            return {
                ...state,
                last_additional_suggestion: moment().format("YYYY-MM-DD HH:mm:ss")
            }



        default:
            return state
    }
}
