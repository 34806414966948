const noses = {
    nose1: true,
    nose2: true,
    nose3: true,
};
export const nosesList = [
    {
        name: "nose1",
    },
    {
        name: "nose2",
    },
    {
        name: "nose3",
    }
];
export default noses;
