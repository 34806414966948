import React, {Fragment, useContext, useRef} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useNavigation} from "@react-navigation/native";
import {interviewReportService} from "@data/services/interviewReportService";
import {PaginatedTableM} from "../../admin/PaginatedTableM";
import {IconButton} from "react-native-paper";
import TitleM from "@common/components/text/TitleM";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TextButtonM from "@common/components/button/TextButtonM";
import {secondsToHMS} from "@data/utility/Utils";
import {SchoolSubjectBadge} from "../../tutor/students/ProfilDetails";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {EngagementScoreComponent} from "../../admin/live/LiveReportsScreen";

export const PrioritizedSchoolSubjectsWithGrades = (props) => {
    const {prioritizedSchoolSubjects, textStyle, fontSize, iconWidth, badgeStyle} = props

    return prioritizedSchoolSubjects?.map((schoolSubject, index) => <View style={{
            alignItems: "center",
            flexDirection: "row"
        }}
                                                                          key={index}>
            <SchoolSubjectBadge schoolSubject={schoolSubject} iconWidth={iconWidth} fontSize={fontSize} style={badgeStyle}/>
            <TextM style={{marginLeft: 5, ...textStyle}} color={theme.colors.primary}
                   fontSize={fontSize}>{schoolSubject?.last_grades?.map((grade) => grade?.value)?.join(", ")}</TextM>
        </View>
    )

}

export const AssimilationStatsComponent = (props) => {
    const {stats, fontSize, iconWidth, style, progressIconSize, badgeStyle, forAdmin = false} = props
    // console.log(stats)
    // console.log(props.schoolSubjects)

    return Object.entries(stats ?? {}).map(([key, value]) => {
        const schoolSubject = props.schoolSubjects?.find(subject => subject?.name === key)
        const results = {
            understanding: {
                global: value?.average_school_subject_global_understanding?.value?.result,
                progress: value?.average_school_subject_understanding_progress?.value?.result,
                label: "Comp."
            },
            sense: {
                global: value?.average_school_subject_global_sense?.value?.result,
                progress: value?.average_school_subject_sens_progress?.value?.result,
                label: "Sens"
            },
            pleasure: {
                global: value?.average_school_subject_global_pleasure?.value?.result,
                progress: value?.average_school_subject_pleasure_progress?.value?.result,
                label: "Plai."
            }
        }
        // console.log(results)

        return forAdmin ? <View key={key} style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 5,
            // backgroundColor: "red"
            ...style
        }}>
            <SchoolSubjectBadge key={schoolSubject?.id} schoolSubject={schoolSubject} fontSize={fontSize}
                                iconWidth={iconWidth} style={badgeStyle}/>
            <TextM style={{marginHorizontal: 3}} color={theme.colors.primary} fontSize={fontSize ?? 10}>:</TextM>
            {
                Object.values(results).map((value, index) => {
                    return <Fragment key={index}>
                        <TextM style={{marginHorizontal: 3}} color={theme.colors.primary}
                               fontSize={fontSize ?? 10}>{value?.label} </TextM>
                        <TextM style={{marginHorizontal: 3}} color={theme.colors.primary}
                               fontSize={fontSize ?? 10}>{value?.global}</TextM>
                        {value?.progress !== 0 &&
                            <FontAwesome5 name={value?.progress > 0 ? "arrow-alt-circle-up" : "arrow-alt-circle-down"}
                                          color={value?.progress > 0 ? theme.colors.success : theme.colors.error}
                                          size={progressIconSize ?? 12}/>}
                        <TextM style={{marginHorizontal: 3}} color={theme.colors.primary}
                               fontSize={fontSize ?? 10}>|</TextM>
                    </Fragment>
                })
            }
        </View> : <Fragment key={key}>
            <SchoolSubjectBadge schoolSubject={schoolSubject} style={{marginBottom: 10, maxWidth: 300}}
                                key={schoolSubject?.id} fontSize={17} iconWidth={40}/>
            <View style={{
                flexDirection: "row",
                // alignItems: "center",
                marginBottom: 15,
                justifyContent: "space-between",
                // backgroundColor: "red",
                width: "80%"
            }}>
                {Object.values(results).map((value, index) => <View key={index}
                                                                    style={{
                                                                        alignItems: "center",
                                                                        flex: 1
                                                                    }}>
                        <TextM fontSize={16} fontWeight={'Bold'} wrap>
                            {value?.label}
                        </TextM>

                        <View style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 5
                        }}>
                            <TextM fontSize={14} fontWeight={'Medium'} wrap style={{marginRight: 3}}>
                                {(value?.global ?? "-") + "/7"}
                            </TextM>
                            {value?.progress !== 0 &&
                                <FontAwesome5
                                    name={value?.progress > 0 ? "arrow-alt-circle-up" : "arrow-alt-circle-down"}
                                    color={value?.progress > 0 ? theme.colors.success : theme.colors.error}
                                    size={16}/>}
                        </View>

                    </View>
                )}

            </View>
        </Fragment>
    })
}

const InterviewReportsScreen = (props) => {
    const dimensions = useContext(DimensionsContext)
    const flatListRef = useRef()
    const navigation = useNavigation()

    const {studentId} = props.route.params

    useApiStatus(
        interviewReportService.getStudentInterviewReports, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiIdle(interviewReportService.getStudentInterviewReports)
        } // success callback
    )

    const columns = [
        {
            name: 'Période',
            style: {
                width: 180, // maxWidth: 80,
                marginRight: 40
            },
            selector: 'created_at',
            sortable: true,
            cell: row => <TextM color={theme.colors.primary} fontWeight={"medium"}>{row?.start_date + " -> " + row?.end_date ?? "-"}</TextM>
        },
        {
            name: 'Résultats scolaires',
            style: {
                width: 140, // maxWidth: 80,
                marginRight: 40
            },
            selector: 'prioritized_school_subjects',
            sortable: true,
            cell: row => props.prioritizedSchoolSubjects?.map((schoolSubject, index) => <View key={index} style={{
                    alignItems: "center",
                    flexDirection: "row",
                    marginBottom: 5
                }}>
                    <SchoolSubjectBadge schoolSubject={schoolSubject}/>
                    <TextM style={{marginLeft: 5}}
                           color={theme.colors.primary}>{schoolSubject?.last_grades?.map((grade) => grade?.value)?.join(", ")}</TextM>
                </View>
            )
        },
        {
            name: 'Score d\'engagement',
            style: {
                width: 700, // maxWidth: 80,
                marginRight: 40
            },
            selector: 'assimilation',
            sortable: true,
            cell: row => {
                const engagementScores = {
                    assiduity: {
                        score: row?.statistics?.['average_assiduity_score']?.value?.result,
                        score_max: 40,
                    },
                    interaction: {
                        score: row?.statistics?.['average_interaction_score']?.value?.result,
                        score_max: 30,
                    },
                    productivity: {
                        score: row?.statistics?.['average_productivity_score']?.value?.result,
                        score_max: 30,
                    },
                }
                return !row?.statistics ? "-" :
                    <EngagementScoreComponent totalScore={row?.statistics?.['average_engagement_score']?.value?.result}
                                              scores={engagementScores} disableScoreButton/>
            }
        },
        {
            name: 'Message au parent',
            style: {
                width: 120,
                // maxWidth: 80,
                marginRight: 40
            },
            selector: 'parent_message',
            sortable: true,
            cell: row => <TextButtonM onPress={() => {
                navigation.push("ir", {
                    id: row?.id
                })
            }} label={row?.parent_message ?? "-"} style={{
                maxWidth: 120
            }} numberOfLines={2}/>
        },
        {
            name: 'Message au tuteur',
            style: {
                width: 120, // maxWidth: 80,
                marginRight: 40
            },
            selector: 'tutor_message',
            sortable: true,
            cell: row => <TextButtonM onPress={() => {
                navigation.push("ir", {
                    id: row?.id
                })
            }} label={row?.tutor_message ?? "-"} style={{
                maxWidth: 120
            }} numberOfLines={2}/>
        },
        {
            name: 'Autres commentaires',
            style: {
                width: 120, // maxWidth: 80,
                marginRight: 40
            },
            selector: 'internal_comment',
            sortable: true,
            cell: row => <TextButtonM onPress={() => {
                navigation.push("ir", {
                    id: row?.id
                })
            }} label={row?.internal_comment ?? "-"} style={{
                maxWidth: 120
            }} numberOfLines={2}/>
        }
    ]

    const routeParams = {
        id: studentId
    }


    return <>
        <HeaderContainerM>
            <TitleM>Rapports périodiques</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
            {/*<FilledButtonM label={"Créer"}*/}
            {/*               icon={"plus"}*/}
            {/*               style={{width: null, margin: 10, marginLeft: 100}}*/}
            {/*               labelStyle={{fontSize: 12}}*/}
            {/*               onPress={() => {*/}
            {/*                   navigation.push("interview-report", {*/}
            {/*                       studentId: studentId*/}
            {/*                   })*/}
            {/*               }}/>*/}
        </HeaderContainerM>
        <PaginatedTableM data={props.interviewReports}
                         flatListRef={flatListRef}
                         columns={columns}
                         headerHeight={60}
                         requestService={interviewReportService.getStudentInterviewReports}
                         routeParams={routeParams}
        />
    </>
}
const mapStateToProps = state => {
    return {
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        interviewReports: state.data.interviewReports?.interviewReportsList,
        prioritizedSchoolSubjects: state.data.interviewReports?.prioritizedSchoolSubjects
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(InterviewReportsScreen)