import React, {useState} from "react";
import {TouchableOpacity, View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "../../theme/theme";

const DualIconButton = ({
                            icon1 = "clipboard-list",
                            icon2 = "book",
                            onSelectIcon1 = () => {
                            },
                            onSelectIcon2 = () => {
                            },
                            initialSelectedIcon = "icon1"
                        }) => {
    const [selectedIcon, setSelectedIcon] = useState(initialSelectedIcon);

    return (
        <View
            style={{
                flexDirection: "row",
                borderRadius: 10,
                overflow: "hidden",
                marginRight: 5
            }}
        >
            <TouchableOpacity
                onPress={() => {
                    setSelectedIcon("icon1");
                    onSelectIcon1();
                }}
                disabled={selectedIcon === "icon1"}
                style={{
                    paddingVertical: 6,
                    paddingHorizontal: 8,
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: selectedIcon !== "icon1" ? 0.5 : 1,
                    backgroundColor: theme.colors.primary
                }}
            >
                <FontAwesome5 name={icon1} color={theme.colors.white} size={14}/>
            </TouchableOpacity>
            <TouchableOpacity
                onPress={() => {
                    setSelectedIcon("icon2");
                    onSelectIcon2();
                }}
                disabled={selectedIcon === "icon2"}
                style={{
                    paddingVertical: 6,
                    paddingHorizontal: 8,
                    alignItems: "center",
                    justifyContent: "center",
                    opacity: selectedIcon !== "icon2" ? 0.5 : 1,
                    backgroundColor: theme.colors.primary
                }}
            >
                <FontAwesome5 name={icon2} size={14} color={theme.colors.white}/>
            </TouchableOpacity>
        </View>
    );
};

export default DualIconButton;
