import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {roomService} from "@data/services/roomService";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {convertMomentToFrench} from "../../AssignTutorAndLives";
import {backOfficeService} from "@data/services/backOfficeService";
import {RankingListItem} from "../../../student/social/RankingScreen";

const AddUsersToSessions = (props) => {

    const {room_id} = props.route.params
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const room = findItemInListWithId(room_id, props.rooms) ?? null
    const defaultValues = {}

    const formData = [
        {
            label: "Choisir les sessions ?",
            type: "select.multiple",
            name: "live_session_ids",
            options: room?.live_sessions
                ?.map(x => {
                    //console.log(x?.day_time)
                    return {
                        value: x?.id,
                        label: convertMomentToFrench(x),
                        description: x?.students_count === 0 ? "Aucun élève" : x?.students_count + " élèves : " + x?.students_list_string,
                        avatar: room?.tutor

                    }
                }),
            defaultValue: "",
            validationRules: {...validation.required()}
        },
        {
            label: "Ajouter des membres ?",
            name: "user_ids",
            type: "select.paginated",
            singleMode: false,
            service: backOfficeService.searchUserFormStep,
            additionalUrlParams: {
                roles: "student,tutor",
                light_data: 1
            },
            responseKey: "users",
            renderItem: (item, selected) => <RankingListItem user={item}
                                                             isHighlighted={selected}
                                                             withEmail
                                                             height={60}
            />,
            defaultValue: [],
            validationRules: {...validation.required()}
        },
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        //console.log(formValues)

    }, [formValues])

    const onSubmit = data => {
        if (props.addMembersRequest) return

        props.apiRequest(roomService.addMembersToSessions, {id: room_id}, data)

    }
    useApiStatus(
        roomService.addMembersToSessions, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(roomService.getRooms, {}, {}, {type: room?.type})
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("rooms")
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.addMembersRequest}
                         previousRouteName={"rooms"}
                         submittedMessage=""
                         serverError={props.addMembersError}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        tutors: state.data.tutors?.list,
        addMembersRequest: state.api.addMembersToSessions?.request,
        addMembersError: state.api.addMembersToSessions?.error,
        rooms: state.data.backOffice?.rooms,
        users: state.data.users?.usersList,
        usersFormStep: state.data?.users?.usersListFormStep,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AddUsersToSessions)
