import React, {useContext, useEffect} from 'react';
import {Platform, ScrollView, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {hasRole} from "@data/utility/ability";
import {authService} from "@data/services/authService";
import * as Notifications from "expo-notifications";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {ActivityIndicator} from "react-native-paper";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const DeleteAccountScreen = (props) => {

    const {navigation} = props
    const insets = useSafeAreaInsets();

    const hasStudents = hasRole(props.user, "tutor") && props.user?.students?.length > 0
    const isSubscribed = hasRole(props.user, "premium-student") || hasRole(props.user, "plus-student")
    const subscribedProduct = hasRole(props.user, "premium-student") ? "PREMIUM" : "PLUS"

    const loading = props.deleteAccount || props.unsubscribeFromNotificationsRequest

    const onPress = () => {
        DialogM.show({
            text1: 'Veux-tu vraiment fermer ton compte ?',
            text2: 'Toutes tes données seront supprimées dans un délai de 7 jours.',
            buttonText1: 'Annuler',
            buttonText2: 'Supprimer mon compte',
            onPressBack: () => {
                Notifications.dismissAllNotificationsAsync().then(() => {
                    if (props.expoToken)
                        props.apiRequest(
                            authService.unsubscribeFromNotifications,
                            {},
                            {expo_token: props.expoToken}
                        );
                    else {
                        props.apiRequest(authService.deleteAccount)
                    }
                });
            }
        })
        /*        alertPolyfill('Veux-tu vraiment fermer ton compte ?', 'Toutes tes données seront supprimées dans un délai de 7 jours.', [
                    {
                        text: 'Annuler',
                        onPress: () => {
                        },
                        style: 'cancel'
                    },
                    {
                        text: 'Supprimer mon compte', onPress: () => {
                            Notifications.dismissAllNotificationsAsync().then(() => {
                                if (props.expoToken)
                                    props.apiRequest(
                                        authService.unsubscribeFromNotifications,
                                        {},
                                        {expo_token: props.expoToken}
                                    );
                                else {
                                    props.apiRequest(authService.deleteAccount)
                                }
                            });
                        }
                    }
                ]);*/
    }

    useEffect(() => {
        if (props.unsubscribeFromNotificationsSuccess) {
            props.apiIdle(authService.unsubscribeFromNotifications)
            props.apiRequest(authService.deleteAccount)
        } else if (props.unsubscribeFromNotificationsError) {
            props.apiIdle(authService.unsubscribeFromNotifications)
            if (props.unsubscribeFromNotificationsError?.status === "failed") { // when server sends back "failed" status, the token probably does not exist
                props.apiRequest(authService.deleteAccount)
            } else {
                DialogM.show({
                    text1: 'Erreur', text2: props.unsubscribeFromNotificationsError.message
                })
                //alertPolyfill('Erreur', props.unsubscribeFromNotificationsError.message)
            }
        }
    }, [props.unsubscribeFromNotificationsSuccess, props.unsubscribeFromNotificationsError])


    useApiStatus(
        authService.deleteAccount, // api service
        null, // success message on toast (string or null)
        true // error message on toast (true) or keep it in redux state (false)
    )
    const dimensions = useContext(DimensionsContext)


    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            width: dimensions?.width,
            paddingTop: Platform.OS === "ios" ? insets.top : 0,
            paddingBottom: 20,
            // alignItems: "center",
            justifyContent: "center",
            alignSelf: "center"
        }}>
            {loading ? <ActivityIndicator/> : <ScrollView
                contentContainerStyle={{
                    paddingHorizontal: 20
                    // paddingTop: 5
                    // alignItems: 'center'
                }}>
                {/*<TitleM style={{textAlign: "left"}}> ton compte</TitleM>*/}
                {isSubscribed && <TextM wrap
                                        style={{textAlign: "justify", marginTop: 10}}>Ton abonnement
                    Masteur {subscribedProduct} est
                    encore valable. Si tu souhaites annuler ton abonnement, tu peux passer à Masteur BASIC pour arrêter
                    de payer : lorsque ton abonnement aura expiré, tu pourras toujours accéder à l'univers Masteur, mais
                    sans analyse de la qualité de ton travail et sans tuteur.</TextM>}
                {hasStudents ? <TextM wrap
                                      style={{textAlign: "justify", marginTop: 10}}>Tu ne peux pas supprimer ton compte
                        pour le moment, car tu es actuellement tuteur et tu as encore des élèves.</TextM>
                    : <>
                        <TextM wrap
                               style={{textAlign: "justify", marginTop: 10}}>Pour supprimer définitivement ton compte de
                            toutes
                            les applications et de tous les services Masteur, tu peux fermer ton compte toi-même
                            :</TextM>
                        <FilledButtonM mode="text"
                                       label="Fermer mon compte"
                                       onPress={onPress}
                                       style={{marginTop: 10, marginBottom: 50}}
                                       color={theme.colors.grey}/>
                        <TitleM style={{textAlign: "left"}}>Rouvrir mon compte</TitleM>
                        <TextM style={{textAlign: "justify"}}
                               wrap>Après avoir fermé ton compte, il sera supprimé définitivement dans un délai de 7
                            jours.</TextM>
                        <TextM style={{textAlign: "justify", marginTop: 10}}
                               wrap>Passé ce délai, tu ne pourras plus le réactiver (mais tu pourras toujours en créer
                            un autre).</TextM>
                    </>}

                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.navigate("settings", {initialTab: 2})
                                              else
                                                  navigation.replace("settings", {initialTab: 2})
                                          }}
                                          label={"Retour"}/>}
            </ScrollView>}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        deleteAccountRequest: state.api.deleteAccount?.request,
        unsubscribeFromNotificationsRequest: state.api.unsubscribeFromNotifications?.request,
        unsubscribeFromNotificationsSuccess: state.api.unsubscribeFromNotifications?.success,
        unsubscribeFromNotificationsError: state.api.unsubscribeFromNotifications?.error,

        user: state.data.currentUser?.object,
        expoToken: state.data.session?.object?.expo_token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountScreen)