import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import {StatisticsItem} from "./StatisticsItem";
import {DimensionsContext} from "../../utils/mobileUtils";
import ranking from "../../../../assets/icons/menus/ranking.png"
import xpSquare from "../../../../assets/icons/generic/xp_square.png"
import treesImage from "../../../../assets/icons/generic/tree.png"
import mealsImage from "../../../../assets/icons/generic/lunch-bag.png"
import {plr} from "@data/utility/Utils";
import {theme} from "../../theme/theme";
import React, {useContext} from "react";

function StatisticsList(props) {

    const {student, style} = props
    // const countActivities = student?.statistics?.prioritized_school_subjects?.activities + student?.statistics?.challenges?.activities
    const countTreesPurchases = student?.shop_purchases?.filter(p => p?.shop_product_id === 1)?.length
    const countMealsPurchases = student?.shop_purchases?.filter(p => p?.shop_product_id === 2)?.length

    // const treesImage = props.shopProducts?.find(sp => sp?.id === 1)?.icon_path
    // const mealsImage = props.shopProducts?.find(sp => sp?.id === 2)?.icon_path
    const dimensions = useContext(DimensionsContext)

    return (<View style={{
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            width: dimensions?.width,
            ...style
        }}>
            {/*<StatisticsItem source={target}*/}
            {/*                title={plr("activité", countActivities) + " " + plr("réalisée", countActivities)}*/}
            {/*    // count={0}*/}
            {/*                count={countActivities}*/}
            {/*/>*/}

            <StatisticsItem source={xpSquare}
                            color={theme.colors.accentDark}
                            title={plr("XP gagné", student?.xp)}
                            count={student?.xp}
            />

            <StatisticsItem source={ranking}
                            color={theme.colors.errorDark}
                            title="classement actuel"
                            count={student?.rank}
            />

            <StatisticsItem source={treesImage}
                            color={theme.colors.petalDarker}
                            title="arbres plantés"
                            count={countTreesPurchases}
            />

            <StatisticsItem source={mealsImage}
                            color={theme.colors.petalDarker}
                            title="repas offerts"
                            count={countMealsPurchases}
            />

            {/*{props.gems?.map(g => <StatisticsItem key={g?.id}*/}
            {/*                                uri={cleanMediaUrl(g?.icon)}*/}
            {/*                                title={plr("gemme", student?.statistics?.gems[g?.slug]) + " " + g?.name}*/}
            {/*                                count={student?.statistics?.gems[g?.slug] ?? 0}*/}
            {/*/>)}*/}
        </View>
    );
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
        gems: state.data.staticData?.gems,
        shopProducts: state.data.staticData?.shop_products

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsList)