import React, {useState} from "react";
import {theme} from "../../theme/theme";
import {
    TouchableOpacity
  } from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";


const ToggleIconButtonM = props => {

    const {iconName, defaultValue = true,style, onChange, size,name} = props

    const [value, setValue] = useState(defaultValue)
    const color = value ? theme.colors.link : theme.colors.light

    return <TouchableOpacity  style={style} onPress={() => {
        const newValue = !value
        setValue(newValue)
        onChange(newValue)
       
    }
    }>
        <FontAwesome5  name={iconName} color={color} solid size={size} style={{
            marginHorizontal: 10
        }}/>
    </TouchableOpacity>
}
export default ToggleIconButtonM;
