import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import TextM from "../components/text/TextM";
import {theme} from "../theme/theme";
import {DimensionsContext} from "../utils/mobileUtils";
import SuccessAnimation from "../animations/SuccessAnimation";

const GenericCongrats = props => {

    const {congratsTitle, congratsText} = props

    const successSize = 100
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 10,
        paddingHorizontal: 15,
        width: dimensions?.width
    }}>
        <View style={{height: 100}}/>

        <SuccessAnimation
            size={successSize}
            iconSize={successSize * 0.7}
            dotColor={theme.colors.success}
            backgroundColor={theme.colors.success}
            iconColor={"white"}
            dotSize={20}
            duration={2000}
            style={{
                // position: "absolute",
                // alignSelf: "center",
                marginBottom: successSize / 3
                // right: -successSize / 3
                // right: -30
            }}
            animatedLayerColor={"white"}
        />
        <TextM fontSize={22}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center"
                   // marginTop: 50
               }}
               color={theme.colors.primary}>
            {congratsTitle.toUpperCase()}
        </TextM>

        <TextM fontSize={18}
               wrap
               fontWeight="Regular"
               style={{
                   textAlign: "center",
                   marginTop: 40,
                   marginHorizontal: 30
               }}
               color={theme.colors.grey}>
            {congratsText}
        </TextM>

        <View style={{height: 100}}/>

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(GenericCongrats)