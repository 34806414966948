import React, {useContext, useRef, useState} from 'react';
import {FlatList, Platform, Pressable, SectionList, TouchableOpacity, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import FeelingQuizItem from "@common/chapters/feeling-quiz/FeelingQuizItem";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ModalM} from "@common/components/modal/ModalM";
import {SelectInputM} from "../../student/account/onboarding/SelectInputM";
import {validation} from "@data/utility/validation";
import DatePickerModalM from "@common/components/modal/DatePickerModalM";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../assets/icons/generic/live.png";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {ActivityIndicator} from "react-native-paper";
import AvatarM from "@common/components/avatar/AvatarM";
import {RankingListItem} from "../../student/social/RankingScreen";
import NextOccurencesScreen from "../../admin/live/NextOccurencesScreen";
import StudyProgramItemsList from "@common/studyPrograms/StudyProgramItemsList";
import SelectChapterScreen from "@common/chapters/SelectChapterScreen";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import ErrorTextM from "@common/components/text/ErrorTextM";
import ServerSearch from "../../admin/ServerSearch";
import {chaptersService} from "@data/services/chaptersService";
import ChapterItemComponent from "@common/chapters/ChapterItemComponent";
import {useNavigation} from "@react-navigation/native";
import {PaginatedFlatListNoNavigation} from "@common/screens/PaginatedFlatListNoNavigation";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import NewChapterModal from "@common/chapters/NewChapterModal";

const SelectChapterComponent = (props) => {
    const {control, goToNextStep, register, errors, serverError, setFormValues, field, formValues, selectedChapter, setSelectedChapter} = props

    const dimensions = useContext(DimensionsContext)
    const [urlParams, setUrlParams] = useState({
        school_subject_id: formValues?.school_subject_id,
        class: formValues?.class,
        search: ""
    })
    const [newChapter, setNewChapter] = useState(null);
    const flatListRef = useRef();

    const canAddChapter = () => {
        if (props.chapters?.length === 0)
            return true
    }

    const onSelectChapter = (value) => {
        // console.log(value)
        setNewChapter(null)
        props.apiRequest(chaptersService.newChapter, {}, {
            ...value,
            school_subject_id: formValues?.school_subject_id,
            class: formValues?.class
        })
    }

    const [visible, setVisible] = useState(false);
    const handleShowModal = () => setVisible(!visible);

    return (
        <View style={{
            width: dimensions?.width,
            alignSelf: 'center',
            // height: IS_WEB ? dimensions?.height / 1.1 : null,
            flex: 1,
            alignItems: 'center',
            paddingTop: 20
        }}>
            <ServerSearch style={{
                width: dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 2,
                margin: 10
            }}
                          urlParams={urlParams}
                          setUrlParams={setUrlParams}
            />
            {/*<ErrorTextM errorData={errors}*/}
            {/*            style={{textAlign: "center", marginBottom: 20}}/>*/}

            <PaginatedFlatListNoNavigation
                ref={flatListRef}
                data={props.chapters}
                style={{
                    height: dimensions.height / 2,
                    width: dimensions.width / 1.5
                }}
                contentContainerStyle={{paddingHorizontal: 100}}
                additionalOnRefresh={() => {
                }}
                additionalRefreshing={false}
                requestApiService={chaptersService.getChaptersAsAdmin}
                requestUrlParams={urlParams}
                requestData={null}
                ListEmptyComponent={
                    props.chapters?.length === 0 && <>
                        <TextM style={{
                            textAlign: "center",
                            marginTop: 20
                        }}>Aucun chapitre trouvé</TextM>
                        <FilledButtonM
                            onPress={() => {
                                if (canAddChapter()) {
                                    handleShowModal()
                                }
                            }}
                            icon={"plus"}
                            style={{
                                alignSelf: 'center',
                                marginTop: 10
                            }}
                            buttonColor={theme.colors.primary}
                            textColor={theme.colors.white}
                            label="Ajouter un chapitre"
                            mode={"contained"}
                        />
                    </>}
                renderItem={(item) => {
                    const selected = selectedChapter === item.item
                    const disabled = field.value.map((item) => item.chapter_id).includes(item.item?.id)
                    return (
                        <ChapterItemComponent chapter={item.item}
                                              key={item.index}
                                              onPress={() => {
                                                  setSelectedChapter(item.item)
                                              }}
                                              isHighlighted={selected}
                                              style={{width: dimensions.width / 1.5 - 30 , margin: 10}}
                                              renderChapterOnly
                                              // animated={disabled}
                        />
                    );
                }}
            />
            {visible && <NewChapterModal
                handleShowModal={handleShowModal}
                isOpen={visible}
                setNewChapter={setNewChapter}
                onSelectChapter={onSelectChapter}
                defaultValues={{
                    name: urlParams?.search,
                    school_subject_id: urlParams?.school_subject_id,
                    class: urlParams?.class
                }}
            />}
        </View>
    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        chapters: state.data.chapters?.chaptersList,
        getChaptersRequest: state.api.getChaptersAsAdmin?.request,
        getChaptersError: state.api.getChaptersAsAdmin?.error,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectChapterComponent)