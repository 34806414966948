import {authService} from "../../../services/authService"
import {apiConstants} from "../../../constants/apiConstants"
import {tutorsService} from "../../../services/tutorsService"
import {studentsService} from "../../../services/studentsService"
import {activitiesService} from "../../../services/activitiesService"
import {interviewsService} from "../../../services/interviewsService"
import {findKeyByValue, updateDataKeys} from "../../../utility/Utils"
import {authConstants} from "../../../constants/authConstants";
import {challengesService} from "../../../services/challengesService";
import {achievementsService} from "../../../services/achievementsService";
import {schoolSubjectsService} from "../../../services/schoolSubjectsService";
import {shopService} from "../../../services/shopService";
import {RESET_ACTIVITIES_HISTORY} from "../../../constants/activitiesConstants";
import {schoolsService} from "../../../services/schoolsService";
import {subscriptionService} from "../../../services/subscriptionService";
import {messengerConstants} from "../../../constants/messengerConstants";
import {messengerService} from "../../../services/messengerService";
import {skillSetsService} from "../../../services/skillSetsService";
import {liveModeConstants} from "../../../constants/liveModeConstants";
import {SET_TODO_ACTIVITIES} from "@data/constants/activitiesConstants";
import {liveService} from "@data/services/liveService";
import {chaptersService} from "@data/services/chaptersService";

const initialState = {}

export function currentUser(state = initialState, action) {
    switch (action.type) {

        case messengerConstants.IN_LIVE_MESSENGER_NOT_READ_COUNT:

            return {
                ...state,
                object: {
                    ...state.object,
                    live_sessions: state.object?.live_sessions?.map(session => {
                        const id = findKeyByValue(session.thread_ids, action.threadId)
                        const currentCount = session.unread_private_messages_count[action.threadId === session.thread_id ? 0 : id]

                        return action.threadId === session.thread_id ? {
                            ...session,
                            unread_messages_count: action?.data === 0 ? 0 : session.unread_messages_count + 1
                        } : id ? {
                            ...session,
                            unread_private_messages_count: {
                                ...session.unread_private_messages_count,
                                [id]: action?.data === 0 ? 0 : currentCount + 1
                            }
                        } : session

                    })
                }
            }

        case liveModeConstants.ACTIVITY_FINALIZED:
            let userObject = null
            const session0 = state.object?.live_sessions.find((s => s.id === action.sessionId))

            session0.users?.map((u) => {
                if (u.id === action?.studentId) {
                    userObject = {
                        ...u,
                        statistics: {
                            ...u.statistics,
                            today: {
                                ...u.statistics?.today,
                                activities: {
                                    ...u.statistics?.today?.activities,
                                    count: u.statistics?.today?.activities?.count + 1
                                }
                            }
                        }
                    }
                    return userObject
                    //console.log(userObject)
                }
            })
            const tab = state.object.sessions?.length > 0 ? state.object.sessions : state.object?.live_sessions
            return {
                ...state,
                object: {
                    ...state.object,
                    live_sessions: state.object?.live_sessions?.map((session => {
                        if (session?.id === action?.sessionId) {
                            let users = session.users?.map((u) => {
                                if (u?.id == action?.studentId) {
                                    return {
                                        ...u,
                                        statistics: {
                                            ...u.statistics,
                                            today: {
                                                ...u.statistics?.today,
                                                activities: {
                                                    ...u.statistics?.today?.activities,
                                                    count: u.statistics?.today?.activities?.count + 1
                                                }
                                            }
                                        }
                                    }
                                    //console.log(userObject)
                                }
                                return u
                            })
                            return {
                                ...session,
                                users: users
                            }
                        }
                        return session
                    }))

                }
            }
        case messengerConstants.IN_CHAT_MESSENGER_NOT_READ_COUNT:
            return {
                ...state,
                object: {
                    ...state.object,
                    unreadInChatMessagesCount: action?.data === 0 ? 0 : state.object?.unreadInChatMessagesCount + 1
                }
            }
        // case messengerConstants.RESET_LIVE_MESSAGES_COUNT:
        //     return {
        //         ...state,
        //         object: {
        //             ...state.object,
        //             //messages: state.object.messages?.concat([action.data])
        //             // messages: [action.data]?.concat(state.object.messages),
        //             live_sessions: state.object?.live_sessions.map(session => {
        //                 if (session.thread_id === action.threadId) {
        //                     return {
        //                         ...session,
        //                         unread_messages_count: 0
        //                     };
        //                 }
        //
        //                 return session;
        //             })
        //         }
        //     }
        case messengerConstants.NEW_MESSAGE:
            return {
                ...state,
                object: {
                    ...state.object,
                    //messages: state.object.messages?.concat([action.data])
                    new_message: action.data
                    // live_sessions: state.object?.live_sessions.map(session => {
                    //     if (session.thread_id === action.data.thread_id) {
                    //         // console.log("action.data.owner_id", action.data.owner_id)
                    //         // console.log("state.object.id", state.object.id)
                    //         return {
                    //             ...session,
                    //             unread_messages_count: (session.unread_messages_count ?? 0) + (action.data.owner_id !== state.object.id ? 1 : 0)
                    //         };
                    //     }
                    //
                    //     return session;
                    // })
                }
            }


        case authConstants.LOGOUT_401:
        case authService.logout.name + apiConstants._SUCCESS:
        case authService.revokeSanctumToken.name + apiConstants._SUCCESS:
        case authService.deleteAccount.name + apiConstants._SUCCESS:
            return {}

        case authService.registerV2.name + apiConstants._SUCCESS:
        case authService.registerTrialV2.name + apiConstants._SUCCESS:
        case authService.registerSpecialV2.name + apiConstants._SUCCESS:
        case authService.registerAndGetSanctumToken.name + apiConstants._SUCCESS:
        case authService.getSanctumToken.name + apiConstants._SUCCESS:
            return {
                // object: user
                object: !state.object ? action.data.user : updateDataKeys(action.data.user, state.object)
            }
        case challengesService.abandonChallenge.name + apiConstants._SUCCESS:
        case schoolSubjectsService.removeSchoolSubject.name + apiConstants._SUCCESS:
        case schoolSubjectsService.chooseSchoolSubject.name + apiConstants._SUCCESS:
        case authService.postNewTrialLiveSessionChoice.name + apiConstants._SUCCESS:
            return {
                // object: user
                object: !state.object ? action.data : updateDataKeys(action.data, state.object)
            }

        case authService.emailVerify.name + apiConstants._SUCCESS:
            return {
                object: !state.object ? null : {
                    ...state.object,
                    is_verified: true
                }
            }

        case shopService.purchase.name + apiConstants._SUCCESS:
        case authService.updateAvatarSelected.name + apiConstants._SUCCESS:
        case authService.updateUserProfile.name + apiConstants._SUCCESS:
        case authService.updateProfile.name + apiConstants._SUCCESS:
        case authService.updateStudentAccount.name + apiConstants._SUCCESS:
        case authService.updateTutorAccount.name + apiConstants._SUCCESS:
        case authService.onboardingViewed.name + apiConstants._SUCCESS:
        case authService.championIntroViewed.name + apiConstants._SUCCESS:
        case authService.getUser.name + apiConstants._SUCCESS:
        case authService.updateUser.name + apiConstants._SUCCESS:
        case authService.updateClass.name + apiConstants._SUCCESS:
        // case authService.resetPassword.name + apiConstants._SUCCESS:
        case authService.login.name + apiConstants._SUCCESS:
        case authService.register.name + apiConstants._SUCCESS:
        case authService.updateTitleSkillSet.name + apiConstants._SUCCESS:
        case tutorsService.updateSlot.name + apiConstants._SUCCESS:
        case authService.updateSlots.name + apiConstants._SUCCESS:
        case authService.updateStudentSlot.name + apiConstants._SUCCESS:
        case subscriptionService.subscribeToTrial.name + apiConstants._SUCCESS:
        case subscriptionService.subscribeToTrialV2.name + apiConstants._SUCCESS:
        case subscriptionService.submitTrialSlots.name + apiConstants._SUCCESS:
        case schoolsService.joinSchool.name + apiConstants._SUCCESS:
        case tutorsService.submitPersonalInfos.name + apiConstants._SUCCESS:
        case tutorsService.submitCompleteProfile.name + apiConstants._SUCCESS:

            // update each key that was sent by server
            const user = {...state.object}
            Object.keys(action.data).map(key => {
                if (key === "live_sessions") { // hack for getUser that comes after getLiveSession, to keep all keys
                    user[key] = action.data?.[key].map(session => {
                        const previousSession = user?.[key]?.find(s => s.id === session.id) ?? {}
                        return {
                            ...previousSession,
                            ...session
                        }
                    })
                } else {
                    user[key] = action.data[key]
                }
            })
            // console.log(action.data)

            return {
                object: user
                // object: !state.object ? action.data : updateDataKeys(action.data, state.object)
            }

        // case messengerService.postMessages.name + apiConstants._SUCCESS:
        //
        //     return {
        //         ...state,
        //         object: {
        //             ...state.object,
        //             messages: (state.object?.messages ?? [])?.concat([action.all_data])
        //
        //
        //         }
        //     }
        case tutorsService.updateNote.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    live_sessions: state.object?.live_sessions.map(session => {
                        session?.users?.forEach((user) => {
                            user?.tutor_notes?.map(n => n.id === action.data?.id ? action.data : n)
                        })
                        return session
                    }),
                    tutor_notes: state.object?.tutor_notes?.map(n => n.id === action.data?.id ? action.data : n)
                }
            }
        case tutorsService.deleteNote.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    live_sessions: state.object?.live_sessions.map(session => {
                        session?.users?.forEach((user) => {
                            //user?.tutor_notes?.filter(n => n?.id !== action.routeParams?.id)
                            user?.tutor_notes?.filter(n => n?.id !== action.routeParams?.id)
                        })
                        return session
                    }),
                    tutor_notes: state.object?.tutor_notes?.filter(n => n?.id !== action.routeParams?.id)
                }
            }
        case tutorsService.postNote.name + apiConstants._SUCCESS:
            let sessions = []
            sessions = state.object?.live_sessions.map(session => {
                session?.users?.forEach(user => {
                    if (user?.id === action?.data?.student_id) {
                        user?.tutor_notes?.push(action?.data)
                    }
                })
                return session
            })
            return {
                ...state,
                object: {
                    ...state.object,
                    live_sessions: sessions,
                    tutor_notes: [action?.data].concat(state.object?.tutor_notes)
                }
            }
        case tutorsService.getNotes.name + apiConstants._SUCCESS:

            let newNotes = []

            if (action.data?.meta?.current_page === 1) { // refresh
                newNotes = action.data?.tutor_notes

                // console.log("page 1 reducer", action.data?.meta)
            } else { // concat
                newNotes = state?.notes.concat(action.data?.tutor_notes)
                // console.log("page + reducer", action.data?.meta)
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    tutor_notes: newNotes
                }
            }

        case liveService.getLiveSession.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    // conference_token: action.data?.conference_token,
                    live_sessions: state.object?.live_sessions.map(currentSession => {

                        // hack to keep all keys for activities of users of each live session


                        if (currentSession.id === action.data.live_session.id) {

                            return {
                                ...currentSession,
                                ...action.data.live_session,

                                // concat or delete users according to action.data.live_session.users
                                users: (currentSession.users?.filter(u => !!u && action.data?.live_session?.users?.map(x => x.id)?.includes(u.id))
                                    ?.map(currentUser => {

                                    const user = action.data?.live_session?.users?.find(user => user.id === currentUser.id)
                                    return {
                                        ...currentUser,
                                        ...user,
                                        todo_activities: currentUser?.todo_activities?.filter(a => !!a && user?.todo_activities.map(x => x.id)?.includes(a.id))
                                            ?.map(a => {
                                                const activity = user?.todo_activities?.find(activity => activity.id === a.id)
                                                return {
                                                    ...a,
                                                    ...activity
                                                }
                                            })?.concat(user?.todo_activities?.filter(a => !currentUser?.todo_activities?.map(x => x.id)?.includes(a.id)) ?? []),
                                        activities: currentUser?.activities?.filter(a => !!a && user?.activities.map(x => x.id)?.includes(a.id))
                                            ?.map(a => {
                                                const activity = user?.activities?.find(activity => activity.id === a.id)
                                                return {
                                                    ...a,
                                                    ...activity
                                                }
                                            })?.concat(user?.activities?.filter(a => !currentUser?.activities?.map(x => x.id)?.includes(a.id)) ?? []),
                                    }
                                }) ?? [])?.concat((action.data?.live_session?.users?.filter(u => !(currentSession?.users ?? [])?.map(x => x.id)?.includes(u.id))) ?? [])
                            }
                        }

                        return currentSession;
                    })
                }
            }
        case schoolsService.getClassroom.name + apiConstants._SUCCESS:
        case schoolsService.putClassroom.name + apiConstants._SUCCESS:
        case schoolsService.removeStudentFromClassroom.name + apiConstants._SUCCESS:
        case schoolsService.postTeacherChallenge.name + apiConstants._SUCCESS:

            return {
                ...state,
                object: {
                    ...state.object,
                    classrooms: (state.object?.classrooms ?? [{id: action.data?.id}])?.map(c => ((c?.id === action.data?.id) ? action.data : c))
                }
            }

        case schoolsService.getClassrooms.name + apiConstants._SUCCESS:
        case schoolsService.deleteClassroom.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    classrooms: action.data
                }
            }

        case schoolsService.postClassroom.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    classrooms: (state.object?.classrooms ?? [])?.concat([action.data])
                }

            }

        case skillSetsService.getSkillSets.name + apiConstants._SUCCESS:
        case skillSetsService.markSkillSetEvolutionAsRead.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    skill_sets: action.data
                }

            }

        case messengerConstants.INCREMENT_STUDENT_MESSENGER_NOT_READ_COUNT:

            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object.students?.map(st => {
                        // console.log(action.studentId, st?.id?.toString)

                        return action.studentId?.toString() !== st?.id?.toString() ? st : {
                            ...st,
                            student_messenger_not_read_count: (st?.student_messenger_not_read_count ?? 0) + 1
                        }
                    })
                }
            }


        case messengerConstants.INCREMENT_TUTOR_MESSENGER_NOT_READ_COUNT:
            return {
                ...state,
                object: {
                    ...state.object,
                    tutor_messenger_not_read_count: (state.object?.tutor_messenger_not_read_count ?? 0) + 1
                }
            }

        case messengerService.getMarkRead.name + apiConstants._SUCCESS:

            // console.log("action.routeParams?.threadId", action.routeParams?.threadId)

            return {
                ...state,
                object: {
                    ...state.object,
                    // the user is a student : check if it is the thread with his own tutor
                    tutor_messenger_not_read_count: state.object?.tutor_messenger_thread_id === action.routeParams?.threadId
                        ? 0
                        : state.object?.tutor_messenger_thread_id,
                    // the user is a tutor, update the thread with the student if found
                    students: state.object?.students?.map(st => {
                        return action.routeParams?.threadId !== st?.tutor_messenger_thread_id ? st : {
                            ...st,
                            student_messenger_not_read_count: 0
                        }
                    }),
                    // user or tutor, update the thread if found in live session
                    live_sessions: state.object?.live_sessions?.map(session => {
                        if (session.thread_id === action.routeParams?.threadId) {
                            return {
                                ...session,
                                unread_messages_count: 0
                            };
                        } else {
                            const userId = findKeyByValue(session.thread_ids, action.routeParams?.threadId)

                            return userId ? {
                                ...session,
                                unread_private_messages_count: {
                                    ...session?.unread_private_messages_count,
                                    [userId]: 0
                                }
                            } : session
                        }
                    })
                }
            }
        case challengesService.postChallengeAsTutor.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object.students?.map(student => {

                        return (student.id === action.routeParams?.studentId) ? {
                            ...student,
                            challenges: student?.challenges.concat([action.data?.new_challenge]),
                            activities: student?.activities?.concat(action.data?.new_activities)
                        } : student
                    })
                }

            }
        case liveModeConstants.SET_MESSAGES:
            return {
                ...state,
                object: {
                    messages: state?.object?.messages?.push(action.newState)
                }

            }

        case messengerService.postImage.name + apiConstants._SUCCESS:
        case messengerService.postAudio.name + apiConstants._SUCCESS:
        case messengerService.getPageMessages.name + apiConstants._SUCCESS:
        case messengerService.getMessages.name + apiConstants._SUCCESS:

            let messenger = []
            const threadId = action.routeParams?.threadId
            if (action.all_data?.meta?.page_id === null) { // refresh
                messenger = action.data

            } else { // concat

                messenger = (state.object?.messages[threadId] ?? [])?.concat(action.data)
            }


            return {
                ...state,
                object: {
                    ...state.object,
                    messages: {
                        ...state.object?.messages,
                        [threadId]: messenger
                    },
                    messageMeta: action.all_data?.meta
                }
            }

        // case schoolsService.joinSchool.name + apiConstants._SUCCESS:
        //     return {
        //         ...state,
        //         object: {
        //             ...state.object,
        //             schools: (state.object?.schools ?? [])?.concat([action.data])
        //         }
        //
        //     }

        case schoolsService.joinClassroom.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    classrooms: (state.object?.classrooms ?? [])?.concat([action.data])
                }

            }

        case studentsService.getStudent.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object.students?.map(student => student.id)?.includes(action.data.id)
                        ? state.object.students?.map(student => {

                            const u = student
                            const user = action.data

                            return (student.id === action.data.id)
                                ? {
                                    ...u,
                                    ...user,
                                    todo_activities: (u?.todo_activities ?? [])?.filter(a => !!a && user?.todo_activities.map(x => x.id)?.includes(a.id))
                                        ?.map(a => {
                                            const activity = user?.todo_activities?.find(activity => activity.id === a.id)
                                            return {
                                                ...a,
                                                ...activity
                                            }
                                        })?.concat(user?.todo_activities?.filter(a => !u?.todo_activities?.map(x => x.id)?.includes(a.id)) ?? []) ?? [],
                                    activities: (u?.activities ?? [])?.filter(a => !!a && user?.activities.map(x => x.id)?.includes(a.id))
                                        ?.map(a => {
                                            const activity = user?.activities?.find(activity => activity.id === a.id)
                                            return {
                                                ...a,
                                                ...activity
                                            }
                                        })?.concat(user?.activities?.filter(a => !u?.activities?.map(x => x.id)?.includes(a.id)) ?? []) ?? [],
                                }
                                : student
                        })
                        : !state.object.students
                            ? [action.data]
                            : state.object.students?.concat([action.data]),
                    live_sessions: state.object?.live_sessions?.map(session => {

                        const user = action.data
                        return {
                            ...session,
                            users: session.users?.map(u => u.id === action.data.id ? {
                                ...u,
                                ...user,
                                todo_activities: (u?.todo_activities ?? [])?.filter(a => !!a && user?.todo_activities.map(x => x.id)?.includes(a.id))
                                    ?.map(a => {
                                        const activity = user?.todo_activities?.find(activity => activity.id === a.id)
                                        return {
                                            ...a,
                                            ...activity
                                        }
                                    })?.concat(user?.todo_activities?.filter(a => !u?.todo_activities?.map(x => x.id)?.includes(a.id)) ?? []),
                                activities: u?.activities?.filter(a => !!a && user?.activities.map(x => x.id)?.includes(a.id))
                                    ?.map(a => {
                                        const activity = user?.activities?.find(activity => activity.id === a.id)
                                        return {
                                            ...a,
                                            ...activity
                                        }
                                    })?.concat(user?.activities?.filter(a => !u?.activities?.map(x => x.id)?.includes(a.id)) ?? []),
                            } : u)
                        }
                    })
                }
            }

        case challengesService.postChallengeAsStudent.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    champion_intros_viewed_at: {
                        ...state.object?.champion_intros_viewed_at,
                        zoe: state.object?.champion_intros_viewed_at?.zoe ?? true
                    },
                    challenges: (state.object?.challenges ?? [])?.concat([action.data?.new_challenge]),
                    activities: (state.object?.activities ?? [])?.concat(action.data?.new_activities)
                }
            }

        case challengesService.getChallengeHistory.name + apiConstants._SUCCESS:
        case challengesService.getStudentChallengeHistory.name + apiConstants._SUCCESS:

            const studentId = action.routeParams?.id
            let challengeHistory = []
            let activityHistory = []

            if (action.data?.meta?.current_page === 1) { // refresh
                challengeHistory = action.data?.challenges
                activityHistory = action.data?.activities

            } else { // concat
                challengeHistory = state.object?.history?.challenges.concat(action.data?.challenges)
                activityHistory = state.object?.history?.activities.concat(action.data?.activities)
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    history: {
                        ...state.object.history,
                        challenges: challengeHistory,
                        activities: activityHistory
                    },
                    students: state.object?.students?.map(s => {
                        return s.id !== studentId ? s : {
                            ...s,
                            history: {
                                ...state.object.history,
                                challenges: challengeHistory,
                                activities: activityHistory
                            }
                        }
                    })
                }
            }

        case schoolSubjectsService.getSchoolSubjectHistory.name + apiConstants._SUCCESS:
        case schoolSubjectsService.getStudentSchoolSubjectHistory.name + apiConstants._SUCCESS:

            const stId = action.routeParams?.id
            let ssHistory = []
            let aHistory = []

            if (action.data?.meta?.current_page === 1) { // refresh
                ssHistory = action.data?.school_subjects
                aHistory = action.data?.activities

            } else { // concat
                ssHistory = state.object?.history?.school_subjects.concat(action.data?.school_subjects)
                aHistory = state.object?.history?.activities.concat(action.data?.activities)
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    history: {
                        ...state.object.history,
                        school_subjects: ssHistory,
                        activities: aHistory
                    },
                    students: state.object?.students?.map(s => {
                        return s.id !== stId ? s : {
                            ...s,
                            history: {
                                ...state.object.history,
                                school_subjects: ssHistory,
                                activities: aHistory
                            }
                        }
                    })
                }
            }


        case activitiesService.getFinishedActivities.name + apiConstants._SUCCESS:
        case activitiesService.getStudentFinishedActivities.name + apiConstants._SUCCESS:

            const stuId = action.routeParams?.id
            let finishedActivities = []
            let challenges = []

            // console.log(action.data?.meta?.current_page)

            if (action.data?.meta?.current_page === 1) { // refresh
                finishedActivities = action.data?.activities
                challenges = action.data?.challenges

                // console.log("page 1 reducer", action.data?.meta)
            } else { // concat
                finishedActivities = state.object?.history?.activities.concat(action.data?.activities)
                challenges = state.object?.history?.challenges.concat(action.data?.challenges)

                // console.log("page + reducer", action.data?.meta)
            }

            return {
                ...state,
                object: {
                    ...state.object,
                    history: {
                        ...state.object.history,
                        activities: finishedActivities,
                        challenges: challenges
                    },
                    students: state.object?.students?.map(s => {
                        return s.id !== stuId ? s : {
                            ...s,
                            history: {
                                ...state.object.history,
                                activities: finishedActivities,
                                challenges: challenges
                            }
                        }
                    }),
                    live_sessions: state.object?.live_sessions?.map(session => {
                        return {
                            ...session,
                            users: session.users?.map(u => {
                                return u.id !== stuId ? u : {
                                    ...u,
                                    history: {
                                        ...state.object.history,
                                        activities: finishedActivities,
                                        challenges: challenges
                                    }
                                }
                            })
                        }
                    })
                }
            }

        case RESET_ACTIVITIES_HISTORY:

            const sId = action?.studentId ?? null

            return {
                ...state,
                object: {
                    ...state.object,
                    history: {
                        ...state.object.history,
                        activities: []
                    },
                    students: state.object?.students?.map(s => {
                        return s.id !== sId ? s : {
                            ...s,
                            history: {
                                ...state.object.history,
                                activities: []
                            }
                        }
                    })
                }
            }

        case activitiesService.getActivitiesForReview.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    pending_reviews_count: action.data?.pending_reviews_count
                }
            }

        case activitiesService.reviewActivity.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    pending_reviews_count: state.object?.pending_reviews_count > 0 ? state.object?.pending_reviews_count - 1 : 0
                }
            }

        //** TO DO
        case SET_TODO_ACTIVITIES:

            const todoStudentId = action?.todoActivities?.[0]?.student_id

            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: action.todoActivities,
                    students: state.object?.students?.map((s) => (s?.id !== todoStudentId) ? s : {
                        ...s,
                        todo_activities: action.todoActivities
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => !ls?.users?.map(u => u?.id)?.includes(todoStudentId) ? ls : {
                        ...ls,
                        users: ls?.users?.map(u => u?.id !== todoStudentId ? u : {
                            ...u,
                            todo_activities: action.todoActivities
                        })
                    })
                }
            }
        case activitiesService.updateActivityTodo.name + apiConstants._SUCCESS:
        case activitiesService.getActivity.name + apiConstants._SUCCESS:
        case activitiesService.lockActivity.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: state.object.todo_activities?.map(todo => ((todo?.id === action?.data?.id) ? action?.data : todo)),
                    students: state.object?.students?.map((s) => (s.id !== action?.data?.student_id) ? s : {
                        ...s,
                        todo_activities: s?.todo_activities?.map(todo => ((todo?.id === action?.data?.id) ? action?.data : todo))
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => {
                        return !ls?.users?.map(u => u?.id)?.includes(action?.data?.student_id) ? ls : {
                            ...ls,
                            users: ls?.users?.map(u => u?.id !== action?.data?.student_id ? u : {
                                ...u,
                                todo_activities: u?.todo_activities?.map(todo => ((todo?.id === action?.data?.id) ? action?.data : todo))
                            })
                        }
                    })
                }
            }
        case chaptersService.archiveChapter.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object?.students?.map((s) => (s.id !== action?.data?.student_id) ? s : {
                        ...s,
                        ongoing_student_chapters: s?.ongoing_student_chapters?.filter(x => x?.id !== action?.data?.id)
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => {
                        return !ls?.users?.map(u => u?.id)?.includes(action?.data?.student_id) ? ls : {
                            ...ls,
                            users: ls?.users?.map(u => u?.id !== action?.data?.student_id ? u : {
                                ...u,
                                ongoing_student_chapters: u?.ongoing_student_chapters?.filter(x => x?.id !== action?.data?.id)
                            })
                        }
                    })
                }
            }
        case chaptersService.studentChapterFeelings.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    ongoing_student_chapters: state.object?.ongoing_student_chapters?.map(x => x?.id === action?.data?.id ? action?.data : x)

                }
            }

        case activitiesService.activityTodoAsTutor.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object.students.map((s) => (s.id !== action?.data?.student_id) ? s : {
                        ...s,
                        todo_activities: s?.todo_activities?.concat(action?.data),
                        ongoing_student_chapters: s?.ongoing_student_chapters?.map(x => x?.id)?.includes(action?.data?.student_chapter?.id) ? s?.ongoing_student_chapters : s?.ongoing_student_chapters?.concat(action?.data?.student_chapter)
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => {
                        return !ls?.users?.map(u => u?.id)?.includes(action?.data?.student_id) ? ls : {
                            ...ls,
                            users: ls?.users?.map(u => u?.id !== action?.data?.student_id ? u : {
                                ...u,
                                todo_activities: u?.todo_activities?.concat(action?.data),
                                ongoing_student_chapters: u?.ongoing_student_chapters?.map(x => x?.id)?.includes(action?.data?.student_chapter?.id) ? u?.ongoing_student_chapters : u?.ongoing_student_chapters?.concat(action?.data?.student_chapter)
                            })
                        }
                    })
                }
            }
        case activitiesService.deleteCommonActivityTodo.name + apiConstants._SUCCESS:
        case activitiesService.activityTodoForStudents.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    live_sessions: state.object?.live_sessions.map(session => {
                        if (session.id === action.data.live_session.id) {
                            return action.data.live_session;
                        }

                        return session;
                    })
                }
            }
        case activitiesService.activityTodoAsStudent.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: state.object.todo_activities?.concat(action?.data),
                    ongoing_student_chapters: state.object?.ongoing_student_chapters?.map(x => x?.id)?.includes(action?.data?.student_chapter?.id) ? state.object?.ongoing_student_chapters : state.object?.ongoing_student_chapters?.concat(action?.data?.student_chapter)
                }
            }
        case activitiesService.deleteActivityTodo.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: state.object?.todo_activities?.filter(todo => todo?.id !== action.routeParams?.id),
                    students: state.object?.students?.map((s) => {
                        return {
                            ...s,
                            todo_activities: s.todo_activities?.filter(todo => todo?.id !== action.routeParams?.id)
                        }
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => {
                        return {
                            ...ls,
                            users: ls?.users?.map(u => {
                                return {
                                    ...u,
                                    todo_activities: u?.todo_activities?.filter(todo => todo?.id !== action.routeParams?.id)
                                }
                            })
                        }
                    })
                }
            }
        case activitiesService.deleteActivitiesTodoAsStudent.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: state.object?.todo_activities?.filter(todo => !action.data?.deleted_activity_ids?.includes(todo?.id))
                }
            }
        case activitiesService.deleteActivitiesTodoAsTutor.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object?.students?.map((s) => {
                        return {
                            ...s,
                            todo_activities: s.todo_activities?.filter(todo => !action.data?.deleted_activity_ids?.includes(todo?.id))
                        }
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => {
                        return {
                            ...ls,
                            users: ls?.users?.map(u => {
                                return {
                                    ...u,
                                    todo_activities: u?.todo_activities?.filter(todo => !action.data?.deleted_activity_ids?.includes(todo?.id))
                                }
                            })
                        }
                    })
                }
            }
        case activitiesService.deleteActivityLog.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: state.object?.todo_activities?.map(activity => {
                        return {
                            ...activity,
                            activity_logs: activity?.activity_logs?.filter(log => log?.id !== action.routeParams?.id)
                        }
                    }),
                }
            }
        case activitiesService.lockActivityV2.name + apiConstants._SUCCESS:
        case activitiesService.postActivityLogV1.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: state.object?.todo_activities?.map(activity => {
                        return activity.id === action.data?.id ? action.data : activity
                    }),
                }
            }
        case activitiesService.activityTodoIndexesAsStudent.name + apiConstants._SUCCESS:
        case activitiesService.activityTodoIndexesAsTutor.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    todo_activities: action?.data
                }
            }
        case activitiesService.getActivities.name + apiConstants._SUCCESS:

            // console.log(action?.data)

            const updateActivityIfFoundInResponse = a => {
                return action.data?.find(x => x?.id === a?.id) ?? a
            }

            // console.log('activity',action.data)
            // console.log('new activities',newActivities)

            return {
                ...state,
                object: {
                    ...state.object,
                    activities: state.object?.activities?.map(updateActivityIfFoundInResponse),
                    history: {
                        ...state.object.history,
                        activities: state.object?.history?.activities?.map(updateActivityIfFoundInResponse)
                    },
                    students: state.object?.students?.map(s => {
                        return !action.data?.find(x => x?.student_id === s?.id) ? s : {
                            ...s,
                            activities: s?.activities?.map(updateActivityIfFoundInResponse),
                            todo_activities: s?.todo_activities?.map(updateActivityIfFoundInResponse),
                            history: {
                                ...s?.history,
                                activities: s?.history?.activities?.map(updateActivityIfFoundInResponse)
                            }
                        }
                    }),
                    live_sessions: state.object?.live_sessions?.map((ls) => {
                        return !ls?.users?.map(u => u?.id)?.includes(action?.data?.[0]?.student_id) ? ls : {
                            ...ls,
                            users: ls?.users?.map(u => u?.id !== action?.data?.[0]?.student_id ? u : {
                                ...u,
                                activities: u?.activities?.map(updateActivityIfFoundInResponse),
                                todo_activities: u?.todo_activities?.map(updateActivityIfFoundInResponse),
                                history: {
                                    ...u?.history,
                                    activities: u?.history?.activities?.map(updateActivityIfFoundInResponse)
                                }
                            })
                        }
                    })
                }
            }

        case activitiesService.initActivity.name + apiConstants._SUCCESS:

            // if there is no user in the response, it is a true init success, else, it is like finalize
            if (!action.data?.user?.id) {
                const updateSingleActivity = activity => {
                    return activity.id === action.data?.id ? action.data : activity
                }
                let newActivities = []
                const stateActivity = state.object?.activities?.find(x => x.id === action.data?.id)
                if (stateActivity) {
                    newActivities = state.object?.activities?.map(updateSingleActivity)
                } else {
                    newActivities = [...state.object?.activities]
                    newActivities.push(action.data)
                }

                // console.log('activity',action.data)
                // console.log('new activities',newActivities)

                return {
                    ...state,
                    object: {
                        ...state.object,
                        activities: newActivities
                    }
                }
            }


        case activitiesService.readReview.name + apiConstants._SUCCESS:
        case activitiesService.finalizeActivity.name + apiConstants._SUCCESS:

            const u = {...state.object}
            Object.keys(action.data?.user).map(key => {
                u[key] = action.data?.user[key]
            })

            const updateActivity = activity => {
                return activity.id === action.data?.activity?.id ? action.data?.activity : activity
            }

            const updateChallenge = challenge => {
                return challenge.id === action.data?.challenge?.id ? action.data?.challenge : challenge
            }

            return {
                object: {
                    ...u,
                    history: {
                        ...u?.history,
                        activities: [],
                        challenges: u?.history?.challenges?.map(updateChallenge)
                    },
                    activities: action.data.user?.activities?.map(updateActivity),
                    todo_activities: action.data.user?.todo_activities?.filter(activity => activity.id !== action.data?.activity?.id),
                    skill_sets: action.data?.skill_sets
                }
                // object: !state.object ? action.data : updateDataKeys(action.data, state.object)
            }

        case achievementsService.markAsRead.name + apiConstants._SUCCESS:
            // console.log(action.data)
            const updateAchievement = achievement => {
                return achievement.id === action.data?.id ? action.data : achievement
            }
            return {
                ...state,
                object: {
                    ...state.object,
                    achievements: state.object?.achievements?.map(updateAchievement)
                }
            }

        case tutorsService.chooseTutor.name + apiConstants._SUCCESS:
            return {
                object: action.data
            }

        case studentsService.getStudents.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: action?.data?.map((s) => {
                        return {
                            ...s,
                            todo_activities: state.object?.students?.find((student) => student?.id === s.id)?.todo_activities,
                            challenges: state.object?.students?.find((student) => student?.id === s.id)?.challenges
                        }
                    })
                }
            }

        case challengesService.abandonChallengeAsTutor.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object.students?.map(student => {

                        return (student.id === action.routeParams?.studentId) ? action.data : student
                    })
                }

            }

        case interviewsService.interviewWeekComments.name + apiConstants._SUCCESS:
        case interviewsService.interviewGlobalComment.name + apiConstants._SUCCESS:
        case interviewsService.interviewFinalize.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    students: state.object?.students?.map(student => {
                        return student?.id === action.data.student_id ? {
                            ...student,
                            interviews: student?.interviews.map(interview => {
                                return interview?.id === action.data.id ? action.data : interview
                            })
                        } : student

                    })
                }
            }
        case activitiesService.postMaterial.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    activities: state.object?.activities?.map(a => {
                        return a?.id === action?.routeParams?.activity_id ? {...a, material: action?.data} : a
                    }),
                    todo_activities: state.object?.todo_activities?.map(a => {
                        return a?.id === action?.routeParams?.activity_id ? {...a, material: action?.data} : a
                    })
                }
            }

        case activitiesService.putMaterial.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    activities: state.object?.activities?.map(a => {
                        return a?.material?.id === action?.data?.id ? {...a, material: action?.data} : a
                    }),
                    todo_activities: state.object?.todo_activities?.map(a => {
                        return a?.material?.id === action?.data?.id ? {...a, material: action?.data} : a
                    })
                }
            }

        case activitiesService.deleteMaterial.name + apiConstants._SUCCESS:
            return {
                ...state,
                object: {
                    ...state.object,
                    activities: state.object?.activities?.map(a => {
                        return a?.material?.id === action?.routeParams?.id ? {...a, material: null} : a
                    }),
                    todo_activities: state.object?.todo_activities?.map(a => {
                        return a?.material?.id === action?.routeParams?.id ? {...a, material: null} : a
                    })
                }
            }

        default:
            return state
    }
}
