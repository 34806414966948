import React, {useContext} from 'react'
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {checkConditionsForClassUpdate} from "../../../student/organize/challenges/StudentSuggestions";

const SlotCompletion = (props) => {

    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)

    return (
        <>
            {(props.user?.profile_completion < 53 || checkConditionsForClassUpdate(props.user)) &&

                <View style={{
                    borderWidth: 1,
                    borderColor: theme.colors.lightMore,
                    borderRadius: 5,
                    padding: 20,
                    backgroundColor: theme.colors.white,
                    marginTop: 30,
                    width: dimensions?.width,
                    justifyContent: 'center'
                }}>
                    <View>
                        <TextM fontWeight={"Bold"}
                               wrap
                               fontSize={20}>
                            Complète ton profil de tuteur
                        </TextM>
                        <TextM wrap
                               style={{
                            marginTop: 10
                        }}>{"Indique tes informations de profil pour commencer à prendre des groupes de Lives !"}
                        </TextM>
                    </View>
                    <View style={{
                        marginTop: 20,
                        alignSelf: 'flex-start'
                    }}>
                        <FilledButtonM style={{
                            width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 4 : null
                        }} label={"Compléter"} color={theme.colors.primary}
                                       onPress={() => navigation.push("tutor/complete-profile")}/>
                    </View>
                </View>
            }


        </>
    )
}
// Inject redux store logic into components
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}
const mapDispatchToProps =
    {}

export default connect(mapStateToProps, mapDispatchToProps)(SlotCompletion)