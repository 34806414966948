import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React from "react";

export const TouchableTile = ({
                                  icon, title, subtitle, style, selected = false, onPress = () => {
    }, additionalComponent = null
                              }) => {

    return <TouchableOpacity style={{
        flex: 2,
        borderRadius: theme.roundness,
        borderWidth: 1,
        borderColor: selected ? theme.colors.primary : theme.colors.lightMore,
        backgroundColor: selected ? theme.colors.primaryLighter : theme.colors.white,
        margin: 4,
        paddingVertical: 5,
        ...style
    }}
                             onPress={onPress}>
        <View style={{alignItems: "center", justifyContent: "center", flex: 1, padding: 4}}>
            <IconImageM width={70}
                        style={{
                            // position: "absolute"
                        }}
                        uri={icon}/>
            <TextM fontSize={15}
                   wrap
                   fontWeight="Bold"
                   style={{marginTop: -6, textAlign: "center"}}
                   color={theme.colors.primary}>{title}</TextM>
            <TextM fontSize={12}
                   wrap
                   fontWeight="Light"
                   style={{textAlign: "center"}}
                   color={theme.colors.grey}>{subtitle}</TextM>
            {selected && <FontAwesome5 name={"check"}
                                       style={{position: "absolute", right: 12, top: 12}}
                                       color={theme.colors.primary}
                                       size={20}/>}
        </View>
        {additionalComponent !== null && additionalComponent}
    </TouchableOpacity>
}