import React, {forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
// @ts-ignore
import Svg from 'react-native-svg';
import Body from './components/body';
import Eyes from './components/eyes';
import Hair from './components/hair';
import Tshirt from './components/shirt';
import {backgroundColors} from './constants/background';
import {facialHairColors, facialHairs} from './constants/facial-hair';
import {hairColors, hairs} from './constants/hair';
import {bodies, bodyColors} from './constants/body';
import {skinColors} from './constants/skin';
import {combineCharacters, DEFAULT_CHARACTERS, parseCharacters, random} from './utils';
import {eyeColors, eyeses} from './constants/eyes';
import shirts from './constants/tshirt';
import noses from './constants/nose';
import {Characters} from './types';
import Jacket from "./components/jacket";
import {jackets} from "./constants/jacket";

type Props = {
    style: ViewStyle;
    characters?: string;
    onNewCharacters?: (characters: string) => void;
};

type Methods = {
    randomize: () => void;
};

function PersonasAvatar({style, characters, onNewCharacters}: Props, ref: Ref<Methods>) {
    const [internalCharacters, setCharacters] = useState<Characters>(DEFAULT_CHARACTERS);

    const {
        skinColor,
        hair,
        hairColor,
        body,
        bodyColor,
        facialHair,
        facialHairColor,
        eyes,
        eyesColor,
        shirt,
        jacket,
        nose,
        backgroundColor,
    } = internalCharacters;

    useImperativeHandle(ref, () => ({
        randomize: randomAvatar,
    }));

    useEffect(() => {
        if (!characters) {
            randomAvatar();
        } else {
            const components = parseCharacters(characters);
            setCharacters(components);
        }
    }, [characters]);

    const randomAvatar = useCallback(() => {
        const randomCharacters = {
            skinColor: random(Object.keys(skinColors)),
            hair: random(Object.keys(hairs)),
            hairColor: random(Object.keys(hairColors)),
            facialHair: random(Object.keys(facialHairs)),
            facialHairColor: random(Object.keys(facialHairColors)),
            body: random(Object.keys(bodies)),
            bodyColor: random(Object.keys(bodyColors)),
            eyes: random(Object.keys(eyeses)),
            eyesColor: random(Object.keys(eyeColors)),
            shirt: random(Object.keys(shirts)),
            nose: random(Object.keys(noses)),
            jacket: random(Object.keys(jackets)),
            backgroundColor: random(Object.keys(backgroundColors)),
        };
        const characters = combineCharacters(randomCharacters);
        if (onNewCharacters) {
            onNewCharacters(characters);
        }
        setCharacters(randomCharacters);
    }, [onNewCharacters]);

    const containerStyle: StyleProp<ViewStyle> = useMemo(() => ({
        width: 160,
        height: 230,
        alignItems: 'center',
        justifyContent: 'center',
        // marginBottom: 80,
        //backgroundColor: 'red',
    }), [backgroundColor]);

    if (!internalCharacters) {
        return null;
    }

    return (
        <View
            style={[
                containerStyle,
                style,
            ]}
        >
            <Body value={body} color={bodyColors[bodyColor]} persona={true}/>
            {/*<Skin color={skinColors[skinColor]}/>*/}
            <Eyes value={eyes} persona={true} color={eyeColors[eyesColor]}/>
            <Hair value={hair} color={hairColors[hairColor]} persona={true}/>
            <Tshirt value={shirt} persona={true}/>
            <Jacket value={jacket} persona={true}/>
            {/*<FacialHair value={facialHair} color={facialHairColors[facialHairColor]}/>*/}
            {/*<Nose value={nose} color={skinColors[skinColor]}/>*/}
        </View>
    );
}

export default forwardRef(PersonasAvatar);
