import React, {useContext, useRef} from 'react';
import {StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect} from "react-redux";
import ChampionBubble from "./ChampionBubble";
import FilledButtonM from "@common/components/button/FilledButtonM";
import championsConstants from "@data/constants/championsConstants";
import {authService} from "@data/services/authService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {DimensionsContext} from "@common/utils/mobileUtils";


const ZoeIntroScreen = (props) => {

    // ** Component props
    const {navigation} = props

    const championBubbleRef = useRef();

    const name = "zoe"

    useApiStatus(
        authService.championIntroViewed, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            props.apiIdle(authService.championIntroViewed)
            // navigation.replace("menu")
        } // success callback
    )
    const dimensions = useContext(DimensionsContext)


    return (
        <View style={{
            flex: 1,
            // backgroundColor: theme.colors.white,
            alignItems: "center",
            justifyContent: "space-between",
            alignSelf: "center",
            width: dimensions?.width,
            height: "100%"
        }}>
            <ChampionBubble name={name}
                            ref={championBubbleRef}
                            variant={championsConstants?.[name + "_intro"]?.variant}
                            texts={championsConstants?.[name + "_intro"]?.texts}
                            championPosition="center"
                            onNextPress={() => {
                                props.apiRequest(authService.championIntroViewed, {}, {intro_name: "zoe"})
                            }}/>


            <View style={{
                alignItems: "center",
                justifyContent: "center",
                width: dimensions?.width
            }}>
                <FilledButtonM
                    onPress={() => {
                        championBubbleRef.current?.onChampionBubbleNext()
                    }}
                    disabled={props.championIntroViewedRequest}
                    loading={props.championIntroViewedRequest}
                    label={"Continuer"}
                    color={theme.colors.primary}
                    style={{
                        // width: WINDOW_WIDTH - 20,
                        alignSelf: "center",
                        marginTop: 10,
                        marginBottom: 30
                    }}
                />

            </View>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        championIntroViewedRequest: state.api.championIntroViewed?.request,

        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ZoeIntroScreen)