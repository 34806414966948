import React, {useContext, useState} from 'react';
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import moment from "moment";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useNavigation} from "@react-navigation/native";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {tutorsService} from "@data/services/tutorsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ActivityIndicator, TouchableRipple} from "react-native-paper";
import {boxedCardBorderStyle} from "../../reviewer/BoxedCardBorderStyle";

const renderSection = (title, data) => <View style={{marginTop: 5}}>
    <TextM color={theme.colors.primary}
        // fontSize={13}
           fontWeight={'SemiBold'}
           wrap
           style={{marginTop: 5}}>
        {title}
    </TextM>
    <TextM wrap
    >
        {data ?? "-"}
    </TextM>
</View>
const NoteCard = (props) => {
    const {note} = props
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const [deletedId, setDeletedId] = useState(null);
    const onDeleteLive = () => {
        setDeletedId(note?.id)
        DialogM.show({
            text1: 'Veux-tu vraiment supprimer cette note ?',
            buttonText1: 'Supprimer',
            buttonColor1: theme.colors.error,
            onPress: () => {
                props.apiRequest(tutorsService.deleteNote, {id: note?.id})
            },
            buttonText2: 'Annuler'
        })
    }
    useApiStatus(
        tutorsService.deleteNote, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(tutorsService.getNotes, {id: note?.student_id})
            //console.log(data)
        } // success callback
    )
    return (
        <View style={{
            marginTop: 30,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            backgroundColor: theme.colors.white,
            width: dimensions?.width,
            alignSelf: 'center',
            borderColor: theme.colors.lightMore,
            paddingVertical: 20,
            paddingHorizontal: 20,
            ...boxedCardBorderStyle,
        }}>
            <TextM color={theme.colors.primary}
                   fontSize={15}
                   fontWeight={'Bold'}
                   style={{textAlign: 'center'}}
            >
                {moment(note?.created_at)?.format("dddd DD/MM").toUpperCase()}
            </TextM>
            <View style={{
                alignSelf: 'flex-end',
                flexDirection: 'row',
                alignItems: 'center',
                position: "absolute",
                top: 15,
                right: 15
            }}>
                <TouchableRipple style={{marginRight: 5, padding: 5}}
                                 onPress={() => {
                                     navigation.push("new-note", {
                                         studentId: note?.student_id,
                                         noteId: note?.id
                                     })
                                 }}>
                    <FontAwesome5 name="pen"
                                  size={22}
                                  color={theme.colors.primary}/>
                </TouchableRipple>
                {props.deleteNoteRequest && deletedId === note?.id ?
                    <ActivityIndicator color={theme?.colors.primary} size={20}/> :
                    <TouchableRipple style={{padding: 5}}
                                     onPress={onDeleteLive}>
                        <FontAwesome5 name="trash"
                                      size={22}
                                      color={theme.colors.primary}/>
                    </TouchableRipple>
                }
            </View>

            {renderSection("Derniers résultats", note?.last_results)}
            {renderSection("Prochaines échéances", note?.next_deadlines)}
            {renderSection("Autre", note?.other)}

        </View>
    )
};
const mapStateToProps = (state) => {
    return {
        deleteNoteRequest: state.api.deleteNote?.request
    };
};
const mapDispatchToProps = {
    apiRequest,
    apiIdle
};
export default connect(mapStateToProps, mapDispatchToProps)(NoteCard);