import {KeyValueItem} from "@common/components/card/KeyValueItem";
import {theme} from "@common/theme/theme";
import React from "react";
import JsonFormatter from "react-json-formatter";

export function ActivityJsonDetails({activity}) {

    const scoringDetails = {
        post_treatment: activity?.post_treatment,
        treatment_result: activity?.treatment_result,
        detailed_scores: activity?.detailed_scores
    }

    return (activity?.status === "validated" || activity?.status === "rejected") &&
        <KeyValueItem keyText="DETAILS DE L'EVALUATION"
                      valueComponent={<JsonFormatter json={scoringDetails} tabWith={4} jsonStyle={{
                          propertyStyle: {color: theme.colors.primary},
                          stringStyle: {color: theme.colors.grey},
                          numberStyle: {color: theme.colors.grey}
                      }}/>}
                      style={{marginTop: 20}}
                      columnMode/>;
}