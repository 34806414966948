export const eyeses = {
    eyes1: true,
    eyes2: true,
    eyes3: true,
    eyes4: true,
    eyes5: true,
    eyes6: true,
    eyes7: true,
};
export const eyesList = [
    {
        name: "eyes1"
    },
    {
        name: "eyes2"
    },
    {
        name: "eyes3"
    },
    {
        name: "eyes4"
    },
    {
        name: "eyes5"
    },
    {
        name: "eyes6"
    },
    {
        name: "eyes7"
    },
];
export const eyeColors = {
    ec1: 'bleu',
    ec2: 'rose',
    ec3: 'marron',
    ec4: 'vert',
    ec5: 'violet',
};

export const eyeColorsList = [
    {
        name: "ec1",
        color: "#3343ba"
    },
    {
        name: "ec5",
        color: "#834eb3"
    },
    {
        name: "ec2",
        color: "#ba338a"
    }, {
        name: "ec3",
        color: "#b56e47"
    }, {
        name: "ec4",
        color: "#3f9048"
    },
];