import React, {useContext} from 'react';
import {View, Platform, ScrollView} from "react-native";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {getPrivilegedRole} from "@data/utility/ability";
import live from "../../../assets/icons/generic/live.png";
import rituals from "../../../assets/icons/generic/rituals.png";
import books from "../../../assets/icons/generic/books.png";
import {essentialOffersPrivileges, OfferPrivilegesList} from "../../student/account/onboarding/TrialOnboardingScreen";
import FilledButtonM from "@common/components/button/FilledButtonM";
import prof from "../../../assets/icons/menus-new/prof.png";

export const holidayModePrivileges = [
    {
        icon: rituals,
        title: "Une thématique au choix : combler ses lacunes, se maintenir en forme ou prendre de l'avance"
    },
    {
        icon: books,
        title: "Des activités chaque semaine, sur 1 ou 2 matières, à faire en autonomie ou en live"
    },
    {
        icon: live,
        title: "Des sessions live les lundis, mardis et mercredis matins de 10h30 à 11h30"
    },
    {
        icon: prof,
        title: "Pour travailler en direct avec son tuteur dans un cadre stimulant"
    }
]
const SuspensionContinuityInformation = (props) => {
    const {control, goToNextStep, register, errors, serverError, setFormValues, field, nextProductHolidayWorkbook} = props

    const dimensions = useContext(DimensionsContext)


    return <>
        <ScrollView style={{flex: 1, width: dimensions?.width, alignSelf: "center"}}
                    contentContainerStyle={{
                        alignItems: "flex-start",
                        alignSelf: "center",
                        width: dimensions?.width,
                        paddingHorizontal: 20
                    }}
        >
            {nextProductHolidayWorkbook ? <>
                <TextM style={{
                    marginTop: 10
                }}
                       wrap>L'été est l'occasion de se ressourcer... mais il ne faut pas laisser les apprentissages s'effacer !</TextM>
                <TextM style={{
                    marginTop: 10
                }}
                       wrap>Du 1er juillet au 1er septembre 2024, découvrez les cahiers vacances en live de Masteur
                    :</TextM>
            </> : <>
                <TextM style={{
                    marginTop: 10
                }}
                       wrap>Offrez à votre enfant un cadre stimulant, pour travailler dans la régularité et booster ses résultats !</TextM>
            </>}

            <OfferPrivilegesList privilegesList={nextProductHolidayWorkbook ? holidayModePrivileges : essentialOffersPrivileges}
                                 whiteBackgroundMode={true}/>
            <FilledButtonM onPress={goToNextStep}
                           style={{
                               width: dimensions?.width < 800 ? dimensions?.width - 20 : dimensions?.width / 2.5,
                               marginTop: 20
                               // bottom: 5,
                           }}
                           label={"Continuer"}/>
        </ScrollView>
    </>
};
const mapStateToProps = state => {
    return {
        holidayOptions: state.data.formData?.holidayOptions,
        subscriptionUser: state.data.formData?.user,
        daysBeforePayment: state.data.formData?.daysBeforePayment,
        discountPercentage: state.data.formData?.discountPercentage
    }
}
const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SuspensionContinuityInformation)