import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {View} from "react-native";
import React, {useContext} from 'react'
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {useNavigation} from "@react-navigation/native";
import {ProgressBarM} from "@common/components/progress/ProgressBarM";

function ProfileCompletionCard(props) {
    const dimensions = useContext(DimensionsContext)

    const navigation = useNavigation()
    return (
        <>
            {(props.user?.profile_completion < 100) &&
                <View style={{
                    borderWidth: 1,
                    borderColor: theme.colors.lightMore,
                    borderRadius: 5,
                    padding: 20,
                    backgroundColor: theme.colors.white,
                    marginTop: 30,
                    width: dimensions?.width,
                    justifyContent: 'center'
                }}>
                    <View>
                        <TextM fontWeight={"Bold"}
                               wrap
                               fontSize={20}>
                            Étape 1 : Complète ton profil
                        </TextM>
                        <TextM wrap style={{
                            marginTop: 10,
                            marginBottom: 10
                        }}>{props.message}
                        </TextM>
                    </View>
                    <ProgressBarM progressColor={theme.colors.accent}
                                  count={props.user?.profile_completion === null ? 0 : props.user?.profile_completion}
                                  percent
                                  total={100}/>
                    <View style={{
                        marginTop: 20,
                        alignSelf: 'flex-start'
                    }}>
                        <FilledButtonM
                            style={{
                                width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 4 : null
                            }}
                            label={"Compléter"}
                            color={theme.colors.primary}
                            onPress={() => navigation.push("settings", {initialTab: 1})}/>
                    </View>

                </View>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCompletionCard)