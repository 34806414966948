import {Platform, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {IconImageM} from "@common/components/icons/IconsM";
import SuccessAnimation from "@common/animations/SuccessAnimation";
import {Avatar} from "react-native-paper";
import React, {useContext} from 'react';
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ActivityXpKeyValueItem} from "./ActivityXpKeyValueItem";
import {ActivityScoringKeyValueItem} from "./ActivityScoringKeyValueItem";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {activitiesService} from "@data/services/activitiesService";
import {useNavigation} from "@react-navigation/native";
import useLaunchActivity from "@common/hooks/useLaunchActivity";


const ActivityReviewedCongrats = (props) => {

    const {activity} = props

    const userChallenges = props.user?.challenges?.concat(props.user?.history?.challenges)

    const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)
    const challenge = activity?.challenge_id ? findItemInListWithId(activity?.challenge_id, userChallenges) : null
    const schoolSubject = activity?.school_subject_id ? findItemInListWithId(activity?.school_subject_id, props.schoolSubjects) : null
    const navigation = useNavigation()
    const [visible, setVisible] = React.useState(false);
    const showModal = (item) => {
        return setVisible(true);
    }
    const hideModal = () => {
        return setVisible(false);
    }
    // console.log(activity)

    const iconSize = 70
    const successSize = iconSize * 4 / 5
    const dimensions = useContext(DimensionsContext)
    const requestApiService = activitiesService.activityTodoAsStudent
    const launchActivity = useLaunchActivity()

    useApiStatus(
        requestApiService, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            launchActivity({
                activity_template_id: activityTemplate?.id,
                id: data.id,
                school_subject_id: schoolSubject?.id,
                chapterName: activity?.student_chapter?.chapter?.name,
                chapterId: activity?.student_chapter?.id,
                xpGained: activity?.student_chapter?.xp_gained,
                ongoing: activity?.student_chapter?.ongoing,
                lastFeelingSentAt: activity?.student_chapter?.last_feeling_sent_at,
            }, "replace")
        } // success callback
    )
    const onPress = () => {
        props.apiRequest(requestApiService, {}, {
            activity_template_id: activityTemplate?.id,
            school_subject_id: schoolSubject?.id,
            material_id: activity?.material?.id,
            material_chapter: activity?.chapter,
            estimated_duration: activity?.estimated_duration,
            chapter_id: activity?.student_chapter?.chapter?.id,
            chapter_name: activity?.student_chapter?.chapter?.name,
            chapter_class: activity?.student_chapter?.chapter?.class,
        })
    }
    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        width: dimensions?.width,
        paddingHorizontal: 15
    }}>
        {activity?.status === "rejected" && <TextM fontSize={32}
                                                   fontWeight="ExtraBold"
                                                   color={theme.colors.grey}
                                                   style={{
                                                       textAlign: "center",
                                                       alignSelf: "center",
                                                       marginBottom: 10,
                                                       marginTop: 30
                                                   }}
                                                   wrap
        >
            ACTIVITÉ REJETÉE
        </TextM>}

        <View style={{
            marginTop: 30,
            alignItems: "center",
            justifyContent: "center"
        }}>

            <Avatar.Image size={iconSize * 1.7}
                          style={{
                              backgroundColor: theme.colors.white,
                              borderColor: theme.colors.success
                              // borderWidth: 1
                          }}/>

            <IconImageM width={iconSize}
                        style={{
                            position: "absolute"
                            // opacity: (0.5)
                        }}
                        uri={activityTemplate?.icon}/>
            <SuccessAnimation
                size={successSize}
                iconSize={successSize * 0.7}
                dotColor={activity?.status === "rejected" ? theme.colors.grey : theme.colors.success}
                backgroundColor={activity?.status === "rejected" ? theme.colors.grey : theme.colors.success}
                iconColor={"white"}
                dotSize={20}
                duration={10}
                icon={activity?.status === "rejected" ? "times" : "check"}
                style={{
                    position: "absolute",
                    // alignSelf: "center",
                    top: -successSize / 3,
                    right: -successSize / 3
                    // right: -30
                }}
                animatedLayerColor={"white"}
            />
        </View>

        <TextM fontSize={20}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center",
                   marginTop: -5
               }}
               color={theme.colors.primary}>{activityTemplate?.title}</TextM>

        {(challenge || schoolSubject) && <View style={{
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            // width: "100%",
            paddingTop: 5
            // paddingBottom: 5
        }}>
            <View style={{
                // flex: 1,
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "row"
            }}>
                <TextM fontWeight="SemiBold"
                       color={theme.colors.grey}
                       fontSize={12}>{schoolSubject ? schoolSubject?.name : challenge?.title}</TextM>
                {challenge && <TextM fontWeight="Medium"
                                     color={theme.colors.light}
                                     fontSize={12}> - {challenge?.school_subject?.name}</TextM>}
            </View>


        </View>}

        <View style={{
            alignItems: "center",
            width: dimensions?.width - 20,
            marginTop: 15
        }}>
            {activity?.status === "rejected" && <TextM fontWeight="Bold"
                                                       color={theme.colors.warning}
                                                       style={{marginBottom: 15, textAlign: "center"}}
                                                       wrap
                                                       fontSize={14}>Les points et récompenses gagnés avec cette
                activité ont été
                retirés.</TextM>}
            <ActivityXpKeyValueItem activity={activity} large/>

            {activity?.status === "validated"
                && <ActivityScoringKeyValueItem activity={activity} large/>}


        </View>
        {/*<View style={{height: 100}}/>*/}
        {activity?.status === "rejected" && <FilledButtonM color={theme.colors.grey}
                                                           icon={"redo"}
            // mode={"outlined"}
                                                           onPress={onPress}
                                                           style={{
                                                               width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,
                                                               alignSelf: "center",
                                                               marginTop: 10
                                                           }}
                                                           loading={props.activityTodoAsStudentRequest}
                                                           label="Refaire l'activité"/>}
    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        activityTodoAsStudentRequest: state.api.activityTodoAsStudent?.request,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReviewedCongrats)