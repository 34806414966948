import React, {useContext} from 'react';
import {Image, Keyboard, Platform, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import LinkTextM from "@common/components/text/LinkTextM";
import {useController, useForm} from "react-hook-form";
import yup from "@data/utility/validation"
import {yupResolver} from '@hookform/resolvers/yup';
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import * as Device from 'expo-device';
import ErrorTextM from "@common/components/text/ErrorTextM";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {Checkbox, IconButton} from "react-native-paper";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {renderers} from "react-native-popup-menu";
import {MenuM} from "@common/components/menu/MenuM";
import tutor from "../../assets/avatar/default/tutor.png"
import student from "../../assets/avatar/default/student.png"
import {IconImageM} from "@common/components/icons/IconsM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import logo from "../../assets/logo-blue.png";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useApiStatus} from "@common/hooks/useApiStatus";

const formSchema = yup.object().shape({
    user_profile: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    newsletter_opt_in: yup.boolean().required()
})

const userTypesImages = {
    student: student,
    tutor: tutor
}

const userTypesDescriptions = {
    student: "Je suis un élève",
    tutor: "Je veux devenir tuteur"
}

const userTypesTexts = {
    student: "Je m'inscris pour démarrer mon essai",
    tutor: "Je m'inscris pour devenir tuteur"
}

const userTypesEmailPlaceholders = {
    student: " de l'élève",
    tutor: ""
}

const UserType = ({userProfile, withCaret = false, style}) => <View
    style={{
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: withCaret ? "space-between" : "flex-start", ...style
    }}>
    <View style={{flexDirection: "row", alignItems: 'center'}}>
        <IconImageM source={userTypesImages[userProfile]}
                    style={{borderRadius: 70}}
                    width={40}/>
        <TextM fontWeight="SemiBold"
               style={{
                   // marginTop: 4
               }}>
            {userTypesDescriptions[userProfile]}
        </TextM>
    </View>
    {withCaret && <FontAwesome5 name={"caret-down"}
                                style={{marginLeft: 10}}
                                color={theme.colors.primary}
                                size={40}/>}
</View>
const RegisterNewScreen = (props) => {

    const {navigation} = props

    const resolver = yupResolver(formSchema);
    const dimensions = useContext(DimensionsContext)

    const {control, handleSubmit, formState: {errors, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            email: '',
            password: '',
            newsletter_opt_in: false,
            user_profile: "tutor",
            'device_name': Device.deviceName ?? "WEB"
        }
    })

    const {field: newsletterOptInField} = useController({
        name: "newsletter_opt_in",
        control
    })

    const {field: userProfileField} = useController({
        name: "user_profile",
        control
    })

    const onSubmit = data => {
        if (props.loggingIn) return
        Keyboard.dismiss()

        props.apiRequestWithPrerequest(authService.registerV2, {}, data)
    }

    useApiStatus(
        authService.registerV2, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            navigation.popToTop()
        } // success callback
    )

    const insets = useSafeAreaInsets()

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            justifyContent: 'flex-start',
            // width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center",
            alignItems: "center",
            width: "100%"
        }}>

            <HeaderContainerM style={{
                justifyContent: "space-between",
                paddingRight: 15
            }}>
                <IconButton
                    icon={"arrow-left"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        // position: "absolute",
                        // left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
                {/*<UserType userProfile={userProfileField?.value}*/}
                {/*    // withCaret*/}
                {/*          style={{}}/>*/}
                {/*<MenuM buttonComponent={<UserType userProfile={userProfileField?.value}*/}
                {/*                                  withCaret*/}
                {/*                                  style={{}}/>}*/}
                {/*       style={{*/}
                {/*           alignSelf: "center"*/}

                {/*           // marginBottom: insets.bottom,*/}
                {/*           // marginTop: 20*/}
                {/*       }}*/}
                {/*       items={[*/}
                {/*           {*/}
                {/*               leftComponent: <UserType userProfile={"student"} style={{marginRight: 10}}/>,*/}
                {/*               onPress: () => userProfileField.onChange("student")*/}
                {/*           },*/}
                {/*           {*/}
                {/*               leftComponent: <UserType userProfile={"tutor"} style={{marginRight: 10}}/>,*/}
                {/*               onPress: () => userProfileField.onChange("tutor")*/}
                {/*           }*/}
                {/*       ]}*/}
                {/*       renderer={renderers.SlideInMenu}*/}
                {/*/>*/}
                {/*<TitleM>Live</TitleM>*/}
            </HeaderContainerM>

            <View style={{
                paddingHorizontal: 15,
                paddingTop: 20,
                width: dimensions?.width,
                alignItems: "center"
            }}>
                <View style={{
                    marginBottom: 30,
                    // marginTop: insets.top + 10,
                    alignItems: "center",
                    alignSelf: 'center'
                }}>
                    <Image style={{
                        width: 150,
                        height: 50
                    }}
                           resizeMode="contain"
                           source={logo}
                    />
                </View>
                <TextM fontSize={24}
                       fontWeight={"ExtraBold"}
                       color={theme.colors.primary}
                       style={{textAlign: "center", marginBottom: 20}}
                       wrap
                >{userTypesTexts[userProfileField.value]}</TextM>
                {/*<FilledButtonM*/}
                {/*    style={{marginTop: 20}}*/}
                {/*    icon={"facebook"}*/}
                {/*    buttonColor={"#3b5998"}*/}
                {/*    disabled*/}
                {/*    // disabled={!dirtyFields.email || !dirtyFields.password}*/}
                {/*    // onPress={handleSubmit(onSubmit)}*/}
                {/*    loading={props.loggingIn || props.loggingInWeb}*/}
                {/*    label={"S'inscrire avec Facebook"}*/}
                {/*/>*/}
                {/*<FilledButtonM*/}
                {/*    style={{marginTop: 10}}*/}
                {/*    icon={"google"}*/}
                {/*    buttonColor={"#db4a39"}*/}
                {/*    disabled*/}
                {/*    // disabled={!dirtyFields.email || !dirtyFields.password}*/}
                {/*    // onPress={handleSubmit(onSubmit)}*/}
                {/*    loading={props.loggingIn || props.loggingInWeb}*/}
                {/*    label={"S'inscrire avec Google"}*/}
                {/*/>*/}
                {/*<View style={{*/}
                {/*    width: !IS_WEB || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,*/}
                {/*    marginVertical: 25*/}
                {/*}}>*/}
                {/*    <Divider/>*/}
                {/*    <View style={{*/}
                {/*        backgroundColor: theme.colors.whiteAlmost,*/}
                {/*        position: "absolute",*/}
                {/*        alignSelf: "center",*/}
                {/*        top: -8,*/}
                {/*        paddingHorizontal: 8*/}
                {/*    }}>*/}
                {/*        <TextM fontSize={14}*/}
                {/*               fontWeight={"Bold"}*/}
                {/*               color={theme.colors.light}*/}
                {/*               wrap*/}
                {/*        >ou</TextM>*/}
                {/*    </View>*/}
                {/*</View>*/}

                <TextInputM
                    placeholder={`Adresse email${userTypesEmailPlaceholders[userProfileField.value]}`}
                    name="email"
                    textContentType="emailAddress"
                    keyboardType="email-address"
                    liveCheck={{service: authService.checkEmailAddress}}
                    liveCheckPreRequest={true}
                    control={control}
                    errors={errors}
                />

                <TextInputM
                    placeholder="Mot de passe"
                    name="password"
                    control={control}
                    password
                    errors={errors}
                />

                <Checkbox.Item label="J’accepte de recevoir des actualités et des offres de Masteur"
                               position={'leading'}
                               onPress={() => {
                                   newsletterOptInField.onChange(!newsletterOptInField.value)
                               }}
                               color={theme.colors.primary}
                               labelStyle={{
                                   fontSize: 14,
                                   textAlign: "left"
                               }}
                               style={{
                                   marginHorizontal: 5,
                                   marginTop: 5
                               }}
                               status={newsletterOptInField.value ? "checked" : "indeterminate"}/>

                <ErrorTextM errorData={props.error} style={{textAlign: "center"}}/>

                <FilledButtonM
                    style={{marginTop: 10}}
                    // disabled={!dirtyFields.email || !dirtyFields.password}
                    onPress={handleSubmit(onSubmit)}
                    loading={props.registering}
                    disabled={props.registering}
                    label={"S'inscrire avec un email"}
                    // label={props.loggingIn ? "" : "Connexion"}
                />
                <View style={{
                    marginBottom: 20,
                    marginTop: 10,
                    paddingHorizontal: 10
                }}>

                    <TextM style={{
                        fontSize: 12,
                        textAlign: 'center'
                    }} wrap>
                        En m'inscrivant, j'accepte les <LinkTextM url="https://masteur.com/cgu">Conditions générales
                        d'utilisation</LinkTextM> de Masteur. Je confirme avoir plus de 15 ans. Dans le cas contraire,
                        je confirme avoir l'autorisation d'un parent pour utiliser ce service.
                    </TextM>
                </View>

            </View>
            <View style={{
                marginBottom: 30,
                marginTop: 30,
                paddingHorizontal: 10,
                justifySelf: "flex-end"
            }}>
                <TextM style={{alignSelf: 'center'}}>
                    Dèja un compte ? <LinkTextM onPress={() => {
                    navigation.replace("login")
                }
                }>Je me connecte</LinkTextM>
                </TextM>
            </View>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        registered: state.api.registerV2?.success,
        registering: state.api.registerV2?.request,
        error: state.api.registerV2?.error,
        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNewScreen)