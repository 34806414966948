import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useState} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import {AbilityContext} from "@data/utility/Can";
import {getPrivilegedRole, offersNames} from "@data/utility/ability";
import premiumPlus from "../../../assets/icons/generic/premium-plus.png";
import {TutorProfileCard} from "../account/onboarding/StartTrialNewScreen";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {authService} from "@data/services/authService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import * as PropTypes from "prop-types";
import RescheduleTrialLiveSessionModal from "./RescheduleTrialLiveSessionModal";
const OfferChosenCongrats = props => {

    const {onPress} = props

    const successSize = 100
    const ability = useContext(AbilityContext)
    const subscriptionName = offersNames?.[getPrivilegedRole(props.user)]?.toUpperCase()

    const navigation = useNavigation()

    const congratsTitle = "Félicitations !"
    // const congratsTitle = "ESSAI GRATUIT ACTIVÉ !"
    const congratsText = "Votre essai gratuit est activé"
    const congratsText2 = "Rendez-vous confirmé"
    const dimensions = useContext(DimensionsContext)

    const [selectLiveSessionModalVisible, setSelectLiveSessionModalVisible] = useState(false)

    useApiStatus(
        authService.postConfirmTrial, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            onPress()
        }
    )

    return <View style={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 50,
        paddingHorizontal: 15,
        width: dimensions?.width
    }}>
        <IconImageM width={80} source={premiumPlus}/>
        <TextM fontSize={22}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center",
                   marginTop: 10,
               }}
               color={theme.colors.primary}>
            {congratsTitle.toUpperCase()}
        </TextM>

        <TextM fontSize={16}
               wrap
               fontWeight="Regular"
               style={{
                   textAlign: "center",
                   marginTop: 10,
                   marginHorizontal: 30
               }}
               color={theme.colors.grey}>
            {congratsText}
        </TextM>
        <TextM fontSize={14}
               wrap
               fontWeight="SemiBold"
               style={{
                   textAlign: "center",
                   marginTop: 30,
                   marginBottom: -20,
                   marginHorizontal: 30
               }}
               color={theme.colors.success}>
            {congratsText2.toUpperCase()}
        </TextM>

        <TutorProfileCard tutor={props.user?.tutor}
                          liveRoom={{live_sessions: props.user?.live_sessions}}
                          style={{
                              marginBottom: 10
                          }}
                          withIndication
                          hideSlots
        />

        <FilledButtonM
            style={{marginTop: 10}}
            // disabled={!dirtyFields.email || !dirtyFields.password}
            trackingId={"confirm_trial_session"}
            onPress={() => {
                props.apiRequest(authService.postConfirmTrial)
            }}
            loading={props.postConfirmTrialRequest}
            disabled={props.postConfirmTrialRequest}
            label={"Continuer"}
            // label={props.loggingIn ? "" : "Connexion"}
        />
        <FilledButtonM
            style={{marginTop: 0}}
            mode={"outlined"}
            trackingId={"reschedule_trial_session"}
            // disabled={!dirtyFields.email || !dirtyFields.password}
            onPress={() => {
                setSelectLiveSessionModalVisible(true)
            }}
            label={"Pas disponible ? Replanifier"}
            // label={props.loggingIn ? "" : "Connexion"}
        />
        <TextM fontSize={14}
               style={{marginHorizontal: 20, textAlign: "center", marginTop: 10}}
               color={theme.colors.grey}
               fontWeight="Light"
               wrap>{"En attendant la séance, vous allez pouvoir découvrir notre application !"}</TextM>

        <RescheduleTrialLiveSessionModal visible={selectLiveSessionModalVisible}
                                         navigation={navigation}
                                         setVisible={setSelectLiveSessionModalVisible}
        />

        <View style={{height: 100}}/>

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        trialOptions: state.data.formData?.trialOptions,
        postConfirmTrialRequest: state.api?.[authService.postConfirmTrial?.name]?.request,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(OfferChosenCongrats)