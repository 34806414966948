import {activitiesConstants} from "@data/constants/activitiesConstants";

export function pauseActivity(paused) {
    return {type: activitiesConstants.PAUSE_ACTIVITY, data: paused}
}

export function setOngoingActivity(data) {
    return {type: activitiesConstants.ONGOING_ACTIVITY, data}
}

export function setCameraStatus(cameraOn) {
    return {type: activitiesConstants.SET_CAMERA_STATUS, cameraOn}
}

export function audioRecordingStatus(data) {
    return {type: activitiesConstants.AUDIO_STATUS, data}
}

export function updateActivityEvent(data) {
    return {type: activitiesConstants.UPDATE_ACTIVITY_CHRONO, data}
}