import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {Keyboard, Platform, StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {quizzService} from "@data/services/quizzservice";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {validation} from "@data/utility/validation";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ProgressStepper from "@common/components/stepper/ProgressStepper";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import {useController, useForm} from "react-hook-form";
import {ListGroupM} from "@common/components/list/ListGroupM";
import {ListItemM} from "@common/components/list/ListItemM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import ChampionBubble from "../student/champions/ChampionBubble";
import QuizzStepSuccessBottomSheet from "./QuizzStepSuccessBottomSheet";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";


function QuizzStatusChampion(props) {

    const {question, champion, variant} = props

    return <ChampionBubble name={champion}
                           variant={variant}
                           championPosition="left"
                           texts={[question]}
                           style={{
                               paddingBottom: 0
                               // marginBottom: -300
                           }}
                           onNextPress={() => {
                           }}
        // ref={championsBubbleRef}
        // style={{marginTop: 30}}
    />;
}

function QuizzStep(props) {

    const {
        data,
        control,
        setError,
        track = false,
        errors,
        goToNextStep,
        isLastStep,
        trigger,
        formValues,
        setFormValues,
        register,
        resetsToDefaultOnNext,
        hideNextButton,
        isStepDirty,
        champion,
        serverError,
        index,
        playSound
    } = props

    const dimensions = useContext(DimensionsContext)

    const {field} = useController({
        control, name: data?.name ?? ""
    })

    const [status, _setStatus] = useState(null)
    const statusRef = React.useRef(status); // ref is used to make use of state within app state event listener (timer)
    const setStatus = data => {
        statusRef.current = data;
        _setStatus(data);
    };

    const [animate, setAnimate] = useState(false)
    const isValid = data?.validOptions?.[0] === field.value?.[0]
    const finishStep = () => {
        setStatus("finished")
        playSound(isValid)
    }


    useEffect(() => {
        if (status === "finished") {
            setAnimate(true)
        } else setAnimate(false)
    }, [status])

    return <View style={{
        flex: 1
    }}>
        <QuizzStatusChampion question={data?.label}
                             variant={status !== "finished" ? "main" : isValid ? "happy" : "unhappy"}
                             champion={data?.champion?.name}/>

        <View style={{
            flex: 1,
            paddingHorizontal: 20
        }}>
            <ListGroupM options={data?.options}
                // initialScrollIndex={data?.initialVisibleIndex}
                        style={{
                            marginBottom: 70
                            // flex: 1
                        }}
                        itemHeight={data?.height ? data?.height : 70}
                        renderItem={(item) => {
                            return <ListItemM isFirst={(item.index === 0)}
                                              isLast={(item.index === data?.options?.length - 1)}
                                              onPress={() => {
                                                  field.onChange([item.item?.value])
                                                  // if (!isLastStep)
                                                  //     goToNextStep()
                                              }}
                                              itemHeight={data?.height ? data?.height : 70}
                                              selected={item.item?.value === field.value?.[0]}
                                              option={item.item}
                                              disabled={item.item?.disabled || status === "finished"}
                            />
                        }}
            />
        </View>

        {status !== 'finished' && <FilledButtonM color={theme.colors.success}
                                                 onPress={() => {
                                                     Keyboard.dismiss()
                                                     finishStep()
                                                 }}
                                                 style={{
                                                     width: dimensions?.width - 20,
                                                     position: "absolute",
                                                     alignSelf: "center",
                                                     bottom: Platform.OS === 'ios' ? 20 : 5
                                                 }}
                                                 disabled={!isStepDirty}
                                                 label="Je valide ma réponse"/>}

        {animate && <QuizzStepSuccessBottomSheet isValid={isValid}
                                                 index={index}
                                                 description={isValid ? null : "La bonne réponse était : " + data?.validOptions?.[0]}
        />}

        {!!status && status === "finished" && <FilledButtonM color={theme.colors.success}
                                                             onPress={() => {
                                                                 goToNextStep()
                                                             }}
                                                             disabled={status !== "finished"}
                                                             style={{
                                                                 width: dimensions?.width - 20,
                                                                 position: "absolute",
                                                                 alignSelf: "center",
                                                                 bottom: 15
                                                             }}
                                                             label={isLastStep ? "Envoyer" : "Continuer"}/>}
    </View>;
}

const NewQuizzScreen = (props) => {
    const navigation = useNavigation()

    const [formValues, setFormValues] = useState(null)
    const defaultValues = {}

    const [quizzQuestions, setQuizzQuestions] = useState([]);


    const dimensions = useContext(DimensionsContext)

    // console.log(formValues)

    useEffect(() => {
        navigation.push('intro', {
            name: "tina",
            introSuffix: '_quizz_intro',
            isTracked: false
        })
        props.apiRequest(quizzService.quizzQuestions)
    }, [])

    useApiStatus(
        quizzService.quizzQuestions, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
            setQuizzQuestions(successData)
        }
    )

    useApiStatus(
        quizzService.initQuizzSubmit, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {

            navigation.replace("quizz-confirm", {
                quizzSubmitId: successData?.id
            })
        }
    )

    const formData = quizzQuestions?.map((quizz, index) => {
        return {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: quizz?.question,
            description: "",
            type: "select.single.correction", // select.single, select.multiple, text, date
            name: index.toString(),
            options: quizz?.options
                ?.map(x => {
                    return {value: x, label: x}
                }),
            validOptions: quizz?.validOptions,
            defaultValue: [],
            validationRules: {
                ...validation.minLength(1),
                ...validation.required()
            },
            champion: {name: quizz?.champion, variant: quizz?.variant}
        }
    })

    const [currentStep, setCurrentStep] = useState(1)
    const isFirstStep = currentStep === 1
    const isLastStep = currentStep === formData?.length
    const goToFirstStep = () => {
        if (!isFirstStep) setCurrentStep(currentStep - 1)
    }

    const {control, handleSubmit, formState: {errors, dirtyFields}, resetField, getValues, setValue} = useForm({
        // resolver,
        defaultValues
    })
    const goToNextStep = () => {

        // from step to step : clean offline mode status and stop sound if any
        // stopSound()

        // console.log("next step save")
        setFormValues({...control._formValues})

        if (!isLastStep) setCurrentStep(currentStep + 1)
        // console.log(getValues())
    }


    const onSubmit = data => {
        // console.log("data", data)
        props.apiRequest(quizzService.initQuizzSubmit, {}, {choices: data})

    }

    const playSound = (isValid = true) => {
        if (isValid)
            PlaySoundM.play(require('../../assets/sounds/next-step.wav'))
        else
            PlaySoundM.play(require('../../assets/sounds/error_004.mp3'))
    }


    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            {/*<FormStepper navigation={navigation}*/}
            {/*             track*/}
            {/*             formData={formData?.filter(x => !x.is_masked)}*/}
            {/*             setFormValues={setFormValues}*/}
            {/*             defaultValues={defaultValues}*/}
            {/*             onSubmit={onSubmit}*/}
            {/*             submitted={props.initQuizzSubmitRequest}*/}
            {/*             exitRouteName={"menu"}*/}
            {/*             submittedMessage=""*/}
            {/*             submitButtonText="Envoyer"*/}
            {/*/>*/}
            <ProgressStepper navigation={navigation}
                             exitWarning
                             noPrevious
                             currentStep={currentStep}
                             setCurrentStep={setCurrentStep}
                             onNextButtonPress={isLastStep ? handleSubmit(onSubmit) : goToNextStep}
                             submitted={props.initQuizzSubmitRequest}
                             submittedMessage={""}
                             headerRightComponent={<TextM fontWeight={"Bold"}
                                                          style={{marginRight: 4}}>{currentStep+"/"+formData?.length}</TextM>}
                // circularProgress
                // circularProgressValue={globalProgress}
                // headerRightComponent={<IconImageM width={40}
                //                                   uri={activityTemplate?.icon}/>}
                             steps={formData?.map((x, index) => {
                                 return {
                                     content: <QuizzStep key={index}
                                                         nextStepType={index < formData?.length - 1 && formData[index + 1]?.type}
                                                         previousStepType={index > 0 && formData[index - 1]?.type}
                                                         data={x}
                                                         goToFirstStep={goToFirstStep}
                                                         goToNextStep={isLastStep ? handleSubmit(onSubmit) : goToNextStep}
                                                         playSound={playSound}
                                         // newImage={newImage}
                                                         index={index}
                                                         isStepDirty={dirtyFields?.[index?.toString()]}
                                                         control={control}
                                                         isLastStep={isLastStep}
                                                         errors={errors}
                                     />,
                                     hideNextButton: true
                                 }
                             })}/>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}

const mapStateToProps = state => {
    return {
        initQuizzSubmitRequest: state.api.initQuizzSubmit?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle

};

export default connect(mapStateToProps, mapDispatchToProps)(NewQuizzScreen)