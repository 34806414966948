import {TouchableOpacity, View} from "react-native";
import React, {useContext} from "react";
import {useNavigation} from "@react-navigation/native";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import {boxedCardBorderStyle} from "../../reviewer/BoxedCardBorderStyle";

function ClassroomCard(props) {

    const {classroom, noClick} = props
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const schoolSubject = findItemInListWithId(classroom?.school_subject_id, props.schoolSubjects)
    return (
        <TouchableOpacity style={{
            ...boxedCardBorderStyle,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            width: dimensions?.width,
            borderColor: theme.colors.lightMore,
            backgroundColor: theme.colors.white,
            paddingHorizontal: 10,
            paddingVertical: 5,
            marginVertical: 10,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
        }}
                          onPress={(noClick) ? () => {
                          } : () => {
                              navigation.push("classroom", {
                                  classroomId: classroom?.id
                              })
                          }}
                          disabled={noClick}
        >
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: "80%",
            }}>
                <IconImageM uri={schoolSubject?.icon} width={70}/>
                <View style={{
                    paddingHorizontal: 20,
                    width: "80%",
                }}>
                    <TextM fontWeight={"Bold"} fontSize={20}>{classroom?.name}</TextM>
                    <TextM wrap>{classroom?.teacher_display_name}</TextM>
                </View>
            </View>
        </TouchableOpacity>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects
    }
}
const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomCard)