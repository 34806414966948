import React from 'react';
import {Platform, View} from "react-native";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {Tabs, TabScreen} from "react-native-paper-tabs";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import RankingScreen from "./RankingScreen";
import {ChampionImageM} from "../champions/ChampionImageM";


export const RankingTabScreen = (props) => {

    // function onButtonPress() {
    //     navigation.push('Challenges');
    // }


    const user = props.user
    // console.log(user?.xp)

    const tabs = [
        {
            name: "Général",
            user: user,
            user_rank: props.user?.rank,
            ranking_data: props.user?.rankings,
            component: RankingScreen
        },
        {
            name: "Classe de " + user?.profile?.class,
            user: user,
            user_rank: props.user?.rank_class,
            ranking_data: props.user?.rankings_class,
            component: RankingScreen
        }
    ]

    return <View style={{
        flex: 1,
        backgroundColor: theme.colors.whiteAlmost,
    }}>{(user?.xp > 0) ?
        <>
            {/*<View style={{*/}
            {/*    alignItems: 'center',*/}
            {/*    justifyContent: 'center',*/}
            {/*    width: WINDOW_WIDTH,*/}
            {/*    paddingTop: 10,*/}
            {/*    backgroundColor: theme.colors.white,*/}
            {/*}}>*/}
            {/*    <TitleM fontWeight="ExtraBold">CLASSEMENT</TitleM>*/}
            {/*</View>*/}
            <Tabs
                // defaultIndex={0} // default = 0
                // uppercase={false} // true/false | default=true | labels are uppercase
                // showTextLabel={false} // true/false | default=false (KEEP PROVIDING LABEL WE USE IT AS KEY INTERNALLY + SCREEN READERS)
                // iconPosition="leading" // leading, top | default=leading
                style={{
                    backgroundColor: theme.colors.white, width: "100%",
                    alignSelf: Platform.OS !== 'web' ? null : "center"
                }} // works the same as AppBar in react-native-paper
                // dark={false} // works the same as AppBar in react-native-paper
                // theme={} // works the same as AppBar in react-native-paper
                // mode="scrollable" // fixed, scrollable | default=fixed
                // onChangeIndex={(newIndex) => {}} // react on index change
                // showLeadingSpace={true} //  (default=true) show leading space in scrollable tabs inside the header
                disableSwipe={true} // (default=false) disable swipe to left/right gestures
            >

                {tabs.map((tab) => {
                    return <TabScreen label={tab.name}
                                      key={tab.name}
                    >
                        {React.createElement(tab.component, {
                            user: user,
                            rankingData: tab?.ranking_data,
                            userRank: tab?.user_rank
                        })}
                    </TabScreen>
                })}

            </Tabs>
        </> : <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingHorizontal: 20,
            // backgroundColor: theme.colors.white,
            flex: 1
        }}>
            <ChampionImageM name="tina" variant={"main"} width={90}/>
            <TitleM fontWeight="ExtraBold" style={{textAlign: "center", marginTop: 20}} wrap>Débloque le classement
                !</TitleM>
            <TextM fontWeight="Medium" style={{textAlign: "center"}} wrap>Réalise ta première activité pour te lancer
                dans
                la
                compétition</TextM>
        </View>}</View>;
};
const mapStateToProps = state => {
    return {
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingTabScreen)