import React, {useContext, useEffect} from 'react';
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {Platform, View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import Animated, {Easing, useAnimatedStyle, useSharedValue, withTiming} from "react-native-reanimated";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {stepErrorMessage, stepFinishedMessage} from "@data/utility/Utils";

const StepSuccessBottomSheet = React.forwardRef((props, ref) => {

    const {isValid = true, index = 0, description = null} = props

    const move = useSharedValue(100);
    const reanimatedStyle = useAnimatedStyle(() => {
        return {
            transform: [{translateY: move.value}]
        }
    })

    useEffect(() => {
        move.value = withTiming(0, {duration: 300, easing: Easing.linear});
    })
    const dimensions = useContext(DimensionsContext)

    return <Animated.View style={[{
        Position: 'absolute',
        top: Platform.OS !== 'web' ? 0 : 0,
        paddingTop: 15,
        backgroundColor: isValid ? theme.colors.successLight : theme.colors.errorLight,
        width: Platform.OS !== 'web' ? dimensions?.width : dimensions?.width,
        height: 200,
        borderRadius: 0,
        alignItems: "center",
    }, reanimatedStyle]}>
        <View style={{
            paddingHorizontal: 10,
            justifyContent: "center",
            flexDirection: "row",
        }}>
            <FontAwesome5 name={isValid ? "check" : "times"}
                          size={20}
                          color={isValid ? theme.colors.successDark : theme.colors.error}
            />
            <TextM fontWeight="ExtraBold"
                   fontSize={20}
                   style={{marginLeft: 10}}
                   color={isValid ? theme.colors.successDark : theme.colors.error}
            >{isValid ? stepFinishedMessage(index) : stepErrorMessage(index)}</TextM>

        </View>
        {description && <TextM fontWeight="Regular"
                               fontSize={16}
                               style={{
                                   marginHorizontal: 20,
                                   marginTop: 10,
                                   textAlign: "center"
        }}
                               wrap
        >{description}</TextM>}

    </Animated.View>
    /*<BottomSheetM backgroundStyle={{
        backgroundColor: theme.colors.successLight,
        borderRadius: 0
    }}
                         ref={ref}
                         {...props}
    >
        <View style={{
            alignItems: 'center',
            paddingTop: 15,
            flexDirection: "row",
            paddingHorizontal: 10,
            justifyContent: "center"
        }}>
            <FontAwesome5 name="check" size={20} color={theme.colors.successDark}/>
            <TextM fontWeight="ExtraBold"
                   fontSize={20}
                   style={{marginLeft: 10}}
                   color={theme.colors.successDark}>Étape terminée !</TextM>
            {/!*<IconImageM source={happy} width={34}/>*!/}
        </View>
    </BottomSheetM>*/

})

export default StepSuccessBottomSheet