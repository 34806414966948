import * as React from 'react'
// import ImageZoom from "./ImageZoom";
import {Image} from "react-native";
import {Zoom} from "@common/components/zoom";

export const PinchableBox = React.forwardRef(({imageStyle, imageUri, onLayout}, ref) => {

    return <Zoom ref={ref}
                 style={{
                     flex: 1,
                 }}
    >
        <Image
            source={{uri: imageUri}}
            resizeMode="contain"
            style={{
                flex: 1,
                // width: 300,
                // height: 400,
                ...imageStyle
            }}
            onLayout={onLayout}
        />
    </Zoom>

    // return <ImageZoom uri={imageUri}
    //                   minPanPointers={1}
    //                   rotate={rotate}
    //                   zoom={zoom}
    //                   containerStyle={{
    //                       // flex:1
    //                   }}
    //                   style={{
    //                       ...imageStyle,
    //                   }}/>
});
