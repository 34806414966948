import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect, useRef, useState} from 'react'
import {TouchableOpacity, View} from "react-native";
import {Camera, CameraType} from "expo-camera";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../assets/icons/generic/live.png";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useNavigation} from "@react-navigation/native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import {setCallPortal, setIsLiveModeEnabled, setIssoundMode} from "@data/redux/actions/liveMode";
import {setMuteAudio, setMuteVideo} from "@data/redux/actions/videoCall";

const PreCallScreen = (props) => {

    const liveSessionId = props.route?.params?.liveSessionId

    const cameraRef = useRef()
    const dimension = useContext(DimensionsContext)
    const liveSession = props.liveSessions?.find(x => x?.id === liveSessionId)
    // const [batteryLevel, setBatteryLevel] = useState(null);
    const navigation = useNavigation()
    // const [volume, setVolume] = useState(null);
    // const getBatterLevel = async () => {
    //     const batteryLevel = await Battery.getBatteryLevelAsync();
    //     setBatteryLevel(batteryLevel)
    //     const _subscription = Battery.addBatteryLevelListener(({batteryLevel}) => {
    //         setBatteryLevel(batteryLevel)
    //     });
    // }
    const [hasPermission, setHasPermission] = useState(null);

    const requestCameraAccess = async () => {
        try {
            // (async () => {
            const {status} = await Camera.requestCameraPermissionsAsync();
            setHasPermission(status === 'granted');
            // })();
        } catch (e) {
            //Bugsnag.notify(e)
        }
    }

    useEffect(() => {
        requestCameraAccess().then()
    }, []);


    const renderItem = (icon, text, action = () => {
    }) => <TouchableOpacity style={{alignItems: 'center'}} onPress={action}>
        <FontAwesome5 name={icon} size={30} color={theme.colors.white} style={{marginRight: 10}}/>
        <TextM color={theme.colors.white} wrap>{text}</TextM>
    </TouchableOpacity>

    return <View style={{flex: 1, alignItems: "center", width: "100%"}}>
        {/*<HeaderContainerM>*/}
        {/*    <TitleM>Rejoindre une session</TitleM>*/}
        {/*    <IconButton*/}
        {/*        icon={"times"}*/}
        {/*        iconColor={theme.colors.primary}*/}
        {/*        size={32}*/}
        {/*        style={{*/}
        {/*            position: "absolute",*/}
        {/*            left: 4*/}
        {/*        }}*/}
        {/*        onPress={() => {*/}
        {/*            navigation.goBack()*/}
        {/*        }}*/}
        {/*    />*/}
        {/*</HeaderContainerM>*/}
        <View style={{
            alignItems: 'center',
            // justifyContent: 'space-between',
            width: IS_WEB ? dimension.width / 2 : dimension?.width - 20,
            marginTop: 20,
            alignSelf: 'center'
        }}>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: "flex-start",
                width: "100%"
            }}>
                <IconImageM source={live}
                            width={20}
                            style={{
                                tintColor: theme.colors.primary,
                                marginRight: 5
                            }}
                />
                <TextM fontSize={15} style={{}}>
                    LIVE - {liveSession?.live_room?.name}
                </TextM>
            </View>
        </View>
        <View style={{
            width: IS_WEB ? dimension.width / 2 : dimension?.width - 20,
            alignItems: "center",
            height: dimension.height / 2,
            borderRadius: 25,
            overflow: 'hidden',
            marginTop: 10,
            backgroundColor: theme.colors.black
        }}>
            {!props.isVideoMuted && hasPermission && <Camera style={{
                alignSelf: "center",
                width: IS_WEB ? dimension.width / 2 : dimension?.width - 20, //TODO : imagepadding
                height: dimension.height / 2
            }}
                                                             useCamera2Api={false}
                                                             type={CameraType.front}
                                                             ref={cameraRef}
                                                             onMountError={(e) => {
                                                                 // console.error(e)
                                                                 //Bugsnag.notify(e.message)
                                                             }}
                                                             onCameraReady={() => {
                                                             }}
                                                             ratio={props.ratioSaved ?? "4:3"}
                // ratio={ratio}
            >
            </Camera>}
            {!IS_WEB && <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: IS_WEB ? dimension.width / 2 : dimension?.width - 20,
                alignSelf: 'center',
                justifyContent: 'space-around',
                position: 'absolute',
                bottom: 20
            }}>
                {renderItem(props.isVideoMuted ? "video-slash" : "video", props.isVideoMuted ? "Video désactivée" : "Video activée", () => {
                    props.setMuteVideo(!props.isVideoMuted)
                })}
                {/*   {renderItem(props.isAudioMuted ? "microphone-slash" : "microphone", props.isAudioMuted ? "Micro désactivée" : "Micro activé", () => {
                    props.setMuteAudio(!props.isAudioMuted)
                })}
                {renderItem("headphones", "Casque")}*/}
            </View>}
        </View>

        {/*<ProgressBarM progressColor={theme.colors.primary}*/}
        {/*    // previousCount={0}*/}
        {/*              count={50}*/}
        {/*    // canStartAnimation={canStartAnimation}*/}
        {/*              barWidth={IS_WEB ? dimension.width / 2 : dimension?.width - 20}*/}
        {/*              style={{alignSelf: 'center', marginTop: 15}}*/}
        {/*              noText*/}
        {/*    // withAnimation*/}
        {/*              total={100}/>*/}
        <TouchableOpacity
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: IS_WEB ? dimension.width / 2 : dimension?.width - 20,
                alignSelf: 'center',
                justifyContent: 'center',
                marginTop: 15
            }}
            onPress={() => PlaySoundM.play(require('../../../assets/sounds/maximize_001.mp3'))}
        >
            <FontAwesome5 name={"volume-up"} size={25} color={theme.colors.primary} style={{marginRight: 10}}/>
            <TextM color={theme.colors.primary} fontWeight={"Bold"} style={{textAlign: 'center'}}>TESTER LE SON</TextM>
        </TouchableOpacity>
        <View style={{position: 'absolute', bottom: 20, alignSelf: 'center'}}>
            {/*<TextM wrap*/}
            {/*       style={{*/}
            {/*           width: IS_WEB ? dimension.width / 2 : dimension?.width - 20,*/}
            {/*           marginBottom: 10,*/}
            {/*           textAlign: 'center'*/}
            {/*       }}>*/}
            {/*    En cas de coupure, ton tuteur {liveSession?.tutor?.display_name} pourra t'appeler en numéro masqué au*/}
            {/*</TextM>*/}
            <FilledButtonM
                label="Rejoindre maintenant"
                style={{alignSelf: 'center'}}
                onPress={() => {
                    navigation.goBack()
                    // navigation.replace("menu")
                    props.setIsLiveModeEnabled(true, liveSessionId)
                    props.setIssoundMode(false, props.user.id)
                    // props.setCallPortal(true)
                    // if (!IS_WEB) {
                    //     RNCallKeep.startCall(createUUID(), liveSession?.live_room?.name, "Live Masteur", 'generic', false);
                    //     RNCallKeep.setAvailable(false)
                    //     RNCallKeep.backToForeground();
                    // }
                }}

            />
        </View>

    </View>
}
const mapStateToProps = state => {
    return {
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        isAudioMuted: state.liveMode?.isAudioMuted,
        isVideoMuted: state.liveMode?.isVideoMuted,
        user: state.data.currentUser?.object,
        ratioSaved: state.camera?.ratio,
        imagePaddingSaved: state.camera?.imagePadding
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setCallPortal,
    setMuteAudio,
    setMuteVideo,
    setIsLiveModeEnabled,
    setIssoundMode
}
export default connect(mapStateToProps, mapDispatchToProps)(PreCallScreen)
