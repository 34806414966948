import moment from "moment";
import { View } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { theme } from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import React from "react";
import { IconImageM } from "@common/components/icons/IconsM";
import intensityLevel1 from "../../../../assets/icons/intensity-level/intensity_level_1.png";

import {materialLevelsColors, materialLevelsIcons} from "@common/chapters/ActivityLevelBadge";
import {calculateTimerColor} from "../../activity/execute/TimerContainer";

export const isActivityTooOld = (activity) =>
  !activity?.finished_at && moment().diff(activity?.created_at, "days") >= 7;
export const TodoActivityBadges = (props) => {

    const {activity, style, noCalendarWarning = false, updatedActivityEventData, custom = false } = props // aaa

    // const status = activity?.started_at !== null && (activity?.locked_at !== null || (updatedActivityEventData?.locked_at !== null && updatedActivityEventData?.activity_id === activity?.id)) ? "En cours" :
    //     activity?.started_at !== null && (activity?.locked_at === null || (updatedActivityEventData?.locked_at === null && updatedActivityEventData?.activity_id === activity?.id)) ? "Commencée"
    //         : null
  // find the right level from associative JSON object

  return (
    <View style={{ flexDirection: "row", ...style }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <FontAwesome5
          name={"stopwatch"}
          solid
          style={{ marginRight: 6 }}
          color={activity?.duration ? calculateTimerColor(
              (activity?.duration * 100) / (props.activity?.estimated_duration * 60),
              "finished"
          ) : theme.colors.light}
          size={custom ? 10 : 14}
        />
        <TextM
          fontWeight="SemiBold"
          fontSize={custom ? 10 : 12}
          wrap
          color={theme.colors.light}
          style={{}}
        >
            {activity?.duration && <TextM fontSize={custom ? 10 : 12}
                                          fontWeight="Bold"
                                          color={calculateTimerColor(
                (activity?.duration * 100) / (props.activity?.estimated_duration * 60),
                "finished"
            )}>
                {Math.floor(activity?.duration/60)}
            </TextM>}
            {activity?.duration !== null ? " /" : "- /"}{activity?.estimated_duration + "min"}
        </TextM>
        {!custom && (
          <IconImageM
            source={materialLevelsIcons[activity?.level] ?? intensityLevel1}
            width={custom ? 12 : 18}
            style={{
              marginLeft: 6,
              tintColor: materialLevelsColors[activity?.level] ?? theme.colors.light,
            }}
          />
        )}
      </View>
    </View>
  );
};
