import {Platform, ScrollView, TouchableOpacity, View} from "react-native";
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {DimensionsContext} from "../utils/mobileUtils";
import {theme} from "../theme/theme";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {Badge, IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import CommonChat, {transformMessage} from "@common/messenger/CommonChat";
import {useSendWhisper} from "@common/messenger/socketHook";
import {resetLiveMessagesCount} from "../../data/redux/actions/liveMode";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {hasRole} from "@data/utility/ability";
import {changeUnreadMessageInLiveCount} from "@data/redux/actions/broadcast";
import {messengerService} from "@data/services/messengerService";
import {GiftedChat} from "react-native-gifted-chat";
import LiveAvatarM from "../../tutor/students/LiveAvatarM";
import {avatarColors} from "@data/constants/studentsConstants";
import getContrastingColor from "react-native-paper/src/utils/getContrastingColor";
import {BadgeM} from "@common/components/badge/BadgeM";

export const AvatarWithInfos = ({user}) => <View style={{
    flexDirection: "row",
    alignItems: "center"
}}>
    {user?.id !== 0 && <LiveAvatarM user={user} size={40}/>}
    <View style={{
        marginLeft: 5
    }}>
        <TextM fontSize={14} fontWeight={"Medium"}>{user?.display_name}</TextM>
        {user?.profile?.class && <TextM fontSize={10}>{user?.profile?.class}</TextM>}
    </View>
</View>

export function hasValueGreaterThanZero(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key) && typeof obj[key] === 'number' && obj[key] > 0) {
            return true; // Found a value greater than 0
        }
    }
    return false; // No value greater than 0 found
}

const LiveChat = (props) => {
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const userId = props.route.params?.userId ?? null
    const updateRequest = props.postMessagesRequest;
    const [messages, setMessages] = useState([]);
    const liveSession = props.currentLiveSession
    const groupChatUser = {
        display_name: "Groupe",
        thread_id: liveSession?.thread_id,
        id: 0
    }
    const chatMembers = hasRole(props.user, 'student') ? liveSession?.users?.filter(x => x?.is_tutor) ?? [] : liveSession?.users?.filter(user => !user.is_tutor) ?? []
    const user = userId?.toString() === "0" ? groupChatUser : chatMembers.find(x => x.id?.toString() === userId?.toString())
    const [currentUser, setCurrentUser] = useState(user ?? groupChatUser);
    const [threadId, setThreadId] = useState(liveSession?.thread_ids[currentUser?.id] ?? liveSession?.thread_id)

    const sendWhisper = useSendWhisper({
        channel: `presence-messenger.thread.${liveSession?.thread_ids[currentUser?.id] ?? liveSession?.thread_id}`,
        id: props.user?.id
    })
    const renderButton = () => <View style={{
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "center"
    }}>
        {currentUser && <AvatarWithInfos user={currentUser}/>}
        <FontAwesome5 name="caret-down" size={32} color={theme.colors.primary}
                      style={{marginHorizontal: 5}}/>
        {(hasValueGreaterThanZero(props.currentLiveSession?.unread_private_messages_count) || props.currentLiveSession?.unread_messages_count > 0) &&
            <Badge size={10}
                   style={{
                       backgroundColor: theme.colors.error,
                       color: theme.colors.white,
                       position: "absolute",
                       top: 15,
                       right: 0,
                       alignSelf: "center"
                   }}/>}
    </View>

    const ChatAvatar = ({user}) => {
        return <TouchableOpacity
            style={{
                // borderRadius: 40,
                // borderWidth: user?.id === currentUser?.id ? 4 : 0,
                // borderColor: theme.colors.primary,
                alignItems: "center",
                marginHorizontal: 10,
                paddingHorizontal: 15,
                justifyContent: "center",
                backgroundColor: user?.id === currentUser?.id ? theme.colors.primaryLighter : null,
            }}
            onPress={() => {
                setCurrentUser(user)
                setThreadId(liveSession?.thread_ids?.[user.id] ?? liveSession?.thread_id)
            }}
        >
            <View style={{flexDirection: "row", alignItems: "center"}}>
                {user?.display_name === "Groupe" ?
                    <View style={{
                        width: 40,
                        height: 40,
                        borderRadius: 20,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: avatarColors[user?.id ? (user?.id % avatarColors.length) : 0]
                    }}>
                        <TextM
                            fontSize={8}
                            color={getContrastingColor(avatarColors[user?.id ? (user?.id % avatarColors.length) : 0], theme.colors.white, 'rgba(0, 0, 0, .54)')}>
                            {user?.display_name}
                        </TextM>
                    </View> :
                    <LiveAvatarM user={user} size={40} style={{marginRight: 0}}/>}
                {user?.id === currentUser?.id && <TextM color={theme.colors.primary}
                                                        style={{marginLeft: 10, maxWidth: 100}}
                                                        fontSize={14}
                                                        fontWeight={"Bold"}>{currentUser?.display_name}</TextM>}
            </View>
            {(user?.id !== 0 ? props.currentLiveSession?.unread_private_messages_count?.[user.id] > 0 : props.currentLiveSession?.unread_messages_count > 0) &&
                <BadgeM
                    count={props.currentLiveSession.unread_private_messages_count?.[user.id] ?? props.currentLiveSession?.unread_messages_count}
                    size={16}
                    style={{
                        backgroundColor: theme.colors.error,
                        color: theme.colors.white,
                        position: "absolute",
                        top: 10,
                        right: 10
                    }}/>}
        </TouchableOpacity>
    }

    useEffect(() => {
        let item = transformMessage(props.user?.new_message);

        setMessages((old) => {
            if (old.find(msg => msg.threadId === props.user?.new_message?.thread_id)) {
                return GiftedChat.append(old, [item])
            }

            return old
        });
    }, [props.user?.new_message]);

    const prevThreadId = useRef();
    const setMessagesCallback = useCallback(() => {
        if (prevThreadId.current === undefined) return;

        if (props.user.messages[threadId] && threadId !== prevThreadId.current) {
            setMessages(props.user?.messages[threadId]?.map(transformMessage).filter(msg => msg._id));
            props.apiRequest(messengerService.getMarkRead, {
                threadId: threadId
            });
            prevThreadId.current = threadId;
        }
    }, [props.user.messages, threadId]);

    useEffect(() => {
        setMessagesCallback();
    }, [setMessagesCallback]);

    // list with currentUser as first (if currentUser?.id === 0, group is first)
    const orderedUsersList = [currentUser, ...([groupChatUser, ...chatMembers].filter(user => user?.id !== currentUser?.id))]

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            paddingTop: 0,
            // justifyContent: "space-between",
            width: "100%",
            alignSelf: Platform.OS !== 'web' ? null : "center",
            alignItems: "center"
        }}>
            <HeaderContainerM style={{
                justifyContent: "flex-end",
                alignItems: "center",
                width: "100%"
            }}>

                {/*<View style={{alignItems: "center"}}>
                    <TitleM>Messages</TitleM>
                    {threadId === liveSession?.thread_id && <TextM>Tous les participants</TextM>}
                </View>*/}
                <ScrollView horizontal={true}
                            style={{flex: 1, height: 60}}
                            contentContainerStyle={{
                                flexDirection: "row",
                                paddingLeft: 50,
                                // alignItems: "center"
                            }}>
                    {orderedUsersList.map((user, index) => {
                        return  <ChatAvatar user={user} key={index}/>
                    })}

                </ScrollView>

                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 0,
                        backgroundColor: theme.colors.white,
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />

                {/*  <MenuM buttonComponent={renderButton()}
                       style={{
                           //width: IS_WEB || dimensions?.width > 800 ? dimensions?.width * 3 / 5 : "100%",
                           //alignSelf: "center",
                           marginLeft: 5,
                       }}
                       items={chatMembers.concat(groupChatUser).map(user => (
                           {
                               //title: user.display_name,
                               //subtitle: user?.profile?.class,
                               component: <View>
                                   <AvatarWithInfos user={user} size={32}/>
                                   {(props.currentLiveSession?.unread_private_messages_count[user.id] > 0 || (props.currentLiveSession?.unread_messages_count > 0 && user?.thread_id === liveSession.thread_id)) &&
                                       <BadgeM
                                           count={props.currentLiveSession?.unread_private_messages_count[user.id] > 0 ? props.currentLiveSession?.unread_private_messages_count[user.id] : props.currentLiveSession.unread_messages_count}
                                           size={10}
                                           style={{
                                               backgroundColor: theme.colors.error,
                                               color: theme.colors.white,
                                               position: "absolute",
                                               top: 8,
                                               right: 0
                                           }}/>}
                               </View>,
                               highlighted: currentUser?.id === user?.id,
                               onPress: () => {
                                   //console.log(user)
                                   setCurrentUser(user)
                                   setThreadId(liveSession?.thread_ids[user.id] ?? liveSession?.thread_id)
                               }
                           }
                       ))}
                       renderer={renderers.SlideInMenu}
                />*/}


            </HeaderContainerM>
            <CommonChat setMessages={setMessages}
                        messages={messages}
                        sendWhisper={currentUser?.id === 0 ? sendWhisper : () => {}}
                        threadId={threadId}
                        liveChat
                        groupChat
                        participants={liveSession?.users}
            />
        </View>
    )
}
const mapStateToProps = state => {
    return {
        getMessagesRequest: state.api.getMessages?.request,
        getMessagesSuccess: state.api.getMessages?.success,
        getMessagesError: state.api.getMessages?.error,

        getPageMessagesRequest: state.api.getPageMessages?.request,
        getPageMessagesSuccess: state.api.getPageMessages?.success,
        getPageMessagesError: state.api.getPageMessages?.error,

        getMarkReadRequest: state.api.getMarkRead?.request,
        getMarkReadSuccess: state.api.getMarkRead?.success,
        getMarkReadError: state.api.getMarkRead?.error,

        postMessagesRequest: state.api.postMessages?.request,
        postMessagesSuccess: state.api.postMessages?.success,
        postMessagesError: state.api.postMessages?.error,

        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token,

        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),

        participants: state.liveMode?.liveParticipants,
        tutors: state.liveMode?.liveTutors
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setIsLiveModeEnabled,
    resetLiveMessagesCount,
    changeUnreadMessageInLiveCount
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveChat)