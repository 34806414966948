import React, {useContext, useEffect, useState} from 'react';
import {ScrollView, View} from "react-native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import StatsCardItem from "./StatsCardItem";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "@common/theme/theme";

export function groupByAttribute(objects, attribute) {
    const grouped = {};

    for (const obj of objects) {
        const key = obj[attribute];

        if (!grouped[key]) {
            grouped[key] = [];
        }

        grouped[key].push(obj);
    }

    return grouped;
}

const GrowthTabScreen = (props) => {

    const dimensions = useContext(DimensionsContext)
    const [statsGroupedByCategory, setStatsGroupedByCategory] = useState({})
    useEffect(() => {
        if (props.appStats) {
            setStatsGroupedByCategory(groupByAttribute(Object.values(props.appStats), 'category'))
        }
    }, [props.appStats]);

    return (
        <View style={{
            width: dimensions.width,
            alignSelf: 'center',
            marginVertical: 20,
        }}>
            {props.getApplicationStatisticsRequest ? <ActivityIndicator color={theme.colors.primary}/>
                :
                <ScrollView style={{
                    width: dimensions.width,
                }}>
                    <View style={{
                        width: dimensions.width,
                        flexDirection: IS_WEB ? 'row' : 'column',
                        justifyContent: IS_WEB ? 'space-between' : 'center',
                        alignItems: IS_WEB ? 'stretch' : 'center',
                        flexWrap: IS_WEB ? 'wrap' : 'nowrap',
                    }}>
                        {!IS_WEB && <StatsCardItem mainTitle={"North Star Metric"}
                                                   style={{
                                                       width: dimensions.width - 20,
                                                       marginTop: 20,
                                                       // alignSelf: 'center'
                                                   }}
                                                   items={[
                                                       {
                                                           name: 'Bientôt disponible'
                                                       },
                                                   ]}
                        />}
                        <StatsCardItem
                            mainTitle={"Sticky growth engine"}
                            style={{
                                marginTop: IS_WEB ? 0 : 10,
                                marginRight: IS_WEB ? 10 : 0,
                                width: IS_WEB ? null : dimensions.width - 20
                            }}
                            items={statsGroupedByCategory["sticky_growth_engine"]}
                        />
                        <StatsCardItem
                            mainTitle={"Viral Growth Engine"}
                            style={{
                                marginTop: IS_WEB ? 0 : 10,
                                marginRight: IS_WEB ? 10 : 0,
                                width: IS_WEB ? null : dimensions.width - 20
                            }}
                            items={statsGroupedByCategory["viral_growth_engine"]}
                        />
                        <StatsCardItem
                            mainTitle={"Paid Growth Engine"}
                            style={{
                                marginTop: IS_WEB ? 0 : 10,
                                width: IS_WEB ? null : dimensions.width - 20

                            }}
                            items={statsGroupedByCategory["paid_growth_engine"]}
                        />
                    </View>
                    {IS_WEB && <StatsCardItem mainTitle={"North Star Metric"}
                                              style={{
                                                  width: dimensions.width,
                                                  marginTop: 20,
                                              }}
                                              items={[
                                                  {
                                                      name: 'Bientôt disponible'
                                                  },
                                              ]}
                    />}
                </ScrollView>}
        </View>
    )
}
const mapStateToProps = state => {
    return {
        appStats: state.data.backOffice?.appStats,
        getApplicationStatisticsRequest: state.api.getApplicationStatistics?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowthTabScreen)