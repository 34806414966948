import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {FlatList, RefreshControl, ScrollView, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import FileStep from "./FileStep";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import ApplicationFinishedScreen from "./ApplicationFinishedScreen";
import ChampionBubble from "../../student/champions/ChampionBubble";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {plr} from "@data/utility/Utils";
import {authService} from "@data/services/authService";
import {StatusBar} from "expo-status-bar";
import {SliderNavigationM} from "../../auth/AuthNavigator";

const TutorApplicationScreen = (props) => {
    const dimensions = useContext(DimensionsContext)
    const flatListRef = useRef()
    // console.log("props.user?.application_current_step?.step", props.user?.application_current_step?.step)
    // console.log("currentVisibleIndex", currentVisibleIndex)
    const applicationSteps = props.application_steps
    const onViewableItemsChanged = ({viewableItems, changed}) => {
        setCurrentVisibleIndex(viewableItems?.[0]?.index)
        // console.log("viewableItems?.[0]?.index", viewableItems?.[0]?.index)
        // console.log("viewableItems", viewableItems)
    }
    const viewabilityConfig = {waitForInteraction: false, viewAreaCoveragePercentThreshold: 95}
    const viewabilityConfigCallbackPairs = useRef([{viewabilityConfig, onViewableItemsChanged}])
    const itemHeight = 500
    const stepsCount = applicationSteps?.length
    const currentStep = props.user?.application_current_step?.step
    const completion = props.user?.application_current_step?.completion
    const navigation = useNavigation()
    const applicationFinished = props.user?.application_current_step?.step === stepsCount && completion >= 100
    const [currentVisibleIndex, setCurrentVisibleIndex] = useState(currentStep - 1)

    const currentStepName = props.application_steps?.[currentStep - 1]?.test_category
    const currentStepChampion = currentStepName === "motivation"
        ? "tina"
        : currentStepName === "educational"
            ? "zoe"
            : currentStepName === "technical"
                ? "sam"
                : "abou"

    const remainingStepsCount = stepsCount - currentStep + 1

    useFocusEffect(
        useCallback(() => {
            props.apiRequest(authService.getUser)
        }, [])
    );


    useEffect(() => {
        if (currentStep > 0 && stepsCount > 0) {
            setCurrentVisibleIndex(currentStep - 1)
            flatListRef?.current?.scrollToIndex({index: currentStep - 1})
        }
    }, [currentStep, stepsCount]);

    useEffect(() => {
        if (currentStep === 1) {
            setTimeout(() => {
                navigation.push("intro", {
                    name: "abou",
                    introSuffix: `_application_interview_intro`
                })
            }, 300)
        }
    }, [])


    return <View style={{
        flex: 1,
        alignSelf: "center",
        width: dimensions.width,
        marginTop: 20
    }}>
        <ScrollView refreshControl={<RefreshControl
            refreshing={!!props.getUserRequest}
            onRefresh={() => props.apiRequest(authService.getUser)}
            containerStyle={{
                flex: 1,
                width: dimensions?.width
            }}
        />}>
            {applicationFinished ? <ApplicationFinishedScreen/> :
                <>
                    <TextM fontWeight={"Bold"}
                           fontSize={20}
                           color={theme.colors.primary}
                           style={{
                               marginLeft: 10
                           }}
                    >
                        MA CANDIDATURE
                    </TextM>
                    <View style={{
                        width: dimensions?.width,
                        flex: 1
                    }}>
                        <FlatList
                            data={applicationSteps}
                            ref={flatListRef}
                            horizontal={true}
                            //showsHorizontalScrollIndicator={false}
                            pagingEnabled
                            style={{
                                width: dimensions?.width,
                                flex: 1
                            }}
                            initialScrollIndex={currentVisibleIndex}
                            getItemLayout={(data, index) => (
                                {length: dimensions?.width, offset: dimensions?.width * index, index}
                            )}
                            viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
                            renderItem={(item) => {

                                const stepNum = item?.index + 1
                                const step = item?.item
                                const disabled = currentStep !== stepNum

                                const onStepPress = () => {
                                    if (stepNum === 1)
                                        navigation.push("application-personal-infos")
                                    else if (stepNum === 2 || stepNum === 3)
                                        navigation.push("intro", {
                                            name: currentStepChampion,
                                            introSuffix: `_${currentStepName}_interview_intro`,
                                            isTracked: false,
                                            goBack: true,
                                            nextScreen: "video-recording",
                                            nextScreenParams: {
                                                testMode: 1,
                                                testCategory: currentStepName
                                            }
                                        })
                                    else
                                        navigation.push("intro", {
                                            name: currentStepChampion,
                                            introSuffix: `_${currentStepName}_interview_intro`,
                                            isTracked: false,
                                            goBack: true,
                                            nextScreen: "application-interview",
                                            nextScreenParams: {
                                                testCategory: currentStepName
                                            }
                                        })
                                }
                                // console.log(completion)
                                return <View style={{
                                    height: itemHeight,
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    width: dimensions?.width,
                                    paddingTop: 20,
                                    opacity: (currentStep >= stepNum) ? 1 : 0.5
                                    // flex: 1

                                    // borderTopWidth: 1,
                                    // borderBottomWidth: 1
                                    // backgroundColor: theme.colors.primaryTransparent
                                }}>
                                    <View style={{
                                        alignItems: "flex-start",
                                        justifyContent: "center",
                                        paddingRight: 20
                                        // height: "100%",
                                    }}>
                                        <FileStep stepNum={stepNum}
                                                  stepInfo={step}
                                                  currentStep={currentStep}
                                                  onPress={onStepPress}/>
                                    </View>
                                    <View style={{
                                        marginLeft: 40
                                    }}>
                                        <View style={{
                                            height: 20,
                                            width: dimensions?.width,
                                            backgroundColor: theme.colors.lighter
                                        }}/>
                                        <View style={{
                                            height: 20,
                                            width: stepNum === currentStep ? (20 + (dimensions?.width) * completion / 100) : stepNum < currentStep ? dimensions?.width : 0,
                                            backgroundColor: theme.colors.primary,
                                            position: "absolute",
                                            left: stepNum === 1 ? 10 : 0
                                        }}/>
                                        <View style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: 20,
                                            backgroundColor: currentStep > stepNum ? theme.colors.success : theme.colors.white,
                                            borderWidth: 5,
                                            borderColor: theme.colors.primary,
                                            position: "absolute",
                                            left: 0,
                                            top: -10,
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                            {currentStep > stepNum && <FontAwesome5
                                                style={{
                                                    backgroundColor: theme.colors.success
                                                }}
                                                name={"check"}
                                                size={20}
                                                color={theme.colors.white}
                                            />}
                                        </View>
                                        <View style={{
                                            backgroundColor: theme.colors.primary,
                                            width: 3,
                                            height: 20,
                                            position: "absolute",
                                            left: 20 - 2,
                                            top: -25
                                        }}/>
                                    </View>
                                    <View style={{alignItems: 'flex-start', marginTop: 15, marginLeft: 42}}>
                                        <TextM color={theme.colors.primary}
                                               fontSize={16}
                                               fontWeight={"Bold"}
                                               style={{marginBottom: 5}}>ÉTAPE {stepNum + "/" + stepsCount}</TextM>
                                        <TextM wrap style={{
                                            textAlign: 'left',
                                            maxWidth: dimensions?.width - 80,
                                            marginBottom: 15
                                        }}>{step?.description}</TextM>
                                        {stepNum < currentStep
                                            ? <TextM wrap
                                                     style={{textAlign: 'left'}}
                                                     fontWeight={"SemiBold"}
                                                     color={theme.colors.success}
                                                     fontSize={16}>TERMINÉ !</TextM>
                                            : stepNum === currentStep
                                                ? <FilledButtonM onPress={onStepPress}
                                                                 style={{
                                                                     width: 100,
                                                                     alignSelf: "flex-start"
                                                                 }}
                                                                 label={"GO !"}/>
                                                : null}
                                    </View>
                                </View>

                            }}
                        />
                        <SliderNavigationM style={{
                            width: dimensions?.width,
                            position: "absolute",
                            alignSelf: "center",
                            top: 194
                        }}
                                           currentIndex={currentVisibleIndex}
                                           flatListRef={flatListRef}
                                           data={applicationSteps}
                        />

                    </View>
                </>}
        </ScrollView>
        {!applicationFinished && <ChampionBubble name={currentStepChampion}
                                                 variant={"main"}
                                                 championPosition={"left"}
                                                 style={{
                                                     position: "absolute",
                                                     bottom: 0
                                                 }}
                                                 texts={[`Il reste ${remainingStepsCount} ${plr("étape", remainingStepsCount)} pour finir ta candidature !`]}
                                                 onNextPress={() => {
                                                 }}
        />}
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

    </View>
}
const mapStateToProps = state => {
    return {
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationScreen)