import React, {useContext, useState} from "react";
import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {LinkPreview} from "@flyerhq/react-native-link-preview";
import {API_URL} from "@data/constants/apiConstants";
import MaterialModal from "../activity/execute/MaterialDisplayModal";
import {MaterialImage} from "./MaterialImage";

const MaterialItemComponent = (props) => {
    const {material, style, field, goToNextStep, register} = props;

    const dimensions = useContext(DimensionsContext);

    const url = !material?.url
        ? null
        : IS_WEB
            ? API_URL + "/api/cors-proxy?url=" + material?.url
            : material?.url;
    const usageCount = material?.activities_count;

    const [isMaterialOpen, setMaterialOpen] = useState(false);

    const handleShowMaterialModal = () => setMaterialOpen(!isMaterialOpen);

    // console.log(material)
    const description = `utilisé ${usageCount} fois`

    return (
        <TouchableOpacity style={{}} onPress={handleShowMaterialModal}>
            <View
                style={{
                    backgroundColor:
                        field.value === material?.id
                            ? theme.colors.primaryLighter
                            : theme.colors.transparent,
                    borderWidth: field.value === material?.id ? 1 : 0,
                    borderColor:
                        field.value === material?.id
                            ? theme.colors.primary
                            : theme.colors.lightMore,
                    alignSelf: "center",
                    borderRadius: 10,
                    marginTop: 10,
                    padding: 10,
                    // paddingTop: 15,
                    // paddingBottom: 0,
                    width: dimensions.width - 40,
                    // backgroundColor: "red",
                    ...style
                }}
            >
                {url && (
                    <LinkPreview
                        text={url}
                        header={description}
                        renderText={() => <TextM>{url}</TextM>}
                        renderHeader={(headerText) => <TextM style={{textAlign: "right"}}>{headerText}</TextM>}
                        renderImage={() => <></>}
                        renderTitle={(title) => {
                            return <TextM fontWeight={"Bold"}>{title}</TextM>;
                        }}
                        renderDescription={(description) => <TextM>{description}</TextM>}
                        containerStyle={{
                            backgroundColor: theme.colors.linkLighter,
                            borderRadius: theme.roundness,
                            // marginTop: 20,
                            overflow: "hidden"
                            // maxHeight: 180
                        }}
                        touchableWithoutFeedbackProps={{
                            onPress: handleShowMaterialModal
                        }}
                    />
                )}
                {material?.attachments?.length > 0 && (
                    <MaterialImage
                        images={material?.attachments}
                        title={material?.title}
                        description={description}
                        canDeleteImage={false}
                        canOpenImage={false}
                    />
                )}
            </View>

            <MaterialModal
                handleShowModal={handleShowMaterialModal}
                isOpen={isMaterialOpen}
                material={material}
                manageTodo={props.manageTodo}
                field={field}
                goToNextStep={goToNextStep}
                materialUrlField={props.materialUrlField}
                materialTitleField={props.materialTitleField}
                materialAttachmentsField={props.materialAttachmentsField}
            />
        </TouchableOpacity>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MaterialItemComponent);
