import React, {useEffect} from "react";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {activitiesService} from "@data/services/activitiesService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {dismissNotificationIfExists} from "@common/notifications/notifications";
import {hasRole} from "@data/utility/ability";
import alertPolyfill from "@common/components/alert/alertPolyfill";
import {IS_WEB} from "@common/utils/mobileUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const ReadReviewRequestHandler = (props) => {
    const navigation = useNavigation()

    useEffect(() => {
        if (props.readReviewSuccess) {

            const user = {...props.readReviewSuccessData?.user}
            const activity = {...props.readReviewSuccessData?.activity}
            const challenge = props.readReviewSuccessData?.user?.challenges?.find(c => c.id === activity?.challenge_id) ? {...props.readReviewSuccessData?.user?.challenges?.find(c => c.id === activity?.challenge_id)} : null
            const skillSets = props.readReviewSuccessData?.skill_sets

            const congrats = [{
                congratsType: congratsTypes.ACTIVITY_REVIEWED,
                congratsData: {
                    activity
                }
            }]

            if (activity?.status === "validated") {

                if (challenge) {
                    congrats.push({
                        congratsType: congratsTypes.CHALLENGE_PROGRESS,
                        congratsData: {
                            challenge,
                            activity
                        }
                    })
                }

                // props.readReviewSuccessData?.user?.achievements?.filter(a => !a?.is_read)?.forEach(achievement => {
                //     if (!achievement?.is_read)
                //         congrats.push({
                //             congratsType: congratsTypes.NEW_ACHIEVEMENT,
                //             congratsData: {...achievement}
                //         })
                // })


                // Skill sets progress congrats
                congrats.push({
                    congratsType: congratsTypes.MY_PROGRESS,
                    congratsData: {
                        userSkillSets: skillSets?.filter(ss => (ss?.id === 3))
                    }
                })

                // If new title, Show skill-set new title congrats
                skillSets?.filter(ss => (
                    ss?.current_count === 0
                    && !ss?.is_last_evolution_read
                    && ss?.current_step >= 2
                    && ss?.steps?.[ss?.current_step - 1]?.title_gained !== ss?.steps?.[ss?.current_step - 2]?.title_gained
                ))?.forEach(ss => {
                    congrats.push({
                        congratsType: congratsTypes.NEW_TITLE_CONGRATS,
                        congratsData: {
                            userSkillSet: ss
                        }
                    })
                })

                // If petals gained, show petals gained congrats
                const petalsGainedCount = skillSets?.filter(ss => (
                    ss?.current_count === 0
                    && !ss?.is_last_evolution_read
                ))?.reduce((pValue, cValue) => pValue + cValue?.steps?.[cValue?.current_step - 1]?.petals_gained, 0)
                if (petalsGainedCount > 0) {
                    congrats.push({
                        congratsType: congratsTypes.PETALS_GAINED,
                        congratsData: {
                            petalsGainedCount
                        }
                    })
                }


            } else if (activity?.status === "rejected") {
                // TODO : Si rejeté, liste exhaustives des retours arrières appliqués (filtre sur les achievement logs à appliquer ?, marqueur à ajouter pour pouvoir filtrer ?) --> retours arrières possibles XP (succès 1 + défi éventuellement + succès 2 éventuellement) / Temps hors-ligne (succès 3 éventuellement) / pétales retirées lorsque retour arrière
                // congrats.push({
                //     congratsType: congratsTypes.ACTIVITY_REJECTED_LOSSES,
                //     congratsData: {
                //         // TODO
                //     }
                // })

            }

            props.apiIdle(activitiesService.readReview)

            if (!IS_WEB)
                dismissNotificationIfExists("new-review", "activity_id", activity?.id)

            if (hasRole(props.readReviewSuccessData?.user, "student") && !props.readReviewSuccessData?.user?.champion_intros_viewed_at?.sam) {
                navigation.push("intro", {
                    name: "sam",
                    nextScreen: "congrats",
                    nextScreenParams: {congrats}
                })

            } else {

                navigation.push("congrats", {congrats})
            }
            // console.log(activities)
        }
        if (props.readReviewError) {
            props.apiIdle(activitiesService.readReview)
            DialogM.show({
                text1: 'Erreur',
                text2: props.readReviewError.message
            })
            //alertPolyfill('Erreur', props.readReviewError.message)

        }


    }, [props.readReviewSuccess, props.readReviewError]);
    return <></>;
}

const mapStateToProps = state => {
    return {
        readReviewRequest: state.api.readReview?.request,
        readReviewSuccess: state.api.readReview?.success,
        readReviewError: state.api.readReview?.error,
        readReviewSuccessData: state.api.readReview?.data
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadReviewRequestHandler)