import React, {useContext, useEffect, useRef, useState} from "react";
import {FlatList, ScrollView, View} from "react-native";
import {ActivityIndicator} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {liveService} from "@data/services/liveService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {StatusBar} from "expo-status-bar";
import RNCallKeep from "react-native-callkeep";
import LiveReportComponent from "@common/liveMode/live-report/LiveReportComponent";
import SatisfactionComponent from "@common/liveMode/SatisfactionComponent";
import EndSessionStudentComment from "./EndSessionStudentComment";
import moment from "moment";
import AutoSaveTextInputM from "../components/input/TextInputAutoSaveM";
import {useNavigation} from "@react-navigation/native";
import {authService} from "../../data/services/authService";
import {plr} from "@data/utility/Utils";

const EndSessionScreen = (props) => {
    const occurrenceId = props.route?.params?.occurrence_id ?? null;

    const dimensions = useContext(DimensionsContext);
    const [message, setMessage] = useState("");
    const insets = useSafeAreaInsets();
    const [liveReports, setLiveReports] = useState([]);
    const [qualityScore, setQualityScore] = useState(0);
    const [showReports, setShowReports] = useState(false);
    const [liveSessionDate, setLiveSessionDate] = useState(null);
    const [commentsSentCount, setCommentsSentCount] = useState(0);

    const navigation = useNavigation();

    // calculate average call_count, duration, activities_count and tutor_call_duration  by number of students without digits after comma
    const avgStats = liveReports
        ?.filter((x) => !!x?.stats)
        ?.reduce(
            (acc, curr) => {
                return {
                    call_count: acc.call_count + curr?.statistics?.call_count,
                    duration: acc.duration + curr?.statistics?.duration,
                    activities_count:
                        acc.activities_count + curr?.statistics?.activities_count,
                    tutor_call_duration:
                        acc.tutor_call_duration + curr?.statistics?.tutor_call_duration,
                    live_connection_duration:
                        acc.live_connection_duration +
                        curr?.statistics?.live_connection_duration
                };
            },
            {
                call_count: 0,
                duration: 0,
                activities_count: 0,
                tutor_call_duration: 0,
                live_connection_duration: 0
            }
        );

    avgStats.call_count =
        Math.round(avgStats.call_count / liveReports?.length) ?? 0;
    avgStats.duration = Math.round(avgStats.duration / liveReports?.length) ?? 0;
    avgStats.activities_count =
        Math.round(avgStats.activities_count / liveReports?.length) ?? 0;
    avgStats.tutor_call_duration =
        Math.round(avgStats.tutor_call_duration / liveReports?.length) ?? 0;
    avgStats.live_connection_duration =
        Math.round(avgStats.live_connection_duration / liveReports?.length) ?? 0;

    useEffect(() => {
        if (occurrenceId)
            props.apiRequest(liveService.getLiveOccurrence, {
                id: occurrenceId
            });
    }, []);

    useApiStatus(
        liveService.getLiveOccurrence, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setLiveReports(successData?.live_reports);
            setQualityScore(successData?.tutor_live_quality_score);
            setMessage(successData?.tutor_comment);
            setCommentsSentCount(
                successData?.live_reports?.filter(
                    (r) => r?.tutor_comment || r?.comment_record
                ).length
            );

            setLiveSessionDate(
                moment(successData?.date).format("dddd DD MMMM HH:mm").toUpperCase()
            );
        },
        () => {
        }
    );

    useApiStatus(
        liveService.sendStudentInfos, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {

        },
        () => {
        }
    );

    const listRef = useRef(null);
    const studentCommentsListRef = useRef(null);
    const inputRef = useRef(null);

    const commentsNeededToBeSent = liveReports?.filter(
        (report) => !!report?.statistics
    )?.length;

    return (
        <>
            {props.getTodayLiveOccurrenceRequest || props.getLiveOccurrenceRequest ? (
                <ActivityIndicator
                    color={theme.colors.primary}
                    size={24}
                    style={{paddingTop: insets.top + 20}}
                />
            ) : (props.getLiveOccurrenceError || !occurrenceId) ? <>
                    <TextM color={theme.colors.error}
                           wrap
                           style={{
                               marginTop: 20,
                               marginBottom: 20,
                               textAlign: "center"
                           }}
                           fontWeight={"Bold"}
                           fontSize={14}>
                        {props.getLiveOccurrenceError?.message || "Une erreur est survenue"}
                    </TextM>
                    <FilledButtonM
                        onPress={() => {
                            navigation.goBack()
                        }}
                        label={"Retour à l'accueil"}
                    />
                </> : (
                <ScrollView style={{
                    flex: 1
                }}
                            contentContainerStyle={{
                                width: dimensions?.width,
                                alignSelf: "center"
                            }}
                >
                    <View
                        style={{
                            alignSelf: "center",
                            alignItems: "center",
                            marginTop: 32,
                            paddingTop: insets.top
                        }}
                    >
                        <TextM
                            wrap
                            fontSize={14}
                            style={{
                                textAlign: "center",
                                marginHorizontal: 10
                            }}
                        >
                            Live du {liveSessionDate}
                        </TextM>
                        <TextM
                            wrap
                            fontSize={18}
                            fontWeight={"Bold"}
                            color={theme.colors.grey}
                            style={{marginBottom: 30}}
                        >
                            La session Live a bien été clôturée
                        </TextM>
                    </View>
                    <View style={{marginTop: 20}}>
                        <TextM
                            wrap
                            color={theme.colors.primary}
                            fontWeight={"Bold"}
                            fontSize={18}
                            style={{
                                textAlign: "center",
                                marginHorizontal: 10
                                // marginBottom: 20
                            }}
                        >
                            FAIS UN PETIT COMPTE-RENDU DE LA SÉANCE POUR CHAQUE ÉLÈVE
                        </TextM>
                        <TextM
                            wrap
                            fontSize={14}
                            style={{
                                textAlign: "center",
                                marginHorizontal: 10
                                // marginBottom: 20
                            }}
                        >
                            En vocal ou en texte, c'est toi qui choisis !
                        </TextM>
                        <TextM
                            wrap
                            fontSize={14}
                            fontWeight={"Medium"}
                            style={{
                                textAlign: "center",
                                marginHorizontal: 10,
                                marginTop: 20
                            }}
                        >
                            <TextM
                                wrap
                                fontSize={18}
                                fontWeight={"Bold"}
                                color={commentsSentCount >= commentsNeededToBeSent ? theme.colors.success : theme.colors.error}
                            >{commentsSentCount}</TextM> /{commentsNeededToBeSent} {plr("effectué", commentsSentCount)}
                        </TextM>
                    </View>
                    <View
                        style={{margin: 20}}
                    >
                        {liveReports?.map((item, index) => {
                            return <EndSessionStudentComment key={index}
                                                             liveReport={item}
                                                             setCommentsSentCount={setCommentsSentCount}
                                                             commentsSentCount={commentsSentCount}
                                                             style={{
                                                                 width: dimensions?.width,
                                                                 marginTop: 20
                                                             }}
                            />
                        })}
                    </View>
                    <View
                        style={{
                            backgroundColor: theme.colors.white,
                            marginTop: 100
                        }}
                    >
                        <View
                            style={{
                                marginVertical: 20
                            }}
                        >
                            <TextM
                                wrap
                                color={theme.colors.primary}
                                fontWeight={"Bold"}
                                fontSize={18}
                                style={{
                                    textAlign: "center",
                                    marginHorizontal: 10
                                    // marginBottom: 20
                                }}
                            >
                                Evalue la qualité du LIVE
                            </TextM>
                            <TextM
                                wrap
                                fontSize={14}
                                style={{
                                    textAlign: "center",
                                    marginHorizontal: 10
                                    // marginBottom: 20
                                }}
                            >
                                Ton retour régulier est précieux, pour nous aider à améliorer
                                nos services.
                            </TextM>
                            <SatisfactionComponent
                                rating={qualityScore}
                                setRating={setQualityScore}
                                withApiRequest
                                requestApiService={liveService.sendLiveQuality}
                                requestRouteParams={{id: occurrenceId}}
                                requestData={{quality_score: qualityScore}}
                            />
                        </View>
                        {!!qualityScore && (
                            <>
                                <TextM
                                    wrap
                                    color={theme.colors.primary}
                                    fontWeight={"Bold"}
                                    fontSize={16}
                                    style={{
                                        textAlign: "center",
                                        marginHorizontal: 10,
                                        marginTop: 20
                                    }}
                                >
                                    {props.satisfactionSubtitles?.[qualityScore]}
                                </TextM>
                                <AutoSaveTextInputM
                                    ref={inputRef}
                                    placeholder=""
                                    defaultValue={message}
                                    name="message"
                                    contentContainerStyle={{
                                        width: "80%",
                                        alignSelf: "center",
                                        paddingHorizontal: 10
                                    }}
                                    style={{
                                        height: 100,
                                        backgroundColor: theme.colors.white,
                                        marginTop: 10,
                                        alignSelf: "center",
                                        marginBottom: 20
                                    }}
                                    multiline={true}
                                    numberOfLines={2}
                                    onChangeText={(value) => {
                                        setMessage(value);
                                        props.apiRequest(
                                            liveService.sendLiveQuality,
                                            {id: occurrenceId},
                                            {
                                                quality_score: qualityScore,
                                                comment: value
                                            }
                                        );
                                    }}
                                />
                            </>
                        )}
                    </View>
                    <FilledButtonM
                        onPress={() => {
                            setShowReports(!showReports);
                        }}
                        mode={"outlined"}
                        label={`${showReports ? "Masquer" : "Afficher"} les rapports`}
                        color={theme.colors.primary}
                        style={{
                            // width: "100%",
                            alignSelf: "center",
                            marginTop: 100
                        }}
                    />
                    {showReports && (
                        <>
                            <TextM
                                wrap
                                color={theme.colors.primary}
                                fontWeight={"Bold"}
                                fontSize={20}
                                style={{
                                    textAlign: "center",
                                    marginTop: 20
                                }}
                            >
                                RAPPORTS DÉTAILLÉS
                            </TextM>

                            <FlatList
                                data={liveReports}
                                ref={listRef}
                                horizontal
                                pagingEnabled={!IS_WEB}
                                decelerationRate={0}
                                renderItem={({item}) => {
                                    return (
                                        <LiveReportComponent
                                            withTitle={true}
                                            noDate
                                            liveReport={item}
                                            style={{
                                                width: !IS_WEB
                                                    ? dimensions?.width - 40
                                                    : dimensions?.width,
                                                marginHorizontal: 20
                                            }}
                                        />
                                    );
                                }}
                                contentContainerStyle={{
                                    alignSelf: "center",
                                    alignItems: "flex-start",
                                    paddingHorizontal: IS_WEB ? 0 : 10
                                }}
                                style={
                                    {
                                        // width: dimensions.width,
                                    }
                                }
                            />
                        </>
                    )}

                    <FilledButtonM
                        onPress={() => {
                            if (navigation.canGoBack()) navigation.goBack();
                            else navigation.replace("menu");
                            props.apiRequest(authService.getUser);
                        }}
                        label={"Terminer"}
                        loading={props.sendStudentInfosRequest}
                        color={theme.colors.success}
                        disabled={commentsSentCount < commentsNeededToBeSent}
                        style={{
                            // width: "100%",
                            alignSelf: "center",
                            marginTop: 10,
                            marginBottom: 40
                        }}
                    />
                </ScrollView>
            )}
            <StatusBar
                style="dark"
                backgroundColor={theme.colors.white}
                translucent={false}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
            (x) => x?.id === state.liveMode?.liveSessionId
        ),
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        participants: state.liveMode?.liveParticipants,
        sendStudentInfosRequest: state.api.sendStudentInfos?.request,
        getTodayLiveOccurrenceRequest: state.api.getTodayLiveOccurrence?.request,
        getTodayLiveOccurrenceError: state.api.getTodayLiveOccurrence?.error,
        getLiveOccurrenceRequest: state.api.getLiveOccurrence?.request,
        getLiveOccurrenceError: state.api.getLiveOccurrence?.error,
        satisfactionSubtitles: state.data.staticData?.satisfaction_subtitles
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setIsLiveModeEnabled
};

export default connect(mapStateToProps, mapDispatchToProps)(EndSessionScreen);
