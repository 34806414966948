import React from 'react';
import {Keyboard, ScrollView, StyleSheet, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useForm} from "react-hook-form";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import ErrorTextM from "@common/components/text/ErrorTextM";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import yup from "@data/utility/validation"
import {yupResolver} from '@hookform/resolvers/yup';
import {congratsTypes} from "@common/screens/CongratsScreen";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {IS_WEB} from "@common/utils/mobileUtils";

const formSchema = yup.object().shape({
    current_password: yup.string().required(),
    password: yup.string().required().min(8, 'Le mot de passe doit contenir 8 caractères minimum'),
    password_confirmation: yup.string().required().oneOf([yup.ref('password'), null], 'Le mot de passe de confirmation ne correspond pas')
})
const UpdatePasswordScreen = (props) => {
    const resolver = yupResolver(formSchema);
    const {navigation} = props
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: '#fff',
            paddingTop: 30,
            justifyContent: 'space-between'
        }
    });

    const {control, handleSubmit, formState: {errors, dirtyFields}} = useForm(
        {
            resolver,
            defaultValues: {
                current_password: '',
                password: '',
                password_confirmation: ''

            }
        });

    const onSubmit = data => {
        if (props.loggingIn) return
        Keyboard.dismiss()
        // console.log(data)
        props.apiRequest(authService.updatePassword, {}, {...data})
    }

    useApiStatus(
        authService.updatePassword, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {

            // console.log("success data", successData)

            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Enregistré",
                    text: "Ton mot de passe a bien été mis à jour"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )


    return (
        <View style={styles.container}>
            <View style={{
                marginHorizontal: 15
            }}>
                <ScrollView

                    contentContainerStyle={{
                        alignItems: 'center'
                        // paddingTop: 5
                    }}>

                    <TextInputM contentContainerStyle={{
                        marginBottom: 20
                    }}
                                name="current_password"
                                label="Ancien mot de passe"
                                textContentType="password"
                                password
                                control={control}
                                errors={errors}
                    />

                    <TextInputM contentContainerStyle={{
                        marginBottom: 20
                    }}

                                name="password"
                                label="Nouveau mot de passe"
                                textContentType="password"
                                password
                                control={control}
                                errors={errors}
                    />


                    <TextInputM contentContainerStyle={{
                        marginBottom: 20
                    }}

                                name="password_confirmation"
                                textContentType="password"
                                password
                                label="Confimer mot de passe"
                                control={control}
                                errors={errors}
                    />


                    <ErrorTextM errorData={props.updatePasswordError}/>

                    <FilledButtonM
                        style={{marginTop: 10, marginBottom: 20}}
                        onPress={handleSubmit(onSubmit)}
                        loading={props.updatePasswordRequest}
                        label={"Enregister"}
                    />

                    {IS_WEB && <FilledButtonM mode={"text"}
                                              icon={"chevron-left"}
                                              color={theme.colors.link}
                                              style={{marginTop: 60}}
                                              onPress={() => {
                                                  if (navigation.canGoBack())
                                                      navigation.goBack()
                                                  else
                                                      navigation.replace("settings", {initialTab: 0})
                                              }}
                                              label={"Retour"}/>}


                </ScrollView>
            </View>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        updatePasswordRequest: state.api.updatePassword?.request,
        updatePasswordSuccess: state.api.updatePassword?.success,
        updatePasswordError: state.api.updatePassword?.error,
        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordScreen)