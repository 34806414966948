import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import TextM from "../components/text/TextM";
import {theme} from "../theme/theme";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";

const SponsorshipConfirmation = props => {
    const {control, goToNextStep, register, errors, serverError, setFormValues, field} = props

    const dimensions = useContext(DimensionsContext)

    return <>
        <View style={{
            alignItems: "center",
            paddingTop: 10,
            width: dimensions?.width,
            flex: 1
        }}>
            <TextM fontSize={22}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center"
                       // marginTop: 50
                   }}
                   color={theme.colors.primary}>
                {"Bienvenue !"}
            </TextM>

            <TextM fontSize={18}
                   wrap
                   fontWeight="Regular"
                   style={{
                       textAlign: "center",
                       marginTop: 40,
                       marginHorizontal: 30
                   }}
                   color={theme.colors.grey}>
                {"Nous tenons à vous informer que vous bénéficiez d'un parrainage"}
            </TextM>

        </View>
        <FilledButtonM label={"Confirmer"}
                       color={theme.colors.success}
                       style={{
                           alignSelf: 'center',
                           width: IS_WEB && dimensions.width > 800 ? dimensions.width / 1.2 : dimensions.width - 20,
                       }}
                       onPress={goToNextStep}
        />
    </>

}
const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SponsorshipConfirmation)