import React, {useContext, useEffect, useState} from 'react';
import {View, TouchableOpacity, Pressable} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {formatFullDateTime} from "@data/utility/Utils";
import {BadgeM} from "@common/components/badge/BadgeM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {backOfficeService} from "@data/services/backOfficeService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ActivityIndicator, Chip} from "react-native-paper";
import AvatarM from "@common/components/avatar/AvatarM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

const NextOccurencesScreen = (props) => {
    const nextOccurrences = props.liveSession.next_occurrences;
    const reschedulings = props?.liveSession.reschedulings;
    //const substitutions = liveSession.substitutions;
    const [substitutions, setSubstitutions] = useState(props.liveSession.substitutions);
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    useEffect(() => {
        // console.log("props.liveSession", props.liveSession)
    }, [props.liveSession]);

    useApiStatus(
        backOfficeService.resetReschedulingsForLiveSession, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )

    useApiStatus(
        backOfficeService.requestSubstitution, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )
    useApiStatus(
        backOfficeService.removeSubstitution, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )

    return (
        <View style={{
            alignItems: 'center',
            marginTop: 40,
            marginBottom: 20
        }}>
            <TextM color={theme.colors.primary}
                   fontWeight={"Bold"}
                   fontSize={18}
                   style={{marginBottom: 20}}
            >
                {"Prochaines occurrences"}
            </TextM>
            <View style={{
                position: "absolute",
                top: 10,
                right: 10
            }}>

                    <FilledButtonM
                        style={{
                            width: 200,
                        }}
                        labelStyle={{fontSize: 8}}
                        contentStyle={{height: 20}}
                        disabled={props.resetReschedulingsRequest}
                        loading={props.resetReschedulingsRequest}
                        icon={"trash"}
                        color={theme.colors.grey}
                        onPress={() => {
                            DialogM.show({
                                text1: 'Réinitialiser toutes les replanifications de ce Live ?',
                                buttonText1: 'Réinitialiser', // buttonColor1: theme.colors.error,
                                onPress: () => {
                                    props.apiRequest(backOfficeService.resetReschedulingsForLiveSession, {id: props.liveSession?.id})
                                },
                                buttonText2: 'Annuler',
                                onPressBack: () => {

                                }
                            })
                        }}
                        label={"Réinitialiser"}
                    />
            </View>
            {props.liveSession.next_occurrences?.map((occ, index) => {
                const reschedulingForOcc = props?.liveSession.reschedulings?.find(res => res?.id === occ?.rescheduling_id);
                const substitutionForOcc = props.liveSession.substitutions?.find(sub => sub?.id === occ?.substitution_id);
                return <View style={{
                    width: dimensions?.width,
                    borderWidth: 1,
                    borderRadius: 10,
                    borderColor: theme.colors.lightMore,
                    padding: 10,
                    marginTop: 5
                }}
                             key={index}
                >
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <TextM fontWeight={'SemiBold'}>Date :</TextM>
                        <TextM fontWeight={'Bold'} color={theme.colors.success}>{formatFullDateTime(occ?.date)}</TextM>
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10
                    }}>
                        <TextM fontWeight={'SemiBold'}>Replanification :</TextM>
                        {reschedulingForOcc ?
                            <TouchableOpacity onPress={() => {
                                navigation?.push("reschedule-live", {
                                    liveId: reschedulingForOcc?.live_session_id,
                                    occurrenceDate: occ?.date
                                })
                            }}>
                                <BadgeM count={"Date initiale : "+formatFullDateTime(reschedulingForOcc?.previous_date)}
                                        style={{
                                            backgroundColor: theme.colors.light,
                                            color: theme.colors.white,
                                        }}/>
                            </TouchableOpacity>
                            :
                            <FilledButtonM
                                style={{
                                    width: 200,
                                }}
                                labelStyle={{fontSize: 8}}
                                contentStyle={{height: 20}}
                                onPress={() => {
                                    navigation?.push("reschedule-live", {
                                        liveId: props.liveSession?.id,
                                        occurrenceDate: occ?.date
                                    })
                                }}
                                label={"Replanifier"}
                            />
                        }
                    </View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 10
                    }}>
                        <TextM fontWeight={'SemiBold'}>Remplacement :</TextM>
                        {props.requestSubstitutionRequest && props.substitutionDate === occ?.date ?
                            <ActivityIndicator color={theme.colors.primary}/> :
                            substitutionForOcc ? substitutionForOcc?.substitute ?
                                    <Chip avatar={<View>
                                        <AvatarM size={25}
                                                 user={substitutionForOcc?.substitute}
                                                 style={{marginRight: 15}}
                                        /></View>}
                                          onPress={() => navigation?.push("accept-substitution", {
                                              subId: substitutionForOcc?.id,
                                          })}
                                          onClose={() => {
                                              DialogM.show({
                                                  text1: 'Annuler le remplacement',
                                                  text2: `Voulez-vous Annuler le remplacement ?`,
                                                  buttonText1: 'Oui',
                                                  buttonColor1: theme.colors.primary,
                                                  onPress: () => {
                                                      props.apiRequest(backOfficeService.removeSubstitution, {id: substitutionForOcc?.id})
                                                  },
                                                  buttonText2: 'Annuler'
                                              })
                                          }}
                                    >
                                        {substitutionForOcc?.substitute?.display_name}
                                    </Chip> :
                                    <TouchableOpacity onPress={() => {
                                        navigation?.push("accept-substitution", {
                                            subId: substitutionForOcc?.id,
                                        })
                                    }}>
                                        <BadgeM count={"Remplacement demandé"}
                                                style={{
                                                    backgroundColor: theme.colors.successLighter,
                                                    color: theme.colors.successLight,
                                                }}/>
                                    </TouchableOpacity>
                                :

                                <FilledButtonM
                                    style={{
                                        width: 200,
                                    }}
                                    labelStyle={{fontSize: 8}}
                                    contentStyle={{height: 20}}
                                    onPress={() => {
                                        const occDate = occ?.date
                                        DialogM.show({
                                            text1: 'Demander un remplacement',
                                            text2: `Voulez vous demander un remplacemnt pour l'occurence du ${formatFullDateTime(occDate)}?`,
                                            buttonText1: 'Oui',
                                            buttonColor1: theme.colors.primary,
                                            onPress: () => {
                                                props.apiRequest(backOfficeService.requestSubstitution, {id: props.liveSession?.id}, {
                                                    date: occDate
                                                })
                                            },
                                            buttonText2: 'Annuler'
                                        })
                                    }}
                                    label={"Demander un remplacement"}
                                />
                        }
                    </View>
                </View>
            })}
        </View>
    )
}
const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        rescheduleRequest: state.api?.reschedule?.request,
        resetReschedulingsRequest: state.api?.resetReschedulings?.request,
        requestSubstitutionRequest: state.api?.requestSubstitution?.request,
        requestSubstitutionError: state.api?.requestSubstitution?.error,
        rescheduleError: state.api?.reschedule?.error,
        liveSession: state.data.backOffice?.liveSession,
        substitutionDate: state.api.requestSubstitution?.data?.date,
        removeSubData: state.api.removeSubstitution,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NextOccurencesScreen)
