import TutorHomeTabScreen from "./home/TutorHomeTabScreen";
import {IconImageM} from "@common/components/icons/IconsM";
import home from "../../assets/icons/menus/home1.png";
import homeG from "../../assets/icons/menus/home1-g.png";
import MyStudentsTabScreen from "./students/MyStudentsTabScreen";
import students from "../../assets/icons/menus/students.png";
import studentsG from "../../assets/icons/menus/students-g.png";
import React, {useEffect} from "react";
import referral from "../../assets/icons/menus/referral.png";
import referralG from "../../assets/icons/menus/referral-g.png";
import loupe from "../../assets/icons/menus/loupe.png";
import loupeG from "../../assets/icons/menus/loupe-g.png";
import write from "../../assets/icons/menus/write.png";
import writeG from "../../assets/icons/menus/write-g.png";
import cog from "../../assets/icons/menus/cogwheel.png";
import cogG from "../../assets/icons/menus/cogwheel-g.png";
import school from "../../assets/icons/menus/school.png";
import schoolG from "../../assets/icons/menus/school-g.png";
import graduate from "../../assets/icons/menus/graduate.png";
import graduateG from "../../assets/icons/menus/graduate-g.png";
import {useDispatch} from "react-redux";
import * as Notifications from "expo-notifications";
import {incrementStudentMessengerNotReadCount} from "@data/redux/actions/data";
import TutorResources from "./home/tutorResources/TutorResources";
import Schools from "../school/Schools";
import Classrooms from "../teacher/classrooms/Classrooms";
import moment from "moment";
import {Platform} from "react-native";
import {nextInterviewForStudent} from "@data/utility/dataUtils";
import AdminActionsScreen from "../admin/AdminActionsScreen";
import ReviewActivitiesTabScreen from "../reviewer/ReviewActivitiesTabScreen";
import {hasRole} from "@data/utility/ability";
import TutorApplicationScreen from "./application/TutorApplicationScreen";
import {findClosestNextOccurrence} from "../student/account/onboarding/LiveRoomCard";
import ReferralsBackofficeScreen from "../admin/referrals/ReferralsBackofficeScreen";

export const tutorTabScreens = (user) => {
    const iconSize = 36

    const dispatch = useDispatch();
    useEffect(() => {
        const subscription = Notifications.addNotificationReceivedListener(notification => {
            if (notification.request.content.data.path === "messages") {
                dispatch(incrementStudentMessengerNotReadCount(notification.request.content.data.data?.owner_id))
            }
        });
        return () => subscription.remove();
    }, [])

    const countMessageNotifications = user?.students?.reduce((pValue, cValue) => cValue?.student_messenger_not_read_count + pValue, 0)

    const homeNotifications = user?.live_sessions
            ?.filter(liveSession => {
                const closestNextOccurrence = findClosestNextOccurrence([liveSession])

                const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null

                if (!liveMoment) return false

                return liveMoment?.isSame(moment(), "day")
            })
            ?.length
        + user?.students
            ?.map(x => nextInterviewForStudent(x))
            ?.filter(x => !!x)
            ?.filter(interview => {
                return moment(interview?.date_time).isSame(moment(), "day") || moment(interview.date_time).diff(moment(), "day") <= 0
            })
            ?.length

    const mt = (Platform.OS === "ios" ? 0 : 40)

    return [
        {
            name: "dashboard",
            component: hasRole(user, "tutor-candidate") ? TutorApplicationScreen : TutorHomeTabScreen,
            can: 'my_tutor_profile_completion',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={home}/> :
                    <IconImageM width={iconSize} source={homeG}/>,
                headerTitle: "Tableau de bord",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                tabBarBadge: homeNotifications > 0 ? homeNotifications : null
            }
        },
        {
            name: "schools",
            component: Schools,
            can: 'schools',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={school}/> :
                    <IconImageM width={iconSize} source={schoolG}/>,
                headerTitle: "Écoles",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                // tabBarBadge: badgeSSActivities > 0 ? badgeSSActivities : null
            }
        }, {
            name: "classrooms",
            component: Classrooms,
            can: 'classroom',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={graduate}/> :
                    <IconImageM width={iconSize} source={graduateG}/>,
                headerTitle: "Mes classes",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
                // tabBarBadge: badgeSSActivities > 0 ? badgeSSActivities : null
            }
        },
        {
            name: "students",
            component: MyStudentsTabScreen,
            //can: 'my_students',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize * 1.1} source={students}/> :
                    <IconImageM width={iconSize * 1.1} source={studentsG}/>,
                tabBarBadge: countMessageNotifications > 0 ? countMessageNotifications : null,
                headerTitle: "Mes élèves",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        },
        {
            name: "activities-review",
            component: ReviewActivitiesTabScreen,
            can: 'review_activities',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={loupe}/> :
                    <IconImageM width={iconSize} source={loupeG}/>,
                tabBarBadge: user?.pending_reviews_count > 0 ? user?.pending_reviews_count : null,
                headerTitle: "Revue d'activités",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        },
        {
            name: "tutor-resources",
            can: 'tutor_resources',
            component: TutorResources,
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={write}/> :
                    <IconImageM width={iconSize} source={writeG}/>,
                headerTitle: "Ressources pédagogiques",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        },
        // {
        //     name: "list-of-users",
        //     can: 'backoffice',
        //     component: UsersTabScreen,
        //     options: {
        //         tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={students}/> :
        //             <IconImageM width={iconSize} source={studentsG}/>,
        //         headerTitle: "Liste des utilisateurs"
        //     }
        // },
        {
            name: "admin-actions",
            can: 'backOffice',
            component: AdminActionsScreen,
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={cog}/> :
                    <IconImageM width={iconSize} source={cogG}/>,
                headerTitle: "Actions",
                headerShown: false,
                tabBarItemStyle: {
                    width: iconSize + 100,
                },
            }
        },
        {
            name: "referrals-backoffice",
            component: ReferralsBackofficeScreen,
            can: 'manage_referrals',
            options: {
                tabBarIcon: (status) => status?.focused ? <IconImageM width={iconSize} source={referral}/> :
                    <IconImageM width={iconSize} source={referralG}/>,
                headerTitle: "Affiliations",
                headerShown: false,
            }
        },

    ]
}