import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import React, {useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {formatFullDateTime} from "@data/utility/Utils";

const RescheduleLive = (props) => {

    const navigation = useNavigation()
    const {liveId, occurrenceDate} = props.route.params
    const [formValues, setFormValues] = useState(null)
    useApiStatus(
        backOfficeService.reschedule, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("menu")
        }
    )

    const defaultValues = {}

    const formData = [
        {
            label: "Replanifier le live",
            description: "La date précedente est " + formatFullDateTime(occurrenceDate),
            type: "date-time", // select.single, select.multiple, text, date
            name: "new_date",
            defaultValue: occurrenceDate,
            validationRule: {
                ...validation.required()
            }
        },
        {
            label: "Notifier les élèves et le tuteur ? ",
            // is_masked: !currentDay,
            type: "select.single", // select.single, select.multiple, text, date
            name: "notify",
            options:
                [
                    {
                        value: true,
                        label: "Oui",
                    },
                    {
                        value: false,
                        label: "Non",
                    },
                ],
            defaultValue: false,
        }
    ]

    const onSubmit = data => {
        if (props.rescheduleRequest) return
        props.apiRequest(backOfficeService.reschedule, {id: liveId}, {
            ...data,
            previous_date: occurrenceDate
        })
    }

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.rescheduleRequest}
                         previousRouteName={""}
                         submittedMessage=""
                         serverError={props.rescheduleError}
                         submitButtonText="Terminer"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        rescheduleRequest: state.api?.reschedule?.request,
        rescheduleError: state.api?.reschedule?.error,
        liveSession: state.data.backOffice?.liveSession
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleLive)