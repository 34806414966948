import {useEffect} from "react";
import {IS_WEB} from "@common/utils/mobileUtils";
import Constants from "expo-constants";

export const useMetaPixel = () => {
    useEffect(() => {
        // Only run in web and production environment
        if (IS_WEB && Constants.expoConfig.extra.releaseStage === "production") {
            // Dynamically inject the Meta Pixel script tag into the <head>
            (function (f, b, e, v, n, t, s) {
                if (f.fbq) return;
                n = f.fbq = function () {
                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                };
                if (!f._fbq) f._fbq = n;
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                t = b.createElement(e);
                t.async = !0;
                t.src = v;
                s = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s)
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            // Initialize the Meta Pixel with your Pixel ID
            fbq('init', '362490989992981');
            fbq('track', 'PageView');

            // Dynamically inject the noscript tag for tracking without JavaScript
            const noscript = document.createElement('noscript');
            const img = document.createElement('img');
            img.height = "1";
            img.width = "1";
            img.style.display = "none";
            img.src = "https://www.facebook.com/tr?id=362490989992981&ev=PageView&noscript=1";
            noscript.appendChild(img);
            document.body.insertBefore(noscript, document.body.firstChild);
        }
    }, []);
}