import React, {useContext, useState} from 'react';
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useNavigation} from "@react-navigation/native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {tutorsService} from "@data/services/tutorsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {findItemInListWithId} from "@data/utility/dataUtils";

const NewNoteScreen = (props) => {

    const {studentId, noteId} = props.route.params
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}
    const note = findItemInListWithId(noteId, props.user?.tutor_notes)


    const formData = [
        {
            label: !note ? "Nouvelle note" : "Modifier la note",
            description: null,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "last_results",
                    placeholder: "Derniers résultats",
                    height: 120,
                    defaultValue: note?.last_results
                },
                {
                    name: "next_deadlines",
                    placeholder: "Prochaines échéances",
                    height: 120,
                    defaultValue: note?.next_deadlines
                },
                {
                    name: "other",
                    placeholder: "Autre",
                    height: 120,
                    defaultValue: note?.other
                }
            ]
        }
    ]

    const onSubmit = data => {
        if (props.newNoteRequest || props.updateNoteRequest) return
        //console.log(data)
        if (note)
            props.apiRequest(tutorsService.updateNote, {id: note?.id}, {
                ...data
            })
        else
            props.apiRequest(tutorsService.postNote, {id: studentId}, {
                ...data
            })
    }

    useApiStatus(
        tutorsService.postNote, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(tutorsService.getNotes, {id: studentId})
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("student-notes")
        } // success callback
    )
    useApiStatus(
        tutorsService.updateNote, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            //console.log(data)
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("student-notes")
        } // success callback
    )

    return (
        <View style={{
            alignSelf: 'center',
            width: dimensions?.width,
            flex: 1,
            alignItems: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.newNoteRequest || props.updateNoteRequest}
                         previousRouteName={"student-notes"}
                         submittedMessage=""
                         serverError={props.newNoteError || props.updateNoteError}
            />
        </View>
    )
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        newNoteRequest: state.api.postNote?.request,
        newNoteError: state.api.postNote?.error,
        updateNoteRequest: state.api.updateNote?.request,
        updateNoteError: state.api.updateNote?.error,
        notes: state.data.tutors?.notes
    };
};
const mapDispatchToProps = {
    apiRequest,
    apiIdle
};
export default connect(mapStateToProps, mapDispatchToProps)(NewNoteScreen);