import React, {useContext, useRef} from 'react';
import {FlatList, TouchableOpacity} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import Body from "@common/avatar/components/body";
import Hair from "@common/avatar/components/hair";
import FacialHair from "@common/avatar/components/facial-hair";
import Eyes from "@common/avatar/components/eyes";
import Nose from "@common/avatar/components/nose";
import Shirt from "@common/avatar/components/shirt";
import Jacket from "@common/avatar/components/jacket";

const CustomizeShapes = (props) => {

    const flatListRef = useRef();
    const {avatar, setAvatar, name, colors, data, color = "bleu"} = props
    const dimensions = useContext(DimensionsContext)
    const renderShape = (shape, value) => {
        switch (shape) {
            case "body":
                return <Body value={value} color={color} persona={false}/>
            case "hair":
                return <Hair value={value} color={color} persona={false}/>
            case "facialHair":
                return <FacialHair value={value} color={color}/>
            case "eyes":
                return <Eyes value={value} persona={false} color={color}/>
            case "nose":
                return <Nose value={value} color={color}/>
            case "shirt":
                return <Shirt value={value} persona={false}/>
            case "jacket":
                return <Jacket value={value} persona={false}/>
        }
    }
    return (
        <FlatList data={data}
                  ref={flatListRef}
                  style={{
                      width: dimensions?.width,
                      height: dimensions?.height / 2.1,
                  }}
                  contentContainerStyle={{
                      alignItems: "center",
                      justifyContent: 'center',
                      paddingBottom: 120
                  }}
                  keyExtractor={(item, index) => index.toString()}
                  numColumns={2}
                  renderItem={(item) => {
                      return <TouchableOpacity style={{
                          width: 200,
                          height: 140,
                          alignItems: 'center',
                          justifyContent: 'center'
                      }}
                                               onPress={() => {
                                                   if (item.item?.name !== 'jacket0' && item.item?.name !== 'hair0')
                                                       setAvatar({...avatar, [name]: item.item?.name})
                                                   else setAvatar({...avatar, [name]: null})
                                               }}
                      >

                          {renderShape(name, item.item.name)}
                      </TouchableOpacity>
                  }}
        />
    );
}

export default CustomizeShapes;