import React, {useContext, useEffect, useState} from 'react';
import {theme} from "../theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import {staticDataService} from "@data/services/staticDataService";
import {hasRole} from "@data/utility/ability";
import {AbilityContext} from "@data/utility/Can";
import NotificationSubscriptionHandler from "../notifications/NotificationSubscriptionHandler";
import {Dimensions, Platform} from "react-native";
import {clearActivityData} from "@data/redux/actions/formData";
import {tutorTabScreens} from "../../tutor/tutorTabScreens";
import {studentTabScreens} from "../../student/studentTabScreens";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useTourGuideController} from "rn-tourguide";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import * as amplitude from "@amplitude/analytics-react-native";
import {clearLastNotificationIdentifier, setLastNotificationIdentifier} from "@data/redux/actions/notifications";
import {skillSetsService} from "@data/services/skillSetsService";
import {studentsService} from "@data/services/studentsService";
import {versionCompare} from "@data/utility/Utils";
import Constants from "expo-constants";
import VersionUpdateModal from "@common/components/modal/VersionUpdateModal";
import {setStatusBarStyle} from "expo-status-bar";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {createBottomTabNavigator} from "@react-navigation/bottom-tabs";
import RNCallKeep from "react-native-callkeep";


const Tab = createBottomTabNavigator();
//const Tab = createMaterialBottomTabNavigator();

const BottomTabsNavigator = (props) => {

    const {navigation} = props

    const [hasMinimumVersion, setHasMinimumVersion] = useState(true)

    useApiStatus(
        authService.getUser, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data)
        } // success callback
    )

    useApiStatus(
        staticDataService.getStaticData, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data?.genius_scan_key)
            if (versionCompare(data?.min_client_version, Constants.expoConfig?.version) === 1) {
                setHasMinimumVersion(false)
            }


        } // success callback
    )

    useApiStatus(
        authService.championIntroViewed, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            props.apiIdle(authService.championIntroViewed)
            // navigation.replace("menu")
        } // success callback
    )
    useApiStatus(
        studentsService.getStudents, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data)
            // navigation.replace("menu")
        } // success callback
    )

    // Use Hooks to control!
    const {
        canStart = false, // a boolean indicate if you can start tour guide
        start, // a function to start the tourguide
        stop, // a function  to stopping it
        eventEmitter // an object for listening some events
    } = IS_WEB ? {} : useTourGuideController()

    // Can start at mount 🎉
    // you need to wait until everything is registered 😁
    React.useEffect(() => {
        if (!IS_WEB && canStart && hasRole(props.user, "student") && props.user?.champion_intros_viewed_at?.zoe && !props.user?.champion_intros_viewed_at?.zoe_menus_v1) {
            // 👈 test if you can start otherwise nothing will happen
            start()
            amplitude.track("animation.loaded:didacticiel_zoe")
        }
    }, [canStart, props.user?.champion_intros_viewed_at?.zoe]) // 👈 don't miss it!
    const handleOnStop = () => {
        props.apiRequest(authService.championIntroViewed, {}, {intro_name: "zoe_menus_v1"})
    }
    const dimensions = useContext(DimensionsContext)

    const insets = useSafeAreaInsets()

    React.useEffect(() => {

        if (hasRole(props.user, "student") && !props.user?.champion_intros_viewed_at?.zoe) {
            const nextScreen = {
                nextScreen: "intro",
                nextScreenParams: {
                    name: "zoe"
                }
            }

            const congrats = []

            if (props.user?.live_sessions?.length > 0) {
                congrats.push({
                    congratsType: congratsTypes.OFFER_CHOSEN,
                    congratsData: {
                    },
                    hideButton: true
                })

            } else if (hasRole(props.user, "holidaycourse-student")) {
                congrats.push({
                    congratsType: congratsTypes.GENERIC,
                    congratsData: {
                        title: "Votre inscription a bien été prise en compte",
                        text: "Rendez-vous Samedi 3 février à 10h pour le Live spécial"
                    }
                })
            } else {
                congrats.push({
                    congratsType: congratsTypes.GENERIC,
                    congratsData: {
                        title: "Votre demande a bien été prise en compte",
                        text: "Nous vous contacterons dans les plus brefs délais pour vous attribuer une séance d'essai gratuit"
                    }
                })
            }

            if (IS_WEB) {
                congrats.push({
                    congratsType: congratsTypes.DOWNLOAD_APP,
                    congratsData: {}
                })
            }

            setTimeout(() => {
                navigation.push("congrats", {
                    congrats,
                    ...nextScreen
                })
            }, 300);
        }

    }, [props.user?.champion_intros_viewed_at?.zoe])

    React.useEffect(() => {
        if (!IS_WEB) {

            // clean any remaining active call
            RNCallKeep.endAllCalls()

            eventEmitter.on('stop', handleOnStop)

            return () => {
                eventEmitter.off('stop', handleOnStop)
            }
        }
    }, [])


    //** ComponentDidMount
    useEffect(() => {
        if (props.user) {
            const identifyObj = new amplitude.Identify();
            identifyObj.set('roles', props.user?.roles);
            identifyObj.set('subscription_state', props.user?.subscription_state?.status);
            amplitude.identify(identifyObj)
            amplitude.setUserId(props.user?.email)
        }
        setStatusBarStyle('dark')

        setTimeout(function () {
            props.apiRequest(staticDataService.getStaticData) //TODO : optimiser cette requête (une seule fois par heure ?)
            props.apiRequest(authService.getUser)
            if (hasRole(props.user, 'student'))
                props.apiRequest(skillSetsService.getSkillSets)
            else if (hasRole(props.user, 'tutor'))
                props.apiRequest(studentsService.getStudents)
        }, 500)

        // setTimeout(function () {
        //
        // }, 800)

        // Linking.addEventListener('url', _handleUrl)
        // return () => {
        //     Linking.removeEventListener('url', _handleUrl)
        // }

    }, [])

    // const championModalRef = useContext(ChampionModalContext)


    // const _handleUrl = ({url}) => {
    //     let {path, queryParams} = Linking.parse(url);
    //     console.log(`Linked to app with path: ${path} and data: ${JSON.stringify(queryParams)}`)
    //     alert(`Linked to app with path: ${path} and data: ${JSON.stringify(queryParams)}`);
    // };

    const ability = useContext(AbilityContext)

    const tabScreens = (hasRole(props.user, 'student')
        ? studentTabScreens(props.user, props.activityTemplates, props.isLiveMode)
        : tutorTabScreens(props.user))?.filter(screen => !screen?.can || ability?.can('read', screen?.can))

    return (
        <>
            {Platform.OS !== 'web' && <NotificationSubscriptionHandler/>}
            {!IS_WEB && !hasMinimumVersion && !__DEV__ && <VersionUpdateModal/>}
            {!!props.user && <Tab.Navigator
                screenOptions={{
                    tabBarShowLabel: false,
                    unmountOnBlur: false,
                    tabBarStyle: {
                        backgroundColor: theme.colors.white,
                        borderTopWidth: 1,
                        borderColor: theme.colors.lightMore,
                        height: (IS_WEB ? 74 : 80) + insets.bottom/2,
                        width: Platform.OS === 'web' ? Dimensions.get('window').width : null,
                        alignSelf: Platform.OS !== 'web' ? null : "center",
                        alignItems: 'center',
                    }
                }}
                // initialRouteName="Home"
                shifting={tabScreens?.length > 1}
                labeled={false}
                sceneAnimationEnabled={false}
                activeColor={theme.colors.white}
                inactiveColor={theme.colors.white}
            >
                {tabScreens.map(screen => {
                    return <Tab.Screen
                        key={screen.name}
                        name={screen.name}
                        component={screen.component}
                        options={screen.options}
                    />

                })
                }
            </Tab.Navigator>}
        </>
    )
}

const mapStateToProps = state => {
    return {
        getStaticDataError: state.api.getStaticData?.error,
        getUserError: state.api.getUser?.error,

        savedActivityData: state.data?.formData?.activity,

        user: state.data.currentUser?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        minimumVersion: state.data.staticData?.min_client_version,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        lastNotificationIdentifier: state.notifications.lastIdentifier
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    clearActivityData,
    setLastNotificationIdentifier,
    clearLastNotificationIdentifier
}

export default connect(mapStateToProps, mapDispatchToProps)(BottomTabsNavigator)
