import axios from "axios"

function getChaptersAsStudent({}, {}, params) {
    return axios.get(`api/v1/chapters`, {params})
}

function getChaptersAsTutor({id}, {}, params) {
    if (!id)
        return axios.get(`/api/v1/students/chapters`, {params})
    else
        return axios.get(`/api/v1/students/${id}/chapters`, {params})
}

function getChaptersForStudents({}, {}, params) {
    return axios.get(`/api/v1/students/chapters`, {params})
}

function getChaptersAsAdmin({}, {}, params) {
    return axios.get(`/api/backoffice/chapters`, {params})
}

function newChapter({}, data) {
    return axios.post(`/api/backoffice/chapters`, data)
}

function archiveChapter({id}, data) {
    return axios.post(`/api/v1/student-chapters/${id}/archive`, data)
}

function archiveChapterForStudents({}, data) {
    return axios.post(`/api/v1/student-chapters/archive`, data)
}

function studentChapterFeelings({id}, data) {
    return axios.post(`/api/v1/student-chapters/${id}/fill-quiz`, data)
}

export const chaptersService = {
    getChaptersAsStudent,
    getChaptersAsTutor,
    getChaptersAsAdmin,
    archiveChapter,
    studentChapterFeelings,
    newChapter,
    getChaptersForStudents,
    archiveChapterForStudents
}

