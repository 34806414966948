import {apiConstants} from "@data/constants/apiConstants"
import {backOfficeService} from "@data/services/backOfficeService"
import {authService} from "@data/services/authService"
import {authConstants} from "@data/constants/authConstants";
import {tutorsService} from "@data/services/tutorsService";

// ** Initial State
const initialState = {
    list: [],
    usersList: [],
    usersListFormStep: [],
}

export function tutors(state = initialState, action) {
    switch (action.type) {

        case authConstants.LOGOUT_401:
        case authService.logout.name + apiConstants._SUCCESS:
        case authService.revokeSanctumToken.name + apiConstants._SUCCESS:
        case authService.deleteAccount.name + apiConstants._SUCCESS:
            return initialState

        case backOfficeService.assignTutor.name + apiConstants._SUCCESS:
        case backOfficeService.updateSalary.name + apiConstants._SUCCESS:
        case backOfficeService.updateMaxStudentsPerLive.name + apiConstants._SUCCESS:
        case backOfficeService.assignAdmin.name + apiConstants._SUCCESS:
        case backOfficeService.changePrivileges.name + apiConstants._SUCCESS:
        case backOfficeService.updatePrioritizedSchoolSubjects.name + apiConstants._SUCCESS:
        case backOfficeService.updateChannel.name + apiConstants._SUCCESS:
        case backOfficeService.getUserBackoffice.name + apiConstants._SUCCESS:
        case backOfficeService.acceptNewStudents.name + apiConstants._SUCCESS:
        case backOfficeService.updateSlotTutor.name + apiConstants._SUCCESS:
        case backOfficeService.updateUserBackoffice.name + apiConstants._SUCCESS:
        case backOfficeService.updateStudent.name + apiConstants._SUCCESS:
        case backOfficeService.updateTutor.name + apiConstants._SUCCESS:
        case backOfficeService.validateTutor.name + apiConstants._SUCCESS:
        case backOfficeService.postNewTrialLiveSessionChoiceBackoffice.name + apiConstants._SUCCESS:
            return {
                ...state,
                usersList: state.usersList?.length === 0 ? [action.data] : state.usersList?.map((user) => user?.id === action.data?.id ? action.data : user)
            }

        case tutorsService.submitApplicationEvaluation.name + apiConstants._SUCCESS:
            return {
                ...state,
                usersList: state.usersList?.map((user) => user?.id === action.data?.id ? action.data : user)
            }

        case backOfficeService.getAllUsers.name + apiConstants._SUCCESS:
            return {
                ...state,
                list: action.data
            }

        case backOfficeService.searchUserFormStep.name + apiConstants._SUCCESS:
            let usersListFormStep = []
            //console.log("meta", action.data)
            if (action.data?.meta?.current_page === 1) { // refresh
                usersListFormStep = action.data?.users
            } else { // concat
                usersListFormStep = state.usersListFormStep.concat(action.data?.users)
            }
            return {
                ...state,
                usersListFormStep: usersListFormStep
            }

        case backOfficeService.deleteUser.name + apiConstants._SUCCESS:
            return {
                ...state,
                usersList: state.usersList?.filter((user) => user?.id !== action.routeParams?.id)
            }

        case backOfficeService.searchUser.name + apiConstants._SUCCESS:
            let usersList = []
            //console.log("meta", action.data)
            if (action.data?.meta?.current_page === 1) { // refresh
                usersList = action.data?.users
            } else { // concat
                usersList = state.usersList.concat(action.data?.users)
            }
            return {
                ...state,
                usersList: usersList
            }
        case backOfficeService.putStudentAccount.name + apiConstants._SUCCESS:
            //console.log(action.data)
            const updateStudentIfFoundInStore = a => {
                return action.data?.id === a?.id ? action.data : a
            }
            return {
                ...state,
                usersList: state.usersList?.map(updateStudentIfFoundInStore)
            }
        case backOfficeService.putTutorAccount.name + apiConstants._SUCCESS:
            const updateTutorIfFoundInStore = a => {
                return action.data?.id === a?.id ? action.data : a
            }
            return {
                ...state,
                usersList: state.usersList?.map(updateTutorIfFoundInStore)
            }

        case backOfficeService.createWeeklyReport.name + apiConstants._SUCCESS:
            return {
                ...state,
                report: action.data
            }
        case backOfficeService.sendWeeklyReport.name + apiConstants._SUCCESS:
        case backOfficeService.getAppreciation.name + apiConstants._SUCCESS:
        case backOfficeService.assignTutorAndLiveSlots.name + apiConstants._SUCCESS:
            const updateStudentTutorIfFoundInStore = a => {
                return action.data?.id === a?.id ? {...a, tutor: action.data?.tutor} : a
            }
            return {
                ...state,
                usersList: state.usersList?.map(updateStudentTutorIfFoundInStore)
            }
        case backOfficeService.updateTutorSlots.name + apiConstants._SUCCESS:
            return {
                ...state,
                usersList: state.usersList?.map(user => {
                    return user.id === action.data?.id ? action.data : user
                })
            }
        case backOfficeService.newStudent.name + apiConstants._SUCCESS:

            return {
                ...state,
                usersList: [...state.usersList, action?.data]
            }

        default:
            return state
    }
}

export default tutors
