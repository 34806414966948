import {ScrollView} from "react-native";
import React, {useContext} from "react"
import InterviewStatisticsCard from "../InterviewStatisticsCard";
import {theme} from "@common/theme/theme";
import TextInputM from "@common/components/input/TextInputM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {useForm} from "react-hook-form";
import TextM from "@common/components/text/TextM";
import InterviewHeader, {InterviewTitle} from "../InterviewHeader";

export default function InterviewStepFinal({student, setGlobalComment, interview, control, errors}) {

    // const defaultValues = {
    //     comment: interview?.global_comment,
    //     status: "validated"
    // }
    // const {control, handleSubmit, formState: {errors, dirtyFields}, getValues, trigger} = useForm({
    //     defaultValues
    // })
    const dimensions = useContext(DimensionsContext)

    return (<>
            <InterviewHeader student={student}
                             interview={interview}
            />

            <ScrollView style={{marginBottom: 40, paddingTop: 10}}>
                {InterviewTitle("Synthèse")}

                {/*<View style={{*/}
                {/*    backgroundColor: theme.colors.white,*/}
                {/*    borderColor: theme.colors.lightMore,*/}
                {/*    borderRadius: 10,*/}
                {/*    borderWidth: 1,*/}
                {/*    margin: 20,*/}
                {/*    paddingBottom: 20,*/}
                {/*}}>*/}
                <TextM fontWeight={"Bold"} fontSize={20} style={{
                    // padding: 10,
                    marginTop: 20,
                    marginBottom: 10,
                    textAlign: IS_WEB && dimensions.width > 800 ? "left" : "center"
                }}>
                    Message aux parents
                </TextM>
                <TextInputM
                    placeholder="Pour conclure l'entretien..."
                    name="global_comment"
                    style={{
                        width: IS_WEB && dimensions.width > 800 ? dimensions?.width : dimensions?.width - 20,
                        height: 120,
                        backgroundColor: theme.colors.white
                    }}
                    multiline={true}
                    // numberOfLines={3}
                    // textContentType="emailAddress"
                    // keyboardType="email-address"
                    control={control}
                    errors={errors}
                    onChangeAdditional={(value) => {
                        setGlobalComment(value)
                    }}
                />
                {/*</View>*/}
                <InterviewStatisticsCard statistics={student?.statistics}/>


            </ScrollView>
        </>
    )
}