import React from "react";
import {FlatList, SectionList, View} from "react-native";
import {theme} from "../../theme/theme";
import {IS_WEB} from "../../utils/mobileUtils";

export function ListGroupM(props) {

    // ** Component props
    const {options, renderItem, initialScrollIndex = 0, itemHeight, style, sectionMode = false, renderSectionHeader, contentContainerStyle} = props

    return <>
        {!sectionMode ? <FlatList data={options}
                                  initialScrollIndex={IS_WEB ? null : initialScrollIndex}
                                  getItemLayout={(data, index) => (
                                      {length: itemHeight, offset: itemHeight * index, index}
                                  )}
                                  style={{
                                      borderWidth: 1,
                                      borderRadius: 15,
                                      borderColor: theme.colors.lightMore,
                                      marginTop: 10,
                                      marginBottom: 20,
                                      flexGrow: IS_WEB ? null : 0,
                                      width: "100%",
                                      ...style
                                  }}
                                  contentContainerStyle={{
                                      borderRadius: 15,
                                      overflow: "hidden",
                                      ...contentContainerStyle
                                  }}
                                  keyExtractor={(item, index) => index.toString()}
                                  renderItem={renderItem}/> : <SectionList sections={options}
                                                                           stickySectionHeadersEnabled={false}
                                                                           initialScrollIndex={IS_WEB ? null : initialScrollIndex}
                                                                           keyExtractor={(item, index) => item + index}
                                                                           renderSectionHeader={renderSectionHeader}
                                                                           getItemLayout={(data, index) => (
                                                                               {
                                                                                   length: itemHeight,
                                                                                   offset: itemHeight * index,
                                                                                   index
                                                                               }
                                                                           )}
                                                                           style={{
                                                                               borderWidth: 1,
                                                                               borderRadius: 15,
                                                                               borderColor: theme.colors.lightMore,
                                                                               marginTop: 10,
                                                                               marginBottom: 20,
                                                                               flexGrow: 0,
                                                                               width: "100%",
                                                                               ...style
                                                                           }}
                                                                           contentContainerStyle={{
                                                                               ...contentContainerStyle
                                                                           }}
                                                                           renderItem={renderItem}/>}
    </>
}