import axios from "axios"


function markAsRead({id}) {
  return axios.post(`/api/achievements/${id}/read`)
}


export const achievementsService = {
  markAsRead
}

