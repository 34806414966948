import {Platform, RefreshControl, SectionList, View} from "react-native";
import React, {useContext, useRef} from 'react'
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {DimensionsContext} from "../utils/mobileUtils";
import {RankingListItem} from "../../student/social/RankingScreen";
import TextM from "../components/text/TextM";
import {theme} from "../theme/theme";
import {clearMembers, setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {liveService} from "@data/services/liveService";

const LiveParticipants = (props) => {
    const flatListRef = useRef()
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const liveSession = props.currentLiveSession

    const studentParticipants = liveSession?.users?.filter(x => props.participants?.includes(x?.id) && !x?.is_tutor) ?? []
    const tutorParticipants = liveSession?.users?.filter(x => props.participants?.includes(x?.id) && x?.is_tutor) ?? []

    const request = props.getSessionsRequest || props.getCallRequest


    // console.log(props.currentLiveSession)
    const refreshLiveSession = () => {
        props.apiRequest(liveService.getLiveSession, {id: props.liveSessionId})
        props.apiRequest(liveService.getCall, {id: props.liveSessionId})
    }


    // console.log("studentParticipants", studentParticipants)
    // console.log("tutorParticipants", tutorParticipants)
    // console.log("props.participants", props.participants)
    // console.log("liveSession", liveSession)

    return (
        <View style={{
            //marginVertical: 10,
            // marginBottom: 235,
            // width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: 'center',
            alignItems: 'center',
            flex: 1,
            width: "100%"
        }}>
            <HeaderContainerM>
                <TitleM>Participants</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>


            <SectionList sections={[
                {
                    title: "Élèves (" + studentParticipants?.length + ")",
                    data: studentParticipants
                },
                {
                    title: "Masteur (" + tutorParticipants?.length + ")",
                    data: tutorParticipants
                }
            ]}
                         stickySectionHeadersEnabled={false}
                         refreshControl={
                             <RefreshControl
                                 onRefresh={() => {
                                     refreshLiveSession()
                                 }}
                                 refreshing={!!request}
                             />
                         }
                         style={{
                             flex: 1
                         }}
                         contentContainerStyle={{
                             // flex: 1,
                             width: dimensions?.width
                             // alignItems: 'center'
                         }}
                         ref={flatListRef}
                         showsHorizontalScrollIndicator={Platform.OS === "web" && false}
                         renderSectionHeader={({section: {title}}) => (
                             <View style={{
                                 paddingLeft: 15,
                                 paddingTop: 15,
                                 backgroundColor: theme.colors.whiteAlmost,
                                 flex: 1
                             }}>
                                 <TextM color={theme.colors.light}
                                        fontSize={20}
                                 >
                                     {title}
                                 </TextM>
                             </View>
                         )}
                // ListFooterComponent={<View style={{
                //     margin: 100
                // }}>
                //     <FilledButtonM
                //         onPress={() => {
                //             DialogM.show({
                //                 text1: 'Veux-tu vraiment quitter ?',
                //                 text2: 'Tu seras déconnecté du LIVE',
                //                 buttonText1: 'Quitter le LIVE',
                //                 buttonColor1: theme.colors.error,
                //                 onPress: exit,
                //                 buttonText2: 'Annuler'
                //             })
                //         }}
                //         label="Quitter le live"
                //         color={theme.colors.error}
                //         textColor={theme.colors.white}
                //
                //         icon={"sign-out-alt"}
                //         style={{
                //             alignSelf: "center"
                //         }}
                //     />
                // </View>}
                         renderItem={(item) => {

                             return <RankingListItem user={item.item}
                                                     isHighlighted={false}
                                                     height={60}
                                                     isLiveMode
                                                     isInCall={props.inCallUsers?.find(id => id === item.item.id)}
                             />
                         }}
            />
        </View>
    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        usersList: state.data.users?.list,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        participants: state.liveMode?.liveParticipants,
        // tutors: state.liveMode?.liveTutors,
        call: state.data.currentUser?.object?.call,
        inCallUsers: state.liveMode?.inCallUsers
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setIsLiveModeEnabled,
    clearMembers
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveParticipants)
