import React, {useEffect, useState} from 'react';
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {weekDays, weekDaysObject} from "@data/constants/activitiesConstants";
import {slotsList} from "@data/constants/slotTutorConstants";
import moment from "moment";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {authService} from "@data/services/authService";
import * as yup from "yup";


const SlotUpdateScreen = (props) => {

    const slotId = props.route.params.slotId
    const tutor = props.user
    const slot = tutor?.interview_slots?.find(s => s?.id === parseInt(slotId))
    const slotMoment = slot?.day_time ? moment(slot?.day_time, "dddd HH:mm", "en") : null
    const slotMomentDay = slotMoment ? slotMoment?.format("dddd") : null
    const slotMomentTime = slotMoment ? slotMoment?.format("HH:mm") : null

    const [formValues, setFormValues] = useState(null)

    let currentDay = formValues?.new_day
    const currentAllNextInterviews = !formValues ? true : formValues?.all_next_interviews

    let possibleTimes = slotsList("07:00", "22:00", 20)
        ?.map(x => {
            const xMomentFormatted = `${currentDay} ${x?.value}`
            const isTaken = tutor?.interview_slots?.find(s => (s?.day_time === xMomentFormatted) && s?.is_taken)
            return {
                ...x,
                disabled: isTaken && x?.value !== slotMomentTime
            }
        })

    const navigation = useNavigation()

    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Jour",
            description: "Ancien jour : " + weekDaysObject[slotMomentDay],
            type: "select.single", // select.single, select.multiple, text, date
            name: "new_day",
            options: weekDays
                ?.map(x => {
                    return {
                        value: x,
                        label: weekDaysObject[x]
                    }
                }),
            defaultValue: slotMomentDay ?? "",
            validationRule: yup.string().required()
        },
        {
            label: "Heure",
            description: "Ancienne heure : " + slotMomentTime,
            // is_masked: !currentDay,
            type: "select.single", // select.single, select.multiple, text, date
            name: "new_time",
            options: possibleTimes,
            defaultValue: slotMomentTime ?? "",
            validationRule: yup.string().required()

        },
        {
            label: "Mettre à jour",
            // is_masked: !currentDay,
            type: "select.single", // select.single, select.multiple, text, date
            name: "all_next_interviews",
            options: [
                {value: true, label: "Tous les entretiens à venir"},
                {value: false, label: "Uniquement le prochain entretien"}
            ],
            defaultValue: false,
            validationRule: yup.boolean().required()
        }
    ]

    const onSubmit = data => {
        //console.log("data", data)
        if (props.updateStudentSlotRequest) return
        props.apiRequest(authService.updateStudentSlot, {slotId, tutorId: tutor?.id}, data)
    }

    useApiStatus(
        authService.updateStudentSlot, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            props.apiIdle(authService.updateStudentSlot)
            // navigation.goBack()
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Le créneau a bien été mis à jour"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )
    const defaultValues = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log("PARENT :")
        // console.log(defaultValues)

    }, [formValues])

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.updateStudentSlotRequest}
                         previousRouteName={"update-slots"}
                         submittedMessage=""
                         serverError={props.updateStudentSlotError}
                         submitButtonText="Mettre à jour"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        updateStudentSlotRequest: state.api.updateStudentSlot?.request,
        updateStudentSlotSuccess: state.api.updateStudentSlot?.success,
        updateStudentSlotError: state.api.updateStudentSlot?.error,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiRequestWithPrerequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SlotUpdateScreen)