import {apiConstants} from "@data/constants/apiConstants";
import {interviewReportService} from "@data/services/interviewReportService";
import {gradesService} from "@data/services/gradesService";

const initialState = {
    interviewReportsList: [],
    prioritizedSchoolSubjects: [],
    interviewStats: {}
}

export default function interviewReports(state = initialState, action) {
    switch (action.type) {
        case interviewReportService.getStudentInterviewReports.name + apiConstants._SUCCESS:
            let interviewReportsList = []
            if (action.data?.meta?.current_page === 1) {
                interviewReportsList = action.data?.interview_reports
            } else {
                interviewReportsList = state.interviewReportsList.concat(action.data?.interview_reports)
            }
            return {
                ...state,
                interviewReportsList: interviewReportsList,
                prioritizedSchoolSubjects: action.data?.prioritized_school_subjects
            }
        case interviewReportService.getInterviewReport.name + apiConstants._SUCCESS:
        case interviewReportService.newInterviewReport.name + apiConstants._SUCCESS:
            return {
                ...state,
                interviewReportsList: [action.data, ...state.interviewReportsList]
            }
        case interviewReportService.getPrioritizedSchoolSubjects.name + apiConstants._SUCCESS:
            return {
                ...state,
                prioritizedSchoolSubjects: action.data
            }
        case gradesService.newGrade.name + apiConstants._SUCCESS:
            // console.log(action)
            return {
                ...state,
                prioritizedSchoolSubjects: state.prioritizedSchoolSubjects.map((schoolSubject) => {
                    if (schoolSubject?.id === action.data?.school_subject_id) {
                        if (schoolSubject?.last_grades?.length < 5) {
                            schoolSubject.last_grades = schoolSubject.last_grades.concat(action.data).sort((a, b) => {
                                // if dates are equal sort by created_at
                                if (new Date(b?.date) - new Date(a?.date) === 0) {
                                    return new Date(b?.created_at) - new Date(a?.created_at);
                                }
                                return new Date(b?.date) - new Date(a?.date);
                            })
                            // console.log(schoolSubject.last_grades)
                        } else {
                            // if grade date is more recent than the earliest grade date add it to the list and remove the earliest grade
                            if (new Date(action.data?.date) > new Date(schoolSubject.last_grades[4]?.date)) {
                                schoolSubject.last_grades = schoolSubject.last_grades.concat(action.data).sort((a, b) => {
                                    // if dates are equal sort by created_at
                                    if (new Date(b?.date) - new Date(a?.date) === 0) {
                                        return new Date(b?.created_at) - new Date(a?.created_at);
                                    }
                                    return new Date(b?.date) - new Date(a?.date);
                                })
                                schoolSubject.last_grades.pop()
                            } else if (action.data?.date === schoolSubject.last_grades[0]?.date) {
                                // shift the list and add the new grade
                                schoolSubject.last_grades.unshift(action.data)
                                schoolSubject.last_grades.pop()
                            }
                        }
                    }
                    return schoolSubject
                })
            }
        case gradesService.deleteGrade.name + apiConstants._SUCCESS:
            // console.log(action.data)
            return {
                ...state,
                prioritizedSchoolSubjects: state.prioritizedSchoolSubjects.map((schoolSubject) => {
                    schoolSubject.last_grades = schoolSubject.last_grades.filter((grade) => grade?.id !== action?.routeParams?.id)
                    return schoolSubject
                })
            }
        case gradesService.updateGrade.name + apiConstants._SUCCESS:
            // console.log(action.data)
            return {
                ...state,
                prioritizedSchoolSubjects: state.prioritizedSchoolSubjects.map((schoolSubject) => {
                    schoolSubject.last_grades = schoolSubject.last_grades.map((grade) => {
                        if (grade?.id === action.data?.id) {
                            return action.data
                        }
                        return grade
                    }).sort((a, b) => {
                        return new Date(b?.date) - new Date(a?.date);
                    })
                    return schoolSubject
                })
            }
        case interviewReportService.getInterviewStatistics.name + apiConstants._SUCCESS:
            // console.log(action.data)
            return {
                ...state,
                interviewStats: action.data?.statistics
            }
        case interviewReportService.addPrioritizedSchoolSubjects.name + apiConstants._SUCCESS:
            // console.log(action.data)
            return {
                ...state,
                prioritizedSchoolSubjects: state.prioritizedSchoolSubjects.concat(action.data)
            }
        case interviewReportService.removePrioritizedSchoolSubject.name + apiConstants._SUCCESS:
            // console.log(action)
            return {
                ...state,
                prioritizedSchoolSubjects: state.prioritizedSchoolSubjects.filter((schoolSubject) => schoolSubject?.id !== action?.data?.school_subject_id)
            }
        default:
            return state
    }
}
