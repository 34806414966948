import {View} from "react-native";
import {theme} from "../theme/theme";
import TextM from "../components/text/TextM";
import LinkTextM from "../components/text/LinkTextM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import React, {useContext, useEffect} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import {DimensionsContext} from "../utils/mobileUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useNavigation} from "@react-navigation/native";

function ValidateEmailCard(props) {

    const {fullScreenMode = false} = props
    const navigation = useNavigation()
    const onResendEmailPress = () => {
        props.apiRequest(authService.emailResend)
    }
    const dimensions = useContext(DimensionsContext)

    useEffect(() => {
        // Show Toast if email reset is success or failure
        if (props.resentEmail) {
            props.apiIdle(authService.emailResend)
            DialogM.show({
                text1: 'Succès', text2: 'Email renvoyé.'
            })
            //alertPolyfill('Succès', 'Email renvoyé.')
        } else if (props.resendEmailError) {
            props.apiIdle(authService.emailResend)
            DialogM.show({
                text1: 'Erreur', text2: props.resendEmailError.message
            })
            //alertPolyfill('Erreur', props.resendEmailError.message)
        }
    }, [props.resentEmail, props.resendEmailError])

    return !props?.user?.is_verified ? <View style={{
        width: "100%",
        paddingVertical: 20
        // paddingHorizontal: 15
    }}>
        <View style={{
            width: dimensions?.width,
            backgroundColor: theme.colors.successLight,
            // borderBottomWidth: 1,
            // borderColor: theme.colors.lightMore,
            paddingVertical: 20,
            paddingHorizontal: 15,
            marginBottom: !fullScreenMode ? 0 : 20
        }}>
            <TextM color={theme.colors.grey}
                   fontSize={16}
                   style={{
                       textAlign: !fullScreenMode ? "justify" : "center"
                   }}
                   fontWeight="Medium"
                   wrap
            >
                Valide ton adresse email en cliquant sur le lien qui t'a été envoyé par email
            </TextM>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <TextM color={theme.colors.grey}
                       fontSize={20}
                       style={{
                           textAlign: !fullScreenMode ? "justify" : "center"
                       }}
                       fontWeight="Medium"
                       wrap
                >
                    {props.user?.email}
                </TextM>
                <IconButton iconColor={theme.colors.primary} icon={"pen"} size={22} onPress={() => {
                    navigation.push("edit-email")
                }}/>
            </View>

        </View>


        <TextM color={theme.colors.grey}
               fontSize={14}
               style={{
                   textAlign: !fullScreenMode ? "justify" : "center",
                   paddingHorizontal: 15
               }}
               fontWeight="Regular"
               wrap
        >
            Tu n'as pas reçu d'email ? <LinkTextM onPress={onResendEmailPress}>Clique ici</LinkTextM> pour
            en
            générer un nouveau
        </TextM>
        {props.isResendingEmail && <ActivityIndicator animating={true}
                                                      size="small"
                                                      style={{
                                                          marginTop: 2
                                                      }}
                                                      color={theme.colors.primary}/>}
    </View> : null
}

const mapStateToProps = state => {
    return {
        isResendingEmail: state.api.emailResend?.request,
        resentEmail: state.api.emailResend?.success,
        resendEmailError: state.api.emailResend?.error,

        user: state.data.currentUser?.object,

        getUserRequest: state.api.getUser?.request,

        avatars: state.data.staticData?.avatars
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmailCard)