import {activitiesConstants} from "@data/constants/activitiesConstants";

const initialState = {
    activityPaused: false,
    ongoingActivity: false,
    cameraOn: false,
    audioStatus: "",
    updatedActivityEventData: null
}

export default function activity(state = initialState, action) {
    switch (action.type) {
        case activitiesConstants.UPDATE_ACTIVITY_CHRONO:
            return {
                ...state,
                updatedActivityEventData: action?.data
            }
        case activitiesConstants.AUDIO_STATUS:
            return {
                ...state,
                audioStatus: action?.data
            }
        case activitiesConstants.ONGOING_ACTIVITY:
            return {
                ...state,
                ongoingActivity: action?.data
            }
        case activitiesConstants.PAUSE_ACTIVITY:
            return {
                ...state,
                activityPaused: action?.data
            }
        case activitiesConstants.SET_CAMERA_STATUS:
            return {
                ...state,
                cameraOn: action?.cameraOn
            }
        default:
            return state
    }
}
