import React, {useContext} from "react";
import {Linking, Platform, ScrollView, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {hasRole} from "@data/utility/ability";
import {StatusBar} from "expo-status-bar";
import LinkTextM from "@common/components/text/LinkTextM";
import {Text} from "react-native-paper";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {AbilityContext} from "@data/utility/Can";

const SettingsGeneralScreen = (props) => {
    const {navigation, control, errors} = props;

    const marginBottom = 10
    const dimensions = useContext(DimensionsContext)
    const user = props.userId ? findItemInListWithId(props.userId, props.users) : props.user
    const ability = useContext(AbilityContext)
    const isAdmin = ability.can("read", "backOffice")


    return (
        <View style={{
            flex: 1,
            // backgroundColor: theme.colors.whiteAlmost,
            paddingTop: 0,
            justifyContent: "space-between",
            width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            <ScrollView>
                <View
                    style={{
                        marginHorizontal: 15,
                        marginTop: 20
                    }}
                >
                    <TextInputM
                        contentContainerStyle={{
                            marginBottom
                        }}
                        placeholder="Nom"
                        name="firstname"
                        labelTop="Nom"
                        control={control}
                        errors={errors}
                    />

                    <TextInputM
                        contentContainerStyle={{
                            marginBottom
                        }}
                        placeholder="Prénom"
                        name="lastname"
                        labelTop="Prénom"
                        control={control}
                        errors={errors}
                    />


                    <TextInputM
                        contentContainerStyle={{
                            marginBottom
                        }}
                        placeholder="ex : 0601020304"
                        name="mobile"
                        textContentType="telephoneNumber"
                        labelTop="Numéro de téléphone"
                        control={control}
                        errors={errors}
                    />

                    <TextInputM
                        contentContainerStyle={{
                            marginBottom
                        }}
                        disabled
                        placeholder="Adresse email"
                        name="email"
                        textContentType="emailAddress"
                        labelTop="Adresse email"
                        control={control}
                        errors={errors}
                    />

                    {hasRole(user, "student") && (<>
                        <TextInputM
                            contentContainerStyle={{
                                marginBottom
                            }}
                            placeholder="ex : 0601020304"
                            disabled={!isAdmin}
                            name="parent_mobile"
                            textContentType="telephoneNumber"
                            keyboardType="phone-pad"
                            labelTop="Numéro de téléphone d'un parent"
                            control={control}
                            errors={errors}
                        />
                        <TextInputM
                            style={{
                                marginBottom
                            }}
                            // disabled
                            placeholder="Adresse email d'un parent"
                            name="parent_email"
                            labelTop="Adresse email d'un parent"
                            control={control}
                            errors={errors}
                        />
                    </>)}

                    <Text style={{
                        textAlign: 'center',
                        fontSize: 12,
                        marginBottom: 20
                    }}>
                        Si tu souhaites modifier une adresse email <LinkTextM
                        onPress={() => Linking.openURL("mailto:support@masteur.com")}>contacte le support</LinkTextM>
                    </Text>


                    <FilledButtonM
                        mode="outlined"
                        style={{
                            borderColor: theme.colors.primary,
                            borderRadius: 10,
                            marginTop: 20,
                            marginBottom: 180
                        }}
                        onPress={() => {
                            navigation.push("account-password");
                        }}
                        label={"Modifier mon mot de passe"}
                    />
                </View>
            </ScrollView>

            <StatusBar
                style="dark"
                backgroundColor={theme.colors.white}
                translucent={false}
            />
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        updateStudentAccountRequest: state.api.updateStudentAccount?.request,
        updateStudentAccountSuccess: state.api.updateStudentAccount?.success,
        updateStudentAccountError: state.api.updateStudentAccount?.error,


        updateTutorAccountRequest: state.api.updateTutorAccount?.request,
        updateTutorAccountSuccess: state.api.updateTutorAccount?.success,
        updateTutorAccountError: state.api.updateTutorAccount?.error,


        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token,
        users: state.data.users?.usersList

    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsGeneralScreen);
