import Constants from "expo-constants";
import {IS_WEB} from "@common/utils/mobileUtils";

export const apiConstants = {
    _IDLE: "_IDLE",
    _REQUEST: "_REQUEST",
    _SUCCESS: "_SUCCESS",
    _FAILURE: "_FAILURE",
    NETWORK_ON: "NETWORK_ON",
    NETWORK_OFF: "NETWORK_OFF",
};

const API_URLS = {
    development: "http://192.168.1.133:8000",
    integration: "https://api-integration.masteur.com",
    staging: "https://api-staging.masteur.com",
    production: "https://api.masteur.com",
}; //when modifying here for server environments, you must also modify same const in app.config.js
const PUSHER_KEYS = {
    development: "ed1b8471abc0fa1357be",
    integration: "3388927f7e865d866d23",
    staging: "4df10506a95efb22bcee",
    production: "9065ede73c2499fd45de",
}; //when modifying here for server environments, you must also modify same const in app.config.js

export const VAPOR_FILE_UPLOAD = "VAPOR_FILE_UPLOAD";

const apiURLWeb = "https://api.masteur.test";
const apiURLMobile = API_URLS.development;

const devServerMode = false; // change this to true to use integration servers in dev environment

const devServer = "integration"; // indicate here the server you want to use in dev environment (staging, integration, development)

export const API_URL = __DEV__
    ? devServerMode
        ? API_URLS[devServer]
        : IS_WEB
            ? apiURLWeb
            : apiURLMobile
    : Constants.expoConfig.extra.apiUrl;
export const PUSHER_KEY =
    __DEV__ && devServerMode
        ? PUSHER_KEYS[devServer]
        : Constants.expoConfig.extra.pusher.key;

export const cleanMediaUrl = (mediaUrl) =>
    mediaUrl?.replace("https://api.masteur.test", API_URL) ?? null;
