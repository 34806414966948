import axios from "axios"

function getSchools() {
    return axios.get('/api/schools')

}

function getSchool({id}, {}, params) {
    return axios.get(`api/schools/${id}`, {
        params
    })
}

function getClassrooms() {
    return axios.get('/api/classrooms')

}

function getClassroom({id}, {}, params) {
    return axios.get(`api/classrooms/${id}`, {
        params
    })
}

function postClassroom({}, formData) {
    return axios.post(`api/classrooms`, formData)
}

function putClassroom({id}, formData) {
    return axios.put(`api/classrooms/${id}`, formData)
}

function deleteClassroom({id}) {
    return axios.delete(`api/classrooms/${id}`)
}

function joinClassroom({}, formData) {
    return axios.post(`api/classrooms/join`, formData)
}

function joinSchool({}, formData) {
    return axios.post(`api/schools/join`, formData)
}

function removeStudentFromClassroom({id}, formData) {
    return axios.post(`api/classrooms/${id}/remove-student`, {
        ...formData,
        include_history_since_last_interview: 1
    })
}

function postTeacherChallenge({id}, formData) {
    return axios.post(`api/classrooms/${id}/challenges`, {
        ...formData,
        include_history_since_last_interview: 1
    })
}

export const schoolsService = {
    removeStudentFromClassroom,
    postTeacherChallenge,
    getSchools,
    getSchool,
    getClassrooms,
    getClassroom,
    postClassroom,
    putClassroom,
    deleteClassroom,
    joinClassroom,
    joinSchool,
}

