import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Platform, RefreshControl, StatusBar, Vibration, View} from "react-native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import DraggableFlatList from "react-native-draggable-flatlist";
import RealizationsListItem from "../../organize/list/RealizationsListItem";
import {theme} from "@common/theme/theme";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import TodoModal, {canEditActivity} from "./TodoModal";
import {activitiesService} from "@data/services/activitiesService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import TextM from "@common/components/text/TextM";
import {hasRole} from "@data/utility/ability";
import {findItemInListWithId} from "@data/utility/dataUtils";
import * as StoreReview from "expo-store-review";
import {
    getActivitiesCount,
    getLastVersionPromptedForReview,
    setLastVersionPromptedForReview
} from "@data/utility/asyncStorageUtils";
import Constants from "expo-constants";
import StudentSuggestions from "../../organize/challenges/StudentSuggestions";
import TitleM from "@common/components/text/TitleM";
import {studentsService} from "@data/services/studentsService";
import {authService} from "@data/services/authService";
import Animated, {SlideOutLeft} from "react-native-reanimated";
import {FlatList, Swipeable} from "react-native-gesture-handler";
import {setTodoActivities} from "@data/redux/actions/data";
import {AbilityContext} from "@data/utility/Can";
import {ChampionImageM} from "../../champions/ChampionImageM";
import {calculateSumOfEstimatedTime} from "../../../tutor/students/StudentsListItem";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import NextLiveSessionBanner from "./NextLiveSessionBanner";
import {useNewTodo} from "@common/messenger/socketHook";
import useLaunchActivity from "@common/hooks/useLaunchActivity";
import IconButtonM from "@common/components/button/IconButtonM";
import ChapterItemComponent from "../../../common/chapters/ChapterItemComponent";
import DualIconButton from "../../../common/components/icons/DualIconButton";
import ChaptersList from "./ChaptersList";

const TodoListScreen = (props) => {
    // ** Component props
    const {route, student, todoList = null, goBack = false} = props;

    const [byChapter, setByChapter] = useState(
        (props.isLiveMode
            ? false
            : student?.default_display_by_chapter ?? props.user?.default_display_by_chapter)
    );

    const navigation = useNavigation();
    const liveSession = props.currentLiveSession;

    const request = student
        ? props.getStudentRequest
        : props.getUserRequest || props.getSessionsRequest || props.getCallRequest;
    const getUserService = student
        ? studentsService.getStudent
        : authService.getUser;
    const getUserParams = student ? {id: student?.id} : {};

    useEffect(() => {
        props.apiRequest(getUserService, getUserParams);
    }, []);

    const promptStoreReviewIsApplicable = async () => {
        const isAvailable = await StoreReview.isAvailableAsync();
        const hasAction = await StoreReview.hasAction();
        if (isAvailable && hasAction) {
            const count = await getActivitiesCount();
            // console.debug("count", count)
            if (count >= 1) {
                const lastVersionPromptedForReview =
                    await getLastVersionPromptedForReview();

                // console.debug("lastVersionPromptedForReview", lastVersionPromptedForReview)
                if (lastVersionPromptedForReview !== Constants.expoConfig.version) {
                    return true;
                }
            }
        }
        return false;
    };

    useFocusEffect(
        useCallback(() => {
            if (hasRole(props.user, "student")) {
                let isActive = true;
                if (Platform.OS !== "web")
                    promptStoreReviewIsApplicable().then((isApplicable) => {
                        if (isApplicable)
                            setTimeout(() => {
                                if (isActive) {
                                    // console.debug("PROMPT")
                                    setLastVersionPromptedForReview().then();
                                    StoreReview.requestReview()
                                        .then(() => {
                                            // console.log("success")
                                        })
                                        .catch((error) => {
                                            console.error("error", error);
                                        });
                                }
                            }, 2000);
                    });
                return () => {
                    isActive = false;
                };
            }
        }, [])
    );

    // console.log(student)
    const user = student ? student : props.user;
    const draggableFlatListRef = useRef();
    const chaptersListRef = useRef();
    const todos = todoList ?? user?.todo_activities;

    console.log("user", user)
    console.log("todos", todos)

    const totalEstimatedTime = calculateSumOfEstimatedTime(
        todos,
        "estimated_duration"
    );
    const dimensions = useContext(DimensionsContext);
    const [isOpen, setOpen] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const handleShowModal = () => setOpen(!isOpen);
    const selectedItem = findItemInListWithId(selectedItemId, todos);
    const schoolSubject = findItemInListWithId(
        selectedItem?.school_subject_id,
        props.schoolSubjects
    );

    const newTodoEvent = useNewTodo({
        channel: `presence-messenger.thread.${liveSession?.thread_id}`,
        id: student ? student?.id : props.user?.id
    });

    useApiStatus(
        activitiesService.activityTodoIndexesAsStudent, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (props.isLiveMode) newTodoEvent();
        } // success callback
    );
    useApiStatus(
        activitiesService.activityTodoIndexesAsTutor, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (props.isLiveMode) newTodoEvent();
        } // success callback
    );
    useApiStatus(
        activitiesService.deleteActivityTodo, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (props.isLiveMode) newTodoEvent();
        } // success callback
    );

    const ability = useContext(AbilityContext);
    const onDeleteTodo = (todoId) => {
        const activity = findItemInListWithId(todoId, todos);
        const canEdit = canEditActivity(activity, ability);

        if (!canEdit) {
            DialogM.show({
                text1: "Non autorisé",
                text2:
                    "L'activité a été créée par ton tuteur, tu ne peux pas la supprimer."
            });
            return;
        }
        setPendingAction("delete");
        props.apiRequest(activitiesService.getActivity, {id: todoId});
        if (props.getActivitySuccess)
            DialogM.show({
                text1: "Veux-tu vraiment supprimer cette activité ?",
                buttonText1: "Supprimer",
                buttonColor1: theme.colors.error,
                onPress: () => {
                    props.apiRequest(activitiesService.deleteActivityTodo, {
                        id: todoId
                    });
                },
                buttonText2: "Annuler"
            });
    };
    const onUpdatePress = (todoId) => {
        const activity = findItemInListWithId(todoId, todos);
        const canEdit = canEditActivity(activity, ability);
        if (!canEdit) {
            DialogM.show({
                text1: "Non autorisé",
                text2:
                    "L'activité a été créée par ton tuteur, tu ne peux pas la modifier."
            });
            return;
        }
        setPendingAction("update");
        props.apiRequest(activitiesService.getActivity, {id: todoId});
    };
    let row = [];
    let prevOpenedRow;
    const [pendingAction, setPendingAction] = useState(null);

    const onPressShowInfo = (item) => {
        navigation.push("activity-presentation", {
            activityTemplateId: item?.activity_template_id,
            activityId: item?.id,
            schoolSubjectId: item?.school_subject_id,
            studentId: item?.student_id,
            chapterId: item?.student_chapter?.id,
            chapterName: item?.student_chapter?.chapter?.name,
            ongoing: item?.student_chapter?.ongoing,
            xpGained: item?.student_chapter?.xp_gained,
            lastFeelingSentAt: item?.student_chapter?.last_feeling_sent_at
        });
    };
    useApiStatus(
        activitiesService.getActivity, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (data?.started_at && data?.student_id !== props.user?.id) {
                DialogM.show({
                    text1: "Impossible",
                    text2:
                        "L'activité a déjà été commencée par l'élève il y a moins d'une heure."
                });
            } else {
                if (pendingAction === "delete") {
                    onDeleteTodo(data?.id);
                } else if (pendingAction === "update") {
                    navigation?.push("new-todo", {
                        todoId: data?.id,
                        studentId: student ? student?.id : null
                    });
                }
            }
            setPendingAction(null);
        },
        () => {
            handleShowModal();
            setPendingAction(null);
        }
    );
    const launchActivity = useLaunchActivity();
    useApiStatus(
        activitiesService.lockActivityV2, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (pendingAction === "start") {
                if (goBack) navigation.goBack(); // go back to the previous screen if the user was in the
                launchActivity(data)
                setPendingAction(null)
            } else if (pendingAction === "resume") {
                if (goBack) navigation.goBack(); // go back to the previous screen if the user was in the
                launchActivity(data)
                setPendingAction(null)
            }
        },
        () => {
        }
    )

    // useEffect(() => {
    //     if (props.updatedActivityEventData && hasRole(props.user, "tutor")) {
    //         const todo = findItemInListWithId(props.updatedActivityEventData?.activity_id, todos)
    //         const activityTemplate = findItemInListWithId(todo?.activity_template_id, props.activityTemplates)
    //         const formData = activityTemplate?.steps
    //         const activityType = formData?.find(step => step.type === "photo") ? "photo" : "audio"
    //         const user = findItemInListWithId(props.updatedActivityEventData?.student_id, liveSession?.users)
    //         if (props.updatedActivityEventData.action === "activity media sent") {
    //             const msg = `${user?.display_name} a envoyé ${activityType === "photo" ? "une photo" : "un audio"}`
    //             Toast.info(msg, "top")
    //         }
    //     }
    //
    // }, [props.updatedActivityEventData]);

    const [isSwiped, setIsSwiped] = useState(false);

    const renderItem = ({item, index, drag, isActive}) => {
        const closeRow = (index) => {
            setIsSwiped(false);
            if (prevOpenedRow && prevOpenedRow !== row[index]) {
                prevOpenedRow.close();
            }
            prevOpenedRow = row[index];
        };
        const status = item?.started_at !== null && (item?.locked_at !== null || (props.updatedActivityEventData?.locked_at !== null && props.updatedActivityEventData?.activity_id === item?.id)) ? "En cours" :
            item?.started_at !== null && (item?.locked_at === null || (props.updatedActivityEventData?.locked_at === null && props.updatedActivityEventData?.activity_id === item?.id)) ? "Commencée"
                : null
        const renderRightActions = (progress, dragX, onClick, item) => {
            return (
                <View
                    style={{
                        flexDirection: "row",
                        paddingRight: dimensions?.width > 800 ? 60 : 0
                    }}
                >
                    <IconButton
                        onPress={() => onPressShowInfo(item)}
                        iconColor={theme.colors.light}
                        icon={"info"}
                        style={{
                            marginRight: 10,
                            marginBottom: 15,
                            alignSelf: "center"
                        }}
                        size={17}
                    />
                    {props.getActivityRequest && pendingAction === "update" ? (
                        <View
                            style={{
                                width: 40,
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <ActivityIndicator color={theme.colors.primary}/>
                        </View>
                    ) : (
                        <IconButton
                            onPress={() => onUpdatePress(item?.id)}
                            iconColor={theme.colors.light}
                            icon={"edit"}
                            style={{
                                marginRight: 10,
                                marginBottom: 15,
                                alignSelf: "center"
                            }}
                            size={17}
                        />
                    )}
                    {props.getActivityRequest && pendingAction === "delete" ? (
                        <View
                            style={{
                                width: 40,
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <ActivityIndicator color={theme.colors.primary}/>
                        </View>
                    ) : (
                        <IconButton
                            onPress={onClick}
                            iconColor={theme.colors.light}
                            icon={"trash"}
                            style={{
                                marginRight: 10,
                                marginBottom: 15,
                                alignSelf: "center"
                            }}
                            size={17}
                        />
                    )}
                </View>
            );
        };
        return (
            <Swipeable
                renderRightActions={(progress, dragX) => (
                    <>
                        {renderRightActions(
                            progress,
                            dragX,
                            () => onDeleteTodo(item?.id),
                            item
                        )}
                    </>
                )}
                containerStyle={{
                    width: dimensions?.width
                }}
                onBegan={() => setIsSwiped(true)}
                onSwipeableClose={() => closeRow(item?.id)}
                ref={(ref) => (row[item?.id] = ref)}
                rightOpenValue={-200}
            >
                <Animated.View exiting={SlideOutLeft.duration(500)}>
                    <RealizationsListItem
                        activity={item}
                        onPressSwipe={() => {
                            row[item?.id]?.openRight();
                        }}
                        custom
                        student={student}
                        dragButton={drag}
                        onLongPress={drag}
                        disabled={isActive}
                        todo={true}
                        onPress={() => {
                            if (!isSwiped || !IS_WEB) {
                                setSelectedItemId(item?.id);
                                if (hasRole(props.user, "student")) {
                                    setPendingAction("start");
                                    props.apiRequest(
                                        activitiesService.lockActivityV2,
                                        {id: item?.id},
                                        {
                                                      lock: true,
                                                      live_mode: props.isLiveMode,
                                                      live_session_id: props.liveSessionId ?? undefined
                                                  })
                                              } else if (status) {
                                                  navigation.push("activity-result", {
                                                      activityIds: [item?.id],
                                                      studentId: student?.id
                                                  }
                                    );
                                } else handleShowModal();
                            }
                        }}
                    />
                </Animated.View>
            </Swipeable>
        );
    };

    //chapters list
    function renderChaptersList() {
        const chaptersSet = new Set();

        const chaptersList = todos?.reduce((acc, todo) => {
            const {student_chapter} = todo || {};
            const {chapter} = student_chapter || {};
            const chapterId = chapter?.id;

            if (chapterId && !chaptersSet.has(chapterId)) {
                chaptersSet.add(chapterId);
                acc.push({
                    chapter: {
                        ...student_chapter,
                        ...chapter
                    }
                });
            }

            return acc;
        }, []);

        const todosWithoutChapter = todos?.filter((todo) => {
            const {student_chapter} = todo || {};
            const {chapter} = student_chapter || {};
            const chapterId = chapter?.id;

            return !chapterId;
        });
        const groupedChapters = todosWithoutChapter.reduce((acc, c) => {
            const schoolSubject = props.schoolSubjects?.find(
                (s) => s.id === c.school_subject_id
            );

            if (!acc[c.school_subject_id]) {
                acc[c.school_subject_id] = {
                    chapter: {
                        name: "Autres activités",
                        school_subject_id: schoolSubject?.id,
                        xp_gained: 0,
                        xp_total: 0,
                        xp_gained_steps: [],
                        xp_remaining_steps: []
                    },
                    validated_activities_count: 0,
                    todo_activities_count: 0
                };
            }

            acc[c.school_subject_id].chapter.xp_gained += c?.xp_gained || 0;
            acc[c.school_subject_id].chapter.xp_total += c?.xp_value || 0;

            acc[c.school_subject_id].chapter.xp_gained_steps.push(c?.xp_gained || 0);

            return acc;
        }, {});


        const otherChapters = Object.values(groupedChapters);
        const chapters = [...chaptersList, ...otherChapters];

        // console.log("chaptersList", otherChapters);

        return (
            <FlatList
                data={chapters}
                ref={chaptersListRef}
                renderItem={({item}) => {
                    const todosList = todos.filter((todo) => {
                        const chapterId = todo?.student_chapter?.chapter?.id;
                        const schoolSubject = props.schoolSubjects?.find(
                            (s) => s.id === todo.school_subject_id
                        );

                        return (
                            (item?.chapter?.id === chapterId && chapterId) ||
                            (schoolSubject?.id === item?.chapter?.school_subject_id &&
                                item?.chapter?.name === "Autres activités" &&
                                !chapterId)
                        );
                    });


                    return (
                        <ChapterItemComponent
                            onPress={() => {
                                navigation.push("todos-by-chapter", {
                                    todosList
                                });
                            }}
                            chapter={item?.chapter}
                            style={{marginBottom: 0}}
                        />
                    );
                }}
                ListHeaderComponent={renderListHeaderComponent}
                containerStyle={{
                    flex: 1
                }}
                contentContainerStyle={{
                    alignItems: "center"
                }}
            />
        );
    }

    //FAB button
    const [isExtended, setIsExtended] = useState(!IS_WEB);

    const onScroll = (value) => {
        if (!IS_WEB) {
            // console.log(value);
            setIsExtended(value <= 10);
        }
    };

    const onPressNewTodo = () => {

        if (todos?.length < props.maxTodoActivities)
            navigation?.push("new-todo", {
                // todoId: null,
                studentId: student ? student?.id : null
            });
        else
            DialogM.show({
                champion: "zoe",
                text1: `Tu ne peux pas dépasser ${props.maxTodoActivities} activités dans ta liste`,
                text2: "C’est l’heure de faire un peu de ménage ! Supprime les activités qui ne sont plus utiles."
            })
    }

    function renderListHeaderComponent() {
        return (
            !todoList && <>
                <NextLiveSessionBanner/>
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        alignSelf: "center",
                        justifyContent: "space-between",
                        width: dimensions?.width, // borderBottomWidth: 1,
                        borderColor: theme.colors.lightMore, // backgroundColor: theme.colors.accentLighter,
                        paddingVertical: 15,
                        paddingHorizontal: 10
                        // marginBottom: 20
                        // marginTop: 10
                    }}
                >
                    <View>
                        <TextM wrap
                               fontSize={10}
                               color={theme.colors.primary}
                               fontWeight={"Light"} style={{marginBottom: -5}}>À FAIRE</TextM>
                        <TitleM fontWeight="ExtraBold">
                            {!byChapter
                                ? "ACTIVITÉS"
                                : "PAR CHAPITRE"}
                        </TitleM>
                    </View>

                    {!todoList && (
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <DualIconButton
                                icon1="th-list"
                                icon2="book"
                                onSelectIcon1={() => {
                                    setByChapter(false);
                                }}
                                onSelectIcon2={() => {
                                    setByChapter(true);
                                }}
                                initialSelectedIcon={byChapter ? "icon2" : "icon1"}
                            />
                            {request && IS_WEB ? (
                                <ActivityIndicator
                                    color={theme.colors.primary}
                                    size={"small"}
                                />
                            ) : (
                                <IconButton
                                    onPress={() => {
                                        props.apiRequest(getUserService, getUserParams);
                                    }}
                                    iconColor={theme.colors.primary}
                                    disabled={request}
                                    icon={"sync-alt"}
                                    size={20}
                                />
                            )}
                        </View>
                    )}
                </View>
            </>
        );
    }

    const renderListEmptyComponent = () => {
        return <View
            style={{
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 20,
                paddingVertical: 20,
                // backgroundColor: theme.colors.white,
                flex: 1,
                // width: Platform.OS === 'web' ? dimensions?.width : null,
                alignSelf: Platform.OS !== "web" ? null : "center"
            }}
        >
            <ChampionImageM name="zoe" variant={"main"} width={90}/>
            <TitleM
                fontWeight="ExtraBold"
                style={{
                    textAlign: "center",
                    marginTop: 10,
                    color: theme.colors.grey
                }}
                wrap
            >
                Lance une activité !
            </TitleM>
            <TextM fontWeight="Medium" style={{textAlign: "center"}} wrap>
                Ta liste est vide pour l'instant...
            </TextM>

        </View>
    }

    const renderListFooterComponent = () => {
        return !todoList && <IconButtonM
            onPress={onPressNewTodo}
            icon={"plus"}
            size={30}
            style={{
                height: 50,
                width: 50,
                backgroundColor: theme.colors.primary,
                alignSelf: "center",
                borderRadius: 30
            }}
            iconColor={theme.colors.white}
        />
    }

    return (
        <View
            style={{
                flex: 1,
                // backgroundColor: theme.colors.whiteAlmost,
                alignItems: "center",
                width: Platform.OS === "web" ? dimensions?.width : null,
                alignSelf: Platform.OS !== "web" ? null : "center"
                // marginTop: 20
            }}
        >
            {Platform.OS !== "web" &&
                !student &&
                props.user?.champion_intros_viewed_at?.zoe_menus_v1 && (
                    <>
                        <StudentSuggestions/>
                    </>
                )}
            {byChapter ? (
                <ChaptersList
                    todos={todos}
                    schoolSubjects={props.schoolSubjects}
                    navigation={navigation}
                    chaptersListRef={chaptersListRef}
                    listHeaderComponent={renderListHeaderComponent}
                    listEmptyComponent={renderListEmptyComponent}
                    ListFooterComponent={renderListFooterComponent}
                />
            ) : (
                <DraggableFlatList
                    data={todos ?? []}
                    containerStyle={{
                        flex: 1
                    }}
                    contentContainerStyle={{
                        alignItems: "center"
                    }}
                    onScrollOffsetChange={(value) => onScroll(value)}
                    refreshControl={
                        <RefreshControl
                            onRefresh={() => {
                                props.apiRequest(getUserService, getUserParams);
                            }}
                            refreshing={!!request}
                        />
                    }
                    ListHeaderComponent={renderListHeaderComponent}
                    ListFooterComponent={renderListFooterComponent}
                    ref={draggableFlatListRef}
                    onDragBegin={() => Vibration.vibrate(80)}
                    onDragEnd={({data}) => {
                        Vibration.vibrate(80);
                        props.setTodoActivities(data);
                        if (hasRole(props.user, "student"))
                            props.apiRequest(
                                activitiesService.activityTodoIndexesAsStudent,
                                {},
                                {
                                    todo_activities_ids: data.map((todo) => todo.id)
                                }
                            );
                        else
                            props.apiRequest(
                                activitiesService.activityTodoIndexesAsTutor,
                                {id: student?.id},
                                {
                                    todo_activities_ids: data.map((todo) => todo.id)
                                }
                            );
                    }}
                    keyExtractor={(item) => item?.id}
                    ListEmptyComponent={
                        renderListEmptyComponent()
                    }
                    renderItem={renderItem}
                />
            )}

            {isOpen && <TodoModal isOpen={isOpen}
                                  handleShowModal={handleShowModal}
                                  item={selectedItem}
                                  navigation={navigation}
                                  onDeleteTodo={onDeleteTodo}
                                  studentId={student?.id}
                                  schoolSubject={schoolSubject}
            />
            }
            <StatusBar
                style="dark"
                backgroundColor={theme.colors.white}
                translucent={false}
            />
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        activityTodoIndexesAsStudentRequest:
        state.api.activityTodoIndexesAsStudent?.request,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects,
        getUserRequest: state.api.getUser?.request,
        getStudentRequest: state.api.getStudent?.request,
        isLive: state.liveMode.isLiveModeEnabled,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
            (x) => x?.id === state.liveMode?.liveSessionId
        ),
        inCallUsers: state.liveMode?.inCallUsers,
        getLiveSessionSuccess: state.api.getLiveSession?.success,
        callPortal: state.liveMode?.callPortal,
        maxTodoActivities: state.data.staticData?.max_todo_activities,
        isLiveMode: state.liveMode.isLiveModeEnabled,
        getActivityRequest: state.api.getActivity?.request,
        getActivitySuccess: state.api.getActivity?.success,
        updatedActivityEventData: state.activity?.updatedActivityEventData,

    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setTodoActivities,
    setIsLiveModeEnabled
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoListScreen);
