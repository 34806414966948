import React, {useContext} from 'react';
import {Keyboard, Platform, ScrollView, StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import TitleM from "@common/components/text/TitleM";
import {useForm} from "react-hook-form";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import ErrorTextM from "@common/components/text/ErrorTextM";
import {validation} from "@data/utility/validation";
import {schoolsService} from "@data/services/schoolsService";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";


const JoinClassroomScreen = (props) => {

    // ** Component props
    const {navigation} = props

    const {control, handleSubmit, formState: {errors, dirtyFields}, register} = useForm({
        defaultValues: {
            code: ''
        }
    })

    const onSubmit = data => {
        if (props.joinClassroomRequest) return
        Keyboard.dismiss()
        // console.log(data)
        props.apiRequest(schoolsService.joinClassroom, {}, {...data})
    }

    useApiStatus(
        schoolsService.joinClassroom, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {

            // console.log("success data", successData)

            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Bravo !",
                    text: "Tu as rejoint la classe " + successData?.name + ", gérée par " + successData?.teacher_display_name
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )
    const dimensions = useContext(DimensionsContext)


    return (
        <View style={{
            flex: 1,
            // backgroundColor: theme.colors.white,
            alignItems: 'center',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
            // justifyContent: 'center'
        }}>

            <ScrollView contentContainerStyle={{
                // alignItems: 'center'
                paddingTop: 20,
                paddingHorizontal: 15
            }}>

                <TitleM fontWeight="ExtraBold"
                        style={{
                            marginBottom: 10
                        }}>À propos</TitleM>
                <TextM style={{
                    marginBottom: 40,
                    // textAlign: "justify",
                    lineHeight: 28
                }}
                       fontSize={16}
                       wrap>
                    Partage ton activité avec ton professeur en utilisant le code à 6 lettres de ta classe. Cela lui
                    permettra de te donner des défis et d'accéder à tes réalisations.
                </TextM>

                <TitleM fontWeight="ExtraBold"
                        style={{
                            marginBottom: 10
                        }}>Code de classe</TitleM>

                <TextInputM {...register("code", {...validation.required()})}
                            placeholder="Exemple : ABCDEF"
                            control={control}
                            errors={errors}
                            autoCapitalize="characters"
                            maxLength={6}
                />
                {props.user.classrooms?.length > 0 && <TextM style={{
                    marginTop: 10
                }}
                                                             color={theme.colors.light}
                                                             fontSize={16}
                                                             wrap>
                    Tes classes
                    : {props.user.classrooms?.map((x, index) => x?.name + ((index === props.user.classrooms?.length - 1) ? "" : ", "))}
                </TextM>}
                <ErrorTextM errorData={props.joinClassroomError}/>
                <FilledButtonM style={{
                    alignSelf: Platform.OS !== 'web' ? 'stretch' : 'center',
                    marginTop: 20
                }}
                               loading={props.joinClassroomRequest}
                               onPress={handleSubmit(onSubmit)}
                               label="Rejoindre la classe"/>
                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else
                                                  navigation.replace("account")
                                          }}
                                          label={"Retour"}/>}
            </ScrollView>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        joinClassroomRequest: state.api.joinClassroom?.request,
        joinClassroomError: state.api.joinClassroom?.error,

        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(JoinClassroomScreen)