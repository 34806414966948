import {theme} from "../../theme/theme";
import {Avatar} from "react-native-paper";
import React from "react";
import {cleanMediaUrl} from "@data/constants/apiConstants";
import {avatarColors} from "@data/constants/studentsConstants";
import {hasRole} from "@data/utility/ability";
import {View} from "react-native";
import {IconImageM} from "@common/components/icons/IconsM";
import bowTie0 from "../../../../assets/icons/bow-tie/bow-tie-0.png";
import bowTie1 from "../../../../assets/icons/bow-tie/bow-tie-1.png";
import bowTie2 from "../../../../assets/icons/bow-tie/bow-tie-2.png";
import bowTie3 from "../../../../assets/icons/bow-tie/bow-tie-3.png";
import bowTie4 from "../../../../assets/icons/bow-tie/bow-tie-4.png";
import prof from "../../../../assets/icons/menus-new/prof-gris.png";

const championTies = {
    "zoe": bowTie1,
    "abou": bowTie2,
    "sam": bowTie3,
    "tina": bowTie4
}

export const championForTutorSkillSetId = (skillSetId) => {
    return {
        101: "zoe",
        102: "abou",
        103: "sam",
        104: "tina"
    }?.[skillSetId]
}

export const championBowTieForTutorSkillSetId = (skillSetId) => {
    const champion = championForTutorSkillSetId(skillSetId) ?? "zoe"
    return championTies[champion]
}
export const championColorForTutorSkillSetId = (skillSetId) => {
    const champion = championForTutorSkillSetId(skillSetId) ?? "zoe"
    return theme.colors[champion]
}
export const championLightColorForTutorSkillSetId = (skillSetId) => {
    const champion = championForTutorSkillSetId(skillSetId) ?? "zoe"
    return theme.colors[champion + "Light"]
}


const AvatarM = ({size = 40, user, style, onboardingMode = false}) => {

    const borderWidth = size > 80 ? 6 : size > 60 ? 4 : 3

    const isTutor = hasRole(user, "tutor") || user?.is_tutor

    const tutorAvatarStyle = isTutor ? {
        borderColor: onboardingMode ? theme.colors.link : championColorForTutorSkillSetId(user?.title_skill_set_id),
        borderWidth
    } : {}

    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        width: size,
        height: size,
        ...style
    }}>
        {!user?.avatar && isTutor
            ? <IconImageM source={prof}
                          style={{
                              tintColor: theme.colors.lightMore,
                              marginTop: size/8
                          }}
                          width={3*size/4}/>
            : user?.avatar
                ? <Avatar.Image size={size-borderWidth}
                                style={{
                                    backgroundColor: theme.colors.white,
                                    // ...style
                                }}

                                source={{
                                    uri: cleanMediaUrl(user?.avatar)
                                }}
                />
                : <Avatar.Text size={size}
                           style={{
                               backgroundColor: avatarColors[user?.id ? (user?.id % avatarColors.length) : 0]
                               // ...style
                           }}
                           label={user?.display_name?.toUpperCase()?.slice(0, 1)}/>}
        {isTutor && <View style={{
            position: "absolute",
            bottom: 0,
            // backgroundColor: theme.colors.white,
            width: size,
            height: size,
            borderRadius: size / 2,
            ...tutorAvatarStyle
        }}/>}
        {isTutor && <IconImageM source={onboardingMode ? bowTie0 : championBowTieForTutorSkillSetId(user?.title_skill_set_id)}
                    style={{
                        position: "absolute",
                        bottom: -size / 5,
                        transform: [{rotateZ:'45deg'}],
                    }}
                    width={2.3* size / 5}/>}
    </View>

}

export default AvatarM