import {offlineModeConstants} from "../../../constants/offlineModeConstants";

export function setIsOfflineModeEnabled(newState) {
  return { type: offlineModeConstants.IS_OFFLINE_MODE_CHANGE, newState }
}

export function setIsInternetReachable(newState) {
  return { type: offlineModeConstants.IS_INTERNET_REACHABLE_CHANGE, newState }
}

export function activityOfflineModeInvalid() {
  return { type: offlineModeConstants.ACTIVITY_OFFLINE_MODE_INVALID }
}

export function activityStartedWithOfflineMode() {
  return { type: offlineModeConstants.ACTIVITY_STARTED_WITH_OFFLINE_MODE }
}

export function timerStepStarted() {
  return { type: offlineModeConstants.TIMER_STEP_STARTED }
}

export function offlineModeClear() {
  return { type: offlineModeConstants.OFFLINE_MODE_CLEAR }
}