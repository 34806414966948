import React, {useContext, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {Platform, View} from "react-native";
import {connect} from "react-redux";
import {theme} from "../theme/theme";
import {GiftedChat} from "react-native-gifted-chat";
import "dayjs/locale/fr";
import {useSendWhisper, useSocket} from "./socketHook";
import HeaderMessenger from "./ChatScreenHeader";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import {findItemInListWithId} from "@data/utility/dataUtils";
import CommonChat, {transformMessage} from "@common/messenger/CommonChat";
import {changeCount} from "@data/redux/actions/broadcast";
import {StatusBar} from "expo-status-bar";


const ChatScreen = (props) => {
        const {navigation, route} = props;
        const {studentId} = route.params;
        // console.log("studentId", studentId)

        const studentsFromLives = props.user?.live_sessions?.reduce((acc, session) => {
            return {
                ...acc,
                ...(session.students?.reduce((acc2, student) => {
                    return {
                        ...acc2,
                        [student.id]: student
                    }
                }, {}))
            }
        }, {})
        const studentsFromTutor = props.user?.students?.reduce((acc, student) => {
            return {
                ...acc,
                [student.id]: student
            }
        }, {})

        const students = Object.values({
            ...studentsFromLives,
            ...studentsFromTutor
        })

        const student = studentId ? findItemInListWithId(studentId, students) : props.user

        // console.log("findItemInListWithId(studentId, props.user?.students)", findItemInListWithId(studentId, props.user?.students))
        const [messages, setMessages] = useState([]);

        const liveSessionsThreadIds = props.user?.live_sessions?.reduce((acc, session) => {
            return {
                ...acc,
                ...session.thread_ids
            }
        }, {})

        const threadId = studentId ? (liveSessionsThreadIds[studentId] ?? student?.tutor_messenger_thread_id) : student?.tutor_messenger_thread_id


        const sendWhisper = useSendWhisper({
            channel: `presence-messenger.thread.${threadId}`,
            id: props.user?.id
        })

        // console.log("threadId", threadId)

        useSocket({
            event: "new.message",
            callBack: (event) => {
                if ((event.thread_id === threadId) && ((event?.owner_id !== props.user?.id) || event?.image)) {
                    // console.log("new msg")
                    let item = transformMessage(event);
                    setMessages((old) => GiftedChat.append(old.filter(msg => msg._id !== "temporaryMsg"), [item]));
                }
                if (event.thread_id === threadId && event?.owner_id !== props.user?.id) {
                    props.changeCount(1)
                    //console.log(event)
                }

            },
            channel: `private-messenger.user.${props.user?.id}`
        });

        // LogBox.ignoreLogs(["timer"]);


        // useEffect(() => {
        //     loadEarlear;
        // }, []);


        // useEffect(() => {
        //     props.apiRequest(messengerService.getMarkRead, {
        //         threadId: threadId
        //     });
        // }, []);

        const dimensions = useContext(DimensionsContext)


        return (
            <>
                <View
                    style={{
                        flex: 1,
                        backgroundColor: IS_WEB ? theme.colors.whiteAlmost : theme.colors.greyBg,
                        paddingTop: 0,
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        alignSelf: Platform.OS !== 'web' ? null : "center"
                    }}
                >
                    <HeaderMessenger navigation={navigation} student={student}/>
                    <CommonChat threadId={threadId}
                                sendWhisper={sendWhisper}
                                messages={messages}
                                setMessages={setMessages}
                                groupChat={false}
                    />
                    <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
                </View>
            </>
        );
    }
;

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        count: state.data.currentUser?.object?.unreadInChatMessagesCount,
        token: state.data.session?.object?.token
    };
};
const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    changeCount
};
export default connect(mapStateToProps, mapDispatchToProps)(ChatScreen);