// import PropTypes from 'prop-types';
import React from 'react';
import {Animated, Image, StyleSheet, Text, TouchableOpacity, View} from 'react-native';

import TypeWriter from './TypeWriter';
import {theme} from "../../theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {DialogM} from "@common/components/alert/dialog/Dialog";

// const propTypes = {
//     allowSkip: PropTypes.bool,
//     allowSpeechReplay: PropTypes.bool,
//     noAnimation: PropTypes.bool,
//     onSpeechEnd: PropTypes.func,
//     onSpeechNext: PropTypes.func,
//     onSpeechReplay: PropTypes.func,
//     speaker: PropTypes.string,
//     speakerStyle: ViewPropTypes.style,
//     speakerTextStyle: Text.propTypes.style,
//     speechBubbleActiveOpacity: PropTypes.number,
//     speechBubbleStyle: ViewPropTypes.style,
//     speechBubbleTextStyle: ViewPropTypes.style,
//     speeches: PropTypes.array.isRequired,
//     typeWriterStyle: Text.propTypes.style,
//     nextStyle: ViewPropTypes.style,
//     style: ViewPropTypes.style,
//     hideIcons: PropTypes.bool,
//     position: PropTypes.string,
//     animateTouchIcon: PropTypes.bool,
//     touchIcon: Image.propTypes.source,
//     animateReplayIcon: PropTypes.bool,
//     replayIcon: Image.propTypes.source,
//     writingDelay: PropTypes.number
// };

const defaultProps = {
    animateTouchIcon: true,
    touchIcon: require('./assets/ic_touch_app.png'),
    animateReplayIcon: true,
    replayIcon: require('./assets/ic_replay.png'),
    writingDelay: 100
};

const styles = StyleSheet.create({
    dialog: {
        flexDirection: 'row',
        borderRadius: 50,
        backgroundColor: 'white',
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 20,
        paddingLeft: 20,
        paddingRight: 10,
        paddingTop: 20,
        paddingBottom: 20,
        overflow: 'hidden'
    },
    dialogText: {
        flexShrink: 1
    },
    dialogNext: {
        padding: 5,
        justifyContent: 'center',
        backgroundColor: 'transparent'
    },
    typeWriter: {
        backgroundColor: 'transparent'
    },
    triangle: {
        position: 'absolute',
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderTopWidth: 20,
        borderRightWidth: 8,
        borderBottomWidth: 20,
        borderLeftWidth: 8,
        borderTopColor: 'transparent',
        borderRightColor: 'transparent',
        borderLeftColor: 'transparent',
        zIndex: -10,
    },
    bottomTriangle: {
        alignSelf: "center",
        borderBottomColor: theme.colors.lighter,
        bottom: -40,
        transform: [{ rotate: '180deg' }],
    },
    leftTriangle: {
        top: 0,
        borderBottomColor: theme.colors.lighter,
        left: -22,
        transform: [{ rotate: '-90deg' }],
    },
});


class SpeechBubble extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            speechIndex: 0,
            speechBubbleScale: new Animated.Value(1),
            nextDialogAnimation: new Animated.ValueXY(),
            replaySpeechBubbleAnimation: new Animated.Value(0),
            typeEnd: false,
            lastSpeech: false
        };

        this.onSpeechBubblePress = this.onSpeechBubblePress.bind(this);
        this.onSpeechBubblePressIn = this.onSpeechBubblePressIn.bind(this);
        this.onSpeechBubblePressOut = this.onSpeechBubblePressOut.bind(this);
        this.nextSpeechBubbleAnimation = this.nextSpeechBubbleAnimation.bind(this);
        this.replaySpeechBubbleAnimation = this.replaySpeechBubbleAnimation.bind(this);
    }

    get NextSpeechBubble() {
        return !this.state.lastSpeech ? (
            <Animated.View
                style={[
                    styles.dialogNext,
                    this.props.nextStyle,
                    {
                        transform: this.props.animateTouchIcon
                            ? [{translateY: this.state.nextDialogAnimation.y}]
                            : []
                    },
                    {opacity: this.state.typeEnd && !this.state.lastSpeech ? 1 : 0}
                ]}
            >
                <Image source={this.props.touchIcon} style={{tintColor: theme.colors.grey}}/>
            </Animated.View>
        ) : null;
    }

    get ReplaySpeechBubble() {
        const interpolatedRotateAnimation = this.state.replaySpeechBubbleAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: ['0deg', '360deg']
        });

        return this.state.lastSpeech ? (
            <Animated.Image
                style={[
                    styles.dialogNext,
                    this.props.nextStyle,
                    {
                        transform: this.props.animateReplayIcon
                            ? [{rotate: interpolatedRotateAnimation}]
                            : []
                    },
                    {
                        opacity: this.state.typeEnd && this.state.lastSpeech ? 1 : 0,
                        tintColor: theme.colors.grey,
                        margin: 4
                    }
                ]}
                source={this.props.replayIcon}
            />
        ) : null;
    }

    componentDidMount() {
        this.nextSpeechBubbleAnimation();
        this.replaySpeechBubbleAnimation();
    }

    onSpeechBubblePress() {

        const {speechIndex, typeEnd} = this.state;
        const {allowSkip, onSpeechNext, onSpeechReplay, speeches} = this.props;

        if (allowSkip || typeEnd) {
            if (speechIndex + 1 < speeches.length) {
                const lastSpeech = (speechIndex + 1) + 1 === speeches.length;

                // Next speech
                if (onSpeechNext) {
                    onSpeechNext();
                }

                this.setState({
                    speechIndex: speechIndex + 1,
                    typeEnd: false,
                    lastSpeech
                });
            } else {
                // Replay speech
                if (onSpeechReplay) {
                    onSpeechReplay();
                    // console.log("on speech replay")
                }

                if (this.props.allowSpeechReplay) {
                    this.setState({
                        speechIndex: 0,
                        typeEnd: false,
                        lastSpeech: speeches.length === 1
                    });
                }
            }
        }
    }

    onSpeechBubblePressIn() {
        Animated.spring(this.state.speechBubbleScale, {
            toValue: 0.9,
            tension: 40,
            friction: 3,
            useNativeDriver: true
        }).start();
    }

    onSpeechBubblePressOut() {
        Animated.spring(this.state.speechBubbleScale, {
            toValue: 1,
            tension: 40,
            friction: 3,
            useNativeDriver: true
        }).start();
    }

    nextSpeechBubbleAnimation() {
        const anim = this.state.nextDialogAnimation;
        const translateDuration = 200;

        Animated.sequence([
            Animated.timing(anim.y, {
                toValue: -5,
                duration: translateDuration,
                useNativeDriver: true
            }),
            Animated.timing(anim.y, {
                toValue: 5,
                duration: translateDuration,
                useNativeDriver: true
            })
        ]).start(() => {
            this.nextSpeechBubbleAnimation();
        });
    }

    replaySpeechBubbleAnimation() {
        const anim = this.state.replaySpeechBubbleAnimation;
        const rotateDuration = 750;

        Animated.sequence([
            Animated.timing(anim, {
                toValue: 1,
                duration: rotateDuration,
                useNativeDriver: true
                // easing: Easing.linear,
            }),
            Animated.delay(1000),
            Animated.timing(anim, {
                toValue: 0,
                duration: rotateDuration,
                useNativeDriver: true
            })
        ]).start(() => {
            this.replaySpeechBubbleAnimation();
        });
    }

    render() {
        return (
            <View style={this.props.style}>
                <View style={this.props.speakerStyle}>
                    <Text style={this.props.speakerTextStyle}> {this.props.speaker} </Text>
                </View>
                <TouchableOpacity
                    style={{transform: !this.props.hideIcons ? [{scale: this.state.speechBubbleScale}] : []}}
                    activeOpacity={!this.props.hideIcons ? (this.props.speechBubbleActiveOpacity || 0.6) : 1}
                    onPress={!this.props.hideIcons ? this.onSpeechBubblePress : () => {
                        if(this.props.numberOflines>=6)
                            DialogM.show({text1: this.props.speeches})
                    }}
                    onPressIn={!this.props.hideIcons ? this.onSpeechBubblePressIn : () => {
                    }}
                    onPressOut={!this.props.hideIcons ? this.onSpeechBubblePressOut : () => {
                    }}
                >
                    <View style={this.props.speechBubbleStyle || styles.dialog}>
                        <View style={[styles.dialogText, this.props.speechBubbleTextStyle]}>
                            <TypeWriter
                                maxDelay={this.props.writingDelay}
                                minDelay={1}
                                text={this.props.speeches[this.state.speechIndex]}
                                typing={this.props.noAnimation ? this.props.speeches[this.state.speechIndex]?.length : 1}
                                onTypingEnd={() => {
                                    if (this.props.onSpeechEnd) {
                                        this.props.onSpeechEnd();
                                    }

                                    this.setState({typeEnd: true});
                                }}
                                typeWriterStyle={this.props.typeWriterStyle || styles.typeWriter}
                                numberOfLines={this.props.numberOflines}
                            />
                        </View>
                        <View style={{}}/>

                    </View>
                    {this.props.numberOflines>=6 && <FontAwesome5 name={"ellipsis-h"} size={20} color={theme.colors.greyAlt} />}
                    <View
                        style={[styles.triangle, this.props.position === 'left' ? styles.leftTriangle : styles.bottomTriangle]}
                    />
                    {!this.props.hideIcons && this.NextSpeechBubble}
                    {!this.props.hideIcons && this.ReplaySpeechBubble}
                </TouchableOpacity>
            </View>
        );
    }
}

// SpeechBubble.propTypes = propTypes;
SpeechBubble.defaultProps = defaultProps;

export default SpeechBubble;
