import moment from "moment"
import {formatDate} from "@data/utility/Utils";
import {findClosestNextOccurrence} from "../../student/account/onboarding/LiveRoomCard";

export const subscriptionPausedStatuses = ["on_grace_period_before_pause", "paused", "planned"]

export const findItemInListWithId = (id, list, otherFieldName) => {

    return list?.find((item) => {
        return item?.id === parseInt(id) || item?.[otherFieldName] === id
    })

}

export const idsToObjects = (idList, objectList) => {
    return idList?.map(x => objectList?.find(y => y.id === x))
}

export const tasksForActivities = (activities) => {
    let tasks = []

    activities?.forEach((activity) => {
        tasks = tasks.concat(activity?.tasks)
    })
    return tasks
}

export const filterTodayActivitiesForChallenge = (allActivities, challenge) => {

    const today = moment().format('YYYY-MM-DD')

    const challengeActivities = idsToObjects(challenge.activity_ids, allActivities)
    return challengeActivities?.filter(activity => moment(activity?.date).isSame(today, 'day'))

}

export const countLateActivitiesForChallenge = (allActivities, challenge) => {

    const challengeActivities = idsToObjects(challenge.activity_ids, allActivities)
    return challengeActivities?.filter(activity => activity?.is_late)?.length

}

export const interviewsForStudents = (students) => {

    let interviews = []

    students?.forEach((student) => {
        interviews = interviews.concat(student?.interviews)
    })

    return interviews.sort((a, b) => moment(a.date_time).diff(b.date_time))

}

export const studentActivitiesForChallengeId = (student, challengeId) => {
    return student?.activities?.filter(x => x.challenge_id === challengeId)
}

export const studentActivitiesForActivityTemplateId = (student, activityTemplateId) => {
    return student?.activities?.filter(x => x?.activity_template_id === activityTemplateId)
}

export const studentSurveyForActivityTemplate = (student, activityTemplate) => {
    return student?.survey_answers?.filter(x => x.id === activityTemplate.student_survey_question_id)
}

export const nextInterviewForStudent = (student) => {

    const nextOrTwoDaysBeforeList = student?.interviews
        ?.filter(x => !x.finalized_at && moment(x?.date_time).isAfter(moment().subtract(2, 'day')))
        ?.sort((a, b) => moment(a?.date_time)?.diff(b?.date_time))

    if (nextOrTwoDaysBeforeList?.length > 0) {
        return nextOrTwoDaysBeforeList[0]
    }

    return null
}

export const todayLivesForStudent = (student) => {

    const sameDayList = student?.live_sessions
        ?.filter(x => moment(x?.day_time).isSame(moment(), "day"))
        ?.sort((a, b) => moment(a?.day_time)?.diff(b?.day_time))

    if (sameDayList?.length > 0) {
        return sameDayList
    }

    return null
}

export const lastFinishedInterviewForStudent = (student) => {

    const finishedInterviews = student?.interviews?.filter(x => !!x?.finalized_at)?.sort((a, b) => moment(b.date_time).diff(a.date_time))

    if (finishedInterviews?.length > 0) {
        return finishedInterviews[0]
    }

    return null
}

export const isWebConfVisibleForStudent = student => {
    const interview = nextInterviewForStudent(student)
    if (!interview) return false

    return moment(interview?.date_time).isSameOrBefore(moment(), 'day')
}

export const isLiveVisibleForStudent = student => {
    const live = nextLiveForStudent(student)
    if (!live) return false

    return moment(interview?.date_time).isSameOrBefore(moment(), 'day')
}

export const activitiesForStudent = (student) => {

    return student.activities?.tasks?.filter(x => moment(x?.date).isSame(moment(), 'day'))

}

export const isSubscribed = (student) => {
    return student?.subscription_state?.status === "recurring"
        || student?.subscription_state?.status === "on_trial"
        || student?.subscription_state?.status === "on_grace_period"
        || student?.subscription_state?.status === "on_grace_period_before_pause"
        || student?.subscription_state?.status === "planned"

}
export const countSameActivitiesToday = (activityTemplate, activities, challenge_id = null, school_subject_id = null) => {

    if (activityTemplate?.parent_type === "challenge") {

        return activities?.filter(x => x?.activity_template_id === activityTemplate?.id)
            ?.filter(x => x?.challenge_id === challenge_id)
            ?.filter(x => x?.finished_at && moment().isSame(x?.finished_at, 'day'))
            ?.filter(x => x?.status !== 'rejected')
            ?.length

    } else {
        return activities?.filter(x => x?.activity_template_id === activityTemplate?.id)
            ?.filter(x => x?.school_subject_id === school_subject_id)
            ?.filter(x => x?.finished_at && moment().isSame(x?.finished_at, 'day'))
            ?.filter(x => x?.status !== 'rejected')
            ?.length
    }

}

export const countSameAgainActivities = (activityTemplate, activities, againActivityId) => {

    const activity = activities?.find(x => x?.id === againActivityId)

    if (!activity || !activity?.finished_at)
        return 0

    return 1 + activities
        ?.filter(x => x?.activity_template_id === activityTemplate?.id)
        ?.filter(x => x?.again_activity_id === againActivityId)
        ?.filter(x => !!x?.finished_at)
        ?.filter(x => x?.status !== 'rejected')
        ?.length

}

export const todaySchoolSubjectActivities = (user, activityTemplates) => {

    let doneCount = 0
    let totalCount = 0

    const todaySchoolSubjects = user?.school_subjects
        ?.filter(s => s?.prioritized)

    const todaySchoolSubjectActivities = []

    const todayWeekDay = moment().format("dddd")

    todaySchoolSubjects?.forEach(ts => {

        const titles = {
            lesson_day: 'Le ' + todayWeekDay + ' tu as cours de ' + ts?.name + "...",
            lesson_day_before: 'Prépare-toi pour ton cours de ' + ts?.name + " de demain...",
            lesson_day_after: 'Hier, tu as eu cours de ' + ts?.name + "...",
            week_end: "On est le week-end, profite-en pour t'avancer en " + ts?.name
        }

        let filteredATs = []

        Object.keys(titles)?.forEach(t => {
            if (ts?.today_status[t]) {

                filteredATs = filteredATs?.concat(activityTemplates?.filter(at => (at?.schedule_day === t)))

            }
        })

        if (filteredATs?.length > 0) {
            const filteredActivities = user?.activities
                ?.filter(a => filteredATs?.map(at => at?.id)?.includes(a?.activity_template_id))
                ?.filter(a => a?.school_subject_id === ts?.id)
                ?.filter(a => moment().isSame(a?.finished_at, 'day'))
            // ?.filter(a => !!a?.finished_at)

            // console.log(filteredActivities)

            const doneFilteredATs = filteredATs
                ?.filter(at => filteredActivities?.map(a => a?.activity_template_id)?.includes(at?.id))

            doneCount += doneFilteredATs?.length
            totalCount += filteredATs?.length

            filteredATs?.forEach(at => {
                todaySchoolSubjectActivities.push({
                    // title: titles[t],
                    school_subject: ts,
                    activity_template: at,
                    activities: filteredActivities?.filter(a => a?.activity_template_id === at?.id)
                })
            })

            // todaySchoolSubjectActivities.push({
            //     // title: titles[t],
            //     school_subject: ts,
            //     activity_templates: filteredATs,
            //     activities: filteredActivities
            // })
        }
        // else {
        //     todaySchoolSubjectActivities.push({
        //         // title: titles[t],
        //         school_subject: ts,
        //         activity_templates: [],
        //         activities: []
        //     })
        // }

    })

    return {
        items: todaySchoolSubjectActivities,
        total_count: totalCount,
        done_count: doneCount
    }
}

export const todayChallengeActivities = (user, activityTemplates) => {

    let doneCount = 0
    let totalCount = 0
    const todayChallengeActivities = []

    user?.challenges
        ?.filter(c => {
            if (!c?.planning_mode)
                return false

            const todayActivities = user?.activities?.filter(a => a?.challenge_id === c?.id && a?.is_shown_today)
            return todayActivities?.length > 0
        })
        ?.forEach(c => {

            const todayActivities = user?.activities?.filter(a => a?.challenge_id === c?.id && a?.is_shown_today)
            const againActivities = user?.activities?.filter(a => a?.challenge_id === c?.id && a?.again_activity_id)
            const doneTodayActivities = todayActivities?.filter(a => !!a?.finished_at)
            totalCount += todayActivities?.length
            doneCount += doneTodayActivities?.length


            todayActivities?.forEach(ta => {
                todayChallengeActivities.push({
                    challenge: c,
                    activities: todayActivities
                        ?.filter(a => a?.activity_template_id === ta?.activity_template_id)
                        ?.concat(againActivities?.filter(a => a?.activity_template_id === ta?.activity_template_id)),
                    activityPlanned: ta,
                    activity_template: activityTemplates?.find(at => at?.id === ta?.activity_template_id)
                })
            })


        })

    return {
        items: todayChallengeActivities,
        total_count: totalCount,
        done_count: doneCount
    }
}


export const sortByDateAttribute = (list, dateAttribute = "date", asc = true) => {
    return list?.sort((a, b) => moment(a?.[dateAttribute]).diff(b?.[dateAttribute]) * (asc ? 1 : -1))
}

export const groupBy = (xs, key) => {
    return xs?.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

export const isSameCalendarDay = (moment1, moment2) => {
    return moment(moment1?.format("YYYY-MM-DD"))?.isSame(moment2?.format("YYYY-MM-DD"))
}

export const sortLiveSessionsCallback = (a, b) => {
    // const n = 7 * 24 * 60

    const closestNextOccurrenceA = findClosestNextOccurrence([a]) // 2023-10-02
    const closestNextOccurrenceB = findClosestNextOccurrence([b]) // 2023-09-28

    // if (a?.live_room_id === 3) {
    //     console.log("closestNextOccurrenceA", closestNextOccurrenceA)
    //     console.log("closestNextOccurrenceB", closestNextOccurrenceB)
    // }

    const liveMomentA = moment(closestNextOccurrenceA?.date)
    const liveMomentB = moment(closestNextOccurrenceB?.date)

    const diffA = isSameCalendarDay(liveMomentA, moment()) ? Math.abs(liveMomentA.diff(moment(), "minutes")) : liveMomentA.diff(moment(), "minutes")
    const diffB = isSameCalendarDay(liveMomentB, moment()) ? Math.abs(liveMomentB.diff(moment(), "minutes")) : liveMomentB.diff(moment(), "minutes")

    return diffB > diffA ? -1 : diffB < diffA ? 1 : 0
}

export const isSubscriptionPaused = (user) => subscriptionPausedStatuses.includes(user?.subscription_state?.status)
export const isSubscriptionRecurring = (user) => user?.subscription_state?.status === "recurring"
export const isSubscriptionTrialing = (user) => user?.subscription_state?.status === "on_trial"

export const subscriptionPausePeriod = user => moment(user?.subscription_state?.end_date).isBefore(user?.subscription_state?.planned_start_date) ? `du ${formatDate(user?.subscription_state?.end_date)} au ${formatDate(moment(user?.subscription_state?.planned_start_date))}` : ""
