import React, {useEffect} from 'react';
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {Tabs, TabScreen} from 'react-native-paper-tabs';
import {findItemInListWithId} from "@data/utility/dataUtils";
import {theme} from "@common/theme/theme";
import {studentsService} from "@data/services/studentsService";
import RealizationsTabScreen from "./list/RealizationsTabScreen";
import ChallengesTabScreen from "./challenges/ChallengesTabScreen";
import {Dimensions, Platform, View} from "react-native";
import StudentMainMenuHeader from "@common/header/StudentMainMenuHeader";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TodoListScreen from "../activity/todo/TodoListScreen";

const StudentTabsNavigator = (props) => {

    const {navigation, route} = props
    const studentId = route.params?.studentId
    const initialTabRealizations = route.params?.initialTabRealizations ?? false
    const anonymous = route.params?.anonymous ?? false


    useEffect(() => {
        if (studentId)
            props.apiRequest(studentsService.getStudent, {id: studentId})
    }, []);

    useApiStatus(
        studentsService.getStudent, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            //console.log(data)
        } // success callback
    )

    const insets = useSafeAreaInsets()

    const liveSession = props.currentLiveSession;

    const student = !studentId ? props.user : (findItemInListWithId(studentId, liveSession?.users) ?? findItemInListWithId(studentId, props.user?.students))
    const tabs = [
        // {
        //     name: "Devoirs",
        //     component: HomeworkTabScreen
        // },
        // {
        //     name: "Défis",
        //     component: ChallengesTabScreen
        // },
        {
            name: "À faire",
            component: TodoListScreen,
        },
        {
            name: "Réalisations",
            component: RealizationsTabScreen
        }
    ]

    function onButtonPress() {
        // props.apiRequest(authService.revokeSanctumToken, {}, {})
    }

    return <>
        <View style={{
            paddingVertical: 5,
            paddingTop: insets.top + 5,
            backgroundColor: theme.colors.white,
            paddingRight: 10
        }}>
            <StudentMainMenuHeader studentId={studentId} anonymous={anonymous}/>
        </View>
        <Tabs
            defaultIndex={anonymous || initialTabRealizations ? 1 : 0} // default = 0
            // uppercase={false} // true/false | default=true | labels are uppercase
            // showTextLabel={false} // true/false | default=false (KEEP PROVIDING LABEL WE USE IT AS KEY INTERNALLY + SCREEN READERS)
            // iconPosition="leading" // leading, top | default=leading
            style={{
                backgroundColor: theme.colors.white,
                width: Platform.OS === 'web' ? Dimensions.get("window").width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }} // works the same as AppBar in react-native-paper
            // dark={false} // works the same as AppBar in react-native-paper
            // theme={} // works the same as AppBar in react-native-paper
            // mode="scrollable" // fixed, scrollable | default=fixed
            // onChangeIndex={(newIndex) => {}} // react on index change
            // showLeadingSpace={true} //  (default=true) show leading space in scrollable tabs inside the header
            disableSwipe={true} // (default=false) disable swipe to left/right gestures
        >
            {tabs.map((tab) => {
                return <TabScreen label={tab.name}
                                  key={tab.name}
                    // icon={<TextM>t</TextM>}
                >
                    {React.createElement(tab.component, {student: !studentId ? null : student, navigation})}
                </TabScreen>
            })}

        </Tabs>
    </>

};

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
            (x) => x?.id === state.liveMode?.liveSessionId
        ),
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentTabsNavigator)