import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import React, {useContext, useEffect, useRef} from "react";
import {theme} from "@common/theme/theme";
import {StatusBar, View} from "react-native";
import {idsToObjects} from "@data/utility/dataUtils";
import ChallengesListItem from "./ChallengesListItem";
import moment from "moment";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import {challengesService} from "@data/services/challengesService";
import {resetActivitiesHistory} from "@data/redux/actions/data";
import { IS_WEB} from "@common/utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import TitleM from "@common/components/text/TitleM";
import {IconButton} from "react-native-paper";
import {HeaderContainerM} from "@common/header/HeaderContainerM";

const ChallengeHistoryScreen = (props) => {

    // ** Component props
    const {navigation, route} = props
    const {studentId} = route.params


    const user = studentId ? props.user?.students?.find(s => s.id === parseInt(studentId)) : props.user
    const requestApiService = studentId ? challengesService.getStudentChallengeHistory : challengesService.getChallengeHistory
    const requestRouteParams = studentId ? {id: studentId} : {}

    // ** Manage scrolling to specific index in flat list
    const flatListRef = useRef()

    const challenges = user?.history?.challenges
        ?.sort((a, b) => {
            const dateA = a?.finished_at ? a?.finished_at : a?.abandoned_at
            const dateB = b?.finished_at ? b?.finished_at : b?.abandoned_at
            return moment(dateB).diff(dateA)
        })

    useEffect(() => {
        return () => {
            props.resetActivitiesHistory(studentId)
        };
    }, []);


    return <View style={{
        flex: 1,
        backgroundColor: theme.colors.whiteAlmost,
        alignItems: 'center',
        justifyContent: 'flex-start'
    }}>
        <HeaderContainerM>
            <TitleM>Historique défis</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>
        <PaginatedFlatList ref={flatListRef}
                           data={challenges}
                           additionalOnRefresh={() => {
                           }}
                           additionalRefreshing={false}
                           requestApiService={requestApiService}
                           requestRouteParams={requestRouteParams}
                           requestData={null}
                           style={{
                               paddingTop: 20
                           }}
                           ListHeaderComponent={<>
                               {/*{IS_WEB && <FilledButtonM mode={"text"}*/}
                               {/*                          icon={"chevron-left"}*/}
                               {/*                          color={theme.colors.link}*/}
                               {/*                          style={{alignSelf: "flex-start"}}*/}
                               {/*                          onPress={() => {*/}
                               {/*                              if (navigation.canGoBack())*/}
                               {/*                                  navigation.goBack()*/}
                               {/*                              else*/}
                               {/*                                  navigation.replace("menu")*/}
                               {/*                          }}*/}
                               {/*                          label={"Retour"}/>}*/}
                           </>}
                           renderItem={(item) => {

                               const activities = idsToObjects(item.item.activity_ids, user?.history?.activities)

                               return <ChallengesListItem activities={activities}
                                                          student={studentId ? user : null}
                                                          index={item.index}
                                                          challenge={item.item}
                               />
                           }}

        />

        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        challengeIntensityLevels: state.data.staticData?.challenge_intensity_levels,
        challengeModes: state.data.staticData?.challenge_modes,
        staticData: state.data.staticData,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        resetActivitiesHistory
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeHistoryScreen)