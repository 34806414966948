import {View} from "react-native";
import {IconImageM} from "../components/icons/IconsM";
import dead from "../../../assets/icons/emojis/dead.png";
import TextM from "../components/text/TextM";
import FilledButtonM from "../components/button/FilledButtonM";
import {theme} from "../theme/theme";
import {StatusBar} from "expo-status-bar";
import React from "react";

export const ErrorBoundaryFallbackView = ({clearError}) => {

    return <View style={{
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <IconImageM source={dead} width={80} style={{marginBottom: 10}}/>
        <TextM fontWeight="SemiBold" style={{textAlign: "center"}} wrap>Une erreur s'est produite</TextM>
        <TextM style={{textAlign: "center"}} wrap>Nous nous efforçons de la corriger dans les plus brefs délais</TextM>
        <FilledButtonM mode="outlined"
                       color={theme.colors.primary}
                       style={{marginTop: 20}}
            // labelStyle={{color: theme.colors.white}}
                       onPress={clearError}
                       label="Retour"/>
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}