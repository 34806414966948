import moment from "moment"

export const activitiesConstants = {
    PAUSE_ACTIVITY: 'PAUSE_ACTIVITY',
    ONGOING_ACTIVITY: 'ONGOING_ACTIVITY',
    SET_CAMERA_STATUS: 'SET_CAMERA_STATUS',
    AUDIO_STATUS: 'AUDIO_STATUS',
    UPDATE_ACTIVITY_CHRONO: 'UPDATE_ACTIVITY_CHRONO',
}
export const RESET_ACTIVITIES_HISTORY = "RESET_ACTIVITIES_HISTORY"
export const SET_ACTIVITIES_REVIEW_FILTER = "SET_ACTIVITIES_REVIEW_FILTER"
export const SET_TODO_ACTIVITIES = "SET_TODO_ACTIVITIES"

export const categoryColors = {
    write: 'info',
    speak: 'warning',
    play: 'primary',
    form: 'light'
}

export const congratsList = [
    "Activité terminée !",
    "Bien joué !",
    "Mission accomplie !",
    "Ça, c'est fait !"
]

export const stepFinishedList = [
    "Bien joué !",
    "Formidable !",
    "Super !",
    "Génial !",
    "Bien !",
    "Très bien !",
    "Fantastique !",
    "Yay !",
    "Bravo !",
    "Splendide !"
]

export const stepErrorList = [
    "Raté",
    "Mauvaise réponse",
    "Faux",
    "Dommage",
]

// export const activityCategories = [
//   'Savoir',
//   'Motivation',
//   'Bien-Être',
//   'Organisation',
//   'Evènement'
// ]

export const weekDaysObject = {
    Monday: 'Lundi',
    Tuesday: 'Mardi',
    Wednesday: 'Mercredi',
    Thursday: 'Jeudi',
    Friday: 'Vendredi',
    Saturday: 'Samedi',
    Sunday: 'Dimanche'
}

export const weekDays = Object.keys(weekDaysObject)

export const startDates = [
    {value: moment().format("YYYY-MM-DD"), label: `Aujourd'hui, le ${moment().format("DD/MM")}`},
    {value: moment().add(1, 'day').format("YYYY-MM-DD"), label: `Demain, le ${moment().add(1, 'day').format("DD/MM")}`}
]

export const activityDurations = [{value: 1, label: "1 semaine"}]

export const weekDaysShort = {
    Monday: 'L',
    Tuesday: 'M',
    Wednesday: 'M',
    Thursday: 'J',
    Friday: 'V',
    Saturday: 'S',
    Sunday: 'D'
}

export const weekDayNums = {
    Monday: 0,
    Tuesday: 1,
    Wednesday: 2,
    Thursday: 3,
    Friday: 4,
    Saturday: 5,
    Sunday: 6
}

export const activityTypes = {
    card: "Fiche",
    written_exercise: "Exercice écrit",
    revision: "Révision",
    oral_exercise: "Exercice oral",
    external_resource: "Ressource externe"
}

export const activityGoals = {
    external_resource: "Comprendre",
    card: "Apprendre",
    exercise: "S'exercer",
    revision: "Réviser",
}

export const schoolSubjectCategories = {
    "scientific": "Scientifiques",
    "litterary/economic": "Littéraires / éco",
    "language": "Langues",
}

export const activityAssessmentResponses = [
    "Insatisfaisant",
    "Moyen",
    "Satisfaisant"
]
