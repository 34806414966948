import {apiService} from "../../../services"
import {apiConstants, VAPOR_FILE_UPLOAD} from "../../../constants/apiConstants"
import {authConstants} from "../../../constants/authConstants";

const initialState = {
    networkOn: true
}

export default function api(state = initialState, action) {

    // let i = 0

    if (action.type === apiConstants.NETWORK_ON)
        return {
            ...state,
            networkOn: true
        }
    else if (action.type === apiConstants.NETWORK_OFF)
        return {
            ...state,
            networkOn: false
        }

    if (action.type === VAPOR_FILE_UPLOAD + apiConstants._IDLE)
        return {
            ...state,
            vaporFileUpload: {}
        }
    else if (action.type === VAPOR_FILE_UPLOAD + apiConstants._REQUEST)
        return {
            ...state,
            vaporFileUpload: {
                request: true,
                file: action.file
            }
        }
    else if (action.type === VAPOR_FILE_UPLOAD + apiConstants._SUCCESS)
        return {
            ...state,
            vaporFileUpload: {
                success: true,
                response: action.response,
                file: action.file,
                formId: action.formId
            }
        }
    else if (action.type === VAPOR_FILE_UPLOAD + apiConstants._FAILURE)
        return {
            ...state,
            vaporFileUpload: {
                error: action.error,
                file: action.file
            }
        }

    for (const service of Object.keys(apiService)) {
        for (const request of Object.keys(apiService[service])) {

            // console.log(request)
            // i++

            switch (action.type) {

                case authConstants.LOGOUT_401:
                case request + apiConstants._IDLE:
                    return {
                        ...state,
                        [request]: {}
                    }

                case request + apiConstants._REQUEST:
                    return {
                        ...state,
                        [request]: {
                            request: true,
                            routeParams: action.routeParams,
                            data: action.data,
                            urlParams: action.urlParams
                        }
                    }

                case request + apiConstants._SUCCESS:
                    return {
                        ...state,
                        [request]: {
                            success: true,
                            routeParams: action.routeParams,
                            data: action.data,
                            all_data:action.all_data,
                            urlParams: action.urlParams
                        }
                    }

                case request + apiConstants._FAILURE:
                    return {
                        ...state,
                        [request]: {error: action.error, routeParams: action.routeParams, urlParams: action.urlParams}
                    }
            }
        }
    }
    // console.log(i)
    return state
}
