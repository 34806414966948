import React, {useContext} from 'react';
import {TouchableOpacity, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";

const FeelingQuizItem = (props) => {
    const {
        question,
        onSelectAnswer,
        type,
        selectedIndexes
    } = props

    const dimensions = useContext(DimensionsContext)
    const renderAnswers = () => {
        const answers = []
        for (let i = 1; i < 8; i++) {
            answers.push(<TouchableOpacity
                key={i}
                style={{
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderLeftWidth: i === 1 || selectedIndexes[type] === i ? 1 : 0,
                    borderColor: selectedIndexes[type] === i ? theme.colors.primary : theme.colors.lightMore,
                    flex: 1,
                    paddingVertical: 20,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: selectedIndexes[type] === i ? theme.colors.primaryLighter : theme.colors.white
                }}
                onPress={() => onSelectAnswer(i, type)}
            >
                <TextM wrap color={theme.colors.primary} fontSize={18}
                       fontWeight={selectedIndexes[type] === i ? "Bold" : "Regular"}>
                    {i}
                </TextM>
            </TouchableOpacity>)
        }
        return answers
    }
    return (
        <View style={{
            marginBottom: 25,
            alignItems: 'flex-start',
            width: dimensions?.width - 40
        }}>
            <TextM wrap color={theme.colors.primary} fontSize={16} fontWeight={"SemiBold"}>
                {question}
            </TextM>
            <View style={{
                flexDirection: 'row',
                width: dimensions?.width - 40,
                //justifyContent: 'space-between',
                marginTop: 5
            }}>
                {renderAnswers()}
            </View>
        </View>
    )
}

export default FeelingQuizItem;