import * as React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import {Button} from "react-native-paper";
import {theme} from "@common/theme/theme";
import {ChampionImageM} from "./ChampionImageM";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {useEffect, useState} from "react";

export const ChampionTooltipM = ({
                            isFirstStep,
                            isLastStep,
                            handleNext,
                            handlePrev,
                            handleStop,
                            currentStep,
                            labels,
                        }) => {

    const [currentText, setCurrentText] = useState(currentStep?.text)

    useEffect(() => {
        if(currentStep?.text !== currentText)
        setTimeout(() => {
            setCurrentText(currentStep?.text)
        }, 200)
    }, [currentStep.text])


    return (


        <View
            style={{
                borderRadius: 16,
                paddingTop: 12,
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 16,
                width: '80%',
                backgroundColor: '#ffffffef',
            }}
        >
            <View style={styles.tooltipContainer}>
                <ChampionImageM name="zoe" variant={isLastStep ? "happy" : "main"} width={90} />
                <TextM testID='stepDescription' wrap fontWeight={"Medium"} style={styles.tooltipText}>
                    {!!currentText && currentText}
                </TextM>
            </View>

            <View style={[styles.bottomBar]}>
                {!isFirstStep ? (
                    <TouchableOpacity>
                        <Button onPress={handlePrev}>{labels?.previous || 'Précédent'}</Button>
                    </TouchableOpacity>
                ) : null}
                {!isLastStep ? (
                    <TouchableOpacity>
                        <Button onPress={handleNext}>{labels?.next || 'Suivant'}</Button>
                    </TouchableOpacity>
                ) : (
                    <TouchableOpacity>
                        <Button onPress={handleStop}>{labels?.finish || 'Terminer'}</Button>
                    </TouchableOpacity>
                )}
            </View>
            <View style={[styles.bottomBar, {position: "absolute", top: 0, left: 0}]}>
                {!isLastStep ? (
                    <TouchableOpacity>
                        <Button onPress={handleStop}><FontAwesome5 name="times" size={20} color={theme.colors.primary}/></Button>
                    </TouchableOpacity>
                ) : null}
            </View>
        </View>
    )
}




export const Z_INDEX = 100
export const MARGIN = 13
export const OFFSET_WIDTH = 4

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: Z_INDEX,
    },
    tooltip: {
        position: 'absolute',
        paddingHorizontal: 15,
        overflow: 'hidden',
        width: '100%',
        borderRadius: 16,
        paddingTop: 24,
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 16,
        zIndex: Z_INDEX - 1,
    },
    nonInteractionPlaceholder: {
        backgroundColor: 'transparent',
        zIndex: Z_INDEX - 2,
    },
    tooltipText: {
        textAlign: 'center',
        marginTop: 10,
    },
    tooltipContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-around',
        width: '80%',
    },
    button: {
        padding: 10,
    },
    buttonText: {
        color: theme.colors.primary,
    },
    bottomBar: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    overlayContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    },
})