import {Characters} from './types';

export const DEFAULT_CHARACTERS: Characters = {
    skinColor: 'sc1',
    hair: 'hair1',
    hairColor: 'hc1',
    body: 'body1',
    bodyColor: 'bc1',
    facialHair: 'facialHair1',
    facialHairColor: 'fhc1',
    eyes: 'eyes1',
    eyesColor: 'ec1',
    shirt: 'shirt1',
    nose: 'nose1',
    backgroundColor: 'bgc1',
    jacket: null,
};

export function combineCharacters({
                                      body,
                                      eyes,
                                      eyesColor,
                                      facialHair,
                                      hair,
                                      shirt,
                                      nose,
                                      backgroundColor,
                                      bodyColor,
                                      skinColor,
                                      hairColor,
                                      facialHairColor,
                                      jacket
                                  }: Characters) {
    return `${body}|${eyes}|${eyesColor}|${facialHair}|${hair}|${shirt}|${nose}|${backgroundColor}|${skinColor}|${hairColor}|${facialHairColor}|${bodyColor}|${jacket}`;
}

export function parseCharacters(characters: string): Characters {
    const array = characters?.split('|');
    if (array.length < 13) {
        console.log('Invalid characters.');
        return DEFAULT_CHARACTERS;
    }
    return {
        body: array[0] as Characters['body'],
        eyes: array[1] as Characters['eyes'],
        eyesColor: array[2] as Characters['eyesColor'],
        facialHair: array[3] as Characters['facialHair'],
        hair: array[4] as Characters['hair'],
        shirt: array[5] as Characters['shirt'],
        nose: array[6] as Characters['nose'],
        backgroundColor: array[7] as Characters['backgroundColor'],
        skinColor: array[8] as Characters['skinColor'],
        hairColor: array[9] as Characters['hairColor'],
        facialHairColor: array[10] as Characters['facialHairColor'],
        bodyColor: array[11] as Characters['bodyColor'],
        jacket: array[12] as Characters['jacket'],
    };
}

export const random = (array: any[]) => {
    const index = Math.floor(Math.random() * array.length);
    return array[index];
};
