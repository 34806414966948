import {cameraConstants} from "@data/constants/cameraConstants";

export function setLastPhoto(formId, newPhoto) {
    return {type: cameraConstants.NEW_PHOTO, formId, newPhoto}
}

export function setPhotos(formId, newPhotos) {
    return {type: cameraConstants.NEW_PHOTOS, formId, newPhotos}
}

export function clearLastPhoto() {
    return {type: cameraConstants.PHOTO_CLEAR}
}

export function clearPhotos() {
    return {type: cameraConstants.PHOTOS_CLEAR}
}

export function recordCameraSettings(imagePadding, ratio) {
    // console.log("saved new settings : ratio ", ratio, ", imagePadding ", imagePadding)
    return {type: cameraConstants.RECORD_CAMERA_SETTINGS, ratio, imagePadding}
}

export function resetCameraSettings() {
    return {type: cameraConstants.RESET_CAMERA_SETTINGS}
}

export function setVideoRecord(video) {
    return {type: cameraConstants.SET_VIDEO_RECORD, video}
}