import {View} from "react-native";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TitleM from "@common/components/text/TitleM";

function SkillSetHeader(props) {

    const {name, color, title, style} = props


    return <View style={{
        ...style,
        flexGrow: 0,
        width: "100%",
        // backgroundColor: color + "22",
        paddingHorizontal: 15,
        paddingTop: 15,
        alignItems: "center"
    }}>

        <TitleM color={color}
                style={{width: "80%", textAlign: "center"}}
                fontWeight="ExtraBold">{name?.toUpperCase()}</TitleM>
        {/*<TextM color={theme.colors.greyAlt}*/}
        {/*       fontWeight={"Medium"}*/}
        {/*>*/}
        {/*    {title}*/}
        {/*</TextM>*/}


    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
        skill_sets: state.data.staticData?.skill_sets
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(SkillSetHeader)