import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import {SuccessBadge} from "./SuccessBadge";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {PetalCounter} from "@common/components/statistics/Counters";
import {achievementsService} from "@data/services/achievementsService";

const NewAchievementCongrats = props => {

    const {achievement} = props

    const success = findItemInListWithId(achievement?.success_id, props.successes)

    useEffect(() => {
        props.apiRequest(achievementsService.markAsRead, {id: achievement?.id})
    }, [])

    useEffect(() => {
        if (props.markAchievementAsReadError)
            console.log(props.markAchievementAsReadError)

    }, [props.markAchievementAsReadError])
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        alignItems: "center",
        width: dimensions?.width
    }}>
        <TextM fontSize={20}
               fontWeight="Bold"
               color={theme.colors.primary}
               style={{textAlign: "center", alignSelf: "center", marginTop: 40}}
               wrap
        >NIVEAU SUPÉRIEUR !</TextM>

        <SuccessBadge success={success}
                      level={achievement?.level}
                      style={{marginTop: 80}}/>

        <TextM fontSize={20}
               fontWeight="Bold"
               color={theme.colors.primary}
               style={{textAlign: "center", alignSelf: "center", marginTop: 30, paddingHorizontal: 20}}
               wrap
        >Tu as remporté le succès {success?.name} Niv. {achievement?.level} !</TextM>

        <TextM fontSize={16}
               fontWeight="Medium"
               color={theme.colors.primary}
               style={{textAlign: "center", alignSelf: "center", marginTop: 10}}
               wrap
        >{success?.levels[achievement?.level] > 1
            ? success?.achievement_description?.replace("[count]", success?.levels[achievement?.level])
            : success?.achievement_description_sng?.replace("[count]", success?.levels[achievement?.level])}</TextM>


        {achievement?.last_gained_petals > 0 && <PetalCounter count={"+" + achievement?.last_gained_petals} style={{
            marginTop: 50
        }}/>}

        <View style={{height: 100}}/>

    </View>


}
const mapStateToProps = state => {
    return {
        successes: state.data.staticData?.successes,

        markAchievementAsReadRequest: state.api.markAsRead?.request,
        markAchievementAsReadSuccess: state.api.markAsRead?.success,
        markAchievementAsReadError: state.api.markAsRead?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewAchievementCongrats)