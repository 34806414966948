import {TouchableOpacity, View} from "react-native";
import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {useNavigation} from "@react-navigation/native";
import {boxedCardBorderStyle} from "../reviewer/BoxedCardBorderStyle";

export function SchoolCard({school, small, withStudents, noClick, noCard = false}) { //TODO : pas de card pour l'écran SchoolScreen

    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    return (
        <TouchableOpacity style={[{
            width: dimensions?.width,
            borderColor: theme.colors.lightMore,
            paddingHorizontal: 10,
            paddingVertical: 10,
            marginVertical: 10,
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
        }, !noCard && {
            ...boxedCardBorderStyle,
            borderTopWidth: 1,
            borderBottomWidth: 1,
            backgroundColor: theme.colors.white,
        }]}
                          onPress={(noClick) ? () => {
                          } : () => {
                              navigation.push("school", {
                                  schoolId: school?.id
                              })
                          }}
                          disabled={noClick}
        >
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                width: "80%",
            }}>
                <IconImageM uri={school?.logo_path} width={70} style={{resizeMode: 'contain'}}/>
                <View style={{
                    paddingHorizontal: 20,
                    width: "80%",
                }}>
                    <TextM fontWeight={"Bold"} fontSize={20}>{school?.name}</TextM>
                    <TextM wrap>{school?.description}</TextM>
                </View>
            </View>
            <View>
                <TextM fontWeight={"Bold"}>{school?.students_count}</TextM>
                <TextM>élèves</TextM>
            </View>
        </TouchableOpacity>
    )
}