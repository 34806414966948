import {cameraConstants} from "@data/constants/cameraConstants";

const initialState = {
    ratio: null,
    imagePadding: null,
    lastPhoto: null,
    formId: null,
    chatPhoto: null,
    videoRecord: null
}

export default function camera(state = initialState, action) {
    switch (action.type) {
        case cameraConstants.SET_VIDEO_RECORD:
            return {
                ...state,
                videoRecord: action.video,
            }
        case cameraConstants.RECORD_CAMERA_SETTINGS:
            return {
                ...state,
                ratio: action.ratio,
                imagePadding: action.imagePadding
            }

        case cameraConstants.RESET_CAMERA_SETTINGS:
            return {
                ...state,
                ratio: null,
                imagePadding: null
            }
        case cameraConstants.NEW_PHOTO:
            return {
                ...state,
                formId: action.formId,
                lastPhoto: action.newPhoto
            }
        case cameraConstants.NEW_PHOTOS:
            return {
                ...state,
                formId: action.formId,
                lastPhotos: action.newPhotos
            }
        case cameraConstants.PHOTO_CLEAR:
            return {
                ...state,
                formId: null,
                lastPhoto: null
            }
        case cameraConstants.PHOTOS_CLEAR:
            return {
                ...state,
                formId: null,
                lastPhotos: []
            }
        default:
            return state
    }
}
