import {View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React, {useContext} from "react";
import {DimensionsContext} from "../../utils/mobileUtils";

export const IconTextM = ({icon, color = theme.colors.grey, text, style, textStyle, fontSize, iconSize, iconStyle, right=false}) => {


    const dimensions = useContext(DimensionsContext)

    const renderIcon = () => <FontAwesome5 name={icon}
                                           size={iconSize ?? 22}
                                           color={color}
                                           style={{
                                               width: iconSize ?? 22,
                                               marginRight: 5,
                                               ...iconStyle
                                           }}/>

    return <View style={{
        flexDirection: 'row',
        marginTop: 20,
        alignItems: 'center',
        marginHorizontal: 10,
        width: dimensions.width,
        ...style
    }}>
        {!right && renderIcon()}
        <TextM fontSize={fontSize ?? 16}
               wrap
               color={color}
               style={{
                   width: "86%",
                   ...textStyle
               }}>
            {text}
        </TextM>
        {right && renderIcon()}
    </View>
}