import {Keyboard, Platform, View} from "react-native";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import React, {useEffect, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TitleM from "@common/components/text/TitleM";
import {activitiesService} from "@data/services/activitiesService";
import {findItemInListWithId} from "@data/utility/dataUtils";
import SelectBox from "@common/components/select/selectSingle-Multiple";
import {scoringService} from "@data/services/scoringService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import SamAppreciationGenerator from "./SamAppreciationGenerator";
import {useForm} from "react-hook-form";
import alertPolyfill from "@common/components/alert/alertPolyfill";

const RejectActivity = (props) => {

    const {activity, schoolSubject, time, dimensions, navigation} = props

    const rejectionComments = props.scoringComments?.filter(sc => sc?.type === 'reject'
        && sc?.activity_types?.includes(activity?.type)
        && sc?.school_subject_categories?.includes(schoolSubject?.category)
    )

    const {control, handleSubmit, formState: {errors, dirtyFields}, getValues, trigger} = useForm({
        defaultValues: {
            comment: ""
        }
    })

    const [selectedCommentId, setSelectedCommentId] = useState(null)

    // const selectedComment = findItemInListWithId(selectedCommentId, props.scoringComments)?.comment ?? ""

    // console.log(selectedCommentId)
    // console.log(selectedComment)

    const hideButton = getValues()?.comment === ""

    useApiStatus(
        scoringService.postScoringComment, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (data?.type === "reject") {
                // console.log("SUCCESS reject")
                // console.log("data", data)
                setSelectedCommentId(data?.id)
                props.apiIdle(scoringService.postScoringComment)
            }

        }, // success callback
        () => {
        },
        true
    )

    const onSubmit = (data) => {
        if (props.reviewActivityRequest) return
        Keyboard.dismiss()
        // console.log({...data, review_duration: time})
        props.apiRequest(activitiesService.reviewActivity, {id: activity?.id}, {
            status: "rejected",
            comment: data?.comment,
            review_duration: time,
        })
    }

    const commentWidth = Platform.OS !== 'web' ? dimensions?.width - 30 : dimensions?.width - 100

    return <View style={{
        alignItems: "center",
        justifyContent: "flex-start",
        alignSelf: "center",
        // paddingHorizontal: 15,
        paddingBottom: 20,
        width: commentWidth,
        // backgroundColor: "red",
        height: "90%"
    }}>
        <View style={{flexDirection: "row", width: commentWidth, alignItems: "center", justifyContent: "center"}}>
            <FontAwesome5 name={"times-circle"}
                          solid
                          size={24}
                          color={theme.colors.grey}/>
            <TitleM style={{marginLeft: 6}} color={theme.colors.grey}>ACTIVITÉ REJETÉE</TitleM>
        </View>
        <View style={{width: "100%", alignItems: "flex-start", marginTop: 20}}>
            <SelectBox title="Commentaire de Sam"
                       withClipBoard
                       description={"Activité rejetée"}
                       style={{
                           width: commentWidth,
                           // marginTop: -30
                       }}
                       options={rejectionComments?.map(x => {
                           return {
                               label: x?.comment,
                               value: x?.id
                           }
                       })}
                       valueMode
                       value={selectedCommentId}
                       onSingleSelect={(value) => {
                           setSelectedCommentId(value.value);
                       }}
                       titleRightButtonIcon={"plus"}
                       onTitleRightButtonPress={() => {
                           navigation.push("new-scoring-comment", {
                               type: "reject",
                               description: "Activité rejetée",
                               scoringRuleId: null
                           })
                       }}
            />

        </View>
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 20,
            borderTopWidth: 1,
            // borderBottomWidth: 1,
            borderColor: theme.colors.lightMore
            // backgroundColor: theme.colors.white
        }}>
            <TextM fontSize={18}
                   wrap
                   fontWeight="Bold"
                   color={theme.colors.primary}
                   style={{textAlign: "center", marginTop: 20, marginBottom: 20}}>
                APPRÉCIATION DE SAM
            </TextM>
            <SamAppreciationGenerator variant={"unhappy"}
                                      fieldName={"comment"}
                                      control={control}
                                      errors={errors}
                                      activity={activity}
                                      dimensions={dimensions}
                                      rawAppreciation={findItemInListWithId(selectedCommentId, props.scoringComments)?.comment ?? null}
                                      activityStatus={"rejected"}
                                      width={commentWidth}/>

            <FilledButtonM color={theme.colors.error}
                           style={{
                               // width: IS_WEB && dimensions.width > 800 ? dimensions.width / 1.2 : dimensions.width - 20,
                               alignSelf: 'center',
                               marginTop: 20,
                               marginBottom: 15
                               // bottom: 15
                           }}
                           disabled={hideButton}
                           loading={props.reviewActivityRequest}
                           textColor={theme.colors.white}
                           onPress={handleSubmit(onSubmit)}
                           label="Rejeter"/>

        </View>


    </View>
}
const mapStateToProps = state => {
    return {

        rejection_comments: state.data.staticData?.rejection_comments,
        scoringComments: state.data.reviewer?.object?.scoringComments,

        reviewActivityRequest: state.api.reviewActivity?.request,
        reviewActivitySuccess: state.api.reviewActivity?.success,
        reviewActivityError: state.api.reviewActivity?.error
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RejectActivity)