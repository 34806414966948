import {React, useState} from "react";
import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {IconImageM} from "@common/components/icons/IconsM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {ImageViewerModal} from "@common/camera/CameraImagesM";

export const MaterialImage = (props) => {
    const {
        images, setImages, renderIconButton, canDeleteImage, handleShowMaterialModal = () => {
        }, title, description
    } = props

    const [openImageViewer, setOpenImageViewer] = useState(false)

    const hideImageViewer = () => {
        return setOpenImageViewer(false)
    }

    return <TouchableOpacity style={{
        backgroundColor: theme.colors.primaryLighter,
        borderRadius: theme.roundness,
        marginTop: 20,
        padding: 20
    }}
                             onPress={handleShowMaterialModal}
                             disabled={props.canOpenImage === false}
    >
        <View style={{
            width: "100%",
            justifyContent: "center",
            alignItems: "stretch",
            alignSelf: "center"
            // backgroundColor: "red"
        }}>
            <View style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center"
            }}>
                <TextM wrap
                       fontWeight="ExtraBold"
                       color={theme.colors.primary}
                       fontSize={18}
                       style={{}}>{title ? title : "Image"}</TextM>
                <TextM wrap
                       fontWeight="Regular"
                       color={theme.colors.grey}
                       fontSize={14}
                       style={{}}>{description ? description : ""}</TextM>
            </View>
            <IconImageM source={{uri: images[0]?.uri ?? images[0]}}
                        resizeMode={"contain"}
                        width={160}
                        height={160}
                        style={{
                            alignSelf: "center",
                            // tintColor: theme.colors.primary,
                            marginTop: 15
                            // height: 160
                        }}
            />
            {canDeleteImage && renderIconButton(() => {
                DialogM.show({
                    text1: 'Retirer l\'image ?',
                    buttonText1: 'Oui',
                    buttonColor1: theme.colors.error,
                    onPress: () => {
                        setImages([])
                    },
                    buttonText2: 'Annuler'
                })
            })}
        </View>
        <ImageViewerModal visible={openImageViewer}
                          onDismiss={hideImageViewer}
                          imageIndex={0}
                          images={images?.map(i => i?.uri ?? i)}
        />
    </TouchableOpacity>
}