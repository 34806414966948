import React, {useContext, useState} from "react";
import {Dimensions, Platform, TouchableOpacity, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {displayTimeSeconds, formatFullDateTime} from "@data/utility/Utils";
import {theme} from "@common/theme/theme";
import {useInterval} from "@common/utils/useInterval";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import AvatarM from "@common/components/avatar/AvatarM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {Appbar} from "react-native-paper";
import {StatusBar} from "expo-status-bar";
import {useNavigation} from "@react-navigation/native";
import TitleM from "@common/components/text/TitleM";

export const InterviewTitle = (title) => <View style={{alignItems: 'center', marginTop: 10}}>
    <TitleM fontWeight="ExtraBold">{title}</TitleM>
</View>
export const header = (title) => {
    const navigation = useNavigation()
    return (<View style={{
        width: Platform.OS === 'web' ? Dimensions.get('window').width : null,
        alignSelf: Platform.OS !== 'web' ? null : "center"
    }}>
        <Appbar.Header
            theme={{colors: {primary: theme.colors.white}}}
            statusBarHeight={Platform.OS === "ios" ? StatusBar.height : 0}
        >
            {Platform.OS !== "web" && (

                <Appbar.BackAction
                    onPress={navigation.goBack}
                    color={theme.colors.primary}
                />)}
            <Appbar.Content
                color={theme.colors.primary}
                titleStyle={{
                    fontFamily: "Montserrat-Bold"
                }}
                title={title}
            />
        </Appbar.Header>
    </View>)
}
const CardStyle = {
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 20,
    borderColor: theme.colors.lightMore,
    borderWidth: 1,
    padding: 10,
    backgroundColor: theme.colors.white
}

function InterviewHeader(props) {

    const {interview, student, title} = props
    const [time, setTime] = useState(0)


    useInterval(() => {
        setTime(pt => pt + 1)
    }, !interview?.finished_at ? 1000 : null)

    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        width: dimensions?.width,
        alignItems: "center",
        marginTop: -10,
        borderBottomWidth: 1,
        borderColor: theme.colors.lightMore,
        // marginBottom: 10
    }}>
        <TextM wrap color={theme.colors.primary} fontWeight={"SemiBold"}>Entretien hebdo
            - {formatFullDateTime(interview?.date_time)}</TextM>

        <View style={{
            flexDirection: 'row',
            width: dimensions?.width,
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginTop: 5
        }}>

            <TouchableOpacity style={{
                flexDirection: 'row',
                flex: 1,
                // ...CardStyle,
                alignItems: 'center',
                // backgroundColor: theme.colors.white,
                flexWrap: 'wrap',
                paddingVertical: 5,
                justifyContent: 'center'
            }} onPress={() => {
                navigation.push("student-profil",
                    {studentId: student?.id}
                );
            }}>
                <AvatarM user={student}/>
                <View style={{marginLeft: 15}}>
                    <TextM color={theme.colors.primary}>{student?.firstname} {student?.lastname} </TextM>
                    <TextM color={theme.colors.light}>{student?.profile?.class}  </TextM>
                </View>

            </TouchableOpacity>
            <View style={{
                // ...CardStyle,
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                paddingVertical: 5

                // backgroundColor: theme.colors.white
            }}>
                <FontAwesome5 name="clock" size={22} color={theme.colors.primary}/>
                <TextM fontSize={20}
                       style={{marginLeft: 6, width: 60, textAlign: "center"}}>{displayTimeSeconds(time)}</TextM>
            </View>
        </View>
    </View>
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        tutorConnectedRequest: state.api.interviewTutorConnected?.request,
        students: state.data?.currentUser?.object?.students,
        slots: state.data?.currentUser?.object?.interview_slots
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewHeader)