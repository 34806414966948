import React, {useContext, useEffect, useState} from 'react';
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {theme} from "@common/theme/theme";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {tutorsService} from "@data/services/tutorsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import InterviewQuestion from "./InterviewQuestion";
import {ActivityIndicator} from "react-native-paper";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {congratsTypes} from "@common/screens/CongratsScreen";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import moment from "moment";

const InterviewScreen = (props) => {

    const {navigation} = props
    const [formValues, setFormValues] = useState(null)
    const testCategory = props.route.params?.testCategory ?? "motivation"
    const defaultValues = {}
    // const currentStepId = props.user?.application_current_step?.step
    const [currentStepName, setCurrentStepName] = useState(testCategory)
    const [champion, setChampion] = useState(null);

    const questions = props.testQuestions?.[0]?.category === currentStepName ? props.testQuestions : []

    //     ?.filter((question) =>
    //     props.testAnswers?.find((answer) => answer?.application_question_id === question?.id)?.application_question_id !== question?.id
    // )


    useEffect(() => {
        props.apiRequest(tutorsService.getTestQuestions, {}, {}, {
            test_category: currentStepName
        })
        // console.log("currentStepName", currentStepName)
    }, []);

    useApiStatus(
        tutorsService.getTestQuestions, // api service
        null, // success message on toast (string or null)
        false, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
            setChampion(props.testQuestions?.[0]?.champion)
        }
    )

    const formData = questions?.map((q) => {
        return {
            label: q?.question,
            type: "custom",
            name: q?.category + "_" + q?.id,
            options: [],
            defaultValue: "",
            validationRules: {...validation.required()},
            component: InterviewQuestion,
            showNextButton: false
        }
    }) ?? []

    const onSubmit = data => {
        // navigation.replace("menu")

        if (champion !== "sam") {
            const congratsText = champion === "tina"
                ? "Bravo tu as fini cette étape ! Tu vas pouvoir passer à l'entretien de Zoé"
                : champion === "zoe"
                    ? "Génial tu as fini cette étape ! Tu vas pouvoir passer à l'entretien de Sam"
                    : "Bravo tu as fini cette étape ! Tu vas pouvoir passer à la suite"

            const congrats = [{
                congratsType: congratsTypes.APPLICATION_STEP_CONGRATS,
                congratsData: {
                    champion: champion,
                    text: congratsText
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    }

    const dimensions = useContext(DimensionsContext)
    return <View style={{
        flex: 1,
        // backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        width: dimensions.width,
        alignSelf: "center"
    }}>

        {props.getTestQuestionsRequest ? <ActivityIndicator color={theme.colors.primary}/>
            : formData?.length === 0 ? <>
                <TextM wrap style={{textAlign: "center", marginBottom: 10}}>Tu as déjà vu cette question, passe à la
                    suite.</TextM>
                <FilledButtonM onPress={() => {
                    navigation.goBack()
                }}
                               style={{
                                   // alignSelf: "flex-start"
                               }}
                               label={"Continuer"}/>
            </> : <FormStepper navigation={navigation}
                               track
                               formData={formData?.filter(x => !x.is_masked)}
                               champion={{name: props.testQuestions?.[0]?.champion ?? "tina", variant: "main"}}
                               formValues={formValues}
                               setFormValues={setFormValues}
                               defaultValues={defaultValues}
                               onSubmit={onSubmit}
                               submitted={props.getTestQuestionsRequest}
                               previousRouteName={"menu"}
                               submittedMessage=""
                               serverError={props.submitPersonalInfosError}
                               noPrevious
                               exitWarning
                               exitWarningMessage={"Si tu quittes cet écran avant d'avoir soumis ta réponse, c'est comme si tu avais passé la question."}
            />}
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>
}

const mapStateToProps = state => {
    return {
        getTestQuestionsSuccess: state.api.getTestQuestions?.success,
        getTestQuestionsRequest: state.api.getTestQuestions?.request,
        getTestQuestionsError: state.api.getTestQuestions?.error,
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        testQuestions: state.data.tutors?.testQuestions,
        testAnswers: state.data.tutors?.testAnswers
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(InterviewScreen)