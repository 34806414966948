import {apiConstants} from "@data/constants/apiConstants";
import {activitiesService} from "@data/services/activitiesService";

const initialState = {
    materialsList: []
}

export default function materials(state = initialState, action) {
    switch (action.type) {
        case activitiesService.materialsSearch.name + apiConstants._SUCCESS:
            let materialsList = []
            if (action.data?.meta?.current_page === 1) {
                materialsList = action.data?.materials
            } else {
                materialsList = state.materialsList.concat(action.data?.materials)
            }
            return {
                ...state,
                materialsList: materialsList
            }
        default:
            return state
    }
}
