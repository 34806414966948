import React from 'react';
import {Text} from "react-native-paper";
import {theme} from "../../theme/theme";
import * as WebBrowser from 'expo-web-browser';

const LinkTextM = (props) => {

    const {url, onPress, children, style} = props

    return <Text onPress={() => {
        if (url) WebBrowser.openBrowserAsync(url)
        else onPress()
    }}
                 style={{
                     color: theme.colors.primary,
                     fontFamily: "Montserrat-SemiBold",
                     ...style
                 }}>
        {children}
    </Text>
}

export default LinkTextM