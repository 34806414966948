import { Dimensions, TouchableOpacity, View } from "react-native";
import React, { useContext, useEffect, useState } from "react";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  playASound,
  removeInCallUsers,
  setInCallUsers,
  setIsLiveModeEnabled,
  setIsTyping,
  setLiveParticipants,
  setTypingUser,
} from "@data/redux/actions/liveMode";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import { theme } from "../theme/theme";
import { setCallPortal } from "../../data/redux/actions/liveMode";
import { BadgeM } from "@common/components/badge/BadgeM";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import { hasRole } from "../../data/utility/ability";
import { liveService } from "../../data/services/liveService";
import { ActivityIndicator, Badge } from "react-native-paper";
import { IconImageM } from "@common/components/icons/IconsM";
import live from "../../../assets/icons/generic/live.png";
import TextM from "@common/components/text/TextM";
import { DialogM } from "@common/components/alert/dialog/Dialog";
import { useApiStatus } from "@common/hooks/useApiStatus";
import moment from "moment";
import { TIME_SINCE_LAST_CALL_MINUTES } from "@common/liveMode/StudentsList";
import { hasValueGreaterThanZero } from "@common/liveMode/LiveChat";
import { findClosestNextOccurrence } from "../../student/account/onboarding/LiveRoomCard";
import RNCallKeep from "react-native-callkeep";
import SatisfactionComponent from "@common/liveMode/SatisfactionComponent";
import { showSatisfactionDialog } from "@common/liveMode/EndSessionScreenStudent";
import AvatarM from "@common/components/avatar/AvatarM";
import liveLogo from "../../../assets/icons/generic/live.png";
import { tr } from "react-native-paper-dates";
import LiveSessionDialogComponent from "./LiveSessionDialogComponent";

export const onExitPress = (action1, action2, isFinalized, tutor, user) => {
  const standardTitle = "Veux-tu vraiment quitter ?";
  const standardButton1 = "Quitter le LIVE";

  const studentTitle = standardTitle;
  const studentText = isFinalized
    ? null
    : `${tutor?.display_name} n'a pas encore mis fin à la session`;
  const studentButton1 = standardButton1;

  const tutorTitle = isFinalized
    ? standardTitle
    : "Veux-tu mettre fin au LIVE ?";
  const tutorText = isFinalized
    ? null
    : `Si tu clôtures le LIVE, il ne sera plus accessible aujourd'hui.`;
  const tutorButton1 = isFinalized ? standardButton1 : "Quitter sans clôturer";
  const tutorButton3 = isFinalized ? null : "Clôturer et envoyer rapports";

  const title = hasRole(user, "student") ? studentTitle : tutorTitle;
  const text = hasRole(user, "student") ? studentText : tutorText;
  const button1 = hasRole(user, "student") ? studentButton1 : tutorButton1;
  const button3 = hasRole(user, "student") ? null : tutorButton3;

  DialogM.show({
    champion: "zoe",
    variant: "main",
    text1: title,
    text2: text,
    buttonText1: button1,
    onPress: action1,
    buttonText2: "Annuler",
    buttonText3: button3,
    buttonColor3: theme.colors.error,
    onPress3: action2,
  });
};
const LiveModeHeader = (props) => {
  const {
    buttonsDisabled = false,
    headerNavigation,
    headerRoute,
    setMiniMode = () => {},
    noSubHeader = false,
  } = props;
  const navigation = headerNavigation ?? useNavigation();
  const route = headerRoute ?? useRoute();
  const tabs = ["presence", "messages"];
  const [rating, setRating] = useState(null);

  const color = buttonsDisabled ? theme.colors.grey : noSubHeader ? theme.colors.white : theme.colors.primary;

  const liveSession = props.currentLiveSession;
  const badgeCount = liveSession?.unread_messages_count;
  const timesSinceLastCallAlert = Object?.values(
    props.timesSinceLastCall ?? {}
  )?.filter((x) => x >= TIME_SINCE_LAST_CALL_MINUTES * 60)?.length;
  const dimensions = useContext(DimensionsContext);
  const [namesList, setNamesList] = useState("");
  const isHandRaised = props.raisedHands?.includes(props.user?.id);
  const isFinalized = props.todayLiveSessionLogs?.find(
    (log) => log?.action === "finalized_live_session"
  );

  const closestNextOccurrence = findClosestNextOccurrence([liveSession]);

  const liveMoment = closestNextOccurrence?.date
    ? moment(closestNextOccurrence?.date)
    : null;

  useApiStatus(
    liveService.raiseHand, // api service
    false, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData) => {} // success callback
  );

  useEffect(() => {
    if (hasRole(props.user, "tutor")) noNotesStudentsList();
  }, [props.notes, props.inCallUsers]);

  const noNotesStudentsList = () => {
    let notes = [];
    liveSession?.users
      ?.filter((user) => !user?.is_tutor)
      ?.forEach((user) => {
        if (
          !user?.tutor_notes?.find(
            (n) => moment(n?.created_at).day() === liveMoment.day()
          )
        )
          notes.push(user.display_name);
        // let array = user?.tutor_notes
        //notes = notes.concat(array)
      });
    setNamesList(notes.join(", "));
  };
  const exit = () => {
    if (hasRole(props.user, "tutor"))
      props.apiRequest(liveService.leaveLiveSession, {
        id: props.liveSessionId,
      });
    if (hasRole(props.user, "student"))
      if (
        moment().isAfter(liveMoment.clone().add(45, "minutes")) ||
        moment().isSameOrAfter(liveMoment.clone().add(45, "minutes"))
      )
        setTimeout(() => {
          DialogM.show({
            text1: `Qu’as-tu pensé de cette session LIVE avec ${props.currentLiveSession.tutor?.display_name}`,
            component: <LiveSessionDialogComponent navigation={navigation} />,
            // buttonText1: "Envoyer",
            // // buttonDisabled1: rating === null,
            // onPress: () => {
            //     if (rating !== null) {
            //         props.apiRequest(liveService.sendSatisfaction, {id: props.liveSessionId}, {
            //             satisfaction: rating
            //         })
            //     }
            //
            //     if (leave)
            //         props.apiRequest(liveService.leaveLiveSession, {id: props.liveSessionId})
            //     else
            //         navigation.goBack()
            // },
            hideButton1: true,
            buttonText2: "Pas maintenant",
            onPressBack: () => {
              // console.log('Sortir du live')
              props.apiRequest(liveService.leaveLiveSession, {
                id: props.liveSessionId,
              });
            },
          });
        }, 200);
      else
        props.apiRequest(liveService.leaveLiveSession, {
          id: props.liveSessionId,
        });
    if (!IS_WEB) RNCallKeep.endAllCalls();
    //RNCallKeep.endCall(props.callKeepUUID);
    //props.setIsLiveModeEnabled(false)
  };
  useApiStatus(
    liveService.sendSatisfaction, // api service
    null, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData) => {},
    () => {}
  );
  const endSession = () => {
    navigation.push("todo-check");
    /*setTimeout(() => {
            DialogM.show({
                champion: "sam",
                variant: "main",
                text1: "Les activités à faire de chaque élève sont-elles à jour ?",
                text2: "En clôturant, un rapport de LIVE sera envoyé à chaque parent, incluant des informations sur les chapitres et les activités de l’élève.",
                buttonText1: "Oui, clôturer",
                buttonColor1: theme.colors.error,
                onPress: () => {
                    props.apiRequest(liveService.finalizeLiveSession, {id: props.liveSessionId})
                },
                buttonText2: 'Annuler',
            })
        }, 200)*/
  };

  const isOnline = props.pusherStatus?.toLowerCase() === "connected";

  useApiStatus(
    liveService.leaveLiveSession, // api service
    false, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData) => {
      props.setIsLiveModeEnabled(false);
      props.apiIdle(liveService.sendSatisfaction);
      props.apiIdle(liveService.sendStudentComment);

      //console.log(successData)
      // if (navigation.canGoBack())
      //     navigation.popToTop()
      // else
      //     navigation.replace("menu")
    } // success callback
  );
  useApiStatus(
    liveService.finalizeLiveSession, // api service
    false, // success message on toast (string or null)
    true, // error message on toast (true) or keep it in redux state (false)
    (successData) => {
      navigation.push("end-session-tutor", {occurrence_id: successData?.id});
      props.apiRequest(liveService.leaveLiveSession, {
        id: props.liveSessionId,
      });

    } // success callback
  );
  return (
    <View
      style={{
        flex: 1,
        // backgroundColor: theme.colors.successLight,
        justifyContent: "center",
        // flexDirection: "row",
        alignItems: "center",
        // paddingHorizontal: 10
      }}
    >
      <View
        style={{
          width: noSubHeader
            ? null
            : IS_WEB && dimensions?.width > 800
            ? dimensions?.width
            : dimensions?.width - 40,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          // paddingHorizontal: 10
        }}
      >
        <TouchableOpacity
          style={{
            alignItems: "center",
            // marginLeft: 10
          }}
          disabled={true}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <IconImageM
              source={live}
              width={26}
              style={{
                tintColor: theme.colors.success,
              }}
            />
            <TextM
              fontSize={18}
              fontWeight={"ExtraBold"}
              color={theme.colors.success}
              style={{
                paddingLeft: 5,
              }}
            >
              LIVE
            </TextM>
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/*                    {!buttonsDisabled && <FontAwesome5 solid name="sign-out-alt" size={18} color={theme.colors.error}
                                                       style={{transform: [{rotate: '180deg'}]}}/>}*/}
            <TextM
              fontSize={12}
              fontWeight={"Light"}
              ellipsizeMode={"tail"}
              color={theme.colors.success}
              style={{
                paddingLeft: 8,
                maxWidth: 70,
              }}
            >
              {liveSession?.live_room?.name}
            </TextM>
          </View>
        </TouchableOpacity>
        {props.leaveLiveSessionRequest || props.finalizeLiveSessionRequest ? (
          <ActivityIndicator
            color={theme.colors.primary}
            style={{ marginHorizontal: 7 }}
          />
        ) : (
          <TouchableOpacity
            style={{
              alignItems: "center",
              marginLeft: 10,
            }}
            onPress={() => {
              setMiniMode(true);
              onExitPress(
                exit,
                endSession,
                isFinalized,
                liveSession?.tutor,
                props.user
              );
            }}
            disabled={buttonsDisabled}
          >
            <FontAwesome5
              solid
              name="sign-out-alt"
              size={32}
              color={theme.colors.error}
              style={{
                opacity: buttonsDisabled ? 0.2 : 1,
                transform: [{ rotate: "180deg" }],
              }}
            />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            alignItems: "center",
            marginLeft: 10,
          }}
          onPress={() => {
            setMiniMode(true);
            if (tabs.includes(route.name) && route.name !== "presence") {
              navigation.replace("presence");
            } else {
              navigation.navigate("presence");
            }
          }}
          disabled={buttonsDisabled}
        >
          <FontAwesome5
            solid
            name="user-friends"
            size={32}
            color={color}
            style={{
              opacity: buttonsDisabled ? 0.2 : 1,
            }}
          />
          {props.participants?.length > 0 && (
            <BadgeM
              count={props.participants?.length}
              style={{
                backgroundColor: theme.colors.light,
                color: theme.colors.white,
                position: "absolute",
                top: -8,
                right: -8,
              }}
            />
          )}
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            setMiniMode(true);
            if (tabs.includes(route.name) && route.name !== "messages") {
              // console.log("replace")
              navigation.replace("messages", {
                userId: hasRole(props.user, "tutor")
                  ? null
                  : liveSession?.tutor?.id,
              });
            } else {
              // console.log("navigate")
              navigation.navigate("messages", {
                userId: hasRole(props.user, "tutor")
                  ? null
                  : liveSession?.tutor?.id,
              });
            }
          }}
          style={{
            marginLeft: 10,
          }}
          disabled={buttonsDisabled}
        >
          <FontAwesome5 solid name="comments" size={32} color={color} />
          {(hasValueGreaterThanZero(
            liveSession?.unread_private_messages_count
          ) ||
            liveSession?.unread_messages_count > 0) && (
            <Badge
              size={15}
              style={{
                backgroundColor: theme.colors.error,
                color: theme.colors.white,
                position: "absolute",
                top: 0,
                right: -4,
              }}
            />
          )}
        </TouchableOpacity>
        {/*{hasRole(props.user, "student") && liveSession?.tutor?.id === props.user?.tutor?.id && <TouchableOpacity onPress={*/}
        {/*    () => {*/}
        {/*        if (tabs.includes(route.name) && route.name !== "messages") {*/}
        {/*            navigation.replace("messenger", {*/}
        {/*                studentId: props.user.id*/}
        {/*            });*/}
        {/*        } else {*/}
        {/*            navigation.navigate("messenger", {*/}
        {/*                studentId: props.user.id*/}
        {/*            });*/}
        {/*        }*/}
        {/*    }*/}
        {/*}*/}
        {/*                                                     style={{*/}
        {/*                                                         marginLeft: 10*/}

        {/*                                                     }}*/}
        {/*                                                     disabled={buttonsDisabled}>*/}
        {/*    <FontAwesome5 solid name="comment" size={32} color={color}/>*/}
        {/*    <AvatarM*/}
        {/*        user={props.user?.tutor}*/}
        {/*        size={26}*/}
        {/*        style={{*/}
        {/*            position: "absolute",*/}
        {/*            bottom: -8,*/}
        {/*            right: -8*/}
        {/*        }}*/}
        {/*    />*/}
        {/*    {props?.user?.tutor_messenger_not_read_count > 0 && <BadgeM count={props?.user?.tutor_messenger_not_read_count}*/}
        {/*                               style={{*/}
        {/*                                   backgroundColor: theme.colors.error,*/}
        {/*                                   color: theme.colors.white,*/}
        {/*                                   position: "absolute",*/}
        {/*                                   top: -8,*/}
        {/*                                   right: -8*/}
        {/*                               }}/>}*/}
        {/*</TouchableOpacity>}*/}

        {props.raiseHandRequest ? (
          <ActivityIndicator
            color={theme.colors.primary}
            style={{ marginHorizontal: 7 }}
          />
        ) : (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              borderRadius: 10,
              // borderWidth: 0,
              // borderWidth: isHandRaised ? -2 : 0,
              borderColor: color,
              backgroundColor: isHandRaised ? color : null,
              // paddingVertical: -4,
              paddingLeft: 4,
              marginLeft: 10 - 4,
              paddingRight: 6,
              marginRight: -6,
            }}
            disabled={buttonsDisabled || hasRole(props.user, "tutor")}
            onPress={() => {
              // props.setCallPortal(true)
              props.apiRequest(
                liveService.raiseHand,
                { id: props.liveSessionId },
                { raised: !isHandRaised }
              );
            }}
          >
            <FontAwesome5
              name="hand-paper"
              solid
              size={32}
              style={{
                opacity:
                  hasRole(props.user, "tutor") && !buttonsDisabled ? 0.2 : 1,
              }}
              color={isHandRaised ? theme.colors.accent : color}
            />
            {props.raisedHands?.length > 0 && (
              <BadgeM
                count={props.raisedHands?.length}
                style={{
                  backgroundColor: theme.colors.error,
                  color: theme.colors.white,
                  position: "absolute",
                  // borderWidth: 0.8,
                  // borderColor: theme.colors.primary,
                  top: -8,
                  right: -8,
                }}
              />
            )}
          </TouchableOpacity>
        )}
        {hasRole(props.user, "tutor") && (
          <TouchableOpacity
            onPress={() => {}}
            style={{
              marginLeft: 10,
            }}
            disabled={true}
          >
            <FontAwesome5
              solid
              name="stopwatch"
              size={32}
              style={{
                opacity: buttonsDisabled ? 1 : 0.2,
              }}
              color={color}
            />
            {timesSinceLastCallAlert > 0 && (
              <BadgeM
                count={timesSinceLastCallAlert}
                style={{
                  backgroundColor: theme.colors.error,
                  color: theme.colors.white,
                  position: "absolute",
                  top: -8,
                  right: -8,
                }}
              />
            )}
          </TouchableOpacity>
        )}
      </View>

      {!noSubHeader && (
        <View
          style={{
            backgroundColor: theme.colors.successLightMore,
            width: Dimensions?.get("window")?.width,
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            bottom: -12,
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: theme.colors.lightMore,
          }}
        >
          <Badge
            style={{
              backgroundColor: isOnline
                ? theme.colors.success
                : theme.colors.light,
              marginRight: 4,
              marginBottom: 1,
              // position: "absolute",
              // bottom: 0,
              // right: 0
            }}
            size={10}
          />
          <TextM fontSize={10}>
            {isOnline ? "Connecté.e au LIVE" : "Non connecté.e..."}
            {isFinalized ? " - Cette session LIVE est terminée" : ""}
          </TextM>
        </View>
      )}
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
    currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(
      (x) => x?.id === state.liveMode?.liveSessionId
    ),
    successes: state.data.staticData?.successes,
    liveSessionId: state.liveMode?.liveSessionId,
    liveSessions: state.data.currentUser?.object?.live_sessions,
    conferenceToken: state.liveMode?.conferenceToken,
    // tutors: state.liveMode?.liveTutors,
    participants: state.liveMode?.liveParticipants,
    inCallUsers: state.liveMode?.inCallUsers,
    raisedHands: state.liveMode?.raisedHands,
    sound: state.liveMode?.sound,
    timesSinceLastCall: state.liveMode?.timesSinceLastCall,
    notes: state.data.tutors?.notes,
    soundMode: state.liveMode?.soundMode,
    isAudioMuted: state.liveMode?.isAudioMuted,
    callPortal: state.liveMode?.callPortal,
    leaveLiveSessionRequest: state.api.leaveLiveSession?.request,
    finalizeLiveSessionRequest: state.api.finalizeLiveSession?.request,
    raiseHandRequest: state.api.raiseHand?.request,
    pusherStatus: state.pusher?.status,
    todayLiveSessionLogs: state.liveMode?.todayLiveSessionLogs,
    callKeepUUID: state.liveMode?.callKeepUUID,
    sendSatisfactionSuccess: state.api.sendSatisfaction?.success,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setIsLiveModeEnabled,
  setLiveParticipants,
  setIsTyping,
  setTypingUser,
  setInCallUsers,
  removeInCallUsers,
  setCallPortal,
  playASound,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveModeHeader);
