import {Platform, ScrollView, View} from "react-native";
import React, {useContext, useEffect, useState} from 'react'
import {connect} from "react-redux";
import moment from "moment";
import TitleM from "../common/components/text/TitleM";
import {SubscriptionSection} from "./SubscriptionSection";
import {ActivityIndicator, Button} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";
import {ActivitiesSection} from "./ActivitiesSection";
import {GlobalStatsSection} from "./GlobalStatsSection";
import FilledButtonM from "../common/components/button/FilledButtonM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {capitalize} from "@data/utility/Utils";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";

const Analytics = (props) => {

    const month = props.route?.params?.month ?? moment().format("YYYY-MM")
    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)
    const [analyticsData, setAnalyticsData] = useState(null)
    const isLast = moment().isSame(moment(month, "YYYY-MM"), "month")
    const monthMoment = moment(month, "YYYY-MM")
    const previousMonth = () => {
        const newMonth = monthMoment.subtract(1, "month").format("YYYY-MM")
        props.apiRequest(backOfficeService.getAnalytics, {}, {}, {month: newMonth})
        navigation.replace("analytics", {
            month: newMonth
        })
    }

    const nextMonth = () => {
        const newMonth = monthMoment.add(1, "month").format("YYYY-MM")
        props.apiRequest(backOfficeService.getAnalytics, {}, {}, {month: newMonth})
        navigation.replace("analytics", {
            month: newMonth
        })
    }
    useEffect(() => {
        props.apiRequest(backOfficeService.getAnalytics, {}, {}, {month})
    }, [])

    useApiStatus(
        backOfficeService.getAnalytics, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
            setAnalyticsData(successData)
            // console.log(sumCC(successData?.student_accounts_created?.["plus"]?.weekly_series))
        }
    )
    return (
        <ScrollView>
            <View style={{
                flex: 1,
                alignItems: "center",
                width: dimensions?.width,
                alignSelf: Platform.OS !== 'web' ? null : "center",
                marginTop: 20
            }}>
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: dimensions?.width,
                }}>
                    <Button icon={"arrow-left"} color={theme.colors.primary}
                            disabled={props.getAnalyticsRequest}
                            onPress={previousMonth}
                            labelStyle={{fontSize: 25}}
                    />

                    <TitleM fontWeight="ExtraBold">{capitalize(moment(month, "YYYY-MM").format("MMMM YYYY"))}</TitleM>
                    <Button icon={"arrow-right"} color={isLast ? theme.colors.lightMore : theme.colors.primary}
                            disabled={isLast || props.getAnalyticsRequest}
                            onPress={nextMonth}
                            labelStyle={{fontSize: 25}}
                    />
                </View>
                {analyticsData ? <View style={{}}>
                    <SubscriptionSection
                        data={analyticsData}
                    />
                    <ActivitiesSection data={analyticsData}/>
                    <GlobalStatsSection data={analyticsData}/>
                </View> : <ActivityIndicator color={theme.colors.primary}/>}
                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          // textColor={theme.colors.white}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else
                                                  navigation.replace("menu")
                                          }}
                                          label={"Retour"}/>}
            </View>
        </ScrollView>
    )
}
const mapStateToProps = state => {
    return {
        getAnalyticsRequest: state.api?.getAnalytics?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
}

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)