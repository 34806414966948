import React, {useContext, useEffect, useRef, useState} from "react";
import {FlatList, Platform, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ActivityIndicator} from "react-native-paper";
import {connect} from "react-redux";
import TitleM from "@common/components/text/TitleM";
import {studentsService} from "@data/services/studentsService";
import StudentsListItem from "./StudentsListItem";
import {AbilityContext} from "@data/utility/Can";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";


const MyStudentsTabScreen = (props) => {
    function onButtonPress() {
        // props.apiRequest(authService.revokeSanctumToken, {}, {})
    }

    // ** Manage scrolling to specific index in flat list
    const flatListRef = useRef();

    const navigation = useNavigation()
    const scrollToIndex = (index) => {
        flatListRef.current.scrollToIndex({index});
    };
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        // setTimeout(() => {
        //     // props.apiRequest(studentsService.getStudents)
        //     setLoading(false)
        // }, 1000)

    }, [])
    const ability = useContext(AbilityContext)
    const dimensions = useContext(DimensionsContext)
    return (
        <View
            style={{
                flex: 1,
                backgroundColor: theme.colors.whiteAlmost,
                alignItems: "center",
                width: dimensions?.width,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
        >
            {/*{ability?.can('read', 'my_tutor_profile_completion') ? <>

                <View>
                    <ProfileCompletionCard
                        message="Remplis les informations sur toi pour permettre à tes élèves de te connaître"/>

                    <SlotCompletion
                        message="Indique tes disponibilités pour les entretiens hebdomadaires avec tes élèves"/>
                </View>

            </> :*/}
            {(IS_WEB && (props.getStudentRequest)) ? <ActivityIndicator animating={true}
                                                                        style={{
                                                                            marginTop: 50
                                                                        }}
                                                                        color={theme.colors.primary}/> :
                <FlatList
                    data={props.user.students
                        // ?.sort((a,b) => b?.student_messenger_not_read_count - a?.student_messenger_not_read_count)
                    }
                    ref={flatListRef}
                    refreshing={!!props.getStudentRequest}
                    onRefresh={() => props.apiRequest(studentsService.getStudents)}
                    style={{
                        width: dimensions?.width
                    }}
                    ListEmptyComponent={<>
                        <TextM style={{
                            textAlign: "center",
                            marginTop: 0
                        }}>Aucun élève</TextM>
                    </>}
                    ListHeaderComponent={
                        <>
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: dimensions?.width,
                                    borderColor: theme.colors.lightMore,
                                    paddingVertical: 8,
                                    marginBottom: 20
                                }}
                            >
                                <TitleM fontWeight="ExtraBold">MES ELÈVES</TitleM>
                                <View style={{
                                    position: "absolute",
                                    right: 10,
                                    top: 10,
                                    alignItems: "center"
                                }}>
                                    <FilledButtonM
                                        onPress={() => {
                                            navigation.push("new-todo", {forStudents: true})
                                        }}
                                        icon={"plus"}
                                        // compact
                                        style={{
                                            marginBottom: 0,
                                            maxWidth: 150,
                                            height: 30,
                                        }}
                                        contentStyle={{
                                            marginBottom: 0,
                                            maxWidth: 150,
                                            height: 30,
                                        }}
                                        labelStyle={{
                                            fontSize: 11
                                        }}
                                        color={theme.colors.primary}
                                        // mode={"text"}
                                        label={"Activité"}
                                    />
                                </View>

                            </View>
                        </>
                    }
                    renderItem={(item) => {
                        const scroll = () => {
                            scrollToIndex(item.index);
                        };

                        return <StudentsListItem student={item.item} index={item.index}/>;
                    }}
                />}
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        getStudentRequest: state.api.getStudents?.request
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MyStudentsTabScreen);