import React, {useEffect, useState} from "react";
import {Searchbar} from "react-native-paper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";

const ServerSearch = (props) => {

    const {style, urlParams, setUrlParams, request, value = null} = props;
    // const [isSearching, setIsSearching] = useState(false)
    const [previousSearchQuery, setPreviousSearchQuery] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [nextQuery, setNextQuery] = useState(null);

    useEffect(() => {
        //console.log('textChanged')


        const delayDebounceFn = setTimeout(() => {
            if (searchQuery === previousSearchQuery) return

            if (request) {
                setNextQuery({...urlParams, search: searchQuery})
                setPreviousSearchQuery(searchQuery)
            } else if (searchQuery !== null && nextQuery) {
                setUrlParams(nextQuery)
                setNextQuery(null)
            } else if (searchQuery !== null) {
                setUrlParams({...urlParams, search: searchQuery})
                setPreviousSearchQuery(searchQuery)
                setNextQuery(null)
            }

        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchQuery, request])

    const onChangeSearch = query => setSearchQuery(query);

    return (
        <Searchbar
            placeholder="Rechercher..."
            onChangeText={(value) => {
                onChangeSearch(value)
            }}
            value={value ?? searchQuery}
            // loading={props.searchingRequest && isSearching}
            style={{
                ...style
            }}
            inputStyle={{
                fontSize: 14
            }}
        />
    );
}

const mapStateToProps = state => {
    return {
        //liveSession: state.data.backOffice?.liveSession,
        users: state.data.users?.usersList
        //getUsersRequest: state.api.getAllUsers?.request,
        // searchingRequest: state.api.searchUser?.request
        //addUsersRequest: state.api.addUsersLive?.request,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ServerSearch)