import React, {useContext, useEffect, useRef, useState} from "react";
import * as ImagePicker from "expo-image-picker";
import {Dimensions, FlatList, TouchableHighlight, Vibration, View} from "react-native";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import {theme} from "../theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "../components/text/TextM";
import PhotoElement from "./PhotoElement";
//import ImageView from "react-native-image-viewing";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {clearLastPhoto, clearPhotos} from "@data/redux/actions/camera";
import {connect} from "react-redux";
import {ModalM} from "../components/modal/ModalM";
import ImageViewer from "../components/image/ImageViewer";
import {GestureHandlerRootView} from "react-native-gesture-handler";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useCamera} from "@common/hooks/useCamera";
import {activitiesService} from "@data/services/activitiesService";

export const pickImage = async (images, setImages) => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        // allowsEditing: true,
        // aspect: [4, 3],
        quality: 1,
    });

    //console.log(result);
    if (!result.canceled) {
        const imagesCopy = images?.map(x => x) ?? []
        imagesCopy?.push({
            uri: result.assets[0].uri,
            width: result.assets[0].width,
            height: result.assets[0].height,
            selected: false
        })
        setImages(imagesCopy);
    }
};

export const ImagesList = (props) => {
    const {
        listHeaderComponent, listFooterComponent, contentHeight, style, images, photoLongPress = () => {
        }, withChrono = false, time, isSelecting = false, useFullWidthToScroll = false, longPressDisabled = false
    } = props

    const dimensions = useContext(DimensionsContext)
    const flatListRef = useRef();
    // ** Image viewer
    // const [visible, setVisible] = useState(false)
    const [openImageViewer, setOpenImageViewer] = useState(false)
    const [visibleIndex, setVisibleIndex] = useState(0)
    const hideImageViewer = () => {
        return setOpenImageViewer(false)
    }

    useEffect(() => {
        flatListRef?.current?.scrollToEnd({animated: false})
    }, []);

    // const hideImageView = () => {
    //     setVisible(false)
    //     setVisibleIndex(0)
    // }

    return <>
        <FlatList data={images}
                  ref={flatListRef}
                  getItemLayout={(data, index) => (
                      {length: contentHeight, offset: contentHeight * index, index}
                  )}
                  horizontal
                  style={{
                      // marginTop: 10,
                      //backgroundColor: "red",
                      width: dimensions?.width,
                      alignSelf: "center",
                      ...style
                  }}
                  contentContainerStyle={{
                      // backgroundColor: "blue",
                      alignItems: "center",
                      justifyContent: "center",
                      height: contentHeight,


                  }}

                  ListHeaderComponent={listHeaderComponent}
                  ListFooterComponent={listFooterComponent}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={(itemInfo) => {
                      return <PhotoElement uri={itemInfo.item?.uri}
                                           height={itemInfo.item?.height}
                                           width={itemInfo.item?.width}
                                           parentHeight={contentHeight}
                                           isSelecting={isSelecting}
                                           selected={itemInfo.item?.selected}
                                           onLongPress={photoLongPress ? () => photoLongPress(itemInfo.index) : () => {
                                           }}
                                           longPressDisabled={longPressDisabled}
                                           onPress={() => {
                                               // press(itemInfo.index)
                                               setOpenImageViewer(true)
                                               setVisibleIndex(itemInfo.index)
                                           }}
                      />

                  }
                  }/>

        {/*        {Platform.OS !== 'web' && <ImageView
            images={images?.map(x => {

                // console.log("images member", x)
                return {uri: cleanMediaUrl(x?.uri)}
            })}
            imageIndex={visibleIndex}
            visible={visible}
            onRequestClose={hideImageView}
            swipeToCloseEnabled={false}
            // presentationStyle="overFullScreen"
        />}*/}

        <ImageViewerModal visible={openImageViewer}
                          onDismiss={hideImageViewer}
                          imageIndex={visibleIndex}
                          images={images?.map(i => i?.uri)}
                          withChrono={withChrono}
                          time={time}
                          useFullWidthToScroll={useFullWidthToScroll}
        />
    </>;
}

export function ImageViewerModal(props) {

    const {visible, onDismiss, imageIndex = 0, images, withChrono, time, useFullWidthToScroll = false} = props
    const dimensions = useContext(DimensionsContext)

    return <ModalM style={{
        height: IS_WEB ? dimensions?.height : null,
        width: Dimensions.get("window").width,
        flex: 1,
        backgroundColor: "#000",
        borderRadius: 0,
    }}
                   visible={visible}
                   onDismiss={onDismiss}>
        <GestureHandlerRootView style={{flex: 1}}>
            <ImageViewer imageIndex={imageIndex}
                         images={images}
                         close={onDismiss}
                         withChrono={withChrono}
                         time={time}
                         useFullWidthToScroll={useFullWidthToScroll}
            />
        </GestureHandlerRootView>
    </ModalM>;
}

function CameraImagesM(props) {

    const {
        images, setImages, formId, style, dismissModal = () => {
        },
        useFullWidthToScroll = false,
        activityId = null,
        noButton = false,
        longPressDisabled = false,
    } = props


    //const navigation = useNavigation()
    const [isSelecting, setIsSelecting] = useState(false);
    const bottomSheetRef = useRef();

    const [permissionStatus, requestPermission] = ImagePicker.useCameraPermissions();
    const dimensions = useContext(DimensionsContext)

    // console.log("images", images)

    // function useForceUpdate() {
    //     const [value, setValue] = useState(0); // integer state
    //     return () => setValue(value => value + 1); // update state to force render
    //     // A function that increment 👆🏻 the previous state like here
    //     // is better than directly setting `setValue(value + 1)`
    // }

    // const forceUpdate = useForceUpdate()
    // const pickImage = async () => {
    //     // No permissions request is necessary for launching the image library
    //     let result = await ImagePicker.launchImageLibraryAsync({
    //         mediaTypes: ImagePicker.MediaTypeOptions.Images,
    //         allowsEditing: true,
    //         aspect: [4, 3],
    //         quality: 1,
    //     });
    //
    //     //console.log(result);
    //     if (!result.canceled) {
    //         const imagesCopy = images?.map(x => x) ?? []
    //         imagesCopy?.push({
    //             uri: result.assets[0].uri,
    //             width: result.assets[0].width,
    //             height: result.assets[0].height,
    //             selected: false
    //         })
    //         setImages(imagesCopy);
    //     }
    //     forceUpdate();
    // };

    //console.log(dimensions)
    useEffect(() => {
        if (
            props.lastPhotoFormId === formId
            && !!props.lastPhoto
            && !images?.map(x => x?.uri)?.includes(props.lastPhoto?.uri)
        ) {
            const imagesCopy = images?.map(x => x) ?? []
            imagesCopy?.push(props.lastPhoto)
            setImages(imagesCopy);
            props.clearLastPhoto()
            // navigation.navigate("Activité", {
            //     newImage: null
            // })
            // console.log(imagesCopy)
        } else if (
            props.lastPhotoFormId === formId
            && props.lastPhotos?.length > 0
        ) {
            const imagesCopy = images?.map(x => x) ?? []
            imagesCopy?.push(...props.lastPhotos)
            setImages(imagesCopy);
            props.clearPhotos()
        }
        //console.log(props.lastPhotos)
    }, [props.lastPhoto, props.lastPhotos])

    const longPress = (index) => {
        if (isSelecting || longPressDisabled) return
        Vibration.vibrate(50)
        // setIsSelecting(true)
        const copy = images?.slice()
        copy[index].selected = true
        setImages(copy)
        // bottomSheetRef.current.present()
        dismissModal()
        DialogM.show({
            text1: 'Supprimer la photo ?',
            text2: '',
            buttonText1: 'Supprimer',
            buttonColor1: theme.colors.error,
            onPress: () => {
                setImages(images => images.filter(x => !x.selected))
                if (activityId)
                    props.apiRequest(activitiesService.deleteActivityLog, {id: images[index]?.id}, {
                        live_mode: !!props.liveSessionId,
                        live_session_id: props.liveSessionId ?? undefined
                    })
                resetSelection()
            },
            buttonText2: 'Annuler',
            onPressBack: resetSelection
        })
    }

    const press = (index) => {
        if (isSelecting) {
            const copy = images?.slice()
            copy[index].selected = !copy[index].selected
            setImages(copy)
        }
    }

    const resetSelection = () => {
        setIsSelecting(false)
        //bottomSheetRef.current.dismiss()
        setImages(images => images.map(x => ({...x, selected: false})))
    }

    const height = dimensions?.height * 0.4

    const [launchCamera] = useCamera(formId, true, true)


    return <ImagesList listHeaderComponent={images?.length === 0 ? <View style={{ // spacer for add photo button
        width: (dimensions?.width - (120 + 2 * 20 + 2 * 20)) / 2
    }}/> : <></>}
                       useFullWidthToScroll={useFullWidthToScroll}
                       listFooterComponent={!noButton && <View style={{height: height}}>

                           <TouchableHighlight onPress={() => {
                               launchCamera()
                               dismissModal()
                           }}
                                               underlayColor={theme.colors.lighter}
                                               disabled={isSelecting}
                                               style={{
                                                   flex: 1,
                                                   marginRight: 20,
                                                   //height: height
                                                   // alignSelf: "center"
                                               }}>
                               <View style={{
                                   flex: 1,
                                   marginHorizontal: 20,
                                   width: 100,
                                   //height: height,
                                   justifyContent: "center",
                                   alignItems: "center"
                                   // alignSelf: "center"
                               }}>
                                   <FontAwesome5 name={"camera"} size={32} color={theme.colors.primary}/>
                                   <TextM wrap
                                          fontWeight="ExtraBold"
                                          color={theme.colors.primary}
                                          fontSize={18}
                                          style={{
                                              textAlign: "center",
                                              marginTop: 10
                                          }}>{images?.length === 0 ? "Prendre en photo mon travail" : "Ajouter une photo"}</TextM>
                               </View>
                           </TouchableHighlight>
                           {props.useGallery && <TouchableHighlight onPress={() => {
                               pickImage(images, setImages);
                           }}
                                                                    underlayColor={theme.colors.lighter}
                                                                    disabled={isSelecting}
                                                                    style={{
                                                                        flex: 1,
                                                                        marginHorizontal: 20,
                                                                        //height: height,
                                                                        // alignSelf: "center"
                                                                    }}>

                               <View style={{
                                   flex: 1,
                                   marginHorizontal: 20,
                                   width: 120,
                                   //height: height,
                                   justifyContent: "center",
                                   alignItems: "center"
                                   // alignSelf: "center"
                               }}>
                                   <FontAwesome5 name="image" size={32} color={theme.colors.primary}/>
                                   <TextM wrap
                                          fontWeight="ExtraBold"
                                          color={theme.colors.primary}
                                          fontSize={18}
                                          style={{textAlign: "center", marginTop: 10}}>Galerie</TextM>
                               </View>
                           </TouchableHighlight>}
                       </View>}
                       photoLongPress={longPress}
                       longPressDisabled={longPressDisabled}
                       images={images}
                       contentHeight={height}
                       style={style}

    />
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        lastPhoto: state.camera?.lastPhoto,
        lastPhotos: state.camera?.lastPhotos,
        lastPhotoFormId: state.camera?.formId,
        liveSessionId: state.liveMode?.liveSessionId,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        clearLastPhoto,
        clearPhotos,
    }

export default connect(mapStateToProps, mapDispatchToProps)(CameraImagesM)