export const backgroundColors = {
    bgc1: '#93a7fe',
    bgc2: '#a9e874',
    bgc3: '#ff7a9a',
    bgc4: '#b378f7',
    bgc5: '#ff6674',
    bgc6: '#89e6e5',
    bgc7: '#ffcc64',
    bgc8: '#f8fbff',
};

export const backgroundColorsList =
    [
        {
            name: "bgc1",
            color: '#93a7fe',
        },
        {
            name: "bgc2",
            color: '#a9e874',
        },
        {
            name: "bgc3",
            color: '#ff7a9a',
        },
        {
            name: "bgc4",
            color: '#b378f7',
        },
        {
            name: "bgc5",
            color: '#ff6674',
        },
        {
            name: "bgc6",
            color: '#89e6e5',
        },
        {
            name: "bgc7",
            color: '#ffcc64',
        },
        {
            name: "bgc7",
            color: '#f8fbff',
        }
    ]
;