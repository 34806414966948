import axios from "axios"

function getStudyProgramsAsStudent() {
    return axios.get('/api/v1/study-programs')
}

function getStudyProgramsAsTutor({id}, {}, params) {
    if (!id)
        return axios.get(`/api/v1/students/study-programs`, {params})
    else
        return axios.get(`/api/v1/students/${id}/study-programs`, {params})
}

function indexPaginatedStudyPrograms() {
    return axios.get(`/api/backoffice/study-programs`)
}

function deleteStudyProgram({id}) {
    return axios.delete(`/api/backoffice/study-programs/${id}`)
}

function newStudyProgram({}, formData) {
    return axios.post(`/api/backoffice/study-programs`, formData)
}

function updateStudyProgram({id}, formData) {
    return axios.put(`/api/backoffice/study-programs/${id}`, formData)
}

export const studyProgramsService = {
    getStudyProgramsAsStudent,
    getStudyProgramsAsTutor,
    indexPaginatedStudyPrograms,
    deleteStudyProgram,
    newStudyProgram,
    updateStudyProgram
}
