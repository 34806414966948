import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Platform, View} from "react-native";
import {ChampionImageM} from "./ChampionImageM";
import {SpeechBubbleM} from "@common/components/speech-bubble/SpeechBubbleM";
import {whatIsIt} from "@data/utility/Utils";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";

const ChampionBubble = React.forwardRef((props, ref) => {

    const {
        name,
        variant,
        texts,
        onNextPress,
        championPosition,
        style,
        noAnimation = false,
        allowSpeechReplay = false,
        hideIcons = true,
    } = props


    useEffect(() => {
        // console.log("HEY")

        // if (!props.user?.champion_intros_viewed_at?.[name])
        //     championModalRef?.current?.showChampion(
        //         name,
        //         championsConstants?.[name + "_intro"]?.variant,
        //         championsConstants?.[name + "_intro"]?.texts,
        //         () => {
        //         }
        //     )

    }, []);

    const championWidthLeft = 90
    const paddingHorizontalLeft = 20

    const speechBubbleRef = useRef()

    // variant can be array or string
    const isVariantArray = whatIsIt(variant) === "Array"
    const [variantIndex, setVariantIndex] = useState(0)
    const goToNextVariant = () => {
        if (variantIndex < variant?.length - 1)
            setVariantIndex(index => index + 1)
    }
    const dimensions = useContext(DimensionsContext)

    useImperativeHandle(ref, () => ({
        onChampionBubbleNext() {
            if (isVariantArray) {
                goToNextVariant()
            }
            speechBubbleRef.current?.onSpeechBubblePress()
        }
    }));





    if (championPosition === "left") {
        return <View style={{
            // flex: 1,
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: dimensions?.width,
            // paddingVertical: 10,
            paddingLeft: paddingHorizontalLeft,
            paddingRight: paddingHorizontalLeft + 10,
            alignSelf: "center",
            ...style
        }}

                     //onLayout={handleParentLayout}
        >
            <ChampionImageM name={name}
                            style={{
                                // flex:1
                            }}
                            variant={isVariantArray ? variant[variantIndex] : variant}
                            width={championWidthLeft}
                            height={Platform.OS === 'web' ? 140 : null}
                            //onLayout={handleParentLayout}
            />
            <View>
                {texts && <SpeechBubbleM ref={speechBubbleRef}
                                         allowSpeechReplay={allowSpeechReplay}
                                         hideIcons={hideIcons}
                                         texts={texts}
                                         noAnimation={noAnimation}
                                         onNextPress={onNextPress}
                                         position={championPosition}
                                         containerStyle={{
                                             width: dimensions?.width - championWidthLeft - paddingHorizontalLeft * 2 - 10,
                                             marginLeft: 10,
                                             marginBottom: 6,
                                             // flex:2
                                         }}
                                         textStyle={{
                                             textAlign: "left",
                                             fontSize: 15,
                                         }}
                                         numberOfSpeechLines={props.numberOfSpeechLines}

                />
                }
            </View>
        </View>;
    } else {
        return <View style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "flex-end",
            width: dimensions?.width,
            paddingBottom: 60,
            ...style
        }}>
            <SpeechBubbleM ref={speechBubbleRef}
                           texts={texts}
                           noAnimation={noAnimation}
                           onNextPress={onNextPress}
                           containerStyle={{
                               width: Platform.OS !== 'web' ? dimensions?.width - 40 : dimensions?.width / 1.5,
                               marginTop: 40,
                               marginBottom: 20,
                               marginHorizontal: 20
                           }}
                           textStyle={{
                               textAlign: "center",
                               fontSize: 16
                           }}/>

            <ChampionImageM name={name}
                            style={{
                                // flex:1
                            }}
                            variant={isVariantArray ? variant[variantIndex] : variant}
                            width={140}
                            height={Platform.OS === 'web' ? 230 : null}
            />
        </View>;
    }
});

const mapStateToProps = state => {
    return {

        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ChampionBubble)