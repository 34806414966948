import React, {useContext, useRef} from 'react';
import {FlatList, Platform, Pressable, View} from "react-native";
import {StatusBar} from "expo-status-bar";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {authService} from "@data/services/authService";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {connect} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import AvatarM from "@common/components/avatar/AvatarM";
import {SkillSetTitleFull, XPCount} from "@common/components/statistics/Counters";
import LiveAvatarM from "../../tutor/students/LiveAvatarM";
import {ActivityIndicator} from "react-native-paper";
import {hasRole} from "@data/utility/ability";
import {useNavigation} from "@react-navigation/native";

const ITEM_HEIGHT = 60

export function RankingListItem({
                                    user,
                                    isHighlighted,
                                    rank,
                                    height = 60,
                                    isLiveMode = false,
                                    backofficeMode = false,
                                    onDelete = () => {
                                    },
                                    isLoading = false,
                                    withEmail = false,
                                }) {
    const dimensions = useContext(DimensionsContext)
    const isTutor = hasRole(user, "tutor") || user?.is_tutor
    const navigation = useNavigation()

    return <View style={{
        width: Platform.OS !== "web" ? dimensions?.width : dimensions?.width - 120,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        height: height,
        // flex:1,
        paddingVertical: 10,
        paddingHorizontal: 10,
        backgroundColor: isHighlighted ? theme.colors.primaryLighter : null
    }}>
        <View style={{
            flexDirection: "row",
            alignItems: "center"
        }}>
            {rank && <TextM fontWeight="ExtraBold" style={{width: 30}} fontSize={rank > 99 ? 11 : 16} wrap
                            color={theme.colors.primary}>{rank}</TextM>}
            {isLiveMode
                ? <LiveAvatarM user={user}/>
                : <AvatarM user={user} style={{marginRight: 2}} size={50}/>}
            <View style={{
                minWidth: 120, marginLeft: 10
            }}>
                <View style={{
                    flexDirection: withEmail ? "row" : "column",
                    alignItems: withEmail ? "center" : null
                }}>
                    <TextM fontWeight="Bold"
                           style={{marginRight: 10}}
                           fontSize={16}
                           wrap
                           color={isTutor ? theme.colors.primary : theme.colors.grey}>
                        {user?.display_name}
                    </TextM>
                    <TextM fontWeight="Medium"
                           style={{}}
                           color={isTutor ? theme.colors.primary : null}
                           fontSize={12}>
                        {!isTutor ? user?.profile?.class : "Masteur"}
                    </TextM>
                </View>
                {withEmail && <TextM fontWeight="Light" fontSize={10}>{user?.email}</TextM>}
            </View>
        </View>
        <View style={{
            minWidth: 110,
            // marginLeft: 10,
            alignItems: "flex-end",
            justifyContent: "center"
        }}>
            {backofficeMode ?
                isLoading ? <ActivityIndicator color={theme.colors.primary}/> :
                    <Pressable onPress={onDelete}>
                        <FontAwesome5 name={"trash"}
                                      size={20}
                                      color={theme.colors.light}
                        />
                    </Pressable>
                : !isTutor ? <>
                    <SkillSetTitleFull title={user?.student_title}
                                       skillSetId={user?.title_skill_set_id}
                                       icon={user?.student_title_icon}
                                       small
                                       right/>
                    {/*<TextM fontWeight="SemiBold" style={{}} fontSize={12}>Niv. {user?.achievements[0]?.level}</TextM>*/}
                    <XPCount count={user?.xp} small right style={{marginRight: 3}}/>
                </> : null
            }

        </View>

    </View>;
}

const RankingScreen = (props) => {

    const {rankingData, userRank, user} = props

    const highlightIndex = ((userRank <= rankingData?.length) ? (userRank - 1) : -1)

    const flatListRef = useRef()
    const dimensions = useContext(DimensionsContext)


    return (
        <View style={{
            flex: 1,
            // backgroundColor: theme.colors.white,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
            // paddingTop: 10
        }}>
            <FlatList data={rankingData}
                      ref={flatListRef}
                      getItemLayout={(data, index) => (
                          {length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index}
                      )}
                      showsHorizontalScrollIndicator={Platform.OS === "web" && false}
                      initialScrollIndex={(highlightIndex >= 0) ? highlightIndex : (rankingData.length - 1)}
                      refreshing={!!props.getUserRequest}
                      onRefresh={() => props.apiRequest(authService.getUser)}
                      ListFooterComponent={highlightIndex < 0 && <View style={{
                          width: dimensions?.width

                      }}>
                          {userRank - 1 > rankingData?.length && <View style={{
                              width: dimensions?.width,
                              // backgroundColor: theme.colors.white,
                              alignItems: "center",
                              paddingVertical: 6
                          }}>
                              <FontAwesome5 name="ellipsis-h" size={30} color={theme.colors.light}/>
                          </View>}
                          <RankingListItem user={user}
                                           isHighlighted={true}
                                           height={ITEM_HEIGHT}
                                           rank={userRank}/>
                      </View>}
                      renderItem={(item) => {

                          return <RankingListItem user={item.item}
                                                  isHighlighted={item.index === highlightIndex}
                                                  height={ITEM_HEIGHT}
                                                  rank={item.index + 1}/>
                      }}
            />

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}


const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        // user: state.data.currentUser?.object
        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(RankingScreen)