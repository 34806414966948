import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ScrollView, View} from "react-native";
import React from 'react';
import {ProfileSection} from "../account/ProfileSection";
import {ProfileInfo} from "./ProfileInfo";
import AvatarM from "@common/components/avatar/AvatarM";
import {MesStatistiques} from "../account/MesStatistiques";
import {theme} from "@common/theme/theme";
import {IconButton} from "react-native-paper";
import {useNavigation} from "@react-navigation/native";

const ProfilFriendScreen = (props) => {
    const navigation = useNavigation()
    const user = props.route.params.user

    return (
        <>
            <View style={{
                backgroundColor: '#fff',
                paddingTop: 10,
                paddingLeft: 10
            }}>
                <IconButton
                    icon={"arrow-left"}
                    iconColor={theme.colors.primary}
                    size={20}
                    onPress={() => {
                        navigation.goBack()
                    }}
                />
            </View>
            <View style={{
                flex: 1,
                backgroundColor: '#fff',
                alignItems: 'center',
                justifyContent: 'flex-start'
                // paddingTop: 5
            }}>

                <ScrollView>
                    <ProfileSection title={user?.display_name}>
                        <ProfileInfo user={user}/>
                        <AvatarM user={user} style={{
                            position: "absolute",
                            top: 15,
                            right: 15
                        }} size={60}/>
                    </ProfileSection>
                    <ProfileSection title={"Statistiques"}>
                        <MesStatistiques user={user}/>
                    </ProfileSection>
                    {/*<ProfileSection title={"Succés"}>*/}
                    {/*    <AchievementsList student={user} limit={3}/>*/}
                    {/*</ProfileSection>*/}
                </ScrollView>
            </View>
        </>);
}

const mapStateToProps = state => {
    return {
        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        successes: state.data.staticData?.successes
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ProfilFriendScreen)