import {View} from "react-native";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import React from "react";

export const DoneChip = ({count, large, style, noBorder, status = "not_reviewed"}) => {

    const icon = (status === "not_reviewed" || status === "ongoing" || status === "started" || status === "todo")
        ? ""
        : (status === "pending_review")
            ? "hourglass-half"
            : (status === "rejected")
                ? "times"
                : "check"

    const color = (status === "not_reviewed" || status === "ongoing" || status === "started")
        ? theme.colors.primary
        : (status === "pending_review")
            ? theme.colors.warning
            : (status === "rejected")
                ? theme.colors.grey
                : (status === "todo") ? theme.colors.light : theme.colors.success

    const text = (status === "not_reviewed")
        ? `Fait${count > 1 ? ` ${count}x` : ""}`
        : (status === "pending_review")
            ? "En attente"
            : (status === "rejected")
                ? "Rejeté"
                : (status === "started") ? "En pause" :
                    (status === "ongoing") ? "En cours" : (status === "todo") ? "À faire" :
                        "Validé"

    const fontSize = large ? 16 : 10

    return <View style={{
        borderWidth: noBorder ? 0 : 1,
        borderColor: theme.colors.white,
        backgroundColor: color,
        borderRadius: fontSize,
        paddingHorizontal: 8,
        paddingVertical: 4,
        flexDirection: "row",
        alignItems: "center",
        ...style
    }}
    >
        {(status !== "not_reviewed") && <FontAwesome5 name={icon}
                                                      style={{marginRight: 4}}
                                                      color={theme.colors.white}
                                                      size={fontSize + 2}/>}
        <TextM fontWeight="SemiBold"
               color={theme.colors.white}
               fontSize={fontSize}>
            {text}
        </TextM>
    </View>;
}