import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {connect} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {validation} from "@data/utility/validation";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {referralService} from "@data/services/referralService";
import * as yup from "yup";

const CreateEditReferralCode = (props) => {

    const referral_code_id = props.route.params?.referral_code_id ?? null
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const editing = !!referral_code_id
    const referralCode = findItemInListWithId(referral_code_id, props.referralCodes) ?? null
    const defaultValues = {}


    const referrerName = formValues?.name

    const formData = [
        {
            label: "Infos sur l'affiliateur",
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "name",
                    label: "Nom de l'affiliateur",
                    placeholder: "ex: Tennis club de Nogent",
                    defaultValue: referralCode?.name ?? "",
                    validationRules: {
                        ...validation.required()
                    }
                },
                {
                    name: "commission_amount",
                    label: "Montant de la commission",
                    placeholder: "ex: 50",
                    defaultValue: referralCode?.commission_amount ?? 50,
                    validationRules: {
                        ...validation.required(),
                        ...validation.number(),
                        ...validation.min(0),
                        ...validation.max(100)
                    }
                },
                {
                    name: "referrer_email",
                    label: "Email",
                    placeholder: "ex: tennis-club-nogent@gmail.com",
                    defaultValue: referralCode?.referrer_email ?? "",
                    validationRules: {
                        ...validation.email()
                    }
                },
            ],
        },
        {
            label: "Promotion pour chaque nouveau client",
            description: "Avantage pour les nouveaux clients qui utiliseront le lien de " + referrerName,
            type: "select.single", // select.single, select.multiple, text, date
            name: "referee_discount",
            height: 70,
            options: [0, 5, 10, 15, 20]?.map(x => ({label: `${x}%`, value: x})) ?? [],
            defaultValue: referralCode?.referee_discount ?? 20,
            validationRule: yup.string(),
        }
    ]

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })
    }, [formValues])

    const onSubmit = data => {
        if (props.createReferralCodeRequest || props.updateReferralCodeRequest) return

        const formData = {
            ...data,
            commission_type: "cash"
        }

        if (editing)
            props.apiRequest(referralService.updateReferralCode, {id: referral_code_id}, formData)
        else
            props.apiRequest(referralService.createReferralCode, {}, formData)
    }
    useApiStatus(
        referralService.createReferralCode, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(referralService.indexPaginatedReferralCodes)
            navigation.goBack()
        } // success callback
    )
    useApiStatus(
        referralService.updateReferralCode, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            navigation.goBack()
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            <FormStepper navigation={navigation}
                         track
                         hideProgressBar
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.createReferralCodeRequest || props.updateReferralCodeRequest}
                         previousRouteName={"menu/referrals-backoffice"}
                         submittedMessage=""
                         serverError={props.createReferralCodeError || props.updateReferralCodeError}
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        createReferralCodeRequest: state.api.createReferralCode?.request,
        updateReferralCodeRequest: state.api.updateReferralCode?.request,
        createReferralCodeError: state.api.createReferralCode?.error,
        updateReferralCodeError: state.api.updateReferralCode?.error,
        referralCodes: state.data?.referrals?.referralCodes
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditReferralCode)
