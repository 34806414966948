import {Dimensions, View} from "react-native";
import {theme} from "@common/theme/theme";
import {PaginatedFlatList} from "@common/screens/PaginatedFlatList";
import DataTableTitleM from "@common/components/dataTable/DataTableTitleM";
import DataTableCellM from "@common/components/dataTable/DataTableCellM";
import {Divider} from "react-native-paper";
import React from "react";


export function PaginatedTableM({
                                    data,
                                    flatListRef = null,
                                    columns,
                                    headerHeight,
                                    requestService,
                                    urlParams,
                                    routeParams,
                                    noHeader = false,
                                    expandedRow,
                                    expandedRowContent,
                                }) {

    return (
        <PaginatedFlatList
            data={data}
            ref={flatListRef}
            style={{
                width: "100%",
                backgroundColor: theme.colors.white,
                flex: 1,
            }}
            contentContainerStyle={{
                alignItems: "flex-start",
                width: "100%", // Ensure the width is 100%
            }}
            additionalOnRefresh={() => {
            }}
            requestApiService={requestService}
            requestRouteParams={routeParams}
            requestUrlParams={urlParams}
            requestData={null}
            tooManyColumns={true}
            ListHeaderComponent={
                !noHeader && (
                    <View
                        style={{
                            height: headerHeight,
                            backgroundColor: theme.colors.primaryLighter,
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            minWidth: Dimensions.get("window").width,
                        }}
                    >
                        {columns.map((column, index) => (
                            <DataTableTitleM
                                key={index}
                                style={column.style}
                                sortingColumn={column}
                                dataToSort={data}
                                setDataToSort={data}
                                sortDirection={column.sortable ? "ascending" : null}
                                fontSize={12}
                                fontWeight={"Bold"}
                            >
                                {column.name}
                            </DataTableTitleM>
                        ))}
                    </View>
                )
            }
            renderItem={({item}) => {
                const isExpanded = expandedRow === item.id;

                return (
                    <View>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                minWidth: Dimensions.get("window").width,
                            }}
                        >
                            {columns.map((col, colIndex) => (
                                <DataTableCellM
                                    key={colIndex}
                                    style={col.style}
                                    textStyle={{
                                        maxWidth: col?.style?.maxWidth,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {col.cell(item)}
                                </DataTableCellM>
                            ))}
                        </View>
                        {isExpanded && expandedRowContent(item)}
                        <Divider/>
                    </View>
                );
            }}
        />
    );
}
