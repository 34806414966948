import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {DoneChip} from "./DoneChip";
import TextM from "@common/components/text/TextM";
import {hasRole} from "@data/utility/ability";
import FilledButtonM from "@common/components/button/FilledButtonM";
import ActivityScoringKeyValueItem from "./ActivityScoringKeyValueItem";
import {StepLogs} from "./StepLogs";
import React, {useContext, useEffect, useState} from "react";
import {formatCalendarMoment} from "@data/utility/Utils";
import {Badge} from "react-native-paper";
import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {activitiesService} from "@data/services/activitiesService";
import {ActivityXpKeyValueItem} from "./ActivityXpKeyValueItem";
import {KeyValueItem} from "@common/components/card/KeyValueItem";
import ReadReviewRequestHandler from "./ReadReviewRequestHandler";
import ActivityGem from "./ActivityGem";
import ChampionBubble from "../../champions/ChampionBubble";
import {samVariantForActivity} from "../../champions/ChampionImageM";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";
import {definedMaterialSlug} from "../todo/ActivityPicker";
import MaterialModal from "../execute/MaterialDisplayModal";

const SingleActivityResult = (props) => {
    const {activity} = props;

    useEffect(() => {
        if (!activity?.is_review_read && hasRole(props.user, "student"))
            props.apiRequest(activitiesService.readReview, {id: activity?.id});
    }, [activity]);

    const dimensions = useContext(DimensionsContext);

    const activityMaterial = activity?.material;
    const dMaterialSlug = definedMaterialSlug(activity?.slug);
    const [isOpen, setOpen] = useState(false);
    const handleShowModal = () => setOpen(!isOpen);

    const status = activity?.finished_at
        ? null
        : activity?.started_at !== null && activity?.locked_at !== null
            ? "ongoing"
            : activity?.started_at !== null && activity?.locked_at === null
                ? "started"
                : null;

    return (
        <View
            style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "flex-start",
                paddingHorizontal: 5,
                backgroundColor: theme.colors.white,
                borderTopWidth: 1,
                borderBottomWidth: 1,
                marginTop: 30,
                borderColor: theme.colors.lightMore,
                paddingVertical: 15,
                width: dimensions?.width,
                ...boxedCardBorderStyle

                // paddingTop: 20,
            }}
        >
            <ReadReviewRequestHandler/>
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    paddingHorizontal: 10,
                    marginBottom: 10,
                    width: "100%"
                }}
            >
                {activity?.finished_at && (
                    <TextM
                        fontWeight="Bold"
                        color={theme.colors.light}
                        style={{marginTop: 5}}
                    >
                        {formatCalendarMoment(activity?.finished_at, true)}
                    </TextM>
                )}
                <DoneChip count={0} status={status ?? activity?.status} large/>
            </View>
            {!!activity?.gem_id && (
                <View
                    style={{
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        paddingHorizontal: 10,
                        marginBottom: 10,
                        width: "100%",
                        borderColor: theme.colors.lightMore
                    }}
                >
                    <ActivityGem activity={activity} large/>
                </View>
            )}
            {activity?.status === "pending_review" && (
                <ChampionBubble
                    name={"sam"}
                    variant={"main"}
                    championPosition="left"
                    texts={[
                        "Habituellement il me faut moins de 24h pour analyser ton travail"
                    ]}
                    onNextPress={() => {
                    }}
                />
            )}
            {!activity?.is_review_read && hasRole(props.user, "student") ? (
                <View style={{marginTop: 30, width: "100%"}}>
                    <FilledButtonM
                        color={theme.colors.accentDark}
                        mode="outlined"
                        // labelStyle={{color: theme.colors.white}}
                        loading={props.readReviewRequest}
                        disabled={props.readReviewRequest}
                        onPress={() => {
                            if (props.readReviewRequest) return;
                            props.apiRequest(activitiesService.readReview, {
                                id: activity?.id
                            });
                        }}
                        label="Voir l'évaluation"
                    />
                    <Badge
                        size={12}
                        style={{
                            backgroundColor: theme.colors.warning,
                            position: "absolute",
                            top: -2,
                            right: -2
                        }}
                    />
                </View>
            ) : (
                <>
                    <ActivityXpKeyValueItem
                        activity={activity}
                        style={{marginTop: 30}}
                    />
                    {activity?.status === "validated" && (
                        <ActivityScoringKeyValueItem
                            activity={activity}
                            student={props.user}
                            style={{marginTop: 20}}
                        />
                    )}

                    {/* ** Activity review comment (if applicable) */}
                    {!!activity?.review_comment && (
                        <KeyValueItem
                            keyText="COMMENTAIRE DE SAM"
                            valueComponent={
                                <ChampionBubble
                                    name={"sam"}
                                    variant={samVariantForActivity(activity)}
                                    noAnimation={true}
                                    championPosition="left"
                                    texts={[activity?.review_comment]}
                                    onNextPress={() => {
                                    }}
                                    // ref={championsBubbleRef}
                                />
                            }
                            style={{marginTop: 20}}
                            columnMode
                        />
                    )}
                </>
            )}

            {activityMaterial && (
                <View
                    style={{
                        marginTop: 30
                        // backgroundColor: theme.colors.lighter,
                        // height: dimensions?.height / 3 - 50
                    }}
                >
                    <FilledButtonM
                        mode={"outlined"}
                        label={"Voir " + dMaterialSlug}
                        style={{}}
                        color={theme.colors.primary}
                        icon={
                            activityMaterial?.url
                                ? "link"
                                : activityMaterial?.attachments?.length > 0
                                    ? "image"
                                    : "align-justify"
                        }
                        onPress={() => {
                            // open material modal
                            handleShowModal();
                        }}
                    />
                </View>
            )}

            {activity?.activity_logs &&
                activity?.steps?.map((step, index) => (
                    <StepLogs
                        step={step}
                        key={index}
                        index={index}
                        style={{marginTop: 20}}
                        updatedActivityEventData={props.updatedActivityEventData}
                        activity={activity}
                    />
                ))}

            {isOpen && (
                <MaterialModal
                    handleShowModal={handleShowModal}
                    isOpen={isOpen}
                    material={activityMaterial}
                />
            )}
        </View>
    );
};

const mapStateToProps = (state) => {
    return {
        readReviewRequest: state.api.readReview?.request,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object,
        updatedActivityEventData: state.activity?.updatedActivityEventData
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleActivityResult);
