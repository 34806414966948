import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext, useEffect} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import SuccessAnimation from "@common/animations/SuccessAnimation";
import {IconImageM} from "@common/components/icons/IconsM";
import {API_URL} from "@data/constants/apiConstants";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import plantTree from "../../assets/icons/generic/plant_tree.png";


const QuizzConfirmedCongrats = (props) => {
    const dimensions = useContext(DimensionsContext)

    useEffect(() => {
        PlaySoundM.play(require('../../assets/sounds/new-title.mp3'))

    }, [])

    return (
        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 40,
            paddingHorizontal: 15,
            width: dimensions?.width
        }}>

            <TextM fontSize={34}
                   wrap
                   fontWeight="Bold"
                   style={{
                       textAlign: "center"
                       // marginTop: 50
                   }}
                   color={theme.colors.primary}>
                {props.congratsTitle.toUpperCase()}
            </TextM>

            <TextM fontSize={18}
                   wrap
                   fontWeight="Regular"
                   style={{
                       textAlign: "center",
                       marginTop: 40,
                       marginHorizontal: 30
                   }}
                   color={theme.colors.petalDarker}>
                {props.congratsText}
            </TextM>

            <IconImageM source={plantTree} width={150} style={{borderRadius: 15, marginTop: 10}}/>

        </View>)
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(QuizzConfirmedCongrats)