import {View} from "react-native";
import AvatarM from "@common/components/avatar/AvatarM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "@common/theme/theme";
import {Badge} from "react-native-paper";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";

const LiveAvatarM = props => {

    const {user, size = 50, style} = props
    const isOnline = !!props.participants?.find(x => x === user?.id)
    const isHandRaised = !!props.raisedHands?.find(x => x?.toString() === user?.id?.toString())
    // console.log(props.inCallUsers)

    return <View style={{...style}}>
        <AvatarM user={user} style={{marginRight: 2, ...style}} size={size}/>
        {isHandRaised && <FontAwesome5 name="hand-paper"
                                       solid
                                       size={14}
                                       style={{
                                           position: "absolute",
                                           bottom: 14,
                                           right: 0
                                       }}
                                       color={theme.colors.accent}/>}
        <Badge
            style={{
                backgroundColor: (isOnline ? theme.colors.success : theme.colors.light),
                position: "absolute",
                bottom: 0,
                right: 0
            }}
            size={12}
        />
    </View>
}
const mapStateToProps = state => {
    return {
        currentUser: state.data.currentUser?.object,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessionId: state.liveMode?.liveSessionId,
        participants: state.liveMode?.liveParticipants,
        inCallUsers: state.liveMode?.inCallUsers,
        raisedHands: state.liveMode?.raisedHands,
        voiceActivityRecordingIds: state.liveMode?.voiceActivityRecordingIds,
        getSessionsRequest: state.api.getLiveSession?.request,
        isLive: state.liveMode.isLiveModeEnabled
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(LiveAvatarM)