import * as Notifications from "expo-notifications";
import {authService} from "@data/services/authService";
import React, {useEffect} from "react";
import {Platform} from "react-native";
import FilledButtonM from "../components/button/FilledButtonM";
import {theme} from "../theme/theme";
import TextM from "../components/text/TextM";
import Constants from "expo-constants";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect, useDispatch, useSelector} from "react-redux";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import * as amplitude from '@amplitude/analytics-react-native';

export const useLogout = () => {

    const dispatch = useDispatch()

    const loggingOutWeb = useSelector(state => state.api.logout?.request),
        loggedOutWeb = useSelector(state => state.api.logout?.success),
        errorWeb = useSelector(state => state.api.logout?.error),
        loggingOut = useSelector(state => state.api.revokeSanctumToken?.request),
        loggedOut = useSelector(state => state.api.revokeSanctumToken?.success),
        error = useSelector(state => state.api.revokeSanctumToken?.error),
        unsubscribeFromNotificationsRequest = useSelector(state => state.api.unsubscribeFromNotifications?.request),
        unsubscribeFromNotificationsSuccess = useSelector(state => state.api.unsubscribeFromNotifications?.success),
        unsubscribeFromNotificationsError = useSelector(state => state.api.unsubscribeFromNotifications?.error),
        expoToken = useSelector(state => state.data.session?.object?.expo_token)

    const request = loggingOut || unsubscribeFromNotificationsRequest || loggingOutWeb

    function onLogout() {
        amplitude.reset()
        if (Platform.OS !== 'web') {
            Notifications.dismissAllNotificationsAsync().then(() => {
                if (expoToken)
                    dispatch(apiRequest(
                        authService.unsubscribeFromNotifications,
                        {},
                        {expo_token: expoToken}
                    ))
                else {
                    dispatch(apiRequest(authService.revokeSanctumToken, {}, {}));
                }
            });
        } else {
            // apiRequest(authService.revokeSanctumToken, {}, {});
            dispatch(apiRequest(authService.logout, {}, {}));
        }

    }

    useEffect(() => {
        if (loggedOut) {
            dispatch(apiIdle(authService.revokeSanctumToken))
        } else if (error) {
            dispatch(apiIdle(authService.revokeSanctumToken))
            //alertPolyfill('Erreur', error.message)
            DialogM.show({
                text1: 'Erreur', text2: error.message
            })
        }
    }, [loggedOut, error])

    useEffect(() => {
        if (loggedOutWeb) {
            dispatch(apiIdle(authService.logout))
        } else if (error) {
            dispatch(apiIdle(authService.logout))
            //alertPolyfill('Erreur', error.message)
            DialogM.show({
                text1: 'Erreur', text2: error.message
            })
        }
    }, [loggedOutWeb, errorWeb])

    useEffect(() => {
        if (unsubscribeFromNotificationsSuccess) {
            dispatch(apiIdle(authService.unsubscribeFromNotifications))
            dispatch(apiRequest(authService.revokeSanctumToken, {}, {}))
        } else if (unsubscribeFromNotificationsError) {
            dispatch(apiIdle(authService.unsubscribeFromNotifications))
            if (unsubscribeFromNotificationsError?.status === "failed") { // when server sends back "failed" status, the token probably does not exist
                dispatch(apiRequest(authService.revokeSanctumToken, {}, {}))
            } else {
                DialogM.show({
                    text1: 'Erreur', text2: unsubscribeFromNotificationsError.message
                })
                //alertPolyfill('Erreur', unsubscribeFromNotificationsError.message)
            }
        }
    }, [unsubscribeFromNotificationsSuccess, unsubscribeFromNotificationsError])

    return [onLogout, request]

}
const LogoutButton = (props) => {

    const {noVersion = false} = props

    const [onButtonPress, logoutRequest] = useLogout()

    return <>
        <FilledButtonM
            icon={"sign-out-alt"}
            mode="outlined"
            style={{
                marginTop: 40,
            }}
            color={theme.colors.error}
            // textColor={theme.colors.white}
            onPress={onButtonPress}
            loading={logoutRequest}
            disabled={logoutRequest}
            label="Se déconnecter"
        />

        {!noVersion && <TextM
            fontSize={12}
            color={theme.colors.light}
            style={{textAlign: "center", marginBottom: 15}}
        >
            Version {Constants.expoConfig.version}
        </TextM>}
    </>;
}

const mapStateToProps = (state) => {
    return {

        getUserRequest: state.api.getUser?.request,
        successes: state.data.staticData?.successes,
        token: state.data.session?.object?.token
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LogoutButton);