import React, {useContext} from 'react';
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {formatHoursMinutesAndSeconds} from "@common/liveMode/live-report/LiveReportComponent";
import {IconImageM} from "@common/components/icons/IconsM";
import liveIcon from "../../../assets/icons/generic/live.png";
import {DimensionsContext} from "@common/utils/mobileUtils";

const StatsCardItem = (props) => {
    const {mainTitle, items, style, componentBeforeItems = false} = props

    const dimensions = useContext(DimensionsContext)

    // console.log(items)

    return (
        <View style={{
            flex: 1,
            borderWidth: 1,
            borderColor: theme.colors.lightMore,
            backgroundColor: theme.colors.white,
            borderRadius: theme.roundness,
            paddingVertical: 10,
            paddingHorizontal: 25,
            alignItems: 'center',
            // justifyContent: 'center',
            ...style
        }}>
            {mainTitle && <TextM color={theme.colors.primary}
                    wrap
                    fontSize={22}
                    fontWeight={'Bold'}
                    style={{
                        marginBottom: 15,
                        textAlign: "center"
                    }}
            >
                {mainTitle.toUpperCase()}
            </TextM>}

            {props.icon && <IconImageM source={props.icon} width={dimensions.width > 800 ? 50 : 35} style={{
                marginBottom: 15,
            }}/>}

            {props.customComponent && componentBeforeItems && props.customComponent}

            <View style={{
                ...props.itemsContainerStyle
            }}>
                {items && items?.map((item, index) => {
                    const value = item?.value?.result ?? item?.value

                    return <View key={index} style={{
                        marginBottom: 10,
                        // backgroundColor: "red",
                        // flex: 1
                        ...props.itemStyle
                    }}>
                        <TextM color={theme.colors.primary}
                               fontSize={20}
                               wrap
                               style={{textAlign: 'center'}}
                        >
                            {item?.name}
                        </TextM>
                        {item?.value !== undefined && <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            {<TextM fontSize={30} fontWeight={'Bold'} wrap>
                                {value === undefined ? "-" : item?.name?.toLowerCase()?.includes("temps") ? formatHoursMinutesAndSeconds(value) : value}<TextM
                                fontSize={18} fontWeight={'Bold'} wrap>{item?.type === 'ratio' && '%'}</TextM>
                            </TextM>}
                            {!!item?.evolution && <TextM fontSize={10}
                                                         wrap
                                                         color={item?.evolution?.toString()?.includes('-') ? theme.colors.error : item?.evolution === 0 ? theme.colors.grey : theme.colors.success}>
                                {!item?.evolution?.toString()?.includes('-') ? ('+'.concat(item?.evolution)) : item?.evolution}%
                            </TextM>}
                        </View>}
                        {(item?.type === 'ratio' || item?.type === 'average') && <View style={{
                            flexDirection: 'row',
                            justifyContent: 'center'
                        }}>
                            <TextM fontSize={12} fontWeight={'Regular'} wrap>
                                {item?.numerator ?? item?.value?.numerator} / {item.denominator ?? item?.value?.denominator ?? "-"}
                            </TextM>
                        </View>}
                    </View>
                })}
            </View>


            {props.customComponent && !componentBeforeItems && props.customComponent}

        </View>
    )
}

export default StatsCardItem;