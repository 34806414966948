import React, { useEffect, useRef, useState } from "react";
import { Text, TextInput } from "react-native-paper";
import { theme } from "../../theme/theme";
import { View } from "react-native";
import TextM from "../text/TextM";
import {IS_WEB} from "@common/utils/mobileUtils";
import {IconTextM} from "@common/components/icons/IconTextM";

const AutoSaveTextInputM = React.forwardRef((props, ref) => {
  const {
    password,
    name,
    errors,
    onChangeText,
    value,
    style = {},
    contentContainerStyle = {},
    saveData = () => {},
  } = props;

  const [secure, setSecure] = React.useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  const handleChangeText = (value) => {
    setIsSaving(false);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setIsSaving(true);
      onChangeText(value);
      saveData({ [name]: value });
      // setTimeout(() => setIsSaving(false), 3000);
    }, 1000);
  };

  return (
    <>
      <View style={{ width: "100%", ...contentContainerStyle }}>
        <TextInput
          {...props}
          ref={ref}
          selectionColor={theme.colors.light}
          mode="outlined"
          outlineColor={theme.colors.light}
          activeOutlineColor={theme.colors.light}
          style={{
            fontSize: 16,
            width: "100%",
            ...style,
          }}
          value={value}
          onChangeText={handleChangeText}
          secureTextEntry={password && secure}
          right={
            password && (
              <TextInput.Icon
                color={theme.colors.primary}
                name={secure ? "eye" : "eye-off"}
                onPress={() => setSecure(!secure)}
              />
            )
          }
          error={errors && errors[name]}
        />
        {errors && errors[name] && (
          <Text style={{ color: theme.colors.error }}>
            {errors[name].message}
          </Text>
        )}
        <View style={{ height: 20, marginTop: 5 }}>
          {isSaving && (
              <IconTextM text={`Modifications enregistrées`}
                         style={{
                         }}
                         icon={"check"}
                         iconSize={14}
                         fontSize={14}
                         color={theme.colors.success}/>
          )}
        </View>
      </View>
    </>
  );
});

AutoSaveTextInputM.displayName = "AutoSaveTextInputM";

export default AutoSaveTextInputM;
