import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import {Image, TouchableOpacity, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import silver from "../../../assets/leagues/silver.png";
import bronze from "../../../assets/leagues/bronze.png";

const LeagueHistoryScreen = (props) => {
    const dimensions = useContext(DimensionsContext)

    const tab = [
        {
            date: "20 Octobre",
            badge: silver,
            rank: 6,
        }, {
            date: "13 Octobre",
            badge: silver,
            rank: 12,
        }, {
            date: "6 Octobre",
            badge: bronze,
            rank: 4,
        }

    ]

    return (<>
        {tab.map((el, key) => (
            <View color={theme.colors.grey}
                  style={{alignItems: "center", marginTop: 30}}
                  wrap
                  key={key}
            >
                <TouchableOpacity onPress={() => {
                }} style={{
                    width: dimensions?.width - 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    height: 60,
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                    backgroundColor: theme.colors.lightMore,
                }}>


                    <TextM fontWeight="Bold" fontSize={15}>Du {el.date} </TextM>
                    <Image
                        style={{
                            marginBottom: 20,
                            marginTop: 20,
                            flex: .3
                        }}
                        source={el.badge}
                        resizeMode='contain'
                    />
                    <View style={{
                        marginLeft: 10
                    }}>
                        <TextM fontWeight="Bold" style={{}} fontSize={15}>{el.rank}éme rang</TextM>
                    </View>


                </TouchableOpacity>

            </View>)
        )}

    </>)
}
const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(LeagueHistoryScreen)