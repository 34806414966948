import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {Text} from "react-native-paper";
import React from "react";
import {offersNames} from "@data/utility/ability";

export const productsColors = {
    basic: theme.colors.light,
    plus: theme.colors.material.secondary["200"],
    premium: theme.colors.accentDark,
    initial: theme.colors.material.secondary["300"],
    essential: theme.colors.material.secondary["500"],
    integral: theme.colors.material.secondary["700"],
    holidaycourse: theme.colors.material.secondary["200"],
}

export const productsLightColors = {
    basic: theme.colors.lighter,
    plus: theme.colors.material.secondary["50"],
    premium: theme.colors.material.secondary["100"],
    initial: theme.colors.material.secondary["50"],
    essential: theme.colors.material.secondary["100"],
    integral: theme.colors.material.secondary["200"],
    holidaycourse: theme.colors.material.secondary["100"],
}

export function SubscriptionPrivilegeChip({product = "basic", withRotation = false, style, large = false}) {
    return <View style={[{
        backgroundColor: productsColors?.[product],
        paddingHorizontal: 6,
        paddingTop: 2,
        paddingBottom: 0,
        borderRadius: 8,
        ...style
    }, {
        transform: [{rotate: withRotation ? "10deg" : "0deg"}]
    }]}>
        <Text
            style={{
                color: theme.colors.white,
                fontFamily: "Owl-Cute",
                fontSize: large ? 28 : 24
            }}
        >
            {offersNames?.[product]}
        </Text>
    </View>;
}

export function SubscriptionProductChip({product = "basic", withRotation = false, style, large = false}) {
    return <View style={[{
        backgroundColor: theme.colors.material.secondary["600"],
        paddingHorizontal: 6,
        paddingTop: 2,
        paddingBottom: 0,
        borderRadius: 8,
        ...style
    }, {
        transform: [{rotate: withRotation ? "10deg" : "0deg"}]
    }]}>
        <Text
            style={{
                color: theme.colors.white,
                fontFamily: "Owl-Cute",
                fontSize: large ? 28 : 24
            }}
        >
            {product}
        </Text>
    </View>;
}