import React, {useContext} from 'react';
import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import {IconImageM} from "@common/components/icons/IconsM";
import plantTree from "../../../assets/icons/generic/candidat.png";
import ChampionBubble from "../../student/champions/ChampionBubble";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";

const ApplicationFinishedScreen = (props) => {
    const dimensions = useContext(DimensionsContext)

    const text = props.user?.candidature_status === "pending"
        ? "Merci ! \nNous étudions ta candidature. En général, nous envoyons une réponse en quelques semaines."
        : "Tu as reçu un email avec ton évaluation.";
    const variant = props.user?.candidature_status === "rejected"
        ? "main"
        : "happy";

    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        width: dimensions?.width,
        alignSelf: "center",
        flex: 1,
    }}>
        <TextM
            color={theme.colors.primary}
            fontWeight={"Bold"}
            fontSize={20}
        >
            CANDIDATURE TERMINÉE !
        </TextM>
        <View>
            <IconImageM source={plantTree} width={100} style={{borderRadius: 15, marginTop: 10}}/>
            {/*<View style={{*/}
            {/*    backgroundColor: theme.colors.primary,*/}
            {/*    position: 'absolute',*/}
            {/*    right: 0,*/}
            {/*    top: 20,*/}
            {/*    borderRadius: 20,*/}
            {/*    width: 25,*/}
            {/*    height: 25,*/}
            {/*    alignItems: "center",*/}
            {/*    justifyContent: "center",*/}
            {/*}}>*/}
            {/*    <FontAwesome5*/}
            {/*        name={"check"}*/}
            {/*        size={20}*/}
            {/*        color={theme.colors.white}*/}
            {/*    />*/}
            {/*</View>*/}
        </View>
        <ChampionBubble name={"zoe"}
                        variant={variant}
                        championPosition="center"
                        texts={[text]}
                        style={{
                            // position: "absolute",
                            // bottom: 0
                            // marginBottom: -300
                        }}
                        onNextPress={() => {
                        }}
            // ref={championsBubbleRef}
            // style={{marginTop: 30}}
        />
    </View>
}
const mapStateToProps = state => {
    return {
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationFinishedScreen)