import moment from "moment"

export const slotSaturday = [
    {value: '09:00', label: '09:00'},
    {value: '09:30', label: '09:30'},
    {value: '10:00', label: '10:00'},
    {value: '10:30', label: '10:30'},
    {value: '11:00', label: '11:00'},
    {value: '11:30', label: '11:30'},
    {value: '12:00', label: '12:00'},
    {value: '12:30', label: '12:30'},
    {value: '13:00', label: '13:00'},
    {value: '13:30', label: '13:30'},
    {value: '14:00', label: '14:00'},
    {value: '14:30', label: '14:30'},
    {value: '15:00', label: '15:00'},
    {value: '15:30', label: '15:30'},
    {value: '16:00', label: '16:00'},
    {value: '16:30', label: '16:30'},
    {value: '17:00', label: '17:00'},
    {value: '17:30', label: '17:30'},
    {value: '18:00', label: '18:00'},
    {value: '18:30', label: '18:30'},
    {value: '19:00', label: '19:00'}
]
export const slotSunday = [
    {value: '09:00', label: '09:00'},
    {value: '09:30', label: '09:30'},
    {value: '10:00', label: '10:00'},
    {value: '10:30', label: '10:30'},
    {value: '11:00', label: '11:00'},
    {value: '11:30', label: '11:30'},
    {value: '12:00', label: '12:00'},
    {value: '12:30', label: '12:30'},
    {value: '13:00', label: '13:00'},
    {value: '13:30', label: '13:30'},
    {value: '14:00', label: '14:00'},
    {value: '14:30', label: '14:30'},
    {value: '15:00', label: '15:00'},
    {value: '15:30', label: '15:30'},
    {value: '16:00', label: '16:00'},
    {value: '16:30', label: '16:30'},
    {value: '17:00', label: '17:00'},
    {value: '17:30', label: '17:30'},
    {value: '18:00', label: '18:00'},
    {value: '18:30', label: '18:30'},
    {value: '19:00', label: '19:00'}
]

export const slotsList = (minTime, maxTime, intervalMinutes) => {

    const list = [minTime]
    let newTime = moment(`2000-01-01 ${minTime}`).add(intervalMinutes, 'minutes').format('HH:mm')

    while (moment(`2000-01-01 ${newTime}`).diff(`2000-01-01 ${maxTime}`) < 0) {
        list.push(newTime)
        newTime = moment(`2000-01-01 ${newTime}`).add(intervalMinutes, 'minutes').format('HH:mm')
    }

    return list.map(x => {
        return {value: x, label: x}
    })
}