import React, {useContext, useRef} from 'react';
import {Platform, SectionList, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import ChapterItemComponent from "@common/chapters/ChapterItemComponent";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {hasRole} from "@data/utility/ability";
import {useNavigation} from "@react-navigation/native";
import {IconButton} from "react-native-paper";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import moment from "moment";

const StudyProgramItemsList = (props) => {
    const {
        studyProgramItems,
        headerComponent,
        studyProgramChapters,
        selectedChapters,
        removeStudyProgramItem,
        noDelete = false,
        onSelectChapter = () => {},
        selectedChapterId,
        renderChapterOnly = true,
        liveReportMode = false
    } = props

    const flatListRef = useRef();
    const dimensions = useContext(DimensionsContext)

    const navigation = useNavigation();

    const getDatesList = (studyProgramItems) => {
        const datesList = [];
        const startDate = new Date(studyProgramItems?.sort((a, b) => new Date(a?.start_date) - new Date(b?.start_date))[0]?.start_date);
        const endDate = new Date(studyProgramItems?.sort((a, b) => new Date(a?.end_date) - new Date(b?.end_date))[studyProgramItems?.length - 1]?.end_date);

        for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
            datesList.push(new Date(date));
        }

        return datesList;
    };

    const datesList = getDatesList(studyProgramItems);

    // const initialDate = moment().format('YYYY-MM-DD')
    // const initialScrollIndex = () => {
    //     let index = 0
    //     while (moment(datesList[index]).isBefore(initialDate, 'day')) {
    //         index++
    //     }
    //     return index
    // }

    return (


        <SectionList sections={datesList.map((date, index) => {
            return {
                title: moment(date).format("dddd DD MMMM"),
                data: studyProgramItems?.filter(x => date >= new Date(x?.start_date) && date <= new Date(x?.end_date))
                    .map(x => x?.chapter ?? studyProgramChapters?.find((chapter) => chapter?.id === x?.chapter_id) ?? [...props.chapters, ...studyProgramChapters, ...selectedChapters]?.find((chapter) => chapter?.id === x?.chapter_id))
            }
        })?.filter(x => x?.data?.length > 0)}
                     stickySectionHeadersEnabled={false}
                     initi
                     // initialScrollIndex={initialScrollIndex()}
                     style={{
                         flex: 1
                     }}
                     contentContainerStyle={{
                         // flex: 1,
                         // width: dimensions?.width,
                         // alignItems: 'center'
                         height: dimensions.height / 1.5 + 50,
                     }}
                     ref={flatListRef}
                     ListHeaderComponent={headerComponent}
                     showsHorizontalScrollIndicator={Platform.OS === "web" && false}
                     renderSectionHeader={({section: {title, data}}) => (
                         <>
                             <View style={{
                                 paddingLeft: 0,
                                 paddingTop: 20,
                                 backgroundColor: theme.colors.whiteAlmost,
                                 flex: 1
                             }}>
                                 <TextM color={theme.colors.primary}
                                        fontSize={16}
                                        fontWeight="Bold"
                                 >
                                     {title}
                                 </TextM>
                             </View>
                             {/*{data?.length === 0 && <TextM style={{*/}
                             {/*    textAlign: "center",*/}
                             {/*    marginTop: 20*/}
                             {/*}}>Aucun chapitre pour cette date</TextM>}*/}
                         </>
                     )}
                     renderItem={(item) => {
                         // console.log("item.item?.id", item.item?.id)
                         // console.log("selectedChapterId", selectedChapterId)
                         return <View style={{
                             justifyContent: "center"
                         }}>
                             <ChapterItemComponent chapter={item.item}
                                                   renderChapterOnly={renderChapterOnly}
                                                   liveReportMode={liveReportMode}
                                                   isHighlighted={selectedChapterId === item.item?.id}
                                                   onPress={() => {
                                                       onSelectChapter(item.item)
                                                   }}/>
                             {!noDelete && <IconButton
                                 icon="trash"
                                 iconColor={theme.colors.greyAlt}
                                 size={24}
                                 style={{position: "absolute", right: 0}}
                                 onPress={() => {
                                     DialogM.show({
                                         text1: `Supprimer le chapitre ${item.item?.name} ?`,
                                         buttonText1: 'Oui', // buttonColor1: theme.colors.error,
                                         onPress: () => {
                                             removeStudyProgramItem(item.item?.id)
                                         },
                                         buttonText2: 'Annuler',
                                         onPressBack: () => {
                                         }
                                     })
                                 }}
                             />}
                         </View>
                     }}
            ListFooterComponent={<View style={{height: 200}}/>}
        />
    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        feelingQuestions: state.data.staticData?.feeling_questions,
        feelingQuestionsRequest: state.api.feelingQuestionsRequest,
        studentChapterFeelingsRequest: state.api.studentChapterFeelings?.request,
        feelingQuestionsError: state.api.feelingQuestionsError,
        chapters: state.data.chapters?.chaptersList
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyProgramItemsList)