import React, {useState} from 'react';
import {View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {validation} from "@data/utility/validation";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {backOfficeService} from "@data/services/backOfficeService";
import {findItemInListWithId} from "@data/utility/dataUtils";

function FixSalaryScreen(props) {
    const {userId} = props.route.params
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}
    const user = findItemInListWithId(userId, props.users)
    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Fixer sa rémuneration",
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "salary",
                    placeholder: "Rémuneration",
                    defaultValue: user?.profile?.salary,
                    validationRules: {
                        ...validation.required(),
                    }
                }
            ]
        },
    ]
    const onSubmit = data => {
        if (props.updateSalaryRequest || props.updateSalaryError) return
        props.apiRequest(backOfficeService.updateSalary, {id: userId}, data)
    }

    useApiStatus(
        backOfficeService.updateSalary, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("menu")
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={{name: "tina", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.updateSalaryRequest}
                         previousRouteName={"manage-tutors"}
                         submittedMessage=""
                         serverError={props.updateSalaryError}
            />
        </View>);
}

const mapStateToProps = state => {
    return {
        updateSalaryRequest: state.api.updateSalary?.request,
        updateSalaryError: state.api.updateSalary?.error,
        user: state.data.currentUser?.object,
        users: state.data?.users?.usersList,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(FixSalaryScreen)