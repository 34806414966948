import React, {useEffect, useState} from 'react';
import {StatusBar, View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";
import {activityTypes, schoolSubjectCategories} from "@data/constants/activitiesConstants";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {validation} from "@data/utility/validation";
import {scoringService} from "@data/services/scoringService";


const NewScoringCommentScreen = (props) => {

    const {navigation, route} = props

    const description = route.params?.description
    const type = route.params?.type
    const scoringRuleId = route.params?.scoringRuleId
    const currentActivityType = route.params?.currentActivityType
    const currentSchoolSubjectCategory = route.params?.currentSchoolSubjectCategory

    const commentPlaceholder = type === "rule"
        ? "Bref point d'amélioration ou conseil, qui alimentera l'appréciation de Sam"
        : type === "introduction"
            ? "Brève introduction, qui alimentera l'appréciation de Sam"
            : type === "conclusion"
                ? "Brève conclusion, qui alimentera l'appréciation de Sam"
                : "Bref commentaire, pour alimenter l'appréciation de Sam"

    const [formValues, setFormValues] = useState(null)

    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Nouveau commentaire",
            description: description ?? null,
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "comment",
                    placeholder: commentPlaceholder,
                    height: 120,
                    defaultValue: "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ]
        },
        {
            label: "Pour quels types d'activités ce nouveau commentaire est-il valable ?",
            description: "(pour les futures évaluations)",
            is_masked: type === "rule",
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "activity_types",
            height: 70,
            options: Object.keys(activityTypes)?.map(x => {
                return {
                    label: activityTypes[x],
                    value: x,
                    disabled: x === currentActivityType
                }
            }),
            defaultValue: Object.keys(activityTypes),
            validationRules: {
                ...validation.minLength(1)
            }
        },
        {
            label: "Pour quelles matières ce nouveau commentaire est-il valable ?",
            description: "(pour les futures évaluations)",
            is_masked: type === "rule",
            type: "select.multiple", // select.single, select.multiple, text, date
            name: "school_subject_categories",
            height: 70,
            options: Object.keys(schoolSubjectCategories)?.map(x => {
                return {
                    label: schoolSubjectCategories[x],
                    value: x,
                    disabled: x === currentSchoolSubjectCategory
                }
            }),
            defaultValue: Object.keys(schoolSubjectCategories),
            validationRules: {
                ...validation.minLength(1)
            }
        }
    ]

    const onSubmit = data => {
        // console.log("data", data)
        if (props.postScoringCommentRequest) return


        props.apiRequest(scoringService.postScoringComment, {}, {
            ...data,
            type: type ?? null,
            scoring_rule_id: scoringRuleId ?? null
        })
    }

    useApiStatus(
        scoringService.postScoringComment, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log("successData", successData)
            // props.apiIdle(scoringService.postScoringComment)
            navigation.goBack()
            // const congrats = [{
            //     congratsType: congratsTypes.GENERIC,
            //     congratsData: {
            //         title: "Effectué",
            //         text: "Le créneau a bien été mis à jour"
            //     }
            // }]
            // navigation.replace("congrats", {congrats})
        },
        () => {
        },
        true
    )
    const defaultValues = {}

    useEffect(() => {
        formData?.forEach(x => {
            if (x?.name)
                defaultValues[x.name] = x.defaultValue
            else if (x?.inputs)
                x?.inputs?.forEach(i => {
                    defaultValues[i.name] = i.defaultValue
                })
        })

        // console.log("PARENT :")
        // console.log(defaultValues)

    }, [formValues])

    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.postScoringCommentRequest}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.postScoringCommentError}
                         submitButtonText="Enregistrer"
            />
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        postScoringCommentRequest: state.api.postScoringComment?.request,
        postScoringCommentSuccess: state.api.postScoringComment?.success,
        postScoringCommentError: state.api.postScoringComment?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewScoringCommentScreen)