import React, {useContext, useState} from 'react';
import {TouchableOpacity, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {setOngoingActivity} from "@data/redux/actions/activity";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {connect} from "react-redux";
import {IconButton} from "react-native-paper";
import {liveReportService} from "@data/services/liveReportService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TitleM from "@common/components/text/TitleM";
import {useNavigation} from "@react-navigation/native";
import AvatarM from "@common/components/avatar/AvatarM";
import {PaginatedTableM} from "../PaginatedTableM";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {PrioritizedSchoolSubjects} from "../../tutor/students/ProfilDetails";
import moment from "moment";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import SatisfactionComponent from "@common/liveMode/SatisfactionComponent";
import {EngagementScoreBar} from "@common/liveMode/live-report/LiveReportComponent";
import {capitalize} from "@data/utility/Utils";
import {backOfficeService} from "@data/services/backOfficeService";

export const EngagementScoreComponent = (props) => {
    const {scores, totalScore, width, disableScoreButton = false, forceVerticalDetails = false} = props
    return <View style={{
        marginLeft: 0,
        maxWidth: 700,
        flexDirection: forceVerticalDetails ? "column" : "row",
        alignItems: "center", ...props.style
    }}>
        {!forceVerticalDetails && <View style={{
            flexDirection: 'row',
            alignItems: 'center'
            // marginTop: 10
        }}>
            <TextM fontSize={20} fontWeight={'ExtraBold'}>
                {totalScore ?? "-"} <TextM fontSize={16}
                                           fontWeight={'Bold'}>/100</TextM>
            </TextM>
        </View>}
        <EngagementScoreBar scores={scores}
                            width={width ?? (400)}
                            noProgressBar
                            forceVerticalDetails={forceVerticalDetails}
                            disableScoreButton={disableScoreButton}
        />
    </View>
}

const LiveReportScreen = (props) => {
    const {studentId} = props.route?.params

    const navigation = useNavigation()

    const requestApiService = studentId ? liveReportService.getStudentLiveReports : backOfficeService.getLiveReports
    const requestRouteParams = studentId ? {id: studentId} : {}

    const dimensions = useContext(DimensionsContext)

    const [student, setStudent] = useState(null)

    // console.log("liveReports", props.liveReports)

    useApiStatus(
        liveReportService.getStudentLiveReports, // api service
        false, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setStudent(successData?.student)
        } // success callback,
    )

    useApiStatus(
        backOfficeService.getLiveReports, // api service
        false, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        } // success callback,
    )

    const columns = [
        {
            name: 'Date du Live',
            style: {
                width: 140,
                marginRight: 20
                // height: 60,
            },
            selector: 'updated_at',
            sortable: true,
            cell: row => {
                const sessionDate = capitalize(moment(row?.statistics?.live_date_time ?? row?.created_at).format("dddd DD MMMM HH:mm"))

                return <TouchableOpacity
                    disabled={!row?.statistics}
                    onPress={() => {
                        navigation?.push("r", {
                            h: row?.hash
                        })
                    }}>
                    <TextM wrap color={!row?.statistics ? theme.colors.grey : theme.colors.primary}
                           fontWeight={!row?.statistics ? "Medium" : "Bold"}>{sessionDate}</TextM>
                </TouchableOpacity>
            }
        },
        {
            name: "Score d'engagement",
            style: {
                width: 700,
                marginRight: 40
                // height: 60,
            },
            selector: 'statistics.scores.total_score',
            sortable: true,
            cell: row => {
                // console.log(row?.statistics?.scores)
                return !row?.statistics ? "-" :
                    <EngagementScoreComponent totalScore={row?.statistics?.scores?.total_score}
                                              scores={row?.statistics?.scores}/>
            }
        },
        {
            name: 'Nombre d’activités',
            style: {
                width: 80,
                marginRight: 40
            },
            selector: 'statistics.activities_count',
            sortable: true,
            cell: row => <TextM wrap
                                style={{}}>{`${row?.statistics?.activities_count ?? '-'}`}</TextM>
        },
        {
            name: 'Commentaire tuteur',
            style: {
                width: 300,
                marginRight: 40
            },
            selector: 'statistics.activities_count',
            sortable: true,
            cell: row => <TextM wrap
                                fontSize={10}
                                style={{}}>{`${row?.live_occurrence?.tutor_comment ?? '-'}`}</TextM>
        },
        {
            name: 'Satisfaction élève',
            style: {
                width: 140,
                marginRight: 40
                // height: 60,
            },
            selector: 'satisfaction',
            sortable: true,
            cell: row => !row?.satisfaction ? "-" : <View style={{
                justifyContent: "flex-start", alignItems: "center", flexDirection: "row"
            }}>
                <View style={{marginLeft: 0, maxWidth: 140}}>
                    <SatisfactionComponent rating={row?.satisfaction}
                                           imageSize={20}
                                           readonly/>
                </View>
            </View>
        }
    ]

    if (!studentId) {

        columns.splice(1, 0, {
            name: 'Élève',
            style: {
                width: 140,
                marginRight: 40
                // height: 60,
            },
            selector: 'student',
            sortable: true,
            cell: row => !row?.student ? "-" :
                <View style={{flexDirection: "row", alignItems: "center", marginRight: 23}}>
                    <AvatarM user={row?.student} size={45}/>
                    <View>
                        <TextM color={theme.colors.primary} fontWeight={"Bold"} fontSize={16} style={{marginLeft: 10}}>
                            {row?.student?.display_name}
                        </TextM>
                        <TextM style={{marginLeft: 10}}>
                            {row?.student?.profile?.class}
                        </TextM>
                    </View>
                </View>
        })

    }

    return <View style={{
        flex: 1
    }}
    >
        <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}} noTopInset>
            <View style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        // position: "absolute",
                        marginRight: 10
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
                <TitleM>Rapports des Lives</TitleM>
            </View>

            {studentId && <View style={{flexDirection: "row", alignItems: "center", marginRight: 23}}>
                <AvatarM user={student} size={45}/>
                <View>
                    <TextM color={theme.colors.primary} fontWeight={"Bold"} fontSize={16} style={{marginLeft: 10}}>
                        {student?.display_name}
                    </TextM>
                    <TextM style={{marginLeft: 10}}>
                        {student?.profile?.class}
                    </TextM>
                </View>
                {student?.prioritized_school_subjects?.length > 0 ?
                    <PrioritizedSchoolSubjects student={student} style={{marginLeft: 12, flexDirection: "column"}}/> :
                    <TextM style={{marginLeft: 12}}>-</TextM>}
            </View>}
        </HeaderContainerM>
        <PaginatedTableM data={studentId ? props.liveReports : props.backOfficeLiveReports}
                         flatListRef={null}
                         columns={columns}
                         headerHeight={60}
                         requestService={requestApiService}
                         routeParams={requestRouteParams}
                         urlParams={{}}
        />
    </View>
}
const mapStateToProps = state => {
    return {
        liveReports: state.data?.liveReports?.liveReports,
        backOfficeLiveReports: state.data?.backOffice?.liveReports,
        users: state.data?.users?.usersList,
        getStudentLiveReportsRequest: state.api.getStudentLiveReports?.request,
        getStudentLiveReportsError: state.api.getStudentLiveReports?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setOngoingActivity,
        setIsLiveModeEnabled
    }

export default connect(mapStateToProps, mapDispatchToProps)(LiveReportScreen)