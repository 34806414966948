import {View} from "react-native";
import {KeyValueItem} from "@common/components/card/KeyValueItem";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import React from "react";

export const ActivityXpKeyValueItem = ({activity, large, style}) => {
    const xpOfflineBonus = activity?.xp_bonuses?.offline_mode ?? 0
    const xpLiveBonus = activity?.xp_bonuses?.live_mode ?? 0
    const xpScoringBonus = activity?.xp_bonuses?.scoring ?? 0
    const xpChronoBonus = activity?.xp_bonuses?.chrono

    // const totalBonus = xpOfflineBonus + xpScoringBonus + xpLiveBonus + xpChronoBonus

    return <View style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        ...style
    }}>
        <KeyValueItem keyText="XP GAGNÉS"
                      valueText={activity?.xp_gained.toString()}
                      large={large}
                      isXP/>
        {xpChronoBonus !== undefined && <View style={{
            flexDirection: "row",
            position: "absolute",
            top: -10,
            right: 0
        }}>
            {<TextM style={{
                paddingVertical: 3,
                paddingHorizontal: 5,
                marginLeft: 4,
                backgroundColor: theme.colors.gemsLight,
                borderWidth: 1,
                borderColor: theme.colors.gems,
                borderRadius: 5
            }}
                                       fontSize={8}
                                       fontWeight="Bold"
                                       color={theme.colors.gems}>BONUS CHRONO +{xpChronoBonus}</TextM>}
            {xpScoringBonus > 0 && <TextM style={{
                paddingVertical: 3,
                paddingHorizontal: 5,
                marginLeft: 4,
                backgroundColor: theme.colors.accentLighter,
                borderWidth: 1,
                borderColor: theme.colors.accentDark,
                borderRadius: 5
            }}
                                          fontSize={8}
                                          fontWeight="Bold"
                                          color={theme.colors.accentDark}>BONUS SCORE +{xpScoringBonus}</TextM>}
            {xpOfflineBonus > 0 && <TextM style={{
                paddingVertical: 3,
                paddingHorizontal: 5,
                marginLeft: 4,
                backgroundColor: theme.colors.purpleLight,
                borderWidth: 1,
                borderColor: theme.colors.purple,
                borderRadius: 5
            }}
                                          fontSize={8}
                                          fontWeight="Bold"
                                          color={theme.colors.purple}>BONUS HORS-LIGNE +{xpOfflineBonus}</TextM>}
            {xpLiveBonus > 0 && <TextM style={{
                paddingVertical: 3,
                paddingHorizontal: 5,
                marginLeft: 4,
                backgroundColor: theme.colors.purpleLight,
                borderWidth: 1,
                borderColor: theme.colors.purple,
                borderRadius: 5
            }}
                                          fontSize={8}
                                          fontWeight="Bold"
                                          color={theme.colors.purple}>BONUS LIVE +{xpLiveBonus}</TextM>}

        </View>}

    </View>;
}