import React, {useContext} from 'react';
import {useController} from "react-hook-form";
import {DimensionsContext} from "../../utils/mobileUtils";
import {DatePickerModal} from "react-native-paper-dates";
import moment from "moment";

const DatePickerModalM = React.forwardRef((props, ref) => {

        const {
            label,
            control,
            name,
            cancelLabel,
            style,
            saveLabel,
            closeIcon,
            editIcon,
            onChangeAdditional = () => {
            },
            contentContainerStyle,
            allowEditing = false,
            calendarIcon,
            locale,
            mode,
            visible,
            onDismiss = () => {
            },
        } = props

        const {field} = useController({
            control,
            name
        })

        const dimensions = useContext(DimensionsContext)
        return <DatePickerModal
            label={label}
            cancelLabel={cancelLabel}
            saveLabel={saveLabel}
            closeIcon={closeIcon}
            editIcon={editIcon}
            allowEditing={allowEditing}
            calendarIcon={calendarIcon}
            locale={locale}
            mode={mode}
            visible={visible}
            onDismiss={onDismiss}
            date={new Date(field?.value)}
            onChange={(e) => {
                const dd = new moment(e.date).format("YYYY-MM-DD")
                field.onChange(dd)
                onChangeAdditional(dd)
            }}
        />
    }
)

export default DatePickerModalM