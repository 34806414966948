import React, { useContext } from "react";
import { DimensionsContext, IS_WEB } from "@common/utils/mobileUtils";
import { ModalM } from "@common/components/modal/ModalM";
import TitleM from "@common/components/text/TitleM";
import { theme } from "@common/theme/theme";
import { useController, useForm } from "react-hook-form";
import TextInputM from "../../../common/components/input/TextInputM";
import { yupResolver } from "@hookform/resolvers/yup";
import yup from "../../../data/utility/validation";
import FilledButtonM from "../../../common/components/button/FilledButtonM";
import { backOfficeService } from "../../../data/services/backOfficeService";
import { useApiStatus } from "../../../common/hooks/useApiStatus";
import { ListItemM } from "../../../common/components/list/ListItemM";
import { ListGroupM } from "../../../common/components/list/ListGroupM";
import { View } from "react-native";

const formSchema = yup.object().shape({
  transfer_amount: yup.number().required(),
  tutoring_credit_amount: yup.number().required(),
  application_fee_amount: yup.number().required(),
  application_compensation_amount: yup.number().required(),
});

export default function ChangeStatusModal(props) {
  const { isOpen, handleShowModal, data, isLoading, apiRequest } = props;
  const dimensions = useContext(DimensionsContext);
  const resolver = yupResolver(formSchema);

  const items = [
    {
      value: "pending_review",
      description: "En attente",
    },
    {
      value: "validated",
      description: "Accepté",
    },
    {
      value: "canceled",
      description: "Annulé",
    },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    resolver,
    defaultValues: {
      status: data?.status,
    },
  });

  const { field } = useController({
    control,
    name: "status",
  });

  const onSubmit = (status) => {
    apiRequest(backOfficeService.editBilling, { id: data.id }, status);
  };

  //   useApiStatus(
  //     backOfficeService.editBilling, // api service
  //     null, // success message on toast (string or null)
  //     true, // error message from server in toast (true) or keep it in redux state (false)
  //     (successData) => {
  //     }
  //   );
  return (
    <ModalM
      visible={isOpen}
      onDismiss={handleShowModal}
      style={{
        // width: IS_WEB && dimensions.width > 800 ? "50%" : "90%",
        maxHeight: "80%",
        padding: 20,
        alignItems: "center",
      }}
    >
      <TitleM>Status</TitleM>
      <View
        style={{
          marginBottom: 10,
          marginTop: 20,
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <ListGroupM
          options={items}
          style={{
            // marginHorizontal: 20
            marginBottom: 70,
          }}
          itemHeight={70}
          renderItem={(item) => {
            return (
              <ListItemM
                isFirst={item.index === 0}
                isLast={item.index === items?.length - 1}
                onPress={() => {
                  field.onChange(item.item?.value);
                  onSubmit({ status: item.item?.value });
                  handleShowModal();
                }}
                itemHeight={70}
                selected={item.item?.value === field.value}
                option={item.item}
              />
            );
          }}
        />
      </View>
    </ModalM>
  );
}
