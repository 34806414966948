import axios from "axios";

function getLiveReport({ hash }) {
  return axios.get(`api/v1/live-reports/${hash}`);
}

function getStudentLiveReports({ id }, {}, params) {
  return axios.get(`api/v1/students/${id}/live-reports`, { params });
}

function sendStudentComment({ id }, data) {
  return axios.post(
    `api/v1/live-reports/${id}/student-comment?_method=PUT`,
    data
  );
}

function deleteCommentRecord({ id }, data) {
  return axios.post(`api/v1/live-reports/${id}/delete-comment-record`);
}

export const liveReportService = {
  getLiveReport,
  getStudentLiveReports,
  sendStudentComment,
  deleteCommentRecord,
};
