import React, {useContext, useEffect, useState} from 'react';
import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {DimensionsContext} from "@common/utils/mobileUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ModalM} from "@common/components/modal/ModalM";
import StudyProgramItemsList from "@common/studyPrograms/StudyProgramItemsList";
import SelectChapterComponent from "@common/studyPrograms/SelectChapterComponent";
import TextM from "@common/components/text/TextM";
import moment from "moment";
import DateTimePicker from "../../admin/DateTimePicker";
import {useController, useForm} from "react-hook-form";
import {connect} from "react-redux";
import {theme} from "../theme/theme";

const StudyProgramItemsStep = (props) => {
    const {control, goToNextStep, register, errors, serverError, setFormValues, field, formValues, studyProgram} = props

    const dimensions = useContext(DimensionsContext)

    const now = moment().format("YYYY-MM-DD")

    const studyProgramItems = control._formValues.study_program_items ?? []
    const studyProgramChapters = studyProgram?.study_program_items?.map(x => x?.chapter) ?? []

    const [addStudyProgramItemModalVisible, setAddStudyProgramItemModalVisible] = useState(false);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const [selectedChapters, setSelectedChapters] = useState(studyProgramChapters);
    const {control: datesControl} = useForm({
        defaultValues: {
            start_date: now,
            end_date: now
        }
    })

    const {field: startDateField} = useController({
        name: "start_date",
        control
    })

    const {field: endDateField} = useController({
        name: "end_date",
        control
    })
    const startDate = startDateField.value
    const endDate = endDateField.value
    // const forNewStudyProgram = studyProgram === null

    useEffect(() => {
        if (selectedChapter !== null) {
            setSelectedChapters([...selectedChapters, selectedChapter])
        }
    }, [selectedChapter])

    useEffect(() => {
        console.log("startDate change", startDate)
        endDateField.onChange(startDate)
    }, [startDate])

    useEffect(() => {
        if (startDate !== null && endDate !== null && selectedChapter !== null && !addStudyProgramItemModalVisible) {
            field.onChange([...control._formValues.study_program_items, {
                chapter_id: selectedChapter.id,
                start_date: startDate,
                end_date: endDate
            }])
            setFormValues({
                ...formValues,
                study_program_items: [
                    ...formValues.study_program_items,
                    {
                        chapter_id: selectedChapter.id,
                        start_date: startDate,
                        end_date: endDate
                    }
                ]
            })
        }
    }, [startDate, endDate, selectedChapter, addStudyProgramItemModalVisible]);

    useEffect(() => {
        if (!addStudyProgramItemModalVisible && selectedChapter?.id !== null) {
            setSelectedChapter(null)
            startDateField?.onChange(now)
            endDateField?.onChange(now)
        }
    }, [addStudyProgramItemModalVisible, selectedChapter]);

    const removeStudyProgramItem = (chapterId) => {
        const newItems = studyProgramItems.filter(x => x.chapter_id !== chapterId)
        field.onChange(newItems)
        setFormValues({
            ...formValues,
            study_program_items: newItems
        })
        setSelectedChapters(selectedChapters.filter(x => x.id !== chapterId))
    }

    // useEffect(() => {
    //     if (formValues?.class !== studyProgram?.class || formValues?.school_subject_id !== studyProgram?.school_subject?.id || forNewStudyProgram) {
    //         console.log("resetting study program items")
    //         field.onChange([])
    //         setFormValues({
    //             ...formValues,
    //             study_program_items: []
    //         })
    //     } else {
    //         field.onChange(studyProgram?.study_program_items?.map((item) => {
    //             return {
    //                 chapter_id: item?.chapter?.id,
    //                 start_date: item?.start_date,
    //                 end_date: item?.end_date
    //             }
    //         }))
    //         setFormValues({
    //             ...formValues,
    //             study_program_items: studyProgram?.study_program_items?.map((item) => {
    //                 return {
    //                     chapter_id: item?.chapter?.id,
    //                     start_date: item?.start_date,
    //                     end_date: item?.end_date
    //                 }
    //             })}
    //         )}
    // }, [formValues.school_subject_id, formValues.class]);

    // const navigation = useNavigation()

    return (
        <View style={{
            // marginTop: 20,
            width: "100%"
        }}>
            <StudyProgramItemsList
                studyProgramItems={studyProgramItems}
                studyProgramChapters={studyProgramChapters}
                headerComponent={<FilledButtonM
                    label={"Ajouter un chapitre"}
                    icon={"plus"}
                    style={{width: null, margin: 20}}
                    labelStyle={{fontSize: 12}}
                    onPress={() => {
                        setAddStudyProgramItemModalVisible(true)
                    }}/>}
                selectedChapters={selectedChapters}
                removeStudyProgramItem={removeStudyProgramItem}
            />
            {formValues.study_program_items?.length > 0 && <FilledButtonM
                label={"Suivant"}
                onPress={goToNextStep}
                // style={{marginTop: 20}}
            />}
            <ModalM visible={addStudyProgramItemModalVisible}
                    onDismiss={() => setAddStudyProgramItemModalVisible(false)}
            >
                <SelectChapterComponent {...props}
                                        selectedChapter={selectedChapter}
                                        setSelectedChapter={setSelectedChapter}/>
                <View style={{
                    backgroundColor: theme.colors.primaryLighter,
                }}>
                    <View style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingHorizontal: 20,
                        paddingBottom: 10,
                        borderRadiusBottomLeft: 15,
                        borderRadiusBottomRight: 15
                    }}>
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <TextM style={{marginRight: 5}}
                                   fontSize={16}
                                   fontWeight={"Bold"}>Du</TextM>
                            <DateTimePicker
                                type={"date"}
                                control={datesControl}
                                field={startDateField}
                                setFormValues={() => {
                                }}
                            />
                        </View>
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <TextM style={{marginRight: 5}}
                                   fontSize={16}
                                   fontWeight={"Bold"}>Au</TextM>
                            <DateTimePicker
                                type={"date"}
                                control={datesControl}
                                field={endDateField}
                                setFormValues={() => {
                                }}
                            />
                        </View>
                        <FilledButtonM
                            label={"Ajouter"}
                            disabled={!selectedChapter || !startDate || !endDate}
                            onPress={() => {
                                setAddStudyProgramItemModalVisible(false)
                            }}
                            style={{marginBottom: 0, marginTop: 10}}
                        />
                    </View>

                </View>


            </ModalM>

        </View>
    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        feelingQuestions: state.data.staticData?.feeling_questions,
        feelingQuestionsRequest: state.api.feelingQuestionsRequest,
        studentChapterFeelingsRequest: state.api.studentChapterFeelings?.request,
        feelingQuestionsError: state.api.feelingQuestionsError
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyProgramItemsStep)