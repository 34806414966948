import React, {useContext} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ModalM} from "@common/components/modal/ModalM";
import {ScrollView, View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {CardButtonM} from "../../../tutor/home/interview/TutorInterviewCard";
import {IconImageM} from "@common/components/icons/IconsM";
import live from "../../../../assets/icons/generic/live.png";
import AvatarM from "@common/components/avatar/AvatarM";
import moment from "moment";
import {capitalize} from "@data/utility/Utils";
import {ActivityIndicator, IconButton} from "react-native-paper";

export default function RoomSessionsModal({
                                              isOpen,
                                              sessions,
                                              handleShowModal,
                                              navigation,
                                              room_id,
                                              onDelete,
                                              deleteRequest
                                          }) {
    const dimensions = useContext(DimensionsContext)

    return (
        <ModalM visible={isOpen} onDismiss={handleShowModal}
                style={{width: IS_WEB && dimensions.width > 800 ? "50%" : "90%", maxHeight: "80%"}}>
            <ScrollView style={{
                marginTop: 10,
                backgroundColor: theme.colors.white,
                paddingHorizontal: 20,
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10
            }}
                        contentContainerStyle={{
                            flexDirection: IS_WEB && dimensions.width > 800 ? "row" : "column",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            paddingBottom: 30
                        }}>
                <View style={{
                    marginBottom: 10,
                    marginTop: 20,
                    width: "100%",
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}>
                    <TextM fontWeight={"Bold"} fontSize={20}>Sessions live</TextM>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <IconButton style={{
                            // marginLeft: 5
                        }} iconColor={theme.colors.primary} icon={"plus"} size={15} onPress={() => {
                            navigation.push("new-sessions", {
                                room_id: room_id
                            })
                            handleShowModal()
                        }}/>
                        <IconButton style={{
                            // marginLeft: 5
                        }} iconColor={theme.colors.primary} icon={"pen"} size={15} onPress={() => {
                            navigation.push("new-room", {
                                room_id: room_id
                            })
                            handleShowModal()
                        }}/>
                        <IconButton style={{
                            // marginLeft: 5
                        }} iconColor={theme.colors.primary} icon={"user-plus"} size={15} onPress={() => {
                            navigation.push("add-members-to-sessions", {
                                room_id: room_id
                            })
                            handleShowModal()
                        }}/>
                        {deleteRequest ? <ActivityIndicator color={theme.colors.primary}/> :
                            <IconButton iconColor={theme.colors.primary} icon={"trash"} size={15} onPress={() => {
                                onDelete(room_id)
                            }}/>}
                    </View>
                </View>

                {sessions?.map((item, key) => {
                    //console.log(item)
                    // const slotMoment = moment(item?.next_occurrences[0]?.date)
                    const slotMoment = item?.day_time ? moment(item?.day_time, "dddd HH:mm", "en") : null
                    const slotMomentFormatted = slotMoment ? capitalize(slotMoment?.locale("fr")?.format("dddd HH:mm")) : null
                    return (
                        <CardButtonM key={key}
                                     onPress={() => {
                                         navigation.push("live-show", {
                                             live_id: item?.id,
                                             room_id: room_id
                                         })
                                         handleShowModal()
                                     }}
                                     membersCount={item?.students_count}
                                     icon={<IconImageM source={live}
                                                       width={30}
                                                       style={{
                                                           // tintColor: theme.colors.primary,
                                                       }}
                                     />}
                                     title={slotMomentFormatted}
                                     style={{
                                         marginBottom: 15,
                                         width: "100%",
                                         backgroundColor: theme.colors.white
                                     }}
                                     width={"100%"}
                                     description={
                                         <View style={{}}>
                                             <TextM
                                                 wrap
                                                 style={{
                                                     marginLeft: 5,
                                                     marginBottom: 10,
                                                 }}>
                                                 {item?.students_list_string}
                                             </TextM>
                                             <View
                                                 style={{
                                                     flexDirection: 'row',
                                                     alignItems: 'center',
                                                 }}>
                                                 <AvatarM
                                                     user={item?.tutor}/>
                                                 <TextM
                                                     style={{marginLeft: 5}}
                                                     color={theme.colors.primary}>{item?.tutor?.display_name}  </TextM>
                                             </View>
                                         </View>
                                     }/>
                    )
                })}
            </ScrollView>
        </ModalM>
    )
}