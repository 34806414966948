import React, {useContext, useEffect} from 'react';
import {Platform, RefreshControl, ScrollView, TouchableOpacity, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {skillSetsService} from "@data/services/skillSetsService";
import {useNavigation} from "@react-navigation/native";
import SkillItem from "./SkillItem";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TitleM from "@common/components/text/TitleM";
import {useApiStatus} from "@common/hooks/useApiStatus";

const MySkillSetsTabScreen = (props) => {


    useEffect(() => {
        props.apiRequest(skillSetsService.getSkillSets)
    }, [])

    useApiStatus(
        skillSetsService.getSkillSets, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        } // success callback
    )

    const navigation = useNavigation()
    const skillSetsList = props.user?.skill_sets

    const skills = props.skills
    const dimensions = useContext(DimensionsContext)


    return <View style={{
        backgroundColor: theme.colors.whiteAlmost,
        flex: 1,
    }}>
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            <ScrollView contentContainerStyle={{
                paddingHorizontal: 10,
                paddingTop: 10,
            }}
                        showsHorizontalScrollIndicator={false}
                        refreshControl={<RefreshControl
                            refreshing={!!props.getSkillSetsRequest}
                            onRefresh={() => props.apiRequest(skillSetsService.getSkillSets)}
                        />}>
                <TitleM fontWeight="ExtraBold" style={{textAlign: "center", marginBottom: 5}}>MES QUÊTES</TitleM>


                {skills && skillSetsList ? skillSetsList?.map((s, index) => {

                    return (
                        <View key={index} style={{
                            flex: 1,
                            borderWidth: 1,
                            borderRadius: 15,
                            backgroundColor: theme.colors.white,
                            borderColor: theme.colors.lightMore,
                            marginTop: 20,
                        }}>
                            <TouchableOpacity style={{
                                marginTop: 10,
                                width: IS_WEB ? dimensions?.width / 1.2 : null
                            }}
                                              onPress={() => {
                                                  navigation.push("skill-set", {skillSetId: s?.id})
                                              }}>
                                <SkillItem stepNum={s?.current_step}
                                           skillSet={s}
                                           withAnimation={false}
                                           active={true}
                                           width={90}
                                />
                            </TouchableOpacity>
                        </View>
                    )
                }) : ''}

                <View style={{
                    height: 100,
                }}/>

            </ScrollView>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

        </View>
    </View>
};

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getSkillSetsRequest: state.api.getSkillSets?.request,
        getSkillSetsSuccess: state.api.getSkillSets?.success,
        getSkillSetsError: state.api.getSkillSets?.error,
        successes: state.data.staticData?.successes,
        skill_sets: state.data.staticData?.skill_sets,
        skills: state.data.staticData?.skills
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(MySkillSetsTabScreen)