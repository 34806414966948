import React, {useContext} from 'react';
import {ActivityIndicator, Appbar} from "react-native-paper";
import {theme} from "../theme/theme";
import {Dimensions, Platform, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {AbilityContext} from "@data/utility/Can";
import {StatusBar} from "expo-status-bar";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import StudentMainMenuHeader from "./StudentMainMenuHeader";
import {hasRole} from "@data/utility/ability";
import TutorMainMenuHeader from "./TutorMainMenuHeader";
import LiveModeHeader from "./LiveModeHeader";

const Header = (props) => {

    const {navigation, route, options, back} = props

    const ability = useContext(AbilityContext)


    // const {options} = scene.descriptor;
    const title =
        options.headerTitle !== undefined
            ? options.headerTitle
            : options.title !== undefined
                ? options.title
                : route.name;


    const insets = useSafeAreaInsets();

    const isStudent = hasRole(props.user, 'student')
    const goToAccount = () => {
        navigation.push("settings", {initialTab: 0});
    }
    const liveSession = props.currentLiveSession

    return (<>
            <View style={{
                width: Platform.OS === 'web' ? Dimensions.get('window').width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center",
                backgroundColor: theme.colors.white
                // borderBottomWidth: 1,
                // borderColor: theme.colors.lightMore
            }}>

                {!props.isLiveMode ? route.name === "menu" || route.name === "not-found" || options.headerTitle === "Non autorisé" ? (
                    <>
                        <Appbar.Header theme={{colors: {primary: theme.colors.white}}}
                                       style={{
                                           padding: 0,
                                           backgroundColor: theme.colors.white
                                           // borderBottomWidth: 1,
                                           // borderColor: theme.colors.lightMore
                                       }}
                                       statusBarHeight={Platform.OS === "ios" ? StatusBar.height : 0}
                        >
                            {isStudent ? <StudentMainMenuHeader/> : <TutorMainMenuHeader/>}
                            {(props.getStaticDataRequest || props.subscribeToNotificationsRequest) &&
                                <ActivityIndicator animating={true}
                                                   color={theme.colors.primary}
                                                   size={14}
                                                   style={{
                                                       position: "absolute",
                                                       right: 10
                                                   }}/>}
                        </Appbar.Header>
                    </>
                ) : <Appbar.Header
                    theme={{colors: {primary: theme.colors.white}}}
                    style={{
                        padding: 0,
                        backgroundColor: theme.colors.white
                        // borderBottomWidth: 1,
                        // borderColor: theme.colors.lightMore
                    }}
                    statusBarHeight={Platform.OS === "ios" ? insets.top : 0}
                >

                    {back && Platform.OS !== "web" && (

                        <Appbar.BackAction
                            onPress={navigation.goBack}
                            color={theme.colors.primary}
                        />)}

                    <Appbar.Content
                        title={title}
                        color={theme.colors.primary}
                        titleStyle={{
                            fontFamily: "Montserrat-Bold"
                        }}
                    />
                    {route.name === "account" &&
                        <Appbar.Action icon={"cog"} color={theme.colors.primary} onPress={goToAccount}/>}
                    {route.name === "settings" &&
                        <Appbar.Action icon={"save"} color={theme.colors.primary} onPress={goToAccount}/>}
                </Appbar.Header> : <Appbar.Header
                    theme={{colors: {primary: theme.colors.white}}}
                    style={{
                        padding: 0,
                        backgroundColor: theme.colors.white
                        // borderBottomWidth: 1,
                        // borderColor: theme.colors.lightMore
                    }}
                    statusBarHeight={Platform.OS === "ios" ? insets.top : 0}
                >
                    <LiveModeHeader/>
                </Appbar.Header>}
            </View>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        successes: state.data.staticData?.successes,
        getStaticDataRequest: state.api.getStaticData?.request,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        subscribeToNotificationsRequest: state.api.subscribeToNotifications?.request,
        isLiveMode: state.liveMode?.isLiveModeEnabled,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        liveSessionId: state.liveMode?.liveSessionId,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)