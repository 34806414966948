import React, {useContext, useEffect} from 'react';
import {Image, StatusBar, TouchableOpacity, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect} from "react-redux";
import Onboarding from "react-native-onboarding-swiper";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import appScreen from "../../../assets/screens/app_screen.png";
import chronoScreen from "../../../assets/screens/chrono_screen.png";
import coachScreen from "../../../assets/screens/coach_screen.png";
import liveScreen from "../../../assets/screens/live_screen_.png";
import gesturesScreen from "../../../assets/screens/gestures_screen.png";
import scoringScreen from "../../../assets/screens/scoring_screen.png";
import successesScreen from "../../../assets/screens/quests_screen.png";
import TextM from "@common/components/text/TextM";
import {SubscriptionPrivilegeChip} from "./SubscriptionPrivilegeChip";
import * as amplitude from "@amplitude/analytics-react-native";

const DoneLabelButton = (props) => {
    const {isLight} = props

    return <OnboardingLabelButton isLight={isLight} label="Terminé" {...props} />
}

const NextLabelButton = (props) => {

    const {isLight, nextLabel = "Suivant"} = props

    return <OnboardingLabelButton isLight={isLight} label={nextLabel} {...props} />
}

const SkipLabelButton = (props) => {

    const {isLight, skipLabel = "Passer"} = props

    return <OnboardingLabelButton isLight={isLight} label={skipLabel} {...props}/>
}

export const OnboardingLabelButton = (props) => {

    const {isLight, label} = props

    return <TouchableOpacity style={{
        marginHorizontal: 15,
        padding: 5
    }}
                             {...props}>
        <TextM color={isLight ? theme.colors.grey : theme.colors.white}>{label}</TextM>
    </TouchableOpacity>
}

export function Slide({title, image, product: product}) {
    const dimensions = useContext(DimensionsContext)

    const width = IS_WEB || dimensions?.width > 800 ? 320 : dimensions?.width - 80
    const height = width * 878 / 440
    const croppedHeight = IS_WEB || dimensions?.width > 800 ? height : height * 0.7

    return <View style={{width: dimensions?.width}}>
        <View>
            <TextM color={theme.colors.primary}
                   fontWeight={"Black"}
                   fontSize={22}
                   style={{
                       textAlign: 'center',
                       alignSelf: 'center',
                       marginHorizontal: 15,
                       maxWidth: width + 50
                   }}
                   wrap
            >{title}</TextM>
            <View
                style={{
                    overflow: 'hidden',
                    width,
                    height: croppedHeight,
                    backgroundColor: 'transparent',
                    marginTop: 20,
                    alignSelf: 'center'

                }}
            >
                <Image
                    style={{
                        position: 'absolute',
                        bottom: -(IS_WEB || dimensions?.width > 800 ? 0 : height * 0.3),
                        left: 0,
                        width,
                        height
                    }}
                    resizeMode="cover"
                    source={image}
                />
            </View>
            {/*<Image source={image}*/}
            {/*       style={{*/}
            {/*    // marginBottom: 20,*/}
            {/*    marginBottom: 30,*/}
            {/*    // marginRight: 20,*/}

            {/*    alignSelf: 'center'*/}
            {/*}}/>*/}
            {product && <SubscriptionPrivilegeChip product={product} style={{
                position: "absolute",
                top: 15,
                right: 25 - (product.length * 2)
            }}
            />}
        </View>
    </View>;
}

const IntroScreen = (props) => {

    // ** Component props
    const {navigation} = props

    const goToNextScreen = () => {
        navigation.replace("menu");
    }
    const dimensions = useContext(DimensionsContext)

    return (
        <>
            <Onboarding
                titleStyles={{
                    color: theme.colors.primary,
                    fontFamily: "Montserrat-Black",
                    fontSize: 24
                }}
                subTitleStyles={{
                    color: theme.colors.grey,
                    fontFamily: "Montserrat-Medium",
                    fontSize: 16
                }}
                containerStyles={{
                    width: dimensions?.width,
                    marginTop: 30
                }}
                imageContainerStyles={{
                    position: "absolute",
                    bottom: -100
                }}
                transitionAnimationDuration={100}
                onSkip={goToNextScreen}
                onDone={goToNextScreen}
                nextLabel="Suivant"
                skipLabel="Passer"
                SkipButtonComponent={SkipLabelButton}
                NextButtonComponent={NextLabelButton}
                DoneButtonComponent={DoneLabelButton}
                pages={[
                    {
                        backgroundColor: theme.colors.accentDark,
                        image: <Slide title="L'appli idéale pour booster ton travail en autonomie !"
                                      image={appScreen}/>,
                        title: "",
                        subtitle: ""
                    },
                    {
                        backgroundColor: theme.colors.link,
                        image: <Slide title="Ton tuteur personnel t'aide à organiser ton travail et à te motiver"
                            // product={"Premium"}
                                      image={tuteurScreen}/>,
                        title: "",
                        subtitle: ""
                    },
                    {
                        backgroundColor: theme.colors.accentDark,
                        image: <Slide
                            title="Étudie avec ton tuteur dans les meilleures conditions pendant les sessions LIVE"
                            // product={"Premium"}
                            image={liveScreen}/>,
                        title: "",
                        subtitle: ""
                    },
                    {
                        backgroundColor: theme.colors.link,
                        image: <Slide title="Réalise ton travail personnel en suivant des méthodes efficaces"
                                      image={chronoScreen}/>,
                        title: "",
                        subtitle: ""
                    },
                    {
                        backgroundColor: theme.colors.accentDark,
                        image: <Slide title="Obtiens des conseils personnalisés pour chaque travail enregistré"
                            // product={"Plus"}
                                      image={scoringScreen}/>,
                        title: "",
                        subtitle: ""
                    },
                    {
                        backgroundColor: theme.colors.link,
                        image: <Slide title="Réalise des quêtes et rivalise avec tes amis"
                                      image={successesScreen}/>,
                        title: "",
                        subtitle: ""
                    },
                    {
                        backgroundColor: theme.colors.link,
                        image: <Slide title="Tes efforts permettent de financer des gestes éco-solidaires !"
                                      image={gesturesScreen}/>,
                        title: "",
                        subtitle: ""
                    }


                ]}
            />


            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(IntroScreen)