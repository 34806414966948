import React, {useContext, useEffect, useRef, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {clearLastPhoto} from "@data/redux/actions/camera";
import {connect} from "react-redux";
import {Dimensions, FlatList, View} from "react-native";
import {DimensionsContext, IS_WEB} from "../../utils/mobileUtils";
import {theme} from "../../theme/theme";
import {PinchableBox} from "./PinchableBox";
import {IconButton} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import {displayTimeSeconds} from "@data/utility/Utils";
import {TapGestureHandler} from "react-native-gesture-handler";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import IconButtonM from "@common/components/button/IconButtonM";

function ImageViewer(props) {

    const {images, imageIndex = 0, close, withChrono, time, useFullWidthToScroll = false} = props
    const flatListRef = useRef()
    const [currentIndex, setCurrentIndex] = useState(imageIndex)

    // const zoomRef = useRef()

    const zoomRefs = images?.map((i, index) => useRef())


    const dimensions = useContext(DimensionsContext)
    const insets = useSafeAreaInsets()

    useEffect(() => {

        // console.log("imageIndex", imageIndex)

        if (imageIndex !== undefined) {
            flatListRef?.current?.scrollToIndex({index: imageIndex})
            setCurrentIndex(imageIndex)
        }
    }, [imageIndex]);

    let mWidth = (dimensions?.width / 2 - 60)

    let adaptedWidth = mWidth < 140 ? mWidth : 140

    return <View style={{flex: 1}}>
        <View style={{
            // flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            paddingBottom: 5,
            // paddingTop: 5 + insets.top,
            width: Dimensions.get("window").width
        }}>
            {withChrono && <TapGestureHandler
                // onHandlerStateChange={onMultiTap}
                numberOfTaps={30}>
                <View style={{
                    width: 2 * adaptedWidth,
                    //height:  adaptedWidth,
                    borderColor: theme.colors.primary,
                    borderRadius: adaptedWidth,
                    alignItems: 'center',
                    justifyContent: "center",
                    alignSelf: "center",
                    position: "absolute",
                    // right: 5,
                    top: 5 + insets.top,
                }}>
                    <TextM fontWeight="Medium"
                           fontSize={40}
                           color={theme.colors.white}
                    >{displayTimeSeconds(time)}</TextM>
                </View>
            </TapGestureHandler>}
            <IconButton
                icon={"times"}
                iconColor={theme.colors.white}
                size={32}
                onPress={close}

            />
        </View>

        <FlatList
            ref={flatListRef}
            data={images}
            pinchGestureEnabled={true}
            style={{
                flex: 1
            }}
            pagingEnabled={!IS_WEB}
            showsHorizontalScrollIndicator={false}
            initialScrollIndex={currentIndex}
            horizontal
            getItemLayout={(data, index) => ({
                length: useFullWidthToScroll ? Dimensions.get("window").width : dimensions?.height,
                offset: useFullWidthToScroll ? Dimensions.get("window").width * index : dimensions?.height * index,
                index,
            })}
            renderItem={(item, index) => {
                return <PinchableBox imageUri={item?.item}
                                     imageStyle={{
                                         // flex:1,
                                         width: Dimensions.get("window").width,
                                         height: dimensions?.height,
                                     }}
                                     ref={zoomRefs[item?.index]}/>

            }}
        />
        <View style={{flexDirection: "row", alignItems: 'center', justifyContent: 'center'}}>
            <IconButtonM
                size={40}
                icon={"angle-left"}
                disabled={currentIndex === 0}
                iconColor={theme.colors.white}
                onPress={() => {
                    if (currentIndex > 0) {
                        flatListRef?.current?.scrollToIndex({index: currentIndex - 1})
                        setCurrentIndex(currentIndex - 1)
                    }
                }}
            />
            {<IconButtonM
                size={30}
                icon={"minus-circle"}
                iconColor={theme.colors.white}
                onPress={() => {
                    zoomRefs[currentIndex].current?.zoomOut()
                }}
            />}
            <IconButtonM
                size={30}
                icon={"redo"}
                iconColor={theme.colors.white}
                onPress={() => {
                    zoomRefs[currentIndex].current?.rotate()
                }}
            />
            {<IconButtonM
                size={30}
                icon={"plus-circle"}
                iconColor={theme.colors.white}
                onPress={() => {
                    zoomRefs[currentIndex].current?.zoomIn()
                }}
            />}
            <IconButtonM
                size={40}
                icon={"angle-right"}
                disabled={currentIndex === images?.length - 1}
                iconColor={theme.colors.white}
                onPress={() => {
                    if (currentIndex < images?.length - 1) {
                        flatListRef?.current?.scrollToIndex({index: currentIndex + 1})
                        setCurrentIndex(currentIndex + 1)
                    }
                }}
            />

        </View>
    </View>;
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        lastPhoto: state.camera?.lastPhoto,
        lastPhotoFormId: state.camera?.formId
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        clearLastPhoto
    }

export default connect(mapStateToProps, mapDispatchToProps)(ImageViewer)