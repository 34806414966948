import axios from "axios"

function getStudentInterviewReports({id}) {
    return axios.get(`api/v1/students/${id}/interview-reports`)
}

function getInterviewReport({hash}) {
    return axios.get(`api/v1/interview-reports/${hash}`)
}

function newInterviewReport({studentId}, formData) {
    return axios.post(`api/v1/students/${studentId}/interview-reports`, formData)
}

function getPrioritizedSchoolSubjects({studentId}) {
    return axios.get(`api/v1/students/${studentId}/prioritized-school-subjects`)
}

function getInterviewStatistics({studentId}, {}, params) {
    return axios.get(`api/v1/students/${studentId}/interview-stats`, {params})
}

function sendParentSatisfaction({id}, formData) {
    return axios.post(`api/v1/interview-reports/${id}/parent-satisfaction`, formData)
}

function addPrioritizedSchoolSubjects({studentId}, formData) {
    return axios.post(`api/v1/students/${studentId}/add-prioritized`, formData)
}

function removePrioritizedSchoolSubject({studentId}, formData) {
    return axios.post(`api/v1/students/${studentId}/remove-prioritized`, formData)
}

function getDefaultStartDate({studentId}) {
    return axios.get(`api/v1/students/${studentId}/default-start-date`)
}

export const interviewReportService = {
    getStudentInterviewReports,
    getInterviewReport,
    getPrioritizedSchoolSubjects,
    getInterviewStatistics,
    newInterviewReport,
    sendParentSatisfaction,
    addPrioritizedSchoolSubjects,
    removePrioritizedSchoolSubject,
    getDefaultStartDate
}

