import React, {useContext, useEffect, useState} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ModalM} from "@common/components/modal/ModalM";
import {ScrollView, View} from "react-native";
import {theme} from "@common/theme/theme";
import FilledButtonM from "@common/components/button/FilledButtonM";
import TextM from "@common/components/text/TextM";
import MaterialModal from "../execute/MaterialDisplayModal";
import {IconImageM} from "@common/components/icons/IconsM";
import {capitalize, formatRelativeDateTime} from "@data/utility/Utils";
import {isActivityTooOld, TodoActivityBadges} from "../../organize/list/TodoActivityBadges";
import {IconTouchableRipple} from "../../../tutor/students/IconTouchableRipple";
import {activitySlugsMaterialSlugs} from "./ActivityPicker";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {AbilityContext} from "@data/utility/Can";
import {ActivityInfosIcons} from "../../organize/list/RealizationsListItemContent";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {activitiesService} from "@data/services/activitiesService";
import {ActivityIndicator} from "react-native-paper";
import {DoneChip} from "../read/DoneChip";
import {StepLogs} from "../read/StepLogs";
import {hasRole} from "@data/utility/ability";
import {SnackBarM} from "@common/components/alert/snackbar/SnackBar";
import useLaunchActivity from "@common/hooks/useLaunchActivity";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {useNewTodo} from "@common/messenger/socketHook.web";

export const canEditActivity = (activity, ability) =>
    ability?.can("assign", "activities") ||
    activity?.created_by?.id === activity?.student_id;
const TodoModal = (props) => {
    const {
        isOpen,
        handleShowModal,
        item,
        navigation,
        onDeleteTodo,
        studentId = null,
        schoolSubject
    } = props;
    const dimensions = useContext(DimensionsContext);

    const [isMaterialOpen, setMaterialOpen] = useState(false);
    const handleShowMaterialModal = () => setMaterialOpen(!isMaterialOpen);

    const ability = useContext(AbilityContext);

    const canEdit = canEditActivity(item, ability);

    const [pendingAction, setPendingAction] = useState(null);

    const onDeletePress = () => {
        if (!canEdit) {
            handleShowModal();
            DialogM.show({
                text1: "Non autorisé",
                text2:
                    "L'activité a été créée par ton tuteur, tu ne peux pas la supprimer."
            });
            return;
        }
        setPendingAction("delete");
        props.apiRequest(activitiesService.getActivity, {id: item.id});
    };
    const onUpdatePress = () => {
        if (!canEdit) {
            handleShowModal();
            DialogM.show({
                text1: "Non autorisé",
                text2:
                    "L'activité a été créée par ton tuteur, tu ne peux pas la modifier."
            });
            return;
        }
        setPendingAction("update");
        props.apiRequest(activitiesService.getActivity, {id: item.id});
    };
    const refresh = () => {
        props.apiRequest(activitiesService.getActivities, {}, {}, {
            with_logs: 1,
            activity_ids: item?.id
        })
    }
    useEffect(() => {

        if (!!item?.started_at && item?.media_count !== item?.activity?.activity_logs?.filter(al => al?.action === "step_media_sent")?.length)
            refresh()

    }, [item?.media_count]);

    const onStartPress = () => {
        setPendingAction("start");
        props.apiRequest(
            activitiesService.lockActivityV2,
            {id: item?.id},
            {
                lock: true,
                live_mode: !!props.liveSessionId,
                live_session_id: props.liveSessionId ?? undefined
            }
        );
    };

    useApiStatus(
        activitiesService.getActivity, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            handleShowModal();
            if (data?.locked_at && data?.student_id !== props.user?.id) {
                DialogM.show({
                    text1: "Impossible",
                    text2:
                        "L'élève a actuellement ouvert cette activité."
                });
            } else {
                if (pendingAction === "delete") {
                    onDeleteTodo(item?.id);
                } else if (pendingAction === "update") {
                    navigation?.push("new-todo", {
                        todoId: item?.id,
                        studentId: studentId
                    });
                }
            }
            setPendingAction(null);
        },
        () => {
            handleShowModal();
            setPendingAction(null);
        }
    );

    useApiStatus(
        activitiesService.lockActivityV2, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            handleShowModal();
            if (pendingAction === "start") {
                navigation.push("activity", {
                    activityTemplateId: data?.activity_template_id,
                    activityId: data?.id,
                    schoolSubjectId: data?.school_subject_id
                });
                setPendingAction(null);
            }
        },
        () => {
            handleShowModal();
            setPendingAction(null);
        }
    );

    useApiStatus(
        activitiesService.getActivities, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        } // success callback
    )

    const requestApiService = hasRole(props.user, 'student') ? activitiesService.activityTodoAsStudent : activitiesService.activityTodoAsTutor
    const launchActivity = useLaunchActivity()

    const liveSession = props.currentLiveSession

    const newTodoEvent = useNewTodo({
        channel: `presence-messenger.thread.${liveSession?.thread_id}`,
        id: studentId ?? props.user?.id
    })

    useApiStatus(
        requestApiService, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (hasRole(props.user, 'student')) {
                launchActivity({
                    activity_template_id: activityTemplate?.id,
                    id: item.id,
                    school_subject_id: schoolSubject?.id,
                    chapterName: item?.student_chapter?.chapter?.name,
                    chapterId: item?.student_chapter?.id
                }, "replace")
            } else {
                SnackBarM.show({
                    text1: "✅ La même activité a été ajoutée à la liste de l'élève",
                    duration: 4000,
                });
                handleShowModal()

                newTodoEvent()
            }
        } // success callback
    )

    const activityTemplate = item ? findItemInListWithId(item?.activity_template_id, props.activityTemplates) : null

    const onPress = () => {
        props.apiRequest(requestApiService, hasRole(props.user, "student") ? {} : {id: studentId}, {
            activity_template_id: activityTemplate?.id,
            school_subject_id: schoolSubject?.id,
            material_id: item?.material?.id,
            material_chapter: item?.chapter,
            estimated_duration: item?.estimated_duration,
            chapter_id: item?.student_chapter?.chapter?.id,
            chapter_name: item?.student_chapter?.chapter?.name,
            chapter_class: item?.student_chapter?.chapter?.class
        })
    }

    const status = item?.finished_at
        ? null
        : item?.started_at !== null && item?.locked_at !== null
            ? "ongoing"
            : item?.started_at !== null && item?.locked_at === null
                ? "started"
                : null;

    return (
        <ModalM
            visible={isOpen}
            onDismiss={handleShowModal}
            style={{
                width: IS_WEB && dimensions.width > 800 ? "50%" : "90%",
                maxHeight: "90%"
            }}
        >
            <ScrollView
                style={{
                    marginTop: 20,
                    backgroundColor: theme.colors.white,
                    //paddingHorizontal: 20,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10
                }}
                contentContainerStyle={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: 10
                }}
            >
                <View style={{
                    // flexDirection: 'row',
                    alignItems: "center",
                    //justifyContent: 'space-around',
                    justifyContent: "center",
                    width: "100%",
                    paddingHorizontal: 20,
                    marginBottom: 30
                }}
                >
                    <IconImageM width={50} style={{marginBottom: 4}} uri={item?.icon}/>
                    <TextM
                        fontSize={14}
                        wrap
                        fontWeight={"SemiBold"}
                        color={theme.colors.grey}
                    >
                        {item?.material?.title ?? item?.title}
                    </TextM>
                    {(!!item?.chapter || item?.student_chapter?.chapter?.name) && (
                        <TextM
                            fontWeight="SemiBold"
                            //wrap
                            color={schoolSubject?.color}
                            style={{
                                marginBottom: 4
                            }}
                            fontSize={12}
                        >
                            {item?.chapter ?? item?.student_chapter?.chapter?.name} - {schoolSubject?.name}
                        </TextM>
                    )}
                    <TextM
                        fontSize={10}
                        wrap
                        style={{marginBottom: 5, textAlign: "right"}}
                    >
                        activité ajoutée{" "}
                        <TextM
                            fontSize={10}
                            fontWeight={"Medium"}
                            color={
                                isActivityTooOld(item) ? theme.colors.error : theme.colors.grey
                            }
                        >
                            {formatRelativeDateTime(item?.created_at, true)}
                        </TextM>
                    </TextM>
                    <TodoActivityBadges activity={item} style={{marginBottom: 10}}/>
                    <View style={{flexDirection: "row", alignItems: "center", marginBottom: 10}}>
                        {<DoneChip count={0} status={status ?? item?.status} large style={{marginRight: 10}}/>}
                        <ActivityInfosIcons
                            activity={item}
                            large
                            style={{}}
                        />
                    </View>

                    {props.getActivitiesRequest && <ActivityIndicator color={theme.colors.primary}/>}
                    {item?.activity_logs &&
                        item?.steps?.map((step, index) => (
                            <StepLogs
                                step={step}
                                key={index}
                                index={index}
                                style={{marginTop: 20}}
                                activity={item}
                            />
                        ))}

                </View>

                <View
                    style={{
                        flexDirection: "row",
                        paddingHorizontal: 10,
                        width: "100%"
                    }}
                >
                    {props.getActivityRequest && pendingAction === "update" ? (
                        <View
                            style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <ActivityIndicator color={theme.colors.primary}/>
                        </View>
                    ) : (
                        <IconTouchableRipple
                            icon={"pen"}
                            label={"Modifier"}
                            // badgeCounter={activityFinalizedCount}
                            onPress={onUpdatePress}
                        />
                    )}
                    {props.getActivityRequest && pendingAction === "delete" ? (
                        <View
                            style={{
                                flex: 1,
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                        >
                            <ActivityIndicator color={theme.colors.primary}/>
                        </View>
                    ) : (
                        <IconTouchableRipple
                            icon={"trash"}
                            label={"Supprimer"}
                            // badgeCounter={activityFinalizedCount}
                            onPress={onDeletePress}
                        />
                    )}
                    {/*<IconTouchableRipple*/}
                    {/*  icon={"info"}*/}
                    {/*  label={"Information"}*/}
                    {/*  // badgeCounter={activityFinalizedCount}*/}
                    {/*  onPress={() => {*/}
                    {/*    handleShowModal();*/}
                    {/*    navigation.push("activity-result", {*/}
                    {/*      activityIds: [item?.id],*/}
                    {/*      studentId: item?.student_id,*/}
                    {/*    });*/}
                    {/*  }}*/}
                    {/*/>*/}
                    {!!item?.material && (
                        <IconTouchableRipple
                            icon={
                                item?.material?.url
                                    ? "link"
                                    : item?.material?.attachments?.length > 0
                                        ? "image"
                                        : "align-justify"
                            }
                            label={
                                capitalize(activitySlugsMaterialSlugs[item?.slug]) || "Support"
                            }
                            // badgeCounter={activityFinalizedCount}
                            onPress={() => {
                                handleShowMaterialModal();
                            }}
                        />
                    )}
                    {!!item?.finished_at && (
                        props.activityTodoAsStudentRequest || props.activityTodoAsTutorRequest ? (
                                <View
                                    style={{
                                        flex: 1,
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <ActivityIndicator color={theme.colors.primary}/>
                                </View>
                            ) : <IconTouchableRipple
                            icon={"redo"}
                            label={"Redonner l'activité"}
                            // badgeCounter={activityFinalizedCount}
                            onPress={onPress}
                        />
                    )}
                </View>

                {!!studentId || (
                    <FilledButtonM
                        onPress={onStartPress}
                        loading={props.lockActivityRequest}
                        disabled={props.lockActivityRequest}
                        style={{
                            alignSelf: "center",
                            marginTop: 10,
                            width: "90%"
                        }}
                        label="COMMENCER"
                    />
                )}
            </ScrollView>
            <MaterialModal
                handleShowModal={handleShowMaterialModal}
                isOpen={isMaterialOpen}
                material={item?.material}
            />
        </ModalM>
    );
};
const mapStateToProps = (state) => {
    return {
        user: state.data.currentUser?.object,
        getActivityRequest: state.api.getActivity?.request,
        lockActivityRequest: state.api.lockActivityV2?.request,
        liveSessionId: state.liveMode?.liveSessionId,
        activityTemplates: state.data.staticData?.activity_templates,
        getActivitiesRequest: state.api.getActivities?.request,
        activityTodoAsStudentRequest: state.api.activityTodoAsStudent?.request,
        activityTodoAsTutorRequest: state.api.activityTodoAsTutor?.request,
        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),

    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(TodoModal);
