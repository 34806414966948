import React, {useContext, useEffect} from 'react';
import {View} from "react-native";
import {ModalM} from "@common/components/modal/ModalM";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {useNavigation} from "@react-navigation/native";
import {connect, useSelector} from "react-redux";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ActivityIndicator} from "react-native-paper";


const FormStepperModal = ((props) => {

    const {visible, navigation, setVisible, formData, track = true, setFormValues = () => {}, initialGetService = null, submitService, submitRouteParams = null, submitSuccessMessage = null, submitButtonText = "Terminer", submitAdditionalData = {}, submitErrorCalback = () => {}} = props

    const submitted = useSelector(state => state.api?.[submitService?.name]?.request)
    const error = useSelector((state) => state.api[submitService?.name]?.error)

    const dimensions = useContext(DimensionsContext)

    useApiStatus(
        submitService, // api service
        submitSuccessMessage ?? "", // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setVisible(false)
        },
        submitErrorCalback
    )

    useApiStatus(
        initialGetService, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
        },
        (error) => {
            setVisible(false)
        }
    )

    const initialGetRequest = useSelector(state => state.api?.[initialGetService?.name]?.request)

    useEffect(() => {
        if (initialGetService && visible) {
            props.apiRequest(initialGetService)
        }
        return () => {};
    }, [visible]);


    return <ModalM visible={visible}
                   onDismiss={() => setVisible(false)}
                   style={{height: "90%", width: dimensions?.width}}>
        <View style={{
            flex: 1,
            borderRadius: 10,
            paddingTop: 20,
            paddingBottom: 10,
            backgroundColor: theme.colors.whiteAlmost,
        }}>
            {initialGetRequest
                ? <ActivityIndicator animating={true}
                                     style={{
                                         marginTop: 50
                                     }}
                                     size="large"
                                     color={theme.colors.primary}/>
                :  <FormStepper navigation={navigation}
                     noNavigation={true}
                     track={track}
                     formData={formData?.filter(x => !x.is_masked)}
                     champion={null}
                     setFormValues={setFormValues}
                     defaultValues={{}}
                     onSubmit={(data) => {
                         props.apiRequest(submitService, submitRouteParams ?? {}, {
                             ...submitAdditionalData,
                             ...data
                         })
                     }}
                     submitted={submitted}
                     previousRouteName={""}
                     submittedMessage={""}
                     serverError={error}
                     submitButtonText={submitButtonText}
        />}
        </View>
    </ModalM>
})

const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(FormStepperModal)