import React, {forwardRef, useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {Dimensions, FlatList, ScrollView, View} from "react-native";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import {theme} from "../theme/theme";
import {ActivityIndicator} from "react-native-paper";
import TextM from "../components/text/TextM";
import {useFocusEffect} from "@react-navigation/native";
import {DialogM} from "@common/components/alert/dialog/Dialog";

export const PaginatedFlatListContent = forwardRef((props, ref) => {

    const {
        data,
        ListHeaderComponent,
        renderItem,
        style,
        additionalOnRefresh,
        additionalRefreshing = false,
        requestApiService,
        requestRouteParams,
        requestUrlParams,
        requestData,
        contentContainerStyle,
        tooManyColumns = false,
        ListEmptyComponent,
        noRefreshWhenNoResults = false,
        noActivityIndicator = false,
        totalCount,
        setTotalCount,
        currentPage,
        setCurrentPage,
        lastPage,
        setLastPage,
        refresh,
        refreshing
    } = props

    useEffect(() => {
        if (totalCount !== -1 && noRefreshWhenNoResults) {
            // console.log("SEARCH CHANGE")
            refresh()
        }
    }, [requestUrlParams])

    useEffect(() => {
        if (totalCount !== -1) {
            // console.log("SEARCH CHANGE")
            refresh()
        }
    }, [requestUrlParams?.search])
    const renderList = () => {
        return <>
            {!noActivityIndicator && IS_WEB && refreshing && (currentPage === 1) && <ActivityIndicator color={theme.colors.primary}
                               style={{marginVertical: 20}}/>}
            <FlatList data={(totalCount === 0 && data?.length === 0) ? [] : data}
                      ref={ref}
                      refreshing={refreshing && (currentPage === 1)}
                      onRefresh={refresh}
                      style={{
                          width: dimensions?.width,
                          ...style
                      }}
                      contentContainerStyle={{
                          alignItems: "center",
                          ...contentContainerStyle
                      }}
                      ListHeaderComponent={<>
                          {ListHeaderComponent}
                          {ListEmptyComponent ? ListEmptyComponent : totalCount === 0 && !refreshing && data?.length === 0 &&
                              <TextM style={{textAlign: "center", marginTop: 10}}>Aucun élément</TextM>}
                      </>}
                      ListFooterComponent={renderFooter()}
                      keyExtractor={(item, index) => index.toString()}
                      onEndReachedThreshold={0.2}
                      onEndReached={() => {
                          // console.log("END REACHED")
                          loadMore()
                      }}
                      renderItem={renderItem}/>
        </>
    }

    const success = useSelector((state) => state.api[requestApiService?.name]?.success)
    const error = useSelector((state) => state.api[requestApiService?.name]?.error)
    const successData = useSelector((state) => state.api[requestApiService?.name]?.data)
    const dispatch = useDispatch();


    const loadMore = () => {
        if (!refreshing && currentPage < lastPage && data?.length > 0) {
            // console.log("current page before request", currentPage)
            dispatch(apiRequest(requestApiService, requestRouteParams ?? {}, requestData ?? {}, {
                ...requestUrlParams,
                page: currentPage + 1
            }))
            setCurrentPage(p => p + 1)
        }
    }

    useEffect(() => {
        // console.log("load")
        if (!refreshing)
            refresh()
    }, []);


    useEffect(() => {
        //console.log("data_returned")
        if (success) {
            dispatch(apiIdle(requestApiService))
            // console.log(successData?.meta)
            setCurrentPage(successData?.meta?.current_page)
            setLastPage(successData?.meta?.last_page)
            setTotalCount(successData?.meta?.total)
        }
        if (error) {
            dispatch(apiIdle(requestApiService))
            DialogM.show({
                text1: 'Erreur', text2: error.message
            })
            //alertPolyfill('Erreur', error.message)
        }
    }, [success, error]);
    const dimensions = useContext(DimensionsContext)

    const renderFooter = () => {
        //it will show indicator at the bottom of the list when data is loading otherwise it returns null
        return <View style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center"
        }}>
            {(refreshing && currentPage > 1) && <ActivityIndicator animating={true}
                                                                   size="small"
                                                                   style={{marginVertical: 5}}
                                                                   color={theme.colors.primary}/>}

            <View style={{
                width: Dimensions.get("window").width,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                paddingHorizontal: 10,
                marginBottom: 100
            }}>
                {data?.length > 0 && totalCount > 0 &&
                    <TextM color={theme.colors.grey} fontSize={12}
                           fontWeight="Light">{data?.length} / {totalCount}</TextM>}
            </View>

        </View>

    };

    return <>
        {tooManyColumns ?
            <ScrollView horizontal={true}
                        contentContainerStyle={{
                            // width: Dimensions.get("window").width,
                            alignItems: "flex-start",
                            justifyContent: "center"
                        }}
                        style={{
                            backgroundColor: theme.colors.whiteAlmost, // alignItems: 'center',
                            width: Dimensions.get("window").width,
                            flex: 1
                        }}
            >
                <View style={{
                    height: "100%"
                }}>
                    {renderList()}
                </View>
            </ScrollView> :
            renderList()


        }
    </>
})