import {View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import * as PropTypes from "prop-types";
import React from "react";
import {IS_WEB} from "@common/utils/mobileUtils";

export function UsernameComponent(props) {
    return <View style={{
        position: props.withVideo ? "absolute" : 'relative',
        bottom: props.withVideo ? props.bottom ? props.bottom : 10 : 0,
        alignSelf: "center",
        width: "100%",
    }}>
        <View style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            alignSelf: "center",
            backgroundColor: props.soundMode || props.userObject?.is_tutor ? theme.colors.successTransparent : props.user?.uid === 0 ? theme.colors.successTransparent : theme.colors.purpleTransparent,
            padding: 5,
            borderRadius: 10,
            ...props.speakerStyles
        }}>
            <TextM color={theme.colors.white}
                   fontSize={IS_WEB ? 14 : 10}>{props.userObject?.display_name ?? props.rtcProps?.username}</TextM>
            <FontAwesome5
                name={props.soundMode || props.userObject?.is_tutor ? "volume-up" : props.user?.uid === 0 ? "volume-up" :"volume-mute"}
                size={IS_WEB ? 14 : 12}
                color={theme.colors.white}
                style={{marginLeft: 8}}
            />
            <FontAwesome5
                name={props.condition ? "microphone-slash" : "microphone"}
                size={IS_WEB ? 14 : 12}
                color={theme.colors.white}
                style={{marginLeft: 5}}
            />
        </View>

    </View>;
}

UsernameComponent.propTypes = {
    userObject: PropTypes.any,
    rtcProps: PropTypes.any,
    user: PropTypes.any
};