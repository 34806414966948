import React, {useContext, useEffect} from "react";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {setOngoingActivity} from "@data/redux/actions/activity";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {connect} from "react-redux";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {interviewReportService} from "@data/services/interviewReportService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {InterviewStatisticsCard} from "../../admin/interviewReports/StatisticsStep";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {IconImageM} from "../components/icons/IconsM";
import xpSquare from "../../../assets/icons/generic/xp_square.png";
import calendar from "../../../assets/icons/generic/calendar-days.png";
import rise from "../../../assets/icons/menus/rise.png";
import ChapterItemComponent from "../chapters/ChapterItemComponent";

const InterviewReportComponent = (props) => {
    const {interviewReport, style, currentWeekChapters, nextWeekChapters} =
        props;

    const dimensions = useContext(DimensionsContext);

    const boxStyle = {
        borderWidth: 2,
        borderColor: theme.colors.lightMore,
        borderRadius: theme.roundness,
        padding: 20,
        backgroundColor: theme.colors.white,
        marginTop: 20,
        width: dimensions?.width - 60,
        justifyContent: "center",
        alignItems: "center"
    };

    useApiStatus(
        interviewReportService.sendParentSatisfaction, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data)
            props.apiIdle(interviewReportService.sendParentSatisfaction);
            DialogM.show({
                text1: "Succès",
                text2: data.message
            });
            setValue("satisfaction", "");
        } // success callback
    );

    useEffect(() => {
        if (props.sendParentSatisfactionError) {
            DialogM.show({
                text1: "Erreur",
                text2: props.sendParentSatisfactionError?.message
            });
        }
    }, [props.sendParentSatisfactionError]);

    return (
        <>
            <View
                style={{
                    width: dimensions?.width - 20,
                    marginTop: 30,
                    marginBottom: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                    ...style
                }}
            >
                {currentWeekChapters && currentWeekChapters?.length > 0 && (
                    <View
                        style={{
                            ...boxStyle
                        }}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 20,
                                width: "100%"
                            }}
                        >
                            <IconImageM
                                source={rise}
                                width={30}
                                style={{marginHorizontal: 10}}
                            />
                            <TextM
                                fontSize={18}
                                fontWeight={"ExtraBold"}
                                color={theme.colors.primary}
                                wrap
                            >
                                Avancement cette semaine
                            </TextM>
                        </View>
                        {currentWeekChapters?.map((item, index) => {
                            const chapterInfos = {...item, ...item?.chapter};

                            return (
                                <ChapterItemComponent chapter={chapterInfos}
                                                      key={index}
                                                      liveReportMode
                                                      validatedActivities={chapterInfos?.validated_activities_count}
                                                      todoActivities={chapterInfos?.todo_activities_count}
                                                      interviewReport
                                />
                            );
                        })}
                    </View>
                )}
                {!!interviewReport?.statistics?.["total_live_reports"]?.value && (
                    <View
                        style={{
                            ...boxStyle
                        }}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 20,
                                width: "100%"
                            }}
                        >
                            <IconImageM
                                source={xpSquare}
                                width={30}
                                style={{marginHorizontal: 10}}
                            />
                            <TextM
                                fontSize={18}
                                fontWeight={"ExtraBold"}
                                color={theme.colors.primary}
                                wrap
                            >
                                Engagement cette semaine
                            </TextM>
                        </View>

                        <InterviewStatisticsCard statistics={interviewReport?.statistics}/>
                    </View>
                )}
                {nextWeekChapters && nextWeekChapters?.length > 0 && (
                    <View
                        style={{
                            ...boxStyle
                        }}
                    >
                        <View
                            style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 20,
                                width: "100%"
                            }}
                        >
                            <IconImageM
                                source={calendar}
                                width={30}
                                style={{marginHorizontal: 10}}
                            />
                            <TextM
                                fontSize={18}
                                fontWeight={"ExtraBold"}
                                color={theme.colors.primary}
                                wrap
                            >
                                Au programme la semaine prochaine
                            </TextM>
                        </View>
                        {nextWeekChapters?.map((item, index) => {
                            const chapterInfos = {...item, ...item?.chapter};

                            return (
                                <ChapterItemComponent
                                    chapter={chapterInfos}
                                    key={index}
                                    liveReportMode
                                    renderChapterOnly
                                    interviewReport
                                />
                            );
                        })}{" "}
                    </View>
                )}
            </View>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        getInterviewReportRequest: state.api.getInterviewReport?.request,
        getInterviewReportError: state.api.getInterviewReport?.error,
        schoolSubjectsFromStaticData: state.data?.staticData?.school_subjects,
        sendParentSatisfactionRequest: state.api.sendParentSatisfaction?.request,
        sendParentSatisfactionError: state.api.sendParentSatisfaction?.error
    };
};

const mapDispatchToProps = {
    apiRequest,
    apiIdle,
    setOngoingActivity,
    setIsLiveModeEnabled
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InterviewReportComponent);
