import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {useNavigation} from "@react-navigation/native";
import {setVideoRecord} from "@data/redux/actions/camera";
import Webcam from "react-webcam";
import {View} from "react-native";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const VideoRecordingComponent = (props) => {

    const {
        questionId,
        setRecordingStarted,
        answerDuration,
        testMode = false
    } = props
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [recordedChunks, setRecordedChunks] = useState([]);

    const [permissionGranted, setPermissionGranted] = useState(false);

    const mimeType = MediaRecorder.isTypeSupported("video/webm") ? "video/webm" : "video/mp4"

    useEffect(() => {
        // if (navigator.permissions)
        //     navigator.permissions?.query({name: "camera"})?.then((res) => {
        //         navigator.permissions?.query({name: "microphone"})?.then((res2) => {
        //             if (res.state === "granted" && res2.state === "granted") {
        //                 setPermissionGranted(true)
        //             }
        //         })
        //     })
        // else

        setTimeout(() => {
            console.log("PERMISSION GRANTED")
            setPermissionGranted(true)
        }, 1000)

    }, []);

    const handleStartCaptureClick = useCallback(() => {
        if (webcamRef.current?.stream) {
            setRecordingStarted(true)
            mediaRecorderRef.current = new MediaRecorder(webcamRef?.current?.stream, {
                mimeType
            });

            mediaRecorderRef.current.addEventListener(
                "dataavailable",
                handleDataAvailable
            );

            mediaRecorderRef.current.addEventListener(
                "error",
                handleMediaRecorderError
            );

            console.log("STARTING RECORDING")
            mediaRecorderRef.current.start();
        }
    }, [webcamRef, mediaRecorderRef]);

    const handleDataAvailable = useCallback(
        (data) => {
            if (data.data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data.data));
            }
        },
        [setRecordedChunks]
    );

    const handleMediaRecorderError = useCallback((event) => {
        console.log("ERREUR MEDIA RECORDER", event.error)
        // setRecordingStarted(false);
        // navigation?.goBack()
        // DialogM.show({
        //     text1: 'Une erreur est survenue',
        //     text2: "Si l'erreur persiste, merci de retenter depuis un autre navigateur ou un autre appareil"
        // })
    }, [mediaRecorderRef, webcamRef]);

    useEffect(() => {
        console.log("mediaRecorderRef.current?.state", mediaRecorderRef.current?.state)
    }, [mediaRecorderRef.current?.state])

    const handleStopCaptureClick = useCallback(() => {

        if (mediaRecorderRef.current?.state === "recording" || mediaRecorderRef.current?.state === "paused")
            mediaRecorderRef.current?.stop();

        setRecordingStarted(false)
    }, [mediaRecorderRef, webcamRef]);

    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()

    useEffect(() => {
        // console.log("useEffect")
        if (webcamRef && permissionGranted && !testMode) {
            // console.log("useEffect2")
            setTimeout(() => {
                handleStartCaptureClick()
            }, 2000)
        }
    }, [webcamRef, permissionGranted]);
    useEffect(() => {
        if (props.videoRecord && !testMode) {
            navigation?.goBack()
        }
    }, [props.videoRecord]);

    useEffect(() => {
        if (answerDuration < 1 && !testMode && !props.videoRecord) {
            handleStopCaptureClick()
            setRecordingStarted(false)
            if (recordedChunks.length) {
                //console.log(recordedChunks)
                const blob = new Blob(recordedChunks, {
                    type: mimeType
                });
                let url = URL.createObjectURL(blob);
                //window.URL.revokeObjectURL(url);
                let file = new File([blob], "testName." + mimeType?.split("/")?.[1], {type: mimeType});
                props.setVideoRecord({...file, uri: url})
                setRecordedChunks([]);
            }
        }
    }, [answerDuration, recordedChunks]);

    return (
        <View style={{flex: 1, justifyContent: 'center'}}>
            <Webcam audio={true}
                    ref={webcamRef}
                    height={dimensions?.height}
                    mirrored={true}
                    muted={true}/>
        </View>
    )
}
const mapStateToProps = state => {
    return {
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        testQuestions: state.data.tutors?.testQuestions,
        videoRecord: state.camera?.videoRecord
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setVideoRecord
    }

export default connect(mapStateToProps, mapDispatchToProps)(VideoRecordingComponent)