import {messengerConstants} from "../../../constants/messengerConstants";

export function newMessage(data) {

    // console.log("saveActivityData action")

    return {type: messengerConstants.NEW_MESSAGE, data}
}

export function changeCount(data) {
    return {type: messengerConstants.IN_CHAT_MESSENGER_NOT_READ_COUNT, data}
}

export function changeUnreadMessageInLiveCount(threadId, userId, count = 1) {
    return {type: messengerConstants.IN_LIVE_MESSENGER_NOT_READ_COUNT, threadId, userId, data: count}
}