import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import ChallengeHeaderNew from "./ChallengeHeaderNew";

const ChallengeLaunchCongrats = props => {

    const {challenge, activities} = props

    return <View style={{
        alignItems: "center",
        paddingHorizontal: 10
    }}>

        <TextM fontSize={34}
               fontWeight="ExtraBold"
               color={theme.colors.primary}
               style={{textAlign: "center", alignSelf: "center", marginTop: 20}}
               wrap
        >DÉFI LANCÉ !</TextM>
        <ChallengeHeaderNew challenge={challenge}
            // columnMode
            //              hideProgressBar
                            hideChevron/>
        {/*<KeyValueItem keyText="XP À GAGNER"*/}
        {/*              valueText={challenge?.xp_target}*/}
        {/*              style={{marginTop: 0}}*/}
        {/*              isXP/>*/}
        {/*<KeyValueItem keyText="DATE LIMITE"*/}
        {/*              valueText={capitalize(moment(challenge?.deadline).format("dddd DD/MM"))}*/}
        {/*              style={{marginTop: 10, marginBottom: 10}}/>*/}

        {/*{activities?.length > 0 ? <>*/}
        {/*    /!*<TextM fontSize={14}*!/*/}
        {/*    /!*       fontWeight="Bold"*!/*/}
        {/*    /!*       color={theme.colors.light}*!/*/}
        {/*    /!*       style={{textAlign: "center", marginTop: 30, marginBottom: 10}}*!/*/}
        {/*    /!*       wrap*!/*/}
        {/*    /!*>DÉFIS PLANIFIÉS</TextM>*!/*/}

        {/*    <ChallengePlanningScroll challenge={challenge}*/}
        {/*                             activities={activities}*/}
        {/*                             readOnly/>*/}
        {/*</> : <>*/}
        {/*    <TextM fontSize={14}*/}
        {/*           fontWeight="Bold"*/}
        {/*           color={theme.colors.light}*/}
        {/*           style={{textAlign: "center", marginTop: 30, marginBottom: 10}}*/}
        {/*           wrap*/}
        {/*    >ACTIVITÉS À RÉALISER</TextM>*/}
        {/*    <ScrollView horizontal style={{*/}
        {/*        width: WINDOW_WIDTH,*/}
        {/*        backgroundColor: theme.colors.primaryLighter*/}
        {/*    }}>*/}
        {/*        <View*/}
        {/*            style={{*/}
        {/*                // height: 160,*/}
        {/*                flexDirection: 'row',*/}
        {/*                justifyContent: 'space-evenly'*/}
        {/*                // flexWrap: "wrap",*/}
        {/*            }}*/}
        {/*        >*/}
        {/*            {props.activityTemplates*/}
        {/*                ?.filter(at => {*/}
        {/*                    return at?.challenge_template_ids[challenge?.school_subject?.category]?.includes(challenge?.challenge_template_id)*/}
        {/*                })*/}
        {/*                ?.map((activityTemplate, index) => {*/}
        {/*                    return <ActivityButton key={index}*/}
        {/*                                           readOnly*/}
        {/*                                           activityTemplate={activityTemplate}*/}
        {/*                                           challenge={challenge}*/}
        {/*                    />*/}
        {/*                })}*/}
        {/*        </View>*/}
        {/*    </ScrollView>*/}
        {/*</>}*/}

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeLaunchCongrats)