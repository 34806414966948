import {theme} from "../theme/theme";
import {Image, Pressable, View} from "react-native";
import React from "react";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import IconButtonM from "@common/components/button/IconButtonM";

const PhotoElement = (props) => {

    // ** Component props
    const {uri, height, width, isSelecting, selected, parentHeight, onLongPress, onPress, longPressDisabled = true} = props


    return <View
        style={{
            alignItems: "center",
            height: parentHeight
        }}>
        <Pressable onLongPress={onLongPress}
                   onPress={onPress}
        >
            <Image
                source={{uri: uri}}
                resizeMode={"contain"}
                style={{
                    // flex: 1,
                    width: parentHeight * width / height,
                    height: parentHeight,
                    marginRight: 5,
                    opacity: selected ? 0.5 : 1
                }}
            />
        </Pressable>


        {!longPressDisabled && <IconButtonM
            onPress={onLongPress}
            icon={"trash"}
            size={16}
            style={{
                height: 30,
                width: 30,
                backgroundColor: theme.colors.white,
                borderRadius: 30,
                position: "absolute",
                top: 10,
                right: 10
            }}
            iconColor={theme.colors.grey}
        />}

        {isSelecting && <FontAwesome5 name={selected ? "check-circle" : "circle"}
                                      solid={selected}
                                      color={theme.colors.grey}
                                      size={26}
                                      style={{
                                          position: "absolute",
                                          top: 5,
                                          right: 10
                                      }}
        />}
    </View>
}

export default PhotoElement