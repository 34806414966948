import React from 'react';
// @ts-ignore
import Svg, {Circle, Defs, G, LinearGradient, Path, Stop} from 'react-native-svg';
import {Characters} from '../types';
import {Animated} from "react-native";
import veste1 from "../../../../assets/avatar/avatarParts/veste/veste1.png";
import veste2 from "../../../../assets/avatar/avatarParts/veste/veste2.png";
import veste3 from "../../../../assets/avatar/avatarParts/veste/vesteTeddy1.png";
import veste4 from "../../../../assets/avatar/avatarParts/veste/vesteTeddy2.png";
import veste5 from "../../../../assets/avatar/avatarParts/veste/vesteTeddy3.png";
import {avatarPartsStyles} from "./body";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "../../theme/theme";
import Image = Animated.Image;
import View = Animated.View;

export default function Jacket({value, persona}: { value: Characters['jacket']; persona: boolean }) {
    switch (value) {
        case 'jacket0':
            return <View style={[avatarPartsStyles.part, {
                alignItems: 'center',
                justifyContent: 'flex-end'
            }]}>
                <FontAwesome5 name={"ban"}
                              style={{}}
                              color={theme.colors.light}
                              size={30}/>
            </View>;
        case 'jacket1':
            return <Image style={persona ? avatarPartsStyles.persona : avatarPartsStyles.part}
                          source={veste1}/>;
        case 'jacket2':
            return <Image style={persona ? avatarPartsStyles.persona : avatarPartsStyles.part}
                          source={veste2}/>;
        case 'jacket3':
            return <Image style={persona ? avatarPartsStyles.persona : avatarPartsStyles.part}
                          source={veste3}/>;
        case 'jacket4':
            return <Image style={persona ? avatarPartsStyles.persona : avatarPartsStyles.part}
                          source={veste4}/>;
        case 'jacket5':
            return <Image style={persona ? avatarPartsStyles.persona : avatarPartsStyles.part}
                          source={veste5}/>;
        default:
            return null;
    }
}

