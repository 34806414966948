import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import TextM from "@common/components/text/TextM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextInputM from "@common/components/input/TextInputM";
import {theme} from "@common/theme/theme";
import InterviewStatisticsCard from "../tutor/home/interview/InterviewStatisticsCard";
import {Platform, ScrollView, View} from "react-native";
import React, {useContext, useState} from "react";
import {backOfficeService} from "@data/services/backOfficeService";
import {useForm} from "react-hook-form";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const NewWeeklyReportScreen = (props) => {
    const dimensions = useContext(DimensionsContext)

    const [globalComment, setGlobalComment] = useState("")
    const student = props.users?.find(x => x?.id === parseInt(props.route.params?.studentId))
    const navigation = useNavigation();

    const {control, handleSubmit, formState: {errors, dirtyFields}, trigger, setValue} = useForm({
        comment: "",
        status: "validated"

    })
    const onSubmit = () => {
        if (globalComment?.length === 0) {
            DialogM.show({
                text1: 'Erreur',
                text2: 'Merci d\'indiquer un commentaire'
            })
            return
        }
        //console.log('submitted')
        props.apiRequest(backOfficeService.createWeeklyReport, {user_id: props.route.params?.studentId}, {comment: globalComment})
    }
    useApiStatus(
        backOfficeService.createWeeklyReport, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            //console.log(successData)
            navigation.push("send-weekly-report", {
                report_id: successData?.id,
                student_id: student?.id
                // global_comment:successData?.comment
            })
        } // success callback
    )

    useApiStatus(
        backOfficeService.getAppreciation, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
            setValue("comment", successData?.weekly_comment);
            setGlobalComment(successData?.weekly_comment)
        } // success callback
    )

    return <ScrollView style={{marginBottom: 40, paddingTop: 10}} contentContainerStyle={{alignItems: "center"}}>
        {/*{InterviewTitle("Synthèse")}*/}

        <View style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: IS_WEB && dimensions.width > 800 ? dimensions?.width : dimensions?.width - 20
        }}>
            <TextM fontWeight={"Bold"} fontSize={20} style={{
                // padding: 10,
                marginTop: 20,
                marginBottom: 10,
                textAlign: IS_WEB && dimensions.width > 800 ? "left" : "center"
            }}>
                Message aux parents
            </TextM>
            {
                props.getAppreciationRequest ?
                    <ActivityIndicator color={theme.colors.primary} size={"small"}/>
                    :
                    <IconButton onPress={() => {
                        props.apiRequest(backOfficeService.getAppreciation, {user_id: props.route.params?.studentId})
                    }}
                                iconColor={theme.colors.primary}
                                icon={"sync"}
                                size={28}/>
            }

        </View>

        <TextInputM
            placeholder="Bilan et planification..."
            name="comment"
            style={{
                width: IS_WEB && dimensions.width > 800 ? dimensions?.width : dimensions?.width - 20,
                height: 120,
                backgroundColor: theme.colors.white
            }}
            multiline={true}
            // numberOfLines={3}
            // textContentType="emailAddress"
            // keyboardType="email-address"
            onChangeAdditional={(value) => {
                setGlobalComment(value)
            }}
            control={control}
            errors={errors}
        />

        <InterviewStatisticsCard statistics={student?.statistics}/>

        <FilledButtonM label={"Terminer"}
                       color={theme.colors.success}
                       loading={props.createWeeklyReportRequest}
                       style={{
                           width: IS_WEB && dimensions.width > 800 ? dimensions.width / 1.2 : dimensions.width - 20,
                           alignSelf: 'center',
                           marginTop: Platform.OS !== 'web' ? null : 15,
                           marginBottom: Platform.OS !== 'web' ? null : 15
                           // bottom: 15
                       }}
                       onPress={handleSubmit(onSubmit)}
        />

        {IS_WEB ? <FilledButtonM mode={"text"}
                                 icon={"chevron-left"}
                                 color={theme.colors.link}
                                 style={{marginTop: 60}}
                                 onPress={() => {
                                     if (navigation.canGoBack())
                                         navigation.goBack()
                                     else
                                         navigation.replace("menu")
                                 }}
                                 label={"Retour"}/> : null}


    </ScrollView>
}

const mapStateToProps = state => {
    return {
        users: state.data.users?.usersList,
        getAppreciationRequest: state.api?.getAppreciation?.request,
        createWeeklyReportRequest: state.api?.createWeeklyReport?.request
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(NewWeeklyReportScreen)