import {connect} from "react-redux";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import moment from "moment";
import {capitalize, formatFullDateTime} from "@data/utility/Utils";
import {nextInterviewForStudent} from "@data/utility/dataUtils";
import React from "react";
import {Platform, View} from "react-native";
import TextM from "@common/components/text/TextM";
import AvatarM from "@common/components/avatar/AvatarM";
import FilledButtonM from "@common/components/button/FilledButtonM";


export const StudentSlot = (props) => {

    const {tutor, slotId, studentId, dimensions} = props

    const slot = tutor?.interview_slots?.find(s => s?.id === slotId)
    const student = tutor?.students?.find(s => s?.id === studentId)
    const slotMoment = slot?.day_time ? moment(slot?.day_time, "dddd HH:mm", "en") : null
    const slotMomentFormatted = slotMoment ? capitalize(slotMoment?.locale("fr")?.format("dddd HH:mm")) : null
    const interview = nextInterviewForStudent(student ?? props.user)

    return <View style={{width: dimensions.width}}>
        <View
            style={{flexDirection: 'row', alignItems: 'center', paddingVertical: 20, justifyContent: 'center'}}>
            <AvatarM
                user={student}
                size={75}
            />
            <TextM style={{paddingHorizontal: 20}}>{student?.display_name}</TextM>
        </View>
        <View style={{
            // width: "100%",
            flexDirection: 'column',
            paddingVertical: 10,
            alignItems: 'center'
        }}>
            <TextM fontWeight={"Bold"}>
                Créneau hebdomadaire
            </TextM>
            <TextM style={{paddingHorizontal: 10}}>
                {slotMomentFormatted}
            </TextM>
        </View>
        <View style={{
            flexDirection: 'column',
            paddingVertical: 10,
            alignItems: 'center',
            paddingBottom: 20
        }}>
            <TextM fontWeight={"Bold"}>
                Prochain entretien
            </TextM>
            <TextM wrap style={{paddingHorizontal: 10}}>
                {interview ? formatFullDateTime(interview?.date_time) : "-"}
            </TextM>
        </View>
    </View>
}
const SlotModal = (props) => {

    const {isOpen, handleShowModal, slotId, studentId, tutor, navigation, dimensions} = props

    // const dimensions = useContext(DimensionsContext)

    const onModifyClick = () => {
        navigation.push("update-occupied-slots", {
            slotId
        })
        handleShowModal()
    }

    return (


        <View style={{
            paddingTop: 20,
            alignItems: "center"
        }}>

            <TextM fontWeight={"ExtraBold"} fontSize={20} style={{
                alignSelf: 'center'
            }}>
                Créneau occupé
            </TextM>

            <StudentSlot tutor={tutor} slotId={slotId} studentId={studentId} dimensions={dimensions}/>

            <FilledButtonM
                style={{
                    width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 80 : dimensions?.width / 2,
                    alignSelf: 'center'
                }}
                label="Modifier le créneau"
                onPress={() => {
                    onModifyClick()
                }}
            />
        </View>

    )

}

const mapStateToProps = state => {
    return {
        updateStudentSlotRequest: state.api.updateStudentSlot?.request,
        updateStudentSlotSuccess: state.api.updateStudentSlot?.success,
        updateStudentSlotError: state.api.updateStudentSlot?.error,
        updateStudentSlotForTutorRequest: state.api.updateStudentSlotForTutor?.request,
        updateStudentSlotForTutorSuccess: state.api.updateStudentSlotForTutor?.success,
        updateStudentSlotForTutorError: state.api.updateStudentSlotForTutor?.error
    }
}
const mapDispatchToProps = {
    apiRequest,
    apiIdle
}
export default connect(mapStateToProps, mapDispatchToProps)(SlotModal)