import {View} from "react-native";
import {theme} from "../theme/theme";
import TextM from "../components/text/TextM";
import ToggleIconButtonM from "../components/toggleIconButton";
import React from "react";

export function NotificationSettingsItem(props) {

    const {notificationItem, field, control, errors, lastItem = false} = props

    return <View
        style={{
            borderColor: theme.colors.lightMore,
            borderBottomWidth: lastItem ? 0 : 1,
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            paddingVertical: 20
        }}
    >
        <TextM
            fontWeight="Bold"
            color={theme.colors.grey}
            wrap
            fontSize={16}
            style={{paddingLeft: 10}}
        >
            {notificationItem.name}
        </TextM>
        {notificationItem.config?.channels?.push === true && (
            <ToggleIconButtonM
                key={props.notification?.id}
                iconName={"mobile-alt"}
                style={{position: "absolute", right: 60}}
                size={24}
                defaultValue={field.value?.[notificationItem.slug]?.channels?.push}
                name={notificationItem.slug}
                control={control}
                errors={errors}
                onChange={(newValue) => {
                    let filedValue = {
                        ...field.value,
                        [notificationItem.slug]: {
                            channels: {
                                push: newValue,
                                email: field.value?.[notificationItem.slug]?.channels?.email
                            },
                            custom_time: field.value?.[notificationItem.slug]?.custom_time
                        }
                    };

                    field.onChange(filedValue);
                }}
            ></ToggleIconButtonM>
        )}
        {notificationItem.config?.channels?.email === true && (
            <ToggleIconButtonM
                key={notificationItem.notification?.id}
                iconName={"envelope"}
                style={{position: "absolute", right: 12}}
                size={24}
                defaultValue={field.value?.[notificationItem.slug]?.channels?.email}
                name={notificationItem.slug}
                control={control}
                errors={errors}
                onChange={(newValue) => {
                    let filedValue = {
                        ...field.value,
                        [notificationItem.slug]: {
                            channels: {
                                push: field.value?.[notificationItem.slug]?.channels?.push,
                                email: newValue
                            },
                            custom_time: field.value?.[notificationItem.slug]?.custom_time
                        }
                    };

                    field.onChange(filedValue);
                }}
            ></ToggleIconButtonM>
        )}
    </View>;
}