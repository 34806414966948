import React, {useCallback, useContext, useEffect, useRef} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ModalM} from "../components/modal/ModalM";
import TextM from "../components/text/TextM";
import {theme} from "../theme/theme";
import {Animated, Vibration, View} from "react-native";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import AvatarM from "../components/avatar/AvatarM";
import {useNavigation} from "@react-navigation/native";
import {setCallPortal} from "@data/redux/actions/liveMode";
import {IconButton} from "react-native-paper";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";

const LiveInvitationModal = props => {

    const {visible, onDismiss} = props
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const liveSession = props.currentLiveSession

    const soundRef = useRef()
    // console.log("liveSession", liveSession)
    const anim = useRef(new Animated.Value(0, {
        useNativeDriver: true
    }));

    const shake = useCallback(() => {
        // makes the sequence loop
        Animated.loop(
            // runs the animation array in sequence
            Animated.sequence([
                // shift element to the left by 2 units
                Animated.timing(anim.current, {
                    toValue: -1,
                    duration: 50,
                    useNativeDriver: true
                }),
                // shift element to the right by 2 units
                Animated.timing(anim.current, {
                    toValue: 1,
                    duration: 50,
                    useNativeDriver: true

                }),
                // bring the element back to its original position
                Animated.timing(anim.current, {
                    toValue: 0,
                    duration: 50,
                    useNativeDriver: true
                })
            ])
            // loops the above animation config 2 times
            // { iterations: 2 }
        ).start();
    }, []);

    const stopSound = () => {
        if (soundRef.current !== null && soundRef.current !== undefined && soundRef.current?.isLoaded) {
            soundRef.current?.unloadAsync()
            soundRef.current = null
        }
    }

    useEffect(() => {
        // shake()
        return () => {
            Vibration.cancel()


            // playSoundRef.current?.forceStopSound()
        }
    }, []);

    useEffect( () => {
        if (visible) {
            PlaySoundM.play(require('../../../assets/sounds/office-telephone-ringing.mp3'), 0, true).then((sound) => {
                soundRef.current = sound
            })
            shake()
            Vibration.vibrate([200, 800, 200, 800], true)
        } else {
            Vibration.cancel()
            stopSound()
        }
    }, [visible]);


    return visible && <ModalM visible={visible}
                              onDismiss={onDismiss}
    >
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10
        }}>
            {/*            <PlaySound soundFile={require('../../../assets/sounds/office-telephone-ringing.mp3'))}
                       ref={playSoundRef}
                       isLooping
                       delay={500}/>*/}

            <AvatarM size={80} user={liveSession?.tutor} style={{paddingVertical: 10}}/>
            <TextM wrap fontWeight={"Bold"} fontSize={18} style={{textAlign: 'center', paddingVertical: 20}}>
                Ton tuteur {liveSession?.tutor?.display_name} t’invite à le rejoindre sur la visio-conférence
            </TextM>
            <Animated.View
                style={{transform: [{translateX: anim.current}], flexDirection: 'row', alignItems: 'center'}}>
                {!IS_WEB && <IconButton
                    icon={"phone"}
                    size={34}
                    onPress={() => {
                        props.setCallPortal(true, "audio")
                        stopSound()

                        onDismiss()
                        //props.apiRequest(liveService.joinCall, {id: 1})
                    }}
                    style={{
                        padding: 2,
                        marginHorizontal: 12,
                    }}
                    iconColor={theme.colors.white}
                    containerColor={theme.colors.phone}
                />}
                <IconButton
                    icon={"video"}
                    size={34}
                    onPress={() => {
                        props.setCallPortal(true, "video")
                        stopSound()

                        onDismiss()
                        //props.apiRequest(liveService.joinCall, {id: 1})
                    }}
                    style={{
                        padding: 2,
                        marginHorizontal: 12,
                    }}
                    iconColor={theme.colors.white}
                    containerColor={theme.colors.phone}
                />
            </Animated.View>

        </View>

    </ModalM>
}

const mapStateToProps = state => {
    return {

        updateTitleSkillSetRequest: state.api.updateTitleSkillSet?.request,
        updateTitleSkillSetSuccess: state.api.updateTitleSkillSet?.success,
        updateTitleSkillSetError: state.api.updateTitleSkillSet?.error,

        currentLiveSession: state.data.currentUser?.object?.live_sessions?.find(x => x?.id === state.liveMode?.liveSessionId),
        liveSessionId: state.liveMode?.liveSessionId,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        
        user: state.data.currentUser?.object,
        expoToken: state.data.session?.object?.expo_token,

        getUserRequest: state.api.getUser?.request,

        successes: state.data.staticData?.successes,
        avatars: state.data.staticData?.avatars,
        skill_sets: state.data.staticData?.skill_sets,
        skills: state.data.staticData?.skills

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setCallPortal
    }

export default connect(mapStateToProps, mapDispatchToProps)(LiveInvitationModal)