import {View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import React, {useContext} from "react";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";

const ChallengeProgressCongrats = props => {

    const {shopProduct} = props

    const congratsTitle = "MERCI ❤️"
    const congratsText = "Tu viens de financer un geste "
    const dimensions = useContext(DimensionsContext)


    return <View style={{
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 40,
        paddingHorizontal: 15,
        width: dimensions?.width
    }}>
        {/*<View style={{height: 100}}/>*/}

        <TextM fontSize={34}
               wrap
               fontWeight="Bold"
               style={{
                   textAlign: "center"
                   // marginTop: 50
               }}
               color={theme.colors.primary}>
            {congratsTitle.toUpperCase()}
        </TextM>

        <TextM fontSize={18}
               wrap
               fontWeight="Regular"
               style={{
                   textAlign: "center",
                   marginTop: 40,
                   marginHorizontal: 30
               }}
               color={theme.colors.grey}>
            {congratsText}
        </TextM>
        <TextM fontSize={18}
               wrap
               fontWeight="SemiBold"
               style={{
                   textAlign: "center",
                   marginHorizontal: 30
               }}
               color={theme.colors.grey}>
            {shopProduct?.name}
        </TextM>

        <IconImageM uri={shopProduct?.icon_path} width={200} style={{borderRadius: 15, marginTop: 10}}/>

        {/*<TextM fontSize={14}*/}
        {/*       wrap*/}
        {/*       fontWeight="Regular"*/}
        {/*       style={{*/}
        {/*           textAlign: "center",*/}
        {/*           marginTop: 20,*/}
        {/*           marginHorizontal: 30*/}
        {/*       }}*/}
        {/*       color={theme.colors.grey}>*/}
        {/*    {}*/}
        {/*</TextM>*/}


        {/*<View style={{height: 100}}/>*/}

    </View>


}
const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeProgressCongrats)