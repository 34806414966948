import {ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {IconImageM} from "@common/components/icons/IconsM";
import {PetalCounter} from "@common/components/statistics/Counters";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {theme} from "@common/theme/theme";
import React, {useContext} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";

const ShopProductItemDetails = (props) => {

    const {shopProduct, onPurchase, loading} = props

    const notEnoughPetals = props.user?.petals < shopProduct?.petals_value
    const dimensions = useContext(DimensionsContext)
    return <ScrollView style={{
        // maxHeight: dimensions?.height * 2 / 3
    }}>
        <View style={{
            alignItems: "center",
            paddingHorizontal: 15,
            paddingVertical: 20
        }}>
            <TextM fontWeight="Bold" fontSize={16} wrap style={{}}>{shopProduct?.name}</TextM>
            <TextM fontWeight="Medium" fontSize={null} wrap
                   style={{marginTop: 0, textAlign: "center"}}>{shopProduct?.short_description}</TextM>
            <IconImageM uri={shopProduct?.icon_path} width={150} style={{borderRadius: 15, marginTop: 10}}/>
            <TextM fontWeight="Medium" fontSize={null} wrap
                   style={{marginTop: 10, textAlign: "justify"}}>{shopProduct?.description}</TextM>

            <PetalCounter count={shopProduct?.petals_value} style={{marginTop: 15, marginBottom: 25}}/>
            {notEnoughPetals &&
                <TextM fontSize={12} wrap style={{marginBottom: 2}} color={theme.colors.light}>Tu n'as pas assez de
                    pétales</TextM>}

            <FilledButtonM
                onPress={onPurchase}
                loading={loading}
                disabled={notEnoughPetals}
                label="Acheter"
                color={theme.colors.petalNew}
                textColor={theme.colors.white}

                style={{
                    width: "100%",
                    alignSelf: "center",
                }}
            />

        </View>
    </ScrollView>;
}

const mapStateToProps = state => {
    return {

        user: state.data.currentUser?.object,
        shopProducts: state.data.staticData?.shop_products
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopProductItemDetails)