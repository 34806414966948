import axios from "axios";

export async function vaporStore(file, options = {}) {
    const response = await axios.post(options.signedStorageUrl ? options.signedStorageUrl : '/vapor/signed-storage-url', {
        'bucket': options.bucket || '',
        'content_type': options.contentType || file.type,
        'expires': options.expires || '',
        'visibility': options.visibility || '',
        ...options.data
    }, {
        baseURL: options.baseURL || null,
        headers: options.headers || {},
        ...options.options
    });

    let headers = response.data.headers;

    // if (typeof options.onUploadProgress === 'undefined') {
    //     options.onUploadProgress = () => {
    //     };
    // }

    // const cancelToken = options.cancelToken || ''

    // TODO : add upload progress
    // option 1 : keep fetch and add to it progress logic (library ?)
    // option 2 : use XMLHttpRequest https://finnian.io/blog/uploading-files-to-s3-react-native-ruby-on-rails/ and https://gist.github.com/developius/1fa35f2192b886dfce4e7f4eaed8b923#file-axios_gcs_expo_example-ts
    await fetch(response.data.url, {
        method: 'PUT',
        headers: headers,
        body: file
    })
        .catch(error => {
            console.log('Error:', JSON.stringify(error, null, 2))
            return Promise.reject(error)
        })

    // delete axios.defaults.headers.common['Authorization']
    //
    // await axios.put(response.data.url, file, {
    //     // cancelToken: cancelToken,
    //     headers,
    //     onUploadProgress: options.onUploadProgress
    // })

    // response.data.extension = file._data.name.split('.').pop()

    return response.data;
}