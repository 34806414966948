import {theme} from "../../theme/theme";
import {MenuM} from "./MenuM";
import React, {useContext, useEffect, useState} from "react";
import {View} from "react-native";
import {IconTextM} from "../icons/IconTextM";
import {DimensionsContext} from "../../utils/mobileUtils";
import {SelectModalM} from "../select/selectSingle-Multiple";
import TextM from "../text/TextM";
import {allOptionsFromSectionOptions, isSameArray} from "@data/utility/Utils";

export const MenuFilter = ({
                               filterItems,
                               position,
                               style,
                               formValues,
                               setFormValues,
                               onFinishSelecting,
                               noMenu = false,
                           }) => {

    const dimensions = useContext(DimensionsContext)

    const defaultValues = filterItems?.reduce((pValue, cValue) => {
        // console.log("cValue?.defaultValue", cValue?.defaultValue)
        const nValue = {...pValue}
        nValue[cValue?.name] = cValue?.defaultValue ?? null
        return nValue
    }, {})
    const width = style?.width ?? dimensions?.width

    // console.log("defaultValues", defaultValues)

    useEffect(() => {
        //console.log("defaultValues", defaultValues)
        if (!formValues)
            setFormValues(defaultValues)
    }, [])

    const [modalVisible, setModalVisible] = useState(false)
    const [currentItemIndex, setCurrentItemIndex] = useState(-1)
    const currentTitle = currentItemIndex >= 0 ? "Filtrer par " + filterItems?.[currentItemIndex]?.label : ""
    const currentOptions = currentItemIndex >= 0 ? filterItems?.[currentItemIndex]?.options : []
    const currentSelectedValues = currentItemIndex >= 0 ? formValues?.[filterItems?.[currentItemIndex]?.name] : []
    const currentSectionMode = currentItemIndex >= 0 ? filterItems?.[currentItemIndex]?.sectionMode : false

    const onMultiSelect = (item) => {

        if (currentItemIndex < 0) return

        // console.log("value", item)
        // console.log("currentItemIndex", currentItemIndex)

        if (currentSelectedValues?.includes(item?.value)) {
            // console.log("included")
            const x = [
                ...currentSelectedValues
            ]

            const index = x.indexOf(item?.value);
            x.splice(index, 1);
            // console.log("x", currentSelectedValues)
            setFormValues({
                ...formValues,
                [filterItems?.[currentItemIndex]?.name]: x
            })
        } else {
            // console.log("not included")
            const x = [
                ...currentSelectedValues,
                item?.value
            ]
            // console.log("x", x)
            setFormValues({
                ...formValues,
                [filterItems?.[currentItemIndex]?.name]: x
            })
        }
        // console.log("currentSelectedValues", currentSelectedValues)
    }

    const selectMultipleItems = (items, forceDeselect = false) => {
        if (currentItemIndex < 0) return

        const x = [
            ...currentSelectedValues
        ]
        items?.forEach(item => {
            if (forceDeselect && currentSelectedValues?.includes(item?.value)) {
                const index = x.indexOf(item?.value);
                x.splice(index, 1);
            } else if (!currentSelectedValues?.includes(item?.value)) {
                x?.push(item?.value)
            }
        })
        setFormValues({
            ...formValues,
            [filterItems?.[currentItemIndex]?.name]: x
        })

    }

    const menuItems = [
        ...(filterItems.map((filterItem, index) => {
            return {
                ...filterItem,
                title: filterItem.label,
                // title, disabled, options (=icon, name, value)
                onPress: () => {
                    setCurrentItemIndex(index)
                    setModalVisible(true)
                }
            }
        })),
        {
            type: "divider"
        },
        {
            title: "Effacer",
            icon: "times",
            // title, disabled, options (=icon, name, value)
            onPress: () => {
                // show select Modal for choosing filters
                setFormValues(defaultValues)
                onFinishSelecting(defaultValues)
            }
        }
    ]


    const renderButton = () => {


        const isFilterCleared = filterItems?.reduce((pValue, cValue) => pValue && isSameArray(allOptionsFromSectionOptions(cValue?.options), formValues?.[cValue?.name]), true);

        return <View
            style={{width: width - 20, flexDirection: "row", alignItems: "center", alignSelf: "center"}}>
            <IconTextM text={isFilterCleared ? `Filtrer` : ""}
                       icon={"filter"}
                       style={{
                           width: null,
                           marginTop: 10,
                           marginBottom: 10
                       }}
                       textStyle={{width: null}}
                       color={theme.colors.light}/>
            {filterItems.map((filterItem, index) => {
                if (!isSameArray(allOptionsFromSectionOptions(filterItem?.options), formValues?.[filterItem?.name]) && formValues?.[filterItem?.name]?.length > 0)
                    return <View key={index} style={{
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: theme.colors.light,
                        padding: 5,
                        marginLeft: 5,
                        alignSelf: "center"
                    }}>
                        <TextM>{filterItem?.label}</TextM>
                    </View>
                else
                    return null
            })}
        </View>
    }

    return <View style={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        width: width,
        // backgroundColor: "red",
        marginTop: 20,
        ...style
        // marginTop: 10
    }}>
        {!noMenu && <MenuM buttonComponent={renderButton()}
                           items={menuItems}
                           position={position}
                           style={{
                               alignSelf: "flex-end"
                           }}
        />}
        <>
            <SelectModalM isMulti
                          valueMode
                          sectionMode={currentSectionMode}
                          modalVisible={modalVisible}
                          setModalVisible={setModalVisible}
                          title={currentTitle}
                          options={currentOptions}
                          itemHeight={70}
                          selectedValues={currentSelectedValues}
                          onMultiSelect={onMultiSelect}
                          selectMultipleItems={selectMultipleItems}
                          onFinishSelecting={() => {
                              setCurrentItemIndex(-1)
                              onFinishSelecting()
                          }}
            />
        </>
    </View>
}