import React, {useContext, useState} from "react";
import {isEmpty} from "lodash";
import {TouchableOpacity, View} from "react-native";
import {IconButton} from "react-native-paper";

import {ListGroupM} from "../list/ListGroupM";
import {ListItemM} from "../list/ListItemM";
import {DimensionsContext, IS_WEB} from "../../utils/mobileUtils";
import {ModalM} from "../modal/ModalM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "../text/TextM";
import {theme} from "../../theme/theme";
import FilledButtonM from "../button/FilledButtonM";

export const SelectModalM = (props) => {

    const {
        isMulti,
        modalVisible,
        setModalVisible,
        sectionMode = false,
        title,
        options,
        itemHeight = 70,
        selectedValues = [],
        selectedValue,
        onMultiSelect,
        onSingleSelect,
        selectMultipleItems,
        onFinishSelecting = () => {
        },
        valueMode = false,
        titleRightButtonIcon = null,
        onTitleRightButtonPress = () => {
        },
        withClipBoard
    } = props


    return <ModalM
        style={{
            justifyContent: "center",
            alignItems: "center",
            height: "95%",
            width: "100%"
            // flex: 1,
            // paddingTop: insets.top
        }}
        visible={modalVisible}
        onDismiss={() => {
            setModalVisible(!modalVisible)
            onFinishSelecting()
        }}
    >
        <IconButton
            icon={"times"}
            iconColor={theme.colors.primary}
            style={{
                alignSelf: "flex-start"
            }}
            size={32}
            onPress={() => {
                setModalVisible(!modalVisible)
                onFinishSelecting()
            }}
        />

        <View style={{
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            // paddingHorizontal: 15,
            // paddingVertical: 20,
            width: "100%",
            paddingHorizontal: 20
            // flex:1,
            // height: 50
        }}>
            <TextM
                fontSize={22}
                fontWeight={"Bold"}
                color={theme.colors.primary}
                style={{
                    flex: 1
                    // marginBottom: 10
                }}
                wrap
            >
                {title}
            </TextM>
            {titleRightButtonIcon && <IconButton
                size={30}
                icon={titleRightButtonIcon}
                iconColor={theme.colors.primary}
                style={{
                    borderWidth: 1,
                    borderRadius: 15,
                    borderColor: theme.colors.lightMore
                }}
                onPress={() => {
                    setModalVisible(!modalVisible)
                    onTitleRightButtonPress()
                }}
            />}
        </View>

        <View style={{
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: 5,
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors.white,
            paddingHorizontal: 20
        }}>

            {isMulti ? (
                <ListGroupM
                    options={options}
                    style={{marginBottom: 30}}
                    itemHeight={itemHeight}
                    sectionMode={sectionMode}
                    renderSectionHeader={({section: {title}}) => {

                        const sectionData = options?.find(option => option?.title === title)?.data
                        const isAllSectionSelected = sectionData?.reduce((pValue, cValue) => pValue && selectedValues?.includes(cValue?.value), true)

                        return <TouchableOpacity style={{
                            backgroundColor: theme.colors.lightMore,
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 15,
                            paddingBottom: 10,
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row"
                        }}
                                                 onPress={() => {
                                                     if (isAllSectionSelected)
                                                         selectMultipleItems(sectionData, true)
                                                     else
                                                         selectMultipleItems(sectionData)
                                                 }}
                        >
                            <TextM fontWeight={"Medium"}>{title}</TextM>
                            <FontAwesome5 size={22}
                                          solid={isAllSectionSelected}
                                          name={isAllSectionSelected ? "check-square" : "square"}
                                          color={theme.colors.grey}
                            />
                        </TouchableOpacity>
                    }}
                    renderItem={(item) => {
                        const selected = (valueMode ? selectedValues : selectedValues?.map((x) => x.value))?.includes(item.item.value);
                        return (
                            <ListItemM
                                onPress={() => {
                                    onMultiSelect(item.item);
                                }}
                                itemHeight={item?.height ? item?.height : itemHeight}
                                selected={selected}
                                disabled={item.item?.disabled ?? false}
                                option={item.item}
                                withClipBoard={withClipBoard}
                            />
                        );

                    }}
                />
            ) : (
                <ListGroupM
                    options={options}
                    style={{
                        marginBottom: 40
                    }}
                    itemHeight={itemHeight}
                    renderItem={(item) => {
                        return (
                            <ListItemM
                                onPress={() => {
                                    onSingleSelect(item.item)
                                    setModalVisible(!modalVisible)
                                    onFinishSelecting()
                                }}
                                isLast={(item.index === options?.length - 1)}
                                itemHeight={item?.height ? item?.height : itemHeight}
                                selected={item.item?.value === (valueMode ? selectedValue : selectedValue?.value)}
                                option={item.item}
                                withClipBoard={withClipBoard}
                            />
                        );
                    }}
                />
            )}
        </View>
        {isMulti && (
            <FilledButtonM
                style={{marginRight: 20, marginLeft: 20, marginBottom: 70, width: "90%"}}
                onPress={() => {
                    setModalVisible(!modalVisible)
                    onFinishSelecting()
                }}
                label={"Terminer"}
            />
        )}
    </ModalM>

}

function SelectBox({
                       title,
                       onSingleSelect,
                       onMultiSelect,
                       isMulti,
                       options,
                       value: selectedValue,
                       selectedValues,
                       valueMode = false,
                       titleRightButtonIcon,
                       onTitleRightButtonPress,
                       style,
                       withClipBoard,
                       hideTitle = false,
                       buttonStyle,
                       iconComponent,
                       defaultLabel = "",
                       clearButton = false
                   }) {
    const [modalVisible, setModalVisible] = useState(false);

    function onPressShowOptions() {
        setModalVisible(!modalVisible);
    }

    const dimensions = useContext(DimensionsContext)
    //const insets = useSafeAreaInsets();

    const currentLabel = valueMode ? options?.find(x => x?.value === selectedValue)?.label ?? defaultLabel : selectedValue?.label ?? defaultLabel

    function renderChip(selectedValue) {

        const currentFullItem = valueMode ? options?.find(x => x?.value === selectedValue) : selectedValue
        const currentLabel = currentFullItem?.label

        return (
            <View style={{
                flexDirection: "row",
                borderRadius: 20,
                paddingVertical: 5,
                paddingRight: 10,
                paddingLeft: 10,
                marginRight: 4,
                marginBottom: 2,
                marginTop: 2,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.colors.primary
            }} key={(valueMode ? selectedValue : selectedValue?.value)}>
                <TextM style={{
                    fontSize: 10,
                    textAlign: "center"
                }} color={theme.colors.white} fontSize={12}>
                    {currentLabel}
                </TextM>
            </View>
        );
    }

    return (
        <>
            <View
                style={{
                    marginBottom: 10,
                    width: IS_WEB && dimensions?.width > 800 ? dimensions?.width / 2 : "100%",
                    alignSelf: "center",
                    ...style
                }}
            >
                {!hideTitle && <TextM style={{
                    fontSize: 13,
                    paddingBottom: 4
                }} color={theme.colors.grey}
                                      wrap>
                    {title}
                </TextM>}
                <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={onPressShowOptions}
                >
                    <View style={{
                        flexDirection: "row",
                        width: "100%",
                        backgroundColor: theme.colors.greyBg,
                        borderRadius: 15,
                        borderColor: theme.colors.light,
                        borderWidth: 1,
                        padding: 12,
                        justifyContent: "space-between",
                        // flexWrap: "wrap",
                        ...buttonStyle
                    }}>
                        <View style={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            {iconComponent && iconComponent}
                            {isMulti ? (
                                selectedValues?.length > 0
                                    ? selectedValues?.map((selectedValue) => renderChip(selectedValue))
                                    : <TextM style={{
                                        borderColor: theme.colors.primary,
                                        fontSize: 14,
                                        color: isEmpty(valueMode ? selectedValue : selectedValue?.label) ? "rgba(60, 60, 67, 1)" : "#000",
                                        marginVertical: 7
                                    }} wrap/>
                            ) : (
                                <TextM style={{
                                    borderColor: theme.colors.primary,
                                    fontSize: 14,
                                    color: isEmpty(currentLabel) ? "rgba(60, 60, 67, 1)" : "#000",
                                    marginVertical: 7
                                }}>
                                    {currentLabel ?? " "}
                                </TextM>
                            )}
                        </View>
                        {clearButton && (isMulti ? selectedValues?.length > 0 : (!!selectedValue && selectedValue !== "")) &&
                            <TouchableOpacity
                                style={{
                                    paddingHorizontal: 8,
                                    paddingVertical: 5,
                                    marginRight: -8
                                }}
                                onPress={() => {
                                    isMulti ? onMultiSelect([]) : onSingleSelect({value: "", label: ""})
                                }}>
                                <FontAwesome5 name={"times-circle"}
                                              color={theme.colors.greyAlt}
                                              size={20}/>
                            </TouchableOpacity>}
                    </View>
                </TouchableOpacity>

                <>
                    <SelectModalM isMulti={isMulti}
                                  modalVisible={modalVisible}
                                  setModalVisible={setModalVisible}
                                  title={title}
                                  selectedValue={selectedValue}
                                  options={options}
                                  valueMode={valueMode}
                                  selectedValues={selectedValues}
                                  onMultiSelect={onMultiSelect}
                                  onSingleSelect={onSingleSelect}
                                  titleRightButtonIcon={titleRightButtonIcon}
                                  onTitleRightButtonPress={onTitleRightButtonPress}
                                  withClipBoard={withClipBoard}
                    />
                </>
            </View>
        </>
    );
}

export default SelectBox;
