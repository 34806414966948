import React, {useState} from 'react';
import {View} from "react-native";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {validation} from "@data/utility/validation";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";

function ChangeEmailStepper(props) {
    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});
    const defaultValues = {}
    const formData = [
        {
            // is_masked: !currentChallenge?.needs_school_subject,
            label: "Choisis une nouvelle adresse email",
            type: "text.multiple", // select.single, select.multiple, text, date
            inputs: [
                {
                    name: "email",
                    placeholder: "Adresse email",
                    keyboardType: "email-address",
                    defaultValue: props.user?.email,
                    liveCheck: {service: authService.checkEmailAddressAuth},
                    validationRules: {
                        ...validation.required(),
                        ...validation.email()
                    }
                }
            ]
        },
    ]
    const onSubmit = data => {
        if (props.updateEmailRequest || props.updateEmailError) return
        props.apiRequest(authService.updateEmail, {}, data)
    }

    useApiStatus(
        authService.updateEmail, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            props.apiRequest(authService.getUser)
            if (navigation.canGoBack())
                navigation.goBack()
            else
                navigation.replace("menu")
        } // success callback
    )
    return (
        <View style={{
            flex: 1,
            // backgroundColor: '#fff',
            alignItems: 'center'
            // justifyContent: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={formData?.filter(x => !x.is_masked)}
                         champion={{name: "tina", variant: "main"}}
                         formValues={formValues}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.updateEmailRequest}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.updateEmailError}
                         hideProgressBar
            />
        </View>);
}

const mapStateToProps = state => {
    return {
        updateEmailRequest: state.api.updateEmail?.request,
        updateEmailError: state.api.updateEmail?.error,
        user: state.data.currentUser?.object,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailStepper)