import React, {useContext, useEffect, useRef, useState} from 'react';
import {Dimensions, Keyboard, Platform, ScrollView, View} from "react-native";
import TextInputM from "@common/components/input/TextInputM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import LinkTextM from "@common/components/text/LinkTextM";
import {useController, useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import * as Device from 'expo-device';
import ErrorTextM from "@common/components/text/ErrorTextM";
import {theme} from "@common/theme/theme";
import {StatusBar} from "expo-status-bar";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {ActivityIndicator, Checkbox, IconButton} from "react-native-paper";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {ChampionImageM} from "../../champions/ChampionImageM";
import TitleM from "@common/components/text/TitleM";
import yup from "@data/utility/validation";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {subscriptionService} from "@data/services/subscriptionService";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {referralCodeRetrieve, TutorProfileCard} from "./StartTrialNewScreen";
import {hasRole} from "@data/utility/ability";
import IconButtonM from "@common/components/button/IconButtonM";
import PhoneInput from "@sesamsolutions/phone-input";
import * as amplitude from "@amplitude/analytics-react-native";
import RegisterTrialPopup from "@common/messenger/RegisterTrialPopup";

const formSchema = yup.object().shape({
    user_profile: yup.string().required(),
    email: yup.string().email().required(),
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    password: yup.string().required(),
    newsletter_opt_in: yup.boolean().required()
})

const RegisterTrialScreen = (props) => {

    const {navigation, route} = props

    const referralCode = route?.params?.ref ?? null
    const studentClass = route?.params?.student_class
    const subjectPriority = route?.params?.subject_priority
    const liveRoomIdOrSlug = route?.params?.live_room
    const tutorId = route?.params?.tutor_id === "null" ? null : route?.params?.tutor_id

    const user = props.user

    useEffect(() => {
        referralCodeRetrieve(navigation, route).then()
    }, []);


    if (!studentClass || !liveRoomIdOrSlug || (!tutorId && parseInt(liveRoomIdOrSlug) !== 0) || (user && !hasRole(user, "student")))
        return <View style={{
            alignItems: 'center',
            flex: 1,
            padding: 20
        }}>

            <ChampionImageM name="zoe" variant={"unhappy"} width={140}/>
            <TitleM fontWeight="ExtraBold"
                    style={{textAlign: "center", marginTop: 10, color: theme.colors.primary}}
                    wrap>Une erreur s'est produite</TitleM>
            <TextM fontWeight="Medium" style={{textAlign: "center"}} wrap>Es-tu sûr.e d'être au bon endroit ?</TextM>
            {/*<LogoutButton noVersion />*/}
            <FilledButtonM
                onPress={() => {
                    if (navigation.canGoBack())
                        navigation.goBack()
                    else
                        navigation.navigate("menu")
                }}
                mode={"text"}
                label={"Retour"}
            />

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>

    const [availableLiveRooms, setAvailableLiveRooms] = useState([])

    // group available live rooms by tutor
    const tutors = availableLiveRooms?.reduce((acc, curr) => {

        const foundTutor = acc.find(x => x?.id === curr?.tutor?.id)

        if (!foundTutor) {
            acc.push({
                ...curr?.tutor,
                live_rooms: [curr]
            })
        } else {
            foundTutor?.live_rooms?.push(curr)
        }
        return acc

    }, [])

    const tutor = findItemInListWithId(tutorId, tutors)
    const liveRoom = liveRoomIdOrSlug ? findItemInListWithId(liveRoomIdOrSlug, tutor?.live_rooms, "slug") : null

    useEffect(() => {
        if (!studentClass) return

        props.apiRequest(subscriptionService.getLiveRoomsAvailableForTrial, {}, {}, {
            student_class: studentClass,
            subject_priority: subjectPriority ?? null
        })
    }, [studentClass, subjectPriority])

    useApiStatus(
        subscriptionService.getLiveRoomsAvailableForTrial, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            setAvailableLiveRooms(data)
        } // success callback
    )

    const resolver = yupResolver(formSchema);
    const dimensions = useContext(DimensionsContext)

    const [lastInputTime, setLastInputTime] = useState(Date.now());
    const handleInputChange = () => {
        setLastInputTime(Date.now());
    };
    const {control, handleSubmit, formState: {errors, isDirty, dirtyFields}} = useForm({
        resolver,
        defaultValues: {
            email: user?.email ?? '',
            password: !user ? '' : 'test1234',
            firstname: user?.firstname ?? '',
            lastname: user?.lastname ?? '',
            newsletter_opt_in: false,
            user_profile: "student",
            'device_name': Device.deviceName ?? "WEB",
            student_class: studentClass,
            subject_priority: subjectPriority,
            live_room: liveRoomIdOrSlug,
            parent_mobile: user?.parent_mobile ?? '',
            referral_code: referralCode
        }
    })

    const [submittedOnce, setSubmittedOnce] = useState(false)

    const {field: newsletterOptInField} = useController({
        name: "newsletter_opt_in",
        control
    })

    const {field: parentMobileField} = useController({
        name: "parent_mobile",
        control
    })

    const service = !props.user ? authService.registerTrialV2 : subscriptionService.subscribeToTrialV2
    const request = !props.user ? props.registerTrialV2Request : props.subscribeToTrialV2Request

    const onSubmit = data => {
        setSubmittedOnce(true)

        // console.log(data)

        if (request) {
            return
        }
        if (!parentMobileField.value) {
            return
        }
        Keyboard.dismiss()

        props.apiRequestWithPrerequest(service, {}, data, {})
    }

    useApiStatus(
        service, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {

            // const dataUser = data?.user ?? data

            // console.log(data)

            if (IS_WEB) {
                // console.log("EVENT SUBSCRIBE SUCCESS")

                // Déclencher l'événement pour GTM
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'subscribe_to_trial_success' // Nom de l'événement personnalisé
                });
            }

            amplitude.track("register.trial.success")

            navigation.goBack()


        } // success callback
    )

    const insets = useSafeAreaInsets()

    const scrollViewRef = useRef()
    const lastnameRef = useRef()
    const emailRef = useRef()
    const passwordRef = useRef()

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.whiteAlmost,
            justifyContent: 'flex-start',
            paddingTop: insets.top,
            // width: Platform.OS === 'web' ? dimensions?.width : null,
            alignSelf: Platform.OS !== 'web' ? null : "center",
            alignItems: "center",
            width: "100%"
        }}>
            {props.getLiveRoomsAvailableForTrialRequest
                ? <ActivityIndicator color={theme.colors.primary} size={"small"} style={{marginTop: 20}}/>
                : <ScrollView ref={scrollViewRef}
                              contentContainerStyle={{
                                  paddingBottom: 200,
                                  width: Dimensions.get('screen').width,
                                  alignItems: "center"
                              }}
                >

                    <View style={{
                        paddingHorizontal: 15,
                        // paddingTop: 20,
                        width: dimensions?.width,
                        alignItems: "center"
                    }}>

                        <IconButton
                            icon={"arrow-left"}
                            iconColor={theme.colors.primary}
                            size={32}
                            style={{
                                alignSelf: "flex-start"
                                // left: 4
                            }}
                            onPress={() => {
                                navigation.replace(props.user ? "start-trial" : "register-trial", {
                                    student_class: studentClass,
                                    subject_priority: subjectPriority
                                })
                            }}
                        />

                        {!tutorId
                            ? <>
                            </>
                            : <>
                                <TextM fontSize={18}
                                       fontWeight={"Bold"}
                                       color={theme.colors.success}
                                       style={{textAlign: "center", marginBottom: -18}}
                                       wrap
                                >RENDEZ-VOUS SÉLECTIONNÉ</TextM>

                                <TutorProfileCard tutor={tutor}
                                                  liveRoom={liveRoom}
                                                  style={{
                                                      marginBottom: 10
                                                  }}
                                />

                                <IconButtonM
                                    size={40}
                                    icon={"angle-double-down"}
                                    iconColor={theme.colors.primary}
                                    onPress={() => {
                                        // scroll to index 0
                                        scrollViewRef.current?.scrollTo({
                                            animated: true,
                                            x: 0,
                                            y: 300
                                        })
                                    }}
                                />
                            </>}

                        <TextM fontSize={24}
                               fontWeight={"ExtraBold"}
                               color={theme.colors.primary}
                               style={{textAlign: "center", marginBottom: 20}}
                               wrap
                        >{!tutorId ? "Je demande un nouveau groupe" : "Je confirme le rendez-vous et je démarre mon essai"}</TextM>

                        {!props.user && <>

                            {/*<FilledButtonM*/}
                            {/*    style={{marginTop: 20}}*/}
                            {/*    icon={"facebook"}*/}
                            {/*    buttonColor={"#3b5998"}*/}
                            {/*    disabled*/}
                            {/*    // disabled={!dirtyFields.email || !dirtyFields.password}*/}
                            {/*    // onPress={handleSubmit(onSubmit)}*/}
                            {/*    loading={props.loggingIn || props.loggingInWeb}*/}
                            {/*    label={"S'inscrire avec Facebook"}*/}
                            {/*/>*/}
                            {/*<FilledButtonM*/}
                            {/*    style={{marginTop: 10}}*/}
                            {/*    icon={"google"}*/}
                            {/*    buttonColor={"#db4a39"}*/}
                            {/*    disabled*/}
                            {/*    // disabled={!dirtyFields.email || !dirtyFields.password}*/}
                            {/*    // onPress={handleSubmit(onSubmit)}*/}
                            {/*    loading={props.loggingIn || props.loggingInWeb}*/}
                            {/*    label={"S'inscrire avec Google"}*/}
                            {/*/>*/}
                            {/*<View style={{*/}
                            {/*    width: !IS_WEB || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,*/}
                            {/*    marginVertical: 25*/}
                            {/*}}>*/}
                            {/*    <Divider/>*/}
                            {/*    <View style={{*/}
                            {/*        backgroundColor: theme.colors.whiteAlmost,*/}
                            {/*        position: "absolute",*/}
                            {/*        alignSelf: "center",*/}
                            {/*        top: -8,*/}
                            {/*        paddingHorizontal: 8*/}
                            {/*    }}>*/}
                            {/*        <TextM fontSize={14}*/}
                            {/*               fontWeight={"Bold"}*/}
                            {/*               color={theme.colors.light}*/}
                            {/*               wrap*/}
                            {/*        >ou</TextM>*/}
                            {/*    </View>*/}
                            {/*</View>*/}

                            <View style={{
                                width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4
                            }}>
                                <TextM style={{alignSelf: "flex-start"}}>Téléphone portable d'un parent</TextM>
                                <PhoneInput
                                    initialCountry="FR"
                                    dismissKeyboard={false}
                                    textStyle={{
                                        fontFamily: "Montserrat-Regular",
                                        fontSize: 16
                                    }}
                                    style={{
                                        width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                                        borderColor: (!parentMobileField.value && submittedOnce) ? theme.colors.error : theme.colors.light,
                                        borderWidth: 1,
                                        borderRadius: theme.roundness,
                                        height: 55,
                                        alignItems: "center",
                                        paddingHorizontal: 10
                                    }}
                                    onChange={(data) => {
                                        parentMobileField.onChange(data?.e164)
                                        handleInputChange()
                                    }}/>
                                {!parentMobileField.value && submittedOnce &&
                                    <ErrorTextM errorData={{message: "Numéro de téléphone invalide"}}
                                                style={{textAlign: "center"}} marginTop={3}/>}
                            </View>

                            {/*<TextInputM*/}
                            {/*    placeholder={`Téléphone portable d'un parent`}*/}
                            {/*    name="parent_mobile"*/}
                            {/*    keyboardType="phone-pad"*/}
                            {/*    control={control}*/}
                            {/*    errors={errors}*/}
                            {/*/>*/}

                            <TextInputM
                                placeholder={`Prénom de l'élève`}
                                labelTop={`Prénom de l'élève`}
                                // label={`Adresse email de l'élève`}
                                name="firstname"
                                control={control}
                                errors={errors}
                                contentContainerStyle={{marginTop: 10}}
                                onSubmitEditing={(e) => {
                                    lastnameRef.current?.focus()
                                }}
                                onChangeAdditional={handleInputChange}
                            />
                            <TextInputM
                                placeholder={`Nom de l'élève`}
                                labelTop={`Nom de l'élève`}
                                // label={`Adresse email de l'élève`}
                                name="lastname"
                                ref={lastnameRef}
                                control={control}
                                errors={errors}
                                contentContainerStyle={{marginTop: 10}}
                                onSubmitEditing={(e) => {
                                    emailRef.current?.focus()
                                }}
                                onChangeAdditional={handleInputChange}
                            />

                            <TextInputM
                                placeholder={`Adresse email de l'élève`}
                                labelTop={`Adresse email de l'élève`}
                                // label={`Adresse email de l'élève`}
                                name="email"
                                ref={emailRef}
                                textContentType="emailAddress"
                                keyboardType="email-address"
                                liveCheck={{service: authService.checkEmailAddress}}
                                liveCheckPreRequest={true}
                                control={control}
                                errors={errors}
                                contentContainerStyle={{marginTop: 10}}
                                onSubmitEditing={(e) => {
                                    passwordRef.current?.focus()
                                }}
                                onChangeAdditional={handleInputChange}
                            />

                            <TextInputM
                                placeholder="Mot de passe"
                                ref={passwordRef}
                                labelTop="Mot de passe"
                                // label="Mot de passe"
                                name="password"
                                control={control}
                                password
                                errors={errors}
                                contentContainerStyle={{marginTop: 10}}
                                onChangeAdditional={handleInputChange}
                            />

                            <Checkbox.Item label="J’accepte de recevoir des actualités et des offres de Masteur"
                                           position={'leading'}
                                           onPress={() => {
                                               newsletterOptInField.onChange(!newsletterOptInField.value)
                                           }}
                                           color={theme.colors.primary}
                                           labelStyle={{
                                               fontSize: 14,
                                               textAlign: "left"
                                           }}
                                           style={{
                                               marginHorizontal: 5,
                                               marginTop: 5
                                           }}
                                           status={newsletterOptInField.value ? "checked" : "indeterminate"}/>
                        </>}

                        <ErrorTextM errorData={props.error} style={{textAlign: "center"}}/>

                        {/*    <TextM fontSize={14}*/}
                        {/*           style={{marginHorizontal: 20, textAlign: "center", marginTop: 40}}*/}
                        {/*           color={theme.colors.grey}*/}
                        {/*           fontWeight="Light"*/}
                        {/*           wrap>{"Sans engagement."}</TextM>*/}
                        {/*    <TextM fontSize={14}*/}
                        {/*           style={{marginHorizontal: 20, textAlign: "center", marginTop: 10, marginBottom:2}}*/}
                        {/*           color={theme.colors.grey}*/}
                        {/*           fontWeight="Light"*/}
                        {/*           wrap>{"Pas besoin de carte bancaire."}</TextM>*/}
                        <FilledButtonM
                            style={{}}
                            // disabled={!dirtyFields.email || !dirtyFields.password}
                            onPress={handleSubmit(onSubmit)}
                            loading={request}
                            disabled={request}
                            label={"Démarrer mon essai"}
                            // label={props.loggingIn ? "" : "Connexion"}
                        />
                        <View style={{
                            marginBottom: 20,
                            marginTop: 10,
                            paddingHorizontal: 10
                        }}>

                            <TextM style={{
                                fontSize: 12,
                                textAlign: 'center'
                            }} wrap>
                                En continuant, j'accepte les <LinkTextM url="https://masteur.com/cgu">Conditions
                                générales d'utilisation</LinkTextM> de Masteur. Je confirme avoir plus de 15 ans. Dans
                                le cas
                                contraire, je confirme avoir l'autorisation d'un parent pour utiliser ce service.
                            </TextM>
                        </View>

                    </View>
                    {!props.user && <>
                        <View style={{
                            marginBottom: 50,
                            marginTop: 30,
                            paddingHorizontal: 10,
                            justifySelf: "flex-end"
                        }}>
                            <TextM style={{alignSelf: 'center'}}>
                                Dèja un compte ? <LinkTextM onPress={() => {
                                navigation.navigate("login")
                            }
                            }>Je me connecte</LinkTextM>
                            </TextM>
                        </View>
                    </>}

                </ScrollView>}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
            <RegisterTrialPopup referralCode={referralCode} lastInputTime={lastInputTime} resolver={resolver}/>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        getLiveRoomsAvailableForTrialRequest: state.api.getLiveRoomsAvailableForTrial?.request,
        subscribeToTrialV2Request: state.api.subscribeToTrialV2?.request,
        callMeBackRequest: state.api.callMeBack?.request,
        getLiveRoomsAvailableForTrialError: state.api.getLiveRoomsAvailableForTrial?.error,
        registerTrialV2Request: state.api.registerTrialV2?.request,
        registerTrialV2Success: state.api.registerTrialV2?.success,
        registerTrialV2Error: state.api.registerTrialV2?.error,
        user: state.data.currentUser?.object,
        token: state.data.session?.object?.token
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTrialScreen)