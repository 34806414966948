import React, {useState} from 'react';
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {FormStepper} from "@common/components/stepper/FormStepper";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {validation} from "@data/utility/validation";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {authService} from "@data/services/authService";
import {studentClasses} from "@data/constants/formConstants";
import {congratsTypes} from "@common/screens/CongratsScreen";

const UpdateClassScreen = (props) => {
    const navigation = useNavigation()

    const [formValues, setFormValues] = useState(null)
    const user = props.user
    useApiStatus(
        authService.updateClass, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Ta classe a été mise à jour !"
                }
            }]
            navigation.replace("congrats", {congrats})
        }
    )

    const defaultValues = {}
    const formData = [
        {
            label: "En quelle classe es-tu ?",
            type: "select.single",
            name: "student_class",
            options: studentClasses?.map(x => {
                return {value: x, label: x}
            }),
            defaultValue: user?.profile?.class,
            validationRules: {...validation.required()}
        },]
    const onSubmit = data => {
        if (props.updateClassRequest) return
        props.apiRequest(authService.updateClass, {}, data, {})
    }
    return <View style={{
        flex: 1,
        // backgroundColor: '#fff',
        alignItems: 'center'
        // justifyContent: 'center'
    }}>
        <FormStepper navigation={navigation}
                     track
                     formData={formData?.filter(x => !x.is_masked)}
                     champion={null}
                     setFormValues={setFormValues}
                     defaultValues={defaultValues}
                     onSubmit={onSubmit}
                     submitted={props.updateClassRequest}
                     previousRouteName={""}
                     submittedMessage=""
                     serverError={props.updateClassError}
                     submitButtonText="Terminer"
        />
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </View>;
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        tutors: state.data?.tutors?.list,
        users: state.data?.users?.usersList,
        assignTutorRequest: state.api?.assignTutor?.request,
        assignTutorError: state.api?.assignTutor?.error,
        getUserBackofficeRequest: state.api?.getUserBackoffice?.request,
        updateClassRequest: state.api.updateClass?.request,
        updateClassError: state.api.updateClass?.error,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClassScreen)