import {View} from "react-native";
import React, {useContext} from "react"
import {weekDayNums, weekDaysShort} from "@data/constants/activitiesConstants";
import {capitalize} from "@data/utility/Utils";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";

export const arrayRotate = (arr, count) => {
    count -= arr.length * Math.floor(count / arr.length)
    arr.push.apply(arr, arr.splice(0, count))
    return arr
}
export const twoWeeksAgoValues = (d) => (d ? Object.values(d).slice(0, 7) : null)
export const lastWeekValues = (d) => (d ? Object.values(d).slice(7, 14) : null)
export const thisWeekValues = (d) => (d ? Object.values(d).slice(14) : null)
const ActivityDaysStatistics = ({label, data, endOfWeekDay, color, withDivider}) => {

    const startOfWeekDayNum = (weekDayNums[capitalize(endOfWeekDay ?? 'sunday')] + 1) % 7

    const dayLabels = arrayRotate(Object.values(weekDaysShort), startOfWeekDayNum)
    const dimensions = useContext(DimensionsContext)
    return (
        <View style={{
            // borderRightWidth: withDivider && IS_WEB && dimensions.width > 800 ? 1 : 0,
            // borderLeftWidth: withDivider && IS_WEB && dimensions.width > 800 ? 1 : 0,
            borderColor: theme.colors.lightMore,

        }}>
            <TextM style={{paddingLeft: 10}} color={color} fontWeight={"Bold"}>{label}</TextM>
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',

            }}>

                {dayLabels?.map((day, index) => {

                    return <View key={index} style={{padding: 3, alignItems: 'center',}}>
                        <TextM fontSize={15}>{day}</TextM>
                        {(index <= data?.length - 1) ? (data[index] ?
                                <FontAwesome5 solid name="check-circle" size={25} color={color}/>
                                : <FontAwesome5 name="circle" size={25} color={"#EEEEEE"}/>)
                            : <FontAwesome5 name="circle" size={25} color={"#FFFFFF"}/>}
                    </View>
                })}
            </View>
        </View>
    )

}
export const statisticsColors = {
    duration: "#FC6249",
    count: "#C4115F",
    score: "#FFB831",
    day: "#008CEC"
}

export default function ActivityDaysSinceLastWeek({data, endOfWeekDay}) {

    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flexDirection: IS_WEB && dimensions.width > 800 ? 'row' : 'column',

            borderColor: theme.colors.lightMore,
            borderWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 20,
            paddingTop: 20,
            marginBottom: 20,
            flexWrap: IS_WEB && dimensions.width > 800 ? 'wrap' : null
        }}>
            <ActivityDaysStatistics data={twoWeeksAgoValues(data)}
                                    endOfWeekDay={endOfWeekDay} color={"#BBBBBB"}
                                    label="Il y a 2 semaines"

            />
            <ActivityDaysStatistics data={lastWeekValues(data)} endOfWeekDay={endOfWeekDay} color={"#BBBBBB"}
                                    label="La semaine dernière" withDivider/>

            <ActivityDaysStatistics data={thisWeekValues(data)} endOfWeekDay={endOfWeekDay}
                                    color={statisticsColors["day"]}
                                    label="Cette semaine"/>
        </View>
    )
}