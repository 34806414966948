import React, {useContext} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {View} from "react-native";
import PricingColumnM from "./PricingColumnM";

export const PricingTableM = (props) => {
    const {withCommitment, pricingTableData, pricingTableDataNextMode, userEmail, startNextMonth = false, onPress = null} = props
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        flexDirection: dimensions?.width < 800 ? "column" : "row",
        alignItems: dimensions?.width < 800 ? "center" : "stretch",
        flex: 1,
        marginTop: 60,
        paddingHorizontal: 100
    }}>
        {pricingTableData?.map((pricingColumn, index) => {

            return <PricingColumnM withCommitment={withCommitment}
                                   userEmail={userEmail}
                                   data={pricingColumn}
                                   onPress={onPress}
                                   nextModeData={pricingTableDataNextMode?.[0]}
                                   index={index}
                                   startNextMonth={startNextMonth}
                                   key={index}/>
        })}
    </View>;
}