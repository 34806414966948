import React, {useContext, useState} from 'react';
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {Tabs, TabScreen} from 'react-native-paper-tabs';
import {theme} from "@common/theme/theme";
import {Dimensions, Platform} from "react-native";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {IconButton} from "react-native-paper";
import TutorsApplicationsScreen from "./TutorsApplicationsScreen";
import ServerSearch from "../ServerSearch";
import {DimensionsContext} from "@common/utils/mobileUtils";

const TutorApplicationsTabNavigator = (props) => {

    const {navigation, route} = props
    const {initialTab} = route.params;
    const dimensions = useContext(DimensionsContext)
    const [urlParams, setUrlParams] = useState({})

    const tabs = [
        {
            name: "En cours",
            component: TutorsApplicationsScreen,
            tabIndex: 0,
            urlParams: {...urlParams, candidature_sent: 0}
        },
        {
            name: "Soumises",
            component: TutorsApplicationsScreen,
            tabIndex: 1,
            urlParams: {...urlParams, candidature_sent: 1, sort_by: "candidature_sent_at", order: "desc"}
        },
    ]


    return <>
        <HeaderContainerM style={{justifyContent: 'space-between'}}>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{}}
                onPress={() => {
                    if (navigation.canGoBack()) navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
            <TitleM>Liste des candidatures</TitleM>
            <ServerSearch style={{
                width: dimensions?.width / 4,
                marginBottom: 5,
                alignSelf: "flex-end"
            }}
                          urlParams={urlParams}
                          setUrlParams={setUrlParams}
            />
        </HeaderContainerM>
        <Tabs
            defaultIndex={initialTab ?? 1}
            style={{
                backgroundColor: theme.colors.white,
                width: Platform.OS === 'web' ? Dimensions.get("window").width : null,
                alignSelf: Platform.OS !== 'web' ? null : "center"
            }}
            disableSwipe={true}
        >
            {tabs.map((tab) => {
                return <TabScreen label={tab.name}
                                  key={tab.name}
                    // icon={<TextM>t</TextM>}
                >
                    {React.createElement(tab.component, {
                        name: tab?.name,
                        tab: tab?.tabIndex,
                        urlParams: tab?.urlParams
                    })}
                </TabScreen>
            })}

        </Tabs>
    </>

};

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        activityTemplates: state.data.staticData?.activity_templates
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(TutorApplicationsTabNavigator)