import React, {useContext, useState} from "react";
import FilledButtonM from "./FilledButtonM";
import {View} from "react-native";
import {theme} from "../../theme/theme";
import {DimensionsContext} from "../../utils/mobileUtils";

export const ToggleButtonM = props => {

    const {
        color = theme.colors.white,
        labels = [],
        subLabels = [],
        trackingIds = null,
        initialIndex = 0,
        onSelectIndex = (index) => {
        },
        style={},
        buttonsStyle={},
        labelStyle={},
        contentStyle={},
        disabled = false
    } = props
    const [selectedIndex, setSelectedIndex] = useState(initialIndex)
    const dimensions = useContext(DimensionsContext)

    return <View style={{flexDirection: "row",...style}}>
        {labels?.map((label, index) => {

            const isSelected = index === selectedIndex
            const isFirst = index === 0
            const isLast = index === labels?.length - 1


            return <FilledButtonM mode={!isSelected ? "contained" : "outlined"}
                                  trackingId={trackingIds?.[index]}
                                  key={index}
                                  disabled={disabled}
                                  style={{
                                      borderTopLeftRadius: isFirst ? 30 : 0,
                                      borderBottomLeftRadius: isFirst ? 30 : 0,
                                      borderTopRightRadius: isLast ? 30 : 0,
                                      borderBottomRightRadius: isLast ? 30 : 0,
                                      borderColor: isSelected ? theme.colors.primary : theme.colors.gray,
                                      opacity: isSelected ? 1 : 0.3,
                                      // borderRadius: isSelected ? 30 : 0,
                                      width: dimensions?.width / 4,
                                      // height: 40,
                                      ...buttonsStyle
                                  }}
                                  color={isSelected ? color : theme.colors.light}
                                  label={label}
                                  labelStyle={{
                                      fontSize:12,
                                      ...labelStyle
                                  }}
                                  contentStyle={{
                                      height: 40,
                                      ...contentStyle
                                  }}
                                  onPress={() => {
                                      setSelectedIndex(index)
                                      onSelectIndex(index)
                                  }}
            />

        })}
    </View>

}