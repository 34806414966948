import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import moment from "moment";
import MaterialCommunityIcon from "react-native-paper/src/components/MaterialCommunityIcon";
import React from "react";

export function ChallengeTimeline(props) {
    return <View style={{
        width: props.itemWidth,
        marginTop: 20,
        // marginBottom: 5,
        height: 30,
        // backgroundColor: theme.colors.primaryLighter,
        // flexDirection: "row"
    }}>

        {props.itemInfo.item?.type !== "none" && props.itemInfo.item?.date !== "" &&
        <TextM color={theme.colors.link}
               fontWeight="Bold"
               fontSize={12}
               style={{
                   position: "absolute",
                   bottom: 18,
                   left: 20
               }}>{moment(props.itemInfo.item?.date).format("ddd DD/MM").toUpperCase()}</TextM>}

        <View style={{
            width: props.itemWidth,
            backgroundColor: theme.colors.linkLight,
            height: 8,
            position: "absolute",
            bottom: 7,
            left: 0
        }}/>

        {props.itemInfo.item?.type !== "none"  && <View style={{
            width: 22,
            height: 22,
            backgroundColor: (props.itemInfo.item?.type === "launch" || props.itemInfo.item?.type === "deadline" || props.itemInfo.index === props.flagIndex) ? theme.colors.link : theme.colors.linkLight,
            borderRadius: 20,
            position: "absolute",
            left: -1,
            bottom: 0,
            alignItems: "center",
            justifyContent: "center"
        }}>
            {(props.itemInfo.item?.type === "launch" || props.itemInfo.item?.type === "deadline" || props.itemInfo.index === props.flagIndex) &&
            <MaterialCommunityIcon
                name={props.itemInfo.item?.type === "launch" ? "rocket-launch" : props.itemInfo.item?.type === "deadline" ? "bullseye-arrow" : "flag"}
                direction="ltr"
                color={theme.colors.white}
                size={16}/>}
        </View>}

    </View>;
}