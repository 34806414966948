import React, {useEffect, useState} from 'react';
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {setOngoingActivity} from "@data/redux/actions/activity";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {connect} from "react-redux";
import {ActivityIndicator, IconButton} from "react-native-paper";
import {liveReportService} from "@data/services/liveReportService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TitleM from "@common/components/text/TitleM";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import LiveReportComponent from "@common/liveMode/live-report/LiveReportComponent";
import {StatusBar} from "expo-status-bar";
import {ScrollView, Dimensions} from "react-native";

const LiveReportScreen = (props) => {
    const hash = props.route?.params?.h ?? null
    const [liveReportFromHash, setLiveReportFromHash] = useState(null);

    const navigation = useNavigation()

    useEffect(() => {
        if (hash) {
            //props.apiRequest(schoolSubjectsService.getSchoolSubjects)
            props.apiRequest(liveReportService.getLiveReport, {hash})
        }
    }, [])

    useApiStatus(
        liveReportService.getLiveReport, // api service
        false, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            setLiveReportFromHash(successData)
        } // success callback,
    )


    return <>
        {hash && <HeaderContainerM>
            <TitleM>Rapport de LIVE</TitleM>
            <IconButton
                icon={"times"}
                iconColor={theme.colors.primary}
                size={32}
                style={{
                    position: "absolute",
                    left: 4
                }}
                onPress={() => {
                    if (navigation.canGoBack())
                        navigation.goBack()
                    else navigation.replace("menu")
                }}
            />
        </HeaderContainerM>}

        {props.getLiveReportRequest
            ? <ActivityIndicator color={theme.colors.primary} style={{marginTop: 20}}/>
            : props.getLiveReportError
                ? <>
                    <TextM color={theme.colors.error}
                           wrap
                           style={{
                               marginTop: 20,
                               marginBottom: 20,
                               textAlign: "center"
                           }}
                           fontWeight={"Bold"}
                           fontSize={14}>
                        {props.getLiveReportError.message}
                    </TextM>
                    <FilledButtonM
                        onPress={() => {
                            if (navigation.canGoBack())
                                navigation.goBack()
                            else navigation.replace("menu")
                        }}
                        label={"Retour à l'accueil"}
                    />
                </>
                : <ScrollView containerStyle={{
                    width: Dimensions.get("window").width,
                }}>

                <LiveReportComponent liveReport={liveReportFromHash} withTitle={false}/>
                </ScrollView>}
        <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
    </>
}
const mapStateToProps = state => {
    return {
        getLiveReportRequest: state.api.getLiveReport?.request,
        getLiveReportError: state.api.getLiveReport?.error
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setOngoingActivity,
        setIsLiveModeEnabled
    }

export default connect(mapStateToProps, mapDispatchToProps)(LiveReportScreen)