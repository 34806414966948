import {DimensionsContext} from "@common/utils/mobileUtils";
import React, {useContext, useRef, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {PersonasAvatar} from "@common/avatar";
import {ScrollView, StyleSheet, View} from "react-native";
import ViewShot from "react-native-view-shot";
import {authService} from "@data/services/authService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import CustomButton from "@common/components/button/CustomButton";
import {theme} from "@common/theme/theme";
import CustomizeColors from "@common/avatar/CustomizationComponents/CustomizeColors";
import {skinColors} from "@common/avatar/constants/skin";
import {hairColors, hairColorsList, hairsList} from "@common/avatar/constants/hair";
import {backgroundColorsList} from "@common/avatar/constants/background";
import {facialHairColors, facialHairColorsList, facialHairsList} from "@common/avatar/constants/facial-hair";
import {bodiesList, bodyColors, bodyColorsList} from "@common/avatar/constants/body";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {DEFAULT_CHARACTERS, parseCharacters} from "@common/avatar/utils";
import CustomizeShapes from "@common/avatar/CustomizationComponents/CustomizeShapes";
import {eyeColors, eyeColorsList, eyesList} from "@common/avatar/constants/eyes";
import {nosesList} from "@common/avatar/constants/nose";
import {shirtList} from "@common/avatar/constants/tshirt";
import {useNavigation} from "@react-navigation/native";
import {jacketList} from "@common/avatar/constants/jacket";
import jacketIcon from "../../../assets/icons/generic/jacket.png"
import hairIcon from "../../../assets/icons/generic/hair.png"
import moment from "moment";

const styles = StyleSheet.create({
    container: {
        //flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: theme.colors.white,
        paddingVertical: 20,
    },
    randomizeButton: {
        marginTop: 20,
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: 'gray',
        borderRadius: 5,
    },
});

export const createAvatar = (avatar) => {
    const jacket = avatar.shirt?.includes("shirt") ? "|" + avatar.jacket : "|"
    return avatar.body + "|" + avatar.eyes + "|" + avatar.eyesColor + "|" + avatar.facialHair + "|" + avatar.hair + "|" +
        avatar.shirt + "|" + avatar.nose + "|" + avatar.backgroundColor + "|" +
        avatar.skinColor + "|" + avatar.hairColor + "|" + avatar.facialHairColor + "|" + avatar.bodyColor + jacket
}
const CreateAvatarScreen = props => {
    const avatarRef = useRef();
    const characters = props.user?.avatar_selected?.includes("[characters]") ? props.user?.avatar_selected?.replace("[characters]", "") : null
    const [avatar, setAvatar] = useState(characters ? parseCharacters(characters) : DEFAULT_CHARACTERS)
    const ref = useRef();

    const {visible, onDismiss} = props
    const dimensions = useContext(DimensionsContext)
    const navigation = useNavigation()
    const onSubmit = () => {
        ref.current?.capture().then(uri => {
            const cleanFormData = new FormData();
            const image = {
                uri: uri,
                name: `Avatar_${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.png`,
                type: "image/png"
            }
            cleanFormData.append("avatar", image)
            cleanFormData.append("avatar_characters", createAvatar(avatar))
            props.apiRequest(authService.updateAvatarSelected, {}, cleanFormData)
        });

    }
    useApiStatus(
        authService.updateAvatarSelected, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (navigation.canGoBack())
                navigation.goBack()
            else navigation.push("account")
            //console.log(data)
        } // success callback
    )
    const [selectedIndex, setSelectedIndex] = useState("Skin");
    const avatarTab = [
        {
            label: ["Peau"],
            icon: ["child"]
        },
        {
            label: ["Yeux", "Couleur des yeux"],
            icon: ["eye", "palette"]
        },
        {
            label: ["Style des cheveux", "Couleur des cheveux"],
            icon: ["palette"],
            image: [hairIcon]
        },
        {
            label: ["Tshirt"],
            icon: ["tshirt"]
        },
        avatar.shirt?.includes("shirt") && {
            label: ["Veste"],
            image: [jacketIcon]
        },
        /*        {
                    label: ["Arrière plan"],
                    icon: ["fill"]
                },
                {
                    label: ["Barbe", "Couleur du barbe"],
                    icon: ["smile", "palette"]
                },
                {
                    label: ["Style du corps", "Couleur du corps"],
                    icon: ["child", "palette"]
                },
                {
                    label: ["Nez"],
                    icon: ["box"]
                },*/
    ]

    const renderComponent = (label) => {
        switch (label) {
            case "Peau":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={bodiesList}
                                        name={"body"}
                                        color={bodyColors[avatar.bodyColor]}
                />
            case "Couleur des cheveux":
                return <CustomizeColors avatar={avatar} setAvatar={setAvatar} name={"hairColor"}
                                        colors={hairColorsList}/>
            case "Style des cheveux":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={hairsList}
                                        name={"hair"}
                                        color={hairColors[avatar.hairColor]}
                />
            case "Arrière plan":
                return <CustomizeColors avatar={avatar} setAvatar={setAvatar} name={"backgroundColor"}
                                        colors={backgroundColorsList}/>
            case "Couleur du barbe":
                return <CustomizeColors avatar={avatar} setAvatar={setAvatar} name={"facialHairColor"}
                                        colors={facialHairColorsList}/>
            case "Barbe":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={facialHairsList}
                                        name={"facialHair"}
                                        color={facialHairColors[avatar.facialHairColor]}
                />
            case "Couleur du corps":
                return <CustomizeColors avatar={avatar} setAvatar={setAvatar} name={"bodyColor"}
                                        colors={bodyColorsList}/>
            case "Style du corps":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={bodiesList}
                                        name={"body"}
                                        color={bodyColors[avatar.bodyColor]}
                />
            case "Yeux":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={eyesList}
                                        name={"eyes"}
                                        color={eyeColors[avatar.eyesColor]}
                />
            case "Couleur des yeux":
                return <CustomizeColors avatar={avatar} setAvatar={setAvatar} name={"eyesColor"}
                                        colors={eyeColorsList}/>

            case "Nez":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={nosesList}
                                        name={"nose"}
                                        color={skinColors[avatar.skinColor]}
                />
            case "Tshirt":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={shirtList}
                                        name={"shirt"}
                />
            case "Veste":
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={jacketList}
                                        name={"jacket"}
                />
            default:
                return <CustomizeShapes avatar={avatar}
                                        setAvatar={setAvatar}
                                        data={bodiesList}
                                        name={"body"}
                                        color={bodyColors[avatar.bodyColor]}
                />
        }
    }
    return <>
        <View
            style={styles.container}
        >
            <ViewShot ref={ref}>
                <PersonasAvatar
                    ref={avatarRef}
                    characters={createAvatar(avatar)}
                    onNewCharacters={characters => {
                        // console.warn(characters);
                    }}
                />
            </ViewShot>
            <ScrollView
                horizontal
                //pagingEnabled
                style={{
                    marginVertical: 20,
                    marginTop: 0,
                    //flex: 1
                }}
                contentContainerStyle={{
                    alignItems: "center",
                }}
            >
                {avatarTab.map((el, index) =>
                        el.label && <CustomButton
                            label={el.label}
                            icon={el?.icon}
                            image={el?.image}
                            key={index}
                            index={index}
                            setSelectedIndex={setSelectedIndex}
                            selected={el.label.includes(selectedIndex)}
                            selectedIndex={selectedIndex}
                            toggleMode={el.label.length > 1}
                        />
                )}

            </ScrollView>
            {renderComponent(selectedIndex)}


        </View>

        <FilledButtonM color={theme.colors.primary}
                       onPress={onSubmit}
                       style={{
                           width: dimensions?.width - 20,
                           alignSelf: 'center',
                           position: 'absolute',
                           bottom: 10,
                       }}
                       loading={props.updateAvatarSelectedRequest}
                       label="Confirmer"/>
    </>
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        updateAvatarSelectedRequest: state.api.updateAvatarSelected?.request,
        updateAvatarSelectedSuccess: state.api.updateAvatarSelected?.success,
        updateAvatarSelectedError: state.api.updateAvatarSelected?.error,

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(CreateAvatarScreen)