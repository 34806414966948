import React from 'react';
import {ModalM} from "@common/components/modal/ModalM";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {Dimensions, Linking, Platform, View} from "react-native";
import {ChampionImageM} from "../../../student/champions/ChampionImageM";
import {theme} from "@common/theme/theme";
import * as StoreReview from "expo-store-review";

const VersionUpdateModal = (props) => {

    const visible = true
    const onPress = () => {
        Linking.canOpenURL(StoreReview.storeUrl())
            .then(() => {
                Linking.openURL(StoreReview.storeUrl()).then();
            })
            .catch();
    };
    return (
        <ModalM visible={visible} style={{width: Dimensions.get("window").width, height: Dimensions.get("window").height}}>
            <View style={{
                padding: 20
            }}>
                <View style={{
                    // flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-start",
                }}>
                    <ChampionImageM name="sam" variant={"main"} width={140}/>
                    <TextM fontWeight="Bold"
                           fontSize={18}
                           wrap
                           style={{
                               paddingHorizontal: 10,
                               marginTop: 10,
                               textAlign: "center"
                           }}
                           color={theme.colors.sam}>
                        Une nouvelle version est disponible
                    </TextM>
                    <TextM fontWeight="Regular"
                           fontSize={16}
                           wrap
                           style={{
                               paddingHorizontal: 10,
                               marginTop: 4,
                               textAlign: "center"
                           }}>
                        Merci de mettre à jour l'application !
                    </TextM>
                </View>
                <FilledButtonM
                    style={{
                        width: "100%",
                        marginTop: 30
                    }}
                    onPress={onPress}
                    label="Mettre à jour"
                    //mode="outlined"
                />
            </View>
        </ModalM>
    )
};

export default VersionUpdateModal;