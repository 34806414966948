import React from 'react';
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useNavigation} from "@react-navigation/native";
import {StatusBar} from "expo-status-bar";
import {ChampionImageM} from "../student/champions/ChampionImageM";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import LogoutButton from "@common/account/LogoutButton";


const RegisterReturnScreen = (props) => {

    const navigation = useNavigation()

    return (
        <View style={{
            alignItems: 'center',
            flex: 1,
            padding: 20
        }}>

            <ChampionImageM name="zoe" variant={"main"} width={140}/>
            <TitleM fontWeight="ExtraBold"
                    style={{textAlign: "center", marginTop: 10, color: theme.colors.primary}}
                    wrap>Tu es connecté en tant que {props.user?.display_name}</TitleM>
            <TextM fontWeight="Medium" style={{textAlign: "center"}} wrap>Pour créer un compte il faut d'abord te déconnecter</TextM>
            <LogoutButton noVersion />
            <FilledButtonM
                onPress={() => {
                    navigation.goBack()
                }}
                mode={"text"}
                label={"Retour"}
            />

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterReturnScreen)