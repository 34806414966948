import React, {useContext} from 'react';
import {Button} from "react-native-paper";
import withPreventDoubleClick from "../../utils/withPreventDoubleClick";
import {Platform, StyleSheet} from "react-native";
import {DimensionsContext} from "../../utils/mobileUtils";
import {theme} from "@common/theme/theme";
import * as amplitude from '@amplitude/analytics-react-native';

const FilledButtonM = (props) => {

    const {mode, label, fontWeight, color, trackingId, onPress} = props
    const dimensions = useContext(DimensionsContext)

    const styles = StyleSheet.create({
        button: {
            marginBottom: 10,
            // marginHorizontal: 15,
            // paddingVertical: 5,
            borderRadius: 15,
            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 2,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        },
        text: {
            fontFamily: "Montserrat-" + (fontWeight ?? "ExtraBold"),
            fontSize: 15
        },
        content: {
            // paddingVertical: 5
            height: 50
        }
    });

    return <Button textColor={(!mode || mode === "contained") ? theme.colors.white : color}
                   buttonColor={(!mode || mode === "contained") ? color : theme.colors.transparent}
                   {...props}
                   uppercase={true}
                   mode={mode ?? "contained"}
                   onPress={(e) => {
                       if (trackingId) {
                           amplitude.track("button.clicked." + trackingId)
                       }
                       onPress(e)
                   }}
                   contentStyle={{...styles.content, ...props.contentStyle}}
                   style={{...styles.button, ...props.style, elevation: 0}}
                   labelStyle={{...styles.text, ...props.labelStyle}}
    >

        {label}
    </Button>
}

export default withPreventDoubleClick(FilledButtonM)