import React, {useContext, useState} from "react";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import TextButtonM from "@common/components/button/TextButtonM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {TutorNextEventsModal} from "./TutorNextEventsModal";
import {boxedCardBorderStyle} from "../../../reviewer/BoxedCardBorderStyle";

export const TutorNextEventsCard = props => {

    const {data, renderItem, title} = props

    const [modal, setModal] = useState(false)
    const navigation = useNavigation()
    const handleShowModal = () => setModal(!modal)

    const tab = data?.slice(0, 3)

    // console.log(data?.length)

    let dimensions = useContext(DimensionsContext)

    return <View style={{
        // flex: 1,
        marginTop: 30,
        borderColor: theme.colors.lightMore,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        backgroundColor: theme.colors.white,
        paddingHorizontal: 20,
        paddingVertical: 20,
        width: dimensions?.width,
        ...boxedCardBorderStyle
    }}>

        <View style={{
            flexDirection: "row",
            justifyContent: "space-between"
        }}>
            <TextM fontWeight={"Bold"} fontSize={20}>{title}</TextM>
            {(IS_WEB && data?.length > 3) && <TextButtonM onPress={handleShowModal}
                                                          style={{
                                                              paddingHorizontal: 10
                                                          }}
                                                          fontWeight={"Bold"}
                                                          label="VOIR PLUS"/>}
        </View>
        {(!data || data?.length === 0) && <>
            <View>
                <TextM style={{marginTop: 10}}>
                    Aucun élément
                </TextM>
            </View>
        </>}


        {data?.length > 0 && <View style={{
            flexDirection: dimensions?.width > 800 ? 'row' : 'column',
            flexWrap: 'wrap',
            alignItems: "stretch"
        }}>
            {tab?.map((item, key) => {
                    return renderItem(item, key)
                }
            )
            }
            {(!IS_WEB && data?.length > 3) && <FilledButtonM mode="text"
                                                             labelStyle={{
                                                                 fontSize: IS_WEB ? 10 : 16
                                                             }}
                                                             style={{
                                                                 // marginLeft: 10,
                                                                 marginTop: 20,
                                                                 alignSelf: 'center',
                                                                 width: IS_WEB && dimensions.width > 800 ? 100 : dimensions?.width - 40

                                                             }}
                                                             onPress={handleShowModal}
                                                             label="Voir plus"/>}
        </View>}

        <TutorNextEventsModal isOpen={modal}
                              data={data}
                              handleShowModal={handleShowModal}
                              renderItem={renderItem}
                              title={title}
                              navigation={navigation}
        />

    </View>
}