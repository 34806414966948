import {DatePickerModal} from "react-native-paper-dates";
import TextInputM from "@common/components/input/TextInputM";
import DatePickerModalM from "@common/components/modal/DatePickerModalM";
import {useForm} from "react-hook-form";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {backOfficeService} from "@data/services/backOfficeService";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {gradesService} from "@data/services/gradesService";
import moment from "moment";
import {useContext, useState} from "react";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {TouchableOpacity, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import yup from "@data/utility/validation";
import {yupResolver} from "@hookform/resolvers/yup";

const AddResultsComponent = (props) => {

    const {studentId, schoolSubjectId, style, grade, requestApiService, requestRouteParams} = props

    const now = moment()?.format("YYYY-MM-DD")
    const [date, setDate] = useState(grade?.date ?? now);
    // const [grade, setGrade] = useState(null);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

    const dimensions = useContext(DimensionsContext)
    // const navigation = useNavigation()
    const showDatePicker = () => {
        setDatePickerVisibility(!isDatePickerVisible);
    };

    const formSchema = yup.object().shape({
        date: yup.string().required(),
        value: yup.string().required(),
        total: yup.string().required()
    })

    const resolver = yupResolver(formSchema);

    const defaultValues = {
        date: grade?.date ?? now,
        value: grade?.value ?? "",
        total: grade?.total ?? 20
    }

    const {control, handleSubmit, formState: {errors, dirtyFields}, trigger, setValue} = useForm({
        resolver,
        defaultValues
    })
    const onSubmit = (data) => {
        // console.log(data)
        props.apiRequest(requestApiService, requestRouteParams, {
            ...data,
            school_subject_id: schoolSubjectId,
            type: 'evaluation'
        })
    }
    useApiStatus(
        gradesService.newGrade, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            // console.log(successData)
            setValue("value", "")
            setValue("total", 20)
            setValue("date", now)
            setDate(now)
        } // success callback
    )

    return <View style={{
        // backgroundColor: "black",
        width: dimensions?.width / 3 - 20,
        ...style
    }}>
        <TouchableOpacity onPress={() => {
            showDatePicker()
        }}
        >
            <View style={{
                borderRadius: 5,
                borderColor: theme.colors.light,
                padding: 5,
                borderTopWidth: 1,
                borderRightWidth: 1,
                borderLeftWidth: 1,
                borderBottomWidth: 1,
                // width: "100%"
            }}>
                <TextM color={theme.colors.black}
                       fontWeight={"Medium"}
                    // fontSize={10}
                       style={{
                           // marginLeft: 5
                       }}
                >{date === now ? "Aujourd'hui" : date}</TextM>
            </View>
        </TouchableOpacity>

        <View style={{
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            marginVertical: 5,
            // backgroundColor: "black"
        }}>
            <TextInputM
                placeholder="Note"
                name="value"
                contentContainerStyle={{
                    flex: 1,
                    // backgroundColor: theme.colors.black,
                    // height: 40
                }}
                style={{
                    // width: "50%",
                    // height: 40,
                    fontSize: 14,
                    // backgroundColor: theme.colors.black,
                    flex: 1,
                    paddingVertical: 0,
                    paddingHorizontal: 0
                }}
                // outlineStyle={{
                //     height: 40
                // }}
                contentStyle={{
                    // height: 40
                }}
                multiline={true}
                // disabled={evaluated}
                // numberOfLines={3}
                control={control}
                errors={errors}
                onChangeAdditional={(value) => {
                    // setGrade(value)
                }}
            />

            <TextM style={{marginHorizontal: 5, flex: 1}}>/</TextM>

            <TextInputM
                placeholder="Total"
                name="total"
                contentContainerStyle={{
                    flex: 1,
                    // height: 20
                }}
                style={{
                    // width: "50%",
                    // height: 20,
                    fontSize: 14
                }}
                // outlineStyle={{
                //     height: 40
                // }}
                multiline={true}
                // disabled={evaluated}
                // numberOfLines={3}
                control={control}
                errors={errors}
                onChangeAdditional={(value) => {
                }}
            />


        </View>

        <FilledButtonM label={"ENVOYER"}
                       color={theme.colors.primary}
                       loading={props.newGradeRequest && props.newGradeRequestSchoolSubjectId === schoolSubjectId || props.updateGradeRequest && props.updateGradeRequestGradeId === grade?.id}
                       style={{
                           width: "100%",
                           alignSelf: 'center',
                           // marginTop: Platform.OS !== 'web' ? null : 15,
                           // marginBottom: Platform.OS !== 'web' ? null : 15
                           // bottom: 15,
                           height: 30
                       }}
                       contentStyle={{
                           height: 30
                       }}
                       mode={"outlined"}
                       onPress={handleSubmit(onSubmit)}
        />

        <DatePickerModalM
            label={"Sélectionner une date"}
            name={"date"}
            cancelLabel={"Annuler"}
            saveLabel={"Confirmer"}
            closeIcon={"times"}
            editIcon={'edit'}
            allowEditing={false}
            calendarIcon={'calendar'}
            locale="fr"
            mode="single"
            visible={isDatePickerVisible}
            onDismiss={showDatePicker}
            // date={date}
            control={control}
            errors={errors}
            onChangeAdditional={(e) => {
                showDatePicker()
                setDate(e)
                // setUrlParams({
                //     ...urlParams,
                //     reference_date: dd
                // })
            }}
        />
    </View>
}

const mapStateToProps = state => {
    return {
        newGradeRequest: state.api?.newGrade?.request,
        newGradeRequestSchoolSubjectId: state.api?.newGrade?.data?.school_subject_id,
        updateGradeRequest: state.api?.updateGrade?.request,
        updateGradeRequestGradeId: state.api?.updateGrade?.routeParams?.id,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(AddResultsComponent)
