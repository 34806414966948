import {ScrollView, View} from "react-native";
import React, {useContext, useEffect} from "react"
import InterviewStatisticsCard from "./InterviewStatisticsCard";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TitleM from "@common/components/text/TitleM";
import TextM from "@common/components/text/TextM";
import {formatFullDateTime} from "@data/utility/Utils";
import {theme} from "@common/theme/theme";
import {findItemInListWithId, interviewsForStudents} from "@data/utility/dataUtils";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {ChampionImageM} from "../../../student/champions/ChampionImageM";
import {interviewsService} from "@data/services/interviewsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {useNavigation} from "@react-navigation/native";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {IconButton} from "react-native-paper";
import {congratsTypes} from "@common/screens/CongratsScreen";

function InterviewSummary(props) {

    const studentForId = (student_id) => props.user?.students?.find(student => parseInt(student?.id) === parseInt(student_id))
    const currentStudent = () => props.route?.params?.student_id ? studentForId(props.route.params?.student_id) : null
    const student = props.student ? props.student : currentStudent()
    const interviews = () => interviewsForStudents(props.user?.students)
    const currentInterview = () => findItemInListWithId(parseInt(props.route.params.interview_id), interviews())
    const interview = props.interview ? props.interview : currentInterview()

    const onSubmit = () => {
        props.apiRequest(interviewsService.interviewFinalize, {id: interview.id})
    }
    useEffect(() => {
        // console.log(student)
    })

    const navigation = useNavigation()

    useApiStatus(
        interviewsService.interviewFinalize, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Entretien terminé",
                    text: "Le rapport a bien été envoyé"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )
    useEffect(() => {
        if (props.interviewFinalizeSuccess)
            navigation.goBack()
    }, [])
    const dimensions = useContext(DimensionsContext)
    const insets = useSafeAreaInsets()

    return (
        <View style={{
            backgroundColor: theme.colors.white,
            paddingTop: insets.top
        }}>
            {/*{props.student && header()}*/}
            <ScrollView>
                {/*<View style={{*/}
                {/*    alignItems: 'center',*/}
                {/*    justifyContent: 'center',*/}
                {/*    paddingTop: 20*/}
                {/*}}>*/}
                {/*    {interview.finalized_at ? <>*/}
                {/*        <TitleM>Entretien hebdomadaire</TitleM>*/}
                {/*        <TextM wrap> {formatFullDateTime(interview?.date_time)}</TextM>*/}
                {/*    </> : <TitleM>Relire le rapport et envoyer</TitleM>}*/}

                {/*</View>*/}
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    onPress={() => {
                        if (navigation.canGoBack()) {
                            navigation.goBack()
                        } else {
                            navigation.replace("menu")
                        }
                    }}
                />
                {!interview.finalized_at && <TitleM style={{textAlign: "center"}}>Relire et envoyer le rapport</TitleM>}
                <View style={{
                    width: dimensions?.width,
                    alignSelf: 'center',
                    // marginTop: 30,
                    paddingHorizontal: 10,
                    // paddingBottom: 0,
                    alignItems: 'center'
                }}>
                    <ChampionImageM style={{alignSelf: 'center'}} name="zoe" variant={"main"} width={100}/>
                    <View style={{
                        borderColor: theme.colors.lightMore,
                        borderBottomWidth: 1,
                        alignItems: "center",
                        paddingBottom: 20
                    }}>
                        <TextM style={{marginTop: 10}}>Bonjour</TextM>
                        <TextM style={{
                            paddingBottom: 5,
                            textAlign: 'center',
                            marginTop: 10
                        }} wrap>Suite à l'entretien de votre enfant avec son
                            tuteur {props?.user?.firstname}, voici votre rapport hebdomadaire.</TextM>
                    </View>
                    <TitleM wrap color={theme.colors.grey}
                            style={{
                                paddingVertical: 20,
                                paddingHorizontal: 10,
                                textAlign: 'center'
                            }}>
                        Entretien hebdomadaire - {formatFullDateTime(interview?.date_time)}
                    </TitleM>
                    <View style={{
                        width: dimensions.width,
                        alignSelf: "flex-start",
                        alignItems: IS_WEB && dimensions.width > 800 ? "flex-start" : "center",
                        marginTop: 20
                    }}>
                        <TitleM color={theme.colors.grey}

                        >
                            Message aux parents
                        </TitleM>
                        <TextM wrap>
                            {interview?.global_comment}
                        </TextM>
                    </View>

                    <InterviewStatisticsCard
                        statistics={interview?.finalized_at ? interview?.student_statistics : student?.statistics}
                        report={interview?.report}/>
                    {/*                    <View style={{borderColor: theme.colors.lightMore, borderTopWidth: 1}}>
                        <TextM style={{paddingTop: 5}}>À très vite,</TextM>
                        <TextM style={{paddingTop: 5}}>L'équipe Masteur</TextM>
                    </View>*/}
                    {interview.finalized_at ? <></> :

                        <FilledButtonM color={theme.colors.success}
                                       onPress={onSubmit}
                                       loading={props.interviewFinalizeRequest}
                                       style={{
                                           marginTop: 20,
                                           width: IS_WEB && dimensions.width > 800 ? dimensions?.width / 2 : dimensions?.width - 20,
                                           // marginTop: Platform.OS !== 'web' ? null : 20,
                                           //bottom: 30
                                           marginBottom: 70
                                           //paddingBottom: Platform.OS === 'ios' ? 20 : 5,

                                       }}
                                       label={"Envoyer le rapport"}/>
                    }
                </View>


                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else navigation.push("menu")
                                          }}
                                          label={"Retour"}/>}
            </ScrollView>

        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        students: state.data.currentUser?.object?.students,
        getStudentRequest: state.api.getStudents?.request,
        interviewFinalizeRequest: state.api.interviewFinalize?.request,
        interviewFinalizeSuccess: state.api.interviewFinalize?.success
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewSummary)