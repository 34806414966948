import React, {useContext, useEffect} from 'react';
import {Platform, RefreshControl, ScrollView, StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "../theme/theme";
import {connect} from "react-redux";
import {authService} from "@data/services/authService";
import ValidateEmailCard from "./ValidateEmailCard";
import FilledButtonM from "../components/button/FilledButtonM";
import * as Notifications from "expo-notifications";
import TitleM from "../components/text/TitleM";
import {DimensionsContext} from "../utils/mobileUtils";
import {ChampionImageM} from "../../student/champions/ChampionImageM";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import * as amplitude from "@amplitude/analytics-react-native";
import LogoutButton from "./LogoutButton";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import {IconButton} from "react-native-paper";


const ValidateEmailScreen = (props) => {

    // ** Component props
    const {navigation} = props


    useEffect(() => {
        if (props.user?.is_verified) {
            if (navigation.canGoBack()) navigation.goBack()
            else navigation.replace("menu")
        }
    }, [props.user?.is_verified])

    const insets = useSafeAreaInsets();

    useEffect(() => {
        setTimeout(function () {
            props.apiRequest(authService.getUser)
        }, 500)
    }, []);
    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flex: 1,
            backgroundColor: theme.colors.white,
            alignItems: 'center',
            // justifyContent: 'center'
        }}>

            <HeaderContainerM>
                <TitleM>Valider l'email</TitleM>
                <IconButton
                    icon={"times"}
                    iconColor={theme.colors.primary}
                    size={32}
                    style={{
                        position: "absolute",
                        left: 4
                    }}
                    onPress={() => {
                        if (navigation.canGoBack()) navigation.goBack()
                        else navigation.replace("menu")
                    }}
                />
            </HeaderContainerM>
            <ScrollView refreshControl={<RefreshControl
                refreshing={!!props.getUserRequest}
                onRefresh={() => props.apiRequest(authService.getUser)}
            />}
                        contentContainerStyle={{
                            alignItems: 'center',
                        }}>

                <ChampionImageM name="tina" variant={"main"} width={140}/>
                <TitleM fontWeight="ExtraBold"
                        style={{
                            textAlign: "center",
                            marginTop: 10
                        }}>Hello 👋</TitleM>


                <ValidateEmailCard fullScreenMode/>

                <FilledButtonM icon={"redo"}
                               mode="text"
                               color={theme.colors.primary}
                    // labelStyle={{color: theme.colors.white}}
                               style={{
                                   width: dimensions?.width - 20,
                                   marginTop: 5
                               }}
                               onPress={() => {
                                   props.apiRequest(authService.getUser)
                               }}
                               loading={(props.getUserRequest)}
                               label="Actualiser"/>
                {/*<LogoutButton/>*/}

            </ScrollView>


            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        loggingOut: state.api.revokeSanctumToken?.request,
        loggedOut: state.api.revokeSanctumToken?.success,
        error: state.api.revokeSanctumToken?.error,

        getUserRequest: state.api.getUser?.request,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEmailScreen)