import {useEffect} from 'react';
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import * as Linking from "expo-linking";
import {IS_WEB, isMobile} from "@common/utils/mobileUtils";
import {navigationScreens} from "@common/navigation/navigationScreens";
import {URL} from "react-native-url-polyfill";

function DeepLinking({prefix}) {

    const handleUrl = async () => {
        let initialUrl
        await Linking.getInitialURL().then((url) => initialUrl = url)
        return "masteur:///" + initialUrl?.substring(prefix.length)
    }

    const openStore = () => {
        DialogM.show({
            text1: "L'application n'est pas installée",
            text2: "Télécharger l'application ?",
            buttonText1: 'Télécharger', // buttonColor1: theme.colors.error,
            onPress: () => {
                if (navigator.userAgent.toLowerCase().includes("android")) {
                    window.location = 'market://details?id=com.masteur.app'
                } else {
                    window.location = 'itms-apps://apps.apple.com/id/app/masteur/id1614253966'
                }
            },
            buttonText2: 'Pas maintenant',
            onPressBack: () => {
            }
        })
    }

    useEffect(() => {
        if (isMobile() && IS_WEB) {
            let now = new Date().valueOf();
            handleUrl().then((url) => {
                if (new Date().valueOf() - now > 100) return;
                //const deepLink = Object.keys(deepLinks).find((d) => url.includes(d))
                const urlObject = new URL(url)
                const urlPath = urlObject.pathname.slice(1)
                const deepLink = navigationScreens.main.find((d) => urlPath === d.name) //TODO Warning : to be improved
                if (deepLink && deepLink?.deepLinkConfig?.platform === "web&mobile") {
                    window.location = url
                    window.onfocus = function () {
                        DialogM.hide()
                    }
                    if (deepLink?.deepLinkConfig.downloadBanner) {
                        setTimeout(function () {
                            openStore()
                        }, 2000)
                        // setTimeout(openStore, 100);
                    } else if (deepLink?.deepLinkConfig.downloadRequired) {
                        /*  setTimeout(function () {
                              window.location = StoreReview.storeUrl()
                          }, 100);*/
                    }
                }
            })
        }

    }, [])

}

const mapStateToProps = state => {
    return {
        registered: state.api.registerAndGetSanctumToken?.success,
        registering: state.api.registerAndGetSanctumToken?.request,
        error: state.api.registerAndGetSanctumToken?.error,

        getSchoolSubjectsRequest: state.api.getSchoolSubjects?.request,
        getSchoolSubjectsSuccess: state.api.getSchoolSubjects?.success,
        getSchoolSubjectsError: state.api.getSchoolSubjects?.error,

        schoolSubjects: state.data.staticData?.school_subjects
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiRequestWithPrerequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(DeepLinking)