import React, {useContext, useEffect} from 'react';
import {Platform, ScrollView, StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {ActivityIndicator, IconButton, Text} from "react-native-paper";
import {authService} from "@data/services/authService";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import TextM from "@common/components/text/TextM";
import {IconImageM, PremiumPlus} from "@common/components/icons/IconsM";
import live from "../../../../assets/icons/generic/live.png"
import students from "../../../../assets/icons/menus/students.png"
import books from "../../../../assets/icons/generic/books.png"
import report from "../../../../assets/icons/generic/reportf.png"
import rituals from "../../../../assets/icons/generic/rituals.png"
import FilledButtonM from "@common/components/button/FilledButtonM";
import {getPrivilegedRole, isOnTrial} from "@data/utility/ability";
import {isSubscribed, isSubscriptionPaused} from "@data/utility/dataUtils";
import {URL} from 'react-native-url-polyfill';
import prof from "../../../../assets/icons/menus-new/prof.png";

export const trialPrivileges = [
    {
        icon: live,
        title: "2 x 1h d’étude en Live Masteur chaque semaine"
    },
    {
        icon: prof,
        title: "Des tuteurs sélectionnés parmi les meilleures filières d'études"
    },
    {
        icon: rituals,
        title: "Une méthode stimulante de soutien scolaire, inspirée des neurosciences"
    },
    {
        icon: books,
        title: "Essai gratuit et sans engagement"
    }
]

export const essentialOffersPrivileges = [
    {
        icon: books,
        title: "Objectif : retravailler les chapitres qu’il voit en classe, et préparer ses contrôles"
    },
    {
        icon: live,
        title: "1h, 2h ou 3h de Live Masteur chaque semaine"
    },
    {
        icon: prof,
        title: "Des tuteurs d'excellence, sélectionnés parmi les meilleures filières d'études"
    },
    {
        icon: students,
        title: "Des petits groupes de 4 élèves maximum"
    },

    {
        icon: report,
        title: "Un rapport personnalisé pour suivre sa progression au quotidien"
    }
]

export const OfferPrivilegesList = ({privilegesList, product = "essential", whiteBackgroundMode = false}) => {

    const dimensions = useContext(DimensionsContext)

    const productLiveText = product === "essential" ? "2 x 1h" : product === "initial" ? "1h" : "3 x 1h"

    return <View style={{
        marginTop: 20,
        width: dimensions?.width < 800 ? "100%" : "50%"
    }}>
        {privilegesList.map((privilege, index) => {

                return <View key={index} style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                    marginBottom: 10,
                    paddingHorizontal: 20,
                    width: "100%"
                }}>
                    <IconImageM width={36}
                                source={privilege.icon}/>
                    <TextM fontSize={16}
                           style={{marginHorizontal: 20, textAlign: "left", width: "85%"}}
                           color={whiteBackgroundMode ? theme.colors.grey : theme.colors.white}
                           fontWeight="SemiBold"
                           wrap>{privilege.title?.replace("2 x 1h", productLiveText)}</TextM>
                </View>
            }
        )}
    </View>
}

const TrialOnboardingScreen = (props) => {

    // ** Component props
    const {navigation} = props
    const insets = useSafeAreaInsets();

    const onboardingMode = !props.user?.onboarding_viewed_at
    // const trialMode = !props.user?.onboarding_viewed_at || canSubscribeToTrialEssential(props.user)
    const product = getPrivilegedRole(props.user)

    const goToNextScreen = () => {
        navigation.push(onboardingMode ? "start-trial" : "start-trial")
    }

    useEffect(() => {
        if (props.loggedOut) {
            props.apiIdle(authService.revokeSanctumToken)
        } else if (props.error) {
            props.apiIdle(authService.revokeSanctumToken)
            DialogM.show({
                text1: 'Erreur', text2: props.error.message
            })
        }
    }, [props.loggedOut, props.error])

    useEffect(() => {
        if (props.loggedOutWeb) {
            props.apiIdle(authService.logout)
        } else if (props.error) {
            props.apiIdle(authService.logout)
            DialogM.show({
                text1: 'Erreur', text2: props.error.message
            })
        }
    }, [props.loggedOutWeb, props.errorWeb])

    const alertBeforeExit = () => {
        DialogM.show({
            text1: 'Veux-tu vraiment revenir en arrière ?',
            text2: 'Tu seras déconnecté de ton compte.',
            buttonText1: 'Quitter',
            onPress: () => {
                if (Platform.OS !== 'web') {
                    props.apiRequest(authService.revokeSanctumToken, {}, {});
                } else {
                    props.apiRequest(authService.logout, {}, {});
                }
            },
            buttonText2: 'Annuler'
        })
    }

    const exit = () => {
        navigation.goBack()
    }

    const dimensions = useContext(DimensionsContext)


    return (

        <View style={{
            flex: 1,
            backgroundColor: theme.colors.white,
            alignItems: 'center'
        }}>

            <ScrollView style={{
                paddingTop: insets.top,
                width: dimensions?.width,
                flex: 1
            }}
                        contentContainerStyle={{
                            alignItems: "center"
                        }}
            >
                {(props.loggingOut || props.loggingOutWeb)
                    ? <ActivityIndicator animating={true}
                                         color={theme.colors.primary}
                                         style={{
                                             alignSelf: "flex-start"
                                         }}/>
                    : <IconButton
                        icon={"times"}
                        style={{
                            alignSelf: "flex-start"
                        }}
                        iconColor={theme.colors.primary}
                        size={32}
                        onPress={onboardingMode ? alertBeforeExit : exit}
                    />}

                {/*{trialMode &&                     <TextM fontSize={20}*/}
                {/*                                         style={{marginHorizontal: 20, textAlign: "center", marginBottom: 20}}*/}
                {/*                                         color={theme.colors.grey}*/}
                {/*                                         fontWeight="ExtraBold"*/}
                {/*                                         wrap>{"Tu bénéficies d'1 semaine d'essai gratuit !"}</TextM>}*/}

                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: -10,
                    marginBottom: 0
                }}>
                    <Text
                        style={{
                            flex: IS_WEB ? null : 0,
                            color: theme.colors.accentDark,
                            fontFamily: "Owl-Cute",
                            fontSize: 38,
                            textAlign: "center"
                            // marginBottom: 40
                            // flexShrink: 1,
                            // alignSelf: 'center'
                        }}
                    >
                        Offre Essentielle
                    </Text>
                    <PremiumPlus size={38}
                                 style={{
                                     // alignSelf: "flex-end",
                                     marginLeft: 10,
                                     marginBottom: 5
                                 }}
                    />
                </View>

                <OfferPrivilegesList privilegesList={essentialOffersPrivileges} whiteBackgroundMode={true}/>

                {/*{trialMode && <>*/}
                {/*    <TextM fontSize={14}*/}
                {/*           style={{marginHorizontal: 20, textAlign: "center", marginTop: 40}}*/}
                {/*           color={theme.colors.grey}*/}
                {/*           fontWeight="Light"*/}
                {/*           wrap>{"Sans engagement."}</TextM>*/}
                {/*    <TextM fontSize={14}*/}
                {/*           style={{marginHorizontal: 20, textAlign: "center"}}*/}
                {/*           color={theme.colors.grey}*/}
                {/*           fontWeight="Light"*/}
                {/*           wrap>{"Pas besoin de carte bancaire."}</TextM>*/}
                {/*</>}*/}
                {/*{trialMode && <FilledButtonM color={theme.colors.accentDark}*/}
                {/*               onPress={goToNextScreen}*/}
                {/*               style={{*/}
                {/*                   width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,*/}
                {/*                   marginTop: 6*/}
                {/*                   // bottom: 5,*/}
                {/*               }}*/}
                {/*               label={onboardingMode ? "Continuer" : "Essai gratuit"}/>}*/}

                {(product === "basic" || isOnTrial(props.user))
                    && !isSubscriptionPaused(props.user)
                    // && !trialMode
                    && (Platform.OS !== "ios" ? <FilledButtonM color={theme.colors.accentDark}
                                                               onPress={() => {
                                                                   // navigation.replace("choose-trial", {product: "product"})
                                                                   // navigation.push("")
                                                                   const url = new URL(props.user?.subscribe_url)
                                                                   navigation.push(url.pathname.slice(1), {
                                                                       user: url.searchParams.get("user")
                                                                   })
                                                               }}
                        // labelStyle={{color: theme.colors.white}}
                                                               style={{
                                                                   // width: dimensions?.width - 20,
                                                                   marginTop: 30
                                                               }}
                                                               label={(isSubscribed(props.user)) ? "Gérer l'abonnement" : "S'abonner"}/> : (!isOnTrial(props.user)) ? null :
                        <TextM color={theme.colors.grey}
                               style={{
                                   textAlign: "center",
                                   marginTop: 10,
                                   paddingHorizontal: 20
                               }}
                               fontWeight="Medium"
                               wrap
                        >
                            On ne peut pas s'abonner dans l'appli. Ce n'est pas idéal, nous le savons.
                        </TextM>)}

                <View style={{height: 130}}/>
            </ScrollView>
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
};
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,

        loggingOutWeb: state.api.logout?.request,
        loggedOutWeb: state.api.logout?.success,
        errorWeb: state.api.logout?.error,

        loggingOut: state.api.revokeSanctumToken?.request,
        loggedOut: state.api.revokeSanctumToken?.success,
        error: state.api.revokeSanctumToken?.error
    }
};

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }
export default connect(mapStateToProps, mapDispatchToProps)(TrialOnboardingScreen)