import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {ScrollView, View} from "react-native";
import TextM from "@common/components/text/TextM";
import React, {useContext} from "react";
import {RatingM} from "@common/components/rating/RatingM";
import {theme} from "@common/theme/theme";

const ScoringDetails = (props) => {

    const {activity} = props

    const activityScoringIds = Object.keys(activity?.score_array)?.map(c => parseInt(c?.substring(10)))
    // const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)
    const activityScoringCriteria = props.scoringCriteria
        // ?.filter(s => activityTemplate?.scoring_criteria_ids?.includes(s?.id))
        ?.filter(s => activityScoringIds?.includes(s?.id))
    const dimensions = useContext(DimensionsContext)

    return <ScrollView style={{
        maxHeight: 500,
        width: dimensions?.width - 50
    }}>
        <View style={{
            alignItems: "center",
            paddingHorizontal: 15,
            paddingVertical: 20,
            width: dimensions?.width - 50
        }}>
            {activityScoringCriteria?.map((criterion, index) => {

                return <View key={index} style={{
                    width: "100%",
                    marginBottom: 15,
                    paddingHorizontal: 5,
                    alignItems: "center"
                }}>

                    <TextM fontSize={16}
                           wrap
                           fontWeight="Bold"
                           color={criterion?.color ?? theme.colors.grey}
                           style={{textAlign: "center", marginTop: 0}}>
                        {criterion?.title}
                    </TextM>
                    {/*<TextM fontSize={12}*/}
                    {/*       wrap*/}
                    {/*       fontWeight="Light"*/}
                    {/*       style={{textAlign: "center", marginTop: 0, marginHorizontal: 4}}>*/}
                    {/*    {criterion?.question}*/}
                    {/*</TextM>*/}
                    <RatingM size={30}
                             score={activity?.score_array["criterion_" + criterion?.id]}
                             color={criterion?.color}
                             scale={criterion?.scale}/>
                </View>
            })}
            {/*{!!activity?.review_comment && <>*/}
            {/*    <TextM fontSize={16}*/}
            {/*           wrap*/}
            {/*           fontWeight="Bold"*/}
            {/*           style={{textAlign: "center", marginTop: 0}}>*/}
            {/*        Commentaire*/}
            {/*    </TextM>*/}
            {/*    <TextM fontSize={14}*/}
            {/*           wrap*/}
            {/*           fontWeight="Medium"*/}
            {/*           style={{textAlign: "center", marginTop: 5}}>*/}
            {/*        {activity?.review_comment}*/}
            {/*    </TextM>*/}
            {/*</>}*/}

        </View>
    </ScrollView>;
}

const mapStateToProps = state => {
    return {

        scoringCriteria: state.data.staticData?.scoring_criteria,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoringDetails)