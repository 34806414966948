import React from "react";
import {IconImageM} from "@common/components/icons/IconsM";
import zoe_main from "../../../assets/champions/zoe_main.png"
import zoe_happy from "../../../assets/champions/zoe_happy.png"
import zoe_unhappy from "../../../assets/champions/zoe_unhappy.png"
import abou_main from "../../../assets/champions/abou_main.png"
import abou_happy from "../../../assets/champions/abou_happy.png"
import abou_unhappy from "../../../assets/champions/abou_unhappy.png"
import sam_main from "../../../assets/champions/sam_main.png"
import sam_happy from "../../../assets/champions/sam_happy.png"
import sam_unhappy from "../../../assets/champions/sam_unhappy.png"
import tina_main from "../../../assets/champions/tina_main.png"
import tina_happy from "../../../assets/champions/tina_happy.png"
import tina_unhappy from "../../../assets/champions/tina_unhappy.png"
import {Image, Platform} from "react-native";

const championImages = {
    zoe_main,
    zoe_happy,
    zoe_unhappy,
    abou_main,
    abou_happy,
    abou_unhappy,
    sam_main,
    sam_happy,
    sam_unhappy,
    tina_main,
    tina_happy,
    tina_unhappy
}

export const ChampionImageM = (props) => {

    const {name = "zoe", variant = "main", width, eye = false, style, height} = props

    const getSource = (name, variant) => {
        return championImages[`${name}_${variant}`]
    }

    if (eye)
        return <Image
            source={getSource(name, variant)}
            style={{
                width: width,
                height: Platform.OS !== 'web' ? width/3.3 : width,
                ...style
            }}/>
    else
        return <IconImageM {...props}
                           source={getSource(name, variant)}
                           width={width}
                           style={style}
                           height={height? height : width*853/512}/>
}
export const samVariantForActivity = (activity) => activity?.score >= 4 ? "happy" : activity?.score < 2.5 ? "unhappy" : "main"
export const samVariantForScore = (score) => score >= 4 ? "happy" : score < 2.5 ? "unhappy" : "main"