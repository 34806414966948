import React, {useContext, useState} from "react";
import {TouchableHighlight, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {StatisticsItemCol} from "@common/components/statistics/StatisticsItemCol";
import chronometer from "../../../assets/icons/generic/chronometer.png";
import {capitalize, niceTimeFromSeconds, plr} from "@data/utility/Utils";
import target from "../../../assets/icons/menus/target.png";
import calendarDays from "../../../assets/icons/generic/calendar-days.png";
import starRating from "../../../assets/icons/stars/sparkle_filled.png";
import {theme} from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import {weekDayNums, weekDaysObject} from "@data/constants/activitiesConstants";
import moment from "moment";

export function ActivitiesStatistics({user, style}) {


    const startOfWeekDay = (weekDayNums[capitalize(user?.statistics?.end_of_week_day)] + 1) % 7
    const diff = moment().weekday() - startOfWeekDay
    // console.log("diff", diff)

    const thisWeekText = "Depuis " + ((diff === 1) ? "Hier" : (Object.values(weekDaysObject)[startOfWeekDay] ?? "Lundi") + " dernier")

    const periodNames = [
        "Aujourd'hui",
        (diff === 0) ? "La semaine dernière" : thisWeekText
        // "La semaine dernière"
    ]
    const periods = [
        "today",
        (diff === 0) ? "last_week" : "this_week"
        // "last_week"
    ]

    const [periodIndex, setPeriodIndex] = useState((diff === 0) ? 0 : 1)
    const period = periods[periodIndex]
    const periodName = periodNames[periodIndex]

    const countActivities = user?.statistics?.[period]?.activities?.count
    const activityDays = user?.statistics?.[period]?.activity_days?.[0]

    const onPeriodPress = () => {
        setPeriodIndex(index => ((index + 1) % periods.length))
    }
    const dimensions = useContext(DimensionsContext)

    return <View style={{
        alignItems: "center",
        justifyContent: 'center',
        alignSelf: "center",
        // backgroundColor: theme.colors.whiteAlmost,
        // borderTopWidth: 1,
        // borderWidth: 1,
        // borderColor: theme.colors.lightMore,
        borderRadius: 15,
        width: dimensions?.width,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 28,
        paddingBottom: 18,
        ...style
    }}>
        {/* lastItem(user?.statistics?.per_day_since_last_week?.duration) */}

        <View style={{
            marginTop: 10,
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: 'space-between',
            alignSelf: "center",
            paddingHorizontal: 10
        }}>
            <StatisticsItemCol source={chronometer}
                               count={niceTimeFromSeconds(user?.statistics?.[period]?.activities?.duration)[0]}
                // count={niceTimeFromSeconds(50000)[0]}
                               title={niceTimeFromSeconds(user?.statistics?.[period]?.activities?.duration)[1] + " d'activité"}
                // title={niceTimeFromSeconds(50000)[1] + " d'activités"}
            />
            <StatisticsItemCol source={target}
                               title={plr("activité", countActivities) + " " + plr("réalisée", countActivities)}
                // count={200}
                               count={countActivities}
            />
            <StatisticsItemCol source={calendarDays}
                               title={plr("jour", activityDays) + " d'activité sur " + user?.statistics?.[period]?.activity_days?.[1]}
                               count={activityDays}
                // count={user?.statistics?.[period]?.activity_days?.[0]}
            />
            <StatisticsItemCol source={starRating}
                               count={user?.statistics?.[period]?.activities?.average_score ?? "-"}
                               title="score qualité"
            />
        </View>

        <TouchableHighlight onPress={onPeriodPress}
                            underlayColor={theme.colors.lighter}
                            style={{
                                borderWidth: 1,
                                borderColor: theme.colors.lightMore,
                                // backgroundColor: theme.colors.white,
                                borderRadius: 10,
                                alignItems: "center",
                                width: dimensions?.width - 40,
                                // paddingHorizontal: 4,
                                paddingVertical: 5,
                                position: "absolute",
                                top: 10
                                // right: 10
                            }}>
            <TextM color={theme.colors.grey}
                   fontWeight="Medium">{periodName.toUpperCase()}</TextM>
        </TouchableHighlight>


    </View>
}