import {useContext} from 'react'
import {NavigationRefContext} from "@common/utils/mobileUtils";

export function useCamera(
    formId = "default"
) {

    // const [scannedImage, setScannedImage] = useState(null);
    //const navigation = useNavigation()
    const navigationRefContext = useContext(NavigationRefContext)
    const launchCamera = () => {
        navigationRefContext?.navigationRef?.current?.navigate("camera", {
            formId
        })
        /*navigation.push("camera", {
            formId
        })*/
    }

    return [launchCamera]
}
