import React, {useContext, useState} from "react";
import {AbilityContext} from "@data/utility/Can";
import {getPrivilegedRole} from "@data/utility/ability";
import {View} from "react-native";
import {SubscriptionProductChip} from "../SubscriptionPrivilegeChip";
import TextM from "@common/components/text/TextM";
import {isSubscriptionPaused, isSubscriptionRecurring, isSubscriptionTrialing} from "@data/utility/dataUtils";
import moment from "moment";
import {ProductIcon, subscriptionStatusNames} from "./MySubscriptionScreen";
import {ProfileSection} from "../ProfileSection";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import * as WebBrowser from "expo-web-browser";
import {theme} from "@common/theme/theme";
import TitleM from "@common/components/text/TitleM";
import {LinearGradient} from "expo-linear-gradient";
import {PremiumPlus} from "@common/components/icons/IconsM";
import {formatDate} from "@data/utility/Utils";
import LinkTextM from "@common/components/text/LinkTextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import base64 from "@common/utils/base64";
import {useNavigation} from "@react-navigation/native";
import {SchoolSubjectBadge} from "../../../tutor/students/ProfilDetails";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {AvailabilitySlotsM} from "../../../admin/AvailabilitySlotsM";

function SubscriptionContinuityCTA(props) {

    const {user} = props
    const dimensions = useContext(DimensionsContext)

    const navigation = useNavigation()

    return <LinearGradient style={{
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 20,
        width: dimensions?.width - 40,
        paddingHorizontal: 15,
        paddingTop: 10,
        paddingBottom: 10,
        marginTop: 10
    }}
                           colors={[theme.colors.material.secondary["50"], theme.colors.material.secondary["100"]]}
    >
        <View style={{
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            // justifyContent: 'flex-start',
            flexDirection: "row",
            flex: 1
        }}>
            <View style={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                // justifyContent: 'flex-start',
                flex: 1
            }}>
                <TitleM color={theme.colors.accentDark} fontWeight={"Black"}>La suite...</TitleM>
                <TextM wrap>{user?.firstname} semble avoir bien profité de l'accompagnement Masteur jusqu'ici. Ne vous
                    arrêtez pas là, et
                    profitez de notre offre de continuité* !</TextM>
                <TextM wrap fontSize={10} fontWeight={"Light"} style={{marginTop: 4}}>*Offre promotionnelle valable
                    jusqu'au {moment(user?.subscription_state?.end_date).add(-1, "day")?.format("DD MMMM YYYY")}</TextM>
            </View>
            <PremiumPlus size={40}/>
        </View>
        <FilledButtonM color={theme.colors.accentDark}
                       style={{marginTop: 10, width: "100%"}}
                       textColor={theme.colors.white}
                       onPress={() => {
                           const userEmail = base64.encode(user?.email)
                           navigation.push("subscription-continuity", {
                               user: userEmail
                           })
                       }}
                       label={"En savoir plus"}/>

    </LinearGradient>;
}


function MySubscriptionInfos(props) {

    const {billingPortalUrl, user, subscriptionTableData, initialShowDetails = false} = props

    const ability = useContext(AbilityContext)
    const product = getPrivilegedRole(user)
    const productName = user?.subscription_state?.plan

    const commitmentEndsAt = user?.commitment_ends_at ? moment(user?.commitment_ends_at) : null
    const commitmentEndsAtFormatted = commitmentEndsAt?.isAfter(moment()) ? commitmentEndsAt.format("DD MMMM YYYY") : null

    const nextPaymentAmount = user?.upcoming_invoice_data?.amount_due > 0 ? user?.upcoming_invoice_data?.amount_due : (user?.planned_subscription?.amount + user?.stripe_balance)
    const nextPaymentAttempt = user?.upcoming_invoice_data?.next_payment_attempt ?? user?.planned_subscription?.start_date

    const [showDetails, setShowDetails] = useState(initialShowDetails)

    const noSchoolSubject = !!user?.profile?.subject_priority && user?.prioritized_school_subjects?.length === 0

    const navigation = useNavigation()

    return <>
        {user?.current_holiday_courses?.length > 0 && <ProfileSection title={"Mes stages"}
                                                                      noBorder
                                                                      style={{
                                                                          paddingBottom: 20,
                                                                          width: "100%"
                                                                      }}>
            {user?.current_holiday_courses?.map((course) => {

                return <View key={course?.id}
                             style={{marginTop: 10}}>
                    {/*    name, start_date, end_date, class, first_school_subject_id, second_school_subject_id*/}
                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: 10
                    }}>
                        <TextM fontWeight={"Medium"} style={{marginRight: 10}} wrap>{course?.name}</TextM>
                        <TextM wrap>{moment(course?.start_date).format("DD MMMM")} - {moment(course?.end_date).format("DD MMMM")}</TextM>
                    </View>
                    <View style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 10
                    }}>
                        <TextM wrap style={{marginRight: 10}}>{course?.class}</TextM>
                        <SchoolSubjectBadge style={{marginRight: 10}}
                                            schoolSubject={props.schoolSubjects?.find((subject) => subject?.id === course?.first_school_subject_id)}/>
                        {course?.second_school_subject_id && <SchoolSubjectBadge style={{marginRight: 10}}
                                                                                 schoolSubject={props.schoolSubjects?.find((subject) => subject?.id === course?.second_school_subject_id)}/>}
                    </View>
                </View>
            })}
        </ProfileSection>}
        <ProfileSection title={"Mon abonnement"}
                        noBorder
                        style={{
                            paddingBottom: 20,
                            width: "100%"
                        }}>
            <View style={{
                // alignItems: "center",
                width: "100%"
            }}>

                <View style={{
                    flexDirection: "row",
                    marginTop: 20
                    // alignItems: "center",
                }}>
                    <SubscriptionProductChip product={productName} style={{marginRight: 10}}/>
                    <ProductIcon size={38}
                                 style={{
                                     // alignSelf: "flex-end",
                                     marginLeft: 10,
                                     marginBottom: 5
                                 }}/>

                </View>

                <View style={{}}>
                    <TextM style={{
                        marginTop: 10
                    }}
                           fontWeight={"Medium"}
                           wrap>{subscriptionStatusNames?.[user?.subscription_state?.status] ?? "Pas d'abonnement actif"}</TextM>
                    <LinkTextM onPress={() => {
                        setShowDetails(!showDetails)
                    }}
                               style={{
                                   fontSize: 10,
                                   marginTop: 8
                               }}>Afficher les détails<FontAwesome5 name={!showDetails ? "chevron-down" : "chevron-up"}
                                                                    style={{marginLeft: 4}}
                                                                    size={10}
                                                                    solid
                                                                    color={theme.colors.primary}
                    /></LinkTextM>
                </View>

                {showDetails && <View style={{marginLeft: 10}}>
                    {/*<PrivilegesChecklistM data={subscriptionTableData} tinyMode style={{marginTop: 10}}/>*/}
                    {isSubscriptionPaused(user) && user?.subscription_state?.end_date && user?.subscription_state?.end_date !== "-" &&
                        <View style={{flexDirection: "row", alignItems: "flex-start", marginTop: 10}}>
                            <FontAwesome5 name={"pause"}
                                          solid
                                          color={theme.colors.primary}
                                          style={{marginRight: 6, marginTop: 1, width: 18}}
                                          size={16}/>
                            <TextM style={{}} wrap>Date de suspension :
                                le {moment(user?.subscription_state?.end_date)?.format("DD MMMM YYYY")}</TextM>

                        </View>}

                    {/* If no planned subscription */}
                    {!isSubscriptionPaused(user) && !isSubscriptionRecurring(user) && !isSubscriptionTrialing(user) && user?.subscription_state?.end_date !== "-" &&
                        <View style={{flexDirection: "row", alignItems: "flex-start", marginTop: 10}}>
                            <FontAwesome5 name={"calendar-times"}
                                          solid
                                          color={theme.colors.primary}
                                          style={{marginRight: 6, marginTop: 1, width: 18}}
                                          size={16}/>
                            <TextM style={{}} wrap>Date de fin de l'abonnement :
                                le {moment(user?.subscription_state?.end_date)?.format("DD MMMM YYYY")}</TextM>
                        </View>}

                    {user?.subscription_state?.planned_start_date &&
                        <View style={{flexDirection: "row", alignItems: "flex-start", marginTop: 10}}>
                            <FontAwesome5 name={"arrow-right"}
                                          solid
                                          color={theme.colors.primary}
                                          style={{marginRight: 6, marginTop: 1, width: 18}}
                                          size={16}/>
                            {isSubscriptionPaused(user) ?
                                <TextM style={{}} wrap>
                                    Reprise en {user?.subscription_state?.planned_plan} prévue
                                    le {formatDate(moment(user?.subscription_state?.planned_start_date))}
                                </TextM> : <TextM style={{}} wrap>
                                    Passage en {user?.subscription_state?.planned_plan} prévu
                                    le {formatDate(moment(user?.subscription_state?.planned_start_date))}
                                </TextM>}
                        </View>}

                    {nextPaymentAmount > 0 &&
                        <View style={{flexDirection: "row", alignItems: "flex-start", marginTop: 10}}>
                            <FontAwesome5 name={"file-invoice-dollar"}
                                          solid
                                          color={theme.colors.primary}
                                          style={{marginRight: 6, marginTop: 1, width: 18}}
                                          size={16}/>
                            <TextM style={{}} wrap>Prochaine facture : {nextPaymentAmount}€
                                le {moment(nextPaymentAttempt)?.format("DD MMMM YYYY")}</TextM>
                        </View>}

                    {commitmentEndsAtFormatted &&
                        <View style={{flexDirection: "row", alignItems: "flex-start", marginTop: 10}}>
                            <FontAwesome5 name={"handshake"}
                                          solid
                                          color={theme.colors.primary}
                                          style={{marginRight: 6, marginTop: 1, width: 18}}
                                          size={16}/>
                            <TextM style={{}} wrap>Date de fin de l'engagement : le {commitmentEndsAtFormatted}</TextM>
                        </View>}

                </View>}

                {!isSubscriptionPaused(user) && !isSubscriptionRecurring(user) && !isSubscriptionTrialing(user) &&
                    <SubscriptionContinuityCTA user={user}/>}
            </View>
        </ProfileSection>
        {(isSubscriptionPaused(user) || isSubscriptionRecurring(user) || isSubscriptionTrialing(user)) && <>
            <ProfileSection title={"Mes matières"}
                            tiny
                            noBorder
                            style={{
                                width: "100%"
                            }}>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: 10
                }}>
                    {user?.prioritized_school_subjects?.length > 0
                        ?
                        (user?.prioritized_school_subjects?.map((item) => {
                            return <SchoolSubjectBadge key={item?.id}
                                                       iconWidth={24}
                                                       fontSize={14}
                                                       style={{maxWidth: 180}}
                                                       schoolSubject={item}/>
                        }))
                        : user?.profile?.subject_priority === null
                            ? <SchoolSubjectBadge iconWidth={24}
                                                  fontSize={14}
                                                  style={{maxWidth: 180}}
                                                  schoolSubject={{name: "Aide aux devoirs"}}/>
                            : <TextM wrap
                                     fontWeight={"Medium"}
                                     color={theme.colors.warning}>Pas de matière choisie</TextM>
                    }
                </View>

                <FilledButtonM mode={"outlined"}
                               icon={"book"}
                               color={theme.colors.primary}
                    // textColor={theme.colors.white}
                               onPress={() => {
                                   navigation.push("prioritized-school-subjects", {user: base64.encode(user?.email)})
                               }}
                               labelStyle={{fontSize: 12}}
                               contentStyle={{height: 30}}
                               label={!noSchoolSubject ? "Je souhaite changer de matière" : "Choisir mes matières"}/>
            </ProfileSection>
            <ProfileSection title={"Mes disponibilités"}
                            tiny
                            noBorder
                            style={{
                                paddingBottom: 20,
                                width: "100%"
                            }}>
                {user?.slots_possible?.length > 0
                    ? <AvailabilitySlotsM slots={user?.slots_possible}/>
                    : <TextM wrap
                             fontWeight={"Medium"}
                             color={theme.colors.warning}>Pas de créneaux renseignés</TextM>}
                <FilledButtonM mode={"outlined"}
                               icon={"clock"}
                               color={theme.colors.primary}
                               onPress={() => {
                                   navigation.push("availability-slots", {user: base64.encode(user?.email)})
                               }}
                               style={{
                                   marginTop: 7,
                                   alignSelf: "center"
                               }}
                               labelStyle={{fontSize: 12}}
                               contentStyle={{height: 30}}
                               label={user?.slots_possible?.length > 0 ? "Je souhaite changer d'horaires" : "Indiquer mes disponibilités"}/>
            </ProfileSection>
        </>}
        <ProfileSection title={"Gérer"}
                        noBorder
                        style={{
                            paddingBottom: 20,
                            paddingTop: 20,
                            width: "100%"
                        }}>
            <FilledButtonM mode={"outlined"}
                           icon={"credit-card"}
                           color={theme.colors.primary}
                // textColor={theme.colors.white}
                           style={{marginTop: 20}}
                           onPress={() => {
                               if (IS_WEB) {
                                   window.open(billingPortalUrl);
                               } else {
                                   WebBrowser.openBrowserAsync(billingPortalUrl);
                               }
                           }}
                           labelStyle={{fontSize: 12}}
                           contentStyle={{height: 30}}
                           label={"Mon moyen de paiement"}/>
            <FilledButtonM mode={"outlined"}
                           icon={"file-invoice"}
                           color={theme.colors.primary}
                // textColor={theme.colors.white}
                           onPress={() => {
                               if (IS_WEB) {
                                   window.open(billingPortalUrl);
                               } else {
                                   WebBrowser.openBrowserAsync(billingPortalUrl);
                               }
                           }}
                           labelStyle={{fontSize: 12}}
                           contentStyle={{height: 30}}
                           label={"Mes factures"}/>
            {/*{!commitmentEndsAtFormatted && <FilledButtonM mode={"outlined"}*/}
            {/*                                              icon={"pause"}*/}
            {/*                                              color={theme.colors.primary}*/}
            {/*    // textColor={theme.colors.white}*/}
            {/*                                              onPress={() => {*/}
            {/*                                              }}*/}
            {/*                                              disabled*/}
            {/*                                              style={{}}*/}
            {/*                                              labelStyle={{fontSize: 12}}*/}
            {/*                                              contentStyle={{height: 30}}*/}
            {/*                                              label={"Suspendre mon abonnement"}/>}*/}
        </ProfileSection>
    </>
}

const mapStateToProps = state => {
    return {
        request: state.api.getSubscribePricing?.request,
        success: state.api.getSubscribePricing?.success,
        error: state.api.getSubscribePricing?.error,
        user: state.data.formData?.user,
        billingPortalUrl: state.data.formData?.billingPortalUrl,
        pricingTableData: state.data.formData?.pricingTableData,
        schoolSubjects: state.data.formData?.schoolSubjects,
        pricingTableDataNextMode: state.data.formData?.pricingTableDataNextMode
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest,
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(MySubscriptionInfos)