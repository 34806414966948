import photo from "../../../assets/icons/activity-steps/photo.png"
import play from "../../../assets/icons/activity-steps/play.png"
import form from "../../../assets/icons/activity-steps/form.png"
import text from "../../../assets/icons/activity-steps/text.png"
import audio from "../../../assets/icons/activity-steps/audio.png"
import chrono from "../../../assets/icons/activity-steps/chrono.png"
import {IconImageM} from "@common/components/icons/IconsM";
import React from "react";

export const ActivityIcon = props => {
    const {width, type, style} = props

    const source = () => {
        switch (type) {
            case "chrono":
            case "timer":
                return chrono

            case "audio_video":
                return audio

            case "photo":
                return photo

            case "play":
                return play

            case "text":
                return text

            default:
                return form
        }
    }

    return <IconImageM source={source()} width={width} style={style}/>
}