import React, { useContext } from "react";
import { View } from "react-native";
import { apiIdle, apiRequest } from "@data/redux/actions/api";
import { connect } from "react-redux";
import { theme } from "@common/theme/theme";
import TextM from "@common/components/text/TextM";
import { setIsLiveModeEnabled } from "@data/redux/actions/liveMode";
import { DimensionsContext } from "@common/utils/mobileUtils";
import FilledButtonM from "../../common/components/button/FilledButtonM";
import { boxedCardBorderStyle } from "../../reviewer/BoxedCardBorderStyle";
import { IS_WEB } from "../../common/utils/mobileUtils";
import { useNavigation } from "@react-navigation/native";
import { BadgeM } from "../../common/components/badge/BadgeM";

function MissingLiveReportsCard(props) {
  const dimensions = useContext(DimensionsContext);
  const navigation = useNavigation();
  const count = props.user?.incomplete_live_occurrences?.count;
  const occurrenceId =
    props.user?.incomplete_live_occurrences?.last_incomplete_live_occurrence_id;

  return (
    <View
      style={{
        // flex: 1,
        marginTop: 30,
        borderColor: theme.colors.lightMore,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        backgroundColor: theme.colors.white,
        paddingHorizontal: 20,
        paddingVertical: 20,
        width: dimensions?.width,
        ...boxedCardBorderStyle,
      }}
    >
      {count > 0 && (
        <BadgeM
          count={count}
          style={{
            backgroundColor: theme.colors.error,
            color: theme.colors.white,
            position: "absolute",
            top: 5,
            right: 5,
          }}
        />
      )}
      <View>
        <TextM fontWeight={"Bold"} wrap fontSize={20}>
          {"Comptes-rendus de lives manquants"}
        </TextM>
        <TextM
          wrap
          style={{
            marginTop: 10,
          }}
        >
          Il manque des comptes-rendus de lives. Merci de les compléter
          rapidement !
        </TextM>
      </View>
      <FilledButtonM
        style={{
          width:
            IS_WEB && dimensions.width > 800 ? dimensions?.width / 4 : null,
          marginTop: 20,
          alignSelf: "flex-start",
        }}
        color={theme.colors.accent}
        label={"compléter"}
        onPress={() => {
          navigation.push("end-session-tutor", {
            occurrence_id: occurrenceId,
          });
        }}
      />
    </View>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.data.currentUser?.object,
  };
};

const mapDispatchToProps = {
  apiRequest,
  apiIdle,
  setIsLiveModeEnabled,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MissingLiveReportsCard);
