import React from 'react';
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {ListItemM} from "@common/components/list/ListItemM";
import {IconButton} from "react-native-paper";
import {FlatList, View} from "react-native";


const SchoolSubjectModal = ((props) => {

    const {options, onSelectSchoolSubject, setVisible} = props

    return <View style={{
        height: "100%"
    }}>
        <View>
        <TextM fontWeight="ExtraBold"
               fontSize={20}
               style={{
                   marginLeft: 15,
                   marginTop: 15,
                   marginBottom: 20
               }}
               color={theme.colors.primary}>Choisis la matière</TextM>
        <IconButton
            icon="times"
            iconColor={theme.colors.grey}
            size={22}
            style={{
                position: "absolute",
                right: 5,
                top: 5
            }}
            onPress={() => {
                setVisible(false)
            }}
        />
        </View>
        <FlatList
            data={options}
            initialScrollIndex={0}
            itemHeight={70}
            renderItem={(item) => {
                return <ListItemM isFirst={(item.index === 0)}
                                  isLast={(item.index === options?.length - 1)}
                                  onPress={() => {
                                      onSelectSchoolSubject(item?.item?.value)
                                  }}
                    // selected={item?.item?.value === selectedItem}
                                  itemHeight={70}
                                  option={item.item}/>
            }}
        />
    </View>
})

export default SchoolSubjectModal