import {ActivityIndicator, Text} from "react-native-paper";
import {subscriptionService} from "@data/services/subscriptionService";
import {TouchableOpacity, View} from "react-native";
import React, {useState} from 'react'
import {useController, useForm} from "react-hook-form";
import PhoneInput from "@sesamsolutions/phone-input";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {theme} from "@common/theme/theme";


const ReminderDialogComponent = (props) => {
    const {resolver} = props
    const [selectedDuration, setSelectedDuration] = useState(null)
    const durationOptions = ["Dès que possible", "Dans 3 jours", "Dans 2 semaines", "Dans 1 mois"]

    const {control: dialogControl, handleSubmit, formState: {errors, isDirty, dirtyFields}} = useForm({
        resolver,
        defaultValues: {}
    })
    const {field: mobileNumber} = useController({
        name: "mobile",
        control: dialogControl
    })
    const {field: when} = useController({
        name: "when",
        control: dialogControl
    })

    return <View style={{width: "100%"}}>
        <Text variant="bodyMedium" style={{textAlign: 'center', marginBottom: 20}}>
            Indiquez votre numéro, et dîtes-nous quand on peut vous rappeler
        </Text>
        <PhoneInput
            initialCountry="FR"
            dismissKeyboard={false}
            textStyle={{
                fontFamily: "Montserrat-Regular",
                fontSize: 16
            }}
            style={{
                width: "100%",
                borderColor: !mobileNumber.value ? theme.colors.error : theme.colors.light,
                borderWidth: 1,
                borderRadius: theme.roundness,
                height: 55,
                alignItems: "center",
                paddingHorizontal: 10,
                marginTop: 5
            }}
            onChange={(data) => {
                mobileNumber.onChange(data?.e164)
            }}/>
        {props.callMeBackRequest ?
            <ActivityIndicator color={theme.colors.primary} style={{marginTop: 10}}/> :
            <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: 5,
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                {durationOptions.map((duration, index) => {
                    return <TouchableOpacity key={index}
                                             style={{
                                                 borderRadius: 10,
                                                 backgroundColor: selectedDuration === duration ? theme.colors.white : theme.colors.primary,
                                                 borderColor: theme.colors.primary,
                                                 borderWidth: 1,
                                                 justifyContent: 'center',
                                                 alignItems: 'center',
                                                 margin: 5,
                                                 padding: 10,
                                                 opacity: mobileNumber.value ? 1 : 0.5
                                             }}
                                             disabled={!mobileNumber.value}
                                             onPress={() => {
                                                 setSelectedDuration(duration)
                                                 when.onChange(duration)
                                                 const data = {
                                                     mobile: mobileNumber.value,
                                                     when: duration
                                                 }
                                                 props.apiRequest(subscriptionService.callMeBack, {}, data, {})
                                             }}
                    >
                        <Text variant="bodyMedium" style={{
                            textAlign: 'center',
                            color: selectedDuration === duration ? theme.colors.primary : theme.colors.white
                        }}>
                            {duration}
                        </Text>
                    </TouchableOpacity>
                })}
            </View>}
    </View>;
}
const mapStateToProps = state => {
    return {
        callMeBackRequest: state.api.callMeBack?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ReminderDialogComponent)