import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import Constants from "expo-constants";

export const recordStartTime = async () => {
    try {
        const now = new Date();
        await AsyncStorage.setItem("@start_time", now.toISOString());
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};
export const getElapsedTime = async () => {
    try {
        const startTime = await AsyncStorage.getItem("@start_time");
        const now = moment();
        return now.diff(startTime, 'seconds');
    } catch (err) {
        // TODO: handle errors from getItem properly
        console.warn(err);
    }
};

export const incrementActivitiesCount = async () => {
    try {
        const count = await AsyncStorage.getItem("@activities_count");

        await AsyncStorage.setItem("@activities_count", (parseInt(count ?? "0") + 1).toString());
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};

export const getActivitiesCount = async () => {
    try {
        return parseInt(await AsyncStorage.getItem("@activities_count") ?? "0");

    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};

export const getLastVersionPromptedForReview = async () => {
    try {
        return await AsyncStorage.getItem("@last_version_prompted_for_review");
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
}

export const setLastVersionPromptedForReview = async () => {
    try {
        await AsyncStorage.setItem("@last_version_prompted_for_review", Constants.expoConfig.version);
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};

export const getSamSecurityShown = async () => {
    try {
        return await AsyncStorage.getItem("@sam_security_shown");
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
}

export const setSamSecurityShown = async () => {
    try {
        await AsyncStorage.setItem("@sam_security_shown", "true");
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};

export const getFromStorage = async (sKey) => {
    try {
        return await AsyncStorage.getItem(sKey);
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
}

export const setToStorage = async (sKey, sValue) => {
    try {
        await AsyncStorage.setItem(sKey, sValue);
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};

export const removeFromStorage = async (sKey) => {
    try {
        await AsyncStorage.removeItem(sKey);
    } catch (err) {
        // TODO: handle errors from setItem properly
        console.warn(err);
    }
};