import axios from "axios"

function getRooms({}, {}, params) {
    return axios.get(`api/backoffice/live-rooms`, {params})
}

function newRoom({}, data) {
    return axios.post(`/api/backoffice/live-rooms`, data)
}

function editRoom({id}, data) {
    return axios.put(`/api/backoffice/live-rooms/${id}`, data)
}

function deleteRoom({id}) {
    return axios.delete(`/api/backoffice/live-rooms/${id}`)
}

function addMembersToSessions({id}, data) {
    return axios.post(`/api/backoffice/live-rooms/${id}/add-users`, data)
}

function addMultipleSessions({}, data) {
    return axios.post(`/api/backoffice/live-sessions/multiple`, data)
}

export const roomService = {
    getRooms,
    newRoom,
    editRoom,
    deleteRoom,
    addMembersToSessions,
    addMultipleSessions
}

