import {FormStepper} from "@common/components/stepper/FormStepper";
import React, {useState} from "react";
import {validation} from "@data/utility/validation";
import {schoolsService} from "@data/services/schoolsService";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect, useSelector} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {View} from "react-native";

export const classroomFormData = (defaultValues = {}) => {

    const schoolSubjects = useSelector(state => state.data.staticData?.school_subjects)

    return [
        {
            label: "Nom de la classe",
            type: "text.multiple",
            inputs: [
                {
                    label: "Nom de la classe",
                    name: "name",
                    placeholder: "Nom de la classe",
                    defaultValue: defaultValues?.name ?? "",
                    validationRules: {
                        ...validation.required()
                    }
                }
            ]
        },
        {
            label: "Quelle matière est étudiée par la classe ?",
            type: "select.single",
            name: "school_subject_id",
            options: schoolSubjects
                ?.map(x => {
                    return {icon: x?.icon, value: x.id, label: x.name}
                }),
            defaultValue: defaultValues?.school_subject_id ?? ""
        }
    ]
}

function NewClassroomStepper(props) {

    const [formValues, setFormValues] = useState(null)
    const navigation = useNavigation()
    const defaultValues = {}


    useApiStatus(
        schoolsService.postClassroom, // api service
        false, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        () => {
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Succés",
                    text: "Classe ajoutée !"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )
    const onSubmit = data => {
        if (props.postClassroomRequest) return
        // console.log(data)
        props.apiRequest(schoolsService.postClassroom, {}, {
            ...data
        })
    }
    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>
            <FormStepper navigation={navigation}
                         track
                         formData={classroomFormData()?.filter(x => !x.is_masked)}
                         champion={null}
                         setFormValues={setFormValues}
                         defaultValues={defaultValues}
                         onSubmit={onSubmit}
                         submitted={props.postClassroomRequest}
                         previousRouteName={"menu"}
                         submittedMessage=""
                         serverError={props.postClassroomError}
            />
        </View>
    )

}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        schoolSubjects: state.data.staticData?.school_subjects,
        postClassroomRequest: state.api.postClassroom?.request,
        postClassroomError: state.api.postClassroom?.error,
        postClassroomSuccessData: state.api.postClassroom?.data,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(NewClassroomStepper)