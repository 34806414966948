import React from 'react';
import {BottomSheetModalM} from "@common/components/modal/BottomSheetM";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {ListItemM} from "@common/components/list/ListItemM";
import {BottomSheetFlatList} from "@gorhom/bottom-sheet";
import {IconButton} from "react-native-paper";


const SchoolSubjectBottomSheetModal = React.forwardRef((props, ref) => {

    const {options, onSelectSchoolSubject} = props

    return <BottomSheetModalM backgroundStyle={{
        backgroundColor: theme.colors.lighter,
        borderRadius: 0,
        borderTopWidth: 1,
        borderColor: theme.colors.lightMore
    }}
                              ref={ref}
                              withPanning
                              handleIndicatorStyle={{
                                  backgroundColor: theme.colors.grey
                              }}
                              {...props}
    >
        <TextM fontWeight="ExtraBold"
               fontSize={20}
               style={{
                   marginLeft: 15,
                   marginTop: 15,
                   marginBottom: 20
               }}
               color={theme.colors.primary}>Choisis la matière</TextM>
        <IconButton
            icon="times"
            iconColor={theme.colors.grey}
            size={22}
            style={{
                position: "absolute",
                right: 5,
                top: 5
            }}
            onPress={() => {
                ref?.current?.dismiss()
            }}
        />
        <BottomSheetFlatList
            data={options}
            keyExtractor={(i, index) => index}
            initialScrollIndex={0}
            itemHeight={70}
            renderItem={(item) => {
                return <ListItemM isFirst={(item.index === 0)}
                                  isLast={(item.index === options?.length - 1)}
                                  onPress={() => {
                                      ref?.current?.dismiss()
                                      onSelectSchoolSubject(item?.item?.value)
                                  }}
                    // selected={item?.item?.value === selectedItem}
                                  itemHeight={70}
                                  option={item.item}/>
            }}
        />
    </BottomSheetModalM>
})

export default SchoolSubjectBottomSheetModal