const shirts = {
    shirt1: true,
    shirt2: true,
    shirt3: true,
    shirt4: true,
    shirt5: true,
    shirt6: true,
    chemise1: true,
    chemise2: true,
    chemise3: true,
    pull1: true,
    pull2: true,
    pull3: true,
    robe1: true,
    robe2: true,
    robe3: true,
    shirtBeige: true,
    shirtBlanc: true,
    shirtCyan: true,
    shirtGris: true,
    shirtJaune: true,
    shirtNoir: true,
    shirtViolet: true,

};
export const shirtList = [
    {
        name: "shirt1"
    },
    {
        name: "shirt2"
    },
    {
        name: "shirt3"
    },
    {
        name: "shirt4"
    },
    {
        name: "shirt5"
    },
    {
        name: "shirt6"
    },
    {
        name: "chemise1"
    },
    {
        name: "chemise2"
    },
    {
        name: "chemise3"
    },
    {
        name: "pull1"
    },
    {
        name: "pull2"
    },
    {
        name: "pull3"
    },
    {
        name: "robe1"
    },
    {
        name: "robe2"
    },
    {
        name: "robe3"
    },
    {
        name: "shirtBeige"
    },
    {
        name: "shirtBlanc"
    },
    {
        name: "shirtCyan"
    },
    {
        name: "shirtGris"
    },
    {
        name: "shirtJaune"
    },
    {
        name: "shirtNoir"
    },
    {
        name: "shirtViolet"
    }
];

export default shirts;
