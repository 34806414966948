import {View} from "react-native";
import {IconImageM} from "@common/components/icons/IconsM";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import React from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import moment from "moment";
import {BadgeM} from "@common/components/badge/BadgeM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {capitalize} from "@data/utility/Utils";
import {ChallengeProgressBarNew} from "./ChallengeProgressBarNew";

function ChallengeHeaderNew(props) {

    const {challenge, hideProgressBar, columnMode, countLateActivities} = props

    const isFinished = !!challenge?.finished_at
    const isAbandoned = !!challenge?.abandoned_at

    return <View style={{
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        width: "100%",
        paddingTop: 20,
        // paddingBottom: 20
    }}>
        <View style={{
            marginHorizontal: 10,
            paddingHorizontal: 4,
            marginBottom: columnMode ? 20 : 0,
            opacity: (isAbandoned) ? 0.6 : 1,
            alignItems: "center",
            width: "100%"
        }}>

            <IconImageM
                uri={challenge?.school_subject?.icon ?? challenge?.icon}
                // uri={findItemInListWithId(challenge?.challenge_template_id, props.challengeTemplates)?.icon}
                // uri={challenge?.icon}
                width={80}
            />
            <View style={{
                paddingHorizontal: 15,
                paddingVertical: 6,
                borderRadius: 15,
                marginTop: -20,
                backgroundColor: theme.colors.material.secondary["300"],
                opacity: (isAbandoned) ? 0.6 : 1
            }}>
                <TextM fontWeight="Bold"
                       color={theme.colors.brown}
                       fontSize={20}>{challenge?.title?.toUpperCase()}</TextM>
            </View>
            <TextM fontWeight="Bold"
                   color={theme.colors.greyAlt}
                   style={{
                       textAlign: "center",
                       width: "100%",
                       marginTop: 3
                   }}
                   wrap
                   fontSize={18}>
                {challenge?.school_subject?.name}{((challenge?.chapter || challenge?.domain) && " | " + (challenge?.chapter || challenge?.domain))}
            </TextM>
        </View>

        <View style={{
            // flex: 1,
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 0,
            opacity: (isAbandoned) ? 0.6 : 1
        }}>
            {!!challenge?.school_subject && <View style={{flexDirection: "row"}}>


                <View style={{
                    // flex: 1,
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: "center",
                    paddingHorizontal: 5,
                    // backgroundColor: theme.colors.primaryLighter,
                    paddingVertical: 18,
                }}>

                    <FontAwesome5 color={theme.colors.greyAlt} solid size={20} name="calendar"/>
                    <TextM fontSize={16}
                           style={{
                               marginLeft: 8
                           }}
                           fontWeight="Bold"
                           color={theme.colors.greyAlt}>{capitalize(moment(challenge?.deadline)?.format("ddd DD MMMM"))}</TextM>

                </View>

            </View>}
            {isAbandoned
                ? <TextM fontSize={12}
                         color={theme.colors.greyAlt}
                         style={{marginVertical: 3}}
                >Abandonné le {moment(challenge?.abandoned_at).format(`DD/MM à HH:mm`)}</TextM>
                : isFinished
                    ? <TextM fontSize={12}
                             color={theme.colors.success}
                             style={{marginTop: 3}}
                    >Terminé le {moment(challenge?.finished_at).format("DD/MM à HH:mm")}</TextM>
                    : <></>}
            {!hideProgressBar && <ChallengeProgressBarNew challenge={challenge}/>}

        </View>

        {/*<TextM fontWeight="SemiBold"*/}
        {/*       color={theme.colors.primary}*/}
        {/*       style={{position: "absolute", left: 30, bottom: 8}}*/}
        {/*       fontSize={10}>DÉFIS</TextM>*/}
        {/*{!hideChevron && <FontAwesome5 name={"chevron-" + (expanded ? "up" : "down")}*/}
        {/*                               style={{position: "absolute", left: 12, bottom: 5}}*/}
        {/*                               color={theme.colors.primary}*/}
        {/*                               size={18}/>}*/}
        {isAbandoned
            ? <></>
            : isFinished
                ? <></>
                : countLateActivities > 0 && <BadgeM count={countLateActivities}
                                                     style={{
                                                         backgroundColor: theme.colors.warning,
                                                         color: theme.colors.white,
                                                         position: "absolute",
                                                         top: 8,
                                                         right: 8
                                                     }}/>}
        {/*<View style={{*/}
        {/*    position: "absolute",*/}
        {/*    top: 10,*/}
        {/*    left: 10,*/}
        {/*    backgroundColor: theme.colors.white,*/}
        {/*    padding: 10,*/}
        {/*    borderRadius: 50,*/}
        {/*}}>*/}
        {/*    <IconImageM*/}
        {/*        uri={findItemInListWithId(challenge?.challenge_template_id, props.challengeTemplates)?.icon}*/}
        {/*        // uri={challenge?.school_subject?.icon}*/}
        {/*        width={50}*/}
        {/*    />*/}
        {/*</View>*/}

    </View>
}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        challengeModes: state.data.staticData?.challenge_modes
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeHeaderNew)