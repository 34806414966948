import React, {useContext} from 'react';
import {View} from "react-native";
import {theme} from "@common/theme/theme";
import {DimensionsContext} from "@common/utils/mobileUtils";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import TextM from "@common/components/text/TextM";
import TitleM from "@common/components/text/TitleM";
import StudyProgramItemsList from "@common/studyPrograms/StudyProgramItemsList";

const StudyProgramItemComponent = (props) => {

    const {
        studyProgram,
        noTitles = false,
        onSelectChapter,
        selectedChapterId,
        liveReportMode = false
    } = props

    const dimensions = useContext(DimensionsContext)
    const studyProgramItems = studyProgram?.study_program_items


    return (
        <View style={{
            alignItems: "center",
            flex: 1
        }}>
            <StudyProgramItemsList studyProgramItems={studyProgramItems}
                                   onSelectChapter={onSelectChapter}
                                   selectedChapterId={selectedChapterId}
                                   headerComponent={<>
                                       {!noTitles && <TitleM style={{
                                           marginVertical: 20
                                       }}>{studyProgram?.title}</TitleM>}
                                       {!noTitles && <TextM fontWeight={"Bold"} color={theme.colors.light} style={{
                                           marginBottom: 40
                                       }}>{studyProgram?.school_subject?.name} | {studyProgram?.class}</TextM>}
                                   </>}
                                   renderChapterOnly={true}
                                   liveReportMode={liveReportMode}
                                   noDelete/>

        </View>
    )
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        schoolSubjects: state.data.staticData?.school_subjects
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
};

export default connect(mapStateToProps, mapDispatchToProps)(StudyProgramItemComponent)