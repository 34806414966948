import {TouchableHighlight, View} from "react-native";
import {theme} from "../../theme/theme";
import {IconImageM} from "../icons/IconsM";
import TextM from "../text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React from "react";
import {IconButton} from "react-native-paper";
import * as Clipboard from 'expo-clipboard';
import AvatarM from "@common/components/avatar/AvatarM";

export function ListItemM(props) {

    // ** Component props
    const {isFirst, isLast, option, selected, itemHeight, noBorder, disabled, withClipBoard = false} = props

    return <TouchableHighlight style={{
        borderTopStartRadius: isFirst ? 15 : 0,
        borderTopEndRadius: isFirst ? 15 : 0,
        borderBottomStartRadius: isLast ? 15 : 0,
        borderBottomEndRadius: isLast ? 15 : 0,
        opacity: option?.disabled ? 0.5 : 1,
        backgroundColor: selected ? theme.colors.primaryLighter : null,
        width: "100%"
    }}
                               onPress={props.onPress}
                               onLongPress={props.onLongPress}
                               disabled={disabled || option?.disabled}
                               underlayColor={theme.colors.lightMore}
    >
        <View style={{
            ...props.style,
            height: itemHeight,
            borderColor: theme.colors.lightMore,
            borderBottomWidth: !noBorder && !isLast ? 1 : 0,
            flexDirection: "row",
            alignItems: "center",
            paddingLeft: 10,
            paddingRight: 20,
            width: "100%"
        }}
        >
            {option?.leftComponent ? option?.leftComponent : null}

            {!!option?.icon && <IconImageM uri={option?.icon}
                                           style={{
                                               marginRight: 10,
                                               marginBottom: itemHeight > 70 ? itemHeight / 3 : 0,
                                               tintColor: option?.tintColor ?? null
                                           }}
                                           width={40}/>}

            {option?.source && <IconImageM source={option?.source}
                                           style={{
                                               marginRight: 10,
                                               marginBottom: 0,
                                               tintColor: option?.tintColor ?? null
                                           }}
                                           width={40}/>}
            {option?.avatar && <AvatarM size={40}
                                        user={option?.avatar}
                                        style={{
                                            marginRight: 10,
                                            marginBottom: itemHeight > 70 ? itemHeight / 3 : 0
                                        }}
            />}

            <View style={{
                // width: "100%",
                flex: 1,
                paddingVertical: 2
            }}
            >
                <TextM fontWeight="Bold"
                       color={theme.colors.grey}
                       wrap
                       fontSize={16}>{option?.label}</TextM>
                {option?.description && <TextM fontWeight="Regular"
                                               color={theme.colors.grey}
                                               style={{
                                                   marginRight: 10
                                               }}
                                               wrap
                                               fontSize={12}>{option?.description}</TextM>}
            </View>
            {selected && <FontAwesome5 name={"check"}
                                       style={{position: "absolute", right: 12}}
                                       color={theme.colors.primary}
                                       size={20}/>}
            {withClipBoard && <IconButton
                icon="copy"
                iconColor={theme.colors.greyAlt}
                size={24}
                style={{position: "absolute", top: 0, right: 0}}
                onPress={() => {
                    Clipboard.setStringAsync(option?.label).then();
                }}
            />}
        </View>
    </TouchableHighlight>;
}