// ** React Imports
import React, {useContext, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {HeaderContainerM} from "@common/header/HeaderContainerM";
import TitleM from "@common/components/text/TitleM";
import {TouchableOpacity, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import ServerSearch from "../../admin/ServerSearch";
import {PaginatedTableM} from "../../admin/PaginatedTableM";
import {referralService} from "@data/services/referralService";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import LinkTextM from "@common/components/text/LinkTextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import AvatarM from "@common/components/avatar/AvatarM";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {studyProgramsService} from "@data/services/studyProgramsService";
import {IconButton} from "react-native-paper";
import {validation} from "@data/utility/validation";
import {backOfficeService} from "@data/services/backOfficeService";
import FormStepperModal from "@common/components/stepper/FormStepperModal";
import {studentClasses} from "@data/constants/formConstants";
import {hasRole} from "@data/utility/ability";

const StudyProgramsScreen = (props) => {

    const {navigation, route} = props

    const studentId = route.params?.studentId

    const [urlParams, setUrlParams] = useState({})
    const dimensions = useContext(DimensionsContext);

    const isAdmin = !studentId;
    const routeParams = isAdmin ? {} : {id: studentId};
    const requestApiService = isAdmin ? studyProgramsService.indexPaginatedStudyPrograms : studyProgramsService.getStudyProgramsAsTutor

    const columns = [
        {
            name: 'Titre',
            style: {
                width: 200,
                marginRight: 40
                // height: 60,
            },
            selector: 'name',
            sortable: true,
            cell: row => <TouchableOpacity onPress={() => {
                navigation.push("study-program", isAdmin ? {id: row?.id} : {id: row?.id, studentId: studentId})
            }}
                                           style={{
                                               justifyContent: "flex-start", alignItems: "center", flexDirection: "row"
                                           }}>
                <View style={{marginLeft: 0}}>
                    <TextM color={theme.colors.primary}
                           fontWeight={"Medium"}
                           wrap
                           style={{}}>{`${row?.title}`}</TextM>
                </View>
            </TouchableOpacity>
        },
        {
            name: 'Classe',
            style: {
                width: 100,
                marginRight: 40
            },
            selector: 'class',
            sortable: true,
            cell: row => <TextM style={{flexWrap: "wrap"}}>{row?.class}</TextM>
        },
        {
            name: 'Matière',
            style: {
                width: 100,
                marginRight: 40
            },
            selector: 'school_subject',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.school_subject?.name}`}</TextM>
            </View>
        },
        {
            name: 'Date de début',
            style: {
                width: 100,
                marginRight: 40
            },
            selector: 'start_date',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.start_date}`}</TextM>
            </View>
        },
        {
            name: 'Date de fin',
            style: {
                width: 100,
                marginRight: 40
            },
            selector: 'end_date',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.end_date}`}</TextM>
            </View>
        },
        {
            name: 'Elèves',
            style: {
                width: 200,
                marginRight: 40
            },
            selector: 'students',
            sortable: true,
            cell: row => <View style={{}}>
                <TextM wrap
                       style={{}}>{`${row?.students?.map(x => x?.display_name)?.join(", ")}`}</TextM>
            </View>
        },
    ]

    const actionsColumn = {
        name: 'Actions',
        style: {
            width: 340,
            marginRight: 40
        },
        selector: 'commission_amount',
        sortable: true,
        cell: row => <View style={{flexDirection: "row"}}>
            <FilledButtonM label={"Activité"}
                           icon={"plus"}
                           style={{
                               marginRight: 5,
                               marginBottom: 0,
                               width: null
                           }}
                           color={theme.colors.primary}
                           labelStyle={{fontSize: 10}}
                           contentStyle={{height: 30}}
                           onPress={() => {
                               navigation.push("new-todo", {forStudents: true, studyProgramId: row?.id})
                           }}/>
            <FilledButtonM label={"Modifier"}
                           icon={"edit"}
                           style={{
                               marginRight: 5,
                               marginBottom: 0,
                               width: null
                           }}
                           color={theme.colors.primary}
                           labelStyle={{fontSize: 10}}
                           contentStyle={{height: 30}}
                           onPress={() => {
                               navigation.push("new-study-program", {study_program_id: row?.id})
                           }}/>
            <FilledButtonM label={"Supprimer"}
                           style={{
                               marginBottom: 0,
                               width: null
                           }}
                           icon={"trash"}
                           loading={props.deleteReferralCodeRequest && props.deleteReferralCodeRequestId === row?.id}
                           disabled={props.deleteReferralCodeRequest && props.deleteReferralCodeRequestId === row?.id}
                           color={theme.colors.error}
                           labelStyle={{fontSize: 10}}
                           contentStyle={{height: 30}}
                           onPress={() => {
                               DialogM.show({
                                   text1: 'Supprimer le propramme ?',
                                   buttonColor1: theme.colors.error,
                                   buttonText1: 'Supprimer', // buttonColor1: theme.colors.error,
                                   onPress: () => {
                                       props.apiRequest(studyProgramsService.deleteStudyProgram, {id: row?.id})
                                   },
                                   buttonText2: 'Annuler',
                                   onPressBack: () => {

                                   }
                               })
                           }}/>
        </View>
    }

    useApiStatus(
        studyProgramsService.deleteStudyProgram, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
        } // success callback
    )

    return (
        <View style={{
            flex: 1
        }}
        >
            <HeaderContainerM style={{justifyContent: "space-between", width: "100%"}} noTopInset>
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginHorizontal: 20
                }}>
                    <IconButton
                        icon={"times"}
                        iconColor={theme.colors.primary}
                        size={32}
                        style={{
                        }}
                        onPress={() => {
                            if (navigation.canGoBack()) navigation.goBack()
                            else navigation.replace("menu")
                        }}
                    />
                    <TitleM>Programmes de révision</TitleM>
                </View>
                {isAdmin && <View style={{flexDirection: "row", alignItems: "center"}}>
                    <FilledButtonM label={"Créer"}
                                   icon={"plus"}
                                   style={{width: null, margin: 20}}
                                   labelStyle={{fontSize: 12}}
                                   onPress={() => {
                                       navigation.push("new-study-program")
                                   }}/>
                </View>}
            </HeaderContainerM>
            <PaginatedTableM data={props.studyPrograms}
                             flatListRef={null}
                             columns={isAdmin ? [...columns, actionsColumn] : columns}
                             headerHeight={60}
                             requestService={requestApiService}
                             urlParams={urlParams}
                             routeParams={routeParams}
            />
        </View>
    )


}

const mapStateToProps = state => {
    return {
        studyPrograms: state.data?.studyPrograms?.studyPrograms,
        indexPaginatedStudyProgramsRequest: state.api?.indexPaginatedStudyPrograms?.request,
        indexPaginatedStudyProgramsSuccess: state.api?.indexPaginatedStudyPrograms?.success,
        deleteStudyProgramRequest: state.api?.deleteStudyProgram?.request,
        deleteStudyProgramRequestId: state.api?.deleteStudyProgram?.routeParams?.id,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyProgramsScreen)