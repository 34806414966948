import {useForm} from "react-hook-form";
import React, {useContext, useEffect, useState} from "react";
import {Keyboard, Platform, View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {AbilityContext} from "@data/utility/Can";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {activitiesService} from "@data/services/activitiesService";
import alertPolyfill from "@common/components/alert/alertPolyfill";
import {calculateAverage} from "@data/utility/Utils";
import {RatingM} from "@common/components/rating/RatingM";
import {samVariantForScore} from "../student/champions/ChampionImageM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TitleM from "@common/components/text/TitleM";
import ScoringCriterion from "./ScoringCriterion";
import SelectBox from "@common/components/select/selectSingle-Multiple";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {scoringService} from "@data/services/scoringService";
import SamAppreciationGenerator from "./SamAppreciationGenerator";


export function ValidateActivity(props) {

    const ability = useContext(AbilityContext)

    const {activity, schoolSubject, time, dimensions, navigation} = props

    const activityTemplate = findItemInListWithId(activity?.activity_template_id, props.activityTemplates)

    const activityScoringCriteria = props.scoringCriteria?.filter(s => activityTemplate?.new_scoring_criteria_ids?.includes(s?.id))
    // console.log(activityScoringCriteria)

    const defaultValues = {
        comment: "",
        scoring_rule_ids: [],
        scoring_comment_ids: {},
        status: "validated"
    }
    activityScoringCriteria?.forEach(criterion => {
        defaultValues["criterion_" + criterion?.id] = 5
    })

    const {control, handleSubmit, formState: {errors, dirtyFields}, getValues, trigger} = useForm({
        defaultValues
    })
    // console.log(dirtyFields)

    // const allFieldsDirty = activityScoringCriteria?.reduce((previousValue, item) => previousValue && dirtyFields["criterion_" + item?.id], true)
    const formDirty = getValues()?.comment !== ""

    const score = calculateAverage(activityScoringCriteria?.map(c => control?._formValues["criterion_" + c?.id]))
    const onSubmit = data => {
        // console.log(data)
        if (props.reviewActivityRequest) return
        Keyboard.dismiss()
        props.apiRequest(activitiesService.reviewActivity, {id: activity?.id}, {
            ...data,
            review_duration: time
        })
    }

    const commentWidth = Platform.OS !== 'web' ? dimensions?.width - 30 : dimensions?.width - 100

    const [selectedIntroductionCommentId, setSelectedIntroductionCommentId] = useState(null)
    const introductionComments = props.scoringComments?.filter(sc => sc?.type === 'introduction'
        && sc?.activity_types?.includes(activity?.type)
        && sc?.school_subject_categories?.includes(schoolSubject?.category)
    )

    const [selectedConclusionCommentId, setSelectedConclusionCommentId] = useState(null)
    const conclusionComments = props.scoringComments?.filter(sc => sc?.type === 'conclusion'
        && sc?.activity_types?.includes(activity?.type)
        && sc?.school_subject_categories?.includes(schoolSubject?.category)
    )

    const rawIntroduction = findItemInListWithId(selectedIntroductionCommentId, props.scoringComments)?.comment ?? ""
    const rawComments = Object.values(control?._formValues?.scoring_comment_ids)?.map(x => props.scoringComments?.find(y => y?.id === x)?.comment)
    const rawConclusion = findItemInListWithId(selectedConclusionCommentId, props.scoringComments)?.comment ?? ""
    const rawAppreciation = rawIntroduction + " " + rawComments?.join(". ") + " " + rawConclusion

    useApiStatus(
        scoringService.postScoringComment, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            if (data?.type === "introduction") {
                // console.log("SUCCESS intro")
                // console.log("data", data)
                setSelectedIntroductionCommentId(data?.id)
                props.apiIdle(scoringService.postScoringComment)
            } else if (data?.type === "conclusion") {
                // console.log("SUCCESS ccl")
                // console.log("data", data)
                setSelectedConclusionCommentId(data?.id)
                props.apiIdle(scoringService.postScoringComment)
            }

        }, // success callback
        () => {
        },
        true
    )

    return <>

        <View style={{flexDirection: "row", width: commentWidth, alignItems: "center", justifyContent: "center"}}>
            <FontAwesome5 name={"check-circle"}
                          solid
                          size={24}
                          color={theme.colors.success}/>
            <TitleM style={{marginLeft: 6}} color={theme.colors.success}>ACTIVITÉ VALIDÉE</TitleM>
        </View>
        <TextM wrap style={{textAlign: "center"}}>Sélectionner les règles qui ne sont pas suffisamment
            respectées</TextM>

        {activityScoringCriteria?.map((criterion, index) => {

            return <ScoringCriterion criterion={criterion}
                                     control={control}
                                     trigger={trigger}
                                     activityType={activity?.type}
                                     schoolSubjectCategory={schoolSubject?.category}
                                     studentClass={activity?.student_class}
                                     key={index}/>
        })}


        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 10,
            paddingBottom: 10,
            // paddingLeft: 20,
            // paddingRight: 20,
            marginTop: 20,
            borderTopWidth: 1,
            // borderBottomWidth: 1,
            borderColor: theme.colors.lightMore,
            width: "100%"
            // backgroundColor: theme.colors.white
        }}>
            <View style={{flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
                <TextM fontSize={18}
                       wrap
                       fontWeight="Bold"
                       color={theme.colors.accentDark}
                       style={{textAlign: "left", marginLeft: 10}}>
                    SCORE
                </TextM>
                <RatingM score={score} size={34} style={{marginRight: 40}}/>
            </View>
            <View style={{width: "100%", alignItems: "flex-start", marginTop: 20}}>
                <TextM fontSize={18}
                       wrap
                       fontWeight="Bold"
                       color={theme.colors.grey}
                       style={{textAlign: "left", marginLeft: 10, marginBottom: 4}}>
                    INTRODUCTION
                </TextM>
                <SelectBox title="Introduction"
                           hideTitle
                    // description={"Activité rejetée"}
                           style={{
                               width: dimensions?.width - 30
                               // marginTop: -30
                           }}
                           options={introductionComments?.map(x => {
                               return {
                                   label: x?.comment,
                                   value: x?.id
                               }
                           })}
                           valueMode
                           value={selectedIntroductionCommentId}
                           onSingleSelect={(value) => {
                               setSelectedIntroductionCommentId(value.value);
                           }}
                           withClipBoard
                           titleRightButtonIcon={"plus"}
                           onTitleRightButtonPress={() => {
                               navigation.push("new-scoring-comment", {
                                   type: "introduction",
                                   description: "Introduction",
                                   scoringRuleId: null,
                                   currentActivityType: activity?.type,
                                   currentSchoolSubjectCategory: schoolSubject?.category,
                               })
                           }}
                />

            </View>
            <View style={{width: "100%", alignItems: "flex-start", marginTop: 10}}>
                <TextM fontSize={18}
                       wrap
                       fontWeight="Bold"
                       color={theme.colors.grey}
                       style={{textAlign: "left", marginLeft: 10}}>
                    POINTS D'AMÉLIORATION
                </TextM>
                {rawComments?.length > 0 ? rawComments?.map((x, index) => <TextM key={index} style={{
                        marginLeft: 10,
                        marginTop: 5
                    }} wrap>- {x}</TextM>
                ) : <TextM fontWeight={"LightItalic"} style={{textAlign: "left", marginLeft: 10}}>Aucune règle
                    sélectionnée</TextM>}
            </View>

            <View style={{width: "100%", alignItems: "flex-start", marginTop: 20}}>
                <TextM fontSize={18}
                       wrap
                       fontWeight="Bold"
                       color={theme.colors.grey}
                       style={{textAlign: "left", marginLeft: 10, marginBottom: 4}}>
                    CONCLUSION
                </TextM>
                <SelectBox title="Conclusion"
                           hideTitle
                           style={{
                               width: dimensions?.width - 30
                               // marginTop: -30
                           }}
                           options={conclusionComments?.map(x => {
                               return {
                                   label: x?.comment,
                                   value: x?.id
                               }
                           })}
                           valueMode
                           withClipBoard
                           value={selectedConclusionCommentId}
                           onSingleSelect={(value) => {
                               setSelectedConclusionCommentId(value.value);
                           }}
                           titleRightButtonIcon={"plus"}
                           onTitleRightButtonPress={() => {
                               navigation.push("new-scoring-comment", {
                                   type: "conclusion",
                                   description: "Conclusion",
                                   scoringRuleId: null,
                                   currentActivityType: activity?.type,
                                   currentSchoolSubjectCategory: schoolSubject?.category,
                               })
                           }}
                />

            </View>

        </View>

        <View style={{
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 20,
            borderTopWidth: 1,
            // borderBottomWidth: 1,
            borderColor: theme.colors.lightMore
            // backgroundColor: theme.colors.white
        }}>
            <TextM fontSize={18}
                   wrap
                   fontWeight="Bold"
                   color={theme.colors.primary}
                   style={{textAlign: "center", marginTop: 20, marginBottom: 20}}>
                APPRÉCIATION DE SAM
            </TextM>
            <SamAppreciationGenerator variant={!score ? "main" : samVariantForScore(score)}
                                      fieldName={"comment"}
                                      control={control}
                                      errors={errors}
                                      activity={activity}
                                      dimensions={dimensions}
                                      rawAppreciation={rawAppreciation}
                                      width={commentWidth}/>


            <FilledButtonM color={theme.colors.success}
                           style={{
                               marginTop: 30,
                               width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2,
                               marginBottom: Platform.OS !== 'web' ? 280 : null
                           }}
                           disabled={!formDirty}
                           loading={props.reviewActivityRequest}
                           onPress={handleSubmit(onSubmit)}
                           label="Valider"/>
        </View>
    </>
}

const mapStateToProps = state => {
    return {
        generateSamAppreciationRequest: state.api.generateSamAppreciation?.request,
        getActivitiesRequest: state.api.getActivities?.request,
        getActivitiesSuccess: state.api.getActivities?.success,
        getActivitiesError: state.api.getActivities?.error,

        reviewActivityRequest: state.api.reviewActivity?.request,
        reviewActivitySuccess: state.api.reviewActivity?.success,
        reviewActivityError: state.api.reviewActivity?.error,

        scoringComments: state.data.reviewer?.object?.scoringComments,
        scoringCriteria: state.data.staticData?.scoring_criteria,
        reviewer: state.data.reviewer?.object,
        activityTemplates: state.data.staticData?.activity_templates,
        schoolSubjects: state.data.staticData?.school_subjects,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidateActivity)