import {View} from "react-native";
import {Feather, Petal, SkillSetTitle, Xp} from "../icons/IconsM";
import {Text} from "react-native-paper";
import {theme} from "../../theme/theme";
import TextM from "../text/TextM";
import React from "react";

export function XPCounter(props) {

    const {count, totalCount, style, level} = props

    return <View style={{
        // flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: 48,
        ...style
    }}>
        <Xp size={30}/>
        <TextM
            color={theme.colors.white}
            fontWeight="ExtraBold"
            fontSize={16}
            style={{marginBottom: 2}}
        >
            {count}
        </TextM>
        <TextM
            color={theme.colors.white}
            fontWeight="SemiBold"
            fontSize={12}
        >
            {" /" + totalCount}
        </TextM>
        <TextM
            color={theme.colors.accent}
            fontWeight="SemiBold"
            fontSize={10}
            style={{
                position: 'absolute',
                top: 0,
                left: 20
            }}
        >
            NIVEAU {level}
        </TextM>
    </View>;
}

export function FeatherCounter(props) {
    const {count, style} = props

    return <View style={{
        // flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        ...style
    }}>
        <Feather size={26}/>
        <Text
            style={{
                color: theme.colors.accentDark,
                fontFamily: "Montserrat-ExtraBold",
                alignContent: "center",
                fontSize: 18
            }}
        >
            {count}
        </Text>
    </View>;
}

export function PetalCounter(props) {
    const {count, small = false, style} = props

    return <View style={{
        // flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        // height: "100%",
        ...style
    }}>
        <Petal size={!small ? 25 : 21}/>
        <Text
            style={{
                color: theme.colors.petalNew,
                fontFamily: "Montserrat-ExtraBold",
                alignContent: "center",
                fontSize: !small ? 16 : 14,
                marginLeft: 4
            }}
        >
            {count}
        </Text>
    </View>;
}

export function XPCount(props) {
    const {count, style, small = false, right = false} = props

    return <>{count !== undefined && <View style={{
        // flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        // height: "100%",
        ...style
    }}>
        {!right && <Xp size={small ? 14 : 22}/>}
        <Text
            style={{
                color: theme.colors.accent,
                fontFamily: small ? "Montserrat-Bold" : "Montserrat-ExtraBold",
                alignContent: "center",
                fontSize: small ? 12 : 16,
                marginLeft: right ? 0 : 4,
                marginRight: right ? 4 : 0
            }}
        >
            {count}
        </Text>
        {right && <Xp size={small ? 14 : 22}/>}
    </View>}</>
}

export function SkillSetTitleFull(props) {
    const {title, skillSetId, icon, style, large = false, small = false, right = false} = props

    // console.log(icon)

    const color = props.skillSetId === 1
        ? theme.colors.zoe
        : props.skillSetId === 3
            ? theme.colors.sam
            : props.skillSetId === 2
                ? theme.colors.abou
                : theme.colors.tina

    return <View style={{
        // flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        // height: "100%",
        ...style
    }}>
        {!right && <SkillSetTitle size={small ? 20 : large ? 32 :  28} icon={icon}/>}
        <Text
            style={{
                color: color,
                fontFamily: small ? "Montserrat-Bold" : "Montserrat-ExtraBold",
                alignContent: "center",
                fontSize: small ? 12 : large ? 16 :  14,
                // marginLeft: 2,
                marginRight: right ? 0 : 4,
                marginLeft: right ? 4 : 0,
                // width: 100,
                flexWrap: "wrap"
            }}
        >
            {title}
        </Text>
        {right && <SkillSetTitle size={small ? 20 : 28} icon={icon}/>}

    </View>;
}