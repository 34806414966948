import React, {useContext, useEffect, useRef, useState} from 'react';
import {BackHandler, Platform, ScrollView, StatusBar, TouchableOpacity, View} from "react-native";
import ChallengeLaunchCongrats from "../../student/organize/challenges/ChallengeLaunchCongrats";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {DimensionsContext, IS_WEB} from "../utils/mobileUtils";
import {theme} from "../theme/theme";
import FilledButtonM from "../components/button/FilledButtonM";
import ActivityFinalizedCongrats from "../../student/activity/execute/ActivityFinalizedCongrats";
import NewAchievementCongrats from "../../student/progress/successes/NewAchievementCongrats";
import ChallengeProgressCongrats from "../../student/organize/challenges/ChallengeProgressCongrats";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import ShopProductPurchasedCongrats from "../../student/shop/ShopProductPurchasedCongrats";
import ActivityReviewedCongrats from "../../student/activity/read/ActivityReviewedCongrats";
import ProfileCompletedCongrats from "../../student/account/profile/ProfileCompletedCongrats";
import OfferChosenCongrats from "../../student/tutoring/OfferChosenCongrats";
import GenericCongrats from "./GenericCongrats";
import ChampionBubble from "../../student/champions/ChampionBubble";
import championsConstants from "@data/constants/championsConstants";
import {AbilityContext} from "@data/utility/Can";
import moment from "moment";
import {samVariantForActivity} from "../../student/champions/ChampionImageM";
import LeagueCongrats from "../../student/league/LeagueCongrats";
import MyProgressCongrats from "../../student/progress/my progress/MyProgressCongrats";
import NewTitleCongrats from "../../student/progress/my progress/NewTitleCongrats";
import PetalsGainedCongrats from "../../student/progress/my progress/PetalsGainedCongrats";
import LottieView from "lottie-react-native";
import ShareStatsCongrats from "../../student/social/ShareStatsCongrats";
import NewTodoCongrats from "../../student/activity/todo/NewTodoCongrats";
import QuizzInitializedCongrats from "../../quiz/QuizzInitializedCongrats";
import QuizzConfirmedCongrats from "../../quiz/QuizzConfirmedCongrats";
import TextM from "@common/components/text/TextM";
import linkedin from "../../../assets/social/linkedin.png";
import facebook from "../../../assets/social/facebook.png";
import instagram from "../../../assets/social/instagram.png";
import {IconImageM} from "@common/components/icons/IconsM";
import * as WebBrowser from "expo-web-browser";
import ApplicationStepCongrats from "../../tutor/application/ApplicationStepCongrats";
import ApplicationFinishedCongrats from "../../tutor/application/motivation/ApplicationFinishedCongrats";
import DownloadAppCongrats from "../../student/tutoring/DownloadAppCongrats";
import * as amplitude from "@amplitude/analytics-react-native";
import {getPrivilegedRole} from "@data/utility/ability";
import ChapterProgressCongrats from "@common/chapters/ChapterProgressCongrats";

export const congratsTypes = {
    CHALLENGE_LAUNCHED: "challenge_launched",
    SHOP_PRODUCT_PURCHASED: "SHOP_PRODUCT_PURCHASED",
    ACTIVITY_REVIEWED: "ACTIVITY_REVIEWED",
    ACTIVITY_REJECTED_LOSSES: "ACTIVITY_REJECTED_LOSSES",
    ACTIVITY_FINALIZED: "activity_finalized",
    NEW_ACHIEVEMENT: "new_achievement",
    CHALLENGE_PROGRESS: "challenge_progress",
    PROFILE_COMPLETED: "PROFILE_COMPLETED",
    OFFER_CHOSEN: "OFFER_CHOSEN",
    SCORING: "scoring",
    GENERIC: "GENERIC",
    LEAGUE_PROGRESS: "LEAGUE_PROGRESS",
    MY_PROGRESS: "MY_PROGRESS",
    NEW_TITLE_CONGRATS: "NEW_TITLE_CONGRATS",
    PETALS_GAINED: "PETALS_GAINED",
    SHARE_STATS: "SHARE_STATS",
    NEW_TODO_CONGRATS: "NEW_TODO_CONGRATS",
    QUIZZ_INITIALIZED: "QUIZZ_INITIALIZED",
    QUIZZ_CONFIRMED: "QUIZZ_CONFIRMED",
    APPLICATION_STEP_CONGRATS: "PERSONAL_INFO_CONGRATS",
    APPLICATION_FINISHED_CONGRATS: "APPLICATION_FINISHED_CONGRATS",
    DOWNLOAD_APP: "DOWNLOAD_APP",
    CHAPTER_PROGRESS_CONGRATS: "CHAPTER_PROGRESS_CONGRATS",
}

export const CongratsScreen = (props) => {

    // ** Component props
    const {navigation, route} = props
    const {congrats, nextScreen = null, nextScreenParams = {}} = route.params
    const insets = useSafeAreaInsets();
    const ability = useContext(AbilityContext)
    const [show, setShow] = useState(false)

    const supportLinks = [
        {
            icon: instagram,
            label: 'Instagram',
            url: 'https://www.instagram.com/masteur_com/'
        },
        {
            icon: linkedin,
            label: 'Linkedin',
            url: 'https://www.linkedin.com/company/masteur/'
        },
        {
            icon: facebook,
            label: 'Facebook',
            url: 'https://www.facebook.com/Masteur.fr'
        }
    ]

    //console.log(congrats[0].congratsData.title)

    // ** Handle click on hardware back button
    useEffect(() => {


        if (!congrats?.[0]?.congratsType) {
            navigation.goBack()
        } else {
            amplitude.track("congrats." + congrats?.[0]?.congratsType)
        }

        setTimeout(() => {
            setShow(true)
        }, 1000)

        setTimeout(() => {
            setShow(false)
        }, 5000)

        BackHandler.addEventListener('hardwareBackPress', backPressed);
        return () => {
            BackHandler.removeEventListener('hardwareBackPress', backPressed);
        }
    }, [])
    const backPressed = () => true

    const animation = useRef(null);

    const renderCongratsContent = (congratsType, congratsData) => {
        switch (congratsType) {
            case congratsTypes.CHALLENGE_LAUNCHED:
                return <ChallengeLaunchCongrats challenge={congratsData?.new_challenge}
                                                activities={congratsData?.new_activities}/>
            case congratsTypes.ACTIVITY_FINALIZED:
                return <ActivityFinalizedCongrats activity={congratsData?.activity}
                                                  scoringRequested={congratsData?.scoringRequested}
                />

            case congratsTypes.NEW_ACHIEVEMENT:
                return <NewAchievementCongrats achievement={congratsData}/>

            case congratsTypes.CHALLENGE_PROGRESS:
                return <ChallengeProgressCongrats challenge={congratsData?.challenge}
                                                  activity={congratsData?.activity}
                />
            case congratsTypes.SHOP_PRODUCT_PURCHASED:
                return <ShopProductPurchasedCongrats shopProduct={congratsData?.shopProduct}
                />
            case congratsTypes.ACTIVITY_REVIEWED:
                return <ActivityReviewedCongrats activity={congratsData?.activity}
                />

            case congratsTypes.PROFILE_COMPLETED:
                return <ProfileCompletedCongrats
                />

            case congratsTypes.OFFER_CHOSEN:
                return <OfferChosenCongrats onPress={onPress}
                />

            case congratsTypes.GENERIC:
                return <GenericCongrats congratsText={congratsData?.text}
                                        congratsTitle={congratsData?.title}
                />
            case congratsTypes.LEAGUE_PROGRESS:
                return <LeagueCongrats congratsText={congratsData?.text}
                                       congratsTitle={congratsData?.title}
                                       rankings={congratsData?.rankings}
                />
            case congratsTypes.MY_PROGRESS:
                return <MyProgressCongrats userSkillSets={congratsData?.userSkillSets}
                />
            case congratsTypes.NEW_TITLE_CONGRATS:
                return <NewTitleCongrats userSkillSet={congratsData?.userSkillSet}
                />

            case congratsTypes.PETALS_GAINED:
                return <PetalsGainedCongrats petalsGainedCount={congratsData?.petalsGainedCount}
                />
            case congratsTypes.SHARE_STATS:
                return <ShareStatsCongrats shopProduct={congratsData?.shopProduct}
                />
            case congratsTypes.NEW_TODO_CONGRATS:
                return <NewTodoCongrats todo={congratsData?.todo} studentId={congratsData?.studentId}
                />

            case congratsTypes.QUIZZ_INITIALIZED:
                return <QuizzInitializedCongrats congratsTitle={congratsData?.title}
                                                 score={congratsData?.score}
                                                 congratsText={congratsData?.text}
                />

            case congratsTypes.QUIZZ_CONFIRMED:
                return <QuizzConfirmedCongrats congratsTitle={congratsData?.title}
                                               congratsText={congratsData?.text}
                />

            case congratsTypes.APPLICATION_STEP_CONGRATS:
                return <ApplicationStepCongrats congratsTitle={congratsData?.title}
                                                congratsText={congratsData?.text}
                />
            case congratsTypes.APPLICATION_FINISHED_CONGRATS:
                return <ApplicationFinishedCongrats congratsTitle={congratsData?.title}
                                                    congratsText={congratsData?.text}
                />
            case congratsTypes.CHAPTER_PROGRESS_CONGRATS:
                return <ChapterProgressCongrats congratsTitle={congratsData?.title}
                                                congratsText={congratsData?.text}
                                                activity={congratsData?.activity}
                />
            case congratsTypes.DOWNLOAD_APP:
                return <DownloadAppCongrats/>

            default:
                return <></>
        }
    }

    const onPress = () => {

        // remove first element
        let congratsCopy = congrats?.slice()
        congratsCopy.shift()

        // navigate to next element or go back
        if (congratsCopy.length > 0)
            navigation.replace("congrats", {
                congrats: congratsCopy,
                nextScreen,
                nextScreenParams
            })
        else {
            if (nextScreen)
                navigation.replace(nextScreen, nextScreenParams)
            else if (navigation.canGoBack()) {
                //console.log("go back from congrats")
                navigation.goBack()
            } else {
                // console.log("replace from congrats")
                // to be able to use a generic congrats in auth screens
                if (props.token)
                    navigation.replace("menu")
                else navigation.replace("login")
            }

        }
    }

    const championsBubbleRef = useRef()

    const champion = () => {
        if (congrats.length === 0)
            return null

        const activity = congrats[0]?.congratsData?.activity

        switch (congrats[0]?.congratsType) {
            case congratsTypes.QUIZZ_INITIALIZED:
                const index = congrats[0]?.congratsData?.score >= 80 ? 0 : congrats[0]?.congratsData?.score >= 50 ? 1 : 2
                return {
                    name: "sam",
                    variant: [championsConstants.sam_quizz_initialized_congrats.variant[index]],
                    texts: [championsConstants.sam_quizz_initialized_congrats.texts[index]]
                    // texts: congrats[0]?.congratsData?.score >= 80 ? [championsConstants.sam_quizz_initialized_congrats.texts[0]] : congrats[0]?.congratsData?.score >= 50 ? [championsConstants.sam_quizz_initialized_congrats.texts[1]] : [championsConstants.sam_quizz_initialized_congrats.texts[2]]
                }
            case congratsTypes.QUIZZ_CONFIRMED:
                return {
                    name: "tina",
                    variant: championsConstants.tina_quizz_confirmed_congrats.variant,
                    texts: championsConstants.tina_quizz_confirmed_congrats.texts
                }
            case congratsTypes.CHALLENGE_LAUNCHED:
                if (props.user?.challenges?.length === 1 && props.user?.xp === 0)
                    return {
                        name: "zoe",
                        variant: championsConstants.zoe_challenge_launch_congrats_first.variant,
                        texts: championsConstants.zoe_challenge_launch_congrats_first.texts
                    }
                else
                    return {
                        name: "zoe",
                        variant: championsConstants.zoe_challenge_launch_congrats_any.variant,
                        texts: championsConstants.zoe_challenge_launch_congrats_any.texts
                    }
            case congratsTypes.ACTIVITY_FINALIZED:
                const loggedActions = activity?.activity_logs?.map(x => x?.action)
                const stepsTypes = activity?.steps?.map(x => x?.type)
                const photoStepSkipped = stepsTypes?.includes("photo") && !loggedActions?.includes("step_media_sent")
                const isUserSubscribed = getPrivilegedRole(props.user) !== "basic"

                if (photoStepSkipped && isUserSubscribed && IS_WEB)
                    return {
                        name: "sam",
                        variant: championsConstants.sam_activity_finalized_congrats_photo_skipped_subscribed.variant,
                        texts: championsConstants.sam_activity_finalized_congrats_photo_skipped_subscribed.texts
                    }
                else if (photoStepSkipped && !isUserSubscribed && IS_WEB)
                    return {
                        name: "sam",
                        variant: championsConstants.sam_activity_finalized_congrats_photo_skipped_not_subscribed.variant,
                        texts: championsConstants.sam_activity_finalized_congrats_photo_skipped_not_subscribed.texts
                    }
                else {
                    if (ability.can("read", "request_activity_review")) {
                        return {
                            name: "sam",
                            variant: championsConstants.sam_activity_finalized_congrats_pending_scoring.variant,
                            texts: championsConstants.sam_activity_finalized_congrats_pending_scoring.texts
                        }
                    } else {
                        return {
                            name: "sam",
                            variant: championsConstants.sam_activity_finalized_congrats_no_scoring.variant,
                            texts: championsConstants.sam_activity_finalized_congrats_no_scoring.texts
                        }
                    }
                }

            case congratsTypes.CHAPTER_PROGRESS_CONGRATS:
                const chapterActivity = congrats[0]?.congratsData?.activity
                if (chapterActivity?.student_chapter?.xp_gained !== chapterActivity?.student_chapter?.xp_total) {
                    return {
                        name: "zoe",
                        variant: championsConstants.zoe_chapter_progress_congrats.variant,
                        texts: championsConstants.zoe_chapter_progress_congrats.texts
                    }
                } else if (chapterActivity?.student_chapter?.xp_gained < props.xpToValidateChapter) {
                    return {
                        name: "zoe",
                        variant: championsConstants.zoe_chapter_progress_congrats_todo_ended.variant,
                        texts: championsConstants.zoe_chapter_progress_congrats_todo_ended.texts
                    }
                } else {
                    return {
                        name: "zoe",
                        variant: championsConstants.zoe_chapter_progress_congrats_finalized.variant,
                        texts: championsConstants.zoe_chapter_progress_congrats_finalized.texts
                    }
                }
            case congratsTypes.ACTIVITY_REVIEWED:
                return {
                    name: "sam",
                    variant: samVariantForActivity(activity),
                    texts: [activity?.review_comment]
                }
            case congratsTypes.SHOP_PRODUCT_PURCHASED:
                const shopProduct = congrats[0]?.congratsData?.shopProduct
                return {
                    name: "tina",
                    variant: "main",
                    texts: [shopProduct?.congrats_message]
                }

            case congratsTypes.APPLICATION_STEP_CONGRATS:
                const champion = congrats[0]?.congratsData?.champion
                const championText = congrats[0]?.congratsData?.text
                return {
                    name: champion,
                    variant: "happy",
                    texts: [championText]
                }

            case congratsTypes.NEW_ACHIEVEMENT:
            case congratsTypes.PROFILE_COMPLETED:
            case congratsTypes.OFFER_CHOSEN:
            case congratsTypes.GENERIC:
            default:
                return null

        }
    }

    function renderCongratsChampion() {

        return !champion() ? <></> : <ChampionBubble name={champion().name}
                                                     variant={champion().variant}
                                                     championPosition="left"
                                                     texts={champion().texts}
                                                     onNextPress={onPress}
                                                     ref={championsBubbleRef}
                                                     style={{marginTop: 30}}
        />
    }

    const dimensions = useContext(DimensionsContext)

    return (
        <View style={{
            flex: 1,
            backgroundColor: '#fff',
            width: dimensions?.width,
            paddingTop: insets.top,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>
            {(show && (
                congrats[0]?.congratsType === congratsTypes.NEW_ACHIEVEMENT
                || congrats[0]?.congratsType === congratsTypes.NEW_TITLE_CONGRATS
                || congrats[0]?.congratsType === congratsTypes.QUIZZ_CONFIRMED
            )) && <LottieView autoPlay
                              speed={1}
                              loop={false}
                              pointerEvents="none"
                              ref={animation}
                              style={{
                                  width: Platform.OS !== 'web' ? dimensions?.width * 1.4 : dimensions?.width,
                                  alignSelf: "center",
                                  // height: WINDOW_HEIGHT,
                                  position: "absolute",
                                  top: 0
                                  // backgroundColor: '#eee',
                              }}
                // Find more Lottie files at https://lottiefiles.com/featured
                              source={require('../../../assets/animations/62717-confetti.json')}
            />
            }
            <ScrollView contentContainerStyle={{
                // flex: 1
                // height: WINDOW_HEIGHT,
            }}>

                {congrats.length > 0 && <>
                    {renderCongratsContent(congrats[0]?.congratsType, congrats[0]?.congratsData)}
                    {renderCongratsChampion(congrats[0]?.congratsType)}
                    {congrats[0]?.congratsType === congratsTypes.QUIZZ_CONFIRMED &&
                        <>
                            {/*<TextM fontSize={18}*/}
                            {/*       wrap*/}
                            {/*       fontWeight="ExtraBold"*/}
                            {/*       style={{*/}
                            {/*           textAlign: "center",*/}
                            {/*           marginTop: 20,*/}
                            {/*           marginHorizontal: 30*/}
                            {/*       }}*/}
                            {/*       color={theme.colors.primary}>*/}
                            {/*    Gardons contact ! Suivez-nous sur :*/}
                            {/*</TextM>*/}
                            <View style={{
                                marginTop: 20,
                                alignItems: "center",
                                alignSelf: "center",
                                flexDirection: "row"
                            }}>
                                {
                                    supportLinks?.map(link => {
                                        return <TouchableOpacity style={{
                                            width: (dimensions?.width / supportLinks?.length) - 10,
                                            marginHorizontal: 5
                                        }}
                                                                 onPress={() => {
                                                                     WebBrowser.openBrowserAsync(link?.url).then()
                                                                 }}>

                                            <View style={{
                                                // flexDirection: 'row',
                                                alignItems: 'center'
                                            }}>

                                                <IconImageM source={link?.icon} width={60} style={{marginBottom: 6}}/>
                                                <TextM style={{fontsize: 30, textAlign: "center"}}
                                                       fontWeight="Bold"
                                                       wrap>{link?.label}</TextM>
                                            </View>
                                        </TouchableOpacity>
                                    })
                                }
                            </View>
                        </>
                    }
                    {/*{congrats[0]?.congratsType === congratsTypes.ACTIVITY_FINALIZED && getPrivileges(props.user) === "Basic" &&*/}
                    {/*    <TextButtonM*/}
                    {/*        // mode="text"*/}
                    {/*        style={{*/}
                    {/*            marginTop: 2,*/}
                    {/*            alignSelf: "center"*/}
                    {/*        }}*/}
                    {/*        fontWeight="Bold"*/}
                    {/*        onPress={() => {*/}
                    {/*            navigation.push("offer-essential")*/}
                    {/*        }}*/}
                    {/*        label="Voir les offres"/>}*/}
                </>}


                <View style={{height: 100}}/>

            </ScrollView>

            {!congrats[0]?.hideButton && <View style={{
                backgroundColor: theme.colors.whiteTransparentMore,
                paddingVertical: 10,
                width: dimensions?.width,
                position: "absolute",
                alignSelf: "center",
                bottom: 0
            }}>
                <FilledButtonM color={theme.colors.primary}
                               onPress={!champion() ? onPress : () => {
                                   championsBubbleRef.current?.onChampionBubbleNext()
                               }}
                               style={{
                                   width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,
                                   alignSelf: "center",
                                   marginTop: 5
                               }}
                               label="CONTINUER"/>
            </View>}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>

        </View>
    )
}

const mapStateToProps = state => {
    return {
        postChallengeRequest: state.api.postChallengeAsStudent?.request,
        postChallengeSuccess: state.api.postChallengeAsStudent?.success,
        postChallengeError: state.api.postChallengeAsStudent?.error,
        postChallengeSuccessData: state.api.postChallengeAsStudent?.data,
        token: state.data.session?.object?.token,
        user: state.data.currentUser?.object,
        isLive: state.liveMode.isLiveModeEnabled,
        xpToValidateChapter: state.data.staticData?.xp_to_validate_chapter,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(CongratsScreen)
