import axios from "axios"


function getStaticData() {
    return axios.get(`/api/v1/static-data`)

}


export const staticDataService = {

    getStaticData

}