import React, {useEffect, useRef, useState} from 'react';
import * as amplitude from "@amplitude/analytics-react-native";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {theme} from "@common/theme/theme";
import {IS_WEB, isMobile} from "@common/utils/mobileUtils";
import {Linking} from "react-native";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {subscriptionService} from "@data/services/subscriptionService";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import ReminderDialogComponent from "@common/messenger/ReminderDialogComponent";

const RegisterTrialPopup = (props) => {

    if (!IS_WEB) return <></>

    const {referralCode, lastInputTime, resolver} = props
    const request = useRef(null);
    useEffect(() => {
        request.current = props.callMeBackRequest
    }, [props.callMeBackRequest]);

    const dialogOption = (type) => {
        amplitude.track(`dialog.viewed.redirect.${type}`)
        if (type === "holiday-course") {
            DialogM.show({
                champion: "tina",
                variant: "main",
                text1: "Pas prêt pour un accompagnement régulier ?",
                text2: "Testez notre stage de vacances en profitant d'une offre exceptionnelle à -60% (valable jusqu’au 15 décembre)",
                buttonText1: "Découvrir le stage de vacances",
                buttonColor1: theme.colors.primary,
                onPress: () => {
                    const url = `https://masteur.com/stage-vacances?ref=${referralCode ?? "ESSAISTAGE60"}}`
                    if (IS_WEB)
                        window.open(url, '_blank', 'noopener,noreferrer')
                    else
                        Linking.openURL(url).catch(err => console.error("Couldn't load page", err))
                },
                buttonText2: 'Retour',
                onPressBack: () => {
                }
            })
        } else if (type === "reminder")
            DialogM.show({
                champion: "tina",
                variant: "main",
                text1: "Vous ne souhaitez pas démarrer un essai tout de suite ?",
                component: <ReminderDialogComponent resolver={resolver}/>,
                hideButton1: true,
                buttonText2: 'Retour',
                onPressBack: () => {
                }
            })
    }


    const [timeoutExecuted, setTimeoutExecuted] = useState(false);

    //"reminder" or "holiday-course"
    const currentDialogOption = "reminder"

    useEffect(() => {
        let timeoutId
        if (IS_WEB && isMobile()) {
            if (!timeoutExecuted) {
                timeoutId = setTimeout(() => {
                    const currentTime = Date.now();
                    const elapsedTime = currentTime - lastInputTime;

                    if (elapsedTime >= 20000) {
                        dialogOption(currentDialogOption)
                        setTimeoutExecuted(true);
                    }
                }, 20000);
            }
            return () => clearTimeout(timeoutId);
        }

    }, [lastInputTime]);


    useEffect(() => {
        if (IS_WEB && !isMobile()) {
            const handleMouseOut = (event) => {
                // Check if the mouse is outside the window
                if (event.clientY < 0) {
                    dialogOption(currentDialogOption)
                    window.removeEventListener('mouseout', handleMouseOut);
                }
            };

            window.addEventListener('mouseout', handleMouseOut);

            return () => {
                window.removeEventListener('mouseout', handleMouseOut);
            };
        }
    }, []);

    useApiStatus(
        subscriptionService.callMeBack, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            DialogM.show({
                champion: "tina",
                variant: "main",
                hideButton1: true,
                text1: "Bien reçu !",
                text2: data?.message,
                buttonText2: 'Ok',
            })
        } // success callback
    )

    return <></>
}
const mapStateToProps = state => {
    return {
        callMeBackRequest: state.api.callMeBack?.request,
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiRequestWithPrerequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterTrialPopup)
