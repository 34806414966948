import React from 'react';
import {FlatList} from 'react-native';
import ChapterItemComponent from "@common/chapters/ChapterItemComponent";

const ChaptersList = ({
                          todos = [],
                          schoolSubjects = [],
                          navigation,
                          width,
                          chaptersListRef,
                          listHeaderComponent,
                          listEmptyComponent,
                          ListFooterComponent,
                          style
                      }) => {

    const chaptersSet = new Set();

    const chaptersList = todos?.reduce((acc, todo) => {
        const {student_chapter} = todo || {};
        const {chapter} = student_chapter || {};
        const chapterId = chapter?.id;

        if (chapterId && !chaptersSet.has(chapterId)) {
            chaptersSet.add(chapterId);
            acc.push({
                chapter: {
                    ...student_chapter,
                    ...chapter
                }
            });
        }

        return acc;
    }, []);

    const todosWithoutChapter = todos?.filter((todo) => {
        const {student_chapter} = todo || {};
        const {chapter} = student_chapter || {};
        const chapterId = chapter?.id;

        return !chapterId;
    });

    const groupedChapters = todosWithoutChapter.reduce((acc, c) => {
        const schoolSubject = schoolSubjects?.find(
            (s) => s.id === c.school_subject_id
        );

        if (!acc[c.school_subject_id]) {
            acc[c.school_subject_id] = {
                chapter: {
                    name: "Autres activités",
                    school_subject_id: schoolSubject?.id,
                    xp_gained: 0,
                    xp_total: 0,
                    xp_gained_steps: [],
                    xp_remaining_steps: []
                },
                validated_activities_count: 0,
                todo_activities_count: 0
            };
        }

        acc[c.school_subject_id].chapter.xp_gained += c?.xp_gained || 0;
        acc[c.school_subject_id].chapter.xp_total += c?.xp_value || 0;

        acc[c.school_subject_id].chapter.xp_gained_steps.push(c?.xp_gained || 0);

        return acc;
    }, {});

    const otherChapters = Object.values(groupedChapters);
    const chapters = [...chaptersList, ...otherChapters];

    return (
        <FlatList
            data={chapters}
            ref={chaptersListRef}
            renderItem={({item}) => {
                const todosList = todos.filter((todo) => {
                    const chapterId = todo?.student_chapter?.chapter?.id;
                    const schoolSubject = schoolSubjects?.find(
                        (s) => s.id === todo.school_subject_id
                    );

                    return (
                        (item?.chapter?.id === chapterId && chapterId) ||
                        (schoolSubject?.id === item?.chapter?.school_subject_id &&
                            item?.chapter?.name === "Autres activités" &&
                            !chapterId)
                    );
                });

                return (
                    <ChapterItemComponent
                        onPress={() => {
                            navigation.push("todos-by-chapter", {
                                todosList
                            });
                        }}
                        chapter={item?.chapter}
                        style={{marginBottom: 0, ...style}}
                        width={width}
                    />
                );
            }}
            ListHeaderComponent={listHeaderComponent}
            ListEmptyComponent={listEmptyComponent}
            ListFooterComponent={ListFooterComponent}
            containerStyle={{
                flex: 1
            }}
            contentContainerStyle={{
                alignItems: "center"
            }}
        />
    );
};

export default ChaptersList;
