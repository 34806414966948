import React, {useContext, useEffect, useRef, useState} from 'react';
import {Keyboard, Platform, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {findItemInListWithId} from "@data/utility/dataUtils";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import yup from "@data/utility/validation";
import {activitiesService} from "@data/services/activitiesService";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {offlineModeClear} from "@data/redux/actions/offlineMode";
import {StatusBar} from "expo-status-bar";
import {theme} from "@common/theme/theme";
import {clearActivityData, saveActivityData} from "@data/redux/actions/formData";
import {audioRecordingStatus, setOngoingActivity} from "@data/redux/actions/activity";
import {hasRole} from "@data/utility/ability";
import {incrementActivitiesCount} from "@data/utility/asyncStorageUtils";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {PlaySoundM} from "@common/components/playSound/PlaySoundM";
import {fetchResourceFromURI, useRequestsBatch} from "@common/hooks/useRequestsBatch";
import TimerActivityStep from "./TimerActivityStep";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {useCamera} from "@common/hooks/useCamera";
import {formIds} from "@data/constants/formConstants";
import {clearLastPhoto, clearPhotos} from "@data/redux/actions/camera";
import FilledButtonM from "@common/components/button/FilledButtonM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {BadgeM} from "@common/components/badge/BadgeM";
import MyWorkModal from "../one-step/MyWorkModal";
import moment from "moment";
import {ActivityIndicator, IconButton} from "react-native-paper";
import TextM from "@common/components/text/TextM";
import {dataURLtoFile} from "@data/utility/Utils";
import {cleanMediaUrl} from "@data/constants/apiConstants";
import {useApiStatus} from "@common/hooks/useApiStatus";

export const ActivityScreen = (props) => {

    // ** Component props
    const {navigation, route} = props
    const {
        activityTemplateId,
        challengeId,
        activityId,
        againActivityId,
        schoolSubjectId,
        savedActivityLogs = [],
        savedWorkAttachmentLogs = []
    } = route.params

    const dimensions = useContext(DimensionsContext)
    // ** Component variables
    const activityTemplate = findItemInListWithId(activityTemplateId, props.activityTemplates)
    const activity = findItemInListWithId(activityId, props.user?.activities?.concat(props.user?.todo_activities))

    // console.log("activityId", activityId)
    // console.log("props.user?.activities", )
    // console.log("activity", activity)

    const formData = activityTemplate?.steps
    const stepDescriptions = [formData[0]?.description_init, formData[1]?.description_init]
    const activityType = formData?.find(step => step.type === "photo") ? "photo" : "audio"

    // ** state
    const [genuineActivityId, setGenuineActivityId] = useState(activityId)    // activity id sent back by server after initActivity
    const [submittedMessage, setSubmittedMessage] = useState("On réceptionne ton activité.")


    const defaultValues = {
        init: {
            activity_template_id: activityTemplateId,
            challenge_id: challengeId,
            school_subject_id: schoolSubjectId,
            activity_id: activityId ?? null,
            again_activity_id: againActivityId ?? null
        },
        activity_logs: [],
        work_attachment_logs: []
    }
    const formSchema = {
        init: yup.object().required(),
        activity_logs: yup.array().required(),
        work_attachment_logs: yup.array().nullable()
    }

    const resolver = yupResolver(yup.object().shape(formSchema));
    const {control, handleSubmit, formState: {errors, dirtyFields}, resetField, getValues, setValue} = useForm({
        resolver,
        defaultValues
    })
    useEffect(() => {
        props.setOngoingActivity(true)
        return () => {
            props.setOngoingActivity(false)
        }
    }, []);
    const [recordingURI, setRecordingURI] = useState(activityType === "audio" ? cleanMediaUrl(activity?.activity_logs?.find(log => log?.action === "step_media_sent")?.work_attachment) : null)
    const [currentAudio, setCurrentAudio] = useState(null)
    // console.log(getValues()?.activity_logs)
    const mediaLogs = activity?.activity_logs?.filter(log => log?.action === "step_media_sent")?.map(log => {
        return {
            uri: log?.work_attachment,
            width: 200,
            height: 200,
            id: log?.id,
        }
    })

    const [images, setImages] = useState(mediaLogs ?? []);
    useEffect(() => {
        if (activity?.activity_logs)
            if (activityType === "photo")
                setImages(activity?.activity_logs?.filter(log => log?.action === "step_media_sent" && !!log?.work_attachment)?.map(log => {
                    return {
                        uri: log?.work_attachment,
                        width: 200,
                        height: 200,
                        id: log?.id,
                    }
                }))
            else {
                setCurrentAudio({
                    uri: activity?.activity_logs?.find(log => log?.action === "step_media_sent")?.work_attachment,
                    id: activity?.activity_logs?.find(log => log?.action === "step_media_sent")?.id,
                })
            }
    }, [props.user.todo_activities]);

    //const filesToUpload = getValues()?.work_attachment_logs?.map(log => log?.work_attachment)
    const filesToUpload = images.length > 0 ? images?.map(img => img?.uri) : !!getValues()?.work_attachment_logs && getValues()?.work_attachment_logs?.map(log => log?.work_attachment)

    // const [soundPlaying, setSoundPlaying] = React.useState(false);
    const playSound = () => {

        PlaySoundM.play(require('../../../../assets/sounds/next-step.wav'))
    }

    const [globalProgress, submitted, startRequests] = useRequestsBatch(
        [],
        [],
        "ACTIVITY_FORM",
        {
            service: activitiesService.postActivityLogs,
            data: {},
            tmpFilesKey: "activity_logs",
            tmpFilesFormatter: (attachmentTmp, index) => {
                return {"action": "step_started", "step": 1, "created_at": new moment().format('Y-MM-DD HH:mm:ss')}
            },
            // additionalData: getValues()?.activity_logs,
            additionalData: [
                {"action": "step_finished", "step": 1, "created_at": new moment().format('Y-MM-DD HH:mm:ss')},
                {"action": "step_started", "step": 2, "created_at": new moment().format('Y-MM-DD HH:mm:ss')},
                {"action": "step_finished", "step": 2, "created_at": new moment().format('Y-MM-DD HH:mm:ss')},
            ],
            params: {id: genuineActivityId},
            onSuccessBeforeNext: () => {
                return true
            },
            onError: () => {
            }
        },
        [
            {
                service: activitiesService.finalizeActivity,
                data: {},
                params: {id: genuineActivityId},
                onSuccessBeforeNext: (successData) => {
                    // setRequestsReset()
                    // console.log(props.finalizeActivitySuccessData)
                    props.setOngoingActivity(false)
                    const activity = {...successData?.activity}
                    const user = {...successData?.user}
                    const skillSets = successData?.skill_sets
                    pushSuccessScreen(user, activity, skillSets)

                    incrementActivitiesCount().then()

                    return true

                },
                onError: () => {
                }
            }
        ]
    )

    const onSubmit = data => {
        // console.log("test")
        if (submitted) return
        Keyboard.dismiss()
        // console.log("before first request = ", data)
        setStatus("finished")
        startRequests()
    }

    useEffect(() => {
        // if activity is already finished
        if (activity?.finished_at) {
            handleSubmit(onSubmit)()
        }

        return () => {
            // if (soundPlaying) stopSound()
        }

    }, [])


    const pushSuccessScreen = (user, activity, skillSets) => {

        props.clearActivityData()

        // console.log("skillSets", skillSets)
        const challenge = user?.challenges?.find(c => c.id === activity?.challenge_id) ? {...user?.challenges?.find(c => c.id === activity?.challenge_id)} : null

        const congrats = [{
            congratsType: congratsTypes.ACTIVITY_FINALIZED,
            congratsData: {
                activity
            }
        }]

        if (activity?.student_chapter)
            congrats.push({
                congratsType: congratsTypes.CHAPTER_PROGRESS_CONGRATS,
                congratsData: {
                    activity
                }
            })

        if (challenge) {
            congrats.push({
                congratsType: congratsTypes.CHALLENGE_PROGRESS,
                congratsData: {
                    challenge,
                    activity
                }
            })
        }

        // Skill sets progress congrats
        congrats.push({
            congratsType: congratsTypes.MY_PROGRESS,
            congratsData: {
                userSkillSets: skillSets?.filter(ss => (ss?.id === 1 || ss?.id === 2))
            }
        })

        // If new title, Show skill-set new title congrats
        skillSets?.filter(ss => (
            ss?.current_count === 0
            && !ss?.is_last_evolution_read
            && ss?.current_step >= 2
            && ss?.steps?.[ss?.current_step - 1]?.title_gained !== ss?.steps?.[ss?.current_step - 2]?.title_gained
        ))?.forEach(ss => {
            congrats.push({
                congratsType: congratsTypes.NEW_TITLE_CONGRATS,
                congratsData: {
                    userSkillSet: ss
                }
            })
        })

        // If petals gained, show petals gained congrats
        const petalsGainedCount = skillSets?.filter(ss => (
            ss?.current_count === 0
            && !ss?.is_last_evolution_read
        ))?.reduce((pValue, cValue) => pValue + cValue?.steps?.[cValue?.current_step - 1]?.petals_gained, 0)
        if (petalsGainedCount > 0) {
            congrats.push({
                congratsType: congratsTypes.PETALS_GAINED,
                congratsData: {
                    petalsGainedCount
                },
                hideButton: true
            })
        }


        // mark as read request

        // console.log("CONGRATS = ", congrats)

        if (hasRole(user, "student") && !user?.champion_intros_viewed_at?.sam) {
            navigation.replace("congrats", {
                congrats,
                nextScreen: "intro",
                nextScreenParams: {name: "sam"}
            })
        } else {
            navigation.replace("congrats", {
                congrats
            })
        }

    }


    // console.log(defaultValues)
    // console.log(formSchema)


    const [currentStep, setCurrentStep] = useState(savedActivityLogs?.length > 0 ? Math.max(...savedActivityLogs?.map(al => al?.step)) : 1)
    const isFirstStep = currentStep === 1
    const isLastStep = currentStep === formData?.length
    const goToFirstStep = () => {
        if (!isFirstStep) setCurrentStep(currentStep - 1)
    }
    const goToNextStep = () => {
        if (!props.isInternetReachable && props.isOfflineModeEnabled) {
            DialogM.show({
                text1: 'Mode hors-ligne activé', text2: "Désactive le mode hors-ligne pour continuer l'activité."
            })
            //alertPolyfill('Mode hors-ligne activé', "Désactive le mode hors-ligne pour continuer l'activité.");

            return
        }

        // from step to step : clean offline mode status and stop sound if any
        props.offlineModeClear()

        // console.log("next step save")
        props.saveActivityData({...control._formValues})

        if (!isLastStep) setCurrentStep(currentStep + 1)
        // console.log(getValues())
    }
    // console.log(formData?.length)
    // console.log(control._formState)
    const [status, _setStatus] = useState(null)
    const statusRef = useRef(status); // ref is used to make use of state within app state event listener (timer)
    const setStatus = data => {
        statusRef.current = data;
        _setStatus(data);
    };
    const [animationFinished, setAnimationFinished] = useState(false);
    const [launchCamera] = useCamera(formIds.NEW_MATERIAL, true, true)

    const [visibleModal, setVisibleModal] = useState(false)
    const handleShowModal = () => {
        setVisibleModal(!visibleModal)
    }

    const postImage = (uri) => {
        const cleanFormData = new FormData();
        cleanFormData.append("temporary_id", "test")
        const image = {
            uri: uri,
            name: `activity_image_${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.png`,
            type: "image/png"
        }

        if (IS_WEB)
            cleanFormData.append("work_attachment", dataURLtoFile(uri, `activity_image_${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.png`))
        else
            cleanFormData.append("work_attachment", image)

        cleanFormData.append("step", 2)
        cleanFormData.append("action", "step_media_sent")
        cleanFormData.append("created_at", new moment().format('Y-MM-DD HH:mm:ss'))
        cleanFormData.append("live_mode", props.liveSessionId ? 1 : 0)
        if (props.liveSessionId)
            cleanFormData.append("live_session_id", props.liveSessionId)

        props.apiRequest(
            activitiesService.postActivityLogV1,
            {
                id: genuineActivityId,
            },
            cleanFormData
        );
    }


    useEffect(() => {
        if (
            props.lastPhotoFormId === formIds.NEW_MATERIAL
            && !!props.lastPhoto
            && !images?.map(x => x?.uri)?.includes(props.lastPhoto?.uri)
        ) {

            const imagesCopy = images?.map(x => x) ?? []
            imagesCopy?.push(props.lastPhoto)
            setImages(imagesCopy);

            postImage(props.lastPhoto?.uri)

            props.clearLastPhoto()

            handleShowModal()


        } else if (
            props.lastPhotoFormId === formIds.NEW_MATERIAL
            && props.lastPhotos?.length > 0
        ) {
            const imagesCopy = images?.map(x => x) ?? []
            imagesCopy?.push(...props.lastPhotos)
            setImages(imagesCopy);

            props.lastPhotos?.forEach(photo => {
                postImage(photo?.uri)
            })

            props.clearPhotos()

            handleShowModal()

        }

    }, [props.lastPhoto, props.lastPhotos])

    const audioSend = async () => {
        //console.log(recordingURI)
        const cleanFormData = new FormData();
        const audio = IS_WEB ? await fetchResourceFromURI(recordingURI) : {
            uri: recordingURI,
            name: `Activity_audio${props.user?.display_name}_${moment().format("YYYY-MM-DD")}.m4a`,
            type: "audio/m4a"
        }
        //console.log(audio)
        cleanFormData.append("work_attachment", audio);
        cleanFormData.append("step", 2)
        cleanFormData.append("action", "step_media_sent")
        cleanFormData.append("created_at", new moment().format('Y-MM-DD HH:mm:ss'))
        cleanFormData.append("live_mode", props.liveSessionId ? 1 : 0)
        if (props.liveSessionId)
            cleanFormData.append("live_session_id", props.liveSessionId)
        props.apiRequest(
            activitiesService.postActivityLogV1,
            {
                id: genuineActivityId,
            },
            cleanFormData
        );
    }

    useEffect(() => {
        if (props.audioStatus === "recorded") {
            audioSend()
            props.audioRecordingStatus("")
        }
    }, [props.audioStatus]);

    useEffect(() => {
        if (props.audioStatus === "deleted") {
            props.apiRequest(activitiesService.deleteActivityLog, {id: currentAudio?.id}, {
                live_mode: !!props.liveSessionId,
                live_session_id: props.liveSessionId ?? undefined
            })
            props.audioRecordingStatus("")
        }
    }, [props.audioStatus]);

    useApiStatus(
        activitiesService.postActivityLogV1, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )
    useApiStatus(
        activitiesService.deleteActivityLog, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
            setRecordingURI(null)
        }
    )

    const buttonLabel = activityType === "photo" ? "scanner mon travail" : "Enregistrer mon travail"
    const buttonIcon = activityType === "photo" ? "camera" : "microphone"
    return (
        <View style={{
            flex: 1,
            // backgroundColor: theme.colors.white,
            alignItems: 'center',
        }}>
            <TimerActivityStep activity={activity}
                               control={control}
                               animationFinished={animationFinished}
                               setAnimationFinished={setAnimationFinished}
                               handleShowMyWorkModal={handleShowModal}
                               status={status}
                               setStatus={setStatus}
                               chronoMode
                               submitted={submitted}
                               globalProgress={globalProgress}
                               submittedMessage={submittedMessage}
                               stepDescriptions={stepDescriptions}
            />
            {animationFinished && !submitted &&
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        // width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 1.5,
                        marginBottom: 20,
                    }}>
                    {/*{images.length > 0 && activityType === "photo" && <IconButton*/}
                    {/*    icon={buttonIcon}*/}
                    {/*    iconColor={theme.colors.white}*/}
                    {/*    size={32}*/}
                    {/*    style={{*/}
                    {/*        width: "33%",*/}
                    {/*        height: 50,*/}
                    {/*        backgroundColor: theme.colors.primary,*/}
                    {/*        alignItems: "center",*/}
                    {/*        justifyContent: "center",*/}
                    {/*        alignSelf: 'center',*/}
                    {/*        margin: 0,*/}
                    {/*        marginBottom: 10,*/}
                    {/*        borderRadius: 15,*/}
                    {/*    }}*/}
                    {/*    onPress={launchCamera}*/}
                    {/*/>}*/}
                    <FilledButtonM color={theme.colors.primary}
                                   onPress={images.length > 0 || activityType === "audio" ? handleShowModal : launchCamera}
                                   icon={() => <View
                                       style={{
                                           backgroundColor: theme.colors.black,
                                           height: 50,
                                           width: 50,
                                           alignItems: "center",
                                           justifyContent: "center",
                                           alignSelf: 'flex-start',
                                           right: 12,
                                           borderTopLeftRadius: 15,
                                           borderBottomLeftRadius: 15,
                                       }}>
                                       {props.postActivityLogV1Request ? <ActivityIndicator color={theme.colors.white} size={"small"}/> : <>
                                       <FontAwesome5 name={activityType === "photo" ? "camera" : "microphone"}
                                                     size={18}
                                                     color={theme.colors.white}
                                                     style={{alignSelf: 'center'}}/>
                                       {images?.length > 0 && activityType === "photo" && <BadgeM
                                           style={{
                                               backgroundColor: theme.colors.primary,
                                               position: "absolute",
                                               bottom: 5,
                                               right: 10,
                                               color: theme.colors.white,
                                           }}
                                           count={images?.length} size={16}/>}
                                       </>}
                                   </View>}
                                   style={{
                                       // width: images?.length > 0 && activityType === "photo" ? "66%" : "100%",
                                       alignSelf: "center",
                                       //marginBottom: 20,
                                   }}
                                   contentStyle={{
                                       justifyContent: images?.length > 0 && activityType === "photo" ? 'space-between' : 'center',
                                       alignItems: 'center',
                                   }}
                                   condition={images?.length > 0 && activityType === "photo"}
                                   labelStyle={{
                                       fontSize: 12,
                                       flex: 1,
                                       right: 12
                                   }}
                                   label={images?.length > 0 ? "Mon travail" : buttonLabel}/>
                </View>}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
            <MyWorkModal
                images={images}
                isOpen={visibleModal}
                handleShowModal={handleShowModal}
                setImages={setImages}
                navigation={navigation}
                onSubmit={handleSubmit(onSubmit)}
                activityType={activityType}
                status={status}
                setStatus={setStatus}
                control={control}
                activityId={genuineActivityId}
                recordingURI={recordingURI}
                setRecordingURI={setRecordingURI}
                stepDescriptions={stepDescriptions}
            />
        </View>
    )
}
const mapStateToProps = state => {
    return {


        postActivityLogV1Request: state.api.postActivityLogV1?.request,
        finalizeActivityRequest: state.api.finalizeActivity?.request,
        finalizeActivitySuccess: state.api.finalizeActivity?.success,
        finalizeActivityError: state.api.finalizeActivity?.error,

        finalizeActivitySuccessData: state.api.finalizeActivity?.data,

        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object,
        activityLogs: state.data.currentUser?.object?.activity_logs,

        isInternetReachable: state.offlineMode.isInternetReachable,
        isOfflineModeEnabled: state.offlineMode.isOfflineModeEnabled,
        liveSessionId: state.liveMode?.liveSessionId,
        isLive: state.liveMode.isLiveModeEnabled,
        liveSessions: state.data.currentUser?.object?.live_sessions,
        ongoingActivity: state.activity?.ongoingActivity,
        lastPhoto: state.camera?.lastPhoto,
        lastPhotos: state.camera?.lastPhotos,
        lastPhotoFormId: state.camera?.formId,
        audioStatus: state.activity?.audioStatus,
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        offlineModeClear,
        saveActivityData,
        clearActivityData,
        setOngoingActivity,
        clearLastPhoto,
        clearPhotos,
        audioRecordingStatus
    }

export default connect(mapStateToProps, mapDispatchToProps)(ActivityScreen)