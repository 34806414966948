import {FlatList, View} from "react-native";
import {ActivityIndicator, Button} from "react-native-paper";
import React, {useContext, useEffect, useRef, useState} from 'react'
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import StudentsListItem from "../../tutor/students/StudentsListItem";
import {theme} from "@common/theme/theme";
import {schoolsService} from "@data/services/schoolsService";
import {ClassroomInviteModal} from "./ClassroomInviteModal";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {boxedCardBorderStyle} from "../../reviewer/BoxedCardBorderStyle";

function ClassroomStudentsTab(props) {

    const dimensions = useContext(DimensionsContext)
    const classroom = props.classroom
    const flatListRef = useRef();
    const navigation = useNavigation()
    const [modal, setModal] = useState(false)
    const handleShowModal = () => setModal(!modal)
    const [selectedStudentId, setSelectedStudentId] = useState(null)

    useEffect(() => {
        props.apiRequest(schoolsService.getClassroom, {id: classroom?.id})
    }, [])

    useApiStatus(
        schoolsService.removeStudentFromClassroom, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        () => {
        } // success callback
    )
    const scrollToIndex = (index) => {
        flatListRef.current.scrollToIndex({index});
    };

    return (
        <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
        }}>

            {props.getClassroomRequest && IS_WEB ?
                <ActivityIndicator color={theme.colors.primary}/> : <FlatList
                    data={classroom?.students}
                    ref={flatListRef}
                    refreshing={!!props.getClassroomRequest}
                    onRefresh={() => {
                        props.apiRequest(schoolsService.getClassroom, {id: classroom?.id})
                    }}
                    ListHeaderComponent={
                        <>
                            <View style={{
                                ...boxedCardBorderStyle,
                                borderTopWidth: 1,
                                borderBottomWidth: 1,
                                width: dimensions?.width,
                                borderColor: theme.colors.lightMore,
                                backgroundColor: theme.colors.white,
                                paddingHorizontal: 10,
                                paddingVertical: 10,
                                marginVertical: 10,
                                justifyContent: 'space-between',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <TextM fontWeight={"Bold"} fontSize={20}>
                                    {classroom?.students?.length ?? 0} {"Élève".toUpperCase()}{classroom?.students?.length > 1 && "S"}
                                </TextM>
                                <Button
                                    icon="plus"
                                    onPress={() => {
                                        setModal(true)
                                    }}
                                    mode={"contained"}
                                    labelStyle={{fontSize: 12}}

                                >
                                    Inviter des élèves
                                </Button>
                            </View>
                        </>
                    }
                    ListEmptyComponent={() => <TextM style={{
                        alignSelf: 'center',
                        paddingVertical: 30
                    }}>
                        Pas d'élèves
                    </TextM>}
                    style={{
                        width: dimensions?.width
                    }}
                    renderItem={(item) => {
                        const scroll = () => {
                            scrollToIndex(item.index);
                        };

                        return <StudentsListItem student={item.item}
                                                 key={item.index}
                                                 index={item.index}
                                                 isLoading={props.removeStudentFromClassroomRequest && (item.item?.id === props.removeStudentFromClassroomStudentId)}
                                                 menuItems={[{
                                                     title: "Supprimer", onPress: () => {
                                                         DialogM.show({
                                                             text1: 'Supprimer l\'élève de la classe ?',
                                                             text2: 'Si vous changez d\'avis plus tard, vous devrez réinviter cet élève à rejoindre la classe',
                                                             buttonText1: 'Supprimer',
                                                             buttonColor1: theme.colors.error,
                                                             onPress: () => {
                                                                 props.apiRequest(
                                                                     schoolsService.removeStudentFromClassroom,
                                                                     {id: classroom?.id},
                                                                     {student_id: item.item?.id}
                                                                 )
                                                             },
                                                             buttonText2: 'Annuler',
                                                         })
                                                         setSelectedStudentId(item.item?.id)
                                                     }
                                                 }]}
                        />;
                    }}
                />}
            <ClassroomInviteModal handleShowModal={handleShowModal} isOpen={modal} classroom={classroom}/>
        </View>
    )
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request,
        schoolSubjects: state.data.staticData?.school_subjects,
        getClassroomRequest: state.api.getClassroom?.request,
        removeStudentFromClassroomRequest: state.api.removeStudentFromClassroom?.request,
        removeStudentFromClassroomStudentId: state.api.removeStudentFromClassroom?.data?.student_id
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomStudentsTab)