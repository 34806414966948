import {FlatList, View} from "react-native";
import {findItemInListWithId, sortByDateAttribute} from "@data/utility/dataUtils";
import React, {useRef, useState} from "react";
import moment from "moment";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {ChallengeTimeline} from "./ChallengeTimeline";
import {IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import ActivityPlannedButton from "../list/ActivityPlannedButton";

const timelineDataList = (activities, challenge) => {

    const data = []
    const sortedActivities = sortByDateAttribute(activities)

    // ** first milestone = launch
    data?.push({
        type: "none",
        date: ""
        // date: moment(sortedActivities[0]?.date).isSame(challenge?.created_at, 'day') ? "" : challenge?.created_at
    })


    // ** other milestones
    sortedActivities?.forEach((activity, index) => {

        // if(challenge?.id === 55)
        //     console.log(activity)

        data?.push({
            type: index === 0 ? "launch" : !moment(activity?.date)?.isSame(sortedActivities[index - 1]?.date, "day") ? "day" : "none",
            date: activity?.date,
            activity: activity
        })
    })

    // ** last milestone = deadline
    data?.push({
        type: "deadline",
        date: moment(sortedActivities[sortedActivities?.length - 1]?.date).isSame(challenge?.deadline, 'day') ? "" : challenge?.deadline
    })

    return data

}

function ChallengePlanningScroll(props) {

    // ** Component props
    const {challenge, activities, readOnly, countLateActivities, student} = props

    // ** Challenge template and Activity templates associated to challenge
    // const challengeTemplate = findItemInListWithId(challenge?.challenge_template_id, props.challengeTemplates)

    // ** Manage scrolling to specific index in flat list
    const flatListRef = useRef()

    // ** Manage calendar
    const initialDate = moment().isBetween(challenge?.created_at, challenge?.deadline) ? moment().format('YYYY-MM-DD') : challenge?.deadline

    // ** Calculate initial index to scroll to
    const initialScrollIndex = (timelineData) => {
        let index = 0
        while (moment(timelineData[index]?.date).isBefore(initialDate, 'day')) {
            index++
        }
        return index
    }

    // ** FlatList item width
    const ITEM_WIDTH = 116

    // Calculate flag index
    const flagIndex = (timelineData) => {
        let index = timelineData?.length - 1
        while (moment(timelineData[index]?.date).isAfter(initialDate, 'day')) {
            index--
        }
        return index + 1
    }

    // ** Manage filtering of late activities
    const [filterLate, setFilterLate] = useState(false)
    // const filterLatePressed = () => {
    //     if (!filterLate) scrollToIndex(0)
    //     setFilterLate(!filterLate)
    // }
    const timelineDataActivities = timelineDataList(activities?.filter(x => !x?.again_activity_id && (!filterLate || x?.is_late)), challenge)

    return <View>
        {/*<ChallengePlanningScrollHeader countLateActivities={countLateActivities}*/}
        {/*                               filterLate={filterLate}*/}
        {/*                               onPressFilter={filterLatePressed}*/}
        {/*                               onPressToday={() => scrollToIndex(initialScrollIndex(timelineDataActivities))}/>*/}
        <FlatList data={timelineDataActivities}
                  ref={flatListRef}
                  initialScrollIndex={initialScrollIndex(timelineDataActivities)}
                  getItemLayout={(data, index) => (
                      {length: ITEM_WIDTH, offset: ITEM_WIDTH * index, index}
                  )}
                  horizontal
                  style={{
                      // marginTop: 10,
                      height: 200,
                      backgroundColor: theme.colors.primaryLighter,
                      borderBottomLeftRadius: IS_WEB ? 10 : 0,
                      borderBottomRightRadius: IS_WEB ? 10 : 0,
                  }}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={(itemInfo) => {


                      return <View style={{width: ITEM_WIDTH}}>
                          <ChallengeTimeline itemInfo={itemInfo}
                                             itemWidth={ITEM_WIDTH}
                                             flagIndex={flagIndex(timelineDataActivities)}/>
                          {itemInfo.item?.activity && <ActivityPlannedButton
                              challenge={challenge}
                              activityTemplate={findItemInListWithId(itemInfo.item?.activity?.activity_template_id, props.activityTemplates)}
                              activityPlanned={itemInfo.item?.activity}
                              student={student}
                              activities={[itemInfo.item?.activity]?.concat(activities?.filter(a => a?.again_activity_id === itemInfo.item?.activity?.id))}
                              small
                              readOnly={readOnly}
                              // activities={!activityPlanningMode && activities.filter(activity => activity.activity_template_id === activityTemplate.id)}
                          />}
                      </View>
                  }
                  }/>
    </View>;
}

const mapStateToProps = state => {
    return {
        challengeTemplates: state.data.staticData?.challenge_templates,
        activityTemplates: state.data.staticData?.activity_templates,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(ChallengePlanningScroll)