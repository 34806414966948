import axios from "axios"

function subscribeToTrial({}, data) {
    return axios.post(`/api/v1/user/subscribe-trial`, data)
}

function getLiveRoomsAvailableForTrial({}, {}, params) {
    return axios.get(`/api/v2/live-rooms/available-for-trial`, {params})
}

function subscribeToTrialV2({}, data) {
    return axios.post(`/api/v2/user/subscribe-trial`, data)
}

function subscribeToSpecialV2({}, data) {
    return axios.post(`/api/v2/user/subscribe-special`, data)
}

function getSubscribePricing({}, {}, params) {
    return axios.get(`/api/v2/subscribe/pricing`, {params})
}

function getSubscribeRedirectUrl({}, {}, params) {
    return axios.get(`/api/v2/subscribe/redirect`, {params})
}

function submitTrialSlots({}, data) {
    return axios.post(`/api/v1/user/submit-trial-slots`, data)
}

function updatePrioritizedSchoolSubjects({}, data) {
    return axios.put(`/api/v2/subscribe/prioritized-school-subjects`, data)
}

function updateAvailabilitySlots({}, data) {
    return axios.put(`/api/v2/subscribe/availability-slots`, data)
}

function checkPromoCode({}, data) {
    return axios.post(`/api/user/check-promo-code`, data)
}

function suspendSubscription({}, data) {
    return axios.post(`/api/v1/subscription/pause-resume-choice`, data)
}

function getPauseResumeChoice({}, {}, params) {
    return axios.get(`/api/v1/subscription/pause-resume-choice`, {params})
}

function callMeBack({}, data) {
    return axios.post(`/api/v2/trial-call-me-back`, data)
}

export const subscriptionService = {
    subscribeToTrial,
    subscribeToTrialV2,
    subscribeToSpecialV2,
    submitTrialSlots,
    checkPromoCode,
    suspendSubscription,
    getPauseResumeChoice,
    getLiveRoomsAvailableForTrial,
    getSubscribeRedirectUrl,
    getSubscribePricing,
    callMeBack,
    updatePrioritizedSchoolSubjects,
    updateAvailabilitySlots
}

