import * as Notifications from "expo-notifications";

export const dismissNotificationIfExists = (pathValue, identifierKey, identifierValue) => {
    Notifications.getPresentedNotificationsAsync().then((notifications) => {
        // console.log("notifications list", notifications)

        notifications?.forEach(n => {

            if (
                n &&
                !!n.request.content.data
            ) {
                if (
                    n.request.content.data.path &&
                    n.request.content.data.path === pathValue
                ) {
                    if (
                        n.request.content.data[identifierKey] &&
                        n.request.content.data[identifierKey] === identifierValue
                    ) {
                        Notifications.dismissNotificationAsync(n.request.identifier)
                    }
                }
            }
        })
    })


}