import {StyleSheet, View} from "react-native";
import React from "react";
import TextM from "@common/components/text/TextM";

const DataTableCellM = ({
                            children,
                            textStyle,
                            style,
                            numeric,
                            ...rest
                        }) => (
    <View
        {...rest}
        style={[styles.container, numeric && styles.right, style]}
    >
        <TextM style={textStyle} wrap {...rest}>
            {children}
        </TextM>
    </View>
);


const styles = StyleSheet.create({
    container: {
        // flex: 1,
        paddingHorizontal: 15,
        paddingVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },

    right: {
        justifyContent: 'flex-end',
    },
});

export default DataTableCellM;
