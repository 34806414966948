import {TouchableOpacity, View} from "react-native";
import {ChampionImageM} from "../champions/ChampionImageM";
import {SkillSetTitleFull} from "@common/components/statistics/Counters";
import TextM from "@common/components/text/TextM";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {theme} from "@common/theme/theme";
import React from "react";
import {championSlugsToNames} from "@data/constants/championsConstants";
import {IS_WEB} from "@common/utils/mobileUtils";

export const formatFrenchDe = (word) => {
    if (["a", "e", "i", "o", "u", "y"]?.includes(word?.substring(0, 1)?.toLowerCase()))
        return "d'" + word
    else
        return "de " + word
}

export function SkillSetTitleButton(props) {

    const {
        skillSet,
        onPress = () => {
        },
        style,
        disabled = false,
        readOnly = false,
        withChampion = true,
        withIcon = false
    } = props

    return <TouchableOpacity style={{
        backgroundColor: skillSet?.color + "22",
        flex: 1,
        width: "100%",
        flexDirection: "row",
        alignSelf: "center",
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: 20,
        height: 62,
        overflow: IS_WEB ? 'hidden' : null,
        opacity: disabled ? 0.2 : 1,
        // marginTop: 20;
        ...style
    }}
                             onPress={onPress}
                             disabled={disabled || readOnly}
    >
        {withChampion && <ChampionImageM name={skillSet?.champion}
                                         variant={"main"}
                                         style={{
                                             position: "absolute",
                                             right: -20,
                                             opacity: 0.08
                                         }}
                                         eye
                                         width={200}/>}
        <View style={{
            alignItems: "flex-start"
        }}>
            <SkillSetTitleFull title={skillSet?.steps?.[skillSet?.current_step - 1]?.title_gained}
                               icon={skillSet?.icon_path + skillSet?.steps?.[skillSet?.current_step - 1]?.title_gained_icon}
                               skillSetId={skillSet?.id}/>
            <TextM fontSize={12}
                   style={{marginLeft: 28}}
                   fontWeight={"Light"}>Quêtes {formatFrenchDe(championSlugsToNames[skillSet?.champion])} finies
                : {skillSet?.current_step - 1}/{skillSet?.steps?.length - 1}</TextM>
        </View>
        {withIcon && <FontAwesome5 name={"pen"}
                                   color={theme.colors.primary}
                                   size={16}
                                   style={{
                                       position: "absolute",
                                       right: 20
                                   }}/>}

    </TouchableOpacity>;
}