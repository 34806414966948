import React, {useContext, useEffect, useState} from "react";
import {ChampionModalContext} from "../../champions/ChampionBottomSheetModal";
import moment from "moment";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {markAdditionalSuggestionAsShown, markHomeworkSuggestionAsShown} from "@data/redux/actions/suggestions";
import {clearActivityData} from "@data/redux/actions/formData";
import {connect} from "react-redux";
import {useNavigation} from "@react-navigation/native";
import {DialogM} from "@common/components/alert/dialog/Dialog";
import {capitalize} from "@data/utility/Utils";
import {setIsLiveModeEnabled} from "@data/redux/actions/liveMode";
import {findClosestNextOccurrence} from "../../account/onboarding/LiveRoomCard";
import useLaunchActivity from "@common/hooks/useLaunchActivity";
import {getFromStorage, setToStorage} from "@data/utility/asyncStorageUtils";

export const checkConditionsForClassUpdate = (user) => {
    // Get the current date
    const currentDate = moment();

    // Get current year string
    const currentYear = currentDate.format('YYYY');

    // Check if the current date is between August 15th and September 30th of the current year
    const isWithinDateRange = currentDate.isBetween(currentYear + '-08-15', currentYear + '-09-30', 'day', '[]');

    // Calculate the difference between now and "updated_at" on the profile
    const updatedAt = moment(user?.profile?.updated_at);
    const monthsDiff = currentDate.diff(updatedAt, 'months');

    // Check if the difference is positive and greater than 2 months
    const isTimeDifferenceValid = monthsDiff >= 2;

    return isWithinDateRange && isTimeDifferenceValid;
};
const StudentSuggestions = (props) => {

    const navigation = useNavigation()

    const {showSchoolSubjectBottomSheet} = props

    const championModalRef = useContext(ChampionModalContext)

    const [savedActivityShown, setSavedActivityShown] = useState(false)

    const todayLiveSession = props.user?.live_sessions?.find((liveSession) => {

        const closestNextOccurrence = findClosestNextOccurrence([liveSession])

        const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null

        return liveMoment?.isSame(moment(), "day") && moment().clone().isBetween(liveMoment.subtract(5, "minutes"), liveMoment.clone().add(45, "minutes"))
    })


    const closestNextOccurrence = findClosestNextOccurrence([todayLiveSession])
    const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null

    const liveSessionTodoTime = props.user?.live_sessions?.find((liveSession) => {
        const closestNextOccurrence = findClosestNextOccurrence([liveSession])
        const liveMoment = closestNextOccurrence?.date ? moment(closestNextOccurrence?.date) : null
        return liveMoment?.isSame(moment(), "day") && moment().clone().isBetween(liveMoment.clone().subtract(60, "minutes"), liveMoment.clone().subtract(5, "minutes"))
    })
    const launchActivity = useLaunchActivity()

    useEffect(() => {
        if (props.savedActivityData && !savedActivityShown && !props.ongoingActivity) {

            setSavedActivityShown(true)

            DialogM.show({
                text1: 'Activité récupérée',
                text2: 'Une activité interrompue a été récupérée. Souhaites-tu la continuer ?',
                buttonText2: 'Supprimer',
                onPressBack: () => {
                    props.clearActivityData()
                    setSavedActivityShown(false)
                },
                buttonText1: 'Continuer',
                onPress: () => {
                    launchActivity({
                        activity_template_id: props.savedActivityData?.init?.activity_template_id,
                        id: props.savedActivityData?.init?.activity_id,
                        school_subject_id: props.savedActivityData?.init?.school_subject_id,
                        challengeId: props.savedActivityData?.init?.challenge_id,
                        againActivityId: props.savedActivityData?.init?.again_activity_id,
                        savedActivityLogs: props.savedActivityData?.activity_logs,
                        savedWorkAttachmentLogs: props.savedActivityData?.work_attachment_logs,
                        chapterName: props.savedActivityData?.student_chapter?.chapter?.name,
                        chapterId: props.savedActivityData?.student_chapter?.id,
                        xpGained: props.savedActivityData?.student_chapter?.xp_gained,
                        ongoing: props.savedActivityData?.student_chapter?.ongoing,
                        lastFeelingSentAt: props.savedActivityData?.student_chapter?.last_feeling_sent_at
                    }, "push")
                    setSavedActivityShown(false)
                }
            })

        } else if (checkConditionsForClassUpdate(props.user) && !props.isLiveMode) {
            championModalRef.current?.showChampion(
                "zoe",
                "happy",
                "Une nouvelle année scolaire commence…",
                "Mets à jour ta classe !",
                [
                    {
                        label: "Mettre à jour",
                        onPress: () => {
                            navigation.push("update-class")
                        }
                    }
                ]
            )

        } else if (!!liveSessionTodoTime && !props.isLiveMode) {
            championModalRef.current?.showChampion(
                "zoe",
                "happy",
                "Prépare ta liste d’activités",
                "Avant le début de ton Live, il faut que la durée totale d’activités à faire soit de 45 min",
                [
                    {
                        label: "Ok !",
                        onPress: () => {
                            // props.setIsLiveModeEnabled(true, todayLiveSession?.id)
                        }
                    }
                ]
            )

        } else if (!!todayLiveSession && !props.isLiveMode) {
            championModalRef.current?.showChampion(
                "zoe",
                "happy",
                "C'est l'heure de te connecter à ta session LIVE !",
                capitalize(liveMoment?.format("dddd HH:mm")),
                [
                    {
                        label: "Rejoindre le LIVE",
                        onPress: () => {
                            navigation.push("pre-call", {
                                liveSessionId: todayLiveSession?.id
                            })
                        }
                    }
                ]
            )

        } else if (!props.user?.avatar_selected || props.user?.avatar_selected?.includes("png")) {
            getFromStorage('avatarLastSuggestion').then((avatarLastSuggestion) => {

                // check if last suggestion was less than 2 days ago
                const shouldNotSuggest = avatarLastSuggestion && ((Date.now() - avatarLastSuggestion) <= (2 * 24 * 60 * 60 * 1000))

                if (!shouldNotSuggest) {
                    setToStorage('avatarLastSuggestion', JSON.stringify(Date.now())).then();

                    championModalRef.current?.showChampion(
                        "tina",
                        "main",
                        "Choisis ton avatar !",
                        "Personnalise ton avatar pour qu’il te ressemble !",
                        [
                            {
                                label: "GO !",
                                onPress: () => {
                                    navigation.push("avatar")
                                }
                            }
                        ]
                    )
                }

            })


        }

    }, [props.activityTemplates])


    return <></>;
}

const mapStateToProps = state => {
    return {
        abandonChallengeRequest: state.api.abandonChallenge?.request,
        abandonChallengeSuccess: state.api.abandonChallenge?.success,
        abandonChallengeError: state.api.abandonChallenge?.error,
        abandonChallengeSuccessData: state.api.abandonChallenge?.data,

        savedActivityData: state.data?.formData?.activity,
        isLiveMode: state.liveMode?.isLiveModeEnabled,
        ongoingActivity: state.activity?.ongoingActivity,

        getUserRequest: state.api.getUser?.request,
        getUserSuccess: state.api.getUser?.success,
        getUserError: state.api.getUser?.error,
        user: state.data.currentUser?.object,
        last_homework_suggestion: state.data.suggestions?.last_homework_suggestion,
        last_additional_suggestion: state.data.suggestions?.last_additional_suggestion,
        schoolSubjects: state.data.staticData?.school_subjects,
        maxSimultaneousChallenges: state.data.staticData?.max_simultaneous_challenges,
        activityTemplates: state.data.staticData?.activity_templates,
        challengeTemplates: state.data.staticData?.challenge_templates
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        markHomeworkSuggestionAsShown,
        markAdditionalSuggestionAsShown,
        clearActivityData,
        setIsLiveModeEnabled
    }

export default connect(mapStateToProps, mapDispatchToProps)(StudentSuggestions)