import { apiConstants } from "../../../constants/apiConstants";
import { backOfficeService } from "../../../services/backOfficeService";
import { roomService } from "@data/services/roomService";

// ** Initial State
const initialState = {
  liveReports: [],
  liveOccurrences: [],
  liveSessions: [],
  liveSession: {},
  rooms: [],
  appStats: null,
};

export function backOffice(state = initialState, action) {
  switch (action.type) {
    case backOfficeService.deleteOccurrence.name + apiConstants._SUCCESS:
      return {
        ...state,
        liveOccurrences: state?.liveOccurrences?.filter(
          (occurrence) => occurrence?.id !== action?.routeParams?.id
        ),
      };
    case backOfficeService.editBilling.name + apiConstants._SUCCESS:
      return {
        ...state,
        liveOccurrences: state.liveOccurrences.map((occurrence) => {
          if (occurrence?.id === action?.data?.id) {
            return action?.data;
          }
          return occurrence;
        }),
      };
    case backOfficeService.getLiveOccurrences.name + apiConstants._SUCCESS:
      let liveOccurrences = [];
      if (action.data?.meta?.current_page === 1) {
        liveOccurrences = action.data?.live_occurrences;
      } else {
        liveOccurrences = state.liveOccurrences?.concat(
          action.data?.live_occurrences
        );
      }
      return {
        ...state,
        liveOccurrences,
      };
    case backOfficeService.getLiveReports.name + apiConstants._SUCCESS:
      let liveReports = [];
      if (action.data?.meta?.current_page === 1) {
        liveReports = action.data?.live_reports;
      } else {
        liveReports = state.liveReports?.concat(action.data?.live_reports);
      }
      return {
        ...state,
        liveReports: liveReports,
      };

    case backOfficeService.getApplicationStatistics.name +
      apiConstants._SUCCESS:
      return {
        ...state,
        appStats: action?.data,
      };
    case backOfficeService.newLiveSession.name + apiConstants._SUCCESS:
    case roomService.addMultipleSessions.name + apiConstants._SUCCESS:
      return {
        ...state,
        liveSessions: action?.data?.live_sessions ?? [
          ...state.liveSessions,
          action?.data,
        ],
      };

    case backOfficeService.getLiveSessions.name + apiConstants._SUCCESS:
      let sessions = [];
      // console.log("meta", action.data)
      if (action.data?.meta?.current_page === 1) {
        // refresh
        sessions = action.data?.live_sessions;
      } else {
        // concat
        sessions = state.liveSessions.concat(action.data?.live_sessions);
      }
      return {
        ...state,
        liveSessions: sessions,
      };

    case backOfficeService.deleteLiveSession.name + apiConstants._SUCCESS:
      return {
        ...state,
        rooms: state?.rooms?.map((room) => {
          return {
            ...room,
            live_sessions: room?.live_sessions?.filter(
              (session) => action.routeParams?.id !== session?.id
            ),
          };
        }),
      };
    /*        case backOfficeService.addUsersLive.name + apiConstants._SUCCESS:
                case backOfficeService.deleteUsersFromLive.name + apiConstants._SUCCESS:
                    const count = action?.data?.students_count ?? action?.data?.live_session?.students_count
                    return {
                        ...state,
                        liveSession: action?.data?.live_session ?? action?.data,
                        liveSessions: state.liveSessions.map((session) => {
                            if (session?.id === (action?.data?.live_session?.id ?? action?.data?.id)) {
                                return action?.data?.live_session ?? action?.data
                            }
                            return session
                        }),
                        rooms: state?.rooms?.map((room) => {
                            if (room?.live_sessions?.find((session) => session?.id === (action?.data?.live_session?.id ?? action?.data?.id)))
                                return {
                                    ...room,
                                    students_count: room?.students_count + (count - room?.live_sessions?.find((session) => session?.id === (action?.data?.live_session?.id ?? action?.data?.id))?.students_count),
                                    live_sessions: room?.live_sessions?.map((session) => {
                                        if (session?.id === (action?.data?.live_session?.id ?? action?.data?.id)) {
                                            return action?.data?.live_session ?? action?.data
                                        }
                                        return session
                                    })
                                }
                            return room
                        })
                    }*/
    case backOfficeService.removeSubstitution.name + apiConstants._SUCCESS:
      return {
        ...state,
        liveSession: {
          ...state?.liveSession,
          substitutions: state?.liveSession?.substitutions?.filter(
            (substitution) => substitution?.id !== action?.routeParams?.id
          ),
        },
      };
    case backOfficeService.reschedule.name + apiConstants._SUCCESS:
    case backOfficeService.resetReschedulingsForLiveSession.name +
      apiConstants._SUCCESS:
      return {
        ...state,
        liveSession: action?.data,
      };
    case backOfficeService.requestSubstitution.name + apiConstants._SUCCESS:
    case backOfficeService.acceptSubstitution.name + apiConstants._SUCCESS:
      const sub = state?.liveSession?.substitutions?.find(
        (substitution) => substitution?.id === action?.data?.id
      );
      return {
        ...state,
        liveSession: {
          ...state?.liveSession,
          substitutions: sub
            ? state?.liveSession?.substitutions?.map((substitution) => {
                if (substitution?.id === action?.data?.id) {
                  return action?.data;
                }
                return substitution;
              })
            : state?.liveSession?.substitutions?.concat(action?.data),
          next_occurrences: state?.liveSession?.next_occurrences?.map(
            (occurrence) => {
              if (occurrence?.date === action?.data?.date?.slice(0, -3)) {
                return {
                  ...occurrence,
                  substitution_id: action?.data?.id,
                };
              }
              return occurrence;
            }
          ),
        },
      };
    case backOfficeService.addUsersLive.name + apiConstants._SUCCESS:
    case backOfficeService.deleteUsersFromLive.name + apiConstants._SUCCESS:
    case backOfficeService.getLiveSessionAdmin.name + apiConstants._SUCCESS:
    case backOfficeService.updateLiveSession.name + apiConstants._SUCCESS:
      return {
        ...state,
        liveSession: action?.data?.live_session ?? action?.data,
        liveSessions: state.liveSessions.map((session) => {
          if (
            session?.id === (action?.data?.live_session?.id ?? action?.data?.id)
          ) {
            return action?.data?.live_session ?? action?.data;
          }
          return session;
        }),
        rooms: state?.rooms?.map((room) => {
          return {
            ...room,
            live_sessions: room?.live_sessions?.map((session) => {
              if (
                session?.id ===
                (action?.data?.live_session?.id ?? action?.data?.id)
              ) {
                return action?.data?.live_session ?? action?.data;
              }
              return session;
            }),
          };
        }),
      };
    case roomService.getRooms.name + apiConstants._SUCCESS:
      let rooms = [];
      if (action.data?.meta?.current_page === 1) {
        rooms = action.data?.live_rooms;
      } else {
        rooms = state.rooms.concat(action.data?.live_rooms);
      }
      return {
        ...state,
        rooms: rooms,
      };
    case roomService.newRoom.name + apiConstants._SUCCESS:
      return {
        ...state,
        rooms: action?.data?.live_rooms ?? [...state.rooms, action?.data],
      };
    case roomService.editRoom.name + apiConstants._SUCCESS:
    case roomService.addMembersToSessions.name + apiConstants._SUCCESS:
    case roomService.deleteRoom.name + apiConstants._SUCCESS:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (room?.id === (action?.data?.live_room?.id ?? action?.data?.id)) {
            return action?.data?.live_room ?? action?.data;
          }
          return room;
        }),
      };
    default:
      return state;
  }
}

export default backOffice;
