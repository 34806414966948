import {theme} from "../../theme/theme";
import {MenuM} from "./MenuM";
import React from "react";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

export const Menu3Point = ({
                               menuItems,
                               position,
                               style,
                               color = theme.colors.primary,
                               optionStyle,
                               icon = 'ellipsis-v'
                           }) => {

    const renderButton = () => <FontAwesome5 style={{paddingHorizontal: 10, paddingVertical: 5}}
                                             name={icon}
                                             size={20}
                                             color={color}/>


    return <MenuM buttonComponent={renderButton()}
                  items={menuItems}
                  position={position}
                  style={style}
                  optionStyle={optionStyle}
    />
}