import React, {useContext} from "react";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {View} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import * as WebBrowser from "expo-web-browser";
import {IconImageM} from "@common/components/icons/IconsM";
import initial from "../../assets/offers/initial.png"
import essential from "../../assets/offers/essential.png"
import integral from "../../assets/offers/integral.png"
import holidays from "../../assets/offers/holidays.png"
import {useApiStatus} from "@common/hooks/useApiStatus";
import {subscriptionService} from "@data/services/subscriptionService";
import {apiIdle, apiRequest, apiRequestWithPrerequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {BadgeM} from "@common/components/badge/BadgeM";

export const offersImages = {
    plus: essential, premium: integral, initial, essential, integral, holidayworkbook: holidays
}

const borderColors = [theme.colors.material.secondary["100"], theme.colors.material.secondary["300"], theme.colors.material.secondary["500"]]

const textColors = [theme.colors.material.secondary["300"], theme.colors.material.secondary["500"], theme.colors.material.secondary["700"]]

export function PriceDataHeaderM({
                                     data,
                                     withCommitment,
                                     index,
                                     nextMode = false,
                                     size = "lg",
                                     parentTitle = "Essentielle",
                                     lightMode = false
                                 }) {
    const priceData = data?.prices["monthly"]
    const discountData = withCommitment ? priceData?.commitment_discount_data : priceData?.discount_data
    const successColor = nextMode ? theme.colors.grey : theme.colors.success

    const titleSize = size === "lg" ? 32 : size === "md" ? 24 : 16
    const descriptionSize = size === "lg" ? 14 : size === "md" ? 12 : 10
    const priceSize = size === "lg" ? 28 : size === "md" ? 20 : 18
    const priceSmallSize = size === "lg" ? 22 : size === "md" ? 16 : 12
    const marginBottom = size === "lg" ? 20 : size === "md" ? 10 : 5
    const imgWidth = size === "lg" ? 50 : size === "md" ? 30 : 25

    return <View
        style={{opacity: nextMode ? 0.8 : 1, borderLeftWidth: nextMode ? 1 : 0, paddingLeft: nextMode ? 10 : 0}}>
        <View style={{
            alignItems: 'flex-start'
            // flex: 1
        }}>
            {!nextMode && <IconImageM source={offersImages[data?.product_name]} width={imgWidth}/>}
            {nextMode && <BadgeM
                style={{
                    backgroundColor: theme.colors.lightMore,
                    color: theme.colors.greyAlt,
                    alignSelf: "flex-start",
                    marginBottom: -3
                }}
                count={data?.product_period_current}
                size={16}/>}
            <TextM fontWeight={!nextMode ? "Black" : "Medium"}
                   fontSize={titleSize}
                   wrap
                   style={{
                       marginTop: 5
                       // marginBottom: 10
                   }}
                   color={!nextMode ? textColors[index] : null}
            >{!nextMode && data?.product_title?.replace("Révisions d'été", parentTitle) + " - "}{data?.product_mode}</TextM>
            <TextM fontWeight={"Light"}
                   fontSize={descriptionSize}
                   wrap
                   style={{
                       // marginTop: 20,
                       // marginBottom: tinyMode ? 5 :20
                   }}>{data?.product_tagline}</TextM>
            {nextMode && !lightMode && <TextM fontWeight={"Light"}
                                              fontSize={descriptionSize}
                                              wrap
                                              style={{
                                                  // marginTop: 20,
                                                  // marginBottom: tinyMode ? 5 :20
                                              }}>{data?.product_slots}</TextM>}
        </View>
        <View style={{
            alignItems: 'flex-start',
            marginTop: marginBottom
            // flex: 1
        }}>
            <TextM fontWeight={nextMode ? "Medium" : "Bold"}
                   fontSize={priceSize}
                   color={discountData?.hourly_amount ? successColor : null}
                   wrap
                   style={{}}>{discountData?.hourly_amount ? <><TextM fontWeight={"Medium"}
                                                                      fontSize={priceSmallSize}
                                                                      color={successColor}
                                                                      wrap
                                                                      style={{
                                                                          textDecorationLine: 'line-through',
                                                                          textDecorationStyle: 'solid'
                                                                      }}>{priceData?.hourly_amount?.toString()?.replace(".", ",")}€</TextM>{" " + discountData?.hourly_amount?.toString()?.replace(".", ",")}</> : priceData?.hourly_amount?.toString()?.replace(".", ",")}<TextM
                color={discountData?.discount ? successColor : null}
                fontSize={14}
                fontWeight={nextMode ? "Medium" : "Bold"}>{priceData?.period}</TextM></TextM>
        </View>
        <View style={{
            alignItems: 'flex-start'
            // flex: 1
        }}>
            <TextM fontWeight={nextMode ? "Medium" : "Bold"}
                   fontSize={descriptionSize}
                   color={discountData?.discount ? successColor : null}
                   wrap
                   style={{
                       // marginTop: 20,
                       marginBottom: marginBottom
                   }}>soit {discountData?.amount ? <><TextM fontWeight={"Medium"}
                                                            color={successColor}
                                                            fontSize={descriptionSize}
                                                            wrap
                                                            style={{
                                                                textDecorationLine: 'line-through',
                                                                textDecorationStyle: 'solid'
                                                            }}>{priceData?.amount?.toString()?.replace(".", ",")}€</TextM> {" " + discountData?.amount?.toString()?.replace(".", ",")}€</> : priceData?.amount?.toString()?.replace(".", ",") + "€"} /mois</TextM>
        </View>
        {!lightMode && !nextMode && <View style={{
            alignItems: 'flex-start'
            // flex: 1
        }}>
            <TextM fontWeight={discountData?.discount ? "Medium" : "Light"}
                   fontSize={descriptionSize}
                   wrap
                   color={discountData?.discount ? successColor : theme.colors.primary}
                   style={{
                       // marginTop: 20,
                       marginBottom: 10
                   }}>{discountData?.discount ? discountData?.advantage : null}</TextM>
        </View>}
    </View>;
}

export function PrivilegesChecklistM({data, style = {}, tinyMode = false}) {

    const fontSize = tinyMode ? 12 : 14
    const iconSize = tinyMode ? 12 : 16
    return <>
        <View style={{
            alignItems: 'flex-start', // flex: 1,
            marginTop: 20,
            ...style
        }}>
            <TextM fontWeight={"Medium"}
                   fontSize={14}
                   wrap
                   style={{
                       // marginTop: 20,
                       marginBottom: 10
                   }}>{data?.privileges_title}</TextM>
        </View>
        <View style={{
            alignItems: 'flex-start',
            paddingLeft: 10
            // flex: 1
        }}>
            {data?.privileges_list?.map((privilege, index) => {
                return <View style={{flexDirection: "row", marginBottom: tinyMode ? 5 : 20}} key={index}>
                    <FontAwesome5 name={"check"}
                                  solid
                                  color={theme.colors.grey}
                                  style={{marginRight: tinyMode ? 4 : 6, marginTop: tinyMode ? 1 :2}}
                                  size={iconSize}/>
                    <TextM fontWeight={"Regular"}
                           fontSize={fontSize}
                           wrap
                           style={{
                               // marginTop: 20,
                               marginBottom: tinyMode ? 4 : 10
                           }}>{privilege}</TextM>
                </View>
            })}

        </View>
    </>
}

PrivilegesChecklistM.propTypes = {};
export const PricingColumnM = (props) => {
    const {index, withCommitment, data, userEmail, startNextMonth = false, nextModeData, onPress = null} = props

    const dimensions = useContext(DimensionsContext)

    const priceData = data?.prices["monthly"]
    const discountData = withCommitment ? priceData?.commitment_discount_data : priceData?.discount_data

    const navigation = useNavigation()

    useApiStatus(subscriptionService.getSubscribeRedirectUrl, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (data) => {

            if (IS_WEB) {
                window.open(data?.url, "_self")
            } else {
                // console.log(data)

                WebBrowser.openBrowserAsync(data?.url)
                navigation.goBack()
            }

        } // success callback
    )


    return <View style={{
        flex: 1, marginBottom: 50
        // alignItems: "stretch"
    }}>
        {data?.most_popular && <View style={{
            backgroundColor: borderColors[1],
            borderRadius: theme.roundness,
            width: dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 3,
            paddingVertical: 10,
            position: "absolute",
            top: -36,
            alignSelf: "center",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 30
        }}>
            <FontAwesome5 name={"star"}
                          solid
                          color={theme.colors.grey}
                          style={{marginRight: 4}}
                          size={16}/>
            <TextM wrap
                   fontWeight={"Medium"}
                   color={theme.colors.grey}>La plus populaire</TextM>

        </View>}
        <View style={{
            alignItems: 'flex-start',
            flex: 1,
            backgroundColor: theme.colors.white,
            borderRadius: theme.roundness,
            borderWidth: 2,
            borderColor: borderColors[index], // borderTopWidth: index === 1 ? 40 : 2,
            width: dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width / 3,
            marginHorizontal: 15,
            padding: 20,
            paddingBottom: dimensions?.width < 800 ? 50 : 20
        }}>
            <PriceDataHeaderM data={data} withCommitment={withCommitment} index={index}/>
            <FilledButtonM color={textColors[index]}
                           style={{marginTop: 10, width: "100%"}}
                           loading={props.request && props.requestPriceSlug === data?.slug}
                           disabled={props.request}
                           onPress={onPress ? () => {
                               onPress(data?.product_name, withCommitment)
                           } : () => {
                               props.apiRequest(subscriptionService.getSubscribeRedirectUrl, {}, {}, {
                                   email: userEmail,
                                   price_slug: data?.product_name,
                                   with_commitment: withCommitment,
                                   next_month: startNextMonth ? 1 : 0
                               })
                           }}
                           label={props.request && props.requestPriceSlug === priceData?.slug ? "Redirection" : "Choisir cette offre"}/>
            <PrivilegesChecklistM data={data}/>
            <View style={{
                alignItems: 'flex-start'
                // flex: 1
            }}>
                <TextM fontWeight={"Regular"}
                       fontSize={12}
                       wrap
                       style={{
                           // marginTop: 20,
                           marginBottom: 5
                       }}>{data?.product_period_end}, puis :</TextM>
                <PriceDataHeaderM data={nextModeData} withCommitment={withCommitment} index={index} nextMode size={"sm"}
                                  parentTitle={data?.product_title}/>
            </View>

        </View>
    </View>;
}

const mapStateToProps = state => {
    return {
        request: state.api.getSubscribeRedirectUrl?.request,
        success: state.api.getSubscribeRedirectUrl?.success,
        error: state.api.getSubscribeRedirectUrl?.error,
        requestPriceSlug: state.api.getSubscribeRedirectUrl?.urlParams?.price_slug,
        user: state.data.currentUser?.object
    }
}

const mapDispatchToProps = {
    apiRequestWithPrerequest, apiRequest, apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(PricingColumnM)