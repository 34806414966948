import React, {useContext, useEffect, useState} from 'react';
import {KeyboardAvoidingView, Platform, ScrollView, View} from "react-native";
import {DimensionsContext} from "@common/utils/mobileUtils";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import {useInterval} from "@common/utils/useInterval";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {tutorsService} from "@data/services/tutorsService";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {congratsTypes} from "@common/screens/CongratsScreen";
import {useNavigation} from "@react-navigation/native";
import {authService} from "@data/services/authService";
import {ImagesList} from "@common/camera/CameraImagesM";
import moment from "moment";
import {setAnswerInput} from "@data/redux/actions/formData";
import UncontrolledTextInputM from "@common/components/input/UncontrolledTextInputM";
import {DialogM} from "@common/components/alert/dialog/Dialog";

const TechnicalQuestionsScreen = (props) => {

    const {question, control, goToNextStep, field} = props
    const questionId = field?.name?.split('_')?.[1]
    const dimensions = useContext(DimensionsContext)
    const [answer, setAnswer] = useState(props.testAnswers?.find((answer) => answer?.application_question_id === parseInt(questionId)));

    const tooLate = answer?.viewed_at && moment().diff(answer?.viewed_at, 'seconds') > 5 * 60

    const [answerDuration, setAnswerDuration] = useState(Math.max(0, question?.answer_duration - Math.min(question?.answer_duration - 1, (answer?.viewed_at ? moment().diff(answer?.viewed_at, 'seconds') : 0))));

    // console.log("answer", answer)
    // console.log("moment().diff(answer?.viewed_at, 'seconds')", moment().diff(answer?.viewed_at, 'seconds'))

    // console.log("answer", answer)
    const minutes = Math.floor(answerDuration / 60);
    const [success, setSuccess] = useState(false);
    const isLastQuestion = props.testQuestions[props.testQuestions.length - 1] === question
    const navigation = useNavigation()
    const seconds = answerDuration % 60;

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    const result = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;

    useInterval(() => {
        setAnswerDuration(p => p - 1)
    }, answerDuration > 0 && !(answer && tooLate) ? 1000 : null);

    useEffect(() => {
        if (answerDuration < 1)
            props.apiRequest(tutorsService.submitTestAnswer, {id: question?.id}, {answer: props.answerInput ?? "-"})
    }, [answerDuration]);

    // useEffect(() => {
    //     props.setAnswerInput("")
    // }, []);

    useApiStatus(
        tutorsService.submitTestAnswer, // api service
        null, // success message on toast (string or null)
        true, // error message on toast (true) or keep it in redux state (false)
        (successData) => {
            props.setAnswerInput(null)
            //console.log("successData", successData)
            setAnswer(successData)
            setSuccess(true)
            if (isLastQuestion) {
                const congrats = [{
                    congratsType: congratsTypes.APPLICATION_FINISHED_CONGRATS,
                    congratsData: {
                        title: "CANDIDATURE TERMINÉE",
                        text: "C’était le dernier passage de ton dépôt de candidature. Nous reviendrons vers toi dans les plus brefs délais !"
                    }
                }]
                props.apiRequest(authService.getUser)
                navigation.replace("congrats", {congrats})
            } else goToNextStep()
        }
    )

    // console.log("props.answerInput", props.answerInput)

    return <>
        <View style={{
            flex: 1,
            width: dimensions?.width,
            alignItems: 'flex-start',
            justifyContent: 'flex-start'
            //justifyContent: 'center',
        }}>
            <ScrollView style={{
                flex: 1,
                width: dimensions?.width
                // backgroundColor: theme.colors.white,
            }}>
                <KeyboardAvoidingView style={{
                    flex: 1

                    // backgroundColor: theme.colors.white,
                }}
                                      contentContainerStyle={{
                                          backgroundColor: theme.colors.whiteAlmost,
                                          marginHorizontal: 5,
                                          alignItems: 'center',
                                          justifyContent: 'flex-start'
                                      }}
                                      keyboardVerticalOffset={180}
                                      behavior={'position'}>
                    {!answer?.answer && !(answer && tooLate) && <TextM color={theme.colors.error}
                                                                       wrap
                                                                       style={{
                                                                           marginBottom: 15,
                                                                           alignSelf: "center",
                                                                           textAlign: "center",
                                                                           width: "90%"
                                                                       }}
                                                                       fontWeight={"Light"}
                                                                       fontSize={14}>
                        Attention, si tu quittes cet écran, c'est comme si tu avais passé la question.
                    </TextM>}
                    <View style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: "center",
                        justifyContent: 'space-between',
                        width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4
                    }}>
                        <View>
                            <TextM fontWeight={"Bold"} wrap>
                                {answer && tooLate ? "Ta réponse" : "Rédige ta réponse"}
                            </TextM>
                            {!!answer && tooLate && <TextM wrap
                                                           style={{}}>{answer?.answer ? answer?.answer : "Pas de réponse. Comme tu as déjà vu cette question, passe à la suite."}</TextM>}
                        </View>

                        {!answer?.answer && !(answer && tooLate) && <View style={{
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <TextM fontWeight={"Bold"} color={theme.colors.primary}>
                                TEMPS RESTANT
                            </TextM>
                            <TextM fontWeight={"Bold"} color={theme.colors.primary}>
                                {result}
                            </TextM>
                        </View>}
                    </View>
                    {!answer?.answer && !(answer && tooLate) && <UncontrolledTextInputM placeholder="Ta réponse..."
                                                                                        returnKeyType={"done"}
                                                                                        style={{
                                                                                            width: Platform.OS !== 'web' || dimensions?.width < 800 ? dimensions?.width - 40 : dimensions?.width * 3 / 4,
                                                                                            height: Platform.OS !== 'web' || dimensions?.width < 800 ? 120 : 200,
                                                                                            alignSelf: "center"
                                                                                        }}
                                                                                        multiline={true}
                                                                                        numberOfLines={6}
                                                                                        value={props.answerInput}
                                                                                        name={"answer_" + question?.id}
                                                                                        onChangeText={(value) => {
                                                                                            props.setAnswerInput(value)
                                                                                        }}
                        // value={formData[x?.name]}
                    />}
                    <ImagesList images={[{
                        uri: question?.attachment_path,
                        width: 200,
                        height: 200
                    }]}
                                contentHeight={dimensions?.height / 4}
                                style={{
                                    marginTop: 10
                                }}/>
                </KeyboardAvoidingView>
            </ScrollView>
        </View>
        <FilledButtonM label={"Terminer"}
                       color={isLastQuestion ? theme.colors.success : theme.colors.primary}
                       style={{
                           position: 'absolute',
                           bottom: 0
                       }}
                       loading={props.submitTestAnswerRequest}
                       disabled={props.submitTestAnswerRequest}
                       onPress={() => {
                           DialogM.show({
                               text1: 'Envoyer la réponse ?',
                               // text2: `Voulez-vous valider la candidature de ${row?.firstname} ${row?.lastname} ?`,
                               buttonText1: 'Envoyer', // buttonColor1: theme.colors.error,
                               onPress: () => {
                                   props.apiRequest(tutorsService.submitTestAnswer, {id: question?.id}, {answer: props.answerInput ?? "-"})
                               },
                               buttonText2: 'Annuler',
                               onPressBack: () => {

                               }
                           })
                       }}
        />
    </>

}
const mapStateToProps = state => {
    return {
        application_steps: state.data.staticData?.application_steps,
        user: state.data.currentUser?.object,
        testQuestions: state.data.tutors?.testQuestions,
        testAnswers: state.data.tutors?.testAnswers,
        videoRecord: state.camera?.videoRecord,
        answerInput: state.data.formData?.answerInput,
        submitTestAnswerRequest: state.api.submitTestAnswer?.request,
        submitTestAnswerSuccess: state.api.submitTestAnswer?.success
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle,
        setAnswerInput
    }

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalQuestionsScreen)