import React, {useContext, useState} from "react";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {Dimensions, Linking, Platform, TouchableOpacity, View} from "react-native";
import {connect} from "react-redux";
import TitleM from "@common/components/text/TitleM";
import {displayTimeSeconds, formatFullDateTime} from "@data/utility/Utils";
import {theme} from "@common/theme/theme";
import {useInterval} from "@common/utils/useInterval";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import TextM from "@common/components/text/TextM";
import AvatarM from "@common/components/avatar/AvatarM";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {ActivityIndicator, Appbar, IconButton} from "react-native-paper";
import {StatusBar} from "expo-status-bar";
import {useNavigation} from "@react-navigation/native";
import {interviewsService} from "@data/services/interviewsService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import TextButtonM from "@common/components/button/TextButtonM";

export const header = (title, _goBack = () => {
}) => {
    return (<View style={{
        width: Platform.OS === 'web' ? Dimensions.get('window').width : null,
        alignSelf: Platform.OS !== 'web' ? null : "center"
    }}>
        <Appbar.Header
            theme={{colors: {primary: theme.colors.white}}}
            statusBarHeight={Platform.OS === "ios" ? StatusBar.height : 0}
        >
            {!IS_WEB && <Appbar.BackAction onPress={_goBack}/>}
            <Appbar.Content
                color={theme.colors.primary}
                titleStyle={{
                    fontFamily: "Montserrat-Bold",
                }}
                title={title}
            />
        </Appbar.Header>
    </View>)
}
const CardStyle = {
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 20,
    marginRight: 20,
    borderColor: theme.colors.lightMore,
    borderWidth: 1,
    padding: 10,
    backgroundColor: theme.colors.white,
}

export function InterviewHeader(props) {

    const {interview, student} = props
    const [time, setTime] = useState(0)

    const onWebConfClick = (e) => {
        props.apiRequest(interviewsService.interviewTutorConnected, {id: props.interview?.id})
        if (IS_WEB)
            window.open(props.slot?.conference_link, '_blank', 'noopener,noreferrer')
        else Linking.openURL(props.slot?.conference_link).catch(err => console.error("Couldn't load page", err))
    }

    useApiStatus(
        interviewsService.interviewTutorConnected, // api service
        null, // success message on toast (string or null)
        true, // error message from server in toast (true) or keep it in redux state (false)
        (successData) => {
        }
    )


    useInterval(() => {
        setTime(pt => pt + 1)
    }, !interview?.finished_at ? 1000 : null)

    const navigation = useNavigation()
    const dimensions = useContext(DimensionsContext)

    return <View style={{width: dimensions?.width}}>

        {header(<View style={{
            flexDirection: 'row',
        }}>
            <View>
                <TitleM>Entretien hebdomadaire</TitleM>
                <TextM wrap> {formatFullDateTime(interview?.date_time)}</TextM>
            </View>

            {!IS_WEB && <View style={{alignSelf: 'flex-end'}}>
                <IconButton
                    icon={"video"}
                    iconColor={theme.colors.primary}
                    size={32}
                    onPress={onWebConfClick}
                />
            </View>}

        </View>)}
        <View style={{flexDirection: 'row', width: dimensions?.width, justifyContent: 'center', flexWrap: 'wrap'}}>

            {IS_WEB && <View style={{
                ...CardStyle,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.colors.white,
            }}>
                {props.tutorConnectedRequest ? <ActivityIndicator color={theme.colors.primary}/> :
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <TextButtonM wrap onPress={onWebConfClick} label="Accéder à la web conférence"/>
                        <IconButton
                            icon={"video"}
                            iconColor={theme.colors.primary}
                            size={22}
                            onPress={onWebConfClick}
                        />
                    </View>
                }
            </View>}
            <View style={{
                ...CardStyle,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.colors.white,
            }}>
                <FontAwesome5 name="clock" size={22} color={theme.colors.primary}/>
                <TextM fontSize={20} style={{marginLeft: 6}}>{displayTimeSeconds(time)}</TextM>
            </View>
            <TouchableOpacity style={{
                flexDirection: 'row',
                ...CardStyle,
                alignItems: 'center',
                backgroundColor: theme.colors.white,
                flexWrap: 'wrap',
                justifyContent: 'center'
            }} onPress={() => {
                navigation.push("student-profil",
                    {studentId: student?.id}
                );
            }}>
                <AvatarM user={student}/>
                <View style={{marginLeft: 15}}>
                    <TextM color={theme.colors.primary}>{student?.firstname} {student?.lastname} </TextM>
                    <TextM color={theme.colors.light}>{student?.profile?.class}  </TextM>
                </View>

            </TouchableOpacity>

        </View>
    </View>
}

const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        tutorConnectedRequest: state.api.interviewTutorConnected?.request,
        students: state.data?.currentUser?.object?.students,
        slots: state.data?.currentUser?.object?.interview_slots
    }
}

const mapDispatchToProps = {
    apiRequest,
    apiIdle
}

export default connect(mapStateToProps, mapDispatchToProps)(InterviewHeader)