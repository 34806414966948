import React, {useEffect, useState} from 'react';
import {FormStepper} from "@common/components/stepper/FormStepper";
import {StatusBar, View} from "react-native";
import {theme} from "@common/theme/theme";
import {useNavigation} from "@react-navigation/native";
import {validation} from "@data/utility/validation";
import {slotsList} from "@data/constants/slotTutorConstants";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {connect} from "react-redux";
import {backOfficeService} from "@data/services/backOfficeService";
import {useApiStatus} from "@common/hooks/useApiStatus";
import {findItemInListWithId} from "@data/utility/dataUtils";
import SchoolResultsStep from "./SchoolResultsStep";
import StatisticsStep from "./StatisticsStep";
import {ActivityIndicator, Text} from "react-native-paper";
import {IS_WEB} from "@common/utils/mobileUtils";
import LinkTextM from "@common/components/text/LinkTextM";
import {interviewReportService} from "@data/services/interviewReportService";
import {congratsTypes} from "@common/screens/CongratsScreen";
import moment from "moment";

const NewInterviewReportScreen = (props) => {

    const {studentId} = props.route.params

    const navigation = useNavigation()
    const [formValues, setFormValues] = useState({});

    const [defaultStartDate, setDefaultStartDate] = useState(null)

    useEffect(() => {
        props.apiRequest(interviewReportService.getDefaultStartDate, {studentId: studentId})
    }, []);

    useApiStatus(
        interviewReportService.getDefaultStartDate,
        null,
        false,
        (data) => {
            // console.log(data)
            // defaultValues.start_date = data?.start_date
            setDefaultStartDate(data?.start_date)
        }
    )

    const formData = [
        {
            label: "Période",
            type: "date-multiple",
            inputs: [
                {
                    title: "Date de début",
                    name: "start_date",
                    type: "date",
                    defaultValue: defaultStartDate
                },
                {
                    title: "Date de fin",
                    name: "end_date",
                    type: "date",
                    defaultValue: moment().format("YYYY-MM-DD")
                }
            ]
            // validationRules: {...validation.required()}
        },
        {
            label: "Résultats scolaires",
            type: "custom",
            name: "prioritized_school_subjects",
            showNextButton: true,
            component: SchoolResultsStep,
            componentProps: {studentId},
            defaultValue: [],
            validationRules: {...validation.required()}
        },
        {
            label: "Engagement Masteur",
            type: "custom",
            name: "statistics",
            showNextButton: true,
            component: StatisticsStep,
            componentProps: {studentId},
            defaultValue: [],
            validationRules: {...validation.required()}
        },
        {
            label: "Commentaires",
            type: "text.multiple",
            inputs: [
                {
                    name: "parent_message",
                    label: "Message au parent",
                    placeholder: "Message au parent",
                    height: 150,
                    defaultValue: "",
                    validationRules: {
                        // ...validation.required(),
                        // ...validation.minLength(8)
                    }
                },
                {
                    name: "tutor_message",
                    label: "Message au tuteur",
                    placeholder: "Message au tuteur",
                    height: 150,
                    defaultValue: "",
                    validationRules: {
                        // ...validation.required(),
                        // ...validation.minLength(8)
                    }
                },
                {
                    name: "internal_comment",
                    label: "Autres commentaires",
                    placeholder: "Autres commentaires",
                    height: 150,
                    defaultValue: "",
                    validationRules: {
                        // ...validation.required(),
                        // ...validation.minLength(8)
                    }
                }
            ]
        },
        {
            label: "Configurer l'envoi",
            type: "text.multiple",
            inputs: [],
            checkBoxes: [
                {
                    name: "send_to_parent",
                    label: "Envoyer le rapport par SMS au parent",
                    defaultValue: true
                    // validationRules: {...validation.required()}
                },
                {
                    name: "with_rdv",
                    label: "Inclure le lien de prise de RDV à la fin du rapport",
                    defaultValue: true
                    // validationRules: {...validation.required()}
                },
                {
                    name: "send_to_tutor",
                    label: "Envoyer le rapport par SMS au tuteur",
                    defaultValue: true
                    // validationRules: {...validation.required()}
                }
            ]
        }
    ]

    const onSubmit = data => {
        if (props.newInterviewReportRequest) return
        // console.log(data)

        delete data.statistics
        delete data.prioritized_school_subjects

        // console.log(data)
        props.apiRequest(interviewReportService.newInterviewReport, {studentId: studentId}, data)
    }
    useApiStatus(
        interviewReportService.newInterviewReport, // api service
        null, // success message on toast (string or null)
        false, // error message on toast (true) or keep it in redux state (false)
        (data) => {
            // console.log(data)
            props.apiIdle(interviewReportService.newInterviewReport)
            // navigation.goBack()
            const congrats = [{
                congratsType: congratsTypes.GENERIC,
                congratsData: {
                    title: "Effectué",
                    text: "Le rapport d'entretien a été créé avec succès"
                }
            }]
            navigation.replace("congrats", {congrats})
        } // success callback
    )

    return (
        <View style={{
            flex: 1,
            alignItems: 'center'
        }}>

            {props.getDefaultStartDateRequest || !defaultStartDate ? <ActivityIndicator color={theme.colors.primary}
                                                                                        style={{marginTop: 30}}
                />
                : <FormStepper navigation={navigation}
                               track
                               formData={formData?.filter(x => !x.is_masked)}
                               champion={null}
                               formValues={formValues}
                               setFormValues={setFormValues}
                               onSubmit={onSubmit}
                               submitted={props.newInterviewReportRequest}
                               previousRouteName={"main"}
                               submittedMessage=""
                               serverError={props.newInterviewReportError}
                               submitButtonText="Envoyer"
                />}
            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    )
};
const mapStateToProps = state => {
    return {
        newInterviewReportRequest: state.api.newInterviewReport?.request,
        newInterviewReportError: state.api.newInterviewReport?.error,
        getDefaultStartDateRequest: state.api.getDefaultStartDate?.request,
        getDefaultStartDateSuccess: state.api.getDefaultStartDate?.success
    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(NewInterviewReportScreen)
