import {View} from "react-native";
import TextM from "@common/components/text/TextM";
import {theme} from "@common/theme/theme";
import moment from "moment";
import React from "react";
import {PrioritizedSchoolSubjects} from "../../tutor/students/ProfilDetails";

export const ProfileInfo = ({user, showEmail, showMobile, showSchoolSubjects = false, dateFormat = "MMM YYYY", style}) => {
    return (<View style={{width: "80%", alignItems: "flex-start", justifyContent: "flex-start", marginBottom: 20, ...style}}>
        {showEmail && <TextM color={theme.colors.light} style={{marginTop: 0}} wrap>{user?.email}</TextM>}
        {showMobile && <TextM color={theme.colors.light} style={{marginTop: 0}} wrap>{user?.mobile}</TextM>}
        {user?.profile?.class &&
            <TextM style={{marginTop: 5}} wrap>{user?.profile?.class}</TextM>}
        {showSchoolSubjects && user?.prioritized_school_subjects && <PrioritizedSchoolSubjects student={user} />}
        <TextM style={{marginTop: 5}} fontWeight="Light" wrap>Membre
            depuis {moment(user?.created_at).format(dateFormat)}</TextM>
    </View>);
}