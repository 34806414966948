const SAVE_USER_DATA = 'SAVE_USER_DATA';

const CLEAR_HMS_INSTANCE = 'CLEAR_HMS_INSTANCE';

const RESET_JOIN_CONFIG = 'RESET_JOIN_CONFIG';

const CHANGE_JOIN_AUDIO_MUTED = 'CHANGE_JOIN_AUDIO_MUTED';

const CHANGE_JOIN_VIDEO_MUTED = 'CHANGE_JOIN_VIDEO_MUTED';

const CHANGE_MIRROR_CAMERA = 'CHANGE_MIRROR_CAMERA';

const MUTE_AUDIO = 'MUTE_AUDIO';

const MUTE_VIDEO = 'MUTE_VIDEO';

export default {
    SAVE_USER_DATA,
    CLEAR_HMS_INSTANCE,
    RESET_JOIN_CONFIG,
    CHANGE_JOIN_AUDIO_MUTED,
    CHANGE_JOIN_VIDEO_MUTED,
    CHANGE_MIRROR_CAMERA,
    MUTE_AUDIO,
    MUTE_VIDEO,
};