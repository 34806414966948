import React, {useContext} from 'react';
import {Platform, RefreshControl, ScrollView, StatusBar, View} from "react-native";
import {apiIdle, apiRequest} from "@data/redux/actions/api";
import {theme} from "@common/theme/theme";
import {connect, useSelector} from "react-redux";
import {DimensionsContext, IS_WEB} from "@common/utils/mobileUtils";
import {IconImageM} from "@common/components/icons/IconsM";
import FilledButtonM from "@common/components/button/FilledButtonM";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import TextM from "@common/components/text/TextM";
import {getPrivilegedRole, isOnTrial} from "@data/utility/ability";
import {authService} from "@data/services/authService";
import {isSubscribed, isSubscriptionPaused} from "@data/utility/dataUtils";
import {URL} from 'react-native-url-polyfill';
import {offersImages} from "../../../auth/PricingColumnM";
import {canSubscribeToTrialEssential} from "./BecomePremiumCard";
import {SubscriptionStateCard} from "./SubscriptionStateCard";


export const subscriptionStatusNames = {
    recurring: "Abonnement actif",
    on_grace_period: "Abonnement actif jusqu'à échéance",
    on_trial: "Essai gratuit en cours",
    on_generic_trial: "Essai gratuit en cours",
    on_grace_period_before_pause: "Mise en pause planifiée",
    paused: "Abonnement mis en pause",
    planned: "Activation planifiée",
    expired_trial: "Essai gratuit expiré",
    ended: "Abonnement expiré"
}


export const ProductIcon = ({size = 24, style}) => {

    const user = useSelector(state => state.data.currentUser?.object)

    return getPrivilegedRole(user) !== "basic" && <IconImageM width={size}
                                                              source={offersImages[getPrivilegedRole(user)]}
                                                              style={{
                                                                  // alignSelf: "flex-end",
                                                                  // marginLeft: 10,
                                                                  // marginBottom: 5,
                                                                  ...style
                                                              }}/>
}

const MySubscriptionScreen = (props) => {

    // ** Component props
    const {navigation} = props

    const insets = useSafeAreaInsets();

    const product = getPrivilegedRole(props.user)

    const dimensions = useContext(DimensionsContext)
    return (
        <View style={{
            flex: 1,
            alignItems: 'center',
            width: dimensions?.width,
            alignSelf: Platform.OS !== 'web' ? null : "center"
        }}>

            <ScrollView style={{
                // alignItems: 'center',
                paddingTop: insets.top + 10,
                width: dimensions?.width
                // paddingHorizontal: 20,
                // justifyContent: 'center'
            }}
                        refreshControl={<RefreshControl
                            refreshing={!!props.getUserRequest}
                            onRefresh={() => props.apiRequest(authService.getUser)}
                        />}
                        contentContainerStyle={{
                            alignItems: "center"
                        }}
            >
                {/*<MySubscriptionInfos user={props.user}/>*/}

                <SubscriptionStateCard user={props.user}
                                       disabled
                                       style={{
                                           width: dimensions?.width,
                                           alignSelf: "center"
                                       }}/>

                {((product === "basic"))
                    && <FilledButtonM color={theme.colors.accentDark}
                                      onPress={() => {
                                          if (canSubscribeToTrialEssential(props.user))
                                              navigation.push("start-trial")
                                          else
                                              navigation.push("offer-essential")
                                      }}
                                      textColor={theme.colors.white}
                                      style={{
                                          width: Platform.OS !== 'web' ? dimensions?.width - 20 : dimensions?.width / 2.5,
                                          marginTop: 30
                                      }}
                                      label={canSubscribeToTrialEssential(props.user) ? "ESSAI GRATUIT" : "Voir les offres"}/>}

                {(isOnTrial(props.user))
                    && !isSubscriptionPaused(props.user)
                    && (Platform.OS !== "ios" ? <FilledButtonM color={theme.colors.accentDark}
                                                               onPress={() => {
                                                                   // navigation.replace("choose-trial", {product: "product"})
                                                                   const url = new URL(props.user?.subscribe_url)
                                                                   navigation.push(url.pathname.slice(1), {
                                                                       user: url.searchParams.get("user")
                                                                   })
                                                                   // WebBrowser.openBrowserAsync(props.user?.subscribe_url)
                                                               }}
                        // labelStyle={{color: theme.colors.white}}
                                                               style={{
                                                                   // width: dimensions?.width - 20,
                                                                   marginTop: 30
                                                               }}
                                                               label={(isSubscribed(props.user)) ? "Mon abonnement" : "S'abonner"}/> : (!isOnTrial(props.user))
                        ? null
                        : <TextM color={theme.colors.grey}
                                 style={{
                                     textAlign: "center",
                                     marginTop: 10,
                                     paddingHorizontal: 20
                                 }}
                                 fontWeight="Medium"
                                 wrap
                        >
                            On ne peut pas s'abonner dans l'appli. Ce n'est pas idéal, nous le savons.
                        </TextM>)}

                {(product !== "basic"
                        && !isOnTrial(props.user))
                    && (Platform.OS !== "ios" ? <FilledButtonM color={theme.colors.primary}
                                                               mode="outlined"
                                                               onPress={() => {
                                                                   const url = new URL(props.user?.subscribe_url)
                                                                   navigation.push(url.pathname.slice(1), {
                                                                       user: url.searchParams.get("user")
                                                                   })
                                                               }}
                        // labelStyle={{color: theme.colors.white}}
                                                               style={{
                                                                   width: dimensions?.width - 20,
                                                                   marginTop: 30
                                                               }}
                                                               label="Gérer l'abonnement"/> : null)}

                {IS_WEB && <FilledButtonM mode={"text"}
                                          icon={"chevron-left"}
                                          color={theme.colors.link}
                                          style={{marginTop: 60}}
                                          onPress={() => {
                                              if (navigation.canGoBack())
                                                  navigation.goBack()
                                              else
                                                  navigation.replace("account")
                                          }}
                                          label={"Retour"}/>}

                <View style={{height: 130}}/>
            </ScrollView>

            <StatusBar style="dark" backgroundColor={theme.colors.white} translucent={false}/>
        </View>
    );
}
const mapStateToProps = state => {
    return {
        user: state.data.currentUser?.object,
        getUserRequest: state.api.getUser?.request

    }
}

const mapDispatchToProps =
    {
        apiRequest,
        apiIdle
    }

export default connect(mapStateToProps, mapDispatchToProps)(MySubscriptionScreen)